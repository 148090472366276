import axios from "axios";
declare const dataURL: string;

export class EngineAPIRepo {

  async engineList(pageNumber: number) {
    const paramsQuery: any = {
      "controller": "Engine",
      "FunctionName": "List",
      "PageNumber": pageNumber.toString()
    };
    
    const response = await axios.post(dataURL + "?ReturnType=JSON", paramsQuery);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    //return data
    return response.data;
  }
  
  async engineRun(EngineID: number) {
    const paramsQuery: any = {
      "controller": "Engine",
      "FunctionName": "RunEngine",
      "EngineID": EngineID
    };
    
    const response = await axios.post(dataURL + "?ReturnType=JSON", paramsQuery);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    //return data
    return response.data;
  }

}