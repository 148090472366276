






































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import USState from "../static/USstates.json";

import {
  notifier
} from "../models/common";
import directives from "@/helpers/directives";
import axios from "axios";
import Icon from 'vue-awesome/components/Icon'
import {ApiHelper} from "@/helpers/all";

declare const $: any;
declare const dataURL: string;
declare const Stripe: any;
declare const htmlParse : Function;
declare const htmlCheck : Function;
declare const getRouteData: Function;

interface Props {
}

interface Events {
}

@Component({
  inheritAttrs: false,
  components: {},
  methods: {},
  directives
})
export default class PayInvoice extends TSXComponent<Props, Events> {
  orderNumber: number = 0;
  orderTotal: number = 0;
  'v-icon': Icon;
  browserHeight = 0;
  sameAsBilling = true;
  loading = false;
  viewType = "CurrentUsers";
  currentRole = sessionStorage.getItem("userRole");
  pageNumber = 1;
  items: any[] = [];
  filters = {
    aID: "",
    UUID: "",
  };
  pageData = {
    isLoading: false,
    isPaying: false,
    hasError: false,
    paymentTotalAmount: true,
    verifiedBtnEnabled: false,
    countries: [],
    items: [],
    controls: {
      stripe: {
        id: '',
        token: '',
        last4: '',
        error: '',
        publicKey: '',
      },
      email: {
        value: '',
        error: '',
      },
      cardName: {
        value: '',
        error: '',
      },
      cardNumber: {
        value: '',
        type: '',
        error: '',
      },
      cardExpire: {
        month: '',
        errorMonth: '',
        year: '',
        errorYear: '',
        value: '',
      },
      cvc: {
        value: '',
        error: '',
      },
      billingAddress: {
        address: '',
        addressError: '',
        address2: '',
        address2Error: '',
        city: '',
        cityError: '',
        state: '',
        stateError: '',
        zipcode: '',
        zipcodeError: '',
      },
      shippingAddress: {
        sameAsBilling: true,
        address: '',
        addressError: '',
        address2: '',
        address2Error: '',
        city: '',
        cityError: '',
        state: '',
        stateError: '',
        zipcode: '',
        zipcodeError: '',
      }
    },
    cartInfo: {
      subTotal: 0,
      total: 0,
      poID: 0,
    }
  }
  DistyTotal: any = {};
  quoteData = [];
  quoteInfo = {};
  quoteInfoArray = [];
  billing_countries = [];
  billing_states: any[] = [];
  shipping_countries = [];
  shipping_states: any[] = [];
  shipping_address = {
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    sameAsBilling: true
  };
  billing_address = {
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  };
  customer = "";
  reseller = "";
  aID = 0;
  source = "";
  accountID = "";
  sourceQuoteIds = "";
  financialSource: object[] = [];
  selectedGlobalTagsID: number[] = [];
  selectedGlobalTags: object[] = [];
  estPercent = 0;
  fundingSourceName = "";
  expandDecimalPrecision = 2;
  hpDirectTaxRate = 0;
  hpDirectTaxRateTmp = 0;
  globalMargin = 0;
  globalMarginBK = 0;
  indirectTaxRate = 0;
  indirectTaxRateTmp = 0;
  validSynnexCredential = false;
  validTechdataCredential = false;
  sourceCredentials: any = [];
  confirmedGlobalMargin = false;
  selectedOrderContracts = "";
  $decimals: any;
  orderContractsID: number[] = [];
  customerDepartment: any = [];
  stateList: any = [];
  INVOICEPAID = false;
  PaymentText = '';
  created() {
    for (const i in USState) {
      this.shipping_states.push({
        state_code: USState[i].Abbr,
        name: USState[i].Abbr,
        data: USState[i]
      });
      this.billing_states.push({
        state_code: USState[i].Abbr,
        name: USState[i].Abbr,
        data: USState[i]
      });
    }

    document.title = 'Invoice Payment Page';
    this.browserHeight = document.documentElement.clientHeight;
    // $("body").addClass('login');

    // this.getStates("shipping", "United States");
    // this.getStates("billing", "United States");

    if (typeof this.$route.query.aID === "string") {
      this.filters.aID = this.$route.query.aID;
    }
    if (typeof this.$route.query.UUID === "string") {
      this.filters.UUID = this.$route.query.UUID;
    }
    if (typeof this.$route.query.template === "string") {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `css/${this.$route.query.template}_customer.css`;
      document.head.appendChild(link);
    }
    if (this.filters.aID && this.filters.UUID) {
      this.fetchData();
    }
  }
  isDynamic() {
    if(this.sourceID == 10) {
      return true;
    }
    return false;
  }
  getTaxRateBySource() {
    let tmpTaxRate = 0;
    if(this.isIndirect() && this.indirectTaxRate > 0) {//indirect
      tmpTaxRate = this.indirectTaxRate;
    }else if(!this.isIndirect() && this.hpDirectTaxRate > 0) {//direct
      tmpTaxRate = this.hpDirectTaxRate;
    }

    return tmpTaxRate;
  }


  editGetMarginRate(item, type) {
    let tmpRate = (typeof item.MARGINRATE != "undefined" && item.MARGINRATE != "" ? item.MARGINRATE : 0);
    if(type == "order") {
      if(tmpRate == 0 && item.POMARGIN != 0 && item.POMARGIN != "" && item.PRICEREG_BK != 0) {
        tmpRate = item.POMARGIN / item.PRICEREG_BK * 100;
      }
    }else if(type == "quote") {
      if(tmpRate == 0 && item.QMARGIN != 0 && item.QMARGIN != "" && item.QPRICEREG_BK != 0) {
        tmpRate = item.QMARGIN / item.QPRICEREG_BK * 100;
      }
    }
    tmpRate = parseFloat(tmpRate.toFixed(this.$decimals));

    return tmpRate;
  }

  checkItemIsFee(val) {
    return (val.POPRODSKU.toLowerCase() == "fee" && val.POCUSTOMERPRICE == 0 && val.POINCLUDED == 0) ? true : false;
  }
  getMarkupFromMargin(marginRate) {
    let markup = 0;
    if(marginRate == 100) {
      return 100;
    }
    if(marginRate != 0) {
      const tmp = parseFloat((marginRate/100).toFixed(4));
      markup = parseFloat(((tmp / (1 - tmp))*100).toFixed(4))
    }

    return markup;
  }
  getConfirmedGlobalMargin(isLineItem = false) {
    if(this.isIndirect() && this.confirmedGlobalMargin) {
      if(isLineItem) {
        // return markup
        return this.getMarkupFromMargin(this.globalMargin);
      }else {
        // return order margin
        return this.globalMargin;
      }
    }else if(this.source =='Synnex'){
      return this.getMarkupFromMargin(this.globalMargin);
    }

    return 0;
  }

  globalTags: any = {
    CUSTOMFIELDOPTIONNAME: ""
  };
  customDropdown: object[] = [];
  selectedContracts: any = [];

  async getCustomData(quoteId) {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller				: "Helpers",
      FunctionName			: "CustomData",
      DataType          : "1",
      Id                : quoteId,
      DropdownField     : 25,
      contractDropdown  : 26,
      orderCustomData   : true
    });

    if(response.data.STATUS == 1) {
      this.fullCustomData = [];
      for(const val of response.data.CUSTOMDEFINITION) {
        let tmpValue: any = [];
        if(typeof response.data.CUSTOMDATA != "undefined") {
          tmpValue = response.data.CUSTOMDATA.filter(tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID);
        }
        this.fullCustomData.push({
          AID: val.AID,
          CUSTOMFIELDID: val.CUSTOMFIELDID,
          CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
          CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
          CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
          CUSTOMDTYPE: val.CUSTOMDTYPE,
          OPTIONS: val.OPTIONS || []
        });
      }

      this.financialSource = response.data.financialSource;
      this.globalTags = response.data.globalTags;
      this.customDropdown = response.data.customnDropdownField;
      this.orderContractNumber = response.data.customContractNumber || [];
      this.selectedContracts = response.data.selectedContractData || [];
      if(this.selectedContracts.length) {
        this.selectedOrderContracts = this.selectedContracts[0].CUSTOMFIELDOPTIONNAME;
        this.orderContractsID = this.selectedContracts.map(
          (val: any) => val.CUSTOMFIELDOPTIONID
        );
      }
      this.selectedGlobalTags = (response.data.selectedGlobalTags || []).map((val: any) => ({
        CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
        CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
      }));
      this.selectedGlobalTagsID = this.selectedGlobalTags.map((val: any) => val.CUSTOMFIELDOPTIONID);
    }


    // sync custom data from quote to order
    try {
      // get custom data of quote
      const result = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomData",
        DataType: "2",
        Id: quoteId
      });
      if (result.data.STATUS == 1) {
        const quoteCustomFields = result.data.CUSTOMDEFINITION || [];
        const quoteCustomData = result.data.CUSTOMDATA || [];
        for(const item of quoteCustomFields) {
          const customData = quoteCustomData.find(t => t.CUSTOMFIELDID == item.CUSTOMFIELDID);
          if(!customData) continue;

          // check related field in order by field name
          const orderRelatedField = this.fullCustomData.find((t: any) => t.CUSTOMFIELDNAME.toLowerCase() == item.CUSTOMFIELDNAME.toLowerCase());
          if(!orderRelatedField) continue;

          orderRelatedField.CUSTOMVALUE = customData.CUSTOMVALUE;
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async fetchData() {
    try {
      this.pageData.isLoading = true;
      this.pageData.hasError = false;
      fetch(dataURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          controller: "Pay",
          FunctionName: "invoiceDetails",
          UUID: this.filters.UUID,
          aID: this.filters.aID,
          convertingToOrder: true,
          session: sessionStorage.getItem("sessionID"),
          userRole: sessionStorage.getItem("userRole"),
        })
      })
        .then(response => response.json())
        .then(async data => {
          const QUOTEITEMS = (data.INVOICEITEMS || []).map((item) => {
            return {
              name: item.IPRODDESC || '',
              SKU: item.IPRODSKU || '',
              pricePer: item.ICUSTOMERPRICE || 0,
              itemTotal: item.TOTALPRICE || 0,
              quantity: item.IPRODQUANTITY || 0,
            }
          });

          this.INVOICEPAID = !!data.INVOICEPAID;
          this.PaymentText = data.PaymentText || '';
          const INVOICEDETAILS = data.INVOICEDETAILS || {};
          this.splitBySubQuoteId = data.splitBySubOrderId || [];
          // if (this.splitBySubQuoteId[0] && this.splitBySubQuoteId[0].euInfo && this.splitBySubQuoteId[0].euInfo.ADDR1 != '') {
          //   this.pageData.controls.shippingAddress.sameAsBilling = false;
          //   this.pageData.controls.shippingAddress.address = this.splitBySubQuoteId[0].euInfo.ADDR1;
          //   this.pageData.controls.shippingAddress.address2 = this.splitBySubQuoteId[0].euInfo.ADDR2;
          //   this.pageData.controls.shippingAddress.city = this.splitBySubQuoteId[0].euInfo.CITY;
          //   this.pageData.controls.shippingAddress.state = this.splitBySubQuoteId[0].euInfo.EUSTATE;
          //   this.pageData.controls.shippingAddress.zipcode = this.splitBySubQuoteId[0].euInfo.ZIP;
          // }
          this.pageData.controls.billingAddress.address = INVOICEDETAILS.IADDRESSBILLING || '';
          this.pageData.controls.billingAddress.address2 = INVOICEDETAILS.IADDRESS2BILLING || '';
          this.pageData.controls.billingAddress.city = INVOICEDETAILS.ICITYBILLING || '';
          this.pageData.controls.billingAddress.state = INVOICEDETAILS.ISTATEBILLING || '';
          this.pageData.controls.billingAddress.zipcode = INVOICEDETAILS.IZIPBILLING || '';
          this.pageData.controls.shippingAddress.sameAsBilling = false;

          this.pageData.controls.shippingAddress.address = INVOICEDETAILS.IADDRESS || '';
          this.pageData.controls.shippingAddress.address2 = INVOICEDETAILS.IADDRESS2 || '';
          this.pageData.controls.shippingAddress.city = INVOICEDETAILS.ICITY || '';
          this.pageData.controls.shippingAddress.state = INVOICEDETAILS.ISTATE || '';
          this.pageData.controls.shippingAddress.zipcode = INVOICEDETAILS.IZIP || '';
          
          this.acctPriceTypeStr = data.acctPriceTypeStr || '';
          this.quoteData = QUOTEITEMS;
          this.quoteInfo = {
            quoteId: INVOICEDETAILS.INVOICEID || '',
            description: INVOICEDETAILS.QDESCRIPTION || '',
            aAccountname: INVOICEDETAILS.AACCOUNTNAME || '',
          };
          this.pageData.controls.stripe.publicKey = data.StripePublicKey || '';
          this.pageData.cartInfo.total = 0;
          this.pageData.cartInfo.subTotal = 0;
          this.quoteData.map((item: any) => {
            this.pageData.cartInfo.total += (item.price || 0) * (item.quantity || 1);
            this.pageData.cartInfo.subTotal += (item.price || 0) * (item.quantity || 1);
          });
          //Handle stripe key:

          const comInstance = this;
          try {
            $("input#quoteName").val(data.QUOTENAME);
            const retData = data.INVOICEITEMS;
            //set some value for comInstance
            const quoteDetails = data.INVOICEDETAILS;
            comInstance.aID = quoteDetails.AID.toString();
            comInstance.purchaseID = data.PURCHASEID || 0;
            comInstance.purchaseStatus = data.PURCHASESTATUS || 0;
            comInstance.accountID = quoteDetails.AACCOUNTID;
            comInstance.customer = quoteDetails.AACCOUNTNAME;
            comInstance.reseller = quoteDetails.AACCOUNTNAME;
            comInstance.quoteID = quoteDetails.INVOICEID;
            comInstance.quoteStatus = quoteDetails.QOPEN;
            comInstance.quoteName = data.QUOTENAME;
            comInstance.orderName = data.QUOTENAME;
            comInstance.orderID = `PAY-${quoteDetails.INVOICEID}`;
            comInstance.sourceQuoteIds = quoteDetails.QQUOTEID || "";
            comInstance.selectedGlobalTags = (data.selectedGlobalTags || []).map((val: any) => ({
              CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
              CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
            }));
            comInstance.selectedGlobalTagsID = comInstance.selectedGlobalTags.map((val: any) => val.CUSTOMFIELDOPTIONID);
            comInstance.USECUSTOMTAX =
              data.USECUSTOMTAX != "undefined" &&
              data.USECUSTOMTAX == 1
                ? 1
                : 0;
            comInstance.source =
              typeof quoteDetails.VARSOURCE_NAME != "undefined"
                ? quoteDetails.VARSOURCE_NAME
                : "";
            comInstance.sourceID =
              typeof quoteDetails.SOURCE != "undefined" ? quoteDetails.SOURCE : 0;
            comInstance.estPercent = quoteDetails.ESTPERCENT
              ? quoteDetails.ESTPERCENT
              : 0;
            if (comInstance.source == 'HPE' || comInstance.source == 'HP Direct') {
              comInstance.financialSource.filter(function(finSource:any) {
                if (finSource.VARSOURCE_NAME == comInstance.source) {
                  comInstance.fundingSourceId = finSource.VARSOURCE_ID;
                  comInstance.fundingSourceName = finSource.VARSOURCE_NAME;
                }
              });
            }
            else if (quoteDetails.FUNDINGSOURCEID) {
              comInstance.financialSource.filter(function(finSource:any) {
                if (finSource.VARSOURCE_ID == quoteDetails.FUNDINGSOURCEID) {
                  comInstance.fundingSourceId = finSource.VARSOURCE_ID;
                  comInstance.fundingSourceName = finSource.VARSOURCE_NAME;
                }
              });
            }
            if(quoteDetails.GLOBALMARGIN != "undefined" && quoteDetails.GLOBALMARGIN != 0) {
              comInstance.globalMargin = quoteDetails.GLOBALMARGIN;
              comInstance.globalMarginBK = quoteDetails.GLOBALMARGIN;
              comInstance.confirmedGlobalMargin = true;
            }

            let profitTotal = quoteDetails.PROFIT;
            if (comInstance.estPercent > 0) {
              profitTotal =
                (quoteDetails.CUSTOMERTOTALPRICE * comInstance.estPercent) / 100;
            }
            comInstance.hpDirectTaxRate = (typeof quoteDetails.HPDIRECTTAXRATE != "undefined" ? quoteDetails.HPDIRECTTAXRATE : 0);
            comInstance.indirectTaxRate = (typeof quoteDetails.INDIRECTTAXRATE != "undefined" ? quoteDetails.INDIRECTTAXRATE : 0);
            comInstance.currentTaxRate = comInstance.getTaxRateBySource();
            comInstance.validSynnexCredential = data.validSynnexCredential || false;
            comInstance.validTechdataCredential = data.validTechdataCredential || false;
            comInstance.sourceCredentials = data.sourceCredentials || [];

            // override current tax rate if user used an override value before
            if(typeof quoteDetails.QTAXRATE != "undefined" && quoteDetails.QTAXRATE !== "") {
              comInstance.currentTaxRate = quoteDetails.QTAXRATE;
            }

            // Set expandDecimal and expandDecimalPrecision
            const quoteParams = JSON.parse(quoteDetails.QUOTEPARAMS || "{}");
            comInstance.quoteParams = quoteParams;
            const endUser = quoteParams.endUser || {};
            this.ssInfo.SSId = quoteParams.SSId || "";
            this.ssInfo.SSUrl = quoteParams.SSUrl || "";
            this.ssInfo.SSName = quoteParams.SSName || "";

            // specify uncaptured stripe
            // comInstance.stripeToken = quoteParams.stripeToken || "";
            comInstance.paymentCaptured = data.captured || 0;
            const paymentConfirmID = data.PaymentConfirmID || "";
            if(paymentConfirmID && paymentConfirmID != "0" && !comInstance.paymentCaptured) {
              // if has confirmed id and uncapture this amount
              comInstance.stripeToken = paymentConfirmID;
              comInstance.confirmedPayment = true;
              comInstance.stripeCustomerId = data.stripeCustomerId || "";
              comInstance.stripeCardId = data.stripeCardId || "";
            }

            // end user info
            comInstance.euName = endUser.Name || "";
            comInstance.euEmail = endUser.SubmitterEmail || "";

            if (quoteParams.expandDecimal) {
              comInstance.expandDecimal = quoteParams.expandDecimal
              comInstance.expandDecimalPrecision = 5;
            }

            //Fill customer department
            if (typeof quoteParams.departmentDetails != "undefined" && quoteParams.departmentDetails != "") {
              comInstance.selectedDepartment = [parseInt(quoteParams.departmentDetails.ID)];
              comInstance.selectedDepartmentName = quoteParams.departmentDetails.NAME;
            }

            //Get customer department details
            if(comInstance.aID > 0) {
              comInstance.getCustomerDepartment(comInstance.aID);
            }

            //Set Contract Number
            if((data.selectedContractData || []).length) {
              comInstance.selectedOrderContracts = data.selectedContractData[0].CUSTOMFIELDOPTIONNAME;
              comInstance.orderContractsID = data.selectedContractData.map(
                (val: any) => val.CUSTOMFIELDOPTIONID
              );
            }


            comInstance.getCustomData(comInstance.quoteID).then(() => {
              // nothing
            });

            comInstance.includedItem = quoteParams.includedItem || false;
            comInstance.includedPrice = typeof quoteParams.includedPrice != "undefined" ? quoteParams.includedPrice : true;
            comInstance.selectedBusinessLine = quoteDetails.BUSINESSLINEID ? [quoteDetails.BUSINESSLINEID] : [];

            //set summary
            comInstance.summary = {
              grandTotal: quoteDetails.GRANDTOTAL || 0,
              itemTotal: quoteDetails.CUSTOMERTOTALPRICE || 0,
              itemTotalFormatted: quoteDetails.ITOTALPRICEFORMATTED || '$0.00',
              profit: profitTotal,
              estPercent: comInstance.estPercent || 0,
              totalCost: quoteDetails.ITOTALPRICE || 0,
              qShip: quoteDetails.ISHIPPING || 0,
              qTax: quoteDetails.CUSTOMERTAX || 0,
              totalShipping: quoteDetails.ISHIPPING || 0,
              includeVarCost: true,
              totalTax: quoteDetails.CUSTOMERTAX || 0,
            };

            if ($("#orderID").val() == "") {
              comInstance.quoteName =
                comInstance.quoteID + " - " + data.QUOTENAME;
            }

            const items: {
              sku: string;
              name: string;
              price: number;
              included: number;
              ISCONFIG?: number;
              margin: number;
              marginRate: number;
              total: number;
              taxRate: number;
              tax: number;
              shipping: number;
              priceWarn: boolean;
              noTax: boolean;
              customerPrice: number;
              quantity: number;
              ItemCategory: number;
              ItemCategoryName: string;
              isFee: boolean;
              dynamicInfo: any;
              statusid: number;
              contractPrice: any;
              contractPriceDate: Date | undefined;
              orgPrice: number;
              orgCustomerPrice: number;
              disable: boolean;
              isContractFee: boolean;
              includedparent: number;
              baseProductId: number;
              includeItemCount: number;
              quoteLiId?: number;
              quoteParentLiId?: number;
              CONFIGCOUNT?: number;
              savedAsConfig?: number;
              quoteId?: number | string;
              subQuoteId?: number;
            }[] = [];

            let baseQty = 0;
            $.each(retData, function(i, val) {
              let quantity = (val.IINCLUDED ? val.IPRODQUANTITY_BK : (typeof val.IPRODQUANTITY != "number" ? 0 : val.IPRODQUANTITY));
              if(!(val.IINCLUDED || 0)) {
                baseQty = quantity;
              }
              if(val.ISCONFIG || 0) {
                // force config item to follow the base's quantity
                quantity = baseQty;
              }
              items.push({
                sku: val.IPRODSKU,
                name: val.IPRODDESC.replace(/[$|,]/g, ""),
                price: (val.QINCLUDED ? val.QPRICEREG_BK : val.QPRICEREG),
                included: typeof val.QINCLUDED != "undefined" && val.QINCLUDED ? 1 : 0,
                ISCONFIG: val.ISCONFIG || 0,
                customerPrice: (val.QINCLUDED ? val.QCUSTOMERPRICE_BK : val.QCUSTOMERPRICE),
                margin: (val.QINCLUDED ? val.QMARGIN_BK : val.QMARGIN),
                marginRate: comInstance.editGetMarginRate(val, "quote"),
                total:
                  typeof val.CUSTOMERTOTALPRICE != "undefined"
                    ? val.CUSTOMERTOTALPRICE
                    : val.IPRICEACT || 0,
                quantity,
                taxRate:
                  typeof val.QTAXRATE != "undefined" && val.QTAXRATE != ""
                    ? val.QTAXRATE
                    : 0,
                tax: (val.QINCLUDED ? val.QTAX_BK : comInstance.getLineItemTax(val.CUSTOMERTOTALPRICE, val.QTAXRATE)),
                shipping:
                  typeof val.QSHIPPING != "undefined" && val.QSHIPPING != ""
                    ? val.QSHIPPING
                    : 0,
                priceWarn: false,
                noTax: typeof val.NOTAX != "undefined" && val.NOTAX ? true : false,
                ItemCategory: typeof val.QCATEGORY != "undefined" ? val.QCATEGORY : 0,
                ItemCategoryName: typeof val.CATEGORYNAME != "undefined" ? val.CATEGORYNAME : "",
                isFee: comInstance.checkItemIsFee({
                  POPRODSKU: val.IPRODSKU,
                  POCUSTOMERPRICE: val.QCUSTOMERPRICE_BK,
                  POINCLUDED: val.QINCLUDED
                }),
                dynamicInfo: val,
                statusid: typeof val.STATUSID != "undefined" && val.STATUSID ? val.STATUSID : 0,
                contractPrice: typeof val["CONTRACTPRICE"] != "undefined" ? val["CONTRACTPRICE"] : undefined,
                contractPriceDate: typeof val["CONTRACTPRICEDATE"] != "undefined" ? val["CONTRACTPRICEDATE"] : undefined,
                orgPrice: val.IINCLUDED ? val.IPRICEREG_BK : typeof val.ORGQPRICEREG != "undefined" ? val.ORGQPRICEREG : val.IPRICEREG || 0,
                orgCustomerPrice: val.IINCLUDED ? val.QCUSTOMERPRICE_BK : typeof val.ORGQCUSTOMERPRICE != "undefined" ? val.ORGQCUSTOMERPRICE : val.IPRICEREG || 0,
                // disable: (typeof val.PARENTDISABLE != "undefined" && val.PARENTDISABLE) || (val.QINCLUDED && comInstance.includedItem && !comInstance.includedPrice) ? true : false,
                disable: false,
                isContractFee: typeof val.ISCONTRACTFEE != "undefined" && val.ISCONTRACTFEE ? true : false,
                includedparent : typeof val.INCLUDEDPARENT != "undefined" ? val.INCLUDEDPARENT : 0,
                baseProductId : typeof val.QUOTELIID != "undefined" ? val.QUOTELIID : 0,
                includeItemCount : typeof val.INCLUDEITEMCOUNT != "undefined" ? val.INCLUDEITEMCOUNT : 0,
                quoteLiId: val.QUOTELIID || 0,
                quoteParentLiId: val.PARENTLIID || 0,
                CONFIGCOUNT: val.CONFIGCOUNT || 0,
                savedAsConfig: val.ISCONFIG || 0,
                quoteId: comInstance.quoteID,
                subQuoteId: val.SUBQUOTEID || 0
              });
            });

            /*
            if(comInstance.isDynamic()) {
              orderFormLineItemList.autoName = 1;
              orderFormLineItemList.autoCost = 1;
              $(".switch.auto input[type=checkbox].autoName, .switch.auto input[type=checkbox].autoCost").prop("checked", true);
              comInstance.DistyTotal = (typeof data.DistyTotal != "undefined" ? data.DistyTotal : {});
            }

            orderFormLineItemList.setRows(items, data);
            orderFormLineItemList.$emit("summaryChange", comInstance.summary);

            if(comInstance.aID > 0) {
              // eslint-disable-next-line require-atomic-updates
              await comInstance.getCustomerAddresses(comInstance.aID, true);
              await comInstance.updateAddressForCustomer(comInstance.aID);
            }
            */


            this.items = items;

            document.title = `Invoice #${quoteDetails.INVOICEID} - ${quoteDetails.GRANDTOTALFORMATTED || '$0.00'}`;

            this.$nextTick(() => {
              const quoteDetails = document.getElementById('quoteDetailsContainer');
              if (quoteDetails && (quoteDetails.clientHeight + 85) > document.documentElement.clientHeight) {
                $(".totalContainer, .table-container").addClass("fixed");
                $(".paymentForm").addClass("position-fixed");
              }
              else {
                $(".totalContainer, .table-container").removeClass("fixed");
                $(".paymentForm").removeClass("position-fixed");
              }

              // Update the position when the window is resized or the component is mounted
              window.addEventListener('resize', this.updateFixedDivPosition);
              this.updateFixedDivPosition();
            });
            // if(comInstance.aID > 0) {
            //   await comInstance.getCustomerAddresses(comInstance.aID);
            // }

            console.log('Start load stripe key:', this.pageData.controls.stripe.publicKey);
            if (this.pageData.controls.stripe.publicKey != "undefined" && this.pageData.controls.stripe.publicKey != "") {
              const stripePKey = this.pageData.controls.stripe.publicKey;
              await $.getScript("https://js.stripe.com/v2/", function (
                data,
                textStatus,
                jqxhr
              ) {
                //Loaded
                let stripe = Stripe.setPublishableKey(stripePKey);
              }).fail(function (jqxhr, settings, exception) {
                // console.log("stripe load failes");
              });
              console.log('Load customer stripe key!');
            }
          } catch (err) {
            console.log('err', err.message);
          }
          this.pageData.isLoading = false;
        })
        .catch(error => {
          this.pageData.hasError = true;
          this.pageData.isLoading = false;
          notifier.alert('Network response was not ok');
        });

      // Handle successful response
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  checkCardType() {
    const value = this.pageData.controls.cardNumber.value.replace(/\s+/g, "");
    const cardType = this.detectCardType(value);
    this.pageData.controls.cardNumber.type = cardType;
  }

  ccFormat() {
    const val = this.pageData.controls.cardNumber.value.replace(/\D/g, "");
    let formatted = "";
    for (let i = 0; i < val.length; i++) {
      if (i % 4 == 0 && i > 0) {
        formatted += " ";
      }
      formatted += val[i];
    }
    this.pageData.controls.cardNumber.value = formatted;
  }

  detectCardType(value: any) {
    if (/^4/.test(value)) {
      return "Visa";
    } else if (/^5[1-5]/.test(value)) {
      return "Mastercard";
    } else if (/^3[47]/.test(value)) {
      return "Amex";
    } else if (/^6(?:011|5[0-9]{2})/.test(value)) {
      return "Discover";
    } else {
      return "";
    }
  }

  async getStates(type: any, country: any) {
    fetch('https://countriesnow.space/api/v0.1/countries/states', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ country })
    })
      .then(response => response.json())
      .then(data => {
        this[`${type}_states`] = data.data.states;
        if(this.pageData.isLoading){
          this.pageData.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error);
      });

  }

  onCheckboxChange() {
    if (this.pageData.controls.shippingAddress.sameAsBilling) {
      this.pageData.controls.shippingAddress = {
        ...this.pageData.controls.shippingAddress,
        address: this.pageData.controls.billingAddress.address,
        address2: this.pageData.controls.billingAddress.address2,
        city: this.pageData.controls.billingAddress.city,
        state: this.pageData.controls.billingAddress.state,
        zipcode: this.pageData.controls.billingAddress.zipcode,
      };
      $("#shipping-address-container").slideUp();
    } else {
      $("#shipping-address-container").slideDown();
      this.pageData.controls.shippingAddress = {
        ...this.pageData.controls.shippingAddress,
        address: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      };
    }
  }

  async updatePayment(purchaseId) {
    const self = this;
    self.pageData.isLoading = true;
    Stripe.card.createToken($("#posttodetailpop"), (status, response) => {
      //Stripe token failure...
      if (response.error) {
        self.pageData.isLoading = false;
        let msg = "Card Name";
        const errorParam = response.error.param;
        const errorMessage = response.error.message || '';
        if (errorMessage) {
          notifier.alert(errorMessage);
        } else {
          switch (errorParam) {
            case "number":
              self.pageData.controls.cardNumber.error = 'Card Number is invalid';
              msg = "number";
              break;
            case "exp_month":
              self.pageData.controls.cardExpire.errorMonth = 'Exp Month is invalid';
              msg = "Exp Month";
              break;
            case "exp_year":
              self.pageData.controls.cardExpire.errorYear = 'Exp Year is invalid';
              msg = "Exp Year";
              break;
            case "cvc":
              self.pageData.controls.cvc.error = 'CVC is invalid';
              msg = "CVC";
              break;
            default:
              self.pageData.controls.cardName.error = 'Card Name is invalid';
              break;
          }
          notifier.alert("verification failed due to invalid " + msg);
        }
        self.pageData.verifiedBtnEnabled = false;
      } else {
        self.pageData.controls.stripe.id = response.id;
        self.pageData.controls.stripe.last4 = response.card.last4;
        self.pageData.controls.stripe.error = '';
        const saveRecord = getRouteData({
          controller: "Pay",
          FunctionName: "invoiceUpdatePayment",
          token: self.pageData.controls.stripe.id,
          last4: self.pageData.controls.stripe.last4,
          aID: self.filters.aID,
          Account: self.filters.aID,
          cardName: self.pageData.controls.cardName.value,
          purchaseID: purchaseId,
          isUpdate: true,
          isNew: 0,
          InvoiceID: this.quoteID || 0,
          orderPaymentTotal: self.summary.grandTotal,
          paymentType: "Stripe",
          userRole: "Reseller",
          euEmail: this.pageData.controls.email.value,
          billingAddress : {
            ...this.pageData.controls.billingAddress,
          },
          shippingAddress: {
            ...this.pageData.controls.shippingAddress
          }
        });
        saveRecord.then(async function(response, statusText, jqXHR) {
          /* if (response.data.ERROR) {
            notifier.alert(response.data.ERROR);
            return false;
          } */
          const resData = response.data || {};
          const resStatus = resData.STATUS || 0;
          const PaymentStatus = resData.PaymentStatus || 0;
          if (resStatus || PaymentStatus) {
            /* notifier.success(`Paid`); */
            const ORDERDETAILS = response.data.ORDERDETAILS || {};
            self.orderNumber = ORDERDETAILS.PURCHASEID || 0;
            self.orderTotal = ORDERDETAILS.TOTAL || 0;
            self.pageData.isLoading = false;
            self.paymentComplete();
          } else {
            self.pageData.isLoading = false;
            notifier.alert(response.data.STATUSMESSAGE || 'Something was wrong');
            self.purchaseID = 0;
          }
        }).catch((err) => {
          self.pageData.isLoading = false;
          notifier.alert('Something was wrong');
        });
      }
    });

  }

  paymentComplete() {
    $("form").animate({
      height: "0px",
      opacity: 0
    }, 5000, () => {
      $(this).hide();
    });

    $("#paymentComplete").show().animate({
      height: "100%",
      opacity: 1
    }, 5000);

    $(".quoteID, .payTo span").animate({
      opacity: 0
    }, 2500);
  }

  async onSubmit() {
    await this.updatePayment(this.purchaseID);
  }

  itemName = {};
  itemNames = this.itemName;
  sourceID = 0;
  purchaseID = 0;
  purchaseStatus = 0;
  sourceName = "";
  contactEmail = "";
  updateNotes = "";
  quoteIds = [];
  expandDecimal = false;
  onetimeAddress: any = {};
  onetimeAddressBilling: any = {};
  generateForUnverify = false;
  summary = {
    itemTotal: 0,
    itemTotalFormatted: '',
    totalTax: 0,
    qTax: 0,
    totalShipping: 0,
    includeVarCost: true,
    totalCost: 0,
    qShip: 0,
    profit: 0,
    estPercent: 0,
    grandTotal: 0
  };
  currentTaxRate = 0;
  grandTotal = 0;
  USECUSTOMTAX = 0;
  selectedBusinessLine: number[] = [];
  fundingSourceId = 0;
  selectedDepartment: number[] = [];
  selectedDepartmentName = "";
  onetimeDepartment: any = {};
  includedItem = false;
  includedPrice = true;
  details = {};
  quoteID: string = "";
  quoteStatus = 0;
  quoteName = "";
  orderID = "";
  orderName = "";
  processWithDisty = false;
  selectedAddress: number[] = [];
  selectedBillingAddress: number[] = [];
  orderContractNumber: object[] = [];
  fullCustomData: any = [];
  objDate = new Date(new Date().toLocaleString('en-US', {timeZone: 'America/Denver', hour12: false}));
  orderDate = [
    (this.objDate.getMonth()+1 < 10 ? "0" : "")+(this.objDate.getMonth() + 1),
    (this.objDate.getDate() < 10 ? "0" : "")+this.objDate.getDate(),
    this.objDate.getFullYear()
  ].join("/");
  quoteParams: any = {};
  ssInfo = {
    SSId: "",
    SSUrl: "",
    SSName: ""
  };
  paymentCaptured = 0;
  stripeToken = "";
  confirmedPayment = false;
  stripeCustomerId = "";
  stripeCardId = "";
  euID = 0;
  euName = "";
  euPhone = "";
  euPhoneText = "";
  euEmail = "";
  duplicateOrderCreate = false;
  unverifyOrder: any = {};
  confirmedUnverifyOrder = false;
  uploadedFileName = '';
  selectedEndUsers: number[] = [];

  isIndirect() {
    if(this.sourceID == 1 || this.sourceID == 12) {
      return false;
    }

    return true;
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  createOrder(value, inEditPage) {
    this.pageData.isPaying = true;
    if (!inEditPage) {
      inEditPage = false;
    }
    const saveData = {};
    saveData["Controller"] = "Pay";
    saveData["FunctionName"] = "createOrder";
    saveData["inEditPage"] = inEditPage;
    saveData["plines"] = value.items.length;
    saveData["orderID"] = value.orderID; // "MSPP" + Math.floor(Math.random() * 100000000).toString().substring(0, 8);
    saveData["aID"] = this.aID;
    saveData["Account"] = this.filters.aID;
    saveData["accountID"] = value.accountID;
    saveData["orderDate"] = value.orderDate;
    saveData["orderName"] = value.orderName;
    saveData["totalPrice"] = "$ " + value.grandTotal;
    saveData["orderTotal"] = 0;
    saveData["qTax"] = parseFloat(value.summary.totalTax).toFixed(2);
    saveData["qShip"] = parseFloat(value.summary.totalShipping).toFixed(2);
    saveData["source"] = value.sourceID;
    saveData["EstPercent"] = this.isIndirect() ? 0 : value.summary.estPercent;
    saveData["includeVarCost"] = value.summary.includeVarCost;
    saveData["purchaseID"] = value.purchaseID;
    saveData["contactEmail"] = value.contactEmail.length ? value.contactEmail.join() : "";
    // end user info
    saveData["endUserId"] = value.selectedEndUsers.length ? value.selectedEndUsers.join() : "";
    saveData["euName"] = value.euName || "";
    saveData["euPhone"] = value.euPhone || "";
    saveData["euPhoneText"] = value.euPhoneText || "";
    saveData["euEmail"] = value.pageData.controls.email.value; // value.euEmail || "";
    saveData["euID"] = value.euID || 0;
    saveData["isDisty"] = value.processWithDisty;
    saveData["customSourceName"] = value.sourceName;
    saveData["orderUpdateNotes"] = this.updateNotes;
    saveData["expandDecimal"] = this.expandDecimal;
    saveData["orderQuotes"] = value.quoteIds;
    saveData["DefaultShippingID"] = (value.selectedAddress.length ? value.selectedAddress.join() : 0);
    saveData["DefaultBillingID"] = (value.selectedBillingAddress.length ? value.selectedBillingAddress.join() : 0);
    saveData["FileName"] = this.uploadedFileName;
    if(value.selectedAddress.length && saveData["DefaultShippingID"] == 0) {
      // if use a one time address for this order
      saveData["onetimeAddress"] = this.onetimeAddress;
    }
    if (value.selectedBillingAddress.length && saveData["DefaultBillingID"] == 0) {
      // if use a one time address for this order
      saveData["onetimeAddressBilling"] = this.onetimeAddressBilling;
    }

    if (inEditPage) {
      saveData["customerName"] = value.customer;
      saveData["sourceName"] = value.source;
    }

    if (
      typeof value.confirmedDuplicatePO != "undefined" &&
      value.confirmedDuplicatePO == true
    ) {
      saveData["confirmedDuplicatePO"] = true;
    }

    if (typeof value.quoteID != "undefined") {
      saveData["orderQuote"] = value.quoteID;
      if(!inEditPage) {
        // create new from ss quote
        if((this.ssInfo.SSId || "") != "") {
          saveData["SSId"] = this.ssInfo.SSId;
        }
        if((this.ssInfo.SSUrl || "") != "") {
          saveData["SSUrl"] = this.ssInfo.SSUrl;
        }
        if((this.ssInfo.SSName || "") != "") {
          saveData["SSName"] = this.ssInfo.SSName;
        }
        saveData["confirmedPayment"] = this.confirmedPayment || false;
      }
    }
    // uncapture payment
    if(this.stripeToken && !this.paymentCaptured) {
      saveData["stripeToken"] = this.stripeToken || "";
      saveData["stripeCustomerId"] = this.stripeCustomerId || "";
      saveData["stripeCardId"] = this.stripeCardId || "";
    }

    if(this.generateForUnverify) {
      saveData["generateForUnverify"] = this.generateForUnverify;
      saveData["unverifyTotal"] = this.summary.grandTotal;
    }
    // saveData["globalTag"] = value.globalTag;
    // saveData["customID"] = value.customID;
    saveData["selectedGlobalTagsID"] = value.selectedGlobalTagsID.join(",");
    saveData["globalMargin"] = this.isIndirect() ? value.globalMargin : 0; //(value.confirmedGlobalMargin ? value.globalMargin : 0);

    saveData["selectedContractNumberValue"] = value.selectedOrderContracts;
    saveData["contractNumberFieldId"] = value.orderContractNumber.length > 0 ? value.orderContractNumber[0].CUSTOMFIELDID : 0;

    //check/remove HTML for custom field values
    for(const val of value.fullCustomData) {
      val.CUSTOMVALUE = this.removeHTML(val.CUSTOMVALUE);
    }
    saveData["customData"] = JSON.stringify(value.fullCustomData);
    saveData["taxRate"] = this.currentTaxRate;
    saveData["usedCustomTax"] = !!this.USECUSTOMTAX;
    // business line
    saveData["businessLineId"] = this.selectedBusinessLine[0] || 0;
    // confirmed linking with an unverify order
    saveData["confirmedUnverifyOrder"] = !inEditPage ? this.confirmedUnverifyOrder : false;
    saveData["unverifyOrder"] = JSON.stringify(this.unverifyOrder);

    let totalFee = 0;
    let totalContractFee = 0;
    let itemOrder = 0;
    const sourceIDs: any = [];
    $.each(value.items, function(i, val) {
      const ind = i + 1;
      saveData["ItemDescription" + ind] = val.name;
      saveData["ItemNumber" + ind] =
        typeof val.sku != "undefined" ? val.sku : "";
      saveData["ItemQuantity" + ind] =
        val.quantity > 0 ? val.quantity.toString() : "";
      saveData["RegPrice" + ind] =
        val.price != 0
          ? val.price.toFixed(value.expandDecimalPrecision).toString()
          : "";
      saveData["ItemTotal" + ind] = val.total > 0 ? val.total.toString() : "";
      saveData["ItemCustomerPrice" + ind] =
        val.customerPrice != 0
          ? val.customerPrice.toFixed(value.expandDecimalPrecision).toString()
          : "";
      saveData["ItemMargin" + ind] = val.margin.toString();
      saveData["ItemMarginRate" + ind] =
        typeof val.marginRate != "undefined" ? val.marginRate : 0;
      saveData["ItemShipping" + ind] =
        val.shipping > 0 ? val.shipping.toString() : "";
      saveData["ItemTaxRate" + ind] =
        val.taxRate > 0 ? val.taxRate.toString() : "";
      saveData["ItemTax" + ind] = val.tax > 0 ? val.tax.toString() : "";
      saveData["ItemNoTax" + ind] = val.noTax ? 1 : 0;
      saveData["ItemIncluded" + ind] = val.included ? 1 : 0;
      saveData["statusid" + ind] =
        typeof val.statusid != "undefined" ? val.statusid : "0";

      if (inEditPage) {
        saveData["poLiID" + ind] =
          typeof val.poLiID != "undefined" ? val.poLiID : 0;
      }
      saveData["ItemCategory" + ind] =
        typeof val.ItemCategory != "undefined" ? val.ItemCategory : 0;
      saveData[`ItemQuoteLiId${ind}`] = val.quoteLiId || 0;
      saveData[`ItemQuoteParentLiId${ind}`] = val.quoteParentLiId || 0;
      saveData[`sourceID${ind}`] = val.dynamicInfo
        ? val.dynamicInfo.selectedDistyID || 0
        : 0;
      saveData[`ItemQuoteId${ind}`] = val.quoteId || "";
      saveData[`ItemSubQuoteId${ind}`] = val.subQuoteId || val.subOrderId || 0;
      saveData[`ItemIsConfig${ind}`] = val.ISCONFIG || 0;
      saveData[`ItemSubOrderId${ind}`] = val.subOrderId || 0;

      // specify item order
      itemOrder++;
      saveData[`ItemOrder${ind}`] = itemOrder;

      if (saveData[`sourceID${ind}`] > 0) {
        sourceIDs.push(saveData[`sourceID${ind}`]);
      }

      // calculate total fee
      if (val.isFee || false) {
        totalFee += val.price;
      }

      // calculate total contract fee
      if (val.isContractFee || false) {
        totalContractFee += val.price;
      }
    });

    saveData["totalFee"] = totalFee;
    saveData["totalContractFee"] = totalContractFee;

    // auto update source to a distributor in case dynamic
    if(saveData["source"] == 10 && sourceIDs.length) {
      const usedMultiSource = sourceIDs.find((s: any) => s != sourceIDs[0]);
      if(!usedMultiSource) {
        saveData["source"] = sourceIDs[0];
      }
    }
    saveData["fundingSource"] = this.fundingSourceId;
    // Department Info
    const departmentDetails = {
      ID: this.selectedDepartment.length ? this.selectedDepartment.join() : 0,
      NAME: this.selectedDepartmentName
    }
    saveData["departmentDetails"] = departmentDetails;
    let DefaultDeptID = (this.selectedDepartment.length ? this.selectedDepartment.join() : 0);
    if (DefaultDeptID == 0) {
      // if use a one time dept for this order
      saveData["onetimeDepartment"] = this.onetimeDepartment;
    }
    saveData["includedItem"] = this.includedItem;
    saveData["includedPrice"] = this.includedPrice;

    // case multi end users
    saveData["quoteEndUsers"] = this.quoteEndUsers || [];
    saveData["dataFields"] = this.dataFields || [];
    saveData["generalDataFields"] = this.generalDataFields || [];

    // add data when duplicate order has multi end users
    // should same as convert from multi quotes (so using "subOrderId" as a "subQuoteId")
    if( /*this.$route.name == "DuplicateOrder" && */ this.isMultiEndUsers) {
      const quoteEndUsers: any = [];
      let dataFields: any = [];
      let generalDataFields: any = [];
      for(const item of this.splitBySubQuoteId) {
        const subQuoteId = item.subQuoteId;
        quoteEndUsers.push({
          EUNAME: item.euInfo.EUNAME || "",
          // "REQUESTNAME": "Test Approver",
          EUSTATE: item.euInfo.EUSTATE || "",
          ADDR2: item.euInfo.ADDR2 || "",
          SUBMITTEREMAIL: item.euInfo.EMAIL || "",
          COUNTRY: item.euInfo.COUNTRY || "",
          ZIP: item.euInfo.ZIP || "",
          SUBQUOTEID: subQuoteId,
          LASTNAME: item.euInfo.LASTNAME || "",
          QUOTEID: this.quoteID,
          EUID: item.euInfo.EUID || 0,
          // APPROVERNAME: "Loy Castillo",
          // SUBMITTERNAME: "Erin Aldrich",
          FIRSTNAME: item.euInfo.FIRSTNAME || "",
          ADDR1: item.euInfo.ADDR1 || "",
          CITY: item.euInfo.CITY || "",
          PHONE: item.euInfo.PHONE || "",
          EMAIL: item.euInfo.EMAIL || "",
        });

        dataFields = [...dataFields, ...item.euCD.filter(t => t.isEUData == 1).map(t => ({
          quoteId: this.quoteID,
          subQuoteId,
          customFieldId: t.customFieldId,
          customValue: t.customValue
        }))];

        generalDataFields = [...generalDataFields, ...item.euCD.filter(t => t.isEUData == 0).map(t => ({
          quoteId: this.quoteID,
          subQuoteId,
          customFieldId: t.customFieldId,
          customValue: t.customValue
        }))];
      }

      saveData["quoteEndUsers"] = quoteEndUsers;
      saveData["dataFields"] = dataFields;
      saveData["generalDataFields"] = generalDataFields;
      saveData["duplicateOrderHasMultiEUs"] = this.duplicateOrderCreate;
    }
    // else if ((this.$route.query.quoteIds || '').length) {
    //   let dataFields: any = [];

    //   for (const item of this.dataFields) {
    //     try {
    //       const subQuoteId = item.SUBQUOTEID;
    //       const quoteId = item.QUOTEID;

    //       const filteredData = this.selectedSubOrder.euCD.filter(t => t.isEUData == 1 && t.customFieldId === item.CUSTOMFIELDID).map(t => ({
    //         quoteId,
    //         subQuoteId,
    //         customFieldId: t.customFieldId,
    //         customValue: t.customValue
    //       }));

    //       // Check if filteredData is empty
    //       if (filteredData.length === 0) {
    //         console.log('No data added for item with CUSTOMFIELDID:', item.CUSTOMFIELDID);
    //       } else {
    //         dataFields = [...dataFields, ...filteredData];
    //       }
    //     } catch (error) {
    //       console.error('Error in loop:', error);
    //     }
    //   }
    //   saveData["dataFields"] = dataFields;
    // }

    const self = this;
    const saveRecord = getRouteData(saveData);
    saveRecord.then(async function(response, statusText, jqXHR) {
      self.pageData.isPaying = false;
      /* if (response.data.ERROR) {
        notifier.alert(response.data.ERROR);
        return false;
      } */

      if (response.data.STATUS) {
        // self.items = response.data.ORDER_LINEITEMS || [];
        // self.details = response.data || {};
        self.purchaseID = response.data.PURCHASEID || "";
        // notifier.success(`Created order #${response.data.PURCHASEID}`);
        await self.updatePayment(self.purchaseID);

      } else {// handle errors
        if (
          typeof response.data.POexisted != "undefined" &&
          response.data.POexisted == true
        ) {
          notifier.alert('This PO is already in use');
        } else if (
          typeof response.data.invalidCard != "undefined" &&
          response.data.invalidCard == true
        ) {
          if(response.data.MESSAGE) {
            notifier.alert(response.data.MESSAGE);
          }
        } else if (!response.data.STATUS) {
          notifier.alert(response.data.STATUSMESSAGE);
        }
      }
    }).catch((err) => {
      self.pageData.isPaying = false;
      notifier.alert(err);
    });
  }

  getTotal() {
    const total = this.summary.grandTotal.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    return `$${total}`;
  }

  getSubTotal() {
    const subTotal = this.summary.itemTotalFormatted || '$0.00';
    return `${subTotal}`;
  }

  getTaxTotal() {
    const totalTax = this.summary.totalTax.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    return `$${totalTax}`;
  }

  getShippingTotal() {
    const total = this.summary.qShip.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    return `$${total}`;
  }

  async stripeTest() {
    let isValid = true;
    let $this = this;
    $this.pageData.verifiedBtnEnabled = false;
    /*
    $('#cc-container input').each(() => {
      const $thisInput = $(this);
      if ($thisInput[0].value == ''){
        $thisInput.addClass('error');
        isValid = false;
        $this.pageData.verifiedBtnEnabled = false;
      }
    });
    */
    if (this.pageData.controls.cardNumber.value.length < 6) {
      this.pageData.controls.cardNumber.error = 'Card Number is invalid'
      isValid = false;
    } else {
      this.pageData.controls.cardNumber.error = ''
    }

    if (!this.pageData.controls.cardExpire.month) {
      this.pageData.controls.cardExpire.errorMonth = 'Exp Month is invalid'
      isValid = false;
    } else {
      this.pageData.controls.cardExpire.errorMonth = ''
    }

    if (!this.pageData.controls.cardExpire.year) {
      this.pageData.controls.cardExpire.errorYear = 'Exp Year is invalid'
      isValid = false;
    } else {
      this.pageData.controls.cardExpire.errorYear = ''
    }

    if (this.pageData.controls.cvc.value.length < 3) {
      this.pageData.controls.cvc.error = 'CVC is invalid'
      isValid = false;
    } else {
      this.pageData.controls.cvc.error = ''
    }

    if (!isValid) {
      $this.pageData.verifiedBtnEnabled = false;
    }

    if(isValid) {
      if(this.pageData.paymentTotalAmount == false || this.pageData.verifiedBtnEnabled) {
        return;
      }
      const $form = $("#posttodetailpop");
      const $this = this;
      $this.pageData.isLoading = true;
      const stripeResponseHandler = function (status, response) {
        $this.pageData.isLoading = false;
        //Stripe token failure...
        if (response.error) {
          // console.log("response error",response)
          let msg = "Card Name";
          const errorParam = response.error.param;
          const errorMessage = response.error.message || '';
          if (errorMessage) {
            notifier.alert(errorMessage);
          } else {
            switch (errorParam) {
              case "number":
                $this.pageData.controls.cardNumber.error = 'Card Number is invalid';
                msg = "number";
                break;
              case "exp_month":
                $this.pageData.controls.cardExpire.errorMonth = 'Exp Month is invalid';
                msg = "Exp Month";
                break;
              case "exp_year":
                $this.pageData.controls.cardExpire.errorYear = 'Exp Year is invalid';
                msg = "Exp Year";
                break;
              case "cvc":
                $this.pageData.controls.cvc.error = 'CVC is invalid';
                msg = "CVC";
                break;
              default:
                $this.pageData.controls.cardName.error = 'Card Name is invalid';
                break;
            }
            notifier.alert("verification failed due to invalid " + msg);
          }
          $this.pageData.verifiedBtnEnabled = false;
        } else {
          /* notifier.success("Card details verified successfully.."); */
          $this.pageData.controls.stripe.id = response.id;
          $this.pageData.controls.stripe.last4 = response.card.last4;
          $this.pageData.controls.stripe.error = '';
          $this.pageData.verifiedBtnEnabled = true;
        }
      };
      if(this.pageData.controls.stripe.publicKey.length) {
        Stripe.card.createToken($form, stripeResponseHandler);
      }
      $this.pageData.isLoading = false;
    }
  }

  updateShippingInfo() {
    if (this.pageData.controls.shippingAddress.sameAsBilling) {
      this.pageData.controls.shippingAddress.address = this.pageData.controls.billingAddress.address;
      this.pageData.controls.shippingAddress.address2 = this.pageData.controls.billingAddress.address2;
      this.pageData.controls.shippingAddress.city = this.pageData.controls.billingAddress.city;
      this.pageData.controls.shippingAddress.state = this.pageData.controls.billingAddress.state;
      this.pageData.controls.shippingAddress.zipcode = this.pageData.controls.billingAddress.zipcode;
    }
  }

  updateFixedDivPosition() {
    const container = $(".left .container");
    const containerRect = container[0].getBoundingClientRect();

    $(".totalContainer.fixed").css('width', containerRect.width + 'px');
  }

  acctPriceTypeStr = '';
  splitBySubQuoteId: any = [{
    subId: 0
  }];

  generalDataFields: any = [];
  dataFields: any = [];
  quoteEndUsers: any = [];

  get isMultiEndUsers() {
    // check data of splitBySubQuoteId
    if (
      this.splitBySubQuoteId.length >= 1 &&
      this.splitBySubQuoteId[0].subOrderId > 0
    ) {
      return true;
    }

    return false;
  }

  getAddressText(address) {
    let ret = "";
    const addr1 = address.ADDR1 || "";
    const addr2 = address.ADDR2 || "";
    const city = address.CITY || "";
    const state = address.EUSTATE || "";
    const zip = address.ZIP || "";
    if(addr1 != "") {
      ret += " " + addr1;
    }
    if(addr2 != "") {
      ret += " " + addr2;
    }
    if(city != "") {
      ret += " " + city;
    }
    if(state != "" || zip != "") {
      ret += ",";
      if(state != "") {
        ret += " " + state;
      }
      if(zip != "") {
        ret += " " + zip;
      }
      ret += ", US ";
    }

    return ret;
  }

  async getCustomerDepartment(aID) {
    if(aID > 0) {
      /*
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Contacts",
          FunctionName: "UpdateDepartment",
          aID: aID,
          action: "list"
        });
        if(response.data.STATUS) {
          this.customerDepartment = response.data.DEPARTMENTDETAILS.map(tmp => ({
            ID: tmp.ACCOUNTDEPARTMENTID,
            TEXT: tmp.ADEPARTMENTNAME
          }));
        }
        this.customerDepartment.push({
          ID: "onetime",
          TEXT: "Add new department"
        });
        if (Object.keys(this.onetimeDepartment).length) {
          this.customerDepartment.unshift({
            ID: 0,
            TEXT: this.onetimeDepartment.Name
          });
        }
      } catch (error) {
        // console.log(error);
      }
      */
    }

  }
  getLineItemTax(total, taxRate) {
    return parseFloat((total * (taxRate / 100)).toFixed(2));
  }
}
