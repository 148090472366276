































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import customerdetails from "../pages/CustomerDetails.vue";

interface Props {
  title: string;
  selectedIDs: string[];
}

interface Events {
  onClose: void;
  onConfirm: void;
  updateCommission: {
    Id
  };
}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    customerdetails
  }
})
export default class ConfirmCopyAccountsModal extends TSXComponent<
  Props,
  Events
> {
  $parent:any;
  $root:any;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  accId!: string;

  saving = false;

  $refs!: {
    modal: HTMLDivElement;
  };

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    var objId =
      this.$route.params.id && parseInt(this.$route.params.id) > 0
        ? this.$route.params.id
        : sessionStorage.getItem("userId");
    for (
      var comIx = 0;
      comIx < this.$root.items[0].COMMISIONUSERS.length;
      comIx++
    ) {
      if (this.$root.items[0].COMMISIONUSERS[comIx].USERID == objId) {
        for (
          var updateCom = 0;
          updateCom < this.$parent.balanceCommissions.length;
          updateCom++
        ) {
          if (
            this.$parent.balanceCommissions[updateCom].ACCOUNTID ==
            this.$root.items[0].ACCOUNTID
          ) {
            this.$parent.balanceCommissions[
              updateCom
            ].ADDEDPERCENT = this.$root.items[0].COMMISIONUSERS[
              comIx
            ].PERCENTAGE;
            this.$parent.balanceCommissions[updateCom].REMAININGPERCENT = 0;
            break;
          }
        }
        break;
      }
    }
    $(this.$refs.modal).modal("hide");
  }

  confirm() {
    this.saving = true;
    this.$emit("close");
  }
}
