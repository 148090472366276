
import { Component as TSXComponent } from "vue-tsx-support"
import { Component, Prop, Vue } from 'vue-property-decorator';

interface Props {
  msg: string
}

@Component({
  inheritAttrs: false,
})
export default class HelloWorld extends TSXComponent<Props> {
  @Prop() private msg!: string;

  render() {
    return (
      <h3>{this.msg}</h3>
    )
  }
}
