var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.VARCONTACT : depth0)) != null ? stack1.SignatureAZ : stack1), depth0))
    + "\" alt=\"signature\" style=\"width: 180px;\"/> "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CURRENTDATE : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerContent : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <br/>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerContent : stack1), depth0))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <br />"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.FOOTERNOTES : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":79}}})) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.FOOTERNOTES : stack1), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"quoteDetails_notes\">\n  <tr><td>&nbsp;</td></tr>\n  <tr>\n    <td width=\"60%\"></td>\n    <td width=\"40%\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.VARCONTACT : depth0)) != null ? stack1.SignatureAZ : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":163}}})) != null ? stack1 : "")
    + "</td>\n  </tr>\n</table>\n<div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showParagarph),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":15,"column":9}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});