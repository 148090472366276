



























































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier } from "../models/common";

@Component({
  inheritAttrs: false
})
export default class passwordChange extends TSXComponent<void> {
  userRole = window.location.href.includes("var360customer")
    ? "Customer"
    : "Reseller";
  password = "";
  check = false;
  loading = true;
  confirmPassword = "";
  async created() {
    var self = this;
    self.loading = true;
    var dataObj = {
      controller: "Authentication",
      FunctionName: "passwordReset",
      userId: this.$route.params.id,
      token: this.$route.params.token,
      system :  window.location.href.includes("var360customer") ? "Customer" : "Reseller"
    };
    var resultCategoryTrend = getRouteData(dataObj);
    await resultCategoryTrend.then(function(response, statusText, jqXHR) {
      if (response.data.status == 1) {
        self.check = true;
      } else {
        self.check = false;
      }
      self.loading = false;
    });
  }

  async validateBeforeSubmit(e) {
    this.$validator.validateAll().then(result => {
      if ($("#Password").val() == "") {
        $(".errorMsg.password").text("The confirm password field is required");
        result = false;
      } else {
        $(".errorMsg.password").text("");
        result = true;
      }
      if ($("#confirmPassword").val() == "") {
        $(".errorMsg.confirmPassword").text(
          "The confirm password field is required"
        );
        result = false;
      } else {
        $(".errorMsg.confirmPassword").text("");
        result = true;
      }
      if ($(".passResetRequirements .validation.fail").length) {
        result = false;
      }
      if (result) {
        e.preventDefault();
        return this.submit();
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  validatePassword() {
    var pwd = $("#Password").val();
    var repwd = $("#confirmPassword").val();

    if ($.trim(pwd).length > 0) {
      if ($.trim(pwd) == $.trim(repwd)) {
        $(".validationMatch")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationMatch").hasClass("fail")) {
          $(".validationMatch")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).match(/[A-Z]/)) {
        $(".validationUppercase")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationUppercase").hasClass("fail")) {
          $(".validationUppercase")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).length >= 8) {
        $(".validationLength")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationLength").hasClass("fail")) {
          $(".validationLength")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).match(/\d/)) {
        $(".validationNumber")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationNumber").hasClass("fail")) {
          $(".validationNumber")
            .addClass("fail")
            .removeClass("success");
        }
      }
    } else {
      $(".passResetRequirements .validation")
        .removeClass("success")
        .addClass("fail");
    }
  }
  submit() {
    var self = this;
    var dataObj = {
      controller: "Authentication",
      FunctionName: "passwordReset",
      userId: this.$route.params.id,
      update: 1,
      password: this.password,
      token: this.$route.params.token,
      system :  window.location.href.includes("var360customer") ? "Customer" : "Reseller"
    };
    let resultCategoryTrend = getRouteData(dataObj);
    resultCategoryTrend.then(function(response, statusText, jqXHR) {
      if (response.data.msg) notifier.success(response.data.msg);
    });
    self.$router.push({ name: "Login" });
  }
}
