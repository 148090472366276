























import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import directives from "../helpers/directives";
import LaddaButton from "./LaddaButton.vue";
import Datepicker from "../components/Datepicker.vue";
import moment from "moment";

declare const $: any;
declare const dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    LaddaButton,
    Datepicker
  },
  directives
})
export default class DeploymentESTTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: {} })
  currentItem!: any;

  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  @Prop({ required: false, default: "statusType" })
  type?: string;

  @Prop({ required: false, default: 0 })
  groupId?: number;

  $parent: any;
  errors: any;
  estDate = "";

  async created() {
    this.estDate = this.currentItem.estFull || "";
    setTimeout(() => {
      $("#estDateInput").click();
      $("#datepicker").insertAfter("#estDateInput");
    }, 50);
  }

  selectedESTDay(selected) {
    if (this.type == "statusType") {
      this.$emit("selectedESTDay", {
        deploymentId: this.deploymentId,
        statusTypeId: this.currentItem.id,
        selected,
        type: this.type
      });
    } else if (this.type == "group") {
      this.$emit("selectedESTDay", {
        deploymentId: this.deploymentId,
        groupId: this.groupId,
        selected,
        type: this.type
      });
    }
  }

  beforeDestroy() {
    //
  }

  clickOutside() {
    this.$emit("close");
  }
}
