var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <table width=\"100%\" id=\"quoteDetails_info\">\n    <tr>\n      <td width=\"60%\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":129,"column":15}}})) != null ? stack1 : "")
    + "\n      </td>\n      <td width=\"40%\">\n        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderDate),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":10},"end":{"line":142,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":10},"end":{"line":167,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":10},"end":{"line":175,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ENDUSERID),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":10},"end":{"line":187,"column":17}}})) != null ? stack1 : "")
    + "\n          <tr>\n            <td class=\"title\">&nbsp;</td>\n          </tr>\n          <tr>\n            <td>&nbsp;</td>\n          </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPVIA),{"name":"if","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":10},"end":{"line":202,"column":17}}})) != null ? stack1 : "")
    + "        </table>\n      </td>\n    </tr>\n    <!-- \n    <tr>\n      <td>\n        Sales Tax Rate:<strong style=\"border: 1px solid black;padding-left: 63px;\"> "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POTAX), depth0)) != null ? stack1 : "")
    + "%</strong>\n      </td>\n    </tr> -->\n  </table>\n  <table width=\"100%\" id=\"quoteDetails_info\">\n    <tr style=\"color: #b1b1b1; height: 40px;\">\n      <td width=\"100%\" style=\"border-top: 5px solid #b1b1b1;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.INDIRECTQUOTEID),{"name":"if","hash":{},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":216,"column":8},"end":{"line":218,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":8},"end":{"line":222,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.DISTYACCTNO),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":223,"column":8},"end":{"line":225,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS),{"name":"if","hash":{},"fn":container.program(83, data, 0),"inverse":container.program(85, data, 0),"data":data,"loc":{"start":{"line":227,"column":8},"end":{"line":233,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.NASPOCONTRACT),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":235,"column":8},"end":{"line":237,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.DISTYNOTES),{"name":"if","hash":{},"fn":container.program(90, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":238,"column":8},"end":{"line":240,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n    </tr>\n  </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":129,"column":8}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":39,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.notes),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":69,"column":17}}})) != null ? stack1 : "")
    + "        </table>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <tr>\n              <td class=\"title\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + " Rep and Info:</strong></td>\n            </tr>\n            <tr>\n              <td><b>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":21},"end":{"line":13,"column":108}}})) != null ? stack1 : "")
    + "</b></td>\n            </tr>\n            <tr>\n              <td><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":26},"end":{"line":16,"column":115}}})) != null ? stack1 : "")
    + "</strong></td>\n            </tr>\n            <tr>\n              <td>\n                <strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR2),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":113}}})) != null ? stack1 : "")
    + "</strong>\n              </td>\n            </tr>\n            <tr>\n              <td>\n                <strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYCITY),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":111}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYSTATE),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":111},"end":{"line":25,"column":207}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYZIP),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":207},"end":{"line":25,"column":298}}})) != null ? stack1 : "")
    + "</strong>\n              </td>\n            </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":38,"column":19}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR1), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR2), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYCITY), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ",&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYSTATE), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYZIP), depth0));
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.notesContent),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.notes),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":68,"column":19}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr>\n                <td class=\"title\"><br /><br /><br /><br /><br />NOTES:</td>\n              </tr>\n              <tr>\n                <td>\n                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.notesContent), depth0))
    + "\n                </td>\n              </tr>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr>\n                <td class=\"title\"><br /><br /><br /><br /><br />NOTES:</td>\n              </tr>\n              <tr>\n                <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":56,"column":18},"end":{"line":60,"column":25}}})) != null ? stack1 : "")
    + "                </td>\n              </tr>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES), depth0))
    + "\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "                    Blind shipping required on all orders and shipments.\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.notes), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":10},"end":{"line":97,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.notes),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":10},"end":{"line":127,"column":17}}})) != null ? stack1 : "")
    + "        </table>\n        ";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <tr><td class=\"title\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + " Rep and Info:</strong></td></tr>\n            <tr><td><b>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.NAME),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":23},"end":{"line":74,"column":169}}})) != null ? stack1 : "")
    + "</b></td></tr>\n            <tr><td><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ATTRN),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":28},"end":{"line":75,"column":119}}})) != null ? stack1 : "")
    + "</strong></td></tr>\n            <tr>\n              <td><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":77,"column":26},"end":{"line":77,"column":209}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.PHONE),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":80,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.EMAIL),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":16},"end":{"line":83,"column":23}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":12},"end":{"line":90,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":12},"end":{"line":93,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":12},"end":{"line":96,"column":19}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.synnex),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":74,"column":65},"end":{"line":74,"column":162}}})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    return "TD SYNNEX Corporation ";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.NAME), depth0)) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ATTRN), depth0)) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS), depth0)) != null ? stack1 : "")
    + "</strong><br><strong>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS1), depth0)) != null ? stack1 : "")
    + "</strong>";
},"46":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.PHONE), depth0)) != null ? stack1 : "")
    + "\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.EMAIL), depth0)) != null ? stack1 : "")
    + "\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr>\n              <td class=\"title\">P.O. Date</td>\n            </tr>\n            <tr>\n              <td>\n                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":139,"column":16},"end":{"line":139,"column":142}}})) != null ? stack1 : "")
    + "\n              </td>\n            </tr>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED), depth0))
    + "</strong>";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <tr>\n              <td colspan=\"2\" class=\"title\"><strong>Ship To</strong></td>\n            </tr>\n            <tr>\n              <td><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.program(58, data, 0),"data":data,"loc":{"start":{"line":148,"column":26},"end":{"line":148,"column":146}}})) != null ? stack1 : "")
    + "</strong></td>\n            </tr>\n            <tr>\n              <td class=\"title\">Shipping Address</td>\n            </tr>\n            <tr>\n              <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.program(62, data, 0),"data":data,"loc":{"start":{"line":155,"column":16},"end":{"line":164,"column":23}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO), depth0));
},"58":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0));
},"60":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.program(65, data, 0),"data":data,"loc":{"start":{"line":158,"column":18},"end":{"line":163,"column":25}}})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":161,"column":20},"end":{"line":162,"column":107}}})) != null ? stack1 : "")
    + "\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<strong>"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS), depth0))
    + "<br />"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ACITY), depth0))
    + ",\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ASTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AZIP), depth0))
    + "</strong>";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr>\n              <td class=\"title\">Customer PO</td>\n            </tr>\n            <tr>\n              <td>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + "</td>\n            </tr>\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <tr>\n            <td class=\"title\">End User Information</td>\n          </tr>\n          <tr>\n            <td>\n              "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.EUNAME),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":14},"end":{"line":183,"column":106}}})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.EUPHONE),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":14},"end":{"line":184,"column":108}}})) != null ? stack1 : "")
    + "\n            </td>\n          </tr>\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.EUNAME), depth0))
    + "</strong><br />";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.EUPHONE), depth0))
    + "</strong><br />";
},"75":function(container,depth0,helpers,partials,data) {
    return "          <tr>\n            <td class=\"title\">&nbsp;</td>\n          </tr>\n          <tr>\n            <td>&nbsp;</td>\n          </tr>\n";
},"77":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        <strong> "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + " Quote ID:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.INDIRECTQUOTEID), depth0))
    + "<br/>\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <strong style=\"color: red;\">SHIPMENT NOTES: "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES), depth0)) != null ? stack1 : "")
    + "</strong><br/>\n";
},"81":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "          "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + " Account Number: "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.DISTYACCTNO), depth0))
    + "<br/>\n";
},"83":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <br>Contract Terms: "
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS), depth0))
    + "\n";
},"85":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS),{"name":"if","hash":{},"fn":container.program(86, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":230,"column":10},"end":{"line":232,"column":17}}})) != null ? stack1 : "");
},"86":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <br>Contract Terms: "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS), depth0))
    + "\n";
},"88":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <br>Contract Number: "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.NASPOCONTRACT), depth0))
    + "<br>\n";
},"90":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <br>Email Disty Notes: "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.DISTYNOTES), depth0))
    + "<br>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":244,"column":7}}})) != null ? stack1 : "");
},"useData":true});