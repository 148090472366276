var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":86,"column":14},"end":{"line":90,"column":21}}})) != null ? stack1 : "")
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":91,"column":14},"end":{"line":95,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":96,"column":14},"end":{"line":100,"column":21}}})) != null ? stack1 : "")
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":101,"column":14},"end":{"line":105,"column":21}}})) != null ? stack1 : "")
    + "              <br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.remitEmail),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":108,"column":14},"end":{"line":112,"column":21}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <strong>Milestone Computer Technology Inc.</strong>\n              ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress), depth0)) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                314 Wilcox St. Ste.\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState), depth0)) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                101 Castle Rock, CO 80104\n              ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo), depth0)) != null ? stack1 : "")
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEPHONECONTACT)) && stack1.QuoteContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":104,"column":16},"end":{"line":104,"column":175}}})) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEPHONECONTACT)) && stack1.QuoteContactPhoneValueText), depth0))
    + " ";
},"19":function(container,depth0,helpers,partials,data) {
    return " (303) 840-0606 (303)840-8202 ";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.remitEmail), depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTECONTACT)) && stack1.QuoteContactValue),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":111,"column":16},"end":{"line":111,"column":139}}})) != null ? stack1 : "")
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTECONTACT)) && stack1.QuoteContactValue), depth0))
    + " ";
},"26":function(container,depth0,helpers,partials,data) {
    return " om@milestonetech.com ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.LOGOSRC)) && stack1.length),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":6},"end":{"line":133,"column":13}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <td align=\"right\" colspan=\"2\" style=\"border-radius: 50%; padding-right: 30px;\"><img src="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.LOGOSRC), depth0))
    + " style=\"height: 75px;\"/></td>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showCustomerName),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":4},"end":{"line":136,"column":4}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <td align=\"right\" colspan=\"2\" style=\"border-radius: 50%; padding-right: 30px; font-size: 25px; font-weight: 600;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "</td>\n    ";
},"34":function(container,depth0,helpers,partials,data) {
    return "      <td align=\"right\" colspan=\"2\"><span style=\"padding: 10px; background-color: #cdcdcd; text-align:center; color: white;\">Customer Indicator</span></td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n\n<table width=\"100%\" id=\"quoteDetails_header\" style=\"padding: 0;\">\n  <tr>\n    <td style=\"padding: 0; line-height: 20px; letter-spacing: 1.7px; padding-right: 5px;\" valign=\"middle\">\n      <table>\n        <tr>\n          <td style=\"color:#bbb; padding-top: 0; padding-bottom: 0; padding-left: 15px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showRemitAddress),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":113,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n      </table>\n    </td>\n    <td valign=\"middle\" align=\"right\">\n      <table style=\"margin-right: 25px;\">\n        <tr>\n          <td><img src=\"https://smartstoresgeneric.s3.amazonaws.com/mail/milestonetechlogo.png\" style=\"display: block; border: 0; float: right; height: 27px;\" border=\"0\"></td>\n        </tr>\n        <tr>\n          <td style=\"color: #AEADB1;font-size: 10px;font-weight: 500;letter-spacing: 0.28px;text-align: right;\">MILESTONETECH.COM</td>\n        </tr>\n      </table>\n    </td>\n  </tr>\n  <tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showLogo),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":130,"column":4},"end":{"line":136,"column":11}}})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showTempIndicator),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":4},"end":{"line":141,"column":11}}})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td>&nbsp;</td>\n    <td style=\"padding-top: 22px;\"></td>\n  </tr>\n</table>\n";
},"useData":true});