
























































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from "axios";
import {notifier} from '../models/common'
import LaddaButton from "../components/LaddaButton.vue"
import Loader from './Loader.vue';

interface Props {
  title: string
  acctCommission: object[];
  selectedSGID: number[];
  selectedTagID: number[];
}

interface Events {
  onClose: void
}

declare const $: any
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Loader
  }
})
export default class remainingCommissionModal extends TSXComponent<
  Props,
  Events
> {

  @Prop({required: true})
  title!: string

  @Prop({required: true})
  acctCommission!: []

  @Prop({required: true})
  selectedSGID!: []

  @Prop({required: true})
  selectedTagID!: []

  @Prop({required: true})
  aid!: number

  @Prop({required: false, default: 0})
  businessLineSwitch!: number;
  
  $parent: any;
  saving: any = false;
  loading = false;
  accounts: object[] = [];

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async created() {
    this.loading = true;
    try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "Accounts",
        FunctionName			: "ListExt",
        queryType         : "accts_with_tag_sg",
        currentAID        : this.aid,
        selectedTagID     : this.selectedTagID,
        selectedSGID      : this.selectedSGID
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
      }
      
      if(response.data.STATUS == 1) {
        this.accounts = response.data.ACCOUNTS;
      }
		}catch (err) {
      // console.log(err.message);
		}finally {
      this.loading = false;
		}
  }

  async confirm() {
    this.saving = true;
    try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "Users",
        FunctionName			: "AccountCommission",
        action            : "accounts_batch_update",
        aIDsList          : this.accounts.map((val: any) => val.AID).join(","),
        businessLineSwitch: this.businessLineSwitch || 0,
        acctCommission    : JSON.stringify(this.acctCommission.map((val: any) => ({
          USERID      : val.USERID,
          TYPE        : val.TYPE,
          PERCENTAGE  : val.PERCENTAGE,
          BUSINESSLINEID: val.BUSINESSLINEID || 0
        })))
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
      }
      
      if(response.data.STATUS == 1) {
        if(response.data.MESSAGE) {
          notifier.success(response.data.MESSAGE);
        }

        this.saving = false;
        this.$emit('close');
      }
		}catch (err) {
      this.saving = 'error';
		}finally {
      this.saving = false;
		}
  }
}
