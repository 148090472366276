import { render, staticRenderFns } from "./LedgerssourceAutoSuggestInput.vue?vue&type=template&id=ef2fcdba&scoped=true"
import script from "./LedgerssourceAutoSuggestInput.vue?vue&type=script&lang=tsx"
export * from "./LedgerssourceAutoSuggestInput.vue?vue&type=script&lang=tsx"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef2fcdba",
  null
  
)

export default component.exports