





















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class SimModeModal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: {} })
  simModes!: any;

  saving: boolean = false;
  manualEntry: boolean = true;
  unpaidInvoices: boolean = false;
  opportunities: boolean = false;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    if (typeof this.simModes.manualEntry != 'undefined') {
      this.manualEntry = this.simModes.manualEntry;
    }
    if (typeof this.simModes.unpaidInvoices != 'undefined') {
      this.unpaidInvoices = this.simModes.unpaidInvoices;
    }
    if (typeof this.simModes.opportunities != 'undefined') {
      this.opportunities = this.simModes.opportunities;
    }
  }

  save() {
    this.saving = true;
    this.$emit('save', {
      manualEntry     : this.manualEntry,
      unpaidInvoices  : this.unpaidInvoices,
      opportunities   : this.opportunities
    });
  }
}
