import { render, staticRenderFns } from "./LinkUnverifyOderModal.vue?vue&type=template&id=4882d1e5"
import script from "./LinkUnverifyOderModal.vue?vue&type=script&lang=tsx"
export * from "./LinkUnverifyOderModal.vue?vue&type=script&lang=tsx"
import style0 from "./LinkUnverifyOderModal.vue?vue&type=style&index=0&id=4882d1e5&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports