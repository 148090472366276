









































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Pagination from "../components/Pagination.vue";
import ItemStatus from "../components/ItemStatus.vue";
import { notifier } from "../models/common";
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    ConfirmRemoveItemModal,
    ItemStatus
  }
})
export default class Sources extends TSXComponent<void> {
  $systemGroups: any;
  sourceType = "source";
  confirmRemoveModalVisible = false;
  deleting: string | boolean = false;
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  source = {
    items: []
  };
  fundingSource = {
    items: []
  };
  selectedIds = [];
  confirmArchive = false;
  currentChangeStatus = {
    sourceId: 0,
    status: 0
  };
  filters: any = {
    status: [1]
  };
  hasChangedStatusFilters = false;

  async created() {
    const query: any = this.$route.query;
    if (
      query.sourceType &&
      ["source", "funding_source"].includes(query.sourceType)
    ) {
      this.sourceType = query.sourceType;
    }
    await this.fetchData();
  }

  async fetchData() {
    this.hasChangedStatusFilters = false;
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Sources",
        FunctionName: "List",
        sourceType: this.sourceType,
        filters: this.filters
      });
      if (response.data.STATUS) {
        if (this.sourceType == "source") {
          this.source.items = response.data.items || [];
        } else if (this.sourceType == "funding_source") {
          this.fundingSource.items = response.data.items || [];
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async sourceTypeChange(type) {
    if (!type) return;

    this.hasChangedStatusFilters = false;
    this.sourceType = type;
    this.filters.status = [1];

    // switch route
    this.$router.replace({
      name: "Sources",
      query: {
        sourceType: this.sourceType
      }
    });
    await this.fetchData();
  }

  async changeStatus(sourceId, status) {
    if (!this.$systemGroups.includes("financial_admin")) {
      return;
    }

    // confirm archive
    if (status == 2 && !this.confirmArchive) {
      this.currentChangeStatus = {
        sourceId,
        status
      };
      this.confirmRemoveModalVisible = true;
      return;
    }

    try {
      // this.loading = true;
      if (status == 2) {
        this.deleting = true;
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Sources",
        FunctionName: "Edit",
        action: "updateStatus",
        sourceId,
        sourceType: this.sourceType,
        sStatus: status
      });
      if (response.data.STATUS) {
        if (status == 2) {
          this.deleting = false;
          this.confirmRemoveModalVisible = false;
        }
        notifier.success("Updated successfully");
        await this.fetchData();
      } else {
        if (status == 2) {
          this.deleting = "error";
        }
      }
    } catch (err) {
      console.log(err);
      if (status == 2) {
        this.deleting = "error";
      }
    } finally {
      // this.loading = false;
      this.currentChangeStatus = {
        sourceId: 0,
        status: 0
      };
    }
  }

  async resetFilter(type) {
    if (type == "status") {
      this.filters.status = [1];
      await this.fetchData();
    }
  }
}
