



































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { loginCheck } from "@/helpers/ApiHelper";

@Component({
  inheritAttrs: false,
  components: {},
  methods: {
    loginCheck
  }
})
export default class Error extends TSXComponent<void> {
  @Prop({ required: true })
  message!: string;

  @Prop({ required: false })
  redirectName?: string;

  @Prop({ required: false, default: {} })
  redirectQuery?: object;

  $allowedFunctions: any;
  $userFunctions: any;
  $systemGroups: any;

  created() {
    this.$nextTick().then(() => {
      this.$forceUpdate();
    });
  }

  get isResellerNoDashboardAccess() {
    return (
      loginCheck() == "Reseller" &&
      !this.$allowedFunctions.includes(this.$userFunctions.Dashboard) &&
      !this.$systemGroups.includes("sys_admin")
    );
  }

  get hideMessage() {
    let ret = false;
    ret = this.isResellerNoDashboardAccess && this.$route.name == "Dashboard";

    return ret;
  }
}
