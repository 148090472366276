


































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import Loader from "@/components/Loader.vue";
import { notifier } from "@/models/common";

declare const $: any;
declare const dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: { LaddaButton, Loader }
})
export default class SchedulePortalSettingsTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  @Prop({ required: false, default: "" })
  deploymentUUID!: string;

  @Prop({ required: false, default: 0 })
  accountId!: number;

  @Prop({
    required: false,
    default: {
      isShowAll: true,
      lockUnscheduledUsers: false
    }
  })
  portalSettings!: any;

  $parent: any;
  loading = false;
  saving: boolean | string = false;
  // portalLink = "";

  clickOutside() {
    this.$emit("close");
  }

  async created() {
    // generate portal link
    // this.generateLink();
  }

  /* generateLink() {
    if (this.deploymentUUID == "" || !this.accountId) {
      return "";
    }

    let query = {
      isShowAll: this.portalSettings.isShowAll,
      lockUnscheduledUsers: this.portalSettings.lockUnscheduledUsers,
      cd: this.$parent.euCustomDataFieldIds
    };
    if (!this.portalSettings.isShowAll) {
      query = { ...query, ...this.$parent.euFilters };
    }

    const routeData = this.$router.resolve({
      name: "DeploymentSchedule",
      params: {
        uuid: this.deploymentUUID,
        aID: `${this.accountId}`,
        userId: "0"
      },
      query
    });
    this.portalLink = new URL(routeData.href, window.location.origin).href;
  } */

  copyLink() {
    const portalLink = this.$parent.schedulePortalLink();
    if (!portalLink) return;
    navigator.clipboard.writeText(portalLink);
    notifier.success("Copied");
  }

  async updatePortalSettings() {
    try {
      this.saving = true;
      // this.generateLink();
      await ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "UpdateDeployment",
        action: "SchedulePortalSettings",
        deploymentId: this.deploymentId,
        schedulePortal: {
          isShowAll: this.portalSettings.isShowAll,
          lockUnscheduledUsers: this.portalSettings.lockUnscheduledUsers
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.saving = false;
    }
  }
}
