











































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "@/models/common";

declare const $: any
declare const getImageRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class ReportStatusModal extends TSXComponent<void> {

  uploading = false

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  handleReportFileUpload(e) {
    $(".reportingFileErrorMsg").html("");
  }

  handleUploadNewFile() {
    var comInstance = this;

    if (!$("#reportingFileUpload").get(0).files.length) {
      $(".reportingFileErrorMsg").html("Please upload a file.");
      return false;
    }
    var filePath = $("#reportingFileUpload").get(0).files[0].name;
    var allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
    if (!allowedExtensions.exec(filePath)) {
      $(".reportingFileErrorMsg").html("Please upload file having extensions .xls/.xlsx only.");
      return false;
    } else {
      $(".reportingFileErrorMsg").html("");
      //upload file, and get returned json
      comInstance.uploading = true;
      var processedFileUpload = getImageRouteData({
          Controller: "Invoices",
          FunctionName: "ReportingStatus",
          orderfile: $("#reportingFileUpload").get(0).files[0],
          fileType: filePath.indexOf(".csv") != -1
            ? "csv"
            : filePath.indexOf(".xls") != -1
            ? "xls"
            : ""
      }).then(async (response, statusText, jqXHR) => {
          comInstance.uploading = false
          if(response.data.STATUS == 1) {
            //reset modal
            $(".modal").removeClass('b');
            $("#reportingFileUpload").val("");
            if (response.data.notUpdatedAllInv) {
              notifier.alert(response.data.STATUSMESSAGE)
            }
            else {
              notifier.success(response.data.STATUSMESSAGE)
            }
            this.$emit("save");
          }
          else if (!response.data.STATUS) {
            var downloadLink =
              "<a href='https://var360.s3.amazonaws.com/spreadsheets/reportingStatus.csv' style='color:blue; cursor: pointer' target='_blank'>here</a>";
            var messageError = response.data.STATUSMESSAGE;
            messageError +=
              ". If don't have standard file, please download sample template " +
              downloadLink;
            $(".reportingFileErrorMsg").html(messageError);
            $(".reportingFileErrorMsg").attr("style", "position: relative;");
          }
          else {
            notifier.alert(response.data.STATUSMESSAGE)
          }
      }).catch(function (error) {
          // console.log(error);
      });
    }
  }

}
