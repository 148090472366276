










































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier, wait } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import axios from "axios";
import PageTitle from "../components/pageTitle.vue";

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    PageTitle
  }
})
export default class HardwareImport extends TSXComponent<void> {
  uploading: any = false;
  errorMsg = "";
  updateMap: any = [];
  createdAccounts: any = [];
  ignoredLinesLength = 0;
  ignoredPoIds: any = [];
  diffLines: any = [];
  notFoundSKUs: any = [];

  listPageRedirection() {
    this.$router.push({ name: "Hardware" });
  }

  showFileUpload() {
    $("#hardwareFileUpload").click();
  }

  async handleHardwareFileUpload(e) {
    e.stopPropagation();
    e.preventDefault();

    const fileName = e.target.files[0].name;
    const allowedExtensions = /(\.csv)$/i;
    if (!allowedExtensions.exec(fileName)) {
      this.errorMsg = "Please upload file having extensions .csv only.";
      return false;
    }

    try {
      this.uploading = true;
      this.errorMsg = "";
      this.updateMap = [];

      //send data
      const requestObj = {
        Controller: "Hardware",
        FunctionName: "ImportHardwares",
        isWebSubmit: true,
        filePath: e.target.files[0],
        fileName,
        fileType:
          fileName.indexOf(".csv") != -1
            ? "csv"
            : fileName.indexOf(".xls") != -1
            ? "xls"
            : ""
      };
      const formData = new FormData();
      for (let key in requestObj) {
        formData.append(key, requestObj[key]);
      }
      formData.append("session", sessionStorage.getItem("sessionID") || "");
      const response: any = await axios.post(
        dataURL + "?ReturnType=JSON&r=Hardware/ImportHardwares",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data"
          }
        }
      );

      if (response.data.STATUS == 1) {
        notifier.success("Update hardwares successfully");
        this.uploading = false;
        this.updateMap = response.data.updateMap || [];
        this.createdAccounts = response.data.createdAccounts || [];
        this.ignoredLinesLength = response.data.ignoredLinesLength || 0;
        this.ignoredPoIds = response.data.ignoredPoIds || [];
        this.diffLines = response.data.diffLines || [];
        this.notFoundSKUs = response.data.notFoundSKUs || [];
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }

        this.uploading = "error";
      }
    } catch (error) {
      this.uploading = "error";
      console.log(error);
    }
  }

  getResult() {
    if (!this.updateMap.length) return [];

    const poIds: string[] = [];
    const ret: any = [];
    for (const item of this.updateMap) {
      if (!poIds.includes(item.POID)) {
        poIds.push(item.POID);
        const newStatus = this.updateMap.find(
          t => t.POID == item.POID && t.NEWPSTATUS != ""
        );
        ret.push({
          poId: item.POID,
          lines: this.updateMap.filter(t => t.POID == item.POID).length,
          newStatus: newStatus ? newStatus.NEWPSTATUS : ""
        });
      }
    }

    return ret;
  }
}
