






















































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier, printHtml,downloadFileUrl } from "../models/common";
import { OrderAPIRepo } from "../repos/OrderAPIRepo";
import axios from "axios";
import Datepicker from "./Datepicker.vue";
import { ReportAPIRepo, SortField, Filters,Sort } from "../repos/ReportAPIRepo";

const reportRepo = new ReportAPIRepo();

declare function require(params: any): any;
const Handlebars = require("handlebars/runtime.js");
interface Props {}

interface Events {
  onClose: void;
  onOk: void;
  onPrint: void;
}

declare const $: any;
const orderRepo = new OrderAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Datepicker
  }
})
export default class ExportModal extends TSXComponent<Props, Events> {
  // @Prop({
  //   required: false,
  //   default: {
  //     purchaseSDate: "",
  //     purchaseEDate:""
  //   }
  // })


  @Prop({ required: true, type: Object })
  selectedReport!: Object;

  $refs!: {
    modal: HTMLDivElement;
  };

  currentRole = sessionStorage.getItem("userRole");
  $root: any;
  saving = false;

  created() {
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async save() {
    // this.$emit("genereateId", { start : $('#purchaseSDate').val(),end : $('#purchaseEDate').val() } );
    var fromDate = $('#purchaseSDate').val();
    var toDate = $('#purchaseEDate').val();
    var fromDateYear = new Date(fromDate);
    var toDateYear = new Date(toDate);
    var date_regex = /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    if(fromDate == "" || toDate == "") {
      if(fromDate == "" && toDate == "") {
        $(".errorMsg.purchaseSDate").text("The from date field is required");
        $(".errorMsg.purchaseEDate").text("The to date field is required");
        return false;
      } else {
        $(".errorMsg.purchaseSDate").text("");
        $(".errorMsg.purchaseEDate").text("");
      }
      if(fromDate == "") {
        $(".errorMsg.purchaseSDate").text("The from date field is required");
        return false;
      } else {
        $(".errorMsg.purchaseSDate").text("");
      } 
      if(toDate == "") {
        $(".errorMsg.purchaseEDate").text("The to date field is required");
        return false;
      } else {
        $(".errorMsg.purchaseEDate").text("");
      }
    }
    if ($("#purchaseSDate").val() != "") {
      if (
        !$("#purchaseSDate")
          .val()
          .trim()
          .match(date_regex)
      ) {
        $(".errorMsg.purchaseSDate").text("Invalid From Date");
        return false;
      } else {
        $(".errorMsg.purchaseSDate").text("");
      }
    }
    if ($("#purchaseEDate").val() != "") {
      if (
        !$("#purchaseEDate")
          .val()
          .trim()
          .match(date_regex)
      ) {
        $(".errorMsg.purchaseEDate").text("Invalid To Date");
        return false;
      } else {
        $(".errorMsg.purchaseEDate").text("");
      }
    }
    if(new Date() < fromDateYear || new Date() < toDateYear) {
      if(new Date() < fromDateYear)
        $(".errorMsg.purchaseSDate").text("The date is invalid");
      else
        $(".errorMsg.purchaseSDate").text("");
      if(new Date() < toDateYear)
        $(".errorMsg.purchaseEDate").text("The date is invalid");
      else
        $(".errorMsg.purchaseEDate").text("");
      return false;
    } else {
      $(".errorMsg.purchaseSDate").text("");
      $(".errorMsg.purchaseEDate").text("");
    }
    if(fromDateYear > toDateYear) {
      $(".errorMsg.purchaseSDate").text("From date must be less than to date");
      return false;
    } else {
      $(".errorMsg.purchaseSDate").text("");
    }
    this.saving = true;
    // var dataObj = {
    //   controller: "Reports",
    //   FunctionName: "SaveReport",
    //   reportName: $('#reportName').val(),
    //   reportStatus: $('#reportStatus').val(),
    //   productSKU: $('#productSKU').val(),
    //   lineItem: $('#lineItem').val(),
    //   purchaseSDate: $('#purchaseSDate').val(),
    //   purchaseEDate: $('#purchaseEDate').val()
    // };
    // var reportData = getRouteData(dataObj);
    // reportData.then(function(response, statusText, jqXHR) {
    //   try {
    //     notifier.success(response.data.MESSAGE);
    //   } catch (e) {
    //     notifier.alert(e.message);
    //     return false;
    //   }
    // });
    try {
      const reportData = await reportRepo.reportGenerate(
       this.selectedReport["id"],$('#purchaseSDate').val(),$('#purchaseEDate').val(),this.selectedReport["ExportType"]
      );
      // this.reportList = reportData.REPORTS;
      // console.log('reports',reportData)
      if(reportData.STATUS == 1) {
        downloadFileUrl(reportData.S3URL);
        this.$emit("reportUpdate");
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.saving = false;
      this.$emit("close");
    }
  }
}
