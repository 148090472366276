











































































































































































































































































































































































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import { InvoiceLineItem } from "../models/invoice";
import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import SKUAutoSuggestInput from "./SKUAutoSuggestInput.vue";
import { QuoteLineItemAPIRepo } from "../repos/QuoteLineItemAPIRepo";
import { notifier, roundCents } from "../models/common";
import directives from "../helpers/directives";
import ProductCatTooltip from "../components/ProductCatTooltip.vue";
import DatepickerTooltip from "./DatepickerTooltip.vue";
import { dollarFormat, getEUAddress } from "@/helpers/ApiHelper";

const quoteLineItemAPIRepo = new QuoteLineItemAPIRepo();

interface Props {}
interface Events {}
declare const dataURL: string;
declare const htmlCheck: Function;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    AutoSuggestInput,
    ProductCatTooltip,
    // SKUAutoSuggestInput,
    DatepickerTooltip
  },
  directives,
  methods: {
    dollarFormat,
    getEUAddress
  }
})
export default class InvoiceFormLineItemList extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: false })
  autoName?: boolean;

  @Prop({ required: false, default: false })
  autoCost?: boolean;

  @Prop({ required: false, default: 2 })
  expandDecimalPrecision?: number;

  $parent: any;
  $decimals: any;
  lengthItem = 0;
  isDescLoad = false;
  isPriceLoad = false;
  currRow = -1;
  itemNames: { [key: string]: InvoiceLineItem } = {};
  showDatePicker = "";

  constructor() {
    super();
    this.lengthItem = 3;
    var itemName: { [key: string]: InvoiceLineItem } = {};
    for (let i = 1; i <= this.lengthItem; i++) {
      itemName["productName_" + i] = {
        description: "",
        partNo: "",
        unitPrice: 0,
        quantity: 0,
        included: 0,
        noTax: 0,
        estPrice: 0,
        taxable: false,
        taxRate: 0,
        taxAmount: 0,
        totalAmount: 0,
        includedparent: 0,
        baseProductId: 0,
        includeItemCount: 0,
        POliID: 0,
        shippingDate: ""
      };
    }
    this.itemNames = itemName;
  }

  errors!: ErrorBag;
  showError = 0;
  get validation() {
    const validations: Object = {};
    validations["required"] = true;
    return validations;
  }

  calcEstPrice(index: string) {
    var totalAll = 0.0;
    const item = this.itemNames[index];
    if (
      item.quantity &&
      item.quantity >= 0 &&
      item.unitPrice &&
      item.unitPrice != 0
    ) {
      item.estPrice = item.unitPrice * item.quantity;
    } else {
      var quantity: number | undefined = 0;
      if (item.quantity <= 0 || item.quantity == undefined) {
        quantity = 0;
      } else {
        quantity = item.quantity;
      }

      var unitPrice: number | undefined = 0;
      if (item.unitPrice <= 0 || item.unitPrice == undefined) {
        unitPrice = 0;
      } else {
        unitPrice = item.unitPrice;
      }
      item.estPrice = unitPrice * quantity;
    }

    totalAll = this.getTotalAll();
    this.$forceUpdate();

    this.$emit("summaryTotal", totalAll);
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    try {
      const data = await quoteLineItemAPIRepo.find(search);

      return {
        options: data.map(
          (item): Option => {
            return {
              value: item.QPRODSKU,
              label: `${item.QPRODSKU} - ${item.QPRODDESC}`,
              text: item.QPRODSKU
            };
          }
        )
      };
    } catch (err) {
      notifier.alert(err.message);
      return {
        options: []
      };
    }
  };

  addRow(subOrderId = 0) {
    // this.lengthItem = this.lengthItem + 1;
    //new index = max index + 1
    // var indArr: number[] = [];
    // $.each(this.itemNames, function(i, val) {
    //   indArr.push(parseInt(i.replace("productName_", "")));
    // });
    // var ind = 1;
    // if (indArr.length) {
    //   ind = Math.max.apply(Math, indArr) + 1;
    // }
    // if (this.inlineOrderItems.length) {
    //   this.$set(this.inlineOrderItems, this.inlineOrderItems.length, {
    //     POPRODDESC: "",
    //     POPRODSKU: "",
    //     POPRICEREG: 0,
    //     POCUSTOMERPRICE: 0,
    //     POFINALPRICE: 0,
    //     LICUSTOMERTOTALPRICE: 0,
    //     POPRODQUANTITY: 0
    //   });
    // }
    this.lengthItem = Object.keys(this.itemNames).length + 1;
    this.$set(this.itemNames, "productName_" + (this.getMaxIndex() + 1), {
      description: "",
      partNo: "",
      unitPrice: 0.0,
      quantity: 0,
      included: 0,
      estPrice: 0.0,
      noTax: 0,
      taxable: false,
      taxRate: 0,
      taxAmount: 0,
      totalAmount: 0,
      includedparent: 0,
      baseProductId: 0,
      includeItemCount: 0,
      POliID: 0,
      shippingDate: "",
      subOrderId: subOrderId
    });
    setTimeout(() => {
      $("html, body").animate(
        { scrollTop: $("html, body").get(0).scrollHeight },
        200
      );
    }, 100);
  }

  htmlCheck(InputVal) {
    if (InputVal.length > htmlParse(InputVal).length) {
      return true;
    } else {
      return false;
    }
  }

  removeHTML(index, name, item) {
    var htmlCount = 0;
    this.currRow = index;
    // var regex = /[^a-zA-Z0-9#?]/gi;
    // New update from amanda
    var regex = /[^a-zA-Z0-9#-]/gi;
    // if (!this.inlineOrderItems.length) {
    if (htmlCheck(this.itemNames[index][name])) {
      this.itemNames[index][name] = htmlParse(this.itemNames[index][name]);
      htmlCount++;
    }
    if (this.itemNames[index].partNo != "") {
      this.itemNames[index].partNo = this.itemNames[index].partNo.replace(
        regex,
        ""
      );
      if (regex.test(this.itemNames[index].partNo) == false) {
        this.$set(
          this,
          "partNo_productName_" + index,
          this.itemNames[index].partNo.replace(regex, "")
        );
      }
    }
    /*} else {
      if (htmlCheck(this.inlineOrderItems[index][name])) {
        this.inlineOrderItems[index][name] = htmlParse(
          this.inlineOrderItems[index][name]
        );
        htmlCount++;
      }
      if (this.inlineOrderItems[index].POPRODSKU != "") {
        this.inlineOrderItems[index].POPRODSKU = this.inlineOrderItems[
          index
        ].POPRODSKU.replace(regex, "");
        if (regex.test(this.inlineOrderItems[index].POPRODSKU) == false) {
          this.$set(
            this,
            "partNo_" + index,
            this.inlineOrderItems[index].POPRODSKU.replace(regex, "")
          );
        }
      }
    }*/
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
    }
    var sku = this.itemNames[index][name];
    // if (!this.inlineOrderItems.length) {
    //   sku = this.itemNames[index][name];
    // } else {
    //   sku = this.inlineOrderItems[index].POPRODSKU;
    // }
    if (name == "description") {
      //just run skulookup when blur on sku box
      return;
    }

    if (sku.length) {
      var dataObj = {
        // controller: "Orders",
        // FunctionName: "purchaseLineOrderslist",
        controller: "Quotes",
        FunctionName: "quoteLineItemList",
        search: sku
      };
      var $this = this;
      if ($this.autoName) {
        this.isDescLoad = true;
      }
      if ($this.autoCost) {
        this.isPriceLoad = true;
      }
      var processedFileUpload = getRouteData(dataObj)
        .then(function(response, statusText, jqXHR) {
          if ("LINEITEMS" in response.data && response.data.LINEITEMS[0]) {
            var productInfo = response.data.LINEITEMS[0];
            setTimeout(function() {
              if ($this.autoName) {
                // if (!$this.inlineOrderItems.length) {
                //   $this.itemNames[index].description =
                //     response.data.LINEITEMS[0].PRODUCTNAME;
                // } else {
                //   $this.inlineOrderItems[index].POPRODDESC =
                //     response.data.LINEITEMS[0].PRODUCTNAME;
                // }
                $this.itemNames[index].description =
                  response.data.LINEITEMS[0].PRODUCTNAME;

                if (
                  typeof productInfo.PRODUCTSKU != "undefined" &&
                  productInfo.PRODUCTSKU != ""
                ) {
                  // if (!$this.inlineOrderItems.length) {
                  //   $this.itemNames[index].partNo = productInfo.PRODUCTSKU;
                  // }else {
                  //   $this.inlineOrderItems[index].POPRODSKU = productInfo.PRODUCTSKU;
                  // }
                  $this.itemNames[index].partNo = productInfo.PRODUCTSKU;
                }

                // if (!$this.inlineOrderItems.length) {
                if (typeof productInfo.DEFAULTCATEGORYID != "undefined") {
                  $this.itemNames[index]["ItemCategory"] =
                    productInfo.DEFAULTCATEGORYID;
                  $this.itemNames[index]["ItemCategoryName"] =
                    productInfo.CATEGORYNAME;
                  //$this.$parent.productCatTTVisibleIndex = ""; //hide product category tooltip
                } else {
                  $this.itemNames[index]["ItemCategory"] = 0;
                  $this.itemNames[index]["ItemCategoryName"] = "";
                  $this.$parent.productCatTTVisibleIndex = index; //show product category tooltip
                }
                /*}else {
                  if(typeof productInfo.DEFAULTCATEGORYID != "undefined") {
                    $this.inlineOrderItems[index]["ItemCategory"] = productInfo.DEFAULTCATEGORYID;
                    $this.inlineOrderItems[index]["ItemCategoryName"] = productInfo.CATEGORYNAME;
                    //$this.$parent.productCatTTVisibleIndex = ""; //hide product category tooltip
                  }else {
                    $this.inlineOrderItems[index]["ItemCategory"] = 0;
                    $this.inlineOrderItems[index]["ItemCategoryName"] = "";
                    $this.$parent.productCatTTVisibleIndex = index; //show product category tooltip
                  }
                }*/
              }
              if ($this.autoCost) {
                /*if (!$this.inlineOrderItems.length) {
                  // $this.itemNames[index].estPrice =
                  //   response.data.LINEITEMS[0].PRODUCTPRICE;
                  $this.itemNames[index].unitPrice =
                    response.data.LINEITEMS[0].PRODUCTPRICE;
                } else {
                  // $this.inlineOrderItems[index].LICUSTOMERTOTALPRICE =
                  //   response.data.LINEITEMS[0].PRODUCTPRICE;
                  $this.inlineOrderItems[index].POCUSTOMERPRICE =
                    response.data.LINEITEMS[0].PRODUCTPRICE;
                }*/

                $this.itemNames[index].unitPrice =
                  response.data.LINEITEMS[0].PRODUCTPRICE;
              }
              $this.isDescLoad = false;
              $this.isPriceLoad = false;
            }, 300);
          } else {
            $(
              ".list-item[data-index=" +
                index +
                "] .new-quote-name-input .loader, .list-item[data-index=" +
                index +
                "] .new-quote-cost-input .loader"
            ).addClass("hidden");
            $this.isDescLoad = false;
            $this.isPriceLoad = false;
            $this.$parent.productCatTTVisibleIndex = index; //show product category tooltip
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      this.$parent.productCatTTVisibleIndex = ""; //hide product category tooltip
      item.ItemCategory = 0;
      item.ItemCategoryName = "";
    }
    // this.isDescLoad = false;
    // this.isPriceLoad = false;
  }

  addDelete(index,subOrderId = 0) {
    /* var regex = /[^a-zA-Z0-9#?]/gi;
    //get max index
    var indArr: number[] = [];
    var maxInd = 0;
    $.each(this.itemNames, function(i, val) {
      indArr.push(parseInt(i.replace("productName_", "")));
    });
    if (indArr.length) {
      maxInd = Math.max.apply(Math, indArr);
    }*/

    if (parseInt(index.replace("productName_", "")) == this.getMaxIndex(subOrderId)) {
      this.addRow(subOrderId);
    }
  }

  findOtherIncludedItems(index) {
    const idx = parseInt(index.split("_")[1]);
    const items: any = [];
    for (let i = idx - 1; i >= 1; i--) {
      const each = this.itemNames[`productName_${i}`];
      if (!each) {
        continue;
      }
      if (each.included === 0) {
        break;
      }

      items.push(each);
    }

    return items;
  }

  findParent(index) {
    const idx = parseInt(index.split("_")[1]);
    // find parent
    for (let i = idx - 1; i >= 1; i--) {
      const each = this.itemNames[`productName_${i}`];
      if (!each) {
        continue;
      }
      if (each.included === 0) {
        return each;
      }
    }

    return null;
  }

  removeItem(item, index) {
    // const removedIndex = parseInt(index.split("_")[1]);
    // const item = this.itemNames[index];
    // this.$delete(this.itemNames, index);
    // this.lengthItem = Object.keys(this.itemNames).length;
    // if (Object.keys(this.itemNames).length) {
    //   for (let i in this.itemNames) {
    //     let tmpIndex = parseInt(i.split("_")[1]);
    //     if (tmpIndex > removedIndex) {
    //       if (this.itemNames[i].included == 0) {
    //         break;
    //       } else {
    //         this.$delete(this.itemNames, i);
    //       }
    //     }
    //   }
    // }
    // const includedItems = this.findOtherIncludedItems(index);
    // const parent = this.findParent(index);

    // var includedItemsBelowTheLine: any = [];
    // if(!parent) {//no based item
    //   if(includedItems.length) {
    //     includedItems[includedItems.length - 1].included = 0;//make this item to be based item
    //   }else if(Object.keys(this.itemNames).length) {
    //     for(let i in this.itemNames) {
    //       let tmpIndex = parseInt(i.split("_")[1]);
    //       if(tmpIndex > removedIndex) {
    //         if(this.itemNames[i].included == 0) {
    //           break;
    //         }else {
    //           includedItemsBelowTheLine.push(this.itemNames[i]);
    //         }
    //       }
    //     }
    //     if(includedItemsBelowTheLine.length) {
    //       includedItemsBelowTheLine[0].included = 0;//make this item to be based item
    //     }
    //   }
    // }

    // firstly remove config items if any
    const includedItems = this.getIncludedItems(item, index);
    const configItems = includedItems.filter(t => t.ISCONFIG || 0);
    for (const t of configItems) {
      this.$delete(this.itemNames, t.indexKey);
    }
    // exclude grouped item
    const groupedItems = includedItems.filter(t => !(t.ISCONFIG || 0));
    for (const t of groupedItems) {
      t.included = 0;
    }
    // remove this item
    this.$delete(this.itemNames, index);

    this.$emit("summaryTotal", this.getTotalAll());
  }

  //get total est prices of line items
  getTotalAll() {
    var totalAll = 0;
    for (var i in this.itemNames) {
      totalAll += parseFloat(this.itemNames[i].estPrice.toFixed(2));
    }

    return totalAll;
  }

  showHideDatePickerTooltip(index) {
    this.showDatePicker = this.showDatePicker == index ? "" : index;
  }

  addShipDate(date, index) {
    this.itemNames[index].shippingDate = date.replace(/([^0-9/])/g, "");
  }

  getMaxIndex(subOrderId = 0) {
    //get max index
    const indArr: number[] = [];
    const allIndArr: number[] = [];
    let maxInd = 0;
    $.each(this.itemNames, function(i, val) {
      if (val.subOrderId == subOrderId)
        indArr.push(parseInt(i.replace("productName_", "")));
      else if (!subOrderId)
        allIndArr.push(parseInt(i.replace("productName_", "")));
    });
    if (indArr.length) {
      maxInd = Math.max.apply(Math, indArr);
    }
    else if (allIndArr.length){
      maxInd = Math.max.apply(Math, allIndArr);
    }
    return maxInd;
  }

  isEmptyRow(item) {
    if (item.partNo == "" && item.description == "" && item.quantity == 0) {
      return true;
    }

    return false;
  }

  lastGroupItem(index) {
    if (!this.itemNames[index] || !this.itemNames[index].included) {
      return false;
    }

    // check if next item is a base, then this is last item
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        const nextItem = this.itemNames[keys[i + 1]];
        if (nextItem && !nextItem.included) {
          return true;
        }
        break;
      }
    }

    return false;
  }

  hasConfigs(item, index) {
    if (item.included || 0) return false;

    let ret = false;
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for (let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem: any = this.itemNames[keys[j]];
          if (!nextItem) break;

          // check if this base has a config item
          if (!(nextItem.included || 0)) {
            // this is an other base
            break;
          } else if (nextItem.ISCONFIG || 0) {
            ret = true;
            break;
          }
        }

        break;
      }
    }

    return ret;
  }

  getBaseTotal(item, index) {
    const ret = {
      price: 0,
      customerPrice: 0,
      margin: 0,
      marginRate: 0,
      total: 0
    };
    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    for (const t of configItems) {
      // cost
      ret.price += t.price || 0;
      ret.price = parseFloat(ret.price.toFixed(this.expandDecimalPrecision));
      // price
      ret.customerPrice += t.unitPrice || 0;
      ret.customerPrice = parseFloat(
        ret.customerPrice.toFixed(this.expandDecimalPrecision)
      );
      // total
      ret.total += t.estPrice || 0;
      ret.total = parseFloat(ret.total.toFixed(2));
    }

    // append base cost per/price per
    ret.price += item.price || 0;
    ret.price = parseFloat(ret.price.toFixed(this.expandDecimalPrecision));
    ret.customerPrice += item.unitPrice || 0;
    ret.customerPrice = parseFloat(
      ret.customerPrice.toFixed(this.expandDecimalPrecision)
    );
    ret.total += item.estPrice || 0;
    ret.total = parseFloat(ret.total.toFixed(2));

    // margin
    ret.margin = parseFloat((ret.customerPrice - ret.price).toFixed(2));
    // marginRate
    if (ret.margin == 0) {
      ret.marginRate = 0;
    } else if (ret.price == 0) {
      ret.marginRate =
        ret.customerPrice > 0 ? 100 : ret.customerPrice < 0 ? -100 : 0;
    } else {
      //just allow 4 decimals for rate
      ret.marginRate = parseFloat(
        ((ret.margin / ret.price) * 100).toFixed(this.$decimals)
      );
    }

    return ret;
  }

  // get included items of a base product
  getIncludedItems(item, index) {
    if (item.included) return [];

    const ret: any = [];
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for (let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem: any = this.itemNames[keys[j]];
          if (!nextItem || !nextItem.included) break;

          if (nextItem.included) {
            nextItem.indexKey = keys[j];
            ret.push(nextItem);
          }
        }

        break;
      }
    }

    return ret;
  }

  mouseEnterBase(type, item, index) {
    if (!this.hasConfigs(item, index)) {
      return;
    }

    // outline related config items
    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    if (!configItems.length) return;

    // reset
    item.priceHover = 0;
    item.customerPriceHover = 0;
    for (const t of configItems) {
      t.priceHover = 0;
      t.customerPriceHover = 0;
    }

    if (type == "price") {
      item.priceHover = 1;
      for (const t of configItems) {
        t.priceHover = 1;
      }
    } else if (type == "customerPrice") {
      item.customerPriceHover = 1;
      for (const t of configItems) {
        t.customerPriceHover = 1;
      }
    }

    this.$forceUpdate();
  }

  mouseLeaveBase(type, item, index) {
    if (!this.hasConfigs(item, index)) {
      return;
    }
    // reset
    item.priceHover = 0;
    item.customerPriceHover = 0;
    for (const t of this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    )) {
      t.priceHover = 0;
      t.customerPriceHover = 0;
    }
    this.$forceUpdate();
  }

  baseQuantityChange(item, index) {
    // apply same quantity for config items
    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    if (!configItems.length) return;

    for (const t of configItems) {
      t.quantity = item.quantity || 0;
      t.estPrice = (t.unitPrice || 0) * t.quantity;
    }
  }

  getSSTotal(subOrderId) {
    const ret = {
      total: 0,
      totalFormatted: ""
    };
    if (!subOrderId) return ret;

    for (const i in this.itemNames) {
      const item: any = this.itemNames[i];
      if (item.subOrderId != subOrderId) {
        continue;
      }

      ret.total += item.estPrice || 0;
      ret.total = parseFloat(ret.total.toFixed(2));
    }
    ret.totalFormatted = dollarFormat(ret.total);

    return ret;
  }

  itemTaxable(item, index, taxRate) {
    // disable tax on config item
    // if (item.included && item.ISCONFIG) {
    //   return;
    // }

    // let totalTaxCheckBox: number;
    let checkbox = "#taxable_" + index;
    if ($(checkbox).hasClass("checkbox_checked")) {
      // unchecked
      item.taxable = false;
      item.taxRate = 0;
      item.taxAmount = 0;
      item.totalAmount = item.estPrice + item.taxAmount;
    } else if ($(checkbox).hasClass("checkbox_unchecked")) {
      // checked
      item.taxable = true;
      taxRate = parseFloat(taxRate.replace("%", ""));
      item.taxRate = taxRate;
      item.taxAmount = parseFloat(
        roundCents((item.estPrice * taxRate) / 100).toFixed(2)
      );
      item.totalAmount = item.estPrice + item.taxAmount;
    }

    // config items have same taxRate as parent
    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    for (const t of configItems) {
      t.taxRate = item.taxRate;
      t.taxable = item.taxable;
      t.taxAmount = parseFloat(
        roundCents((t.estPrice * t.taxRate) / 100).toFixed(2)
      );
      t.totalAmount = t.estPrice + t.taxAmount;
    }

    this.$parent.updateTotalTaxShipCheckBox();
  }
}
