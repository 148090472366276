








import { Component as TSXComponent } from "vue-tsx-support";
import DeploymentsGridView from "@/components/Deployment/DeploymentsGridView.vue";
import { Component } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {
    DeploymentsGridView,
  },
  methods: {}
})
export default class DeploymentsNew extends TSXComponent<void> {

}
