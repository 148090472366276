


















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

@Component({
  inheritAttrs: true,
  components: {}
})

export default class EmployeePlanComponent extends TSXComponent<void> {

  @Prop({ required: false, default: "" })
  planUUID!: string;

  empPlanData: any = [];
  totalEmpPlan: number = 0;
  loading: boolean = false;
  
  clickOutside() {
    this.$emit("close");
  }

  async created() {
    if (this.planUUID.length) {
      this.loading = true;
      const response = await ApiHelper.callApi('post', {
        controller: "Finances",
        FunctionName: "EmpPlanList",
        planUUID: this.planUUID
      });

      if (response.STATUS === 1) {
        this.empPlanData = response.EMPPLANS;
        this.totalEmpPlan = response.TOTALEMPPLANS;
      }
      this.loading = false;
    }
  }
}
