












































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { validateFn } from "../helpers";
@Component({
  inheritAttrs: false,
  components: {}
})
export default class quoteEdit extends TSXComponent<void> {
  tableColumns = [];
  items = {
    quoteID: "[Auto Generate]",
    qDescription: "",
    qDateTime: "",
    aID: "",
    specnotes: "",
    qlines: "6",
    mdPurchaseNote: "",
    purchaseinfo: "",
    purchaseinfoModal: "",
    presetdropmenu: "",
    qSpecialPrice: "",
    Contract: "",
    qLocationID: "0",
    ItemDescription1: "",
    ItemNumber1: "",
    RegPrice1: "",
    ItemQuantity1: "",
    ItemTotal1: 0,
    ItemDescription2: "",
    ItemNumber2: "",
    RegPrice2: "",
    ItemQuantity2: "",
    ItemTotal2: 0,
    ItemDescription3: "",
    ItemNumber3: "",
    RegPrice3: "",
    ItemQuantity3: "",
    ItemTotal3: 0,
    ItemDescription4: "",
    ItemNumber4: "",
    RegPrice4: "",
    ItemQuantity4: "",
    ItemTotal4: 0,
    ItemDescription5: "",
    ItemNumber5: "",
    RegPrice5: "",
    ItemQuantity5: "",
    ItemTotal5: 0,
    ItemDescription6: "",
    ItemNumber6: "",
    RegPrice6: "",
    ItemQuantity6: "",
    ItemTotal6: 0,
    totalPrice: "0.00",
    accItems: [],
    suggestedProducts: []
  };
  quoteItems: {
    QPRODDESC: string;
    QPRODSKU: string;
    QPRICEREG: number;
    QPRODQUANTITY: number;
    QPRICEACT: string;
    QCATEGORY: string;
    QUOTELIID: string;
  }[] = [];
  accItems = [];
  details = {
    QUOTEDETAILS: {
      AACCOUNTID: 0,
      QOPEN: true
    }
  };

  created() {
    this.getAccs();
  }

  async getAccs() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Accounts",
      FunctionName: "List"
    });
    try {
      this.accItems = response.data.ACCOUNTS;
    } catch (e) {
      console.log("quotes.js : grabOrderData error : ", e);
    }
  }
  getNumber(number, defaultNumber) {
    return isNaN(parseInt(number, 10)) ? defaultNumber : parseInt(number, 10);
  }
  totalcalFn() {
    const ItemTotal1 =
      this.getNumber(this.items.RegPrice1, 0) *
      this.getNumber(this.items.ItemQuantity1, 0);
    const ItemTotal2 =
      this.getNumber(this.items.RegPrice2, 0) *
      this.getNumber(this.items.ItemQuantity2, 0);
    const ItemTotal3 =
      this.getNumber(this.items.RegPrice3, 0) *
      this.getNumber(this.items.ItemQuantity3, 0);
    const ItemTotal4 =
      this.getNumber(this.items.RegPrice4, 0) *
      this.getNumber(this.items.ItemQuantity4, 0);
    const ItemTotal5 =
      this.getNumber(this.items.RegPrice5, 0) *
      this.getNumber(this.items.ItemQuantity5, 0);
    const ItemTotal6 =
      this.getNumber(this.items.RegPrice6, 0) *
      this.getNumber(this.items.ItemQuantity6, 0);
    const totalPrice =
      ItemTotal1 +
      ItemTotal2 +
      ItemTotal3 +
      ItemTotal4 +
      ItemTotal5 +
      ItemTotal6;
    this.items["ItemTotal1"] = ItemTotal1 > 0 ? ItemTotal1 : 0;
    this.items["ItemTotal2"] = ItemTotal2 > 0 ? ItemTotal2 : 0;
    this.items["ItemTotal3"] = ItemTotal3 > 0 ? ItemTotal3 : 0;
    this.items["ItemTotal4"] = ItemTotal4 > 0 ? ItemTotal4 : 0;
    this.items["ItemTotal5"] = ItemTotal5 > 0 ? ItemTotal5 : 0;
    this.items["ItemTotal6"] = ItemTotal6 > 0 ? ItemTotal6 : 0;
    this.items["totalPrice"] = totalPrice > 0 ? totalPrice.toFixed(2) : "";
  }

  async saveFn(data) {
    // edit call
    const saveData = data;
    saveData["Controller"] = "Quotes";
    saveData["FunctionName"] = "Add";
    saveData["qlines"] = "6";
    saveData["aID"] = this.items.aID;
    saveData["ItemTotal1"] = this.items.ItemTotal1;
    saveData["ItemTotal2"] = this.items.ItemTotal2;
    saveData["ItemTotal3"] = this.items.ItemTotal3;
    saveData["ItemTotal4"] = this.items.ItemTotal4;
    saveData["ItemTotal5"] = this.items.ItemTotal5;
    saveData["ItemTotal6"] = this.items.ItemTotal6;
    const response = await axios.post(dataURL + "?ReturnType=JSON", saveData);

    try {
      if (response.data.STATUS && response.data.STATUSMESSAGE) {
        this.items = response.data.QUOTES || [];
        this.details = response.data || {};

        this.$router.push({ name: "Quotes" });
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
      }
    } catch (e) {
      console.log("quotes.js : updateDetailItem error : ", e);
    }
  }
}
