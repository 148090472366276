



















































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import { ApiHelper } from "@/helpers/all";
import Loader from "@/components/Loader.vue";
import { v4 as uuidv4 } from "uuid";
import { notifier } from "@/models/common";
import moment from "moment";
import DropdownControl from "@/components/DropdownControl.vue";

declare const $: any;
declare const dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: { LaddaButton, Datepicker, Loader, DropdownControl }
})
export default class PickWindowTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: -1 })
  currentIndex!: number;

  @Prop({ required: false, default: {} })
  currentItem!: any;

  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  @Prop({ required: false, default: {} })
  deploymentParams?: any;

  $parent: any;
  timeId = "";
  loading = false;
  saving: boolean | string = false;
  isOverride = false;
  scheduleDate = "";
  available: any = {
    start: "",
    end: "",
    daysOfWeek: [],
    duration: {
      hours: "",
      startTime: "",
      startTimePeriod: "",
      startTimeFull: "",
      endTime: "",
      endTimePeriod: "",
      endTimeFull: "",
      full: ""
    }
  };
  exceptions: any = [];
  blockTimeGroups: any = [];
  timeSlots: any = [];
  // time slots selected by group/ schedule a time for other users
  selectedTimeSlots: any = [];
  initDate = "";
  datePickerKey = uuidv4();
  disabledDates: string[] = [];
  availabilitiesList: any = [];
  selectedAvailabilityIds: number[] = [];
  timeInfo: any = null;
  fitAvailabilities: any = [];

  clickOutside() {
    this.$emit("close");
  }

  async created() {
    // get availability
    this.loading = true;
    const [availabilityRes, scheduleATimeRes] = await Promise.all([
      ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "GetAvailabilitiesList",
        deploymentId: this.deploymentId
      }),
      ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "GetScheduleATime",
        deploymentId: this.deploymentId,
        endUserId: this.currentItem.USERID
      })
    ]);
    this.loading = false;

    const datePicker: any = this.$refs.datePicker;
    if (availabilityRes.STATUS == 1) {
      this.availabilitiesList = availabilityRes.availabilitiesList || [];
      this.blockTimeGroups = availabilityRes.blockTimeGroups || [];
      this.selectedTimeSlots = (availabilityRes.selectedTimeSlots || []).filter(
        item => (item.ENDUSERID || 0) != this.currentItem.USERID
      );
    }

    if (scheduleATimeRes.STATUS == 1) {
      const scheduleATime = scheduleATimeRes.scheduleATime || [];
      const timeInfo = scheduleATime.find(
        item => item.ENDUSERID == this.currentItem.USERID
      );
      if (timeInfo) {
        this.timeInfo = timeInfo;
        this.loadAvailability(timeInfo.AVAILABILITYID || 0);
      }
    }

    datePicker.destroy();
    this.disabledDates = [];
    this.datePickerKey = uuidv4();

    // specify related availabilities based on criteria
    for (const item of this.availabilitiesList) {
      item.disabled = false;
      if (!item.criteriaFilters.length) continue;

      let matchCnt = 0;
      for (const filter of item.criteriaFilters) {
        if ((filter.value || []).length && filter.value[0] != "") {
          let dataKey = `CUSTOMDATA${filter.customFieldId}`;
          if (
            [
              "requestName",
              "submitterName",
              "submitterEmail",
              "leaseCoordinator"
            ].includes(filter.customFieldKey)
          ) {
            dataKey = filter.customFieldKey.toUpperCase();
          }
          const dataVal = this.currentItem[dataKey] || "";

          if (dataVal && filter.value.includes(dataVal)) {
            matchCnt += 1;
          }
        }
      }

      // allow selecting if match with all filters
      if (matchCnt == item.criteriaFilters.length) {
        item.disabled = false;
        this.fitAvailabilities.push(item.available.availabilityId);
      } else {
        item.disabled = true;
      }
    }

    if (!this.selectedAvailabilityIds.length && this.fitAvailabilities.length) {
      // auto select first one in the list if not selected before
      this.loadAvailability(this.fitAvailabilities[0]);
    }
    // if cannot specify a related availability based on criteria, try to set first one in the list
    if (
      !this.selectedAvailabilityIds.length &&
      this.availabilitiesList.length
    ) {
      // auto select an availability has no any criteria
      const availabilitiesNoCriteria = this.availabilitiesList.filter(
        item => !(item.criteriaFilters || []).length
      );
      if (availabilitiesNoCriteria.length) {
        this.loadAvailability(
          availabilitiesNoCriteria[0].available.availabilityId
        );
      }
    }
  }

  async addSchedule() {
    let valid = true;
    const selectedTime = this.timeSlots.find(item => item.id == this.timeId);
    let message = "";
    if (!selectedTime) {
      message = "Timeslot is required";
      valid = false;
    }
    if (this.timeSlots.length == 0) {
      message = "Time cannot be scheduled. Please set availability";
    }
    // make sure scheduleDate is not in disabledDates list
    if (this.disabledDates.includes(this.scheduleDate)) {
      message = "Schedule Date is disabled";
      valid = false;
    }

    // number of end users should not over the ability spots
    // if (valid && this.selectedAvailabilityIds.length) {
    //   const availability = this.availabilitiesList.find(
    //     item => item.available.availabilityId == this.selectedAvailabilityIds[0]
    //   );
    //   const eus = availability ? availability.eus || [] : [];
    //   const allEUs = [...new Set([...eus, this.currentItem.USERID])];
    //   if (
    //     availability.available.spots > 0 &&
    //     allEUs.length > availability.available.spots
    //   ) {
    //     valid = false;
    //     notifier.alert(
    //       `Exceeded Spots Available Per Block For This Availability (allow ${
    //         availability.available.spots
    //       })`
    //     );
    //   }
    // }

    if (!valid) {
      ApiHelper.showErrorMessage(message);
      return;
    }

    try {
      this.saving = true;
      const response = await ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "UpdateScheduleATime",
        deploymentId: this.deploymentId,
        availabilityId: this.selectedAvailabilityIds.length
          ? this.selectedAvailabilityIds[0]
          : 0,
        endUserId: this.currentItem.USERID,
        timeInfo: {
          scheduleDate: this.scheduleDate,
          startTime: selectedTime.start,
          endTime: selectedTime.end,
          startTimePeriod: selectedTime.startTimePeriod,
          endTimePeriod: selectedTime.endTimePeriod,
          isOverride: this.isOverride ? 1 : 0
        }
      });

      if (response.STATUS == 1) {
        this.saving = false;
        // update for currentItem
        this.currentItem.SCHEDULEDATE = this.scheduleDate;
        this.currentItem.ISOVERRIDE = this.isOverride ? 1 : 0;
        this.currentItem.STARTTIME = selectedTime.start;
        this.currentItem.STARTTIMEPERIOD = selectedTime.startTimePeriod;
        this.currentItem.ENDTIME = selectedTime.end;
        this.currentItem.ENDTIMEPERIOD = selectedTime.endTimePeriod;

        notifier.success(response.STATUSMESSAGE);
        this.$emit("close");
        this.$emit("reloadData");
      } else {
        this.saving = "error";
      }
    } catch (err) {
      this.saving = "error";
      console.log(err);
    }
  }

  dayChange(selected) {
    const availabilityId = this.selectedAvailabilityIds.length
      ? this.selectedAvailabilityIds[0]
      : 0;
    let selectedTimeSlotsByAvailability = this.selectedTimeSlots || [];
    if (availabilityId) {
      selectedTimeSlotsByAvailability = selectedTimeSlotsByAvailability.filter(
        t => (t.AVAILABILITYID || 0) == availabilityId
      );
    }
    // duration start/ end time from availability
    if (this.available.duration) {
      for (const item of this.timeSlots) {
        item.picked = false;
        // check in selected time slots
        const inList = selectedTimeSlotsByAvailability.filter(
          t =>
            t.SELECTEDTEXT.toUpperCase() == item.text.toUpperCase() &&
            new Date(selected) >= new Date(t.STARTDATE) &&
            new Date(selected) <= new Date(t.ENDDATE)
        );
        if (inList.length) {
          // need to check if has spots setting
          // if no spots setting, or selected time slots number is over the spots setting
          const spots = parseInt(this.available.spots || 0);
          if (!spots || (spots && inList.length >= spots)) {
            item.picked = true;
          }
        }

        // check exception time slots
        if (!item.picked && this.exceptions.length) {
          const timeFormat = "hh:mm A";
          const startTime = moment(
            `${item.start} ${item.startTimePeriod}`,
            timeFormat
          );
          const endTime = moment(
            `${item.end} ${item.endTimePeriod}`,
            timeFormat
          );

          for (const t of this.exceptions) {
            const eStartDate = t.start || "";
            let eEndDate = t.end || "";
            if (eStartDate && !eEndDate) {
              // set exception in a day (just using startDate)
              eEndDate = eStartDate;
            }
            if (
              !(
                new Date(selected) >= new Date(eStartDate) &&
                new Date(selected) <= new Date(eEndDate)
              )
            ) {
              // ignore if not in exception date range
              continue;
            }

            if (t.betweenTimes.start == "" && t.betweenTimes.end == "") {
              // ignore if exception has not a time range
              continue;
            }

            const eStartTime = moment(
              `${t.betweenTimes.startFull.toUpperCase()}`,
              timeFormat
            );
            const eEndTime = moment(
              `${t.betweenTimes.endFull.toUpperCase()}`,
              timeFormat
            );

            if (!eStartTime.isValid() || !eEndTime.isValid()) {
              continue;
            }

            // disable picking if time slot is in exception time range
            // case time slot is in exception time range (eStartTime <= startTime <= endTime <= eEndTime)
            if (
              startTime.isSameOrAfter(eStartTime) &&
              startTime.isSameOrBefore(eEndTime) &&
              endTime.isSameOrAfter(eStartTime) &&
              endTime.isSameOrBefore(eEndTime)
            ) {
              item.picked = true;
              break;
            }
            // case exception time range is in time slot range (startTime <= eStartTime <= eEndTime <= endTime)
            if (
              !item.picked &&
              (eStartTime.isSameOrAfter(startTime) &&
                eStartTime.isSameOrBefore(endTime)) &&
              (eEndTime.isSameOrAfter(startTime) &&
                eEndTime.isSameOrBefore(endTime))
            ) {
              item.picked = true;
              break;
            }
            // case exception time (start or end) is in time slot range
            // startTime < eStartTime < endTime, or startTime < eEndTime < endTime
            if (
              !item.picked &&
              ((eStartTime.isAfter(startTime) &&
                eStartTime.isBefore(endTime)) ||
                (eEndTime.isAfter(startTime) && eEndTime.isBefore(endTime)))
            ) {
              item.picked = true;
              break;
            }
          }
        }
      }
    }
  }

  overrideChange() {
    if (!this.isOverride && this.timeId) {
      const selected = this.timeSlots.find(item => item.id == this.timeId);
      if (selected && selected.picked) {
        // if picked for other user, reset the selection
        this.timeId = "";
      }
    }
    this.updateDatePicker();
  }

  datePickerFilter(date, view) {
    if (view == "day") {
      const datePicker: any = this.$refs.datePicker;
      const dateFormatted = datePicker.formatDate(date);
      let valid = true;

      // set available days based on "days of week" availability settings
      if ((this.available.daysOfWeek || []).length) {
        const dayMap = {
          "0": "sun",
          "1": "mon",
          "2": "tue",
          "3": "wed",
          "4": "thu",
          "5": "fri",
          "6": "sat"
        };
        if (!this.available.daysOfWeek.includes(dayMap[`${date.getDay()}`])) {
          this.disabledDates.push(dateFormatted);
          valid = false;
        }
      }
      if (!valid) {
        // disable selection this day
        return false;
      }

      // disable selection based on exception settings of availability
      if (this.exceptions.length) {
        for (const item of this.exceptions) {
          if (
            (item.betweenTimes.start && !item.betweenTimes.end) ||
            (item.betweenTimes.end && !item.betweenTimes.start)
          ) {
            // ignore case just set a start, or end time
            continue;
          }

          // disable selecting this day if availability time is between this exception time range
          if (
            this.available.duration &&
            item.betweenTimes.start &&
            item.betweenTimes.end
          ) {
            const timeFormat = "hh:mm A";
            const eStartTime = moment(
              `${item.betweenTimes.startFull}`.toUpperCase(),
              timeFormat
            );
            const eEndTime = moment(
              `${item.betweenTimes.endFull}`.toUpperCase(),
              timeFormat
            );
            const availableStartTime = moment(
              `${this.available.duration.startTimeFull}`.toUpperCase(),
              timeFormat
            );
            const availableEndTime = moment(
              `${this.available.duration.endTimeFull}`.toUpperCase(),
              timeFormat
            );

            if (
              eStartTime.isValid() &&
              eEndTime.isValid() &&
              availableStartTime.isValid() &&
              availableEndTime.isValid() &&
              availableStartTime.isSameOrAfter(eStartTime) &&
              availableStartTime.isSameOrBefore(eEndTime) &&
              availableEndTime.isSameOrAfter(eStartTime) &&
              availableEndTime.isSameOrBefore(eEndTime)
            ) {
              // check if need to disable this day at next step
            } else {
              // allow selecting this day
              continue;
            }
          }

          // if has a date range exception
          const startDate = item.start || "";
          let endDate = item.end || "";
          if (startDate && !endDate) {
            // set exception in a day (just using startDate)
            endDate = startDate;
          }
          if (
            new Date(dateFormatted) >= new Date(startDate) &&
            new Date(dateFormatted) <= new Date(endDate)
          ) {
            this.disabledDates.push(dateFormatted);
            valid = false;
            break;
          }
        }
        if (!valid) {
          // disable selection this day
          return false;
        }
      }

      // disable selection based on blockTimeGroups if turn off "override" option
      if (!this.isOverride) {
        const availabilityId = this.selectedAvailabilityIds.length
          ? this.selectedAvailabilityIds[0]
          : 0;
        let blockTimeGroupsByAvailability = this.blockTimeGroups;
        if (availabilityId) {
          blockTimeGroupsByAvailability = this.blockTimeGroups.filter(
            t => (t.AVAILABILITYID || 0) == availabilityId
          );
        }
        for (const item of blockTimeGroupsByAvailability) {
          if (!item.STARTDATE || !item.ENDDATE) {
            continue;
          }
          if (
            new Date(dateFormatted) >= new Date(item.STARTDATE) &&
            new Date(dateFormatted) <= new Date(item.ENDDATE)
          ) {
            valid = false;
            this.disabledDates.push(dateFormatted);
            break;
          }
        }
        if (!valid) {
          // disable selection this day
          return false;
        }
      }
    }
  }

  updateDatePicker() {
    const datePicker: any = this.$refs.datePicker;
    datePicker.destroy();
    this.disabledDates = [];
    if (this.scheduleDate) {
      this.initDate = this.scheduleDate;
    }
    this.datePickerKey = uuidv4();
    this.$nextTick().then(() => {
      // unselect time slot if the selected date in disabled dates list
      if (this.scheduleDate && this.disabledDates.includes(this.scheduleDate)) {
        this.scheduleDate = "";
        this.timeId = "";
      }
      if (
        !this.scheduleDate &&
        this.initDate &&
        !this.disabledDates.includes(this.initDate)
      ) {
        this.scheduleDate = this.initDate;
      }
    });
  }

  getAvailabilityInfoStr(item) {
    let ret: string[] = [];
    // start/ end date
    ret.push(this.getAvailableDates(item));
    // days of week
    if (item.available.daysOfWeek.length) {
      ret.push(item.available.daysOfWeek.join(", "));
    }
    // start/ end time
    ret.push(this.getAvailableTimes(item));

    return ret
      .filter(t => t != "")
      .join(" | ")
      .toUpperCase();
  }

  getAvailableDates(item) {
    const ret: any = [];
    if (item.available.start && moment(item.available.start).isValid()) {
      ret.push(moment(item.available.start).format("MM/DD/YY"));
    }
    if (item.available.end && moment(item.available.end).isValid()) {
      ret.push(moment(item.available.end).format("MM/DD/YY"));
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  getAvailableTimes(item: any) {
    const ret: any = [];
    const duration: any = item.available.duration;
    if (duration.startTime && duration.startTimePeriod) {
      ret.push(`${duration.startTime} ${duration.startTimePeriod}`);
    }
    if (duration.endTime && duration.endTimePeriod) {
      ret.push(`${duration.endTime} ${duration.endTimePeriod}`);
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  resetCurrentAvailability() {
    this.available = {};
    this.exceptions = [];
    this.timeSlots = [];
    this.scheduleDate = "";
    this.isOverride = false;
    this.initDate = "";
    this.timeId = "";
  }

  loadAvailability(id) {
    if (!id) return;

    const inList = this.availabilitiesList.find(
      item => item.available.availabilityId == id
    );
    if (!inList) return;

    // reset
    this.resetCurrentAvailability();

    this.selectedAvailabilityIds = [id];
    this.available = inList.available || {};
    this.exceptions = inList.exceptions || [];
    // this.blockTimeGroups = availabilityRes.blockTimeGroups || [];
    // this.selectedTimeSlots = (availabilityRes.selectedTimeSlots || []).filter(
    //   item => (item.ENDUSERID || 0) != this.currentItem.USERID
    // );
    if (this.available.duration) {
      this.timeSlots = (this.available.duration.timeSlots || []).map(item => ({
        id: uuidv4(),
        picked: false,
        ...item
      }));
    }

    // specify init dates
    const startDate = this.available.start || "";
    if (startDate) {
      this.initDate = startDate;
    }

    // select time slots if was saved before
    if (this.timeInfo && (this.timeInfo.AVAILABILITYID || 0) == id) {
      this.scheduleDate = this.timeInfo.SCHEDULEDATE;
      this.isOverride = this.timeInfo.ISOVERRIDE || 0 ? true : false;
      this.initDate = this.scheduleDate;

      const selectedArr: any = [];
      const startFull = `${this.timeInfo.STARTTIME} ${
        this.timeInfo.STARTTIMEPERIOD
      }`;
      const endFull = `${this.timeInfo.ENDTIME} ${this.timeInfo.ENDTIMEPERIOD}`;
      if (startFull) {
        selectedArr.push(startFull);
      }
      if (endFull) {
        selectedArr.push(endFull);
      }
      const selectedText = selectedArr.join(" - ");
      const inList = this.timeSlots.find(
        item => item.text.toUpperCase() == selectedText.toUpperCase()
      );
      if (inList) {
        this.timeId = inList.id;
      }
    }

    if (this.initDate) {
      this.scheduleDate = this.initDate;
    }
    if (this.scheduleDate) {
      this.dayChange(this.scheduleDate);
    }

    const datePicker: any = this.$refs.datePicker;
    datePicker.destroy();
    this.datePickerKey = uuidv4();
  }

  // isLockForm() {
  //   return (
  //     (!this.availabilitiesList.length ||
  //       !this.availabilitiesList.find(item => !item.disabled)) &&
  //     !this.loading
  //   );
  // }

  isLockedSchedule(availabilityId = 0) {
    let ret = false;
    const lockedSchedule = this.deploymentParams.lockedSchedule || 0;
    const lockSelect = this.deploymentParams.lockSelect || 0;
    const lockAvailability =
      (this.deploymentParams.lockAvailability || "") != ""
        ? this.deploymentParams.lockAvailability.split(",")
        : [];
    const lockOnDate = this.deploymentParams.lockOnDate || 0;
    const lockDate = this.deploymentParams.lockDate || "";
    if (
      lockedSchedule &&
      (!lockSelect || lockAvailability.includes(`${availabilityId}`))
    ) {
      // set lock, need to check if lock now or on a specific date onward
      if (
        !lockOnDate ||
        (lockDate &&
          moment(lockDate).isValid() &&
          new Date(moment().format("MM/DD/YYYY")) >=
            new Date(moment(lockDate).format("MM/DD/YYYY")))
      ) {
        // detect locked schedule
        ret = true;
      }
    }

    return ret;
  }
}
