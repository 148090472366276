



























import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

interface Events {
  dateUpdate: { date };
}

@Component({
  inheritAttrs: true,
  components: {
    DateRangePicker
  }
})
export default class CustomInputDate extends TSXComponent<void> {
  @Prop({ required: true })
  sdate!: string;

  @Prop({ required: true })
  edate!: string;
  dateRange = {
    startDate: this.sdate,
    endDate: this.edate
  };
  $refs!: {
    picker:DateRangePicker
  }

  updateValues(e) {
    // console.log("updateValues", e);
    this.$emit("dateUpdate", e);
  }
  checkOpen() {
    this.dateRange = {
      startDate: this.sdate,
      endDate: this.edate
    };
    $('#page-header').click()
    this.$refs.picker.monthDate = this.sdate;
    // this.$refs.picker.nextMonthDate = this.edate;
    // console.log("event",this.$refs.picker.monthDate,this.$refs.picker.nextMonthDate);
  }
}
