
























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {}
})
export default class HardwareImportSelect extends TSXComponent<void> {
	selectedID:number[] = [];
	excelData:object[] = [];
	headers:string[] = [];
	allHeaders:string[] = [];

  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      if (this.allHeaders.indexOf(this.headers[i]) != -1)
        this.selectedID.push(
          this.allHeaders.indexOf(this.headers[i])
        );
    }
  }
  @Watch("allHeaders", { deep: true })
  handler(to, from) {
    for (var i = 0; i < this.allHeaders.length; i++) {
      this.selectedID.push(i);
    }
  }

  @Watch("selectedID", { deep: true })
  idSelect(to, from) {
    this.headers = [];
    for (var i = 0; i < this.allHeaders.length; i++) {
      if (to.indexOf(i) != -1) this.headers.push(this.allHeaders[i]);
    }
  }
}
