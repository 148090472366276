var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<!DOCTYPE html>\n<html>\n  <head>\n    <link href=\"https://var360.s3.amazonaws.com/public/css/export.css\" rel=\"stylesheet\">\n    <link href=\"https://fonts.googleapis.com/css?family=Work+Sans&display=swap\" rel=\"stylesheet\">\n  </head>\n  <body>\n    <div id=\"container\">\n"
    + ((stack1 = container.invokePartial(require("./partials/reportList_header.handlebars"),depth0,{"name":"reportList_header","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      \n"
    + ((stack1 = container.invokePartial(require("./partials/reportList_items.handlebars"),depth0,{"name":"reportList_items","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/reportList_summary.handlebars"),depth0,{"name":"reportList_summary","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    </div>\n  </body>\n</html>\n";
},"usePartial":true,"useData":true});