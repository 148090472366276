

















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import axios from "axios";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import directives from "../helpers/directives";
import UserGenerateModal from "../components/UserGenerateModal.vue";
import CustomAlertModal from "@/components/Alert/CustomAlertModal.vue";
import { v4 as uuidv4 } from "uuid";
import UserStatus, {UserStatusValue} from "../components/UserStatus.vue";

interface Props {
  alias: string;
  accname: string;
  accid: string;
  aid: number;
  active: boolean;
  createdPortal: boolean;
  usersList: object[];
}
interface Events {
  initialLoad: { index };
}
@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    UserGenerateModal,
    UserStatus,
    CustomAlertModal
  },
  methods: {
    structuredClone(obj) {
      return window.structuredClone(obj)
    }
  },
  directives
})
export default class CustomerDetailsTabAlerts extends TSXComponent<Props> {
  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: true })
  logindetails!: any;

  @Prop({ required: true })
  loginstatus!: number;

  @Prop({ required: true })
  show!: number;

  @Prop({ required: true })
  accid!: string;

  @Prop({ required: true })
  domains!: object[];

  @Prop({ required: false, default: false })
  createdPortal!: boolean;

  @Prop({ required: false, default: [] })
  usersList!: object[];

  @Prop({ required: false, default: [] })
  autoAlerts!: any[];

  @Prop({ required: true, default: 0 })
  aid!: number;

  @Prop({ required: false, default: 1 })
  noMultiReseller!: number;

  saving: boolean | string = false;
  selectedSiteName: any = 0;
  currentSiteName = "";
  siteNameList: object[] = [];
  isUpdate = false;
  portal: any = {
    currentUserID: 0,
    currentAcctName: "",
    accName: "",
    siteName: "",
    cName: "",
    emailAddress: "",
    usedDefault: 0
  };
  portalID = 0;
  isDefault = false;
  userGenerateModalVisible = false;
  apiKey = "";
  smartstoreAPIKey = "";
  savingParams = false;
  originalFormData: any = {};
  isEdited = false;
  isInitial = true;
  priceType = 0;
  autoAlertModalVisible = false;
  openAutoAlertId = 0;
  orderStatusOption = [
    {
      ID: 1,
      TEXT: "Placed"
    },
    {
      ID: 2,
      TEXT: "Received"
    },
    {
      ID: 3,
      TEXT: "Shipped"
    },
    {
      ID: 4,
      TEXT: "Delivered"
    },
    {
      ID: 6,
      TEXT: "Production"
    }
  ];
  invoiceStatusOptions = [
    {
      ID: 0,
      TEXT: "Sent"
    },
    {
      ID: 1,
      TEXT: "Paid"
    },
    {
      ID: 2,
      TEXT: "Created"
    },
    {
      ID: 3,
      TEXT: "Partial Pay"
    }
  ];
  quotesStatusOption = [
    {
      ID: 1,
      TEXT: "Open"
    },
    {
      ID: 2,
      TEXT: "Closed"
    },
    {
      ID: 5,
      TEXT: "Purchased"
    },
    {
      ID: 6,
      TEXT: "Requested"
    },
    {
      ID: 7,
      TEXT: "Assigned"
    },
    {
      ID: 8,
      TEXT: "Purchase Pending"
    }
  ];

  createPortal() {
    // var result=true;
    $("#advancedDet input.initial.error").removeClass("initial");

    //remove HTML
    if (typeof this.logindetails["ACCOUNTNAME"] != "undefined") {
      this.logindetails["ACCOUNTNAME"] = this.removeHTML(
        this.logindetails["ACCOUNTNAME"]
      );
    }
    if (typeof this.logindetails["UFNAME"] != "undefined") {
      this.logindetails["UFNAME"] = this.removeHTML(
        this.logindetails["UFNAME"]
      );
    }

    if (!this.isEdited) {
      return;
    }

    this.$validator.validateAll().then(async result => {
      // if (this.$validator.errors.has(`emailAddress`)) {
      //   result = false;
      // } else {
      //   result = true;
      // }

      // if ($("#CompanyName").val() == "") {
      //   $(".errorMsg.CompanyName").text(
      //     "The Customer Company Name field is required"
      //   );
      //   result = false;
      // } else {
      //   $(".errorMsg.CompanyName").text("");
      // }
      // if ($("#subDomain").val() == "") {
      //   $(".errorMsg.subDomain").text("The site Name field is required");
      //   result = false;
      // } else {
      //   $(".errorMsg.subDomain").text("");
      // }
      // if ($("#emailAddress").val() == "") {
      //   $(".errorMsg.emailAddress").text("The email Address field is required");
      //   result = false;
      // } else {
      //   $(".errorMsg.emailAddress").text("");
      // }

      // if(!this.createdPortal) {//validate these when create
      //   if ($("#contactName").val() == "") {
      //     $(".errorMsg.contactName").text("The contact Name field is required");
      //     result = false;
      //   } else {
      //     $(".errorMsg.contactName").text("");
      //   }
      //   if ($("#emailAddress").val() != "") {
      //     if (
      //       !$("#emailAddress")
      //         .val()
      //         .match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}")
      //     ) {
      //       $(".errorMsg.emailAddress").text(
      //         "The email field must be a valid email"
      //       );
      //       result = false;
      //     }
      //   }
      // }
      //var self = this;
      if (result) {
        this.$validator.errors.clear();
        this.saving = true;
        try {
          var dataObj = {
            controller: "CustomerPortals",
            FunctionName: "CreatePortal",
            accName: this.logindetails["ACCOUNTNAME"], //$("#CompanyName").val(),
            emailAddress: this.logindetails["UEMAIL"],
            domain: this.getFullDomain(), //$("#subDomain").val() + "." + $("#domain").val(),
            cName: this.logindetails["UFNAME"],
            externalKey: this.logindetails["EXTERNALKEY"],
            aID: this.aid,
            siteName: this.portal.siteName.length
              ? this.portal.siteName
              : this.logindetails["ACCOUNTNAME"].replace(/[^a-zA-Z0-9]/gi, ""),
            multiAccts: false,
            isUpdate: this.isUpdate,
            accountID: this.portalID,
            apiKey: this.apiKey,
            noMultiReseller: this.noMultiReseller ? 1 : 0

            //aId: this.accid,
            // address: $("#aAddress").val(),
            // city: $("#aCity").val(),
            // state: $("#aState").val(),
            // zip: $("#aZip").val(),
          };

          // if (this.loginstatus === 1 || this.loginstatus === 3) {
          //   dataObj["FunctionName"] = "updateCustomer";
          // }

          const response = await axios.post(
            dataURL + "?ReturnType=JSON",
            dataObj
          );
          if (response.data.ERROR) {
            throw new Error(response.data.ERROR);
          }

          //self.saving = false;
          if (response.data.STATUS == 1) {
            this.saving = false;
            this.isUpdate = true;
            if (typeof response.data.NEWACCOUNTID != "undefined") {
              this.portalID = response.data.NEWACCOUNTID;
            }
            this.$emit("initialLoad", 5);
            notifier.success(response.data.STATUSMSG);
            this.originalFormData = { ...this.logindetails };
            this.isEdited = false;

            // this.logindetails = [];
            // this.loginstatus = response.data.STATUS;
            // this.logindetails = {
            //   ACCOUNTNAME: response.data.ACCNAME,
            //   UEMAIL: response.data.EMAIL,
            //   UFNAME: response.data.CNAME,
            //   DOMAIN: response.data.DOMAIN,
            //   DOMAINNAME: response.data.DOMAINNAME
            // };
          } else {
            // notifier.alert(response.data.STATUSMSG);
            // this.saving = "error";
            // this.loginstatus = response.data.STATUS;
            // this.logindetails = {
            //   ACCOUNTNAME: response.data.ACCNAME,
            //   UEMAIL: response.data.EMAIL,
            //   UFNAME: response.data.CNAME,
            //   DOMAIN: response.data.DOMAIN,
            //   DOMAINNAME: response.data.DOMAINNAME
            // };
            this.saving = "error";
            var tmpField = "";
            if (typeof response.data.EMAILEXISTED != "undefined") {
              tmpField = "emailAddress";
            }
            if (typeof response.data.SITENAMEEXISTED != "undefined") {
              tmpField = "siteName";
            }
            if (typeof response.data.COMPANYNAMEEEXISTED != "undefined") {
              tmpField = "companyName";
            }
            if (typeof response.data.EXTERNALKEYEXISTED != "undefined") {
              tmpField = "externalKey";
            }
            if (tmpField != "") {
              this.$validator.errors.add({
                field: tmpField,
                msg: response.data.STATUSMSG
              });
            }
          }
        } catch (err) {
          this.saving = "error";
        }
      } else {
        this.isEdited = false;
      }
    });
  }

  getFullDomain() {
    if (this.portal.siteName.trim() != "") {
      return (
        this.portal.siteName.replace(/\s/g, "") +
        "." +
        this.logindetails["DOMAIN"]
      );
    }
    return (
      this.logindetails["ACCOUNTNAME"].replace(/[^a-zA-Z0-9]/gi, "") +
      "." +
      this.logindetails["DOMAIN"]
    );
  }

  buildSiteNameList(portalInfo: any = {}) {
    this.siteNameList = [
      {
        label: "Default (customer)",
        value: 0
      }
    ];
    if (
      typeof portalInfo.DEFAULTDOMAIN != "undefined" &&
      portalInfo.DEFAULTDOMAIN == 0
    ) {
      let siteName = portalInfo.FULLDOMAIN.split(".")[0];
      this.selectedSiteName = siteName;
      this.siteNameList.push({
        label: siteName,
        value: siteName
      });
    }
    this.siteNameList.push({
      label: "OR...",
      value: 1
    });
  }

  selectedSiteNameChange() {
    if (this.selectedSiteName == 0) {
      //default
      this.portal.siteName = "";
    } else if (this.selectedSiteName == 1) {
      if (this.portal.usedDefault) {
        this.portal.siteName = "";
      } else {
        this.portal.siteName = this.currentSiteName;
      }
      //clear validate error for site name
      let tmpInd = this.$validator.errors.items.findIndex(
        val => val.field == "siteName"
      );
      this.$validator.errors.items.splice(tmpInd, 1);
      setTimeout(() => {
        $("#siteName")
          .focus()
          .select();
      }, 200);
    } else if (this.selectedSiteName != 1 && this.selectedSiteName != 0) {
      this.portal.siteName = this.currentSiteName;
    }
    if (this.originalFormData.DOMAINNAME != this.selectedSiteName) {
      this.isEdited = true;
    } else {
      this.isEdited = false;
    }
  }

  // @Watch("logindetails")
  // async logindetailsChanged() {
  created() {
    if (typeof this.logindetails["ACCOUNTID"] != "undefined") {
      this.portal.DEFAULTDOMAIN = this.logindetails["DEFAULTDOMAIN"];
      this.portal.FULLDOMAIN = this.logindetails["FULLDOMAIN"];
      this.portal.usedDefault = this.portal.DEFAULTDOMAIN;
      this.currentSiteName = this.portal.FULLDOMAIN.substr(
        0,
        this.portal.FULLDOMAIN.indexOf(".")
      );
      this.portal.siteName = this.currentSiteName;
      this.logindetails["DOMAIN"] = this.portal.FULLDOMAIN.substr(
        this.portal.FULLDOMAIN.indexOf(".") + 1
      );
      if (this.loginstatus === 1 || this.loginstatus === 3) {
        this.isUpdate = true;
      }
      this.portalID = this.logindetails["ACCOUNTID"];
      this.isDefault = this.logindetails["ISDEFAULT"];
    }
    this.smartstoreAPIKey = this.logindetails.SMARTSTOREAPIKEY || "";
    this.apiKey = this.logindetails.APIKEY || "";
    this.priceType = this.logindetails.PRICETYPE || 0;
    this.buildSiteNameList(this.portal);
  }

  @Watch("logindetails", { deep: true })
  async isFormChanged() {
    if (this.isInitial) {
      this.originalFormData = { ...this.logindetails };
      this.isInitial = false;
    }
    this.isEdited = Object.keys(this.logindetails).some(
      key => this.logindetails[key] !== this.originalFormData[key]
    );
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  async userAdd(userVals) {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "CustomerPortals",
        FunctionName: "userAdder",
        emailAddress: userVals.uEmail,
        firstName: userVals.uFName,
        lastName: userVals.uLName,
        userState: userVals.userState,
        address: userVals.uAddress,
        city: userVals.uCity,
        Phone: userVals.uPhone,
        PhoneExt: userVals.uPhoneExt,
        zip: userVals.uZIP,
        accountID: this.portalID,
        aID: this.aid,
        defaultAID: this.isDefault ? this.aid : 0
      });
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
        this.$emit("initialLoad", 5);
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.userGenerateModalVisible = false;
    }
  }

  addAutoAlert(data) {
    if (data.PARAMS) {
      if (data.ALERTID && data.ISUPDATE) {
        let alertIdx = this.autoAlerts.findIndex(item => item.alertId && item.alertId == data.ALERTID);
        if (alertIdx >= 0 ) {
          this.autoAlerts[alertIdx] = data.PARAMS;
        } else {
          this.autoAlerts.push(data.PARAMS);
        }
      } else {
        this.autoAlerts.push(data.PARAMS);
      }
    }
  }

  openAutoAlert(alertId) {
    this.openAutoAlertId = alertId;
    this.autoAlertModalVisible = true;
  }

  async removeAutoAlert(alertId) {
    if (this.savingParams) return;
    try {
      this.savingParams = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Customers",
        FunctionName: "UpdateAccountParams",
        aID: this.aid,
        action: "removeAutoAlert",
        alertId
      });
      if (response.data.STATUS == 1) {
        const deletedAlertIndex = this.autoAlerts.findIndex((alert) => alert.alertId === alertId);
        if (deletedAlertIndex >= 0) {
          this.autoAlerts.splice(deletedAlertIndex , 1);
        }
  
        this.$forceUpdate();
        notifier.success(response.data.STATUSMSG);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.savingParams = false;
    }
  }

  async generateApiKey() {
    this.apiKey = uuidv4();
    // force saving this data point
    try {
      var dataObj = {
        controller: "Customers",
        FunctionName: "UpdateAccountParams",
        aID: this.aid,
        apiKey: this.apiKey,
        action: "saveAPIKey"
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
    } catch (err) {
      console.log(err);
    }
  }

  multiResellerChange() {
    this.noMultiReseller = this.noMultiReseller ? 0 : 1;
    if (this.originalFormData.NOMULTIRESELLER != this.noMultiReseller) {
      this.isEdited = true;
    } else {
      this.isEdited = false;
    }
  }

  async saveSmartstoreAPIKey() {
    if (this.savingParams) return;
    try {
      this.savingParams = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Customers",
        FunctionName: "UpdateAccountParams",
        aID: this.aid,
        action: "saveSmartstoreAPIKey",
        smartstoreAPIKey: this.smartstoreAPIKey
      });
      if (response.data.STATUS == 1) {
        this.logindetails.SMARTSTOREAPIKEY = this.smartstoreAPIKey;
        this.apiKey = this.smartstoreAPIKey;
        this.$forceUpdate();
        notifier.success(response.data.STATUSMSG);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.savingParams = false;
    }
  }

  async priceTypeChange() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      Controller: "Customers",
      FunctionName: "UpdateAccountParams",
      aID: this.aid,
      action: "savePriceType",
      priceType: this.priceType
    });
    if (response.data.STATUS == 1) {
      // this.logindetails.SMARTSTOREAPIKEY = this.smartstoreAPIKey;
      // this.apiKey = this.smartstoreAPIKey;
      // this.$forceUpdate();
      notifier.success(response.data.STATUSMSG);
    }
  }

  async changeStatus(userid, status) {
    try {
      await axios.post(dataURL + "?ReturnType=JSON", {
          "FunctionName"  : "Update",
          "controller"    : "VARUsers",
          "subsystem"     : "VAR360",
          "userId"        : userid,
          "active"        : status
      })
      const matchingUser: any = this.usersList.find((user: any) => user.USERID === userid);
      matchingUser.ACTIVE = status;
    } catch (err) {
      // notifier.alert(err.message)
      console.log(err);
    }
  }
}
