var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.ENDMESSAGE : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        Thank you for your business! \n    ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <tr>\n    <td width=\"37%\">&nbsp;</td>\n    <td style=\"text-align: right; color: #B1B1B1;\" colspan=\"3\">Tax"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.taxRateMessage : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":66},"end":{"line":15,"column":116}}})) != null ? stack1 : "")
    + ":</td>\n    <td width=\"20%\" style=\"text-align: right;font-weight: normal;\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.CUSTOMERTAXFORMATTED : stack1), depth0))
    + "</td>\n  </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return " ("
    + container.escapeExpression(((helper = (helper = helpers.taxRateMessage || (depth0 != null ? depth0.taxRateMessage : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"taxRateMessage","hash":{},"data":data,"loc":{"start":{"line":15,"column":90},"end":{"line":15,"column":108}}}) : helper)))
    + ")";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <tr>\n    <td width=\"37%\">&nbsp;</td>\n    <td style=\"text-align: right; color: #B1B1B1;\" colspan=\"3\">Shipping:</td>\n    <td width=\"20%\" style=\"text-align: right;font-weight: normal;\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.ISHIPPINGFORMATTED : stack1), depth0))
    + "</td>\n  </tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "  <td width=\"75%\" style=\"border-top: 5px solid #ffffff;\"></td>\n  <tr>\n    <td width=\"37%\">&nbsp;</td>\n    <td style=\"text-align: right; color: #B1B1B1;\" colspan=\"3\">Amount Paid:</td>\n    <td width=\"20%\" style=\"text-align: right;font-weight: normal;\">"
    + alias4(((helper = (helper = helpers.INVOICEPAIDAMOUNT || (depth0 != null ? depth0.INVOICEPAIDAMOUNT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INVOICEPAIDAMOUNT","hash":{},"data":data,"loc":{"start":{"line":36,"column":67},"end":{"line":36,"column":88}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <td width=\"37%\">&nbsp;</td>\n    <td style=\"text-align: right; color: #B1B1B1;\" colspan=\"3\">Payment Date:</td>\n    <td width=\"20%\" style=\"text-align: right;font-weight: normal;\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? depth0.relatedOrder : depth0)) != null ? stack1.confirmedPaymentInfo : stack1)) != null ? stack1.PAYMENTDATE : stack1), depth0))
    + "</td>\n  </tr>\n  <tr>\n    <td width=\"37%\">&nbsp;</td>\n    <td style=\"text-align: right; color: #B1B1B1;\" colspan=\"3\">Payment Method:</td>\n    <td width=\"20%\" style=\"text-align: right;font-weight: normal;\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? depth0.relatedOrder : depth0)) != null ? stack1.confirmedPaymentInfo : stack1)) != null ? stack1.PAYMENTTYPE : stack1), depth0))
    + "</td>\n  </tr>\n  <tr>\n    <td width=\"37%\">&nbsp;</td>\n    <td style=\"text-align: right; color: #B1B1B1;\" colspan=\"3\">Balance Due:</td>\n    <td width=\"20%\" style=\"text-align: right;font-weight: normal;\"><strong>"
    + alias4(((helper = (helper = helpers.INVOICEDUEBALANCE || (depth0 != null ? depth0.INVOICEDUEBALANCE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INVOICEDUEBALANCE","hash":{},"data":data,"loc":{"start":{"line":51,"column":75},"end":{"line":51,"column":96}}}) : helper)))
    + "</strong></td>\n  </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<tr>\n  <td width=\"37%\" style=\"border-top: 5px solid #b1b1b1;\">\n    <!-- "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.ENDMESSAGE : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":9},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + " -->\n  </td>\n  <td style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\" colspan=\"3\">Customer Subtotal:</td>\n  <td width=\"20%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;font-weight: normal;\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.ITOTALPRICEFORMATTED : stack1), depth0))
    + "</td>\n</tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.CUSTOMERTAXFORMATTED : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":18,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.ISHIPPINGFORMATTED : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "")
    + "<tr class=\"title_summary\" style=\"font-weight: bold;\">\n  <td width=\"37%\">&nbsp;</td>\n  <td style=\"text-align: right; color: #B1B1B1;\" colspan=\"3\">Grand Total:</td>\n  <td width=\"20%\" style=\"text-align: right;\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.INVOICEDETAILS : depth0)) != null ? stack1.GRANDTOTALFORMATTED : stack1), depth0))
    + "</td>\n</tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.INVOICEPAID : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":0},"end":{"line":53,"column":7}}})) != null ? stack1 : "");
},"useData":true});