


































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import EmailMultiselect from "./EmailMultiselect.vue";
import LaddaButton from "./LaddaButton.vue";
import { notifier, wait } from "../models/common";
import { dollarFormat } from "@/helpers/ApiHelper";
import directives from "../helpers/directives";

declare const dataURL: string;
declare const $: any;

interface Props {}
@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    EmailMultiselect
  },
  directives,
  methods: {
    dollarFormat
  }
})
export default class InvoiceRefund extends TSXComponent<void> {
  @Prop({ required: true, default: "" })
  invoiceId!: string;

  @Prop({ required: true, default: "" })
  purchaseId!: string;

  @Prop({ required: true, default: 0 })
  aId!: number;

  // @Prop({ required: true, default: "" })
  // paymentConfirmId!: string;

  // @Prop({ required: true, default: 0 })
  // paymentAmount!: number;

  // @Prop({ required: false, default: 0 })
  // paymentRefundedAmount!: number;

  @Prop({ required: false, default: 0 })
  orderStatus!: number;

  @Prop({ required: false, default: [] })
  paymentInfo!: any;

  loading: any = false;
  refunding: any = false;
  $parent: any;
  $validator: any;
  currentRole = sessionStorage.getItem("userRole");
  refundAmount = 0;
  refundAmountError = "";
  paymentConfirmId = "";
  paymentsCanRefund: any = [];

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    // specify transaction list can refund based on paymentInfo
    this.paymentsCanRefund = this.paymentInfo.filter(
      item => (item.PAYMENT_AMOUNT || 0) > (item.REFUNDAMOUNT || 0)
    );
    if (this.paymentsCanRefund.length) {
      this.paymentConfirmId = this.paymentsCanRefund[0].PAYMENT_VENDORCONFIRMATIONID;
    }

    this.refundAmount = this.getCanRefundAmount();
  }

  getCanRefundAmount() {
    const transactionPaid = this.getTransactionTotalPaid();
    const transactionRefunded = this.getTransactionRefundAmount();
    const canRefundAmount = parseFloat(
      (transactionPaid - transactionRefunded).toFixed(2)
    );
    return canRefundAmount;
  }

  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.refundAmountError = "";
    let valid = await this.$validator.validateAll();
    const canRefundMore = this.getCanRefundAmount();
    if (this.refundAmount > canRefundMore) {
      this.refundAmountError = `Refund Amount cannot greater than ${dollarFormat(
        canRefundMore
      )}`;
      valid = false;
    }

    if (valid) {
      this.submit();
    }
  }

  async submit() {
    try {
      this.refunding = true;
      const refundAmount = parseFloat(
        parseFloat(`${this.refundAmount}`).toFixed(2)
      );
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Invoices",
        FunctionName: "InvoiceRefund",
        invoiceId: this.invoiceId,
        purchaseId: this.purchaseId,
        aId: this.aId,
        paymentId: this.paymentConfirmId,
        refundAmount,
        isRefundFull: this.isRefundFull,
        orderStatus: this.orderStatus
      });

      if (response.data.STATUS == 1) {
        this.$parent.getInvoiceDetails();
        notifier.success(response.data.MESSAGE);
        this.refunding = false;
        this.$emit("close");
      } else {
        notifier.alert(response.data.MESSAGE);
        this.refunding = "error";
      }
    } catch (err) {
      console.log(err);
      this.refunding = "error";
    }
  }

  // check if this is a refund, and make the invoice is refunded fully
  get isRefundFull() {
    if (this.paymentsCanRefund.length > 1) return false;

    const refundAmount = parseFloat(
      parseFloat(`${this.refundAmount}`).toFixed(2)
    );

    // total paid/refund
    let totalPaid = this.paymentsCanRefund[0].PAYMENT_AMOUNT || 0;
    let totalRefund = this.paymentsCanRefund[0].REFUNDAMOUNT || 0;
    totalRefund += refundAmount;
    totalRefund = parseFloat(totalRefund.toFixed(2));

    if (totalRefund >= totalPaid) {
      return true;
    }

    return false;
  }

  getPaymentOption(item) {
    let ret = `${item.PAYMENT_VENDORCONFIRMATIONID} (Paid: ${dollarFormat(
      item.PAYMENT_AMOUNT
    )}`;
    if ((item.REFUNDAMOUNT || 0) > 0) {
      ret += `, Refunded: ${dollarFormat(item.REFUNDAMOUNT)}`;
    }
    ret += `)`;

    return ret;
  }

  getTransactionTotalPaid() {
    const inList = this.paymentsCanRefund.find(
      item => item.PAYMENT_VENDORCONFIRMATIONID == this.paymentConfirmId
    );
    if (inList) {
      return inList.PAYMENT_AMOUNT;
    }

    return 0;
  }

  getTransactionRefundAmount() {
    const inList = this.paymentsCanRefund.find(
      item => item.PAYMENT_VENDORCONFIRMATIONID == this.paymentConfirmId
    );
    if (inList) {
      return inList.REFUNDAMOUNT;
    }

    return 0;
  }

  paymentsOnChange() {
    // update refund amount based on selected payment id
    this.refundAmount = this.getCanRefundAmount();
    this.refundAmountError = "";
  }
}
