














































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop,Emit} from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import { notifier } from "../models/common";
import axios from "axios";

interface Props {
    userid: number;
    accountid: number;
    isall: boolean;
    isBottom: boolean;
    commission: any;
    othersPercentage: number
}

// interface Events {
//     close: {
//        reload: data
//     }
// }
@Component({
  inheritAttrs: false,
  components: {
  }
})
export default class editCommissionToolTip extends TSXComponent<void>  {
    $parent: any;
    $root: any;
    isSelectAll = 0;
    errorCount = 0;
    @Prop({ required: true })
    accountid!: number;

    @Prop({ required: true })
    currentpayid!: string;

    @Prop({ required: true })
    userid!: number;

    @Prop({ required: true })
    commission!: any; 

    @Prop({ required: true })
    currcommission!: any;

    @Prop({ required: true })
    paymentids!: string; 

    @Prop({ required: false, default: 0 })
    isAll!: boolean;

    @Prop({ required: false, default: 0 })
    isBottom!: boolean;

    @Prop({ required: false, default: 0 })
    othersPercentage!: 0;

    isApiRunning = false;
    loading = false;
    businessLineId = 0;
    bLineRemainingComm: any = [];
    businessLine: any = [];
    orgBusinessLine: any = [];
    businessLineIdLists = "";
    async created(){
      this.getCommission();
    }
		async commissionSubmit(e,value){
			e.preventDefault();
			e.stopPropagation();
			this.commissionEdit(value)
		}
    async commissionEdit(value, bLineItem:any = {}){
        this.isApiRunning = !this.isApiRunning;
        if(this.isApiRunning){
            if(isNaN(value)){
                this.errorCount = 1;
            }
            else{
                var remainingComm = 100;  
                if (Object.keys(bLineItem).length) {
                  let bLineComm = this.bLineRemainingComm.find((bLine) => bLine.ID == bLineItem.BUSINESSLINEID).REMAININGCOMM;
                  let orgComm = bLineItem.BUSINESSLINEID ? this.orgBusinessLine.find((bLine) => bLine.BUSINESSLINEID == bLineItem.BUSINESSLINEID).PERCENTAGE : 0;
                  remainingComm = bLineComm + orgComm;
                }

                if(value <= 0 || value > 100) {
                  this.errorCount = 1;
                  notifier.alert("Commission should be between 0 to 100");
                }
                else if (this.isSelectAll == 0 && parseFloat(value) > remainingComm) {
                  this.errorCount = 1;
                  notifier.alert(`Commission cannot be exceeds ${remainingComm}`);
                } 
                // else if(this.isSelectAll == 0 && this.commission == this.currcommission["PERCENTAGE"]) {
                //   //do nothing
                // }
                else{
                    this.loading = true;
                    var paymentIdsList = this.currentpayid;
                    if(this.isSelectAll == 1){
                      this.accountid = 0;
                      paymentIdsList = this.paymentids;
                    }
                    //this.$parent.editCommissionShowIndex = -1;

                    var dataNeedUpdate: any = [];
                    if(this.isSelectAll == 0) {//single edit
                      this.businessLine.forEach((bLine:any) => {
                        dataNeedUpdate.push({
                          aID: bLine.AID,
                          newPercent: bLine.PERCENTAGE,
                          businessLineId: bLine.BUSINESSLINEID,
                          linkPaymentId: bLine.LINKPAYMENTID
                        });
                      });
                    }
                    var dataObj = {
											"controller": "Users",
											"FunctionName": "AccountCommission",
											"userID": this.userid,
											"aID": this.accountid,
											"linkPaymentId": paymentIdsList,
                      "percentage": bLineItem.PERCENTAGE,
                      "batchUpdate": true,
                      "updateConfirmedAccts": (this.isSelectAll == 0 ? true : false),//do update in case single select
                      "dataNeedUpdate": dataNeedUpdate,
                      "businessLineId": bLineItem.BUSINESSLINEID
                    };
                    try {
											const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
											if (response.data.STATUS !== 1) {
												if (response.data.ERROR || response.data.STATUSMESSAGE) {
														notifier.alert(response.data.ERROR || response.data.STATUSMESSAGE);
												}
											}
											else{
												if(response.data.EXCEEDEDPERCENTACCOUNTS && response.data.EXCEEDEDPERCENTACCOUNTS.length){
													// console.log('res',response.data.EXCEEDEDPERCENTACCOUNTS)
													this.$parent.commissionInput = value;
													this.$parent.balanceCommissions = response.data.EXCEEDEDPERCENTACCOUNTS;
                          this.$parent.remainingCommissionVisible = true;
                          this.$parent.EXCEEDED = response.data.EXCEEDED;
                          this.$parent.businessLineId = bLineItem.BUSINESSLINEID;
													// if(!this.$parent.isConfirmChecked){
													// 		this.$parent.isConfirmChecked = true;
													// }
												}
												else{
													//notifier.success('Account Commission updated Successfully');
													// this.$parent.modalClose(true);
												}
											}
											this.loading = false;
											this.$parent.editCommissionShowIndex = -1;   
											this.$parent.loadCommission();
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        }
    }

    selectAllText(id) {
      setTimeout(() => {
        $("#" + id).select();  
      }, 200);
    }

  async getCommission() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARUsers",
        FunctionName: "AllCommissions",
        subsystem: "VAR360",
        userID: this.userid,
        aID: this.accountid,
        fromEditCommission: true
      });
      if(response.data.STATUS){
        this.bLineRemainingComm =  response.data.BLINEREMAININGCOMM;
        this.businessLine = response.data.BUSINESSLINE;
        this.orgBusinessLine = JSON.parse(JSON.stringify(response.data.BUSINESSLINE));
        this.businessLineIdLists = response.data.BUSINESSLINEIDLISTS
      }
      else{
        // console.log("error");
      }
      this.loading = false;   
    } catch (error) {
        // console.log(error);
    }
  }

  async removeCommission(bLineComm) {
    var dataObj = {
        "controller": "Users",
        "FunctionName": "AccountCommission",
        "userID": this.userid,
        "linkPaymentId": bLineComm.LINKPAYMENTID,
        "updateConfirmedAccts": (this.isSelectAll == 0 ? true : false),//do update in case single select
        "batchUpdate": true,
        "delete": true
      };
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      if (response.data.STATUS !== 1) {
        if (response.data.ERROR || response.data.STATUSMESSAGE) {
          notifier.alert(response.data.ERROR || response.data.STATUSMESSAGE);
        }
      }
      else{
        notifier.success(response.data.STATUSMESSAGE);
      }
      this.loading = false;
			this.$parent.loadCommission();
      this.getCommission();
    } catch (error) {
        // console.log(error);
    }
  }
}
