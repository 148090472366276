import Vue from 'vue'
import Vuex from 'vuex'
import { ApiHelper } from "@/helpers/all";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alerts: 0,
    logs: 0,
    status: 0,
    totalAlerts: 0,
    totalLogs: 0,
    totalStatus: 0,
    newAlerts: 0,
    sourcesObj: 0,
  },
  mutations: {
    setAlerts(state, alerts) {
      state.alerts = alerts;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setLogs(state, logs) {
      state.logs = logs;
    },
    setTotalAlerts(state, totalAlerts) {
      state.totalAlerts = totalAlerts;
    },
    setTotalLogs(state, totalLogs) {
      state.totalLogs = totalLogs;
    },
    setTotalStatus(state, totalStatus) {
      state.totalStatus = totalStatus;
    },
    setNewAlerts(state, newAlerts) {
      state.newAlerts = newAlerts;
    },
    setSourcesObj(state, sourcesObj) {
      state.sourcesObj = sourcesObj;
    },
  },
  actions: {
    async getAlertCount({ commit }) {
      try {
        const response = await ApiHelper.callApi('post', {
          controller: 'Queries',
          FunctionName: 'alertsQuery',
          subsystem: 'Helpers',
          action: 'getAlertCount',
          timeframe: '',
          type: 'alerts'
        });

        if (response.STATUS == 1) {
          commit('setTotalAlerts', response.TOTALALERTS);
          commit('setNewAlerts', response.NEWESTALERTS);
        }

        return response;
      } catch (err) {
        // Handle errors as needed
        console.error(err);
      }
    },
    async getFooterData({ commit }, { type, data, timeframe, page }) {
      const thisData: any = data;
      let dataObj = {
        controller: "Queries",
        subsystem: "Helpers",
        FunctionName: "alertsQuery",
        action: 'getAlerts',
        type: type,
        pageNumber: page
      };
      dataObj["type"] = thisData.activeTab;
      if (thisData.activeTab === "alerts") {
        dataObj["alertId"] = thisData.searchFilters.Id;
        dataObj["alertName"] = thisData.searchFilters.Name;
        dataObj["alertPO"] = thisData.searchFilters.PO;
        dataObj["alertFor"] = thisData.searchFilters.Type;
        dataObj["alertCustomer"] = thisData.searchFilters.Customer;
        dataObj["alertCustomerIds"] = thisData.searchFilters.CustomerIds;
        dataObj["alertCreatedBy"] = thisData.searchFilters.CreatedBy;
        dataObj["alertCreatedByIds"] = thisData.searchFilters.CreatedByIds;
        dataObj["alertUser"] = thisData.searchFilters.User;
        dataObj["timeframe"] = timeframe;
        dataObj["alertTypeList"] = thisData.alertTypeList.length ? thisData.alertTypeList.toString() : "-1";
        if (thisData.searchFilters.sDate) {
          dataObj['alertsDate'] = thisData.searchFilters.sDate;
        }
        if (thisData.searchFilters.eDate) {
          dataObj["alerteDate"] = thisData.searchFilters.eDate;
        }
        if (thisData.sort.field) {
          dataObj["alertOrder"] = thisData.sort.field;
          dataObj["alertDirection"] = thisData.sort.direction ? thisData.sort.direction : 1;
        }
      } else if (thisData.activeTab == 'logs') {
        dataObj["id"] = thisData.searchFilters.Id;
        dataObj["details"] = thisData.searchFilters.Details;
        dataObj["customer"] = thisData.searchFilters.Customer;
        dataObj["user"] = thisData.searchFilters.User;
        if (thisData.searchFilters.sDate) {
          dataObj['sDate'] = thisData.searchFilters.sDate;
        }
        if (thisData.searchFilters.eDate) {
          dataObj["eDate"] = thisData.searchFilters.eDate;
        }
        if (thisData.sort.field) {
          dataObj["order"] = thisData.sort.field;
          dataObj["direction"] = thisData.sort.direction ? thisData.sort.direction : 1;
        }
      } else {
        if (thisData.searchFilters) {
          dataObj["id"] = thisData.searchFilters.Id || "";
          dataObj["customer"] = thisData.searchFilters.Customer || "";

          if (thisData.searchFilters.sDate) {
            dataObj['sDate'] = thisData.searchFilters.sDate;
          }
          if (thisData.searchFilters.eDate) {
            dataObj["eDate"] = thisData.searchFilters.eDate;
          }
          if (thisData.sort.field) {
            dataObj["order"] = thisData.sort.field;
            dataObj["direction"] = thisData.sort.direction ? thisData.sort.direction : 1;
          }
          if (thisData.searchFilters.source) {
            dataObj['source'] = thisData.searchFilters.source;
          }
        }

      }
      let returnData = await ApiHelper.callApi(
        'post',
        dataObj
      );


      if (returnData.STATUS == 1) {
        if (typeof returnData.TOTALALERTS !== 'undefined') {
          commit('setTotalAlerts', returnData.TOTALALERTS);
        }
        if (typeof returnData.NEWESTALERTS !== 'undefined') {
          commit('setNewAlerts', returnData.NEWESTALERTS);
        }
        if (typeof returnData.ALERTS !== 'undefined') {
          var sortedAlerts = sortAlertsByDate(returnData.ALERTS);
          commit('setAlerts', sortedAlerts);
        }
        if (typeof returnData.LOGS !== 'undefined') {
          commit('setLogs', returnData.LOGS);
        }
        if (typeof returnData.STATUSLIST !== 'undefined') {
          commit('setStatus', returnData.STATUSLIST);
        }
        if (typeof returnData.TOTALLOGPAGES !== 'undefined') {
          commit('setTotalLogs', returnData.TOTALLOGPAGES);
        }
        if (typeof returnData.TOTALSTATUSPAGES !== 'undefined') {
          commit('setTotalStatus', returnData.TOTALSTATUSPAGES);
        }
        if (typeof returnData.SOURCESOBJ !== 'undefined') {
          commit('setSourcesObj', returnData.SOURCESOBJ);
        }
      }
    

      return returnData;
    }
  },
});

// Helper function to sort alerts by ALERTRUN from newest to oldest
function sortAlertsByDate(alerts: any[]): any[] {
  return alerts.sort((a, b) => {
    const dateA = new Date(a.ALERTRUN);
    const dateB = new Date(b.ALERTRUN);
    return dateB.getTime() - dateA.getTime(); // Sort descending
  });
}