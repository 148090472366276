


















































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import draggable from "vuedraggable";
import { colsResizeable } from "@/helpers/ApiHelper";
import GlobalTagStatus from "@/components/GlobalTagStatus.vue";
import { ApiHelper } from "@/helpers/all";

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    ConfirmRemoveItemModal,
    draggable,
    ListHeaderActions,
    GlobalTagStatus
  }
})
export default class GlobalTags extends TSXComponent<void> {
  selectedID = [];
  loading = false;
  details: any = {};
  items: any = [];
  pageNumber = 1;
  sort = {
    field: null,
    direction: {
      tagName: 1
    }
  };
  directionField = "";
  filters: any = {
    tagName: ""
  };
  searchFilters: any = {
    tagName: "",
    statuses: [0,1],
  };
  confirmRemoveModalVisible = false;

  async created() {
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Customers",
        FunctionName: "GlobalTagsList",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        tagName: this.filters.tagName,
        isActive: this.searchFilters.statuses.length === 1 ? this.searchFilters.statuses.at(0) : '',
        order: this.sort.field ? this.sort.field : 0,
        direction:
          this.directionField != ""
            ? this.sort.direction[this.directionField]
            : 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.items = this.details.GLOBALTAGS;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  searchBy(field) {
    if (this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field];
    this.fetchData();
  }

  resetBy(field) {
    if (this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field] = "";
    this.fetchData();
  }

  updateValue(field) {
    if (this.searchFilters[field] == "") {
      this.pageNumber = 1;
      this.fetchData();
    }
  }

  getFilterValue(filterProperty) {
    let filter = '';
    switch (filterProperty) {
      case 'statuses':
        if (this.searchFilters.statuses.length === 1) {
          filter = this.searchFilters.statuses.at(0) === 1 ? 'Active' : 'Archived';
        }

        break;
      default:
        // nothing
        break;
    }
    return filter;
  }

  async resetStatusList() {
    this.searchFilters.statuses = [0,1];
    this.pageNumber = 1;
    this.fetchData();
  }

  async checkStatusList(option) {
    const statusIdx = this.searchFilters.statuses.findIndex(status =>  status === option);
    if (statusIdx === -1) {
      this.searchFilters.statuses.push(option);
    } else {
      this.searchFilters.statuses.splice(statusIdx, 1);
    }

    this.pageNumber = 1;
    this.fetchData();
  }

  async removeSelected() {
    var refModal: any = this.$refs.confirmRemoveModal;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Customers",
        FunctionName: "DeleteGlobalTag",
        GlobalTagIDs: this.selectedID.join(",")
      });

      if (response.data.ERROR) {
        refModal.deleting = "error";
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        refModal.deleting = false;
        this.selectedID = [];
        this.fetchData();
      }
    } catch (err) {
      refModal.deleting = "error";
    } finally {
      this.confirmRemoveModalVisible = false;
    }
  }

  async update() {
    this.items.map((item: any, index) => {
      item.CUSTOMFIELDOPTIONORDER = index + 1;
    })
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Customers",
        FunctionName: "UpdateGlobalTag",
        UpdateCustomFieldOptionOrder: true,
        GlobalTags: this.items
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  async changeStatus(tagId, status, globalTagName) {
    const response = await ApiHelper.callApi("post", {
      controller      : "Customers",
      FunctionName    : "UpdateGlobalTag",
      GlobalTagID     : tagId,
      tagName         : globalTagName,
      isActive        : status
    });
    if (response.STATUS) {
      notifier.success(response.STATUSMESSAGE);
    } else {
      notifier.alert("Error Occured");
    }
    await this.fetchData();
  }

  async updateTagAvailability(tagId, globalTagName, updateStatus, updateFor) {
    const response = await ApiHelper.callApi("post", {
      controller      : "Customers",
      FunctionName    : "UpdateGlobalTag",
      GlobalTagID     : tagId,
      tagName         : globalTagName,
      updateStatus    : updateStatus,
      updateFor       : updateFor
    });
    if (response.STATUS) {
      notifier.success(response.STATUSMESSAGE);
    } else {
      notifier.alert("Error Occured");
    }
    await this.fetchData();
  }
}
