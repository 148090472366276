import axios from "axios";
import { notifier } from "@/models/common";

declare const dataURL: string;
declare const getMaxRows: Function;

export type Filters = {
	amount?: string
	entryType: string[]
	purchaseID?: string
	source: string[]
	createdDate: {
		sDate?: string | undefined,
		eDate?: string | undefined,
	}
	ledgerStatus: string[]
	invoiceID?: string
	invoiceDueDate: {
		sDate?: string | undefined,
		eDate?: string | undefined,
	}
	sTotalMin: string | undefined
	sTotalMax: string | undefined
	paidDate: {
		sDate?: string | undefined,
		eDate?: string | undefined,
	}
	AccountTagID: number[]
	gTagIDs?: number[]
	groupIDs?: number[]
	sourceName: (string | undefined)[]
	entryTypeName: (string | undefined)[]
	statusName: (string | undefined)[]
	filterInvoiceDate: string | undefined
	filterInvoiceDueDate: string | undefined
	filterPaidDate: string | undefined
	ledgerCategoryTitle: string
}

export enum SortField {
	purchaseID = 1,
	source = 2,
	entryType = 3,
	amount = 4,
	status = 5,
	invoiceID = 6,
	createdDate = 7,
	invoiceDueDate = 8,
	paidDate = 9
}

export type Sort = {
	field: SortField | null | number
	direction: {
		[SortField.purchaseID]: number,
		[SortField.source]: number,
		[SortField.entryType]: number,
		[SortField.amount]: number,
		[SortField.status]: number,
		[SortField.invoiceID]: number,
		[SortField.createdDate]: number,
		[SortField.invoiceDueDate]: number,
	}
}

export enum ReportStatus {
	Open = 1,
	Close = 0
}

export type LedgerReportFilters = {
	Order: string
	Name: string
	varianceMin: string | undefined
	varianceMax: string | undefined
	ReportStatus: ReportStatus[]
	reportStatusName: (string | undefined)[]
	Reason: string | undefined
}

export enum ledgerReportSortField {
	Order = 1,
	Name = 2,
	variance = 3,
	Status = 4,
	Reason = 5
}

export type LedgerReportSort = {
	field: ledgerReportSortField | null | number
	direction: {
		[ledgerReportSortField.Order]: number,
		[ledgerReportSortField.Name]: number,
		[ledgerReportSortField.variance]: number,
		[ledgerReportSortField.Status]: number,
		[ledgerReportSortField.Reason]: number
	}
}

export class LedgersAPIRepo {
	async find(filters: Filters, sort: Sort, pageNumber: number, viewType = "CurrentUsers", selectedView = false, grouped: number, ledgerCategory: number, initialLoad: boolean = false) {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType, ledgerCategory, initialLoad);
		reqData["FunctionName"] = "ledgers";
		reqData["maxRows"] = sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows();
		reqData["pageNumber"] = pageNumber;
		reqData["selectedView"] = selectedView;
		reqData["grouped"] = grouped;
		reqData["isHtmlExport"] = false;

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}
	
	async exportListsPDFFileUrl(filters: Filters, sort: Sort, viewType = "CurrentUsers", selectedView = false, selectedAll = false, excludedIDs: any, selectedID: any, ledgerCategory: number): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType, ledgerCategory);
		reqData["FunctionName"] = "LedgerExport";
		reqData["ExportType"] = "PDF";
		reqData["selectedView"] = selectedView;
		reqData["isHtmlExport"] = true;
		reqData["selectedID"] = selectedID;
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		
		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async exportListsCSVFileUrl(filters: Filters, sort: Sort, viewType = "CurrentUsers", selectedView = false, selectedAll = false, excludedIDs: any, selectedID: any, ledgerCategory: number): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType, ledgerCategory);
		reqData["FunctionName"] = "LedgerExport";
		reqData["ExportType"] = "CSV";
		reqData["selectedID"] = selectedID;
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;

		if (typeof filters.sTotalMin !== 'undefined') {
			reqData['sTotalMin'] = filters.sTotalMin
		}
		if (typeof filters.sTotalMax !== 'undefined') {
			reqData['sTotalMax'] = filters.sTotalMax
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}
		return response.data.S3URL
	}

	async exportLedgerAgingReportFileUrl(filters: Filters, sort: Sort, viewType = "CurrentUsers", selectedView = false, selectedAll = false, excludedIDs: any, selectedID: any, ledgerCategory: number): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType, ledgerCategory);
		reqData["FunctionName"] = "ExportLedgerAgingReport";
		reqData["ExportType"] = "CSV";
		reqData["selectedID"] = selectedID;
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;
		reqData["agingReport"] = true;

		if (typeof filters.sTotalMin !== 'undefined') {
			reqData['sTotalMin'] = filters.sTotalMin
		}
		if (typeof filters.sTotalMax !== 'undefined') {
			reqData['sTotalMax'] = filters.sTotalMax
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}
		return response.data.S3URL
	}

	async findByIDs(filters: any, sort: any, viewType = "CurrentUsers", selectedView = false, selectedAll = false, excludedIDs: any, selectedID: any, ledgerCategory: number): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType, ledgerCategory);
		reqData["FunctionName"] = "ledgers";
		reqData["isHtmlExport"] = true;
		reqData["selectedID"] = selectedID;
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;

		if (typeof filters.sTotalMin !== 'undefined') {
			reqData['sTotalMin'] = filters.sTotalMin
		}
		if (typeof filters.sTotalMax !== 'undefined') {
			reqData['sTotalMax'] = filters.sTotalMax
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

	prepareFilteredData(filters: any, sort: any, viewType: string = 'CurrentUsers', ledgerCategory: number = 0, initialLoad: boolean = false) {
		var ret = {
			"controller": "finances",
			"view": viewType,
			"purchaseID": filters.purchaseID,
			"source": filters.source ? filters.source.join(",") : '',
			"entryType": filters.entryType ? filters.entryType.join(",") : '',
			"createdDate_sDate": filters.createdDate ? filters.createdDate.sDate : '',
			"createdDate_eDate": filters.createdDate ? filters.createdDate.eDate : '',
			"ledgerStatus": filters.ledgerStatus ? filters.ledgerStatus.join(",") : '',
			"invoiceID": filters.invoiceID,
			"invoiceDueDate_sDate": filters.invoiceDueDate ? filters.invoiceDueDate.sDate : '',
			"invoiceDueDate_eDate": filters.invoiceDueDate ? filters.invoiceDueDate.eDate : '',
			"paidDate_sDate": filters.paidDate ? filters.paidDate.sDate : '',
			"paidDate_eDate": filters.paidDate ? filters.paidDate.eDate : '',
			"selectedTagIDs": filters.AccountTagID.join(","),
			"tagIDs": filters.AccountTagID.join(","),
			"ledgerCategory": ledgerCategory,
		}

		if (typeof filters.sTotalMin !== 'undefined') {
			ret['sTotalMin'] = filters.sTotalMin
		}
		if (typeof filters.sTotalMax !== 'undefined') {
			ret['sTotalMax'] = filters.sTotalMax
		}
		if (filters.source.length == 0 && !initialLoad) {
			ret['source'] = "-1";
		}
		if (filters.entryType.length == 0 && !initialLoad) {
			ret['entryType'] = "-1";
		}
		if (filters.ledgerStatus.length == 0 && !initialLoad) {
			ret['ledgerStatus'] = "-1";
		}

		ret["gTagIDs"] = (typeof filters["gTagIDs"] != "undefined" ? filters["gTagIDs"].join(",") : "");
		ret["groupTagIDs"] = (typeof filters["groupIDs"] != "undefined" ? filters["groupIDs"].join(",") : "");

		if (sort.field) {
			ret["order"] = sort.field;
			ret["direction"] = sort.direction[sort.field].toString();
		}

		return ret;
	}

	async findReportDetails(pageNumber: number, viewType: string = "CurrentUsers", sort: LedgerReportSort, filters: LedgerReportFilters) {
		var requestData = {
			"controller": "finances",
			"FunctionName": "ledgerReportList",
			"pageNumber": pageNumber,
			"maxRows": sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows(),
			"view": viewType
		}

		if (sort.field) {
			requestData["order"] = sort.field;
			requestData["direction"] = sort.direction[sort.field].toString();
		}

		if (filters.Order) {
			requestData['purchaseID'] = filters.Order;
		}

		if (filters.Name) {
			requestData['sPONumber'] = filters.Name;
		}

		if (typeof filters.varianceMin !== 'undefined') {
			requestData['varianceMin'] = filters.varianceMin;
		}
		if (typeof filters.varianceMax !== 'undefined') {
			requestData['varianceMax'] = filters.varianceMax;
		}

		requestData['reportStatus'] = "-1";
		if (filters.ReportStatus.length) {
			requestData['reportStatus'] = filters.ReportStatus.join(",");
		}

		if (typeof filters.Reason !== 'undefined') {
			requestData['reason'] = filters.Reason;
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}
		return response.data
	}

	async updateStatus(purchaseID: number, status: number, reasonCancel: string) {
		var requestData = {
			"controller": "finances",
			"FunctionName": "UpdateLedgerReportStatus",
			"purchaseID": purchaseID,
			"status": status,
			"reasonCancel": reasonCancel
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}
		return response.data
	}
}

