






































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

declare const $: any;
declare const dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {}
})
export default class UnallocatedProductsTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: [] })
  unallocatedProducts!: any;

  // clickOutside() {
  //   this.$emit("close");
  // }
}
