




















































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from "../components/pageTitle.vue";
import Datepicker from "@/components/Datepicker.vue";
import SelectTagControl from "@/components/SelectTagControl.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import GeneralMultiselect from "../components/GeneralMultiselect.vue";

import { notifier } from "../models/common";
import axios from "axios";

interface PriceListOption {
    ID: string;
    TEXT: string;
}

interface DurationOption {
    ID: number;
    TEXT: string;
}

interface Restriction {
    categoryId: number;
    maxQuantity: number;
    fromDate: string;
    toDate: string;
}

@Component({
    inheritAttrs: false,
    components: {
        SelectTagControl,
        LaddaButton,
        PageTitle,
        EmployeeMultiselect,
        GeneralMultiselect,
        Datepicker
    }
})
export default class ContractDetails extends TSXComponent<void> {

    loading = false;
    contractId = "";
    name = '';
    durationId = 0;
    description = '';

    category = '';

    selectedPriceListIds: string[] = [];
    selectedPriceLists: PriceListOption[] = [];
    allPriceLists: PriceListOption[] = [];

    categoryList: any[] = [];
    durationList: DurationOption[] = [
        {
            ID: 7,
            TEXT: '7 days'
        },
        {
            ID: 14,
            TEXT: '14 days'
        },
        {
            ID: 30,
            TEXT: '30 days'
        },
        {
            ID: 60,
            TEXT: '60 days'
        },
        {
            ID: 90,
            TEXT: '90 days'
        },
        {
            ID: 180,
            TEXT: '180 days'
        },
        {
            ID: 365,
            TEXT: 'a year'
        },
        {
            ID: 365 * 2,
            TEXT: '2 years'
        },
        {
            ID: 365 * 3,
            TEXT: '3 years'
        },
        {
            ID: 365 * 4,
            TEXT: '4 years'
        },
        {
            ID: 365 * 5,
            TEXT: '5 years'
        }
    ];

    restrictions: Restriction[] = [];

    async created() {
        if (
            typeof this.$route.params.id === "string"
        ) {
            this.contractId = this.$route.params.id;
        }

        await Promise.all([this.getAllPriceList(), this.getCategoryList()]);
        await this.fetchData();
    }

    checkErrorMsg(errorKey) {
        return this.$validator.errors.first(errorKey);
    }

    async getAllPriceList() {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Quotes",
            FunctionName: "PriceList",
            viewType: 'options'
        });

        this.allPriceLists = response.data.PRICINGLIST.map((pl) => ({
            ID: pl.PRICELISTID,
            TEXT: pl.NAME
        }));
    }

    async getCategoryList() {
        try {
            const response = await axios.post(dataURL + "?ReturnType=JSON", {
                controller: "Helpers",
                FunctionName: "Category"
            });

            if (response.data.STATUS == 1) {
                this.categoryList = response.data.CATEGORY.map((categ) => ({
                    ID: categ.CATEGORYID,
                    TEXT: categ.CATEGORYNAME
                }));
            }
        } catch (err) {
            console.log(err);
        }
    }

    async fetchData() {
        this.loading = true;
        try {
            const response = await axios.post(dataURL + "?ReturnType=JSON", {
                controller: "Quotes",
                FunctionName: "ContractDetailView",
                contractId: +this.contractId
            });

            if (response.data.STATUS == 1) {
                const contract = response.data.CONTRACT;
                this.name = contract.CUSTOMFIELDOPTIONNAME || '';
                this.description = contract.CUSTOMFIELDOPTIONDESC || '';
                this.durationId = contract.DURATIONVALUE;

                if (contract.PRICELISTIDS) {
                    this.selectedPriceListIds = contract.PRICELISTIDS.split(",").map((prId) => +prId);
                } else {
                    this.selectedPriceListIds = [];
                }

                const priceLists: any[] = [];

                for (const plId of this.selectedPriceListIds) {
                    const pl = this.allPriceLists.find((pls) => +pls.ID === +plId);
                    if (pl) {
                        priceLists.push(pl);
                    }
                }

                this.selectedPriceLists = priceLists;
                this.restrictions = contract.RESTRICTIONS || [];
            } else {
                const message = response.data.STATUSMESSAGE || "";
                if (message) {
                    notifier.alert(message);
                }
            }

        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    toggleSelectedPriceListId(optionId, optionText) {
        const index = this.selectedPriceListIds.findIndex(id => id === optionId);
        if (index === -1) {
            this.selectedPriceListIds.push(optionId);
            this.selectedPriceLists.push({
                ID: optionId,
                TEXT: optionText
            });
            return;
        }
        this.selectedPriceListIds.splice(index, 1);
        this.$delete(this.selectedPriceLists, index);
    }

    selectAllPriceLists() {
        this.resetPriceLists();

        const thiz = this;
        this.selectedPriceListIds = this.allPriceLists.map(pl => pl.ID);

        $.each(this.allPriceLists, function (i, val) {
            thiz.selectedPriceLists.push({
                ID: val.ID,
                TEXT: val.TEXT
            });
        });
    }
    resetPriceLists() {
        this.selectedPriceListIds = [];
        this.selectedPriceLists = [];
    }

    updatePriceLists(type, index, plid) {
        if (type == "delete") {
            this.$delete(this.selectedPriceLists, index);
            this.$delete(this.selectedPriceListIds, index);
        }
    }

    chooseCategory(categoryId, rowIdx) {
        this.restrictions[rowIdx].categoryId = categoryId;
        this.category = categoryId;
        this.$forceUpdate();
    }

    chooseDuration(durationId) {
        this.durationId = durationId;
    }

    addRestriction() {
        this.restrictions.push({
            categoryId: 0,
            maxQuantity: 0,
            fromDate: "",
            toDate: ""
        });
    }

    removeRestriction(restriction) {
        const index = this.restrictions.indexOf(restriction);
        if (index > -1) { // only splice array when item is found
            this.restrictions.splice(index, 1);
        }
    }

    async updateContract() {
        try {
            const valid = await this.$validator.validate('name');
            // const valid = await this.$validator.validateAll();

            if (!valid) {
                return;
            }

            this.loading = true;

            const response = await axios.post(dataURL + "?ReturnType=JSON", {
                controller: 'Quotes',
                FunctionName: 'UpdateContract',
                contractId: +this.contractId,
                userID: sessionStorage.getItem("userId"),
                name: this.name,
                durationValue: this.durationId,
                description: this.description,
                priceListIds: this.selectedPriceListIds.join(),
                restrictions: this.restrictions.filter((res) => res.categoryId > 0)
            });

            const message = response.data.STATUSMESSAGE || "";

            if (response.data.STATUS == 1) {
                if (message) {
                    notifier.success(message);
                }
            } else {
                if (message) {
                    notifier.alert(message);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    }

    listPageRedirection() {
        this.$router.go(-1);
    }

}
