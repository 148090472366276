














































































































import axios from "axios";
import {Component as TSXComponent} from "vue-tsx-support";
import {Component, Prop, Emit, Model, Watch} from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import directives from "../../helpers/directives";
import {notifier} from "@/models/common";
import Loader from "@/components/Loader.vue";
import poIDQuoteIDAutoSuggestInput from "@/components/poIDQuoteIDAutoSuggestInput.vue";
import {ApiHelper} from "@/helpers/all";

declare const $: any;
declare const dataURL: string;

interface Props {
}

@Component({
  inheritAttrs: false,
  components: {
    poIDQuoteIDAutoSuggestInput,
    LaddaButton,
    Loader
  },
  directives
})
export default class ModalEditOpportunityOrdersAndQuotes extends TSXComponent<void> {
  @Prop({required: false, default: 'Edit Opportunity Orders And Quotes'})
  title?: string;

  @Prop({required: false, default: 'all'})
  type?: string;

  @Prop({required: false, default: 0})
  opportunityId?: number;

  @Prop({required: false, default: 0})
  AID?: number;

  @Prop({required: false, default: []})
  defaultOrderAndQuoteIDs?: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  loading = false;
  saving: any = false;
  selectedOrderAndQuoteIDs: any = [];
  orderQuoteIDs: string = "";
  field: string =  this.type == 'all' ? "Orders and Quotes" : `${this.type == 'orders' ? 'Orders' : 'Quotes'}`;
  placeholderText: string = this.type == 'all' ? "Order or Quote Number" : `${this.type == 'orders' ? 'Order' : 'Quote'} Number`;
  errors: any;
  hasChange = false;

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    this.selectedOrderAndQuoteIDs = (this.defaultOrderAndQuoteIDs || []).map((item) => { return item; });
  }


  async doSubmit() {
    await this.linkOrderAndQuote()
  }

  getPoAndQuoteText(item) {
    let orderAndQuoteString = "";
    orderAndQuoteString += (item.TYPEASTEXT ? `${item.TYPEASTEXT}: ` : '') + `#${item.ID}`;
    if (item.PO) {
      orderAndQuoteString += ` PO: ${item.PO}`;
    }
    orderAndQuoteString += ` Description: ${item.DESCRIPTION}`;
    return orderAndQuoteString
  }

  async deleteSelectedPoAndQuote(index) {
    this.$delete(this.selectedOrderAndQuoteIDs, index);
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.hasChange = true;
    }, 10);
  }

  async linkOrderAndQuote() {
    let orderIDs = [];
    let quoteIDs = [];
    this.selectedOrderAndQuoteIDs.map((item: any) => {
      if (item.TYPE === 1) {
        orderIDs.push(item.ID);
      } else if (item.TYPE === 2) {
        quoteIDs.push(item.ID);
      }
    });

    const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Opportunities",
          FunctionName: "UpdateOpportunity",
          oppID: this.opportunityId,
          orderIDs: orderIDs.join(),
          quoteIDs: quoteIDs.join(),
          field: "linkOrderAndQuote"
        }
    );
    this.$emit('update', this.selectedOrderAndQuoteIDs);
  }

  async updatePoAndQuote(data) {
    if (data.poAndQuote.ID) {
      // Find the index of the order/quote in the list of selected orders/quotes
      const index = this.selectedOrderAndQuoteIDs.findIndex((item: any) => item.ID == data.poAndQuote.ID);

      // If the order/quote is not already in the list, add it
      if (index === -1) {
        let orderAndQuoteStruct = {
          "ID": data.poAndQuote.ID,
          "TYPE": data.poAndQuote.TYPE,
          "TYPEASTEXT": data.poAndQuote.TYPEASTEXT,
          "PO": data.poAndQuote.PO,
          "DESCRIPTION": data.poAndQuote.DESCRIPTION,
          "STATUSASTEXT": data.poAndQuote.STATUSASTEXT,
          "GRANDTOTAL": data.poAndQuote.GRANDTOTAL,
          "GRANDTOTAL_FORMATTED": `$${this.formatter(data.poAndQuote.GRANDTOTAL || 0)}`,
          "ACCTPRICETYPE": data.poAndQuote.ACCTPRICETYPE,
          "ACCTPRICETYPESTR": data.poAndQuote.ACCTPRICETYPESTR,
          "CUSTOMPRICETYPESTR": data.poAndQuote.CUSTOMPRICETYPESTR
        }
        this.selectedOrderAndQuoteIDs.push(orderAndQuoteStruct);
        this.hasChange = true;
      }
    }
    this.orderQuoteIDs = " ";
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 10);
  }

  formatter(amount) {
    // Use Intl.NumberFormat to format the total as a currency string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }
}
