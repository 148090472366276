

















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import CloseVarianceStatus from "../components/CloseVarianceStatus.vue";

interface Props {
  status: StatusValue;
}

interface Events {
  onChangeStatus: StatusValue
}

export enum StatusValue {
  Open = 1,
  Close = 0
}

@Component({
  inheritAttrs: false,
  components: {
    CloseVarianceStatus
  }
})
export default class LedgerReportComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: StatusValue;

  @Prop({ required: true, default: 0 })
    purchaseID?: number;

  popoverVisible = false
  optionsVisible = false
  closeVarianceModalVisible = false
  option = {
    status: 0,
    reasonCancel: ""
  }

  get iconClass() {
    return this.status == StatusValue.Open ? 'green' : (this.status == StatusValue.Close)? 'red' : 'red'
  }

  get statusText() {
    return this.status == StatusValue.Open ? 'Open' :  'Close'
  }
  
  hideTooltip() {
    this.optionsVisible = false;
  }

  mouseover() {
    if (this.optionsVisible) {
      return
    }

    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false
    
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: StatusValue) {
    this.option = {
      status: status,
      reasonCancel: ""
    }
    if (status) {
      this.$emit('changeStatus', this.option);
      this.optionsVisible = false;
    }
    else {
      this.closeVarianceModalVisible = true;
    }
  }

  closeVarianceStatus(data) {
    this.option = {
      status: 0,
      reasonCancel: data
    }
    this.$emit('changeStatus', this.option);
    this.optionsVisible = false;
    this.closeVarianceModalVisible = false;
  }
}
