
















































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { notifier, wait } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

@Component({
  inheritAttrs: false,
  components: {
    ConfirmRemoveItemModal,
    LaddaButton
  }
})
export default class ExportSetting extends TSXComponent<void> {
  $route: any;
  $router: any;
  title = "New Template";
  templateID = 0;
  saving: any = false;
  loading = false;
  templateName = "";
  templateDesc = "";
  templateType = 1; //quote
  isActive = 1;
  exportStatusTypes = [
    {
      name : "Active",
      value : 1
    },
    {
      name : "Archive",
      value : 0
    }
  ];
  templateLocation = "";
  isDefault = false;
  templateNameOptions: any = [];
  defaultTemplateName = "";
  defaultTemplateNameError = "";
  confirmArchive = false;
  confirmDefualtTemplateArchive = false;
  defaultAccounts = [];
  defaultTemplateType = "";

  created() {
    this.templateID =
      typeof this.$route.params.id != "undefined" && this.$route.params.id != 0
        ? this.$route.params.id
        : 0;
    if (this.templateID > 0) {
      this.editTemplate(this.templateID);
    }
    if (typeof this.$route.query.type === "string") {
      switch (this.$route.query.type) {
        case "quote":
          this.templateType = 1;
          break;
        case "order":
          this.templateType = 3;
          break;
        case "invoice":
          this.templateType = 2;
          break;
      }
    }
  }

  async editTemplate(templateID) {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Templates",
        FunctionName: "View",
        subsystem: "VAR360",
        templateID: this.templateID
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        var details = response.data.TEMPLATEDETAILS;
        this.templateName = details.TEMPLATENAME;
        this.templateDesc = details.TEMPLATEDESC;
        this.templateType = details.TEMPLATETYPE;
        this.isActive = details.ISACTIVE;
        this.templateLocation = details.TEMPLATELOCATION;
        this.title =
          "Edit Template #<span class='t-title' title='" +
          this.templateName +
          "'>" +
          this.templateName +
          "</span>";
        this.isDefault = !!details.ISDEFAULT;

        // specify template details list
        const templateParams = JSON.parse(details.TEMPLATEPARAMS || "{}");
        if (
          this.templateType == 1 &&
          (templateParams.QuoteTemplateName || []).length
        ) {
          this.templateNameOptions = templateParams.QuoteTemplateName;

          const defaultTemplateName = templateParams.defaultTemplateName || "";
          const inList = this.templateNameOptions.find(
            item => item.Name.toLowerCase() == defaultTemplateName.toLowerCase()
          );
          if (inList) {
            this.defaultTemplateName = defaultTemplateName;
          }
        }
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let valid = await this.$validator.validateAll();

    // if is default quote template, and have a template details list, need to ask to select a quote template detail
    if (
      this.templateType == 1 &&
      this.isDefault &&
      this.templateNameOptions.length &&
      !this.defaultTemplateName
    ) {
      this.defaultTemplateNameError =
        "The Quote template detail field is required";
      valid = false;
    }

    if (valid) {
      await this.saveSetting();
    }
  }

  async saveSetting() {
    this.saving = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Templates",
        FunctionName: "Update",
        subsystem: "VAR360",
        templateName: this.templateName,
        templateDesc: this.templateDesc,
        templateType: this.templateType,
        isActive: this.isActive,
        templateLocation: this.templateLocation,
        templateID: this.templateID,
        isDefault: this.isDefault,
        defaultTemplateName: this.defaultTemplateName,
        confirmDefualtTemplateArchive: this.confirmDefualtTemplateArchive,
        accountList: this.defaultAccounts,
        defaultTemplateType: this.defaultTemplateType
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS === 0) {
        if (response.data.STATUSMESSAGEDEFAULTTEMPLATE) {
          this.confirmArchive = true;
          this.defaultAccounts = response.data.ACCOUNTLIST;
          this.defaultTemplateType = response.data.TEMPLATETYPE;
        } else {
          notifier.alert(response.data.STATUSMESSAGE);
        }
        this.saving = "error";
        return;
      }

      if (response.data.STATUS == 1) {
        this.saving = false;
        this.$router.push({ name: "ExportSetting" });
      }
    } catch (err) {
      console.log(err.message);
      this.saving = "error";
    }
  }

  backToList() {
    this.$router.push({ name: "ExportSetting" });
  }

  async archiveTemplateConfirm() {
    this.confirmDefualtTemplateArchive = true;
    await this.saveSetting();
  }
}
