






















export default {
    props: {
        from: String,
        itemIndex: String,
        arrowCounter: Number,
        update: Function,
        updateCustomer: Function,
        updateQuote: Function,
    }
}
