

































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  status: StandardStatusValue;
}

interface Events {
  onChangeStatus: StandardStatusValue
}

export enum StandardStatusValue {
  Draft = 0,
  Active = 1,
  WaitingForApproval = 2,
  Terminated = 3
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class TooltipStandardStatus extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: StandardStatusValue;

  @Prop({ required: false, default: true })
  showStatus?: boolean;

  currentRole = 'Customer'; // sessionStorage.getItem("userRole");
  popoverVisible = false
  optionsVisible = false

  get iconClass() {
    switch (this.status) {
      case StandardStatusValue.Active:
        return 'green';
      case StandardStatusValue.WaitingForApproval:
        return 'yellow';
      case StandardStatusValue.Terminated:
        return 'grey';
      default:
        return 'lightBlue';
    }
  }

  get statusText() {
    switch (this.status) {
      case StandardStatusValue.Draft:
        return 'Draft';
      case StandardStatusValue.Active:
        return 'Active';
      case StandardStatusValue.WaitingForApproval:
        return 'Waiting For Approval';
      case StandardStatusValue.Terminated:
        return 'Terminated';
      default:
        return 'Unknown';
    }
  }

  hideTooltip() {
    this.optionsVisible = false;
  }

  mouseover() {
    if (this.optionsVisible) {
      return
    }

    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false

    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: StandardStatusValue) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }
}
