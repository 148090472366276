








































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../../components/LaddaButton.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";

import { FormControls } from "@/models/FormControls";

interface Props {
  accounts: [];
  users: [];
}

interface Events {}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class ModalEditScheduleGroupNotes extends TSXComponent<
  Props,
  Events
> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: null })
  data?: any;

  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: false })
  loading?: boolean | string;

  mounted() {
    $(this.$refs.modal).modal("show");
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    if (this.data) {
      const initData = this.data || {};
      this.formData.controls.id.value = initData.GROUPID || "0";
      this.formData.controls.notes.value = initData.GROUPNOTES || "";
    }
  }

  formData: {
    loading: boolean;
    controls: {
      deploymentId: FormControls.FormHidden;
      id: FormControls.FormHidden;
      notes: FormControls.FormText;
    };
  } = {
    loading: false,
    controls: {
      deploymentId: {
        value: this.deploymentId || 0,
        error: ""
      },
      id: {
        value: 0,
        error: ""
      },
      notes: {
        value: "",
        error: ""
      }
    }
  };

  async onSubmit() {
    let hasError = false;
    if (this.formData.controls.notes.value == "") {
      this.formData.controls.notes.error = "Notes is required";
      hasError = true;
    }

    if (hasError) {
      notifier.alert("Please complete the form");
    } else {
      this.formData.loading = true;
      const response = await ApiHelper.callApi("post", {
        controller: "Deployments",
        FunctionName: "UpdateScheduleGroupNotes",
        deploymentId: this.formData.controls.deploymentId.value,
        groupId: this.formData.controls.id.value,
        groupNotes: this.formData.controls.notes.value,
        itemType: this.data.ITEMTYPE || 1,
        endUserId: parseInt(this.data.ENDUSERIDS) || 0
      });

      if (response.STATUS == 1) {
        // update deployment percent
        await ApiHelper.updateDeploymentPercent(
          this.formData.controls.deploymentId.value
        );
        notifier.success(response.STATUSMESSAGE);
        this.$emit(
          "callback",
          this.formData.controls.id.value,
          this.formData.controls.notes.value
        );
      } else {
        notifier.alert(response.STATUSMESSAGE || `Cant update notes`);
      }

      this.formData.loading = false;
    }
  }

  clickOutside() {
    this.$emit("close");
  }
}
