







































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from 'axios'

import LaddaButton from '../components/LaddaButton.vue'
import {notifier, wait} from '../models/common'

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
  },
})
export default class CloseVarianceStatus extends TSXComponent<void> {
  @Prop({ required: true, default: 0 })
  purchaseID?: number;

  @Prop({ required: false, default: "Close Variance for Order" })
  modalTitle?: string;

  @Prop({ required: false, default: "Close Variance" })
  buttonLabel?: string;

  saving: boolean | 'error' = false;
  $parent: any;
  reasonCancel = "";

  $refs!: {
    modal: HTMLDivElement;
  };
  
  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
      this.$parent.closeVarianceModalVisible = false;
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async validateBeforeSubmit(e) {
      this.$validator.validateAll().then((result) => {
          if (result) {
              e.preventDefault();
                  return this.submit();
          }
          else {
              e.preventDefault();
              e.stopPropagation();
          }
      });
  }

  async submit() {  
    if (this.reasonCancel.length > htmlParse(this.reasonCancel).length) {
      notifier.alert("HTML content found. This content has been removed!");
      this.reasonCancel = htmlParse(this.reasonCancel);
    }

    try {
      this.saving = true;
      this.$emit("submit", this.reasonCancel);
    } catch (err) {
      this.saving = 'error'
      // console.log(err);
      notifier.alert("Close variance error: " + err.message);
    }
  }
}
