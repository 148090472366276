






























































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import moment from "moment";

import LaddaButton from "../components/LaddaButton.vue";
import { notifier, wait, downloadFileUrl } from "../models/common";
import PhoneInput from "../components/PhoneInput.vue";
import DropdownControl from "../components/DropdownControl.vue";
import USState from "../static/USstates.json";
import UserAutoSuggestInput from "../components/UserAutoSuggestInput.vue";

interface Props {}

interface Events {}

declare const $: any;
declare const dataURL: string;
declare const getMaxRows: Function;

@Component({
	inheritAttrs: false,
	components: {
		LaddaButton,
		PhoneInput,
		DropdownControl,
		UserAutoSuggestInput
	}
})
export default class UserGenerateModal extends TSXComponent<Props, Events> {

	@Prop({required: true, default: ""})
	title!: string
	
	$refs!: {
    	drodown: DropdownControl;
		modal: HTMLDivElement;
	};

	uFName = "";
    uLName = "";
	uEmail = "";
    userState = "";
    uAddress = "";
	uCity = ""
    uPhone = "";
    uPhoneExt = "";
	uZIP = "";
    stateList: any = [];
  	selectedState: string[] = [];
	loading = false;
	zipErrorVisible = false;

	mounted() {
		$(this.$refs.modal).modal("show");

		$(this.$refs.modal).on("hide.bs.modal", () => {
			this.$emit("close");
		});
		for (const i in USState) {
			this.stateList.push({
				ID: USState[i].Abbr,
				TEXT: USState[i].Abbr,
				data: USState[i]
			});
		}
	}

	async generate() {
		const ok = await this.$validator.validateAll();
		if (!ok) {
			return;
		}

		if (this.uZIP != "") {
			if ( !this.uZIP.match("^[0-9]{5}$") ) {
				this.zipErrorVisible = true;
				return;
			} else {
				this.zipErrorVisible = false;
			}
		}

		this.loading = true;
    	const userVals = {
			uFName: this.uFName,
			uLName: this.uLName,
			uEmail: this.uEmail,
			userState: this.userState,
			uAddress: this.uAddress,
			uPhone: this.uPhone,
			uPhoneExt: this.uPhoneExt,
			uZIP: this.uZIP,
			uCity: this.uCity
		};
		await this.$emit('generate', userVals);
		this.loading = false;
	}

	updateUser(selectedUser) {
		const userDetails = selectedUser.User;
		this.uFName = userDetails.UFNAME;
		this.uLName = userDetails.ULNAME;
		this.uEmail = userDetails.UEMAIL;
		this.userState = userDetails.USTATE;
		this.updateUserState(this.userState);
		this.uAddress = userDetails.UADDRESS;
		this.uPhone = userDetails.UPHONE;
		this.uPhoneExt = userDetails.UPHONEEXT;
		this.uZIP = userDetails.UZIP;
		this.uCity = userDetails.UCITY;
	}

	beforeDestroy() {
		$(this.$refs.modal).modal("hide");
	}

	getExt(e){
		if(e != undefined){
			return e.split(' ')[0]
		}
		else{
			return 'US'
		}
	}
	countryChanged(obj) {
		this.uPhoneExt = obj.countryCode + ' ' + '+' + obj.countryCallingCode;
	}
	close(){
		this.$refs.drodown.dropdownVisible = false;
	}

	updateUserState(selectedID) {
		this.selectedState = [selectedID];
		this.userState =  selectedID;
	}
}
