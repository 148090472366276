
































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit } from "vue-property-decorator";
import axios from "axios";
// import CustomerCustomFieldsModal from "../components/CustomerCustomFieldsModal.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import SecurityMultiSelect from "../components/SecurityMultiSelect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from '../components/pageTitle.vue';
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import { notifier } from "../models/common";
import DropdownControl from "../components/DropdownControl.vue";
// import { USState } from "../repos/CustomerAPIRepo";
import USState from "../static/USstates.json";
import CustomerAutoSuggestInput from "../components/CustomerAutoSuggestInput.vue";

declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;

interface Props {
  isPage: boolean;
  includedFrom: string;
  lineInfo: any;
}

interface Events {
  doLinkingWith: {
    item: any;
  };
}

@Component({
  inheritAttrs: false,
  components: {
    // CustomerCustomFieldsModal,
    EmployeeMultiselect,
    SecurityMultiSelect,
    LaddaButton,
    DropdownControl,
    PageTitle,
    CustomerAutoSuggestInput,
    ConfirmRemoveItemModal
  }
})
export default class CustomerAdd extends TSXComponent<void> {
  @Prop({ required: false, default: true })
  isPage?: boolean;
  @Prop({ required: false, default: "" })
  includedFrom!: string;
  @Prop({ required: false, default: {} })
  lineInfo!: any;
  // @Prop({ required: false, default: {} })
  // lineInfo!: any;
  @Prop({ required: false, default: true })
  isFinances!: boolean;

  accountID = "";
  aName = "";
  aAlias = "";
  Address = "";
  City = "";
  State = "";
  Zip = "";
  //ContractID = '';
  //SpecialPricingID = '';
  loading = false;
  stateList: any = [];
  selectedState: string[] = [];
  // customFields:{
	// 	id?:number,
	// 	type?:string,
	// 	required?:boolean,
	// 	maxlength?:number,
	// 	placeholder?:string,
	// 	label?:string,
	// 	class?:string,
	// 	value?:string,
	// }[] = [];
  // customFieldsModalVisible = false;
  users: {
    disabled?: boolean;
    class?: string;
    USERID?: number;
  }[] = [];
  selectedUsers: {
		USERID: number,
		UFULLNAME : string
  }[] = [];
  selectedUserIds:(number | undefined)[] = [];
  selectedGroupIds:number[] = [];
  selectedSettings = {
    hpDirectTaxRate: '',
    indirectTaxRate: '',
    ShipmentNotes: '',
    QuoteNotes: '',
    EndMessage: '',
    PurchasingInstruction: '',
    quote_template: '',
    quoteTemplateDetail: '',
    order_template: '',
    invoice_template: '',
    quote_contactOptionSelected: '',
    quote_contactValue: '',
    order_contactOptionSelected: '',
    order_contactValue: '',
    selectedTagsID: '',
  };
  securityGroup: {
    disabled: boolean;
    class: string;
    SECURITYGROUPID: number;
  }[] = [];
  selectedGroup: {
		SECURITYGROUPID: number,
		SECURITYGROUPNAME : string
  }[] = [];
  selectedAccountId = 0;
  saving:any = false;
  $refs!: {
    drodown: DropdownControl;
  };
  customer = "";
  AccountName_existed: number = 0;
  customDataVisible = false;
  fullCustomData: any = [];
  customDataLoading = false;
  redirectURL:string = "";
  confirmRemoveModalVisible: boolean = false;
  isDuplicateName: boolean = false;

  async created() {
    if (typeof this.$route.query.customerAdd === "string") {
      this.aName = this.$route.query.customerAdd.length ? this.$route.query.customerAdd : "";
    }
    if (typeof this.$route.query.redirectTo === "string") {
      this.redirectURL = this.$route.query.redirectTo.length ? this.$route.query.redirectTo : "";
    }
    /* var self = this;
    var dataObj = {};
    dataObj["controller"] = "Customers";
    dataObj["FunctionName"] = "View";
    dataObj["aID"] = 0;
    var customerResult = getRouteData(dataObj);
    await customerResult.then(function(response, statusText) {
      self.users = response.data.ALLUSERS;
      self.securityGroup = response.data.ALLSECURITYGROUPS;
      const selecteduser = self.selectedUsers;
      const allUser = self.users;
      var userListID: (number | undefined)[] = [];
      if (selecteduser.length > 0) {
        selecteduser.forEach(element => {
          userListID.push(element.USERID);
        });
      }
      if (allUser.length > 0) {
        for (var i = 0; i < allUser.length; i++) {
          if (allUser[i].USERID && userListID.indexOf(allUser[i].USERID) >= 0) {
            allUser[i].disabled = true;
            allUser[i].class = "highlight";
          } else {
            allUser[i].disabled = false;
            allUser[i].class = "";
          }
        }
      }
      const selectedgroups = self.selectedGroup;
      const allGroup = self.securityGroup;
      var securityID: number[] = [];
      if (selectedgroups.length > 0) {
        selectedgroups.forEach(element => {
          securityID.push(element.SECURITYGROUPID);
        });
      }
      if (allGroup.length > 0) {
        for (var ij = 0; ij < allGroup.length; ij++) {
          if (securityID.indexOf(allGroup[ij].SECURITYGROUPID) >= 0) {
            allGroup[ij].disabled = true;
            allGroup[ij].class = "highlight";
          } else {
            allGroup[ij].disabled = false;
            allGroup[ij].class = "";
          }
        }
      }
    }); */
    try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "Accounts",
        FunctionName			: "ListExt",
        queryType         : "newAcct_init_lists",
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
      }
      
      if(response.data.STATUS == 1) {
        this.users = response.data.ALLUSERS;
        this.securityGroup = response.data.ALLSECURITYGROUPS;
      }
      for (const i in USState) {
        this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
		}
		}catch (err) {
      // console.log(err.message);
		}

    //init data in case "make new account" from agent detail screen
    if (!this.isPage && this.includedFrom == "accountLinking") {
      if (this.isFinances) {
        this.accountID = (typeof this.lineInfo.MC_ACCTID != "undefined" ? this.lineInfo.MC_ACCTID : "");
        this.aName = (typeof this.lineInfo.MC_CDETAIL_ACCOUNTNAME != "undefined" ? this.lineInfo.MC_CDETAIL_ACCOUNTNAME : "");
        this.aName = (typeof this.lineInfo.MC_ACCTNAME != "undefined" ? this.lineInfo.MC_ACCTNAME : this.aName);
        this.Zip = (typeof this.lineInfo.MC_CDETAIL_ACCOUNTZIP != "undefined" ? this.lineInfo.MC_CDETAIL_ACCOUNTZIP : "");
        this.State = (typeof this.lineInfo.MC_CDETAIL_ACCOUNTSTATE != "undefined" ? this.lineInfo.MC_CDETAIL_ACCOUNTSTATE : "");
        this.City = (typeof this.lineInfo.MC_CDETAIL_ACCOUNTCITY != "undefined" ? this.lineInfo.MC_CDETAIL_ACCOUNTCITY : "");
        if(typeof this.lineInfo.MSPIFF_ACCOUNTNAME != "undefined") {
          this.accountID = this.lineInfo.MSPIFF_ACCOUNTID;
          this.aName = this.lineInfo.MSPIFF_ACCOUNTNAME;
          this.Zip = this.lineInfo.MSPIFF_ACCOUNTZIP;
          this.State = this.lineInfo.MSPIFF_ACCOUNTSTATE;
          this.City = this.lineInfo.MSPIFF_ACCOUNTCITY;
        }
      }
      else {
        this.accountID = (typeof this.lineInfo.ACCOUNTID != "undefined" ? this.lineInfo.ACCOUNTID : "");
        this.aName = (typeof this.lineInfo.ANAME != "undefined" ? this.lineInfo.ANAME : "");
        this.Zip = (typeof this.lineInfo.AZIP != "undefined" ? this.lineInfo.AZIP : "");
        this.State = (typeof this.lineInfo.ASTATE != "undefined" ? this.lineInfo.ASTATE : "");
        this.City = (typeof this.lineInfo.ACITY != "undefined" ? this.lineInfo.ACITY : "");
      }
    }

    //get custom data
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Helpers",
      FunctionName: "CustomData",
      DataType: "4", // account
      // Id: this.aid,
      // aId: this.aid
    });
    if(response.data.STATUS == 1) {
      this.fullCustomData = [];
      for(let val of response.data.CUSTOMDEFINITION) {
        let tmpValue: any = (response.data.CUSTOMDATA || []).filter(tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID);
        this.fullCustomData.push({
          AID: val.AID,
          CUSTOMFIELDID: val.CUSTOMFIELDID,
          CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
          CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
          CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
          CUSTOMDTYPE: val.CUSTOMDTYPE, 
          OPTIONS: val.OPTIONS || []
        });
      }
    }
  }
  listPageRedirection(){
    this.$router.push({ name: "Customers" });
  }
  updateUserList(type, index, aid) {
    if (type == "delete") {
      this.$delete(this.selectedUsers, index);
      this.$delete(this.selectedUserIds, index);
      $(".addAccount option[val=" + aid + "]").attr("disabled", false);
      $(".addAccount option[val=" + aid + "]").attr("class", "removelight");
    }
  }
  updateSGList(type, index, id) {
    if (type == "delete" && this.selectedGroup.length > 1) {
      this.$delete(this.selectedGroup, index);
      this.$delete(this.selectedGroupIds, index);
      $(".addSG option[val=" + id + "]").attr("disabled", false);
      $(".addSG option[val=" + id + "]").attr("class", "removelight");
    } else if (type == "delete" && this.selectedGroup.length == 1) {
      this.selectedGroup[0].SECURITYGROUPID = 2;
      this.selectedGroup[0].SECURITYGROUPNAME = "DEFAULT";

      $(".addSG option").attr("disabled", false);
      $(".addSG option").attr("class", "removelight");
    }
    this.$forceUpdate();
  }
  onChange() {
    var included = 0;
    const acctList = this.selectedUsers;
    for (var i = 0; i < acctList.length; i++) {
      if (
        acctList[i].USERID ==
        $(".addAccount option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.selectedUsers.push({
        USERID: $(".addAccount option:selected span").attr("data-user"),
        UFULLNAME: $(".addAccount option:selected span").attr("data-name")
      });
    }

    // $(".addAccount option:selected").remove();
    // $(".addAccount option:first-child").prop('selected');
    const numb = $(".addAccount option:selected span").attr("data-id");
    $(".addAccount option[val=" + numb + "]").attr("disabled", true);
    $(".addAccount option[val=" + numb + "]").attr("class", "highlight");
    $(".addAccount option:first-child").prop("selected", true);
  }

  onChangeSGList() {
    var included = 0;
    const selectedList = this.selectedGroup;
    for (var i = 0; i < selectedList.length; i++) {
      if (
        selectedList[i].SECURITYGROUPID ==
        $(".addSG option:selected span").attr("data-id")
      ) {
        included = 1;
        break;
      }
    }

    if (included != 1) {
      this.selectedGroup.push({
        SECURITYGROUPNAME: $(".addSG option:selected span").attr("data-name"),
        SECURITYGROUPID: $(".addSG option:selected span").attr("data-id")
      });
    }

    const numb = $(".addSG option:selected span").attr("data-id");
    $(".addSG option[val=" + numb + "]").attr("disabled", true);
    $(".addSG option[val=" + numb + "]").attr("class", "highlight");
    $(".addSG option:first-child").prop("selected", true);
    this.$forceUpdate();
  }

  validateBeforeSubmit(e) {
    this.$validator.validateAll().then(async result => {
      var htmlCount = 0;
      if ($("#aName").val() != "") {
        if (htmlCheck($("#aName").val())) {
          this.aName = htmlParse($("#aName").val());
          htmlCount++;
        }
      }
      if ($("#accountID").val() != "") {
        if (htmlCheck($("#accountID").val())) {
          this.accountID = htmlParse($("#accountID").val());
          htmlCount++;
        }
        if (
          $("#accountID")
            .val()
            .indexOf(" ") != -1
        ) {
          $(".errorMsg.accountID").text(
            "Account ID should have no white spaces"
          );
          result = false;
        } else {
          $(".errorMsg.accountID").text("");
        }
      }
      if ($("#accountAlias").val() != "") {
        if (htmlCheck($("#accountAlias").val())) {
          this.aAlias = htmlParse($("#accountAlias").val());
          htmlCount++;
        }
      }
      if ($("#aAddress").val() != "") {
        if (htmlCheck($("#aAddress").val())) {
          this.Address = htmlParse($("#aAddress").val());
          htmlCount++;
        }
      }
      if ($("#aCity").val() != "") {
        if (htmlCheck($("#aCity").val())) {
          this.City = htmlParse($("#aCity").val());
          htmlCount++;
        }
      }
      if ($("#aZip").val() != "") {
        if (htmlCheck($("#aZip").val())) {
          this.Zip = htmlParse($("#aZip").val());
          htmlCount++;
        }
        if (
          !$("#aZip")
            .val()
            .match("^[0-9]*$")
        ) {
          $(".errorMsg.zip").text("Please Enter a Valid Zip Code");
          result = false;
        } else {
          $(".errorMsg.zip").text("");
        }
      }
      // if ($("#aState").val() != "") {
      //   if (htmlCheck($("#aState").val())) {
      //     this.State = htmlParse($("#aState").val());
      //     htmlCount++;
      //   }
      //   if (
      //     !$("#aState")
      //       .val()
      //       .match("^[a-zA-Z ]*$")
      //   ) {
      //     $(".errorMsg.state").text("Please Enter Valid text format");
      //     result = false;
      //   } else {
      //     $(".errorMsg.state").text("");
      //   }
      // }
      if (htmlCount != 0) {
        notifier.alert("HTML content found. This content has been removed!");
        result = false;
      }

      if (result) {
        e.preventDefault();
        this.saving = true;
        const responseAID = await this.createCustomer();
        if (responseAID > 0) {
          if (!this.isPage && this.includedFrom == "accountLinking") {
            this.$emit("doLinkingWith", {
              AID: responseAID,
              ACCOUNTID: this.accountID,
              ANAME: this.aName
            });
          }
        }
        // this.saving = false
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  async createCustomer() {
    var userID = JSON.parse(sessionStorage.getItem("currentUser") || '');
    var userList:number[] = [];
    $.each(this.selectedUsers, function(i, val) {
      userList.push(val.USERID);
    });
    //detect selected security groups
    var sgList:number[] = [];
    $.each(this.selectedGroup, function(i, val) {
      sgList.push(val.SECURITYGROUPID);
    });
    var saveData = {};
    saveData["Controller"] = "Accounts";
    saveData["FunctionName"] = "Add";

    saveData["AccountID"] = this.accountID;
    saveData["User"] = userID[0].USERID;
    saveData["aName"] = this.aName;
    saveData["aAlias"] = this.aAlias;
    saveData["aAddress"] = this.Address;
    saveData["aCity"] = this.City;
    saveData["aState"] = this.State;
    saveData["aZip"] = this.Zip;
    //saveData["aContractID"] = this.ContractID;
    //saveData["aSpecialPricingID"] = this.SpecialPricingID;
    saveData["userList"] = userList.join(",");
    saveData["sgList"] = sgList.join(",");
    saveData["customData"] = JSON.stringify(this.fullCustomData.filter(item => item.AID == 0));
    saveData["isDuplicateName"] = this.isDuplicateName;
    saveData["AccountName_existed"] = this.AccountName_existed;
    if (this.selectedAccountId) {
      // default settings
      saveData["hpDirectTaxRate"] = this.selectedSettings.hpDirectTaxRate;
      saveData["indirectTaxRate"] = this.selectedSettings.indirectTaxRate;
      saveData["QuoteNotes"] = this.selectedSettings.QuoteNotes;
      saveData["ShipmentNotes"] = this.selectedSettings.ShipmentNotes;
      saveData["EndMessage"] = this.selectedSettings.EndMessage;
      saveData["PurchasingInstruction"] = this.selectedSettings.PurchasingInstruction;
      saveData["quote_template"] = this.selectedSettings.quote_template;
      saveData["quoteTemplateDetail"] = this.selectedSettings.quoteTemplateDetail;
      saveData["order_template"] = this.selectedSettings.order_template;
      saveData["invoice_template"] = this.selectedSettings.invoice_template;
      saveData["quote_contactOptionSelected"] = this.selectedSettings.quote_contactOptionSelected;
      saveData["quote_contactValue"] = this.selectedSettings.quote_contactValue;
      saveData["selectedTagsID"] = this.selectedSettings.selectedTagsID;
    }
    var self = this;
    // if (this.customFields.length) {
    //   saveData["customFields"] = JSON.stringify(this.customFields);
    // }

    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", saveData);
      if (response.data.STATUS && response.data.STATUSMESSAGE) {
        const aId = response.data.NEWAID || 0;
        if (this.isPage) {
          if (this.redirectURL.length) {
            this.$router.push({ path: this.redirectURL, 
                                query: { 
                                  aName: this.aName,
                                  aId
                                }
                              });
          } else {
            this.$router.push({ 
              name: "ViewCustomer",
              params: { id: this.accountID}
            });
          }
          self.saving = false;
          return 0;
        } else {
          self.saving = false;
          return response.data.NEWAID;
        }
      } else if (response.data.ERROR) {
        if (response.data.ACCOUNTNAME_EXISTED){
          this.confirmRemoveModalVisible = true;
          this.AccountName_existed = response.data.ACCOUNTNAME_EXISTED;
        }
        else if (response.data.ISDUPLICATE) {
          this.isDuplicateName = response.data.ISDUPLICATE;
        }
        notifier.alert(response.data.ERROR);
        self.saving = "error";
      }
    } catch (err) {
      notifier.alert(err.message);
      self.saving = "error";
    }

    return 0;
  }

  // addCustomField(theField) {
  //   if (
  //     $(".profile-row.customeField .half.left").length > 2 &&
  //     $(".profile-row.customeField .half.left").length % 2 == 0
  //   ) {
  //     $("#page-details").attr(
  //       "style",
  //       "height :" +
  //         " " +
  //         (parseInt(
  //           $("#page-details")
  //             .attr("style")
  //             .split(":")[1]
  //             .slice(0, -3)
  //         ) +
  //           100) +
  //         "px;"
  //     );
  //   }
  //   //just add custom field if field ID is not exist before
  //   if (!$("#" + theField.id).length) {
  //     this.customFields.push(theField);
  //   }
  // }

  // removeCustomField(fieldID) {
  //   //get custom field
  //   var theField = {};
  //   var fieldIndex:any = 0;
  //   for (var i in this.customFields) {
  //     if (this.customFields[i].id == fieldID) {
  //       theField = this.customFields[i];
  //       fieldIndex = i;
  //     }
  //   }

  //   if (Object.keys(theField).length) {
  //     if (
  //       confirm(
  //         "This custom field (" +
  //           theField["label"] +
  //           ") will be deleted. Are you sure?"
  //       )
  //     ) {
  //       this.$delete(this.customFields, fieldIndex);
  //     }
  //   }
  //   if (
  //     $(".profile-row.customeField .half.left").length > 2 &&
  //     $(".profile-row.customeField .half.left").length % 2
  //   ) {
  //     $("#page-details").attr(
  //       "style",
  //       "height :" +
  //         " " +
  //         (parseInt(
  //           $("#page-details")
  //             .attr("style")
  //             .split(":")[1]
  //             .slice(0, -3)
  //         ) -
  //           100) +
  //         "px;"
  //     );
  //     window.scrollTo(0, 0);
  //     window.scrollTo(
  //       0,
  //       $("#page-details")
  //         .attr("style")
  //         .split(":")[1]
  //         .slice(0, -3)
  //     );
  //   }
  // }

  // user multi Select
  toggleSelectedUserId(userId: number, name: string) {
    const index = this.selectedUserIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedUserIds.push(userId);
      this.selectedUsers.push({
        USERID: userId,
        UFULLNAME: name
      });
      return;
    }
    this.selectedUserIds.splice(index, 1);
    this.$delete(this.selectedUsers, index);
  }

  selectAllUsers() {
    this.resetUsers();
    var $this = this;
    this.selectedUserIds = this.users.map(u => u.USERID);
    $.each(this.users, function(i, val) {
      $this.selectedUsers.push({
        USERID: val.USERID,
        UFULLNAME: `${val.UFNAME} ${val.ULNAME}`
      });
    });
  }

  resetUsers() {
    this.selectedUserIds = [];
    this.selectedUsers = [];
  }
  // multi Security groups select
  toggleSelectedGroupId(userId, accountName) {
    const index = this.selectedGroupIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedGroupIds.push(userId);
      this.selectedGroup.push({
        SECURITYGROUPNAME: accountName,
        SECURITYGROUPID: userId
      });
      return;
    }
    this.selectedGroupIds.splice(index, 1);
    this.$delete(this.selectedGroup, index);
  }

  selectAllGroups() {
    this.resetGroups();
    var $this = this;
    this.selectedGroupIds = this.securityGroup.map(u => u.SECURITYGROUPID);

    $.each(this.securityGroup, function(i, val) {
      $this.selectedGroup.push({
        SECURITYGROUPNAME: val.SECURITYGROUPNAME,
        SECURITYGROUPID: val.SECURITYGROUPID
      });
    });
  }

  resetGroups() {
    this.selectedGroupIds = [];
    this.selectedGroup = [];
  }
  // updateLicenseCost(value) {
  //     this.licenseCost = value;
  // }

  // updateCountry (value) {
  //     this.country = value;
  // }

  addCustomerState(selectedID) {
    this.selectedState = [selectedID];
    this.State = selectedID;
  }
  close(){
    this.$refs.drodown.dropdownVisible = false;
	}
  async updateCustomer(data) {
    let self = this;
    self.selectedUserIds = [];
    self.selectedUsers = [];
    self.selectedGroupIds = [];
    self.selectedGroup = [];
    self.selectedAccountId = data.account.ACCOUNTID || 0;
    const response = await axios.post(
      dataURL + "?ReturnType=JSON&tab=details",
      {
        controller: "Customers",
        FunctionName: "View",
        aID: data.account.ACCOUNTID
      }
    );
    if (response.data.SELECTEDUSERS.length) {
      response.data.SELECTEDUSERS.forEach(element => {
        if (self.selectedUserIds.indexOf(element.USERID) < 0) {
          self.selectedUserIds.push(element.USERID);
          self.selectedUsers.push({
            USERID: element.USERID,
            UFULLNAME: element.UFULLNAME
          });
        }
      });
    }
    if (response.data.SELECTEDGROUPS.length) {
      response.data.SELECTEDGROUPS.forEach(element => {
        if (self.selectedGroupIds.indexOf(element.SECURITYGROUPID) < 0) {
          self.selectedGroupIds.push(element.SECURITYGROUPID);
          self.selectedGroup.push({
            SECURITYGROUPNAME: element.SECURITYGROUPNAME,
            SECURITYGROUPID: element.SECURITYGROUPID
          });
        }
      });
    }
    const customerData = response.data.CUSTOMERS[0] || {};
    let quote_templateSelected = response.data.OrderTemplateId;
    let invoice_templateSelected = response.data.InvoiceTemplateId;
    let order_templateSelected = '';
    const quoteTemplateDetail = response.data.AccountQuoteAztempname || "";
    const templateResult = response.data.TEMPLATES.map(item => ({
      ...item,
      QUOTETEMPLATENAME: JSON.parse(item.QUOTETEMPLATENAME || "[]")
    }));
    for (let i = 0; i < templateResult.length; i++) {
      if (templateResult[i].TEMPLATETYPE == 1) {
        //quote
        templateResult[i].selected = false;
        if (
          templateResult[i].TEMPLATEID == response.data.AccountQuoteTemplateId
        ) {
          templateResult[i].selected = true;
          quote_templateSelected = templateResult[i].TEMPLATEID;
        }
      } else if (templateResult[i].TEMPLATETYPE == 3) {
        //order
        templateResult[i].selected = false;
        if (
          templateResult[i].TEMPLATEID == response.data.AccountOrderTemplateId
        ) {
          templateResult[i].selected = true;
          order_templateSelected = templateResult[i].TEMPLATEID;
        }
      } else if (templateResult[i].TEMPLATETYPE == 2) {
        //invoice
        templateResult[i].selected = false;
        if (
          templateResult[i].TEMPLATEID == response.data.AccountInvoiceTemplateId
        ) {

          invoice_templateSelected = templateResult[i].TEMPLATEID;
        }
      }
    }

    // set Quote Contact data
    const accountQuoteContact = response.data.AccountQuoteContact;
    let quoteContactOptionSelected = "";
    let quoteContactValue = "";
    if (accountQuoteContact) {
      quoteContactOptionSelected = accountQuoteContact.QuoteContactOptionSelected || "";
      quoteContactValue = accountQuoteContact.QuoteContactValue || "";
    }

    const SELECTEDTAGS = response.data.SELECTEDTAGS || [];
    console.log('SELECTEDTAGS', SELECTEDTAGS);


    // fill settings data
    self.selectedSettings = {
      hpDirectTaxRate: customerData.HPDIRECTTAXRATE || 0,
      indirectTaxRate: customerData.INDIRECTTAXRATE || 0,
      ShipmentNotes: customerData.SHIPMENTNOTES || '',
      QuoteNotes: customerData.QUOTENOTES || '',
      EndMessage: customerData.ENDMESSAGE || '',
      PurchasingInstruction: customerData.PURCHASINGINSTRUCTION || '',
      quote_template: quote_templateSelected,
      quoteTemplateDetail: quoteTemplateDetail || "",
      order_template: order_templateSelected,
      invoice_template: invoice_templateSelected,
      quote_contactOptionSelected: quoteContactOptionSelected,
      quote_contactValue: quoteContactValue || "",
      selectedTagsID: SELECTEDTAGS.map((item) => item.ACCOUNTTAGID).join(','),
    };
  }

  showCustomData() {
    this.customDataVisible = !this.customDataVisible;
  }
}
