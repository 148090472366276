var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRemitAddress : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":73,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInfoHeader : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":2},"end":{"line":143,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <table width=\"100%\" id=\"quoteDetails_header\">\n      <tr>\n        <td style=\"padding-right: 5px;padding-top: 20px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showRemitAddress),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":28,"column":17}}})) != null ? stack1 : "")
    + "        </td>\n        <td colspan=\"2\"><img src=\"https://smartstoresgeneric.s3.amazonaws.com/mail/milestonetechlogo.png\" style=\"display:block; border: 0; float: right; height: 52px;\" border=\"0\" /></td>\n      </tr>\n      <tr>\n        <td colspan=\"3\">\n          <table width=\"100%\">\n            <tr>\n              <td style=\"padding: 20px 0;font-size: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":36,"column":52},"end":{"line":36,"column":145}}})) != null ? stack1 : "")
    + "px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\">\n                <span style=\"color: #0196d5;\">PURCHASE ORDER #:</span>\n                <span style=\"font-size: 22px !important;\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":38,"column":58},"end":{"line":38,"column":175}}})) != null ? stack1 : "")
    + "</span>\n              </td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showLogo),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":14},"end":{"line":46,"column":21}}})) != null ? stack1 : "")
    + "            </tr>\n          </table>\n        </td>\n      </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":6},"end":{"line":67,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SPECIALPRICINGID),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":6},"end":{"line":71,"column":13}}})) != null ? stack1 : "")
    + "    </table>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + " <br />\n            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":141}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "              <strong>Milestone Computer Technology Inc.</strong>\n            ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress), depth0)) != null ? stack1 : "")
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "              314 Wilcox St. Ste.\n            ";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState), depth0)) != null ? stack1 : "")
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "              101 Castle Rock, CO 80104\n            ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo), depth0)) != null ? stack1 : "")
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":25,"column":14},"end":{"line":25,"column":156}}})) != null ? stack1 : "")
    + "\n            ";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText), depth0))
    + " ";
},"21":function(container,depth0,helpers,partials,data) {
    return "303-848-8405 ";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue), depth0))
    + " ";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Email), depth0))
    + " ";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize), depth0));
},"29":function(container,depth0,helpers,partials,data) {
    return "26";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO), depth0))
    + " ";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + " ";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LOGOSRC)) && stack1.length),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":45,"column":23}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <td align=\"right\" colspan=\"2\" style=\"border-radius: 50%;\"><img src="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LOGOSRC), depth0))
    + " style=\"height: 75px;\"/></td>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showTempLogo),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":45,"column":16}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    return "                  <td align=\"right\" colspan=\"2\"><span style=\"padding: 10px; background-color: #cdcdcd; text-align:center; color: white;\">Customer Logo</span></td>\n                ";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <tr>\n          <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 20px;letter-spacing: 1.7px;\">\n            <strong>Ship To: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":54,"column":29},"end":{"line":54,"column":149}}})) != null ? stack1 : "")
    + "</strong><br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":64,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO), depth0));
},"44":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0));
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data,"loc":{"start":{"line":58,"column":14},"end":{"line":63,"column":21}}})) != null ? stack1 : "");
},"49":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":62,"column":103}}})) != null ? stack1 : "")
    + "\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<strong>"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ACITY), depth0))
    + ",\n                "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ASTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AZIP), depth0))
    + "</strong>";
},"54":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <tr><td width=\"30%\" class=\"title\">Contract Number</td></tr>\n        <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SPECIALPRICINGID), depth0))
    + "</strong></td></tr>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":4},"end":{"line":142,"column":11}}})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <table width=\"100%\" id=\"quoteDetails_info\">\n        <tr>\n          <td width=\"60%\">\n            <table>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":14},"end":{"line":83,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderNO),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":14},"end":{"line":87,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderDate),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":14},"end":{"line":91,"column":21}}})) != null ? stack1 : "")
    + "            </table>\n          </td>\n          <td width=\"40%\">\n            <table>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":14},"end":{"line":99,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":14},"end":{"line":109,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":14},"end":{"line":119,"column":21}}})) != null ? stack1 : "")
    + "            </table>\n          </td>\n        </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":8},"end":{"line":126,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":8},"end":{"line":130,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.program(84, data, 0),"data":data,"loc":{"start":{"line":132,"column":8},"end":{"line":140,"column":15}}})) != null ? stack1 : "")
    + "      </table>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">Customer PO </td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + "</strong></td></tr>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">Order Number</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PURCHASEID), depth0))
    + "</strong></td></tr>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">Order Date</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED), depth0))
    + "</strong></td></tr>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">Account Rep</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "</strong></td></tr>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">Phone</td></tr>\n                <tr><td><strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(71, data, 0),"data":data,"loc":{"start":{"line":103,"column":18},"end":{"line":107,"column":25}}})) != null ? stack1 : "")
    + "                </strong></td></tr>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":106,"column":20},"end":{"line":106,"column":162}}})) != null ? stack1 : "")
    + "\n";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">Email</td></tr>\n                <tr><td><strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(74, data, 0),"inverse":container.program(76, data, 0),"data":data,"loc":{"start":{"line":113,"column":18},"end":{"line":117,"column":25}}})) != null ? stack1 : "")
    + "                </strong></td></tr>\n";
},"74":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"76":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":116,"column":20},"end":{"line":116,"column":149}}})) != null ? stack1 : "")
    + "\n";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <tr><td width=\"30%\" class=\"title\">Notes</td></tr>\n          <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES), depth0))
    + "</strong></td></tr>\n";
},"80":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <tr><td width=\"30%\" class=\"title\">Shipment Notes</td></tr>\n          <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES), depth0))
    + "</strong></td></tr>\n";
},"82":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n          <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS), depth0))
    + "</strong></td></tr>\n";
},"84":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS),{"name":"if","hash":{},"fn":container.program(85, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":10},"end":{"line":139,"column":17}}})) != null ? stack1 : "");
},"85":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n            <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS), depth0))
    + "</strong></td></tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.templateItemReorder : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":144,"column":9}}})) != null ? stack1 : "");
},"useData":true});