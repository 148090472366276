
















































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";

declare const dataURL: string;
declare const $: any;

interface Props {
  data: any;
  title: string;
}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Datepicker
  }
})
export default class ModalEditOrderLine extends TSXComponent<void> {
  @Prop({ required: true, default: [] })
  data!: any;

  @Prop({ required: true, default: '' })
  title!: string;

  @Prop({ required: true, default: 0 })
  purchaseID!: number

  formData: {
    controls: {
      status: {
        value: string | number
      },
      orderDate: {
        value: string;
        error: string;
      },
      shippedDate: {
        value: string;
        error: string;
      }
    }
  } = {
    controls: {
      status: {
        value: ''
      },
      orderDate: {
        value: '',
        error: '',
      },
      shippedDate: {
        value: '',
        error: '',
      }
    }
  };

  saving: boolean | "error" = false;
  showField = false;
  $parent: any;
  sourceOrderNumber: string = "";

  $refs!: {
    modal: HTMLDivElement;
  };

  created() {
    let status = this.data.STATUSID || '';
    let statusTargetDate = this.data.STATUSTARGETDATE_FORMATTED || '';
    let shippedDate = this.data.SHIPPEDDATE_FORMATTED || '';
    if (this.data) {
      switch (this.data.POLINESTATUS || '') {
        case 'Estimated Shipment':
          status = -3;
          this.formData.controls.orderDate.value = statusTargetDate ? moment(statusTargetDate).format("MM/DD/YYYY") : '';
          break;
        case 'Shipped':
          status = -2;
          break;
        case 'Delivered':
          status = -1;
          break;
        case 'Production':
          status = -4;
          break;
        case 'Placed':
          status = -5;
          break;
      }
    }
    this.formData.controls.status.value = status;
    this.formData.controls.shippedDate.value = shippedDate ? moment(shippedDate).format("MM/DD/YYYY") : '';
    this.sourceOrderNumber = this.data.LINEITEMSOURCEORDERNUMBER || '';
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
    $('.datepicker-container.datepicker-dropdown').addClass('datepicker-hide');
  }

  async onSave() {
    let hasError = false;
    let errorMessage = '';
    this.formData.controls.orderDate.error = '';
    this.formData.controls.shippedDate.error = '';
    if (this.formData.controls.status.value && this.formData.controls.status.value == -3) {
      if (!moment(this.formData.controls.orderDate.value).isValid()) {
        this.formData.controls.orderDate.error = errorMessage = 'Target Date is required';
        hasError = true;
      }
    }
    if (this.formData.controls.status.value && this.formData.controls.status.value == -2) {
      if (!moment(this.formData.controls.shippedDate.value).isValid()) {
        this.formData.controls.shippedDate.error = errorMessage = 'Shipped Date is required';
        hasError = true;
      }
    }
    if (hasError) {
      ApiHelper.showErrorMessage(errorMessage);
      return false;
    }


    this.saving = true;
    const result = await ApiHelper.callApi(
      'post',
      {
        controller: "Orders",
        FunctionName: "UpdateItemLineStatus",
        poProdSku: this.data.POPRODSKU || '',
        poLiId: this.data.POLIID || '',
        statusId: this.formData.controls.status.value || 0,
        statusTargetDate: this.formData.controls.orderDate.value ? moment(this.formData.controls.orderDate.value).format("YYYY-MM-DD") : "",
        shippedDate: this.formData.controls.shippedDate.value ? moment(this.formData.controls.shippedDate.value).format("YYYY-MM-DD") : "",
        purchaseID: this.purchaseID,
        sourceOrderNumber: this.sourceOrderNumber,
        aId: this.$parent.details.ORDERDETAIL.AID
      }
    );
    this.saving = false;
    if (result.STATUS == 1) {
      this.$emit('callback', this.data.POLIID, this.formData.controls.status.value);
      ApiHelper.showSuccessMessage('Updated Status');
      this.$store.dispatch('getAlertCount');
    } else {
      ApiHelper.showErrorMessage(result.STATUSMESSAGE || 'Something was wrong');
    }
  }

  addField() {
    if (this.formData.controls.status.value == "-3") {
      this.showField = true;
    } else {
      this.showField = false;
    }
    if (this.formData.controls.status.value == -2 && !this.formData.controls.shippedDate.value.length) {
      this.formData.controls.shippedDate.value = moment(new Date()).format("MM/DD/YYYY");
    }
    if (moment(this.formData.controls.orderDate.value).isValid()) {
      this.formData.controls.shippedDate.value = this.formData.controls.orderDate.value;
    }
  }
}
