var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr>\n      <td width=\"20%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.ACCOUNTID || (depth0 != null ? depth0.ACCOUNTID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTID","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":61}}}) : helper)))
    + "</td>\n      <td width=\"40%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.ANAME || (depth0 != null ? depth0.ANAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ANAME","hash":{},"data":data,"loc":{"start":{"line":11,"column":48},"end":{"line":11,"column":57}}}) : helper)))
    + "</td>\n      <td width=\"20%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.TOTALORDER_FORMATTED || (depth0 != null ? depth0.TOTALORDER_FORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALORDER_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":12,"column":48},"end":{"line":12,"column":72}}}) : helper)))
    + "</td>\n      <td width=\"20%\" style=\"text-align: right;\"><span"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.TOTALSALESINCREASED : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":112}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.TOTALSALES || (depth0 != null ? depth0.TOTALSALES : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALSALES","hash":{},"data":data,"loc":{"start":{"line":13,"column":113},"end":{"line":13,"column":127}}}) : helper)))
    + "</span> / <span>"
    + alias4(((helper = (helper = helpers.TOTALPREVSALES || (depth0 != null ? depth0.TOTALPREVSALES : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALPREVSALES","hash":{},"data":data,"loc":{"start":{"line":13,"column":143},"end":{"line":13,"column":161}}}) : helper)))
    + "</span></td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " style=\"color: #28a745;\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"customerList_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"20%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">ACCOUNT ID</td>\n    <td width=\"40%\" style=\"border-bottom: 5px solid #b1b1b1;\">CUSTOMER</td>\n    <td width=\"20%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">Total Orders</td>\n    <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">Total YTD $ / Prior YTD $</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.CUSTOMERS : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "</table>";
},"useData":true});