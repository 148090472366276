























































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../LaddaButton.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";
import directives from "@/helpers/directives";
import { v4 as uuidv4 } from "uuid";
import Loader from "@/components/Loader.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import { getRandomNumber } from "@/helpers/ApiHelper";

interface Props {
  accounts: [];
  users: [];
}

interface Events {}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Datepicker,
    Loader,
    ConfirmRemoveItemModal,
    DropdownControl,
    FormMultiSelect
  },
  directives
})
export default class SetAvailabilityModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: {} })
  settings?: any;

  @Prop({ required: false, default: 0 })
  aId!: number;

  $refs!: {
    modal: HTMLDivElement;
    dropdownControl: any;
  };

  $parent: any;
  days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  availabilityName = "";
  available: any = {
    start: "",
    end: "",
    daysOfWeek: [],
    duration: {
      hours: "",
      startTime: "",
      startTimePeriod: "",
      endTime: "",
      endTimePeriod: ""
    },
    spots: "",
    availabilityId: 0
  };
  exceptionItem: any = {
    id: "",
    start: "",
    end: "",
    betweenTimes: {
      start: "",
      startPeriod: "",
      end: "",
      endPeriod: ""
    }
  };
  exceptions: any = [];
  saving: boolean | string = false;
  loading = false;
  availabilityId = 0;
  availabilitiesList: any = [];
  selectedIdToRemove = 0;
  confirmRemoveVisible = false;
  deleteLoading = false;
  criteriaFilters: any = [];
  euFields: any = [];
  selectedCustomFieldIds: any = [];
  selectedCFOptionIds: number[] = [];
  inputOptionText = "";

  clickOutside(e) {
    if (
      $(e.target).hasClass("confirm-remove-availability") ||
      $(e.target).closest(".confirm-remove-availability").length
    ) {
      return;
    }

    this.$emit("close");
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  mounted() {
    $(this.$refs.modal).modal("show");
  }

  async created() {
    // populdate data
    if (this.settings.available) {
      this.available = JSON.parse(JSON.stringify(this.settings.available));
    }
    if (this.settings.exceptions) {
      this.exceptions = JSON.parse(JSON.stringify(this.settings.exceptions));
    }
    if (this.settings.availabilitiesList) {
      this.availabilitiesList = JSON.parse(
        JSON.stringify(this.settings.availabilitiesList)
      );
    }

    // get availability
    try {
      this.loading = true;
      const response = await ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "GetAvailabilitiesList",
        deploymentId: this.deploymentId,
        aId: this.aId
      });
      this.loading = false;
      if (response.STATUS == 1) {
        this.euFields = (response.euFields || []).filter(
          t => t.CUSTOMFIELDID != "requestName"
        );

        if (this.deploymentId) {
          // update list if saved before for this deployment
          this.availabilitiesList = response.availabilitiesList || [];
          for (const item of this.availabilitiesList) {
            item.exceptions = (item.exceptions || []).map(t => ({
              id: uuidv4(),
              ...t
            }));

            // fix customFieldName empty of saved criteriaFilters
            for (const criteria of item.criteriaFilters || []) {
              if (criteria.customFieldKey && criteria.customFieldName == "") {
                const inList = this.euFields.find(
                  t => t.CUSTOMFIELDID == criteria.customFieldKey
                );
                if (inList) {
                  criteria.customFieldName = inList.CUSTOMFIELDNAME || "";
                }
              }
            }
          }
        }

        // auto select if just has one availability
        if (this.availabilitiesList.length == 1) {
          const availability = JSON.parse(
            JSON.stringify(this.availabilitiesList[0])
          );
          this.availabilityId = availability.available.availabilityId || 0;
          if (this.availabilityId) {
            this.availabilityName = availability.name || "";
            this.available = availability.available || {};
            this.exceptions = availability.exceptions || [];
            this.criteriaFilters = availability.criteriaFilters || [];
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async setAvailability() {
    // valid
    let valid = await this.$validator.validateAll();

    // if empty
    if (
      !this.available.start &&
      !this.available.duration.hours &&
      !this.available.duration.startTime &&
      !this.available.duration.endTime &&
      !this.available.duration.startTimePeriod &&
      !this.available.duration.endTimePeriod &&
      !this.available.daysOfWeek.length
    ) {
      valid = false;
    }

    // more validate unique name at page create new deployment
    if (valid && !this.deploymentId) {
      let found = null;
      if (this.availabilityId) {
        found = this.availabilitiesList.find(
          item =>
            item.name.toLowerCase() == this.availabilityName.toLowerCase() &&
            item.available.availabilityId != this.availabilityId
        );
      } else {
        found = this.availabilitiesList.find(
          item => item.name.toLowerCase() == this.availabilityName.toLowerCase()
        );
      }
      if (found) {
        valid = false;
        this.$validator.errors.add({
          field: "availabilityName",
          msg: "Availability Name already exists"
        });
      }
    }

    if (!valid) {
      return;
    }

    // add exception if any
    this.addException();

    // add criteria filter if any
    this.addCriteriaFilter();

    if (this.deploymentId) {
      try {
        this.saving = true;
        const response = await ApiHelper.callApi("post", {
          Controller: "Deployments",
          FunctionName: "UpdateAvailability",
          deploymentId: this.deploymentId,
          availabilityId: this.availabilityId,
          available: this.available,
          exceptions: this.exceptions,
          availabilityName: this.availabilityName,
          criteriaFilters: this.criteriaFilters
        });

        if (response.STATUS == 1) {
          this.saving = false;
          const returnedId = parseInt(response.availabilityId) || 0;
          // update latest info of related item in list
          this.availabilityId = returnedId;
          this.available.availabilityId = returnedId;
          const inList = this.availabilitiesList.find(
            item => item.available.availabilityId == this.availabilityId
          );
          if (inList) {
            // update
            inList.available = JSON.parse(JSON.stringify(this.available));
            inList.name = this.availabilityName;
            inList.exceptions = JSON.parse(JSON.stringify(this.exceptions));
            inList.criteriaFilters = JSON.parse(
              JSON.stringify(this.criteriaFilters)
            );
          } else {
            // add new, append to list
            this.availabilitiesList.push({
              available: JSON.parse(JSON.stringify(this.available)),
              name: this.availabilityName,
              exceptions: JSON.parse(JSON.stringify(this.exceptions)),
              criteriaFilters: JSON.parse(JSON.stringify(this.criteriaFilters))
            });
          }
          notifier.success(response.STATUSMESSAGE);

          // if is in deployment details page
          if (this.$route.name == "ViewDeployment") {
            this.$parent.deploymentDetails.HASTIMESLOTS =
              response.HASTIMESLOTS || 0;
            this.$parent.availabilitiesList = JSON.parse(
              JSON.stringify(this.availabilitiesList)
            );
          }
          // this.$emit("close");
        } else {
          this.saving = "error";
          const errorCode = response.errorCode || "";
          const message = response.STATUSMESSAGE || "";
          if (errorCode == "name_existed" && message) {
            this.$validator.errors.add({
              field: "availabilityName",
              msg: message
            });
          }
        }
      } catch (err) {
        this.saving = "error";
        console.log(err);
      }
    } else {
      // case add new deployment
      if (!this.availabilityId) {
        this.availabilityId = getRandomNumber(100000, 999999);
      }
      this.available.availabilityId = this.availabilityId;
      const inList = this.availabilitiesList.find(
        item => item.available.availabilityId == this.availabilityId
      );
      if (inList) {
        // update
        inList.available = JSON.parse(JSON.stringify(this.available));
        inList.name = this.availabilityName;
        inList.exceptions = JSON.parse(JSON.stringify(this.exceptions));
        inList.criteriaFilters = JSON.parse(
          JSON.stringify(this.criteriaFilters)
        );
      } else {
        // add new, append to list
        this.availabilitiesList.push({
          available: JSON.parse(JSON.stringify(this.available)),
          name: this.availabilityName,
          exceptions: JSON.parse(JSON.stringify(this.exceptions)),
          criteriaFilters: JSON.parse(JSON.stringify(this.criteriaFilters))
        });
      }

      this.$emit("updateAvailability", {
        available: this.available,
        exceptions: this.exceptions,
        criteriaFilters: this.criteriaFilters,
        availabilitiesList: this.availabilitiesList
      });
      // this.$emit("close");
    }
  }

  addException() {
    // validate
    let valid = true;
    // if empty
    if (
      !this.exceptionItem.start &&
      !this.exceptionItem.betweenTimes.start &&
      !this.exceptionItem.betweenTimes.end &&
      !this.exceptionItem.betweenTimes.startPeriod &&
      !this.exceptionItem.betweenTimes.endPeriod
    ) {
      valid = false;
    }
    // need to check both time & period (start time)
    if (
      (this.exceptionItem.betweenTimes.start &&
        !this.exceptionItem.betweenTimes.startPeriod) ||
      (!this.exceptionItem.betweenTimes.start &&
        this.exceptionItem.betweenTimes.startPeriod)
    ) {
      valid = false;
    }
    // need to check both time & period (end time)
    if (
      (this.exceptionItem.betweenTimes.end &&
        !this.exceptionItem.betweenTimes.endPeriod) ||
      (!this.exceptionItem.betweenTimes.end &&
        this.exceptionItem.betweenTimes.endPeriod)
    ) {
      valid = false;
    }

    if (!valid) return;

    this.exceptionItem.id = uuidv4();
    this.exceptions.push(this.exceptionItem);
    this.resetExceptionItem();
  }

  resetExceptionItem() {
    this.exceptionItem = {
      id: "",
      start: "",
      end: "",
      betweenTimes: {
        start: "",
        startPeriod: "",
        end: "",
        endPeriod: ""
      }
    };
  }

  getExceptionDates(item) {
    const ret: any = [];
    if (item.start && moment(item.start).isValid()) {
      ret.push(moment(item.start).format("MM/DD/YY"));
    }
    if (item.end && moment(item.end).isValid()) {
      ret.push(moment(item.end).format("MM/DD/YY"));
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  getExceptionTimes(item: any) {
    const ret: any = [];
    const betweenTimes = item.betweenTimes;
    if (betweenTimes.start && betweenTimes.startPeriod) {
      ret.push(`${betweenTimes.start} ${betweenTimes.startPeriod}`);
    }
    if (betweenTimes.end && betweenTimes.endPeriod) {
      ret.push(`${betweenTimes.end} ${betweenTimes.endPeriod}`);
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  removeException(item) {
    this.exceptions = this.exceptions.filter(t => t.id != item.id);
  }

  getAvailabilityInfoStr(item) {
    let ret: string[] = [];
    // start/ end date
    ret.push(this.getAvailableDates(item));
    // days of week
    if (item.available.daysOfWeek.length) {
      ret.push(item.available.daysOfWeek.join(", "));
    }
    // start/ end time
    ret.push(this.getAvailableTimes(item));

    return ret
      .filter(t => t != "")
      .join(" | ")
      .toUpperCase();
  }

  getAvailableDates(item) {
    const ret: any = [];
    if (item.available.start && moment(item.available.start).isValid()) {
      ret.push(moment(item.available.start).format("MM/DD/YY"));
    }
    if (item.available.end && moment(item.available.end).isValid()) {
      ret.push(moment(item.available.end).format("MM/DD/YY"));
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  getAvailableTimes(item: any) {
    const ret: any = [];
    const duration: any = item.available.duration;
    if (duration.startTime && duration.startTimePeriod) {
      ret.push(`${duration.startTime} ${duration.startTimePeriod}`);
    }
    if (duration.endTime && duration.endTimePeriod) {
      ret.push(`${duration.endTime} ${duration.endTimePeriod}`);
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  resetCurrentAvailability() {
    this.$refs.dropdownControl.resetSearch();
    this.availabilityId = 0;
    this.availabilityName = "";
    this.available = {
      start: "",
      end: "",
      daysOfWeek: [],
      duration: {
        hours: "",
        startTime: "",
        startTimePeriod: "",
        endTime: "",
        endTimePeriod: ""
      },
      spots: "",
      availabilityId: 0
    };
    this.exceptions = [];
    this.criteriaFilters = [];
    this.selectedCustomFieldIds = [];
    this.selectedCFOptionIds = [];
    this.inputOptionText = "";
    this.$nextTick().then(() => {
      this.$validator.errors.clear();
    });
  }

  addAvailability() {
    this.resetCurrentAvailability();
    $("#availabilityName").focus();
  }

  loadAvailability(item) {
    this.resetCurrentAvailability();
    // populate selected info to the right form
    this.availabilityId = item.available.availabilityId;
    this.availabilityName = item.name;
    this.available = JSON.parse(JSON.stringify(item.available));
    this.exceptions = JSON.parse(JSON.stringify(item.exceptions));
    this.criteriaFilters = JSON.parse(
      JSON.stringify(item.criteriaFilters || [])
    );
  }

  async removeAvailability() {
    if (!this.selectedIdToRemove) return;

    if (this.deploymentId) {
      try {
        this.deleteLoading = true;
        const response = await ApiHelper.callApi("post", {
          Controller: "Deployments",
          FunctionName: "UpdateAvailability",
          action: "remove",
          deploymentId: this.deploymentId,
          availabilityId: this.selectedIdToRemove
        });
        this.deleteLoading = false;
        if (response.STATUS == 1) {
          this.confirmRemoveVisible = false;
          this.availabilitiesList = this.availabilitiesList.filter(
            item => item.available.availabilityId != this.selectedIdToRemove
          );
          if (this.availabilityId == this.selectedIdToRemove) {
            this.resetCurrentAvailability();
          }
          this.selectedIdToRemove = 0;

          // if is in deployment details page
          if (this.$route.name == "ViewDeployment") {
            this.$parent.deploymentDetails.HASTIMESLOTS =
              response.HASTIMESLOTS || 0;
          }

          notifier.success("Deleted successfully");
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.deleteLoading = false;
      }
    } else {
      // case add new deployment
      this.confirmRemoveVisible = false;
      this.availabilitiesList = this.availabilitiesList.filter(
        item => item.available.availabilityId != this.selectedIdToRemove
      );
      if (this.availabilityId == this.selectedIdToRemove) {
        this.resetCurrentAvailability();
      }
      this.selectedIdToRemove = 0;
      this.$emit("updateAvailability", {
        available: this.available,
        exceptions: this.exceptions,
        criteriaFilters: this.criteriaFilters,
        availabilitiesList: this.availabilitiesList
      });
    }
  }

  get confirmRemoveMessage() {
    if (!this.selectedIdToRemove) return "";
    return `You are about to delete this availability &quot;${this.availabilitiesList
      .find(t => t.available.availabilityId == this.selectedIdToRemove)
      .name.toUpperCase()}&quot;. Are you sure?`;
  }

  get selectedCustomField() {
    if (!this.selectedCustomFieldIds.length) return {};

    const selected = this.euFields.find(item =>
      this.selectedCustomFieldIds.includes(item.CUSTOMFIELDID)
    );

    return selected;
  }

  get selectedCFOptionTexts() {
    if (
      (this.selectedCustomField.CUSTOMFIELDID || 0) > 0 &&
      this.selectedCFOptionIds.length
    ) {
      const ret = (this.selectedCustomField.OPTIONS || [])
        .filter(t => this.selectedCFOptionIds.includes(t.CUSTOMFIELDOPTIONID))
        .map(t => t.CUSTOMFIELDOPTIONNAME);
      return ret.join(", ");
    } else {
      return "Pick Filter Option";
    }
  }

  toggleCustomFieldOptions(id, text) {
    if (!this.selectedCFOptionIds.includes(id)) {
      this.selectedCFOptionIds.push(id);
    } else {
      this.selectedCFOptionIds = this.selectedCFOptionIds.filter(t => t != id);
    }
  }

  addCriteriaFilter() {
    let valid = true;
    if (
      !(this.selectedCustomField.CUSTOMFIELDID || 0) ||
      (!this.selectedCFOptionIds.length && this.inputOptionText == "")
    ) {
      valid = false;
    }
    if (!valid) return;

    let value: string[] = [];
    const customFieldId = this.selectedCustomField.CUSTOMFIELDID;
    const customDType = this.selectedCustomField.CUSTOMDTYPE;
    if (customDType == 3) {
      value = (this.selectedCustomField.OPTIONS || [])
        .filter(t => this.selectedCFOptionIds.includes(t.CUSTOMFIELDOPTIONID))
        .map(t => t.CUSTOMFIELDOPTIONNAME);
    } else {
      value = [this.inputOptionText];
    }
    const inList = this.criteriaFilters.find(
      item =>
        item.customFieldId == customFieldId ||
        (item.customFieldKey || "") == customFieldId
    );
    if (!inList) {
      this.criteriaFilters.push({
        customFieldId,
        customFieldName: this.selectedCustomField.CUSTOMFIELDNAME,
        customDType,
        value
      });
    } else {
      inList.value = value;
    }

    // reset
    this.selectedCustomFieldIds = [];
    this.selectedCFOptionIds = [];
    this.inputOptionText = "";
  }

  removeCriteriaFilter(item) {
    this.criteriaFilters = this.criteriaFilters.filter(
      t => t.customFieldId != item.customFieldId
    );
  }
}
