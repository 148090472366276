var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <td class=\"tdcolor\" style ='font-weight: 600;letter-spacing: 1.4px;color:#38c4c5;text-align:right;'>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.totalSalesStatusNow : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          "
    + container.escapeExpression(((helper = (helper = helpers.totalSalesStatusNow || (depth0 != null ? depth0.totalSalesStatusNow : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalSalesStatusNow","hash":{},"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":7,"column":33}}}) : helper)))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          "
    + container.escapeExpression(((helper = (helper = helpers.SALESTHISYEARTREND || (depth0 != null ? depth0.SALESTHISYEARTREND : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SALESTHISYEARTREND","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":32}}}) : helper)))
    + " <img src='https://var360ui.dev-us.com/reseller/images/simple-up.png'>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <td class=\"tdcolor\" style ='font-weight: 600;letter-spacing: 1.4px;color:#f04333;text-align:right;'>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.totalSalesStatusNow : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          "
    + container.escapeExpression(((helper = (helper = helpers.SALESTHISYEARTREND || (depth0 != null ? depth0.SALESTHISYEARTREND : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SALESTHISYEARTREND","hash":{},"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":17,"column":32}}}) : helper)))
    + " <img src='https://var360ui.dev-us.com/reseller/images/simple-down.png'>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <td class=\"tdcolor\" style ='font-weight: 600;letter-spacing: 1.4px;color:#38c4c5;text-align:right;'>"
    + container.escapeExpression(((helper = (helper = helpers.UNITTHISYEARTREND || (depth0 != null ? depth0.UNITTHISYEARTREND : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"UNITTHISYEARTREND","hash":{},"data":data,"loc":{"start":{"line":26,"column":106},"end":{"line":26,"column":127}}}) : helper)))
    + " <img src='https://var360ui.dev-us.com/reseller/images/simple-up.png'></td>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <td class=\"tdcolor\" style ='font-weight: 600;letter-spacing: 1.4px;color:#f04333;text-align:right;'>"
    + container.escapeExpression(((helper = (helper = helpers.UNITTHISYEARTREND || (depth0 != null ? depth0.UNITTHISYEARTREND : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"UNITTHISYEARTREND","hash":{},"data":data,"loc":{"start":{"line":28,"column":106},"end":{"line":28,"column":127}}}) : helper)))
    + " <img src='https://var360ui.dev-us.com/reseller/images/simple-down.png'></td>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.TOPNCATEGORYQTY : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":2},"end":{"line":46,"column":11}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr>\n      <td class=\"tdcolor\">"
    + alias4(((helper = (helper = helpers.CATEGORY || (depth0 != null ? depth0.CATEGORY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CATEGORY","hash":{},"data":data,"loc":{"start":{"line":43,"column":26},"end":{"line":43,"column":38}}}) : helper)))
    + "</td>\n      <td class=\"tdcolor\" style=\"text-align:right;\">"
    + alias4(((helper = (helper = helpers.TOTALQTY || (depth0 != null ? depth0.TOTALQTY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALQTY","hash":{},"data":data,"loc":{"start":{"line":44,"column":52},"end":{"line":44,"column":64}}}) : helper)))
    + "</td>\n    </tr>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "    <tr>\n      <td class=\"tdcolor\" colspan=\"2\">No Category Trends Yet.</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<table width=\"100%\" cellpadding=\"10\" cellspacing=\"0\" border=\"1\" style=\"font:Verdana, Arial, Helvetica, sans-serif;\">\n  <tr>\n    <th class=\"thcolor\">TOTAL SALES</th>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.SALESTHISYEARTRENDSTATUS : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <td class=\"tdcolor\" style=\"text-align:right;\">"
    + alias4(((helper = (helper = helpers.SALESTHISYEAR || (depth0 != null ? depth0.SALESTHISYEAR : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SALESTHISYEAR","hash":{},"data":data,"loc":{"start":{"line":21,"column":50},"end":{"line":21,"column":67}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <th class=\"thcolor\">TOTAL UNITS ORDERED</th>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.UNITSTHISYEARTRENDSTATUS : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + "    <td class=\"tdcolor\" style=\"text-align:right;\">"
    + alias4(((helper = (helper = helpers.TOTALUNITTHISYEAR || (depth0 != null ? depth0.TOTALUNITTHISYEAR : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALUNITTHISYEAR","hash":{},"data":data,"loc":{"start":{"line":30,"column":50},"end":{"line":30,"column":71}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <th class=\"thcolor\">AVERAGE DELIVERY TIME</th>\n    <td class=\"tdcolor\" style=\"text-align:right;\">"
    + alias4(((helper = (helper = helpers.AVGDAY || (depth0 != null ? depth0.AVGDAY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AVGDAY","hash":{},"data":data,"loc":{"start":{"line":34,"column":50},"end":{"line":34,"column":60}}}) : helper)))
    + "</td>\n    <td class=\"tdcolor\" style=\"text-align:right;\">"
    + alias4(((helper = (helper = helpers.AVGTIME || (depth0 != null ? depth0.AVGTIME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AVGTIME","hash":{},"data":data,"loc":{"start":{"line":35,"column":50},"end":{"line":35,"column":61}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <th rowspan=\"10\" class=\"thcolor\">CATEGORY TRENDS (SOLD)</th>\n  </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TOPNCATEGORYQTY : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":40,"column":2},"end":{"line":51,"column":9}}})) != null ? stack1 : "")
    + "</table>  ";
},"useData":true});