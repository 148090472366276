























import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Loader from "./Loader.vue";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader
  }
})
export default class DistyPAOptionsTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: {} })
  currentItem!: any;

  $parent: any;
  loading = false;

  clickOutside() {
    this.$emit("close");
  }

  selectAllText(id) {
    setTimeout(() => {
      $("#" + id).select();  
    }, 100);
  }

  
}
