














































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue"
import { wait } from "../models/common";
import { ApiHelper } from "@/helpers/all";

interface Props {
  title: string
  selectedIDs: string[],
  selectedAll: boolean,
  totalAll: number,
  customMessage: string,
  customCheckbox: string,
  useLaddaButton: boolean,
  progressBar: number
}

interface Events {
  onClose: void
  onConfirm: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class ConfirmRemoveItemModal extends TSXComponent<
  Props,
  Events
> {
  @Prop({required: true})
  title!: string

  @Prop({required: false, default: []})
  selectedIDs!: string[]

  @Prop({required: false, default: false})
  selectedAll!: boolean

  @Prop({required: false, default: 0})
  totalAll!: number

  @Prop({required: false, default: ""})
  customMessage!: string

  @Prop({required: false, default: ""})
  customCheckbox?: string

  @Prop({required: false, default: false})
  useLaddaButton!: boolean

  @Prop({required: false, default: 0})
  progressBar!: number

  @Prop({required: false, default: "Confirm"})
  confirmLabel!: string

  @Prop({required: false, default: "Cancel"})
  cancelLabel!: string

  @Prop({required: false, default: false})
  deleting!: any

  @Prop({required: false, default: []})
  selectedPaycheckDetails!: any

  @Prop({required: false, default: []})
  cancelPaycheckDetails!: any

  @Prop({required: false, default: ""})
  invoiceIds!: string
  
  @Prop({required: false, default: ""})
  statementids!: string

  @Prop({required: false, default: false})
  isPaycheckPreview?: boolean

  @Prop({ required: false, default: false })
  showNoBtn!: boolean

  @Prop({ required: false, default: false })
  isUpdateTemplateDetails!: boolean;

  saving = false
  // deleting: any = false;
  fullWidth = "width: 0%;";
  width = 0;
  inProgress = false;
  progressBarModal = false;
  seconds = this.progressBar;
  intervalId: any = 0;
  customCheckboxVal = false;
  selectedPaycheckIds: any = [];
  paycheckPreview = [];
  paycheckPreviewLoading = false;
  saveExport: boolean = false;
  updateDetailsExport: boolean = false;

  $refs!: {
    modal: HTMLDivElement
  }

  async mounted() {
    $(this.$refs.modal).modal('show');
    this.$emit('afterShow');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });

    $(this.$refs.modal).on('hidden.bs.modal', () => {
      if ($('.modal.show:visible').length) {
        $('body').addClass('modal-open');
      }
    });

    if(this.customCheckbox) {
      // auto check custom checkbox
      this.customCheckboxVal = true;
    }

    if(this.selectedPaycheckDetails.length) {
      await wait(100);
      this.$forceUpdate();
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  created() {
    if (this.cancelPaycheckDetails.length) {
      this.cancelPaycheckDetails.map((item: any) => {
        this.selectedPaycheckIds.push(item.PAYCHECKID);
      });
    }
    if (this.isPaycheckPreview && (this.invoiceIds.length || this.statementids.length)) {
      this.fetchPaycheckPreviewData();
    }
  }

  @Watch("isPaycheckPreview", { deep: true })
  async changeIsPaycheckPreview() {
    if (this.isPaycheckPreview && (this.invoiceIds.length || this.statementids.length)) {
      this.fetchPaycheckPreviewData();
    }
  }

  confirm() {
    this.saving = true
    this.progressBarModal = true
    if (this.inProgress) {
      clearInterval(this.intervalId);
    } else {
      if (this.seconds === 0) {
        this.seconds = this.progressBar;
      }

      this.intervalId = setInterval(() => this.timer(), 1000);
    }

    this.inProgress = !this.inProgress;
    this.$emit('confirm', {customCheckboxVal: this.customCheckboxVal, selectedPaycheckIds: this.selectedPaycheckIds})
  }
  close(){
    this.$emit('close')
    this.$emit('showPrevSource')
  }
  cancel() {
    this.$emit('cancel');
  }
  timer() {
    this.seconds += Math.round(100/this.progressBar);
    this.width = this.seconds;
    this.fullWidth = `width:${this.seconds}%`;

    if (this.seconds > 100) {
      this.seconds = 100;
      this.width = this.seconds;
      this.fullWidth = `width:${this.seconds}%`;
      this.inProgress = !this.inProgress;
      clearInterval(this.intervalId);
    }
  }

  async fetchPaycheckPreviewData() {
    this.paycheckPreviewLoading = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "PaycheckPreview",
      invoiceId: this.invoiceIds,
      statementids: this.statementids
    });

    if (response.STATUS === 1) {
      this.paycheckPreview = response.PREVIEWPAYCHECKARR;
    }
    this.paycheckPreviewLoading = false;
  }

  exportSave() {
    this.saveExport = true;
    this.$emit("confirm", {
      actionType : 'save'
    });
  }

  exportUpdateDetails() {
    this.updateDetailsExport = true;
    this.$emit("confirm", {
      actionType : 'update'
    });
  }
}
