import axios from "axios";

declare const dataURL: string

export class DashboardAPIRepo{

	async fetchData(container){
	    const response = await axios.post(dataURL + "?ReturnType=JSON", container);
	    if (response.data.ERROR) {
	      throw new Error(response.data.ERROR);
	    }
	    if (response.data.STATUS !== 1) {
	      throw new Error(response.data.STATUSMESSAGE);
	    }

	    return response.data
	}

}