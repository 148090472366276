import axios from "axios";

declare const dataURL: string
declare const getMaxRows: Function

export type Filters = {
	purchaseID?: string
	searchName: string
	searchAccountName: string 
	AccountTagID: number[]
	gTagIDs?: number[]
	groupIDs?: number[]
	selectedAccs: (number | undefined)[]
	accountTagName?: (string | undefined)[]
	selectedAccsName?: (string | undefined)[]
	groupName?: (string | undefined)[]
	varCostMin: string | undefined
	varCostMax: string | undefined
	poTotalMin: string | undefined
	poTotalMax: string | undefined
	cashflowPercentMin: string | undefined
	cashflowPercentMax: string | undefined
}

export enum SortField {
  purchaseID = 1,
	orderName = 2,
  Customer = 3,
  total = 4,
  poTotal = 5,
  cashflowPercent = 6
}

export type Sort = {
	field: SortField | null
	direction: {
		[SortField.purchaseID]: 1 | 2,
		[SortField.orderName]: 1 | 2,
		[SortField.Customer]: 1 | 2,
		[SortField.total]: 1 | 2,
		[SortField.poTotal]: 1 | 2,
		[SortField.cashflowPercent]: 1 | 2,
	}
}
export class DashboardCashflowAPIRepo{

	// async fetchData(container){
	//     const response = await axios.post(dataURL + "?ReturnType=JSON", container);
	//     if (response.data.ERROR) {
	//       throw new Error(response.data.ERROR);
	//     }

	//     return response.data
	// }

	async find(filters: Filters, sort: Sort, pageNumber: number, view: string = 'CurrentUsers', selectedView = false, isAllowBoard: boolean = false) {
		var reqData = {
			"controller": "Finances",
			"FunctionName": "Dashboard_cashflow",
			"pageNumber": pageNumber.toString(),
			"maxRows": sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows(),
			"Default_totalRows": getMaxRows(),
			"purchaseID": filters.purchaseID,
			"sPurchaseName": filters.searchName,
			"sAccountName": filters.searchAccountName,
			"selectedTagIDs": filters.AccountTagID.join(","),
			"tagIDs": filters.AccountTagID.join(","),
			"selectedAccountDs": filters.selectedAccs.join(","),
			"view": view,
			"selectedView": selectedView
		}

		if (isAllowBoard) {
			reqData['allowCompanyBoard'] = true;
			reqData['isAllowBoard'] = true;
		}

		if (typeof filters.varCostMin !== 'undefined') {
			reqData['varCostMin'] = filters.varCostMin
		}
		if (typeof filters.varCostMax !== 'undefined') {
			reqData['varCostMax'] = filters.varCostMax
		}
		if (typeof filters.poTotalMin !== 'undefined') {
			reqData['poTotalMin'] = filters.poTotalMin
		}
		if (typeof filters.poTotalMax !== 'undefined') {
			reqData['poTotalMax'] = filters.poTotalMax
		}
		if (typeof filters.cashflowPercentMin !== 'undefined') {
			reqData['cashflowPercentMin'] = filters.cashflowPercentMin
		}
		if (typeof filters.cashflowPercentMax !== 'undefined') {
			reqData['cashflowPercentMax'] = filters.cashflowPercentMax
		}

		reqData["gTagIDs"] = (typeof filters["gTagIDs"] != "undefined" ? filters["gTagIDs"].join(",") : "");
		reqData["groupTagIDs"] = (typeof filters["groupIDs"] != "undefined" ? filters["groupIDs"].join(",") : "");

		if (sort.field) {
			reqData["order"] = sort.field;
			reqData["direction"] = sort.direction[sort.field].toString();
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

}
