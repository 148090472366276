import { render, staticRenderFns } from "./AssetServiceAdd.vue?vue&type=template&id=0b533bbc&scoped=true"
import script from "./AssetServiceAdd.vue?vue&type=script&lang=tsx"
export * from "./AssetServiceAdd.vue?vue&type=script&lang=tsx"
import style0 from "./AssetServiceAdd.vue?vue&type=style&index=0&id=0b533bbc&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b533bbc",
  null
  
)

export default component.exports