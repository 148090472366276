
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Model, Emit, Inject } from "vue-property-decorator";
import { CoolSelect } from 'vue-cool-select'
import { debounce } from "../models/common";

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    class?: string;
  }
}

interface Props {
  defaultText?: string
  placeholder?: string
  value?: string
  sourceList: number
  searchFn: AutosuggestSearch
}

interface Events {
  onInput: string
}

export type Option = {
  value: string
  text: string
  label?: string // optional, if provided, will be used for suggested list item
}

export type AutosuggestSearch = (search: string) => Promise<{
  options: Option[],
}>

@Component({
  inheritAttrs: true,
  components: {
    CoolSelect,
  },
  data() {
    return {
      selected: null,
      items: ""
    };
  },
  inject: ['$validator'],
})
export default class AutoSuggestInput extends TSXComponent<
  Props,
  Events
> {

  @Prop({ required: false, type: String })
  placeholder?: string

  @Prop({ required: false, type: String })
  readonly value?: string

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : 0 })
  sourceList?: number

   @Prop({ required: false, default : false })
  showmore?: boolean

  @Model('input', {
    type: String,
    required: true,
  })

  @Prop({ required: true, type: Function })
  searchFn!: AutosuggestSearch

  options: Option[] = []
  search = ''
  loading = false
  moreUsers = 0
  timeoutId:any = 0

  get infoMsg(): string {
    if (this.search.length === 0) {
      return "Type in the field above to refine your search"
    }

    if (this.search.length === 1) {
      return "Please type at least 2 characters to search"
    }

    if (this.options.length === 0) {
      return "No results found"
    }

    return ''
  }

  get renderOptions(): Option[] {
    if (this.value && !this.options.find((option) => option.value === this.value)) {
      return [
        {
          value: this.value,
          text: this.defaultText || ''
        },
        ...this.options,
      ]
    }
    return this.options.map((o) => ({...o}))
  }

  async localOnSearch(search: string) {
    this.search = search

    this.loading = true

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      const { options } = await this.searchFn(search)
      this.options = options
      if(options.length > 10){
        this.options = options.slice(0,10)
        this.moreUsers = options.length - 10;
      }

      this.setLoading(false)
    }, 300)
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  @Emit('input')
  onInput(value: string) {
    return value
  }

  render() {
    return (
      <CoolSelect
        value={this.value}
        // items={this.options}
        items={this.renderOptions}
        item-value="value"
        item-text="text"
        filter={() => {
          return true // disabled filtering by match text
        }}
        placeholder={this.placeholder}
        onInput={this.onInput}
        onSearch={this.localOnSearch}
        onFocus={() => this.localOnSearch('')}
        menuItemsMaxHeight="178px"
        onChange="getval($event)"

        scopedSlots={{
          item: ({item}) => (
            <div class="item">
              { item.label || item.text }
            </div>
          ),
          'before-items-fixed': () => (
            <div></div>
          ),
          'no-data': () => (
            this.loading ? (
              <div>Loading...</div>
            ) : (
              <div>No results</div>
            )
          ),
          'input-end': () => {
            <span class="icon-dropdown" />
          },
          'before-items': () => (
            <div></div>
          ),
          'after-items': () => (
            this.options.length > 9 && this.showmore && this.moreUsers > 0 ? ( 
              <div  class="itemtext">{this.moreUsers} more (narrow search to see more)</div>
             ) : (
               this.options.length == 5 && this.showmore ? (<div class="itemtext">Top 5 most recent are listed, search to see more</div>) : (this.options.length != 0 && this.search.length === 0 && this.sourceList !== 1? (<div class="itemtext">Top var360 order are listed, search to see more var360 order</div>) : <div></div>)
             )

          ),
        }}
      >
      </CoolSelect>
    );
  }
}
