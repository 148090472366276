

















































































































































































































































































































































































































import Vue from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import { wait, notifier } from "../models/common";
import draggable from "vuedraggable";
import axios from "axios";
import { loginCustomerName } from "@/helpers/ApiHelper";
import MenuModal from "./MenuModal.vue";
import EmployeePlanStats from "@/components/EmployeePlan/EmployeePlanStats.vue";
import {ApiHelper} from "@/helpers/all";

declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    EmployeePlanStats,
    MenuModal
  },
  methods: {
    loginCustomerName
  }
})
export default class Header extends TSXComponent<void> {
  currentRole = sessionStorage.getItem("userRole");
  menuModalVisible = false;
  $allowedFunctions: number[] = Vue.prototype.$allowedFunctions;
  $isCustomer: boolean = Vue.prototype.$isCustomer;
  $userFunctions = Vue.prototype.$userFunctions;
  $hideEmployeePlanNumbers: boolean = !!Vue.prototype.$hideEmployeePlanNumbers || false;
  $pinEmployeePlanStats: boolean = !!Vue.prototype.$pinEmployeePlanStats || false;
  $employeePlanIds: string = Vue.prototype.$employeePlanIds || '';

  employeePlanInfo = {
    isLoading: true,
    UNPAID_COMM: 0,
    UNPAID_COMM_FORMATTED: '',
    PERIOD_COMM: 0,
    PERIOD_COMM_FORMATTED: '',
    PERCENT: 0,
    PERCENT2: 0,
    DOTTEDLINE: 0,
    BLUELINE: 0,
    ITEMS: [],
  };

  hideCompPlanFlg: number = 1;

  async loadEmployeePlanStats() {
    this.employeePlanInfo.isLoading = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanStats",
    });

    if (response.STATUS == 1) {
      this.employeePlanInfo = {
        ...this.employeePlanInfo,
        ITEMS: response.ITEMS || [],
        PERIOD_COMM: response.PERIOD_COMM || 0,
        PERIOD_COMM_FORMATTED: response.PERIOD_COMM_FORMATTED || '',
        UNPAID_COMM: response.UNPAID_COMM || 0,
        UNPAID_COMM_FORMATTED: response.UNPAID_COMM_FORMATTED || '',
        PERCENT: response.PERCENT || 0,
        PERCENT2: response.PERCENT2 || 0,
        DOTTEDLINE: response.DOTTEDLINE || 0,
        BLUELINE: response.BLUELINE || 0
      };

      this.hideCompPlanFlg = response.USERDETAIL.HIDECOMPPLANFLG;
      Vue.prototype.$employeePlanInfo = this.employeePlanInfo;
    }
    this.employeePlanInfo.isLoading = false;
  }

  async toogleHideEmployeePlanNumbers() {
    this.$hideEmployeePlanNumbers = !this.$hideEmployeePlanNumbers;
    Vue.prototype.$hideEmployeePlanNumbers = this.$hideEmployeePlanNumbers;
    this.$forceUpdate();
    await this.updateUserParams();
  }

  async tooglePinEmployeePlanStats() {
    this.$pinEmployeePlanStats = !this.$pinEmployeePlanStats;
    Vue.prototype.$pinEmployeePlanStats = this.$pinEmployeePlanStats;
    this.$forceUpdate();
    await this.updateUserParams();
  }

  async updateUserParams() {
    const response = await ApiHelper.callApi("post", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "update",
      pinEmployeePlanStats: this.$pinEmployeePlanStats ? 1 : 0,
      hideEmployeePlanNumbers: this.$hideEmployeePlanNumbers ? 1 : 0,
    });

    if (response.STATUS === 1) {
      // notifier.success(
      //     "Successfully updated the Employee Plan Config for this Account"
      // );
      localStorage.setItem('EMPLOYEEPLAN', JSON.stringify({
        EMPLOYEEPLANIDS: this.$employeePlanIds,
        HIDEEMPLOYEEPLANNUMBERS: this.$hideEmployeePlanNumbers ? 1 : 0,
        PINEMPLOYEEPLANSTATS: this.$pinEmployeePlanStats ? 1 : 0
      }));
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }


  $parent: any;
  $systemGroups: any;
  loading = false;
  subItems: any = [];
  menuItems: any = [];
  sortedItems = [];
  sideMenuItems: any = [];
  topMenuItems: any = [];
  userID = sessionStorage.getItem("userId");
  varName = sessionStorage.getItem("accountName");
  window = {
    width: 0,
    height: 0
  };
  showModel = -1;
  dragging = false;
  user = sessionStorage.getItem("userFname");
  userName = sessionStorage.getItem("userName");
  userFname = sessionStorage.getItem("userFname") || '';
  userLname = sessionStorage.getItem("userLname") || '';
  incognitoModeOn = sessionStorage.getItem("IncognitoMode");
  varId = sessionStorage.getItem("accountId") || "";
  $showDashboard: any;

  async created() {
    const cachedInfo = Vue.prototype.$employeePlanInfo || {
      isLoading: true,
      UNPAID_COMM: 0,
      UNPAID_COMM_FORMATTED: '',
      PERIOD_COMM: 0,
      PERIOD_COMM_FORMATTED: '',
      PERCENT: 0,
      PERCENT2: 0,
      DOTTEDLINE: 0,
      BLUELINE: 0,
      ITEMS: [],
    };
    this.employeePlanInfo = {
      ...this.employeePlanInfo,
      UNPAID_COMM: cachedInfo.UNPAID_COMM,
      UNPAID_COMM_FORMATTED: cachedInfo.UNPAID_COMM_FORMATTED,
      PERIOD_COMM: cachedInfo.PERIOD_COMM,
      PERIOD_COMM_FORMATTED: cachedInfo.PERIOD_COMM_FORMATTED,
      PERCENT: cachedInfo.PERCENT,
      PERCENT2: cachedInfo.PERCENT2,
      DOTTEDLINE: cachedInfo.DOTTEDLINE,
      BLUELINE: cachedInfo.BLUELINE,
      ITEMS: cachedInfo.ITEMS,
    };
    const names:any[] = [];
    if (this.userLname) {
      names.push(this.userLname);
    }
    if (this.userFname) {
      names.push(this.userFname);
    }
    this.userName = names.join(', ');
    // console.log("Header created");
    if (
      $(".menu-container").html() == "" ||
      $(".menu-container").html() == undefined ||
      this.topMenuItems.length == 0
    ) {
      if ($(".menu-container div").length == 0) {
        await this.createMenu();
      } else {
        await this.loadMenu();
      }
    }

    // reseller user has no function "Dashboard", but access Dashboard page
    if (
      this.loginCheck() == "Reseller" &&
      this.$route.name == "Dashboard" &&
      !this.$showDashboard
    ) {
      // go to first url has access
      const links = this.topMenuItems
        .filter(item => (item.href || "") != "")
        .map(item => item.href.replace("#/", ""));
      if (links.length) {
        this.$router.push({
          path: links[0]
        });
        return;
      }
    }

    if (!sessionStorage.getItem("userFname") || !sessionStorage.getItem("userLname")) {
      var self = this;
      var dataObj = {
        controller: "VARAdmin",
        FunctionName: "Details",
        subsystem: "VAR360"
      };
      var result = getRouteData(dataObj);
      result
        .then(function(response, statusText, jqXHR) {
          try {
            if (response.data.STATUS) {
              const firstName = response.data.USERSDETAILS.UFNAME || '';
              const lastName = response.data.USERSDETAILS.ULNAME || '';
              sessionStorage.setItem(
                "userFname",
                  firstName
              );
              sessionStorage.setItem(
                "userLname",
                  lastName
              );
              self.userFname = firstName;
              self.userLname = lastName;
              const names:any[] = [];
              if (self.userLname) {
                names.push(self.userLname);
              }
              if (self.userFname) {
                names.push(self.userFname);
              }
              self.userName = names.join(', ');
              self.user = firstName;
            } else if (response.data.ERROR) {
              // console.log(response.data.ERROR);
            }
          } catch (e) {
            //handle error
          }
        })
        .catch(function(error) {
          // console.log(error);
        });
    }
    this.loading = false;
    this.user = sessionStorage.getItem("userFname");

    //get total quotes/orders
    if (this.loginCheck() === "Customer") {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Customers",
        FunctionName: "BasicInfo"
      });
      if (response.data.STATUS == 1) {
        this.menuItems.forEach(function(val) {
          if (val.id == "1") {
            val.totalCnt = response.data.STAT.COUNTQUOTE;
          } else if (val.id == "2") {
            val.totalCnt = response.data.STAT.COUNTORDER;
          } else if (val.id == "3") {
            val.totalCnt = response.data.STAT.COUNTHARDWARE;
          } else if (val.id == "5") {
            val.totalCnt = response.data.STAT.COUNTSUPPORT;
          }
        });
      }
    }

    // get Purchase Pending count
    if (this.loginCheck() === "Reseller") {
      const varBasicInfoResponse = await axios.post(
        dataURL + "?ReturnType=JSON",
        {
          controller: "Queries",
          subsystem: "Helpers",
          FunctionName: "varBasicInfo"
        }
      );
      const purchasePendingCnt =
        varBasicInfoResponse.data.purchasePendingCnt || 0;
      const quotesItem = this.topMenuItems.find(item => item.id == "1");
      if (quotesItem && (quotesItem.items || []).length) {
        for (const item of quotesItem.items) {
          if ((item.menus || []).length) {
            const waitingProcessing = item.menus.find(
              t =>
                t.id == "1.4" &&
                (t.customClass || "").indexOf("waiting-for-processing") != -1
            );
            if (waitingProcessing) {
              waitingProcessing.totalCnt = purchasePendingCnt;
              if (!purchasePendingCnt) {
                // waitingProcessing.hide = true;
              }
            }
          }
        }
      }
    }

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    await this.loadEmployeePlanStats();
  }

  mounted() {
    window.addEventListener("resize", this.checkWidth);
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }

  handleScroll(event) {
    $(window).scroll(function(this: Window) {
      if ($(".options-visible").length) {
        $(".options-visible").removeClass("options-visible");
      }

      // console.log($(this).scrollTop());

      if ($(this).scrollTop() > 65 && !$("#navbar").hasClass("sticky")) {
        $("#navbar").addClass("sticky");
      } else if ($(this).scrollTop() < 10 && $("#navbar").hasClass("sticky")) {
        $("#navbar").removeClass("sticky");
      }
    });
  }

  handleResize() {
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
  }

  loginCheck() {
    return sessionStorage.getItem("userRole");
  }

  menuActive(menu: string): Object {
    const currentRoute = this.$router.currentRoute;
    if (!currentRoute || !currentRoute.name) {
      return {};
    }

    let routeName = currentRoute.name;

    if (currentRoute.name.toLowerCase().indexOf("invoice") !== -1) {
      routeName = "orders";
    }
    if (currentRoute.name.toLowerCase().indexOf("indirectorders") !== -1) {
      routeName = "finances";
    }
    if (currentRoute.name.toLowerCase().indexOf("hardware") !== -1) {
      routeName = "hardwares";
    }
    if (
      // currentRoute.name.toLowerCase().indexOf("customers") !== -1 &&
      menu.toLocaleLowerCase().indexOf("custom") !== -1
    ) {
      return {};
    }

    return {
      currentPage: routeName.toLowerCase().indexOf(menu) !== -1
    };
  }

  checkWidth() {
    const fullMenuWidth = $(".top-menu-items").width();
    const menuItemWidth = 200; // fixed width
    let tempMenu: any = [];
    let cutoffWidth = 0;
    const allMenuItems = [...this.topMenuItems, ...this.sideMenuItems];
    for (const item of allMenuItems) {
      if (item.hide || false) {
        tempMenu.push(item);
      } else {
        cutoffWidth += menuItemWidth;
        if (cutoffWidth > fullMenuWidth) {
          break;
        } else {
          tempMenu.push(item);
        }
      }
    }
    this.topMenuItems = tempMenu;
    const topMenuIds = this.topMenuItems.map(item => item.id);
    this.sideMenuItems = allMenuItems.filter(
      item => !topMenuIds.includes(item.id)
    );

    // save sesstion storage topMenu/sideMenu
    const topMenuItems = [...this.topMenuItems].map(item => ({
      ...item,
      menuActive: {
        currentPage: false
      }
    }));
    const sideMenuItems = [...this.sideMenuItems].map(item => ({
      ...item,
      menuActive: {
        currentPage: false
      }
    }));
    sessionStorage.setItem("topMenu", JSON.stringify(topMenuItems));
    sessionStorage.setItem("sideMenu", JSON.stringify(sideMenuItems));

    this.loadScript("js/stripeNav.js");
    // }, 200);
  }

  closeModal() {
    this.menuModalVisible = false;
  }

  loadScript(src) {
    const oldScript = document.querySelector(`script[src="${src}"]`);
    if (oldScript) {
      document.body.removeChild(oldScript);
    }
    const newScript = document.createElement("script");
    newScript.src = src;
    document.body.appendChild(newScript);
  }

  onEnd(e) {
    // console.log('end')
    // console.log('endfrom',e.from.id)
    // console.log('endEnd',e.to.id)
  }

  onAdd(e) {
    // console.log('add')
    // console.log('addFrom',e.from.id)
    // console.log('addEnd',e.to.id)
  }

  subChangelog(e) {
    // console.log('items',this.topMenuItems)
    // console.log('moved',e.moved)
    this.generateParams();
  }

  logSub(e) {
    // console.log('sub',e.moved)
  }

  test(e) {
    // console.log('enter',e)
    this.showModel = e;
  }

  test2(e) {
    // console.log('leave',e)
    // this.showModel = -1;
  }

  testEve(e, t) {
    // console.log('dntKnw',e)
    this.showModel = t;
  }

  drop(e) {
    e.preventDefault();
  }

  async generateParams() {
    var newParams = {};
    newParams["main"] = {};
    newParams["sub"] = {};

    try {
      var dataObj = {
        controller: "Accounts",
        FunctionName: "sortMenu",
        params: newParams,
        userId: sessionStorage.getItem("userId")
      };
      // this.loading = true
      var result = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      if (result.data.STATUS == 1) {
        notifier.success(result.data.MESSAGE);
      } else {
        notifier.alert(result.data.MESSAGE);
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      // this.loading = false
    }
  }

  async createMenu() {
    // this.loading = true
    let menuParams: any = "";
    const result = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "sortMenu",
      userid: sessionStorage.getItem("userId")
    });
    if (result.data.params != "") {
      menuParams = JSON.parse(result.data.params);
      if (!menuParams.MENUORDER) {
        menuParams = "";
      }
    }

    // if (menuParams == "") {

    // declare all menu items in topMenuItems
    this.topMenuItems = [
      {
        id: "1",
        name: "Quotes",
        menuActiveCheck: "quotes",
        menuActive: this.menuActive("quotes"),
        main_class: "quotes",
        href: !this.$allowedFunctions.includes(this.$userFunctions.Quotes)
          ? ""
          : "#/quotes",
        hide:
          !this.$allowedFunctions.includes(this.$userFunctions.Quotes) &&
          !this.$allowedFunctions.includes(this.$userFunctions.Add_Quote)
            ? true
            : false,
        itemArr: [
          this.$allowedFunctions.includes(this.$userFunctions.Quotes) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Quote)
            ? true
            : false
        ],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub:
          this.$allowedFunctions.includes(this.$userFunctions.Quotes) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Quote),
        items: [
          {
            title: "Quotes",
            inRow: 2,
            isHide:
              !this.$allowedFunctions.includes(this.$userFunctions.Quotes) &&
              !this.$allowedFunctions.includes(this.$userFunctions.Add_Quote)
                ? true
                : false,
            menus: [
              {
                id: "1.1",
                name: "Quote List",
                title: "Quotes",
                href: "#/quotes",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Quotes
                )
                  ? true
                  : false,
                customClass: this.$isCustomer && !this.$allowedFunctions.includes(this.$userFunctions.RequestQuote) ? "w-33" : "w-50"
              },
              {
                id: "1.3",
                name: "New Quote",
                title: "Quotes",
                href: "#/quoteCreate",
                hide:
                  !this.$allowedFunctions.includes(
                    this.$userFunctions.Add_Quote
                  ) || this.$isCustomer
                    ? true
                    : false,
                customClass: ""
              },
              {
                id: "1.4",
                name: "Request New Quote",
                title: "Quotes",
                href: "#/requestNewQuote",
                hide: (!this.$isCustomer ? true : false) || (this.$isCustomer && !this.$allowedFunctions.includes(this.$userFunctions.RequestQuote)),
                customClass: ""
              },
              {
                id: "1.4",
                name: "Waiting For Processing",
                title: "Quotes",
                href: "#/quotes?qOpenStatus=8",
                // reseller only
                hide:
                  this.$isCustomer ||
                  !this.$allowedFunctions.includes(this.$userFunctions.Quotes)
                    ? true
                    : false,
                customClass: "waiting-for-processing",
                showTotalCnt: true,
                totalCnt: 0
              },
              {
                id: "1.5",
                name: "Standards",
                title: "Standards",
                href: "#/QuoteStandards",
                // reseller only
                hide:
                  // this.$isCustomer ||
                  !this.$allowedFunctions.includes(this.$userFunctions.Standards)
                    ? true
                    : false,
                customClass: ""
              },
              {
                id: "1.6",
                name: "Contracts",
                title: "Quotes",
                href: "#/Contracts",
                hide:
                  // this.$isCustomer ||
                  !this.$allowedFunctions.includes(this.$userFunctions.Contracts),
                customClass: ""
              }
            ]
          },
          {
            title: "Opportunities",
            inRow: 2,
            isHide: !this.$allowedFunctions.includes(
                this.$userFunctions.Opportunities
              ),
            menus: [
              {
                id: "1.2",
                name: "Opportunities List",
                title: "Quotes",
                href: "#/opportunities",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Opportunities
                )
              }
            ]
          }
        ]
      },
      {
        id: "2",
        name: "Orders",
        menuActiveCheck: "orders",
        menuActive: this.menuActive("orders"),
        main_class: "orders",
        href: !this.$allowedFunctions.includes(this.$userFunctions.Orders)
          ? ""
          : "#/orders",
        hide:
          !this.$allowedFunctions.includes(this.$userFunctions.Orders) &&
          !this.$allowedFunctions.includes(this.$userFunctions.Add_Order) &&
          !this.$allowedFunctions.includes(this.$userFunctions.Order_Status) &&
          !this.$allowedFunctions.includes(this.$userFunctions.Invoices) &&
          !this.$allowedFunctions.includes(this.$userFunctions.Add_Invoice)
            ? true
            : false,
        itemArr: [
          this.$allowedFunctions.includes(this.$userFunctions.Orders) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Order) ||
          this.$allowedFunctions.includes(this.$userFunctions.Order_Status)
            ? true
            : false,
          this.$allowedFunctions.includes(this.$userFunctions.Invoices) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Invoice)
            ? true
            : false
        ],
        totalCnt: 0,
        showSub:
          this.$allowedFunctions.includes(this.$userFunctions.Orders) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Order) ||
          this.$allowedFunctions.includes(this.$userFunctions.Order_Status) ||
          this.$allowedFunctions.includes(this.$userFunctions.Invoices) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Invoice),
        items: [
          {
            title: "Orders",
            inRow: 3,
            isHide:
              !this.$allowedFunctions.includes(this.$userFunctions.Orders) &&
              !this.$allowedFunctions.includes(this.$userFunctions.Add_Order) &&
              !this.$allowedFunctions.includes(this.$userFunctions.Order_Status)
                ? true
                : false,
            menus: [
              {
                id: "2.1",
                name: "Order List",
                title: "Orders",
                href: "#/orders",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Orders
                )
                  ? true
                  : false
              },
              {
                id: "2.2",
                name: "New Order",
                title: "Orders",
                href: "#/orderCreate/0",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Add_Order
                )
                  ? true
                  : false
              },
              {
                id: "2.3",
                name: "Order Status",
                title: "Orders",
                href: "#/orderStatus",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Order_Status
                )
                  ? true
                  : false
              }
            ]
          },
          {
            title: "Invoices",
            inRow: 2,
            isHide:
              !this.$allowedFunctions.includes(this.$userFunctions.Invoices) &&
              !this.$allowedFunctions.includes(this.$userFunctions.Add_Invoice)
                ? true
                : false,
            menus: [
              {
                id: "2.4",
                name: "Invoice List",
                title: "Invoices",
                href: "#/invoices",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Invoices
                )
                  ? true
                  : false
              },
              {
                id: "2.5",
                name: "Audit",
                title: "Invoices",
                href: "#/audit",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Audit
                )
                  ? true
                  : false
              },
              {
                id: "2.6",
                name: "New Invoice",
                title: "Invoices",
                href: "#/invoiceCreate/0",
                hide: true
              },
              {
                id: "2.7",
                name: "Invoice Deposits",
                title: "Invoice Deposits",
                href: "#/invoiceDepositsModal",
                hide: !this.$allowedFunctions.includes(
                    this.$userFunctions.Invoices
                ) || this.loginCheck() === "Customer"
                    ? true
                    : false,
              },
            ]
          }
        ]
      },
      {
        id: "3",
        name: "Customers",
        menuActiveCheck: "customers",
        menuActive: this.menuActive("customers"),
        main_class: "customer",
        href: "#/customers",
        hide:
          !this.$allowedFunctions.includes(this.$userFunctions.Customers) &&
          !this.$allowedFunctions.includes(
            this.$userFunctions.All_Milestone_Accounts
          )
            ? true
            : false,
        itemArr: [
          !this.$allowedFunctions.includes(this.$userFunctions.Customers) &&
          !this.$allowedFunctions.includes(
            this.$userFunctions.All_Milestone_Accounts
          )
            ? true
            : false
        ],
        showSub:
          this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.All_Milestone_Accounts
          ),
        items: ""
      },
      {
        id: "4",
        name: "Finances",
        menuActiveCheck: "finances",
        menuActive: this.menuActive("finances"),
        main_class: "finances",
        href: !this.$allowedFunctions.includes(this.$userFunctions.Finances)
          ? ""
          : "#/financesdashboard",
        hide:
          !this.$allowedFunctions.includes(this.$userFunctions.Finances) &&
          !this.$allowedFunctions.includes(this.$userFunctions.Paychecks) &&
          !this.$allowedFunctions.includes(this.$userFunctions.HP_Agent_Reports) &&
          !this.$allowedFunctions.includes(this.$userFunctions.SPIFFS) &&
          !this.$allowedFunctions.includes(this.$userFunctions.Resale_Indirect) && 
          !this.$allowedFunctions.includes(this.$userFunctions.Boards) && 
          !this.$allowedFunctions.includes(this.$userFunctions.Cashflow) && 
          !this.$allowedFunctions.includes(this.$userFunctions.Ledgers) &&
          !this.$allowedFunctions.includes(this.$userFunctions.CompAdmin) &&
          !this.$allowedFunctions.includes(this.$userFunctions.FinanceAudit)
            ? true
            : false,
        itemArr: [
          this.$allowedFunctions.includes(this.$userFunctions.Finances)
            ? true
            : false,
          this.$allowedFunctions.includes(this.$userFunctions.Paychecks) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.HP_Agent_Reports
          ) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.Resale_Indirect
          ) ||
          this.$allowedFunctions.includes(this.$userFunctions.Finances)
            ? true
            : false,
          this.$allowedFunctions.includes(this.$userFunctions.SPIFFS)
            ? true
            : false
        ],
        showSub:
          this.$allowedFunctions.includes(this.$userFunctions.Finances) ||
          this.$allowedFunctions.includes(this.$userFunctions.Paychecks) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.HP_Agent_Reports
          ) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.Resale_Indirect
          ) ||
          this.$allowedFunctions.includes(this.$userFunctions.Finances) ||
          this.$allowedFunctions.includes(this.$userFunctions.SPIFFS) || 
          this.$allowedFunctions.includes(this.$userFunctions.Boards) ||  
          this.$allowedFunctions.includes(this.$userFunctions.Cashflow) ||  
          this.$allowedFunctions.includes(this.$userFunctions.Ledgers) ||
          this.$allowedFunctions.includes(this.$userFunctions.CompAdmin) ||
          this.$allowedFunctions.includes(this.$userFunctions.FinanceAudit),
        items: [
          {
            title: "Finances",
            inRow: 1,
            isHide:
              this.$allowedFunctions.includes(this.$userFunctions.Finances) ||
              this.$allowedFunctions.includes(this.$userFunctions.Boards) ||
              this.$allowedFunctions.includes(this.$userFunctions.Cashflow) ||
              this.$allowedFunctions.includes(this.$userFunctions.Ledgers) ||
              this.$allowedFunctions.includes(this.$userFunctions.FinanceAudit)
              ? false
              : true,
            menus: [
              {
                id: "4.1",
                name: "Dashboard",
                title: "Dashboard",
                customClass: "w-33",
                href: "#/financesdashboard",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Finances
                )
                  ? true
                  : false
              },
              {
                id: "4.9",
                name: "Distribution",
                title: "Distribution",
                href: "#/distribution",
                customClass: "w-33",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Finances
                )
                  ? true
                  : false
              },
              {
                id: "4.7",
                name: "Cashflow",
                title: "Cashflow",
                href: "#/cashflow",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Cashflow
                )
                  ? true
                  : false
              },
              {
                id: "4.6",
                name: "Ledgers",
                title: "Ledgers",
                href: "#/ledgers",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Ledgers
                )
                  ? true
                  : false
              },
              {
                id: "4.13",
                name: "Finance Audit",
                title: "Finance Audit",
                href: "#/financeAudit",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.FinanceAudit
                )
                  ? true
                  : false
              },
              {
                id: "4.12",
                name: "Boards",
                title: "Boards",
                href: "#/boards",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Boards
                )
                  ? true
                  : false
              }
            ]
          },
          {
            title: "PayRoll",
            inRow: 3,
            isHide:
              !this.$allowedFunctions.includes(this.$userFunctions.Paychecks) &&
              !this.$allowedFunctions.includes(
                this.$userFunctions.HP_Agent_Reports
              ) &&
              !this.$allowedFunctions.includes(
                this.$userFunctions.Resale_Indirect
              )
                ? true
                : false,
            menus: [
              {
                id: "4.2",
                name: "Cycles",
                title: "PayRoll",
                href: "#/payroll",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Paychecks
                )
                  ? true
                  : false
              },
              {
                id: "4.3",
                name: "Agent Payments",
                title: "PayRoll",
                href: "#/hpagent",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.HP_Agent_Reports
                )
                  ? true
                  : false
              },
              {
                id: "4.5",
                title: "PayRoll",
                name: "Resale",
                href: "#/indirectorders",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Resale_Indirect
                )
                  ? true
                  : false
              }
            ]
          },
          {
            title: "Rewards",
            inRow: 1,
            isHide: !this.$allowedFunctions.includes(this.$userFunctions.SPIFFS)
              ? true
              : false,
            menus: [
              {
                id: "4.4",
                name: "SPIFFs",
                title: "Rewards",
                href: "#/spiffs",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.SPIFFS
                )
                  ? true
                  : false
              },
              {
                id: "4.8",
                name: "SPIFF Source",
                title: "Rewards",
                href: "#/spiffSources",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.SPIFFS
                )
                  ? true
                  : false
              }
            ]
          },
          {
            title: "Compensation",
            inRow: 1,
            isHide: !this.$allowedFunctions.includes(
                  this.$userFunctions.CompAdmin
                ),
            menus: [
              {
                id: "4.9",
                name: "Employee Plans",
                title: "Employee Plans",
                href: "#/employeePlans",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.CompAdmin
                )
              },
              /*
              {
                id: "4.10",
                name: "New Employee Plan",
                title: "New Employee Plan",
                href: "#/employeePlanEdit/0",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.CompAdmin
                )
                  ? true
                  : false
              }
              */
             {
                id: "4.11",
                name: "Plan Groups",
                title: "Plan Groups",
                href: "#/planGroups",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.CompAdmin
                )
                  ? true
                  : false
              }
            ]
          }
        ]
      },
      {
        id: "5",
        name: "Support",
        menuActiveCheck: "supports",
        menuActive: this.menuActive("supports"),
        main_class: "support",
        href: "#/supports",
        hide: !this.$allowedFunctions.includes(this.$userFunctions.Support)
          ? true
          : false,
        itemArr: [
          !this.$allowedFunctions.includes(this.$userFunctions.Support)
            ? true
            : false
        ],
        showSub: this.$allowedFunctions.includes(this.$userFunctions.Support),
        totalCnt: 0,
        items: ""
      },
      {
        id: "6",
        name: "Users",
        menuActiveCheck: "users",
        menuActive: this.menuActive("users"),
        main_class: "Users",
        href: "#/users",
        hide:
          !this.$allowedFunctions.includes(this.$userFunctions.Users) ||
          this.loginCheck() === "Customer"
            ? true
            : false,
        itemArr: [
          !this.$allowedFunctions.includes(this.$userFunctions.Users)
            ? true
            : false
        ],
        showSub: this.$allowedFunctions.includes(this.$userFunctions.Users),
        items: ""
      },
      {
        id: "7",
        name: "Reports",
        menuActiveCheck: "reports",
        menuActive: this.menuActive("reports"),
        main_class: "Reports",
        href: "#/reports",
        hide: !this.$allowedFunctions.includes(this.$userFunctions.Reports)
          ? true
          : false,
        itemArr: [
          !this.$allowedFunctions.includes(this.$userFunctions.Reports)
            ? true
            : false
        ],
        showSub: this.$allowedFunctions.includes(this.$userFunctions.Reports),
        items: [
          {
            title: "Reports",
            inRow: 2,
            isHide: !this.$allowedFunctions.includes(
              this.$userFunctions.Reports
            )
              ? true
              : false,
            menus: [
              {
                id: "7.1",
                name: "Reports",
                title: "Reports",
                href: "#/reports",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Reports
                )
                  ? true
                  : false,
                customClass: ""
              },
              {
                id: "7.2",
                name: "Audit",
                title: "Audit",
                href: "#/audit",
                hide: !this.$allowedFunctions.includes(
                  this.$userFunctions.Audit
                )
                  ? true
                  : false,
                customClass: ""
              },
              {
                id: "7.3",
                name: "Alerts",
                title: "Alerts",
                href: "#/alerts",
                hide: (this.$isCustomer ? true : false) || !this.$allowedFunctions.includes(
                  this.$userFunctions.Reports
                )
                  ? true
                  : false,
                customClass: ""
              }
            ]
          }
        ]
      },
      {
        id: "8",
        name: "Hardware",
        menuActiveCheck: "hardware",
        menuActive: this.menuActive("hardware"),
        main_class: "hardware",
        href: !this.$allowedFunctions.includes(
          this.$userFunctions.Hardware_List
        )
          ? ""
          : "#/hardware",
        hide:
          this.$allowedFunctions.includes(this.$userFunctions.Hardware_List) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Hardware) ||
          this.$allowedFunctions.includes(this.$userFunctions.Import_From_Excel)
            ? false
            : true,
        itemArr: [
          this.$allowedFunctions.includes(this.$userFunctions.Hardware_List) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Hardware) ||
          this.$allowedFunctions.includes(this.$userFunctions.Import_From_Excel)
            ? false
            : true
        ],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub:
          this.$allowedFunctions.includes(this.$userFunctions.Hardware_List) ||
          this.$allowedFunctions.includes(this.$userFunctions.Add_Hardware) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.Import_From_Excel
          ),
        items: [
          {
            title: "Hardware",
            inRow: 2,
            isHide:
              this.$allowedFunctions.includes(
                this.$userFunctions.Hardware_List
              ) ||
              this.$allowedFunctions.includes(this.$userFunctions.Add_Hardware)
                ? false
                : true,
            menus: [
              {
                id: "1.5",
                name: "Dashboard",
                title: "Dashboard",
                href: "#/hardware/dashboard",
                hide: this.$allowedFunctions.includes(
                  this.$userFunctions.Hardware_List
                )
                  ? false
                  : true,
                customClass: ""
              },
              {
                id: "1.1",
                name: "Hardware List",
                title: "Hardware",
                href: "#/hardware",
                hide: this.$allowedFunctions.includes(
                  this.$userFunctions.Hardware_List
                )
                  ? false
                  : true,
                customClass: ""
              },
              {
                id: "1.2",
                name: "End User List",
                title: "Hardware",
                href: "#/hardware?viewtype=EndUser",
                hide: this.$allowedFunctions.includes(
                  this.$userFunctions.Hardware_List
                )
                  ? false
                  : true,
                customClass: this.$allowedFunctions.includes(
                  this.$userFunctions.Hardware_List
                )
                  ? "col-6 text-left"
                  : ""
              },
              {
                id: "1.3",
                name: "Add Hardware",
                title: "Hardware",
                href: "#/addhardware/0",
                hide: this.$allowedFunctions.includes(
                  this.$userFunctions.Add_Hardware
                )
                  ? false
                  : true,
                customClass: ""
              },
              {
                id: "1.4",
                name: "Import Hardware",
                title: "Hardware",
                href: "#/importhardwares",
                hide: this.$allowedFunctions.includes(
                  this.$userFunctions.Import_From_Excel
                )
                  ? false
                  : true,
                customClass: ""
              }
            ]
          }
        ]
      },
      {
        id: "9",
        name: "Portals",
        menuActiveCheck: "portal",
        menuActive: this.menuActive("portal"),
        main_class: "portal",
        href: "",
        hide:
          this.loginCheck() === "Reseller" &&
          (this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
            this.$allowedFunctions.includes(
              this.$userFunctions.All_Milestone_Accounts
            ))
            ? false
            : true,
        itemArr: [
          this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.All_Milestone_Accounts
          )
            ? false
            : true
        ],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub:
          this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.All_Milestone_Accounts
          ),
        items: [
          {
            title: "Portals",
            inRow: 2,
            isHide:
              this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
              this.$allowedFunctions.includes(
                this.$userFunctions.All_Milestone_Accounts
              )
                ? false
                : true,
            menus: [
              {
                id: "1.1",
                name: "List",
                title: "Portals List",
                href: "#/customerPortals",
                hide: false,
                customClass: ""
              },
              {
                id: "1.3",
                name: "Create Portal",
                title: "Create Portal",
                href: "#/createPortal",
                hide: false,
                customClass: ""
              }
            ]
          }
        ]
      },
      {
        id: "10",
        name: "Admin",
        menuActiveCheck: "admin",
        menuActive: this.menuActive("admin"),
        main_class: "admin",
        href: "",
        hide: this.hasPermission("admin") ? false : true,
        itemArr: [this.hasPermission("admin") ? false : true],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub: this.hasPermission("admin"),
        items: [
          {
            title: "Account Settings",
            inRow: 3,
            isHide: this.hasPermission("admin") ? false : true,
            menus: [
              {
                id: "1.1",
                name: "Users",
                title: "Users",
                href: "#/customerList",
                hide: this.loginCheck() === "Reseller" || !this.$allowedFunctions.includes(this.$userFunctions.Users) ? true : false,
                customClass: ""
              },
              {
                id: "1.2",
                name: "Profile",
                title: "Profile",
                href: "#/admin",
                hide: false,
                customClass: "w-33"
              },
              {
                id: "1.4",
                name: "Groups",
                title: "Groups",
                href: "#/securityGroups",
                hide:
                  this.loginCheck() === "Reseller" &&
                  this.$allowedFunctions.includes(
                    this.$userFunctions.Security_Groups
                  )
                    ? false
                    : true,
                customClass: "w-33"
              },
              {
                id: "1.6",
                name: "External",
                title: "External",
                href: "#/external",
                hide:
                  this.loginCheck() === "Reseller" &&
                  this.$allowedFunctions.includes(this.$userFunctions.External)
                    ? false
                    : true,
                customClass: ""
              },
              {
                id: "1.7",
                name: "API Keys",
                title: "APIKeys",
                href: "#/apikeys",
                hide: this.loginCheck() === "Reseller" ? false : true,
                customClass: "w-33"
              },
              // {
              //   id: "1.11",
              //   name: "Global Params",
              //   title: "Global Params",
              //   href: "#/globalparams",
              //   hide:
              //     this.loginCheck() === "Reseller" &&
              //     this.$systemGroups.includes("sys_admin")
              //       ? false
              //       : true,
              //   customClass: ""
              // },
              {
                id: "1.12",
                name: "Incognito Mode",
                title: "Incognito Mode",
                href: "#/incognitoMode",
                hide:
                  this.loginCheck() === "Reseller" &&
                  this.$allowedFunctions.includes(
                    this.$userFunctions.IncognitoMode
                  )
                    ? false
                    : true,
                customClass: ""
              },
              {
                id: "1.13",
                name: "Global Security",
                title: "Global Security",
                href: "#/globalSecurity",
                hide:
                  this.loginCheck() === "Reseller" &&
                  this.$allowedFunctions.includes(
                    this.$userFunctions.Global_Security
                  )
                    ? false
                    : true,
                customClass: ""
              }
            ]
          },
          {
            title: "General",
            inRow: 2,
            isHide:
                this.loginCheck() === "Reseller" &&
                this.$allowedFunctions.includes(
                    this.$userFunctions.Subscription ||
                    this.$userFunctions.CommissionStatement
                )
                    ? false
                    : true,
            menus: [
              {
                id: "1.11",
                name: "Settings",
                title: "Settings",
                href: "#/globalparams",
                hide:
                    this.loginCheck() === "Reseller" &&
                    this.$systemGroups.includes("sys_admin")
                        ? false
                        : true,
                customClass: ""
              },
              {
                id: "1.7",
                name: "Subscription",
                title: "Subscription",
                href: "#/subscription",
                hide:
                    this.loginCheck() === "Reseller" &&
                    this.$allowedFunctions.includes(
                        this.$userFunctions.Subscription
                    )
                        ? false
                        : true,
                customClass: ""
              },
              {
                id: "1.14",
                name: "Commission Statement",
                title: "Commission Statement",
                href: "#/commissionStatement",
                hide:
                    this.loginCheck() === "Reseller" &&
                    this.$allowedFunctions.includes(
                        this.$userFunctions.CommissionStatement
                    )
                        ? false
                        : true,
                customClass: ""
              },

            ]
          },
          {
            title: "Templates and Fields",
            inRow: 2,
            isHide:
              this.loginCheck() === "Reseller" &&
              (
                this.$allowedFunctions.includes(
                  this.$userFunctions.Setting_Export ||
                    this.$userFunctions.CustomData
                ) ||
                this.$allowedFunctions.includes(
                  this.$userFunctions.Customers
                ) ||
                this.$allowedFunctions.includes(
                  this.$userFunctions.All_Milestone_Accounts
                )
              )
                ? false
                : true,
            menus: [
              {
                id: "1.5",
                name: "Setting Export",
                title: "Setting Export",
                href: "#/export_setting",
                hide:
                  this.loginCheck() === "Reseller" &&
                  this.$allowedFunctions.includes(
                    this.$userFunctions.Setting_Export
                  )
                    ? false
                    : true,
                customClass: ""
              },
              {
                id: "1.10",
                name: "Custom Data",
                title: "Custom Data",
                href: "#/customdata",
                hide:
                  this.loginCheck() === "Reseller" &&
                  this.$allowedFunctions.includes(
                    this.$userFunctions.CustomData
                  )
                    ? false
                    : true,
                customClass: ""
              },
              {
                id: "1.13",
                name: "Templates",
                title: "Templates",
                href: "#/templates",
                hide: this.loginCheck() === "Reseller" ? false : true,
                customClass: ""
              },
              {
                id: "1.15",
                name: "Global Portal",
                title: "Global Portal",
                href: "#/globalPortal",
                isHide:
                  this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
                  this.$allowedFunctions.includes(
                    this.$userFunctions.All_Milestone_Accounts
                  )
                    ? false
                    : true,
                customClass: ""
              },
              {
                id: "1.17",
                name: "Sources",
                title: "Sources",
                href: "#/sources",
                isHide:
                  this.loginCheck() === "Reseller"
                    ? false
                    : true,
                customClass: ""
              }
            ]
          },
          {
            title: "Tags",
            inRow: 2,
            isHide:
              this.loginCheck() === "Reseller" &&
              this.$allowedFunctions.includes(
                this.$userFunctions.AccountTag || this.$userFunctions.GlobalTag
              )
                ? false
                : true,
            menus: [
              {
                id: "1.8",
                name: "Account Tags",
                title: "Account Tags",
                href: "#/accountTags",
                hide:
                  this.loginCheck() === "Reseller" &&
                  this.$allowedFunctions.includes(
                    this.$userFunctions.AccountTag
                  )
                    ? false
                    : true,
                customClass: ""
              },
              {
                id: "1.9",
                name: "Global Tags",
                title: "Global Tags",
                href: "#/globalTags",
                hide:
                  this.loginCheck() === "Reseller" &&
                  this.$allowedFunctions.includes(this.$userFunctions.GlobalTag)
                    ? false
                    : true,
                customClass: ""
              }
            ]
          },

        ]
      },
      {
        id: "11",
        name: "Deployments",
        menuActiveCheck: "deployments",
        menuActive: this.menuActive("deployments"),
        main_class: "deployments",
        href: "#/deployments",
        hide: this.$allowedFunctions.includes(this.$userFunctions.Deployments)
          ? false
          : true,
        itemArr: [
          this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
          this.$allowedFunctions.includes(
            this.$userFunctions.All_Milestone_Accounts
          )
            ? false
            : true
        ],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub: false,
        items: []
      },
      {
        id: "12",
        name: "Custom",
        menuActiveCheck: "custom",
        menuActive: this.menuActive("custom"),
        main_class: "custom",
        href: "",
        hide: (this.loginCheck() === "Reseller" ? false : true) ||
            (this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
            this.$allowedFunctions.includes(
                this.$userFunctions.All_Milestone_Accounts
            )
            ? false
            : true),
        itemArr: [false],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub: true,
        items: [
          {
            title: "Custom",
            inRow: 2,
            isHide:
              this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
              this.$allowedFunctions.includes(
                this.$userFunctions.All_Milestone_Accounts
              )
                ? false
                : true,
            menus: [
              {
                id: "1.1",
                name: "Distributor Availability",
                title: "Distributor Availability",
                href: "#/distyAvailability",
                hide: false,
                customClass: ""
              }
            ]
          }
        ]
      },
      {
        id: "13",
        name: "Engine",
        menuActiveCheck: "engine",
        menuActive: this.menuActive("engine"),
        main_class: "engine",
        href: "#/engine",
        hide:
          this.loginCheck() === "Reseller" &&
          this.$systemGroups.includes("sys_admin") &&
          this.$allowedFunctions.includes(this.$userFunctions.Engine)
            ? false
            : true,
        itemArr: [false],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub: false,
        items: []
      },
      {
        id: "14",
        name: "Logs",
        menuActiveCheck: "logs",
        menuActive: this.menuActive("logs"),
        main_class: "logs",
        href: "#/logs",
        hide:
          this.$allowedFunctions.includes(this.$userFunctions.Logs) &&
          this.loginCheck() === "Reseller"
            ? false
            : true,
        itemArr: [false],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub: false,
        items: []
      },
      {
        id: "15",
        name: "Files",
        menuActiveCheck: "files",
        menuActive: this.menuActive("files"),
        main_class: "files",
        href: "#/files",
        hide: this.$allowedFunctions.includes(this.$userFunctions.Files)
          ? false
          : true,
        itemArr: [false],
        totalCnt: 0,
        purchasePendingCnt: 0,
        showSub: false,
        items: []
      }
    ];
    this.sideMenuItems = [];

    if (menuParams != "") {
      const menuOrder = (menuParams.MENUORDER || []).map(item => item.id);
      if (menuOrder.length) {
        // sort topMenuItems
        let sorted: any = [];
        for (const id of menuOrder) {
          const menuItem = this.topMenuItems.find(item => item.id == id);
          if (menuItem) {
            sorted.push(menuItem);
          }
        }
        const newItems = this.topMenuItems.filter(
          item => !menuOrder.includes(item.id)
        );
        sorted = [...sorted, ...newItems];
        this.topMenuItems = sorted;
      }
    }

    this.$forceUpdate();
    this.checkWidth();
  }

  async loadMenu() {
    // var newMenu: any = [];
    // var newSideMenu: any = [];
    // var userMenu = this.menuItems.slice(5, 8);
    // var menuParams = JSON.parse(sessionStorage.getItem("fullMenu"));

    // for (var m = 0; m < Object.keys(menuParams.MENUORDER).length; m++) {
    //   for (var mi = 0; mi < menuParams.MENUORDER.length; mi++) {
    //     if (menuParams.MENUORDER[m].name == menuParams.MENUORDER[mi].name) {
    //       newMenu.push(menuParams.MENUORDER[mi]);
    //     }
    //   }
    // }

    const topMenuItems = JSON.parse(
      sessionStorage.getItem("topMenu") || "[]"
    ).map(item => ({
      ...item,
      menuActive: this.menuActive(item.menuActiveCheck)
    }));
    const sideMenuItems = JSON.parse(
      sessionStorage.getItem("sideMenu") || "[]"
    ).map(item => ({
      ...item,
      menuActive: this.menuActive(item.menuActiveCheck)
    }));
    this.topMenuItems = topMenuItems;
    this.sideMenuItems = sideMenuItems;

    this.$forceUpdate();
    this.checkWidth();
  }

  isEmptySubItems(items, sub) {
    var ret = true;
    for (var val of items) {
      if (!val.hide && sub) {
        ret = false;
        break;
      }
    }

    return ret;
  }

  getClass(count) {
    var widths = ["col-12", "col-6", "col-4", "col-3"];
    return widths[count - 1];
  }

  forceredirect(e) {
    const arr = e.split("?") || [];
    let mainPath = e;
    if (arr.length > 0) {
      mainPath = arr[0];
    }
    if ("#" + this.$route.path == mainPath) {
      if (e == "#/quotes?qOpenStatus=8") {
        this.$parent.filters.qOpenStatus = [8];
      } else if (e == "#/quotes") {
        this.$parent.resetqOpenStatusList(false);
      } else if (e == "#/invoices") {
        this.$parent.resetFilters();
      }
      if (this.$parent.fetchData) {
        this.$parent.fetchData();
      }
      // this.$router.go(e);
      // this.$router.push(e)
    }
  }

  gotopending(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.$route.name == "Quotes") {
      this.$parent.filters.qOpenStatus = [8];
      this.$parent.fetchData();
    } else {
      this.$router.push({
        name: "Quotes",
        query: { qOpenStatus: "8" }
      });
    }
  }

  async IncognitoMode() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Setting",
        FunctionName: "IncognitoMode",
        IncognitoMode: 0
      });
      if (response.data.STATUS) {
        this.incognitoModeOn = "0";
        sessionStorage.setItem("IncognitoMode", "0");
        notifier.success(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err);
    } finally {
      // this.loading = false;
    }
  }

  async updatePerfectScroll() {
    await wait(300);
    this.$forceUpdate();
  }

  isEqualIgnoreCase(string1, string2) {
    if (string1 == "/hardware") {
      return "hardware" === string2.toLowerCase();
    } else {
      return string1.replace("/", "").toLowerCase() === string2.toLowerCase();
    }
  }

  hasPermission(type: string) {
    if (!type) return false;

    let ret = false;
    const allow = this.$allowedFunctions;
    const f = this.$userFunctions;

    if (type == "admin") {
      if (
        this.loginCheck() === "Customer" ||
        (this.loginCheck() === "Reseller" &&
          (allow.includes(f.Customers) ||
            allow.includes(f.All_Milestone_Accounts) ||
            allow.includes(f.Security_Groups) ||
            allow.includes(f.Setting_Export) ||
            allow.includes(f.External) ||
            allow.includes(f.Subscription) ||
            allow.includes(f.AccountTag) ||
            allow.includes(f.GlobalTag) ||
            allow.includes(f.CustomData) ||
            allow.includes(f.IncognitoMode)))
      ) {
        ret = true;
      }
    }

    return ret;
  }

  getSubMenus(menuItems: any = []) {
    let ret: any = [];
    for (const item of menuItems) {
      ret = [...ret, ...(item.menus || [])];
    }
    return ret;
  }

  toggleDashboard(e) {
    // $("#container.dashboard-page").show();
    $(".currentPage").removeClass("currentPage");
    $(".nav-item.dashboard").addClass("currentPage");
    window.history.pushState({}, "", "#/dashboard");
    this.$router.push("/dashboard");
  }

  refreshPage(url, event: any = null) {

    if (this.$route.path == url.replace("#", "")) {
      // this.$router.push(url);
      $(".currentPage").removeClass("currentPage");
      $(".nav-item." + url.replace("#/", "").replace("/0", "")).addClass("currentPage");
      if (this.$route.path === '/orderCreate/0') {
        if (confirm("Do you want to abandon this order and create a new one ?")) {
          document.location.href = '#/orderCreate/0';
          document.location.reload();
        } else {
          if (event) {
            event.preventDefault();
            event.stopPropagation();
          }
        }
      }
    }
  }
}
