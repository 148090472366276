











import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";

import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import { notifier } from "../models/common";


interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    line: any
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
})
export default class ProductAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, type: String, default: "" })
  productType?: string

  @Prop({ required: false, type: String, default: "" })
  deploymentId?: string

  @Prop({ required: false, type: String, default: "" })
  returnData!: string

  @Prop({ required: false, type: String, default: "products" })
  products?: string

  @Model('input', {
    type: String,
    required: true
  })
  readonly value!: string

  options: Option[] = []
  searchData: any[] = [];

  async created() {
    if (this.returnData.length) {
      this.products = this.returnData;
    }
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {

      if(!search) {
        const exportObj = {
          controller: "Deployments",
          FunctionName: "Products",
          productType : this.productType,
          deploymentId: this.deploymentId
        };

        if (this.returnData.length) {
          exportObj['Action'] = 'GenerateAssetTag';
        }
      
        const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    
    
        if (response.data.ERROR || !response.data.PRODUCTSARR.length) {
            return {
              options: []
            };
          }
    
          return this.createOptions(response.data.PRODUCTSARR)
      }

      const exportObj = {
        FunctionName: "Products",
        controller: "Deployments",
        productType : this.productType,
        getAll: 1,
        search,
        deploymentId: this.deploymentId
      };

      if (this.returnData.length) {
        exportObj['Action'] = 'GenerateAssetTag';
      }

      const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
      if (response.data.ERROR || !response.data.PRODUCTSARR.length) {
        return {
          options: []
        };
      }
      return this.createOptions(response.data.PRODUCTSARR)
    }

  createOptions(searchData: any[]) {
    const options = searchData.map(
      (item): Option => {
        if (this.returnData == 'product')
          return {
            value: `p${item.PURCHASEID}-${item.SKU}`,
            label: `${item.PRODUCTNAME} (Order #${item.PURCHASEID})`,
            text: item.PRODUCTNAME
          };
        else if (this.returnData == 'sku') {
           return {
            value: `p${item.PURCHASEID}-${item.SKU}`,
            label: `${item.SKU} (Order #${item.PURCHASEID})`,
            text: item.SKU
          };
        } else {
          return {
            value: `p${item.PURCHASEID}-${item.SKU}`,
            label: `${item.SKU} - ${item.PRODUCTNAME} (Order #${item.PURCHASEID})`,
            text: item.SKU
          };
        }
      }
    )

    this.searchData = searchData
    this.options = options

    return {options}
  }

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return;
    const option = this.options.find((o) => o.value === value)
    if (!option) {
      return
    }

    const line = this.searchData.find((item) => `p${item.PURCHASEID}-${item.SKU}` === value)

    if (!line) {
      console.warn(`no line found for value[${value}]`)
      return
    }
    this.$emit('selection', line)
  }
}
