










































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import PageTitle from "../components/pageTitle.vue";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import GlobalTagSelect from "../components/GlobalTagSelect.vue";
import CustomerAutoSuggestInput from "../components/CustomerAutoSuggestInput.vue";

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    LaddaButton,
    PageTitle,
    GlobalTagSelect,
    CustomerAutoSuggestInput
  }
})
export default class CustomDataAdd extends TSXComponent<void> {
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  saving: any = false;
  pageNumber = 1;
  items = [];
  details: any = {};
  fieldExists = false;
  customInfo: any = {
    ISGLOBALTAG: 0,
    CUSTOMFIELDNAME: "",
    CUSTOMFIELDTYPE: 1,
    ISSEARCH: 1,
    SHOWINLIST: 0,
    CUSTOMDTYPE: 1
  };
  customDropdownField = [
    {
      CUSTOMFIELDOPTIONNAME: "",
      CUSTOMFIELDID: 0,
      CUSTOMFIELDOPTIONID: 0
    }
  ];
  customDataName = "";
  selectedAIDs: number[] = [];
  fieldTypes = [
    { ID: 1, TEXT: "Order" },
    { ID: 2, TEXT: "Quote" },
    { ID: 3, TEXT: "Invoice" },
    { ID: 4, TEXT: "Account" },
    { ID: 5, TEXT: "Deployment" }
  ];
  customId = 0;
  selectedFieldTypeIds: number[] = [];
  customer = "";
  aId = 0;

  async created() {
    if (typeof this.$route.query.aName === "string") {
      this.customer = this.$route.query.aName.length ? this.$route.query.aName : "";
    }
    if (
      typeof this.$route.query.aId !== "undefined"
      && typeof this.$route.query.aId !== "number"
    ) {
      this.aId = this.$route.query.aId.length ? this.$route.query.aId : 0;
    }
    if (this.$route.params.id) {
      //edit
      this.customId = parseInt(this.$route.params.id);
      this.fetchData();
    }
  }

  async fetchData() {
    this.loading = true;
    try {
      var reqData = {
        controller: "Setting",
        FunctionName: "customDataEdit",
        dataId: this.customId
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        if (this.customId) {
          this.customDataName = this.details.CUSTOMINFO.CUSTOMFIELDDISPLAYNAME;
          this.aId = this.details.CUSTOMINFO.AID || 0;
          this.customer = this.details.CUSTOMINFO.ANAME || "";
        }
        this.customInfo = this.details.CUSTOMINFO;
        this.customDropdownField = this.details.CUSTOMNDROPDOWNFIELD;
        var customDropdown = {
          CUSTOMFIELDOPTIONNAME: "",
          CUSTOMFIELDID: 0,
          CUSTOMFIELDOPTIONID: 0
        };
        this.customDropdownField.push(customDropdown);
        this.selectedFieldTypeIds = this.details.CUSTOMINFO.FIELDTYPEIDS.split(
          ","
        ).map(id => parseInt(id));
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  listPageRedirection() {
    this.$router.push({ name: "CustomData" });
  }

  async saveField() {
    //remove html
    this.customInfo.CUSTOMFIELDDISPLAYNAME = this.removeHTML(
      this.customInfo.CUSTOMFIELDDISPLAYNAME
    );

    var result = await this.$validator.validateAll();
    if (!result || this.loading) {
      return;
    }
    if (result) {
      this.$validator.errors.clear();
      this.saving = true;
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Setting",
          FunctionName: "customDataEdit",
          dataId: this.customId,
          fieldName: this.customInfo.CUSTOMFIELDDISPLAYNAME,
          fieldType: this.selectedFieldTypeIds,
          searchable: this.customInfo.ISSEARCH ? 1 : 0,
          action: this.customId ? "update" : "add",
          customDropdownList: (this.customDropdownField || []).filter(
            item => item.CUSTOMFIELDOPTIONNAME.trim() != ""
          ),
          showInList: this.customInfo.SHOWINLIST ? 1 : 0,
          customDType: this.customInfo.CUSTOMDTYPE || 1,
          aId: this.customer && this.aId ? this.aId : 0,
          isGlobalTag: this.customInfo.ISGLOBALTAG || 0
        });

        if (response.data.ERROR) {
          this.saving = "error";
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS == 1) {
          this.saving = false;
          this.$router.push({ name: "CustomData" });
        } else {
          this.saving = "error";
          if (response.data.STATUS == 2) {
            this.fieldExists = true;
          }
        }
      } catch (err) {
        this.saving = "error";
      }
    }
  }
  // async checkFieldExists() {
  //   if (
  //     this.customInfo &&
  //     this.customInfo.CUSTOMFIELDNAME &&
  //     this.customInfo.CUSTOMFIELDNAME.trim().length
  //   ) {
  //     this.loading = true;
  //     try {
  //       var reqData = {
  //         controller: "Setting",
  //         FunctionName: "customDataEdit",
  //         dataId: this.customId,
  //         fieldName: this.customInfo.CUSTOMFIELDNAME,
  //         fieldCheck: 1
  //       };

  //       const response = await axios.post(
  //         dataURL + "?ReturnType=JSON",
  //         reqData
  //       );

  //       if (response.data.ERROR) {
  //         throw new Error(response.data.ERROR);
  //       }

  //       if (response.data.STATUS == 1) {
  //         this.details = response.data;
  //         if (response.data.CUSTOMFIELDSCOUNT) {
  //           this.fieldExists = true;
  //         } else {
  //           this.fieldExists = false;
  //         }
  //         // this.customInfo = response.data.CUSTOMINFO;
  //       }
  //     } catch (err) {
  //       // console.log(err.message);
  //     } finally {
  //       this.loading = false;
  //     }
  //   }
  // }
  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }
  addrow(index) {
    if (index == this.customDropdownField.length) {
      var customDropdown = {
        CUSTOMFIELDOPTIONNAME: "",
        CUSTOMFIELDID: 0,
        CUSTOMFIELDOPTIONID: 0
      };
      this.customDropdownField.push(customDropdown);
    }
  }
  async removeItem(index, from) {
    var reqBody = {
      FunctionName: "Delete"
    };
    var isDelete = 0;

    $(".EmailCheck").text("");
    if (this.customDropdownField[index].CUSTOMFIELDOPTIONID) {
      (reqBody["Controller"] = "Setting"),
        (reqBody["FunctionName"] = "customDataEdit"),
        (reqBody["dataId"] = this.customId),
        (reqBody["action"] = "delete"),
        (reqBody["customDropdownOptionID"] = this.customDropdownField[
          index
        ].CUSTOMFIELDOPTIONID);
      isDelete = 1;
    }
    this.customDropdownField.splice(index, 1);
    if (isDelete) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
      }
    }
  }

  updateSelectedFieldTypes(id, text) {
    if (this.selectedFieldTypeIds.includes(id)) {
      this.selectedFieldTypeIds = this.selectedFieldTypeIds.filter(
        typeId => typeId != id
      );
    } else {
      this.selectedFieldTypeIds.push(id);
    }
  }

  selectAllFieldTypes() {
    this.selectedFieldTypeIds = this.fieldTypes.map(item => item.ID);
  }

  resetFieldTypes() {
    this.selectedFieldTypeIds = [];
  }

  async updateCustomer(data) {
    if (!data.account) return;
    this.customer = data.account.ANAME;
    this.aId = data.account.AID;
  }
}
