
















import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";

export interface Item {}

interface Props {}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {}
})
export default class Loader extends TSXComponent<Props, Events> {}
