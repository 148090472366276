














































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier } from "../models/common";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import axios from "axios";
import LaddaButton from "../components/LaddaButton.vue";
import customerStatusComponent, {
  customerStatusValue
} from "../components/customerStatusUpdateTooltip.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { getDateRangeFilter } from "@/helpers/ApiHelper";

declare const getImageRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    customerStatusComponent,
    ConfirmRemoveItemModal,
    ListHeaderActions
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class profile extends TSXComponent<void> {
  isActive = "userSeats";
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  confirmRemoveModalVisible = false;
  userName = "john Egbert";
  customerList = [];
  customerCount = [];
  tableColumns = [];
  selectedID: number[] = [];
  excludedIDs: string[] = [];
  selectedAll = false;
  curruserId = sessionStorage.getItem("userId");
  statusCheckList = [1, 0];
  levelCheckList = [1, 2, 0];
  dataObj = {
    controller: "VARAdmin",
    FunctionName: "Details",
    subsystem: "VAR360",
    from: "customerList"
  };
  details = {
    CURRENTPAGE: "1",
    TOTALPAGES: "1",
    TOTALALERTS: 1,
    sDate: "",
    eDate: "",
    searchId: "",
    searchName: "",
    username: "",
    status: "",
    level: "",
    TOTAL: ""
  };
  sort = {
    field: "",
    direction: ""
  };
  filters = {
    uemail: "",
    username: "",
    sDate: "",
    eDate: "",
    status: "",
    level: "",
    statusName: ["Active", "InActive"],
    searchId: "",
    searchName: ""
  };
  images = {};
  url = "../assets/images/var-peppermint@3x.png";

  toggleActive(div) {
    this.isActive = div;
  }
  async created() {
    var dataObjCount = {
      controller: "VARAdmin",
      FunctionName: "Details",
      subsystem: "VAR360",
      from: "customerList"
    };
    var self = this;
    const response = await axios.post(
      dataURL + "?ReturnType=JSON",
      dataObjCount
    );
    if (response.data.STATUS == 1) {
      self.customerCount = response.data.CUSTOMERSLIST;
      self.details = response.data;
    }
    this.fetchData(this.dataObj, this, "", "", "");
  }
  resetSelected() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
  }
  async fetchData(
    dataObj = {
      controller: "VARAdmin",
      FunctionName: "Details",
      subsystem: "VAR360",
      from: "customerList"
    },
    container = this,
    keyName = "",
    fieldname = "",
    option = ""
  ) {
    var objId = sessionStorage.getItem("userId");
    // var dataObj1 =
    // {
    //   controller: "VARAdmin",
    //   FunctionName: "Details",
    //   subsystem: "VAR360",
    //   from: "customerList"
    // };
    // dataObj = dataObj1;
    // var userData = getRouteData(dataObj);
    var self = this;
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    try {
      if (response.data.STATUS == 1) {
        if (response.data.USERSDETAILS.CUSTOMERLOGO != "") {
          self.url = response.data.USERSDETAILS.CUSTOMERLOGO;
        }
        self.userName = response.data.USERSDETAILS.ACCOUNTNAME;
        self.customerList = response.data.CUSTOMERSLIST;
        self.details = response.data;
        if (
          (typeof dataObj["sDate"] != "undefined" && dataObj["sDate"] != "") ||
          (typeof dataObj["eDate"] != "undefined" && dataObj["eDate"] != "")
        ) {
          self.details.sDate = dataObj["sDate"];
          self.details.eDate = dataObj["eDate"];
        }
        self.details["TOTALALERTS"] = 1;
        self.loading = false;
        if (option === "new") {
          self.details["searchId"] = "";
          self.details["userName"] = "";
          self.details["sDate"] = "";
          self.details["eDate"] = "";
          self.details["searchEmail"] = 1;
          self.details["sortingName"] = 1;
          self.details["sortingDate"] = 1;
          self.details["sortingStatus"] = 1;
          self.details["sortingLevel"] = 1;
          self.details["sortingCost"] = 1;
        } else if (option !== "page") {
          self.details[keyName] = dataObj[fieldname];
        }
        if (dataObj["FunctionName"] == "Details") {
          self.sort = {
            field:
              typeof dataObj["order"] != "undefined" ? dataObj["order"] : null,
            direction:
              typeof dataObj["direction"] != "undefined"
                ? dataObj["direction"]
                : null
          };
          // self.filters = {
          //   uemail:
          //     typeof dataObj["uemail"] != "undefined"
          //       ? dataObj["uemail"]
          //       : null,
          //   username:
          //     typeof dataObj["username"] != "undefined"
          //       ? dataObj["username"]
          //       : null,
          //   sDate:
          //     typeof dataObj["sDate"] != "undefined" ? dataObj["sDate"] : null,
          //   eDate:
          //     typeof dataObj["eDate"] != "undefined" ? dataObj["eDate"] : null,
          //   status:
          //     typeof dataObj["status"] != "undefined"
          //       ? dataObj["status"]
          //       : null,
          //   level:
          //     typeof dataObj["level"] != "undefined" ? dataObj["level"] : null
          // };
        }
      } else if (response.data.ERROR) {
        // console.log(response.data.ERROR);
      }
    } catch (e) {
      // console.log("customer list : getRouteData error : ", e);
    }
  }
  loadNextpage(pageNumber) {
    this.dataObj["pageNumber"] = pageNumber;
    this.dataObj["uemail"] = this.details["searchByEmail"];
    this.dataObj["username"] = this.details["searchByName"];
    this.dataObj["sDate"] = this.details["sDate"];
    this.dataObj["eDate"] = this.details["eDate"];
    this.dataObj["status"] = this.details["status"];
    this.dataObj["level"] = this.details["level"];
    this.fetchData(this.dataObj, this, "", "", "new");
  }
  loadPage(pageNumber, type) {
    if (type == "prev") {
      pageNumber = parseInt(pageNumber) - 1;
    } else if (type == "next") {
      pageNumber = parseInt(pageNumber) + 1;
    }

    if (
      parseInt(pageNumber) <= parseInt(this.details.TOTALPAGES) &&
      parseInt(pageNumber) >= 1
    ) {
      this.dataObj["pageNumber"] = pageNumber;
      this.fetchData(this.dataObj, this, "", "", "page");
    }
  }
  sortingByEmail() {
    this.dataObj["order"] = 1;
    this.dataObj["direction"] =
      parseInt(this.details["searchEmail"]) === 1 ? 2 : 1;
    this.fetchData(this.dataObj, this, "searchEmail", "direction", "");
  }
  sortingByName() {
    this.dataObj["order"] = 2;
    this.dataObj["direction"] =
      parseInt(this.details["sortingName"]) === 1 ? 2 : 1;
    this.fetchData(this.dataObj, this, "sortingName", "direction", "");
  }
  sortingByDate() {
    this.dataObj["order"] = 3;
    this.dataObj["direction"] =
      parseInt(this.details["sortingDate"]) === 1 ? 2 : 1;
    this.fetchData(this.dataObj, this, "sortingDate", "direction", "");
  }
  sortingByStatus() {
    this.dataObj["order"] = 4;
    this.dataObj["direction"] =
      parseInt(this.details["sortingStatus"]) === 1 ? 2 : 1;
    this.fetchData(this.dataObj, this, "sortingStatus", "direction", "");
  }
  sortingByLevel() {
    this.dataObj["order"] = 5;
    this.dataObj["direction"] =
      parseInt(this.details["sortingLevel"]) === 1 ? 2 : 1;
    this.fetchData(this.dataObj, this, "sortingLevel", "direction", "");
  }
  sortingByCost() {
    this.dataObj["order"] = 6;
    this.dataObj["direction"] =
      parseInt(this.details["sortingCost"]) === 1 ? 2 : 1;
    this.fetchData(this.dataObj, this, "sortingCost", "direction", "");
  }
  searchByEmail() {
    this.dataObj["uemail"] = this.details.searchId;
    this.filters.searchId = this.details.searchId;
    if (this.details.searchId != "" || this.details.searchId != undefined) {
      this.fetchData(this.dataObj, this, "searchId", "uemail", "");
    }
  }
  updateValue(id, value) {
    if (value != "") {
      $(".search-list.right#" + id).removeAttr("style");
      $(".reset-list.right").removeClass("disableCursor");
    } else {
      $(".search-list.right#" + id).attr("style", "cursor:not-allowed");
      $(".reset-list.right").addClass("disableCursor");
      if (id == "searchId") this.resetSearchByEmail();
      else this.resetSearchByName();
    }
  }
  resetSearchByEmail() {
    $(".search-list").attr("style", "cursor:not-allowed");
    $(".reset-list.right").addClass("disableCursor");
    this.dataObj["uemail"] = "";
    this.filters.searchId = "";
    // (document.getElementById("searchId") as HTMLInputElement).value = "";
    this.details.searchId = "";
    this.fetchData(this.dataObj, this, "searchId", "uemail", "");
  }

  resetSearchByName() {
    $(".search-list").attr("style", "cursor:not-allowed");
    $(".reset-list.right").addClass("disableCursor");
    this.dataObj["username"] = "";
    this.details.searchName = "";
    this.filters.searchName = "";
    // (document.getElementById("searchName") as HTMLInputElement).value = "";
    this.fetchData(this.dataObj, this, "searchName", "username", "");
  }
  searchByName() {
    this.dataObj["username"] = this.details.searchName;
    this.filters.searchName = this.details.searchName;
    if (this.details.searchName != undefined || this.details.searchName != "") {
      this.fetchData(this.dataObj, this, "searchName", "username", "");
    }
  }

  updateDate(id, value) {
    if (value != "") {
      $(".selectAll.left." + id).removeClass("disableCursor");
      $(".reset-list.right").removeClass("disableCursor");
    } else {
      $(".selectAll.left." + id).addClass("disableCursor");
      $(".reset-list.right").addClass("disableCursor");
    }
  }
  searchByDate() {
    this.dataObj["sDate"] = this.details.sDate;
    this.dataObj["eDate"] = this.details.eDate;
    this.filters.sDate = this.details.sDate;
    this.filters.eDate = this.details.eDate;
    if (
      (this.details.sDate == undefined || this.details.sDate == "") &&
      (this.details.eDate == "" || this.details.eDate == undefined)
    ) {
      // container.notification('alert','Please Fill From or To Date');
    } else {
      this.fetchData(this.dataObj, this, "", "", "");
    }
  }
  resetDate() {
    $(".selectAll.left.eDate").addClass("disableCursor");
    $(".reset-list.right").addClass("disableCursor");
    this.dataObj["sDate"] = "";
    this.dataObj["eDate"] = "";
    this.details.sDate = "";
    this.details.eDate = "";
    this.filters.sDate = "";
    this.filters.eDate = "";
    // (document.getElementById("sDate") as HTMLInputElement).value = "";
    // (document.getElementById("eDate") as HTMLInputElement).value = "";
    this.fetchData(this.dataObj, this, "", "", "");
  }

  checkAllStatusList() {
    this.statusCheckList = [1, 0];
    this.dataObj["status"] = this.statusCheckList.toString();
    // var items = document.getElementsByName("statusCheckBox");
    // for (var i = 0; i < items.length; i++) {
    //   if ((items[i] as HTMLInputElement).type == "checkbox")
    //     (items[i] as HTMLInputElement).checked = true;
    // }
    this.filters.statusName = ["Active", "InActive"];
    this.fetchData(this.dataObj, this, "statusCheckListStr", "status", "");
  }

  async uncheckAllStatus() {
    this.statusCheckList = [-1];
    this.dataObj["status"] = [-1].toString();
    this.dataObj["pageNumber"] = 1;
    this.filters.statusName = [];
    await this.fetchData(
      this.dataObj,
      this,
      "statusUnCheckListStr",
      "status",
      ""
    );
  }

  checkStatusList(value, name:string) {
    var index = this.statusCheckList.indexOf(value);
    if (index < 0) {
      this.statusCheckList.push(value);
    } else {
      this.statusCheckList.splice(index, 1);
    }
    const statusIndex = this.filters.statusName.findIndex(
      statusValue => statusValue === name
    );
    if (statusIndex === -1) {
      this.filters.statusName.push(name);
    } else {
      this.filters.statusName.splice(statusIndex, 1);
    }
    this.dataObj["status"] = this.statusCheckList.toString();
    this.fetchData(this.dataObj, this, "statusCheckListStr", "status", "");
  }
  resetStatusList() {
    this.resetSelected();
    this.checkAllStatusList();
    // this.statusCheckList = [];
    // this.dataObj["status"] = this.statusCheckList.toString();
    // var items = document.getElementsByName("statusCheckBox");
    // for (var i = 0; i < items.length; i++) {
    //   if ((items[i] as HTMLInputElement).type == "checkbox")
    //     (items[i] as HTMLInputElement).checked = false;
    // }
    // this.fetchData(this.dataObj, this, "statusCheckListStr", "status", "");
  }
  checkAllLevelList() {
    this.levelCheckList = [1, 2, 0];
    this.dataObj["level"] = this.levelCheckList.toString();
    var items = document.getElementsByName("statusCheckBoxLevel");
    for (var i = 0; i < items.length; i++) {
      if ((items[i] as HTMLInputElement).type == "checkbox")
        (items[i] as HTMLInputElement).checked = true;
    }
    this.fetchData(this.dataObj, this, "LevelCheckListStr", "level", "");
  }
  checkLevelList(value) {
    var index = this.levelCheckList.indexOf(value);
    if (index < 0) {
      this.levelCheckList.push(value);
    } else {
      this.levelCheckList.splice(index, 1);
    }
    this.dataObj["level"] = this.levelCheckList.toString();
    this.fetchData(this.dataObj, this, "LevelCheckListStr", "level", "");
  }
  checkExcludedIDs(items, key) {
    if (this.selectedAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.customerList.length) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }
  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }
  async changeStatus(userId: string, status: customerStatusValue) {
    //const statusIndex = status === QuoteStatusValue.Open ? '1' : status === QuoteStatusValue.Close ? '2' : status === QuoteStatusValue.Purchased ? '5' : ''
    try {
      this.loading = true;
      var dataObj = {
        controller: "customers",
        FunctionName: "update",
        userId: userId,
        status: status,
        action: 1
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      try {
        if (response.data.STATUS == 1) {
          // console.log("res", response);
          this.selectedID = [];
          this.selectedAll = false;
          this.excludedIDs = [];
          await this.fetchData();
          if (status == 1) {
            notifier.success(`User #${userId} is activated`);
          } else {
            notifier.success(`User #${userId} is inactivated`);
          }

        }
      } catch (e) {
        // console.log("customer list : getRouteData error : ", e);
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  async changeLevel(userId: string, level: customerStatusValue) {
    try {
      this.loading = true;
      var dataObj = {
        controller: "customers",
        FunctionName: "update",
        userId: userId,
        level: level,
        action: 2
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      try {
        if (response.data.STATUS == 1) {
          // console.log("res", response);
          this.selectedID = [];
          this.selectedAll = false;
          this.excludedIDs = [];
          await this.fetchData();
          notifier.success(`User Level Updated Successfully`);
        }
      } catch (e) {
        // console.log("customer list : getRouteData error : ", e);
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }
  showDetail(userId) {
    this.$router.push({ name: "EditUser", params: { id: userId } });
  }
  async updateusers(from) {
    try {
      this.loading = true;
      var dataObj = {
        controller: "customers",
        FunctionName: "update",
        userId: this.selectedID.join(","),
        status: 0,
        action: 3
      };
      if (from) {
        dataObj.status = 2;
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      try {
        if (response.data.STATUS == 1) {
          // console.log("res", response);
          this.selectedID = [];
          this.selectedAll = false;
          this.excludedIDs = [];
          await this.fetchData();
          if (dataObj.status == 2) {
            notifier.success(`Deleted users successfully`);
          } else {
            notifier.success(`Disabled users successfully`);
          }
          // notifier.success(`User Level Updated Successfully`);
        }
      } catch (e) {
        // console.log("customer list : getRouteData error : ", e);
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }
  onFileChange(event) {
    var vm = this;
    let formData = new FormData();
    this.images["image"] = event.target.files[0];
    var file = event.target.files[0];
    formData.append("logo", file, file.name);
    this.$forceUpdate();
    var reader = new FileReader();
    const fileTypeUpload = $("#input-upload").get(0).files[0].type;
    const fileNameUpload = $("#input-upload").get(0).files[0].name;
    const dataFileType = ["image/jpeg", "image/tiff", "image/png","image/gif"];
    const parseFileName = fileNameUpload.split(".");
    var isValid = true;
    if (
      dataFileType.indexOf(fileTypeUpload) == -1 || fileTypeUpload == ""
    ) {
      $(".orderFileErrorMsg").html(
        "Invalid image file type, please upload a .jpeg, .jpg, .png, or .gif file."
      );
      isValid = false;
    }
    else{
      $(".orderFileErrorMsg").html("");
    }
    if (!isValid) {
      return;
    }
    this.url = URL.createObjectURL(event.target.files[0]);
    reader.onload = function(e) {
      var element = $("#image");
      element!.src = e!.target!.result;
      element!.src = vm.url;
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  async uploadLogo() {
    var routedata = {
      controller: "Helpers",
      FunctionName: "uploadToAws",
      status: 0,
      action: 3
    };
    var isValid = true;
    if ($("#input-upload").get(0).files.length) {
      const fileTypeUpload = $("#input-upload").get(0).files[0].type;
      const fileNameUpload = $("#input-upload").get(0).files[0].name;
      const dataFileType = ["image/jpeg", "image/tiff", "image/png","image/gif"];
      const parseFileName = fileNameUpload.split(".");
      routedata["customerLogo"] = $("#input-upload").get(0).files[0];
      routedata["FileName"] = fileNameUpload;
      routedata["FileType"] =  fileTypeUpload;
      if (
        dataFileType.indexOf(fileTypeUpload) == -1 || fileTypeUpload == ""
      ) {
        $(".orderFileErrorMsg").html(
          "Invalid image file type, please upload a .jpeg, .jpg, .png, or .gif file."
        );
        isValid = false;
      }
    }
    if(this.userName.trim() == ""){
       $(".AccountErrMsg").html(
          "Account name should not be empty"
        );
        isValid = false;
    }
    routedata["AccountName"] =  this.userName.trim();
    if (!isValid) {
      return;
    }
    var $this = this;
    this.loading = true;
    await getImageRouteData(routedata)
    .then(async (response, statusText, jqXHR) => {
      $this.url = response.data.S3URL;
      $('#logo.customer-logo.has-custom-image img').attr('src', $this.url);
      $this.fetchData($this.dataObj, $this, "", "", "");
      this.loading = false;
    })
    .catch(function(error) {
      // console.log(error);
    });
  }

  getFilterValue(header) {
    let filter = '';
    switch (header) {
      case 'STATUS':
        if (this.filters.statusName && !this.filters.statusName.length)
          filter = "None";
        else if (
          this.filters.statusName &&
          this.filters.statusName.length != 2
        ) {
          filter = this.filters.statusName.join(", ");
        }
        break;
      default:
        // nothing
        break;
    }
    return filter;
  }
}
