var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:(_vm.fromQuote || ['OrderEdit', 'DuplicateOrder', 'StandardOrderCreate'].includes(_vm.$route.name)) &&
    _vm.item.ISCONFIG
      ? 'panel-collapse collapse in show collapse' + _vm.item.baseProductId
      : ''},[_c('div',{staticClass:"list-item row align-items-center",class:{
      'is-fee': _vm.$parent.isFee(_vm.item),
      cancelReturn:
        (_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
        this.$route.name != 'DuplicateOrder',
      'is-included': _vm.item.included || false,
      'is-config': _vm.item.ISCONFIG || false,
      'last-group-item': _vm.item.isLastGroupItem || false,
      'has-configs': _vm.item.configsCnt || 0
    },attrs:{"data-index":_vm.index}},[(!_vm.$parent.isCancelReturn(_vm.item))?_c('span',{staticClass:"include-btn-ico",class:{ 'exclude-btn': _vm.item.included },on:{"click":function($event){return _vm.$parent.includeItem(_vm.index)}}},[(!_vm.item.included && _vm.showIncludeBtn(_vm.item))?_c('img',{attrs:{"src":require("@/assets/images/include-btn-ico.svg")}}):(_vm.item.included)?_c('img',{attrs:{"src":require("@/assets/images/exclude-btn-ico.svg")}}):_vm._e()]):_vm._e(),(_vm.item.configsCnt || 0)?_c('div',{staticClass:"calloutExpandContainer"},[_c('a',{staticClass:"position-absolute collapseBtn",attrs:{"role":"button","data-toggle":"collapse","data-parent":"#accordion","href":'.collapse' + _vm.item.baseProductId,"aria-expanded":"true","aria-controls":'collapse' + _vm.item.baseProductId},on:{"click":function($event){_vm.item.expanded = !(_vm.item.expanded || false)}}},[_c('span',{staticClass:"rowExpandBtn badge"})]),_c('span',{staticClass:"calloutExpand badge"},[_vm._v(" "+_vm._s(_vm.item.configsCnt || 0)+" ")])]):_vm._e(),_c('div',{staticClass:"col-2 left sku col-sku"},[_c('span',{staticClass:"new-quote-name-input editable-new-quote"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.item.sku),expression:"item.sku",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:({
            required:
              _vm.item.name != '' || (_vm.item.sku == '' && parseInt(_vm.item.quantity)),
            skucheck: _vm.skucheck
          }),expression:"{\n            required:\n              item.name != '' || (item.sku == '' && parseInt(item.quantity)),\n            skucheck\n          }"},{name:"pasteTrim",rawName:"v-pasteTrim"}],staticClass:"initial up",class:{
            error:
              _vm.errors.has(("sku_" + _vm.index)) ||
              (_vm.item.sku == '' && parseInt(_vm.item.quantity))
          },attrs:{"type":"text","name":("sku_" + _vm.index),"id":("sku_" + _vm.index),"placeholder":"Product sku","maxlength":"70","autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
              this.$route.name != 'DuplicateOrder'},domProps:{"value":(_vm.item.sku)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "sku", $event.target.value.trim())},function($event){return _vm.inputSKU(_vm.item, _vm.index, $event)}],"change":function($event){!_vm.$parent.isFee(_vm.item) ? _vm.$emit('SKULookup', _vm.index, _vm.item) : ''},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.item.sku != '' && !_vm.$parent.isFee(_vm.item))?_c('img',{staticClass:"cat-ico",class:{
            'cat-not-available':
              typeof _vm.item.ItemCategory == 'undefined' ||
              _vm.item.ItemCategory == 0
          },attrs:{"src":_vm.categoryImgLink(_vm.item),"title":_vm.$parent.getSKUCatTitle(_vm.item)},on:{"click":function($event){return _vm.$parent.showProductCatTT(_vm.item, _vm.index, $event)}}}):_vm._e(),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[(_vm.$parent.productCatTTVisibleIndex === _vm.index)?_c('ProductCatTooltip',{staticClass:"agent-tooltip",attrs:{"currentItem":_vm.item,"hideConfigToggle":!_vm.includedEnabled || _vm.$parent.isFee(_vm.item),"configCategory":_vm.configCategory,"from":'order'},on:{"configToggleChange":function($event){return _vm.$parent.configToggleChange(_vm.item, _vm.index)},"close":function($event){_vm.$parent.productCatTTVisibleIndex = ''}}}):_vm._e(),(_vm.$parent.feeVisibleIndex === _vm.index)?_c('ConfirmYesNoTooltip',{staticClass:"agent-tooltip",attrs:{"message":'Is this a FEE to be hidden from the invoices, yes or no?'},on:{"yes":function($event){return _vm.$parent.itemIsFee(_vm.item, true, _vm.index)},"no":function($event){return _vm.$parent.itemIsFee(_vm.item, false, _vm.index)},"close":function($event){return _vm.$parent.closeConfirmItemIsFee(_vm.item, _vm.index)}}}):_vm._e()],1)],1),(!(_vm.item.configsCnt || 0))?_c('span',{staticClass:"newline-btn",on:{"click":function($event){return _vm.$parent.addNewSSLine($event, _vm.ss.subId, _vm.item, _vm.index)}}},[_c('img',{attrs:{"src":require("@/assets/images/plus.svg")}})]):_vm._e(),(!(_vm.item.configsCnt || 0))?[_c('span',{staticClass:"buildHeader-btn",on:{"click":function () {
            _vm.buildHeaderTooltipIndex = _vm.index;
          }}},[_c('img',{attrs:{"src":require("@/assets/svg/underline.svg")}})]),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[(_vm.buildHeaderTooltipIndex == _vm.index)?_c('BuildHeaderTooltip',{staticClass:"agent-tooltip",attrs:{"buildHeader":_vm.item.buildHeader},on:{"save":function (data) { return _vm.saveBuildHeader(data, _vm.item); },"close":function($event){_vm.buildHeaderTooltipIndex = -1}}}):_vm._e()],1)]:_vm._e()],2),_c('div',{staticClass:"col-3 left"},[_c('span',{staticClass:"new-quote-name-input editable-new-quote"},[_c('span',{staticClass:"loader hidden"}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.item.name),expression:"item.name",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:({
            required:
              _vm.item.sku != '' || (_vm.item.name == '' && parseInt(_vm.item.quantity))
          }),expression:"{\n            required:\n              item.sku != '' || (item.name == '' && parseInt(item.quantity))\n          }"}],staticClass:"initial",class:{
            error:
              _vm.errors.has(("name_" + _vm.index)) ||
              (_vm.item.name == '' && parseInt(_vm.item.quantity))
          },attrs:{"type":"text","name":("name_" + _vm.index),"placeholder":"Name","id":("name_" + _vm.index),"maxlength":"255","autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
              this.$route.name != 'DuplicateOrder'},domProps:{"value":(_vm.item.name)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "name", $event.target.value.trim())},function($event){return _vm.$emit('addDelete', _vm.index)}],"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-1-5 left",class:{
        'hasPrice-ico':
          _vm.$parent.notHPSource() &&
          _vm.$parent.selectingDisty(_vm.item.Disty) &&
          typeof _vm.item.DistyPrice != 'undefined'
      }},[_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput",class:{ priceHover: _vm.item.priceHover || 0 },on:{"mouseenter":function($event){return _vm.$parent.mouseEnterBase('price', _vm.item, _vm.index)},"mouseleave":function($event){return _vm.$parent.mouseLeaveBase('price', _vm.item, _vm.index)}}},[_c('span',{staticClass:"loader hidden"}),_c('span',{staticClass:"left currency-mark"},[_vm._v("$")]),(_vm.item.configsCnt || 0)?_c('vue-numeric',{staticClass:"number initial",attrs:{"currency":"","separator":",","precision":_vm.expandDecimalPrecision,"type":"text","value":_vm.$parent.getBaseTotal(_vm.item, _vm.index).price,"minus":true,"disabled":true}}):_c('vue-numeric',{directives:[{name:"validate",rawName:"v-validate",value:({
            required:
              _vm.item.sku != '' ||
              _vm.item.name != '' ||
              (_vm.item.isFee == true && _vm.item.price == 0)
          }),expression:"{\n            required:\n              item.sku != '' ||\n              item.name != '' ||\n              (item.isFee == true && item.price == 0)\n          }"},{name:"currencyAll",rawName:"v-currencyAll"}],staticClass:"number initial",class:{
            error:
              _vm.errors.has(("cost_" + _vm.index)) ||
              (_vm.item.isFee == true && _vm.item.price == 0) ||
              (_vm.item.isContractFee && !_vm.item.price),
            distyError:
              _vm.item.distyError === true ||
              (_vm.$parent.autoCost == 1 &&
                _vm.$parent.notHPSource() &&
                _vm.$parent.selectingDisty(_vm.item.Disty) &&
                typeof _vm.item.DistyPrice != 'undefined' &&
                _vm.item.DistyPrice > 0 &&
                _vm.item.price < _vm.item.DistyPrice)
          },attrs:{"currency":"","separator":",","precision":_vm.expandDecimalPrecision,"placeholder":_vm.Cost,"type":"text","name":("cost_" + _vm.index),"minus":true,"id":("cost_" + _vm.index),"max":999999999.99,"maxlength":12,"autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
              this.$route.name != 'DuplicateOrder',"disabled":_vm.item.disable},nativeOn:{"input":function($event){_vm.clearSelectedDisty(_vm.item);
            _vm.$emit('calcWithChangeCostPrice');}},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.price"}})],1),(
          _vm.$parent.notHPSource() &&
            _vm.$parent.selectingDisty(_vm.item.Disty) &&
            typeof _vm.item.DistyPrice != 'undefined' &&
            !(_vm.item.isFee || _vm.item.isContractFee || false)
        )?[(_vm.item.DistyPrice > 0)?[_c('span',{staticClass:"disty-pa-indicator",attrs:{"title":_vm.item.Disty + ' Price: ' + _vm.item.DistyPrice_Format}},[_c('img',{attrs:{"src":require("../assets/images/statistics.png")}}),(_vm.item.price >= _vm.item.DistyPrice)?_c('span',{staticClass:"disty-ico-ok"},[_vm._v("✔")]):(_vm.item.price < _vm.item.DistyPrice)?_c('span',{staticClass:"disty-ico-notok"},[_c('img',{attrs:{"src":require("../../public/reseller/images/alert.png")}})]):_vm._e()])]:(_vm.item.DistyPrice == 0)?[_c('span',{staticClass:"price-not-available disty-pa-indicator"},[_c('img',{attrs:{"src":require("../assets/images/statistics.png"),"title":_vm.item.Disty + ' Price: ' + 'Not Available'}})])]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"col-1-5 left d-grid",class:{
        isPriceExceeded: _vm.item.contractPrice != undefined
        && _vm.item.contractPrice.length
        && _vm.item.sku != ''
        && !_vm.$parent.hasConfigs(_vm.item, _vm.index)
        && (_vm.isPriceExceeded(_vm.item) || _vm.isPriceListExceeded(_vm.item))
      },on:{"mouseleave":function($event){return _vm.hideContractTooltip()}}},[_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput",class:{ customerPriceHover: _vm.item.customerPriceHover || 0 },on:{"mouseenter":function($event){return _vm.$parent.mouseEnterBase('customerPrice', _vm.item, _vm.index)},"mouseleave":function($event){return _vm.$parent.mouseLeaveBase('customerPrice', _vm.item, _vm.index)}}},[_c('span',{staticClass:"left currency-mark"},[_vm._v("$")]),(_vm.item.configsCnt || 0)?_c('vue-numeric',{staticClass:"number initial",attrs:{"currency":"","separator":",","precision":_vm.expandDecimalPrecision,"type":"text","value":_vm.$parent.getBaseTotal(_vm.item, _vm.index).customerPrice,"minus":true,"disabled":true}}):_c('vue-numeric',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.item.sku != '' || _vm.item.name != '' }),expression:"{ required: item.sku != '' || item.name != '' }"},{name:"currencyAll",rawName:"v-currencyAll"}],staticClass:"number initial",class:{ error: _vm.errors.has(("cust_cost_" + _vm.index)) },attrs:{"currency":"","separator":",","precision":_vm.expandDecimalPrecision,"placeholder":_vm.Price,"minus":true,"type":"text","name":("cust_cost_" + _vm.index),"id":("cust_cost_" + _vm.index),"max":999999999.99,"maxlength":12,"autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
              this.$route.name != 'DuplicateOrder',"disabled":_vm.$parent.isFee(_vm.item) || _vm.item.disable},nativeOn:{"input":function($event){return _vm.$emit('marginCalc1')}},model:{value:(_vm.item.customerPrice),callback:function ($$v) {_vm.$set(_vm.item, "customerPrice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.customerPrice"}})],1),(
          _vm.item.contractPrice != undefined &&
            _vm.item.contractPrice.length &&
            _vm.item.sku != '' &&
            !(_vm.item.configsCnt || 0)
        )?[_c('span',{staticClass:"contractPrice",style:(_vm.contractPriceTooltipIndex === _vm.index ? 'z-index: 99999' : ''),on:{"mouseenter":function($event){return _vm.showContractTooltip(_vm.index)}}},[_vm._v("Price Found")]),(_vm.contractPriceTooltipIndex === _vm.index)?_c('ContractPriceTooltip',{attrs:{"data":_vm.item.contractPrice},on:{"updateContractPrice":function (data) { return _vm.updateContractPrice(data, _vm.item); },"close":function($event){return _vm.hideContractTooltip()}}}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"col-1 noOverflow left margin-col"},[(_vm.$parent.marginView == 'margin_per')?[(_vm.$parent.marginInput == 'amount')?_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput"},[_c('span',{staticClass:"left"},[_vm._v("$")]),(_vm.item.configsCnt || 0)?_c('vue-numeric',{staticClass:"number price_margin",attrs:{"currency":"","separator":",","precision":2,"type":"text","value":_vm.$parent.getBaseTotal(_vm.item, _vm.index).margin,"minus":true,"disabled":true}}):_c('vue-numeric',{directives:[{name:"currencyAll",rawName:"v-currencyAll"}],staticClass:"number price_margin",attrs:{"currency":"","separator":",","precision":2,"placeholder":_vm.margin,"type":"text","minus":true,"id":("margin_" + _vm.index),"disabled":_vm.$parent.isFee(_vm.item) || _vm.item.disable,"maxlength":"6","autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
                this.$route.name != 'DuplicateOrder'},nativeOn:{"input":function($event){return _vm.$emit('marginCalc2', _vm.index)}},model:{value:(_vm.item.margin),callback:function ($$v) {_vm.$set(_vm.item, "margin", _vm._n($$v))},expression:"item.margin"}})],1):_c('span',{staticClass:"new-quote-cost-input editable-new-quote"},[(_vm.item.configsCnt || 0)?_c('vue-numeric',{staticClass:"number",attrs:{"currency":"","separator":",","precision":4,"type":"text","value":_vm.$parent.getBaseTotal(_vm.item, _vm.index).marginRate,"minus":true,"disabled":true}}):_c('vue-numeric',{directives:[{name:"marginOnly",rawName:"v-marginOnly"}],staticClass:"number",attrs:{"id":("margin_" + _vm.index),"precision":4,"maxlength":"7","type":"text","minus":true,"disabled":_vm.$parent.isFee(_vm.item) || _vm.item.disable,"autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
                this.$route.name != 'DuplicateOrder'},on:{"blur":function($event){return _vm.$emit('marginRateBlur', _vm.index)}},nativeOn:{"input":function($event){return _vm.$emit('marginRate', _vm.index)}},model:{value:(_vm.item.marginRate),callback:function ($$v) {_vm.$set(_vm.item, "marginRate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.marginRate"}})],1)]:(_vm.$parent.marginView == 'total_margin')?[(_vm.$parent.marginInput == 'amount')?_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput"},[(_vm.item.configsCnt || 0)?[_vm._v(" $"+_vm._s(_vm._f("formatCurrency")((_vm.$parent.getBaseTotal(_vm.item, _vm.index).margin * _vm.item.quantity)))+" ")]:[_vm._v(" $"+_vm._s(_vm._f("formatCurrency")(((_vm.item.customerPrice - _vm.item.price) * _vm.item.quantity)))+" ")]],2):_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput"},[(_vm.item.configsCnt || 0)?[_vm._v(" "+_vm._s(_vm.$parent.getBaseTotal(_vm.item, _vm.index).marginRate)+"% ")]:[_vm._v(" "+_vm._s(_vm.item.marginRate)+"% ")]],2)]:_vm._e()],2),_c('div',{staticClass:"col-1 noOverflow left",class:{
        'hasQty-ico':
          _vm.$parent.notHPSource() &&
          _vm.$parent.selectingDisty(_vm.item.Disty) &&
          typeof _vm.item.DistyAvailability != 'undefined'
      }},[_c('span',{staticClass:"new-quote-quantity-input editable-new-quote"},[(_vm.item.configsCnt || 0)?_c('input',{attrs:{"disabled":""},domProps:{"value":_vm.item.quantity}}):[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.item.quantity),expression:"item.quantity",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:({
              required:
                _vm.item.sku != '' ||
                _vm.item.name != '' ||
                _vm.item.price == 0 ||
                _vm.item.customerPrice == 0,
              min_value: (_vm.item.sku != '' || _vm.item.name != '') == true ? 1 : 0
            }),expression:"{\n              required:\n                item.sku != '' ||\n                item.name != '' ||\n                item.price == 0 ||\n                item.customerPrice == 0,\n              min_value: (item.sku != '' || item.name != '') == true ? 1 : 0\n            }"},{name:"digitOnly",rawName:"v-digitOnly"}],staticClass:"number initial",class:{ error: _vm.errors.has(("quantity_" + _vm.index)) },attrs:{"type":"text","placeholder":"Qty","name":("quantity_" + _vm.index),"id":("quantity_" + _vm.index),"disabled":_vm.$parent.isFee(_vm.item),"autocomplete":"off","maxlength":"8","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
                this.$route.name != 'DuplicateOrder'},domProps:{"value":(_vm.item.quantity)},on:{"keyup":function($event){return _vm.$emit('totalCal', _vm.index)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "quantity", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]],2),(
          _vm.$parent.notHPSource() &&
            _vm.$parent.selectingDisty(_vm.item.Disty) &&
            typeof _vm.item.DistyAvailability != 'undefined' &&
            !(_vm.item.isFee || _vm.item.isContractFee || false)
        )?[(_vm.item.DistyAvailability > 0)?[_c('span',{attrs:{"title":'Availability: ' + _vm.item.DistyAvailability_Format + ' units'}},[_c('img',{attrs:{"src":require("../assets/images/pack.png")}}),(_vm.item.quantity <= _vm.item.DistyAvailability)?_c('span',{staticClass:"disty-ico-ok"},[_vm._v("✔")]):(_vm.item.quantity > _vm.item.DistyAvailability)?_c('span',{staticClass:"disty-ico-notok"},[_c('img',{attrs:{"src":require("../../public/reseller/images/alert.png")}})]):_vm._e()])]:(_vm.item.DistyAvailability == 0)?[_c('span',{staticClass:"qty-not-available"},[_c('img',{attrs:{"src":require("../assets/images/pack.png"),"title":'Availability: Not Available'}})])]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"col-1 totalPriceWarn left"},[(_vm.item.configsCnt || 0)?_c('span',[_vm._v(" "+_vm._s(_vm.dollarFormat(_vm.$parent.getBaseTotal(_vm.item, _vm.index).total))+" ")]):[(_vm.item.isFee)?_c('span',{staticClass:"total noOverflow"},[_vm._v(" $"),_c('span',[_vm._v("0.00")])]):_c('span',{class:{ total: true, update: _vm.item.priceWarn, noOverflow: true }},[(_vm.item.total < 0)?_c('span',[_vm._v("-$"+_vm._s(_vm._f("formatCurrency")(_vm.item.total,true)))]):_c('span',[_vm._v("$"+_vm._s(_vm._f("formatCurrency")(_vm.item.total)))])]),_c('PriceWarnTooltip',{staticClass:"PriceWarnTooltip"})]],2),_c('div',{staticClass:"col-1 noOverflow left col-tax"},[_c('div',{staticClass:"d-flex w-100 justify-content-center"},[_c('div',{staticClass:"smartCheckBox",class:{
            checkbox_checked: (_vm.item.taxRate || 0) != 0,
            checkbox_unchecked: (_vm.item.taxRate || 0) == 0,
            disabled: this.$parent.$parent.USECUSTOMTAX || _vm.$parent.isFee(_vm.item)
          },attrs:{"id":("taxable_" + _vm.index)},on:{"click":function($event){return _vm.$parent.itemTaxable(_vm.index)}}})])]),(!_vm.$parent.isEmptyRow(_vm.item, _vm.i))?_c('img',{staticClass:"deleteIcon",attrs:{"src":"images/close_search.png"},on:{"click":function($event){return _vm.$emit('removeItem', _vm.index)}}}):_vm._e()]),(_vm.item.configsCnt || 0)?_c('div',{staticClass:"list-item row align-items-center is-included is-config is-base-unit",class:{
      cancelReturn:
        _vm.$parent.isCancelReturn(_vm.item) && this.$route.name != 'DuplicateOrder'
    },attrs:{"data-index":_vm.index}},[_c('div',{staticClass:"col-2 left sku col-sku"},[_c('span',{staticClass:"new-quote-name-input editable-new-quote"},[_c('input',{staticClass:"initial up bg-white",class:{ 'bg-transparent': _vm.$parent.isCancelReturn(_vm.item) },staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"type":"text","autocomplete":"off","readonly":""},domProps:{"value":_vm.item.sku}}),(_vm.item.sku != '' && !_vm.$parent.isFee(_vm.item))?_c('img',{staticClass:"cat-ico",attrs:{"src":require("@/assets/images/config-cat-ico.svg")}}):_vm._e()]),_c('span',{staticClass:"newline-btn",on:{"click":function($event){return _vm.$parent.addNewSSLine($event, _vm.ss.subId, _vm.item, _vm.index)}}},[_c('img',{attrs:{"src":require("@/assets/images/plus.svg")}})]),_c('span',{staticClass:"buildHeader-btn",on:{"click":function () {
          _vm.buildHeaderTooltipIndex = _vm.index;
        }}},[_c('img',{attrs:{"src":require("@/assets/svg/underline.svg")}})]),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[(_vm.buildHeaderTooltipIndex == _vm.index)?_c('BuildHeaderTooltip',{staticClass:"agent-tooltip",attrs:{"buildHeader":_vm.item.buildHeader},on:{"save":function (data) { return _vm.saveBuildHeader(data, _vm.item); },"close":function($event){_vm.buildHeaderTooltipIndex = -1}}}):_vm._e()],1)],1),_c('div',{staticClass:"col-3 left"},[_c('span',{staticClass:"new-quote-name-input editable-new-quote"},[_c('input',{staticClass:"nameInput bg-white",class:{ 'bg-transparent': _vm.$parent.isCancelReturn(_vm.item) },attrs:{"type":"text","value":"BASE UNIT","autocomplete":"off","readonly":""}})])]),_c('div',{staticClass:"col-1-5 left",class:{
        'hasPrice-ico':
          _vm.$parent.notHPSource() &&
          _vm.$parent.selectingDisty(_vm.item.Disty) &&
          typeof _vm.item.DistyPrice != 'undefined'
      }},[_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput",class:{ priceHover: _vm.item.priceHover || 0 }},[_c('span',{staticClass:"loader hidden"}),_c('span',{staticClass:"left currency-mark"},[_vm._v("$")]),_c('vue-numeric',{directives:[{name:"validate",rawName:"v-validate",value:({
            required:
              _vm.item.sku != '' ||
              _vm.item.name != '' ||
              (_vm.item.isFee == true && _vm.item.price == 0)
          }),expression:"{\n            required:\n              item.sku != '' ||\n              item.name != '' ||\n              (item.isFee == true && item.price == 0)\n          }"},{name:"currencyAll",rawName:"v-currencyAll"}],staticClass:"number initial",class:{
            error:
              _vm.errors.has(("cost_" + _vm.index)) ||
              (_vm.item.isFee == true && _vm.item.price == 0) ||
              (_vm.item.isContractFee && !_vm.item.price),
            distyError:
              _vm.item.distyError === true ||
              (_vm.$parent.autoCost == 1 &&
                _vm.$parent.notHPSource() &&
                _vm.$parent.selectingDisty(_vm.item.Disty) &&
                typeof _vm.item.DistyPrice != 'undefined' &&
                _vm.item.DistyPrice > 0 &&
                _vm.item.price < _vm.item.DistyPrice)
          },attrs:{"currency":"","separator":",","precision":_vm.expandDecimalPrecision,"placeholder":_vm.Cost,"type":"text","name":("cost_" + _vm.index),"minus":true,"id":("cost_" + _vm.index),"max":999999999.99,"maxlength":12,"autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
              this.$route.name != 'DuplicateOrder',"disabled":_vm.item.disable},nativeOn:{"input":function($event){_vm.clearSelectedDisty(_vm.item);
            _vm.$emit('calcWithChangeCostPrice');}},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.price"}})],1),(
          _vm.$parent.notHPSource() &&
            _vm.$parent.selectingDisty(_vm.item.Disty) &&
            typeof _vm.item.DistyPrice != 'undefined' &&
            !(_vm.item.isFee || _vm.item.isContractFee || false)
        )?[(_vm.item.DistyPrice > 0)?[_c('span',{staticClass:"disty-pa-indicator",attrs:{"title":_vm.item.Disty + ' Price: ' + _vm.item.DistyPrice_Format}},[_c('img',{attrs:{"src":require("../assets/images/statistics.png")}}),(_vm.item.price >= _vm.item.DistyPrice)?_c('span',{staticClass:"disty-ico-ok"},[_vm._v("✔")]):(_vm.item.price < _vm.item.DistyPrice)?_c('span',{staticClass:"disty-ico-notok"},[_c('img',{attrs:{"src":require("../../public/reseller/images/alert.png")}})]):_vm._e()])]:(_vm.item.DistyPrice == 0)?[_c('span',{staticClass:"price-not-available disty-pa-indicator"},[_c('img',{attrs:{"src":require("../assets/images/statistics.png"),"title":_vm.item.Disty + ' Price: ' + 'Not Available'}})])]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"col-1-5 left d-grid",class:{
        isPriceExceeded: _vm.item.contractPrice != undefined
        && _vm.item.contractPrice.length
        && _vm.item.sku != ''
        && (_vm.isPriceExceeded(_vm.item) || _vm.isPriceListExceeded(_vm.item))
      },on:{"mouseleave":function($event){return _vm.hideContractTooltip()}}},[_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput",class:{ customerPriceHover: _vm.item.customerPriceHover || 0 }},[_c('span',{staticClass:"left currency-mark"},[_vm._v("$")]),_c('vue-numeric',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.item.sku != '' || _vm.item.name != '' }),expression:"{ required: item.sku != '' || item.name != '' }"},{name:"currencyAll",rawName:"v-currencyAll"}],staticClass:"number initial",class:{ error: _vm.errors.has(("cust_cost_" + _vm.index)) },attrs:{"currency":"","separator":",","precision":_vm.expandDecimalPrecision,"placeholder":_vm.Price,"minus":true,"type":"text","name":("cust_cost_" + _vm.index),"id":("cust_cost_" + _vm.index),"max":999999999.99,"maxlength":12,"autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
              this.$route.name != 'DuplicateOrder',"disabled":_vm.$parent.isFee(_vm.item) || _vm.item.disable},nativeOn:{"input":function($event){return _vm.$emit('marginCalc1')}},model:{value:(_vm.item.customerPrice),callback:function ($$v) {_vm.$set(_vm.item, "customerPrice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.customerPrice"}})],1),(
          _vm.item.contractPrice != undefined &&
            _vm.item.contractPrice.length &&
            _vm.item.sku != ''
        )?[_c('span',{staticClass:"contractPrice",style:(_vm.contractPriceTooltipIndex === _vm.index ? 'z-index: 99999' : ''),on:{"mouseenter":function($event){return _vm.showContractTooltip(_vm.index)}}},[_vm._v("Price Found")]),(_vm.contractPriceTooltipIndex === _vm.index)?_c('ContractPriceTooltip',{attrs:{"data":_vm.item.contractPrice},on:{"updateContractPrice":function (data) { return _vm.updateContractPrice(data, _vm.item); },"close":function($event){return _vm.hideContractTooltip()}}}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"col-1 noOverflow left margin-col"},[(_vm.$parent.marginView == 'margin_per')?[(_vm.$parent.marginInput == 'amount')?_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput"},[_c('span',{staticClass:"left"},[_vm._v("$")]),_c('vue-numeric',{directives:[{name:"currencyAll",rawName:"v-currencyAll"}],staticClass:"number price_margin",attrs:{"currency":"","separator":",","precision":2,"placeholder":_vm.margin,"type":"text","minus":true,"id":("margin_" + _vm.index),"disabled":_vm.$parent.isFee(_vm.item) || _vm.item.disable,"maxlength":"6","autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
                this.$route.name != 'DuplicateOrder'},nativeOn:{"input":function($event){return _vm.$emit('marginCalc2', _vm.index)}},model:{value:(_vm.item.margin),callback:function ($$v) {_vm.$set(_vm.item, "margin", _vm._n($$v))},expression:"item.margin"}})],1):_c('span',{staticClass:"new-quote-cost-input editable-new-quote"},[_c('vue-numeric',{directives:[{name:"marginOnly",rawName:"v-marginOnly"}],staticClass:"number",attrs:{"id":("margin_" + _vm.index),"precision":4,"maxlength":"7","type":"text","minus":true,"disabled":_vm.$parent.isFee(_vm.item) || _vm.item.disable,"autocomplete":"off","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
                this.$route.name != 'DuplicateOrder'},on:{"blur":function($event){return _vm.$emit('marginRateBlur', _vm.index)}},nativeOn:{"input":function($event){return _vm.$emit('marginRate', _vm.index)}},model:{value:(_vm.item.marginRate),callback:function ($$v) {_vm.$set(_vm.item, "marginRate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.marginRate"}})],1)]:(_vm.$parent.marginView == 'total_margin')?[(_vm.$parent.marginInput == 'amount')?_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput"},[_vm._v(" $"+_vm._s(_vm._f("formatCurrency")(((_vm.item.customerPrice - _vm.item.price) * _vm.item.quantity)))+" ")]):_c('span',{staticClass:"new-quote-cost-input editable-new-quote currencyInput"},[_vm._v(" "+_vm._s(_vm.item.marginRate)+"% ")])]:_vm._e()],2),_c('div',{staticClass:"col-1 noOverflow left",class:{
        'hasQty-ico':
          _vm.$parent.notHPSource() &&
          _vm.$parent.selectingDisty(_vm.item.Disty) &&
          typeof _vm.item.DistyAvailability != 'undefined'
      }},[_c('span',{staticClass:"new-quote-quantity-input editable-new-quote"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.item.quantity),expression:"item.quantity",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:({
            required:
              _vm.item.sku != '' ||
              _vm.item.name != '' ||
              _vm.item.price == 0 ||
              _vm.item.customerPrice == 0,
            min_value: (_vm.item.sku != '' || _vm.item.name != '') == true ? 1 : 0
          }),expression:"{\n            required:\n              item.sku != '' ||\n              item.name != '' ||\n              item.price == 0 ||\n              item.customerPrice == 0,\n            min_value: (item.sku != '' || item.name != '') == true ? 1 : 0\n          }"},{name:"digitOnly",rawName:"v-digitOnly"}],staticClass:"number initial",class:{ error: _vm.errors.has(("quantity_" + _vm.index)) },attrs:{"type":"text","placeholder":"Qty","name":("quantity_" + _vm.index),"id":("quantity_" + _vm.index),"disabled":_vm.$parent.isFee(_vm.item),"autocomplete":"off","maxlength":"8","readonly":(_vm.item.statusid == 1 || _vm.item.statusid == 2) &&
              this.$route.name != 'DuplicateOrder'},domProps:{"value":(_vm.item.quantity)},on:{"keyup":function($event){_vm.$parent.baseQuantityChange(_vm.item, _vm.index);
            _vm.$emit('totalCal', _vm.index);},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "quantity", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),(
          _vm.$parent.notHPSource() &&
            _vm.$parent.selectingDisty(_vm.item.Disty) &&
            typeof _vm.item.DistyAvailability != 'undefined' &&
            !(_vm.item.isFee || _vm.item.isContractFee || false)
        )?[(_vm.item.DistyAvailability > 0)?[_c('span',{attrs:{"title":'Availability: ' + _vm.item.DistyAvailability_Format + ' units'}},[_c('img',{attrs:{"src":require("../assets/images/pack.png")}}),(_vm.item.quantity <= _vm.item.DistyAvailability)?_c('span',{staticClass:"disty-ico-ok"},[_vm._v("✔")]):(_vm.item.quantity > _vm.item.DistyAvailability)?_c('span',{staticClass:"disty-ico-notok"},[_c('img',{attrs:{"src":require("../../public/reseller/images/alert.png")}})]):_vm._e()])]:(_vm.item.DistyAvailability == 0)?[_c('span',{staticClass:"qty-not-available"},[_c('img',{attrs:{"src":require("../assets/images/pack.png"),"title":'Availability: Not Available'}})])]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"col-1 totalPriceWarn left"},[(_vm.item.isFee)?_c('span',{staticClass:"total noOverflow"},[_vm._v(" $"),_c('span',[_vm._v("0.00")])]):_c('span',{class:{ total: true, update: _vm.item.priceWarn, noOverflow: true }},[(_vm.item.total < 0)?_c('span',[_vm._v("-$"+_vm._s(_vm._f("formatCurrency")(_vm.item.total,true)))]):_c('span',[_vm._v("$"+_vm._s(_vm._f("formatCurrency")(_vm.item.total)))])]),_c('PriceWarnTooltip',{staticClass:"PriceWarnTooltip"})],1),_vm._m(0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-1 noOverflow left col-tax"},[_c('div',{staticClass:"d-flex w-100 justify-content-center"},[_c('div',{staticClass:"smartCheckBox checkbox_unchecked disabled"})])])}]

export { render, staticRenderFns }