







































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";

interface Props {
  options: [];
  selectedIDs: [];
  placeholderText: string;
  create_newTag: boolean;
}

interface Events {}

declare const $: any;
declare const htmlCheck: Function;
declare const htmlParse: Function;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class MultiSelect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: any[];

  @Prop({ required: true })
  selectedIDs!: any[];

  @Prop({ required: false, default: "" })
  label!: string;

  @Prop({ required: false, default: "Search" })
  placeholderText!: string;

  @Prop({ required: false, default: false })
  create_newTag!: boolean;

  @Prop({ required: false, default: false })
  isActive!: boolean;
  
  dropdownVisible = false
  search = ''
  showOnlySelected: boolean = false
  creating: any = false;

  // autoExpand() {
  //   var value = $("#autoExpand").val();
  //   $("#autoExpand").css("width", ((value.length+1) * 9.2) + 'px');
  // }

  get filteredOptions() {
    let options = this.options
    console.log('options',options)
    if (this.search) {
      options = options.filter((option) => `${option.ACCOUNTID}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }

    if (this.showOnlySelected) {
      options = options.filter((option) => this.selectedIDs.indexOf(option.ACCOUNTID) !== -1)
    }

    return options
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible
    if(this.dropdownVisible == true) {
      setTimeout(() => {
        $(".account-tags #autoExpand").focus();
      }, 300);
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(id: any): boolean {
    const checked = !!this.selectedIDs.find((val) => val === id);
    return checked;
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  createNewTag() {
    if(!this.create_newTag) {
      return;
    }

    //remove HTML
    this.search = this.removeHTML(this.search);

    if(this.search != "" && this.search.length >= 2) {
      //make sure tag name is unique
      var check = this.options.filter((val: any) => val.SUBACCOUNT.toLowerCase() == this.search.toLowerCase());
      if(check.length === 0) {
        this.$emit("createNewTag", this.search);
      }
    }
  }

  checkSubAcc(){
    this.search = this.search.replace(
      /  +/g,
      ""
    );
    this.search = this.search.replace(
      / ,/g,
      ""
    );
    this.search = this.search.replace(
      /, /g,
      ""
    );
    this.search = this.search.replace(/\s/g, '')
    if (htmlCheck(this.search)) {
      this.search = htmlParse(this.search);
      notifier.alert("HTML content found. This content has been removed!");
    }
  }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }
}
