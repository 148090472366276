var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " - "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.QUOTEREVISIONNUMBER : stack1), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"quoteDetails_instructions\">\n  <tr style=\"text-align: center;\"><td class=\"title\">Arizona Procurement Portal (APP) Instructions</td></tr>\n  <tr>\n    <td>\n      For APP Online users, please follow the steps below to ensure the order is processed correctly and that there are no duplicates:\n      <ol>\n      <li>Choose catalog items from Supplier: HP, Inc (IV0000017231) for the contract.</li>\n      <li>Choose Order Supplier/Distributor as: HP, Inc (IV0000017231).</li>\n      <li>Choose Supplier Contact as: <strong>*PURCHASE ORDERS</strong>.</li>\n      <li>In Item Number (1) Comments please include <strong>Quote ID: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.QUOTEID : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.QUOTEREVISIONNUMBER : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":96},"end":{"line":10,"column":190}}})) != null ? stack1 : "")
    + "</strong>.\"</li>\n      <li>To ensure prompt ordering, in APP hit \"SEND\" to send PO directly to us.</li>\n    </ol>\n    The above steps will email a copy of the PO to Milestone directly for processing and will prevent duplicate orders. This also\n    enables us to track the order and ensure all post sales processes are completed correctly (eg. Carepacks, Invoicing, etc.).\n    Thanks!\n    </td>\n  </tr>\n</table>";
},"useData":true});