

















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Model } from "vue-property-decorator";
import AutoSuggestInput2, { Option } from "@/components/AutoSuggestInput2.vue";
import { ApiHelper } from "@/helpers/all";


interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    line: any
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput2
  },
  inject: ['$validator'],
})
export default class QuoteProductAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  placeholder?: string

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, type: String, default: "" })
  productType?: string

  @Prop({ required: false, type: String, default: "" })
  deploymentId?: string

  @Model('input', {
    type: String,
    required: true
  })
  readonly value!: string

  options: Option[] = []
  searchData: any[] = [];

  async selectItem(selectedOption) {
    if (selectedOption) {
      this.$emit('selection', selectedOption.data)
    }
  }

  searchItems = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    const response = await ApiHelper.callApi(
      "post",
      {
        controller: "Quotes",
        FunctionName: "quoteSuggestLineItemList",
        search: search,
        source: 1,
        contractNumberValue: ''
      }
    );
    const options = response.LINEITEMS || [];
    if (options.length == 0 && search) {
      options.push({
        PRODUCTSKU: search,
        PRODUCTNAME: '',
      });
    }
    return this.createOptions(options)
  }

  createOptions(searchData: any[]) {
    const options = searchData.map(
      (item): Option => {
        return {
          value: `${item.PRODUCTSKU}`,
          label: `#${item.PRODUCTSKU} - ${item.PRODUCTNAME || '[New Product]'}`,
          text: `${item.PRODUCTSKU}`,
          data: item,
        };
      }
    )
    this.searchData = searchData
    this.options = options
    return { options }
  }

  onInput(value: string) {
    this.defaultText = value;
    this.$emit('input', value)
    if (!value) return;
    const line = this.searchData.find((item) => `${item.PRODUCTSKU}`.toLowerCase() == value.toLowerCase())
    if (!line) {
      console.warn(`no line found for value[${value}]`)
      return
    }
    this.$emit('selection', line)
  }
}
