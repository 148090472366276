






















































































































































































































































































































































































































































































































































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from "axios";
import {notifier} from '../models/common'
import LaddaButton from "../components/LaddaButton.vue"
import Loader from './Loader.vue';
import Pagination from "../components/Pagination.vue";
import ConfirmLinkOrderModal from "../components/ConfirmRemoveItemModal.vue";
import ListHeaderActions from "./ListHeaderActions.vue";
import { getRangeFilter, getDateRangeFilter } from "@/helpers/ApiHelper";
import FilterCurrencyBox from "@/components/FilterCurrencyBox.vue";

interface Props {
  title: string
  currentUnverifiedOrder: any
}

interface Events {
  onClose: void
}

@Component({
  inheritAttrs: false,
  components: {
    FilterCurrencyBox,
    LaddaButton,
    Loader,
    Pagination,
    ConfirmLinkOrderModal,
    ListHeaderActions
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class OrderSearchModal extends TSXComponent<
  Props,
  Events
> {

  @Prop({required: true})
  title!: string

  @Prop({required: true, default: {}})
  currentUnverifiedOrder!: any

  @Prop({required: true, default: []})
  accountTags!: any

  @Prop({required: true, default: []})
  varAccsList!: any

  @Prop({required: true, default: []})
  varAccs!: any

  $parent: any;
  saving: any = false;
  loading = false;
  filters: any = {
    customerPO: "",
    orderName: "",
    customerNumber: "",
    customerName: "",
    total: "",
    totalMin: "",
    totalMax: "",
    date: "",
    order: "",
    sDate: "",
    eDate: "",
    AccountTagID: [],
    selectedAccs: [],
    gTagIDs: [],
    groupIDs: [],
    accountTagName: [],
    selectedAccsName: [],
    groupName: []
  };
  suggestedOrders = [];
  pageNumber = 1;
  details: any = {};
  confirmLinkOrder = false;
  confirmLinkOrderItem = {};
  isActive = "customerAccounts";
  hasChangedAccountFilters = false;
  searchTagsTxt = "";
  searchAccTxt = "";
  allAccountTags:any = [];
  searchFilters: any = {
    order: "",
    customerPO: "",
    orderName: "",
    customerName: "",
    total: "",
    totalMin: "",
    totalMax: "",
    sDate: "",
    eDate: ""
  };

  $refs!: {
    modal: HTMLDivElement
  }

  async created() {
    this.filters.total = this.currentUnverifiedOrder.ORDERLINETOTAL || '';
    this.filters.date = this.currentUnverifiedOrder.PURCHASEORDERDATE;
    this.allAccountTags = [...this.accountTags];
    this.fetchData(true);
  }

  async fetchData(isDefault: boolean = false) {
    this.loading = true;
    this.hasChangedAccountFilters = false;
    try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "Orders",
        FunctionName			: "SuggestedListForUnverified",
        maxRows           : (sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows()),
        PageNumber        : this.pageNumber,
        purchaseID        : this.filters.order,
        total             : this.filters.total,
        purchaseOrderDate : this.filters.date,
        customerPO        : this.filters.customerPO,
        orderName         : this.filters.orderName,
        customerName      : this.filters.customerName,
        sDate             : this.filters.sDate,
        eDate             : this.filters.eDate,
        totalMin          : this.filters.totalMin,
        totalMax          : this.filters.totalMax,
        tagIDs            : this.filters.AccountTagID.join(","),
        selectedAccountDs : this.filters.selectedAccs.join(","),
        gTagIDs           : this.filters.gTagIDs.join(","),
        groupIDs          : this.filters.groupIDs.join(",")
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
      
      if(response.data.STATUS == 1) {
        this.suggestedOrders = response.data.suggestedOrders;
        this.details = response.data; 
      }
		}catch (err) {
      // console.log(err.message);
		}finally {
      this.loading = false;
      if (isDefault) {
        this.filters.total = this.searchFilters.total = "";
        this.filters.date = "";
      }
		}
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async ok() {
    this.$emit('search', this.filters);
  }

  linkOrder(item) {
    this.confirmLinkOrderItem = item;
    this.confirmLinkOrder = true;
  }

  async insertLinking(item) {
    //this.loading = true;
    try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "Orders",
        FunctionName			: "InsertLinkingForUnverified",
        unverifyPO        : this.currentUnverifiedOrder.CUSTOMERPO,
        varPurchaseID     : item.PURCHASEID
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
      
      if(response.data.STATUS == 1) {
        this.$parent.fetchData();
        this.$emit('close');
      }
		}catch (err) {
      // console.log(err.message);
		}finally {
      //this.loading = false;
		}
  }

  searchBy(field) {
    if(this.filters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.searchFilters[field] = this.filters[field];
    this.fetchData();
  }

  resetBy(field) {
    this.searchFilters[field] = "";
    if(this.filters[field] === "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = "";
    if (!this.filters.total.length) {
      this.filters.total = this.currentUnverifiedOrder.ORDERLINETOTAL;
    }
    this.filters.date = this.currentUnverifiedOrder.PURCHASEORDERDATE;
    this.fetchData();
  }

  async searchByTotal() {
    this.pageNumber = 1;
    this.filters.totalMin = this.searchFilters.totalMin;
    this.filters.totalMax = this.searchFilters.totalMax;
    await this.fetchData();
  }

  async resetSearchByTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.totalMin = this.searchFilters.totalMin = undefined;
    this.filters.totalMax = this.searchFilters.totalMax = undefined;
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  updateValue(field) {
    if(this.filters[field] == "") {
      this.searchFilters[field] = "";
      if (!this.filters.total.length) {
        this.filters.total = this.currentUnverifiedOrder.ORDERLINETOTAL;
      }
      this.filters.date = this.currentUnverifiedOrder.PURCHASEORDERDATE;
      this.pageNumber = 1;
      this.fetchData(true);
    }
  }

  async searchBySuggestedOrderDate() {
    if (
      (!this.filters.sDate.length) &&
      (!this.filters.eDate.length )
    ) {
      return;
    }
    this.pageNumber = 1;
    this.searchFilters.sDate = this.filters.sDate;
    this.searchFilters.eDate = this.filters.eDate;
    await this.fetchData();
  }

  async resetBySuggestedOrderDate() {
    this.filters.total = this.currentUnverifiedOrder.ORDERLINETOTAL;
    this.filters.date = this.currentUnverifiedOrder.PURCHASEORDERDATE;
    if (
      (!this.filters.sDate.length) &&
      (!this.filters.eDate.length )
    ) {
      return;
    }
    this.pageNumber = 1;
    this.filters.sDate = this.searchFilters.sDate = "";
    this.filters.eDate = this.searchFilters.eDate = "";
    await this.fetchData();
  }

  //filter by Tags
  async checkAllTag() {
    var $this = this;
    this.filters.AccountTagID = [];
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == "undefined") {
        if (typeof val.ISGLOBALTAG == "undefined") {
          if (
            $this.filters.AccountTagID != undefined
          ) {
            $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
          }
        } else {
          $this.filters.gTagIDs.push(val.ACCOUNTTAGID);
        }
      }
    });
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    var $this = this;
    this.filters.groupIDs = [];
    this.accountTags.forEach(function(val: any) {
      if (
        $this.filters.groupIDs &&
        !isNaN(val.ACCOUNTTAGID) &&
        typeof val.ISGROUP != "undefined"
      ) {
        if (
          $this.filters.groupIDs != undefined
        ) {
          $this.filters.groupIDs.push(val.ACCOUNTTAGID);
        }
      }
    });
    // this.filters.groupIDs = this.accountTags.map(val => val.ACCOUNTTAGID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag(allowFetchData = true) {
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    this.filters.accountTagName = [];
    this.filters.groupName = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  toggleActive(div) {
    this.isActive = div;
  }
  filterTagsInBox() {
    let options = this.allAccountTags;

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }
  async checkAllAcc() {
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.filters.selectedAccs = [];
    this.filters.selectedAccsName = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  getFilterValue() {
    const arr: string[] = [];
    if(this.searchFilters.customerName) {
      arr.push(`Customer: ${this.searchFilters.customerName}`);
    }
    if(this.filters.gTagIDs.length || this.filters.AccountTagID.length) {
      arr.push(`Customer Tags: ${this.filters.accountTagName}`);
    }
    if(this.filters.selectedAccs.length) {
      arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
    }
    if(this.filters.groupIDs.length) {
      arr.push(`Customer Groups: ${this.filters.groupName}`);
    }
    return arr.join('; ');
  }

  async resetColumn() {
    this.filters.customerName = this.searchFilters.customerName = "";
    this.resetTag(false);
    this.resetAcc(false);
    await this.fetchData();
  }

  //filter by Tags
  async filterByTag(tagName = "", groupName = "") {
    this.hasChangedAccountFilters = true;

    if (tagName != "" && this.filters.accountTagName != undefined) {
      const tagIndex = this.filters.accountTagName.findIndex(
        tagValue => tagValue === tagName
      );
      if (tagIndex === -1) {
        this.filters.accountTagName.push(tagName);
      } else {
        this.filters.accountTagName.splice(tagIndex, 1);
      }
    }

    if (groupName != "" && this.filters.groupName != undefined) {
      const groupIndex = this.filters.groupName.findIndex(
        groupValue => groupValue === groupName
      );
      if (groupIndex === -1) {
        this.filters.groupName.push(groupName);
      } else {
        this.filters.groupName.splice(groupIndex, 1);
      }
    }
  }

  //filter by Accounts
  async filterByAcc(accountsName: string) {
    this.hasChangedAccountFilters = true;
    if (
      accountsName != undefined &&
      this.filters.selectedAccsName != undefined
    ) {
      const accountsIndex = this.filters.selectedAccsName.findIndex(
        accountsValue => accountsValue === accountsName
      );
      if (accountsIndex === -1) {
        this.filters.selectedAccsName.push(accountsName);
      } else {
        this.filters.selectedAccsName.splice(accountsIndex, 1);
      }
    }
  }
}
