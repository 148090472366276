




















































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    LaddaButton
  }
})
export default class TemplateTableAddModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: false })
  editTable?: boolean;

  @Prop({ required: false, default: { headers: [{ value: "" }], rows: [] } })
  tableDetails?: any;

  @Prop({ required: false, default: {} })
  currentElement?: any;

  elementId = "";

  mounted() {
    // this.tableDetails = [[{ header: "" }]];
    this.elementId = this.currentElement.id || 0;
    if (!this.elementId) {
      // default
      this.tableDetails = { headers: [{ value: "" }], rows: [] };
    } else if (this.currentElement.value) {
      this.tableDetails = JSON.parse(JSON.stringify(this.currentElement.value));
    }
  }

  closeModal() {
    this.$emit("close");
  }

  addNewHeader() {
    // this.tableDetails.forEach((item, index) => {
    //   if (index != 0) item.push({ content: "" });
    //   else item.push({ header: "" });
    // });

    this.tableDetails.headers.push({ value: "" });
    for (const item of this.tableDetails.rows || []) {
      item.push({ value: "" });
    }
  }

  addNewRow() {
    // let tempArr = [];
    // this.tableDetails[0].forEach(item => {
    //   tempArr.push({ content: "" });
    // });
    // this.tableDetails.push(tempArr);

    this.tableDetails.rows.push(
      this.tableDetails.headers.map(item => ({
        value: ""
      }))
    );
  }

  addTable() {
    this.$emit("addTable", this.tableDetails, this.elementId);
  }

  removeRow(trIndex) {
    if ((this.tableDetails.rows || []).length) {
      this.tableDetails.rows.splice(trIndex, 1);
    }
  }
}
