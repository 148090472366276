











































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { validateFn } from "../helpers";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import axios from "axios";

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class IncognitoMode extends TSXComponent<void> {
  loading = false;
  incognitoMode = "";

  async created() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Setting",
        FunctionName: "IncognitoMode"
      });
      if (response.data.STATUS) {
        this.incognitoMode = response.data.INCOGNITOMODE
      }
    }catch (err) {
      // console.log(err);
    }finally {
      this.loading = false;
    }
  }

  async validateBeforeSubmit(e) {
    if (this.incognitoMode != '') {
      await this.save();
    }
  }

  async save() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Setting",
        FunctionName: "IncognitoMode",
        IncognitoMode: this.incognitoMode
      });
      if (response.data.STATUS) {
        sessionStorage.setItem('IncognitoMode', this.incognitoMode);
        notifier.success(response.data.STATUSMESSAGE);
        this.loading = false;
      }
    }catch (err) {
      // console.log(err);
    }finally {
      this.loading = false;
    }
  }
}
