import axios from "axios";

declare const dataURL: string;
export class CustomerAPIRepo {
	async exportFileUrl(type: string, ids: (number | undefined)[], viewType = "", selectedAll = false, excludedIDs: any = [], filters: any = {}, sort: any = {}): Promise<string> {
		var requestData = {
			controller: "Customers",
			ExportType: type,
			FunctionName: "Export",
			selectedIds: ids,
			selectedAll: selectedAll,
      excludedIDs: excludedIDs
		};
		
		if(filters.sAccountID != "") {
			requestData["sAccountID"] = filters.sAccountID;
		}
		if(filters.sAccountName != "") {
			requestData["sAccountName"] = filters.sAccountName;
		}
		if(sort.field != null) {
			requestData["order"] = sort.field;
		}
		if(sort.direction != null) {
			requestData["direction"] = sort.direction;
		}
		if(sort.type != null) {
			requestData["type"] = sort.type;
		}

		if(viewType != "") {
			requestData["view"] = viewType;
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async customersList(customerIDs: (number | undefined)[],isExport = 1, viewType = "", selectedAll = false, excludedIDs: any = [], filters: any = {}, sort: any = {}): Promise<string> {
		var requestData = {
			controller: "Customers",
			sub: 2,
			Export: isExport,
			FunctionName: "List",
			selectedIds: customerIDs,
			selectedAll: selectedAll,
      excludedIDs: excludedIDs
		};

		if(filters.sAccountID != "") {
			requestData["sAccountID"] = filters.sAccountID;
		}
		if(filters.sAccountName != "") {
			requestData["sAccountName"] = filters.sAccountName;
		}
		if(sort.field != null) {
			requestData["order"] = sort.field;
		}
		if(sort.direction != null) {
			requestData["direction"] = sort.direction;
		}
		if(sort.type != null) {
			requestData["type"] = sort.type;
		}

		if(viewType != "") {
			requestData["view"] = viewType;
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}
}