var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "["
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.NASPOCONTRACT : stack1), depth0))
    + "] ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"quoteDetails_terms\">\n  <tr><td class=\"title\">Terms and Conditions</td></tr>\n  <tr>\n    <td>\n      <ul>\n        <li>The terms and conditions of the AZ - STATE OF ARIZONA (NASPO VP PC) "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.NASPOCONTRACT : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":80},"end":{"line":6,"column":154}}})) != null ? stack1 : "")
    + " contract will apply to any\n      order placed as a result of this inquiry, no other terms or conditions shall apply.</li>\n      <li>Product availability and product discontinuation are subject to change without notice. The prices in this quotation are valid\n      for 30 days from quote date above. Please include the quote number and contract from this quote on the corresponding\n      purchase order.</li>\n      <li>HP and/or Milestone Computer Technology, Inc. are not liable for pricing errors. If you place an order for a product that\n      was incorrectly priced, we will cancel your order and credit you for any charges. In the event that we inadvertently ship an\n      order based on a pricing error, we will issue a revised invoice to you for the correct price and contact you to obtain your\n      authorization for the additional charge, or assist you with return of the product. If the pricing error results in an overcharge\n      to you, HP will credit your account for the amount overcharged.</li>\n      <li>Freight is FOB Destination.</li>\n      <li>Taxes shown are estimated only. Final invoice will not tax Service items and will be based on final ship-to location.</li>\n    </ul>\n    </td>\n  </tr>\n</table>\n";
},"useData":true});