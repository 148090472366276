























































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { validateFn } from "../helpers";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import CustomAlertModal from "@/components/Alert/CustomAlertModal.vue";
import axios from "axios";

declare const dataURL: string;
declare const $: any;
declare const window: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    CustomAlertModal
  }
})
export default class GlobalParams extends TSXComponent<void> {
  loading = false;
  saving: boolean | string = false;
  distributor = [];
  estPercent = 0;
  HPEestPercent = 0;
  hpTaxRate = 0;
  indirectTaxRate = 0;
  orderGenerateHardware = "no";
  enable2FAF = 0;
  notShippedYet: boolean = false;
  // fourDaysCreationAlert: boolean = false;
  autoAlerts: any[] = [];
  autoAlertModalVisible: boolean = false;
  openAutoAlertId: number = 0;

  async created() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARSources",
        FunctionName: "GlobalParams",
        subsystem: "VAR360",
        action: "list"
      });
      this.estPercent = response.data.ESTPercent || 0;
      this.HPEestPercent = response.data.HPEESTPercent || 0;
      this.distributor = response.data.distributor;
      this.hpTaxRate = response.data.hpTaxRate || 0;
      this.indirectTaxRate = response.data.indirectTaxRate || 0;
      this.orderGenerateHardware = response.data.orderGenerateHardware || "no";
      this.enable2FAF = response.data.enable2FAF || 0;
      this.autoAlerts = response.data.ALERT || [];
      this.notShippedYet = response.data.notShippedYet || false;
      // this.fourDaysCreationAlert = response.data.fourDaysCreationAlert || false;
      
    }catch (err) {
      console.log(err);
    }finally {
      this.loading = false;
    }
  }

  validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$validator.validateAll().then(async result => {
      if(result) {
        this.submit();
      }      
    });
  }

  async submit() {
    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARSources",
        FunctionName: "GlobalParams",
        subsystem: "VAR360",
        action: "update",
        distributor: this.distributor,
        estPercent: this.estPercent,
        HPEestPercent: this.HPEestPercent,
        hpTaxRate: this.hpTaxRate,
        indirectTaxRate: this.indirectTaxRate,
        orderGenerateHardware: this.orderGenerateHardware,
        enable2FAF: this.enable2FAF,
        notShippedYet: this.notShippedYet,
        // fourDaysCreationAlert: this.fourDaysCreationAlert
      });
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if(response.data.STATUS != 1) {
        this.saving = "error";
      }else {
        notifier.success("Updated Params Successfully");
      }
      this.saving = false;
    }catch (err) {
      this.saving = "error";
    }
  }
  structuredClone(obj) {
    return window.structuredClone(obj)
  }
  addAutoAlert(data) {
    if (data.PARAMS) {
      if (data.ALERTID && data.ISUPDATE) {
        let alertIdx = this.autoAlerts.findIndex(item => item.ALERTID && item.ALERTID == data.ALERTID);
        if (alertIdx >= 0 ) {
          this.autoAlerts[alertIdx] = data.PARAMS;
        } else {
          this.autoAlerts.push(data.PARAMS);
        }
      } else {
        this.autoAlerts.push(data.PARAMS);
      }
    }
  }
  openAutoAlert(alertId) {
    this.openAutoAlertId = alertId;
    this.autoAlertModalVisible = true;
  }
  async removeAutoAlert(alertId) {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Alerts",
        FunctionName: "UpdateAccountAlert",
        action: "deleteAccountAlert",
        alertId
      });
      if (response.data.STATUS == 1) {
        const deletedAlertIndex = this.autoAlerts.findIndex((alert) => alert.ALERTID === alertId);
        if (deletedAlertIndex >= 0) {
          this.autoAlerts.splice(deletedAlertIndex , 1);
        }
  
        this.$forceUpdate();
        notifier.success(response.data.STATUSMSG);
      }
    } catch (error) {
      // console.log(error);
    } 
  }
}
