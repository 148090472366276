























































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ReportAPIRepo, SortField, Filters,Sort } from "../repos/ReportAPIRepo";
import {
  notifier,
} from "../models/common";
const reportRepo = new ReportAPIRepo();


@Component({
  inheritAttrs: false,
  components: {
    
  }
})
export default class Reports extends TSXComponent<void> {
  loading = false;
  reportList = [];
  headers:string[] = [];
  isOrderStatus = false;
  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    var id = this.$route.params.id;
    try {
     const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "reports",
      FunctionName: "run",
      id: id,
      isGenerate: false
    });
      this.isOrderStatus = typeof response.data.ISORDERSTATUS != 'undefined' ? true : false;
      this.headers = !this.isOrderStatus && response.data.RESULT.length ? Object.keys(response.data.RESULT[0]) : [];
      this.reportList =response.data.RESULT;
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
    this.loading = false;
  }

}
