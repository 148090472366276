















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  value: string;
}

interface Events {
  onReset: void
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class ListHeaderActions extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  value!: string;

  async created() {
  }

  doReset() {
    this.$emit('onReset');
  }
}
