import { render, staticRenderFns } from "./GlobalTagSelect.vue?vue&type=template&id=3f9bf0b0&scoped=true"
import script from "./GlobalTagSelect.vue?vue&type=script&lang=tsx"
export * from "./GlobalTagSelect.vue?vue&type=script&lang=tsx"
import style0 from "./GlobalTagSelect.vue?vue&type=style&index=0&id=3f9bf0b0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f9bf0b0",
  null
  
)

export default component.exports