









































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { PaycheckResp } from "../models/PaycheckResp";
import { ApiHelper } from "@/helpers/all";

interface Props {
  options: PaycheckResp.User[];
  selectedUserIds: number[];
  isSingleSelect: boolean;
  userFor: string;
  disabled: boolean;
  title: string;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class EmployeeMultiselect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: PaycheckResp.User[];

  @Prop({ required: true })
  selectedUserIds!: number[];

  @Prop({ required: false, default: false })
  isSingleSelect?: boolean;

  @Prop({ required: false, default: '' })
  userFor?: string;

  @Prop({ required: false, default: false })
  disabled?: boolean;

  @Prop({ required: false, default: false })
  isDisty?: boolean;

  @Prop({ required: false, default: "" })
  title?: string;

  dropdownVisible = false
  search = ''
  selecteduser: any = 'Select user';
  showOnlySelected: boolean = false
  errorMessage = '';

  created(){
    if(this.isDisty){
      this.selecteduser = this.options[0];
    }
  }

  addNew() {
    if(this.isEmail(this.search)) {
      this.$emit('addNew', this.search);
      this.selecteduser = this.search;
      this.search = '';
    } else {
      this.errorMessage = "Invalid Email"
    }
  }

  get isValidEmail() {
    let isValid = true;
    const arrEmails = this.search.split(',');
    arrEmails.map((email) => {
      if (!ApiHelper.validateEmail(email)) {
        isValid = false;
      }
    });
    return isValid;
  }

  get filteredOptions(): PaycheckResp.User[] {
    let options = this.options

    if (this.search) {
      if(this.isDisty){
        options = options.filter((option) => `${option}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      }else{
        options = options.filter((option) => `${option.UEMAIL}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      }
    }

    if (this.showOnlySelected) {
      options = options.filter((o) => this.selectedUserIds.indexOf(o.USERID) !== -1)
    }

    return options
  }

  get isExistEmail() {
    let options = this.options;
    if(this.search) {
      options = options.filter((option) => `${option.UEMAIL}`.toLowerCase() == this.search.toLowerCase())
    }
    return options.length;
  }

  isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  toggleDropdown() {
    if(!this.disabled) {
      this.dropdownVisible = !this.dropdownVisible
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(userId: number): boolean {
    const checked = !!this.selectedUserIds.find((id) => id === userId)
    return checked
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  SelectUser(e){
    this.selecteduser = e.UFNAME;
    this.$emit('check', e.USERID,`${e.UEMAIL}`)
  }
  Selectmail(e){
    this.selecteduser = e;
    this.$emit('check',e)
    this.dropdownVisible = false;
  }
  
}
