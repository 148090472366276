



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "./LaddaButton.vue";
import { notifier, wait } from "../models/common";
import AssetsAllocationTooltip from "../components/AssetsAllocationTooltip.vue";
import moment from "moment";
import DropdownControl from "../components/DropdownControl.vue";
import USState from "../static/USstates.json";
import Inputmask from "inputmask";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Datepicker from "../components/Datepicker.vue";
import Timepicker from "vue2-timepicker";
import { Validator } from "vee-validate";
import { ApiHelper } from "@/helpers/all";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import { loginCheck } from "@/helpers/ApiHelper";
import Loader from "@/components/Loader.vue";

interface Props {
  purchaseID: number;
  details?: any;
  aID?: number;
  type?: string;
  forceProcess?: boolean;
  showNotes?: boolean;
  showSubmitBtn?: boolean;
}

interface Events {}

declare const $: any;
declare const dataURL: string;
declare const getImageRouteData: any;

Validator.extend("requiredTime", {
  validate: value => {
    return value.HH.length && value.mm.length;
  }
});

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    AssetsAllocationTooltip,
    DropdownControl,
    ConfirmRemoveItemModal,
    Datepicker,
    Timepicker,
    FormMultiSelect,
    Loader
  },
  methods: {
    loginCheck
  }
})
export default class DeploymentEUModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: {} })
  selectedUser?: any;

  @Prop({ required: false, default: 0 })
  aID?: number;

  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: {} })
  details?: any;

  @Prop({ required: false, default: 0 })
  type?: string;

  @Prop({ required: false, default: 0 })
  disabled?: any;

  @Prop({ required: false, default: [] })
  scheduleGroups?: any;

  @Prop({ required: false, default: [] })
  allLinkedHardwares?: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  saving: boolean | "error" = false;
  loading = false;
  removing = false;
  euInfoBk: any = null;
  euInfo: any = {
    firstName: "",
    lastName: "",
    siteLocation: "",
    locationName: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    phone: "",
    deploymentDate: "",
    deploymentTime: {
      HH: "",
      mm: ""
    },
    dateTimeFormatted: ""
  };
  allocationTooltipVisibleIndex = "";
  allocationTooltipLoading = false;
  allocationTooltipList: any = [];
  logList = [];
  errors: any;
  stateList: any = [];
  selectedState: string[] = [];
  siteLocationList: any = [
    {
      ID: 0,
      TEXT: "No group site",
      data: {
        ADDRESS1: "",
        ADDRESS2: "",
        CITY: "",
        STATE: "",
        ZIP: ""
      },
      isProject: 0
    }
  ];
  selectedSiteLocation: string[] = [];
  confirmRemoveAction = false;
  deletingLog: any = false;
  selectedLog: any = null;
  deploymentTimeFormatted = "";
  requiredDate = false;
  requiredTime = false;
  currentRole = sessionStorage.getItem("userRole");
  errorMsg = "";
  changeInfoOptions = [
    {
      id: "Quotes",
      text: "Quotes"
    },
    {
      id: "Orders",
      text: "Orders"
    },
    {
      id: "Hardware",
      text: "Hardware"
    },
    {
      id: "Deployments",
      text: "Deployments"
    }
  ];
  // end user change info setting
  euSettingsBk: any = {};
  euSettings: any = {
    selectedChangeInfoIds: [],
    rememberSetting: false
  };
  euFields: any = [];
  euFieldsBk: any = [];
  savingEUCustomData: boolean | "error" = false;
  duplicateLoading: boolean = false;
  confirmDuplicateEU: boolean = false;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    $(this.$refs.modal).on("hidden.bs.modal", () => {
      $("body")
        .removeClass("modal-open")
        .css("padding-right", 0);
    });

    // mask for phone
    $("input[inputmask=phonenumber]").each(function(i, obj) {
      Inputmask("(999) 999-9999", {}).mask(obj);
    });
  }

  async created() {
    this.euSettingsBk = JSON.parse(JSON.stringify(this.euSettings));

    // states list
    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
    const locationId = this.selectedUser.SITELOCATION || "";
    // end user info
    this.euInfo = {
      firstName: this.selectedUser.FIRSTNAME,
      lastName: this.selectedUser.LASTNAME,
      siteLocation: locationId,
      locationName: "", // this.selectedUser.LOCATIONNAME || "",
      addr1: this.selectedUser.ADDR1,
      addr2: this.selectedUser.ADDR2,
      city: this.selectedUser.CITY,
      state: this.selectedUser.STATE,
      zip: this.selectedUser.ZIP,
      email: this.selectedUser.EMAIL,
      phone: this.selectedUser.PHONE,
      phoneExt: this.selectedUser.PHONEEXT,
      deploymentDate: this.selectedUser.DATE,
      deploymentTime: {
        HH: this.selectedUser.HOUR,
        mm: this.selectedUser.MINUTES
      }
    };
    this.euInfoBk = { ...this.euInfo };
    this.selectedState = [this.euInfo.state];
    this.selectedSiteLocation = [locationId];
    this.type = this.type;
    // this.loadSiteLocations().then(() => {});

    // data for site location
    this.siteLocationList = [
      ...this.siteLocationList,
      ...this.scheduleGroups.map(item => ({
        ID: item.GROUPID,
        TEXT: item.GROUPNAME,
        data: {
          ADDRESS1: item.ADDRESS1,
          ADDRESS2: item.ADDRESS2,
          CITY: item.CITY,
          STATE: item.STATE,
          ZIP: item.ZIP
        },
        isProject: item.ISPROJECT || 0
      }))
    ];

    // get actions/logs
    if (
      this.type == "showEU" ||
      this.type == "actions" ||
      this.type == "logs"
    ) {
      try {
        this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "DeploymentLogs",
          deploymentId: this.deploymentId,
          userId: this.selectedUser.USERID
        });
        if (response.data.STATUS == 1) {
          this.logList = response.data.ITEMS || [];
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }

    // get change info setting by account
    if (this.aID) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Accounts",
        FunctionName: "GetParams",
        aId: this.aID,
        key: "selectedChangeInfoIds"
      });
      if (response.data.STATUS == 1 && "keyValue" in response.data) {
        const selectedChangeInfoIds = response.data.keyValue || "";
        this.euSettings.selectedChangeInfoIds = selectedChangeInfoIds
          ? selectedChangeInfoIds.split(",")
          : [];
      }
    }
    this.euSettingsBk = JSON.parse(JSON.stringify(this.euSettings));

    // get deployment end user custom data
    if (this.type == "euCustomData") {
      try {
        this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "GetEUCustomData",
          deploymentId: this.deploymentId,
          aId: this.aID,
          endUserId: this.selectedUser.USERID
        });
        this.loading = false;
        if (response.data.STATUS == 1) {
          this.euFields = response.data.euFields || [];
          this.euFieldsBk = JSON.parse(JSON.stringify(this.euFields));
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  close() {
    this.$emit("close");
  }

  isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  async showAllocationTooltip(type = "") {
    if (this.allocationTooltipVisibleIndex === type) {
      return;
    }

    // get a list of products are assigned for this user
    this.allocationTooltipVisibleIndex = type;
    try {
      this.allocationTooltipLoading = true;
      if (this.selectedUser.USERID > 0) {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          Controller: "Deployments",
          FunctionName: "GetAssetsAllocated",
          deploymentId: this.deploymentId,
          userId: this.selectedUser.USERID,
          type: "related_products",
          productType: type == "products" ? 1 : 2
        });
        if (response.data.STATUS == 1) {
          this.allocationTooltipList = response.data.allocated || [];
          this.allocationTooltipList.forEach(function(val: any) {
            val.oldAllocated = val.ALLOCATED;
          });
        }
      }

      this.allocationTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.allocationTooltipVisibleIndex = "";
    } finally {
      this.allocationTooltipLoading = false;
    }
  }

  reloadAllocation() {
    this.$emit("reload");
  }

  // updateIdentifiers(userId, productId, list) {
  //   // console.log('updateIdentifiers', userId, productId, list);
  // }

  updateAllocated(type, info) {
    if (type == "products") {
      const related = this.selectedUser.ASSETS.find(
        item => item.PRODUCTID == info.productId
      );
      if (related) {
        related.ALLOCATED = info.allocatedThisItem || 0;
      } else {
        // add new
        this.selectedUser.ASSETS.push({
          USERID: this.selectedUser.USERID,
          ALLOCATED: info.allocatedThisItem || 0,
          SKU: info.productInfo.SKU,
          PRODUCTTYPE: 1,
          PRODUCTNAME: info.productInfo.PRODUCTNAME,
          PRODUCTID: info.productInfo.PRODUCTID,
          PURCHASEID: info.productInfo.PURCHASEID
        });
      }
    } else if (type == "services") {
      const related = this.selectedUser.SERVICES.find(
        item => item.PRODUCTID == info.productId
      );
      if (related) {
        related.ALLOCATED = info.allocatedThisItem || 0;
      } else {
        // add new
        this.selectedUser.SERVICES.push({
          USERID: this.selectedUser.USERID,
          ALLOCATED: info.allocatedThisItem || 0,
          SKU: info.productInfo.SKU,
          PRODUCTTYPE: 2,
          PRODUCTNAME: info.productInfo.PRODUCTNAME,
          PRODUCTID: info.productInfo.PRODUCTID
        });
      }
    }
  }

  // formatLogCreatedTs(createdTs) {
  //   return moment(createdTs)
  //     .utc()
  //     .format("MM/DD/YY hh:mm:ss");
  // }

  showSaveBtn() {
    if (
      JSON.stringify(this.euInfo) != JSON.stringify(this.euInfoBk) ||
      JSON.stringify(this.euSettingsBk) != JSON.stringify(this.euSettings)
    ) {
      return true;
    }
    return false;
  }

  async requiredField() {
    this.requiredDate =
      this.euInfo.deploymentTime.HH.length ||
      this.euInfo.deploymentTime.mm.length
        ? true
        : false;
    this.requiredTime = this.euInfo.deploymentDate ? true : false;
  }

  async saveEndUser(EUtype) {
    await this.requiredField();
    // validate
    const valid = await this.$validator.validateAll();
    if (this.errors.items.length) return;

    try {
      this.euInfo.deploymentTime.HH = this.euInfo.deploymentTime.HH.length
        ? this.euInfo.deploymentTime.HH
        : "";
      this.euInfo.deploymentTime.mm = this.euInfo.deploymentTime.mm.length
        ? this.euInfo.deploymentTime.mm
        : "";
      this.deploymentTimeFormatted =
        this.euInfo.deploymentTime.HH + ":" + this.euInfo.deploymentTime.mm;
      this.euInfo.dateTimeFormatted =
        this.euInfo.deploymentTime.HH.length &&
        this.euInfo.deploymentTime.mm.length &&
        this.euInfo.deploymentDate.length
          ? this.euInfo.deploymentDate.concat(" ", this.deploymentTimeFormatted)
          : "";
      this.saving = true;
      const productDetails = $.merge(
        this.selectedUser.ASSETS,
        this.selectedUser.SERVICES
      );
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "AddEndUser",
        firstName: this.euInfo.firstName,
        lastName: this.euInfo.lastName,
        locationId: this.euInfo.siteLocation || 0,
        locationName: this.euInfo.locationName,
        state: this.euInfo.state,
        address1: this.euInfo.addr1,
        address2: this.euInfo.addr2,
        city: this.euInfo.city,
        phone: this.euInfo.phone,
        phoneExt: this.euInfo.phoneExt,
        email: this.euInfo.email,
        zip: this.euInfo.zip,
        deploymentId: this.deploymentId,
        endUserId: this.selectedUser.USERID,
        productDetails,
        action: EUtype,
        deploymentDate: this.euInfo.dateTimeFormatted,
        selectedChangeInfoIds: this.euSettings.selectedChangeInfoIds.join(","),
        rememberSetting: this.euSettings.rememberSetting,
        aId: this.aID || 0,
        euId: this.selectedUser.EUID || 0
      });
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS == 1) {
        notifier.success(
          `${EUtype == "Add" ? "Added" : "Updated"} successfully`
        );

        this.euSettingsBk = JSON.parse(JSON.stringify(this.euSettings));
        const locationId = response.data.locationId || 0;
        this.euInfo = {
          ...this.euInfo,
          siteLocation: locationId
        };
        if (EUtype == "Add") {
          // update deployment percent
          const ret = await ApiHelper.updateDeploymentPercent(
            this.deploymentId
          );
          this.$emit("updateDeploymentPercent", ret);
          this.$emit("callDashboard");
        }

        this.saving = false;
        if (this.type == "showEU") {
          this.$emit("reloadEUInfo", this.selectedUser.USERID, this.euInfo);
          setTimeout(() => {
            this.euInfoBk = { ...this.euInfo };
          }, 500);
        } else {
          this.$emit("reload");
        }
        this.$emit("close");
      } else {
        this.saving = "error";
      }
    } catch (err) {
      console.log(err);
      this.saving = "error";
    }
  }

  async removeProductsAllocation(type = "", product) {
    this.removing = true;
    try {
      const productId = product.PRODUCTID;
      let status = 0;
      if (this.selectedUser.USERID > 0) {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "UpdateAllocation",
          action: "remove",
          linkId: product.LINKID,
          deploymentId: this.deploymentId,
          userId: this.selectedUser.USERID,
          productId
        });
        if (response.data.STATUS == 1) {
          // update on main page
          ApiHelper.updateDeploymentPercent(this.deploymentId).then(ret => {
            this.$emit("updateDeploymentPercent", ret);
          });
          this.$emit("reload");
          status = 1;
        }
      } else {
        status = 1;
      }

      if (status == 1) {
        // remove related items on end user modal
        if (type == "products") {
          this.selectedUser.ASSETS = this.selectedUser.ASSETS.filter(
            item => item.PRODUCTID != productId
          );
        } else if (type == "services") {
          this.selectedUser.SERVICES = this.selectedUser.SERVICES.filter(
            item => item.PRODUCTID != productId
          );
        }
        // update allocationTooltipList
        this.allocationTooltipList = this.allocationTooltipList
          .filter((item: any) => item.PRODUCTID != productId)
          .map((item: any) => ({
            ...item,
            oldAllocated: item.ALLOCATED
          }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.removing = false;
    }
  }

  async removeDeploymentLog() {
    if (!this.selectedLog) return;
    const log = this.selectedLog;
    try {
      this.deletingLog = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateLogs",
        action: "remove",
        deploymentId: this.deploymentId,
        userId: this.selectedUser.USERID,
        logType: log.LOGTYPE,
        logId: log.LOGID
      });
      this.deletingLog = false;
      if (response.data.STATUS == 1) {
        // update presentation
        this.logList = this.logList.filter(
          (item: any) => item.LOGID != log.LOGID
        );
        const prevLogId = response.data.PREVLOGID || 0;
        const prevLogCreatedTs = response.data.PREVLOGCREATEDTS || "";
        const completion = response.data.COMPLETION || 0;
        const deploymentPercent = response.data.deploymentPercent || 0;
        this.$emit("updateDeploymentPercent", deploymentPercent);

        // update on main page
        this.$emit("updateActions", this.selectedUser.USERID, {
          type: "remove",
          prevLogId,
          prevLogCreatedTs,
          completion
        });
        this.confirmRemoveAction = false;
      }
    } catch (err) {
      this.deletingLog = "error";
      console.log(err);
    } finally {
      this.deletingLog = false;
    }
  }

  getToolTipClasses(type, items, modalType) {
    let ret = "";
    if (["assets", "services", "addProductsServices"].includes(modalType)) {
      if (!(items || []).length) {
        if (modalType == "addProductsServices" && type == "services") {
          if (!this.selectedUser.ASSETS.length && !items.length) {
            ret = "zero-services-items";
          }
        } else {
          ret = "zero-items";
        }
      } else {
        if ((items || []).length < 3) {
          if (modalType == "addProductsServices" && type == "services") {
            //
          } else {
            ret = "few-items";
          }
        }
      }
    }
    return ret;
  }

  afterShowConfirm() {
    $("body .modal-backdrop.show:not(:first)").css("background", "none");
  }

  showEndUserFileUpload() {
    $("#endUserFileUpload").click();
  }

  async handleEndUserFileUpload(e) {
    var comInstance = this;
    e.stopPropagation();
    e.preventDefault();

    var filePath = e.target.files[0].name;
    var allowedExtensions = /(\.csv)$/i;
    var isCSV = filePath.indexOf(".csv") != -1 ? true : false;
    this.errorMsg = "";
    if (!allowedExtensions.exec(filePath)) {
      this.errorMsg = "Please upload file having extensions .csv only.";
      return false;
    } else {
      //upload file, and get returned json
      comInstance.saving = true;
      var processedFileUpload = getImageRouteData({
        controller: "Deployments",
        FunctionName: "ImportEndUser",
        filePath: e.target.files[0],
        deploymentId: this.deploymentId,
        fileType: "csv",
        fileName: filePath
      })
        .then(async function(response, statusText, jqXHR) {
          if (response.data.STATUS === 1) {
            if (response.data.NOTIMPORTEDALLEU) {
              notifier.alert(response.data.STATUSMESSAGE);
            } else {
              notifier.success(response.data.STATUSMESSAGE);
            }
            comInstance.$emit("reload");
            comInstance.$emit("close");
          } else if (response.data.STATUS === 2) {
            comInstance.errorMsg = response.data.STATUSMESSAGE;
          } else {
            var downloadLink =
              "<a href='https://var360.s3.amazonaws.com/spreadsheets/OrderUploadTemplate.csv' style='color:blue; cursor: pointer' target='_blank'>here</a>";
            var messageError = response.data.STATUSMESSAGE || "";
            messageError +=
              ". If don't have standard file, please download sample template " +
              downloadLink;
            comInstance.errorMsg = messageError;
          }
          comInstance.saving = false;
        })
        .catch(function(error) {
          comInstance.saving = "error";
        });
    }
  }

  // selectLocationOption(id) {
  //   if (id == "onetime") {
  //     this.selectedSiteLocation = [];
  //     this.selectedState = [];
  //     this.euInfo = {
  //       ...this.euInfo,
  //       siteLocation: "",
  //       locationName: "",
  //       addr1: "",
  //       addr2: "",
  //       city: "",
  //       state: "",
  //       zip: ""
  //     };
  //   } else {
  //     this.selectedSiteLocation = [id];
  //     this.euInfo.siteLocation = id;
  //     const findLocation = this.siteLocationList.find(item => id == item.ID);
  //     if (findLocation && id) {
  //       const locationData = findLocation.data || {};
  //       this.selectedState = [locationData.STATE];
  //       this.euInfo = {
  //         ...this.euInfo,
  //         siteLocation: locationData.LOCATIONID || "",
  //         locationName: "", // locationData.LOCATIONNAME || "",
  //         addr1: locationData.ADDRESS1 || "",
  //         addr2: locationData.ADDRESS2 || "",
  //         city: locationData.CITY || "",
  //         state: locationData.STATE || "",
  //         zip: locationData.ZIP || ""
  //       };
  //     }
  //   }
  // }

  // disableAddressFields() {
  //   const locationId = this.selectedSiteLocation.length
  //     ? this.selectedSiteLocation[0]
  //     : 0;
  //   return !!locationId;
  // }

  get selectedGroup() {
    const locationId = this.selectedSiteLocation.length
      ? this.selectedSiteLocation[0]
      : 0;
    let group = this.siteLocationList.find(item => item.ID == locationId);
    if (!group) {
      group = this.siteLocationList[0];
    }
    return group;
  }

  toggleChangeInfoOption(id, text) {
    if (!this.euSettings.selectedChangeInfoIds.includes(id)) {
      this.euSettings.selectedChangeInfoIds.push(id);
    } else {
      this.euSettings.selectedChangeInfoIds = this.euSettings.selectedChangeInfoIds.filter(
        t => t != id
      );
    }
    // sort
    const ret: any = [];
    for (const t of this.changeInfoOptions) {
      if (this.euSettings.selectedChangeInfoIds.includes(t.id)) {
        ret.push(t.id);
      }
    }
    this.euSettings.selectedChangeInfoIds = ret;
  }

  getIdentifierNames(productId, userId, purchaseID) {
    // this.allLinkedHardwares
    const linkedHardwares = this.allLinkedHardwares.filter(item => {
      return (
        item.PURCHASEID == purchaseID &&
        item.ENDUSERID == userId &&
        item.PRODUCTID == productId &&
        (item.ASSETNUMBER || item.ASSETTAG)
      );
    });
    let names = linkedHardwares
      .map(
        item =>
          `<span style="border-bottom: 1px dotted #000;">${this.getHardwareSerialNumber(
            item.ASSETNUMBER,
            item.ASSETTAG
          )}</span>`
      )
      .join(" , ");
    if (!names) {
      names = '<span class="text-grey">N/A</span>';
    }
    return names;
  }

  getHardwareSerialNumber(serial, tag) {
    const ret: string[] = [];
    if (serial != "") {
      ret.push(serial);
    }
    if (tag != "") {
      ret.push(tag);
    }

    return ret.join("/");
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  async updateEUCustomData() {
    // remove HTML
    for (const item of this.euFields) {
      if ((item.customDtype || 1) != 3) {
        item.customValue = this.removeHTML(item.customValue);
      }
    }

    try {
      this.savingEUCustomData = true;
      const response = await ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "UpdateEUCustomData",
        deploymentId: this.deploymentId,
        aId: this.aID,
        endUserId: this.selectedUser.USERID,
        purchaseId: this.selectedUser.PURCHASEID || 0,
        subOrderId: this.selectedUser.SUBORDERID || 0,
        euFields: this.euFields.map(item => ({
          customFieldId: item.CUSTOMFIELDID,
          customValue: item.customValue || ""
        }))
      });

      if (response.STATUS == 1) {
        this.savingEUCustomData = false;
        this.euFieldsBk = JSON.parse(JSON.stringify(this.euFields));
        notifier.success(response.STATUSMESSAGE);
        this.$emit("reload");
        this.$emit("close");
      } else {
        this.savingEUCustomData = "error";
      }
    } catch (err) {
      this.savingEUCustomData = "error";
      console.log(err);
    }
  }

  async duplicateEU() {
    this.duplicateLoading = true;
    const response = await ApiHelper.callApi("post", {
      Controller: "Deployments",
      FunctionName: "DuplicateEndUser",
      deploymentId: this.deploymentId,
      aId: this.aID || 0,
      endUserId: this.selectedUser.USERID
    });

    if (response.STATUS == 1) {
      notifier.success(response.STATUSMESSAGE);
      this.$emit("reload");
      this.$emit("close");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.duplicateLoading = false;
  }
}
