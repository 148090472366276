







































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import PageTitle from "../components/pageTitle.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { colsResizeable } from "@/helpers/ApiHelper";
import ListHeaderActions from "@/components/ListHeaderActions.vue";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    PageTitle,
    LaddaButton,
    ListHeaderActions
  }
})

export default class SpiffSources extends TSXComponent<void> {
  $route: any;
  loading = false;
  pageNumber = 1;
  curPage = 1;
  totalRows = 0;
  details: any = {};
  spiffDetails = [];
  spiffSourceId = 0;
  filters: any = {
    sku: ""
  }
  sort = {
    field: null,
    direction: {
      sku: 1
    }
  };
  directionField = "";
  searchFilters: any = {
    sku: ""
  }

  async created() {
    this.spiffSourceId = this.$route.params.id ? parseInt(this.$route.params.id) : 0;
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "SpiffSourceView",
        id: this.spiffSourceId,
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        sku: this.filters.sku,
        order: this.sort.field ? this.sort.field : 0,
        direction:
          this.directionField != ""
            ? this.sort.direction[this.directionField]
            : 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS != 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.spiffDetails = this.details.SPIFFDETAILS;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  listPageRedirection() {
    this.$router.go(-1);
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  searchBy(field) {
    if (this.filters[field] == "") {
      return;
    }
    this.searchFilters[field] = this.filters[field];
    this.pageNumber = 1;
    this.fetchData();
  }

  resetBy(field) {
    if (this.filters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = "";
    this.searchFilters[field] = "";
    this.fetchData();
  }
}
