var render, staticRenderFns
import script from "./AutoSuggestInput2.vue?vue&type=script&lang=tsx"
export * from "./AutoSuggestInput2.vue?vue&type=script&lang=tsx"
import style0 from "./AutoSuggestInput2.vue?vue&type=style&index=0&id=513242f2&prod&scoped=true&lang=less"
import style1 from "./AutoSuggestInput2.vue?vue&type=style&index=1&id=513242f2&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513242f2",
  null
  
)

export default component.exports