
























































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import Datepicker from "../components/Datepicker.vue";
import CustomerAutoSuggestInput from "../components/CustomerAutoSuggestInput.vue";
import { notifier } from "../models/common";

@Component({
  inheritAttrs: false,
  components: {
    Datepicker,
    CustomerAutoSuggestInput
  }
})
export default class InvoiceEdit extends TSXComponent<void> {
  title = "Edit Invoice";
  invoiceDate = "";
  invoiceName = "";
  loading = false;
  customer = "";
  aID = 0;
  accountID = "";
  invoicePOID = 0;
  invoiceTotal = 0;
  arrowCounter = 0;
  errorMsg = "";
  itemNames: {}[] = [];

  created() {
    if (this.$route.params.id && parseInt(this.$route.params.id) != 0) {
      this.title =
        "Edit Invoice #<span id='invoiceID'>" +
        this.$route.params.id +
        "</span>";
      this.editInvoice(this.$route.params.id, self);
    }
  }

  updateCustomer(data) {
    // console.log("aID - Customer - AccountID: ", data);
    // this.customer = value;
    // this.aID = id;
    // this.accountID = accountID;
    this.aID = data.account.AID;
    this.accountID = data.account.ACCOUNTID;
    // this.arrowCounter = 0;
    // var element = document.getElementById('autoSuggestCustomer');
    // element.classList.add('hide');
    $("#customer")
      .parent()
      .find(".errorMsg")
      .html("");
  }
  onArrowDown(data) {
    if (this.arrowCounter < data.length) {
      this.arrowCounter = this.arrowCounter + 1;
    }
  }
  onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }
  // onEnter(data) {
  // 	this.customer = data[this.arrowCounter].ANAME;
  // 	this.arrowCounter = 0;
  // 	var element = document.getElementById('autoSuggestCustomer');
  // 	if(!element)
  // 	element.classList.add('hide');
  // }
  validateBeforeSubmit(e) {
    this.$validator.validateAll().then(result => {
      //make sure an account is selected
      if (this.aID == 0) {
        $("#customer")
          .parent()
          .find(".errorMsg")
          .html("Please select a customer from suggested list.");
        result = false;
      }

      if (result && this.$route.params.id != "") {
        e.preventDefault();
        this.loading = true;
        return this.invoiceSave(this);
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  editInvoice(invoiceID, container) {
    var comInstance = this;
    comInstance.itemNames = [];

    var dataObj = {
      controller: "Invoices",
      FunctionName: "View",
      invoiceId: `${invoiceID}`
    };
    if (invoiceID != 0) {
      var invoiceData = getRouteData(dataObj);
      invoiceData.then(function(response, statusText, jqXHR) {
        try {
          //set some value for comInstance
          var invoiceDetails = response.data.INVOICEDETAILS;
          if (invoiceDetails != undefined) {
            comInstance.invoiceName = invoiceDetails.IDESCRIPTION;
            comInstance.invoiceDate = invoiceDetails.LSDATE;
            comInstance.aID = invoiceDetails.AID;
            comInstance.accountID = invoiceDetails.AACCOUNTID;
            comInstance.customer = invoiceDetails.ACCOUNTNAME;
            comInstance.invoicePOID = invoiceDetails.POID;
            comInstance.invoiceTotal = invoiceDetails.ITOTALPRICE;
          } else {
            comInstance.invoiceName = "";
            comInstance.invoiceDate = "";
            comInstance.aID = 0;
            comInstance.accountID = "";
            comInstance.customer = "";
            comInstance.invoicePOID = 0;
            comInstance.invoiceTotal = 0;
          }
        } catch (e) {
          //handle error
          console.log("editInvoice error : ", e);
        }
      });
    } else {
      container.items = { QDESCRIPTION: "" };
    }
  }
  backToDetails() {
    var invoiceID = this.$route.params.id;
    this.$router.push({ name: "InvoiceDetails", params: { id: invoiceID } });
  }
  invoiceSave(quote) {
    var comInstance = this;
    var today = new Date();
    // var invoiceDateNew = document.getElementById('invoiceDate').value;
    const invoiceDateNew = $("#invoiceDate").val();
    const invoiceNameNew = $("#invoiceName").val();
    const POID = $("#invoicePOID").val()
      ? $("#invoicePOID").val()
      : comInstance.invoicePOID;
    const invoiceID = this.$route.params.id.toString();

    var dataObj = {
      controller: "Invoices",
      FunctionName: "Edit",
      invoiceID: invoiceID,
      mdInvoiceDate: invoiceDateNew,
      aID: this.aID,
      accountID: comInstance.accountID,
      mdInvoiceName: invoiceNameNew,
      mdInvoiceStatus: 1,
      mdInvoicePOID: POID,
      mdInvoiceTotal: comInstance.invoiceTotal
    };
    // console.log("dataInput", dataObj);
    var saveData = getRouteData(dataObj)
      .then(function(response, statusText, jqXHR) {
        if (typeof response.data.ERROR != "undefined") {
          console.log("InvoiceUpdate:", response.data.ERROR);
          notifier.alert(response.data.ERROR);
        } else {
          notifier.success("Invoice Updated Successfully!");
          comInstance.backToDetails();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
}
