import { render, staticRenderFns } from "./SnoozeTooltip.vue?vue&type=template&id=661f9737&scoped=true"
import script from "./SnoozeTooltip.vue?vue&type=script&lang=tsx"
export * from "./SnoozeTooltip.vue?vue&type=script&lang=tsx"
import style0 from "./SnoozeTooltip.vue?vue&type=style&index=0&id=661f9737&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661f9737",
  null
  
)

export default component.exports