



































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { AcctsListResp } from "../models/AcctsListResp"

interface Props {
  options: AcctsListResp.AccountsSelect[];
  selectedUserIds: number[];
  showNotSelectedInAnyGroup: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class AccountMultiselect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: AcctsListResp.AccountsSelect[];

  @Prop({ required: true })
  selectedUserIds!: number[];

  @Prop({ required: false, default: true })
  showNotSelectedInAnyGroup!: boolean;

  dropdownVisible = false
  search = ''
  showOnlySelected: boolean = false
  showOnlyNotSelected: boolean = false

  autoExpand() {
    var value = $("#autoExpand").val();
    $("#autoExpand").css("width", ((value.length+1) * 9.2) + 'px');
  }

  get filteredOptions(): AcctsListResp.AccountsSelect[] {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => `${option.ANAME}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || `${option.ACCOUNTALIAS}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || `${option.AZIP}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || `${option.ACITY}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || `${option.ASTATE}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || `${option.ACCOUNTID}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 )
    }

    if (this.showOnlySelected) {
      options = options.filter((o) => this.selectedUserIds.indexOf(o.AID) !== -1)
    }
    if (this.showOnlyNotSelected) {
      options = options.filter((o) => o.GROUPCOUNT == 0)
    }

    return options
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(userId: number): boolean {
    const checked = !!this.selectedUserIds.find((id) => id === userId)
    return checked
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }
}
