
































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier, wait } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import axios from "axios";
import directives from "../helpers/directives";
import PageTitle from '../components/pageTitle.vue';

declare const getImageRouteData: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    PageTitle
  },
  directives
})
export default class Hardwareadd extends TSXComponent<void> {
  productDetails: {
    [key: string]: {
      detail?: string;
      description?: string;
    };
  } = {};
  assetNumber = "";
  sku = "";
  category = "";
  price = "";
  warrentyexp = "";
  categories = "";
  length = 2;
  imageLength = 1;
  images = {};
  randId = Date.now();
  removed: string[] = [];
  hardwareImages: string[] = [];
  saving = false;
  loading: any = false;
  headerContent = false;
  items: {
    HARDWAREIMAGES?: string;
    HARDWAREID?: number;
  } = {};
  details = [];

  categoryName = "";
  catList: any = [];
  inputSKUTimer: any = null;
  checkingSKU = false;
  hardwareDesc = "";
  allowEdit = false;

  mounted() {
    this.showDatePicker();
    const self = this;
    $(document).on("change", "#warrentyexp", e => {
      self.warrentyexp = $("#warrentyexp")
        .val()
        .trim();
    });
  }

  async created() {
    var productDetail: {
      [key: string]: {
        detail?: string;
        description?: string;
      };
    } = {};
    for (let i = 1; i <= 2; i++) {
      productDetail["product_" + i] = {
        detail: "",
        description: ""
      };
    }
    this.productDetails = productDetail;

    // var categoryTrendObj = {
    //   controller: "Dashboard",
    //   FunctionName: "CategoryTrend",
    //   ReportType: "Orders",
    //   Unit: "Quantity",
    //   TopNCategory: "6",
    //   fYear: "2018"
    // };
    // var vm = this;
    // let resultCategoryTrend = getRouteData(categoryTrendObj);
    // resultCategoryTrend.then(function(response, statusText, jqXHR) {
    //   try {
    //     vm.categories = response.data.TOPNCATEGORYQTY;
    //   } catch (e) {
    //     //handle error
    //     console.log("dashBoard.js : grabSearchData error : ", e);
    //   }
    // });
    // if (this.$route.params.id) {
    //   this.grabViewHardwareData();
    // }

    try {
      // this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "Category"
      });

      if (response.data.STATUS == 1) {
        this.catList = response.data.CATEGORY;
      }
    } catch (err) {
      console.log(err);
    } finally {
      // this.loading = false;
    }

    const hardwareId = parseInt(this.$route.params.id) || 0;
    if (hardwareId > 0) {
      // get hardware details
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Hardware",
          FunctionName: "GetDetails",
          hardwareId
        });
        if (response.data.STATUS) {
          const details = response.data.details || {};
          this.assetNumber = details.ASSETNUMBER || "";
          this.sku = details.SKU || "";
          this.hardwareDesc = details.HDESCRIPTION || "";
          this.price = details.PRICE || "";
          this.warrentyexp = details.WARRENTYEXP || "";
          this.category = details.CATEGORYID || 0;
          this.categoryName = details.CATEGORYNAME || "";
          this.allowEdit = details.PURCHASEID ? false : true;

          if (!this.allowEdit) {
            notifier.alert("Not allow editting");
            this.$router.replace({
              name: "Hardware"
            });
            return;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  listPageRedirection() {
    this.$router.push({ name: "Hardware" });
  }

  // grabViewHardwareData() {
  //   var dataObj = {
  //     controller: "Hardware",
  //     Content: "Detailed",
  //     ObjID: this.$route.params.id
  //   };

  //   if (parseInt(this.$route.params.id) != 0) {
  //     dataObj["FunctionName"] = "View";
  //     var container = this;
  //     var hardwareViewData = getRouteData(dataObj);
  //     hardwareViewData
  //       .then(function(response, statusText, jqXHR) {
  //         try {
  //           if (response.data.STATUS && response.data.STATUSMESSAGE) {
  //             container.items = response.data.HARDWAREDETAILS || [];
  //             container.loading = false;
  //             container.productDetails =
  //               response.data.HARDWAREPRODUCTDETAILS || [];
  //             var images = container.items.HARDWAREIMAGES
  //               ? container.items.HARDWAREIMAGES.split(",")
  //               : [];
  //             for (var image in images) {
  //               images[image] =
  //                 dataURL +
  //                 "Inbound/apiFiles/images/" +
  //                 container.items.HARDWAREID +
  //                 "/" +
  //                 images[image];
  //             }
  //             container.hardwareImages =
  //               container.items.HARDWAREIMAGES != "" ? images : [];
  //             container.details = response.data;
  //           } else if (!response.data.STATUS && response.data.STATUSMESSAGE) {
  //             notifier.warning(response.data.STATUSMESSAGE);
  //           } else if (response.data.ERROR) {
  //             console.log(response.data.ERROR);
  //           }
  //         } catch (e) {
  //           //handle error
  //           console.log("hardware.js : grabHardwareData error : ", e);
  //         }
  //       })
  //       .catch(function(error) {
  //         console.log(error);
  //       });
  //   }
  //   // container.items = [{
  //   //     assetNumber: '',
  //   //     sku: '',
  //   //     category: '',
  //   //     price: '',
  //   //     warrentyexp: ''
  //   // }]
  // }

  pdfDownLoadFn(id) {
    var exportObj = {
      controller: "Hardware",
      FunctionName: "ExportView",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      Content: "Detailed",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "PDF";
    exportObj["ObjID"] = id;
    this.downloadProcess(exportObj, this);
  }

  printFn(id) {
    var exportObj = {
      controller: "Hardware",
      FunctionName: "ExportView",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      Content: "Detailed",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "PDF";
    exportObj["Print"] = "Yes";
    exportObj["serviceID"] = id;
    printProcess(exportObj);
  }

  downloadProcess(data, container) {
    var downLoadRes = getRouteData(data);
    downLoadRes.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS) {
          var link = document.createElement("a");
          link.download = "Quotes";
          link.href = response.data.S3URL;
          link.click();
        } else if (response.data.ERROR) {
          console.log(response.data.ERROR);
        }
      } catch (e) {
        //handle error
        console.log("hardware.js : csvDownLoadFn error : ", e);
      }
    });
  }

  updateCategory(value) {
    this.category = value;
    const inList = this.catList.find(item => item.CATEGORYID == value);
    if (inList) {
      this.categoryName = inList.CATEGORYNAME;
    }

    console.log(this.category, this.categoryName);
  }

  async validateBeforeSubmit(e) {
    // this.warrentyexp = document.getElementById("warrentyexp").value;
    e.preventDefault();
    e.stopPropagation();

    const valid = await this.$validator.validateAll();
    if (valid) {
      this.saving = true;

      const hardwareId = parseInt(this.$route.params.id) || 0;
      if (hardwareId > 0) {
        await this.updateHardware(this);
      } else {
        await this.addHardware(this);
      }
      this.saving = false;
    }

    // this.$validator.validateAll().then(async result => {
    //   if (result) {
    //     e.preventDefault();
    //     this.saving = true;
    //     if (this.$root.$route.params.id) await this.updateHardware(this);
    //     else await this.addHardware(this);

    //     this.saving = false;
    //   } else {
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }
    // });
  }
  addRow(index) {
    if (index == "product_" + this.length) {
      this.length = this.length + 1;
      this.productDetails["product_" + this.length] = {
        detail: "",
        description: ""
      };
      this.$forceUpdate();
    }
  }
  onFileChange(event) {
    var vm = this;
    this.images["image_" + this.randId] = event.target.files[0];
    this.$forceUpdate();
    var reader = new FileReader();
    reader.onload = function(e) {
      var element = $("#image_image_" + vm.randId);
      element!.src = e!.target!.result;
      vm.randId = Date.now();
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  removeImage(index) {
    var list;
    if (this.$root.$route.params.id) {
      for (var image in this.images) {
        if (image.search(index) != -1) {
          list = document.getElementById("list_" + index);
          list!.classList.add("hide");
          this.removed.push(image);
        }
      }
    } else {
      list = document.getElementById("list_" + index);
      list!.classList.add("hide");
      this.removed.push(index);
    }
  }

  showDatePicker() {
    var html = "";
    html += '<div id="datepicker" class="datepicker-container">';
    html +=
      '<div class="datepicker-panel" data-view="years picker"><ul class="fr-current"><li data-view="years prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="years current"></li><li data-view="years next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="years"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="months picker"><ul class="fr-current"><li data-view="year prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="year current"></li><li data-view="year next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="months"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="days picker"><ul class="fr-current"><li data-view="month prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="month current"></li><li data-view="month next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="week"></ul><ul data-view="days"></ul></div>';
    html += "</div>";

    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      zIndex: 2048,
      daysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      template: html
    });
  }

  addHardware(value) {
    var saveData = {};
    saveData["Controller"] = "Hardware";
    saveData["FunctionName"] = "Add";
    saveData["hardwareID"] = "[Auto Generate]";
    saveData["assetNumber"] = value.assetNumber;
    saveData["hardwareSKU"] = value.sku;
    saveData["category"] = value.category;
    saveData["hDescription"] = value.hardwareDesc || "";
    saveData["warrentyExp"] = value.warrentyexp;
    // saveData["hstatus"] = "Purchased";
    saveData["hstatus"] = "";
    saveData["userID"] = sessionStorage.getItem("userId");
    saveData["price"] = value.price;
    saveData["uploadPath"] = "Inbound/apiFiles/";

    var k = 1;
    for (i = 1; i <= value.length; i++) {
      if (
        value.productDetails["product_" + i].detail != "" &&
        value.productDetails["product_" + i].description != ""
      ) {
        saveData["productDetail" + k] =
          value.productDetails["product_" + i].detail;
        saveData["productDescription" + k] =
          value.productDetails["product_" + i].description;
        k = k + 1;
      }
    }
    saveData["hLines"] = k - 1;

    var i = 1;
    for (var item in value.images) {
      if (value.removed.includes(item)) continue;
      else {
        saveData["hardwareImage" + i] = value.images[item];
        i = i + 1;
      }
    }

    saveData["hImagesLength"] = i - 1;
    var container = this;
    var saveRecord = getImageRouteData(saveData);
    saveRecord
      .then(function(response, statusText, jqXHR) {
        try {
          if (response.data.ERROR) {
            container.loading = "error";
            console.log(response.data.ERROR);
          } else if (!response.data.STATUS) {
            container.loading = "error";
            const message = response.data.STATUSMESSAGE || "";
            if (message) {
              notifier.alert(message);
            }
          }

          if (response.data.STATUS) {
            container.loading = false;
            container.$router.push({ name: "Hardware" });
          }

          // if (response.data.STATUS && response.data.STATUSMESSAGE) {
          //   container.loading = false;
          //   container.$router.push({ name: "Hardware" });
          // } else if (response.data.ERROR || !response.data.STATUS) {
          //   container.loading = 'error';
          //   console.log(response.data.ERROR);
          // }
        } catch (e) {
          //handle error
          // console.log("hardware.js : grabHardwareData error : ", e);
          container.loading = "error";
          console.log(e);
        }
      })
      .catch(function(error) {
        container.loading = "error";
        console.log(error);
      });
  }

  updateHardware(value) {
    var saveData = {};
    var id = this.$route.params.id;
    saveData["Controller"] = "Hardware";
    saveData["FunctionName"] = "Update";
    saveData["hardwareID"] = id;
    saveData["assetNumber"] = value.assetNumber;
    saveData["hardwareSKU"] = value.sku;
    saveData["category"] = value.category;
    saveData["hDescription"] = value.hardwareDesc || "";
    saveData["warrentyExp"] = value.warrentyexp;
    // saveData["hstatus"] = "Purchased";
    saveData["price"] = value.price;
    saveData["uploadPath"] = "Inbound/apiFiles/";
    saveData["hardwareImages"] = "";
    var i = 1;
    for (var item in value.images) {
      if (value.removed.includes(item)) continue;
      else {
        if (typeof value.images[item] !== "object")
          saveData["hardwareImages"] =
            saveData["hardwareImages"] == ""
              ? value.images[item].replace(
                  dataURL + "Inbound/apiFiles/images/" + id + "/",
                  ""
                )
              : saveData["hardwareImages"] +
                "," +
                value.images[item].replace(
                  dataURL + "Inbound/apiFiles/images/" + id + "/",
                  ""
                );
        else {
          saveData["hardwareImage" + i] = value.images[item];
          i = i + 1;
        }
      }
    }
    saveData["hImagesLength"] = i - 1;

    var k = 1;
    for (var ij = 1; ij <= value.length; ij++) {
      if (
        value.productDetails["product_" + ij].detail != "" &&
        value.productDetails["product_" + ij].description != ""
      ) {
        saveData["productDetail" + k] =
          value.productDetails["product_" + ij].detail;
        saveData["productDescription" + k] =
          value.productDetails["product_" + ij].description;
        k = k + 1;
      }
    }
    saveData["hLines"] = k - 1;
    var self = this;

    let saveRecord = getImageRouteData(saveData);
    saveRecord
      .then(function(response, statusText, jqXHR) {
        try {
          // if (response.data.STATUS && response.data.STATUSMESSAGE) {
          //   self.$router.push({ name: "Hardware" });
          // } else if (response.data.ERROR) {
          //   console.log(response.data.ERROR);
          // }

          if (response.data.ERROR) {
            self.loading = "error";
            console.log(response.data.ERROR);
          } else if (!response.data.STATUS) {
            self.loading = "error";
            const message = response.data.STATUSMESSAGE || "";
            if (message) {
              notifier.alert(message);
            }
          }

          if (response.data.STATUS) {
            self.loading = false;
            self.$router.push({ name: "Hardware" });
          }
        } catch (e) {
          //handle error
          // console.log("hardware.js : grabHardwareData error : ", e);
          self.loading = "error";
          console.log(e);
        }
      })
      .catch(function(error) {
        self.loading = "error";
        console.log(error);
      });
  }

  // importHardware(value) {
  //   var saveData = {};
  //   var headersList = [
  //     "Asset",
  //     "SKU",
  //     "Description",
  //     "Category",
  //     "Price",
  //     "Warrenty Exp",
  //     "Status"
  //   ];
  //   var newHeaders: string[] = [];
  //   saveData["Controller"] = "Hardware";
  //   saveData["FunctionName"] = "Import";
  //   saveData["hardwareID"] = "[Auto Generate]";
  //   saveData["importList"] = value.importedRows;
  //   saveData["userID"] = sessionStorage.getItem("userId");
  //   saveData["uploadPath"] = "Inbound/apiFiles/";
  //   saveData["headerRow"] = this.headerContent;
  //   for (var i = 0; i < value.headers.length; i++) {
  //     newHeaders.push(headersList[i]);
  //   }
  //   saveData["headersList"] = newHeaders;
  //   var container = this;
  //   var saveRecord = getRouteData(saveData);
  //   saveRecord
  //     .then(function(response, statusText, jqXHR) {
  //       try {
  //         if (response.data.STATUS && response.data.STATUSMESSAGE) {
  //           container.loading = false;
  //           container.$router.push({ name: "Hardware" });
  //         } else if (response.data.ERROR) {
  //           console.log(response.data.ERROR);
  //         }
  //       } catch (e) {
  //         //handle error
  //         console.log("hardware.js : grabHardwareData error : ", e);
  //       }
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // }

  // },
  @Watch("$root.items", { deep: true })
  updateonWatch(to, from) {
    this.assetNumber = to.ASSETNUMBER;
    this.sku = to.HARDWARESKU;
    this.category = to.CATEGORY;
    this.price = to.PRICE + "$";
    this.warrentyexp = to.WARRENTYEXPFORMAT;
    var images;
    if (to.HARDWAREIMAGES != "") {
      images = to.HARDWAREIMAGES.split(",");
      for (var image in images) {
        var randomId = Date.now() + image;
        this.images["image_" + randomId] =
          dataURL +
          "Inbound/apiFiles/images/" +
          to.HARDWAREID +
          "/" +
          images[image];
      }
    }
    this.$forceUpdate();
  }

  @Watch("$root.details.HARDWAREPRODUCTDETAILS", { deep: true })
  second(to, from) {
    var k = 1;
    for (var i = 0; i < to.length; i++) {
      this.productDetails["product_" + k] = {
        detail: to[i].HDETAILS,
        description: to[i].HDESCRIPTION
      };
      k = k + 1;
    }
    this.productDetails["product_" + k] = {
      detail: "",
      description: ""
    };
    this.length = k;
  }

  inputSKU() {
    if (this.inputSKUTimer) {
      clearTimeout(this.inputSKUTimer);
    }
    this.inputSKUTimer = setTimeout(() => {
      this.SKULookup();
    }, 300);
  }

  async SKULookup() {
    if (!this.sku) return;

    try {
      this.checkingSKU = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Quotes",
        FunctionName: "quoteLineItemList",
        search: this.sku
      });
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        const productInfo = response.data.LINEITEMS[0];
        if (!productInfo) return;

        const categoryId = productInfo.DEFAULTCATEGORYID || 0;
        this.hardwareDesc = productInfo.PRODUCTNAME || "";
        if (categoryId) {
          this.updateCategory(categoryId);
        }
      } else {
        this.category = "";
        this.categoryName = "";
        this.hardwareDesc = "";
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.checkingSKU = false;
    }
  }
}
