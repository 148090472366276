<template>
    <div>
      <label for="flLogo" class="cursorPointer lblUpload">
        <span class="mr-2">
          <svg fill="#000000" height="16px" width="16px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               viewBox="0 0 384.97 384.97" xml:space="preserve">
             <g id="Upload">
                <path d="M372.939,264.641c-6.641,0-12.03,5.39-12.03,12.03v84.212H24.061v-84.212c0-6.641-5.39-12.03-12.03-12.03
                  S0,270.031,0,276.671v96.242c0,6.641,5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03v-96.242
                  C384.97,270.019,379.58,264.641,372.939,264.641z"/>
                <path d="M117.067,103.507l63.46-62.558v235.71c0,6.641,5.438,12.03,12.151,12.03c6.713,0,12.151-5.39,12.151-12.03V40.95
                  l63.46,62.558c4.74,4.704,12.439,4.704,17.179,0c4.74-4.704,4.752-12.319,0-17.011l-84.2-82.997
                  c-4.692-4.656-12.584-4.608-17.191,0L99.888,86.496c-4.752,4.704-4.74,12.319,0,17.011
                  C104.628,108.211,112.327,108.211,117.067,103.507z"/>
              </g>
          </svg>
        </span>
        <span>Choose logo</span>
      </label>
      <input id="flLogo" type="file" @change="onFileChange($event)" accept="image/*"/>

    <div class="errorMsg">{{ errorMsg }}</div>
    <div v-if="imageUrl && !loading" class="preview-container">
      <div class="preview-box">
        <img :src="imageUrl" ref="image" class="circle"/>
        <!--
        <div class="crop-box"></div>
        -->
      </div>
      <!-- <button @click="saveImage">Save</button> -->
    </div>
  </div>
  </template>
  
  <script>
  import axios from "axios";

  export default {
    name: 'ImageUploader',
    data() {
      return {
        imageFile: null,
        imageWidth: 0,
        imageHeight: 0,
        cropBox: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        },
        isImageLoaded: false,
        errorMsg: '',
        loading: false
      };
    },
    props: {
      imageSrc: {
        type: String,
        default: ''
      }
    },
    computed: {
      imageUrl() {
        return this.imageFile ? URL.createObjectURL(this.imageFile) : this.imageSrc;
      },
      cropBoxStyle() {
        return {
          left: this.cropBox.x + 'px',
          top: this.cropBox.y + 'px',
          width: this.cropBox.width + 'px',
          height: this.cropBox.height + 'px'
        };
      }
    },
    mounted() {
      window.addEventListener('mouseup', this.onCropBoxMouseUp);
    },
    beforeDestroy() {
      window.removeEventListener('mouseup', this.onCropBoxMouseUp);
    },
    methods: {
      async onFileChange(e) {
        this.loading = true;
        this.$parent.active = true;
        const imageFile = e.target.files[0];
        this.imageFile = imageFile;
        const img = new Image();
        img.onload = () => {
          this.imageWidth = img.width;
          this.imageHeight = img.height;
          this.cropBox.width = this.imageWidth / 2;
          this.cropBox.height = this.imageHeight / 2;
        };
        img.src = URL.createObjectURL(imageFile);

        try{
          let allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
          if (!allowedTypes.includes(imageFile.type)) {
            this.errorMsg = 'Please upload image having extensions .png, .jpg, .jpeg only.';
            this.imageFile = null;
            return;
          }
          this.errorMsg = ''
          let fileName = imageFile.name;
          let fileType = imageFile.type;
          let requestObj = {
            controller: "Customers",
            FunctionName: "LogoUpdate",
            isWebSubmit: true,
            filePath: imageFile,
            fileName: fileName,
            fileType: fileType
          };

          let formData = new FormData();
          for (let key in requestObj) {
            formData.append(key, requestObj[key]);
          }
          formData.append("session", sessionStorage.getItem("sessionID") || "");

          const response = await axios.post(
            dataURL + "?ReturnType=JSON", formData,
          );
          if (response.data.STATUS == 1) {
            this.imageSrc = this.$parent.imageSrc = this.$parent.items.LOGOSRC = response.data.LOGOSRC;
            this.$emit('uploadImage', this.imageSrc);
            this.loading = false;
            this.$parent.active = false;
          }
        }
        catch(err) {
          // console.log(err.message);
        }
      },
      onCropBoxMouseDown(e) {
        e.preventDefault();
        const startX = e.clientX;
        const startY = e.clientY;
        const startBox = { ...this.cropBox };
        const onMouseMove = (e) => {
          const dx = e.clientX - startX;
          const dy = e.clientY - startY;
          this.cropBox.x = Math.max(Math.min(startBox.x + dx, this.imageWidth - startBox.width), 0);
          this.cropBox.y = Math.max(Math.min(startBox.y + dy, this.imageHeight - startBox.height), 0);
        };
        window.addEventListener('mousemove', onMouseMove);
        this.$once('hook:beforeDestroy', () => {
          window.removeEventListener('mousemove', onMouseMove);
        });
      },
      onCropBoxMouseUp() {
        // Do nothing
      },
      // saveImage() {
        // const canvas = document.createElement('canvas');
        // canvas.width = this.cropBox.width;
        // canvas.height = this.cropBox.height;
        // const ctx = canvas.getContext('2d');
        // ctx.drawImage(this.$refs.image, this.cropBox.x, this.cropBox.y, this.cropBox.width, this.cropBox.height, 0, 0, canvas.width, canvas.height);
        // const imageData = canvas.toDataURL('image/jpeg');
        // this.$emit('uploadImage', this.imageSrc);
        // Send the imageData to the server to save in the database.
        // You can use Vue.js's built-in AJAX functionality or any other library to make the request.
      // }
    }
  };
  </script>
  
<style>
.preview-container {
  position: relative;
}

.preview-box {
  position: relative;
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.crop-box {
  position: absolute;
  border: 2px dashed #000;
  cursor: move;
  z-index: 100;
}

button {
  display: block;
  margin: auto;
  margin-top: 1rem;
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.lblUpload {
  svg {
  }
}
#flLogo {
  width: 1px;
  opacity: 0;
}
</style>  