



































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { validateFn } from "../helpers";
@Component({
  inheritAttrs: false,
  components: {}
})
export default class quoteEdit extends TSXComponent<void> {
  tableColumns = [];
  items = {
    quoteID: "",
    qDescription: "",
    qDateTime: "",
    aID: "",
    qlines: "6",
    qspecialpricingid: "",
    contractid: "",
    qLocationID: "0",
    ItemDescription1: "",
    ItemNumber1: "",
    RegPrice1: "",
    ItemQuantity1: "",
    ItemTotal1: 0,
    ItemDescription2: "",
    ItemNumber2: "",
    RegPrice2: "",
    ItemQuantity2: "",
    ItemTotal2: 0,
    ItemDescription3: "",
    ItemNumber3: "",
    RegPrice3: "",
    ItemQuantity3: "",
    ItemTotal3: 0,
    ItemDescription4: "",
    ItemNumber4: "",
    RegPrice4: "",
    ItemQuantity4: "",
    ItemTotal4: 0,
    ItemDescription5: "",
    ItemNumber5: "",
    RegPrice5: "",
    ItemQuantity5: "",
    ItemTotal5: 0,
    ItemDescription6: "",
    ItemNumber6: "",
    RegPrice6: "",
    ItemQuantity6: "",
    ItemTotal6: 0,
    totalPrice: "0.00",
    ItemCategory1: "",
    ItemCategory2: "",
    ItemCategory3: "",
    ItemCategory4: "",
    ItemCategory5: "",
    ItemCategory6: "",
    QuoteliID1: "",
    QuoteliID2: "",
    QuoteliID3: "",
    QuoteliID4: "",
    QuoteliID5: "",
    QuoteliID6: "",
    QDESCRIPTION: "",
    LSDATE: ""
  };
  quoteItems:{
    QPRODDESC:string,
    QPRODSKU:string,
    QPRICEREG:number,
    QPRODQUANTITY:number,
    QPRICEACT:(string),
    QCATEGORY:string,
    QUOTELIID:string,
  }[] = [];
  accItems = [];
  details = {
    QUOTEDETAILS: {
      AACCOUNTID: 0,
      QOPEN: true
    }
  };

  created() {
    if (parseInt(this.$route.params.id) != 0) {
      this.getQuotes();
      this.getAccs();
    }
  }
  async getQuotes() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Quotes",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: this.$route.params.id
    });
    try {
      this.quoteItems = response.data.QUOTEITEMS;
      this.details = response.data;
      this.items["qDescription"] = response.data.QUOTEDETAILS.QDESCRIPTION;
      this.items["qDateTime"] = new Date(response.data.QUOTEDETAILS.QDATETIME)
        .toISOString()
        .slice(0, 10);
      this.items["aID"] = response.data.QUOTEDETAILS.AID;
      this.items["qspecialpricingid"] =
        response.data.QUOTEDETAILS.QSPECIALPRICINGID;
      this.items["contractid"] = response.data.QUOTEDETAILS.CONTRACTID;
      this.items["qLocationID"] = response.data.QUOTEDETAILS.QHPLOCATIONID;
      let totalPrice = 0;
      var i;
      for (i = 0; i < this.quoteItems.length; i++) {
        this.items["ItemDescription" + (i + 1)] = this.quoteItems[i].QPRODDESC;
        this.items["ItemNumber" + (i + 1)] = this.quoteItems[i].QPRODSKU;
        this.items["RegPrice" + (i + 1)] = this.quoteItems[i].QPRICEREG;
        this.items["ItemQuantity" + (i + 1)] = this.quoteItems[i].QPRODQUANTITY;
        this.items["ItemTotal" + (i + 1)] = this.quoteItems[i].QPRICEACT;
        this.items["ItemCategory" + (i + 1)] = this.quoteItems[i].QCATEGORY;
        this.items["QuoteliID" + (i + 1)] = this.quoteItems[i].QUOTELIID;
        totalPrice = totalPrice + parseInt(this.quoteItems[i].QPRICEACT, 10);
      }
      this.items["totalPrice"] = totalPrice > 0 ? totalPrice.toFixed(2) : "";
      console.log("this.items", this.items);
    } catch (e) {
      //handle error
      console.log("quotes.js : grabOrderData error : ", e);
    }
  }
  async getAccs() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Accounts",
      FunctionName: "List"
    });
    try {
      this.accItems = response.data.ACCOUNTS;
    } catch (e) {
      console.log("quotes.js : grabOrderData error : ", e);
    }
  }
  getNumber(number, defaultNumber) {
    return isNaN(parseInt(number, 10)) ? defaultNumber : parseInt(number, 10);
  }
  totalcalFn() {
    const ItemTotal1 =
      this.getNumber(this.items.RegPrice1, 0) *
      this.getNumber(this.items.ItemQuantity1, 0);
    const ItemTotal2 =
      this.getNumber(this.items.RegPrice2, 0) *
      this.getNumber(this.items.ItemQuantity2, 0);
    const ItemTotal3 =
      this.getNumber(this.items.RegPrice3, 0) *
      this.getNumber(this.items.ItemQuantity3, 0);
    const ItemTotal4 =
      this.getNumber(this.items.RegPrice4, 0) *
      this.getNumber(this.items.ItemQuantity4, 0);
    const ItemTotal5 =
      this.getNumber(this.items.RegPrice5, 0) *
      this.getNumber(this.items.ItemQuantity5, 0);
    const ItemTotal6 =
      this.getNumber(this.items.RegPrice6, 0) *
      this.getNumber(this.items.ItemQuantity6, 0);
    const totalPrice =
      ItemTotal1 +
      ItemTotal2 +
      ItemTotal3 +
      ItemTotal4 +
      ItemTotal5 +
      ItemTotal6;
    this.items["ItemTotal1"] = ItemTotal1 > 0 ? ItemTotal1 : 0;
    this.items["ItemTotal2"] = ItemTotal2 > 0 ? ItemTotal2 : 0;
    this.items["ItemTotal3"] = ItemTotal3 > 0 ? ItemTotal3 : 0;
    this.items["ItemTotal4"] = ItemTotal4 > 0 ? ItemTotal4 : 0;
    this.items["ItemTotal5"] = ItemTotal5 > 0 ? ItemTotal5 : 0;
    this.items["ItemTotal6"] = ItemTotal6 > 0 ? ItemTotal6 : 0;
    this.items["totalPrice"] = totalPrice > 0 ? totalPrice.toFixed(2) : "";
  }
  async updateDetailItem(row) {
    var detailItemObj = {
      controller: "Quotes",
      FunctionName: "quoteLineItemEdit",
      qProdDesc: this.items["ItemDescription" + row],
      qProdQuantity: this.items["ItemQuantity" + row],
      qPriceReg: this.items["RegPrice" + row],
      quotekeyID: this.$route.params.id,
      qCategory: this.items["ItemCategory" + row],
      qPriceAct: this.items["ItemTotal" + row],
      QuoteliID: this.items["QuoteliID" + row]
    };
    console.log("detailItemObj", detailItemObj);
    const response = await axios.post(
      dataURL + "?ReturnType=JSON",
      detailItemObj
    );
    try {
      if (response.data.STATUS && response.data.STATUSMESSAGE) {
				// console.log('success')
      }
    } catch (e) {
      console.log("quotes.js : updateDetailItem error : ", e);
    }
  }
  async deleteDetailItem(row) {
    var detailItemObj = {
      controller: "Quotes",
      FunctionName: "quoteLineItemDelete",
      QuoteliID: this.items["QuoteliID" + row]
    };
    console.log("detailItemObj", detailItemObj);
    const response = await axios.post(
      dataURL + "?ReturnType=JSON",
      detailItemObj
    );

    try {
      if (response.data.STATUS && response.data.STATUSMESSAGE) {
        this.getQuotes();
        this.getAccs();
      }
    } catch (e) {
      console.log("quotes.js : updateDetailItem error : ", e);
    }
  }

  async saveFn(data) {
    // edit call
    console.log("Edit Call", data);
    var dataObj = {
      FunctionName: "Edit",
      controller: "Quotes"
    };
    dataObj["aID"] = this.items.aID;
    dataObj["mdQuoteName"] = this.items.qDescription;
    dataObj["mdAccountName"] = this.details.QUOTEDETAILS.AACCOUNTID;
    dataObj["mdQuoteStatus"] = this.details.QUOTEDETAILS.QOPEN;
    dataObj["mdQuoteDate"] = this.items.qDateTime;
    dataObj["mdQuoteContractID"] = this.items.contractid;
    dataObj["mdQuoteSpecialPricing"] = this.items.qspecialpricingid;
    dataObj["mdQuoteLocationID"] = this.items.qLocationID;
    dataObj["ObjID"] = this.$route.params.id;
    console.log("dataObj", dataObj);
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);

    try {
      if (response.data.STATUS && response.data.STATUSMESSAGE) {
				// console.log('success')
			}
			
    } catch (e) {
      console.log("quotes.js : updateDetailItem error : ", e);
    }
  }
}
