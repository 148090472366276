





























































































































































































































































import { Component as TSXComponent } from "vue-tsx-support/lib/api";
import { Component, Prop, Watch } from "vue-property-decorator";
import Pagination from "@/components/Pagination.vue";
import axios from "axios";

@Component({
  inheritAttrs: false,
  components: {
    Pagination
  },
  methods: {}
})
export default class DeploymentsGridView extends TSXComponent<void> {
  @Prop({ required: true })
  filterAgency!: string;

  @Prop({ required: true })
  filterAgencyId!: number;

  @Prop({ required: true })
  filterDeliveryCenter!: string;

  @Prop({ required: true })
  filterDeliveryCenterId!: number;

  @Watch("filterAgency", { deep: true })
  filterAgencyChange(to, from) {
    this.getDeployments();
  }

  @Watch("filterDeliveryCenter", { deep: true })
  filterDeliveryCenterChange(to, from) {
    this.activeCurrentPage = 1;
    this.getDeployments();
  }

  loading = false;
  activeDeployments: any = [];
  completeDeployments: any = [];
  activeTotal = 0;
  activeTotalPages = 0;
  activeCurrentPage = 1;
  completeTotal = 0;
  completeTotalPages = 0;
  completeCurrentPage = 1;
  userRole = sessionStorage.getItem("userRole");

  async created() {
    await this.getDeployments();
  }

  async getDeployments(type = "active,complete") {
    try {
      this.loading = true;
      // grid view
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "DeploymentsGrid",
        // maxRows: sessionStorage.getItem("maxRows") || getMaxRows()
        customFilters: [
          {
            id: this.filterAgencyId,
            value: this.filterAgency
          },
          {
            id: this.filterDeliveryCenterId,
            value: this.filterDeliveryCenter
          },
        ],
        activeCurrentPage: this.activeCurrentPage,
        completeCurrentPage: this.completeCurrentPage,
        type
      });

      if (response.data.STATUS == 1) {
        // active
        if (type.split(",").includes("active")) {
          this.activeDeployments = (response.data.activeDeployments || []).map((item) => {
            item.AGENCY = item[`CUSTOMDATA${this.filterAgencyId}`] || '';
            item.DELIVERYCENTER = item[`CUSTOMDATA${this.filterDeliveryCenterId}`] || '';
            const arr: any = [];
            if (item.AGENCY) {
              arr.push(item.AGENCY);
            }
            if (item.DELIVERYCENTER) {
              arr.push(item.DELIVERYCENTER);
            }
            item.SUMMARY = arr.join(', ');
            return item;
          });
          this.activeTotal = response.data.activeTotal || 0;
          this.activeTotalPages = response.data.activeTotalPages || 0;
          this.activeCurrentPage = response.data.activeCurrentPage || 1;
        }
        // complete
        if (type.split(",").includes("complete")) {
          this.completeDeployments = (response.data.completeDeployments || []).map((item) => {
            item.AGENCY = item[`CUSTOMDATA${this.filterAgencyId}`] || '';
            item.DELIVERYCENTER = item[`CUSTOMDATA${this.filterDeliveryCenter}`] || '';
            const arr: any = [];
            if (item.AGENCY) {
              arr.push(item.AGENCY);
            }
            if (item.DELIVERYCENTER) {
              arr.push(item.DELIVERYCENTER);
            }
            item.SUMMARY = arr.join(', ');
            return item;
          });
          this.completeTotal = response.data.completeTotal || 0;
          this.completeTotalPages = response.data.completeTotalPages || 0;
          this.completeCurrentPage = response.data.completeCurrentPage || 1;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async loadPageActive(page: number, type: "prev" | "next" | "") {
    if (page <= 0 || page > this.activeTotalPages) return;
    if (type === "prev") {
      this.activeCurrentPage = this.activeCurrentPage - 1;
    } else if (type === "next") {
      this.activeCurrentPage = this.activeCurrentPage + 1;
    } else {
      this.activeCurrentPage = page;
    }

    if (
      this.activeCurrentPage <= this.activeTotalPages &&
      this.activeCurrentPage >= 1
    ) {
      await this.getDeployments("active");
    }
  }

  async loadPageComplete(page: number, type: "prev" | "next" | "") {
    if (page <= 0 || page > this.completeTotalPages) return;
    if (type === "prev") {
      this.completeCurrentPage = this.completeCurrentPage - 1;
    } else if (type === "next") {
      this.completeCurrentPage = this.completeCurrentPage + 1;
    } else {
      this.completeCurrentPage = page;
    }

    if (
      this.completeCurrentPage <= this.completeTotalPages &&
      this.completeCurrentPage >= 1
    ) {
      await this.getDeployments("complete");
    }
  }
}
