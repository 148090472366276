













import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {}
})

export default class GlobalPortal extends TSXComponent<void> {

  async created() {
    // console.log('created');
  }
}
