import { render, staticRenderFns } from "./ListHeaderActions.vue?vue&type=template&id=8004a488&scoped=true"
import script from "./ListHeaderActions.vue?vue&type=script&lang=tsx"
export * from "./ListHeaderActions.vue?vue&type=script&lang=tsx"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8004a488",
  null
  
)

export default component.exports