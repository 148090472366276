















import {Component as TSXComponent} from "vue-tsx-support";
import {Component, Prop} from "vue-property-decorator";

interface Props {
  linked: boolean;
}

interface Events {
}

@Component({
  inheritAttrs: true,
  components: {}
})
export default class HeaderTabs extends TSXComponent<Props, Events> {
  @Prop({required: true, default: []})
  links!: any[];

  @Prop({required: true, default: ''})
  tab!: string;
}
