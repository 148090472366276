



































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import {
  notifier,
} from "../models/common";
import { colsResizeable, colsResizeableReset, getRangeFilter, getDateRangeFilter } from "@/helpers/ApiHelper";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import PageTitle from "../components/pageTitle.vue";
import LaddaButton from "../components/LaddaButton.vue";

export enum SortField {
  Status = 1,
  EffectiveDate = 2,
  ExpirationDate = 3,
  SKU = 4,
  Description = 5,
  ListPrice = 6,
  ContractPrice = 7,
  Category = 8,
  AvailableQuantity = 9,
  ID = 10
}

export type Sort = {
  field: SortField
  direction: {
    [SortField.Status]: 1 | 2,
    [SortField.EffectiveDate]: 1 | 2,
    [SortField.ExpirationDate]: 1 | 2,
    [SortField.SKU]: 1 | 2,
    [SortField.Description]: 1 | 2,
    [SortField.ContractPrice]: 1 | 2,
    [SortField.ListPrice]: 1 | 2,
    [SortField.Category]: 1 | 2,
    [SortField.AvailableQuantity]: 1 | 2,
    [SortField.ID]: 1 | 2,
  }
}

@Component({
  inheritAttrs: false,
  components: {
    PageTitle,
    LaddaButton,
    ListHeaderActions,
    Pagination,
    FilterCurrencyBox
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class PricingListDetails extends TSXComponent<void> {
  loading = false;
  viewType = "CurrentUsers";
  currentRole = sessionStorage.getItem("userRole");
  priceListId = 0;
  priceListName = "";
  pageNumber = 1;
  details: any = {};
  priceDetailList: any[] = [];
  selectedAll = false;
  excludedIDs: string[] = [];
  selectedID: string[] = [];

  filters: any = {
    status: '',
    effectiveDate: {
      sDate: '',
      eDate: ''
    },
    expirationDate: {
      sDate: '',
      eDate: ''
    },
    sku: '',
    description: '',
    category: '',
    listPriceMin: '',
    listPriceMax: '',
    contractPriceMin: '',
    contractPriceMax: '',
    quantityMin: '',
    quantityMax: '',
  };

  searchFilters: any = {
    status: '',
    effectiveDate: {
      sDate: '',
      eDate: ''
    },
    expirationDate: {
      sDate: '',
      eDate: ''
    },
    sku: '',
    description: '',
    category: '',
    listPriceMin: '',
    listPriceMax: '',
    contractPriceMin: '',
    contractPriceMax: '',
    quantityMin: '',
    quantityMax: '',
  }

  sort: Sort = {
    field: SortField.ID,
    direction: {
      [SortField.Status]: 1,
      [SortField.EffectiveDate]: 1,
      [SortField.ExpirationDate]: 1,
      [SortField.SKU]: 1,
      [SortField.Description]: 1,
      [SortField.ListPrice]: 1,
      [SortField.ContractPrice]: 1,
      [SortField.Category]: 1,
      [SortField.AvailableQuantity]: 1,
      [SortField.ID]: 2,
    }
  };

  pageHeaders = {
    STATUS: "Status",
    EFFECTIVEDATE: "Effective Date",
    EXPIRATIONDATE: "Expiration Date",
    SKU: "SKU",
    DESCRIPTION: "Description",
    LISTPRICE: "List Price",
    CONTRACTPRICE: "Contract Price",
    CATEGORY: "Category",
    AVAILIBLEQTY: "Availible Qty"
  };

  headerOrder: any = [
    "STATUS",
    "EFFECTIVEDATE",
    "EXPIRATIONDATE",
    "SKU",
    "DESCRIPTION",
    "LISTPRICE",
    "CONTRACTPRICE",
    "CATEGORY",
    "AVAILIBLEQTY"
  ];

  selectedHeaders: any = [
    "STATUS",
    "EFFECTIVEDATE",
    "EXPIRATIONDATE",
    "SKU",
    "DESCRIPTION",
    "LISTPRICE",
    "CONTRACTPRICE",
    "CATEGORY",
    "AVAILIBLEQTY"
  ];


  async created() {
    this.priceListId = this.$route.params.id ? parseInt(this.$route.params.id) : 0;

    if (this.$route.query && this.$route.query.name) {
      this.priceListName = this.$route.query.name.toString();
    }

    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const filter = {};

      if (this.filters.status) {
        filter['status'] = this.filters.status.trim();
      }

      if (this.filters.effectiveDate.sDate) {
        filter['effectiveDateStart'] = this.filters.effectiveDate.sDate;
      }

      if (this.filters.effectiveDate.eDate) {
        filter['effectiveDateEnd'] = this.filters.effectiveDate.eDate;
      }

      if (this.filters.expirationDate.sDate) {
        filter['expirationDateStart'] = this.filters.expirationDate.sDate;
      }

      if (this.filters.expirationDate.eDate) {
        filter['expirationDateEnd'] = this.filters.expirationDate.eDate;
      }

      if (this.filters.sku) {
        filter['sku'] = this.filters.sku.trim();
      }

      if (this.filters.description) {
        filter['description'] = this.filters.description.trim();
      }

      if (this.filters.category) {
        filter['category'] = this.filters.category.trim();
      }

      if (this.filters.listPriceMin) {
        filter['listPriceMin'] = this.filters.listPriceMin.trim();
      }

      if (this.filters.listPriceMax) {
        filter['listPriceMax'] = this.filters.listPriceMax.trim();
      }

      if (this.filters.contractPriceMin) {
        filter['contractPriceMin'] = this.filters.contractPriceMin.trim();
      }

      if (this.filters.contractPriceMax) {
        filter['contractPriceMax'] = this.filters.contractPriceMax.trim();
      }

      if (this.filters.quantityMin) {
        filter['quantityMin'] = this.filters.quantityMin.trim();
      }

      if (this.filters.quantityMax) {
        filter['quantityMax'] = this.filters.quantityMax.trim();
      }

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Quotes",
        FunctionName: "PricingListDetailsView",
        priceListID: this.priceListId,
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        ...filter,
        order: this.sort.field,
        direction: this.sort.direction[this.sort.field].toString()
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS != 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.priceDetailList = this.details.PRICINGDETAILLIST;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }

    // colsResizeable({ ms: 500 });
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (
      this.pageNumber <= this.details.TOTALPAGES &&
      this.pageNumber >= 1
    ) {
      await this.fetchData();
    }
  }

  async searchByStatus() {
    this.pageNumber = 1;

    if (this.searchFilters.status) {
      this.filters.status = this.searchFilters.status;
      await this.fetchData();
    }
  }

  async resetSearchByStatus(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.status = this.searchFilters.status = '';
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async sortingByStatus() {
    this.sortBy(SortField.Status);
  }

  async searchByEffDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.effectiveDate.sDate == undefined || this.searchFilters.effectiveDate.sDate == "") &&
      (this.searchFilters.effectiveDate.eDate == "" || this.searchFilters.effectiveDate.eDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      this.filters.effectiveDate.sDate = this.searchFilters.effectiveDate.sDate;
      this.filters.effectiveDate.eDate = this.searchFilters.effectiveDate.eDate;
      await this.fetchData();
    }
  }

  async resetSearchByEffDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.effectiveDate.sDate == undefined || this.searchFilters.effectiveDate.sDate == "") &&
      (this.searchFilters.effectiveDate.eDate == "" || this.searchFilters.effectiveDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.effectiveDate.sDate = this.searchFilters.effectiveDate.sDate = undefined;
      this.filters.effectiveDate.eDate = this.searchFilters.effectiveDate.eDate = undefined;

      await this.fetchData();
    }
  }

  async sortingByEffDate() {
    this.sortBy(SortField.EffectiveDate);
  }

  async searchByExpDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.expirationDate.sDate == undefined || this.searchFilters.expirationDate.sDate == "") &&
      (this.searchFilters.expirationDate.eDate == "" || this.searchFilters.expirationDate.eDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      this.filters.expirationDate.sDate = this.searchFilters.expirationDate.sDate;
      this.filters.expirationDate.eDate = this.searchFilters.expirationDate.eDate;
      await this.fetchData();
    }
  }

  async resetSearchByExpDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.expirationDate.sDate == undefined || this.searchFilters.expirationDate.sDate == "") &&
      (this.searchFilters.expirationDate.eDate == "" || this.searchFilters.expirationDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.expirationDate.sDate = this.searchFilters.expirationDate.sDate = undefined;
      this.filters.expirationDate.eDate = this.searchFilters.expirationDate.eDate = undefined;

      await this.fetchData();
    }
  }

  async sortingByExpDate() {
    this.sortBy(SortField.ExpirationDate);
  }

  async searchBySku() {
    this.pageNumber = 1;

    if (this.searchFilters.sku) {
      this.filters.sku = this.searchFilters.sku;
      await this.fetchData();
    }
  }

  async resetSearchBySku(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.sku = this.searchFilters.sku = '';
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async sortingBySku() {
    this.sortBy(SortField.SKU);
  }

  async searchByDescription() {
    this.pageNumber = 1;

    if (this.searchFilters.description) {
      this.filters.description = this.searchFilters.description;
      await this.fetchData();
    }
  }

  async resetSearchByDescription(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.description = this.searchFilters.description = '';
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async sortingByDescription() {
    this.sortBy(SortField.Description);
  }

  async searchByCategory() {
    this.pageNumber = 1;

    if (this.searchFilters.category) {
      this.filters.category = this.searchFilters.category;
      await this.fetchData();
    }
  }

  async resetSearchByCategory(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.category = this.searchFilters.category = '';
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async sortingByCategory() {
    this.sortBy(SortField.Category);
  }

  async searchByListPrice() {
    this.pageNumber = 1;

    this.filters.listPriceMin = this.searchFilters.listPriceMin;
    this.filters.listPriceMax = this.searchFilters.listPriceMax;
    await this.fetchData();
  }

  async sortingByListPrice() {
    this.sortBy(SortField.ListPrice);
  }

  async searchByContractPrice() {
    this.pageNumber = 1;

    this.filters.contractPriceMin = this.searchFilters.contractPriceMin;
    this.filters.contractPriceMax = this.searchFilters.contractPriceMax;
    await this.fetchData();
  }

  async sortingByContractPrice() {
    this.sortBy(SortField.ContractPrice);
  }

  async searchByAvailableQuantity() {
    this.pageNumber = 1;

    this.filters.quantityMin = this.searchFilters.quantityMin;
    this.filters.quantityMax = this.searchFilters.quantityMax;
    await this.fetchData();
  }

  async sortingByAvailableQuantity() {
    this.sortBy(SortField.AvailableQuantity);
  }

  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async addHeader(c) {
    colsResizeableReset();
    const sortedHeaders: string[] = [];
    for (const item of this.headerOrder) {
      if (this.selectedHeaders.includes(item)) {
        sortedHeaders.push(item);
      }
    }
    this.selectedHeaders = sortedHeaders;

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "pricingListDetails"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    colsResizeable();
  }

  removeColumn(header) {
    this.selectedHeaders = this.selectedHeaders.filter(item => item != header);
    this.addHeader(header);
  }

  disabledHeader(x) {
    if (!this.selectedHeaders.includes(x) && this.selectedHeaders.length > 13) {
      // prevent selecting over 13 cols
      return true;
    }

    return false;
  }

  listPageRedirection() {
    this.$router.go(-1);
  }
}
