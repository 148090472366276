import { render, staticRenderFns } from "./ReportingStatusImport.vue?vue&type=template&id=6d50625c&scoped=true"
import script from "./ReportingStatusImport.vue?vue&type=script&lang=tsx"
export * from "./ReportingStatusImport.vue?vue&type=script&lang=tsx"
import style0 from "./ReportingStatusImport.vue?vue&type=style&index=0&id=6d50625c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d50625c",
  null
  
)

export default component.exports