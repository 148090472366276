
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CoolSelect } from 'vue-cool-select'
import { ApiHelper } from "@/helpers/all";

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    class?: string;
  }
}

interface Props {
  defaultText?: string
  placeholder?: string
  value: string
  sourceList: number
  searchFn: AutosuggestSearch
}

interface Events {
  onInput: string
}

export type Option = {
  value: string
  text: string
  label?: string // optional, if provided, will be used for suggested list item
  fundingsourceid?: number;
  data?: any;
}

export type AutosuggestSearch = (search: string) => Promise<{
  options: Option[],
}>

@Component({
  inheritAttrs: true,
  components: {
    CoolSelect,
  },
  inject: ['$validator'],
})
export default class AutoSuggestInput2 extends TSXComponent<
  Props,
  Events
> {

  @Prop({ required: false, type: String })
  placeholder?: string

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : 0 })
  sourceList?: number

   @Prop({ required: false, default : false })
  showmore?: boolean

  @Prop({ required: false, default : false })
  disabled?: boolean

  @Prop({ required: false, type: String, default: "customers" })
  field?: string

  @Prop({ required: false, default : false })
  showAll?: boolean

  @Prop({ required: false, default : false })
  isSku?: boolean

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  @Prop({ required: true, type: Function })
  searchFn!: AutosuggestSearch

  options: Option[] = []
  search = ''
  loading = false
  moreUsers = 0
  timeoutId:any = 0
  randomId = new Date().getTime() + ApiHelper.getRandomNumber(100000, 999999);

  created() {
    const randomId = this.randomId;
    $(document).on('keypress', `input.skuInput.sku${randomId}`, (e) => {
      const input = String.fromCharCode(e.keyCode);
      if (/[a-zA-Z0-9]/.test(input)) {
        console.log('input was a letter, number', e.keyCode);
      } else {
        return false;
      }
    });
  }

  mounted() {
    const refSelect: any = this.$refs.select;
    refSelect.setSearchData(this.defaultText);
    this.search = this.defaultText || '';
  }

  get infoMsg(): string {
    if (this.search.length === 0) {
      return "Type in the field above to refine your search"
    }

    if (this.search.length === 1) {
      return "Please type at least 2 characters to search"
    }

    if (this.options.length === 0) {
      return "No results found"
    }

    return ''
  }

  get renderOptions(): Option[] {
    // if (this.value && !this.options.find((option) => option.value === this.value)) {
    //   return [
    //     {
    //       value: this.value,
    //       text: this.defaultText || '',
    //       data: {}
    //     },
    //     ...this.options,
    //   ]
    // }
    return this.options.map((o) => ({...o}))
  }

  async localOnFocus() {
    if (!this.search) {
      await this.localOnSearch('');
    }
  }

  async localOnSearch(search: string) {
    this.search = search
    this.loading = true
    this.options = [];
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      const { options } = await this.searchFn(this.search)
      this.options = options
      if(options.length > 10 && !this.showAll){
        this.options = options.slice(0,10)
        this.moreUsers = options.length - 10;
      }

      this.setLoading(false)
    }, 300)
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  localOnBlur() {
    const refSelect: any = this.$refs.select;
    refSelect.setSearchData(this.search);
    this.$emit('onBlur', {
      search: this.search,
      value: this.value
    });
  }

  localOnSelect(item) {
    this.search = item.value;
    this.$emit('onSelect', item);
  }

  @Emit('input')
  onInput(value: string) {
    return value
  }

  render() {
    return (
      <CoolSelect
        ref="select"
        value={this.value}
        items={this.renderOptions}
        item-value="value"
        item-text="text"
        filter={() => {
          return true // disabled filtering by match text
        }}
        inputForTextClass={this.isSku ? 'up skuInput sku'+this.randomId : ''}
        placeholder={this.placeholder}
        onInput={this.onInput}
        onSearch={this.localOnSearch}
        onFocus={() => this.localOnFocus()}
        onBlur={() => this.localOnBlur()}
        onSelect={(item) => this.localOnSelect(item)}
        menuItemsMaxHeight="178px"
        disabled = {this.disabled}

        scopedSlots={{
          item: ({item}) => (
            <div class="item" data-value={item.value || ""}>
              { item.label || item.text }
            </div>
          ),
          'before-items-fixed': () => (
            <div></div>
          ),
          'no-data': () => (
            this.loading ? (
              <div>Loading...</div>
            ) : (
              <div>No results</div>
            )
          ),
          'input-end': () => {
            <span class="icon-dropdown" />
          },
          'before-items': () => (
            <div></div>
          ),
          'after-items': () => (
            this.options.length > 9 && this.showmore && this.moreUsers > 0 ? (
              <div  class="itemtext">{this.moreUsers} more (narrow search to see more accounts)</div>
             ) : (
               this.options.length == 5 && this.showmore ? (<div class="itemtext">Top 5 {this.field} are listed, search to see more {this.field != "customers" ? this.field : "accounts"}</div>) : (this.options.length != 0 && this.search.length === 0 && this.sourceList !== 1? (<div class="itemtext">Top {this.field} are listed, search to see more {this.field != "customers" ? this.field : "accounts"}</div>) : <div></div>)
             )

          ),
        }}
      >
      </CoolSelect>
    );
  }
}
