




















































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import SecurityGroupRowTooltip, {
  Item
} from "../components/SecurityGroupRowTooltip.vue";

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    ConfirmRemoveItemModal,
    SecurityGroupRowTooltip
  }
})
export default class Activities extends TSXComponent<void> {
  selectedID = [];
  loading = false;
  details: any = {};
  items = [];
  pageNumber = 1;
  sort = {
    field: null,
    direction: {
      id: 1,
      details: 1,
      customer: 1,
      user: 1,
      date: 1
    }
  };
  directionField = "";
  filters: any = {
    id: "",
    customer: "",
    user: "",
    AccountTagID: [],
    selectedAccs: [],
    groupIDs: [],
    userID: [],
    details: "",
    sDate: undefined,
    eDate: undefined,
    gTagIDs: []
  };
  accountTags: any = [];
  allUsers: any = [];
  searchTagsTxt = "";
  searchAccTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  AccountTagID: number[] = [];
  userID: number[] = [];
  searchFilters: any = {
    id: "",
    customer: "",
    user: "",
    AccountTagID: [],
    selectedAccs: [],
    groupIDs: [],
    userID: [],
    details: "",
    sDate: undefined,
    eDate: undefined,
    gTagIDs: []
  };

  async created() {
    await this.fetchData(true);
    await this.accsLoad();
  }

  async fetchData(initialLoad: boolean = false) {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Queries",
        subsystem: "Helpers",
        FunctionName: "activities",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        id: this.filters.id,
        customer: this.filters.customer,
        details: this.filters.details,
        sDate: this.filters.sDate ? this.filters.sDate : "",
        eDate: this.filters.eDate ? this.filters.eDate : "",
        user: this.filters.user,
        selectedCustomer: this.filters.selectedAccs.toString(),
        selectedUser: this.filters.userID.toString(),
        order: this.sort.field ? this.sort.field : 0,
        selectedTagIDs: this.filters.AccountTagID.join(","),
        groupIDs: this.filters.groupIDs.join(","),
        gTagIDs: this.filters.gTagIDs.join(","),
        isfilterGlobalTag: this.filters.gTagIDs.length ? true : false,
        direction:
          this.directionField != ""
            ? this.sort.direction[this.directionField]
            : 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.items = this.details.ACTIVITIES;
      }
      if (
        this.searchTagsTxt == "" &&
        typeof this.details.activitiesAccountTags != "undefined"
      ) {
        this.accountTags = this.details.activitiesAccountTags;
      }
      this.allUsers = this.details.ACTIVITYUSERS;
      if (this.filters.userID.length == 0 && initialLoad) {
        this.filters.userID = this.allUsers.map(val => val.USERID);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  searchBy(field) {
    if (this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field];
    this.fetchData();
  }

  async searchByDate() {
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      this.pageNumber = 1;
      this.filters.sDate = this.searchFilters.sDate;
      this.filters.eDate = this.searchFilters.eDate;
      await this.fetchData();
    }
  }

  resetBy(field) {
    if (this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field] = "";
    this.fetchData();
  }

  async resetSearchByDate() {
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = this.searchFilters.sDate = undefined;
      this.filters.eDate = this.searchFilters.eDate = undefined;
      this.pageNumber = 1;
      await this.fetchData();
    }
  }

  updateValue(field) {
    if (this.searchFilters[field] == "") {
      this.pageNumber = 1;
      this.fetchData();
    }
  }

  buildActivitiesDetails(details) {
    var ret = details;
    if (Array.isArray(details)) {
      ret = details.join(". ");
    }

    return ret;
  }

  getActivityType(typeID) {
    var ret = "";
    if ([1, 2, 3].includes(typeID)) {
      ret = "order";
    } else if ([5, 6, 7].includes(typeID)) {
      ret = "quote";
    } else if ([9, 10, 11, 12].includes(typeID)) {
      ret = "account";
    } else if ([13, 14, 15].includes(typeID)) {
      ret = "invoice";
    } else if ([18, 19].includes(typeID)) {
      ret = "paycheck";
    } else if ([20].includes(typeID)) {
      ret = "user_login";
    } else if ([21, 22, 23].includes(typeID)) {
      ret = "support";
    }

    return ret;
  }
  // Customer & User filter
  toggleActive(div) {
    this.isActive = div;
  }

  // Filter by Accounts
  async filterByAcc() {
    this.resetSelected();
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc() {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.pageNumber = 1;
    await this.fetchData();
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async accsLoad() {
    if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          isOrder: 1,
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }

  //filter by Tags
  async filterByTag() {
    this.resetSelected();
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllTag() {
    this.resetSelected();
    var $this = this;
    this.filters.AccountTagID = []; 
    this.accountTags.forEach(function (val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == 'undefined') {
        if (typeof val.ISGLOBALTAG == 'undefined') {
					if($this.filters.AccountTagID != undefined) {
						$this.filters.AccountTagID.push(val.ACCOUNTTAGID);
					}
				}
				else {
					$this.filters.gTagIDs.push(val.ACCOUNTTAGID);
				}
      }
    });
    this.pageNumber = 1;
    await this.fetchData();
  }

  async checkAllGroupTag() {
    this.resetSelected();
    var $this = this;
    this.filters.groupIDs = [];
    this.accountTags.forEach(function (val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
        $this.filters.groupIDs.push(val.ACCOUNTTAGID);
      }
    });
    this.pageNumber = 1;
    await this.fetchData();
  }

  async resetTag() {
    this.resetSelected();
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    this.pageNumber = 1;
    await this.fetchData();
  }
  filterTagsInBox() {
    let options = this.details["activitiesAccountTags"];

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }

  //filter by User
  async filterByUser() {
    this.resetSelected();
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllUser() {
    this.resetSelected();
    this.filters.userID = this.allUsers.map(val => val.USERID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async uncheckAllUser() {
    this.resetSelected();
    this.filters.userID = [-1];
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetUser() {
    // this.resetSelected();
    // this.filters.userID = [];
    // this.pageNumber = 1;
    // await this.fetchData();
    await this.checkAllUser();
  }

  resetSelected() {
    this.selectedID = [];
  }
}
