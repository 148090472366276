

































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import moment from "moment";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination
  }
})
export default class Logs extends TSXComponent<void> {
  $root: any;
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  filters: any = {
    //
  };
  sort: any = {
    field: null,
    direction: {
      // test: 1
    }
  };
  items: any = [];
  view = 1;
  pageNumber = 1;
  totalPages = 1;

  async created() {
    const query: any = this.$route.query;
    this.view = parseInt(query.view) || 1;
    await this.fetchData(true);
  }

  async fetchData(initialLoad: boolean = false) {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Logs",
        FunctionName: "List",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        PageNumber: this.pageNumber
      });
      if (response.data.STATUS == 1) {
        this.items = response.data.ITEMS || [];
        // this.pageNumber = parseInt(response.data.CURRENTPAGE) || 1;
        this.totalPages = response.data.TOTALPAGES || 1;
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  //go to search page
  // gotoSearch(searchData) {
  //   this.$router.push({
  //     path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
  //   });
  // }

  getCurrentViewTitle() {
    if (this.view == 1) {
      return "Robust";
    } else if (this.view == 2) {
      return "Informational";
    }

    return "";
  }

  async selectView(item, e) {
    //
  }

  // async clearFilter() {
  //   await this.fetchData();
  // }

  async loadPage(page: number) {
    if (page <= this.totalPages && page >= 1) {
      this.pageNumber = page;
      await this.fetchData();
    }
  }
}
