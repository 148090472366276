






































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import { ErrorBag } from "vee-validate";
import directives from "../helpers/directives";
import MultiSelect from "../components/MultiSelect.vue";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import PageTitle from '../components/pageTitle.vue';
import QuoteProductAutoSuggestInput from "@/components/Quote/QuoteProductAutoSuggestInput.vue";

const userRepo = new UserAPIRepo();

const findParent = (indexName: string, itemNames: Record<string, any>) => {
  const idx = parseInt(indexName.split("_")[1]);
  // find parent
  for (let i = idx - 1; i >= 1; i--) {
    const each = itemNames[i];

    if (parseInt(each.included) === 0) {
      return each;
    }
    if (!each) {
      continue;
    }
  }

  return null;
};

const findOtherIncludedItems = (
  indexName: string,
  itemNames: Record<string, any>
) => {
  const idx = parseInt(indexName.split("_")[1]);
  const items: any[] = [];
  // find parent
  for (let i = idx - 1; i >= 1; i--) {
    const each = itemNames[i];
    if (!each) {
      continue;
    }
    if (parseInt(each.included) === 0) {
      break;
    }

    items.push(each);
  }

  return items;
};

const findImmediateIncludedItems = (
  indexName: string,
  itemNames: Record<string, any>
) => {
  const idx = parseInt(indexName.split("_")[1]);
  var hasIncluded = false;
  if (Object.keys(itemNames).length != idx) {
    if (parseInt(itemNames[idx + 1].included)) {
      if (itemNames[idx + 1].quantity) {
        hasIncluded = true;
      }
    }
    if (parseInt(itemNames[idx].included) && !itemNames[idx].quantity) {
      for (let i = idx + 1; i <= Object.keys(itemNames).length; i++) {
        const each = itemNames[i];
        if (parseInt(each.included) === 1 && parseInt(each.quantity)) {
          const parent = findParent(indexName, itemNames);
          if (parent) {
            parent.priceWarn = true;
          }
        }
        if (parseInt(each.included) === 0) {
          break;
        }
      }
    }
    if (!parseInt(itemNames[idx].included)) {
      // const parent = findParent(indexName, itemNames);
    }
  }

  return hasIncluded;
};

@Component({
  inheritAttrs: false,
  components: {
    QuoteProductAutoSuggestInput,
    LaddaButton,
    MultiSelect,
    PageTitle
  },
  directives
})
export default class requestNewQuote extends TSXComponent<void> {
  title = "Request New Quote";
  zero = 0;
  itemNames: {
    sku: string;
    name: string;
    price: number;
    quantity: number;
    total: number;
    note: string;
    quoteLiId?: number;
    isDeleted?: number;
  }[] = [];
  details = {};
  customer = "";
  source = "HP Direct";
  sourceID = 1;
  aID = 0;
  length = length;
  arrowCounter = 0;
  profit = "$0.00";
  totalShipping = "$0.00";
  qShip = 0.0;
  totalTax = "$0.00";
  qTax = 0.0;
  taxVal = 0;
  estPercent = 0;
  grandTotal = "$0.00";
  itemTotal = "$0.00";
  quoteDate = "";
  quoteName = "";
  errorMsg = "";
  uploading = false;
  saving = false;
  updating = false;
  taxRateOn = 0;
  placeholderValue = "";
  autoName = 0;
  autoCost = 0;
  resellers: {
    ID?: number;
    TEXT?: string;
  }[] = [];
  loading = false;
  selectedResellers: (number | undefined)[] = [];
  quoteNote = "";
  customErrMsg = {};
  myAccounts: any = [];
  selectedAccounts: number[] = [];
  items = {
    quoteID: "[Auto Generate]",
    qDescription: "",
    qDateTime: "",
    aID: "",
    specnotes: "",
    qlines: "6",
    mdPurchaseNote: "",
    purchaseinfo: "",
    purchaseinfoModal: "",
    presetdropmenu: "",
    qSpecialPrice: "",
    Contract: "",
    qLocationID: "0",
    ItemDescription1: "",
    ItemNumber1: "",
    RegPrice1: "",
    ItemQuantity1: "",
    ItemTotal1: 0,
    ItemDescription2: "",
    ItemNumber2: "",
    RegPrice2: "",
    ItemQuantity2: "",
    ItemTotal2: 0,
    ItemDescription3: "",
    ItemNumber3: "",
    RegPrice3: "",
    ItemQuantity3: "",
    ItemTotal3: 0,
    ItemDescription4: "",
    ItemNumber4: "",
    RegPrice4: "",
    ItemQuantity4: "",
    ItemTotal4: 0,
    ItemDescription5: "",
    ItemNumber5: "",
    RegPrice5: "",
    ItemQuantity5: "",
    ItemTotal5: 0,
    ItemDescription6: "",
    ItemNumber6: "",
    RegPrice6: "",
    ItemQuantity6: "",
    ItemTotal6: 0,
    totalPrice: "0.00",
    accItems: [],
    suggestedProducts: []
  };
  reqQuoteID = 0;
  $isHideReseller: any;

  async created() {
    var length = 0;
    var itemName: {
      sku: string;
      name: string;
      quantity: number;
      price: number;
      total: number;
      note: string;
    }[] = [];
    var errors = [];
    if (
      typeof this.$route.params.qID == "undefined" ||
      parseInt(this.$route.params.qID) == 0
    ) {
      length = 3;

      for (let i = 0; i < 3; i++) {
        itemName[i] = {
          sku: "",
          name: "",
          quantity: 0,
          price: 0,
          total: 0,
          note: ""
        };
      }
    } else {
      await this.editQuote(this.$route.params.qID, this);
      this.reqQuoteID = parseInt(this.$route.params.qID);
    }
    this.itemNames = itemName;
    await this.initData();
    await this.grabRequestQuote();
  }

  async grabRequestQuote() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Accounts",
      FunctionName: "List"
    });
    try {
      this.items.accItems = response.data.ACCOUNTS;
    } catch (e) {
      //handle error
    }
  }

  editQuote(quote, container) {
    var comInstance = this;
    comInstance.loading = true;

    var dataObj = {
      controller: "Quotes",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: quote,
      inEditPage: true
    };

    if (quote != 0) {
      var quoteData = getRouteData(dataObj);
      quoteData.then(function(response, statusText, jqXHR) {
        try {
          var retData = response.data.QUOTEITEMS;
          comInstance.quoteName = response.data.QUOTENAME;
          comInstance.aID = response.data.QUOTEDETAILS.AID;
          comInstance.selectedResellers.push(response.data.ACCOUNT);
          comInstance.selectedAccounts.push(response.data.QUOTEDETAILS.AID);

          comInstance.quoteNote = response.data.QUOTEDETAILS.QNOTES;
          if (retData.length) {
            var totalProducts = retData.length;

            $.each(retData, function(i, val) {
              var quoteItem = {
                sku: val.QPRODSKU,
                name: val.QPRODDESC.replace(/[$|,]/g, ""),
                quantity: val.QPRODQUANTITY,
                total: val.TOTALPRICE,
                price: val.QPRICEREG,
                note: typeof val.LINENOTES != "undefined" ? val.LINENOTES : "",
                quoteLiId: val.QUOTELIID || 0
              };
              comInstance.itemNames.push(quoteItem);
            });

            comInstance.itemNames.push({
              sku: "",
              name: "",
              price: 0,
              quantity: 0,
              total: 0,
              note: "",
              quoteLiId: 0
            });
            comInstance.length = totalProducts + 1;
          } else {
            comInstance.length = 3;
            for (let i = 1; i <= 3; i++) {
              comInstance.itemNames["productName_" + i] = {
                name: "",
                price: 0.0, //"0.00",
                customerPrice: 0.0, //"0.00",
                margin: 0.0, //"0.00",
                marginRate: 0,
                total: 0.0, //"0.00"
                quantity: 0,
                tax: 0.0, //"0.00"
                taxRate: 0, //0
                shipping: 0.0, //"0.00"
                included: 0,
                priceWarn: false,
                totalProducts: "3",
                noTax: 0,
                configID: "",
                DistyPrice: 0,
                DistyAvailability: 0,
                DistyPrice_Format: "",
                DistyAvailability_Format: "",
                Disty: "",
                quoteLiId: 0
              };
            }
          }
          comInstance.loading = false;
        } catch (e) {
          //handle error
          comInstance.loading = false;
        }
      });
    } else {
      container.items = { QDESCRIPTION: "" };
    }
  }

  totalcalFn() {
    const ItemTotal1 =
      this.getNumber(this.items.RegPrice1, 0) *
      this.getNumber(this.items.ItemQuantity1, 0);
    const ItemTotal2 =
      this.getNumber(this.items.RegPrice2, 0) *
      this.getNumber(this.items.ItemQuantity2, 0);
    const ItemTotal3 =
      this.getNumber(this.items.RegPrice3, 0) *
      this.getNumber(this.items.ItemQuantity3, 0);
    const ItemTotal4 =
      this.getNumber(this.items.RegPrice4, 0) *
      this.getNumber(this.items.ItemQuantity4, 0);
    const ItemTotal5 =
      this.getNumber(this.items.RegPrice5, 0) *
      this.getNumber(this.items.ItemQuantity5, 0);
    const ItemTotal6 =
      this.getNumber(this.items.RegPrice6, 0) *
      this.getNumber(this.items.ItemQuantity6, 0);
    const totalPrice =
      ItemTotal1 +
      ItemTotal2 +
      ItemTotal3 +
      ItemTotal4 +
      ItemTotal5 +
      ItemTotal6;
    this.items["ItemTotal1"] = ItemTotal1 > 0 ? ItemTotal1 : 0;
    this.items["ItemTotal2"] = ItemTotal2 > 0 ? ItemTotal2 : 0;
    this.items["ItemTotal3"] = ItemTotal3 > 0 ? ItemTotal3 : 0;
    this.items["ItemTotal4"] = ItemTotal4 > 0 ? ItemTotal4 : 0;
    this.items["ItemTotal5"] = ItemTotal5 > 0 ? ItemTotal5 : 0;
    this.items["ItemTotal6"] = ItemTotal6 > 0 ? ItemTotal6 : 0;
    this.items["totalPrice"] = totalPrice > 0 ? totalPrice.toFixed(2) : "";
  }
  getNumber(number, defaultNumber) {
    return isNaN(parseInt(number, 10)) ? defaultNumber : parseInt(number, 10);
  }
  async saveFn(data) {
    const saveData = data;
    saveData["Controller"] = "Quotes";
    saveData["FunctionName"] = "Add";
    saveData["qlines"] = "6";
    saveData["aID"] = this.items.aID;
    saveData["ItemTotal1"] = this.items.ItemTotal1;
    saveData["ItemTotal2"] = this.items.ItemTotal2;
    saveData["ItemTotal3"] = this.items.ItemTotal3;
    saveData["ItemTotal4"] = this.items.ItemTotal4;
    saveData["ItemTotal5"] = this.items.ItemTotal5;
    saveData["ItemTotal6"] = this.items.ItemTotal6;
    const response = await axios.post(dataURL + "?ReturnType=JSON", saveData);
    try {
      if (response.data.STATUS && response.data.STATUSMESSAGE) {
        this.items = response.data.QUOTES || [];
        this.details = response.data || {};

        this.$router.push({ name: "Quotes" });
      }
    } catch (e) {
      //handle error
    }
  }
  async initData() {
    try {
      var comInstance = this;
      this.loading = true;
      const response = await userRepo.getResellers();
      response.RESELLERS.forEach(function(val) {
        comInstance.resellers.push({
          ID: val.ACCOUNTID,
          TEXT: val.ACCOUNTNAME
        });
      });
      this.selectedResellers = this.resellers.length && this.$isHideReseller  ? [this.resellers[0].ID] : [];
      if (this.resellers.length == 1) {
        //auto select if just has 1 reseller
        this.selectedResellers = this.resellers.map(val => val.ID);
      }

      for (var val of response.myAccounts) {
        this.myAccounts.push({
          ID: val.AID,
          TEXT: val.ANAME
        });
        if (val.ISDEFAULT) {
          this.selectedAccounts = [val.AID];
          this.aID = val.AID;
        }
      }
      if (this.myAccounts.length == 1) {
        this.aID = this.myAccounts[0].ID;
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }
  listPageRedirection() {
    this.$router.push({ name: "Quotes" });
  }
  addRow(ind) {
    this.length = this.length + 1;
    var item = {
      sku: "",
      name: "",
      quantity: 0,
      total: 0,
      price: 0,
      note: "",
      quoteLiId: 0
    };
    // this.$set(this.itemNames, ind, item);
    this.itemNames.push(item);
    setTimeout(() => {
      $("html, body").animate(
        { scrollTop: $("html, body").get(0).scrollHeight },
        200
      );
    }, 100);
  }
  addDelete(index) {
    $(".list-item[data-index='" + index + "'] .deleteIcon")
      .removeClass("displayNone")
      .css("display", "inline-block");
    // if (index) {
    //get max index
    var indArr: number[] = [];
    var maxInd = 0;
    $.each(this.itemNames, function(i, val) {
      indArr.push(i);
    });
    if (indArr.length) {
      maxInd = Math.max.apply(Math, indArr);
    }
    if (index == maxInd) {
      this.addRow(maxInd + 1);
    }
    if (this.htmlCheck(this.itemNames[index].note)) {
      this.itemNames[index].note = htmlParse(this.itemNames[index].note);
      notifier.alert("HTML content found. This content has been removed!");
    }
    // }
  }
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  htmlCheck(InputVal) {
    if (InputVal.length > htmlParse(InputVal).length) {
      return true;
    } else {
      return false;
    }
  }
  checkHTML() {
    var result = false;
    var htmlCount = 0;
    var self = this;
    $(".validateHtml").each(function(this: Window) {
      if ($(this).val() != "" && $(this).attr("id") == "quoteName") {
        if (self.htmlCheck($(this).val())) {
          self.quoteName = htmlParse($(this).val());
          htmlCount++;
        }
      } else {
        if (self.htmlCheck($(this).val())) {
          self.quoteNote = htmlParse($(this).val());
          htmlCount++;
        }
      }
    });
    // if(self.htmlCheck(self.quoteNote)){
    //   self.quoteNote = htmlParse(self.quoteNote);
    //   htmlCount++;
    // }
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
      result = false;
    } else {
      result = true;
    }
    return result;
  }
  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$validator.errors.clear();
    this.$validator.validateAll().then(async result => {
      var resultLineItem = true;

      //make sure at least a reseller is selected
      if (!this.selectedResellers.length) {
        result = false;
        this.$validator.errors.add({
          field: "reseller",
          msg: "Select at least a reseller"
        });
      }

      //make sure selected an account
      if (this.aID == 0) {
        result = false;
        this.$validator.errors.add({
          field: "account",
          msg: "Please select an account"
        });
      }

      //validate data input
      if (
        $("#sku_productName_1").val() == "" ||
        $("#name_productName_1").val() == "" ||
        parseInt($("#quantity_productName_1").val()) < 1 ||
        parseFloat($("#cust_cost_productName_1").val()) < 1
      ) {
        result = false;
        resultLineItem = false;
      }


      let total = 0;
      //qty if inputed for a line, should > 0
      for (var i in this.itemNames) {
        if (this.itemNames[i].sku) {
          total++;
        }
        if (
          (this.itemNames[i].sku != "" ||
            this.itemNames[i].name != "" ||
            this.itemNames[i].note != "") &&
          (this.itemNames[i].quantity < 1 || this.itemNames[i].quantity == 0)
        ) {
          result = false;
          resultLineItem = false;
          this.$validator.errors.add({
            field: "quantity_" + i,
            msg: "invalid number"
          });
        }
      }

      if (total == 0 && result) {
        result = false;
        notifier.alert('Please enter the product info');
      }

      if (!resultLineItem) {
        $("#errorMsgLineQuote").html(
          "Let's enter enough info about SKU/Product, Quantity"
        );
      } else {
        $("#errorMsgLineQuote").html("");
      }
      if (!this.checkHTML()) {
        result = false;
      }

      if (result) {
        this.saving = true;
        await this.createQuote();
        this.saving = false;
      }
    });
  }
  isDeleting = false;
  removeItem(index) {
    this.isDeleting = true;
    const item = this.itemNames[index];
    // $(".list-item[data-index='" + index + "']").remove();
    this.$delete(this.itemNames, index);
    if (this.length > 0) {
      //update length
      this.length -= 1;
    }

    setTimeout(() => {
      this.isDeleting = false;
    }, 1);


    // if (this.length == 1) {
    //   $(".deleteIcon").css("display", "none");
    // }


    // if (item.included === 1) {
    //   const includedItems = findOtherIncludedItems(index, this.itemNames);
    //   if (includedItems.length === 0) {
    //     const parent = findParent(index, this.itemNames);
    //     if (parent) {
    //       parent.priceWarn = false;
    //     }
    //   }
    // }
  }
  removeHtml(index, name) {
    if (this.htmlCheck(name)) {
      this.itemNames[index].name = htmlParse(name);
    }
  }
  async createQuote() {
    var comInstance = this;
    var today = new Date();
    var quoteUpdateID = 0;
    if (
      typeof this.$route.params.qID != "undefined" &&
      parseInt(this.$route.params.qID) > 0
    ) {
      quoteUpdateID = parseInt(this.$route.params.qID);
    }
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var saveData = {};

    saveData["Controller"] = "Quotes";
    saveData["FunctionName"] = "Add";
    saveData["requestNewQuote"] = 1;
    if (quoteUpdateID) {
      saveData["quoteID"] = quoteUpdateID;
      saveData["update"] = true;
    } else {
      saveData["quoteID"] = "[Auto Generate]";
    }
    saveData["aID"] = this.aID;
    saveData["qDateTime"] = date;
    saveData["qDescription"] = this.quoteName;
    saveData["totalPrice"] = "$ " + this.grandTotal;
    saveData["source"] = this.sourceID;
    saveData["resellers"] = this.selectedResellers;
    saveData["qNote"] = this.quoteNote;
    let hasSku = false;
    $.each(this.itemNames, function(i, val) {
      const ind = i;
      const sku = (comInstance.itemNames[ind].sku || '').toUpperCase();
      if (sku) {
        hasSku = true;
      }
      saveData[`ItemDescription${ind}`] = comInstance.itemNames[ind].name;
      saveData[`ItemNumber${ind}`] = sku;
      saveData[`ItemQuantity${ind}`] =
        comInstance.itemNames[ind].quantity > 0
          ? comInstance.itemNames[ind].quantity.toString()
          : "";
      saveData[`RegPrice${ind}`] =
        comInstance.itemNames[ind].price > 0
          ? comInstance.itemNames[ind].price.toString()
          : "";
      saveData[`ItemTotal${ind}`] =
        comInstance.itemNames[ind].total > 0
          ? comInstance.itemNames[ind].total.toString()
          : "";
      saveData[`Note${ind}`] = comInstance.itemNames[ind].note;
      saveData["currentComponent"] = "Direct";
      //------------
      saveData[`ItemCustomerPrice${ind}`] = saveData[`RegPrice${ind}`];
      saveData[`ItemMargin${ind}`] = 0;
      saveData[`ItemShipping${ind}`] = "";
      saveData[`ItemTaxRate${ind}`] = "";
      saveData[`ItemTax${ind}`] = "";
      saveData[`ItemNoTax${ind}`] = 0;
      saveData[`ItemIncluded${ind}`] = 0;
      saveData[`ItemQuoteLiId${ind}`] = comInstance.itemNames[ind].quoteLiId || 0;
    });

    try {
      // this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", saveData);
      if (response.data.STATUS && response.data.STATUS == 1) {
        this.$router.push({ name: "Quotes" });
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      // this.loading = false;
    }
  }
  toggleSelectedResellers(selectedID) {
    const index = this.selectedResellers.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedResellers.push(selectedID);
      return;
    }

    this.selectedResellers.splice(index, 1);
  }
  selectAllResellers() {
    var comInstance = this;
    this.selectedResellers = [];
    this.resellers.forEach(function(val) {
      comInstance.selectedResellers.push(val.ID);
    });
  }
  resetResellers() {
    this.selectedResellers = [];
  }
  SkuCleanup(index) {
    // var regex = /[^a-zA-Z0-9#?]/gi;
    // New update from amanda
    var regex = /[^a-zA-Z0-9#-]/gi;
    if (this.htmlCheck(this.itemNames[index].sku)) {
      this.itemNames[index].sku = htmlParse(this.itemNames[index].sku);
      notifier.alert("HTML content found. This content has been removed!");
    }
    if (this.htmlCheck(this.itemNames[index].name)) {
      this.itemNames[index].name = htmlParse(this.itemNames[index].name);
      notifier.alert("HTML content found. This content has been removed!");
    }
    this.itemNames[index].sku = this.itemNames[index].sku.replace(regex, "");
  }

  toggleSelectedAccounts(selectedID) {
    this.selectedAccounts = [selectedID];
    this.aID = selectedID;
  }
  backToDetails() {
    this.$router.push({
      name: "ViewQuote",
      params: { id: this.$route.params.qID }
    });
  }

  updateProduct(data, item) {
    if(data) {
      item.sku = data.PRODUCTSKU;
      if(data.PRODUCTNAME != ''){
        item.name = data.PRODUCTNAME;
      }
    }
  }
}
