











































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from 'axios'
import {notifier, downloadFile, printHtml, downloadFileUrl} from '../models/common'
import Pagination from '../components/Pagination.vue'

declare const $: any;
declare const dataURL: string;
declare const require: any;
declare const getRouteData: Function;
const template = require('../templates/supports/supportList.handlebars')

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
  }
})
export default class Supports extends TSXComponent<void> {
    selectedID: (number | undefined)[] = [];
    Status = 3;
    selectedAll = false;
    excludedIDs: (number | undefined)[] = [];
    loading = false;
    tableColumns  = [];
    statusCheckList = [1,2,0];
    details = {
        CURRENTPAGE: '1',
        TOTALPAGES: '1',
        TOTALALERTS: 1,
        sDate: "",
        eDate: "",
        searchId: "",
        searchName: "",
        username: "",
        Requester: "",
        TOTAL: "",
    };
    items: {
        TICKETID?: number,
    }[] = [];
    sort = {
        field: "",
        direction: ""
    };
    filters = {
        ticketId: "",
        accountname: "",
        username: "",
        sDate: "",
        eDate: "",
        status: "",
    };
    dataObj = {
        controller : "Supports",
        FunctionName : "list",
        maxRows: sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows(),
    };
    pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["Supports"] ? JSON.parse(sessionStorage.pageDetails)["Supports"] : 1 ;

    async created() {
        this.dataObj["PageNumber"] = this.pageNumber;
        await this.grabSupportsData(this,this.statusCheckList);
    }
    async grabSupportsData(container,status) {
        if(status == 3){
            status = [0,1,2]
        }
        this.tableColumns = [];
        this.statusCheckList = [1,2,0];
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
        const accountid = currentUser[0]['ACCOUNTID'];
        this.dataObj["accountid"] = accountid;
        this.dataObj["status"] = status.toString();
        await this.fetchData(this.dataObj, this, '', '', 'new');
    }
    async fetchData(dataObj, container, keyName, fieldname, option) {
        var self = this;
        self.loading = true;
        var resData = getRouteData(dataObj);
        //now attach a done handler!
        resData.then(function (response, statusText, jqXHR) {
            try {
                if (response.data.STATUS && !response.data.STATUSMESSAGE) {
                    self.items = response.data.SUPPORTTICKETLIST;
                    self.details = response.data;
                    if(typeof(dataObj["sDate"]) != 'undefined' && dataObj["sDate"] != "" && typeof(dataObj["eDate"]) != 'undefined' &&  dataObj["eDate"] != "") {
                        self.details.sDate = dataObj["sDate"];
                        self.details.eDate = dataObj["eDate"];
                    }
                    // self.details['CURRENTPAGE'] = 1;
                    // self.details['TOTALPAGES'] = 1;
                    self.details['TOTALALERTS'] = 1;
                    if (option === 'new') {
                        self.details['searchName'] = '';
                        self.details['userName'] = '';
                        self.details['sDate'] = '';
                        self.details['eDate'] = '';
                        self.details['searchId'] = '';
                        self.details['sortingId'] = 1;
                        self.details['sortingName'] = 1;
                        self.details['sortingRequester'] = 1;
                        self.details['sortingDate'] = 1;
                        self.details['sortingStatus'] = 1;
                    } else if (option !== 'page') {
                        self.details[keyName] = dataObj[fieldname];
                    }

                    if(dataObj["FunctionName"] == "list") {
                        self.sort = {
                            "field"     : (typeof dataObj["order"] != "undefined" ? dataObj["order"] : null),
                            "direction" : (typeof dataObj["direction"] != "undefined" ? dataObj["direction"] : null)
                        };
                        self.filters = {
                            "ticketId"      : (typeof dataObj["ticketId"] != "undefined" ? dataObj["ticketId"] : null),
                            "accountname"   : (typeof dataObj["accountname"] != "undefined" ? dataObj["accountname"] : null),
                            "username"      : (typeof dataObj["username"] != "undefined" ? dataObj["username"] : null),
                            "sDate"         : (typeof dataObj["sDate"] != "undefined" ? dataObj["sDate"] : null),
                            "eDate"         : (typeof dataObj["eDate"] != "undefined" ? dataObj["eDate"] : null),
                            "status"        : (typeof dataObj["status"] != "undefined" ? dataObj["status"] : null)
                        }
                    }
                    self.loading = false;
                } 
            }
            catch (e) {
                //handle error
            }
        }).catch(function (error) {
            // console.log(error);
        });
    }
    resetSelected() {
        this.selectedID = [];
        this.selectedAll = false;
        this.excludedIDs = [];
    }

    searchChange(searchData) {
        this.searchByAll(searchData);
    }
    updateDate(id,value) {
        if(value != '') {
          $('.selectAll.left.'+id).removeClass('disableCursor');
          $('.reset-list.right').removeClass('disableCursor');
        }
        else {
          $('.selectAll.left.'+id).addClass('disableCursor');
          $('.reset-list.right').addClass('disableCursor');
        }
    }
    updateValue(id,value) {
        if(value != '') {
          $('.search-list.right#'+id).removeAttr('style');
          $('.reset-list.right').removeClass('disableCursor');
        }
        else {
          $('.search-list.right#'+id).attr('style','cursor:not-allowed');
          $('.reset-list.right').addClass('disableCursor');
          if(id == 'searchId')
            this.resetSearchById();
          else if(id == 'searchName')
            this.resetSearchByName();
          else
            this.resetRequester();
        }
    }
    removeSelected() {
        var self = this;
        var statusValue = $('#name').val();
        //$(".actionsBtn").addClass('gray').removeClass('teal');
        if(this.selectedID.length){
            const dataObj = {
                "controller" : "Supports",
                "FunctionName" : "Update",
                "ticketId" : this.selectedID.toString(),
                "sStatus" : 0,
                "selectedAll" : this.selectedAll,
                "excludedIDs" : this.excludedIDs
            };
            var getData = getRouteData(dataObj);
            getData.then(function (response, statusText) {
                notifier.success(response.data.STATUSMESSAGE);
                self.grabSupportsData(self.$root,statusValue);
                self.selectedID = [];
                self.selectedAll = false;
                self.excludedIDs = [];
                // var arr = $('#supportTicketCount').attr('class').split(' ');
                // arr.splice(3,arr.length);
                // $('#supportTicketCount').attr('class',arr.join(' '));
                // $(".buttonContainer ul li.selectedCount").addClass('displayNone');
                // $(".actionsBtn .selectedCount").text("");
            });
        }
    }

    // selectallStatus() {
    //     this.selectedID = this.$root.details.supportIds;
    //     $('.selectedCount').text('('+this.selectedID.length+')');
    //     $('.actionsBtn').removeClass('gray').addClass('teal');
    // }

    // UnselectallStatus() {
    //     this.selectedID = [];
    //         $('.selectedCount').text('');
    //     $('.actionsBtn').addClass('gray').removeClass('teal');
    // }

    statusFliter(status) {
        this.selectedID = [];
        this.selectedAll = false;
        this.excludedIDs = [];
        this.grabSupportsData(this.$root,status);
    }
    createNewTicket() {
        this.$router.push({name: "SupportTicket"});
    }
    async printFn(selectedID, filters, sort) {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
        const accountid = currentUser[0]['ACCOUNTID'];
        const reqData = {
            controller: "Supports",
            FunctionName: "list",
            accountid: accountid,
            ExportType: "Print",
            Print:'yes',
            selectedAll: this.selectedAll,
            excludedIDs: this.excludedIDs
        };
        if(this.selectedAll) {
            reqData["selectedID"] = [];
        } 
        else {
            reqData["selectedID"] = selectedID;
        }
        if(filters.ticketId != null) {
            reqData["ticketId"] = filters.ticketId;
        }
        if(filters.accountname != null) {
            reqData["accountname"] = filters.accountname;
        }
        if(filters.username != null) {
            reqData["username"] = filters.username;
        }
        if(filters.sDate != null) {
            reqData["sDate"] = filters.sDate;
        }
        if(filters.eDate != null) {
            reqData["eDate"] = filters.eDate;
        }
        if(filters.status != null) {
            reqData["status"] = filters.status;
        }
        if(sort.field != null) {
            reqData["order"] = sort.field;
        }
        if(sort.direction != null) {
            reqData["direction"] = sort.direction;
        }

        const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

        if (response.data.ERROR) {
            throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
            throw new Error(response.data.STATUSMESSAGE);
        }
        var supportList = response.data;
        var html = template(supportList)
        printHtml(html);
    }

    selectAll(selectedAll = true) {
        this.selectedAll = selectedAll;
        this.selectedID = [];
        if(this.selectedAll == true) {
            for(var val of this.items) {
                if(!this.excludedIDs.includes(val.TICKETID)) {
                    this.selectedID.push(val.TICKETID);
                }
            }
        }else {//uncheck
            this.excludedIDs = [];
        }
    }

    getSelectedTotal(total) {
        var ret = 0;
        if(this.selectedAll) {
            ret = total - this.excludedIDs.length;
        }else {
            ret = this.selectedID.length;
        }
        return ret;
    }

    
    checkClosedSelection(items, key) {
        var ret = false;
        for(var val of items) {
            if(this.selectedID.includes(val[key])) {
                if(val.STATUSID == 1 || val.STATUSID == 2) {
                    ret = true;
                    return ret;
                }
            }
        }
        return ret;
    }
    getSelectionCount(items, key) {
        var ret = 0;
        for(var val of items) {
            if(this.selectedID.includes(val[key])) {
                if(val.STATUSID == 1 || val.STATUSID == 2) {
                    ret = ret + 1 ;
                }
            }
        }
        return ret;
    }

    checkExcludedIDs(items, key) {
        if(this.selectedAll) {
            for(var val of items) {
                if(!this.selectedID.includes(val[key])) {
                    if(!this.excludedIDs.includes(val[key])) {
                        this.excludedIDs.push(val[key]);
                    }
                }else if(this.excludedIDs.includes(val[key])) {
                    this.$delete(this.excludedIDs, this.excludedIDs.findIndex((id) => id == val[key]));
                }
            }

            if(this.getSelectedTotal(this.details.TOTAL) == 0) {
                this.selectedAll = false;
                this.excludedIDs = [];
            }
        }
    }
    loadNextpage(pageNumber) {
        this.dataObj["pageNumber"] = pageNumber;
        this.dataObj["ticketId"] = this.details['searchById'];
        this.dataObj["accountname"] = this.details['searchByName'];
        this.dataObj["userName"] = this.details['userName'];
        this.dataObj["sDate"] = this.details['sDate'];
        this.dataObj["eDate"] = this.details['eDate'];
        this.fetchData(this.dataObj, this, '', '', 'new');
    }
    loadPage(pageNumber, type) {
        if(type == "prev"){
            pageNumber = parseInt(pageNumber) - 1;
        }
        else if(type == "next"){
            pageNumber = parseInt(pageNumber) + 1;
        }
        if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["Supports"]){
            var oldData = JSON.parse(sessionStorage.pageDetails);
            oldData["Supports"] = pageNumber;
            sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
        }

        if(parseInt(pageNumber) <= parseInt(this.details.TOTALPAGES) && parseInt(pageNumber) >= 1){
            this.dataObj["pageNumber"] = pageNumber;
            this.fetchData(this.dataObj, this, '', '', 'page');
        }
    }
    searchById() {
        this.dataObj["ticketId"] = this.details.searchId;
        if(this.details.searchId != '' || this.details.searchId != undefined) {
            this.fetchData(this.dataObj, this, 'searchId', 'ticketId', '');
        }
    }
    searchByAll(searchData) {
        this.dataObj["sSearchAll"] = searchData;
        this.fetchData(this.dataObj, this, 'searchAll', 'sSearchAll', '');
    }
    searchByName() {
        this.dataObj["accountname"] = this.details.searchName;
        if(this.details.searchName != undefined || this.details.searchName !=  '') {
            this.fetchData(this.dataObj, this, 'searchName', 'accountname', '');
        }
    }
    Requester () {
        this.dataObj["username"] = this.details.username;
        if(this.details.username != undefined || this.details.username !=  '') {
            this.fetchData(this.dataObj, this, 'username', 'username', '');
        }
    }
    resetSearchByName() {
        $('.search-list').attr('style','cursor:not-allowed');
        $('.reset-list.right').addClass('disableCursor');
        this.dataObj["accountname"] = '';
        this.details.searchName = '';
        (document.getElementById('searchName') as HTMLInputElement).value = '';
        this.fetchData(this.dataObj, this, 'searchName', 'accountname', '');
    } 
    resetRequester() {
        $('.search-list').attr('style','cursor:not-allowed');
        $('.reset-list.right').addClass('disableCursor');
        this.dataObj["username"] = '';
        this.details.Requester = '';
        (document.getElementById('Requester') as HTMLInputElement).value = '';
        this.fetchData(this.dataObj, this, 'username', 'username', '');
    }
    searchByDate() {
        this.dataObj["sDate"] = this.details.sDate;
        this.dataObj["eDate"] = this.details.eDate;
        if((this.details.sDate == undefined || this.details.sDate ==  '') && (this.details.eDate == '' || this.details.eDate == undefined)) {
            // container.notification('alert','Please Fill From or To Date');
        }else {
            this.fetchData(this.dataObj, this, '', '', '');
        }
    }
    resetDate() {
        $('.selectAll.left.eDate').addClass('disableCursor');
        $('.reset-list.right').addClass('disableCursor');
        this.dataObj["sDate"] = '';
        this.dataObj["eDate"] = '';
        this.details.sDate = '';
        this.details.eDate = '';
        (document.getElementById('sDate') as HTMLInputElement).value = '';
        (document.getElementById('eDate') as HTMLInputElement).value = '';
        this.fetchData(this.dataObj, this, '', '', '');
    }
    resetSearchById() {
        $('.search-list').attr('style','cursor:not-allowed');
        $('.reset-list.right').addClass('disableCursor');
        this.dataObj["ticketId"] = '';
        (document.getElementById('searchId') as HTMLInputElement).value = '';
        this.details.searchId = '';
        this.fetchData(this.dataObj, this, 'searchId', 'ticketId', '');
    }

    sortingById() {
        this.dataObj["order"] = 0;
        this.dataObj["direction"] = parseInt(this.details['sortingId']) === 1 ? 2 : 1;
        this.fetchData(this.dataObj, this, 'sortingId', 'direction', '');
    }
    sortingByName() {
        this.dataObj["order"] = 1;
        this.dataObj["direction"] = parseInt(this.details['sortingName']) === 1 ? 2 : 1;
        this.fetchData(this.dataObj, this, 'sortingName', 'direction', '');
    }
    sortingByRequester() {
        this.dataObj["order"] = 2;
        this.dataObj["direction"] = parseInt(this.details['sortingRequester']) === 1 ? 2 : 1;
        this.fetchData(this.dataObj, this, 'sortingRequester', 'direction', '');
    }

    sortingByDate() {
        this.dataObj["order"] = 3;
        this.dataObj["direction"] = parseInt(this.details['sortingDate']) === 1 ? 2 : 1;
        this.fetchData(this.dataObj, this, 'sortingDate', 'direction', '');
    }
    sortingByStatus() {
        this.dataObj["order"] = 4;
        this.dataObj["direction"] = parseInt(this.details['sortingStatus']) === 1 ? 2 : 1;
        this.fetchData(this.dataObj, this, 'sortingStatus', 'direction', '');
    }
    checkStatusList(value) {
        var index = this.statusCheckList.indexOf(value);
        if (index < 0) {
            this.statusCheckList.push(value);
        } else {
            this.statusCheckList.splice(index, 1);
        }
        this.dataObj["status"] = this.statusCheckList.toString();
        this.fetchData(this.dataObj, this, 'statusCheckListStr', 'status', '');
    }

    checkAllStatusList() {
        this.statusCheckList = [1, 2, 0];
        this.dataObj["status"] = this.statusCheckList.toString();
        var items = (document.getElementsByName('statusCheckBox'));
        for (var i = 0; i < items.length; i++) {
            if ((items[i] as HTMLInputElement).type == 'checkbox')
                (items[i] as HTMLInputElement).checked = true;
        }
        this.fetchData(this.dataObj, this, 'statusCheckListStr', 'status', '');
    }

    uncheckAllStatusList() {
        this.statusCheckList = [];
        this.dataObj["status"] = this.statusCheckList.toString();
        var items = (document.getElementsByName('statusCheckBox'));
        for (var i = 0; i < items.length; i++) {
            if ((items[i] as HTMLInputElement).type == 'checkbox')
                (items[i] as HTMLInputElement).checked = false;
        }
        this.fetchData(this.dataObj, this, 'statusCheckListStr', 'status', '');
    }
    resetStatusList() {
        this.statusCheckList = []
        this.dataObj["status"] = this.statusCheckList.toString();
        var items = document.getElementsByName('statusCheckBox');
        for (var i = 0; i < items.length; i++) {
            if ((items[i] as HTMLInputElement).type == 'checkbox')
                (items[i] as HTMLInputElement).checked = false;
        }
        this.fetchData(this.dataObj, this, 'statusCheckListStr', 'status', '');
    }
    pdfDownLoadFn (selectedID, selectedAll, excludedIDs, filters, sort) {
        var exportObj = {
            controller: "Supports",
            FunctionName: "Export",
            uploadPath: "Inbound/apiFiles/",
            keyName: "Inbound/apiFiles/",
            ExportType: 'PDF',
            "userRole":sessionStorage.getItem('userRole'),
        };
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
        const accountid = currentUser[0]['ACCOUNTID'];
        exportObj["accountid"] = accountid;
        exportObj["selectedID"] = selectedID;
        exportObj["selectedAll"] = (typeof selectedAll != "undefined" ? selectedAll : false);
        exportObj["excludedIDs"] = (typeof excludedIDs != "undefined" ? excludedIDs : []);

        if(filters.ticketId != null) {
            exportObj["ticketId"] = filters.ticketId;
        }
        if(filters.accountname != null) {
            exportObj["accountname"] = filters.accountname;
        }
        if(filters.username != null) {
            exportObj["username"] = filters.username;
        }
        if(filters.sDate != null) {
            exportObj["sDate"] = filters.sDate;
        }
        if(filters.eDate != null) {
            exportObj["eDate"] = filters.eDate;
        }
        if(filters.status != null) {
            exportObj["status"] = filters.status;
        }
        if(sort.field != null) {
            exportObj["order"] = sort.field;
        }
        if(sort.direction != null) {
            exportObj["direction"] = sort.direction;
        }
        this.downloadPdfProcess(exportObj, this);
    }
    downloadPdfProcess(data, container) {
        var downLoadRes = getRouteData(data);
        downLoadRes.then(function (response, statusText, jqXHR) {
            try {
                if (response.data.STATUS) {
                    var link = document.createElement("a");
                    link.download = 'Supports';
                    link.target = "_blank";
                    link.href = response.data.S3URL;
                    link.click();
                } 
            }
            catch (e) {
                //handle error
            }
        });
    }

    @Watch("items")
    async itemsChanged() {
        if(this.selectedAll === true) {
            this.selectAll(true);
        }
    }
}
