





























































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from "../components/pageTitle.vue";
import SelectTagControl from "@/components/SelectTagControl.vue";
import HardwaresImportPreviewModal from "@/components/HardwaresImportPreviewModal.vue";
import { notifier } from "../models/common";
import axios from "axios";

@Component({
  inheritAttrs: false,
  components: {
    SelectTagControl,
    HardwaresImportPreviewModal,
    LaddaButton,
    PageTitle
  }
})
export default class HardwareImportNew extends TSXComponent<void> {

  csvColumnOptions: any = [];

  dbColumnOptions: any = [];

  formStep: number = 0;
  mappingCSVToDatabase: any = [];

  uploading: boolean = false;
  loadingPreview: boolean = false;
  loadingImport: boolean = false;

  csvFileName: string = '';
  csvRows: any[] = [];
  csvDBRows: any[] = [];

  confirmList: any[] = [];

  previewInsertedCount: number = 0;
  previewUpdatedCount: number = 0;
  previewSkippedCount: number = 0;

  importInsertedCount: number = 0;
  importUpdatedCount: number = 0;
  importSkippedCount: number = 0;
  importFailedCount: number = 0;
  importConfigurationsCount: number = 0;

  addToCustomerId = '';
  accountList: any = [];

  showHardwaresImportPreviewFlg = false;
  importHardwareIds: any = [];
  requiredHeader: any = ['SKU','Description','SerialNumber','PONumber'];
  allOrders: any[] = [];
  isCheckDynamicPurchaseID: boolean = false;

  get csvColumnCount() {
    const csvColumns = this.csvColumnOptions.filter((map) => map.ID);
    if (csvColumns.length > 0) {
      return csvColumns.length;
    }

    return 1;
  }

  async created() {
    this.getAccountList();
    this.refreshMappingRows();
  }

  initMappingRows() {
    this.mappingCSVToDatabase = [];
    for (let rowIdx = 0; rowIdx < 3; rowIdx++) {
      this.mappingCSVToDatabase.push({
        rowIndex: rowIdx,
        csvColumnId: '',
        dbColumnId: '',
        isRowKey: false
      })
    }
  }

  isDuplicatedMappingDBColumn(choiceDBColumn) {
    if (choiceDBColumn) {
      const findColumn = this.mappingCSVToDatabase.filter((map) => map.dbColumnId === choiceDBColumn && map.dbColumnId);
      if (findColumn.length > 1) {
        return true;
      }
    }

    return false;
  }

  refreshMappingRows(allowEmptyCSV = true, index = 0) {
    if (index == this.mappingCSVToDatabase.length) {
      let notEmptyRows = this.mappingCSVToDatabase;
      if (allowEmptyCSV) {
        notEmptyRows = this.mappingCSVToDatabase.filter((map) => map.csvColumnId);
      }
  
      // update row index
      notEmptyRows = notEmptyRows.map((row, index) => ({
        ...row,
        rowIndex: index
      }));
  
      let currentRowIdx = notEmptyRows.length;
      const maxAllowedRowCount = currentRowIdx + 1;
  
      for (let rowCount = notEmptyRows.length; rowCount < maxAllowedRowCount; rowCount++) {
        // append new empty row
        currentRowIdx++
        notEmptyRows.push({
          rowIndex: currentRowIdx,
          csvColumnId: '',
          dbColumnId: '',
          isRowKey: false
        })
      }
  
      this.mappingCSVToDatabase = notEmptyRows;
    }
  }

  async getAccountList() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Queries",
        FunctionName: "GetAllAccounts",
        subsystem: "Helpers"
      });

      if (response.data.ACCOUNTLIST) {
        const accountList = response.data.ACCOUNTLIST;

        this.accountList = accountList.map((account) => ({
          ID: account.AID,
          TEXT: account.ANAME
        }));
      }

    } catch (err) {
      console.log(err);
    }
  }

  chooseCsvColumn(columnId, rowIdx) {
    this.mappingCSVToDatabase[rowIdx].csvColumnId = columnId;

    this.refreshMappingRows(false, rowIdx+1);
  }

  chooseDBColumn(columnId, rowIdx) {
    this.mappingCSVToDatabase[rowIdx].dbColumnId = columnId;
  }

  chooseAccount(accountId, rowIdx) {
    this.addToCustomerId = accountId;
  }

  async saveHardwaresImportPreview(updateConfirmList) {
    this.showHardwaresImportPreviewFlg = false;
    this.formStep = 1;

    // reset import statistics
    this.importInsertedCount = 0;
    this.importUpdatedCount = 0;
    this.importSkippedCount = 0;
    this.importFailedCount = 0;
    this.importConfigurationsCount = 0;

    try {
      this.loadingPreview = true;

      let updatedRows;
      updatedRows = updateConfirmList.filter((udp) => ["PONumber", "VAR360 Order"].includes(udp.dbColumnId));
      if (updatedRows.length) {
        for (const dbRow of this.csvDBRows) {
          dbRow.skipFlg = updatedRows[0].skipFlg;
          if (this.isCheckDynamicPurchaseID && typeof updatedRows[0]["purchaseIDs"] != "undefined" && updatedRows[0]["purchaseIDs"].length && updatedRows[0].updateValue != 0) {
            dbRow["purchaseID"] = updatedRows[0].updateValue.toString();
          }
        }
      } else {
        for (const dbRow of this.csvDBRows) {
          updatedRows = updateConfirmList.filter((udp) => udp.lineNo === dbRow.lineNo);
          dbRow.skipFlg = 0;
          for (const updatedRow of updatedRows) {
            if (updatedRow.skipFlg === 0) {
              dbRow[updatedRow.dbColumnId] = updatedRow.updateValue.toString();
            } else {
              dbRow.skipFlg = 1;
            }
          }
        }
      }

      const validMappingCSVToDB = this.mappingCSVToDatabase.filter((map) => map.csvColumnId && map.dbColumnId);

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: 'Hardware',
        FunctionName: 'ImportHardwaresNew',
        stepName: 'Preview',
        csvFileName: this.csvFileName,
        accountId: this.addToCustomerId,
        mappingCSVToDatabase: validMappingCSVToDB,
        csvHeader: validMappingCSVToDB.map((map) => map.dbColumnId),
        csvKeyOnUpdate: validMappingCSVToDB.filter((fmap) => fmap.isRowKey).map((map) => map.dbColumnId),
        csvData: this.csvDBRows
      });

      console.log('preview response ', response);

      if (response.data.STATUS == 1) {
        this.previewInsertedCount = response.data.insertedCount;
        this.previewUpdatedCount = response.data.updatedCount;
        this.previewSkippedCount = response.data.skippedCount;
        this.formStep = 2;
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('preview error ', error);
    } finally {
      this.loadingPreview = false;
    }
  }

  showFileUpload() {
    $("#hardwareFileUpload").click();
  }

  async handleHardwareFileUpload(e) {
    e.stopPropagation();
    e.preventDefault();

    const fileName = e.target.files[0].name;
    const allowedExtensions = /(\.csv)$/i;
    if (!allowedExtensions.exec(fileName)) {
      notifier.alert("Please upload file having extensions .csv only.");
      return false;
    }

    try {
      this.uploading = true;

      const requestObj = {
        Controller: "Hardware",
        FunctionName: "ImportHardwaresNew",
        stepName: 'LoadCSVFile',
        filePath: e.target.files[0],
        fileName,
        fileType: 'csv'

      };

      const formData = new FormData();
      for (let key in requestObj) {
        formData.append(key, requestObj[key]);
      }

      formData.append("session", sessionStorage.getItem("sessionID") || "");
      const response: any = await axios.post(
        dataURL + "?ReturnType=JSON",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data"
          }
        }
      );

      if (response.data.STATUS == 1) {

        // show list column and data on screen
        const headers = response.data.headers;
        const rows = response.data.rows;
        const dbColumns = response.data.dbColumns;
        this.csvFileName = response.data.csvFileName
        this.allOrders = response.data.allOrders || [];

        headers.unshift('');

        this.csvColumnOptions = headers.map((header) => ({
          ID: header,
          TEXT: header
        }));

        this.dbColumnOptions = dbColumns.map((columnName) => ({
          ID: columnName,
          TEXT: columnName
        }));

        this.csvRows = rows.map((row, index) => ({
          lineNo: index+1,
          ...row
        }));

        if (dbColumns.length) {
          this.mappingCSVToDatabase = [];
          this.requiredHeader.map((requiredItem: any, index: number) => {
            let checkDuplicateHeader = this.mappingCSVToDatabase.filter((mappingItem: any) => mappingItem.dbColumnId == requiredItem);

            if (!checkDuplicateHeader.length) {
              this.mappingCSVToDatabase.push({
                rowIndex: index,
                csvColumnId: '',
                dbColumnId: requiredItem,
                isRowKey: false
              });
            }
          });
        }

        this.refreshMappingRows(false, this.mappingCSVToDatabase.length);
        this.formStep = 1; // Mapping CSV and DB
        // this.$refs.hardwareFileUpload.value = null;
        $("#hardwareFileUpload").val("");
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }

    } catch (error) {
      console.log('error ', error);
    } finally {
      this.uploading = false;
    }
  }

  async doPreview() {
    console.log('doPreview popup');

    // convert to db column
    const csvDB: any[] = [];
    const confirmList: any[] = [];
    const duplicatePOList: any[] = [];
    let mapRow: any = null;
    let dbColumnName = null;

    try {
      const valid = await this.$validator.validate('Customer');

      if (!valid) {
        return;
      }

      const validMappingCSVToDB = this.mappingCSVToDatabase.filter((map) => map.csvColumnId && map.dbColumnId);

      if (validMappingCSVToDB.length === 0) {
        notifier.alert('Please map the CSV data to the Var360 column');
        return;
      }

      const validRequiredCSVToDB = this.mappingCSVToDatabase.filter((map) => (!map.csvColumnId && map.dbColumnId) || (map.csvColumnId && !map.dbColumnId));

      if (validRequiredCSVToDB.length) {
        notifier.alert('Please fill the required fields');
        return;
      }

      const validRequiredHeader = this.mappingCSVToDatabase.filter((item: any) => this.requiredHeader.includes(item.dbColumnId));

      if (validRequiredHeader.length != this.requiredHeader.length) {
        let checkSKURequired = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'SKU');
        if (!checkSKURequired.length) {
          notifier.alert("SKU column is required");
          return;
        }
        let checkSerialNumberRequired = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'SerialNumber');
        if (!checkSerialNumberRequired.length) {
          notifier.alert("Serial Number column is required");
          return;
        }
        let checkPONumberRequired = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'PONumber');
        if (!checkPONumberRequired.length) {
          notifier.alert("PO column is required");
          return;
        }
        let checkDescriptionRequired = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'Description');
        if (!checkDescriptionRequired.length) {
          notifier.alert("Description is required");
          return;
        }
      }
      this.isCheckDynamicPurchaseID = false;
      let checkPurchaseIDExists = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'purchaseID');
      if (!checkPurchaseIDExists.length) {
        this.isCheckDynamicPurchaseID = true;
      }

      for (const item of this.csvRows) {
        mapRow = {lineNo: item.lineNo};
        for (const mapColumn of this.mappingCSVToDatabase) {
          dbColumnName = mapColumn.dbColumnId;
          const value = item[mapColumn.csvColumnId];

          if (dbColumnName) {
            if (dbColumnName === 'SKU') {
              if (value) {
                mapRow[dbColumnName] = value;
              } else {
                confirmList.push({
                    lineNo: item.lineNo,
                    csvColumnId: mapColumn.csvColumnId,
                    dbColumnId: dbColumnName,
                    currentValue: value,
                    updateValue: '',
                    generatedValue: `SKU${item.lineNo.toString().padStart(4, '0')}`,
                    updateOriginValue: '',
                    errorMessage: `SKU column is required`,
                    skipFlg: 1
                  });
              }
            } else if (dbColumnName === 'WarrantyExp') {
              if (value) {
                const dateExp = new Date(value);

                if (Number.isNaN(dateExp.valueOf())) {
                  mapRow[dbColumnName] = null;

                  confirmList.push({
                    lineNo: item.lineNo,
                    csvColumnId: mapColumn.csvColumnId,
                    dbColumnId: dbColumnName,
                    currentValue: value,
                    updateValue: '',
                    generatedValue: '',
                    updateOriginValue: null,
                    errorMessage: `Invalid date in the ${dbColumnName} column`,
                    skipFlg: 1
                  });

                } else {
                  mapRow[dbColumnName] = dateExp.toISOString().slice(0, 10);
                }
              } else {
                mapRow[dbColumnName] = null;
              }
            } else if (['purchaseID'].includes(dbColumnName)) {
              // 'PurchaseOrderLineId'
              if (isNaN(value) || !Number.isSafeInteger(+value)) {
                confirmList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: dbColumnName,
                  currentValue: value,
                  updateValue: '0',
                  generatedValue: '0',
                  updateOriginValue: '0',
                  errorMessage: `Only integer values are allowed in the ${dbColumnName} column`,
                  skipFlg: 1
                });
              } else {
                mapRow[dbColumnName] = value;
              }
            } else if (dbColumnName === 'Price') {
              const priceParts = value.toString().split('.');

              let priceNumberStr = '';
              for (let index = 0; index < priceParts.length; index++) {
                const partNumber = priceParts[index].replace(/[^\d]/g, '');

                if (index > 0 && index === priceParts.length - 1) {
                  priceNumberStr = `${priceNumberStr}.`; // append decimal point
                }

                priceNumberStr = `${priceNumberStr}${partNumber}`;
              }

              if (priceNumberStr) {
                mapRow[dbColumnName] = (+priceNumberStr).toString();
              } else {
                confirmList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: dbColumnName,
                  currentValue: value,
                  updateValue: '0',
                  generatedValue: '0',
                  updateOriginValue: '0',
                  errorMessage: 'The Price column contains invalid value',
                  skipFlg: 1
                });
              }

              mapRow[dbColumnName] = '0';
            } else if (dbColumnName === 'PONumber' && this.allOrders.length) {
              let checkPONumber = this.allOrders.filter((item: any) => item.POID == value);
              if (!checkPONumber.length) {
                duplicatePOList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: dbColumnName,
                  currentValue: value,
                  updateValue: '',
                  generatedValue: '',
                  updateOriginValue: '',
                  errorMessage: `PO Not Found in Var360`,
                  skipFlg: 1
                });
              } else if (this.isCheckDynamicPurchaseID && checkPONumber.length > 1) {
                let purchaseIDs = checkPONumber.map(item => ({ "ID" : item.PURCHASEID, "TEXT" : item.PURCHASEID }));
                duplicatePOList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: "VAR360 Order",
                  currentValue: value,
                  updateValue: 0,
                  generatedValue: '',
                  updateOriginValue: purchaseIDs[0]["ID"],
                  errorMessage: `Duplicate PO found, please select the correct order`,
                  skipFlg: 1,
                  purchaseIDs: purchaseIDs
                });
              } else {
                mapRow[dbColumnName] = value;
                if (this.isCheckDynamicPurchaseID && checkPONumber.length) {
                  mapRow["purchaseID"] = checkPONumber[0]["ID"];
                }
              }
            } else {
              mapRow[dbColumnName] = value;
            }
          }
        }

        csvDB.push(mapRow);
      }

      this.confirmList = confirmList;
      if (duplicatePOList.length) {
        let poNotFoundList = duplicatePOList.filter(item => !item.purchaseIDs);
        let poDuplicateList = duplicatePOList.filter(item => item.purchaseIDs);
        if (poNotFoundList.length) {
          this.confirmList.push(poNotFoundList[0]);
        }
        if (poDuplicateList.length) {
          this.confirmList.push(poDuplicateList[0]);
        }
      }

      this.csvDBRows = csvDB;

      if (confirmList.length > 0 || duplicatePOList.length) {
        this.showHardwaresImportPreviewFlg = true;
      } else {
        await this.saveHardwaresImportPreview(confirmList);
      }
    } catch (error) {
      console.log('doPreview error ', error);
    }
  }

  async doImport() {
    // Only do import if already check Preview
    // We also dont do Import if we don't have inserted and updated records
    if (this.formStep !== 2 || (this.previewInsertedCount === 0 && this.previewUpdatedCount === 0)) {
      notifier.warning(`No data can be inserted or updated. Please do Preview to confirm`);
      return;
    }

    console.log('do Import');
    this.loadingImport = true;

    try {
      const valid = await this.$validator.validate('Customer');

      if (!valid) {
        return;
      }

      const validMappingCSVToDB = this.mappingCSVToDatabase.filter((map) => map.csvColumnId && map.dbColumnId);

      if (validMappingCSVToDB.length === 0) {
        notifier.alert('Please map the CSV data to the Var360 column');
        return;
      }

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: 'Hardware',
        FunctionName: 'ImportHardwaresNew',
        stepName: 'Import',
        accountId: this.addToCustomerId,
        userID: sessionStorage.getItem("userId"),
        csvFileName: this.csvFileName,
        mappingCSVToDatabase: validMappingCSVToDB,
        csvHeader: validMappingCSVToDB.map((map) => map.dbColumnId),
        csvKeyOnUpdate: validMappingCSVToDB.filter((fmap) => fmap.isRowKey).map((map) => map.dbColumnId),
        csvData: this.csvDBRows.filter((row) => row.skipFlg === 0 && row.SKU.trim())
      });

      if (response.data.STATUS == 1) {
        this.importInsertedCount = this.previewInsertedCount;
        this.importUpdatedCount = this.previewUpdatedCount;
        this.importSkippedCount = this.previewSkippedCount;
        this.importFailedCount = 0;
        this.importConfigurationsCount = response.data.TOTAL_CONFIGURATIONS_IMPORTED;
        this.formStep = 3;
        this.importHardwareIds = response.data.IMPORTED_HARDWAREIDS.map((ihw) => ihw.HARDWAREID);
      } else {
        this.importInsertedCount = 0;
        this.importUpdatedCount = 0;
        this.importSkippedCount = 0;
        this.importFailedCount = this.previewInsertedCount + this.previewUpdatedCount;
        this.importConfigurationsCount = 0;
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('doImport error ', error);
      this.importInsertedCount = 0;
      this.importUpdatedCount = 0;
      this.importSkippedCount = 0;
      this.importFailedCount = this.previewInsertedCount + this.previewUpdatedCount;
      this.importConfigurationsCount = 0;
    } finally {
      this.loadingImport = false;
    }
  }

  viewImportData() {
    this.$router.push({ name: "Hardware", params: {importHardwareIds: this.importHardwareIds || []} });
  }
}
