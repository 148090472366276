

















































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import { notifier, downloadFileUrl } from "../models/common";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import AdjustmentsModal from "@/components/AdjustmentsModal.vue";
import ReimursementsModal from "@/components/EmployeePlan/ReimursementsModal.vue";
import TargetProfitTooltip from "@/components/TargetProfitTooltip.vue";
import ExportToGustoModal from "@/components/ExportToGustoModal.vue";

@Component({
  inheritAttrs: false,
  components: {
    ConfirmRemoveItemModal,
    AdjustmentsModal,
    ReimursementsModal,
    TargetProfitTooltip,
    ExportToGustoModal
  }
})

export default class PlanGroupDetails extends TSXComponent<void> {
  $router: any;
  $route: any;
  loading: boolean = false;
  planGroupId: number = 0;
  details: any = {};
  pgEmpPlansData: any = [];
  selectedID: string[] = [];
  pgTotalBase: string = "$0.00";
  pgTotalComp: string = "$0.00";
  pgTotalOte: string = "$0.00";
  pgCompToDate: string = "$0.00";
  confirmDeletePlanGroup: boolean = false;
  deleteLoading: boolean = false;
  showAdjustmentsModal: boolean = false;
  selectedAdjustmentMonth: number = 1;
  selectedAdjustmentMonthAsString: string = "";
  showReimursementsModal: boolean = false;
  selectedReimursementMonth: number = 1;
  selectedReimursementMonthAsString: string = "";
  empDetails: any = {};
  lockedMonthData: any = [];
  adjustmentData: any = [];
  reimursementData: any = [];
  pgCompensationData: any = [];
  pgBaseData: any = [];
  pgCommissionData: any = [];
  pgAdjustmentsData: any = [];
  pgReimbursementsData: any = [];
  pgCompensationTotal: string = "$0.00";
  pgBaseTotal: string = "$0.00";
  pgCommissionTotal: string = "$0.00";
  pgAdjustmentsTotal: string = "$0.00";
  pgReimbursementsTotal: string = "$0.00";
  showAcceleratorTooltipIndex = -1;
  showPlanAcceleratorTooltipIndex = -1;
  showExportToGustoModal: boolean = false;


  async created() {
    this.planGroupId = this.$route.params.id ? parseInt(this.$route.params.id) : 0;
    await this.fetchData(true);
  }

  async fetchData(initialLoad: boolean = false) {
    if (initialLoad) {
      this.loading = true;
    }
    try {
      const response = await ApiHelper.callApi("post", {
        controller: "Finances",
        FunctionName: "PlanGroupView",
        planID: this.planGroupId,
        action: "Detailed"
      });

      if (response.STATUS === 1) {
        this.details = response.PLANGROUPDETAIL || {};
        this.pgEmpPlansData = response.PGEMPDETAIL || [];
        this.pgCompensationData = response.PGCOMPENSATION || [];
        this.pgBaseData = response.PGBASE || [];
        this.pgCommissionData = response.PGCOMMISSION || [];
        this.pgAdjustmentsData = response.PGADJUSTMENTS || [];
        this.pgReimbursementsData = response.PGREIMBURSEMENTS || [];
        if (response.PLANGROUPDETAIL && response.PLANGROUPDETAIL.COMPIDS) {
          this.selectedID = response.PLANGROUPDETAIL.COMPIDS.split(",");
        }
        if (response.PGTOTALBASE) {
          this.pgTotalBase = response.PGTOTALBASE;
        }
        if (response.PGTOTALCOMP) {
          this.pgTotalComp = response.PGTOTALCOMP;
        }
        if (response.PGTOTALOTE) {
          this.pgTotalOte = response.PGTOTALOTE;
        }
        if (response.PGCOMPTODATE) {
          this.pgCompToDate = response.PGCOMPTODATE;
        }
        if (response.PGCOMPENSATIONTOTAL) {
          this.pgCompensationTotal = response.PGCOMPENSATIONTOTAL;
        }
        if (response.PGBASETOTAL) {
          this.pgBaseTotal = response.PGBASETOTAL;
        }
        if (response.PGCOMMISSIONTOTAL) {
          this.pgCommissionTotal = response.PGCOMMISSIONTOTAL;
        }
        if (response.PGADJUSTMENTSTOTAL) {
          this.pgAdjustmentsTotal = response.PGADJUSTMENTSTOTAL;
        }
        if (response.PGREIMBURSEMENTSTOTAL) {
          this.pgReimbursementsTotal = response.PGREIMBURSEMENTSTOTAL;
        }
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  listPageRedirection() {
    this.$router.push({ name: "PlanGroups" });
  }

  async exportToGusto(data) {
    if (data.planMonth) {
      let reqData = {};
      reqData["controller"]   = "Finances";
      reqData["FunctionName"]   = "ExportEmpGusto";
      reqData["ExportType"]     = "CSV";
      reqData["selectedID"]     = this.selectedID;
      reqData["planMonth"]     = data.planMonth;

      const response = await ApiHelper.callApi("post", reqData);

      if (response.STATUS === 1) {
        let fileUrl = response.S3URL;
        this.showExportToGustoModal = false;
        return downloadFileUrl(fileUrl);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    }
  }

  async deletePlanGroup() {
    this.deleteLoading = true;
    let reqData = {};
    reqData["controller"]     = "Finances";
    reqData["FunctionName"]   = "PlanGroupAdd";
    reqData["action"]         = "deletePlanGroup";
    reqData["planGroupUUID"]  = this.details.PLANUUID || '';

    const response = await ApiHelper.callApi("post", reqData);

    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.listPageRedirection();
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.deleteLoading = false;
  }

  async reloadPlan() {
    this.showAdjustmentsModal = false;
    this.showReimursementsModal = false;
    await this.fetchData();
  }

  async lockEmpPlanMonth(month, empPlanData: any, planIndex = 0) {
    if (!empPlanData.EMPLOYEEPLANDETAIL.CSTATUS) {
      notifier.alert("This Compensation is Depreciated so you can't update Lock.");
      return;
    }
    let lock_month = [...(empPlanData.LOCKEDMONTHS || [])];
    let lockMonthIndex = lock_month.findIndex((m: any) => m === month);
    if (lockMonthIndex === -1) {
      lock_month.push(month);
    } else {
      lock_month.splice(lockMonthIndex, 1);
    }

    let reqData = {};
    reqData["controller"]     = "Finances";
    reqData["FunctionName"]   = "EmployeePlanAdd";
    reqData["action"]         = "updateLockedMonth";
    reqData["lockedMonths"]   = lock_month || [];
    reqData["compUUID"]       = empPlanData.EMPLOYEEPLANDETAIL.COMPUUID || '';

    const response = await ApiHelper.callApi("post", reqData);

    if (response.STATUS === 1) {
      this.pgEmpPlansData[planIndex].LOCKEDMONTHS = lock_month;
      let newCompToDate = 0;
      this.pgEmpPlansData.map((planItem: any) => {
        (planItem.TOTALCOMPENSATION || []).map((item: any) => {
          if (item.AMOUNT && (planItem.LOCKEDMONTHS || []).includes(item.MONTH)) {
            newCompToDate += parseFloat(item.AMOUNT.toFixed(2));
          }
        });
      });
      this.pgCompToDate = `$${this.formatter(newCompToDate)}`;
      notifier.success(response.STATUSMESSAGE);
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }

  formatter(amount) {
    // Use Intl.NumberFormat to format the total as a currency string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }
}
