






























import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import directives from "../helpers/directives";
import DropdownControl from "../components/DropdownControl.vue";


declare const $: any;
declare const dataURL: string;

interface Props {
  businessLineOptions: any;
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    DropdownControl
  },
  directives
})
export default class BusinessLineToolTip extends TSXComponent<
  Props,
  Events
> {

  @Prop({ required: true })
  businessLineOptions: any = [];

  selectedBusinessLine: number[] = [0];

  $parent: any;
  saving = false;

  async created() {
      this.selectedBusinessLine = [this.$parent.businessLineId];
  }

  clickOutside() {
    if (!this.saving) {
      this.$emit("close");
    }
  }

  updateBusinessLine(id) {
    this.selectedBusinessLine = [id];
    this.$emit("updateID", id);
  }
}
