var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.BUILDHEADER : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <tr>\n        <td style=\"padding-left: "
    + alias4(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":22,"column":33},"end":{"line":22,"column":46}}}) : helper)))
    + "px; font-style: "
    + alias4(((helper = (helper = helpers.FONTSTYLE || (depth0 != null ? depth0.FONTSTYLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FONTSTYLE","hash":{},"data":data,"loc":{"start":{"line":22,"column":62},"end":{"line":22,"column":75}}}) : helper)))
    + ";"
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":76},"end":{"line":22,"column":118}}})) != null ? stack1 : "")
    + "\">\n            <strong>"
    + ((stack1 = ((helper = (helper = helpers.QPRODDESC || (depth0 != null ? depth0.QPRODDESC : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODDESC","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":35}}}) : helper))) != null ? stack1 : "")
    + "</strong><br/>\n            <span style=\"text-transform: uppercase;\"><strong>SKU: "
    + ((stack1 = ((helper = (helper = helpers.QPRODSKU || (depth0 != null ? depth0.QPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODSKU","hash":{},"data":data,"loc":{"start":{"line":25,"column":66},"end":{"line":25,"column":80}}}) : helper))) != null ? stack1 : "")
    + "</strong></span>\n        </td>\n        <td style=\"text-align: center;"
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":38},"end":{"line":27,"column":80}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.QPRODQUANTITY || (depth0 != null ? depth0.QPRODQUANTITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODQUANTITY","hash":{},"data":data,"loc":{"start":{"line":27,"column":82},"end":{"line":27,"column":101}}}) : helper))) != null ? stack1 : "")
    + "</td>\n        <td style=\"text-align: right;"
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":37},"end":{"line":28,"column":79}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.QCUSTOMERPRICEFORMATTED || (depth0 != null ? depth0.QCUSTOMERPRICEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QCUSTOMERPRICEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":28,"column":81},"end":{"line":28,"column":110}}}) : helper))) != null ? stack1 : "")
    + "</td>\n        <td style=\"text-align: right;"
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":37},"end":{"line":29,"column":79}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.CUSTOMERTOTALPRICEFORMATTED || (depth0 != null ? depth0.CUSTOMERTOTALPRICEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CUSTOMERTOTALPRICEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":29,"column":81},"end":{"line":29,"column":114}}}) : helper))) != null ? stack1 : "")
    + "</td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function";

  return "      <tr>\n        <td style=\"padding-left: "
    + container.escapeExpression(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":11,"column":33},"end":{"line":11,"column":46}}}) : helper)))
    + "px; font-style: regular;"
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":70},"end":{"line":11,"column":112}}})) != null ? stack1 : "")
    + "\">\n          <div style=\"border-bottom: 2px solid #f0f0f0; padding-bottom: 3px;\">\n            <strong style=\"font-size: 12px; font-weight: 600;\">"
    + ((stack1 = ((helper = (helper = helpers.BUILDHEADER || (depth0 != null ? depth0.BUILDHEADER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BUILDHEADER","hash":{},"data":data,"loc":{"start":{"line":13,"column":63},"end":{"line":13,"column":80}}}) : helper))) != null ? stack1 : "")
    + "</strong>\n          </div>\n        </td>\n        <td></td>\n        <td></td>\n        <td></td>\n      </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " padding-bottom: 10px;";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.DISPLAYONEXPORT : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            Special Pricing Code: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.SPECIALPRICINGID : stack1), depth0))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <tr>\n        <td colspan=\"2\">&nbsp;</td>\n        <td style=\"font-weight: bold; border-bottom: 3px solid #000; padding-bottom: 10px;\">Estimated Tax</td>\n        <td style=\"text-align: right; border-bottom: 3px solid #000; padding-bottom: 10px;\">"
    + container.escapeExpression(((helper = (helper = helpers.TOTALTAXFORMATTED || (depth0 != null ? depth0.TOTALTAXFORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TOTALTAXFORMATTED","hash":{},"data":data,"loc":{"start":{"line":47,"column":92},"end":{"line":47,"column":113}}}) : helper)))
    + "</td>\n    </tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <tr>\n        <td colspan=\"2\">&nbsp;</td>\n        <td style=\"font-weight: bold; border-bottom: 3px solid #000; padding-bottom: 10px;\">Estimated Shipping</td>\n        <td style=\"text-align: right; border-bottom: 3px solid #000; padding-bottom: 10px;\">"
    + container.escapeExpression(((helper = (helper = helpers.TOTALSHIPPINGFORMATTED || (depth0 != null ? depth0.TOTALSHIPPINGFORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TOTALSHIPPINGFORMATTED","hash":{},"data":data,"loc":{"start":{"line":54,"column":92},"end":{"line":54,"column":118}}}) : helper)))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<table id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\" width=\"100%\">\n    <tr class=\"headerColor\">\n        <td class=\"headerColor\">Product Description</td>\n        <td class=\"headerColor\" style=\"text-align: center;\">Qty</td>\n        <td class=\"headerColor\" style=\"text-align: right; width: 110px;\">Unit Price</td>\n        <td class=\"headerColor\" style=\"text-align: right; width: 110px;\">Total Price</td>\n    </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.QUOTEITEMS : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":31,"column":13}}})) != null ? stack1 : "")
    + "    <tr>\n        <td colspan=\"2\" style=\"border-top: 1px solid #000; padding-top: 20px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.SPECIALPRICINGID : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "        </td>\n        <td style=\"font-weight: bold; border-bottom: 3px solid #000; border-top: 1px solid #000; padding: 20px 0 10px;\">Subtotal</td>\n        <td style=\"text-align: right;  border-bottom: 3px solid #000; border-top: 1px solid #000; padding: 20px 0 10px;\">"
    + alias4(((helper = (helper = helpers.TOTALCUSTOMERAMOUNTFORMATTED || (depth0 != null ? depth0.TOTALCUSTOMERAMOUNTFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALCUSTOMERAMOUNTFORMATTED","hash":{},"data":data,"loc":{"start":{"line":41,"column":121},"end":{"line":41,"column":153}}}) : helper)))
    + "</td>\n    </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.TOTALTAX : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":4},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.TOTALSHIPPING : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":56,"column":11}}})) != null ? stack1 : "")
    + "    <tr>\n        <td colspan=\"2\">&nbsp;</td>\n        <td style=\"font-weight: bold; border-bottom: 3px solid #000; padding-bottom: 10px;\">Grand Total</td>\n        <td style=\"text-align: right; border-bottom: 3px solid #000; padding-bottom: 10px;\">"
    + alias4(((helper = (helper = helpers.GRANDCUSTOMERTOTALFORMATTED || (depth0 != null ? depth0.GRANDCUSTOMERTOTALFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"GRANDCUSTOMERTOTALFORMATTED","hash":{},"data":data,"loc":{"start":{"line":60,"column":92},"end":{"line":60,"column":123}}}) : helper)))
    + "</td>\n    </tr>\n</table>";
},"useData":true});