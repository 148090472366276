































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { AcctsListResp } from "../models/AcctsListResp"

interface Props {
  options: [];
  selectedIDs: [];
  hideSelectAll: boolean;
  multiSelect: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class MultiSelect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: AcctsListResp.AccountsSelect[];

  @Prop({ required: true })
  selectedIDs!: number[];

  @Prop({ required: false, default: "" })
  label!: string;

  @Prop({ required: false, default: false })
  hideSelectAll!: boolean;

  @Prop({ required: false, default: true })
  multiSelect!: boolean;

  dropdownVisible = false
  search = ''
  showOnlySelected: boolean = false

  get filteredOptions() {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => `${option.TEXT}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }

    if (this.showOnlySelected) {
      options = options.filter((option) => this.selectedIDs.indexOf(option.ID) !== -1)
    }

    return options
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(id: any): boolean {
    const checked = !!this.selectedIDs.find((val) => val === id);
    return checked;
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  getSelectedOption() {//use for single select
    var ret = "";
    if(this.selectedIDs.length) {
      let tmp = this.options.filter((item: any) => item.ID == this.selectedIDs[0]);
      if(tmp.length) {
        ret = tmp[0].TEXT;
      }
    }

    return ret;
  }

  checkToHide() {
    if(!this.multiSelect) {//single select case
      this.dropdownVisible = false;
    }
  }
}
