










































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {}
})
export default class UserStatusComponent extends TSXComponent<void> {
  @Prop({ required: true })
  status!: number;

  popoverVisible = false
  optionsVisible = false

  hideTooltip() {
    this.optionsVisible = false;
  }

  mouseover() {
    if (this.optionsVisible) {
      return
    }
    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status) {
    this.$emit('changeStatus', status);
    this.optionsVisible = false
  }
}
