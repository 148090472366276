



























































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";

import axios from "axios";
import AccountTagSelect from "../components/AccountTagSelect.vue";
import subAccountsSelect from "../components/subAccountmultiselect.vue";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";

interface Props {}

interface Events {
  initialLoad: { index };
}
interface accObj {
  ACCOUNTID?: string;
  ANAME?: string;
  SPECIALPRICINGID?: string;
  NASPOCONTRACT?: string;
  AACCOUNTNOTES?: string;
}
@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    subAccountsSelect,
    AccountTagSelect,
    FormMultiSelect
  }
})
export default class CustomerDetailsTabTemplates extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: true })
  accid!: string;

  // @Prop({ required: true })
  items: accObj = {};

  @Prop({ required: true })
  alltags!: object[];

  @Prop({ required: true })
  selectedtags!: object[];

  @Prop({ required: true })
  allaccs!: object[];

  @Prop({ required: true })
  selectedaccs!: number[];

  @Prop({ required: true })
  selectedtagsid!: number[];

  @Prop({ required: true })
  aid!: number;

  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: false, default: [] })
  selectedChangeInfoIds!: string[];

  errorMsg = {
    forAccountID: "",
    forPercentage: ""
  };

  loading = false;
  isActive = "customerDetail";
  customDataVisible = false;
  fullCustomData: any = [];
  customDataLoading = false;
  // customDataIndex = -1;
  aID = "";
  titleAccName = "";
  currentRole = sessionStorage.getItem("userRole");
  userID = 0;
  commAvail = 0;
  remainingComm = 99;

  // customFieldsModalVisible = false;
  customerRemainingPer = "";
  userName = "";
  selectedUserIds: (number | undefined)[] = [];
  selectedSecurityIds: (number | undefined)[] = [];
  userAvailable = false;
  domain = sessionStorage.getItem("domain");
  saving: any = false;
  externalKey = "";
  commissionAdding = false;
  addingCustomer: any = false;
  addressItems = [
    {
      NAME: "kali",
      ADDR1: "west block",
      ADDR2: "KFC street",
      CITY: "moroco",
      STATE: "Tokyo",
      ZIP: "",
      ID: 0,
      ISDEFAULT: 0,
      ACCOUNTSADDRESS_ID: ""
    }
  ];
  contactsItems = [
    {
      NAME: "kali",
      MAIL: "KALI@MILESTONETECH.COM",
      PHONE: "",
      ID: 0,
      ISDUPLICATE: 0,
      ACCOUNTCONTACTID: ""
    }
  ];
  domainsList = [];
  submitFrom = 1;
  userType = 1; //use for add commission %
  options = [];

  usersObj: {
    USERID: number;
    UFULLNAME?: string;
  }[] = [];
  securityObj: {
    SECURITYGROUPID: number;
    SECURITYGROUPNAME?: string;
  }[] = [];
  custLoginObj: {
    ACCOUNTNAME?: string;
    UEMAIL?: string;
    UFNAME?: string;
    DOMAIN?: string;
    DOMAINNAME?: string;
  }[] = [];
  details = {
    CUSTOMERLOGINSTATUS: 0,
    CUSTOMERLOGINDETAILS: this.custLoginObj,
    SELECTEDGROUPS: this.securityObj,
    SELECTEDUSERS: this.usersObj,
    SELECTEDUSERSTOTAL: 0,
    ALLSECURITYGROUPS: this.securityObj,
    ALLUSERS: this.usersObj
  };
  templateObj: {
    ACCOUNTID?: number;
    TEMPLATEID?: number;
    TEMPLATELOCATION?: string;
    TEMPLATENAME?: string;
    TEMPLATETYPE?: number;
  }[] = [];
  subaccounts = [];
  customerAccounts = [];
  templateResult = [];
  invoice_template = this.templateObj;
  order_template = this.templateObj;
  quote_template = this.templateObj;
  quote_templateSelected = 0;
  order_templateSelected = 0;
  invoice_templateSelected = 0;
  invoiceTemplates: number[] = [];
  orderTemplates: number[] = [];
  quoteTemplates: number[] = [];
  quoteTemplateSelected = false;
  orderTemplateSelected = false;
  invoiceTemplateSelected = false;
  selectedSubAccsID: number[] = [];
  accountTags: object[] = [];
  selectedTagsIDComm: number[] = [];
  selectedTagsComm: object[] = [];
  selectedSecurityIdsComm: number[] = [];
  selectedSGComm: object[] = [];
  allSGComm: object[] = [];
  AcctsCommUpdateModalVisible = false;
  currAID = 0;
  currACCID = "";
  currAccName = "";
  currAccAlias = "";
  // isEditANotes = false;
  imageSrc = "";
  quoteTemplateDetail = "";
  quoteTemplateDetailError = "";
  originalFormData: any = {}
  isEdited = false;
  isInitial = true;
  changeInfoOptions = [
    {
      id: "Quotes",
      text: "Quotes"
    },
    {
      id: "Orders",
      text: "Orders"
    },
    {
      id: "Hardware",
      text: "Hardware"
    },
    {
      id: "Deployments",
      text: "Deployments"
    }
  ];

  async created() {
    this.fetch();

    //get custom data
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Helpers",
      FunctionName: "CustomData",
      DataType: "4", // account
      Id: this.aid,
      aId: this.aid
    });
    if(response.data.STATUS == 1) {
      this.fullCustomData = [];
      for(let val of response.data.CUSTOMDEFINITION) {
        let tmpValue: any = (response.data.CUSTOMDATA || []).filter(tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID);
        this.fullCustomData.push({
          AID: val.AID,
          CUSTOMFIELDID: val.CUSTOMFIELDID,
          CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
          CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
          CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
          CUSTOMDTYPE: val.CUSTOMDTYPE, 
          OPTIONS: val.OPTIONS || []
        });
      }
    }
  }

  async fetch() {
    const response = await axios.post(
      dataURL + "?ReturnType=JSON&tab=details",
      {
        controller: "Customers",
        FunctionName: "View",
        aID: this.accid
      }
    );

    this.items = response.data.CUSTOMERS[0];
    this.quote_contactSuggestions = response.data.CUSTOMERS[0].QUOTECONTACTSUGGESTIONS || [];
    this.order_contactSuggestions = response.data.CUSTOMERS[0].ORDERCONTACTSUGGESTIONS || [];
    var self = this;
    // if (
    //   typeof response.data.CUSTOMERS[0].ACCOUNTPARAMS != "undefined" &&
    //   response.data.CUSTOMERS[0].ACCOUNTPARAMS.length
    // ) {
    //   var tmpParams = JSON.parse(response.data.CUSTOMERS[0].ACCOUNTPARAMS);
    //   if (typeof tmpParams["customFields"] != "undefined") {
    //     self.customFields = tmpParams["customFields"];
    //   }
    // }
    this.imageSrc = response.data.LOGOSRC;
    self.order_templateSelected = response.data.OrderTemplateId;
    self.invoice_templateSelected = response.data.InvoiceTemplateId;

    // set Quote Contact data
    let accountQuoteContact = response.data.AccountQuoteContact;
    if (accountQuoteContact) {
      self.quote_contactOptionSelected = accountQuoteContact.QuoteContactOptionSelected || "";
      self.quote_contactValue = accountQuoteContact.QuoteContactValue || "";
    }

    // set Order Contact data
    let accountOrderContact = response.data.AccountOrderContact;
    if (accountOrderContact) {
      self.order_contactOptionSelected = accountOrderContact.OrderContactOptionSelected || "";
      self.order_contactValue = accountOrderContact.OrderContactValue || "";
    }

    self.quote_template = [];
    self.order_template = [];
    self.invoice_template = [];
    var templateResult = response.data.TEMPLATES.map(item => ({
      ...item,
      QUOTETEMPLATENAME: JSON.parse(item.QUOTETEMPLATENAME || "[]")
    }));
    for (let i = 0; i < templateResult.length; i++) {
      if (templateResult[i].TEMPLATETYPE == 1) {
        //quote
        templateResult[i].selected = false;
        if (
          templateResult[i].TEMPLATEID == response.data.AccountQuoteTemplateId
        ) {
          templateResult[i].selected = true;
          self.quote_templateSelected = templateResult[i].TEMPLATEID;

          // specify quote template detail
          const quoteTemplateName = templateResult[i].QUOTETEMPLATENAME || [];
          const quoteTemplateDetail = response.data.AccountQuoteAztempname || "";
          const inList = quoteTemplateName.find(item => item.Name.toLowerCase() == quoteTemplateDetail.toLowerCase());
          if(inList) {
            this.quoteTemplateDetail = quoteTemplateDetail;
          }
        }
        self.quote_template.push(templateResult[i]);
        self.quoteTemplates.push(templateResult[i].TEMPLATEID);
      } else if (templateResult[i].TEMPLATETYPE == 3) {
        //order
        templateResult[i].selected = false;
        if (
          templateResult[i].TEMPLATEID == response.data.AccountOrderTemplateId
        ) {
          templateResult[i].selected = true;
          self.order_templateSelected = templateResult[i].TEMPLATEID;
        }
        self.order_template.push(templateResult[i]);
        self.orderTemplates.push(templateResult[i].TEMPLATEID);
      } else if (templateResult[i].TEMPLATETYPE == 2) {
        //invoice
        templateResult[i].selected = false;
        if (
          templateResult[i].TEMPLATEID == response.data.AccountInvoiceTemplateId
        ) {
          templateResult[i].selected = true;
          self.invoice_templateSelected = templateResult[i].TEMPLATEID;
        }
        self.invoice_template.push(templateResult[i]);
        self.invoiceTemplates.push(templateResult[i].TEMPLATEID);
      }
    }
  }
  async validateBeforeSubmit(e) {
    if(e) {
      e.preventDefault();
      e.stopPropagation();
    }
    var errorTab = 0;
    this.quoteTemplateDetailError = "";

    $(".errorMsg.EmailCheck").text("");
    var result = await this.$validator.validateAll();
    // this.$validator.validateAll().then(async result => {
    if (!result) {
      return;
    }
    if(!this.isEdited) {
      return;
    }
    var htmlCount = 0;
    // if (e.target.className == "details") {
    if ($("#aName").val() == "") {
      result = false;
    } else {
      if (htmlCheck($("#aName").val())) {
        this.items.ANAME = htmlParse($("#aName").val());
        htmlCount++;
      }
    }
    if (this.items.SPECIALPRICINGID != "") {
      if (htmlCheck(this.items.SPECIALPRICINGID)) {
        this.items.SPECIALPRICINGID = htmlParse(this.items.SPECIALPRICINGID);
        htmlCount++;
      }
    }
    if (this.items.NASPOCONTRACT != "") {
      if (htmlCheck(this.items.NASPOCONTRACT)) {
        this.items.NASPOCONTRACT = htmlParse(this.items.NASPOCONTRACT);
        htmlCount++;
      }
    }
    if (this.items.AACCOUNTNOTES != "") {
      if (htmlCheck(this.items.AACCOUNTNOTES)) {
        this.items.AACCOUNTNOTES = htmlParse(this.items.AACCOUNTNOTES);
        htmlCount++;
      }
    }
    if ($("#accountID").val() == "") {
      result = false;
    } else {
      if (
        $("#accountID")
          .val()
          .indexOf(" ") != -1 &&
        this.items.ACCOUNTID
      ) {
        this.items.ACCOUNTID = this.items.ACCOUNTID.replace(/\s/g, "");
      }
      if (htmlCheck($("#accountID").val())) {
        this.items.ACCOUNTID = htmlParse($("#accountID").val());
        htmlCount++;
      }
    }

    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
      result = false;
    }

    this.quoteTemplateSelected =
      this.quoteTemplates.findIndex(
        id => id === this.quote_templateSelected
      ) === -1 && this.quoteTemplates.length
        ? true
        : false;
    this.orderTemplateSelected =
      this.orderTemplates.findIndex(
        id => id === this.order_templateSelected
      ) === -1 && this.orderTemplates.length
        ? true
        : false;
    this.invoiceTemplateSelected =
      this.invoiceTemplates.findIndex(
        id => id === this.invoice_templateSelected
      ) === -1 && this.invoiceTemplates.length
        ? true
        : false;
    if (
      this.quoteTemplateSelected ||
      this.orderTemplateSelected ||
      this.invoiceTemplateSelected
    ) {
      result = false;
      errorTab = 1;
    }

    // if not select a default quote template detail
    // const selectedQuoteTemplate: any = this.quote_template.find(item => item.TEMPLATEID == this.quote_templateSelected);
    // if(selectedQuoteTemplate && (selectedQuoteTemplate.QUOTETEMPLATENAME || []).length && !this.quoteTemplateDetail) {
    //   this.quoteTemplateDetailError = 'The Quote template detail field is required';
    //   result = false;
    //   errorTab = 1;
    // }

    if (result) {
      await this.savedetails(this.items);

      // this.saving = false;
    }
    // }
  }
  savedetails(data) {
    var comInstance = this;
    this.submitFrom = 1;
    var userList: number[] = [];
    if ($("#subAccounts").hasClass("error")) {
      $("#subAccounts").removeClass("error");
    }
    $(".errorMsg.subAccounts").text("");
    $.each(comInstance.details.SELECTEDUSERS, function(i, val) {
      userList.push(val.USERID);
    });

    //detect selected security groups
    var sgList: number[] = [];
    $.each(comInstance.details.SELECTEDGROUPS, function(i, val) {
      sgList.push(val.SECURITYGROUPID);
    });
    comInstance.saving = true;
    return getRouteData({
      controller: "Accounts",
      FunctionName: "Update",
      aID: data.AID,
      accountID: data.ACCOUNTID,
      mdEditAccountName: data.ANAME,
      mdEditStreet: data.AADDRESS,
      mdEditCity: data.ACITY,
      mdEditState: data.ASTATE,
      mdEditZip: data.AZIP,
      mdAddAccountID: data.SubAccountID,
      accountAlias: data.ACCOUNTALIAS,
      mdEditspecialPricingID: data.SPECIALPRICINGID,
      quote_template: this.quote_templateSelected,
      quoteTemplateDetail: this.quoteTemplateDetail || "",
      order_template: this.order_templateSelected,
      invoice_template: this.invoice_templateSelected,
      quote_contactOptionSelected: this.quote_contactOptionSelected,
      quote_contactValue: this.quote_contactValue || "",
      order_contactOptionSelected: this.order_contactOptionSelected,
      order_contactValue: this.order_contactValue || "",
      NASPOContract: data.NASPOCONTRACT,
      mdEditAccountNotes: data.AACCOUNTNOTES,
      logoSrc: this.imageSrc,
      //taxrate: data.TAXRATE,
      // customFields:
      //   typeof this.customFields != "undefined"
      //     ? JSON.stringify(this.customFields)
      //     : "",
      // selectedTagsID: this.selectedtagsid.join(","),
      selectedChangeInfoIds: this.selectedChangeInfoIds.join(",")
    }).then(function(response, statusText, jqXHR) {
      if (response.data.ERROR) {
        // console.log(response.data.ERROR);
      }
      if (response.data.STATUSSUB == 3) {
        notifier.warning(response.data.MESSAGESUB);
      } else if (response.data.STATUS == 1) {
        if (data.ACCOUNTID != comInstance.accid) {
          history.replaceState(null, "", "#/customers/" + data.ACCOUNTID);
          comInstance.$router.push({
            name: "ViewCustomer",
            params: { id: data.ACCOUNTID }
          });
        } else {
          comInstance.$emit("initialLoad", 2);
        }
        comInstance.saving = false;
        // notifier.success(response.data.MESSAGE);
        notifier.success('Templates updated successfully.');
        comInstance.originalFormData = { ...comInstance.items };
        comInstance.originalFormData["quote_templateSelected"] = comInstance.quote_templateSelected;
        comInstance.originalFormData["quoteTemplateDetail"] = comInstance.quoteTemplateDetail;
        comInstance.originalFormData["order_templateSelected"] = comInstance.order_templateSelected;
        comInstance.originalFormData["invoice_templateSelected"] = comInstance.invoice_templateSelected;
        // comInstance.originalFormData["selectedtagsid"] = [ ...comInstance.selectedtagsid ];
        comInstance.originalFormData["quote_contactOptionSelected"] = comInstance.quote_contactOptionSelected;
        comInstance.originalFormData["quote_contactValue"] = comInstance.quote_contactValue;
        comInstance.originalFormData["order_contactOptionSelected"] = comInstance.order_contactOptionSelected;
        comInstance.originalFormData["order_contactValue"] = comInstance.order_contactValue;
        comInstance.originalFormData["selectedChangeInfoIds"] = [...comInstance.selectedChangeInfoIds];
        comInstance.isEdited = false;
        // Need to Remove
        // $("#aNotesEdit input").addClass("displayNone");
        // $("#aNotesEdit .aNotesContainer").removeClass("displayNone");
        // $("#aNotesInfo #saveANotes").removeClass("aEditIcon");
        // $("#aNotesInfo #saveANotes").addClass("aNotesSaveIcon");
        // router.push({ name: "Customers" });//go to customer list // As per DJ commennt to stay in same page.
      } else if (response.data.STATUS == 0) {
        comInstance.saving = "error";
        if (
          typeof response.data.ACCOUNTID_EXISTED != "undefined" &&
          response.data.ACCOUNTID_EXISTED
        ) {
          comInstance.errorMsg.forAccountID = response.data.MESSAGE;
        }
      }
    });
  }



  get quoteTemplateDetailOptions() {
    const selectedQuoteTemplate: any = this.quote_template.find(item => item.TEMPLATEID == this.quote_templateSelected);
    if(selectedQuoteTemplate) {
      return selectedQuoteTemplate.QUOTETEMPLATENAME || [];
    }

    return [];
  }

  @Watch("items", { deep: true })
  async isFormChanged() {
    if (this.isInitial) {
      this.originalFormData = { ...this.items };
      this.originalFormData["quote_templateSelected"] = this.quote_templateSelected;
      this.originalFormData["quoteTemplateDetail"] = this.quoteTemplateDetail;
      this.originalFormData["order_templateSelected"] = this.order_templateSelected;
      this.originalFormData["invoice_templateSelected"] = this.invoice_templateSelected;
      this.originalFormData["quote_contactOptionSelected"] = this.quote_contactOptionSelected;
      this.originalFormData["quote_contactValue"] = this.quote_contactValue;
      this.originalFormData["order_contactOptionSelected"] = this.order_contactOptionSelected;
      this.originalFormData["order_contactValue"] = this.order_contactValue;
      // this.originalFormData["selectedtagsid"] = [ ...this.selectedtagsid ];
      this.originalFormData["selectedChangeInfoIds"] = [ ...this.selectedChangeInfoIds ];
      this.isInitial = false;
    }
    this.isEdited = Object.keys(this.items).some(
      key => this.items[key] !== this.originalFormData[key]
    );
  }

  @Watch("isTemplateChanged", { deep: true})
  async templateChanged(val) {
    this.isEdited = val;
  }

  get isTemplateChanged() {
    if (
      (this.quote_templateSelected != this.originalFormData["quote_templateSelected"]) ||
      (this.quoteTemplateDetail != this.originalFormData["quoteTemplateDetail"]) ||
      (this.order_templateSelected != this.originalFormData["order_templateSelected"]) ||
      (this.invoice_templateSelected != this.originalFormData["invoice_templateSelected"]) ||
      (this.quote_contactOptionSelected != this.originalFormData["quote_contactOptionSelected"]) ||
      (this.quote_contactValue != this.originalFormData["quote_contactValue"]) ||
      (this.order_contactOptionSelected != this.originalFormData["order_contactOptionSelected"]) ||
      (this.order_contactValue != this.originalFormData["order_contactValue"]) ||
      // (this.selectedtagsid.join(",") != this.originalFormData["selectedtagsid"].join(",")) ||
      (this.selectedChangeInfoIds.join(",") != this.originalFormData["selectedChangeInfoIds"].join(","))
    ) {
      return true;
    }
    return false;
  }

  get filterQuoteContactSuggestions() {
      return this.quote_contactSuggestions.filter((contact: any) => contact.QUOTECONTACTVALUE.toLowerCase().includes(this.quote_contactValue.trim().toLowerCase()));
  }

  get filterOrderContactSuggestions() {
      return this.order_contactSuggestions.filter((contact: any) => contact.ORDERCONTACTVALUE.toLowerCase().includes(this.order_contactValue.trim().toLowerCase()));
  }

  toggleChangeInfoOption(id, text) {
    if (!this.selectedChangeInfoIds.includes(id)) {
      this.selectedChangeInfoIds.push(id);
    } else {
      this.selectedChangeInfoIds = this.selectedChangeInfoIds.filter(
        t => t != id
      );
    }
    // sort
    const ret: any = [];
    for (const t of this.changeInfoOptions) {
      if (this.selectedChangeInfoIds.includes(t.id)) {
        ret.push(t.id);
      }
    }
    this.selectedChangeInfoIds = ret;
  }

  updateSelectedChangeInfoIds(type, index, item) {
    if (type == "delete") {
      this.selectedChangeInfoIds = this.selectedChangeInfoIds.filter(t => t != item);
    }
  }
}
