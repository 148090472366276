























import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import * as Ladda from "ladda";
import { wait } from "../models/common";

interface Props {
  type?: "submit" | "button";
  label: string;
  disabled?: boolean;
  loading?: boolean | 'error';
}

interface Events {
  onClick: void;
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class LaddaButton extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "button" })
  type!: "submit" | "button";

  @Prop({ required: true })
  label!: string;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: false })
  loading!: boolean | 'error';

  @Prop({ required: false, default: false })
  fixedWidth?: boolean;

  loaded: boolean = false;
  error: boolean = false;

  $refs!: {
    button: HTMLButtonElement;
  };

  l: any;

  get clickable(): boolean {
    return this.loading !== true && !this.loaded
  }

  mounted() {
    this.l = Ladda.create(this.$refs.button);
    if (this.$refs.button.offsetWidth !== 0) {
      this.calculateWidth()
    }
  }

  calculateWidth() {
    if(this.fixedWidth) return;

    if (this.$refs.button.style.paddingLeft === '0px') {
      return
    }
    Object.assign(this.$refs.button.style, {
      width: this.$refs.button.offsetWidth + "px",
      paddingLeft: "0px",
      paddingRight: "0px",
    })
  }

  @Watch("loading")
  async loadingChanged(loading: boolean | 'error') {
    if (loading === true && !this.l.isLoading()) {
      this.l.start();
    } else if (loading === false && this.l.isLoading()) {
      this.loaded = true;
      this.l.stop();
      await wait(2000);
      this.loaded = false;
      this.error = false
    } else if (loading === 'error' && this.l.isLoading()) {
      this.loaded = true;
      this.error = true
      this.l.stop();
      await wait(2000);
      this.loaded = false;
      this.error = false
    }
  }

  onClick(event) {
    this.calculateWidth()

    if (this.type === "submit") {
      if (!this.clickable) {
        event.preventDefault();
        event.stopPropagation();
      }

      return;
    }

    if (this.type === "button") {
      if (!this.clickable) {
        return;
      }

      this.$emit("click", event);
      return;
    }
  }
}
