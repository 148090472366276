












































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ModalChangeEndUser from "@/components/Deployment/ModalChangeEndUser.vue";

declare const $: any;
declare const dataURL: string;

interface Props {
}

interface Events {
}

@Component({
  inheritAttrs: true,
  components: { ModalChangeEndUser }
})
export default class EndUserInfo extends TSXComponent<Props, Event> {
  @Prop({ required: false, default: [] })
  endUsers!: any[];

  @Prop({ required: false, default: 0 })
  euId!: number;

  selectedEndUser = {
    id: 0,
    name: '',
    address: '',
    address2: '',
  };

  created() {
    this.changeEndUser(this.euId);
  }

  changeEndUser(id) {
    const findEu = this.endUsers.find((item) => item.id == id);
    if (findEu) {
      this.selectedEndUser = {
        ...this.selectedEndUser,
        id: findEu.id,
        name: findEu.name,
        address: findEu.addr1 + ' ' + findEu.addr2,
        address2: `${findEu.city}, ${findEu.state} ${findEu.zip}`
      };
      this.$emit('callback', findEu.id);
    }
  }

  modalChangeEndUser = {
    show: false,
  };
}
