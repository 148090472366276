



































































































































import { VNode } from "vue";
import axios from "axios";
import { notifier } from "../models/common";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import SecurityMultiSelect from "../components/SecurityMultiSelect.vue";
import AccountMultiselect from "../components/AccountMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";

interface Props {
}

interface Events {
  onClose: void
  onSave: void
}

declare const $: any
declare const getImageRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    AccountMultiselect,
    SecurityMultiSelect
  }
})
export default class UploadNewPriceListModal extends TSXComponent<
  Props,
  Events
> {

  saving = false;

  listNameSuggestionVisible: boolean = false;
  form: any = {
    priceListName: '',
    isUploadNew: 0,
    fileUploaded: null,
    fileUploadedName: '',
    fileType: '',
    securityGroups: [],
    accounts: [],
    description: '',
  }

  allSecurityGroups: any[] = [];
  selectedSecurityIds: any[] = [];
  selectedGroups: any[] = [];

  allAccounts: any[] = [];
  selectedAccountIds: any[] = [];
  selectedAccounts: any[] = [];

  priceListOptions: any = [];

  loading: boolean = false;

  $refs!: {
    modal: HTMLDivElement
  }

  get filterListNameSuggestions() {
      return this.priceListOptions.filter((pl: any) => pl.NAME.toLowerCase().includes(this.form.priceListName.trim().toLowerCase()));
  }

  created() {
    this.getSecurityAccountOptions();
    this.getPricingList();
  }

  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  choosePriceListFile(event) {
    $("#priceListFileUpload").click();
  }

  hidePriceListNameSuggestion() {
    this.listNameSuggestionVisible = false;
  }

  handlePriceListFileUpload(event) {
    this.form.fileUploaded = event.target.files[0];
    this.form.fileUploadedName = event.target.files[0].name;
    this.form.fileType = event.target.files[0].name.split('.').pop();
  }

  async getSecurityAccountOptions() {
    this.loading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Quotes",
      FunctionName: "ContractPricingListUploadOptions",
    });

    this.allSecurityGroups = response.data.ALLSECURITYGROUPS;
    this.allAccounts = response.data.ALLACCOUNTS;

    this.loading = false;
  }

  async getPricingList() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Quotes",
      FunctionName: "PriceList",
      viewType: 'options'
    });

    this.priceListOptions = response.data.PRICINGLIST.map((pl) => ({
      ID: pl.PRICELISTID,
      NAME: pl.NAME
    }));
  }

  toggleSelectedGroupId(groupId, groupName) {
    const index = this.selectedSecurityIds.findIndex(id => id === groupId);
    if (index === -1) {
      this.selectedSecurityIds.push(groupId);
      this.selectedGroups.push({
        SECURITYGROUPNAME: groupName,
        SECURITYGROUPID: groupId
      });
      return;
    }
    this.selectedSecurityIds.splice(index, 1);
    this.$delete(this.selectedGroups, index);
  }
  selectAllGroups() {
    this.resetGroups();
    const thiz = this;

    $.each(this.allSecurityGroups, function (i, val) {
      thiz.selectedGroups.push({
        SECURITYGROUPNAME: val.SECURITYGROUPNAME,
        SECURITYGROUPID: val.SECURITYGROUPID
      });
      thiz.selectedSecurityIds.push(val.SECURITYGROUPID);
    });

  }
  resetGroups() {
    this.selectedSecurityIds = [];
    this.selectedGroups = [];
  }

  toggleSelectedAccountId(accountId, accountName) {
    const index = this.selectedAccountIds.findIndex(id => id === accountId);
    if (index === -1) {
      this.selectedAccountIds.push(accountId);
      this.selectedAccounts.push({
        ANAME: accountName,
        AID: accountId
      });
      return;
    }
    this.selectedAccountIds.splice(index, 1);
    this.$delete(this.selectedAccounts, index);
  }
  selectAllAccounts() {
    this.resetAccounts();
    const thiz = this;

    $.each(this.allAccounts, function (i, val) {
      thiz.selectedAccounts.push({
        ANAME: val.ANAME,
        AID: val.AID
      });
      thiz.selectedAccountIds.push(val.AID);
    });

  }
  resetAccounts() {
    this.selectedAccountIds = [];
    this.selectedAccounts = [];
  }

  async save() {
    const valid = await this.$validator.validateAll();

    if (!valid) {
      return;
    }
    this.saving = true;

    this.form.securityGroups = this.selectedSecurityIds;
    this.form.accounts = this.selectedAccountIds;

    const requestObj = {
      Controller: 'Quotes',
      FunctionName: 'UploadPriceList',
      userID: sessionStorage.getItem("userId"),
      ...this.form
    };

    const response = await getImageRouteData(requestObj);

    const message = response.data.STATUSMESSAGE || "";
    if (response.data.STATUS == 1) {
      notifier.success(message);
      this.$emit('save', this.form);
    } else {
      if (message) {
        notifier.alert(message);
      }
    }

    this.saving = false;
  }
}
