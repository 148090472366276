













































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: OppStatusValue;
}

interface Events {
  onChangeStatus: OppStatusValue
}

export enum OppStatusValue {
  Active = 1,
  Won = 2,
  Lost = 3,
  Proposed = 4,
  Delayed = 5,
  Prospect = 6,
  Scope = 7,
  Closed = 8,
  Deleted = 0
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class OppStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: OppStatusValue;

  @Prop({ required: false, default: true })
  showStatus?: boolean;

  currentRole = sessionStorage.getItem("userRole");
  popoverVisible = false
  optionsVisible = false

  get iconClass() {
    if(this.status == 1){
      return 'blue'
    }
    else if(this.status == 2){
      return 'green'
    }
    else if(this.status == 3){
      return 'red'
    }
    else if(this.status == 4){
      return 'lightPurple'
    }
    else if(this.status == 5){
        return 'lightBlue'
    }
    else if(this.status == 6){
        return 'Prospect'
    }
    else if(this.status == 7){
        return 'Scope'
    }
    else if(this.status == 8){
      return 'Closed'
    }
    else if(this.status == 0){
        return 'deleted'
    }
    else{
      return 'purple'
    }
  }

  get statusText() {
    if(this.status == 1){
      return 'Active (75%)'
    }
    else if(this.status == 2){
      return 'Won (100%)'
    }
    else if(this.status == 3){
      return 'Lost'
    }
    else if(this.status == 4){
      return 'Proposed (50%)'
    }
    else if(this.status == 5){
        return 'Delayed'
    }
    else if(this.status == 6){
        return 'Prospect (10%)'
    }
    else if(this.status == 7){
      return 'Scope (25%)'
    }
    else if(this.status == 8){
      return 'Closed'
    }
    else if(this.status == 0){
        return 'Deleted'
    }
    else{
      return 'Unknown'
    }
  }
  hideTooltip() {
    this.optionsVisible = false;
  }
  mouseover() {
    if (this.optionsVisible) {
      return
    }

    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false
    
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: OppStatusValue) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }
}
