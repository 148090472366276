var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteNotes),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":49,"column":2},"end":{"line":73,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <table width=\"100%\" id=\"quoteDetails_notes\" style=\"padding: 0;\">\n      <tr>\n        <td style=\"padding: 40px 35px 12px; color: #A1A1A1; font-size: 10px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 0.24px;\">QUOTE NOTES:</td>\n      </tr>\n      <tr>\n        <td style=\"padding: 0 35px; color: #000; font-size: 11.5px; font-style: normal; font-weight: 600; line-height: normal; letter-spacing: 0.3px;\">\n          "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteNotes), depth0)) != null ? stack1 : "")
    + "\n        </td>\n      </tr>\n    </table>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTENOTES),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":4},"end":{"line":72,"column":11}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <table width=\"100%\" id=\"quoteDetails_notes\" style=\"padding: 0;\">\n        <tr>\n          <td style=\"padding: 40px 35px 12px; color: #A1A1A1; font-size: 10px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 0.24px;\">QUOTE NOTES:</td>\n        </tr>\n        <tr>\n          <td style=\"padding: 0 35px; color: #000; font-size: 11.5px; font-style: normal; font-weight: 600; line-height: normal; letter-spacing: 0.3px;\">\n            "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTENOTES), depth0)) != null ? stack1 : "")
    + "\n          </td>\n        </tr>\n      </table>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.DISPLAYQUOTENOTES),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":0},"end":{"line":74,"column":7}}})) != null ? stack1 : "");
},"useData":true});