





















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: HPAgentStatusValue;
}

interface Events {
  onChangeStatus: HPAgentStatusValue;
}

export enum HPAgentStatusValue {
  Open = 0,
  Pending = 1,
  Closed = 2
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class hpagentstatuscomponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: HPAgentStatusValue;

  @Prop({ required: true, default: "open" })
  statustext!: string;

  popoverVisible = false;
  optionsVisible = false;

  get iconClass() {
    const icon =
      this.status === 1
      ? "purple"
      : this.status === 0
      ? "green"
      :  "red";
      return icon
  }

  get statusText() {
    const status =  this.status === 1
      ? "Pending"
      : this.status === 0
      ? "Open"
      : "Closed";
      return status
  }

  mouseover() {
    if (this.optionsVisible) {
      return;
    }
    this.popoverVisible = true;
  }

  mouseout() {
    this.popoverVisible = false;
  }

  hideTooltip() {
    this.optionsVisible = false;
  }

  click() {
    this.popoverVisible = false;
    this.optionsVisible = !this.optionsVisible;
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false;
    }
  }

  itemClick(status: HPAgentStatusValue) {
    this.$emit("changeStatus", status);
    this.optionsVisible = false;
  }
}
