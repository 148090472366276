






























































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import directives from "../helpers/directives";
import { notifier } from "@/models/common";
import { getOrderStatusText } from "@/helpers/ApiHelper";

declare const $: any;
declare const dataURL: string;

interface Props {}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  },
  directives,
  methods: {
    getOrderStatusText
  }
})
export default class LinkUnverifyOderModal extends TSXComponent<void> {
  @Prop({ required: false, default: {} })
  unverifyOrder?: any;

  $refs!: {
    modal: HTMLDivElement;
    scrollbar: any;
  };

  loading = false;
  saving: any = false;
  errors: any;

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
    this.$refs.scrollbar.ps.update();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async confirm() {
    this.saving = true;
    this.$emit("confirmUnverifyOrder", this.unverifyOrder);
    // this.$emit("close");
  }
}
