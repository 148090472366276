
















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../models/common";

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class GlobalSecurity extends TSXComponent<void> {

  saving: boolean = false;
  IPWhiteList: string = "";
  IPBlackList: string = "";

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    const response = await ApiHelper.callApi(
      'post',
      {
        controller: "VARAdmin",
        FunctionName: "globalSecurity",
        subsystem: "VAR360"
      }
    );

    if (response.STATUS == 1) {
      if (response.ACCOUNTSECURITY) {
        let accountsecurity = response.ACCOUNTSECURITY;
        this.IPWhiteList = accountsecurity.whitelist_ip || "";
        this.IPBlackList = accountsecurity.blacklist_ip || "";
      }
    }
  }

  async validateBeforeSubmit(e) {
    this.$validator.validateAll().then(async result => {
      let ipv4 = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
      if (this.IPWhiteList.length) {
        let IPWhiteListArr = this.IPWhiteList.split(",");
        let IPWhiteListErrorCount = 0;
        IPWhiteListArr.map(item => {
          if (!ipv4.test(item)) {
            IPWhiteListErrorCount++;
          }
        });
        if (IPWhiteListErrorCount) {
          result = false;
          this.$validator.errors.add({
            field: "IPWhiteList",
            msg: "The IP White List field must be a valid ip address"
          });
        }
      }
      if (this.IPBlackList.length) {
        let IPBlackListArr = this.IPBlackList.split(",");
        let IPBlackListErrorCount = 0;
        IPBlackListArr.map(item => {
          if (!ipv4.test(item)) {
            IPBlackListErrorCount++;
          }
        });
        if (IPBlackListErrorCount) {
          result = false;
          this.$validator.errors.add({
            field: "IPBlackList",
            msg: "The IP Black List field must be a valid ip address"
          });
        }
      }
      if (result) {
        e.preventDefault();
        await this.submit();
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  async submit() {
    this.saving = true;

    const response = await ApiHelper.callApi(
      'post',
      {
        controller: "VARAdmin",
        FunctionName: "updateGlobalSecurity",
        subsystem: "VAR360",
        IPWhiteList: this.IPWhiteList,
        IPBlackList: this.IPBlackList
      }
    );
    if (response.STATUS == 1) {
      notifier.success(response.STATUSMESSAGE);
    } else {
      notifier.alert(response.STATUSMESSAGE || 'Something was wrong');
    }
    
    this.saving = false;
  }

  clearError() {
    this.$validator.errors.clear();
  }

}
