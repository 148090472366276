<template>
<div id="container">
    <add-header></add-header>
    <div id="page-header" class="up">
        <span class="page-title left" data-type="quotes">Quote Request <span class="id-number">RQ090001</span></span>
        <div class="buttonContainer has-link">
            <a href="#/quoteCreate" class="btn teal hide-ipad">Create Quote</a>
            <span class="btn teal right show-ipad btn-submit-mobile"><img src="images/check.png" alt="Icon check"></span>
        </div>
    </div>

    <div id="new-page-header">
        <div class="new-container">
            <div class="box-input-3field">
                <div class="col-1 left up">
                    <span class="title">User</span>
                    <div class="name up"><span class="user-name">Chris Lord</span> <span class="user-org">GoCompany</span></div>
                </div>
                <div class="col-2 ib up">
                    <span class="title">Request Date</span>
                    <span class="time">09/01/2018 12:10am</span>						
                </div>
                <div class="col-2 ib up">
                    <span class="title">Last Update</span>
                    <span class="time">09/01/2018 12:10am</span>
                </div>
                <div class="col-3 ib up">
                    <div class="order-box">
                        <div class="title">Status</div>
                        <div class="progress received"><span></span></div>
                    </div>
                    <div class="order-buttonContainer">
                        <span class="actionsBtn btn teal right">Requested</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="clearfix"></div>

    <div id="page-details" class="quote-request-details">
        <div class="page-container">
            <div class="page-content-white">
                <p>Hi, I need a really good desktop, can you quote me the best business desktop?</p>
                <br />
                <p>-</p>
                <p>I like business desktop <a href="#" title="">1MU73UA#ABA - Sprout Pro by HP G2</a> but with a lower price</p>
                <br />
                <p>Thank you!</p>
                <p>-</p>
                <p>John Egbert</p>
            </div>
        </div>
    </div>

    <div class="clearfix"></div>
    <add-footer></add-footer>
</div>
</template>

<script lang="js">
import {validateFn} from '../helpers'

export default {
  methods: validateFn,
};
</script>
