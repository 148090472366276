

















































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import axios from "axios";
import directives from "../helpers/directives";

import Loader from "./Loader.vue";

export interface Item {
  id: number;
  text: string;
}

interface Props {
  list: Item[];
  loading: boolean;
  max_rows: number;
  indexValue: number;
  users: string;
}

interface Events {}

const MAX_ROWS = 5;

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Loader
  },
  directives
})
export default class ProfitStatusChangeTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: false })
  loading!: boolean;

  @Prop({ required: false, default: false })
  status!: boolean;

  @Prop({ required: false, default: 0.0 })
  profit!: number;

  @Prop({ required: true })
  orderstatus!: number;

  $parent: any;
  
  async statusUpdate() {
    // var type;
    // if (this.status) {
    //   type = 5;
    // } else {
    //   type = this.orderstatus;
    // }
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "orders",
        FunctionName: "orderStatus",
        update: true,
        Id: this.$route.params.id,
        date: new Date(),
        type: (this.status ? 5 : 0),
        source: 0,
        name: "order - " + this.$parent.details.ORDERDETAILS.POID,
        profit: this.profit,
        isPaid : this.status,
        aID: this.$parent.details["ORDERDETAILS"]["AID"]
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      this.$parent.profitStatusVisible = false;
      await this.$parent.reloadDetails();
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
    }
  }

  statusChange() {
    if(this.status == true) {
      this.$emit("profitStatusSwitch", "confirm-paid");
    }
  }
}
