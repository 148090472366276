import axios from "axios";
import { notifier } from "@/models/common";

declare const dataURL: string;
declare const getMaxRows: Function;

export enum SortField {
  paycheckID        = 1,
  User              = 2,
  paycheckName      = 3,
  dateRange         = 4,
  createdDate       = 5,
  commissionPercent = 6,
  payTotal          = 7,
  status            = 8,
}

export type Sort = {
  field: SortField | null
  direction: {
    [SortField.paycheckID]        : 1 | 2,
    [SortField.User]              : 1 | 2,
    [SortField.paycheckName]      : 1 | 2,
    [SortField.dateRange]         : 1 | 2,
    [SortField.createdDate]       : 1 | 2,
    [SortField.commissionPercent] : 1 | 2,
    [SortField.payTotal]          : 1 | 2,
    [SortField.status]            : 1 | 2,
  }
}

export enum DetailSortField {
  total        = 1,
  earning      = 2,
  comPercent   = 3,
  paidTotal    = 4,
  paycheckID   = 5,
  poNumber     = 6,
  saleNo       = 7,
  paycheckName = 8,
}

export class UserPaycheckAPIRepo {
  async find(filters: any, sort: any, pageNumber: number) {
    var requestData = {
      "controller"    			: "Finances",
      "FunctionName"  			: "payCheck",
      "pageNumber"					: pageNumber,
      "maxRows"             : sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : 15,
      "user"								: filters.user,
      "paycheckName"				: filters.paycheckName,
      "payDateRange_sDate"	: filters.payDateRange.sDate,
      "payDateRange_eDate"	: filters.payDateRange.eDate,
      "createdDate_sDate"		: filters.createdDate.sDate,
      "createdDate_eDate"		: filters.createdDate.eDate,
      "paycheckStatus"			: filters.paycheckStatus.join(",")
    }
    
    if(sort.field) {
      requestData["order"] = sort.field;
      requestData["direction"] = sort.direction[sort.field].toString();
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }
  
  async getDetails(pageNumber: number, userID: number, paycheckID: number, filters: any, sort: any) {
    var requestData = {
      "controller"    			: "Finances",
      "FunctionName"  			: "payCheckUserview",
      "pageNumber"					: pageNumber,
      "maxRows"             : sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : 15,
      "empId"               : userID,
      "filters"             : JSON.stringify(filters)
    }

    if(sort.field) {
      requestData["order"] = sort.field;
			requestData["direction"] = sort.direction[sort.field];
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data;
  }
}

