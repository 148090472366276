import { render, staticRenderFns } from "./snoozeAlert.vue?vue&type=template&id=5049b9a6&scoped=true"
import script from "./snoozeAlert.vue?vue&type=script&lang=tsx"
export * from "./snoozeAlert.vue?vue&type=script&lang=tsx"
import style0 from "./snoozeAlert.vue?vue&type=style&index=0&id=5049b9a6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5049b9a6",
  null
  
)

export default component.exports