




















import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Model } from "vue-property-decorator";
import Datepicker from "./Datepicker.vue";

interface Props {
  name: string,
  placeholder: string,
  shippingDate: string
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Datepicker
  }
})
export default class DatePickerTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "" })
  shippingDate!: string;

  @Prop({ required: true, type: String })
  name!: string;

  @Prop({ required: true, type: String })
  placeholder!: string;

  @Model("input", {
    type: String,
    required: true
  })

  change() {
    this.$emit('change', this.shippingDate);
  }

  clickOutside() {
    this.$emit("close");
  }
}
