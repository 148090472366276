// deployment status types
export enum DStatusType {
  Receive = 1,
  Allocate = 2,
  CompleteScheduling = 3,
  Imaging = 4,
  Tagging = 5,
  Packaging = 6,
  Deploy = 7,
  SignOff = 8
}
