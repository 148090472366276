






















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: true,
  components: {}
})

export default class TemplateAssociationsTooltip extends TSXComponent<void> {

  @Prop({ required: false, default: [] })
  allAccounts!: any[];

  @Prop({ required: false, default: "" })
  associations!: string;

  accountIds: number[] = [];
  accountData: any = [];
  totalAccount: number = 0;
  loading: boolean = false;
  
  clickOutside() {
    this.$emit("close");
  }

  async created() {
    this.accountIds = this.associations.split(',').map((id) => {
      return Number.parseInt(id, 10);
    })
    this.accountData = this.allAccounts.filter((item) => {
      return this.accountIds.includes(item.AID);
    });
    this.loading = false;
  }
}
