





















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import ProductAutoSuggestInput from "../components/ProductAutoSuggestInput.vue";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from "../components/pageTitle.vue";
import TabAssetServiceAdd from "@/components/AddProducts/TabAssetServiceAdd.vue";
import TabImportProducts from "@/components/AddProducts/TabImportProducts.vue";

@Component({
  inheritAttrs: false,
  components: {
    TabImportProducts,
    TabAssetServiceAdd,
    ProductAutoSuggestInput,
    LaddaButton,
    PageTitle
  }
})
export default class AddAssetsServices extends TSXComponent<void> {
  isActive = "dashboard";
  created() {
    this.isActive = this.$route.params.type || 'import';
  }

  listPageRedirection() {
    this.$router.push({
      name: "ViewDeployment",
      params: { id: this.$route.params.id, activeTab: this.$route.params.type || '' }
    });
  }
}
