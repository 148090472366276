

















































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier } from "../../models/common";
import poIDAutoSuggestInput from "@/components/poIDAutoSuggestInput.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import PageTitle from "@/components/pageTitle.vue";
import { ApiHelper } from "@/helpers/all";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";

@Component({
  inheritAttrs: false,
  components: {
    ConfirmRemoveItemModal,
    poIDAutoSuggestInput,
    LaddaButton,
    PageTitle
  }
})
export default class TabImportProducts extends TSXComponent<void> {
  @Prop({ required: true })
  deploymentId!: string;

  loading = false;
  purchaseID = "";
  selectedOrdersID: number[] = [];
  details: any = {};
  orderDetails: any = [];
  orderDetailsBK: any = [];
  saving: any = false;
  tieOrder = true;
  deployAll = true;
  // isValid = false;

  updateOrder(orderId) {
    const index = this.selectedOrdersID.findIndex(id => id == orderId);
    if (index === -1) {
      this.selectedOrdersID.push(orderId);
    }
    this.purchaseID = " ";
    this.getOrderDetails();
  }

  deleteSelectedOrders(index) {
    this.$delete(this.selectedOrdersID, index);
    this.getOrderDetails();
  }

  async getOrderDetails() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "Purchases",
        Content: "Detailed",
        purchaseID: this.selectedOrdersID.length
          ? this.selectedOrdersID.join()
          : 0
      });

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.orderDetails = this.details.ORDERDETAILS.map(item => {
          const CATTYPENOTE = item.CATTYPENOTE || "";
          switch (CATTYPENOTE) {
            case "Hardware":
              item.CATEGORYTYPE = 1;
              item.CATEGORYNAME = "Hardware";
              break;
            case "Software":
              item.CATEGORYTYPE = 2;
              item.CATEGORYNAME = "Software";
              break;
            case "Services":
              item.CATEGORYTYPE = 3;
              item.CATEGORYNAME = "Services";
              break;
            case "Warranty":
              item.CATEGORYTYPE = 4;
              item.CATEGORYNAME = "Warranty";
              break;
          }
          switch (item.CATEGORYTYPE) {
            case 1:
              item.PRODUCTTYPE = 1;
              item.TYPE = 1;
              break;
            case 2:
            case 3:
              item.PRODUCTTYPE = 2;
              item.TYPE = 2;
              break;
            case 4:
              item.PRODUCTTYPE = 3;
              item.TYPE = 3;
              break;
          }

          item["productId"] = item.POLIID;
          item["includedItems"] = this.details.ORDERDETAILS.filter(
            t => item.POLIID == t.POLIIDPARENT && t.ISCONFIG
          );
          item["show"] =
            item.ISMAINITEM || (item.POINCLUDED && !item.ISCONFIG)
              ? true
              : false;
          item["ProductTypeSelected"] = item.CATEGORYTYPE;
          item["isDeleted"] = false;
          return item;
        });
        if (this.deployAll) {
          this.orderDetails.forEach(item => {
            item.INCLUDE = item.POPRODQUANTITY;
            // item.isValid = true;
          });
        }
        this.orderDetailsBK = JSON.parse(JSON.stringify(this.orderDetails));
      }
    } catch (err) {
      //handle error
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  modalRemoveProduct: {
    show: boolean;
    isDeleting: boolean;
    doRemove: any;
  } = {
    show: false,
    isDeleting: false,
    doRemove: () => {}
  };

  disableInclude(item) {
    this.modalRemoveProduct.show = true;
    this.modalRemoveProduct.doRemove = () => {
      item.INCLUDE = 0;
      item.isDeleted = true;
      this.modalRemoveProduct.show = false;
    };
  }

  async importProducts() {
    try {
      this.saving = true;
      const productDetails = this.orderDetails.filter(
        item =>
          (item.ISMAINITEM || 0 || (item.POINCLUDED && !item.ISCONFIG)) &&
          !item.isDeleted
      );
      for (const item of productDetails) {
        switch (item.ProductTypeSelected) {
          case 1:
            item.CATEGORYNAME = "Hardware";
            item.TYPE = 1;
            item.PRODUCTTYPE = 1;
            break;
          case 2:
            item.CATEGORYNAME = "Software";
            item.TYPE = 2;
            item.PRODUCTTYPE = 2;
            break;
          case 3:
            item.CATEGORYNAME = "Services";
            item.TYPE = 2;
            item.PRODUCTTYPE = 2;
            break;
          case 4:
            item.CATEGORYNAME = "Warranty";
            item.TYPE = 3;
            item.PRODUCTTYPE = 3;
            break;
          default:
            // nothing
            break;
        }
        item.CATEGORYTYPE = item.ProductTypeSelected;
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "productsImport",
        deploymentId: this.deploymentId,
        productDetails,
        tieOrder: this.tieOrder,
        orderIds: this.selectedOrdersID
      });

      if (response.data.ERROR) {
        this.saving = "error";
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        ApiHelper.updateDeploymentPercent(this.deploymentId);
        this.saving = false;
        this.$router.push({
          name: "ViewDeployment",
          params: { id: this.deploymentId, activeTab: "assets" }
        });
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        this.saving = "error";
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      this.saving = "error";
    }
  }

  listPageRedirection() {
    this.$router.push({
      name: "ViewDeployment",
      params: { id: this.deploymentId }
    });
  }

  tieOrderChange() {
    this.tieOrder = !this.tieOrder;
  }

  deployAllChange() {
    this.orderDetails = JSON.parse(JSON.stringify(this.orderDetailsBK));
    this.deployAll = !this.deployAll;
    // this.isValid = true;
    if (this.deployAll) {
      this.orderDetails.forEach(orderItem => {
        orderItem.INCLUDE = orderItem.POPRODQUANTITY;
        orderItem.isDeleted = false;
      });
    }
  }

  // validateValue(item) {
  //   item.isValid = !(item.INCLUDE > item.QUANTITY || item.INCLUDE <= 0);
  //   this.isValid = !this.orderDetails.some(function(product) {
  //     return product.isValid == false;
  //   });
  // }

  get filledDeployedQty() {
    // get items are not deleted
    const items = (this.orderDetails || []).filter(
      t =>
        (!t.POINCLUDED || (t.POINCLUDED && !t.ISCONFIG)) &&
        !(t.isDeleted || false)
    );
    // and all items need to be valid
    if (
      items.every(
        item => item.INCLUDE >= 1 && item.INCLUDE <= item.POPRODQUANTITY
      )
    ) {
      return true;
    }

    return false;
  }

  get allowImport() {
    let ret = false;
    if (
      (this.deployAll && this.orderDetails.length) ||
      this.filledDeployedQty
    ) {
      ret = true;
    }
    return ret;
  }
}
