




























































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import USState from "../static/USstates.json";
import DropdownControl from "../components/DropdownControl.vue";
import LaddaButton from "../components/LaddaButton.vue";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    DropdownControl,
    LaddaButton
  }
})
export default class CustomerAddressTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "" })
  addNewType?: string;

  @Prop({ required: false, default: 0 })
  aID?: number;

  @Prop({ required: false, default: [] })
  addresses?: any[];

  $parent: any;
  loading = false;
  isChecked = true;
  addrName = "";
  addr1 = "";
  addr2 = "";
  city = "";
  addrState = "";
  addrZip = "";
  stateList: any = [];
  selectedState: string[] = [];

  clickOutside() {
    this.$emit("close");
  }

  created() {
    for(const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
  }
  mounted() {
    $("#customer_address_name").focus();
  }

  selectAllText(id) {
    setTimeout(() => {
      $("#" + id).select();  
    }, 100);
  }

  // async updateOrderTaxRate() {
  //   var isValid = await this.$validator.validateAll();
  //   if(isValid) {
  //     this.$emit("updateOrderTaxRate", this.newTaxRate);
  //     this.$emit("close");
  //   }
  // }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  async addAddress() {
    var result = await this.$validator.validateAll();

    if (this.addresses) {
      const existingAddress = this.addresses.find((addr) => addr.ACCOUNTSADDRESS_ADDRESS1.trim() === this.addr1.trim()
                                                              && addr.ACCOUNTSADDRESS_CITY.trim() === this.city.trim()
                                                              && addr.ACCOUNTSADDRESS_STATE.trim() === this.addrState.trim()
                                                              && addr.ACCOUNTSADDRESS_ZIP.trim() === this.addrZip.trim()
                                                            );
      if (existingAddress) {
        result = false;
        notifier.warning('Adding an existing address is not allowed. Please update.')
      }
    }
    if(result) {
      this.loading = true;
      await this.$emit("addNewAddress", [{
        "NAME": this.addrName.trim(),
        "ADDR1": this.addr1.trim(),
        "ADDR2": this.addr2.trim(),
        "CITY": this.city.trim(),
        "STATE": this.addrState.trim(),
        "ZIP": this.addrZip.trim(),
        "isSave":this.isChecked,
        "ID": 0,
        "ADDRTYPE": 1, // default is shipping address
        "ISDEFAULT": 0,
        "ACCOUNTSADDRESS_ID": "",
        "SELECTEDNETTERM": 30
      }]);
      this.loading = false;
      // this.$emit("close");
    }
  }

  updateState(selectedID) {
    this.selectedState = [selectedID];
    this.addrState = selectedID;
  }
}
