var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <table width=\"100%\" id=\"quoteDetails_notes\">\n        <tr><td colspan=\"2\" class=\"headerColor\">Quote Notes</td></tr>\n        <tr><td colspan=\"2\">&nbsp;</td></tr>\n        <tr>\n            <td colspan=\"2\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteNotes),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "            </td>\n        </tr>\n    </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteNotes), depth0))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.DISPLAYONEXPORT),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":12,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTENOTES),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SPECIALPRICINGID),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":161}}})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " Special Pricing ID: <strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SPECIALPRICINGID), depth0))
    + "</strong><br/><br/>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTENOTES), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.DISPLAYQUOTENOTES),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "");
},"useData":true});