






























































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import moment from "moment";

import LaddaButton from '../components/LaddaButton.vue'
import { notifier, wait, downloadFileUrl } from "../models/common";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Loader from './Loader.vue';
import Datepicker from "./Datepicker.vue";

declare const $: any;
declare const dataURL: string;
declare const getMaxRows: Function;

interface Props {
  ledgersPaymentId: number;
}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ConfirmRemoveItemModal,
    Loader,
    Datepicker
  },
})
export default class LedgerPaymentDetailsModal extends TSXComponent<void> {
  @Prop({ required: true, default: 0 })
  ledgersPaymentId?: number;

  @Prop({required: true})
  title!: string

  loading = false;
  paymentDetails:any = [];

  $refs!: {
    modal: HTMLDivElement;
  };
  isDialogBox = true;

  
  selectedIdPaymentArr:any = [];
  confirmdeletePaymentModal = false;
  deleteOrderID = "";
  deletingDetail:any = [];
  edited = false;
  paymentName = "";
  quickBooks = false;

  today = new Date();
  paidDate = (this.today.getMonth()+1 < 10 ? "0" : "") +(this.today.getMonth() + 1) + "/" + (this.today.getDate() < 10 ? "0" : "") + this.today.getDate() + "/" + this.today.getFullYear();

  mounted() {
    $(this.$refs.modal).modal("show");

    // $(this.$refs.modal).on("hide.bs.modal", () => {
    //   this.$emit("close");
    // });
    this.fetchData();
  }

  async fetchData() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller        : "finances",
        FunctionName      : "ledgerPaymentList",
        ledgersPaymentId  : this.ledgersPaymentId
      });
      this.paymentDetails = response.data.LEDGERPAYMENTDETAILS;
      this.paymentName = this.paymentDetails[0].PAYMENTNAME;
      this.paidDate = this.paymentDetails[0].IPAIDDATE;
      this.quickBooks = this.paymentDetails[0].QUICKBOOKS;
    } catch (err) {
      // console.log(err.message); 
    } finally {
      this.loading = false;
    }
  }  

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async generateConfirmation() {
    const ok = await this.$validator.validateAll();
    if (!ok) {
      return;
    } else {
      this.generate();
    }
  }

  checkExcludedIDs(val) {
    for (var i = 0; i < val.length; i++) {
      if (val[i].DATA_PL_AMOUNT != val[i].PL_AMOUNT || (this.paymentName != "" && this.paymentName != val[i].PAYMENTNAME) || (this.paidDate != "" && this.paidDate != val[i].IPAIDDATE) || this.quickBooks != val[i].QUICKBOOKS) {
        this.edited = true;
        break;
      }
      else {
        this.edited = false;
      }
    }
  }

  async generate() {

    this.loading = true;
    try {
      const payment = {
        controller: "Finances",
        FunctionName: "ledgerPayment",
        action: "EditPayment",
        ledgers: this.paymentDetails,
        paidDate: this.paidDate,
        paymentID: this.paymentDetails[0].PAYMENTID,
        paymentName: this.paymentName,
        quickBooks: this.quickBooks
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", payment);
      if (response.data.STATUS == 1) {
        this.$emit('close');
        this.$emit("ledgerUpdate");
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    }
  }
  confirmDeletePayment(detail) {
    this.confirmdeletePaymentModal = true;
    this.deleteOrderID = detail.PURCHASEID;
    this.deletingDetail = detail;
  }

  async deletePayment() {
    var paymentTotal = 0;
    this.paymentDetails.forEach(item => {
      if(item.PLID !=  this.deletingDetail.PLID)
        paymentTotal += parseFloat(item.PL_AMOUNT);
    });
     try {
      const payment = {
        controller: "Finances",
        FunctionName: "ledgerPayment",
        action: "deletePayment",
        ID: this.deletingDetail.PLID,
        paymentAmount: paymentTotal,
        paymentID: this.deletingDetail.PAYMENTID,
        purchaseID: this.deletingDetail.PURCHASEID
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", payment);
      if (response.data.STATUS == 1) {
        this.confirmdeletePaymentModal = false;
        this.$emit('close');
        this.$emit("ledgerUpdate");
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  async exportCSV() {
    let fileUrl = '';
    this.loading = true;
    try {
      const reqData = {
        controller: "finances",
        FunctionName: "LedgerPaymentDetailExport",
        ledgersPaymentId: this.ledgersPaymentId,
        ExportType: 'CSV',
        exportForm: 'PaymentDetailExport',
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);
      if (response.data.STATUS === 1) {
        fileUrl = response.data.S3URL;
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }

    downloadFileUrl(fileUrl);
  }

  closeModal() {
    this.$emit("close");
  }
}
