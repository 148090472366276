

































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ModalAddScheduleGroup from "@/components/Deployment/ModalAddScheduleGroup.vue";
import { colsResizeable } from "@/helpers/ApiHelper";
import DeploymentScheduleUsersTooltip from "@/components/Deployment/DeploymentScheduleUsersTooltip.vue";
import ModalEditScheduleGroupNotes from "@/components/Deployment/ModalEditScheduleGroupNotes.vue";
import UsersAllocationTooltip from "@/components/UsersAllocationTooltip.vue";

interface Props {
  accounts: [];
  users: [];
}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: { UsersAllocationTooltip, ModalEditScheduleGroupNotes, ModalAddScheduleGroup, DeploymentScheduleUsersTooltip }
})
export default class DeploymentAssets extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: [] })
  items?: any;

  @Prop({ required: false, default: [] })
  allocationTooltipList?: any;

  @Prop({ required: false, default: [] })
  accounts?: any;

  @Prop({ required: false, default: [] })
  users?: any;

  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: 0 })
  allocationTooltipVisibleIndex?: number;

  @Prop({ required: false, default: false })
  allocationTooltipLoading?: boolean;

  @Prop({ required: false, default: false })
  loading?: boolean;

  accountOptions = [];
  usersTooltipVisibleIndex = -1;

  async created() {
    colsResizeable({ ms: 500, selector: '.page-list .page-list-container > table.tbl-assets' });
  }

  removeAssetSelected(deploymentId, item) {
    this.$emit('removeAssetSelected', deploymentId, item);
  }

  async updateAllocation(requestObj) {
    this.$emit('updateAllocation', requestObj);
  }

  updateAllocated(info) {
    this.$emit('updateAllocated', info);
  }

  updateDeploymentPercent(percent) {
    this.$emit('updateDeploymentPercent', percent);
  }

  async showAllocationTooltip(type = "", item, index, e) {
    this.$emit('showAllocationTooltip', type, item, index, e);
  }

  async showIdentifiers(assetDetails) {
    this.$emit('showIdentifiers', assetDetails);
  }




}
