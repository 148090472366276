












import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {},
  methods: {}
})
export default class Demo extends TSXComponent<void> {


  mounted() {
    this.pane = document.getElementById('pane');
    this.ghostpane = document.getElementById('ghostpane');

    // Mouse events
    // this.pane.addEventListener('mousedown', this.onMouseDown);
    document.addEventListener('mousemove', this.onMove);
    document.addEventListener('mouseup', this.onUp);

    // Touch events
    // this.pane.addEventListener('touchstart', this.onTouchDown);
    document.addEventListener('touchmove', this.onTouchMove);
    document.addEventListener('touchend', this.onTouchEnd);
    this.animate();
  }

  pane:any;
  ghostpane:any;

  // Minimum resizable area
  minWidth = 60;
  minHeight = 40;

  // Thresholds
  FULLSCREEN_MARGINS = -10;
  MARGINS = 4;

  // End of what's configurable.
  clicked: any = null;
  onRightEdge;
  onBottomEdge;
  onLeftEdge;
  onTopEdge;
  rightScreenEdge;
  bottomScreenEdge;

  preSnapped;

  b;
  x;
  y;

  redraw = false;

  setBounds(element, x, y, w, h) {
    element.style.left = x + 'px';
    element.style.top = y + 'px';
    element.style.width = w + 'px';
    element.style.height = h + 'px';
  }

  hintHide() {
    this.setBounds(this.ghostpane, this.b.left, this.b.top, this.b.width, this.b.height);
    this.ghostpane.style.opacity = 0;
  }


  onTouchDown(e) {
    console.log('onTouchDown', e);
    this.onDown(e.touches[0]);
    e.preventDefault();
  }

  onTouchMove(e) {
    this.onMove(e.touches[0]);
  }

  onTouchEnd(e) {
    if (e.touches.length ==0) this.onUp(e.changedTouches[0]);
  }

  onMouseDown(e) {
    this.onDown(e);
    e.preventDefault();
  }

  onDown(e) {
    this.calc(e);
    const isResizing = this.onRightEdge || this.onBottomEdge || this.onTopEdge || this.onLeftEdge;

    this.clicked = {
      x: this.x,
      y: this.y,
      cx: e.clientX,
      cy: e.clientY,
      w: this.b.width,
      h: this.b.height,
      isResizing: isResizing,
      isMoving: !isResizing && this.canMove(),
      onTopEdge: this.onTopEdge,
      onLeftEdge: this.onLeftEdge,
      onRightEdge: this.onRightEdge,
      onBottomEdge: this.onBottomEdge
    };
  }

  canMove() {
    return this.x > 0 && this.x < this.b.width && this.y > 0 && this.y < this.b.height
      && this.y < 30;
  }

  calc(e) {
    this.b = this.pane.getBoundingClientRect();
    this.x = e.clientX - this.b.left;
    this.y = e.clientY - this.b.top;

    this.onTopEdge = this.y < this.MARGINS;
    this.onLeftEdge = this.x < this.MARGINS;
    this.onRightEdge = this.x >= this.b.width - this.MARGINS;
    this.onBottomEdge = this.y >= this.b.height - this.MARGINS;

    this.rightScreenEdge = window.innerWidth - this.MARGINS;
    this.bottomScreenEdge = window.innerHeight - this.MARGINS;
  }

  e;

  onMove(ee) {

    this.calc(ee);
    this.e = ee;
    this.redraw = true;
  }

  animate() {
    requestAnimationFrame(this.animate);
    if (!this.redraw) {
      return;
    }

    this.redraw = false;

    if (this.clicked && this.clicked.isResizing) {
      if (this.clicked.onRightEdge) this.pane.style.width = Math.max(this.x, this.minWidth) + 'px';
      if (this.clicked.onBottomEdge) this.pane.style.height = Math.max(this.y, this.minHeight) + 'px';

      if (this.clicked.onLeftEdge) {
        const currentWidth = Math.max(this.clicked.cx - this.e.clientX  + this.clicked.w, this.minWidth);
        if (currentWidth > this.minWidth) {
          this.pane.style.width = currentWidth + 'px';
          this.pane.style.left = this.e.clientX + 'px';
        }
      }

      if (this.clicked.onTopEdge) {
        const currentHeight = Math.max(this.clicked.cy - this.e.clientY  + this.clicked.h, this.minHeight);

        if (currentHeight > this.minHeight) {
          this.pane.style.height = currentHeight + 'px';
          this.pane.style.top = this.e.clientY + 'px';
        }
      }

      this.hintHide();
      return;
    }

    if (this.clicked && this.clicked.isMoving) {
      if (this.b.top < this.FULLSCREEN_MARGINS || this.b.left < this.FULLSCREEN_MARGINS || this.b.right > window.innerWidth - this.FULLSCREEN_MARGINS || this.b.bottom > window.innerHeight - this.FULLSCREEN_MARGINS) {
        // hintFull();
        this.setBounds(this.ghostpane, 0, 0, window.innerWidth, window.innerHeight);
        this.ghostpane.style.opacity = 0.2;
      } else if (this.b.top < this.MARGINS) {
        // hintTop();
        this.setBounds(this.ghostpane, 0, 0, window.innerWidth, window.innerHeight / 2);
        this.ghostpane.style.opacity = 0.2;
      } else if (this.b.left < this.MARGINS) {
        // hintLeft();
        this.setBounds(this.ghostpane, 0, 0, window.innerWidth / 2, window.innerHeight);
        this.ghostpane.style.opacity = 0.2;
      } else if (this.b.right > this.rightScreenEdge) {
        // hintRight();
        this.setBounds(this.ghostpane, window.innerWidth / 2, 0, window.innerWidth / 2, window.innerHeight);
        this.ghostpane.style.opacity = 0.2;
      } else if (this.b.bottom > this.bottomScreenEdge) {
        // hintBottom();
        this.setBounds(this.ghostpane, 0, window.innerHeight / 2, window.innerWidth, window.innerWidth / 2);
        this.ghostpane.style.opacity = 0.2;
      } else {
        this.hintHide();
      }

      if (this.preSnapped) {
        this.setBounds(this.pane,
          this.e.clientX - this.preSnapped.width / 2,
          this.e.clientY - Math.min(this.clicked.y, this.preSnapped.height),
          this.preSnapped.width,
          this.preSnapped.height
        );
        return;
      }

      // moving
      this.pane.style.top = (this.e.clientY - this.clicked.y) + 'px';
      this.pane.style.left = (this.e.clientX - this.clicked.x) + 'px';
      return;
    }

    // This code executes when mouse moves without clicking
    // style cursor
    if (this.onRightEdge && this.onBottomEdge || this.onLeftEdge && this.onTopEdge) {
      this.pane.style.cursor = 'nwse-resize';
    } else if (this.onRightEdge && this.onTopEdge || this.onBottomEdge && this.onLeftEdge) {
      this.pane.style.cursor = 'nesw-resize';
    } else if (this.onRightEdge || this.onLeftEdge) {
      this.pane.style.cursor = 'ew-resize';
    } else if (this.onBottomEdge || this.onTopEdge) {
      this.pane.style.cursor = 'ns-resize';
    } else if (this.canMove()) {
      this.pane.style.cursor = 'move';
    } else {
      this.pane.style.cursor = 'default';
    }
  }



  onUp(e) {
    this.calc(e);

    if (this.clicked && this.clicked.isMoving) {

      // Snap
      const snapped = {
        width: this.b.width,
        height: this.b.height
      };
      if (this.b.top < this.FULLSCREEN_MARGINS || this.b.left < this.FULLSCREEN_MARGINS || this.b.right > window.innerWidth - this.FULLSCREEN_MARGINS || this.b.bottom > window.innerHeight - this.FULLSCREEN_MARGINS) {
        // hintFull();
        this.setBounds(this.pane, 0, 0, window.innerWidth, window.innerHeight);
        this.preSnapped = snapped;
      } else if (this.b.top < this.MARGINS) {
        // hintTop();
        this.setBounds(this.pane, 0, 0, window.innerWidth, window.innerHeight / 2);
        this.preSnapped = snapped;
      } else if (this.b.left < this.MARGINS) {
        // hintLeft();
        this.setBounds(this.pane, 0, 0, window.innerWidth / 2, window.innerHeight);
        this.preSnapped = snapped;
      } else if (this.b.right > this.rightScreenEdge) {
        // hintRight();
        this.setBounds(this.pane, window.innerWidth / 2, 0, window.innerWidth / 2, window.innerHeight);
        this.preSnapped = snapped;
      } else if (this.b.bottom > this.bottomScreenEdge) {
        // hintBottom();
        this.setBounds(this.pane, 0, window.innerHeight / 2, window.innerWidth, window.innerWidth / 2);
        this.preSnapped = snapped;
      } else {
        this.preSnapped = null;
      }
      this.hintHide();
    }
    this.clicked = null;
  }

}
