
























































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from "axios";
import {notifier} from '../models/common'
import LaddaButton from "../components/LaddaButton.vue"
import Loader from './Loader.vue';

interface Props {
  title: string
  currentFilters: any
}

interface Events {
  onClose: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Loader,
  }
})
export default class OrderSearchModal extends TSXComponent<
  Props,
  Events
> {

  @Prop({required: true})
  title!: string

  @Prop({required: true, default: {}})
  currentFilters!: any

  $parent: any;
  saving: any = false;
  loading = false;
  filters: any = {
    customerPO: "",
    customerNumber: "",
    customerName: ""
  };

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })

    if(typeof this.currentFilters.customerPO != "undefined") {
      this.filters.customerPO = this.currentFilters.customerPO;
    }
    if(typeof this.currentFilters.customerNumber != "undefined") {
      this.filters.customerNumber = this.currentFilters.customerNumber;
    }
    if(typeof this.currentFilters.customerName != "undefined") {
      this.filters.customerName = this.currentFilters.customerName;
    }
    if(typeof this.currentFilters.fromDate != "undefined") {
      this.filters.fromDate = this.currentFilters.fromDate;
    }
    if(typeof this.currentFilters.endDate != "undefined") {
      this.filters.endDate = this.currentFilters.endDate;
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async ok() {
    this.$emit('search', this.filters);
  }

  reset() {
    this.filters.customerPO = "";
    this.filters.customerNumber = "";
    this.filters.customerName = "";
  }
}
