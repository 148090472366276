

































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue"
import axios from "axios";

interface Props {
  title: string
  customMessage: string,
  useLaddaButton: boolean
}

interface Events {
  onClose: void
  onConfirm: void
}

declare const $: any
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class InvoiceVoidReason extends TSXComponent<
  Props,
  Events
> {
  @Prop({required: true})
  title!: string

  @Prop({required: false, default: ""})
  customMessage!: string

  @Prop({required: false, default: false})
  useLaddaButton!: boolean

  @Prop({required: false, default: false})
  deleting!: any
  saving = false;  
  iVoidComment = "";

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')
    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async confirm() {
    this.saving = true;
    const invoiceComment = {
      voidReason: this.iVoidComment,
    };
    this.$emit('confirm', invoiceComment);
  }

  close(){
    this.$emit('close')
  }
}
