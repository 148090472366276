




























































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
declare const getImageRouteData: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class serviceDetails extends TSXComponent<void> {
  cost = "";
  targetDate = "";
  progress = 1;
  currentRole = sessionStorage.getItem("userRole");
  updateObj = {};
  loading = false;
  created() {
    this.updateObj["controller"] = "Services";
    this.updateObj["FunctionName"] = "Update";
  }
  // methods: {
  mounted() {
    this.showDatePicker();
    this.targetDateChange();
  }
  updateProgress(value) {
    this.progress = value;
    this.update("sStatus", value);
  }
  update(key, value) {
    this.updateObj[key] = value;
    this.updateObj["serviceID"] = this.$route.params.id;
    this.fetchServiceData(this.updateObj, this, "", "", "");
  }
  fetchServiceData(dataObj, container, keyName, fieldname, option) {
    var ServicesData = getRouteData(dataObj);
    //now attach a done handler!
    ServicesData.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS && response.data.STATUSMESSAGE) {
          container.serviceItems = response.data.SERVICES || [];
          container.details = response.data || {};
          container.loading = false;
          if (option === "new") {
            container.details["sortingId"] = 1;
            container.details["sortingTotal"] = 1;
          } else if (option !== "page") {
            container.details[keyName] = dataObj[fieldname];
          }
        } else if (response.data.ERROR) {
          console.log(response.data.ERROR);
        }
      } catch (e) {
        //handle error
        console.log("services.js : grabServicesData error : ", e);
      }
    }).catch(function(error) {
      console.log(error);
    });
  }
  targetDateChange() {
    var $this = this;
    $(document).on("change", "input", function(this:Window,event) {
      if (event.target.id === "targetDate") {
        var value = $(this).val();
        $this.update("targetDate", value);
        $(".new-quote-date").removeClass("hide");
        $(".new-quote-date-input").removeClass("active");
      }
    });
  }

  showDatePicker() {
    var html = "";
    html += '<div id="datepicker" class="datepicker-container">';
    html +=
      '<div class="datepicker-panel" data-view="years picker"><ul class="fr-current"><li data-view="years prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="years current"></li><li data-view="years next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="years"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="months picker"><ul class="fr-current"><li data-view="year prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="year current"></li><li data-view="year next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="months"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="days picker"><ul class="fr-current"><li data-view="month prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="month current"></li><li data-view="month next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="week"></ul><ul data-view="days"></ul></div>';
    html += "</div>";

    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      zIndex: 2048,
      daysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      template: html
    });
  }

  grabViewServicesData(container) {
    var dataObj = {
      controller: "Services",
      Content: "Detailed",
      serviceID: container.$route.params.id
    };

    if (container.$route.params.id != 0) {
      dataObj["FunctionName"] = "View";
      let serviceViewData = getRouteData(dataObj);
      serviceViewData
        .then(function(response, statusText, jqXHR) {
          try {
            if (response.data.STATUS && response.data.STATUSMESSAGE) {
              container.items = response.data.SERVICEDETAILS || [];
              container.items["SERVICENOTESLIST"] =
                response.data.SERVICENOTESLIST;
              container.loading = false;
              // container.productDetails = response.data.HARDWAREPRODUCTDETAILS || [];
              container.details = response.data;
            } else if (!response.data.STATUS && response.data.STATUSMESSAGE) {
              container.notification("info", response.data.STATUSMESSAGE);
            } else if (response.data.ERROR) {
              console.log(response.data.ERROR);
            }
          } catch (e) {
            //handle error
            console.log("service.js : grabServiesData error : ", e);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }

  pdfDownLoadFn(id) {
    var exportObj = {
      controller: "Services",
      FunctionName: "ExportView",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      Content: "Detailed",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "PDF";
    exportObj["serviceID"] = this.$route.params.id;
    this.downloadProcess(exportObj, this);
  }

  printFn(selectedID) {
    var exportObj = {
      controller: "Services",
      FunctionName: "ExportView",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      Content: "Detailed",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "PDF";
    exportObj["Print"] = "Yes";
    exportObj["serviceID"] = this.$route.params.id;
    printProcess(exportObj);
  }

  downloadProcess(data, container) {
    var downLoadRes = getRouteData(data);
    downLoadRes.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS) {
          var link = document.createElement("a");
          link.download = "Quotes";
          link.href = response.data.S3URL;
          link.click();
        } else if (response.data.ERROR) {
          console.log(response.data.ERROR);
        }
      } catch (e) {
        //handle error
        console.log("hardware.js : csvDownLoadFn error : ", e);
      }
    });
  }

  addComments(comment) {
    var exportObj = {
      controller: "Services",
      FunctionName: "ExportView",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      Content: "Detailed",
      userRole: sessionStorage.getItem("userRole")
    }
    $("#serviceComment").val("");
    var insertObj = {
      controller: "Services",
      FunctionName: "Notes",
      serviceID: this.$route.params.id,
      content: comment
    }
    var $this = this;
    let viewData = getRouteData(insertObj);
    viewData.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS && response.data.STATUSMESSAGE) {
          $this.grabViewServicesData($this);
        }
      } catch (e) {
        //handle error
        console.log("supports.js : grabOrderData error : ", e);
      }
    });
  }

  // }

  @Watch("cost", { deep: true })
  costfunc(to, from) {
    this.update("price", parseFloat(to.replace(/\$|,/g, "")));
  }
  @Watch("items", { deep: true })
  handlerfunc(val, oldVal) {
    this.progress = val.SSTATUS;
  }
}
