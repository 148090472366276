





































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "@/models/common";
import LaddaButton from "@/components/LaddaButton.vue";
import Inputmask from "inputmask";
import USState from "../static/USstates.json";
import DropdownControl from "@/components/DropdownControl.vue";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import axios from "axios";

declare const dataURL: string;
declare const $: any;
declare const htmlParse: Function;
declare const htmlCheck: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl,
    FormMultiSelect
  }
})
export default class SubOrderQuoteEndUser extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: {} })
  selectedSubOrder!: any;

  @Prop({ required: false, default: 0 })
  purchaseId!: number;

  @Prop({ required: false, default: {} })
  selectedSubQuote!: any;

  @Prop({ required: false, default: "" })
  quoteId!: string;

  @Prop({ required: false, default: 0 })
  aId!: number;

  @Prop({ required: false, default: false })
  hideApiCall!: boolean;

  loading = false;
  $parent: any;
  $route: any;
  // savedCD: any = [];
  saving: boolean | "error" = false;
  euInfo: any = {
    euId: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addr: "",
    addr2: "",
    city: "",
    state: "",
    zip: ""
  };
  savedInfo: any = {};
  stateList: any = [];
  selectedState: string[] = [];
  currentAddr1 = "";
  changeInfoOptions = [
    {
      id: "Quotes",
      text: "Quotes"
    },
    {
      id: "Orders",
      text: "Orders"
    },
    {
      id: "Hardware",
      text: "Hardware"
    },
    {
      id: "Deployments",
      text: "Deployments"
    }
  ];
  // end user change info setting
  euSettingsBk: any = {};
  euSettings: any = {
    selectedChangeInfoIds: [],
    rememberSetting: false
  };

  async mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    // get change info setting by account
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Accounts",
      FunctionName: "GetParams",
      aId: this.aId,
      key: "selectedChangeInfoIds"
    });
    if (response.data.STATUS == 1 && "keyValue" in response.data) {
      const selectedChangeInfoIds = response.data.keyValue || "";
      this.euSettings.selectedChangeInfoIds = selectedChangeInfoIds
        ? selectedChangeInfoIds.split(",")
        : [];
    }
    this.euSettingsBk = JSON.parse(JSON.stringify(this.euSettings));

    // build states list
    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }

    // mask for phone
    $("input[inputmask=phonenumber]").each(function(i, obj) {
      Inputmask("(999) 999-9999", {}).mask(obj);
    });

    if (
      this.purchaseId > 0 ||
      (this.hideApiCall && Object.keys(this.selectedSubOrder).length)
    ) {
      // from order details page
      const fullName = this.selectedSubOrder.euInfo.EUNAME || "";
      let firstName = this.selectedSubOrder.euInfo.FIRSTNAME || "";
      let lastName = this.selectedSubOrder.euInfo.LASTNAME || "";
      if (firstName == "" && lastName == "" && fullName != "") {
        const index = fullName.indexOf(" ");
        if (index != -1) {
          firstName = fullName.substring(0, index).trim();
          lastName = fullName.substring(index).trim();
        } else {
          firstName = fullName;
        }
      }

      this.euInfo = {
        euId: this.selectedSubOrder.euInfo.EUID || 0,
        firstName,
        lastName,
        email: this.selectedSubOrder.euInfo.EMAIL || "",
        phone: this.selectedSubOrder.euInfo.PHONE || "",
        addr: this.selectedSubOrder.euInfo.ADDR1 || "",
        addr2: this.selectedSubOrder.euInfo.ADDR2 || "",
        city: this.selectedSubOrder.euInfo.CITY || "",
        state: this.selectedSubOrder.euInfo.EUSTATE || "",
        zip: this.selectedSubOrder.euInfo.ZIP || ""
      };
    } else if (
      this.quoteId != "" ||
      (this.hideApiCall && Object.keys(this.selectedSubQuote).length)
    ) {
      // from quote details page
      const fullName = this.selectedSubQuote.euInfo.EUNAME || "";
      let firstName = this.selectedSubQuote.euInfo.FIRSTNAME || "";
      let lastName = this.selectedSubQuote.euInfo.LASTNAME || "";
      if (firstName == "" && lastName == "" && fullName != "") {
        const index = fullName.indexOf(" ");
        if (index != -1) {
          firstName = fullName.substring(0, index).trim();
          lastName = fullName.substring(index).trim();
        } else {
          firstName = fullName;
        }
      }

      this.euInfo = {
        euId: this.selectedSubQuote.euInfo.EUID || 0,
        firstName,
        lastName,
        email: this.selectedSubQuote.euInfo.EMAIL || "",
        phone: this.selectedSubQuote.euInfo.PHONE || "",
        addr: this.selectedSubQuote.euInfo.ADDR1 || "",
        addr2: this.selectedSubQuote.euInfo.ADDR2 || "",
        city: this.selectedSubQuote.euInfo.CITY || "",
        state: this.selectedSubQuote.euInfo.EUSTATE || "",
        zip: this.selectedSubQuote.euInfo.ZIP || ""
      };
    }

    this.savedInfo = JSON.parse(JSON.stringify(this.euInfo));
    this.selectedState = [this.euInfo.state];
    this.currentAddr1 = this.euInfo.addr;

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  get changedValues() {
    if (
      JSON.stringify(this.savedInfo) != JSON.stringify(this.euInfo) ||
      JSON.stringify(this.euSettingsBk) != JSON.stringify(this.euSettings)
    ) {
      return true;
    }

    return false;
  }

  async saveEUInfo() {
    // remove HTML
    // const euData = this.selectedSubOrder.euCD.filter(t => t.isEUData == 1);
    // for (const item of euData) {
    //   if ((item.customDtype || 1) != 3) {
    //     item.customValue = this.removeHTML(item.customValue);
    //   }
    // }

    // validate
    const valid = await this.$validator.validateAll();
    if (!valid) {
      return;
    }

    try {
      this.saving = true;

      if (this.purchaseId > 0) {
        // from order details page
        const response = await ApiHelper.callApi("post", {
          Controller: "Orders",
          FunctionName: "UpdateSubOrderEndUser",
          euInfo: this.euInfo,
          subOrderId: this.selectedSubOrder.subOrderId,
          purchaseId: this.purchaseId,
          aId: this.aId,
          currentAddr1: this.currentAddr1,
          selectedChangeInfoIds: this.euSettings.selectedChangeInfoIds.join(
            ","
          ),
          rememberSetting: this.euSettings.rememberSetting,
          fromSub: "Order"
        });
        this.saving = false;

        if (response.STATUS == 1) {
          this.selectedSubOrder.euInfo.EUNAME = `${this.euInfo.firstName} ${
            this.euInfo.lastName
          }`.trim();
          this.selectedSubOrder.euInfo.FIRSTNAME = this.euInfo.firstName;
          this.selectedSubOrder.euInfo.LASTNAME = this.euInfo.lastName;
          this.selectedSubOrder.euInfo.EMAIL = this.euInfo.email;
          this.selectedSubOrder.euInfo.PHONE = this.euInfo.phone;
          this.selectedSubOrder.euInfo.ADDR1 = this.euInfo.addr;
          this.selectedSubOrder.euInfo.ADDR2 = this.euInfo.addr2;
          this.selectedSubOrder.euInfo.CITY = this.euInfo.city;
          this.selectedSubOrder.euInfo.EUSTATE = this.euInfo.state;
          this.selectedSubOrder.euInfo.ZIP = this.euInfo.zip;

          notifier.success(response.STATUSMESSAGE);
        }
      } else if (this.quoteId != "") {
        // from quote details page
        const response = await ApiHelper.callApi("post", {
          Controller: "Orders",
          FunctionName: "UpdateSubOrderEndUser",
          euInfo: this.euInfo,
          subQuoteId: this.selectedSubQuote.subQuoteId,
          quoteId: this.quoteId,
          aId: this.aId,
          currentAddr1: this.currentAddr1,
          selectedChangeInfoIds: this.euSettings.selectedChangeInfoIds.join(
            ","
          ),
          rememberSetting: this.euSettings.rememberSetting,
          fromSub: "Quote"
        });
        this.saving = false;

        if (response.STATUS == 1) {
          this.selectedSubQuote.euInfo.EUNAME = `${this.euInfo.firstName} ${
            this.euInfo.lastName
          }`.trim();
          this.selectedSubQuote.euInfo.FIRSTNAME = this.euInfo.firstName;
          this.selectedSubQuote.euInfo.LASTNAME = this.euInfo.lastName;
          this.selectedSubQuote.euInfo.EMAIL = this.euInfo.email;
          this.selectedSubQuote.euInfo.PHONE = this.euInfo.phone;
          this.selectedSubQuote.euInfo.ADDR1 = this.euInfo.addr;
          this.selectedSubQuote.euInfo.ADDR2 = this.euInfo.addr2;
          this.selectedSubQuote.euInfo.CITY = this.euInfo.city;
          this.selectedSubQuote.euInfo.EUSTATE = this.euInfo.state;
          this.selectedSubQuote.euInfo.ZIP = this.euInfo.zip;

          notifier.success(response.STATUSMESSAGE);
        }
      } else if (this.hideApiCall) {
        this.saving = false;

        let euName = `${this.euInfo.firstName} ${this.euInfo.lastName}`.trim();
        let firstName = this.euInfo.firstName;
        let lastName = this.euInfo.lastName;
        let email = this.euInfo.email;
        let phone = this.euInfo.phone;
        let addr = this.euInfo.addr;
        let addr2 = this.euInfo.addr2;
        let city = this.euInfo.city;
        let state = this.euInfo.state;
        let zip = this.euInfo.zip;
        if (Object.keys(this.selectedSubOrder).length) {
          this.selectedSubOrder.euInfo.EUNAME = euName;
          this.selectedSubOrder.euInfo.FIRSTNAME = firstName;
          this.selectedSubOrder.euInfo.LASTNAME = lastName;
          this.selectedSubOrder.euInfo.EMAIL = email;
          this.selectedSubOrder.euInfo.PHONE = phone;
          this.selectedSubOrder.euInfo.ADDR1 = addr;
          this.selectedSubOrder.euInfo.ADDR2 = addr2;
          this.selectedSubOrder.euInfo.CITY = city;
          this.selectedSubOrder.euInfo.EUSTATE = state;
          this.selectedSubOrder.euInfo.ZIP = zip;
        } else if (Object.keys(this.selectedSubQuote).length) {
          this.selectedSubQuote.euInfo.EUNAME = euName;
          this.selectedSubQuote.euInfo.FIRSTNAME = firstName;
          this.selectedSubQuote.euInfo.LASTNAME = lastName;
          this.selectedSubQuote.euInfo.EMAIL = email;
          this.selectedSubQuote.euInfo.PHONE = phone;
          this.selectedSubQuote.euInfo.ADDR1 = addr;
          this.selectedSubQuote.euInfo.ADDR2 = addr2;
          this.selectedSubQuote.euInfo.CITY = city;
          this.selectedSubQuote.euInfo.EUSTATE = state;
          this.selectedSubQuote.euInfo.ZIP = zip;
        }

        notifier.success("Updated successfully");
      }

      // update saved values
      this.$nextTick().then(() => {
        this.savedInfo = JSON.parse(JSON.stringify(this.euInfo));
        this.euSettingsBk = JSON.parse(JSON.stringify(this.euSettings));
        this.currentAddr1 = this.euInfo.addr;
      });
    } catch (err) {
      this.saving = "error";
      console.log(err);
    }
  }

  toggleChangeInfoOption(id, text) {
    if (!this.euSettings.selectedChangeInfoIds.includes(id)) {
      this.euSettings.selectedChangeInfoIds.push(id);
    } else {
      this.euSettings.selectedChangeInfoIds = this.euSettings.selectedChangeInfoIds.filter(
        t => t != id
      );
    }
    // sort
    const ret: any = [];
    for (const t of this.changeInfoOptions) {
      if (this.euSettings.selectedChangeInfoIds.includes(t.id)) {
        ret.push(t.id);
      }
    }
    this.euSettings.selectedChangeInfoIds = ret;
  }
}
