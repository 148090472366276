
























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface MultiselectOption {
    ID: string;
    TEXT: string;
}

interface Props {
  options: MultiselectOption[];
  selectedIds: string[];
  isSingleSelect: boolean;
  disabled: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class GeneralMultiselect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: MultiselectOption[];

  @Prop({ required: true })
  selectedOptionIds!: string[];

  @Prop({ required: false, default: false })
  isSingleSelect?: boolean;

  @Prop({ required: true })
  label!: string;

  @Prop({ required: false, default: '' })
  placeholder!: string;

  @Prop({ required: false, default: false })
  disabled?: boolean;

  @Prop({ required: false, default: false })
  highlightOptions?: boolean;

  dropdownVisible = false
  search = ''
  selectedOptionText = this.placeholder;
  showOnlySelected: boolean = false

  get filteredOptions(): MultiselectOption[] {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => option.TEXT.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }

    if (this.showOnlySelected) {
      options = options.filter((o) => this.selectedOptionIds.indexOf(o.ID) !== -1)
    }

    return options
  }

  toggleDropdown() {
    if(!this.disabled) {
      this.dropdownVisible = !this.dropdownVisible
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(optionId: string): boolean {
    const checked = !!this.selectedOptionIds.find((id) => id === optionId)
    return checked
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  selectOption(option: MultiselectOption){
    this.selectedOptionText = option.TEXT;
    this.$emit('check', option.ID, option.TEXT);
    this.dropdownVisible = false;
  }
}
