









import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
}

interface Events {}

@Component({
  inheritAttrs: true,
})
export default class PriceWarnTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: "Please update pricing to reflect the new bundle price for included items." })
  title?: string;
}
