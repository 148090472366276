















































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader
  }
})
export default class ProductCatTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "" })
  currentTaxRate!: string;

  $parent: any;
  loading = false;
  newTaxRate = "0";

  clickOutside() {
    this.$emit("close");
  }

  created() {
    this.newTaxRate = parseFloat(this.currentTaxRate).toFixed(4);
    this.selectAllText("taxRate");
  }

  selectAllText(id) {
    setTimeout(() => {
      $("#" + id).select();  
    }, 100);
  }

  async updateOrderTaxRate() {
    var isValid = await this.$validator.validateAll();
    if(isValid) {
      this.$emit("updateOrderTaxRate", this.newTaxRate);
      this.$emit("close");
    }
  }
}
