












































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: StatusValue;
}

interface Events {
  onChangeStatus: StatusValue
}

export enum StatusValue {
  Active = 1,
  InActive = 0
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class PortalStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: StatusValue;

  popoverVisible = false
  optionsVisible = false

  get iconClass() {
    return this.status == StatusValue.Active ? 'green' : (this.status == StatusValue.InActive)? 'red' : 'red'
  }

  get statusText() {
    return this.status == StatusValue.Active ? 'Active' : (this.status == StatusValue.InActive)? 'Inactive' : 'Deleted'
  }
  
  hideTooltip() {
    this.optionsVisible = false;
  }

  mouseover() {
    if (this.optionsVisible) {
      return
    }

    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false
    
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: StatusValue) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }
}
