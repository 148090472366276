















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import directives from "../helpers/directives";
import { ApiHelper } from "@/helpers/all";

import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";

interface Props {
  defaultText?: string
  value: string
}

interface Events {}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
  directives
})
export default class opportunityAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  show?: boolean

  @Prop({ required: false, default : 0 })
  aID?: number

  @Prop({ required: false, default: "opportunity"})
  placeholderText?: string;

  @Prop({ required: false, default: "bottom" })
  tooltipPosition!: string;

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = []
  searchData: any = []

  createOptions(opportunity: any) {
    const options = opportunity.map(
      (item): Option => {
        return {
          value: item.MS_OPP_ID,
          text: item.DESCRIPTION ? item.DESCRIPTION : `MOP-${item.MS_OPP_ID}`
        };
      }
    )

    this.options = options
    this.searchData = opportunity

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!this.aID) {
      return {
        options: []
      };
    }
    if (!search) {
      const response = await ApiHelper.callApi("post", {
        FunctionName      : "List",
        controller        : "Opportunities",
        queryType         : "top5UsedOpportunities",
        aID               : this.aID || 0
      });
      if (response.ERROR || !response.OPPORTUNITIES) {
        return {
          options: []
        };
      }

      return this.createOptions(response.OPPORTUNITIES)
    }

    const exportObj = {
      FunctionName      : "List",
      controller        : "Opportunities",
      aID               : this.aID || 0,
      search            : search.length ? search.replace('MOP-', '') : ''
    };

    const response = await ApiHelper.callApi("post", exportObj);
    if (response.ERROR || !response.OPPORTUNITIES) {
      return {
        options: []
      };
    }

    return this.createOptions(response.OPPORTUNITIES)
  };

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      //console.log('this.options', this.options)
      //console.warn(`no option found for value[${value}]`)
      return
    }
    const opportunity = this.searchData.find((item) => item.MS_OPP_ID.toString() === value.toString())
    if (!opportunity) {
      // console.warn(`no Order and Quote found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      opportunity,
    })
  }
}
