
























import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  selectedIDs: string[]
}

interface Events {
  onClose: void
  onConfirm: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {}
})
export default class ConfirmRemoveItemModal extends TSXComponent<Props,Events> {
  @Prop({required: true})
  selectedIDs!: string[]

  saving = false

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  confirm() {
    this.saving = true
    this.$emit('mergeConfirm')
  }

  cancel() {
    this.$emit('closeMergeConfirm')
  }
}
