






















































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier, printHtml } from "../models/common";
import axios from "axios";

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class ApiExternal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: 0 })
  sourceId?: number;

  @Prop({ required: false, default: "" })
  PageType?: string;

  @Prop({ required: false, default: "" })
  Email?: string;

  @Prop({ required: false, default: false })
  loading?: boolean | string;

  errors: any;

  mounted() {
    $(this.$refs.modal).modal("show");
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  ok() {
    // validate
    if (this.ssShow) {
      // send to SS, validate approver email
      if (this.Email != "" && this.errors.has("approverEmail")) {
        return;
      }
    }

    this.$emit("confirm");
  }

  clickOutside() {
    this.$emit("close");
  }

  ssShow: boolean = false;

  showSScustom() {
    if (this.sourceId === 1) {
      this.ssShow = true;
    } else {
      this.ssShow = false;
    }
  }

  updateEmail() {
    this.$emit("email-updated", this.Email);
  }
}
