







































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import log from "../helpers/log";
import axios from "axios";
import { ErrorBag } from "vee-validate";
import { notifier, wait } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class signup extends TSXComponent<void> {
  items = {
    accname: "",
    fname: "",
    lname: "",
    email: "",
    address: "",
    address1: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    company: "",
    position: "",
    domainName: "",
    domain: "var360.com"
  };
  domains = [];
  loading: any = false;
  isEmailExists = false;
  isDomainExists = false;
  async created() {
    const domainRes = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Domain",
      FunctionName: "list"
    });
    this.domains = domainRes.data.DOMAINS;
  }

  htmlRemove(field) {
    if (htmlCheck(this.items[field])) {
      this.items[field] = htmlParse(this.items[field]);
      notifier.alert("HTML content found. This content has been removed!");
    }
  }

  async submit(e) {
    e.preventDefault();
    e.stopPropagation();
    // var result = true;
    this.loading = true;
    this.$validator.validateAll().then(async result => {
      var errorTab = 0;
      if (result) {
        try {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "signup",
            FunctionName: "signup",
            AccName: this.items.accname,
            fname: this.items.fname,
            lname: this.items.lname,
            email: this.items.email,
            address: this.items.address,
            address1: this.items.address1,
            city: this.items.city,
            state: this.items.state,
            zip: this.items.zip,
            country: this.items.country,
            company: this.items.company,
            position: this.items.position,
            domain: this.items.domainName + "." + this.items.domain
          });
          if (response.data.ERROR) {
            log.error(response.data.ERROR);
            this.loading = "error";
            return false;
          }
          if (response.data.STATUS == 1) {
            notifier.success(response.data.MESSAGE);
            this.isEmailExists = false;
            this.isDomainExists = false;
            await wait(1000);
            this.$router.push({ name: "Login" });
            this.loading = false;
          } else if (response.data.STATUS == 2) {
            this.isEmailExists = true;
            this.loading = "error";
          } else if (response.data.STATUS == 3) {
            this.isDomainExists = true;
            this.loading = "error";
          } else if (response.data.STATUS !== 1) {
            log.error(response.data.MESSAGE);
            this.loading = "error";
            return false;
          }
        } catch (e) {
          log.debug("loadCommission", e);
          this.loading = "error";
        } finally {
          // this.loading = 'error';
        }
      } else {
        this.loading = "error";
      }
    });
  }
}
