







































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier, downloadFile, printHtml } from "../models/common";
import Pagination from "../components/Pagination.vue";
import FileOptions from "../components/FileOptionsModal.vue";

declare const dataURL: string;
declare const getMaxRows: Function;

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    FileOptions,
    Pagination
  }
})
export default class Users extends TSXComponent<void> {
  loading = false;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["files"] ? JSON.parse(sessionStorage.pageDetails)["files"] : 1 ;

  details: {
    CURRENTPAGE: number;
    TOTALPAGES: number;
  } = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
  };
  // details: any = [];
  files = [];
  fileoption = -1;

  async created() {
    await this.fetchFiles();
  }


  async fetchFiles() {
    this.loading = true;
    var dataObj = {
      "controller"    : "Files",
      "FunctionName"  : "List",
      "pageNumber"    : this.pageNumber,
      "maxRows"       : 15
  }
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    if (response.data.STATUS) {
      this.loading = false;
      this.files = response.data.FILES || [];
      this.details = response.data;
    } else if (response.data.ERROR) {
      console.log(response.data.ERROR);
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    return response.data;
  }

  loadPage(pageNumber:number, type) {
    if (type === "prev") {
      pageNumber = pageNumber - 1;
    } else if (type === "next") {
      pageNumber = pageNumber + 1;
    }

    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["files"]) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["files"] = pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (pageNumber <= this.details.TOTALPAGES && pageNumber >= 1) {
        this.pageNumber = pageNumber;
        this.fetchFiles();
    }
  }


}
