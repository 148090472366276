import { render, staticRenderFns } from "./AddToScheduleGroupTooltip.vue?vue&type=template&id=48dc061b&scoped=true"
import script from "./AddToScheduleGroupTooltip.vue?vue&type=script&lang=tsx"
export * from "./AddToScheduleGroupTooltip.vue?vue&type=script&lang=tsx"
import style0 from "./AddToScheduleGroupTooltip.vue?vue&type=style&index=0&id=48dc061b&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48dc061b",
  null
  
)

export default component.exports