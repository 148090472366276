


































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import GlobalTagSelect from "../components/GlobalTagSelect.vue";
import Datepicker from '../components/Datepicker.vue'
import { ApiHelper } from "../helpers/all";
import directives from "../helpers/directives";
import PageTitle from "../components/pageTitle.vue";
import DropdownControl from "../components/DropdownControl.vue";
import StandardItems from "../components/Quote/StandardItems.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import AccountSelector from "../components/AccountSelector.vue";
import axios from "axios";
import { getRandomNumber } from "@/helpers/ApiHelper";
import Footer from "../components/Footer.vue";

declare const getImageRouteData: any;

const findParent = (index: string, itemNames: any) => {
  const currentItem = itemNames[index];
  if (!currentItem || !(currentItem.included || 0)) return null;

  // find parent for an included item
  let parent: any = null;
  const keys = Object.keys(itemNames);
  for (let i = 0; i <= keys.length - 1; i++) {
    const item = itemNames[keys[i]];
    if (!(item.included || 0)) {
      parent = item;
    }
    if (index == keys[i]) {
      break;
    }
  }

  return parent;
};

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ConfirmRemoveItemModal,
    StandardItems,
    DropdownControl,
    AccountSelector,
    PageTitle,
    GlobalTagSelect,
    Datepicker
  },
  directives
})
export default class QuoteStandardEdit extends TSXComponent<void> {
  $decimals: any;
  $refs!: {
    standardItems: StandardItems
  }
  autoSum = false;
  grandTotal = 0;
  loading = false;
  isSubmitting = false;
  formData: {
    controls: {
      id: {
        value: string;
      }
      name: {
        label: string;
        placeholder: string;
        value: string;
      }
      specialPricingID: {
        label: string;
        placeholder: string;
        value: string;
      }
      endOfLife: {
        label: string;
        value: string;
      }
      updateDate: {
        label: string;
        value: string;
      }
      createDate: {
        label: string;
        value: string;
      }
      accounts: {
        label: string;
        value: number[];
        options: any[];
      }
      useItemEOL : {
        label: string;
        value: number;
        options: any[];
      }
      quoteAfterEOL: {
        label: string;
        value: number;
        options: any[];
      }
      autoSum: {
        label: string;
        value: boolean;
        options: any[];
      }
      groups: {
        label: string;
        value: number[];
        options: any[];
      }
      status: {
        label: string;
        value: number[];
        options: any[];
      }
      total: {
        label: string;
        value: any;
      }
    }
  } = {
    controls: {
      id: {
        value: 0
      },
      specialPricingID: {
        placeholder: 'Special Pricing ID',
        label: 'Special Pricing ID',
        value: ''
      },
      name: {
        label: 'Standard Name',
        placeholder: 'Enter your Standard Name',
        value: ''
      },
      endOfLife: {
        label: '',
        value: ''
      },
      updateDate: {
        label: '',
        value: ''
      },
      createDate: {
        label: '',
        value: ''
      },
      accounts: {
        label: '',
        value: [],
        options: []
      },
      useItemEOL: {
        label: '',
        value: 0,
        options: []
      },
      quoteAfterEOL: {
        label: '',
        value: 0,
        options: []
      },
      autoSum: {
        label: '',
        value: false,
        options: []
      },
      status: {
        label: '',
        value: [1], // default status is Active
        options: []
      },
      groups: {
        label: '',
        value: [],
        options: []
      },
      total: {
        label: '',
        value: ''
      }
    }
  };

  confirmRemoveModal = {
    title: '',
    show: false,
    callback: () => {
    }
  };

  itemNames: {
    id: number,
    hasError?: boolean;
    name?: string;
    nameError?: string;
    price?: any;
    priceBk?: any;
    priceError?: string;
    costPriceError?: string;
    customerPrice?: any;
    sku?: string;
    skuError?: string;
    quantity?: number;
    quantityBk?: number;
    quantityError?: string;
    included?: number;
    total?: any;
    taxRate?: any;
    margin?: any;
    marginRate?: any;
    tax?: any;
    priceWarn?: boolean;
    noTax?: boolean;
    shipping?: number;
    totalProducts?: number;
    configID?: string;
    DistyPrice?: number;
    DistyAvailability?: number;
    DistyPrice_Format?: string;
    DistyAvailability_Format?: string;
    Disty?: string;
    orgPrice?: any;
    orgCustomerPrice?: any;
    disable?: boolean;
    isContractFee?: boolean;
    includedparent: number;
    baseProductId: number;
    includeItemCount: number;
    parentLiId?: number;
    quoteLiId?: number;
    isFee?: boolean;
    isDeleted?: boolean;
    endOfLifeDate?: string;
    ItemCategory?: number;
    ItemCategoryName?: string;
    ISCONFIG?: boolean;
    savedAsConfig?: boolean;
    ItemPLID?: number;
    ItemPLName?: string;
  }[] = [];

  accounts = [];

  includedItem = false;
  // includedPrice = false;
  lineItemErrorMessage: string = '';
  standardUUID: string = "";
  productCatTTVisibleIndex = -1;
  configCategory: any = {};
  autoName = 1;
  autoCost = 0;
  sourceID = 0;
  uploading: any = false;
  errorMsg: string = "";
  expandDecimal: boolean = false;
  expandDecimalPrecision: number = 2;
  quoteId: number = 0

  // idxOf(index: string): number {
  //   return Object.keys(this.itemNames).indexOf(index);
  // }

  // showRollUp() {
  //   const findIncludedItem = this.itemNames.find((item) => item.included && !item.isDeleted);
  //   return !!findIncludedItem;
  // }

  calcWithChangeCostPrice(index) {
    this.setIncludedItemPrice();
    if(this.autoSum == true) {
      this.autoSumItems();
    }
    this.$refs.standardItems.$forceUpdate();
  }

  runAutoSum(event) {
    if (event.target.checked) {
      this.autoSum = true;
    }
    else {
      this.autoSum = false;
    }

    if(this.autoSum == true) {
      this.autoSumItems();
    }
  }

  autoSumItems() {
    this.formData.controls.total.value = 0;
    this.itemNames.forEach(item => {
      this.formData.controls.total.value = this.formData.controls.total.value + (item.price * item.quantity);
    });

    // Format the total as USD currency
    const formattedTotal = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.formData.controls.total.value);

    // Update the total value in the form data with the formatted string
    this.formData.controls.total.value = formattedTotal;
  }

  // autoIncludePriceSwitch() {
  //   this.includedPrice = !this.includedPrice;
  //   this.setIncludedItemPrice();
  // }

  findParent(index: any, itemNames: any) {
    const idx = index;
    // find parent
    for (let i = idx - 1; i >= 0; i--) {
      const each = itemNames[i];
      if (parseInt(each.included) === 0 && !each.isDeleted) {
        return each;
      }
      if (!each) {
        continue;
      }
    }
    return null;
  }

  setIncludedItemPrice(updateSummary = true) {
    let parentPrice = 0;
    let parentCustomerPrice = 0;
    for (const lineIndex in this.itemNames) {
      const lineItem = this.itemNames[lineIndex];
      lineItem.disable = false;
      if (lineItem.priceBk) {
        lineItem.price = lineItem.priceBk;
      }

      // if (lineItem.included) {
      //   if (!this.includedItem) {
      //     this.includedItem = true;
      //   }
      //   const parent = this.findParent(lineIndex, this.itemNames);
      //   if (parent) {
      //     if (this.includedPrice) {
      //       parentPrice += lineItem.price;
      //       parent.price = parentPrice;
      //       parent.disable = true;
      //       lineItem.disable = false;
      //     } else {
      //     parent.disable = false;
      //     lineItem.price = 0;
      //     lineItem.quantity = 0;
      //     lineItem.disable = true;
      //     }
      //   }
      // } else {
        parentPrice = 0;
        parentCustomerPrice = 0;
        lineItem.disable = false;
      // }
    }
  }

  includeItem(index) {
    if (index == 0) return;
    const item: any = this.itemNames[index];

    const configCategoryId = this.configCategory.CATEGORYID || 0;
    // toggle included
    item.included = (item.included || 0) == 0 ? 1 : 0;

    /*if (item.included == 1) {
      // item.price = item.priceBk;
      item.quantity = item.quantityBk;
      item.included = 0;
    } else {
      // item.priceBk = item.price;
      item.quantityBk = item.quantity;
      // item.price = 0.0;
      item.quantity = 0;
      item.included = 1;
    }*/

    // process to include/exclude to base
    if(!item.included) {
      if(item.ItemCategory == configCategoryId || (item.ISCONFIG || 0)) {
        item.ISCONFIG = 0;
        item.savedAsConfig = false;
        item.ItemCategory = 0;
        item.ItemCategoryName = "";
        item.ItemCategoryBk = 0;
        item.ItemCategoryNameBk = "";
      }
    }else {
      // check if item is config
      if(configCategoryId > 0 && (item.ItemCategory || 0) == configCategoryId) {
        item.ISCONFIG = 1;

        // if item is config, follow base taxRate
        const parent = findParent(index, this.itemNames);
        if(parent) {
          item.taxRate = parent.taxRate || 0;
          item.includedparent = parent.baseProductId || 0;
          item.total = (item.customerPrice || 0) * (item.quantity || 0);
        }
      }
    }

    // if(!item.included) {
    //   // uncheck item
    //   item.price = item.orgPrice;
    //   item.customerPrice = item.orgCustomerPrice;
    //   item.disable = false;
    //   if(item.marginBk) {
    //     item.margin = item.marginBk;
    //   }
    //   if(item.marginRateBk) {
    //     item.marginRate = item.marginRateBk;
    //   }
    // } else {
    //   // check this item
    //   item.quantityBk = item.quantity;
    //   item.marginBk = item.margin;
    //   item.marginRateBk = item.marginRate;
    // }


    // if (!this.showRollUp()) {
    //   this.includedPrice = false;
    // }
    this.setIncludedItemPrice();
  }

  async selectGroup(id: any) {
    console.log(this.formData.controls.groups.value, id);
    if(id != "" && this.formData.controls.groups.value == id){
      this.formData.controls.accounts.value = [];
      this.formData.controls.groups.value = [];
    }
    else{
      this.formData.controls.accounts.value = [];
      this.formData.controls.groups.value = [id];
      this.loading = true;
      const response = await ApiHelper.callApi(
        "post",
        {
          controller: "Quotes",
          FunctionName: "GetAccountList",
          groupID: id
        }
      );
      this.loading = false;
      if (response.STATUS === 1) {
        const securityUsersArr = response.ITEMS || [];

        // Loop through securityUsersArr and add each AID to the accounts.value array
        securityUsersArr.forEach((item: any) => {
          if (!this.formData.controls.accounts.value.includes(item.AID)) {
            this.formData.controls.accounts.value.push(item.AID);
          }
        });
      }
    }
  }

  toggleSelectedAccounts(selectedID: number, tagName) {
    const index = this.formData.controls.accounts.value.findIndex(id => id === selectedID);
    if (index === -1) {
      this.formData.controls.accounts.value.push(selectedID);
      return;
    }
    this.formData.controls.accounts.value.splice(index, 1);
  }

  selectAllAccounts() {
    this.resetAccounts();
    this.formData.controls.accounts.value = this.formData.controls.accounts.options.map((val: any) => val.CUSTOMFIELDOPTIONID);
  }

  resetAccounts() {
    this.formData.controls.accounts.value = [];
    this.formData.controls.groups.value = [];
  }

  updateAccountList(type, index, tagID) {
    if (this.formData.controls.accounts.value && type == "delete") {
      this.$delete(this.formData.controls.accounts.value, index);
    }
  }

  async created() {
    this.getGroups().then(() => {
    });
    await this.getAccounts();
    if (this.$route.name == 'QuoteStandardCreate') {
      this.loading = true;
      this.quoteId = parseInt(this.$route.params.quoteId) || 0;
      if (this.quoteId != 0) {
        const response = await ApiHelper.callApi('post', {
          controller: "Quotes",
          FunctionName: "View",
          ObjID: this.quoteId,
          Content: "Detailed"
        });
        if (response.STATUS) {
          this.loading = false;
          this.formData.controls.name.value = response.QUOTEDETAILS.QDESCRIPTION;
          this.formData.controls.total.value = ApiHelper.dollarFormat(response.TOTALAMOUNT);
          this.autoSum = this.formData.controls.autoSum.value = true;
          let items = (response.QUOTEITEMS || []);
          for (let i = 0; i < items.length; i++) {
            this.itemNames[i] = {
              id: 0,
              hasError: false,
              name: items[i].QPRODDESC || '',
              price: items[i].QCUSTOMERPRICE || 0,
              priceBk: items[i].QCUSTOMERPRICE || 0,
              customerPrice: items[i].QPRICEREG || 0,
              sku: items[i].QPRODSKU || '',
              quantity: items[i].QPRODQUANTITY || 0,
              quantityBk: items[i].QPRODQUANTITY || 0,
              included: items[i].QINCLUDED || 0,
              total: items[i].CUSTOMERTOTALPRICE || 0,
              taxRate: 0,
              margin: 0.0,
              marginRate: 0,
              tax: 0.0,
              priceWarn: false,
              noTax: false,
              shipping: 0.0,
              totalProducts: 0,
              configID: "",
              disable: false,
              isContractFee: items[i].ISCONTRACTFEE || items[i].ISFEE || false,
              includedparent: items[i].INCLUDEDPARENT || i,
              baseProductId: items[i].QUOTELIID,
              includeItemCount: items[i].INCLUDEITEMCOUNT || 0,
              parentLiId: items[i].INCLUDEDPARENT || i,
              quoteLiId: 0,
              isFee: items[i].ISFEE || false,
              isDeleted: false,
              endOfLifeDate: '',
              ItemCategory: items[i].ItemCategory || 0,
              ItemCategoryName: items[i].ItemCategoryName || '',
              ISCONFIG: items[i].ISCONFIG || false,
              savedAsConfig: items[i].ISCONFIG || false,
              ItemPLID: items[i].PRODUCTLINEID,
              ItemPLName: items[i].PRODUCTLINENAME
            }
          }
          this.addNewLineItem();
          this.$refs.standardItems.$forceUpdate();
        } else {
          notifier.alert("Unable to create quote");
          this.$router.push({
            name: "Quotes"
          })
        }
      } else {
        notifier.alert("Unable to create quote");
        this.$router.push({
          name: "Quotes"
        })
      }
    } else {
      this.standardUUID = this.$route.params.id || "";
      this.formData.controls.id.value = this.standardUUID;
      if (this.standardUUID) {
        await this.getDetails();
      } else {
        for (let i = 1; i <= 3; i++) {
          this.addNewLineItem();
        }
      }
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    this.configCategory = response.data.configCategory || {};
  }

  addNewLineItem(needCheck = false, item:any = {}, index = 0) {
    let lineItem = {
      id: 0,
      name: "",
      nameError: "",
      sku: "",
      skuError: "",
      price: 0.0,
      priceError: "",
      costPriceError: "",
      priceBk: 0,
      customerPrice: 0.0,
      margin: 0.0,
      marginRate: 0,
      total: 0.0,
      quantity: 1,
      quantityError: "",
      quantityBk: 1,
      tax: 0.0,
      taxRate: 0,
      shipping: 0.0,
      included: item.included || 0 ? 1 : 0,
      priceWarn: false,
      totalProducts: 3,
      noTax: false,
      configID: "",
      DistyPrice: 0,
      DistyAvailability: 0,
      DistyPrice_Format: "",
      DistyAvailability_Format: "",
      Disty: "",
      orgPrice: 0.0,
      orgCustomerPrice: 0.0,
      disable: false,
      isContractFee: false,
      includedparent: 0,
      baseProductId: 0,
      includeItemCount: 0,
      parentLiId: 0,
      quoteLiId: 0,
      isFee: false,
      isDeleted: false,
      endOfLifeDate: "",
      ISCONFIG: item.ISCONFIG || 0 ? true : false,
      savedAsConfig: false,
      ItemPLID: 0,
      ItemPLName: ''
    }
    if(index != 0) {
      this.itemNames.splice(index+1, 0, lineItem);
    } else {
      this.itemNames.push(lineItem);
    }
  }

  deleteLineItem(item, index) {
    if (this.itemNames[index]) {
      this.confirmRemoveModal.title = 'Remove item confirmation';
      this.confirmRemoveModal.callback = () => {
        let allowDelete = true;
        if(!item.included) {
          for(let key in this.itemNames) {
            if(parseInt(key) > index) {
              if(!this.itemNames[key].included) {
                break;
              }
              if(this.itemNames[key].ISCONFIG) {
                this.itemNames[key].isDeleted = true;
              }else {
                // exclude grouped item
                this.itemNames[key].included = 0;
              }
            }
          }
        }
        if (index == 0 && this.itemNames.length > 1) { // if delete first item
          let removeIncludedOfNextItem = false;
          this.itemNames.map((item, index) => {
            if (index > 0 && !item.isDeleted && removeIncludedOfNextItem == false) {
              this.itemNames[index].included = 0;
              removeIncludedOfNextItem = true;
            }
            this.setIncludedItemPrice();
          });
        }
        this.itemNames[index].isDeleted = true;
        this.confirmRemoveModal.show = false;
        this.$refs.standardItems.$forceUpdate();
      }
      this.confirmRemoveModal.show = true;
    }
  }

  async getDetails() {
    this.loading = true;
    const response = await ApiHelper.callApi('post', {
      controller: "Quotes",
      FunctionName: "StandardView",
      Content: "Detailed",
      ObjID: this.standardUUID
    });
    if (response.STATUS) {
      this.loading = false;
      const details = response.DETAILS || {};
      this.formData.controls.name.value = details.STANDARDNAME || '';
      this.formData.controls.specialPricingID.value = details.SPECIALPRICINGID || '';
      this.formData.controls.updateDate.value = details.STANDARDUPDATEDATE || '';
      this.formData.controls.createDate.value = details.STANDARDCREATEDATE || '';
      this.formData.controls.endOfLife.value = details.ENDOFLIFEFORMATTED || '';
      this.formData.controls.status.value = details.STANDARDSTATUS ? [details.STANDARDSTATUS] : [0];
      this.formData.controls.quoteAfterEOL.value = details.ALLOWQUOTINGENDOFLIFEDATE	? details.ALLOWQUOTINGENDOFLIFEDATE	: 0;
      this.formData.controls.useItemEOL.value  = details.ALLOWLINEITEMSENDOFLIFEDATE ? details.ALLOWLINEITEMSENDOFLIFEDATE	: 0;

      if(details.AUTOSUMTOTAL == 1) {
        this.autoSum = true;
        this.formData.controls.autoSum.value = true;
      }
      else {
        this.autoSum = false;
        this.formData.controls.autoSum.value = false;
      }

      this.formData.controls.total.value = details.TOTALPRICE ? details.TOTALPRICE	: [];
      const STANDARDACCOUNTIDS = details.STANDARDACCOUNTIDS || '';
      const selectedIds = (STANDARDACCOUNTIDS ? STANDARDACCOUNTIDS.split(',') : []).map((id) => Number.parseInt(id, 10));
      this.formData.controls.accounts.value = selectedIds;

      const STANDARDGROUPIDS = details.STANDARDGROUPIDS || '';
      const selectedGroupIds = (STANDARDGROUPIDS ? STANDARDGROUPIDS.split(',') : []).map((id) => Number.parseInt(id, 10));
      this.formData.controls.groups.value = selectedGroupIds;

      const items = response.ITEMS || [];
      items.map((item) => {
        const parentId = item.PARENTLIID || 0;
        const included = parentId ? 1 : 0;
        const ISCONFIG = included && (item.CATEGORYNAME || "").toLowerCase() == "config";
        this.itemNames.push({
          id: item.STANDARDLIID || 0,
          name: item.SPRODDESC || '',
          nameError: "",
          sku: item.SPRODSKU || '',
          skuError: "",
          price: item.SPRODPRICE || 0.0,
          priceError: "",
          costPriceError: "",
          customerPrice: item.SPRICEREG || 0.0,
          margin: 0.0,
          marginRate: 0,
          total: 0.0,
          quantity: item.SPRODQUANTITY || 0,
          quantityError: "",
          tax: 0.0,
          taxRate: 0,
          shipping: 0.0,
          included,
          priceWarn: false,
          totalProducts: 3,
          noTax: false,
          configID: "",
          DistyPrice: 0,
          DistyAvailability: 0,
          DistyPrice_Format: "",
          DistyAvailability_Format: "",
          Disty: "",
          orgPrice: 0.0,
          orgCustomerPrice: 0.0,
          disable: false,
          isContractFee: false,
          includedparent: item.PARENTLIID,
          baseProductId: item.STANDARDLIID,
          includeItemCount: 0,
          parentLiId: parentId,
          quoteLiId: 0,
          isFee: false,
          isDeleted: false,
          endOfLifeDate: item.ENDOFLIFEDATE || "",
          ItemCategory: item.CATEGORYID || 0,
          ItemCategoryName: item.CATEGORYNAME || "",
          ISCONFIG: item.ISCONFIG,
          savedAsConfig: item.ISCONFIG,
          ItemPLID: item.SPRODUCTLINE,
          ItemPLName: item.PRODUCTLINE
        });
      });
      this.addNewLineItem(); // add an empty row
      const params = JSON.parse(details.STANDARDPARAMS || '{}');
      // this.includedPrice = params.includedPrice;
    } else {
      ApiHelper.showErrorMessage(response.STATUSMESSAGE || 'Cant get Quote Standard');
      this.$router.replace({
        name: 'QuoteStandards',
      });
    }

  }

  async getGroups() {
    this.loading = true;
    const response = await ApiHelper.callApi("post", {
      controller: "SecurityGroups",
      FunctionName: "List",
    });
    if (response.STATUS == 1) {
      const groups = response.GROUPS || [];
      this.formData.controls.groups.options = groups.map((item) => {
        return {
          ID: item.SECURITYGROUPID || 0,
          TEXT: item.SECURITYGROUPNAME || '-',
        }
      });
    }

  }

  async getAccounts() {
    this.loading = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Accounts",
      FunctionName: "List",
      getAll: 1,
    });
    this.loading = false;
    if (response.STATUS) {
      const data = response || {};
      this.formData.controls.accounts.options = (data.ACCOUNTS || []).map((item) => {
        return {
          CUSTOMFIELDOPTIONID: item.AID,
          CUSTOMFIELDOPTIONNAME: item.ANAME,
        }
      });
    } else {
      ApiHelper.showErrorMessage('Cant get accounts');
    }
  }

  async saveFn() {
    let hasError = false;
    let errorMessage = '';
    if (!this.formData.controls.name.value) {
      hasError = true;
      errorMessage = 'Name is required';
    }
    if (this.formData.controls.status.value.length == 0) {
      hasError = true;
      errorMessage = 'Status is required';
    }
    // if (!this.formData.controls.total.value) {
    //  hasError = true;
    //  errorMessage = 'Total is required';
    // }
    // if (!this.formData.controls.createDate.value) {
    //   hasError = true;
    //   errorMessage = 'Create Date is required';
    // }
    // if (!this.formData.controls.updateDate.value) {
    //   hasError = true;
    //   errorMessage = 'Update Date is required';
    // }
    if(!this.formData.controls.useItemEOL.value){
      if (!this.formData.controls.endOfLife.value) {
        hasError = true;
        errorMessage = 'Contract EOL is required';
      } else if (!ApiHelper.validateDate(this.formData.controls.endOfLife.value)) {
        hasError = true;
        errorMessage = 'Contract EOL is invalid';
      }
    }
    // check line items
    if (this.itemNames.length) {
      this.lineItemErrorMessage = '';
      this.itemNames.map((item) => {
        let hasItemError = false;
        item.nameError = '';
        item.skuError = '';
        item.priceError = '';
        item.costPriceError = '';
        item.quantityError = '';
        item.hasError = false;
        if (item.name || item.sku) {
          // check valid
          if (!item.sku) {
            hasError = true;
            hasItemError = true;
            item.skuError = 'SKU is required';
          }
          if (!item.name) {
            hasError = true;
            hasItemError = true;
            item.nameError = 'Name is required';
          }
          if (!parseInt(item.quantity)) {
            hasError = true;
            hasItemError = true;
            item.quantityError = 'Quantity is required';
          }

          item.hasError = hasItemError;
          if(item.hasError) {
            this.lineItemErrorMessage = "Please Fill Out all missing fields for Line Items"
          }
        } else {
          // ignore
        }
      });

      const findItem = this.itemNames.find((item) => (item.sku || item.name) && !item.isDeleted);
      if (!findItem) {
        hasError = true;
        errorMessage = 'Line Item is required';
      }
    } else {
      hasError = true;
      errorMessage = 'Line Item is required';
    }

    if (hasError) {
      if (this.lineItemErrorMessage.length) {
        notifier.alert(this.lineItemErrorMessage);
      } else {
        ApiHelper.showErrorMessage(errorMessage);
      }
    } else {
      this.isSubmitting = true;
      const dataObj = {
        controller: "Quotes",
        FunctionName: "StandardEdit"
      };
      let total = 0;
      this.itemNames.map((item) => {
        total = total + (item.price * (item.quantity || 0));
      });
      const response = await ApiHelper.callApi('post', {
        ...dataObj,
        ObjID: this.$route.name == 'QuoteStandardCreate' ? '' : this.formData.controls.id.value,
        standardName: this.formData.controls.name.value,
        standardStatus: this.formData.controls.status.value.length ? this.formData.controls.status.value[0] : 0,
        standardTotal: total,
        standardCreateDate: this.formData.controls.createDate.value,
        standardUpdateDate: this.formData.controls.updateDate.value,
        standardEndOfLife: this.formData.controls.endOfLife.value,
        specialPricingID: this.formData.controls.specialPricingID.value,
        standardGroupIds: this.formData.controls.groups.value.join(','),
        standardAccountIds: this.formData.controls.accounts.value.join(','),
        allowQuotingEndOfLifeDate: this.formData.controls.quoteAfterEOL.value,
        allowLineItemsEndOfLifeDate: this.formData.controls.useItemEOL.value,
        autoSumTotal: this.formData.controls.autoSum.value,
        // includedPrice: this.includedPrice,
        lineItems: this.itemNames.map((item) => {
          const price = Number.parseFloat(item.price) || 0;
          const quantity = Number.parseInt(`${item.quantity}`, 10) || 0;
          const total = price * quantity;
          item.total = total;
          return item;
        }),
      });
      if (response.STATUS) {
        ApiHelper.showSuccessMessage(response.STATUSMESSAGE || 'Saved Successfully');
        this.$router.replace({
          name: 'QuoteStandardDetail',
          params: {id: response.STANDARDUUID}
        });
      } else {
        this.isSubmitting = false;
        ApiHelper.showErrorMessage(response.STATUSMESSAGE || 'Something was wrong');
      }
    }

  }

  getTotalPrice() {
    let total = 0;
    let quantity:any = 0;
    this.itemNames.map((item) => {
      if(!item.isDeleted) {
        if(!item.included) {
          quantity = item.quantity;
        }
        total = total + (item.price * (quantity && item.ISCONFIG ? quantity : item.quantity  || 0)) ;
      }
    }); 
    if(this.formData.controls.autoSum.value) {
      this.formData.controls.total.value = ApiHelper.dollarFormat(total);
    } 
    return ApiHelper.dollarFormat(total);
  }

  isFee(item) {
    return item.isFee || item.isContractFee || false;
  }

  categoryImgLink(item) {
    const checkSku = item.sku != '' && !this.isFee(item);
    if(checkSku && (item.ItemCategory || (item.ISCONFIG || 0))) {
      const configCategoryId = this.configCategory.CATEGORYID || 0;
      if((configCategoryId > 0 && configCategoryId == item.ItemCategory) || (item.ISCONFIG || 0)) {
        // specify image for config item
        return require('@/assets/images/config-cat-ico.svg');
      }

      return require('@/assets/images/category_icon.svg');
    } else {
      return require('@/assets/images/category-not-available.svg');
    }
  }

  getSKUCatTitle(item) {
    let ret = "";

    if((item.ISCONFIG || 0) || (item.ItemCategoryName || "") == "Config") {
      ret = "";
    }else if(item.ItemCategoryName || "") {
      ret = `Category: ${item.ItemCategoryName}`;
    }else {
      ret = "Category not available";
    }

    if (ret.length) {
      ret += ", ";
    }
    if (item.ItemPLName || "") {
      ret += `Product Line: ${item.ItemPLName.toUpperCase()}`;
    } else {
      ret += "Product Line not available";
    }

    return ret;
  }

  async showProductCatTT(item, index, e) {
    if(item.sku == "") {
      this.productCatTTVisibleIndex = -1;
      item.ItemCategory = 0;
      item.ItemCategoryName = "";
      return;
    }

    if(this.productCatTTVisibleIndex == index) {
      return;
    }

    if(e.type == "click" || (e.type == "keydown" && this.autoName === 0)) {
      this.productCatTTVisibleIndex = index;
    }
  }

  configToggleChange(item, index) {
    // if set a base to config
    if(!item.included) {
      this.includeItem(index);
    }
    if(item.included && (item.ISCONFIG || 0)) {
      // if item is config, follow base taxRate
      const parent = findParent(index, this.itemNames);
      if(parent) {
        item.taxRate = parent.taxRate || 0;
        item.total = (item.customerPrice || 0) * (item.quantity || 0);
        item.includedparent = parent.baseProductId;
      }
    }
    // this.getTotals();
  }

  htmlCheck(InputVal) {
    if (InputVal.length > htmlParse(InputVal).length) {
      return true;
    } else {
      return false;
    }
  }

  async SKULookup(index, sku, item) {
    var $this = this;
    var htmlCount = 0;
    var regex = /[^a-zA-Z0-9#-]/gi;
    if (sku != "" && typeof sku != "undefined") {
      if ($(".name input[type=checkbox]").is(":checked")) {
        $(
          ".list-item[data-index=" + index + "] .new-quote-name-input .loader"
        ).removeClass("hidden");
      }
      if ($(".price input[type=checkbox]").is(":checked")) {
        $(
          ".list-item[data-index=" + index + "] .new-quote-cost-input .loader"
        ).removeClass("hidden");
      }
      if (this.htmlCheck(sku)) {
        item.sku = htmlParse(sku);
        htmlCount++;
      }
      if (htmlCount != 0) {
        notifier.alert("HTML content found. This content has been removed!");
      }
      item.sku = item.sku.replace(regex, "");
      if (regex.test(item.sku) == false) {
        this.$set(
          this,
          "sku_productName_" + index,
          item.sku.replace(regex, "")
        );
      }
      const dataObj = {
        controller: "Quotes",
        FunctionName: "quoteLineItemList",
        search: sku,
        source: this.sourceID,
        // contractNumberValue: this.selectedOrderContracts,
        // contractNumberId : this.orderContractsID.join()
      };

      if(this.autoName == 1 || this.autoCost == 1) {
        await getRouteData(dataObj).then(function(response, statusText, jqXHR) {
          if ("LINEITEMS" in response.data && response.data.LINEITEMS[0]) {
            var productInfo = response.data.LINEITEMS[0];
            item.dynamicInfo = productInfo;

            if ($this.autoName == 1) {
              $(
                ".list-item[data-index=" +
                  index +
                  "] .new-quote-name-input .loader"
              ).addClass("hidden");
              
              if(response.data.LINEITEMS[0].PRODUCTNAME != "") {
                item.name = response.data.LINEITEMS[0].PRODUCTNAME;
              }

              if (item.name.length > 255) {
                $("#errorName").text(
                  "Item name should not be more than 255 characters"
                );
              } else {
                $("#errorName").text("");
              }

              const categoryId = productInfo.DEFAULTCATEGORYID || 0;
              const categoryName = productInfo.CATEGORYNAME || "";
              const ISCONFIG = productInfo.ISCONFIG || 0;
              if (ISCONFIG && index != 0) {
                item.ISCONFIG = ISCONFIG;
                item.included = 1;
              }
              if(categoryId) {
                if(index == 0 && categoryName == "Config") {
                  // do nothing, if detect sku is a config sku but the line is top of list
                }else {
                  item.ItemCategory = categoryId;
                  item.ItemCategoryName = categoryName;
                  // $this.$refs.quoteView.$forceUpdate();
                }
              }else {
                item.ItemCategory = 0;
                item.ItemCategoryName = "";
                // $this.$refs.quoteView.$forceUpdate();
                $this.productCatTTVisibleIndex = index; //show product category tooltip
              }
            }
            /* if (typeof productInfo.ContractPrice != "undefined") {
              item["contractPrice"] = productInfo.ContractPrice;
            }
            if (typeof productInfo.ContractPriceDate != "undefined") {
              item["contractPriceDate"] = productInfo.ContractPriceDate;
            }
            if (typeof productInfo.PRODUCTLINEID != "undefined") {
              item["ItemPLID"] = productInfo.PRODUCTLINEID;
            }
            if (typeof productInfo.PRODUCTLINENAME != "undefined") {
              item["ItemPLName"] = productInfo.PRODUCTLINENAME;
            }

            if (this.autoCost == 1) {
              $(
                ".list-item[data-index=" +
                  index +
                  "] .new-quote-cost-input .loader"
              ).addClass("hidden");
              if(typeof productInfo.Disty != "undefined") {
                item.Disty = productInfo.Disty;

                if(typeof productInfo.DistyPrice != "undefined") {
                  item.DistyPrice = productInfo.DistyPrice;
                  item.DistyPrice_Format = productInfo.DistyPrice_Format;
                }
                if(typeof productInfo.DistyAvailability != "undefined") {
                  item.DistyAvailability = productInfo.DistyAvailability;
                  item.DistyAvailability_Format = productInfo.DistyAvailability_Format;
                }
              }
              $this.checkDistyError();

              let productPrice = productInfo.PRODUCTPRICE != 0 ? productInfo.PRODUCTPRICE : 0;
              if($this.isDynamic()) {
                // get lowest disty price
                productPrice = $this.getLowestDistyPrice(productInfo, item);
              }
              if(productPrice != 0) {
                item.price = productPrice;

                // recalculate customer price in case had a margin rate on this line
                if($this.isIndirect() && $this.confirmedGlobalMargin && item.marginRate != 0) {
                  // item.margin = parseFloat((item.price * item.marginRate / 100).toFixed($this.$decimals));
                  // item.customerPrice = parseFloat((item.margin + item.price).toFixed($this.$decimals));
                  item.customerPrice = $this.getCustomerPriceByMarinRate(item);
                  $this.reCalculateMargin(item, "amount");
                }

                //force event when changing price per
                $this.marginCalc1(index);

                // changed cost
                $this.calcWithChangeCostPrice(index);
              }
            }

            $this.updateDistyTotal(); */
            
          } else {
            $(
              ".list-item[data-index=" +
                index +
                "] .new-quote-name-input .loader, .list-item[data-index=" +
                index +
                "] .new-quote-cost-input .loader"
            ).addClass("hidden");

            $this.productCatTTVisibleIndex = index; //show product category tooltip
          }
        })
        .catch(function(err) {
          console.log(err);
        });
      }

      // if had a global margin, no suggest a history price
      /* if(!(this.isIndirect() && this.confirmedGlobalMargin) && this.aID > 0 && this.sourceID > 0) {
        // get history price this case
        try {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Quotes",
            FunctionName: "getHistoryPrice",
            sku: sku,
            source: this.sourceID,
            aID: this.aID
          });

          if(response.data.STATUS == 1) {
            var hasHistoryPrice = false;
            if(item.price == 0 && typeof response.data.avgCost != "undefined") {
              // eslint-disable-next-line require-atomic-updates
              item.price = parseFloat(response.data.avgCost.toFixed(this.$decimalsView));
              hasHistoryPrice = true;
            }
            if(item.marginRate == 0 && typeof response.data.avgMarginRate != "undefined" 
              && this.currentComponent != "Direct" && this.estPercent == 0) {

              // eslint-disable-next-line require-atomic-updates
              item.marginRate = parseFloat(response.data.avgMarginRate.toFixed(this.$decimals));
              hasHistoryPrice = true;
            }
            if(hasHistoryPrice) {
              this.marginRate(index);
              // eslint-disable-next-line require-atomic-updates
              item.customerPrice_bk = item.customerPrice;
              if(this.currentComponent == "Direct") {
                // eslint-disable-next-line require-atomic-updates
                item.customerPrice = item.price;
                this.marginCalc1(index);
              }
            }
          }
        }catch (err) {
          console.log(err);
        }
      } */
    }else {
      this.productCatTTVisibleIndex = -1; //hide product category tooltip
      item.ItemCategory = 0;
      item.ItemCategoryName = "";
    }

    // auto toggle config for "config" category
    if (
      index != 0 &&
      item.ItemCategoryName == "Config" &&
      !(item.ISCONFIG || 0)
    ) {
      this.productCatTTVisibleIndex = -1;
      // eslint-disable-next-line require-atomic-updates
      item.included = 0;
      this.includeItem(index);
    }
  }

  hasConfigs(item, index) {
    if(item.included || 0) return false;

    let ret = false;
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for(let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem = this.itemNames[keys[j]];
          if(!nextItem) break;

          // check if this base has a config item
          if(!(nextItem.included || 0)) {
            // this is an other base
            break;
          } else if((nextItem.ISCONFIG || 0) && !(nextItem.isDeleted || false)) {
            ret = true;
            break;
          }
        }

        break;
      }
    }

    return ret;
  }

  configsCount(item, index) {
    const configItems = this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0);
    return configItems.length;
  }

  getIncludedItems(item, index) {
    if(item.included) return [];

    const ret: any = [];
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for(let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem = this.itemNames[keys[j]];
          if(!nextItem || !nextItem.included) break;

          if(nextItem.included) {
            nextItem.indexKey = keys[j];
            ret.push(nextItem);
          }
        }

        break;
      }
    }
    return ret;
  }

  autoNameSwitch(val) {
    this.autoName = val;
  }

  autoExpandDecimalSwitch(togBtn) {
    this.expandDecimalPrecision = 2;
    if (togBtn) {
      this.expandDecimalPrecision = 5;
    }
    this.expandDecimal = !this.expandDecimal;
  }

  showFileUpload() {
    $("#fileUpload").click();
  }

  handleFileUpload(e) {
    var comInstance = this;
    e.stopPropagation();
    e.preventDefault();
    var filePath = e.target.files[0].name;
    var allowedExtensions = /(\.html|\.pdf|\.csv|\.xls|\.xlsx)$/i;
    var isCSV = (filePath.indexOf(".csv") != -1 ? true : false);
    if (!allowedExtensions.exec(filePath)) {
      this.errorMsg = "Please upload file having extensions .html/.pdf/.csv/.xls/.xlsx only.";
      $("#errorMsgLine").html(this.errorMsg);
      return false;
    } else {
      comInstance.uploading = true;
      var processedFileUpload = getImageRouteData({
        Controller: "Quotes",
        FunctionName: "Add",
        quoteFileName: filePath,
        quotefile: e.target.files[0],
        uploadedFileName: e.target.files[0].name,
        fileType:
          filePath.indexOf(".html") != -1
            ? "html"
            : filePath.indexOf(".csv") != -1
            ? "csv"
            : filePath.indexOf(".xls") != -1
            ? "xls"
            : "pdf",
        source:
          typeof comInstance.sourceID != "undefined" ? comInstance.sourceID : 1
      }).then(async function(response, statusText, jqXHR) {
          if (response.data.STATUS) {
            var items = response.data.DATA;
            comInstance.itemNames = [];
            for (let i = 0; i < items.length; i++) {
              comInstance.itemNames.push({
                id: 0,
                name: items[i].Name || '',
                nameError: "",
                sku: items[i].SKU || '',
                skuError: "",
                price: items[i].Price || 0.0,
                priceError: "",
                costPriceError: "",
                customerPrice: items[i].NetPrice || 0.0,
                margin: 0.0,
                marginRate: 0,
                total: 0.0,
                quantity: items[i].Qty || 0,
                quantityError: "",
                tax: 0.0,
                taxRate: 0,
                shipping: 0.0,
                included: items[i].isIncluded,
                priceWarn: false,
                totalProducts: 3,
                noTax: false,
                configID: "",
                DistyPrice: 0,
                DistyAvailability: 0,
                DistyPrice_Format: "",
                DistyAvailability_Format: "",
                Disty: "",
                orgPrice: 0.0,
                orgCustomerPrice: 0.0,
                disable: false,
                isContractFee: false,
                includedparent: 0,
                baseProductId: 0,
                includeItemCount: 0,
                parentLiId: 0,
                quoteLiId: 0,
                isFee: false,
                isDeleted: false,
                endOfLifeDate: "",
                ItemCategory: 0,
                ItemCategoryName: "",
                ISCONFIG: items[i].isConfig,
                savedAsConfig: items[i].isConfig,
                ItemPLID: 0,
                ItemPLName: ''
              });
            }
            let skus: string[] = [];
            for(const i in items) {
              if(items[i].SKU.trim() != "") {
                skus.push(items[i].SKU.trim());
              }
            }
            skus = [...new Set(skus)];
            if(skus.length) {
              const responseSKU = await axios.post(dataURL + "?ReturnType=JSON", {
                controller: "Quotes",
                FunctionName: "quoteLineItemList",
                search: skus,
                source: comInstance.sourceID,
                checkCategory: true
              });
              if(responseSKU.data.STATUS == 1) {
                const lineItems = responseSKU.data.LINEITEMS || [];
                let index = -1;
                for(const i in comInstance.itemNames) {
                  index++;
                  const item = comInstance.itemNames[i];
                  if(item.ISCONFIG || 0)
                    continue;
                  const inList = lineItems.find(t => t.PRODUCTSKU == item.sku);
                  if(inList) {
                    if((inList.CATEGORYNAME || "") == "Config" && index == 0) {
                      // do nothing, if detect sku is a config sku but the line is top of list
                    }else {
                      item.ItemCategory = inList.DEFAULTCATEGORYID || 0;
                      item.ItemCategoryName = inList.CATEGORYNAME || "";
                    }
                  }
                }
              }
            }
          } else if (response.data.ERROR) {
            $("#errorMsgLine").html(response.data.STATUSMESSAGE);
          } else {
            if (response.data.STATUS == 0) {
              var downloadLink =
              "<a href='https://var360.s3.amazonaws.com/spreadsheets/Indirect%20Template.csv' style='color:blue; cursor: pointer' target='_blank'>here</a>";
              var messageError = response.data.STATUSMESSAGE;
              messageError +=
              ". If don't have standard file, please download sample template " +
              downloadLink;
              $("#errorMsgLine").html(messageError);
              $("#errorMsgLine").attr("style", "position: relative;");
            } else {
              $("#errorMsgLine").html(response.data.STATUSMESSAGE);
            }
          }
          // eslint-disable-next-line require-atomic-updates
          comInstance.uploading = false;

          comInstance.$nextTick().then(() => {
            comInstance.checkExpandedItems("upload");
          });
        }
      )
    }
  }

  checkExpandedItems(type) {
    const keys = Object.keys(this.itemNames);
    if (type == "upload") {
      for (let i = 0; i <= keys.length - 1; i++) {
        const index = keys[i];
        const item = this.itemNames[index];
        if (item.included || 0) continue;

        // is base
        item.expanded = true;
        if (!(item.baseProductId || 0)) {
          item.baseProductId = getRandomNumber(100000, 999999);
        }
        const collapseBtn = $(
          `.list-item.has-configs[data-index="${index}"] .calloutExpandContainer .collapseBtn`
        );

        if (collapseBtn.length) {
          // mark this button is expanded
          collapseBtn.removeClass("collapsed").attr("aria-expanded", true);

          // check grouped/config item
          const includedItems = this.getIncludedItems(item, index);
          for (const t of includedItems) {
            t.includedparent = item.baseProductId;
            if (t.ISCONFIG || 0) {
              t.savedAsConfig = true;
              // show configs
              this.$nextTick().then(() => {
                if (t.indexKey) {
                  $(`.list-item[data-index="${t.indexKey}"]`)
                    .parent()
                    .addClass("show");
                }
              });
            }
          }
        }
      }
    }
  }

  getBaseTotal(item, index) {
    const ret = {
      price: 0,
      customerPrice: 0,
      margin: 0,
      marginRate: 0,
      total: 0
    };
    const configItems = this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0);
    for(const t of configItems) {
      ret.price += parseFloat((t.price || 0).toFixed(this.expandDecimalPrecision));
      ret.price = parseFloat(ret.price.toFixed(this.expandDecimalPrecision));
      ret.customerPrice += parseFloat((t.customerPrice || 0).toFixed(this.expandDecimalPrecision));
      ret.customerPrice = parseFloat(ret.customerPrice.toFixed(this.expandDecimalPrecision));
    }
    // append base cost per/price per
    ret.price += parseFloat((item.price || 0).toFixed(this.expandDecimalPrecision));
    ret.price = parseFloat(ret.price.toFixed(this.expandDecimalPrecision));
    ret.customerPrice += parseFloat((item.customerPrice || 0).toFixed(this.expandDecimalPrecision));
    ret.customerPrice = parseFloat(ret.customerPrice.toFixed(this.expandDecimalPrecision));
    ret.total = parseFloat(
      (ret.price * (item.quantity || 0)).toFixed(2)
    );
    return ret;
  }

}
