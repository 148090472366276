import axios from "axios";
import { notifier } from "@/models/common";

declare const dataURL: string;
declare const getMaxRows: Function;

export type Filters = {
  paymentPurchaseID?: string
  paymentSource: string[]
  paymentsTotalMin: string | undefined
  paymentsTotalMax: string | undefined
  paymentInvoiceID?: string
  paymentInvoicesDueDate?: string | undefined
  paymentInvoiceeDueDate?: string | undefined
}

export enum SortField {
  paymentPurchaseID = 1,
  paymentSource = 2,
  paymentAmount = 3,
  paymentInvoiceID = 4,
  paymentDueDate = 5
}

export type Sort = {
  field: SortField | null | number
  direction: {
    [SortField.paymentPurchaseID]: number,
    [SortField.paymentSource]: number,
    [SortField.paymentAmount]: number,
    [SortField.paymentInvoiceID]: number,
    [SortField.paymentDueDate]: number,
  }
}

export type PaymentFilters = {
  paymentId?: string
  paymentName?: string
  pTotalMin?: string | undefined
  pTotalMax?: string | undefined
  paymentsDate?: string | undefined
  paymenteDate?: string | undefined
  paidsDate?: string | undefined
  paideDate?: string | undefined
  paymentSource: string[]
  paymentSourceName: string[]
  quickbooks: number[]
}

export enum PaymentSortField {
  paymentId = 1,
  paymentName = 2,
  paymentDate = 3,
  paidDate = 4,
  paymentTotal = 5,
  quickbooks = 6
}

export type PaymentSort = {
  field: PaymentSortField | null | number
  direction: {
    [PaymentSortField.paymentId]: number,
    [PaymentSortField.paymentName]: number,
    [PaymentSortField.paymentDate]: number,
    [PaymentSortField.paidDate]: number,
    [PaymentSortField.paymentTotal]: number,
  }
}

export class LedgerPaymentAPIRepo {
  async find(filters: Filters, sort: Sort, pageNumber: number, viewType = "CurrentUsers", selectedView = false, initialLoad: boolean = false) {
    var requestData = {
      "controller": "finances",
      "FunctionName": "ledgerPayment",
      "pageNumber": pageNumber,
      "maxRows": sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows(),
      "purchaseID": filters.paymentPurchaseID,
      "ledgerSource": filters.paymentSource ? filters.paymentSource.join(",") : '',
      "invoiceID": filters.paymentInvoiceID,
      "ledgerDueDate_sDate": filters.paymentInvoicesDueDate ? filters.paymentInvoicesDueDate : '',
      "ledgerDueDate_eDate": filters.paymentInvoiceeDueDate ? filters.paymentInvoiceeDueDate : '',
      "view": viewType,
      "selectedView": selectedView,
      "action" : "list"
    }

    if (typeof filters.paymentsTotalMin !== 'undefined') {
      requestData['paymentsTotalMin'] = filters.paymentsTotalMin
    }
    if (typeof filters.paymentsTotalMax !== 'undefined') {
      requestData['paymentsTotalMax'] = filters.paymentsTotalMax
    }
    if (!initialLoad && filters.paymentSource.length == 0) {
      requestData['ledgerSource'] = "-1";
    }

    if (sort.field) {
      requestData["order"] = sort.field;
      requestData["direction"] = sort.direction[sort.field].toString();
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async findPaymentDetails(paymentfilters: PaymentFilters, paymentSort: PaymentSort, pageNumber: number, viewType = "CurrentUsers", selectedView = false, initialLoad: boolean = false) {
    var requestData = {
      "controller": "finances",
      "FunctionName": "ledgerPaymentList",
      "pageNumber": pageNumber,
      "maxRows": sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows(),
      "paymentId": paymentfilters.paymentId,
      "paymentName": paymentfilters.paymentName,
      "paymentDate_sDate": paymentfilters.paymentsDate ? paymentfilters.paymentsDate : '',
      "paymentDate_eDate": paymentfilters.paymenteDate ? paymentfilters.paymenteDate : '',
      "paidDate_sDate": paymentfilters.paidsDate ? paymentfilters.paidsDate : '',
      "paidDate_eDate": paymentfilters.paideDate ? paymentfilters.paideDate : '',
      "view": viewType,
      "selectedView": selectedView,
      "action" : "list",
      "paymentSource": paymentfilters.paymentSource ? paymentfilters.paymentSource.join(",") : '',
      "quickbooks": paymentfilters.quickbooks ? paymentfilters.quickbooks.join(",") : ''
    }

    if (typeof paymentfilters.pTotalMin !== 'undefined') {
      requestData['paymentsTotalMin'] = paymentfilters.pTotalMin
    }
    if (typeof paymentfilters.pTotalMax !== 'undefined') {
      requestData['paymentsTotalMax'] = paymentfilters.pTotalMax
    }

    if (paymentSort.field) {
      requestData["order"] = paymentSort.field;
      requestData["direction"] = paymentSort.direction[paymentSort.field].toString();
    }

    if (!initialLoad && paymentfilters.paymentSource.length == 0) {
      requestData['paymentSource'] = "-1";
    }

    if (!initialLoad && paymentfilters.quickbooks.length == 0) {
      requestData['quickbooks'] = "-1";
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    return response.data
  }

  async exportListsCSVFileUrl(paymentfilters: PaymentFilters, paymentSort: PaymentSort, pageNumber: number, viewType = "CurrentUsers", selectedView = false, selectedAll = false, excludedIDs: any, selectedID: any): Promise<string> {
    const reqData: any = this.prepareFilteredData(paymentfilters, paymentSort, viewType);
    reqData["FunctionName"] = "LedgerPaymentExport";
    reqData["ExportType"] = "CSV";
    reqData["action"] = "list";
    reqData["pageNumber"] = pageNumber;
    reqData["selectedView"] = selectedView;
    reqData["selectedID"] = selectedID;
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }
    return response.data.S3URL
  }

  prepareFilteredData(paymentfilters: any, paymentSort: any, viewType: string = 'CurrentUsers') {
    var ret = {
      "controller": "finances",
      "view": viewType,
      "paymentId": paymentfilters.paymentId,
      "paymentName": paymentfilters.paymentName,
      "paymentDate_sDate": paymentfilters.paymentsDate ? paymentfilters.paymentsDate : '',
      "paymentDate_eDate": paymentfilters.paymenteDate ? paymentfilters.paymenteDate : '',
      "paidDate_sDate": paymentfilters.paidsDate ? paymentfilters.paidsDate : '',
      "paidDate_eDate": paymentfilters.paideDate ? paymentfilters.paideDate : ''
    }

    if (typeof paymentfilters.pTotalMin !== 'undefined') {
      ret['paymentsTotalMin'] = paymentfilters.pTotalMin
    }
    if (typeof paymentfilters.pTotalMax !== 'undefined') {
      ret['paymentsTotalMax'] = paymentfilters.pTotalMax
    }

    if (paymentSort.field) {
      ret["order"] = paymentSort.field;
      ret["direction"] = paymentSort.direction[paymentSort.field].toString();
    }

    return ret;
  }
}
