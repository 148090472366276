import { render, staticRenderFns } from "./AssetsAllocationTooltip.vue?vue&type=template&id=2e1c597d&scoped=true"
import script from "./AssetsAllocationTooltip.vue?vue&type=script&lang=tsx"
export * from "./AssetsAllocationTooltip.vue?vue&type=script&lang=tsx"
import style0 from "./AssetsAllocationTooltip.vue?vue&type=style&index=0&id=2e1c597d&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e1c597d",
  null
  
)

export default component.exports