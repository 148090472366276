<script lang="js">
import VueChartJs from 'vue-chartjs'

export default {
    extends: VueChartJs.Bar,
    props: {         
        data: Array,
        label: Array,
        type: String,
        backgroundColor: String,
    },
    methods: {
        renderBarChart: function () {
            var vm = this;
            this.renderChart({
                labels: this.label,
                datasets: this.chartData,
            }, {  responsive: true, 
                    maintainAspectRatio: false,
                    legend: { //hides the legend
                        display: false,
                    },
                    tooltips: {
                        // Disable the on-canvas tooltip
                        enabled: false,

                        custom: function(tooltipModel) {
                            // Tooltip Element
                            var tooltipEl = document.getElementById('chartjs-tooltip');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = '0';
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            function getBody(bodyItem) {
                                return bodyItem.lines;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                var titleLines = tooltipModel.title || [];
                                var bodyLines = tooltipModel.body.map(getBody);

                                var innerHtml = '';

                                const addTitle = function(title, i) {
                                    if (bodyLines[i][0].startsWith('Prior Year')) {
                                        // title = title.split(',')[0]
                                        const parts = title.split('-')
                                        const year = +parts[1] - 1
                                        const t = parts[0].toUpperCase() + ', ' + year
                                        innerHtml += '<tr><th>' + t + '</th></tr>';
                                        return
                                    }
                                    
                                    // title = title.split(',')[1]
                                    title = title.replace('-', ', ').toUpperCase()
                                    innerHtml += '<tr><th>' + title + '</th></tr>';
                                }

                                // titleLines.forEach(addTitle);
                                // innerHtml += '</thead><tbody>';

                                const addBody = function(body, i) {
                                    addTitle(titleLines[0], i)

                                    var colors = tooltipModel.labelColors[i];
                                    var style = 'background:' + colors.backgroundColor;
                                    style += '; border-color:' + colors.borderColor;
                                    style += '; border-width: 2px';
                                    var span = '<span style="' + style + '"></span>';

                                    const parts = body[0].split(' ')
                                    const amount = parts[parts.length - 1]
                                    const formatedAmount = (vm.type === '$' ? '$' : '') + amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    innerHtml += '<tr style="color: #38c4c5"><td>' + span + formatedAmount + '</td></tr>';
                                }
                                
                                bodyLines.forEach(addBody);
                                // innerHtml += '</tbody>';

                                var tableRoot = tooltipEl.querySelector('table');
                                if(tableRoot)
                                tableRoot.innerHTML = innerHtml;
                            }

                            // `this` will be the overall tooltip
                            var position = this._chart.canvas.getBoundingClientRect();

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = "1";
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 15 + 'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding * 1.5 + 'px ' + tooltipModel.xPadding * 1.5 + 'px';
                            tooltipEl.style.pointerEvents = 'none';
                            tooltipEl.style.backgroundColor = '#fff';
                            tooltipEl.style.boxShadow = '0px 2px 10px 2px rgba(0,0,0,0.2)';
                        }
                    },
                    // tooltips: {
                    //     callbacks: {
                    //     title: function(tooltipItem, data) {
                    //         return data['labels'][tooltipItem[0]['index']];
                    //     },
                    //     label: function(tooltipItem, data) {
                    //         return vm.type == "units" ? tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (tooltipItem.yLabel == 1) ? tooltipItem.yLabel + ' Day' : tooltipItem.yLabel + ' Days' ;
                    //     },
                    //     labelTextColor:function(tooltipItem, chart){
                    //             return '#0066ff' ;
                    //         }
                    //     },
                    //     backgroundColor: '#FFF',
                    //     borderColor: 'grey',
                    //     borderWidth: 1,
                    //     titleFontSize: 14,
                    //     titleFontColor: '#000000',
                    //     bodyFontColor: '#000',
                    //     bodyFontSize: 14,
                    //     displayColors: false
                    // },
                    scales: { //hides the y axis
                        xAxes: [{
                            barThickness: 15
                        }],
                        yAxes: [{
                            ticks: {
                                min: 0,
                                reverse: false,
                                callback: function(value, index, values) {
                                    if(value == 0) {
                                        return '';
                                    }

                                    if (vm.type == "$") {
                                        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    
                                    return vm.type == "units" ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (value == 1) ? value + ' Day' : value + ' Days'
                                }
                            }
                        }]
                    }
                })
        }
    },
    computed: {
        chartData: function() {
            return this.data;
        }
    },
    mounted () {
        this.renderBarChart();
    },
}
</script>
