
























import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {}

interface Events {}

declare const $: any;


@Component({
  inheritAttrs: false,
  components: {}
})

export default class HardwaresWarningModal extends TSXComponent<
  Props,
  Events
> {

  @Prop({ required: false, default: "" })
  title!: string;

  @Prop({ required: false, default: [] })
  invalidError!: any;

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close');
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide');
  }
}
