




















import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader
  }
})
export default class ProductCatTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "margin_per" })
  marginView!: string;

  $parent: any;
  loading = false;

  clickOutside() {
    this.$emit("close");
  }
}
