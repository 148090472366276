var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quotes-list",attrs:{"id":"container"}},[_c('add-header'),_c('div',{staticClass:"up",attrs:{"id":"page-header"}},[_c('div',{staticClass:"col-6 left"},[_c('span',{staticClass:"page-title left",staticStyle:{"top":"0"},attrs:{"data-type":"quotes"}},[_vm._v("Standards")]),_c('span',{staticClass:"list-count left",staticStyle:{"height":"46px"}},[(_vm.loading && !_vm.pageData.TOTALALLITEMS)?_c('span',[_c('span',{staticClass:"searchFound"},[_vm._v("0 TOTAL")])]):(!_vm.loading && _vm.pageData.TOTALALLITEMS != _vm.pageData.TOTAL)?[_c('span',{staticClass:"searchFound"},[_c('span',[_vm._v(_vm._s(_vm.pageData.TOTALALLITEMS)+" TOTAL")]),_c('span',[_vm._v("("+_vm._s(_vm.pageData.TOTAL)+" FOUND)")])])]:_c('span',{staticClass:"searchFound"},[_vm._v(" "+_vm._s(_vm.pageData.TOTALALLITEMS)+" TOTAL ")])],2)]),_c('div',{staticClass:"buttonContainer show-multiDropdown"},[_c('span',{staticClass:"actionsBtn btn gray right multiDropdownOptions"},[_vm._v("Options")]),_c('ul',{staticClass:"menu"},[_c('li',{staticClass:"sub-menu"},[_c('span',{staticClass:"right actionsBtnColor teal"},[(_vm.tab == 1)?_c('span',{staticClass:"name"},[_vm._v(" List View ")]):_c('span',{staticClass:"name"},[_vm._v(" Accounts View ")])]),_c('ul',{staticClass:"child"},[_c('div',[_c('a',{on:{"click":function () {
                    _vm.tab = 1;
                    _vm.fetchData();
                  }}},[_c('li',[_vm._v("List View")])]),_c('a',{on:{"click":function () {
                    _vm.tab = 2;
                    _vm.fetchData();
                  }}},[_c('li',[_vm._v("Accounts View")])])])])]),(_vm.$root.getUserRole() === 'Reseller')?_c('li',{staticClass:"sub-menu"},[_vm._m(0),_c('ul',{staticClass:"child"},[_c('div',[(_vm.currentRole === 'Reseller')?_c('li',{on:{"click":function () {
                  _vm.$router.push({
                    name: 'QuoteStandardEdit',
                    params: {
                      id: 0
                    }
                  });
                }}},[_vm._v(" New Standard ")]):_vm._e()])])]):_vm._e()])]),_c('div',{staticClass:"buttonContainer hide-multiDropdown"},[_c('span',{staticClass:"actionsBtn btn right actionsBtnColor teal"},[(_vm.tab == 1)?_c('span',{staticClass:"name"},[_vm._v(" List View ")]):_c('span',{staticClass:"name"},[_vm._v(" Accounts View ")])]),_c('ul',{staticClass:"menu"},[_c('div',[_c('a',{on:{"click":function () {
                _vm.tab = 1;
                _vm.fetchData();
              }}},[_c('li',[_vm._v("List View")])]),_c('a',{on:{"click":function () {
                _vm.tab = 2;
                _vm.fetchData();
              }}},[_c('li',[_vm._v("Accounts View")])])])])]),(_vm.$root.getUserRole() === 'Reseller')?_c('div',{staticClass:"buttonContainer hide-multiDropdown"},[_vm._m(1),_c('ul',{staticClass:"menu"},[_c('div',[(_vm.currentRole === 'Reseller')?_c('li',{on:{"click":function () {
              _vm.$router.push({
                name: 'QuoteStandardEdit',
                params: {
                  id: 0
                }
              });
            }}},[_vm._v(" New Standard ")]):_vm._e()])])]):_vm._e()]),(_vm.pageData.error)?[_c('div',{staticClass:"page-error p-4"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"p-4"},[_vm._v(_vm._s(_vm.pageData.error))]),_c('div',[_c('a',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.fetchData(true)}}},[_vm._v("Retry")])])])]),_c('div',{staticClass:"clearfix"})]:[_c('div',{staticClass:"page-header-details"}),_c('div',{staticClass:"page-list"},[_c('div',{staticClass:"page-list-container"},[_c('span',{staticClass:"expand-full"}),_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"list-tab"},[_c('FilterCheckboxes',{attrs:{"title":"Status","value":_vm.filters.status,"options":[
                    {
                      value: 0,
                      text: 'Draft'
                    },
                    {
                      value: 1,
                      text: 'Active'
                    },
                    {
                      value: 2,
                      text: 'Waiting For Approval'
                    },
                    {
                      value: 3,
                      text: 'Terminated'
                    } ]},on:{"update":function (value) {
                  _vm.filters.status = value;
                },"reset":function () {
                  _vm.filters.status = [0,1,2,3];
                  _vm.fetchData();
                },"filter":function () {
                  _vm.fetchData();
                }}})],1),_vm._m(2),_c('th',{staticClass:"list-tab"},[_c('FilterSearch',{attrs:{"title":"Special Pricing ID","placeholder":"Search Special Pricing ID","search":_vm.filters.specialPricingID},on:{"search":function (value) {
                  _vm.filters.specialPricingID = value;
                  _vm.fetchData();
                }}})],1),(_vm.tab == 1)?_c('th',{staticClass:"list-tab"},[_vm._m(3)]):_vm._e(),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('th',{staticClass:"list-tab"},[_c('FilterDate',{attrs:{"title":"Contract EOL","placeholder":"Contract EOL","end-date":_vm.filters.enddateEFL,"from-date":_vm.filters.fromdateEFL,"allowFutureDate":true},on:{"reset":function () {
                  _vm.filters.fromdateEFL = '';
                  _vm.filters.enddateEFL = '';
                  _vm.fetchData();
                },"filter":function (fromdate, enddate) {
                  _vm.filters.fromdateEFL = fromdate;
                  _vm.filters.enddateEFL = enddate;
                  _vm.fetchData();
                }}})],1)])]),_c('tbody',[(_vm.loading)?_c('Loader'):_vm._l((_vm.items),function(item,index){return [(item.ISMAIN && _vm.tab == 2)?_c('tr',{key:index},[_c('td',{attrs:{"colspan":"7"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.ANAME))])])]):_vm._e(),_c('tr',{key:index},[_c('td',[_c('TooltipStandardStatus',{attrs:{"status":item.STANDARDSTATUS},on:{"changeStatus":function (status) {
                    _vm.updateStandardStatus(item, status);
                    item.STANDARDSTATUS = status;
                  }}})],1),_c('td',[_c('label',[_c('router-link',{attrs:{"to":{
                  name: 'QuoteStandardDetail',
                  params: {
                    id: ("" + (item.STANDARDUUID))
                  }
                }}},[_vm._v(" "+_vm._s(item.STANDARDNAME || '')+" ")])],1)]),_c('td',[_c('label',[_vm._v(" "+_vm._s(item.SPECIALPRICINGID || '')+" ")])]),(_vm.tab == 1)?_c('td',[(item.STANDARDACCOUNTIDS)?_c('label',{staticClass:"cursorPointer text-truncate",staticStyle:{"max-width":"100%"},attrs:{"title":item.ACCOUNTS.map(function (item) { return item.ANAME; }).join(', ')}},[_vm._v(" "+_vm._s(item.ACCOUNTS.map(function (item) { return item.ANAME; }).join(', '))+" ")]):_c('label',[_vm._v("-")])]):_vm._e(),_c('td',[_c('div',{staticClass:"h-100 d-flex align-items-center justify-content-center",on:{"mouseleave":function () {
                    _vm.productCountTooltipIndex = -1;
                  }}},[_c('div',{staticClass:"position-relative d-block",staticStyle:{"width":"130px"}},[_c('router-link',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"to":{
                        name: 'QuoteStandardDetail',
                        params: {
                          id: ("" + (item.STANDARDUUID))
                        }
                      }}},[_c('div',{staticClass:"productCountPos"},[_c('div',{staticClass:"productCountColor",on:{"mouseenter":function () {
                            _vm.productCountTooltipIndex = index;
                          }}},[_vm._v(" "+_vm._s(item.PRODUCTQUANTITY || 0)+" ")])]),(_vm.productCountTooltipIndex === index)?_c('StandardProductsToolTip',{attrs:{"standardUUID":item.STANDARDUUID,"totalProducts":item.PRODUCTQUANTITY},on:{"close":function () {
                          _vm.productCountTooltipIndex = -1;
                        }}}):_vm._e()],1)],1)])]),_c('td',[_c('label',[_vm._v(" "+_vm._s(item.TOTALPRICEFORMATTED || '$0.00')+" ")])]),_c('td',[_c('label',[_vm._v(" "+_vm._s(item.CREATEDATEFORMATTED || '')+" ")])]),_c('td',[_c('label',[_vm._v(" "+_vm._s(item.UPDATEDATEFORMATTED || '')+" ")])]),_c('td',[((item.TOTALDAYS || 0) < 45 && (item.TOTALDAYS || 0) > 0)?_c('label',{staticClass:"text-danger",attrs:{"total-days":item.TOTALDAYS || 0}},[_vm._v(" "+_vm._s(_vm.getEndOfLifeSummary(item))+" ")]):_c('label',{attrs:{"total-days":item.TOTALDAYS || 0}},[_vm._v(" "+_vm._s(item.ENDOFLIFEFORMATTED || '')+" ")])])])]})],2)]),(!_vm.loading && _vm.items.length === 0)?_c('div',{staticClass:"noData"},[_vm._v("No Records Found")]):_vm._e()])]),_c('div',{staticClass:"clearfix"}),_c('Pagination',{attrs:{"CURRENTPAGE":_vm.pageData.CURRENTPAGE,"TOTALPAGES":_vm.pageData.TOTALPAGES},on:{"loadPage":function (n) { return _vm.loadPage(n); }}}),_c('div',{staticClass:"clearfix"}),_c('add-footer',{attrs:{"hideBottomPanelClose":true,"gotoSearch":_vm.gotoSearch,"totalAlerts":_vm.pageData.TOTALALERTS}},[_c('div',{staticClass:"segments",attrs:{"slot":"footer_right"},slot:"footer_right"}),_c('div',{attrs:{"slot":"bottomPanelContent"},slot:"bottomPanelContent"})])]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"right actionsBtnColor gray",staticStyle:{"width":"150px !important"}},[_c('span',{staticClass:"name"},[_vm._v(" Actions ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"actionsBtn btn right actionsBtnColor gray",staticStyle:{"width":"150px !important"}},[_c('span',{staticClass:"name"},[_vm._v(" Actions ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',[_vm._v("Standard Name")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head-item"},[_c('span',[_vm._v("Accounts")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',[_vm._v("Product Quantity")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',[_vm._v("Total")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',[_vm._v("Create Date")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',[_vm._v("Update Date")])])])}]

export { render, staticRenderFns }