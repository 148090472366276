



































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { validateFn } from "../helpers";
import LaddaButton from "../components/LaddaButton.vue";
import apiModal from "../components/apiCreate.vue";
import UserStatus, { UserStatusValue } from "../components/UserStatus.vue";
import { notifier, wait } from "../models/common";
import { any } from "prop-types";

declare const dataURL: string;
declare const $: any;
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    apiModal,
    UserStatus
  }
})
export default class External extends TSXComponent<void> {
  $validator: any;
  apiModalVisible = false;
  loading = false;
  currentRole = sessionStorage.getItem("userRole");
  varSourceSynnex: {
    VARSOURCECREDENTIAL_USERNAME?: string;
    VARSOURCECREDENTIAL_PASSWORD?: string;
    VARSOURCECREDENTIAL_EXTRA1?: string;
  } = {};
  varSourceTech: {
    VARSOURCECREDENTIAL_USERNAME?: string;
    VARSOURCECREDENTIAL_PASSWORD?: string;
  } = {};
  synnexSaving = false;
  techDataSaving = false;
  options = [];
  varSourceAPI = [];
  varApiStatusCount = 0;
  detail = {};
  DistributorData:
  {
    VARSOURCE_NAME:string;
    DISTFIELDS:{VARSOURCECREDDEFINITIONID:string}[];
    }[] = [];
  dispWhiteGlove = false;
  DHAPIKey: any;

  async apiModalReload() {
    this.apiModalVisible = false;
    await this.fetchData();
  }
  async created() {
    await this.fetchData();
  }
  async fetchData() {
    // this.loading = true;
    // var self = this;
    // var synnexDataObj = {
    //   subsystem: "VAR360",
    //   controller: "VARSources",
    //   FunctionName: "View",
    //   varsource_id: 3
    // };
    // let resultVarSynnex = getRouteData(synnexDataObj);
    // resultVarSynnex.then(function(response, statusText, jqXHR) {
    //   self.varSourceSynnex = response.data.VARSOURCESCREDENTIAL[0];
    //   self.varSourceAPI = response.data.VARAPIDATAARRAY;
    //   self.varApiStatusCount = response.data.VARAPISTATUSCOUNT;
    // });

    // var techDataObj = {
    //   subsystem: "VAR360",
    //   controller: "VARSources",
    //   FunctionName: "View",
    //   varsource_id: 2
    // };
    // let resultVarTech = getRouteData(techDataObj);
    // resultVarTech.then(function(response, statusText, jqXHR) {
    //   self.varSourceTech = response.data.VARSOURCESCREDENTIAL[0];
    // });
    // this.loading = false;

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARSources",
        FunctionName: "distributor",
        subsystem: "VAR360",
      });
      if (response.data.STATUS) {
        this.DistributorData = response.data.DistributorData;
        if(this.DistributorData){
          this.DistributorData = this.DistributorData.map((tmp) => ({
              ...tmp,
            loading: false
          }));
        }
      }
    }catch (err) {
      // console.log(err);
    }finally {
      this.loading = false;
    }
  }
  // async changeStatus(apiKey, status, action) {
  //   const statusIndex = status;
  //   var apiDataObj = {
  //     subsystem: "VAR360",
  //     controller: "VARSources",
  //     FunctionName: "apiKeyUpdate",
  //     apiKey: apiKey,
  //     action: action,
  //     active: status
  //   };
  //   let updateApiKey = getRouteData(apiDataObj);
  //   updateApiKey.then(function(response, statusText, jqXHR) {
  //     if (response.data.STATUS) notifier.success(response.data.STATUSMESSAGE);
  //   });
  //   await wait(4000);
  //   await this.fetchData();
  // }

  // async validateBeforeSubmit(e) {
  //   // return this.submit();
  // }

  // async synnexButton() {
  //   var self = this;
  //   var dataObj = {
  //     subsystem: "VAR360",
  //     controller: "VARSources",
  //     FunctionName: "Add",
  //     varsourcecredential_username: this.varSourceSynnex
  //       .VARSOURCECREDENTIAL_USERNAME,
  //     varsourcecredential_password: this.varSourceSynnex
  //       .VARSOURCECREDENTIAL_PASSWORD,
  //     varsourcecredential_extra1: this.varSourceSynnex
  //       .VARSOURCECREDENTIAL_EXTRA1,
  //     varsource_id: 3
  //   };
  //   if (
  //     $("#email").val() != "" &&
  //     $("#password").val() != "" &&
  //     $("#accountid").val() != "" &&
  //     !this.$validator.errors.items.length
  //   ) {
  //     try {
  //       this.synnexSaving = true;
  //       const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
  //       if (response.data.STATUS) {
  //         this.detail = response.data;
  //       }
  //     }catch (err) {
  //       console.log(err);
  //     }finally {
  //       this.synnexSaving = false;
  //     }
  //   }
  // }
  // async techDataButton() {
  //   var self = this;
  //   var dataObj = {
  //     subsystem: "VAR360",
  //     controller: "VARSources",
  //     FunctionName: "Add",
  //     varsourcecredential_username: this.varSourceTech
  //       .VARSOURCECREDENTIAL_USERNAME,
  //     varsourcecredential_password: this.varSourceTech
  //       .VARSOURCECREDENTIAL_PASSWORD,
  //     varsource_id: 2
  //   };
  //   if ($("#ecid").val() != "" && $("#techDataPassword").val() != "") {
  //     self.techDataSaving = true;
  //     let resultCategoryTrend = getRouteData(dataObj);
  //     resultCategoryTrend.then(function(response, statusText, jqXHR) {
  //       self.detail = response.data;
  //       self.techDataSaving = false;
  //     });
  //   }
  // }

  getDistributorClass(name) {
    return name.toLowerCase().replace(" ", "-") + "-data";
  }

  getRef(name) {
    return name.toLowerCase().replace(" ", "-") + "Ref";
  }

  getInputType(field) {
    if(field.INPUTTYPE == "text") {
      return "text";
    }else if(field.INPUTTYPE == "password") {
      return "password";
    }
  }

  whBtnEnable() {
    this.dispWhiteGlove = !this.dispWhiteGlove;
  }

  getFieldID(field) {
    return "field-" + field.VARSOURCECREDDEFINITIONID;
  }

  isEmailField(name) {
    if(name.toLowerCase().indexOf("email") != -1) {
      return true;
    }
    return false;
  }

  async saveDistributorData(distributor) {
    var otherFieldIDs:any[] = [];
    if(this.DistributorData){
      for(var i in this.DistributorData) {
        if(this.DistributorData[i].VARSOURCE_NAME != distributor.VARSOURCE_NAME) {
          const fields = this.DistributorData[i].DISTFIELDS.map(tmp => "field-" + tmp.VARSOURCECREDDEFINITIONID);
          otherFieldIDs.push(fields.join());
        }
      }
    }
    otherFieldIDs = otherFieldIDs.join().split(",");

    var isValid = true;
    await this.$validator.validateAll().then(async result => {
      if(!result) {
        for(let i in otherFieldIDs) {
          this.$delete(this.$validator.errors.items, this.$validator.errors.items.findIndex(val => val.field == otherFieldIDs[i]));
        }

        if(this.$validator.errors.items.length) {
          isValid = false;
        }
      }
    });

    if(!isValid) {
      return false;
    }

    var data = {
      subsystem: "VAR360",
      controller: "VARSources",
      FunctionName: "Add",
      action: "updateDistributorData",
      varsource_id: distributor.VARSOURCE_ID
    }

    if(distributor.VARSOURCE_NAME == "TechData" || distributor.VARSOURCE_ID == 4) {
      let tmpVal = distributor.DISTFIELDS.find(tmp => tmp.VARSCD_CREDENTIALFIELDTYPE == "username");
      data["varsourcecredential_username"] = tmpVal.CREDFIELDVALUE || "";

      tmpVal = distributor.DISTFIELDS.find(tmp => tmp.VARSCD_CREDENTIALFIELDTYPE == "password");
      data["varsourcecredential_password"] = tmpVal.CREDFIELDVALUE || "";
    }else if(distributor.VARSOURCE_NAME == "Synnex" || distributor.VARSOURCE_NAME == "TDSynnex") {
      let tmpVal = distributor.DISTFIELDS.find(tmp => tmp.VARSCD_CREDENTIALFIELDTYPE == "username");
      data["varsourcecredential_username"] = tmpVal.CREDFIELDVALUE || "";

      tmpVal = distributor.DISTFIELDS.find(tmp => tmp.VARSCD_CREDENTIALFIELDTYPE == "password");
      data["varsourcecredential_password"] = tmpVal.CREDFIELDVALUE || "";

      tmpVal = distributor.DISTFIELDS.find(tmp => tmp.VARSCD_CREDENTIALFIELDTYPE == "extra1");
      data["varsourcecredential_extra1"] = tmpVal.CREDFIELDVALUE || "";
    }else if(distributor.VARSOURCE_NAME == "Stripe") {
      data["varsourcecredential_username"] = "Stripe";

      let tmpVal = distributor.DISTFIELDS.find(tmp => tmp.VARSCD_CREDENTIALFIELDTYPE == "password");
      data["varsourcecredential_password"] = tmpVal.CREDFIELDVALUE || "";

      tmpVal = distributor.DISTFIELDS.find(tmp => tmp.VARSCD_CREDENTIALFIELDTYPE == "extra1");
      data["varsourcecredential_extra1"] = tmpVal.CREDFIELDVALUE || "";
    }

    if(distributor.VARSOURCE_NAME == "Stripe") {
      // validate Stripe keys
      var stripeSecret = data["varsourcecredential_password"];
      var stripePublishable = data["varsourcecredential_extra1"];
      if(stripeSecret.indexOf('sk_') > -1 && stripePublishable.indexOf('pk_') > -1) {
        await $.getScript("https://js.stripe.com/v2/", function(data, textStatus, jqxhr) {
        }).fail(function(jqxhr, settings, exception) {
          // console.log("Stripe load failes");
        });

        if(typeof Stripe != "undefined") {
          Stripe.setPublishableKey(stripePublishable);
          Stripe.card.createToken($("#credentialsFrm"), async function(status, stripeResponse) {
            if(typeof stripeResponse.error != "undefined") {
              if(stripeResponse.error.type == "invalid_request_error" && typeof stripeResponse.error.code != "undefined" && stripeResponse.error.code == "parameter_missing") {//valid Stripe credentials
                try {
                  // eslint-disable-next-line require-atomic-updates
                  distributor.loading = true;
                  const response = await axios.post(dataURL + "?ReturnType=JSON", data);
                  const tmpMessage = response.data.STATUSMESSAGE || "";
                  if (response.data.STATUS) {
                    // eslint-disable-next-line require-atomic-updates
                    distributor.loading = false;
                    if(tmpMessage) {
                      notifier.success(tmpMessage);
                    }
                  }else {
                    // eslint-disable-next-line require-atomic-updates
                    distributor.loading = "error";
                    if(tmpMessage) {
                      notifier.alert(tmpMessage);
                    }
                  }
                }catch (err) {
                  // console.log(err.message);
                }
              }else {
                // eslint-disable-next-line require-atomic-updates
                distributor.loading = true;
                await wait(300);
                // eslint-disable-next-line require-atomic-updates
                distributor.loading = "error";
                notifier.alert("Invalid Credentials");
              }
            }
          });
        }  
			}else if(!(stripeSecret.indexOf("sk_") == 0) || !(stripePublishable.indexOf("pk_") == 0)) {
        // eslint-disable-next-line require-atomic-updates
        distributor.loading = true;
        await wait(300);
        // eslint-disable-next-line require-atomic-updates
        distributor.loading = "error";
        notifier.alert("Invalid Credentials");
			}
    }else {// techdata/synnex
      try {
        // eslint-disable-next-line require-atomic-updates
        distributor.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", data);
        const tmpMessage = response.data.STATUSMESSAGE || "";
        if (response.data.STATUS) {
          // eslint-disable-next-line require-atomic-updates
          distributor.loading = false;
          if(tmpMessage) {
            notifier.success(tmpMessage);
          }
        }else {
          // eslint-disable-next-line require-atomic-updates
          distributor.loading = "error";
          if(tmpMessage) {
            notifier.alert(tmpMessage);
          }
        }
      }catch (err) {
        // console.log(err);
      }
    }
  }
}
