


















































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import DropdownControl from "../components/DropdownControl.vue";
import LaddaButton from "../components/LaddaButton.vue";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    DropdownControl,
    LaddaButton
  }
})
export default class CustomerDepartmentTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "" })
  addNewType?: string;

  @Prop({ required: false, default: 0 })
  aID?: number;

  $parent: any;
  loading = false;
  deptName = "";
  isChecked = true;

  clickOutside() {
    this.$emit("close");
  }

  mounted() {
    $("#customer_department_name").focus();
  }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  async addDepartment() {
    var result = await this.$validator.validateAll();
    if(result) {
      this.loading = true;
      await this.$emit("addNewDepartment", [{
        "Name": this.deptName,
        "isSave": this.isChecked
      }]);
      this.loading = false;
    }
  }
}
