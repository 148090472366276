





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

import Pagination from "../components/Pagination.vue";
// import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue"
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import InfoTooltip from "../components/InfoTooltip.vue";

import {
  OrderAPIRepo,
  OrderStatus
} from "../repos/OrderAPIRepo";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import { Order } from "../models/Order";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import {
  colsResizeable,
  colsResizeableUpdate,
  VarSource,
  getDateRangeFilter
} from "@/helpers/ApiHelper";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { SpiffStatus } from "@/repos/SpiffAPIRepo";
declare function require(params: any): any;
const template = require("../templates/orders/orderList.handlebars");

const orderRepo = new OrderAPIRepo();
const userRepo = new UserAPIRepo();

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    ListHeaderActions,
    Pagination,
    // ConfirmRemoveItemModal,
    FilterCurrencyBox,
    ExportModal,
    CustomerSearchTooltip,
    InfoTooltip
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class OrderStatusPage extends TSXComponent<void> {
  selectedID: (number | undefined)[] = [];
  currentRole = sessionStorage.getItem("userRole");
  ISSEARCH = 0;
  TOTAL = 0;
  CURRENTYEAR = 0;
  LASTYEAR = 0;
  viewType = "CurrentUsers";
  showFilter = 0;
  loading = false;
  ordersData: Order.Root | any = {};
  filters: any = {
    bState: "",
    searchAll: "",
    searchId: "",
    searchName: "",
    searchAccountName: "",
    sProfitMin: undefined,
    sProfitMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    sDate: undefined,
    eDate: undefined,
    sStatus: [1, 2, 3, 4, 5, 7, 10, 13, 14, 0],
    resellerCheckList: [],
    AccountTagID: [],
    selectedAccs: [],
    order: "",
    sSource: [],
    gTagIDs: [],
    groupIDs: [],
    accountTagName: [],
    selectedAccsName: [],
    groupName: []
  };
  totalPages: number = 0;
  sort: any = {
    field: null,
    direction: {
      poId: 1,
      orderName: 1,
      customerName: 1,
      reseller: 1,
      source: 1,
      status: 1,
      dateTime: 1,
      details: 1
    }
  };
  directionField = "";
  pageNumber =
    sessionStorage.pageDetails &&
    JSON.parse(sessionStorage.pageDetails)["Order"]
      ? JSON.parse(sessionStorage.pageDetails)["Order"]
      : 1;
  items: Order.Order[] = [];

  confirmRemoveModalVisible = false;
  exportModalVisible = false;
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  resellers: { ACCOUNTID?: number }[] = [];
  selectedAll = false;
  curYear = new Date().getFullYear();
  lYear = new Date().getFullYear() - 1;
  excludedIDs: number[] = [];
  selectedView = false;
  details: any = [];
  orderCommInfo = {};
  commInfoVisibleIndex = -1;
  accountTags: any = [];
  searchTagsTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  searchAccTxt = "";
  filterBtn = "currentList";
  orderAllStatus: number[] = [1, 2, 3, 4, 5, 7, 10, 13, 14, 0];
  searchFilters: {
    searchAll: string;
    searchId: string;
    searchName: string;
    searchAccountName: string;
    sProfitMin?: string;
    sProfitMax?: string;
    sTotalMin?: string;
    sTotalMax?: string;
    sDate?: string;
    eDate?: string;
    sStatus: number[];
    resellerCheckList: number[];
    AccountTagID: number[];
    selectedAccs: number[];
    order: string;
    sSource: number[];
    gTagIDs: number[];
    groupIDs: number[];
    accountTagName?: (string | undefined)[]
    selectedAccsName?: (string | undefined)[]
    groupName?: (string | undefined)[]
  } = {
    searchAll: "",
    searchId: "",
    searchName: "",
    searchAccountName: "",
    sProfitMin: undefined,
    sProfitMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    sDate: undefined,
    eDate: undefined,
    sStatus: [1, 2, 3, 4, 5, 7, 10, 13, 14, 0],
    resellerCheckList: [],
    AccountTagID: [],
    selectedAccs: [],
    order: "",
    sSource: [],
    gTagIDs: [],
    groupIDs: [],
    accountTagName: [],
    selectedAccsName: [],
    groupName: []
  };

  get paginationVisible(): boolean {
    return this.totalPages !== 1;
  }

  async created() {
    if (typeof this.$route.query.searchAll === "string") {
      this.filters.searchAll = this.$route.query.searchAll;
    }
    if (typeof this.$route.query.searchId === "string") {
      this.filters.searchId = this.searchFilters.searchId = this.$route.query.searchId;
    }
    if (typeof this.$route.query.searchName === "string") {
      this.filters.searchName = this.searchFilters.searchName = this.$route.query.searchName;
    }
    if (typeof this.$route.query.searchAccountName === "string") {
      this.filters.searchAccountName = this.searchFilters.searchAccountName = this.$route.query.searchAccountName;
    }
    if (typeof this.$route.query.sProfitMin === "string") {
      this.filters.sProfitMin = this.$route.query.sProfitMin;
    }
    if (typeof this.$route.query.sProfitMax === "string") {
      this.filters.sProfitMax = this.$route.query.sProfitMax;
    }
    if (typeof this.$route.query.sTotalMin === "string") {
      this.filters.sTotalMin = this.$route.query.sTotalMin;
    }
    if (typeof this.$route.query.sTotalMax === "string") {
      this.filters.sTotalMax = this.$route.query.sTotalMax;
    }
    if (typeof this.$route.query.sDate === "string") {
      this.filters.sDate = this.$route.query.sDate;
    }
    if (typeof this.$route.query.eDate === "string") {
      this.filters.eDate = this.$route.query.eDate;
    }

    if (typeof this.$route.query.sStatus === "string") {
      this.filters.sStatus = [parseInt(this.$route.query.sStatus)];
    } else if (Array.isArray(this.$route.query.sStatus)) {
      this.filters.sStatus = this.$route.query.sStatus.map(s =>
        parseInt(s || "0")
      );
    }

    if (typeof this.$route.query.filterView === "string") {
      this.viewType = this.$route.query.filterView;
      this.selectedView = true;
    } else if (typeof this.$route.query.filterView === "number") {
      var $this = this;
      this.accountTags.forEach(function(val: any) {
        if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == "undefined") {
          $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
        } else if (
          !isNaN(val.ACCOUNTTAGID) &&
          typeof val.ISGROUP != "undefined"
        ) {
          $this.filters.groupIDs.push(val.ACCOUNTTAGID);
        }
      });
    }

    await this.fetchData(false, true);
    await this.accsLoad();

    if (this.currentRole === "Customer") {
      const response = await userRepo.getResellers();
      this.resellers = response.RESELLERS;
    }

    colsResizeable({ ms: 500 });
  }

  get query() {
    const query = Object.entries(this.filters).reduce((a, [k, v]) => {
      if (Array.isArray(v)) {
        return v.length === 0 ? a : { ...a, [k]: v };
      }

      return v == null || v === "" ? a : { ...a, [k]: v };
    }, {});
    return query;
  }

  async fetchData(
    isstatusfilter: boolean = false,
    initialLoad: boolean = false
  ) {
    this.loading = true;
    this.hasChangedSourceFilters = false;
    this.hasChangedStatusFilters = false;
    this.hasChangedAccountFilters = false;
    try {
      this.$router.replace({ query: this.query }).catch(tmp => {});
      const ordersData = await orderRepo.findStatus(
        this.filters,
        this.sort,
        this.pageNumber,
        this.viewType,
        this.selectedView,
        isstatusfilter,
        this.filterBtn,
        initialLoad,
        this.orderAllStatus
      );

      if (ordersData.STATUS == 1) {
        this.ordersData = ordersData;
        this.customerTooltipVisibleIndex = -1;
        this.details = ordersData;
        this.ISSEARCH = ordersData.ISSEARCH;
        this.TOTAL = ordersData.TOTAL;
        var date = new Date().getFullYear();
        this.curYear = date;
        this.lYear = date - 1;

        if (
          this.filters.eDate != undefined ||
          this.filters.resellerCheckList.length != 0 ||
          this.filters.sDate != undefined ||
          this.filters.sStatus.length != 0 ||
          this.filters.sProfitMax != undefined ||
          this.filters.sProfitMin != undefined ||
          this.filters.sTotalMax != undefined ||
          this.filters.sTotalMin != undefined ||
          this.filters.searchAccountName != "" ||
          this.filters.searchAll != "" ||
          this.filters.searchId != "" ||
          this.filters.searchName != ""
        ) {
          this.showFilter = 1;
        } else {
          this.showFilter = 0;
        }
        this.items = ordersData.ORDERS;
        if (this.searchTagsTxt == "") {
          this.accountTags = this.details.accountTags;
        }

        if (
          !this.selectedView &&
          typeof ordersData["userSelectedCurrentView"] != "undefined"
        ) {
          this.selectedView = true;
          this.viewType = ordersData["userSelectedCurrentView"];
        }
        if (
          typeof this.ordersData.sourcesObj != "undefined" &&
          this.filters.sSource.length == 0 &&
          initialLoad
        ) {
          this.filters.sSource = Object.keys(this.ordersData.sourcesObj);
          this.filters.sourceName = Object.values(this.ordersData.sourcesObj);
        }

        colsResizeableUpdate({ ms: 50 });
      }
    } catch (err) {
      console.log(err);
      // notifier.alert(err.message);
    } finally {
      this.loading = false;
    }

    if (this.selectedAll === true) {
      this.selectAll(true);
    }
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    this.filters.directionField = directionField;
    await this.fetchData();
  }

  selectallStatus() {
    this.selectedID = this.ordersData.orderIds;
    $(".actionsBtn")
      .removeClass("gray")
      .addClass("teal");
  }
  UnselectallStatus() {
    this.selectedID = [];
    $(".actionsBtn")
      .addClass("gray")
      .removeClass("teal");
  }
  // async sortingById() {
  //   this.sortBy(SortField.Id);
  // }

  // async sortingByName() {
  //   this.sortBy(SortField.Name);
  // }

  // async sortingByReseller() {
  //   this.sortBy(SortField.Customer);
  // }

  // async sortingByProfit() {
  //   this.sortBy(SortField.Profit);
  // }

  // async sortingByTotal() {
  //   this.sortBy(SortField.Total);
  // }

  // async sortingByDate() {
  //   this.sortBy(SortField.Date);
  // }

  // async sortingByStatus() {
  //   this.sortBy(SortField.Status);
  // }

  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }
  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    // console.log(item)
    // console.log(index)
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      // const subAccounts = await CustomerRepo.findOneFewDetails(item.SECURITYGROUPID, FewDetailsJustGet.Users)
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  async searchChange(searchData) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.searchAll = searchData;
    await this.fetchData();
  }

  async checkStatusList(status: number) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.sStatus.findIndex(s => s === status);
    if (i === -1) {
      this.filters.sStatus.push(status);
    } else {
      this.filters.sStatus.splice(i, 1);
    }
    this.hasChangedStatusFilters = true;
    // await this.fetchData(true);
  }

  async checkAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sStatus = [
      OrderStatus.Placed,
      OrderStatus.Received,
      OrderStatus.Shipped,
      OrderStatus.Delivered,
      OrderStatus.Paid,
      OrderStatus.Cancelled,
      OrderStatus.PartialPay,
      OrderStatus.Complete,
      OrderStatus.CancelledComplete,
      0
    ];
    await this.fetchData();
  }

  async uncheckAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sStatus = [-1];
    await this.fetchData();
  }

  async resetStatusList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sStatus = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllStatusList();
    await this.fetchData();
  }

  async resetSearchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchId = this.searchFilters.searchId = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchName = this.searchFilters.searchName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByAccountName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sProfitMin = undefined;
    this.filters.sProfitMax = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = undefined;
    this.filters.sTotalMax = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.filters.sDate == undefined || this.filters.sDate == "") &&
      (this.filters.eDate == "" || this.filters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = undefined;
      this.filters.eDate = undefined;
      this.searchFilters.sDate = undefined;
      this.searchFilters.eDate = undefined;
      // $("#sDate").val("");
      // $("#eDate").val("");
      await this.fetchData();
    }
  }

  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchId == "") {
      return;
    } else {
      this.filters.searchId = this.searchFilters.searchId;
      await this.fetchData();
    }
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchName == "") {
      return;
    } else {
      this.filters.searchName = this.searchFilters.searchName;
      await this.fetchData();
    }
  }

  async searchByAccountName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchAccountName == "") {
      return;
    } else {
      this.filters.searchAccountName = this.searchFilters.searchAccountName;
      await this.fetchData();
    }
  }

  async searchByProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async searchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else if (
      !document!.querySelector<HTMLInputElement>("#sDate")!.validity!.valid ||
      !document!.querySelector<HTMLInputElement>("#eDate")!.validity!.valid
    ) {
      var errDate = "";
      if (
        !document!.querySelector<HTMLInputElement>("#sDate")!.validity!.valid
      ) {
        errDate += "start";
      }
      if (
        !document!.querySelector<HTMLInputElement>("#eDate")!.validity!.valid
      ) {
        if (errDate.length) {
          errDate += " & ";
        }
        errDate += " end ";
      }
      notifier.alert("Please Fill Valid " + errDate + " Date");
    } else {
      this.filters.sDate = this.searchFilters.sDate;
      this.filters.eDate = this.searchFilters.eDate;
      this.selectedAll = false;
      this.excludedIDs = [];
      await this.fetchData();
    }
  }

  /*async removeSelected() {
    this.loading = true
    this.confirmRemoveModalVisible = false
    let successCount = 0

    //for (const purchaseID of this.selectedID) {
      const reqBody = {
        session: sessionStorage.getItem('sessionID'),
        userRole: sessionStorage.getItem('userRole'),
        Controller: 'orders',
        FunctionName: 'Delete',
        //purchaseID,
        deletePurchase: 'true',
        deletePurchaseFile: 'true',
        selectedAll: this.selectedAll,
        excludedIDs: this.excludedIDs,
        selectedIDs: this.selectedID.join(",")
      };
      
      const response = await axios.post(dataURL + '?ReturnType=JSON', reqBody)
      if (response.data.STATUS !== 1) {
        if (response.data.ERROR || response.data.STATUSMESSAGE) {
          notifier.alert(response.data.ERROR || response.data.STATUSMESSAGE)
        }
        //continue
      }else {
        $(".actionsBtn").addClass('gray').removeClass('teal');
        notifier.success(`${successCount} Order(s) Deleted Successfully`)
        this.selectedID = [];
        this.selectedAll = false;
        this.excludedIDs = [];

        await this.fetchData()
      }

      //successCount++
    //}

    // if (successCount > 0) {
    //   $(".actionsBtn").addClass('gray').removeClass('teal');
    //   notifier.success(`${successCount} Order(s) Deleted Successfully`)
    //   this.selectedID = [];
    //   this.selectedAll = false;
    //   this.excludedIDs = [];
    // }
    // await this.fetchData()
    this.loading = false
  }*/

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if (
      sessionStorage.pageDetails != undefined &&
      JSON.parse(sessionStorage.pageDetails)["Order"]
    ) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["Order"] = this.pageNumber;
      sessionStorage.setItem("pageDetails", JSON.stringify(oldData));
    }

    if (this.pageNumber <= this.ordersData.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async htmlDownLoadFn() {
    const html = await this.selectedOrdersHtml("html");

    return () => {
      if (!html) return;
      var fileNameExport =
        "OrderList_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async selectedOrdersHtml(from: string): Promise<string | false> {
    try {
      var orderList = await orderRepo.exportOrderBySelect(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        [],
        1
      );
      orderList["isOrderStatus"] = 1;
      if (from == "print") {
        orderList["type"] = "print";
      }
      return template(orderList);
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async print() {
    this.loading = true;
    const html = await this.selectedOrdersHtml("print");
    this.loading = false;
    if (!html) return;

    printHtml(html);
  }

  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await orderRepo.exportListsPDFFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        [],
        1
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await orderRepo.exportListsCSVFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        [],
        1
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  // showConfirmRemoveItemModal() {
  //   this.confirmRemoveModalVisible = true
  // }

  async performExport(formats: ExportFormat[]) {
    this.loading = true;
    this.exportModalVisible = false;

    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  async filterByReseller() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async checkAllResellerList() {
    var comInstance = this;
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.resellerCheckList = [];
    this.resellers.forEach(function(val) {
      comInstance.filters.resellerCheckList.push(val.ACCOUNTID);
    });
    await this.fetchData();
  }

  async resetResellerList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.resellerCheckList = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async toggleCustomView(currStatus) {
    this.viewType = currStatus;
    this.selectedAll = false;
    this.excludedIDs = [];
    this.selectedID = [];
    this.selectedView = true;
    this.pageNumber = 1;

    await this.fetchData();
    await this.accsLoad();
  }

  selectAll(selectedAll = true) {
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.items) {
        if (!this.excludedIDs.includes(val.STATUS_ID)) {
          this.selectedID.push(val.STATUS_ID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }
  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll && total) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }
  checkExcludedIDs(items, key) {
    if (this.selectedAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.TOTAL) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }

  commInfoLoading = false;
  async showCommInfo(index, item) {
    if (this.commInfoVisibleIndex === index || item.COMMISSIONPERCENT == 0) {
      return;
    }

    this.orderCommInfo = {};
    this.commInfoVisibleIndex = index;
    this.commInfoLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "GetCommissionLinesByPONumber",
        poNumber: item.POID
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (
        response.data.STATUS == 1 &&
        typeof response.data.COMMISSIONROWS != "undefined"
      ) {
        this.orderCommInfo = response.data.COMMISSIONROWS;
        this.orderCommInfo["PAYMENTPERCENTFORMATTED"] =
          response.data.PAYMENTPERCENTFORMATTED;
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      this.commInfoLoading = false;
    }
  }

  resetSelected() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
  }

  //filter by Tags
  async filterByTag(tagName = "", groupName = "") {
    this.hasChangedAccountFilters = true;

    if (tagName != "" && this.filters.accountTagName != undefined) {
      const tagIndex = this.filters.accountTagName.findIndex(
        tagValue => tagValue === tagName
      );
      if (tagIndex === -1) {
        this.filters.accountTagName.push(tagName);
      } else {
        this.filters.accountTagName.splice(tagIndex, 1);
      }
    }

    if (groupName != "" && this.filters.groupName != undefined) {
      const groupIndex = this.filters.groupName.findIndex(
        groupValue => groupValue === groupName
      );
      if (groupIndex === -1) {
        this.filters.groupName.push(groupName);
      } else {
        this.filters.groupName.splice(groupIndex, 1);
      }
    }

    // this.resetSelected();
    // this.pageNumber = 1;
    // await this.fetchData();
  }
  async checkAllTag() {
    this.resetSelected();
    const $this = this;
    this.filters.AccountTagID = [];
    this.filters.accountTagName = [];
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == "undefined") {
        if (typeof val.ISGLOBALTAG == "undefined") {
          if (
            $this.filters.AccountTagID != undefined &&
            $this.filters.accountTagName != undefined
          ) {
            $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
            $this.filters.accountTagName.push(val.TAGNAME);
          }
        } else {
          $this.filters.gTagIDs.push(val.ACCOUNTTAGID);
          if ($this.filters.accountTagName != undefined) {
            $this.filters.accountTagName.push(val.TAGNAME);
          }
        }
      }
    });
    this.filterByTag();
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    var $this = this;
    this.resetSelected();
    this.filters.groupIDs = [];
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != "undefined") {
        $this.filters.groupIDs.push(val.ACCOUNTTAGID);
      }
    });
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag(allowFetchData = true) {
    this.resetSelected();
    this.filters.AccountTagID = [];
    this.filters.accountTagName = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    this.filters.groupName = [];
    this.pageNumber = 1;
    this.filterByTag();
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  filterTagsInBox() {
    let options = this.details["accountTags"];

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }

  itemProfit(item) {
    return item.PROFIT.replace(/[$|,\s]/g, "");
  }
  toggleActive(div) {
    this.isActive = div;
  }
  hasChangedAccountFilters = false;
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc(accountsName: string) {
    this.hasChangedAccountFilters = true;
    if (
      accountsName != undefined &&
      this.filters.selectedAccsName != undefined
    ) {
      const accountsIndex = this.filters.selectedAccsName.findIndex(
        accountsValue => accountsValue === accountsName
      );
      if (accountsIndex === -1) {
        this.filters.selectedAccsName.push(accountsName);
      } else {
        this.filters.selectedAccsName.splice(accountsIndex, 1);
      }
    }
    // await this.fetchData();
  }
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.filters.selectedAccsName = this.varAccs.map(val => val.ANAME);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.filters.selectedAccsName = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async accsLoad() {
    if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          view: this.viewType,
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
          if (this.searchAccTxt != "") {
            await this.filterAccsInBox();
          }
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }
  hasChangedSourceFilters = false;
  hasChangedStatusFilters = false;
  async checkSourceList(status: string, name: string) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      this.filters.sSource != undefined &&
      this.filters.sourceName != undefined
    ) {
      const i = this.filters.sSource.findIndex(s => s === status);
      const sourceIndex = this.filters.sourceName.findIndex(
        sourceValue => sourceValue === name
      );
      if (i === -1) {
        this.filters.sSource.push(status);
      } else {
        this.filters.sSource.splice(i, 1);
      }
      if (sourceIndex === -1) {
        this.filters.sourceName.push(name);
      } else {
        this.filters.sourceName.splice(sourceIndex, 1);
      }
    }
    this.hasChangedSourceFilters = true;
    // await this.fetchData();
  }
  async checkAllSourceList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sSource = Object.keys(this.ordersData.sourcesObj);
    this.filters.sourceName = Object.values(this.ordersData.sourcesObj);
    await this.fetchData();
  }
  async uncheckSourceList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sSource = [];
    this.filters.sourceName = [];
    await this.fetchData();
  }
  async resetSourceList() {
    this.pageNumber = 1;
    this.filters.sSource = [];
    this.filters.sourceName = [];
    this.checkAllSourceList();
  }

  isHPDirectOSSAuto(sourceId) {
    return sourceId == VarSource.HPDirectOSSAuto;
  }

  async resetColumn(header) {
    switch (header) {
      case "ACCOUNT":
        this.resetSearchByAccountName(false);
        this.resetAcc(false);
        this.resetTag(false);
        await this.fetchData();
        break;
      default:
        break;
    }
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'STATUS':
        if ( this.filters.sStatus && !this.filters.sStatus.length) {
          filter = "None";
        } else if (this.filters.sStatus && this.filters.sStatus.length != 10) {
          const arrStatusNames: string[] = [];
          if (this.filters.sStatus.includes(1)) {
            arrStatusNames.push('Placed');
          }
          if (this.filters.sStatus.includes(2)) {
            arrStatusNames.push('Received');
          }
          if (this.filters.sStatus.includes(3)) {
            arrStatusNames.push('Shipped');
          }
          if (this.filters.sStatus.includes(4)) {
            arrStatusNames.push('Delivered');
          }
          if (this.filters.sStatus.includes(5)) {
            arrStatusNames.push('Paid');
          }
          if (this.filters.sStatus.includes(7)) {
            arrStatusNames.push('Cancelled');
          }
          if (this.filters.sStatus.includes(10)) {
            arrStatusNames.push('Partial Pay');
          }
          if (this.filters.sStatus.includes(13)) {
            arrStatusNames.push('Complete');
          }
          if (this.filters.sStatus.includes(14)) {
            arrStatusNames.push('Cancelled-Complete');
          }
          if (this.filters.sStatus.includes(0)) {
            arrStatusNames.push('Unassigned');
          }
          filter = arrStatusNames.join(', ');
        }
        break;
      case 'SOURCE':
        if (this.filters.sourceName && !this.filters.sourceName.length)
          filter = "None";
        else if (
          this.filters.sourceName &&
          !(Object.keys(this.ordersData.sourcesObj).length == this.filters.sourceName.length)
        ) {
          filter = this.filters.sourceName.join(", ");
        }
        break;
      case 'ACCOUNT':
      {
        const arr: string[] = [];
        if(this.filters.searchAccountName) {
          arr.push(`Customer: ${this.filters.searchAccountName}`);
        }
        if(this.filters.gTagIDs.length || this.filters.AccountTagID.length) {
          arr.push(`Customer Tags: ${this.filters.accountTagName}`);
        }
        if(this.filters.selectedAccs.length) {
          arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
        }
        if(this.filters.groupIDs.length) {
          arr.push(`Customer Groups: ${this.filters.groupName}`);
        }
        searchKey = arr.join('; ');
        break;
      }
      case 'DATE':
        filter = getDateRangeFilter(this.filters.sDate, this.filters.eDate);
        searchKey = '';
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`Min: ${min}`);
    }
    if (max) {
      arr.push(`Max: ${max}`);
    }
    return arr.join('; ');
  }

}
