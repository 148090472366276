













































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import directives from "../../helpers/directives";
import { notifier } from "@/models/common";
import Loader from "@/components/Loader.vue";

declare const $: any;
declare const dataURL: string;

interface Props {}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Loader
  },
  directives
})
export default class ModalAddDeploymentNote extends TSXComponent<void> {
  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: 0 })
  endUserId?: number;

  @Prop({ required: false, default: "" })
  endUserName?: string;

  $refs!: {
    modal: HTMLDivElement;
  };

  loading = false;
  saving: any = false;
  note = "";
  errors: any;

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    setTimeout(() => {
      $("#deploymentNote").focus();
    }, 100);

    await this.getNote();
  }

  async getNote() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "portalGetScheduleATime",
        deploymentId: this.deploymentId,
        endUserId: this.endUserId,
        aID: this.$route.params.aID
      });
      if (response.data.STATUS == 1) {
        const scheduleATime = response.data.scheduleATime || [];
        const timeInfo = scheduleATime.find(
          item => item.ENDUSERID == this.endUserId
        );
        if (timeInfo) {
          this.note = timeInfo.NOTES || "";
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async saveNote() {
    // validate
    if (this.note == "") return;

    const routeParams = this.$route.params;
    const VNAI_Account = routeParams.aID || 0;
    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateScheduleGroupNotes",
        deploymentId: this.deploymentId,
        endUserId: this.endUserId,
        itemType: 2,
        groupNotes: this.note,
        VNAI_Account
      });
      if (response.data.STATUS == 1) {
        notifier.success("Saved note successfully");
        this.$emit("reloadNotes");
        this.$emit("close");
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message != "") {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.saving = false;
    }
  }
}
