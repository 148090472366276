





















































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import PhoneInput from "../components/PhoneInput.vue";
import DropdownControl from "../components/DropdownControl.vue";
// import { USState } from "../repos/UserAPIRepo";
import USState from "../static/USstates.json";
import PageTitle from '../components/pageTitle.vue';

declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;


@Component({
  inheritAttrs: false,
  components: {
    EmployeeMultiselect,
    LaddaButton,
    PhoneInput,
    DropdownControl,
    PageTitle
  }
})
export default class UserAdd extends TSXComponent<void> {
  saving: any = false;
  currentRole = sessionStorage.getItem("userRole");
  isEmailExists = false;
  stateList: any = [];
  selectedState: string[] = [];
  items: null | {
    ufname?: string;
    ulname?: string;
    uaddress?: string;
    ucity?: string;
    ustate?: string;
    uzip?: string;
    uemail?: string;
    uphone?: string;
  } = {
    ufname: "",
    ulname: "",
    uaddress: "",
    ucity: "",
    ustate: "",
    uzip: "",
    uemail: "",
    uphone: ""
  };
  accountList: {
    SELECTEDACCOUNTS?:
      | null
      | {
          ENAME?: any;
          EMPLOYEEID?: any;
          AID?: any;
        }[];
    SELECTEDUSERS?:
      | null
      | {
          AID?: any;
          ENAME?: any;
          EMPLOYEEID?: any;
        }[];
  } = {
    SELECTEDACCOUNTS: [
      {
        ENAME: "",
        EMPLOYEEID: "",
        AID: ""
      }
    ],
    SELECTEDUSERS: [
      {
        AID: "",
        ENAME: "",
        EMPLOYEEID: ""
      }
    ]
  };
  aID = "";
  customer = "";
  $refs!: {
    drodown: DropdownControl;
  };

  // data() {

  // 	return{
  // 	}
  // },
  created() {
    var self = this;
    var dataObj = {};
    dataObj["controller"] = "varUSers";
    dataObj["subsystem"] = "VAR360";
    dataObj["FunctionName"] = "View";
    var usersResult = getRouteData(dataObj);
    usersResult.then(function(response, statusText) {
      self.accountList = response.data;
      // self.$root.accountList = response.data;
    });
    for (const i in USState) {
			this.stateList.push({
				ID: USState[i].Abbr,
				TEXT: USState[i].Abbr,
				data: USState[i]
			});
		}
  }
  listPageRedirection() {
    this.$router.push({ name: "Users" });
  }
  listPageCustomerRedirection() {
    this.$router.push({ name: "customerList" });
  }
  // ,methods:{
  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$validator.validateAll().then(async result => {
      //make sure an account is selected
      if (this.aID == "" && this.customer != "") {
        $("#customer")
          .parent()
          .find(".errorMsg")
          .html("Please select a customer from suggested list.");
        result = false;
      }
      if (
        !$("#uemail")
          .val()
          .match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}")
      ) {
        result = false;
      }
      // if ($("#uphone .input-tel__input").val() == "") {
      //   $(".errorMsg .phone").text("Please enter phone number");
      //   result = false;
      // } else {
      //   $(".errorMsg .phone").text("");
      // }
      if ($("#uzip").val() != "") {
        if (
          !$("#uzip")
            .val()
            .match("^[0-9]{5}$")
        ) {
          $(".errorMsg.zip").text("Please Enter a Valid Zip Code");
          result = false;
        } else {
          $(".errorMsg.zip").text("");
        }
      }
      // if ($("#ustate").val() != "") {
      //   if (
      //     !$("#ustate")
      //       .val()
      //       .match("^[a-zA-Z ]*$")
      //   ) {
      //     $(".errorMsg.state").text("Please Enter Valid text format");
      //     result = false;
      //   } else {
      //     $(".errorMsg.state").text("");
      //   }
      // }
      //validate data input
      if (
        $("#ufname").val() == "" ||
        $("#ulname").val() == "" ||
        $("#ucity").val() == "" ||
        // $("#ustate").val() == "" ||
        $("#uzip").val() == "" 
      ) {
        $("#errorMsgLineQuote").html(
          "Please make sure all your Personl Information is filled out!"
        );
        result = false;
      }

      if (result) {
        e.preventDefault();
        this.saving = true;
        await this.saveFn(this.items);
        // this.saving = false
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  // updateAccountList(type, index, employid) {
  // 	if(type == "delete"){
  // 		this.$delete(this.accountList.SELECTEDACCOUNTS, index);
  // 		$('.addAccount option[val='+employid+']').attr('disabled',false);
  // 		$('.addAccount option[val='+employid+']').removeClass('highlight');
  // 	}
  // }
  // updateUserList(type, index, employid) {
  // 	if(type == "delete"){
  // 		this.$delete(this.accountList.SELECTEDUSERS, index);
  // 		$('.addUser option[val='+employid+']').attr('disabled',false);
  // 		$('.addUser option[val='+employid+']').removeClass('highlight');
  // 	}
  // }
  // onChangeAccount() {
  // 	var included = 0;
  // 	for (var i = 0; i < this.accountList.SELECTEDACCOUNTS.length; i++) {
  // 		if(this.accountList.SELECTEDACCOUNTS[i].AID == $(".addAccount option:selected span").attr('data-id')){
  // 			included = 1;
  // 		}
  // 	}

  // 	if(included != 1){
  // 		this.accountList.SELECTEDACCOUNTS.push(
  // 			{
  // 				'ENAME': $(".addAccount option:selected span").attr('data-name'),
  // 				'EMPLOYEEID': $(".addAccount option:selected span").attr('data-id')
  // 			}
  // 		);
  // 	}
  // }
  // onChangeUser() {
  // 	var included = 0;
  // 	for (var i = 0; i < this.accountList.SELECTEDUSERS.length; i++) {
  // 		if(this.accountList.SELECTEDUSERS[i].AID == $(".addUser option:selected span").attr('data-id')){
  // 			included = 1;
  // 		}
  // 	}

  // 	if(included != 1){
  // 		this.accountList.SELECTEDUSERS.push(
  // 			{
  // 				'ENAME': $(".addUser option:selected span").attr('data-name'),
  // 				'EMPLOYEEID': $(".addUser option:selected span").attr('data-id')
  // 			}
  // 		);
  // 	}
  // }
  toggleSelectedUserId(userId, accountName) {
    // To select single user
    this.accountList.SELECTEDACCOUNTS = [];
    const index = this.accountList.SELECTEDACCOUNTS.findIndex(
      id => id === userId
    );
    if (index === -1) {
      this.accountList.SELECTEDACCOUNTS.push({
        ENAME: accountName,
        EMPLOYEEID: userId
      });
      return;
    }
    this.$delete(this.accountList.SELECTEDACCOUNTS, index);
  }
  toggleSelectedSecurityId(userId, accountName) {
    // To select single user
    this.accountList.SELECTEDUSERS = [];
    const index = this.accountList.SELECTEDUSERS.findIndex(id => id === userId);
    if (index === -1) {
      this.accountList.SELECTEDUSERS.push({
        ENAME: accountName,
        EMPLOYEEID: userId
      });
      return;
    }
    this.$delete(this.accountList.SELECTEDUSERS, index);
  }
  async saveFn(data) {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "");
    const accountid = currentUser[0]["ACCOUNTID"];
    var accountsList: string[] = [];
    $.each(this.accountList.SELECTEDACCOUNTS, function(i, val) {
      accountsList.push(val.EMPLOYEEID);
    });

    var userList: string[] = [];
    $.each(this.accountList.SELECTEDUSERS, function(i, val) {
      userList.push(val.EMPLOYEEID);
    });
    var saveObj = {
      controller: "VARUsers",
      FunctionName: "Add",
      subsystem: "VAR360",
      ufname: data.ufname,
      ulname: data.ulname,
      uemail: data.uemail,
      uaddress: data.uaddress,
      ucity: data.ucity,
      ustate: data.ustate,
      uzip: data.uzip,
      uphone: data.uphone,
      accountList: accountsList.join(","),
      userList: userList.join(",")
    };
    saveObj["accountid"] = accountid;
    var self = this;
    const saveRes = getRouteData(saveObj);
    return saveRes.then(function(response, statusText, jqXHR) {
      if (response.data.STATUS == 1) {
        if (self.currentRole != "Customer") {
          self.$router.push({ name: "Users" });
        } else {
          self.$router.push({ name: "customerList" });
        }
        self.isEmailExists = false;
        self.saving = false;
      } else if (response.data.STATUS == 2) {
        // notifier.alert(response.data.MESSAGE);
        self.isEmailExists = true;
        self.saving = "error";
      } else if (response.data.ERROR) {
        self.saving = "error";
        console.log(response.data.ERROR);
      }
    });
  }
  // }
  addUserState(selectedID) {
    this.selectedState = [selectedID];
    if(this.items){
      this.items.ustate = selectedID;
    }
  }
  close(){
    this.$refs.drodown.dropdownVisible = false;
	}
}
