









































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { validateFn } from "../helpers";

interface Props {}

interface Events {
  onClose: void;
  onOk: void;
}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class CustomerCustomFieldsModal extends TSXComponent<Props, Events> {
  $refs!: {
    modal: HTMLDivElement;
  };

  fieldID = "";
  fieldLabel = "";
  fieldRequired = false;
  fieldMaxlength = 30;
  fieldPlaceHolder = "";
  customMessages = {
    fieldLabel: "",
    fieldID: ""
  };

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  ok() {
    var comInstance = this;
    this.$validator.validateAll().then((result) => {
      /** do some custom validate **/
      //make sure fieldID is unique on the form
      var valid;
      if($('#fieldLabel').val() == '') {
        $(".errorMsg.fieldLabel").text("The Label field is required");
        valid = false;
      }
      else {
        $(".errorMsg.fieldLabel").text("");
      }
      if(!$('#fieldLabel').val().match('^[A-Za-z0-9 ]*$')) {
          $(".errorMsg.fieldLabel").text("Please Enter Valid Label");
          valid = false;
      }
      else {
          $(".errorMsg.fieldLabel").text("");
      }
      if(!$("#fieldMaxlength").val().match('^[0-9]*$')) {
        $(".errorMsg.fieldMaxlength").html("Please enter a valid Max length");
        result = false;
      }
      if($("#" + comInstance.fieldID).length) {
        result = false;
        this.customMessages.fieldID = "The Field ID already exists. Please check on the form.";
      }

      if(result == true) {
        this.$emit("ok", {
          "id"          : this.fieldID,
          "type"        : "text",
          "required"    : this.fieldRequired ? 1 : 0,
          "maxlength"   : this.fieldMaxlength,
          "placeholder" : this.fieldPlaceHolder,
          "label"       : this.fieldLabel,
          "class"       : "customField",
          "value"       : ""
        });
        this.$emit("close");
      }
    });
  }

  setSomeDefaultVals() {
    if(this.fieldLabel != "") {
      this.fieldID = this.fieldLabel.replace(/\s/g, "_"); 
      this.fieldPlaceHolder = "Enter " + this.fieldLabel; 
    }
  }
}
