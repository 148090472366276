var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_header : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":10,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_paragraph : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_shipment_notes : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":2},"end":{"line":40,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRemitAddress : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":2},"end":{"line":134,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInfoHeader : depth0),{"name":"if","hash":{},"fn":container.program(83, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":2},"end":{"line":719,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(215, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":721,"column":2},"end":{"line":725,"column":9}}})) != null ? stack1 : "")
    + "  \n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(218, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":727,"column":2},"end":{"line":746,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_table : depth0),{"name":"if","hash":{},"fn":container.program(224, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":748,"column":2},"end":{"line":774,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_data_fields : depth0),{"name":"if","hash":{},"fn":container.program(234, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":776,"column":2},"end":{"line":805,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-header "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":110}}})) != null ? stack1 : "")
    + " element-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":119},"end":{"line":4,"column":125}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-header-content\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":54}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":4,"column":87},"end":{"line":4,"column":103}}}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <table width=\"100%\" class=\"element-paragraph "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":51},"end":{"line":14,"column":116}}})) != null ? stack1 : "")
    + " element-"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":125},"end":{"line":14,"column":131}}}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.header : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "      </table>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-paragraph-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":14,"column":93},"end":{"line":14,"column":109}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <tr>\n            <td class=\"element-paragraph-header\">"
    + container.escapeExpression(((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"header","hash":{},"data":data,"loc":{"start":{"line":17,"column":49},"end":{"line":17,"column":59}}}) : helper)))
    + "</td>\n          </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <tr>\n            <td class=\"element-paragraph-content\">"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":22,"column":50},"end":{"line":22,"column":59}}}) : helper)))
    + "</td>\n          </tr>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":39,"column":11}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-paragraph "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":51},"end":{"line":31,"column":116}}})) != null ? stack1 : "")
    + " element-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":31,"column":125},"end":{"line":31,"column":131}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-paragraph-header\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":33,"column":47},"end":{"line":33,"column":55}}}) : helper)))
    + "</td>\n        </tr>\n        <tr>\n          <td class=\"element-paragraph-content\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":36,"column":48},"end":{"line":36,"column":57}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <table width=\"100%\" id=\"quoteDetails_header\">\n      <tr>\n        <td style=\"color:#bbb; line-height: 20px; letter-spacing: 1.7px; padding-right: 5px; padding-top: 20px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showRemitAddress),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":10},"end":{"line":69,"column":17}}})) != null ? stack1 : "")
    + "        </td>\n        <td colspan=\"2\"><img src=\"https://smartstoresgeneric.s3.amazonaws.com/mail/milestonetechlogo.png\" style=\"display:block; border: 0; float: right; height: 52px;\" border=\"0\" /></td>\n      </tr>\n      <tr>\n        <td colspan=\"3\">\n          <table width=\"100%\">\n            <tr>\n              <td style=\"padding: 20px 0;font-size: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":77,"column":52},"end":{"line":77,"column":145}}})) != null ? stack1 : "")
    + "px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\">\n                <span style=\"color: #0196d5;\">PURCHASE ORDER #:</span>\n                <span style=\"font-size: 22px !important;\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":79,"column":58},"end":{"line":79,"column":175}}})) != null ? stack1 : "")
    + "</span>\n              </td>\n              <td align=\"right\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.sourceRepInfo),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":16},"end":{"line":93,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showLogo),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(61, data, 0),"data":data,"loc":{"start":{"line":95,"column":16},"end":{"line":103,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showTempIndicator),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":16},"end":{"line":107,"column":23}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n          </table>\n        </td>\n      </tr>\n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"unless","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":6},"end":{"line":132,"column":17}}})) != null ? stack1 : "")
    + "    </table>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":52,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":57,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":62,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":67,"column":19}}})) != null ? stack1 : "")
    + " <br />\n            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":68,"column":12},"end":{"line":68,"column":141}}})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "              <strong>Milestone Computer Technology Inc.</strong>\n            ";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress), depth0)) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "              314 Wilcox St. Ste.\n            ";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState), depth0)) != null ? stack1 : "")
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "              101 Castle Rock, CO 80104\n            ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo), depth0)) != null ? stack1 : "")
    + "\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":66,"column":14},"end":{"line":66,"column":156}}})) != null ? stack1 : "")
    + "\n            ";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText), depth0))
    + " ";
},"36":function(container,depth0,helpers,partials,data) {
    return "303-848-8405 ";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue), depth0))
    + " ";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Email), depth0))
    + " ";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize), depth0));
},"44":function(container,depth0,helpers,partials,data) {
    return "26";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO), depth0))
    + " ";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + " ";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <div style=\"text-align:right; padding: 20px 0; font-size: 26px; letter-spacing: 2px; font-weight: bold; color: #a5a5a5; white-space: nowrap;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.synnex),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":85,"column":20},"end":{"line":91,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n";
},"51":function(container,depth0,helpers,partials,data) {
    return "                      TD SYNNEX Corporation \n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.program(56, data, 0),"data":data,"loc":{"start":{"line":87,"column":20},"end":{"line":91,"column":20}}})) != null ? stack1 : "");
},"54":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + "\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + "\n                    ";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LOGOSRC)) && stack1.length),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":18},"end":{"line":98,"column":25}}})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span style=\"border-radius: 50%;\"><img src="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LOGOSRC), depth0))
    + " style=\"height: 75px;\"/></span>\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showCustomerName),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":16},"end":{"line":103,"column":16}}})) != null ? stack1 : "");
},"62":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":18},"end":{"line":102,"column":25}}})) != null ? stack1 : "")
    + "                ";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span style=\"font-size: 25px; font-weight: 600;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "</span>\n";
},"65":function(container,depth0,helpers,partials,data) {
    return "                  <span style=\"padding: 10px; background-color: #cdcdcd; text-align:center; color: white;\">Customer Indicator</span>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":8},"end":{"line":131,"column":15}}})) != null ? stack1 : "");
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <tr>\n            <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 20px;letter-spacing: 1.7px;\">\n              <strong>Ship To: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(71, data, 0),"data":data,"loc":{"start":{"line":118,"column":31},"end":{"line":118,"column":151}}})) != null ? stack1 : "")
    + "</strong><br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.program(75, data, 0),"data":data,"loc":{"start":{"line":119,"column":14},"end":{"line":128,"column":21}}})) != null ? stack1 : "")
    + "            </td>\n          </tr>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO), depth0));
},"71":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0));
},"73":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.program(78, data, 0),"data":data,"loc":{"start":{"line":122,"column":16},"end":{"line":127,"column":23}}})) != null ? stack1 : "");
},"76":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.program(81, data, 0),"data":data,"loc":{"start":{"line":125,"column":18},"end":{"line":126,"column":105}}})) != null ? stack1 : "")
    + "\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<strong>"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ACITY), depth0))
    + ",\n                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ASTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AZIP), depth0))
    + "</strong>";
},"81":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"83":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.draggableOptions),{"name":"each","hash":{},"fn":container.program(84, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":4},"end":{"line":718,"column":13}}})) != null ? stack1 : "");
},"84":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(85, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":138,"column":6},"end":{"line":717,"column":13}}})) != null ? stack1 : "");
},"85":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <table width=\"100%\" id=\"quoteDetails_info\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isContractNo : depth0),{"name":"if","hash":{},"fn":container.program(86, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":10},"end":{"line":147,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOtherOptions : depth0),{"name":"if","hash":{},"fn":container.program(89, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":10},"end":{"line":715,"column":17}}})) != null ? stack1 : "")
    + "        </table>\n";
},"86":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contractNumber),{"name":"if","hash":{},"fn":container.program(87, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":14},"end":{"line":145,"column":21}}})) != null ? stack1 : "")
    + "            </tr>\n";
},"87":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td width=\"30%\" class=\"title\">Contract Number</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SPECIALPRICINGID), depth0))
    + "</strong></td></tr>\n";
},"89":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].cols : depths[1]),{"name":"if","hash":{},"fn":container.program(90, data, 0, blockParams, depths),"inverse":container.program(146, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":149,"column":12},"end":{"line":689,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES),{"name":"if","hash":{},"fn":container.program(202, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":691,"column":12},"end":{"line":694,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"unless","hash":{},"fn":container.program(204, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":696,"column":12},"end":{"line":701,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMSBYORDER),{"name":"if","hash":{},"fn":container.program(207, data, 0, blockParams, depths),"inverse":container.program(209, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":703,"column":12},"end":{"line":714,"column":19}}})) != null ? stack1 : "");
},"90":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "              <tr>\n                <td width=\"60%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].cols : depths[1])) != null ? stack1.col1 : stack1),{"name":"each","hash":{},"fn":container.program(91, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":20},"end":{"line":348,"column":29}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n                <td width=\"40%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].cols : depths[1])) != null ? stack1.col2 : stack1),{"name":"each","hash":{},"fn":container.program(91, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":354,"column":20},"end":{"line":547,"column":29}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n              </tr>\n";
},"91":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_customerPO : depth0),{"name":"if","hash":{},"fn":container.program(92, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":22},"end":{"line":161,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_orderNumber : depth0),{"name":"if","hash":{},"fn":container.program(95, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":22},"end":{"line":168,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_orderDate : depth0),{"name":"if","hash":{},"fn":container.program(98, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":22},"end":{"line":175,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_accountRep : depth0),{"name":"if","hash":{},"fn":container.program(104, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":22},"end":{"line":182,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_phone : depth0),{"name":"if","hash":{},"fn":container.program(107, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":22},"end":{"line":199,"column":29}}})) != null ? stack1 : "")
    + "                      \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_email : depth0),{"name":"if","hash":{},"fn":container.program(113, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":201,"column":22},"end":{"line":216,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceRepInfo : depth0),{"name":"if","hash":{},"fn":container.program(119, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":218,"column":22},"end":{"line":314,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_shipTo : depth0),{"name":"if","hash":{},"fn":container.program(136, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":316,"column":22},"end":{"line":347,"column":29}}})) != null ? stack1 : "");
},"92":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":24},"end":{"line":160,"column":31}}})) != null ? stack1 : "");
},"93":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Customer PO </td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + "</strong></td></tr>\n";
},"95":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderNO),{"name":"if","hash":{},"fn":container.program(96, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":24},"end":{"line":167,"column":31}}})) != null ? stack1 : "");
},"96":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Order Number</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PURCHASEID), depth0))
    + "</strong></td></tr>\n";
},"98":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderDate),{"name":"if","hash":{},"fn":container.program(99, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":24},"end":{"line":174,"column":31}}})) != null ? stack1 : "");
},"99":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                          <tr><td class=\"title\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceRepInfo : depths[2]),{"name":"if","hash":{},"fn":container.program(100, data, 0, blockParams, depths),"inverse":container.program(102, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":172,"column":48},"end":{"line":172,"column":109}}})) != null ? stack1 : "")
    + "</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED), depth0))
    + "</strong></td></tr>\n";
},"100":function(container,depth0,helpers,partials,data) {
    return "P.O. DATE";
},"102":function(container,depth0,helpers,partials,data) {
    return "Order Date";
},"104":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(105, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":24},"end":{"line":181,"column":31}}})) != null ? stack1 : "");
},"105":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Account Rep</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "</strong></td></tr>\n";
},"107":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(108, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":185,"column":24},"end":{"line":198,"column":31}}})) != null ? stack1 : "");
},"108":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Phone</td></tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n                              <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(109, data, 0),"inverse":container.program(111, data, 0),"data":data,"loc":{"start":{"line":190,"column":32},"end":{"line":194,"column":39}}})) != null ? stack1 : "")
    + "                              </strong>\n                            </td>\n                          </tr>\n";
},"109":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"111":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":193,"column":34},"end":{"line":193,"column":176}}})) != null ? stack1 : "")
    + "\n";
},"113":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(114, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":202,"column":24},"end":{"line":215,"column":31}}})) != null ? stack1 : "");
},"114":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Email</td></tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n                              <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(115, data, 0),"inverse":container.program(117, data, 0),"data":data,"loc":{"start":{"line":207,"column":32},"end":{"line":211,"column":39}}})) != null ? stack1 : "")
    + "                              </strong>\n                            </td>\n                          </tr>\n";
},"115":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"117":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":210,"column":34},"end":{"line":210,"column":163}}})) != null ? stack1 : "")
    + "\n";
},"119":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceRepInfo : depths[2]),{"name":"if","hash":{},"fn":container.program(120, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":24},"end":{"line":313,"column":31}}})) != null ? stack1 : "");
},"120":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                          <tr>\n                            <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(121, data, 0, blockParams, depths),"inverse":container.program(123, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":222,"column":30},"end":{"line":310,"column":37}}})) != null ? stack1 : "")
    + "                            </td>\n                          </tr>\n";
},"121":function(container,depth0,helpers,partials,data) {
    return "";
},"123":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(124, data, 0, blockParams, depths),"inverse":container.program(134, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":223,"column":30},"end":{"line":310,"column":30}}})) != null ? stack1 : "");
},"124":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + alias2(alias1((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + " REP AND INFO\n                                    </td>\n                                  </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (data && data.root)) && stack1.EXPORTPO),{"name":"if","hash":{},"fn":container.program(125, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":248,"column":34},"end":{"line":252,"column":41}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(128, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":255,"column":34},"end":{"line":257,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(130, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":258,"column":34},"end":{"line":260,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(132, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":261,"column":34},"end":{"line":263,"column":41}}})) != null ? stack1 : "")
    + "                                </table>\n";
},"125":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.sourceAddr),{"name":"if","hash":{},"fn":container.program(126, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":249,"column":36},"end":{"line":251,"column":43}}})) != null ? stack1 : "");
},"126":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                      <tr><td style=\"padding: 2px 0; padding-bottom: 7px;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.sourceAddr), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"128":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"130":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"132":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"134":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + alias2(alias1((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + " REP AND INFO\n                                    </td>\n                                  </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (data && data.root)) && stack1.EXPORTPO),{"name":"if","hash":{},"fn":container.program(125, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":293,"column":34},"end":{"line":297,"column":41}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(128, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":300,"column":34},"end":{"line":302,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(130, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":303,"column":34},"end":{"line":305,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(132, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":306,"column":34},"end":{"line":308,"column":41}}})) != null ? stack1 : "")
    + "                                </table>\n                              ";
},"136":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(137, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":317,"column":24},"end":{"line":346,"column":31}}})) != null ? stack1 : "");
},"137":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                          <tr>\n                            <td class=\"title\">Ship To</td>\n                          </tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\"><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(71, data, 0),"data":data,"loc":{"start":{"line":322,"column":64},"end":{"line":322,"column":184}}})) != null ? stack1 : "")
    + "</strong></td>\n                          </tr>\n                          <tr>\n                            <td class=\"title\">Shipping Address</td>\n                          </tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(138, data, 0),"inverse":container.program(140, data, 0),"data":data,"loc":{"start":{"line":329,"column":30},"end":{"line":343,"column":37}}})) != null ? stack1 : "")
    + "                            </td>\n                          </tr>\n";
},"138":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"140":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(141, data, 0),"inverse":container.program(143, data, 0),"data":data,"loc":{"start":{"line":332,"column":32},"end":{"line":342,"column":39}}})) != null ? stack1 : "");
},"141":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"143":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS),{"name":"if","hash":{},"fn":container.program(144, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":335,"column":34},"end":{"line":341,"column":41}}})) != null ? stack1 : "");
},"144":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                    <strong>\n                                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS), depth0))
    + "\n                                      <br />\n                                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ACITY), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ASTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AZIP), depth0))
    + "\n                                    </strong>\n";
},"146":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "              <tr>\n                <td width=\"60%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(147, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":556,"column":20},"end":{"line":559,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderNO),{"name":"if","hash":{},"fn":container.program(149, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":560,"column":20},"end":{"line":563,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderDate),{"name":"if","hash":{},"fn":container.program(151, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":564,"column":20},"end":{"line":567,"column":27}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n                <td width=\"40%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(153, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":572,"column":20},"end":{"line":575,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(155, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":576,"column":20},"end":{"line":589,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(160, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":590,"column":20},"end":{"line":603,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].sourceRepInfo : depths[1]),{"name":"if","hash":{},"fn":container.program(165, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":605,"column":20},"end":{"line":685,"column":27}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n              </tr>\n";
},"147":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Customer PO </td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + "</strong></td></tr>\n";
},"149":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Order Number</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PURCHASEID), depth0))
    + "</strong></td></tr>\n";
},"151":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Order Date</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED), depth0))
    + "</strong></td></tr>\n";
},"153":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Account Rep</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "</strong></td></tr>\n";
},"155":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Phone</td></tr>\n                      <tr>\n                        <td style=\"padding: 2px 0;\">\n                          <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(156, data, 0),"inverse":container.program(158, data, 0),"data":data,"loc":{"start":{"line":581,"column":28},"end":{"line":585,"column":35}}})) != null ? stack1 : "")
    + "                          </strong>\n                        </td>\n                      </tr>\n";
},"156":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"158":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":584,"column":30},"end":{"line":584,"column":172}}})) != null ? stack1 : "")
    + "\n";
},"160":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Email</td></tr>\n                      <tr>\n                        <td style=\"padding: 2px 0;\">\n                          <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(161, data, 0),"inverse":container.program(163, data, 0),"data":data,"loc":{"start":{"line":595,"column":28},"end":{"line":599,"column":35}}})) != null ? stack1 : "")
    + "                          </strong>\n                        </td>\n                      </tr>\n";
},"161":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"163":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":598,"column":30},"end":{"line":598,"column":159}}})) != null ? stack1 : "")
    + "\n";
},"165":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                      <tr>\n                        <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(121, data, 0, blockParams, depths),"inverse":container.program(166, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":608,"column":26},"end":{"line":682,"column":33}}})) != null ? stack1 : "")
    + "                        </td>\n                      </tr>\n";
},"166":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(167, data, 0, blockParams, depths),"inverse":container.program(186, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":609,"column":26},"end":{"line":682,"column":26}}})) != null ? stack1 : "");
},"167":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                            <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                              <tr class=\"element-header-type"
    + alias2(alias1((depths[1] != null ? depths[1].templateType : depths[1]), depth0))
    + "\">\n                                <td class=\"element-header-content\">\n                                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + " REP AND INFO\n                                </td>\n                              </tr>\n                              <tr>\n                                <td><b>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME),{"name":"if","hash":{},"fn":container.program(168, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":617,"column":39},"end":{"line":617,"column":126}}})) != null ? stack1 : "")
    + "</b></td>\n                              </tr>\n                              <tr>\n                                <td><strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR1),{"name":"if","hash":{},"fn":container.program(170, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":620,"column":44},"end":{"line":620,"column":133}}})) != null ? stack1 : "")
    + "</strong></td>\n                              </tr>\n                              <tr>\n                                <td>\n                                  <strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR2),{"name":"if","hash":{},"fn":container.program(172, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":624,"column":42},"end":{"line":624,"column":131}}})) != null ? stack1 : "")
    + "</strong>\n                                </td>\n                              </tr>\n                              <tr>\n                                <td>\n                                  <strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYCITY),{"name":"if","hash":{},"fn":container.program(174, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":629,"column":42},"end":{"line":629,"column":129}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYSTATE),{"name":"if","hash":{},"fn":container.program(176, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":629,"column":129},"end":{"line":629,"column":225}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYZIP),{"name":"if","hash":{},"fn":container.program(178, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":629,"column":225},"end":{"line":629,"column":316}}})) != null ? stack1 : "")
    + "</strong>\n                                </td>\n                              </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(180, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":634,"column":30},"end":{"line":636,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(182, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":637,"column":30},"end":{"line":639,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(184, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":640,"column":30},"end":{"line":642,"column":37}}})) != null ? stack1 : "")
    + "                            </table>\n";
},"168":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0));
},"170":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR1), depth0));
},"172":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR2), depth0));
},"174":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYCITY), depth0));
},"176":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ",&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYSTATE), depth0));
},"178":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYZIP), depth0));
},"180":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"182":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"184":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"186":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                            <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                              <tr class=\"element-header-type"
    + alias2(alias1((depths[1] != null ? depths[1].templateType : depths[1]), depth0))
    + "\">\n                                <td class=\"element-header-content\">\n                                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + " REP AND INFO\n                                </td>\n                              </tr>\n                              <tr><td><b>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.NAME),{"name":"if","hash":{},"fn":container.program(187, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":651,"column":41},"end":{"line":651,"column":186}}})) != null ? stack1 : "")
    + "</b></td></tr>\n                              <tr><td><strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ATTRN),{"name":"if","hash":{},"fn":container.program(192, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":652,"column":46},"end":{"line":652,"column":137}}})) != null ? stack1 : "")
    + "</strong></td></tr>\n                              <tr>\n                                <td>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS),{"name":"if","hash":{},"fn":container.program(194, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":655,"column":34},"end":{"line":657,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS1),{"name":"if","hash":{},"fn":container.program(196, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":658,"column":34},"end":{"line":661,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.PHONE),{"name":"if","hash":{},"fn":container.program(198, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":662,"column":34},"end":{"line":664,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.EMAIL),{"name":"if","hash":{},"fn":container.program(200, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":665,"column":34},"end":{"line":667,"column":41}}})) != null ? stack1 : "")
    + "                                </td>\n                              </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(180, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":672,"column":30},"end":{"line":674,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(182, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":675,"column":30},"end":{"line":677,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(184, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":678,"column":30},"end":{"line":680,"column":37}}})) != null ? stack1 : "")
    + "                            </table>\n                          ";
},"187":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.synnex),{"name":"if","hash":{},"fn":container.program(188, data, 0),"inverse":container.program(190, data, 0),"data":data,"loc":{"start":{"line":651,"column":83},"end":{"line":651,"column":179}}})) != null ? stack1 : "");
},"188":function(container,depth0,helpers,partials,data) {
    return "TD SYNNEX Corporation";
},"190":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.NAME), depth0)) != null ? stack1 : "");
},"192":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ATTRN), depth0)) != null ? stack1 : "");
},"194":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"196":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>\n                                    <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS1), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"198":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.PHONE), depth0)) != null ? stack1 : "")
    + "\n";
},"200":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.EMAIL), depth0)) != null ? stack1 : "")
    + "\n";
},"202":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Notes</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES), depth0))
    + "</strong></td></tr>\n";
},"204":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES),{"name":"if","hash":{},"fn":container.program(205, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":697,"column":14},"end":{"line":700,"column":21}}})) != null ? stack1 : "");
},"205":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td width=\"30%\" class=\"title\">Shipment Notes</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES), depth0))
    + "</strong></td></tr>\n";
},"207":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMSBYORDER), depth0))
    + "</strong></td></tr>\n";
},"209":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS),{"name":"if","hash":{},"fn":container.program(210, data, 0),"inverse":container.program(212, data, 0),"data":data,"loc":{"start":{"line":706,"column":12},"end":{"line":714,"column":12}}})) != null ? stack1 : "");
},"210":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS), depth0))
    + "</strong></td></tr>\n";
},"212":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS),{"name":"if","hash":{},"fn":container.program(213, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":710,"column":14},"end":{"line":713,"column":21}}})) != null ? stack1 : "")
    + "            ";
},"213":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS), depth0))
    + "</strong></td></tr>\n";
},"215":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCoreProduct : depth0),{"name":"if","hash":{},"fn":container.program(216, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":722,"column":4},"end":{"line":724,"column":11}}})) != null ? stack1 : "");
},"216":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./orderDetails_items.handlebars"),depth0,{"name":"orderDetails_items","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"218":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isFooterDetail : depth0),{"name":"if","hash":{},"fn":container.program(219, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":728,"column":4},"end":{"line":745,"column":11}}})) != null ? stack1 : "");
},"219":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showFooterDetail),{"name":"if","hash":{},"fn":container.program(220, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":729,"column":6},"end":{"line":731,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.signature : depth0),{"name":"if","hash":{},"fn":container.program(222, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":734,"column":6},"end":{"line":744,"column":13}}})) != null ? stack1 : "");
},"220":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./orderDetails_summary.handlebars"),depth0,{"name":"orderDetails_summary","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"222":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "        <table width=\"100%\" id=\"quoteDetails_notes\">\n          <tr><td>&nbsp;</td></tr>\n          <tr>\n            <td width=\"60%\"></td>\n            <td width=\"40%\">\n              <img src=\""
    + alias1(((helper = (helper = helpers.signature || (depth0 != null ? depth0.signature : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"signature","hash":{},"data":data,"loc":{"start":{"line":740,"column":24},"end":{"line":740,"column":37}}}) : helper)))
    + "\" alt=\"signature\" style=\"width: 180px;\"/> "
    + alias1(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CURRENTDATE), depth0))
    + "\n            </td>\n          </tr>\n        </table>\n";
},"224":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(225, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":750,"column":4},"end":{"line":756,"column":11}}})) != null ? stack1 : "")
    + "    <table width=\"100%\" class=\"element-table "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(227, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":758,"column":45},"end":{"line":758,"column":106}}})) != null ? stack1 : "")
    + "\">\n      <thead>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.headers : stack1),{"name":"each","hash":{},"fn":container.program(229, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":760,"column":8},"end":{"line":762,"column":17}}})) != null ? stack1 : "")
    + "      </thead>\n      <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.rows : stack1),{"name":"each","hash":{},"fn":container.program(231, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":765,"column":8},"end":{"line":771,"column":17}}})) != null ? stack1 : "")
    + "      </tbody>\n    </table>\n";
},"225":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-header "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":751,"column":48},"end":{"line":751,"column":110}}})) != null ? stack1 : "")
    + " element-header-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":751,"column":126},"end":{"line":751,"column":132}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-header-content\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":753,"column":45},"end":{"line":753,"column":54}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"227":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-table-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":758,"column":83},"end":{"line":758,"column":99}}}) : helper)));
},"229":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <th>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":761,"column":14},"end":{"line":761,"column":23}}}) : helper)))
    + "</th>\n";
},"231":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(232, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":767,"column":10},"end":{"line":769,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"232":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <td>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":768,"column":16},"end":{"line":768,"column":25}}}) : helper)))
    + "</td>\n";
},"234":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "    <table width=\"100%\" id=\"quoteDetails_items\" style=\"padding-top: 0; padding-bottom: 0;\">\n      <tbody>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showEUs : depth0),{"name":"if","hash":{},"fn":container.program(235, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":780,"column":8},"end":{"line":802,"column":15}}})) != null ? stack1 : "")
    + "      </tbody>\n    </table>\n";
},"235":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.orderEUs),{"name":"if","hash":{},"fn":container.program(236, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":781,"column":10},"end":{"line":801,"column":17}}})) != null ? stack1 : "");
},"236":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <tr class=\"element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":782,"column":42},"end":{"line":782,"column":58}}}) : helper)))
    + "\">\n              <td class=\"element-header-content\">\n                End Users\n              </td>\n            </tr>\n            <tr>\n              <td style=\"padding-top: 1px;\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.orderEUs),{"name":"each","hash":{},"fn":container.program(237, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":789,"column":16},"end":{"line":798,"column":25}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n";
},"237":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                  <div "
    + ((stack1 = helpers["if"].call(alias1,(data && data.index),{"name":"if","hash":{},"fn":container.program(238, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":790,"column":23},"end":{"line":790,"column":68}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euName : depths[1]),{"name":"if","hash":{},"fn":container.program(240, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":791,"column":20},"end":{"line":793,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euEmail : depths[1]),{"name":"if","hash":{},"fn":container.program(244, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":794,"column":20},"end":{"line":796,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n";
},"238":function(container,depth0,helpers,partials,data) {
    return "style=\"margin-top: 3px;\"";
},"240":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                      <span>"
    + container.escapeExpression(((helper = (helper = helpers.EUNAME || (depth0 != null ? depth0.EUNAME : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"EUNAME","hash":{},"data":data,"loc":{"start":{"line":792,"column":28},"end":{"line":792,"column":38}}}) : helper)))
    + "</span>"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euEmail : depths[1]),{"name":"if","hash":{},"fn":container.program(241, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":792,"column":45},"end":{"line":792,"column":93}}})) != null ? stack1 : "")
    + "\n";
},"241":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.EMAIL : depth0),{"name":"if","hash":{},"fn":container.program(242, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":792,"column":63},"end":{"line":792,"column":86}}})) != null ? stack1 : "");
},"242":function(container,depth0,helpers,partials,data) {
    return " - ";
},"244":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                      <span>"
    + container.escapeExpression(((helper = (helper = helpers.EMAIL || (depth0 != null ? depth0.EMAIL : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EMAIL","hash":{},"data":data,"loc":{"start":{"line":795,"column":28},"end":{"line":795,"column":37}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.templateItemReorder : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":806,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});