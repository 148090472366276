








import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Dashboard from '../components/FinanceDashboard.vue'
@Component({
  inheritAttrs: false,
  components: {
    Dashboard,
  },
})
export default class DashboardPage extends TSXComponent<void> {
}
