var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "COST PRICE";
},"3":function(container,depth0,helpers,partials,data) {
    return "PRICE PER";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.BUILDHEADER : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ISCONFIG : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":50}}})) != null ? stack1 : "")
    + ">\n      <td width=\"50%\" style=\"padding-left: "
    + alias4(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":56}}}) : helper)))
    + "px; font-style: "
    + alias4(((helper = (helper = helpers.FONTSTYLE || (depth0 != null ? depth0.FONTSTYLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FONTSTYLE","hash":{},"data":data,"loc":{"start":{"line":22,"column":72},"end":{"line":22,"column":85}}}) : helper)))
    + ";\">\n        <strong>"
    + ((stack1 = ((helper = (helper = helpers.POPRODDESC || (depth0 != null ? depth0.POPRODDESC : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODDESC","hash":{},"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":32}}}) : helper))) != null ? stack1 : "")
    + "</strong><br/>\n        <span style=\"text-transform: uppercase;\">"
    + ((stack1 = ((helper = (helper = helpers.POPRODSKU || (depth0 != null ? depth0.POPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODSKU","hash":{},"data":data,"loc":{"start":{"line":25,"column":49},"end":{"line":25,"column":64}}}) : helper))) != null ? stack1 : "")
    + "</span>\n      </td>\n      <td width=\"10%\" style=\"text-align: center;\">"
    + ((stack1 = ((helper = (helper = helpers.POPRODQUANTITY || (depth0 != null ? depth0.POPRODQUANTITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODQUANTITY","hash":{},"data":data,"loc":{"start":{"line":27,"column":50},"end":{"line":27,"column":70}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].EXPORTPO : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":28,"column":49},"end":{"line":28,"column":144}}})) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].EXPORTPO : depths[1]),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":29,"column":49},"end":{"line":29,"column":151}}})) != null ? stack1 : "")
    + "</td>\n    </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function";

  return "      <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ISCONFIG : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":52}}})) != null ? stack1 : "")
    + ">\n        <td width=\"50%\" style=\"padding-left: "
    + container.escapeExpression(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":11,"column":45},"end":{"line":11,"column":58}}}) : helper)))
    + "px; font-style: regular;\">\n          <div style=\"border-bottom: 2px solid #f0f0f0; padding-bottom: 3px;display: inline-block;\">\n            <strong style=\"font-size: 12px; font-weight: 600; color: #000000;\">"
    + ((stack1 = ((helper = (helper = helpers.BUILDHEADER || (depth0 != null ? depth0.BUILDHEADER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BUILDHEADER","hash":{},"data":data,"loc":{"start":{"line":13,"column":79},"end":{"line":13,"column":96}}}) : helper))) != null ? stack1 : "")
    + "</strong>\n          </div>\n        </td>\n        <td width=\"10%\"> </td>\n        <td width=\"10%\"></td>\n        <td width=\"15%\"></td>\n      </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"config-line\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " "
    + ((stack1 = ((helper = (helper = helpers.POPRICEREG_FORMATTED || (depth0 != null ? depth0.POPRICEREG_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"POPRICEREG_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":28,"column":69},"end":{"line":28,"column":95}}}) : helper))) != null ? stack1 : "")
    + " ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " "
    + ((stack1 = ((helper = (helper = helpers.POCUSTOMERPRICE_FORMATTED || (depth0 != null ? depth0.POCUSTOMERPRICE_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"POCUSTOMERPRICE_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":28,"column":105},"end":{"line":28,"column":136}}}) : helper))) != null ? stack1 : "")
    + " ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " "
    + ((stack1 = ((helper = (helper = helpers.LIPOPRICEREG_FORMATTED || (depth0 != null ? depth0.LIPOPRICEREG_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LIPOPRICEREG_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":29,"column":69},"end":{"line":29,"column":97}}}) : helper))) != null ? stack1 : "")
    + " ";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " "
    + ((stack1 = ((helper = (helper = helpers.LICUSTOMERTOTALPRICE_FORMATTED || (depth0 != null ? depth0.LICUSTOMERTOTALPRICE_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LICUSTOMERTOTALPRICE_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":29,"column":107},"end":{"line":29,"column":143}}}) : helper))) != null ? stack1 : "")
    + " ";
},"17":function(container,depth0,helpers,partials,data) {
    return "";
},"19":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" colspan=\"2\" style=\"border-top: 5px solid #b1b1b1;\">&nbsp;</td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\">Subtotal:</td>\n    <td width=\"20%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + container.escapeExpression(((helper = (helper = helpers.ORDERITEMSTOTAL || (depth0 != null ? depth0.ORDERITEMSTOTAL : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ORDERITEMSTOTAL","hash":{},"data":data,"loc":{"start":{"line":37,"column":78},"end":{"line":37,"column":97}}}) : helper)))
    + "</td>\n  </tr>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" colspan=\"2\">\n        <strong>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.TAXMESSAGE : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":44,"column":10},"end":{"line":48,"column":17}}})) != null ? stack1 : "")
    + "          : "
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.LSDATE : stack1), depth0))
    + "\n        </strong>\n      </td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Estimated Tax:</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + alias2(((helper = (helper = helpers.TOTALTAXFORMATTED || (depth0 != null ? depth0.TOTALTAXFORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"TOTALTAXFORMATTED","hash":{},"data":data,"loc":{"start":{"line":54,"column":49},"end":{"line":54,"column":70}}}) : helper)))
    + "</td>\n    </tr>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.TAXMESSAGE : stack1), depth0))
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "            PLEASE NOTIFY US IMMEDIATELY IF THIS <br>ORDER CANNOT BE SHIPPED COMPLETE <br>TAX ON OR BEFORE\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Estimated Shipping:</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + container.escapeExpression(((helper = (helper = helpers.TOTALSHIPPINGFORMATTED || (depth0 != null ? depth0.TOTALSHIPPINGFORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TOTALSHIPPINGFORMATTED","hash":{},"data":data,"loc":{"start":{"line":61,"column":49},"end":{"line":61,"column":75}}}) : helper)))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"50%\" style=\"border-bottom: 5px solid #b1b1b1;\">LINE ITEM / SKU</td>\n    <td width=\"10%\" style=\"text-align: center; border-bottom: 5px solid #b1b1b1;\">QTY</td>\n    <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.EXPORTPO : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":81},"end":{"line":5,"column":131}}})) != null ? stack1 : "")
    + "</td>\n    <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">TOTAL</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ORDERITEMS : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.EXPORTPO : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":32,"column":2},"end":{"line":39,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.TOTALTAX : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":2},"end":{"line":56,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.TOTALSHIPPING : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":2},"end":{"line":63,"column":9}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Grand Total:</td>\n    <td width=\"20%\" style=\"text-align: right;\">"
    + container.escapeExpression(((helper = (helper = helpers.GRANDTOTALFORMATTED || (depth0 != null ? depth0.GRANDTOTALFORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"GRANDTOTALFORMATTED","hash":{},"data":data,"loc":{"start":{"line":67,"column":47},"end":{"line":67,"column":70}}}) : helper)))
    + "</td>\n  </tr>\n</table>";
},"useData":true,"useDepths":true});