
























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { PaycheckResp } from "../models/PaycheckResp";

interface Props {
  options: PaycheckResp.User[];
  selectedUserIds: number[];
  isSingleSelect: boolean;
  userFor: string;
  disabled: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class EmployeeMultiselect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: PaycheckResp.User[];

  @Prop({ required: true })
  selectedUserIds!: number[];

  @Prop({ required: false, default: false })
  isSingleSelect?: boolean;

  @Prop({ required: false, default: '' })
  userFor?: string;

  @Prop({ required: false, default: false })
  disabled?: boolean;

  @Prop({ required: false, default: false })
  highlightOptions?: boolean;

  dropdownVisible = false
  search = ''
  selecteduser = 'Select user'
  showOnlySelected: boolean = false

  get filteredOptions(): PaycheckResp.User[] {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => `${option.UFNAME} ${option.ULNAME}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }

    if (this.showOnlySelected) {
      options = options.filter((o) => this.selectedUserIds.indexOf(o.USERID) !== -1)
    }

    return options
  }

  toggleDropdown() {
    if(!this.disabled) {
      this.dropdownVisible = !this.dropdownVisible
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(userId: number): boolean {
    const checked = !!this.selectedUserIds.find((id) => id === userId)
    return checked
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  SelectUser(e){
    this.selecteduser = e.UFULLNAME;
    this.$emit('check', e.USERID,`${e.UFNAME} ${e.ULNAME}`)
    this.dropdownVisible = false
  }
}
