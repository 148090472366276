import axios from "axios";

import {SecurityGroupDetails} from '../models/SecurityGroupDetails';

declare const dataURL: string;

export enum FewDetailsJustGet {
  Users = 1,
  Accounts = 2,
  Functions = 3,
}

export class SecurityGroupAPIRepo {
  async findOneDetails(groupID: string): Promise<SecurityGroupDetails.Root> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "SecurityGroups",
      FunctionName: "View",
      groupID,
      Content: "Detailed"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async findOneFewDetails(groupID: string, justGet: FewDetailsJustGet): Promise<SecurityGroupDetails.Root> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "SecurityGroups",
      FunctionName: "View",
      groupID,
      Content: "few",
      justGet,
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }
}
