












import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "@/components/AutoSuggestInput.vue";


interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    line: any
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
})
export default class AlertNameAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, type: String, default: "" })
  InputValue!: string

  @Prop({ required: false, type: Boolean, default: false })
  isAutoAlert!: string

  @Prop({ required: true, default: 1 })
  type!: number;

  @Prop({ required: true, default: 0 })
  aID!: number;

  @Prop({ required: false, default: "" })
  alertCreateType!: string;

  @Model('input', {
    type: String,
    required: true
  })
  readonly value!: string

  options: Option[] = []
  searchData: any[] = [];

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {

    let action = "getAlertName";
    if (this.alertCreateType === "accountLevel") {
      action = "getAccountAlertName";
    } else if (this.isAutoAlert) {
      action = "getAutoAlertName";
    }
    if(!search) {
      const requestObj = {
        controller: "Queries",
        subsystem: "Helpers",
        FunctionName: "alerts",
        Action: action,
        aID: this.aID,
        userID: sessionStorage.getItem("userId"),
      }

      const response = await ApiHelper.callApi("post", requestObj);

      if (response.ERROR || !response.ALERT.length) {
        return {
          options: []
        };
      }

      let alertOptions = [];
      if (this.type == 3) { // if alert is for invoice, then only suggest invoice's alert
        alertOptions = response.ALERT.filter((alert) => alert.TYPE === 3);
      } else if (this.alertCreateType === "accountLevel") {
        alertOptions = response.ALERT || [];
      } else {
        alertOptions = response.ALERT.filter((alert) => alert.TYPE === 1 || alert.TYPE === 2);
      }

      return this.createOptions(alertOptions);
    }

    const requestObj = {
      controller: "Queries",
      subsystem: "Helpers",
      FunctionName: "alerts",
      Action: action,
      searchName: search.length ? search : this.InputValue,
      aID: this.aID,
      userID: sessionStorage.getItem("userId"),
    }

    const response = await ApiHelper.callApi("post", requestObj);
    if (response.ERROR || !response.ALERT.length) {
      return {
        options: []
      };
    }
    
    let alertOptions = [];
    if (this.type == 3) { // if alert is for invoice, then only suggest invoice's alert
      alertOptions = response.ALERT.filter((alert) => alert.TYPE === 3);
    } else if (this.alertCreateType === "accountLevel") {
      alertOptions = response.ALERT || [];
    } else {
      alertOptions = response.ALERT.filter((alert) => alert.TYPE === 1 || alert.TYPE === 2);
    }

    return this.createOptions(alertOptions);
  }

  createOptions(searchData: any[]) {
    const options = searchData.map(
      (item): Option => {
        return {
          value: item.ALERTNAME,
          label: item.ALERTNAME,
          text: item.ALERTNAME
        };
      }
    )
    this.searchData = searchData
    this.options = options

    return {options}
  }

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return;
    const option = this.options.find((o) => o.value === value)
    if (!option) {
      return
    }

    const line = this.searchData.find((item) => item.ALERTNAME === value || item.alertName === value)

    if (!line) {
      console.warn(`no line found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      line
    })
  }

  onBlur(data) {
    if (!data.value) {
      this.$emit('alertName', data.search);
    }
  }
}
