
























































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { validateFn } from "../helpers";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import AccountMultiselect from "../components/AccountMultiselect.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import confirmDeleteGroup from "../components/ConfirmRemoveSecurityGroup.vue";
import TagMultiselect from "../components/TagMultiselect.vue";
import PageTitle from '../components/pageTitle.vue';
import BusinessLineMultiselect from '../components/BusinessLineMultiselect.vue';
import FormFunctions from "@/components/Form/FormFunctions.vue";

const userRepo = new UserAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    FormFunctions,
    AccountMultiselect,
    EmployeeMultiselect,
    LaddaButton,
    confirmDeleteGroup,
    TagMultiselect,
    PageTitle,
    BusinessLineMultiselect
  }
})
export default class SecurityGroupsDetails extends TSXComponent<void> {
  selectedAccountIds: number[] = [];
  selectedUserIds: number[] = [];
  gname = "";
  updating: any = false;
  deleting: any = false;
  titleGroupName = "";
  disableGroup = true;
  confirmRemoveModalVisible = false;
  loading = false;
  secAccs: {
    ANAME?: string;
    AID?: number;
    USERID?: number;
  }[] = [];
  secUsers: {
    UNAME?: string;
    USERID?: number;
    AID?: number;
  }[] = [];
  secFunc: {
    SECURITYFUCTIONNAME?: string;
    SECURITYFUNCTIONID?: number;
    AID?: number;
  }[] = [];
  securityObj: {
    SECURITYGROUPNAME?: string;
    SECURITYGROUPACTIVE?: boolean;
  } = {};
  secTags: {
    CUSTOMFIELDOPTIONNAME?: string;
    CUSTOMFIELDOPTIONID?: number;
  }[] = [];
  secBusinessLine: {
    BUSINESSLINENAME?: string;
    ID?: number;
  }[] = [];

  details = {
    securityAccountsArr: this.secAccs,
    securityUsersArr: this.secUsers,
    securityFunctionsArr: this.secFunc,
    securityGroup: this.securityObj,
    ACCOUNTLIST: [],
    USERGROUPLIST: [],
    USERID: 0,
    ACCOUNTTOTAL: 0,
    TAGLIST: [],
    securityTagsArr: this.secTags,
    securityBlockedTagsArr: this.secTags,
    securityBusinessLineArr: this.secBusinessLine,
    securityBlockedBusinessLineArr: this.secBusinessLine,
    ALLOWTAGLIST: [{
      CUSTOMFIELDOPTIONID: 0,
      CUSTOMFIELDOPTIONNAME: ""
    }],
    BLOCKEDTAGLIST: [{
      CUSTOMFIELDOPTIONID: 0,
      CUSTOMFIELDOPTIONNAME: ""
    }],
    ALLOWBUSINESSLINES: [{
      ID: 0,
      BUSINESSLINENAME: ""
    }],
    BLOCKEDBUSINESSLINES: [{
      ID: 0,
      BUSINESSLINENAME: ""
    }],
    FUNCTIONGROUPCATEGORIES: [],
  };
  selectedTagIds: number[] = [];
  selectedBlockedTagIds: number[] = [];
  selectedBusinessLineIds: number[] = [];
  selectedBlockedBusinessLineIds: number[] = [];
  allowTagLOrgList: Object[] = [];
  blockedTagLOrgList: Object[] = [];
  selectedFunctionIds: (number | undefined)[] = [];
  groupTypes: any = [];
  selectedGroupType = 1;

  async created() {
    // this.gname = this.details.securityGroup.SECURITYGROUPNAME;
    var selfIns = this;
    var dataObj = {};
    dataObj["controller"] = "SecurityGroups";
    dataObj["FunctionName"] = "View";
    dataObj["Content"] = "Detailed";
    dataObj["groupID"] = `${this.$route.params.groupID}`;
    if (
      typeof this.$route.params.groupID != "undefined" &&
      parseInt(this.$route.params.groupID) != 0
    ) {
      this.loading = true;
      // return getRouteData(dataObj).then(function (response, statusText, jqXHR) {
      var response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      try {
        if (response.data.STATUS) {
          // selfIns.loading = false;
          // response.data.securityAccountsArr.forEach(element => {
          //     selfIns.selectedAccountIds.push(element.AID);
          // });
          // response.data.securityUsersArr.forEach(element => {
          //     selfIns.selectedUserIds.push(element.USERID);
          // });
          // from static.js
          selfIns.loading = false;
          // this.selectedAccountIds = [];
          // this.selectedUserIds = [];
          response.data.securityAccountsArr.forEach(function(element) {
            selfIns.selectedAccountIds.push(element.AID);
          });
          response.data.securityUsersArr.forEach(function(element) {
            selfIns.selectedUserIds.push(element.USERID);
          });
          response.data.securityTagsArr.forEach(function(element) {
            selfIns.selectedTagIds.push(element.CUSTOMFIELDOPTIONID);
          });
          response.data.securityBlockedTagsArr.forEach(function(element) {
            selfIns.selectedBlockedTagIds.push(element.CUSTOMFIELDOPTIONID);
          });
          response.data.securityBusinessLineArr.forEach(function(element) {
            selfIns.selectedBusinessLineIds.push(element.ID);
          });
          response.data.securityBlockedBusinessLineArr.forEach(function(element) {
            selfIns.selectedBlockedBusinessLineIds.push(element.ID);
          });
          if (
            response.data.ACCOUNTLIST != "" &&
            response.data.ACCOUNTLIST.length > 0
          ) {
            for (let i = 0; i < response.data.ACCOUNTLIST.length; i++) {
              if (response.data.ACCOUNTLIST[i].selected == true) {
                response.data.ACCOUNTLIST[i].class = "highlight";
                response.data.ACCOUNTLIST[i].disabled = true;
              } else {
                response.data.ACCOUNTLIST[i].class = "";
                response.data.ACCOUNTLIST[i].disabled = false;
              }
            }
          }
          if (
            response.data.USERGROUPLIST != "" &&
            response.data.USERGROUPLIST.length > 0
          ) {
            for (let i = 0; i < response.data.USERGROUPLIST.length; i++) {
              if (response.data.USERGROUPLIST[i].selected == true) {
                response.data.USERGROUPLIST[i].class = "highlight";
                response.data.USERGROUPLIST[i].disabled = true;
              } else {
                response.data.USERGROUPLIST[i].class = "";
                response.data.USERGROUPLIST[i].disabled = false;
              }
            }
          }
          if (
            response.data.FUNCTIONGROUPCATEGORIES != "" &&
            response.data.FUNCTIONGROUPCATEGORIES.length > 0
          ) {
            for (
              let i = 0;
              i < response.data.FUNCTIONGROUPCATEGORIES.length;
              i++
            ) {
              if (response.data.FUNCTIONGROUPCATEGORIES[i].selected == true) {
                response.data.FUNCTIONGROUPCATEGORIES[i].class = "highlight";
                response.data.FUNCTIONGROUPCATEGORIES[i].disabled = true;
              } else {
                response.data.FUNCTIONGROUPCATEGORIES[i].class = "";
                response.data.FUNCTIONGROUPCATEGORIES[i].disabled = false;
              }
            }
          }
          if (
            response.data.FUNCTIONGROUPLIST != "" &&
            response.data.FUNCTIONGROUPLIST.length > 0
          ) {
            for (let i = 0; i < response.data.FUNCTIONGROUPLIST.length; i++) {
              if (response.data.FUNCTIONGROUPLIST[i].selected == true) {
                response.data.FUNCTIONGROUPLIST[i].class = "highlight";
                response.data.FUNCTIONGROUPLIST[i].disabled = true;
              } else {
                response.data.FUNCTIONGROUPLIST[i].class = "";
                response.data.FUNCTIONGROUPLIST[i].disabled = false;
              }
            }
          }
          if (
            response.data.TAGLIST != "" &&
            response.data.TAGLIST.length > 0
          ) {
            for (let i = 0; i < response.data.TAGLIST.length; i++) {
              if (response.data.TAGLIST[i].selected == true) {
                response.data.TAGLIST[i].class = "highlight";
                response.data.TAGLIST[i].disabled = true;
              } else {
                response.data.TAGLIST[i].class = "";
                response.data.TAGLIST[i].disabled = false;
              }
            }
          }

          const resData = response.data || {};
          const FUNCTIONGROUPLIST = response.data.FUNCTIONGROUPLIST || [];
          const FUNCTIONGROUPCATEGORIES = (resData.FUNCTIONGROUPCATEGORIES || []).map((group) => {

            group.OPTIONS = FUNCTIONGROUPLIST.filter((item) => {
              return item.TEMPLATEGROUP == group.TEMPLATEGROUP;
            }) || [];
            group.OPTIONS.map((item) => {
              item.checked = item.selected || false;
              return item;
            });
            group.checked = group.OPTIONS.find((item) => !item.checked) ? false : true;
            return group;
          });
          this.groupTypes = resData.GROUPTYPEARRAY;
          this.selectedGroupType = resData.securityGroup.GROUPTYPE
          this.details = {
            ...resData,
            FUNCTIONGROUPCATEGORIES: FUNCTIONGROUPCATEGORIES
          };
          this.toggleSelectedFunctionIds();
          // selfIns.details = response.data;
          selfIns.titleGroupName =
            response.data.securityGroup.SECURITYGROUPNAME;
          selfIns.disableGroup = response.data.securityGroup && response.data.securityGroup.SYSTEM_GROUP == 1 ? true : false;
          this.allowTagLOrgList = response.data.ALLOWTAGLIST;
          this.blockedTagLOrgList = response.data.BLOCKEDTAGLIST;
        }
      } catch (e) {
        //handle error
      }
      selfIns.loading = false;
      // });
    } else {
      // this.details = [];
    }
  }
  securityBackButton(){
    this.$router.push({ name: "SecurityGroups" });
  }
  validateFn;
  updateAccountList(type, index, aid) {
    if (this.details.securityAccountsArr && type == "delete") {
      this.$delete(this.details.securityAccountsArr, index);
      this.$delete(this.selectedAccountIds, index);
      $(".addAccount option[val=" + aid + "]").attr("disabled", false);
      $(".addAccount option[val=" + aid + "]").attr("class", "removelight");
    }
  }
  updateUserList(type, index, employid) {
    if (
      this.details.securityUsersArr != undefined &&
      this.details.securityUsersArr &&
      type == "delete"
    ) {
      this.$delete(this.details.securityUsersArr, index);
      this.$delete(this.selectedUserIds, index);
      $(".addUser option[val=" + employid + "]").attr("disabled", false);
      $(".addUser option[val=" + employid + "]").attr("class", "removelight");
    }
  }
  updateTagList(type, index, employid) {
    if (
      this.details.securityTagsArr != undefined &&
      this.details.securityTagsArr &&
      type == "delete"
    ) {
      this.$delete(this.details.securityTagsArr, index);
      this.$delete(this.selectedTagIds, index);
      const removedTag = this.details.ALLOWTAGLIST.filter(tag => tag.CUSTOMFIELDOPTIONID == employid);
      this.details.BLOCKEDTAGLIST.push(removedTag[0]);
      //Sort by default allow tag list
      this.sortByTags(this.details.BLOCKEDTAGLIST);
      $(".addUser option[val=" + employid + "]").attr("disabled", false);
      $(".addUser option[val=" + employid + "]").attr("class", "removelight");
    }
  }
  updateBlockedTagList(type, index, employid) {
    if (
      this.details.securityBlockedTagsArr != undefined &&
      this.details.securityBlockedTagsArr &&
      type == "delete"
    ) {
      this.$delete(this.details.securityBlockedTagsArr, index);
      this.$delete(this.selectedBlockedTagIds, index);
      const removedTag = this.details.BLOCKEDTAGLIST.filter(tag => tag.CUSTOMFIELDOPTIONID == employid);
      this.details.ALLOWTAGLIST.push(removedTag[0]);
      //Sort by default allow tag list
      this.sortByTags(this.details.ALLOWTAGLIST);
      $(".addUser option[val=" + employid + "]").attr("disabled", false);
      $(".addUser option[val=" + employid + "]").attr("class", "removelight");
    }
  }
  updateBusinessLineList(type, index, businessLineId) {
    if (
      this.details.securityBusinessLineArr != undefined &&
      this.details.securityBusinessLineArr &&
      type == "delete"
    ) {
      this.$delete(this.details.securityBusinessLineArr, index);
      this.$delete(this.selectedBusinessLineIds, index);
      const removedBusinessLine = this.details.ALLOWBUSINESSLINES.filter(businessLine => businessLine.ID == businessLineId);
      this.details.BLOCKEDBUSINESSLINES.push(removedBusinessLine[0]);
    }
  }
  updateBlockedBusinessLine(type, index, businessLineId) {
    if (
      this.details.securityBlockedBusinessLineArr != undefined &&
      this.details.securityBlockedBusinessLineArr &&
      type == "delete"
    ) {
      this.$delete(this.details.securityBlockedBusinessLineArr, index);
      this.$delete(this.selectedBlockedBusinessLineIds, index);
      const removedBusinessLine = this.details.BLOCKEDBUSINESSLINES.filter(businessLine => businessLine.ID == businessLineId);
      this.details.ALLOWBUSINESSLINES.push(removedBusinessLine[0]);
    }
  }
  updateFunctionList(type, index, funcid) {
    if (this.details.securityFunctionsArr && type == "delete") {
      this.$delete(this.details.securityFunctionsArr, index);
      $(".addFunc option[val=" + funcid + "]").attr("disabled", false);
      $(".addFunc option[val=" + funcid + "]").removeClass("highlight");
      $(
        ".optionGroup#" + $(".addFunc option[val=" + funcid + "]").attr("id")
      ).attr("disabled", false);
    }
  }
  AddGroupName(groupName) {
    this.details.securityGroup.SECURITYGROUPNAME = groupName.replace(
      /  +/g,
      " "
    );
    // this.gname = this.details.securityGroup.SECURITYGROUPNAME;
  }
  onChangeAccount() {
    var included = 0;
    for (var i = 0; i < this.details.securityAccountsArr.length; i++) {
      if (
        this.details.securityAccountsArr[i].AID ==
        $(".addAccount option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.details.securityAccountsArr.push({
        ANAME: $(".addAccount option:selected span").attr("data-name"),
        AID: $(".addAccount option:selected span").attr("data-id")
      });
    }
    // $(".addAccount option:selected").remove();
    // $(".addAccount option:first-child").prop('selected', true);
    const numb = $(".addAccount option:selected span").attr("data-id");
    $(".addAccount option[val=" + numb + "]").attr("disabled", true);
    $(".addAccount option[val=" + numb + "]").attr("class", "highlight");
    $(".addAccount option:first-child").prop("selected", true);
  }
  onChangeUser() {
    var included = 0;
    for (var i = 0; i < this.details.securityUsersArr.length; i++) {
      if (
        this.details.securityUsersArr[i].AID ==
        $(".addUser option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.details.securityUsersArr.push({
        UNAME: $(".addUser option:selected span").attr("data-name"),
        USERID: $(".addUser option:selected span").attr("data-id")
      });
    }
    const numb = $(".addUser option:selected span").attr("data-id");
    $(".addUser option[val=" + numb + "]").attr("disabled", true);
    $(".addUser option[val=" + numb + "]").attr("class", "highlight");
    $(".addUser option:first-child").prop("selected", true);
  }
  onChangeFunc() {
    const functionOptions: {
      SECURITYFUCTIONNAME?: string;
      SECURITYFUNCTIONID?: number;
      AID?: number;
    }[] = [];
    for (i = 0; i < this.details.securityFunctionsArr.length; i++) {
      functionOptions.push({
        SECURITYFUCTIONNAME: this.details.securityFunctionsArr[i]
          .SECURITYFUCTIONNAME,
        SECURITYFUNCTIONID: this.details.securityFunctionsArr[i]
          .SECURITYFUNCTIONID
      });
    }
    const numb = $(".addFunc option:selected span").attr("data-id");
    const numbAll = $(".addFunc option:selected").attr("val");
    var included = 0;
    var securityId: number[] = [];
    functionOptions.forEach(element => {
      if (element.SECURITYFUNCTIONID)
        securityId.push(element.SECURITYFUNCTIONID);
    });
    if ($(".addFunc option:selected").hasClass("optionGroup")) {
      $("option span[data-group=" + numb + "]").each(function(
        this: Window,
        index,
        el
      ) {
        if (securityId.indexOf($(this).attr("data-id")) == -1) {
          const result = functionOptions.filter(
            option => option.SECURITYFUNCTIONID == $(this).attr("data-id")
          );

          if (!result.length) {
            functionOptions.push({
              SECURITYFUCTIONNAME: $(this).attr("data-name") || "",
              SECURITYFUNCTIONID: $(this).attr("data-id") || ""
            });
          }
        }
        $(this)
          .parent()
          .attr("disabled", true)
          .addClass("highlight");
      });
      this.details.securityFunctionsArr = functionOptions;
      $(".addFunc option:first-child").prop("selected", true);
      $(".addFunc option[val=" + numbAll + "]").attr("disabled", true);
    } else {
      for (var i = 0; i < this.details.securityFunctionsArr.length; i++) {
        if (
          this.details.securityFunctionsArr[i].AID ==
          $(".addFunc option:selected span").attr("data-id")
        ) {
          included = 1;
        }
      }

      if (included != 1) {
        this.details.securityFunctionsArr.push({
          SECURITYFUCTIONNAME: $(".addFunc option:selected span").attr(
            "data-name"
          ),
          SECURITYFUNCTIONID: $(".addFunc option:selected span").attr("data-id")
        });
      }
      const numb = $(".addFunc option:selected span").attr("data-id");
      $(".addFunc option[val=" + numb + "]").attr("disabled", true);
      $(".addFunc option[val=" + numb + "]").attr("class", "highlight");
      $(".addFunc option:first-child").prop("selected", true);
      var catId = $(".addFunc option[val=" + numb + "]").attr("id");
      if (
        $("#" + catId + ".optionChild").length ==
        $("#" + catId + ".optionChild.highlight").length
      ) {
        $("#" + catId + ".optionGroup").attr("disabled", true);
      } else {
        $("#" + catId + ".optionGroup").attr("disabled", false);
      }
    }
  }
  validateBeforeSubmit(e) {
    this.$validator.validateAll().then(async result => {
      //make sure an account is selected
      if (this["aID"] == "" && this["customer"] != "") {
        $("#customer")
          .parent()
          .find(".errorMsg")
          .html("Please select a customer from suggested list.");
        result = false;
      }

      //validate data input
      if (
        parseFloat($("#cost_productName_1").val()) < 1 ||
        parseFloat($("#cust_cost_productName_1").val()) < 1 ||
        parseInt($("#quantity_productName_1").val()) < 1 ||
        $("#shipping_productName_1").val() == "" ||
        $("#tax_rate_productName_1").val() == "" ||
        $("#tax_productName_1").val() == ""
      ) {
        $("#errorMsgLineQuote").html(
          "Let's enter enough info about SKU, Product, Cost and Quality, ..."
        );
        result = false;
      }

      if (result) {
        e.preventDefault();
        this.updating = true;
        var currentUser = JSON.parse(
          sessionStorage.getItem("currentUser") || ""
        );
        var accountID = currentUser[0]["ACCOUNTID"];

        var dataObj = {
          controller: "SecurityGroups",
          FunctionName: "Edit",
          accountID: accountID,
          groupID: parseInt(this.$route.params.groupID),
          gName: $("#gname").val(),
          gAccounts: this.details.securityAccountsArr,
          gUsers: this.details.securityUsersArr,
          gFunctions: this.details.securityFunctionsArr,
          gTags: this.details.securityTagsArr,
          gBlockedTags: this.details.securityBlockedTagsArr,
          gAllowedBusinessLine: this.details.securityBusinessLineArr,
          gBlockedBusinessLine: this.details.securityBlockedBusinessLineArr,
          selectedGroupTypeId: this.selectedGroupType
        };
        const updateData = getRouteData(dataObj);
        await updateData.then((response, statusText, jqXHR) => {
          if (response.data.STATUS == 1) {
            this.$router.push({ name: "SecurityGroups" });
            this.updating = false;
          } else if (response.data.ERROR) {
            this.updating = "error";
            console.log(response.data.ERROR);
          } else if (response.data.STATUS == 0) {
            this.updating = "error";
            notifier.alert(response.data.MESSAGE);
          }
        });
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  toggleSelectedAccIds(userId: number, accountName) {
    const index = this.selectedAccountIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedAccountIds.push(userId);
      this.details.securityAccountsArr.push({
        USERID: this.details.USERID,
        ANAME: accountName,
        AID: userId
      });
      this.details.ACCOUNTTOTAL = this.details.securityAccountsArr.length;
      return;
    }

    this.selectedAccountIds.splice(index, 1);
    this.$delete(this.details.securityAccountsArr, index);
  }
  selectAllAccs() {
    this.resetAccs();
    var thisUserID = this.details.USERID;
    var $this = this;
    // this.selectedAccountIds = this.details.ACCOUNTLIST.map(u => u.AID);

    $.each(this.details.ACCOUNTLIST, function(i, val) {
      $this.selectedAccountIds.push(val.AID);
      //  = this.details.ACCOUNTLIST.map(u => u.AID);
      $this.details.securityAccountsArr.push({
        USERID: thisUserID,
        ANAME: val.ANAME,
        AID: val.AID
      });
    });
  }
  resetAccs() {
    this.selectedAccountIds = [];
    this.details.securityAccountsArr = [];
  }
  // user multi Select
  toggleSelectedUserId(userId, name) {
    const index = this.selectedUserIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedUserIds.push(userId);
      this.details.securityUsersArr.push({
        USERID: userId,
        UNAME: name
      });
      return;
    }
    this.selectedUserIds.splice(index, 1);
  }
  // tag multi Select
  toggleSelectedTagId(tagId, name, selectedTag) {
    const index = this.selectedTagIds.findIndex(id => id === tagId);
    const blockedTagIndex = this.details.BLOCKEDTAGLIST.findIndex((tag: any) => tag.CUSTOMFIELDOPTIONID === tagId);
    if (index === -1) {
      this.selectedTagIds.push(tagId);
      this.details.securityTagsArr.push({
        CUSTOMFIELDOPTIONID: tagId,
        CUSTOMFIELDOPTIONNAME: name
      });
      if (blockedTagIndex > -1) {
        this.details.BLOCKEDTAGLIST.splice(blockedTagIndex, 1);
      }
      return;
    }
    this.selectedTagIds.splice(index, 1);
    this.details.securityTagsArr.splice(index, 1);
    this.details.BLOCKEDTAGLIST.push(selectedTag);
    //Sort by default blocked tag list
    this.sortByTags(this.details.BLOCKEDTAGLIST);
  }
  toggleSelectedBlockedTagId(tagId, name, selectedTag) {
    const index = this.selectedBlockedTagIds.findIndex(id => id === tagId);
    const allowTagIndex = this.details.ALLOWTAGLIST.findIndex((tag: any) => tag.CUSTOMFIELDOPTIONID === tagId);
    if (index === -1) {
      this.selectedBlockedTagIds.push(tagId);
      this.details.securityBlockedTagsArr.push({
        CUSTOMFIELDOPTIONID: tagId,
        CUSTOMFIELDOPTIONNAME: name
      });
      if (allowTagIndex > -1) {
        this.details.ALLOWTAGLIST.splice(allowTagIndex, 1);
      }
      return;
    }
    this.selectedBlockedTagIds.splice(index, 1);
    this.details.securityBlockedTagsArr.splice(index, 1);
    this.details.ALLOWTAGLIST.push(selectedTag);
    //Sort by default allow tag list
    this.sortByTags(this.details.ALLOWTAGLIST);
  }
  // BusinessLine multi Select
  toggleSelectedBusinessLineId(businessLineId, name, selectedBusinessLine) {
    const index = this.selectedBusinessLineIds.findIndex(id => id === businessLineId);
    const blockedBusinessLineIndex = this.details.BLOCKEDBUSINESSLINES.findIndex((businessLine: any) => businessLine.ID === businessLineId);
    if (index === -1) {
      this.selectedBusinessLineIds.push(businessLineId);
      this.details.securityBusinessLineArr.push({
        ID: businessLineId,
        BUSINESSLINENAME: name
      });
      if (blockedBusinessLineIndex > -1) {
        this.details.BLOCKEDBUSINESSLINES.splice(blockedBusinessLineIndex, 1);
      }
      return;
    }
    this.selectedBusinessLineIds.splice(index, 1);
    this.details.securityBusinessLineArr.splice(index, 1);
    this.details.BLOCKEDBUSINESSLINES.push(selectedBusinessLine);
  }
  toggleSelectedBlockedBusinessLineId(businessLineId, name, selectedBusinessLine) {
    const index = this.selectedBlockedBusinessLineIds.findIndex(id => id === businessLineId);
    const allowBusinessLineIndex = this.details.ALLOWBUSINESSLINES.findIndex((businessLine: any) => businessLine.ID === businessLineId);
    if (index === -1) {
      this.selectedBlockedBusinessLineIds.push(businessLineId);
      this.details.securityBlockedBusinessLineArr.push({
        ID: businessLineId,
        BUSINESSLINENAME: name
      });
      if (allowBusinessLineIndex > -1) {
        this.details.ALLOWBUSINESSLINES.splice(allowBusinessLineIndex, 1);
      }
      return;
    }
    this.selectedBlockedBusinessLineIds.splice(index, 1);
    this.details.securityBlockedBusinessLineArr.splice(index, 1);
    this.details.ALLOWBUSINESSLINES.push(selectedBusinessLine);
  }
  selectAllUsers() {
    this.resetUsers();
    var $this = this;
    // this.selectedUserIds = this.details.USERGROUPLIST.map((u) => u.USERID)
    $.each(this.details.USERGROUPLIST, function(i, val) {
      $this.selectedUserIds.push(val.USERID);
      // = this.details.USERGROUPLIST.map((u) => u.USERID)
      $this.details.securityUsersArr.push({
        USERID: val.USERID,
        UNAME: `${val.UFNAME} ${val.ULNAME}`
      });
    });
  }
  resetUsers() {
    this.selectedUserIds = [];
    this.details.securityUsersArr = [];
  }
  selectAllTags() {
    this.resetTags();
    var $this = this;
    $.each(this.details.ALLOWTAGLIST, function(i, val) {
      $this.selectedTagIds.push(val.CUSTOMFIELDOPTIONID);
      $this.details.securityTagsArr.push({
        CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
        CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
      });
    });
    for(let i=0; i < this.details.BLOCKEDTAGLIST.length; i++) {
      if(this.selectedTagIds.includes(this.details.BLOCKEDTAGLIST[i].CUSTOMFIELDOPTIONID)) {
        this.$delete(this.details.BLOCKEDTAGLIST, i);
        i--;
      }
    }
  }
  resetTags() {
    var $this = this;
    $.each(this.details.ALLOWTAGLIST, function(index, value) {
      if($this.selectedTagIds.includes(value.CUSTOMFIELDOPTIONID)) {
        $this.details.BLOCKEDTAGLIST.push(value);
      }
    });
    this.selectedTagIds = [];
    this.details.securityTagsArr = [];
    //Sort by default blocked tag list
    this.sortByTags(this.details.BLOCKEDTAGLIST);
  }
  selectAllBlockedTags() {
    this.resetBlockedTags();
    var $this = this;
    $.each(this.details.BLOCKEDTAGLIST, function(i, val) {
      $this.selectedBlockedTagIds.push(val.CUSTOMFIELDOPTIONID);
      $this.details.securityBlockedTagsArr.push({
        CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
        CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
      });
    });
    for(let i=0; i < this.details.ALLOWTAGLIST.length; i++) {
      if(this.selectedBlockedTagIds.includes(this.details.ALLOWTAGLIST[i].CUSTOMFIELDOPTIONID)) {
        this.$delete(this.details.ALLOWTAGLIST, i);
        i--;
      }
    }
  }
  resetBlockedTags() {
    var $this = this;
    $.each(this.details.BLOCKEDTAGLIST, function(index, value) {
      if($this.selectedBlockedTagIds.includes(value.CUSTOMFIELDOPTIONID)) {
        $this.details.ALLOWTAGLIST.push(value);
      }
    });
    this.selectedBlockedTagIds = [];
    this.details.securityBlockedTagsArr = [];
    //Sort by default allow tag list
    this.sortByTags(this.details.ALLOWTAGLIST);
  }
  sortByTags(tagList) {
    tagList.sort(function (t1, t2) {
      return t1.CUSTOMFIELDOPTIONORDER - t2.CUSTOMFIELDOPTIONORDER || t1.CUSTOMFIELDOPTIONNAME.localeCompare(t2.CUSTOMFIELDOPTIONNAME);
    });
  }
  selectAllBusinessLine() {
    this.resetBusinessLine();
    var $this = this;
    this.details.ALLOWBUSINESSLINES.forEach(businessLine => {
      $this.selectedBusinessLineIds.push(businessLine.ID);
      $this.details.securityBusinessLineArr.push({
        ID: businessLine.ID,
        BUSINESSLINENAME: businessLine.BUSINESSLINENAME
      });
    });
    for(let i=0; i < this.details.BLOCKEDBUSINESSLINES.length; i++) {
      if(this.selectedBusinessLineIds.includes(this.details.BLOCKEDBUSINESSLINES[i].ID)) {
        this.$delete(this.details.BLOCKEDBUSINESSLINES, i);
        i--;
      }
    }
  }
  resetBusinessLine() {
    var $this = this;
    this.details.ALLOWBUSINESSLINES.forEach(businessLine => {
      if($this.selectedBusinessLineIds.includes(businessLine.ID)) {
        $this.details.BLOCKEDBUSINESSLINES.push(businessLine);
      }
    });
    this.selectedBusinessLineIds = [];
    this.details.securityBusinessLineArr = [];
  }
  selectAllBlockedBusinessLine() {
    this.resetBlockedBusinessLine();
    var $this = this;
    this.details.BLOCKEDBUSINESSLINES.forEach(businessLine => {
      $this.selectedBlockedBusinessLineIds.push(businessLine.ID);
      $this.details.securityBlockedBusinessLineArr.push({
        ID: businessLine.ID,
        BUSINESSLINENAME: businessLine.BUSINESSLINENAME
      });
    });
    for(let i=0; i < this.details.ALLOWBUSINESSLINES.length; i++) {
      if(this.selectedBlockedBusinessLineIds.includes(this.details.ALLOWBUSINESSLINES[i].ID)) {
        this.$delete(this.details.ALLOWBUSINESSLINES, i);
        i--;
      }
    }
  }
  resetBlockedBusinessLine() {
    var $this = this;
    this.details.BLOCKEDBUSINESSLINES.forEach(businessLine => {
      if($this.selectedBlockedBusinessLineIds.includes(businessLine.ID)) {
        $this.details.ALLOWBUSINESSLINES.push(businessLine);
      }
    });
    this.selectedBlockedBusinessLineIds = [];
    this.details.securityBlockedBusinessLineArr = [];
  }
  async removeSecurityGroup() {
    this.deleting = true;
    var currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "");
    var accountID = currentUser[0]["ACCOUNTID"];

    var dataObj = {
      controller: "SecurityGroups",
      FunctionName: "Delete",
      accountID: accountID,
      groupID: parseInt(this.$route.params.groupID)
    };
    const deleteData = getRouteData(dataObj);
    await deleteData.then((response, statusText, jqXHR) => {
      if (response.data.STATUS == 1) {
        this.deleting = false;
        this.$router.push({ name: "SecurityGroups" });
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
        this.deleting = "error";
      } else if (response.data.STATUS == 0) {
        notifier.alert(response.data.MESSAGE);
        this.deleting = "error";
      } else {
        this.deleting = "error";
        console.log(response.data);
      }
    });
  }
  async updateStatus(id) {
    var currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "");
    var accountID = currentUser[0]["ACCOUNTID"];

    var dataObj = {
      controller: "SecurityGroups",
      FunctionName: "Edit",
      accountID: accountID,
      groupID: parseInt(this.$route.params.groupID),
      StatusID: id
    };
    const updateData = getRouteData(dataObj);
    await updateData.then((response, statusText, jqXHR) => {
      if (response.data.STATUS == 1) {
        notifier.success(response.data.MESSAGE);
        this.details.securityGroup.SECURITYGROUPACTIVE = id;
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
      } else if (response.data.STATUS == 0) {
        notifier.alert(response.data.MESSAGE);
      }
    });
  }

  // FUNCTIONS
  updateSelectedFunctions() {
    this.details.securityFunctionsArr = [];
    this.details.FUNCTIONGROUPCATEGORIES.map((cat: any) => {
      if(cat.checked) {
        // this.securityFunctionsArr.push({
        //   SECURITYFUCTIONNAME: cat.SECURITYFUNCTIONCATEGORY,
        //   SECURITYFUNCTIONID: cat.TEMPLATEGROUP
        // });
      }
      cat.OPTIONS.map((item: any) => {
        if(item.checked) {
          this.details.securityFunctionsArr.push({
            SECURITYFUCTIONNAME: item.SECURITYFUCTIONNAME,
            SECURITYFUNCTIONID: item.SECURITYFUNCTIONID
          });
        }
      });
      cat.checked = cat.OPTIONS.find((item) => !item.checked) ? false : true;
    });
  }
  toggleSelectedFunctionIds(funcId = 0, funcName = '') {
    const selectedIds: any[] = [];
    this.details.FUNCTIONGROUPCATEGORIES.map((cat: any) => {
      if(cat.checked) {
        selectedIds.push(cat.TEMPLATEGROUP);
      }
      cat.OPTIONS.map((item: any) => {
        if(item.checked) {
          selectedIds.push(item.SECURITYFUNCTIONID);
        }
      });
    });
    this.selectedFunctionIds = selectedIds;
    this.updateSelectedFunctions();
  }
  selectAllFunctions() {
    const selectedIds: any[] = [];
    this.details.FUNCTIONGROUPCATEGORIES.map((cat: any) => {
      cat.checked = true;
      selectedIds.push(cat.TEMPLATEGROUP);
      cat.OPTIONS.map((item: any) => {
        item.checked = true;
        selectedIds.push(item.SECURITYFUNCTIONID);
      });
    });
    this.selectedFunctionIds = selectedIds;
    this.updateSelectedFunctions();
  }
  resetFunctions() {
    this.selectedFunctionIds = [];
    this.details.FUNCTIONGROUPCATEGORIES.map((cat: any) => {
      cat.checked = false;
      cat.OPTIONS.map((item: any) => {
        item.checked = false;
      });
    });
    this.updateSelectedFunctions();
  }
}
