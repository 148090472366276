






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { OpportunityAPIRepo } from "../repos/OpportunityAPIRepo";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import Datepicker from '../components/Datepicker.vue'
import draggable from "vuedraggable";
import OpportunityTagSelect from "../components/OpportunityTagSelect.vue";
import GlobalTagSelect from "@/components/GlobalTagSelect.vue";
import directives from "../helpers/directives";
import NotesModal from "../components/OppNotesCreate.vue";
import moment from "moment";
import PageTitle from '../components/pageTitle.vue';
import { ApiHelper } from "@/helpers/all";
import UserAutoSuggestInput from "../components/UserSuggestInput.vue";
import { getInitials, priceTypeStr } from "@/helpers/ApiHelper";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import LogInfoModal from "@/components/LogInfoModal.vue";
import poIDQuoteIDAutoSuggestInput from "@/components/poIDQuoteIDAutoSuggestInput.vue";
import ModalEditOpportunityOrdersAndQuotes from "@/components/Opportunity/ModalEditOpportunityOrdersAndQuotes.vue";

declare const $: any;
declare const dataURL: string;
declare const Stripe: any;
declare function require(params: any): any;
declare const htmlCheck: Function;
declare const htmlParse: Function;
declare const getRouteData: Function;

const Handlebars = require("handlebars/runtime.js");
const opportunityRepo = new OpportunityAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    ModalEditOpportunityOrdersAndQuotes,
    NotesModal,
    draggable,
    OpportunityTagSelect,
    Datepicker,
    PageTitle,
    UserAutoSuggestInput,
    ConfirmRemoveItemModal,
    LogInfoModal,
    GlobalTagSelect,
    poIDQuoteIDAutoSuggestInput
  },
  directives,
  methods: {
    getInitials,
    priceTypeStr
  }
})
export default class OpportunityDetails extends TSXComponent<void> {
  notesModalVisible = false;
  ordersModalVisible = false;
  quotesModalVisible = false;
  $root: any;
  $route: any;
  loading = false;
  currentRole = sessionStorage.getItem("userRole");
  customerQuotetemplate: number | undefined = 0;
  bigDealId: string | undefined = "";
  items: any = [];
  details: any = {
    ACCOUNT_NAME: "",
    DESCRIPTION: ""
  };
  notes = "";
  oppID = 0;
  onExport = {
    templateid: 0,
    saveAsDefault: 0,
    showOnExport: 0,
    pricingid: "",
    subTempName: "",
    advanced: {},
    customMessages: {}
  };
  confirmRemoveModalVisible = false;
  opportunityTags: any[] = [];
  stripeObj: any = {};
  stripeToken = "";
  stripeLast4 = "";
  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  cvc = "";
  cardpo = "";
  stripeError = 0;
  cardUpdated = 0;
  poPay = 0;
  creditCardSec = 1;
  poNumberSec = 0;
  poNumberError = 0;
  PaymentText = "";
  useCCPayment = false;
  tags = "";
  selectedOpportunityTagsID: number[] = [];
  selectedOpportunityTags: object[] = [];
  isActive: string = "oppNotes";
  logs: any = [];
  files: any = [];
  modalFileData = {
    file: undefined,
    active: false,
    uploading: false
  };
  confirmFileRemove: boolean = false;
  deleteFileloading: boolean = false;
  removeFileId: number = 0;
  removeFiletype: number = 0;
  filetypes = {
    1: "Purchase Order",
    2: "Web Confirmation",
    3: "Order Confirmation",
    4: "Shipment Confirmation",
    5: "Other",
    6: "Order PO",
    7: "Invoice PDF",
    8: "HP status file"
  };
  confirmOrderQuoteRemove: boolean = false;
  removeOrderQuoteId: number = 0;
  orderQuoteTypeAsText: string = "";
  removeItemType: number = 0;
  logModalVisible = -1;
  orderQuoteIDs: string = "";
  selectedOrderAndQuoteIDs: any = [];
  showAddOrderAndQuote: boolean = false;

  get calculateEmptyCol() {
    let col = 2;
    if (this.details.FOLLOW_UP) {
      col = col - 1;
    }
    if (this.details.EST_PAYMENT_DATE_FORMATTED) {
      col = col - 1;
    }

    return col;
  }

  async created() {
    this.oppID = parseInt(this.$route.params.id);

    if (this.$route.query.previewhtml) {
      const html = await this.opportunityDetailsHtml();
      if (!html) return;

      // replace entire page html
      document.open();
      document.write(html);
      document.close();
    }
    // if (this.currentRole != "Reseller") {
    //   this.stripeLoad();
    // }
    await this.getOpportunities();
  }
  listPageRedirection() {
    this.$router.push({ name: "Opportunities" });
  }
  async getOpportunities() {
    this.loading = true;
    var apiObj = {
      controller: "Opportunities",
      Content: "Detailed",
      ObjID: this.oppID,
      FunctionName: "View"
    };
    var self = this;
    const response: any = await opportunityRepo.findOneDetails(this.$route.params.id);
    self.details = response.OPPORTUNITYDETAILS[0];
    self.details = {
      ...self.details,
      SALES_STAGE: self.details.SALES_STAGE + self.getStatusPercentByName(self.details.SALES_STAGE)
    };
    self.notes = response.OPPORTUNITYNOTES;
    self.selectedOpportunityTagsID = response.OPPORTUNITYDETAILS[0].OPPORTUNITYTAGS;
    self.opportunityTags = response.globalTags.filter(item => item.FOROPPORTUNITIES);
    self.files = response.OPPORTUNITYFILES || [];
    self.logs = response.OPPORTUNITYLOGS || [];
    this.selectedOrderAndQuoteIDs = self.details.OPPLINKORDERANDQUOTE || [];
    
    var selectedOpportunityTagsData: number[] = [];
    $.each(self.selectedOpportunityTagsID, function(i, val) {
      selectedOpportunityTagsData.push(parseInt(val))
    });
    self.selectedOpportunityTagsID = selectedOpportunityTagsData;
    self.opportunityTags.forEach(data => {
      selectedOpportunityTagsData.map(e => {
        if (e === data.CUSTOMFIELDOPTIONID) {
          self.selectedOpportunityTags.push({
            selectedID: parseInt(data.CUSTOMFIELDOPTIONID),
            CUSTOMFIELDOPTIONNAME: data.CUSTOMFIELDOPTIONNAME
          })
        }
      })
    });
    self.loading = false;
  }
  async opportunityDetailsHtml(): Promise<string | false> {
    try {
      const opportunityDetails: any = await opportunityRepo.findOneDetails(
        this.$route.params.id,
        this.onExport
      );

      if (opportunityDetails.TemplateSetting.TemplateName != "") {
        const mainTemplate = opportunityDetails.TemplateSetting.TemplateName.split(
          "/"
        );
        var template = require("../templates/quotes/" +
          mainTemplate[mainTemplate.length - 1]);
        const html = template(opportunityDetails);
        return html;
      } else {
        return "";
      }
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async toggleSelectedOpportunityTags(selectedID: number, tagName) {
    const index = this.selectedOpportunityTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedOpportunityTagsID.push(selectedID);
      this.selectedOpportunityTags.push({
        selectedID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
    }else{
      this.selectedOpportunityTagsID.splice(index, 1);
      this.$delete(this.selectedOpportunityTags, index);
    }
    await opportunityRepo.saveAndRemoveTags(this.selectedOpportunityTagsID,parseInt(this.$route.params.id));
  }

  async selectAllOpportunityTags() {
    // this.resetOpportunityTags();
    this.selectedOpportunityTagsID = this.opportunityTags.map((val: any) => val.CUSTOMFIELDOPTIONID);
    this.selectedOpportunityTags = this.opportunityTags.map((val: any) => ({
      selectedID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    await opportunityRepo.saveAndRemoveTags(this.selectedOpportunityTagsID,parseInt(this.$route.params.id));
  }

  async resetOpportunityTags() {
    this.selectedOpportunityTags = [];
    this.selectedOpportunityTagsID = [];
    await opportunityRepo.saveAndRemoveTags(this.selectedOpportunityTagsID,parseInt(this.$route.params.id));
  }

  async updateOpportunityTagList(index,tag,id,oppID) {
    var tagList = tag;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Opportunities",
      FunctionName: "UpdateOpportunity",
      oppID: oppID,
      oppTagID: id,
      field: "tags",
      fieldVal: tagList
    });

    if (response.data.STATUS == 1) {
      this.$delete(this.selectedOpportunityTagsID, index);
      this.$delete(this.selectedOpportunityTags, index);
    }
  }

  showFieldUpdate(field) {
    $("#"+field+"_COPY").addClass("displayNone");
    $("#"+field).removeClass("displayNone").focus();
  }

  saveField(field) {
    var $this = this;
    var fieldVal = $("#"+field).val();
    var originalCopy = $this.details[`${field}`];

    if (fieldVal != "") {
      if (htmlCheck(fieldVal)) {
        fieldVal = htmlParse(fieldVal);
      }
    }

    if (fieldVal != "" && fieldVal != originalCopy) {
      console.log("saveField");
        getRouteData({
          controller: "Opportunities",
          FunctionName: "UpdateOpportunity",
          oppID: $this.$route.params.id,
          field: field,
          fieldVal: fieldVal,
        }).then(function(response, statusText, jqXHR) {
          $this.details[`${field}`] = fieldVal;
          $("#"+field+"_COPY").removeClass("displayNone").html(fieldVal);
          $("#"+field).addClass("displayNone").val(fieldVal);
        });
    } 
    else {
      $("#"+field+"_COPY").removeClass("displayNone").html(originalCopy);
      $("#"+field).addClass("displayNone").val(originalCopy);
    }
  }

  updateDatepicker(data, field) {
    if (moment(data, "MM/DD/YYYY", true).isValid()) {
      $("#"+field).val(data);
      this.saveField(field);
    } else {
      notifier.alert("Invalid Date (Date must be in the format mm/dd/yyyy)");
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  async createStatus() {
    this.notesModalVisible = true;
  }

  getStatusPercentByName(status) {
    let percent = '';
    switch (status) {
      case 'Prospect':
        percent = ' (10%)';
        break;
      case 'Scope':
        percent = ' (25%)';
        break;
      case 'Proposed':
        percent = ' (50%)';
        break;
      case 'Active':
        percent = ' (75%)';
        break;
      case 'Won':
        percent = ' (100%)';
        break;
    }
    return percent;
  }
  async changeStatus(status: number = 0, text:string = "") {
    if (this.details.SALESSTAGE == status) {
      return;
    }

    try {
      const statusText = text;
      const percent = this.getStatusPercentByName(text);
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Opportunities",
          FunctionName: "UpdateOpportunityStatus",
          selectedID: this.$route.params.id,
          sales_stage: status
        }
      );
      if (response.ERROR) {
        throw new Error(response.ERROR);
      }

      if (response.STATUS && response.STATUS == 1) {
        this.details.SALES_STAGE = statusText + percent;
        this.details.SALESSTAGE = status;
        this.logs = response.OPPORTUNITYLOGS || [];
        notifier.success("Status Updated Successfully");
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  async changeProbability(probability: number = 0, text: string = "") {
    if (this.details.PROBABILITY == probability) {
      return;
    }

    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Opportunities",
          FunctionName: "UpdateOpportunityStatus",
          selectedID: this.$route.params.id,
          probability: probability,
          action: "probability"
        }
      );
      if (response.ERROR) {
        throw new Error(response.ERROR);
      }

      if (response.STATUS && response.STATUS == 1) {
        this.details.PROBABILITYASTEXT = text;
        this.details.PROBABILITY = probability;
        this.logs = response.OPPORTUNITYLOGS || [];
        notifier.success("Probability Updated Successfully");
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  getProbabilityPercent(status) {
    switch (status) {
      case 1: return 100; // Imminent
      case 2: return 75; // High
      case 3: return 50; // Medium
      case 4: return 25; // Investigatory
      default: return 0;
    }
  }

  selection(data) {
    let $this = this;
    const field = 'ACCOUNT_LEAD';
    const fieldVal = data.option.value;
    const fieldName = data.option.text;

    getRouteData({
      controller: "Opportunities",
      FunctionName: "UpdateOpportunity",
      oppID: $this.$route.params.id,
      field: field,
      fieldVal: fieldVal,
      fieldName: fieldName
    }).then(function(response, statusText, jqXHR) {
      $this.details[`${field}`] = fieldName;
      $("#"+field+"_COPY").removeClass("displayNone").html(fieldName);
      $("#"+field).addClass("displayNone").val(fieldName);
    });
  }

  toggleActive(activeTab: string = "") {
    this.isActive = activeTab;
  }

  setActive() {
    this.modalFileData.active = true;
  }
  setInactive() {
    this.modalFileData.active = false;
  }

  onUploadFiles(e) {
    const files = e.target.files || [];
    this.doUploadFiles(files);
  }

  onDrop(e) {
    this.setInactive();
    const files = e.dataTransfer.files;
    this.doUploadFiles(files);
  }

  async doUploadFiles(files) {
    const dataFileType = [
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/tiff",
      "image/png",
      "message/rfc822",
      "text/plain"
    ];
    const fileTypeUpload = files[0].type;
    const fileNameUpload = files[0].name;
    const parseFileName = fileNameUpload.split(".");
    let isValid = true;
    if (
      dataFileType.indexOf(fileTypeUpload) == -1 &&
      (parseFileName[parseFileName.length - 1] != "xls" && fileTypeUpload != "")
    ) {
      notifier.alert(
        "Please select an order file type as: PDF, Word docs, Excel/CSV, TXT"
      );
      isValid = false;
    }

    if (isValid) {
      this.modalFileData.uploading = true;
      const requestObj = {
        Controller: "Opportunities",
        FunctionName: "UploadFile",
        filePath: files[0],
        fileTypeID: 5, // Other
        oppID: this.oppID,
        action: 'upload'
      };

      const formData = new FormData();
      for (let key in requestObj) {
        formData.append(key, requestObj[key]);
      }
      formData.append("session", sessionStorage.getItem("sessionID") || "");
      const response: any = await axios.post(
        dataURL + "?ReturnType=JSON&r=Opportunities/UploadFile",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data"
          }
        }
      );
      if (response.data.STATUS === 1) {
        if (response.data.OPPORTUNITYFILES) {
          this.files = response.data.OPPORTUNITYFILES;
        }
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
      this.modalFileData.uploading = false;
    }
  }

  async uploadFileType(item) {
    const response = await ApiHelper.callApi("post", {
      Controller: "Opportunities",
      FunctionName: "UploadFile",
      oppID: this.oppID,
      action: 'updateType',
      fileTypeID: item.FILETYPE,
      fileID: item.FILEID || 0
    });
    if (response.STATUS === 1) {
      notifier.success(
        response.STATUSMESSAGE || "Updated File Type successfully."
      );
    } else {
      notifier.alert(
        response.STATUSMESSAGE || "Cant update File Type"
      );
    }
  }

  async deleteFile() {
    try {
      this.deleteFileloading = true;
      const response = await ApiHelper.callApi("post", {
        Controller: "Opportunities",
        FunctionName: "UploadFile",
        oppID: this.oppID,
        action: 'delete',
        fileID: this.removeFileId
      });

      if (response.STATUS === 1) {
        if (response.OPPORTUNITYFILES) {
          this.files = response.OPPORTUNITYFILES;
        }
        notifier.success(response.STATUSMESSAGE);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.removeFileId = 0;
      this.confirmFileRemove = false;
      this.deleteFileloading = false;
    }
  }

  async removeOrderAndQuote() {
    try {
      this.deleteFileloading = true;
      const response = await ApiHelper.callApi("post", {
        Controller: "Opportunities",
        FunctionName: "UpdateOpportunity",
        field: "removeOrderAndQuote",
        oppID: this.oppID,
        orderQuoteID: this.removeOrderQuoteId,
        isOrder: (this.removeItemType === 1) ? true : false,
      });
      if (response.STATUS === 1) {
        let removeIndex = this.selectedOrderAndQuoteIDs.findIndex(
          item => item.TYPE === this.removeItemType && item.ID === this.removeOrderQuoteId
        );
        if(removeIndex !== -1)
          this.selectedOrderAndQuoteIDs.splice(removeIndex, 1);
        notifier.success(response.STATUSMESSAGE);
      } else {
        notifier.alert(`Error occurred : Cannot remove ${this.orderQuoteTypeAsText}`);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.removeOrderQuoteId = 0;
      this.confirmOrderQuoteRemove = false;
      this.deleteFileloading = false;
      this.removeItemType = 0;
    }
  }

  async updatePoAndQuote(data) {
    if (data.poAndQuote.ID) {
      // Find the index of the order/quote in the list of selected orders/quotes
      const index = this.selectedOrderAndQuoteIDs.findIndex((item: any) => item.ID == data.poAndQuote.ID);

      // If the order/quote is not already in the list, add it
      if (index === -1) {
        let orderAndQuoteStruct = {
          "ID"          : data.poAndQuote.ID,
          "TYPE"        : data.poAndQuote.TYPE,
          "TYPEASTEXT"  : data.poAndQuote.TYPEASTEXT,
          "PO"          : data.poAndQuote.PO,
          "DESCRIPTION" : data.poAndQuote.DESCRIPTION
        }
        this.selectedOrderAndQuoteIDs.push(orderAndQuoteStruct);
        await this.linkOrderAndQuote();
      }
    }
    this.orderQuoteIDs = " ";
  }

  async deleteSelectedPoAndQuote(index) {
    this.$delete(this.selectedOrderAndQuoteIDs, index);
    await this.linkOrderAndQuote();
  }

  async linkOrderAndQuote() {
    let orderIDs = [];
    let quoteIDs = [];
    this.selectedOrderAndQuoteIDs.map((item: any) => {
      if (item.TYPE === 1) {
        orderIDs.push(item.ID);
      } else if (item.TYPE === 2) {
        quoteIDs.push(item.ID);
      }
    });

    const response = await ApiHelper.callApi(
      'post',
      {
        controller: "Opportunities",
        FunctionName: "UpdateOpportunity",
        oppID: this.$route.params.id,
        orderIDs: orderIDs.join(),
        quoteIDs: quoteIDs.join(),
        field: "linkOrderAndQuote"
      }
    );
  }

  getPoAndQuoteText(item) {
    let orderAndQuoteString = "";
    orderAndQuoteString += (item.TYPEASTEXT ? `${item.TYPEASTEXT}: ` : '') + `#${item.ID}`;
    if (item.PO) {
      orderAndQuoteString += ` PO: ${item.PO}`;
    }
    orderAndQuoteString += ` Description: ${item.DESCRIPTION}`;
    return orderAndQuoteString
  }
}
