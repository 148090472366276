




















































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier } from "../../models/common";
import ProductAutoSuggestInput from "@/components/ProductAutoSuggestInput.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import PageTitle from "@/components/pageTitle.vue";
import { AcctsListResp } from "@/models/AcctsListResp";

@Component({
  inheritAttrs: false,
  components: {
    ProductAutoSuggestInput,
    LaddaButton,
    PageTitle
  }
})
export default class AssetServiceAdd extends TSXComponent<void> {
  @Prop({ required: true })
  deploymentId!: string;

  @Prop({ required: true })
  title!: string;


  loading = false;
  productSKU = "";

  selectedProductsID: string[] = [];
  selectedProducts: any[] = [];
  saving: any = false;
  isValid = false;

  created() {

  }

  deleteSelectedProduct(index) {
    this.$delete(this.selectedProductsID, index);
    this.$delete(this.selectedProducts, index);
  }

  deleteInclude(product) {
    const productId = `p${product.PURCHASEID}-${product.SKU}`;
    const index = this.selectedProductsID.findIndex(id => id == productId);
    this.$delete(this.selectedProductsID, index);
    this.$delete(this.selectedProducts, index);
  }

  updateProduct(product) {
    product["isValid"] = true;
    const productId = `p${product.PURCHASEID}-${product.SKU}`;
    const index = this.selectedProductsID.findIndex(id => id == productId);

    if (index === -1) {
      product.INCLUDE = 0;
      this.selectedProductsID.push(productId);
      this.selectedProducts.push(product);
    }
    this.productSKU = " ";
  }

  async addProducts() {
    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "addProducts",
        deploymentId: this.deploymentId,
        productDetails: this.selectedProducts,
        addMore: true
      });

      if (response.data.ERROR) {
        this.saving = "error";
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        this.saving = false;
        this.$router.push({
          name: "ViewDeployment",
          params: { id: this.deploymentId, activeTab: this.title }
        });
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        this.saving = "error";
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      console.log(err);
      this.saving = "error";
    }
  }

  validateValue(item) {
    item.isValid =
      item.INCLUDE >= 0 &&
      item.INCLUDE <= item.QUANTITY - (item.DEPLOYEDQTY || 0);
    this.isValid = !this.selectedProducts.some(function(product) {
      return product.isValid == false;
    });
  }

  getSelectedProductTitle(item) {
    return `${item.SKU} - ${item.PRODUCTNAME} (Order #${item.PURCHASEID})`;
  }
}
