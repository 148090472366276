













































import Vue from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier, printHtml } from "../models/common";
import axios from "axios";
import draggable from "vuedraggable";

interface Props {
  close: Function;
}

interface Events {
  onClose: void;
  onOk: void;
}
declare const $: any;

@Component({
  components: {
    draggable
  },
  methods: {
    close() {
      this.$parent.closeModal();
    }
  }
})
export default class MenuModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: [] })
  allMenuItems?: any;

  $allowedFunctions: number[] = Vue.prototype.$allowedFunctions;
  $isCustomer: boolean = Vue.prototype.$isCustomer;
  $userFunctions = Vue.prototype.$userFunctions;
  $systemGroups: any;
  $parent: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  currentRole = sessionStorage.getItem("userRole");
  $root: any;
  pageloading = false;
  loading = false;
  today = new Date();
  items: any = [];

  created() {
    this.getMenuOrder("");
  }

  mounted() {
    $(this.$refs.modal).modal("show");
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async getMenuOrder(update) {
    // if (update != "") {
    //   var result: any = { data: { params: { menuOrder: {} } } };
    //   result.data.params = JSON.stringify({ MENUORDER: update });
    // } else {
    //   var dataObj = {
    //     controller: "Users",
    //     FunctionName: "sortMenu",
    //     userid: sessionStorage.getItem("userId")
    //   };
    //   var result: any = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    // }

    /* console.dir(result.data.params); */

    const dataObj = {
      controller: "Users",
      FunctionName: "sortMenu",
      userid: sessionStorage.getItem("userId")
    };
    const result: any = await axios.post(dataURL + "?ReturnType=JSON", dataObj);

    if ((result.data.params || "") == "") {
      this.items = [...this.allMenuItems];
    } else {
      var menuParams = JSON.parse(result.data.params);

      if (typeof menuParams.MENUORDER == "undefined") {
        this.items = [...this.allMenuItems];
      } else {
        // this.items = menuParams.MENUORDER;

        const menuOrder = (menuParams.MENUORDER || []).map(item => item.id);
        if (menuOrder.length) {
          let sorted: any = [];
          for (const id of menuOrder) {
            const menuItem = this.allMenuItems.find(item => item.id == id);
            if (menuItem) {
              sorted.push(menuItem);
            }
          }
          const newItems = this.allMenuItems.filter(
            item => !menuOrder.includes(item.id)
          );
          sorted = [...sorted, ...newItems];
          this.items = sorted;
        }
      }
      // if (update != "") {
      //   this.$parent.createMenu();
      // }
    }
  }

  async update() {
    /* this.items.map((item: any, index) => {
      item.CUSTOMFIELDOPTIONORDER = index + 1;
    }) */

    // process menu items
    // ignore active
    // const activeItem = this.items.find(
    //   (item: any) => item.menuActive.currentPage || false
    // );
    // if (activeItem) {
    //   activeItem.menuActive.currentPage = false;
    // }

    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Users",
        FunctionName: "sortMenu",
        params: this.items,
        userid: sessionStorage.getItem("userId")
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        notifier.success(response.data.MESSAGE);
      }

      // this.getMenuOrder(response.data.PARAMETERS.params);
      this.$parent.createMenu();
    } catch (err) {
      console.log(err);
    }
  }

  loginCheck() {
    return sessionStorage.getItem("userRole");
  }
}
