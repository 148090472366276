










import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: true,
  components: {}
})
export default class InputDate extends TSXComponent<void> {
  @Prop({required: true, type: Date, default: new Date()})
  value!: Date

  dateToYYYYMMDD(date) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset*60*1000));
    const out = date.toISOString().split('T')[0]
    return out
  }
  updateValue(target) {
    this.$emit('input', target.valueAsDate);
  }
  selectAll(event) {
    // Workaround for Safari bug
    // https://stackoverflow.com/q/1269722/1850609
    setTimeout(function () {
      event.target.select()
    }, 0)
  }
}
