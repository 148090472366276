


















































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../../components/LaddaButton.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import { FormControls } from "@/models/FormControls";
import PhoneInput from "@/components/PhoneInput.vue";
import Inputmask from "inputmask";
import { v4 as uuidv4 } from "uuid";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import Loader from "@/components/Loader.vue";
import DropdownControl from "@/components/DropdownControl.vue";

interface Props {
  accounts: [];
  users: [];
}

interface Events {}

@Component({
  inheritAttrs: false,
  components: {
    FormMultiSelect,
    LaddaButton,
    Datepicker,
    PhoneInput,
    VueTimepicker,
    Loader,
    DropdownControl
  }
})
export default class ModalAddScheduleGroup extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: [] })
  accounts?: any;

  @Prop({ required: false, default: [] })
  users?: any;

  @Prop({ required: false, default: null })
  data?: any;

  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: 0 })
  sourceId?: number;

  $refs!: {
    modal: HTMLDivElement;
    datePickerStart: Datepicker;
    datePickerEnd: Datepicker;
  };

  loading = false;
  saving: boolean | string = false;
  controls: any = {
    deploymentId: {
      value: this.deploymentId || 0,
      error: ""
    },
    id: {
      value: 0,
      error: ""
    },
    name: {
      value: "",
      error: ""
    },
    people: {
      value: [],
      options: [],
      error: ""
    },
    address1: {
      value: "",
      error: ""
    },
    address2: {
      value: "",
      error: ""
    },
    city: {
      value: "",
      error: ""
    },
    state: {
      value: "",
      error: "",
      options: []
    },
    zip: {
      value: "",
      error: ""
    },
    startDate: {
      value: "",
      error: ""
    },
    endDate: {
      value: "",
      error: ""
    },
    completion: {
      value: "",
      error: ""
    },
    firstName: {
      value: "",
      error: ""
    },
    lastName: {
      value: "",
      error: ""
    },
    emailAddress: {
      value: "",
      error: ""
    },
    contactName: {
      value: "",
      error: ""
    },
    phoneNumber: {
      value: "",
      Extension: "",
      error: ""
    }
  };

  // selectedAccountIds: number[] = [];
  details: {
    USERID: number;
    ACCOUNTLIST: any[];
    ACCOUNTTOTAL: number;
    // securityAccountsArr: any[];
  } = {
    USERID: 0,
    ACCOUNTLIST: [],
    ACCOUNTTOTAL: 0
    // securityAccountsArr: []
  };
  selectedTime = "0";
  // end user/ project (default turn on project)
  isProject = true;
  isSaveSchedule = false;
  // block of time/ leave time free
  inputTimeFree = false;
  // lock to time/ override
  timeOverride: any = {
    // default is time entry
    enable: true,
    start: {
      hh: "",
      mm: "",
      A: ""
    },
    end: {
      hh: "",
      mm: "",
      A: ""
    }
  };
  $parent: any;
  groupNotes = "";
  available: any = {
    start: "",
    end: "",
    daysOfWeek: [],
    duration: {
      hours: "",
      startTime: "",
      startTimePeriod: "",
      startTimeFull: "",
      endTime: "",
      endTimePeriod: "",
      endTimeFull: "",
      full: ""
    }
  };
  exceptions: any = [];
  criteriaFilters: any = [];
  blockTimeGroups: any = [];
  timeSlots: any = [];
  timeIds: string[] = [];
  selectedTimeSlots: any = [];
  initDates = {
    startDate: "",
    endDate: ""
  };
  datePickerKey = uuidv4();
  availabilitiesList: any = [];
  selectedAvailabilityIds: number[] = [];

  clickOutside(e) {
    if (
      $(e.target).hasClass("time-picker-overlay") ||
      $(e.target).closest(".controls").length
    ) {
      return;
    }
    this.$emit("close");
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      try {
        const datePickerStart: any = this.$refs.datePickerStart;
        const datePickerEnd: any = this.$refs.datePickerEnd;
        datePickerStart.destroy();
        datePickerEnd.destroy();
      } catch (error) {
        // do nothing
      }

      this.$emit("close");
    });
  }

  async created() {
    try {
      this.loading = true;

      const scheduleGroupId = this.data ? this.data.GROUPID || 0 : 0;
      const availabilityRes = await ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "GetAvailabilitiesList",
        deploymentId: this.deploymentId,
        scheduleGroupId: parseInt(scheduleGroupId)
      });
      if (availabilityRes.STATUS == 1) {
        this.availabilitiesList = availabilityRes.availabilitiesList || [];
        this.blockTimeGroups = availabilityRes.blockTimeGroups || [];
        this.selectedTimeSlots = availabilityRes.selectedTimeSlots || [];
      }

      if (this.data) {
        this.loadAvailability(this.data.AVAILABILITYID || 0);
      }

      // if no selected an availability before, try to set first one in the list
      if (
        !this.selectedAvailabilityIds.length &&
        this.availabilitiesList.length
      ) {
        // auto select an availability has no any criteria
        const availabilitiesNoCriteria = this.availabilitiesList.filter(
          item => !(item.criteriaFilters || []).length
        );
        if (availabilitiesNoCriteria.length) {
          this.loadAvailability(
            availabilitiesNoCriteria[0].available.availabilityId
          );
        }
      }

      if (this.data && this.data.GROUPID) {
        const initData = this.data || {};
        // const params = JSON.parse(initData.PARAMS || "{}");
        this.controls.people.value =
          (initData.ENDUSERIDS || "") != ""
            ? initData.ENDUSERIDS.split(",").map(id => parseInt(id))
            : [];
        // this.controls.completion.value = initData.COMPLETION || "";
        this.controls.id.value = initData.GROUPID || "0";
        this.controls.name.value = initData.GROUPNAME || "";
        this.controls.state.value = initData.STATE || "";
        this.controls.city.value = initData.CITY || "";
        this.controls.address1.value = initData.ADDRESS1 || "";
        this.controls.address2.value = initData.ADDRESS2 || "";
        this.controls.zip.value = initData.ZIP || "";
        this.controls.startDate.value = moment(
          initData.TARGETSTARTDATE
        ).isValid()
          ? moment(initData.TARGETSTARTDATE).format("MM/DD/YYYY")
          : "";
        this.controls.endDate.value = moment(initData.TARGETENDDATE).isValid()
          ? moment(initData.TARGETENDDATE).format("MM/DD/YYYY")
          : "";
        // this.controls.firstName.value = initData.FIRSTNAME;
        // this.controls.lastName.value = initData.LASTNAME;
        this.controls.emailAddress.value = initData.EMAILADDRESS;
        this.controls.contactName.value = initData.CONTACTNAME || "";
        this.controls.phoneNumber.value = initData.PHONENUMBER;
        this.controls.phoneNumber.Extension = initData.PHONEEXT;
        this.groupNotes = initData.GROUPNOTES || "";
        this.isProject = !!(initData.ISPROJECT || 0);
        this.inputTimeFree = !!(initData.INPUTTIMEFREE || 0);
        this.timeOverride.enable = !!(initData.TIMEOVERRIDE || 0);
        if (this.timeOverride.enable && (this.data.TIMESLOTS || []).length) {
          const timeInfo = this.data.TIMESLOTS[0];
          const startTime = timeInfo.STARTTIME || "";
          const startTimeArr = startTime != "" ? startTime.split(":") : [];
          this.timeOverride.start = {
            hh: startTimeArr.length ? startTimeArr[0] : "",
            mm: startTimeArr.length > 1 ? startTimeArr[1] : "",
            A: (timeInfo.STARTTIMEPERIOD || "").toUpperCase()
          };
          const endTime = timeInfo.ENDTIME || "";
          const endTimeArr = endTime != "" ? endTime.split(":") : [];
          this.timeOverride.end = {
            hh: endTimeArr.length ? endTimeArr[0] : "",
            mm: endTimeArr.length > 1 ? endTimeArr[1] : "",
            A: (timeInfo.ENDTIMEPERIOD || "").toUpperCase()
          };
        }

        // specify init dates
        this.initDates.startDate = this.controls.startDate.value;
        this.initDates.endDate = this.controls.endDate.value;
      }

      if (this.users) {
        this.controls.people.options = this.users.map(item => {
          const fullName = `${item.FIRSTNAME} ${item.LASTNAME}`;
          return {
            id: item.USERID,
            text: fullName,
            html: `${fullName}
            ${
              item.EUADDRESS
                ? `- <span class="eu-addr">${item.EUADDRESS}</span>`
                : ""
            }
          `,
            title: `${fullName} ${
              item.EUADDRESS ? `- ${item.EUADDRESS}` : ""
            }`.trim(),
            data: item
          };
        });

        // auto select end user if add new from tab end users > attach group tooltip > "setup new"
        const isAddNew = (this.controls.id.value || 0) == 0;
        let linkToEndUserId = 0;
        if (
          this.$parent.isActive == "endUsers" &&
          isAddNew &&
          this.$parent.setupNewScheduleInfo
        ) {
          // setup new schedule group from "end users" tab
          const selectedUser = this.$parent.setupNewScheduleInfo.selectedUser;
          linkToEndUserId = selectedUser.USERID || 0;
          if (linkToEndUserId) {
            this.controls.people.value.push(linkToEndUserId);
          }
        }

        const userIds: any = [];
        this.controls.people.value.map(id => {
          const findUser = this.controls.people.options.find(
            item => item.id == id
          );
          if (findUser) {
            userIds.push(id);
          }
        });
        this.controls.people.value = userIds;
      }

      const states = await ApiHelper.getCountryStates();
      this.controls.state.options = states.map(item => {
        return {
          id: item.state_code,
          name: item.name
        };
      });

      this.maskPhone();

      // if selected time slots
      // if (
      //   this.data &&
      //   this.data.GROUPID &&
      //   (this.data.TIMESLOTS || []).length
      // ) {
      //   for (const item of this.data.TIMESLOTS) {
      //     const selectedArr: any = [];
      //     const startFull = `${item.STARTTIME} ${item.STARTTIMEPERIOD}`;
      //     const endFull = `${item.ENDTIME} ${item.ENDTIMEPERIOD}`;
      //     if (startFull) {
      //       selectedArr.push(startFull);
      //     }
      //     if (endFull) {
      //       selectedArr.push(endFull);
      //     }
      //     const selectedText = selectedArr.join(" - ");
      //     const inList = this.timeSlots.find(
      //       item => item.text.toUpperCase() == selectedText.toUpperCase()
      //     );
      //     if (inList) {
      //       this.timeIds.push(inList.id);
      //     }
      //   }
      // }

      this.datePickerKey = uuidv4();
      this.checkAvailableEUs();
      this.loading = false;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  toggleSelectedEndUser(userId: number, accountName) {
    const index = this.controls.people.value.findIndex(id => id === userId);
    if (index === -1) {
      this.controls.people.value.push(userId);
      return;
    }

    this.controls.people.value.splice(index, 1);
    // this.$delete(this.details.securityAccountsArr, index);
  }

  selectAllEndUsers() {
    this.resetSelectedEndUsers();
    // const $this = this;
    // this.controls.people.value = [];
    // $.each(this.controls.people.options, function(i, val) {
    //   $this.controls.people.value.push(val.id);
    // });
    this.controls.people.value = this.controls.people.options
      .filter(t => !(t.disabled || false))
      .map(t => t.id);
  }

  resetSelectedEndUsers() {
    this.controls.people.value = [];
    // this.details.securityAccountsArr = [];
  }

  async onSubmit() {
    let valid = await this.$validator.validateAll();
    if (
      new Date(this.controls.startDate.value) >
      new Date(this.controls.endDate.value)
    ) {
      this.$validator.errors.add({
        field: "targetStartDate",
        msg: `The Target start date must be before ${
          this.controls.endDate.value
        }`
      });
      valid = false;
    }

    // start/ end date should in a range date of availability setting
    if (valid) {
      const startDate = this.available.start || "";
      const endDate = this.available.end || "";
      const startDateInput = new Date(this.controls.startDate.value);
      const endDateInput = new Date(this.controls.endDate.value);
      if (startDate && endDate) {
        // check start date input
        if (
          startDateInput < new Date(startDate) ||
          startDateInput > new Date(endDate)
        ) {
          valid = false;
        }
        // check end date input
        if (
          endDateInput < new Date(startDate) ||
          endDateInput > new Date(endDate)
        ) {
          valid = false;
        }
        if (!valid) {
          this.$validator.errors.add({
            field: "targetStartDate",
            msg: `The Start Date, End Date must be in range ${startDate} - ${endDate}`
          });
        }
      }
    }

    // number of end users should not over the ability spots
    // if (valid && this.selectedAvailabilityIds.length) {
    //   const availability = this.availabilitiesList.find(
    //     item => item.available.availabilityId == this.selectedAvailabilityIds[0]
    //   );
    //   const eus = availability ? availability.eus || [] : [];
    //   const allEUs = [...new Set([...eus, ...this.controls.people.value])];
    //   if (
    //     availability.available.spots > 0 &&
    //     allEUs.length > availability.available.spots
    //   ) {
    //     valid = false;
    //     notifier.alert(
    //       `Exceeded Spots Available Per Block For This Availability (allow ${availability.available.spots})`
    //     );
    //   }
    // }

    if (!valid) return;

    try {
      this.saving = true;
      const isAddNew = (this.controls.id.value || 0) == 0;
      let linkToEndUserId = 0;
      if (isAddNew && this.$parent.setupNewScheduleInfo) {
        // setup new schedule group from "end users" tab
        const selectedUser = this.$parent.setupNewScheduleInfo.selectedUser;
        linkToEndUserId =
          this.$parent.isActive == "endUsers" && selectedUser.USERID && isAddNew
            ? selectedUser.USERID
            : 0;
      }

      const timeInfo: any = [];
      // if time entry is enable
      if (this.timeOverride.enable) {
        timeInfo.push({
          startTime: "",
          endTime: "",
          startTimePeriod: "",
          endTimePeriod: ""
        });
        if (this.timeOverride.start) {
          const { hh, mm, A } = this.timeOverride.start;
          if (hh && mm && A) {
            timeInfo[0].startTime = `${hh}:${mm}`;
            timeInfo[0].startTimePeriod = A.toLowerCase();
          }
        }
        if (this.timeOverride.end) {
          const { hh, mm, A } = this.timeOverride.end;
          if (hh && mm && A) {
            timeInfo[0].endTime = `${hh}:${mm}`;
            timeInfo[0].endTimePeriod = A.toLowerCase();
          }
        }
      } else {
        // select some time slots
        for (const timeId of this.timeIds) {
          const inList = this.timeSlots.find(item => item.id == timeId);
          if (inList) {
            timeInfo.push({
              startTime: inList.start,
              startTimePeriod: inList.startTimePeriod,
              endTime: inList.end,
              endTimePeriod: inList.endTimePeriod
            });
          }
        }
      }

      // check spots
      let availabilityId = this.selectedAvailabilityIds.length
        ? this.selectedAvailabilityIds[0]
        : 0;
      const availability = this.availabilitiesList.find(
        item => item.available.availabilityId == availabilityId
      );
      const spots = availability
        ? parseInt(availability.available.spots || 0)
        : 0;
      if (spots && this.controls.people.value.length > spots) {
        notifier.alert(
          `Exceeded Spots Available Per Block For This Availability (allow ${
            availability.available.spots
          })`
        );
        this.saving = "error";
        return;
      }

      const response = await ApiHelper.callApi("post", {
        controller: "Deployments",
        FunctionName: "AddScheduleGroup",
        deploymentId: this.controls.deploymentId.value,
        availabilityId,
        groupId: this.controls.id.value,
        groupName: this.controls.name.value,
        address1: this.controls.address1.value,
        address2: this.controls.address2.value,
        city: this.controls.city.value,
        state: this.controls.state.value,
        zip: this.controls.zip.value,
        startDate: moment(this.controls.startDate.value).format(
          "YYYY-MM-DD 00:00:00"
        ),
        endDate: moment(this.controls.endDate.value).format(
          "YYYY-MM-DD 23:59:59"
        ),
        userIds: this.controls.people.value,
        completion: this.controls.completion.value || 0,
        firstName: this.controls.firstName.value,
        lastName: this.controls.lastName.value,
        contactName: this.controls.contactName.value,
        emailAddress: this.controls.emailAddress.value,
        phoneNumber: this.controls.phoneNumber.value,
        phoneExt: this.controls.phoneNumber.Extension,
        groupNotes: this.groupNotes,
        linkToEndUserId,
        isProject: this.isProject ? 1 : 0,
        inputTimeFree: this.inputTimeFree ? 1 : 0,
        timeOverride: this.timeOverride.enable ? 1 : 0,
        timeInfo
      });

      if (response.STATUS == 1) {
        // update deployment percent
        await ApiHelper.updateDeploymentPercent(
          this.controls.deploymentId.value
        );
        const message = isAddNew
          ? "Schedule Group created successfully"
          : "Schedule Group updated successfully";
        notifier.success(message);

        // reset selection
        if (this.$parent.setupNewScheduleInfo) {
          this.$parent.setupNewScheduleInfo.index = -1;
        }

        this.$emit("callback", {
          isAddNew,
          groupId: response.GROUPID || 0,
          groupName: this.controls.name.value
        });
        this.saving = false;
      } else if (response.STATUSMESSAGE) {
        notifier.alert(response.STATUSMESSAGE);
        this.saving = "error";
      } else {
        this.saving = "error";
      }
    } catch (err) {
      this.saving = "error";
      console.log(err);
    }
  }

  // getExt(e) {
  //   if (e != undefined) {
  //     return e.split(" ")[0];
  //   } else {
  //     return "US";
  //   }
  // }

  // countryChanged(obj) {
  //   this.controls.phoneNumber.Extension =
  //     obj.countryCode + " " + "+" + obj.countryCallingCode;
  // }

  get selectedEndUsers() {
    const selected = this.controls.people.options.filter(item =>
      this.controls.people.value.includes(item.id)
    );
    return selected;
  }

  updateSelectedEndUsers(type, index, item) {
    this.controls.people.value = this.controls.people.value.filter(
      id => id != item.id
    );
  }

  isProjectChange() {
    this.$nextTick().then(() => {
      if (this.isProject) {
        // mask for phone
        this.maskPhone();
      }
    });
  }

  maskPhone() {
    $("input[inputmask=phonenumber]").each(function(i, obj) {
      Inputmask("(999) 999-9999", {}).mask(obj);
    });
  }

  dayChange(type = "", selected = "") {
    //
  }

  dropdownPickerClose(type = "") {
    // auto clear selection if not select full for time
    if (type == "start") {
      const { hh, mm, A } = this.timeOverride.start;
      if (hh == "" || mm == "" || A == "") {
        this.timeOverride.start = {
          hh: "",
          mm: "",
          A: ""
        };
      }
    } else if (type == "end") {
      const { hh, mm, A } = this.timeOverride.end;
      if (hh == "" || mm == "" || A == "") {
        this.timeOverride.end = {
          hh: "",
          mm: "",
          A: ""
        };
      }
    }
  }

  toggleSelectedTimeSlot(timeId: string, text) {
    const index = this.timeIds.findIndex(id => id == timeId);
    if (index === -1) {
      this.timeIds.push(timeId);
      return;
    }

    this.timeIds.splice(index, 1);
  }

  datePickerFilter(date, view, type = "") {
    if (view == "day") {
      const datePicker: any =
        type == "startDate"
          ? this.$refs.datePickerStart
          : this.$refs.datePickerEnd;
      const dateFormatted = datePicker.formatDate(date);
      let valid = true;

      // set available days based on "days of week" availability settings
      if ((this.available.daysOfWeek || []).length) {
        const dayMap = {
          "0": "sun",
          "1": "mon",
          "2": "tue",
          "3": "wed",
          "4": "thu",
          "5": "fri",
          "6": "sat"
        };
        if (!this.available.daysOfWeek.includes(dayMap[`${date.getDay()}`])) {
          valid = false;
        }
      }
      if (!valid) {
        // disable selection this day
        return false;
      }

      // disable selection based on exception settings of availability
      if (this.exceptions.length) {
        for (const item of this.exceptions) {
          if (
            (item.betweenTimes.start && !item.betweenTimes.end) ||
            (item.betweenTimes.end && !item.betweenTimes.start)
          ) {
            // ignore case just set a start, or end time
            continue;
          }

          // disable selecting this day if availability time is between this exception time range
          if (
            this.available.duration &&
            item.betweenTimes.start &&
            item.betweenTimes.end
          ) {
            const timeFormat = "hh:mm A";
            const eStartTime = moment(
              `${item.betweenTimes.startFull}`.toUpperCase(),
              timeFormat
            );
            const eEndTime = moment(
              `${item.betweenTimes.endFull}`.toUpperCase(),
              timeFormat
            );
            const availableStartTime = moment(
              `${this.available.duration.startTimeFull}`.toUpperCase(),
              timeFormat
            );
            const availableEndTime = moment(
              `${this.available.duration.endTimeFull}`.toUpperCase(),
              timeFormat
            );

            if (
              eStartTime.isValid() &&
              eEndTime.isValid() &&
              availableStartTime.isValid() &&
              availableEndTime.isValid() &&
              availableStartTime.isSameOrAfter(eStartTime) &&
              availableStartTime.isSameOrBefore(eEndTime) &&
              availableEndTime.isSameOrAfter(eStartTime) &&
              availableEndTime.isSameOrBefore(eEndTime)
            ) {
              // check if need to disable this day at next step
            } else {
              // allow selecting this day
              continue;
            }
          }

          // if has a date range exception
          const startDate = item.start || "";
          let endDate = item.end || "";
          if (startDate && !endDate) {
            // set exception in a day (just using startDate)
            endDate = startDate;
          }

          if (
            new Date(dateFormatted) >= new Date(startDate) &&
            new Date(dateFormatted) <= new Date(endDate)
          ) {
            valid = false;
            break;
          }
        }
        if (!valid) {
          // disable selection this day
          return false;
        }
      }

      // disable selection based on blockTimeGroups
      const availabilityId = this.selectedAvailabilityIds.length
        ? this.selectedAvailabilityIds[0]
        : 0;
      let blockTimeGroupsByAvailability = this.blockTimeGroups;
      if (availabilityId) {
        blockTimeGroupsByAvailability = this.blockTimeGroups.filter(
          t => (t.AVAILABILITYID || 0) == availabilityId
        );
      }
      for (const item of blockTimeGroupsByAvailability) {
        if (!item.STARTDATE || !item.ENDDATE) {
          continue;
        }
        if (
          new Date(dateFormatted) >= new Date(item.STARTDATE) &&
          new Date(dateFormatted) <= new Date(item.ENDDATE)
        ) {
          valid = false;
          break;
        }
      }
      if (!valid) {
        // disable selection this day
        return false;
      }
    }
  }

  get checkedTimeSlots() {
    const ret = this.timeSlots || [];
    const availabilityId = this.selectedAvailabilityIds.length
      ? this.selectedAvailabilityIds[0]
      : 0;
    let selectedTimeSlotsByAvailability = this.selectedTimeSlots || [];
    if (availabilityId) {
      selectedTimeSlotsByAvailability = selectedTimeSlotsByAvailability.filter(
        t => (t.AVAILABILITYID || 0) == availabilityId
      );
    }
    if (this.controls.startDate.value && this.controls.endDate.value) {
      const startDate = this.controls.startDate.value;
      const endDate = this.controls.endDate.value;
      if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
        return ret;
      }

      for (const item of ret) {
        item.disabled = false;
        // check in selected time slots
        if (selectedTimeSlotsByAvailability.length) {
          const inList = selectedTimeSlotsByAvailability.find(
            t =>
              t.SELECTEDTEXT.toUpperCase() == item.text.toUpperCase() &&
              (new Date(startDate) >= new Date(t.STARTDATE) &&
                new Date(startDate) <= new Date(t.ENDDATE)) &&
              (new Date(endDate) >= new Date(t.STARTDATE) &&
                new Date(endDate) <= new Date(t.ENDDATE))
          );
          if (inList) {
            item.disabled = true;
          }
        }

        // check exception time slots
        if (!item.disabled && this.exceptions.length) {
          const timeFormat = "hh:mm A";
          const startTime = moment(
            `${item.start} ${item.startTimePeriod}`,
            timeFormat
          );
          const endTime = moment(
            `${item.end} ${item.endTimePeriod}`,
            timeFormat
          );

          for (const t of this.exceptions) {
            const eStartDate = t.start || "";
            let eEndDate = t.end || "";
            if (eStartDate && !eEndDate) {
              // set exception in a day (just using startDate)
              eEndDate = eStartDate;
            }

            if (
              !(
                new Date(startDate) >= new Date(eStartDate) &&
                new Date(startDate) <= new Date(eEndDate) &&
                (new Date(endDate) >= new Date(eStartDate) &&
                  new Date(endDate) <= new Date(eEndDate))
              )
            ) {
              // ignore if not in exception date range
              continue;
            }

            if (t.betweenTimes.start == "" && t.betweenTimes.end == "") {
              // ignore if exception has not a time range
              continue;
            }

            const eStartTime = moment(
              `${t.betweenTimes.startFull.toUpperCase()}`,
              timeFormat
            );
            const eEndTime = moment(
              `${t.betweenTimes.endFull.toUpperCase()}`,
              timeFormat
            );
            if (!eStartTime.isValid() || !eEndTime.isValid()) {
              continue;
            }

            // disable picking if time slot is in exception time range
            // case time slot is in exception time range (eStartTime <= startTime <= endTime <= eEndTime)
            if (
              startTime.isSameOrAfter(eStartTime) &&
              startTime.isSameOrBefore(eEndTime) &&
              (endTime.isSameOrAfter(eStartTime) &&
                endTime.isSameOrBefore(eEndTime))
            ) {
              item.disabled = true;
              break;
            }
            // case exception time range is in time slot range (startTime <= eStartTime <= eEndTime <= endTime)
            if (
              !item.disabled &&
              (eStartTime.isSameOrAfter(startTime) &&
                eStartTime.isSameOrBefore(endTime)) &&
              (eEndTime.isSameOrAfter(startTime) &&
                eEndTime.isSameOrBefore(endTime))
            ) {
              item.disabled = true;
              break;
            }
            // case exception time (start or end) is in time slot range
            // startTime < eStartTime < endTime, or startTime < eEndTime < endTime
            if (
              !item.disabled &&
              ((eStartTime.isAfter(startTime) &&
                eStartTime.isBefore(endTime)) ||
                (eEndTime.isAfter(startTime) && eEndTime.isBefore(endTime)))
            ) {
              item.disabled = true;
              break;
            }
          }
        }
      }
    }

    return ret;
  }

  resetCurrentAvailability() {
    this.available = {};
    this.exceptions = [];
    this.criteriaFilters = [];
    this.timeSlots = [];
    this.timeIds = [];
    this.controls.people.value = [];
  }

  loadAvailability(id) {
    if (!id) return;

    const inList = this.availabilitiesList.find(
      item => item.available.availabilityId == id
    );
    if (!inList) return;

    // reset
    this.resetCurrentAvailability();

    this.selectedAvailabilityIds = [id];
    this.available = inList.available || {};
    this.exceptions = inList.exceptions || [];
    this.criteriaFilters = inList.criteriaFilters || [];
    // this.blockTimeGroups = availabilityRes.blockTimeGroups || [];
    // this.selectedTimeSlots = availabilityRes.selectedTimeSlots || [];

    if (this.available.duration) {
      this.timeSlots = (this.available.duration.timeSlots || []).map(item => ({
        id: uuidv4(),
        // picked: false,
        disabled: false,
        ...item
      }));
    }

    // specify init dates
    const startDate = this.available.start || "";
    if (startDate) {
      if (!this.initDates.startDate) {
        this.initDates.startDate = startDate;
      }
      if (!this.initDates.endDate) {
        this.initDates.endDate = startDate;
      }
    }

    // check available end users based on selected availability
    this.checkAvailableEUs();

    // auto select some values saved before for this group
    if (this.data) {
      // select start/ end date if was saved before
      if ((this.data.AVAILABILITYID || 0) == id) {
        this.controls.startDate.value = moment(
          this.data.TARGETSTARTDATE
        ).isValid()
          ? moment(this.data.TARGETSTARTDATE).format("MM/DD/YYYY")
          : "";
        this.controls.endDate.value = moment(this.data.TARGETENDDATE).isValid()
          ? moment(this.data.TARGETENDDATE).format("MM/DD/YYYY")
          : "";
      }

      // select end user if was saved before (need to in available end users of this selected availability)
      if ((this.data.ENDUSERIDS || "") != "") {
        const savedEUIds = this.data.ENDUSERIDS.split(",").map(id =>
          parseInt(id)
        );
        const availableEUIds = this.controls.people.options
          .filter(t => !(t.disabled || false))
          .map(t => t.id);
        this.controls.people.value = savedEUIds.filter(id =>
          availableEUIds.includes(id)
        );
      }

      // select time slots if was saved before
      if (
        this.data.GROUPID &&
        (this.data.TIMESLOTS || []).length &&
        (this.data.AVAILABILITYID || 0) == id
      ) {
        for (const item of this.data.TIMESLOTS) {
          const selectedArr: any = [];
          const startFull = `${item.STARTTIME} ${item.STARTTIMEPERIOD}`;
          const endFull = `${item.ENDTIME} ${item.ENDTIMEPERIOD}`;
          if (startFull) {
            selectedArr.push(startFull);
          }
          if (endFull) {
            selectedArr.push(endFull);
          }
          const selectedText = selectedArr.join(" - ");
          const inList = this.timeSlots.find(
            item => item.text.toUpperCase() == selectedText.toUpperCase()
          );
          if (inList) {
            this.timeIds.push(inList.id);
          }
        }
      }
    }

    this.datePickerKey = uuidv4();
  }

  getAvailabilityInfoStr(item) {
    let ret: string[] = [];
    // start/ end date
    ret.push(this.getAvailableDates(item));
    // days of week
    if (item.available.daysOfWeek.length) {
      ret.push(item.available.daysOfWeek.join(", "));
    }
    // start/ end time
    ret.push(this.getAvailableTimes(item));

    return ret
      .filter(t => t != "")
      .join(" | ")
      .toUpperCase();
  }

  getAvailableDates(item) {
    const ret: any = [];
    if (item.available.start && moment(item.available.start).isValid()) {
      ret.push(moment(item.available.start).format("MM/DD/YY"));
    }
    if (item.available.end && moment(item.available.end).isValid()) {
      ret.push(moment(item.available.end).format("MM/DD/YY"));
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  getAvailableTimes(item: any) {
    const ret: any = [];
    const duration: any = item.available.duration;
    if (duration.startTime && duration.startTimePeriod) {
      ret.push(`${duration.startTime} ${duration.startTimePeriod}`);
    }
    if (duration.endTime && duration.endTimePeriod) {
      ret.push(`${duration.endTime} ${duration.endTimePeriod}`);
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  checkAvailableEUs() {
    const endUsers = this.controls.people.options;

    // reset
    for (const eu of endUsers) {
      eu.disabled = false;
    }
    if (!this.criteriaFilters.length) return;

    // disable items not match with criteria filters (if item not match with all criteria in the list)
    // still enable if just match with a criteria
    for (const eu of endUsers) {
      // let match = false;
      let matchCnt = 0;
      for (const filter of this.criteriaFilters) {
        if ((filter.value || []).length && filter.value[0] != "") {
          const dataKey = `CUSTOMDATA${filter.customFieldId}`;
          const dataVal = eu.data[dataKey] || "";

          if (dataVal && filter.value.includes(dataVal)) {
            // match = true;
            // break;
            matchCnt += 1;
          }
        }
      }
      // if match with the filters, show item, else hide it
      eu.disabled = matchCnt == this.criteriaFilters.length ? false : true;
    }
  }
}
