





















import {Component as TSXComponent} from "vue-tsx-support";
import {Component} from "vue-property-decorator";

@Component({
  inheritAttrs: true,
  components: {}
})

export default class ContentToolTip extends TSXComponent<void> {


  clickOutside() {
    this.$emit("close");
  }

  async created() {

  }
}
