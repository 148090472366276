

























import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue"

interface Props {
  title: string
  selectedIDs: string[]
}

interface Events {
  onClose: void
  onConfirm: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class ConfirmCopyAccountsModal extends TSXComponent<
  Props,
  Events
> {
  @Prop({required: true})
  title!: string

  @Prop({required: true})
  selectedName!: string[]

  @Prop({required: false,default : false})
  iconload!: string
  
  saving = false

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  confirm() {
    this.saving = true
    this.$emit('confirm')
  }
}
