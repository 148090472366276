var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr>\n      <td>\n        "
    + alias4(((helper = (helper = helpers.GROUPNAME || (depth0 != null ? depth0.GROUPNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"GROUPNAME","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":23}}}) : helper)))
    + "\n      </td>\n      <td style=\"padding-left: 10px;\">\n        "
    + alias4(((helper = (helper = helpers.ENDUSERS || (depth0 != null ? depth0.ENDUSERS : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ENDUSERS","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":22}}}) : helper)))
    + "\n      </td>\n      <td style=\"padding-left: 10px;\">\n        "
    + alias4(((helper = (helper = helpers.ADDRESS1 || (depth0 != null ? depth0.ADDRESS1 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ADDRESS1","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":22}}}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ADDRESS2 : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "        "
    + alias4(((helper = (helper = helpers.CITY || (depth0 != null ? depth0.CITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CITY","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":18}}}) : helper)))
    + "\n"
    + ((stack1 = (helpers.ifCond||(depth0 && depth0.ifCond)||alias2).call(alias1,(depth0 != null ? depth0.STATE : depth0),"||",(depth0 != null ? depth0.ZIP : depth0),{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "      </td>\n      <td style=\"padding-left: 10px;\">\n"
    + ((stack1 = (helpers.ifCond||(depth0 && depth0.ifCond)||alias2).call(alias1,(depth0 != null ? depth0.ITEMTYPE : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + "      </td>\n      <td style=\"padding-left: 10px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.GROUPNOTES : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n      <td style=\"padding-left: 10px;\">\n        15 2 DAYS AGO\n      </td>\n      <td style=\"padding-left: 10px;\">\n        "
    + alias4(((helper = (helper = helpers.COMPLETIONPERCENT || (depth0 != null ? depth0.COMPLETIONPERCENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"COMPLETIONPERCENT","hash":{},"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":49,"column":31}}}) : helper)))
    + "%\n      </td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          , "
    + container.escapeExpression(((helper = (helper = helpers.ADDRESS2 || (depth0 != null ? depth0.ADDRESS2 : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ADDRESS2","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":26}}}) : helper)))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          , "
    + alias4(((helper = (helper = helpers.STATE || (depth0 != null ? depth0.STATE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"STATE","hash":{},"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":23}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.ZIP || (depth0 != null ? depth0.ZIP : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ZIP","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":33}}}) : helper)))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          "
    + alias4(((helper = (helper = helpers.TARGETSTARTDATEFORMATTED || (depth0 != null ? depth0.TARGETSTARTDATEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TARGETSTARTDATEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":31,"column":10},"end":{"line":31,"column":40}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.TARGETENDDATEFORMATTED || (depth0 != null ? depth0.TARGETENDDATEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TARGETENDDATEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":31,"column":41},"end":{"line":31,"column":69}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.DAYDIFFERENCE || (depth0 != null ? depth0.DAYDIFFERENCE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DAYDIFFERENCE","hash":{},"data":data,"loc":{"start":{"line":31,"column":70},"end":{"line":31,"column":89}}}) : helper)))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond||(depth0 && depth0.ifCond)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ITEMTYPE : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":10},"end":{"line":35,"column":21}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            "
    + alias4(((helper = (helper = helpers.TARGETSTARTDATEFORMATTED || (depth0 != null ? depth0.TARGETSTARTDATEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TARGETSTARTDATEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":34,"column":42}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.STARTTIME || (depth0 != null ? depth0.STARTTIME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"STARTTIME","hash":{},"data":data,"loc":{"start":{"line":34,"column":43},"end":{"line":34,"column":58}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.STARTTIMEPERIOD || (depth0 != null ? depth0.STARTTIMEPERIOD : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"STARTTIMEPERIOD","hash":{},"data":data,"loc":{"start":{"line":34,"column":59},"end":{"line":34,"column":80}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.TARGETSTARTDATEFORMATTED || (depth0 != null ? depth0.TARGETSTARTDATEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TARGETSTARTDATEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":34,"column":81},"end":{"line":34,"column":111}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.ENDTIME || (depth0 != null ? depth0.ENDTIME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ENDTIME","hash":{},"data":data,"loc":{"start":{"line":34,"column":112},"end":{"line":34,"column":125}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.ENDTIMEPERIOD || (depth0 != null ? depth0.ENDTIMEPERIOD : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ENDTIMEPERIOD","hash":{},"data":data,"loc":{"start":{"line":34,"column":126},"end":{"line":34,"column":145}}}) : helper)))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          "
    + container.escapeExpression(((helper = (helper = helpers.GROUPNOTES || (depth0 != null ? depth0.GROUPNOTES : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"GROUPNOTES","hash":{},"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":40,"column":26}}}) : helper)))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "          No Notes\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"customerList_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td style=\"width: 16%; border-bottom: 5px solid #b1b1b1;\">SCHEDULE GROUP</td>\n    <td style=\"padding-left: 10px; width: 15%; border-bottom: 5px solid #b1b1b1;\">END USER</td>\n    <td style=\"padding-left: 10px; width: 15%; border-bottom: 5px solid #b1b1b1;\">SITE LOCATION</td>\n    <td style=\"padding-left: 10px; width: 25%; border-bottom: 5px solid #b1b1b1;\">TARGET START TARGET END</td>\n    <td style=\"padding-left: 10px; width: 10%; border-bottom: 5px solid #b1b1b1;\">NOTES</td>\n    <td style=\"padding-left: 10px; width: 10%; border-bottom: 5px solid #b1b1b1;\">ACTIONS</td>\n    <td style=\"padding-left: 10px; width: 9%; border-bottom: 5px solid #b1b1b1;\">COMPLETION</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.SCHEDULEGROUPS : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":52,"column":11}}})) != null ? stack1 : "")
    + "</table>";
},"useData":true});