








































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import PageTitle from "../components/pageTitle.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { colsResizeable } from "@/helpers/ApiHelper";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    PageTitle,
    LaddaButton
  }
})

export default class SpiffSources extends TSXComponent<void> {
  $root: any;
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  message = "";
  uploading: any = false;
  pageNumber = 1;
  details: any = {};
  spiffSources = [];

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "SpiffSources",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS != 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.spiffSources = this.details.SPIFFSOURCES;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});

  }

  async validateBeforeSubmit(e) {
    const files = $("#spiffSourceFile").get(0).files || [];
    if (!files.length) {
      return;
    }

    const fileName = files[0].name;
    const allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
    if (!allowedExtensions.exec(fileName)) {
      this.message = "Please upload file having extensions .csv or .xls or .xlsx only.";
      return;
    }

    try {
      this.uploading = true;
      this.message = "";
      const requestObj = {
        Controller: "Finances",
        FunctionName: "SpiffSourceImport",
        isWebSubmit: true,
        filePath: files[0],
        fileName,
        fileType:
          fileName.indexOf(".csv") != -1
            ? "csv"
            : fileName.indexOf(".xls") != -1
            ? "xls"
            : ""
      };

      const formData = new FormData();
      for (let key in requestObj) {
        formData.append(key, requestObj[key]);
      }
      formData.append("session", sessionStorage.getItem("sessionID") || "");
      const response: any = await axios.post(
        dataURL + "?ReturnType=JSON&r=Finances/SpiffSourceImport",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data"
          }
        }
      );

      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
        $("#spiffSourceFile").val("");
        this.uploading = false;
        await this.fetchData();
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }

        this.uploading = "error";
      }
    } catch (error) {
      this.uploading = "error";
      console.log(error);
    }
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  listPageRedirection() {
    this.$router.go(-1);
  }
}
