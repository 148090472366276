var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "  <tr><td class=\"title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VARSOURCE_NAME : stack1), depth0))
    + " Quote ID: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.INDIRECTQUOTEID : stack1), depth0))
    + "</td></tr>\n  <tr><td class=\"title\">&nbsp;</td></tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.VARCONTACT : depth0)) != null ? stack1.SignatureCO : stack1), depth0))
    + "\" alt=\"signature\" style=\"width: 180px;\"/> "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CURRENTDATE : stack1), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!--\n<table width=\"100%\" id=\"quoteDetails_terms\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.INDIRECTQUOTEID : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":6,"column":9}}})) != null ? stack1 : "")
    + "  <tr><td class=\"title\">Terms and Conditions</td></tr>\n  <tr><td>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.TERMS : stack1), depth0))
    + "</td></tr>\n</table>\n-->\n\n<table width=\"100%\" id=\"quoteDetails_notes\">\n  <tr><td>&nbsp;</td></tr>\n  <tr>\n    <td style=\"width: 60%;\"></td>\n    <td style=\"width: 40%;\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.VARCONTACT : depth0)) != null ? stack1.SignatureCO : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":171}}})) != null ? stack1 : "")
    + "</td>\n  </tr>\n</table>";
},"useData":true});