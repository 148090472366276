














































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import {notifier, downloadFile, printHtml, downloadFileUrl} from "../models/common";
import PortalStatus from "../components/PortalStatus.vue";
import AccountTagsAssignModal from "../components/AccountTagsAssignModal.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import IconDropdown from "@/components/Header/IconDropdown.vue";
import HeaderTabs from "@/components/Header/HeaderTabs.vue";

@Component({
  inheritAttrs: false,
  components: {
    HeaderTabs,
    IconDropdown,
    Pagination,
    PortalStatus,
    ListHeaderActions
  }
})
export default class CustomerPortals extends TSXComponent<void> {
  tab = '1';
  selectedID: (number | undefined)[] = [];
  currentRole = sessionStorage.getItem("userRole");
  ISSEARCH = 0;
  loading = false;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["customerPortals"] ? JSON.parse(sessionStorage.pageDetails)["customerPortals"] : 1;
  items = [];
  details: any = {};
  sort = {
    field : null,
    direction : {
      company_name: 1,
      contact_name: 1,
      email: 1,
      accountID: 1,
      customer: 1,
      totalUsers: 1,
      totalAccounts: 1,
      status: 1
    }
  }
  directionField = "";
  filters: any = {
    company_name: "",
    contact_name: "",
    email: "",
    accountID: "",
    customer: "",
    domain: "",
    selectedStatus: [],
    statusName: []
  }
  searchFilters: any = {
    company_name: "",
    contact_name: "",
    accountID: "",
    domain: ""
  }
  portalStatuses = [
    {
      value: 1,
      label: "Active"
    },
    {
      value: 0,
      label: "Inactive"
    }
  ];
  hasChangedStatusFilters = false;

  async created() {
    this.tab = this.$route.query.tab ? `${this.$route.query.tab}` : '1';
    await this.fetchData(true);
  }

  async fetchData(initialLoad = false) {
    this.loading = true;
    this.hasChangedStatusFilters = false;
		try {
      var reqData = {
        controller      : "CustomerPortals",
        FunctionName    : "List",
        maxRows         : (sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows()),
        PageNumber      : this.pageNumber,
        companyName     : this.filters.company_name,
        contactName     : this.filters.contact_name,
        email           : this.filters.email,
        type             : this.tab,
        accountID       : this.filters.accountID,
        customerName    : this.filters.customer,
        domain          : this.filters.domain
      }
      if(this.sort.field) {
        reqData["order"] = this.sort.field;
        reqData["direction"] = this.sort.direction[this.directionField];
      }
      reqData["status"] = "";
      if (this.filters.selectedStatus.length) {
        reqData['status'] = this.filters.selectedStatus.join(',');
      } else if (!initialLoad && this.filters.selectedStatus.length == 0) {
        reqData['status'] = "-1";
      }
			const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
			}

      if(response.data.STATUS == 1) {
        this.details = response.data;
        this.items = response.data.PORTALSARRAY;
        if (initialLoad && this.portalStatuses.length) {
          this.filters.selectedStatus = this.portalStatuses.map(item => item.value);
          this.filters.statusName = this.portalStatuses.map(item => item.label);
        }
      }
		}catch (err) {
			// console.log(err.message);
		}finally {
			this.loading = false;
		}
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["customerPortals"] ) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["customerPortals"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] = (this.sort.direction[directionField] == 1 ? 2 : 1);
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  searchBy(field) {
    if(this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field];
    this.fetchData();
  }

  resetBy(field) {
    if(this.searchFilters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = this.searchFilters[field] = "";
    this.fetchData();
  }

  updateValue(field) {
    if(this.searchFilters[field] == "") {
      this.pageNumber = 1;
      this.fetchData();
    }
  }

  checkAllStatus() {
    this.pageNumber = 1;
    this.filters.selectedStatus = this.portalStatuses.map(item => item.value);
    this.filters.statusName = this.portalStatuses.map(item => item.label);
    this.fetchData();
  }
  searchByStatus(status: number, name: string) {
    this.pageNumber = 1;
    this.hasChangedStatusFilters = true;
    const i = this.filters.selectedStatus.findIndex(s => s === status);
    const statusIndex = this.filters.statusName.findIndex(
      statusName => statusName === name
    );
    if (i === -1) {
      this.filters.selectedStatus.push(status);
    } else {
      this.filters.selectedStatus.splice(i, 1);
    }
    if (statusIndex === -1) {
      this.filters.statusName.push(name);
    } else {
      this.filters.statusName.splice(statusIndex, 1);
    }
  }
  async uncheckAllStatus() {
    this.pageNumber = 1;
    this.filters.selectedStatus = [];
    this.filters.statusName = [];
    await this.fetchData();
  }
  resetByStatus() {
    this.pageNumber = 1;
    this.filters.selectedStatus = [];
    this.filters.statusName = [];
    this.checkAllStatus();
    // this.fetchData();
  }

  async changeStatus(id, status) {
    try {
      this.loading = true;
      var result = await axios.post(dataURL + "?ReturnType=JSON", {
        controller    : "CustomerPortals",
        FunctionName  : "ChangeStatus",
        status        : status,
        accountID     : id
      });
      if(result.data.STATUS == 1) {
        this.fetchData();
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  getAccountsByItem(item) {
    const ids = item.ACCOUNTID.split(',');
    const names = item.ACCOUNTNAMES.split(',');
    const accounts: any[] = [];
    ids.map((id, index) => {
      accounts.push({
        id: id,
        name: names[index] ? names[index] : '',
      });
    });
    return accounts;
  }

  getFilterValue(header) {
    let filter = '';
    switch (header) {
      case 'STATUS':
        {
          let statusArr = this.portalStatuses.map(item => item.label);
          if (this.filters.statusName && !this.filters.statusName.length)
            filter = "None";
          else if (
            this.filters.statusName &&
            statusArr.length != this.filters.statusName.length
          ) {
            filter = this.filters.statusName.join(", ");
          }
          break;
        }
      default:
        // nothing
        break;
    }
    return filter;
  }
}
