var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.BUILDHEADER : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <tr>\n      <td width=\"50%\" style=\"padding-left: "
    + alias4(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":56}}}) : helper)))
    + "px; font-style: "
    + alias4(((helper = (helper = helpers.FONTSTYLE || (depth0 != null ? depth0.FONTSTYLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FONTSTYLE","hash":{},"data":data,"loc":{"start":{"line":22,"column":72},"end":{"line":22,"column":85}}}) : helper)))
    + ";\">\n        <strong>"
    + ((stack1 = ((helper = (helper = helpers.QPRODDESC || (depth0 != null ? depth0.QPRODDESC : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODDESC","hash":{},"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":31}}}) : helper))) != null ? stack1 : "")
    + "</strong><br/>\n        <span style=\"text-transform: uppercase;\">"
    + ((stack1 = ((helper = (helper = helpers.QPRODSKU || (depth0 != null ? depth0.QPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODSKU","hash":{},"data":data,"loc":{"start":{"line":25,"column":49},"end":{"line":25,"column":63}}}) : helper))) != null ? stack1 : "")
    + "</span>\n      </td>\n      <td width=\"10%\" style=\"text-align: center;\">"
    + ((stack1 = ((helper = (helper = helpers.QPRODQUANTITY || (depth0 != null ? depth0.QPRODQUANTITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODQUANTITY","hash":{},"data":data,"loc":{"start":{"line":27,"column":50},"end":{"line":27,"column":69}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + ((stack1 = ((helper = (helper = helpers.QCUSTOMERPRICEFORMATTED || (depth0 != null ? depth0.QCUSTOMERPRICEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QCUSTOMERPRICEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":28,"column":49},"end":{"line":28,"column":78}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + ((stack1 = ((helper = (helper = helpers.CUSTOMERTOTALPRICEFORMATTED || (depth0 != null ? depth0.CUSTOMERTOTALPRICEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CUSTOMERTOTALPRICEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":29,"column":49},"end":{"line":29,"column":82}}}) : helper))) != null ? stack1 : "")
    + "</td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function";

  return "      <tr>\n        <td width=\"50%\" style=\"padding-left: "
    + container.escapeExpression(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":11,"column":45},"end":{"line":11,"column":58}}}) : helper)))
    + "px; font-style: regular;\">\n          <div style=\"border-bottom: 2px solid #f0f0f0; padding-bottom: 3px;\">\n            <strong style=\"font-size: 12px; font-weight: 600;\">"
    + ((stack1 = ((helper = (helper = helpers.BUILDHEADER || (depth0 != null ? depth0.BUILDHEADER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BUILDHEADER","hash":{},"data":data,"loc":{"start":{"line":13,"column":63},"end":{"line":13,"column":80}}}) : helper))) != null ? stack1 : "")
    + "</strong>\n          </div>\n        </td>\n        <td width=\"10%\"></td>\n        <td width=\"20%\"></td>\n        <td width=\"20%\"></td>\n      </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Estimated Tax:</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.TOTALTAXFORMATTED), depth0))
    + "</td>\n    </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Estimated Shipping:</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.TOTALSHIPPINGFORMATTED), depth0))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"50%\" style=\"border-bottom: 5px solid #b1b1b1;\">LINE ITEM/SKU</td>\n    <td width=\"10%\" style=\"text-align: center; border-bottom: 5px solid #b1b1b1;\">QTY</td>\n    <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">PRICE PER</td>\n    <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">TOTAL PRICE</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.QUOTEITEMS),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" style=\"border-top: 5px solid #b1b1b1;\" colspan=\"2\">\n    </td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\">Customer Subtotal:</td>\n    <td width=\"20%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + alias3(alias2(((stack1 = (data && data.root)) && stack1.TOTALCUSTOMERAMOUNTFORMATTED), depth0))
    + "</td>\n  </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.TOTALTAX),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":2},"end":{"line":51,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.TOTALSHIPPING),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":2},"end":{"line":58,"column":9}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Grand Total:</td>\n    <td width=\"20%\" style=\"text-align: right;\">"
    + alias3(alias2(((stack1 = (data && data.root)) && stack1.GRANDCUSTOMERTOTALFORMATTED), depth0))
    + "</td>\n  </tr>\n</table>";
},"useData":true});