


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { notifier } from "../models/common";
import Pagination from "../components/Pagination.vue";
import HPAgentDetailsRowTooltip from "../components/HPAgentDetailsRowTooltip.vue";
import HPAgentDetailsPCRowTooltip from "../components/HPAgentDetailsPCRowTooltip.vue";
import HPAgentDetailsLinkAccountTooltip from "../components/HPAgentDetailsLinkAccountTooltip.vue";
import { AcctsListResp } from "../models/AcctsListResp";
import InfoTooltip from "../components/InfoTooltip.vue";
import CustomerAdd from "../components/HPAgentDetailsCustomerAddModal.vue";
import PageTitle from '../components/pageTitle.vue';

declare const dataURL: string;
declare const $: any;
declare const getMaxRows : Function;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    HPAgentDetailsRowTooltip,
    HPAgentDetailsPCRowTooltip,
    HPAgentDetailsLinkAccountTooltip,
    InfoTooltip,
    CustomerAdd,
    PageTitle
  }
})
export default class Finances extends TSXComponent<void> {
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["AgentReportsDetails"] ? JSON.parse(sessionStorage.pageDetails)["AgentReportsDetails"] : 1 ;
  loading = false;
  totalPages = 1;
  currentPage = 1;
  items: any = [];
  currentCustAddDetail = [];
  currentCustIndex = -1;
  Boxheight = '0px';
  total = 0;
  totalAll = 0;
  viewType = "all_detail_rows"; //viewType = all_detail_rows | account_grouped | product_grouped | order_linked
  filters = {
    filterByAccount: ""
  };
  sort = {
    field: null,
    direction: {
      all_detail_rows: {
        c_detail_id: 1,
        account_id: 1,
        account: 1,
        product_number: 1,
        product_name: 1,
        earning: 1,
        paycheck: 1,
        allocation: 1
      },
      account_grouped: {
        account_id: 1,
        account: 1,
        line_items: 1,
        sales_total: 1,
        margin_percent: 1,
        total_profit: 1,
        allocation: 1
      },
      product_grouped: {
        sku: 1,
        product_name: 1,
        qty: 1,
        sales_total: 1,
        margin_low: 1,
        margin_high: 1,
        margin_avg: 1,
        total_profit: 1
      }
      // ,
      // order_linked: {
      //   c_detail_id: 1,
      //   account_id: 1,
      //   account: 2,
      //   product_number: 1,
      //   product_name: 1,
      //   earning: 1,
      //   paycheck: 1,
      //   allocation: 1,
      //   poID: 1
      // }
    }
  };

  viewFilters = {
    allrows: {
      MC_CDetail_ID: "",
      accountID: "",
      aName: "",
      sku: "",
      pName: "",
      earnMin: "",
      earnMax: "",
      allocMin: '',
      allocMax: '',
      salesMin: "",
      salesMax: "",
      profitMin: "",
      profitMax: "",
      poID: "",
      unassigned: false,
      selectedAccts: [],
      unverifiedPo: false
    }
  };
  ISSEARCH = 0;
  isSearching = false;
  cOverview = {
    UNASSIGNEDTOTAL: 0,
    AVGALLOCATION_FORMATTED: ""
  };
  acctsInStatement = [];
  subpageTotal = 0;
  acctInfoTooltipVisibleIndex = -1;
  reloadedParent = false;
  unverifyAccSearch = "";
  searchFilters = {
    MC_CDetail_ID: "",
    accountID: "",
    aName: "",
    sku: "",
    pName: "",
    earnMin: "",
    earnMax: "",
    allocMin: '',
    allocMax: '',
    salesMin: "",
    salesMax: "",
    profitMin: "",
    profitMax: "",
    poID: "",
    unassigned: false,
    selectedAccts: []
  };
  remainingBusinessLineComm: any = [];
  businessLines = false;

  async created() {
    if (typeof this.$route.query.viewType == "undefined") {
      this.$router.replace({ query: { viewType: this.viewType } });
    } else {
      this.viewType = this.$route.query.viewType.toString();
    }

    if (typeof this.$route.query.filterByAccount != "undefined") {
      this.filters.filterByAccount = this.$route.query.filterByAccount.toString();
    }

    this.fetchData();
  }

  @Watch("loading")
  async loadingChanged(loading: boolean | "error") {
    if (loading === true) {
      this.allocationTooltipVisibleIndex = -1;
    }
  }
  listPageRedirection(){
    this.$router.push({ name: "HPAgent" });
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["AgentReportsDetails"]){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["AgentReportsDetails"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (this.pageNumber <= this.totalPages && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  viewBy(viewType) {
    this.pageNumber = 1;
    if (
      this.viewType != viewType ||
      (this.viewType == "all_detail_rows" &&
        typeof this.$route.query.filterByAccount != "undefined")
    ) {
      this.viewType = viewType;
      this.$router.replace({ query: { viewType: this.viewType } });

      //reset
      this.items = [];
      this.filters.filterByAccount = "";
      this.sort.field = null;
    }

    this.allocationTooltipVisibleIndex = -1;
    this.linkAccountTooltipVisible_1 = -1;
    this.resetViewFilters();
    this.fetchData();
  }

  viewAllDetailsWithFilter(accountID) {
    this.pageNumber = 1;
    this.viewType = "all_detail_rows";
    this.filters.filterByAccount = accountID;
    this.items = []; //reset

    this.fetchData();
  }

  sortBy(field) {
    this.sort.field = field;
    var tIndex = 0;
    for (const i in this.sort.direction) {
      if (i == this.viewType) {
        for (const j in this.sort.direction[i]) {
          tIndex += 1;
          if (tIndex == field) {
            this.sort.direction[i][j] = this.sort.direction[i][j] == 1 ? 2 : 1;
          } else {
            this.sort.direction[i][j] = 1; //reset
          }
        }
      } else {
        //reset
        for (const j in this.sort.direction[i]) {
          this.sort.direction[i][j] = 1;
        }
      }
    }

    this.fetchData();
  }

  async fetchData() {
    var requestData = {
      controller: "Finances",
      FunctionName: "AgentReportsDetails",
      cs_id: this.$route.params.id,
      maxRows:
        sessionStorage.getItem("maxRows") != ""
          ? sessionStorage.getItem("maxRows")
          : getMaxRows(),
      pageNumber: this.pageNumber,
      viewType: this.viewType,
      filterByAccount: this.filters.filterByAccount,
      viewFilters: JSON.stringify(this.viewFilters)
    };

    if (this.sort.field) {
      requestData["order"] = this.sort.field;

      var tIndex = 0;
      for (const i in this.sort.direction) {
        if (i == this.viewType) {
          for (const j in this.sort.direction[i]) {
            tIndex += 1;
            if (tIndex == this.sort.field) {
              requestData["direction"] = this.sort.direction[i][j];
            }
          }
        }
      }
    }

    this.loading = true;
    try {
      const response = await axios.post(
        dataURL + "?ReturnType=JSON",
        requestData
      );
      if (response.data.VIEWTYPEPRESENTS == true) {
        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
          throw new Error(response.data.STATUSMESSAGE);
        }

        this.items = response.data.COMMISSIONDETAILSARRAY || [];
        this.total = response.data.TOTAL;
        this.totalPages = response.data.TOTALPAGES;
        this.currentPage = response.data.CURRENTPAGE;
        this.isSearching = this.isSearch();
        this.totalAll =
          typeof response.data.TOTALALL != "undefined"
            ? response.data.TOTALALL
            : 0;
        this.cOverview = response.data.COMMISSIONOVERVIEW;
        this.acctsInStatement =
          typeof response.data.ACCTSINSTATEMENT != "undefined"
            ? response.data.ACCTSINSTATEMENT
            : [];
        this.ISSEARCH = response.data.ISSEARCH;
        this.subpageTotal = response.data.SUBPAGETOTAL_FORMATTED;
      } else {
        this.$router.push({ name: "HPAgent" }); //go to agent list page
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  //filters
  allrows_searchByDetailID() {
    this.pageNumber = 1;
    if (this.searchFilters.MC_CDetail_ID == "") {
      return;
    } else {
      this.viewFilters.allrows.MC_CDetail_ID = this.searchFilters.MC_CDetail_ID;
      this.fetchData();
    }
  }
  allrows_searchByAccountID() {
    this.pageNumber = 1;
    if (this.searchFilters.accountID == "") {
      return;
    } else {
      this.viewFilters.allrows.accountID = this.searchFilters.accountID;
      this.fetchData();
    }
  }
  allrows_searchByAccount() {
    this.pageNumber = 1;
    if (this.searchFilters.aName == "") {
      return;
    } else {
      this.viewFilters.allrows.aName = this.searchFilters.aName;
      this.fetchData();
    }
  }
  allrows_searchBySKU() {
    this.pageNumber = 1;
    if (this.searchFilters.sku == "") {
      return;
    } else {
      this.viewFilters.allrows.sku = this.searchFilters.sku;
      this.fetchData();
    }
  }
  allrows_searchByProductName() {
    this.pageNumber = 1;
    if (this.searchFilters.pName == "") {
      return;
    } else {
      this.viewFilters.allrows.pName = this.searchFilters.pName;
      this.fetchData();
    }
  }
  allrows_searchByEarn() {
    if (!this.$validator.errors.items.length) {
      this.pageNumber = 1;
      if (
        (this.searchFilters.earnMin == undefined ||
          this.searchFilters.earnMin == "") &&
        (this.searchFilters.earnMax == "" ||
          this.searchFilters.earnMax == undefined)
      ) {
        return;
      } else {
        this.viewFilters.allrows.earnMin = this.searchFilters.earnMin;
        this.viewFilters.allrows.earnMax = this.searchFilters.earnMax;
        this.fetchData();
      }
    }
  }
  allrows_searchByAlloc() {
    this.pageNumber = 1;
    this.viewFilters.allrows.allocMin = "0";
    this.viewFilters.allrows.allocMax = "99";
    this.fetchData();
  }
  allrows_searchBySales() {
    if (!this.$validator.errors.items.length) {
      this.pageNumber = 1;
      if (
        (this.searchFilters.salesMin == undefined ||
          this.searchFilters.salesMin == "") &&
        (this.searchFilters.salesMax == "" ||
          this.searchFilters.salesMax == undefined)
      ) {
        return;
      } else {
        this.viewFilters.allrows.salesMin = this.searchFilters.salesMin;
        this.viewFilters.allrows.salesMax = this.searchFilters.salesMax;
        this.fetchData();
      }
    }
  }
  allrows_searchByProfit() {
    if (!this.$validator.errors.items.length) {
      this.pageNumber = 1;
      if (
        (this.searchFilters.profitMin == undefined ||
          this.searchFilters.profitMin == "") &&
        (this.searchFilters.profitMax == "" ||
          this.searchFilters.profitMax == undefined)
      ) {
        return;
      } else {
        this.viewFilters.allrows.profitMin = this.searchFilters.profitMin;
        this.viewFilters.allrows.profitMax = this.searchFilters.profitMax;
        this.fetchData();
      }
    }
  }
  allrows_searchByPOID() {
    this.pageNumber = 1;
    if (this.searchFilters.poID == "") {
      return;
    } else {
      this.viewFilters.allrows.poID = this.searchFilters.poID;
      this.fetchData();
    }
  }
  filterByUnassignedAccounts() {
    this.pageNumber = 1;
    this.viewFilters.allrows.selectedAccts = [];
    this.fetchData();
  }

  //reset filters
  allrows_resetSearchByDetailID() {
    this.pageNumber = 1;
    this.viewFilters.allrows.MC_CDetail_ID = this.searchFilters.MC_CDetail_ID = "";
    this.fetchData();
  }
  allrows_resetSearchByAccountID() {
    this.pageNumber = 1;
    this.viewFilters.allrows.accountID = this.searchFilters.accountID = "";
    this.fetchData();
  }
  allrows_resetSearchByAccount() {
    this.pageNumber = 1;
    this.viewFilters.allrows.aName = this.searchFilters.aName = "";
    this.fetchData();
  }
  allrows_resetSearchBySKU() {
    this.pageNumber = 1;
    this.viewFilters.allrows.sku = this.searchFilters.sku = "";
    this.fetchData();
  }
  allrows_resetSearchByProductName() {
    this.pageNumber = 1;
    this.viewFilters.allrows.pName = this.searchFilters.pName = "";
    this.fetchData();
  }
  resetValue(type) {
    if (type == "min") {
      this.viewFilters.allrows.earnMin = this.searchFilters.earnMin = "";
    } else {
      this.viewFilters.allrows.earnMax = this.searchFilters.earnMax = "";
    }
    this.fetchData();
  }
  resetSales(type) {
    if (type == "min") {
      this.viewFilters.allrows.salesMin = this.searchFilters.salesMin = "";
    } else {
      this.viewFilters.allrows.salesMax = this.searchFilters.salesMax = "";
    }
    this.fetchData();
  }
  resetProfit(type) {
    if (type == "min") {
      this.viewFilters.allrows.profitMin = this.searchFilters.profitMin = "";
    } else {
      this.viewFilters.allrows.profitMax = this.searchFilters.profitMax = "";
    }
    this.fetchData();
  }
  allrows_resetSearchByEarn() {
    this.pageNumber = 1;
    this.viewFilters.allrows.earnMin = this.searchFilters.earnMin = "";
    this.viewFilters.allrows.earnMax = this.searchFilters.earnMax = "";
    this.fetchData();
  }
  allrows_resetSearchByAlloc() {
    this.pageNumber = 1;
    this.viewFilters.allrows.allocMin = "";
    this.viewFilters.allrows.allocMax = "";
    this.fetchData();
  }
  allrows_resetSearchBySales() {
    this.pageNumber = 1;
    this.viewFilters.allrows.salesMin = this.searchFilters.salesMin = "";
    this.viewFilters.allrows.salesMax = this.searchFilters.salesMax = "";
    this.fetchData();
  }
  allrows_resetSearchByProfit() {
    this.pageNumber = 1;
    this.viewFilters.allrows.profitMin = this.searchFilters.profitMin = "";
    this.viewFilters.allrows.profitMax = this.searchFilters.profitMax = "";
    this.fetchData();
  }
  allrows_resetSearchByPOID() {
    this.pageNumber = 1;
    this.viewFilters.allrows.poID = this.searchFilters.poID = "";
    this.fetchData();
  }
  //reset all filter values
  resetViewFilters() {
    this.viewFilters = {
      allrows: {
        MC_CDetail_ID: "",
        accountID: "",
        aName: "",
        sku: "",
        pName: "",
        earnMin: "",
        earnMax: "",
        allocMin: "",
        allocMax: "",
        poID: "",
        salesMin: "",
        salesMax: "",
        profitMin: "",
        profitMax: "",
        unassigned: false,
        selectedAccts: [],
        unverifiedPo: false
      }
    };
  }

  isSearch() {
    var ret = false;

    if (this.viewType == "all_detail_rows" || this.viewType == "order_linked") {
      if (
        this.viewFilters.allrows.MC_CDetail_ID != "" ||
        this.viewFilters.allrows.accountID != "" ||
        this.viewFilters.allrows.aName != "" ||
        this.viewFilters.allrows.sku != "" ||
        this.viewFilters.allrows.pName != "" ||
        this.viewFilters.allrows.earnMin != "" ||
        this.viewFilters.allrows.earnMax != "" ||
        this.viewFilters.allrows.allocMin != "" ||
        this.viewFilters.allrows.allocMax != "" ||
        this.viewFilters.allrows.poID != "" ||
        this.viewFilters.allrows.unassigned == true ||
        this.viewFilters.allrows.unverifiedPo
      ) {
        ret = true;
      }
    }

    return ret;
  }

  allocationTooltipVisibleIndex = -1;
  allocationTooltipLoading = false;
  allocationTooltipList = [];
  allocationTooltipaID = 0;
  /* hover event for allocation column */
  async showTooltipAllocation(item, index, e) {
    if (this.allocationTooltipVisibleIndex === index) {
      return;
    }

    var acountID =
      typeof item.MC_CDETAIL_ACCOUNTID != "undefined"
        ? item.MC_CDETAIL_ACCOUNTID
        : "";
    if (this.viewType == "account_grouped") {
      acountID = item.ACCOUNTID;
    }

    if (!acountID) return;
    this.reloadedParent = false;

    this.linkAccountTooltipVisible_1 = -1;
    try {
      this.allocationTooltipLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Finances",
        FunctionName: "GetAssginedPercent",
        accountID: acountID
      });

      if (response.data.STATUS == 1) {
        this.allocationTooltipList = response.data.ASSIGNEDPERCENTARR;
        this.allocationTooltipList.forEach(function(val: any) {
          val.oldPercent = val.PERCENTAGE;
        });
        this.allocationTooltipaID = response.data.aID;
        this.remainingBusinessLineComm = response.data.REMAININGBUSINESSLINECOMM;
        this.businessLines = response.data.COMMISIONBLINES;
        this.allocationTooltipVisibleIndex = index;
      }

      this.allocationTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.allocationTooltipVisibleIndex = -1;
    }
  }
  checkTooltipBottom(index) {
    const tooltipOffsetTop = $(this.$refs[`paycheckId${index}`]).offset().top;
    const tooltipBottomPosition = tooltipOffsetTop - $(window).scrollTop() + 180;
    const windowHeight = $(window).height();
    const distanceFromBottom = windowHeight - tooltipBottomPosition;
    if (distanceFromBottom <= 200) {
      if (!$(this.$refs[`paycheckId${index}`]).parent().hasClass("topTooltip")) {
        $(this.$refs[`paycheckId${index}`]).parent().addClass("topTooltip");
      }
    } else {
      $(this.$refs[`paycheckId${index}`]).parent().removeClass("topTooltip");
    }
  }
  hideTooltipAllocation(item, index) {
    this.allocationTooltipVisibleIndex = -1;
  }
  updateAllocation(info) {
    if (this.viewType == "all_detail_rows") {
      for (var i in this.items) {
        if (
          this.items[i].ACCOUNTMAPPED == 1 &&
          this.items[i].MC_CDETAIL_ACCOUNTID == info.accountID
        ) {
          this.items[i].ALLOCATIONPERCENT = info.allocation;
        }
      }
    } else if (this.viewType == "account_grouped") {
      for (i in this.items) {
        if (
          this.items[i].ACCOUNTMAPPED == 1 &&
          this.items[i].ACCOUNTID == info.accountID
        ) {
          this.items[i].ALLOCATIONPERCENT = info.allocation;
        }
      }
    }

    if (typeof info.totalAllocation != "undefined") {
      this.cOverview.AVGALLOCATION_FORMATTED = info.totalAllocation;
    }

    //case use function "Allocation other than 100%": detect case to reload data
    if (
      !this.reloadedParent &&
      info.allocation == 100 &&
      (this.allocationTooltipVisibleIndex == -1 ||
        this.allocationTooltipVisibleIndex !== info.currentIndex) &&
      this.viewFilters.allrows.allocMin !== "" &&
      this.viewFilters.allrows.allocMax !== ""
    ) {
      this.reloadedParent = true;
      this.allrows_searchByAlloc();
    }
  }

  /* hover event for paycheck column */
  pcTooltipVisibleIndex = -1;
  pcTooltipLoading = false;
  pcTooltipList = [];
  async showTooltipPC(item, index) {
    if (this.pcTooltipVisibleIndex === index) return;
    if (!item.MC_CDETAIL_ID) return;

    this.pcTooltipVisibleIndex = index;

    try {
      this.pcTooltipLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Finances",
        FunctionName: "GetPaychecksAssigned",
        MC_CDETAIL_ID: item.MC_CDETAIL_ID
      });

      if (response.data.STATUS == 1) {
        this.pcTooltipList = response.data.PCTOOLTIPLIST;
      }

      this.pcTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.pcTooltipVisibleIndex = -1;
    }
  }
  hideTooltipPC(item, index) {
    this.pcTooltipVisibleIndex = -1;
  }

  linkAccountTooltipVisible = -1;
  linkAccountTooltipVisible_1 = -1;
  async linkAccount(linkAccountId: string, acc: AcctsListResp.Accountsarr) {
    this.linkAccountTooltipVisible = -1;
    this.linkAccountTooltipVisible_1 = -1;
    this.currentCustIndex = -1;
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "InsertAccountLinking",
        aID: acc.AID,
        LinkAccountId: linkAccountId,
        cs_id: this.$route.params.id
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      await this.fetchData();
      notifier.success(
        "Linked " + linkAccountId + " to " + acc.ACCOUNTID + " - " + acc.ANAME
      );

      if (this.cOverview.UNASSIGNEDTOTAL == 0) {
        this.viewBy("all_detail_rows");
      }
    } catch (err) {
      notifier.alert("search accounts fail: " + err.message);
    } finally {
      this.loading = false;
    }
  }

  filterBySelectedAccts() {
    this.pageNumber = 1;
    this.fetchData();
  }
    // Unverified list
  get filterByAccounts(): any[] {
    let options: { MC_ACCTID?: number; MC_CDETAIL_ACCOUNTNAME?: string;ACCOUNTID?: string;
      ACCOUNTALIAS?: string; }[] = this
      .acctsInStatement;
    if (this.unverifyAccSearch) {
      options = options.filter(
        option =>
          `${option.MC_ACCTID}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.MC_CDETAIL_ACCOUNTNAME}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1
      );
    }
    return options;
  }

  accountsLen(){
    var height = '250px';
    if(this.filterByAccounts.length == 1){
      height = "163px";
      $('.filter-unassigned-box').css("height",height)

    } else if(this.filterByAccounts.length == 2){
      height = "208px";
      $('.filter-unassigned-box').css("height",height)

    }else if(this.filterByAccounts.length == 3){
      height = "245px";
      $('.filter-unassigned-box').css("height",height)
    }else{
      $('.filter-unassigned-box').css("height",height)
    }
    // $('.clickbox.accs').trigger('click');
    // console.log('test',height)
    // setTimeout(return height, 2000)
    // setTimeout(function(){ return height; }, 3000);
    this.Boxheight=height;
  }
}
