




































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import {
  UserPaycheckAPIRepo,
  DetailSortField
} from "../repos/UserPaycheckAPIRepo";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";

const userPaycheckRepo = new UserPaycheckAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    FilterCurrencyBox
  }
})
export default class UserPaycheckDetail extends TSXComponent<void> {
  loading = false;
  UserPaycheckDetail = [];
  pageNumber = 1;
  selectedID: number[] = [];
  totalPages = 1;
  currentPage = 1;
  total = 0;
  totalAll = 0;
  userID = 0;
  paycheckID = 0;
  userInfo = {};
  totalEarn = 0;
  subTotalEarn = 0;
  dateRange = "";
  paycheckOverview = {
    GENERATEDDATE_FORMATTED: "",
    STATUS: 0
  };
  filters = {
    paycheckID: "",
    paycheckName: "",
    poNumber: "",
    saleNo: "",
    sTotalMin: "",
    sTotalMax: "",
    paidTotalMin: "",
    paidTotalMax: ""
  };
  isSearching = false;
  sort = {
    field: "",
    direction: {
      [DetailSortField.paycheckID]: 1,
      [DetailSortField.paycheckName]: 1,
      [DetailSortField.poNumber]: 1,
      [DetailSortField.saleNo]: 1,
      [DetailSortField.total]: 1,
      [DetailSortField.earning]: 1,
      [DetailSortField.comPercent]: 1,
      [DetailSortField.paidTotal]: 1
    }
  };

  async created() {
    if (typeof this.$route.params.id != "undefined") {
      this.userID = parseInt(this.$route.params.id);
    }
    if (typeof this.$route.query.sTotalMin === "string") {
      this.filters.sTotalMin = this.$route.query.sTotalMin;
    }
    if (typeof this.$route.query.sTotalMax === "string") {
      this.filters.sTotalMax = this.$route.query.sTotalMax;
    }
    if (typeof this.$route.query.paidTotalMin === "string") {
      this.filters.paidTotalMin = this.$route.query.paidTotalMin;
    }
    if (typeof this.$route.query.paidTotalMax === "string") {
      this.filters.paidTotalMax = this.$route.query.paidTotalMax;
    }

    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const ret = await userPaycheckRepo.getDetails(
        this.pageNumber,
        this.userID,
        this.paycheckID,
        this.filters,
        this.sort
      );
      this.UserPaycheckDetail = ret.USERPAYCHECKDETAIL;
      this.userInfo = ret.USERINFO;
      this.totalEarn = ret.TOTALEARN_FORMATTED;
      this.subTotalEarn = ret.SUBTOTALEARN_FORMATTED;
      this.dateRange = ret.DATERANGE;
      this.paycheckOverview = ret.PAYCHECKOVERVIEW;
      this.currentPage = ret.CURRENTPAGE;
      this.totalPages = ret.TOTALPAGES;
      this.total = ret.TOTAL;
      this.isSearching = this.isSearch();
      this.totalAll = typeof ret.TOTALALL != "undefined" ? ret.TOTALALL : 0;
      this.loading = false;
    } catch (err) {
      console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.totalPages && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  //filters
  searchBypaycheckID() {
    this.pageNumber = 1;
    this.fetchData();
  }
  searchBypaycheckName() {
    this.pageNumber = 1;
    this.fetchData();
  }
  searchByPONumber() {
    this.pageNumber = 1;
    this.fetchData();
  }
  searchBySaleNo() {
    this.pageNumber = 1;
    this.fetchData();
  }
  searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.fetchData();
  }

  searchByPaidTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.fetchData();
  }

  //reset filters
  resetSearchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = "";
    this.filters.sTotalMax = "";
    this.fetchData();
  }
  resetSearchByPaidTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.paidTotalMin = "";
    this.filters.paidTotalMax = "";
    this.fetchData();
  }
  resetSearchBypaycheckID() {
    this.pageNumber = 1;
    this.filters.paycheckID = "";
    this.fetchData();
  }
  resetSearchBypaycheckName() {
    this.pageNumber = 1;
    this.filters.paycheckName = "";
    this.fetchData();
  }
  resetSearchByPONumber() {
    this.pageNumber = 1;
    this.filters.poNumber = "";
    this.fetchData();
  }
  resetSearchBySaleNo() {
    this.pageNumber = 1;
    this.filters.saleNo = "";
    this.fetchData();
  }

  isSearch() {
    var ret = false;
    if (
      this.filters.paycheckID != "" ||
      this.filters.paycheckName != "" ||
      this.filters.poNumber != "" ||
      this.filters.saleNo != "" ||
      this.filters.sTotalMin != "" ||
      this.filters.sTotalMax != "" ||
      this.filters.paidTotalMin != "" ||
      this.filters.paidTotalMax != ""
    ) {
      ret = true;
    }

    return ret;
  }

  //sort
  sortByPaycheckID() {
    this.sortBy(DetailSortField.paycheckID);
  }
  sortByPaycheckName() {
    this.sortBy(DetailSortField.paycheckName);
  }
  sortBypoNumber() {
    this.sortBy(DetailSortField.poNumber);
  }
  sortBysaleNo() {
    this.sortBy(DetailSortField.saleNo);
  }
  sortByTotal() {
    this.sortBy(DetailSortField.total);
  }
  sortByEarning() {
    this.sortBy(DetailSortField.earning);
  }
  sortByComPercent() {
    this.sortBy(DetailSortField.comPercent);
  }
  sortByPaidTotal() {
    this.sortBy(DetailSortField.paidTotal);
  }
  sortBy(field) {
    this.sort.field = field;
    this.sort.direction[this.sort.field] =
      this.sort.direction[this.sort.field] == 1 ? 2 : 1;
    this.fetchData();
  }
}
