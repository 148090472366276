import { render, staticRenderFns } from "./createDirect.vue?vue&type=template&id=ff8cf100&scoped=true"
import script from "./createDirect.vue?vue&type=script&lang=tsx"
export * from "./createDirect.vue?vue&type=script&lang=tsx"
import style0 from "./createDirect.vue?vue&type=style&index=0&id=ff8cf100&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff8cf100",
  null
  
)

export default component.exports