















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import { FinancialBoardsAPIRepo } from "@/repos/FinancesDashboardAPIRepo";
import { notifier } from "@/models/common";

declare const $: any;

const financialBoardsRepo = new FinancialBoardsAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class ExpensesTypeModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: "" })
  boardUUID!: string;

  @Prop({ required: false, default: "" })
  expensesTypeName!: string;

  @Prop({ required: false, default: "" })
  expenseTypeUUID!: string;

  $validator: any;
  saving: boolean = false;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async save() {
    var result = await this.$validator.validateAll();
		if (!result) {
			return;
		}
    
    if (this.boardUUID.length) {
      this.saving = true;
  
      const response: any = await financialBoardsRepo.addBoardExpensesType(
        this.boardUUID,
        this.expensesTypeName,
        this.expenseTypeUUID
      );
  
      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.$emit("loadExpenseType", {
          expensesTypeName  : this.expensesTypeName,
          expenseTypeUUID   : response.EXPENSETYPEUUID || '',
          action            : this.expenseTypeUUID.length ? 'update' : 'add'
        });
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.saving = false;
    }
  }

}
