var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " - "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER), depth0))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.LOGOSRC)) && stack1.length),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":14},"end":{"line":25,"column":21}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <td align=\"right\" colspan=\"2\" style=\"border-radius: 50%; padding-right: 30px;\"><img src="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.LOGOSRC), depth0))
    + " style=\"height: 75px;\"/></td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showCustomerName),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":28,"column":12}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <td align=\"right\" colspan=\"2\" style=\"border-radius: 50%; padding-right: 30px; font-size: 25px; font-weight: 600;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "</td>\n            ";
},"9":function(container,depth0,helpers,partials,data) {
    return "              <td align=\"right\" colspan=\"2\"><span style=\"padding: 10px; background-color: #cdcdcd; text-align:center; color: white;\">Customer Indicator</span></td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"quoteDetails_header\">\n  <tr>\n    <td style=\"padding-right: 5px;\">\n      <img src=\"https://s3.amazonaws.com/smartstoresgeneric/mail/HP_logo_remote.png\" width=\"63\" height=\"63\"\n        style=\"display:block; border: 0;\" border=\"0\" />\n    </td>\n    <td colspan=\"2\"><img src=\"https://smartstoresgeneric.s3.amazonaws.com/mail/milestonetechlogo.png\" alt=\"MilestoneTech\" style=\"display:block; border: 0; float: right; height: 52px;\" border=\"0\" /></td>\n  </tr>\n  <tr>\n    <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 20px;letter-spacing: 1.7px;\">\n      <strong>HP INC.</strong><br />\n      14231 Tandem Blvd, Austin, Tx 78728\n    </td>\n  </tr>\n  <tr>\n    <td colspan=\"3\">\n      <table width=\"100%\">\n        <tr>\n          <td style=\"padding: 10px 0;font-size: 26px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\"><span\n              style=\"color: #0196d5;\">QUOTE</span> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEID), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER)) && stack1.length),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":82},"end":{"line":20,"column":188}}})) != null ? stack1 : "")
    + "</td>\n          <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showLogo),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showTempIndicator),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n      </table>\n    </td>\n  </tr>\n</table>";
},"useData":true});