




















































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import DropdownControl from "../components/DropdownControl.vue";
import LaddaButton from "../components/LaddaButton.vue";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    DropdownControl,
    LaddaButton
  }
})
export default class ContactTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "" })
  addNewType?: string;

  @Prop({ required: false, default: 0 })
  aID?: number;

  $parent: any;
  loading = false;
  name = "";
  mail = "";
  isChecked = true;

  clickOutside() {
    this.$emit("close");
  }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  async addContact() {
    var result = await this.$validator.validateAll();
    if(result) {
      this.loading = true;
      await this.$emit("addNewContact", [{
        "name": this.name,
        "mail": this.mail,
        "phone": '',
        "aContactPhoneExt": '',
        "ISDEFAULT": 0,
        "id": 0,
        "ACONTACTTYPE": this.addNewType == 'endUser' ? 1 : 2,
      }]);
      this.loading = false;
    }
  }
}
