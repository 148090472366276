














































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { FinancesDashboardAPIRepo, Sort, SortField } from "../repos/FinancesDashboardAPIRepo";
import InfoTooltip from "../components/InfoTooltip.vue";

declare const require: any;
const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var startPoint = new Date().getMonth();
var lastYear:string[] = [];
var Curryear:string[] = [];
var Currmon:string[] = [];
var lastmon:string[] = [];
for (var i = 0; i < 12; i++) {
	var mon = allMonths[i].substring(0,3);
	if(startPoint < i){
		lastYear.push(mon+", "+( new Date().getFullYear() - 1));
		lastmon.push(allMonths[i]);
	}
	else{
		Curryear.push(mon+", "+(new Date().getFullYear()));
		Currmon.push(allMonths[i]);
	}
}
const last12 = lastYear.concat(Curryear)
const last12months = lastmon.concat(Currmon)

const financesDashboardRepo = new FinancesDashboardAPIRepo();
@Component({
  inheritAttrs: false,
  components: {
		InfoTooltip,
	}
  
})
export default class FinancesDashboard extends TSXComponent<void> {
  sort: Sort = {
    field: null,
    direction: {
      [SortField.Name]: 1,
      [SortField.Profit]: 1
    }
  };
  
  loading = false;
  loadingYear = false;
  sortingYear = new Date().getFullYear();
  detail = {
    YEARS: [this.sortingYear],
    STATUS: 0,
    DASHBOARDDATA: {
      name: '',
      data:[]
    },
    MAXPROFIT: 0,
    ORDERSTOTALPROFIT:[]
  };
  filters = {
    searchName: '',
  }
  years = [];
  accountTags = [];
  tagIDs: number[] = [];
  gTagIDs: number[] = [];
  groupTagIDs: number[] = [];
  selectedTags = [];
  dashView = "CurrentUsers";
  CurrentView = "My Finances Dashboard";
  CurrentHeader = "My Finances Dashboard";
  selectedView = false;
  salesLineChartData = [
    {
      name: '',
      type: 'bar',
      data: []
    }
  ];
  // viewType = "CurrentUsers";
  barOptions ={
		chart: {
      type: 'line',
      height: 350,
      stacked: true,
      // stackType: '100%'
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      toolbar: { show: false },
			zoom: { enabled: false },
    },
    stroke: {
      curve: "smooth",
      width: 3,
      dashArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 7]
    },
    markers: {
      size: 6,
      fillOpacity: 0,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
		xaxis: {
		  categories: this.categories(),
			tooltip: {
				enabled: false,
			},
			axisTicks: {
				show: true,
				height: 11,
			},
      labels: {
				show: true,
        style: {
					colors:"#656468",
          fontFamily: 'Work Sans, sans-serif',
          fontWeight: 600,
          fontSize: '10px',
        },
      }
		},
    yaxis: {
      labels: {
        formatter: function(value) {
          var formatedAmount = value;
          if (!isFinite(value)) {
            formatedAmount = 1;
          }
          else if(!isNaN(value) && value != 0) {
            formatedAmount = value.toFixed(2);
          }
          return formatedAmount;
        }
      },
      max: 0,
      forceNiceScale: true
    },
    fill: {
      opacity: 1
    },
    colors: ['#0096D6', '#00548B', '#005758', '#D11C22', '#41C363', '#3F71C0', '#B6B6B4', '#708090', '#98AFC7', '#3c3564'],
    legend: {
      position: 'top',
      horizontalAlign: 'right', 
      offsetX: 50,
      offsetY: 0
    },
    tooltip: {
			shared: true,
			intersect: false,
			x: {
				formatter: function (value) {
					return value;
				},
			},
			y: {
				formatter: function (value) {
					var formatedAmount = value;
					if (!isNaN(value) && value != 0) {
						value = value.toFixed(2);
            if (value > 0)
						  formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            else
              formatedAmount = "($" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/-/,"") + ")";
					}
					return formatedAmount;
				},
			},
		},
    dataLabels: {
      formatter: function (value) {
        var formatedAmount = value;
        if (!isNaN(value) && value != 0) {
          value = value.toFixed(2);
          if (value > 0)
            formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          else
            formatedAmount = "($" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/-/,"") + ")";
        }
        return formatedAmount;
      },
      enabledOnSeries: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      style: {
        fontSize: '10px',
      }
    },
		grid: { show: false },
  };

  profitInfoModal = -1;
  pageNumber = 1;
  orderTotalProfit = [];
  showPagination = false;
  
  async created() {
    await this.fetchData(this.sortingYear);
  }
  async fetchData(year) {
    this.sortingYear = year;
    this.loading = true;
    this.loadingYear = true;
    var dataObj = {
      controller: "Finances",
      FunctionName: "Dashboard",
      fYear: this.sortingYear,
      searchName: this.filters.searchName,
      tagIDs: this.tagIDs.join(),
      gTagIDs: this.gTagIDs.join(),
      groupTagIDs: this.groupTagIDs.join(),
      view: this.dashView,
      selectedView: this.selectedView
    };
    if (this.sort.field) {
      dataObj["order"] = this.sort.field;
      dataObj["direction"] = this.sort.direction[this.sort.field].toString();
    }
    const resultFinancesDashboard = await financesDashboardRepo.fetchData(
      dataObj
    );
    try {
      this.detail = resultFinancesDashboard;
      this.loading = false;
      this.loadingYear = false;
      this.orderTotalProfit = [...this.detail.ORDERSTOTALPROFIT];
      this.getOrdersTotalProfit();
      
      // this.barOptions.xaxis.categories = this.detail.DASHBOARDDATA.CHARTLABEL;
      var fullMonths: string[] = [];
      var monthNames: string[] = [];
      var salesLineChartData1: any[] = [];
      var enabledOnSeries: number[] = [];
      var dashArray: number[] = [];
      monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
      ];
      for (const month in monthNames) {
        if (isNaN(this.sortingYear)) {
						fullMonths.push(monthNames[month] + "-" + last12[month].split(", ")[1]);
					} else {
						fullMonths.push(monthNames[month] + "-" + this.sortingYear);
					}
      }
      this.barOptions.xaxis.categories = fullMonths;
      this.barOptions.yaxis.max = Math.ceil(this.detail.MAXPROFIT);
      if (this.detail.STATUS && this.detail.STATUS == 1) {
        var $this = this;
        $.each($this.detail.DASHBOARDDATA, function(i, val) {
          salesLineChartData1.push({
            name: val.NAME,
            type: val.TYPE,
            data: val.DATA
          });
          if (val.TYPE != 'line') {
            enabledOnSeries.push(i);
            dashArray.push(0);
          } else{
            dashArray.push(7);
          }
        });
        this.salesLineChartData =salesLineChartData1;
        this.accountTags = resultFinancesDashboard.accountTags;
        this.barOptions.dataLabels.enabledOnSeries = enabledOnSeries;
        this.barOptions.stroke.dashArray = dashArray;
      }
      if (
        !this.selectedView &&
        typeof this.detail["userSelectedCurrentView"] != "undefined"
      ) {
        this.selectedView = true;
        this.dashView = this.detail["userSelectedCurrentView"];
        this.CurrentView = this.CurrentHeader =
          this.dashView == "All" ? "Company Finances Dashboard" : "My Finances Dashboard";
      }
    } catch (e) {
      //handle error
    }
  }
  async sortingByName() {
    this.sortBy(SortField.Name);
  }
  async sortingByProfit() {
    this.sortBy(SortField.Profit);
  }
  async searchByName() {
    if (this.filters.searchName == "") {
      return;
    } else {
      await this.fetchData(this.sortingYear);
    }
  }
  async resetSearchByName() {
    this.filters.searchName = "";
    await this.fetchData(this.sortingYear);
  }
  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData(this.sortingYear);
  }
  selectView(item, e) {
    if (isNaN(item.ACCOUNTTAGID)) {
        // selected my customers / company customers
        this.tagIDs = [];
        this.gTagIDs = [];
        this.groupTagIDs = [];
        this.selectedTags = []; // reset
        this.CurrentView = item.TAGNAME;
        this.dashView = item.ACCOUNTTAGID;
        this.selectedView = true;
        this.CurrentHeader = item.TAGNAME;
        this.fetchData(this.sortingYear);
    } else {
        e.stopPropagation();
    }
  }

  async filterByTag() {
    // selected a tag/gtag
    this.tagIDs = [];
    this.gTagIDs = [];
    this.groupTagIDs = [];
    if (this.selectedTags.length) {
        this.selectedView = true;
        this.CurrentView = this.getCurrentViewTitle();
        this.CurrentHeader = this.getCurrentViewTitle();
        this.dashView = "1"; //just set a number
        for (let i in this.selectedTags) {
        let item: any = this.selectedTags[i];
        if (item.ISGLOBALTAG) {
          this.gTagIDs.push(item.GTAGID);
        } else if (item.ISGROUP) {
          this.groupTagIDs.push(item.ACCOUNTTAGID);
        } else {
          this.tagIDs.push(item.ACCOUNTTAGID);
        }
      }
    } else {
        // reset
        this.selectedView = false;
        this.CurrentHeader = '';
    }
    await this.fetchData(this.sortingYear);
  }
  getCurrentViewTitle() {
    if (this.selectedTags.length) {
        return this.selectedTags.map((tmp: any) => tmp.TAGNAME).join(", ");
    }
    return "";
  }

  async yearSorting(year) {
    this.loadingYear = true;
    this.sortingYear = year;
    await this.fetchData(year);
    this.loadingYear = false;
  }

  // async toggleCustomView(currStatus, year) {
  //   this.viewType = currStatus;
  //   this.sortingYear = year;
  //   await this.fetchData(year);
  // }

  categories(){
		var monthNames;
		monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];		
		var fullMonths:string[] = [];
		for (const month in monthNames) {
			if (isNaN(this.sortingYear)) {
				fullMonths.push(monthNames[month] + "-" + last12[month].split(", ")[1]);
			} else {
				fullMonths.push(monthNames[month] + "-" + this.sortingYear);
			}
		}
		return fullMonths;
	}

  async loadPage(page: number, type: string) {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if (this.pageNumber) {
      await this.getOrdersTotalProfit();
    }
  }

  async getOrdersTotalProfit(){
    let tempOrderTotalProfit = this.orderTotalProfit;
    let startRow = (this.pageNumber - 1) * 8;
    let endRow = this.pageNumber * 8;
    this.showPagination = tempOrderTotalProfit.length <= endRow ? false : true;
    this.detail.ORDERSTOTALPROFIT = tempOrderTotalProfit.slice(startRow, endRow);
  }
}
