













import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";

import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";

interface Props {
  defaultText?: string
  value: string
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
})
export default class UserAutoSuggestInput extends TSXComponent<Props> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  disabled?: boolean
  
  @Prop({ required: false, type: Number })
  customerId?: number

  @Prop({ required: false, type: String })
  fields?: string

  @Prop({ required: false, type: String })
  fieldPass?: string

  @Prop({ required: false, type: String })
  placeholderPass?: string

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string
  $parent: any;

  options: Option[] = []
  searchData = []

  createOptions(Users) {
    const options = Users.map(
      (item): Option => {
        return {
          value: item.USERID.toString(),
          text: item.UEMAIL
        };
      }
    )

    this.options = options
    this.searchData = Users

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!search) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        "controller": "Helpers",
        "FunctionName": "Users",
        "ReturnType": "json",
      });
      if (response.data.ERROR || !response.data.USERS) {
        return {
          options: []
        };
      }
      return this.createOptions(response.data.USERS)
    }

    const exportObj = {
      controller: "Helpers",
      FunctionName: "Users",
      ReturnType: "json",
      search,
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    if (response.data.ERROR || !response.data.USERS) {
      return {
        options: []
      };
    }
    return this.createOptions(response.data.USERS)
  };

  onBlur(data) {
    if (data.search && !data.value) {
      this.$parent.uEmail = data.search;
    }
  }

  onInput(value: string) {
    this.$emit('input', value)
    
    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      return
    }
    const User = this.searchData.find((item: any) => item.USERID.toString() === value)
    const allOption = this.searchData
    if (!User) {
      console.warn(`no user found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      User,
      allOption
    })
  }
}
