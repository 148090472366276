var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderHardwares os-font page-list",class:{
    hasHardwareFilters:
      _vm.$parent.hardwareFilters.filtered.assetNumber ||
      _vm.$parent.hardwareFilters.filtered.assetTag
  }},[_c('div',{staticClass:"page-list-container"},[_c('span',{staticClass:"expand-full"}),(_vm.isLoading)?_c('div',{staticClass:"spinner2",attrs:{"id":"spinner2"}},[_c('Loader',{staticClass:"loader"})],1):_vm._e(),(_vm.hardwareView == 'grouped')?[_c('table',[_vm._m(0),_c('tbody',_vm._l((_vm.items),function(item,key){return _c('tr',{key:("hardware-item-" + key),attrs:{"data-poliids":item.POLIIDS || ''}},[_c('td',[_c('div',{staticClass:"line-item-sku"},[_c('span',{staticClass:"tmp-name textOverflow",attrs:{"title":item.HDESCRIPTION}},[_vm._v(" "+_vm._s(item.HDESCRIPTION)+" ")]),_c('span',{staticClass:"tmp-sku",attrs:{"title":item.HARDWARESKU}},[_vm._v(" "+_vm._s(item.HARDWARESKU)+" ")])])]),_c('td',{staticClass:"text-center"},[(
                  item.DELIVEREDTOTAL ||
                    (!item.DELIVEREDTOTAL && !item.SHIPPEDTOTAL)
                )?_c('div',[_c('div',[_vm._v("Delivered")]),(item.SKUQTY)?_c('div',[_vm._v(" ("+_vm._s(item.DELIVEREDTOTAL || 0)+"/"+_vm._s(item.SKUQTY)+") ")]):_vm._e()]):(item.SHIPPEDTOTAL)?_c('div',[_c('div',[_vm._v("Shipped")]),(item.SKUQTY)?_c('div',[_vm._v(" ("+_vm._s(item.SHIPPEDTOTAL || 0)+"/"+_vm._s(item.SKUQTY)+") ")]):_vm._e()]):_vm._e()]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(item.SKUQTY)+" ")])]),_c('td',{staticClass:"text-center"},[(item.QUANTITY > 0)?[(
                    !_vm.$allowedFunctions.includes(_vm.$userFunctions.Hardware_List)
                  )?[_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(item.QUANTITY)+" ")])]:_c('router-link',{attrs:{"to":{
                    name: 'Hardware',
                    query: {
                      sku: item.HARDWARESKU,
                      purchaseId: item.PURCHASEID,
                      poId: item.POID,
                      view: 'All',
                      showVar360Order: 1
                    }
                  }}},[_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(item.QUANTITY)+" ")])])]:_c('span',{staticClass:"count"},[_vm._v("0")])],2),_c('td',[_c('div',{staticClass:"tmp-name textOverflow",attrs:{"title":item.CATEGORYNAME}},[_vm._v(" "+_vm._s(item.CATEGORYNAME)+" ")])]),_c('td',{staticClass:"textOverflow",attrs:{"title":(item.PRODUCTLINE || '').toUpperCase()}},[_vm._v(" "+_vm._s((item.PRODUCTLINE || '').toUpperCase())+" ")]),_c('td',{staticClass:"indentifiers"},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" Serial Numbers/Asset Tags: ")]),((item.hardwares || []).length)?_c('span',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_vm._l((item.hardwares),function(hardware,hIndex){return [(hIndex < 1)?_c('span',{key:hIndex,on:{"click":function($event){!_vm.$allowedFunctions.includes(
                        _vm.$userFunctions.Hardware_List
                      )
                        ? ''
                        : _vm.$emit('showHardwareDetails', hardware)}}},[_c('span',{staticClass:"tmp-tags tag-underline",class:{
                        'cursor-auto': !_vm.$allowedFunctions.includes(
                          _vm.$userFunctions.Hardware_List
                        )
                      }},[_vm._v(_vm._s(_vm.getHardwareSerialNumber( hardware.ASSETNUMBER, hardware.ASSETTAG )))]),(hIndex !== item.hardwares.length - 1)?_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(" , ")]):_vm._e()]):_vm._e()]}),(item.hardwares.length > 1)?_c('span',{on:{"mouseleave":function () {
                        _vm.hardwareItemTooltipIndex = -1;
                      }}},[_c('span',{staticClass:"d-flex align-items-center justify-content-center position-relative"},[_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',{staticClass:"planCountPos"},[_c('span',{staticClass:"planCountColor cursorPointer text-grey",on:{"mouseenter":function () {
                                _vm.hardwareItemTooltipIndex = key;
                              }}},[_vm._v(" +"+_vm._s(item.hardwares.length - 1)+" more ")])]),(_vm.hardwareItemTooltipIndex === key)?_c('ContentToolTip',{on:{"close":function () {
                              _vm.hardwareItemTooltipIndex = -1;
                            }}},[_c('template',{slot:"content"},[_c('perfect-scrollbar',{staticClass:"ps pr-4",staticStyle:{"max-height":"250px"},attrs:{"options":{
                                  wheelPropagation: false,
                                  suppressScrollX: true,
                                  maxScrollbarLength: 50
                                }}},[_vm._l((item.hardwares),function(hardware,hIndex){return [(hIndex > 0)?_c('span',{key:hIndex,on:{"click":function($event){!_vm.$allowedFunctions.includes(
                                    _vm.$userFunctions.Hardware_List
                                  )
                                    ? ''
                                    : _vm.$emit('showHardwareDetails', hardware)}}},[_c('span',{staticClass:"tmp-tags tag-underline",class:{
                                    'cursor-auto': !_vm.$allowedFunctions.includes(
                                      _vm.$userFunctions.Hardware_List
                                    )
                                  }},[_vm._v(_vm._s(_vm.getHardwareSerialNumber( hardware.ASSETNUMBER, hardware.ASSETTAG )))]),(hIndex !== item.hardwares.length - 1)?_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(" , ")]):_vm._e()]):_vm._e()]})],2)],1)],2):_vm._e()],1)])]):_vm._e()],2):_c('span',{staticClass:"tmp-tags text-grey"},[_vm._v("N/A")]),(_vm.currentRole === 'Reseller')?_c('span',{staticClass:"badge ml-3 bg-light text-dark border",on:{"click":function($event){return _vm.addIdentifier(item)}}},[_vm._v(" Add Hardware/Ids ")]):_vm._e()])])])}),0)]),(_vm.items.length == 0 && !_vm.isLoading)?_c('div',{staticClass:"noData"},[_vm._v(" No Hardwares Available ")]):_vm._e()]:(_vm.hardwareView == 'details')?[_c('table',[_c('thead',{staticClass:"no-resize"},[_c('tr',[_vm._m(1),_vm._m(2),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('div',{staticClass:"left noOverflow"},[_vm._v(" Serial Number ")]),(
                    !_vm.isLoading && _vm.$parent.hardwareFilters.filtered.assetNumber
                  )?_c('div',{staticClass:"filterInfo"},[_c('span',{staticClass:"text-truncate d-block",attrs:{"title":_vm.$parent.hardwareFilters.assetNumber},domProps:{"innerHTML":_vm._s(_vm.$parent.hardwareFilters.assetNumber)}}),_c('span',{staticClass:"resetSearch",attrs:{"title":"Reset filter"},on:{"click":function($event){return _vm.resetSearchBy('assetNumber')}}},[_c('svg',{attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.5 2.5L2.5 7.5","stroke":"#AEADB1","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M2.5 2.5L7.5 7.5","stroke":"#AEADB1","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]):_vm._e(),_c('div',{staticClass:"left"},[_c('span',{staticClass:"searchContainer right clickbox"},[_c('div',{attrs:{"id":"searchBox"}},[_c('div',{attrs:{"id":"searchBox-container"}},[_c('div',{attrs:{"id":"searchInput"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$parent.hardwareFilters.assetNumber),expression:"$parent.hardwareFilters.assetNumber",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":"Search Serial Number","autocomplete":"off"},domProps:{"value":(_vm.$parent.hardwareFilters.assetNumber)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchBy('assetNumber')},"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$parent.hardwareFilters, "assetNumber", $event.target.value.trim())},function($event){_vm.$parent.hardwareFilters.assetNumber == ''
                                ? _vm.resetSearchBy('assetNumber')
                                : ''}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"search"}},[_c('span',{staticClass:"searchBtn right"},[_vm._v("Close")]),_c('span',{staticClass:"reset-list right",style:(_vm.$parent.hardwareFilters.assetNumber
                                  ? 'cursor:pointer'
                                  : 'cursor:not-allowed'),on:{"click":function($event){_vm.$parent.hardwareFilters.assetNumber
                                  ? _vm.resetSearchBy('assetNumber')
                                  : ''}}},[_vm._v("Reset")]),_c('span',{staticClass:"search-list right",style:(_vm.$parent.hardwareFilters.assetNumber
                                  ? 'cursor:pointer'
                                  : 'cursor:not-allowed'),on:{"click":function($event){_vm.$parent.hardwareFilters.assetNumber
                                  ? _vm.searchBy('assetNumber')
                                  : ''}}},[_vm._v("Search")])])])])])])])])]),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('div',{staticClass:"left noOverflow"},[_vm._v(" Asset Tag ")]),(
                    !_vm.isLoading && _vm.$parent.hardwareFilters.filtered.assetTag
                  )?_c('div',{staticClass:"filterInfo"},[_c('span',{staticClass:"text-truncate d-block",attrs:{"title":_vm.$parent.hardwareFilters.assetTag},domProps:{"innerHTML":_vm._s(_vm.$parent.hardwareFilters.assetTag)}}),_c('span',{staticClass:"resetSearch",attrs:{"title":"Reset filter"},on:{"click":function($event){return _vm.resetSearchBy('assetTag')}}},[_c('svg',{attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.5 2.5L2.5 7.5","stroke":"#AEADB1","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M2.5 2.5L7.5 7.5","stroke":"#AEADB1","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]):_vm._e(),_c('div',{staticClass:"left"},[_c('span',{staticClass:"searchContainer right clickbox"},[_c('div',{attrs:{"id":"searchBox"}},[_c('div',{attrs:{"id":"searchBox-container"}},[_c('div',{attrs:{"id":"searchInput"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$parent.hardwareFilters.assetTag),expression:"$parent.hardwareFilters.assetTag",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":"Search Asset Tag","autocomplete":"off"},domProps:{"value":(_vm.$parent.hardwareFilters.assetTag)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchBy('assetTag')},"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$parent.hardwareFilters, "assetTag", $event.target.value.trim())},function($event){_vm.$parent.hardwareFilters.assetTag == ''
                                ? _vm.resetSearchBy('assetTag')
                                : ''}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"search"}},[_c('span',{staticClass:"searchBtn right"},[_vm._v("Close")]),_c('span',{staticClass:"reset-list right",style:(_vm.$parent.hardwareFilters.assetTag
                                  ? 'cursor:pointer'
                                  : 'cursor:not-allowed'),on:{"click":function($event){_vm.$parent.hardwareFilters.assetTag
                                  ? _vm.resetSearchBy('assetTag')
                                  : ''}}},[_vm._v("Reset")]),_c('span',{staticClass:"search-list right",style:(_vm.$parent.hardwareFilters.assetTag
                                  ? 'cursor:pointer'
                                  : 'cursor:not-allowed'),on:{"click":function($event){_vm.$parent.hardwareFilters.assetTag
                                  ? _vm.searchBy('assetTag')
                                  : ''}}},[_vm._v("Search")])])])])])])])])]),_vm._m(3)])]),_c('tbody',_vm._l((_vm.itemDetails),function(item,index){return _c('tr',{key:index,attrs:{"data-poliid":item.POLIID}},[_c('td',[_vm._v(" "+_vm._s(item.HARDWARESKU)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.HSTATUS)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.ASSETNUMBER)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.ASSETTAG)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.HARDWAREID)+" ")])])}),0)]),(_vm.itemDetails.length == 0 && !_vm.isLoading)?_c('div',{staticClass:"noData"},[_vm._v(" No Hardwares Available ")]):_vm._e()]:_vm._e()],2),(_vm.addIdentifiersModalVisible)?_c('AddIdentifiers',{attrs:{"title":'Add Identifiers',"data":_vm.identifierData,"quantity":_vm.hardwareQuantity,"hardwareDetails":_vm.hardwareDetails,"aId":_vm.$parent.details.ORDERDETAILS.AID,"loading":_vm.hardwaresLoading,"showAdvanced":true},on:{"close":function($event){_vm.addIdentifiersModalVisible = false},"updateHardwares":function($event){return _vm.$emit('updateHardwares')},"reloadOrder":_vm.reloadOrder}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"no-resize"},[_c('tr',[_c('th',{staticClass:"list-tab headerName"},[_c('div',{staticClass:"head-item"},[_vm._v("Line item / SKU")])]),_c('th',{staticClass:"list-tab headerStatus"},[_c('div',{staticClass:"head-item"},[_vm._v("Status")])]),_c('th',{staticClass:"list-tab headerQuantity"},[_c('div',{staticClass:"head-item"},[_vm._v("ORDER QUANTITY")])]),_c('th',{staticClass:"list-tab headerQuantity"},[_c('div',{staticClass:"head-item"},[_vm._v("Hardware ID")])]),_c('th',{staticClass:"list-tab headerCat"},[_c('div',{staticClass:"head-item"},[_vm._v("Category")])]),_c('th',{staticClass:"list-tab headerCat"},[_c('div',{staticClass:"head-item"},[_vm._v("Product Line")])]),_c('th',{staticClass:"list-tab headerIdentifiers"},[_c('div',{staticClass:"head-item"},[_vm._v("IDENTIFIERS")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_vm._v("SKU")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_vm._v("Status")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_vm._v("Hardware ID")])])}]

export { render, staticRenderFns }