































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier, wait } from "../models/common";
declare const getImageRouteData: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class HardwareImportListing extends TSXComponent<void> {
  totalRows = 20;
  totalPages = 1;
  selectedRows: object[] = [];
  currentPage = 1;
  starting = 0;
  selectedID: number[] = [];
  importedRows: object[] = [];
  excelData: object[] = [];
  headers: string[] = [];
  allHeaders: string[] = [];
  details = [];
  productDetails: {
    [key: string]: {
      detail?: string;
      description?: string;
    };
  } = {};
  assetNumber = "";
  sku = "";
  category = "";
  price = "$";
  warrentyexp = "";
  categories = "";
  length = 2;
  imageLength = 1;
  images = {};
  randId = Date.now();
  removed: string[] = [];
  hardwareImages: string[] = [];
  saving = false;
  loading = false;
  headerContent = false;
  items: {
    HARDWAREIMAGES?: string;
    HARDWAREID?: number;
  } = {};

  mounted() {
    this.totalPages = Math.ceil(this.excelData.length / this.totalRows);
    for (var i = 0; i < this.totalRows; i++) {
      if (this.excelData[i]) this.selectedRows.push(this.excelData[i]);
    }
    for (var item in this.excelData) {
      for (var header in this.headers) {
        if (this.excelData[item][this.headers[header]] === undefined) {
          this.excelData[item][this.headers[header]] = "";
        }
        if (this.headers[header] == "Warrenty Exp") {
          var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
          var x = this.excelData[item]["Warrenty Exp"];
          if (!date_regex.test(x)) {
            var date = new Date(
              Math.round(
                (this.excelData[item][this.headers[header]] - 25569) *
                  86400 *
                  1000
              )
            );
            this.excelData[item]["Warrenty Exp"] =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
          }
        }
      }
    }
  }
  created() {
    if (this.$route.params.id) {
      this.grabViewHardwareData();
    }
  }

  grabViewHardwareData() {
    var dataObj = {
      controller: "Hardware",
      Content: "Detailed",
      ObjID: this.$route.params.id
    };

    if (parseInt(this.$route.params.id) != 0) {
      dataObj["FunctionName"] = "View";
      var container = this;
      var hardwareViewData = getRouteData(dataObj);
      hardwareViewData
        .then(function(response, statusText, jqXHR) {
          try {
            if (response.data.STATUS && response.data.STATUSMESSAGE) {
              container.items = response.data.HARDWAREDETAILS || [];
              container.loading = false;
              container.productDetails =
                response.data.HARDWAREPRODUCTDETAILS || [];
              var images = container.items.HARDWAREIMAGES
                ? container.items.HARDWAREIMAGES.split(",")
                : [];
              for (var image in images) {
                images[image] =
                  dataURL +
                  "Inbound/apiFiles/images/" +
                  container.items.HARDWAREID +
                  "/" +
                  images[image];
              }
              container.hardwareImages =
                container.items.HARDWAREIMAGES != "" ? images : [];
              container.details = response.data;
            } else if (!response.data.STATUS && response.data.STATUSMESSAGE) {
              notifier.warning(response.data.STATUSMESSAGE);
            } else if (response.data.ERROR) {
              console.log(response.data.ERROR);
            }
          } catch (e) {
            //handle error
            console.log("hardware.js : grabHardwareData error : ", e);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
    // container.items = [{
    //     assetNumber: '',
    //     sku: '',
    //     category: '',
    //     price: '',
    //     warrentyexp: ''
    // }]
  }
  // methods: {
  loadNextPage(startOffSet) {
    this.selectedRows = [];
    this.starting = (startOffSet - 1) * this.totalRows;
    this.currentPage = startOffSet;
    for (var i = this.starting; i < this.starting + this.totalRows; i++) {
      if (this.excelData[i]) this.selectedRows.push(this.excelData[i]);
    }
    this.starting = 0;
  }
  handleImport() {
    if (this.selectedID.length > 0)
      for (var i = 0; i < this.excelData.length; i++) {
        if (this.selectedID.indexOf(i) != -1) {
          this.importedRows.push(this.excelData[i]);
        }
      }
    else this.importedRows = this.excelData;
    this.importHardware(this);
  }

  showDatePicker() {
    var html = "";
    html += '<div id="datepicker" class="datepicker-container">';
    html +=
      '<div class="datepicker-panel" data-view="years picker"><ul class="fr-current"><li data-view="years prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="years current"></li><li data-view="years next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="years"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="months picker"><ul class="fr-current"><li data-view="year prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="year current"></li><li data-view="year next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="months"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="days picker"><ul class="fr-current"><li data-view="month prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="month current"></li><li data-view="month next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="week"></ul><ul data-view="days"></ul></div>';
    html += "</div>";

    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      zIndex: 2048,
      daysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      template: html
    });
  }

  addHardware(value) {
    var saveData = {};
    saveData["Controller"] = "Hardware";
    saveData["FunctionName"] = "Add";
    saveData["hardwareID"] = "[Auto Generate]";
    saveData["assetNumber"] = value.assetNumber;
    saveData["hardwareSKU"] = value.sku;
    saveData["category"] = value.category;
    saveData["hDescription"] = "";
    saveData["warrentyExp"] = value.warrentyexp;
    saveData["hstatus"] = "Purchased";
    saveData["userID"] = sessionStorage.getItem("userId");
    saveData["price"] = value.price.slice(0, -1);
    saveData["uploadPath"] = "Inbound/apiFiles/";

    var k = 1;
    for (i = 1; i <= value.length; i++) {
      if (
        value.productDetails["product_" + i].detail != "" &&
        value.productDetails["product_" + i].description != ""
      ) {
        saveData["productDetail" + k] =
          value.productDetails["product_" + i].detail;
        saveData["productDescription" + k] =
          value.productDetails["product_" + i].description;
        k = k + 1;
      }
    }
    saveData["hLines"] = k - 1;

    var i = 1;
    for (var item in value.images) {
      if (value.removed.includes(item)) continue;
      else {
        saveData["hardwareImage" + i] = value.images[item];
        i = i + 1;
      }
    }

    saveData["hImagesLength"] = i - 1;
    var container = this;
    var saveRecord = getImageRouteData(saveData);
    saveRecord
      .then(function(response, statusText, jqXHR) {
        try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
            container.loading = false;
            container.$router.push({ name: "Hardware" });
          } else if (response.data.ERROR) {
            console.log(response.data.ERROR);
          }
        } catch (e) {
          //handle error
          console.log("hardware.js : grabHardwareData error : ", e);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  updateHardware(value) {
    var saveData = {};
    var id = value.$root.$route.params.id;
    saveData["Controller"] = "Hardware";
    saveData["FunctionName"] = "Update";
    saveData["hardwareID"] = id;
    saveData["assetNumber"] = value.assetNumber;
    saveData["hardwareSKU"] = value.sku;
    saveData["hardwareSKU"] = value.sku;
    saveData["category"] = value.category;
    saveData["hDescription"] = "";
    saveData["warrentyExp"] = value.warrentyexp;
    saveData["hstatus"] = "Purchased";
    saveData["price"] = value.price.slice(0, -1);
    saveData["uploadPath"] = "Inbound/apiFiles/";
    saveData["hardwareImages"] = "";
    var i = 1;
    for (var item in value.images) {
      if (value.removed.includes(item)) continue;
      else {
        if (typeof value.images[item] !== "object")
          saveData["hardwareImages"] =
            saveData["hardwareImages"] == ""
              ? value.images[item].replace(
                  dataURL + "Inbound/apiFiles/images/" + id + "/",
                  ""
                )
              : saveData["hardwareImages"] +
                "," +
                value.images[item].replace(
                  dataURL + "Inbound/apiFiles/images/" + id + "/",
                  ""
                );
        else {
          saveData["hardwareImage" + i] = value.images[item];
          i = i + 1;
        }
      }
    }
    saveData["hImagesLength"] = i - 1;

    var k = 1;
    for (var ij = 1; ij <= value.length; ij++) {
      if (
        value.productDetails["product_" + ij].detail != "" &&
        value.productDetails["product_" + ij].description != ""
      ) {
        saveData["productDetail" + k] =
          value.productDetails["product_" + ij].detail;
        saveData["productDescription" + k] =
          value.productDetails["product_" + ij].description;
        k = k + 1;
      }
    }
    saveData["hLines"] = k - 1;
    var self = this;
    let saveRecord = getImageRouteData(saveData);
    saveRecord
      .then(function(response, statusText, jqXHR) {
        try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
            self.$router.push({ name: "Hardware" });
          } else if (response.data.ERROR) {
            console.log(response.data.ERROR);
          }
        } catch (e) {
          //handle error
          console.log("hardware.js : grabHardwareData error : ", e);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  importHardware(value) {
    var saveData = {};
    var headersList = [
      "Asset",
      "SKU",
      "Description",
      "Category",
      "Price",
      "Warrenty Exp",
      "Status"
    ];
    var newHeaders:string[] = [];
    saveData["Controller"] = "Hardware";
    saveData["FunctionName"] = "Import";
    saveData["hardwareID"] = "[Auto Generate]";
    saveData["importList"] = value.importedRows;
    saveData["userID"] = sessionStorage.getItem("userId");
    saveData["uploadPath"] = "Inbound/apiFiles/";
    saveData["headerRow"] = this.headerContent;
    for (var i = 0; i < value.headers.length; i++) {
      newHeaders.push(headersList[i]);
    }
    saveData["headersList"] = newHeaders;
    var container = this;
    var saveRecord = getRouteData(saveData);
    saveRecord
      .then(function(response, statusText, jqXHR) {
        try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
            container.loading = false;
            container.$router.push({ name: "Hardware" });
          } else if (response.data.ERROR) {
            console.log(response.data.ERROR);
          }
        } catch (e) {
          //handle error
          console.log("hardware.js : grabHardwareData error : ", e);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  // },
}
