
















































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import io from 'socket.io-client';

@Component
export default class HpStatusModal extends TSXComponent<void> {
  @Prop() readonly po!: string;
  @Prop() readonly aID!: string;
  $refs!: {
    modal: HTMLDivElement;
  };

  saving = false;
  errors = { items: [] };
  $parent: any;

  data() {
    

    return {
      orderStatus: 0,
      searchText: "",
      order: {
        status: '',
        items: []
      },
      statusDetails: {
          name: "OSS Status Check",
          type: 0,
          status: 0,
          date: '',
          profit: 0,
        }
    };
  }
  order: any;
  orderStatus: any;
  statusDetails: any;

  async created() {
    const objDate = new Date(new Date().toLocaleString('en-US', {timeZone: 'America/Denver', hour12: false}));
    const statusDate = [(objDate.getMonth()+1 < 10 ? "0" : "")+(objDate.getMonth() + 1), (objDate.getDate() < 10 ? "0" : "")+objDate.getDate(), objDate.getFullYear()].join('/');

    let tmpMargin = this.$parent.details.MARGIN;
    if(isNaN(tmpMargin)){
      tmpMargin = tmpMargin.replace(/[$,]/g, "");
      if(tmpMargin.indexOf("(") != -1 && tmpMargin.indexOf(")") != -1) {
        tmpMargin = "-" + tmpMargin.replace(/[()]/g, "");
      }
    }
    const socket = io('http://localhost:3000');

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('product-update', data => {
      this.order = data;


      let statusNumber = 0;
      switch (this.order.status) {
        case "Placed":
          statusNumber = 1;
          break;
        case "Received":
          statusNumber = 2;
          break;
        case "Shipped":
          statusNumber = 3;
          break;
        case "Delivered":
          statusNumber = 4;
          break;
        case "Production":
          statusNumber = 6;
          break;
        default:
          statusNumber = 1;
          break;
      }

      this.orderStatus = statusNumber;


      this.statusDetails.type = this.orderStatus;
      this.statusDetails.date = statusDate;
      this.statusDetails.profit = tmpMargin;

      this.$emit("submit", this.statusDetails);
      this.$emit('update-progress', statusNumber, this.po, this.aID);
    });
  }

  mounted() {
    this.showModal();
    this.scrapeData();

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  showModal() {
    $(this.$refs.modal).modal("show");
  }

  async scrapeData() {
    this.saving = true;
    try {
      await axios.post('http://localhost:3000/run-puppeteer', {
        searchText: this.po
      });
    } catch (error) {
      // Handle the error here
    } finally {
      this.saving = false;
    }
  }
}
