














































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
declare const getImageRouteData: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class serviceAdd extends TSXComponent<void> {
  categories:{
		CATEGORY?:string
	}[] = [{CATEGORY:"tere"}];
  category = "";
  cost = "$";
  sku = "";
  targetDate = "";
  description = "";
  loading = false;
  mounted() {
    this.showDatePicker();
  }

  created() {
    var categoryTrendObj = {
      controller: "Dashboard",
      FunctionName: "CategoryTrend",
      ReportType: "Orders",
      Unit: "Quantity",
      TopNCategory: "6",
      fYear: "2018"
    };
    var vm = this;
    var resultCategoryTrend = getRouteData(categoryTrendObj);
    resultCategoryTrend.then(function(response, statusText, jqXHR) {
      try {
        vm.categories = response.data.TOPNCATEGORYQTY;
      } catch (e) {
        //handle error
        console.log("dashBoard.js : grabSearchData error : ", e);
      }
    });
  }
  // methods: {
  updateCategory(value) {
    this.category = value;
  }
  validateBeforeSubmit(e) {
		this.targetDate = $("#date").val();
    this.$validator.validateAll().then(result => {
      if (result) {
        e.preventDefault();
        this.loading = true;
        return this.addService(this);
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  addService(value) {
    var saveData = {};
    saveData["Controller"] = "Services";
    saveData["FunctionName"] = "Add";
    saveData["serviceID"] = "[Auto Generate]";
    saveData["serviceSKU"] = value.sku;
    saveData["category"] = value.category;
    saveData["sDescription"] = value.description;
    saveData["cost"] = value.cost.slice(0, -1);
    saveData["targetDate"] = value.targetDate;
    var container = this;
    var saveRecord = getImageRouteData(saveData);
    saveRecord
      .then(function(response, statusText, jqXHR) {
        try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
            container.loading = false;

            container.$router.push({ name: "Services" });
          } else if (response.data.ERROR) {
            console.log(response.data.ERROR);
          }
        } catch (e) {
          //handle error
          console.log("service.js : Add services error : ", e);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  showDatePicker = function() {
    var html = "";
    html += '<div id="datepicker" class="datepicker-container">';
    html +=
      '<div class="datepicker-panel" data-view="years picker"><ul class="fr-current"><li data-view="years prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="years current"></li><li data-view="years next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="years"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="months picker"><ul class="fr-current"><li data-view="year prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="year current"></li><li data-view="year next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="months"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="days picker"><ul class="fr-current"><li data-view="month prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="month current"></li><li data-view="month next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="week"></ul><ul data-view="days"></ul></div>';
    html += "</div>";

    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      zIndex: 2048,
      daysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      template: html
    });
  };
  // },
}
