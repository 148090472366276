










































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from '../components/pageTitle.vue';

declare const $: any;
declare const getRouteData: Function;
declare const htmlParse: Function;
@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    PageTitle
  }
})
export default class supportTicket extends TSXComponent<void> {
  saving = false;
  Subject = "";
  Message = "";
  htmlCheck(InputVal) {
    if (InputVal.length > htmlParse(InputVal).length) {
      return true;
    } else {
      return false;
    }
  }
  listPageRedirection() {
    this.$router.push({ name: "Supports" });
  }
  async validateBeforeSubmit(e) {
    this.$validator.validateAll().then(result => {
      var htmlCount = 0;
      var self = this;
      if ($("#Subject").val() != "") {
        if (self.htmlCheck($("#Subject").val())) {
          self.Subject = htmlParse($("#Subject").val());
          htmlCount++;
        }
      }
      if ($("#message").val() != "") {
        if (self.htmlCheck($("#message").val())) {
          $("#message").val(htmlParse($("#message").val()));
          htmlCount++;
        }
      }
      if (htmlCount != 0) {
        notifier.alert("HTML content found. This content has been removed!");
        result = false;
      }
      if (result) {
        e.preventDefault();
        return this.submit();
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  async submit() {
    const self = this;
    this.saving = true;

    var comInstance = this;
    const currentUser = JSON.parse(
      sessionStorage.getItem("currentUser") || "{}"
    );
    const accountId = currentUser[0]["ACCOUNTID"];
    const userId = currentUser[0]["USERID"];
    const userEmail = currentUser[0]["UEMAIL"];

    const dataObj = {
      controller: "Supports",
      FunctionName: "Add",
      title: this.Subject,
      message: $("#message").val(),
      ticketId: 0,
      accountId: accountId,
      userId: userId,
      userEmail: userEmail
    };
    var getData = getRouteData(dataObj);
    getData.then(function(response, statusText, jqXHR) {
      if (response.data.Id) {
        notifier.success("Ticket Added Successfully!");
        self.saving = false;
        var status = 0;
        self.$router.push({ name: "Supports" });
      }
    });
  }
}
