var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText), depth0))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    return "303-848-8405 ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue), depth0))
    + " ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Email), depth0))
    + " ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"quoteDetails_header\">\n  <tr>\n    <td style=\"padding-right: 5px;\">\n      <strong>Milestone Computer Technology Inc.</strong><br />\n      314 Wilcox St. Ste. 101 Castle Rock, CO 80104<br />\n      303-848-8405 <br />\n      "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":148}}})) != null ? stack1 : "")
    + " <br />\n      "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":8,"column":135}}})) != null ? stack1 : "")
    + "\n    </td>\n    <td colspan=\"2\"><img src=\"https://smartstoresgeneric.s3.amazonaws.com/mail/milestonetechlogo.png\" style=\"display:block; border: 0; float: right; height: 52px;\" border=\"0\" /></td>\n  </tr>\n  <tr>\n    <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 20px;letter-spacing: 1.7px;\">\n    </td>\n  </tr>\n  <tr>\n    <td colspan=\"3\">\n      <table width=\"100%\">\n        <tr>\n          <td width=\"78%\" style=\"padding: 20px 0;font-size: 26px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\"><span style=\"color: #0196d5;\">PURCHASE ORDER</span> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO), depth0))
    + "</td>\n          <td width=\"22%\" style=\"text-align:right;padding: 20px 0;font-size: 26px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n      </table>\n    </td>\n  </tr>\n</table>";
},"useData":true});