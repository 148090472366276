

















































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import directives from "../helpers/directives";
import { notifier } from "@/models/common";

declare const $: any;
declare const dataURL: string;

interface Props {}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  },
  directives
})
export default class DeploymentNoteAddModal extends TSXComponent<void> {
  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  $refs!: {
    modal: HTMLDivElement;
  };

  loading = false;
  saving: any = false;
  note = "";
  errors: any;

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    setTimeout(() => {
      $("#deploymentNote").focus();
    }, 100);
  }

  async saveNote() {
    // validate
    if (this.note == "") return;

    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "DeploymentAddNote",
        deploymentId: this.deploymentId,
        note: this.note
      });
      if (response.data.STATUS == 1) {
        notifier.success("Saved note successfully");
        this.$emit("reloadNotes");
        this.$emit("close");
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message != "") {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.saving = false;
    }
  }
}
