













































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "./LaddaButton.vue";

interface Props {
  purchaseID: number;
  details?: any;
  aID?: number;
  type?: string;
  forceProcess?: boolean;
  showNotes?: boolean;
  showSubmitBtn?: boolean;
}

interface Events {}

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class OrderStatusReportModal extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: [] })
  ordersCheckStatus?: any;

  @Prop({ required: false, default: [] })
  updateMap?: any;

  @Prop({ required: false, default: {} })
  selectedEngine?: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  saving: boolean | "error" = false;
  loading = false;
  removing = false;
  fullCatList: any = [];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  async created() {
    for (const item of this.ordersCheckStatus) {
      const inUpdateMap = this.updateMap.find(
        t => t.ORDERID == item.PURCHASEID
      );
      if (inUpdateMap) {
        item.UPDATEDTO = inUpdateMap.LATESTSTATUS;
      }
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  close() {
    this.$emit("close");
  }
}
