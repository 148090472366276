

































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../../components/LaddaButton.vue";
import axios from "axios";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";

declare const dataURL: string;

interface Props {
  deploymentId: number;
  scheduleGroupId: number;
  userId: number;
  note: string;
}

interface Events {}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class ModalEditScheduleGroupUserNotes extends TSXComponent<
  Props,
  Events
> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: true, default: 0 })
  deploymentId?: number;

  @Prop({ required: true, default: 0 })
  scheduleGroupId?: number;

  @Prop({ required: true, default: 0 })
  userId?: number;

  @Prop({ required: false, default: "" })
  note?: string;

  loading: boolean = false;

  mounted() {
    $(this.$refs.modal).modal("show");
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async onSubmit() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateScheduleGroupUserNotes",
        deploymentId: this.deploymentId,
        scheduleGroupId: this.scheduleGroupId,
        userId: this.userId,
        note: this.note
      });

      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1) {
        notifier.success(message);
        this.$emit("callback", {
          scheduleGroupId: this.scheduleGroupId,
          userId: this.userId,
          note: this.note
        });
      } else {
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      this.loading = false;
    }
  }

  clickOutside() {
    this.$emit("close");
  }
}
