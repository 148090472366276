






















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { validateFn } from "../helpers";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import axios from "axios";

declare const $: any;
declare const getImageRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class CommissionStatementImport extends TSXComponent<void> {
  uploading = false;

  handleCommissionFileUpload(e) {
    $(".commissionFileErrorMsg").html("");
  }

  handleUploadNewFile() {
    var comInstance = this;

    if (!$("#commissionFileUpload").get(0).files.length) {
      $(".commissionFileErrorMsg").html("Please upload a file.");
      return false;
    }
    var filePath = $("#commissionFileUpload").get(0).files[0].name;
    var allowedExtensions = /(\.xls|\.xlsx)$/i;
    if (!allowedExtensions.exec(filePath)) {
      $(".commissionFileErrorMsg").html("Please upload file having extensions .xls or .xlsx only.");
      return false;
    } else {
      $(".commissionFileErrorMsg").html("");
      //upload file, and get returned json
      comInstance.uploading = true;
      var processedFileUpload = getImageRouteData({
          Controller: "Finances",
          FunctionName: "CommissionStatement",
          orderfile: $("#commissionFileUpload").get(0).files[0],
          fileType: filePath.indexOf(".xls") != -1 ? "xls" : ""
      }).then(async (response) => {
          comInstance.uploading = false
          if(response.data.STATUS == 1) {
            //reset upload file
            $("#commissionFileUpload").val("");
            notifier.success(response.data.STATUSMESSAGE)
          }
          else {
            notifier.alert(response.data.STATUSMESSAGE)
          }
      }).catch(function (error) {
          // console.log(error);
      });
    }
  }
}
