var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression((helpers.dynamicPartial||(depth0 && depth0.dynamicPartial)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.partialname : depth0),{"name":"dynamicPartial","hash":{},"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":49,"column":38}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/orderDetails_items.handlebars"),depth0,{"name":"orderDetails_items","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/orderDetails_summary.handlebars"),depth0,{"name":"orderDetails_summary","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!DOCTYPE html>\n<html>\n  <head>\n    <link href=\"https://var360.s3.amazonaws.com/public/css/export.css\" rel=\"stylesheet\">\n    <link href=\"https://fonts.googleapis.com/css?family=Work+Sans:400,600&display=swap\" rel=\"stylesheet\">\n    <style>\n      body * {\n        font-weight: 400;\n      }\n    </style>\n    <style>\n      .check {\n        height: 7px;\n        width: 7px;\n      }\n      .config-line {\n        color: #b1b1b1; \n        font-size: 80%;\n      }\n\n      /* custom style template builder */\n      .element-header-type3, \n      .element-paragraph-type3,\n      .element-table-type3 {\n        padding: 0 10px;\n      }\n      .element-header-type3 td, .element-paragraph-type3 td {\n      }\n      .element-header-type3 .element-header-content,\n      .element-paragraph-type3 .element-paragraph-header,\n      .element-table-type3 th {\n        padding-top: 10px;\n        padding-bottom: 3px;\n        text-transform: uppercase;\n        color: #b1b1b1;\n        font-weight: bold;\n      }\n      .element-table-type3 th {\n        text-align: left;\n        font-weight: 400;\n        padding-top: 0;\n      }\n    </style>\n  </head>\n  <body>\n    <div id=\"container\">\n"
    + ((stack1 = container.invokePartial(require("./partials/orderDetails_header.handlebars"),depth0,{"name":"orderDetails_header","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.PartialPosition1 : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":6},"end":{"line":50,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.BuilderTemplate : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":6},"end":{"line":54,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.PartialPosition2 : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":6},"end":{"line":58,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n  </body>\n</html>\n";
},"usePartial":true,"useData":true});