









































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { validateFn } from "../helpers";
@Component({
  inheritAttrs: false,
  components: {}
})
export default class quoteEdit extends TSXComponent<void> {
  tableColumns = [];
  items = {
    quoteID: "",
    qDescription: "",
    qDateTime: "",
    aID: "",
    qlines: "6",
    qspecialpricingid: "",
    contractid: "",
    qLocationID: "0",
    ItemDescription1: "",
    ItemNumber1: "",
    RegPrice1: "",
    ItemQuantity1: "",
    ItemTotal1: 0,
    ItemDescription2: "",
    ItemNumber2: "",
    RegPrice2: "",
    ItemQuantity2: "",
    ItemTotal2: 0,
    ItemDescription3: "",
    ItemNumber3: "",
    RegPrice3: "",
    ItemQuantity3: "",
    ItemTotal3: 0,
    ItemDescription4: "",
    ItemNumber4: "",
    RegPrice4: "",
    ItemQuantity4: "",
    ItemTotal4: 0,
    ItemDescription5: "",
    ItemNumber5: "",
    RegPrice5: "",
    ItemQuantity5: "",
    ItemTotal5: 0,
    ItemDescription6: "",
    ItemNumber6: "",
    RegPrice6: "",
    ItemQuantity6: "",
    ItemTotal6: 0,
    totalPrice: "0.00",
    ItemCategory1: "",
    ItemCategory2: "",
    ItemCategory3: "",
    ItemCategory4: "",
    ItemCategory5: "",
    ItemCategory6: "",
    QuoteliID1: "",
    QuoteliID2: "",
    QuoteliID3: "",
    QuoteliID4: "",
    QuoteliID5: "",
    QuoteliID6: "",
    QDESCRIPTION: "",
    LSDATE: ""
  };
  statusCheckList:number[] = [];
  resellerCheckList:string[] = [];
  details = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
    TOTALALERTS: 0,
    searchName: "",
    searchId: "",
    sDate: "",
    eDate: "",
    sTotalMin: "",
    sTotalMax: ""
  };
  loading = false;
  fromSearchAll = false;
  tottalFilterResults = 0;
  totalSearchResults = 0;
  dataObj = {
    pageNumber: 1
  };
  exportObj = {
    controller: "quotes",
    FunctionName: "Export",
    uploadPath: "Inbound/apiFiles/",
    keyName: "Inbound/apiFiles/"
  };
  created() {
    this.dataObj["controller"] = "Quotes";
    this.dataObj["FunctionName"] = "List";
    this.dataObj["sub"] = "2";
    this.dataObj["SearchAccountName"] = this.resellerCheckList.toString();
    this.dataObj["status"] = this.statusCheckList.toString();

    this.fetchQuotesData(this.dataObj, this, "", "", "new");
  }
  async fetchQuotesData(dataObj, container, keyName, fieldname, option) {
    const maxRows = getMaxRows() ? getMaxRows() : 15;
    const ses_MaxRows = sessionStorage.getItem("maxRows") != ""?sessionStorage.getItem("maxRows"):getMaxRows();
    dataObj["maxRows"] = ses_MaxRows && ses_MaxRows > 0 ? ses_MaxRows : maxRows;
    this.loading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    try {
      this.items = response.data.QUOTES || [];
      Object.assign(this.details, response.data || {});
      this.loading = false;
      if (this.fromSearchAll) {
        if (dataObj.status && dataObj.status != "") {
          this.tottalFilterResults = response.data.TOTAL;
        } else {
          this.totalSearchResults = response.data.TOTAL;
          this.tottalFilterResults = 0;
        }
      }
      if (option == "page") {
        if (dataObj["sDate"] != "" || dataObj["eDate"] != "") {
          this.details.sDate = dataObj["sDate"];
          this.details.eDate = dataObj["eDate"];
        }
        if (
          typeof dataObj["sTotalMin"] !== "undefined" ||
          typeof dataObj["sTotalMax"] !== "undefined"
        ) {
          this.details.sTotalMin = dataObj["sTotalMin"];
          this.details.sTotalMax = dataObj["sTotalMax"];
        }
      } else {
        if (option === "new") {
          this.details["searchName"] = "";
          this.details["searchId"] = "";
          // this.details['sortingId'] = 1;
          // this.details['sortingName'] = 1;
          // this.details['sortingReseller'] = 1;
          // this.details['sortingTotal'] = 1;
          // this.details['sortingDate'] = 1;
        } else {
          this.details[keyName] = dataObj[fieldname];
        }
      }
    } catch (e) {
      //handle error
      console.log("quotes.js : grabOrderData error : ", e);
    }
  }
  loadNextpage(pageNumber) {
    this.dataObj["pageNumber"] = pageNumber;
    this.dataObj["sQuote"] = this.details["searchById"];
    this.dataObj["sQuoteName"] = this.details["searchByName"];
    this.fetchQuotesData(this.dataObj, this, "", "", "page");
  }
  csvDownLoadFn() {
    console.log("csvDownLoadFn quotes Call");
    this.exportObj["ExportType"] = "CSV";
    this.quoteDownloadProcess(this.exportObj, this);
  }
  pdfDownLoadFn() {
    this.exportObj["ExportType"] = "PDF";
    console.log("pdfDownLoadFn quotes Call");
    this.quoteDownloadProcess(this.exportObj, this);
  }
  htmlDownLoadFn() {
    this.exportObj["ExportType"] = "HTML";
    console.log("htmlDownLoadFn quotes Call");
    this.quoteDownloadProcess(this.exportObj, this);
  }

  searchById() {
    this.dataObj.pageNumber = 1;
    this.dataObj["sQuote"] = this.details.searchId;
    this.fetchQuotesData(this.dataObj, this, "searchId", "sQuote", "");
  }
  searchByName() {
    this.dataObj.pageNumber = 1;
    this.dataObj["sQuoteName"] = this.details.searchName;
    this.fetchQuotesData(this.dataObj, this, "searchName", "sQuoteName", "");
  }

  resetSearchByName() {
    this.details.searchName = "";
    // document.getElementById("searchName").value = "";
    this.dataObj["sQuoteName"] = "";
    this.dataObj.pageNumber = 1;
    this.fetchQuotesData(this.dataObj, this, "searchName", "sQuoteName", "");
  }
  resetSearchById() {
    // document.getElementById("searchId").value = "";
    this.details.searchId = "";
    this.dataObj["sQuote"] = "";
    this.dataObj.pageNumber = 1;
    this.fetchQuotesData(this.dataObj, this, "searchId", "sQuote", "");
  }

  sortingById() {
    this.dataObj["order"] = 1;
    this.dataObj["direction"] =
      parseInt(this.details["sortingId"]) === 1 ? 2 : 1;
    this.fetchQuotesData(this.dataObj, this, "sortingId", "direction", "");
  }
  sortingByName() {
    this.dataObj["order"] = 2;
    this.dataObj["direction"] =
      parseInt(this.details["sortingName"]) === 1 ? 2 : 1;
    this.fetchQuotesData(this.dataObj, this, "sortingName", "direction", "");
  }
  sortingByReseller() {
    this.dataObj["order"] = 3;
    this.dataObj["direction"] =
      parseInt(this.details["sortingReseller"]) === 1 ? 2 : 1;
    this.fetchQuotesData(
      this.dataObj,
      this,
      "sortingReseller",
      "direction",
      ""
    );
  }
  sortingByTotal() {
    this.dataObj["order"] = 4;
    this.dataObj["direction"] =
      parseInt(this.details["sortingTotal"]) === 1 ? 2 : 1;
    this.fetchQuotesData(this.dataObj, this, "sortingTotal", "direction", "");
  }

  sortingByDate() {
    this.dataObj["order"] = 5;
    this.dataObj["direction"] =
      parseInt(this.details["sortingDate"]) === 1 ? 2 : 1;
    this.fetchQuotesData(this.dataObj, this, "sortingDate", "direction", "");
  }

  checkStatusList(value:number) {
    console.log("checkStatusList", value);
    var index = this.statusCheckList.findIndex(s => s === value);
    if (index < 0) {
      this.statusCheckList.push(value);
    } else {
      this.statusCheckList.splice(index, 1);
    }
    this.dataObj.pageNumber = 1;
    this.dataObj["status"] = this.statusCheckList.toString();
    this.fetchQuotesData(
      this.dataObj,
      this,
      "statusCheckListStr",
      "status",
      ""
    );
  }

  checkAllStatusList() {
    this.statusCheckList = [1, 2, 3, 4];
    this.dataObj["status"] = this.statusCheckList.toString();
    var items = document.getElementsByName("statusCheckBox");
    for (var i = 0; i < items.length; i++) {
      if ((items[i] as HTMLInputElement).type == "checkbox")
        (items[i] as HTMLInputElement).checked = true;
    }
    this.dataObj.pageNumber = 1;
    this.fetchQuotesData(
      this.dataObj,
      this,
      "statusCheckListStr",
      "status",
      ""
    );
  }
  resetStatusList() {
    this.statusCheckList = [];
    this.dataObj["status"] = this.statusCheckList.toString();
    var items = document.getElementsByName("statusCheckBox");
    for (var i = 0; i < items.length; i++) {
      if ((items[i] as HTMLInputElement).type == "checkbox")
        (items[i] as HTMLInputElement).checked = false;
    }
    this.dataObj.pageNumber = 1;
    this.fetchQuotesData(
      this.dataObj,
      this,
      "statusCheckListStr",
      "status",
      ""
    );
  }

  checkResellerList(value) {
    console.log("checkResellerList", value);
    var index = this.resellerCheckList.indexOf(value);
    if (index < 0) {
      this.resellerCheckList.push(value);
    } else {
      this.resellerCheckList.splice(index, 1);
    }
    this.dataObj.pageNumber = 1;
    this.dataObj["SearchAccountName"] = this.resellerCheckList.toString();
    this.fetchQuotesData(
      this.dataObj,
      this,
      "resellerCheckListStr",
      "SearchAccountName",
      ""
    );
  }

  checkAllResellerList() {
    this.resellerCheckList = ["Test VAR", "Test Customer", "Milestone"];
    this.dataObj["SearchAccountName"] = this.resellerCheckList.toString();
    var items = document.getElementsByName("resellerCheckbox");
    for (var i = 0; i < items.length; i++) {
      if ((items[i] as HTMLInputElement).type == "checkbox") (items[i] as HTMLInputElement).checked = true;
    }
    this.dataObj.pageNumber = 1;
    this.fetchQuotesData(
      this.dataObj,
      this,
      "resellerCheckListStr",
      "SearchAccountName",
      ""
    );
  }
  resetResellerList() {
    this.resellerCheckList = [];
    this.dataObj["SearchAccountName"] = this.resellerCheckList.toString();
    var items = document.getElementsByName("resellerCheckbox");
    for (var i = 0; i < items.length; i++) {
      if ((items[i] as HTMLInputElement).type == "checkbox") (items[i] as HTMLInputElement).checked = false;
    }
    this.dataObj.pageNumber = 1;
    this.fetchQuotesData(
      this.dataObj,
      this,
      "resellerCheckListStr",
      "SearchAccountName",
      ""
    );
  }

  quoteDownloadProcess(data, container) {
    var downLoadRes = getRouteData(data);
    downLoadRes.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS) {
          // var link = document.createElement("a");
          // link.download = 'Quotes';
          // link.href = response.data.S3URL;
          // link.click();
          var aTemp = $(
            "<a hidden download='Quotes' href='" +
              response.data.S3URL +
              "' target='_blank'></a>"
          );
          $("body").append(aTemp);
          aTemp.get(0).click();
        } else if (response.data.ERROR) {
          console.log(response.data.ERROR);
        }
      } catch (e) {
        //handle error
        console.log("quotes.js : csvDownLoadFn error : ", e);
      }
    });
  }
}
