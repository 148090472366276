var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerTerms : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <br>Footer Notes: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerContent : stack1), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.FOOTERNOTES : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <br>Footer Notes: "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.FOOTERNOTES : stack1), depth0)) != null ? stack1 : "")
    + "<br/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div style=\"margin-top:3rem\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerTerms : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerContent : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});