






































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit } from "vue-property-decorator";
import USState from "@/static/USstates.json";

@Component({
    inheritAttrs: false,
    components: {}
})

export default class FilterStateBox extends TSXComponent<void> {

    stateList: any = [];
    hasChangedStateFilters: boolean = false;
    search: string = "";
    selectedState: any = [];

    created() {
        for (const i in USState) {
            this.stateList.push({
                ID: USState[i].Abbr,
                TEXT: USState[i].Abbr,
                data: USState[i]
            });
        }
    }

    get filteredOptions() {
        let options = this.stateList;

        if (this.search) {
            options = options.filter(
                option =>
                    `${option.TEXT}`.toLowerCase().indexOf(this.search.toLowerCase()) !==
                    -1
            );
        }

        return options;
    }

    selectAll() {
        this.selectedState = this.stateList.map(item => item.ID);
      this.hasChangedStateFilters = true;
    }

    resetAll() {
        this.selectedState = [];
        this.hasChangedStateFilters = true;
    }

    filterByState() {
        this.hasChangedStateFilters = false;
        this.$emit("filter", {
            "selectedState": this.selectedState
        });
    }

    selectBox() {
        this.hasChangedStateFilters = true;
    }

}

