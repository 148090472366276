var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <td "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.widthPercent : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":8},"end":{"line":58,"column":59}}})) != null ? stack1 : "")
    + " style=\"text-align: left; border-bottom: 5px solid #b1b1b1; "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.paddingRight : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":119},"end":{"line":58,"column":178}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = helpers.column || (depth0 != null ? depth0.column : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"column","hash":{},"data":data,"loc":{"start":{"line":58,"column":180},"end":{"line":58,"column":190}}}) : helper)))
    + "</td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "width=\""
    + container.escapeExpression(((helper = (helper = helpers.widthPercent || (depth0 != null ? depth0.widthPercent : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"widthPercent","hash":{},"data":data,"loc":{"start":{"line":58,"column":35},"end":{"line":58,"column":51}}}) : helper)))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "padding-right: "
    + container.escapeExpression(((helper = (helper = helpers.paddingRight || (depth0 != null ? depth0.paddingRight : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"paddingRight","hash":{},"data":data,"loc":{"start":{"line":58,"column":154},"end":{"line":58,"column":170}}}) : helper)))
    + ";";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "  <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].SELECTEDHEADERSARRAY : depths[1]),{"name":"each","hash":{},"fn":container.program(7, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":63,"column":4},"end":{"line":65,"column":13}}})) != null ? stack1 : "")
    + "  </tr>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <td "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.widthPercent : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":8},"end":{"line":64,"column":59}}})) != null ? stack1 : "")
    + " style=\"text-align: left; "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.paddingRight : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":85},"end":{"line":64,"column":144}}})) != null ? stack1 : "")
    + "\"> "
    + ((stack1 = (helpers.getIestedIterationValues||(depth0 && depth0.getIestedIterationValues)||container.hooks.helperMissing).call(alias1,blockParams[1][0],((stack1 = blockParams[0][0]) != null ? stack1.header : stack1),{"name":"getIestedIterationValues","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":64,"column":147},"end":{"line":64,"column":193}}})) != null ? stack1 : "")
    + " </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.SELECTEDHEADERSARRAY : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":57,"column":4},"end":{"line":59,"column":13}}})) != null ? stack1 : "")
    + "  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.INVOICES : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":61,"column":2},"end":{"line":67,"column":11}}})) != null ? stack1 : "")
    + "\n  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" style=\"border-top: 5px solid #b1b1b1;\" colspan=\""
    + alias4(((helper = (helper = helpers.SELECTEDHEADERSLENGTH || (depth0 != null ? depth0.SELECTEDHEADERSLENGTH : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SELECTEDHEADERSLENGTH","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":70,"column":68},"end":{"line":70,"column":93}}}) : helper)))
    + "\"></td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\">Total:</td>\n    <td width=\"20%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + alias4(((helper = (helper = helpers.INVOICETOTALFORMATTED || (depth0 != null ? depth0.INVOICETOTALFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INVOICETOTALFORMATTED","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":72,"column":78},"end":{"line":72,"column":103}}}) : helper)))
    + "</td>\n  </tr>\n\n</table>\n";
},"useData":true,"useDepths":true,"useBlockParams":true});