









































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

interface Props {
}

interface Events {
}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class ModalChangeEndUser extends TSXComponent<Props, Events> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: "" })
  deploymentId!: string;

  @Prop({ required: false, default: [] })
  endUsers!: any[];

  @Prop({ required: false, default: 0 })
  euId!: number;

  selectedId = 0;
  selectedName = '';
  showDropdown = false;

  created() {
    const findEu = this.endUsers.find((item) => item.id == this.euId);
    if (findEu) {
      this.selectedId = findEu.id;
      this.selectedName = findEu.name;
    }
  }

  changeEndUser(item) {
    this.selectedId = item.id;
    this.selectedName = item.name;
    ApiHelper.showSuccessMessage(`Changed end user to "${this.selectedName}"`);
    this.$emit('update', item);
    this.$emit('close');
    this.showDropdown = false;
  }

  mounted() {


  }

  beforeDestroy() {

  }

  createNew() {
    ApiHelper.showSuccessMessage('Coming soon');
  }

}
