




























































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

export enum EmployeePlanStatusValue {
  Depreciated = 0,
  Active = 1,
}

@Component({
  inheritAttrs: false,
  components: {}
})

export default class paystatuscomponent extends TSXComponent<void> {
  @Prop({ required: true })
  status!: EmployeePlanStatusValue;

  @Prop({ required: true, default: "Active" })
  statustext!: string;

  $allowedFunctions: any;
  $userFunctions: any;
  popoverVisible = false;
  optionsVisible = false;

  get iconClass() {
    const icon =
      this.status === 1
      ? "green"
      :  "red";
      return icon
  }

  get statusText() {
    const status =  this.status === 1
      ? "Active"
      : "Depreciated";

      return status
  }

  mouseover() {
    if (this.optionsVisible) {
      return;
    }

    this.popoverVisible = true;
  }

  mouseout() {
    this.popoverVisible = false;
  }

  hideTooltip() {
    this.optionsVisible = false;
  }

  click() {
    this.popoverVisible = false;

    if (this.$allowedFunctions.includes(this.$userFunctions.CompAdmin)) {
      this.optionsVisible = !this.optionsVisible;
    }
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false;
    }
  }

  itemClick(status: EmployeePlanStatusValue) {
    this.$emit("changeStatus", status);
    this.optionsVisible = false;
  }
}
