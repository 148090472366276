











































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import {notifier, downloadFile, printHtml, downloadFileUrl} from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import AccountMultiselect from "../components/AccountMultiselect.vue";
import PageTitle from '../components/pageTitle.vue';

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    LaddaButton,
    AccountMultiselect,
    PageTitle
  }
})
export default class CustomerPortals extends TSXComponent<void> {
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  saving: any = false;
  pageNumber = 1;
  items = [];
  details: any = {
  };
  tagName = "";
  selectedAIDs: number[] = [];

  async created() {
    if(this.$route.params.id) {//edit
      await this.fetchData();
    }else {//add new
      this.loading = true;
      try {
        var reqData = {
          controller      : "Queries",
          FunctionName    : "GetALLAccounts",
          subsystem       : "Helpers"
        }
        const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);
        this.details = response.data;
        this.details.SELECTEDACCOUNTS = [];
      }catch (err) {
        // console.log(err.message);
      }finally {
        this.loading = false;
      }
    }
  }

  async fetchData() {
    this.loading = true;
    try {
      var reqData = {
        controller      : "Customers",
        FunctionName    : "AccountTagView",
        AccountTagID    : this.$route.params.id
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if(response.data.STATUS == 1) {
        this.details = response.data;
        this.details.currentTagName = response.data.TAGINFO.TAGNAME;
        this.tagName = response.data.TAGINFO.TAGNAME;
        this.selectedAIDs = response.data.SELECTEDACCOUNTS.map(val => parseInt(val.AID));
      }
    }catch (err) {
      // console.log(err.message);
    }finally {
      this.loading = false;
    }
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  listPageRedirection(){
    this.$router.push({ name: "AccountTags" });
  }

  toggleSelectedAccIds(aID, accountName) {
    const index = this.selectedAIDs.findIndex(id => id === aID);
    if (index === -1) {
      this.selectedAIDs.push(aID);
      this.details.SELECTEDACCOUNTS.push({
        ANAME: accountName,
        AID: aID
      });
      return;
    }

    this.selectedAIDs.splice(index, 1);
    this.$delete(this.details.SELECTEDACCOUNTS, index);
  }
  selectAllAccs() {
    this.resetAccs();
    this.selectedAIDs = this.details.ACCOUNTLIST.map(val => val.AID);
    this.details.SELECTEDACCOUNTS = this.details.ACCOUNTLIST.map(val => ({
      ANAME: val.ANAME,
      AID: val.AID
    }));
  }
  resetAccs() {
    this.selectedAIDs = [];
    this.details.SELECTEDACCOUNTS = [];
  }
  updateAccountList(type, index, aid) {
    if (this.details.SELECTEDACCOUNTS && type == "delete") {
      this.$delete(this.details.SELECTEDACCOUNTS, index);
      this.$delete(this.selectedAIDs, index);
    }
  }

  async saveTag() {
    //remove html
    this.tagName = this.removeHTML(this.tagName);

    this.$validator.validateAll().then(async result => {
      if(result) {
        this.$validator.errors.clear();
        this.saving = true;
        try {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller      : "Customers",
            FunctionName    : "UpdateAccountTag",
            AccountTagID    : this.$route.params.id ? this.$route.params.id : 0,
            tagName         : this.tagName,
            selectedAIDs    : this.selectedAIDs.join(",")
          });

          if (response.data.ERROR) {
            this.saving = "error";
            throw new Error(response.data.ERROR);
          }
          
          if(response.data.STATUS == 1) {
            this.saving = false;
            this.$router.push({ name: "AccountTags" });
          }else {
            this.saving = 'error';
            if(typeof response.data.tagNameExisted != "undefined" && response.data.tagNameExisted) {
              this.$validator.errors.add({
                field: "tagName",
                msg: response.data.STATUSMESSAGE
              });
            }
          }
        } catch (err) {
          this.saving = "error";
        }
      }
    });
  }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }
}
