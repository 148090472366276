




















import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";

declare const $: any;
interface Props {
  currentItem: any;
}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader
  }
})
export default class ConfirmYesNoTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: true, default: "" })
  message!: string;

  $parent: any;
  loading = false;

  clickOutside() {
    this.$emit("close");
  }

  yes() {
    this.$emit('yes');
    this.$emit("close");
  }

  no() {
    this.$emit('no');
    this.$emit("close");
  }
}
