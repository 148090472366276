





































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

declare const htmlCheck: Function;
declare const htmlParse: Function;

@Component({
  inheritAttrs: true,
  components: {}
})

export default class BuildHeaderTooltip extends TSXComponent<void> {

  @Prop({ required: false, default: "" })
  buildHeader!: string;

  clickOutside() {
    this.$emit("close");
  }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  addBuildHeader() {
    this.$emit("save", {
      buildHeader: this.buildHeader
    })
  }
}
