























import {Component as TSXComponent} from "vue-tsx-support";
import {Component} from "vue-property-decorator";
import Loader from "@/components/Loader.vue";

@Component({
  inheritAttrs: false,
  components: {Loader},
  methods: {}
})

export default class InvoiceDeposits2 extends TSXComponent<void> {


  async created() {
    this.$router.push(
        {
          name: 'Invoices',
          query: {
            action: 'invoiceDeposits',
          },
          replace: true,
        }
    );
  }
}
