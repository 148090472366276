




















































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import PageTitle from "../components/pageTitle.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { colsResizeable, getRangeFilter, getDateRangeFilter } from "@/helpers/ApiHelper";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import FilterCurrencyBox from "@/components/FilterCurrencyBox.vue";
import UploadNewPriceListModal from "@/components/UploadNewPriceListModal.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

declare const dataURL: string;

export enum SortField {
  Name = 1,
  Description = 2,
  TotalItem = 3,
  LastUpdatedDate = 4,
  UploadedDate = 5,
  Uploader = 6
}

export type Sort = {
  field: SortField
  direction: {
    [SortField.Name]: 1 | 2,
    [SortField.Description]: 1 | 2,
    [SortField.TotalItem]: 1 | 2,
    [SortField.LastUpdatedDate]: 1 | 2,
    [SortField.UploadedDate]: 1 | 2,
    [SortField.Uploader]: 1 | 2,
  }
}

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    PageTitle,
    LaddaButton,
    ListHeaderActions,
    FilterCurrencyBox,
    UploadNewPriceListModal,
    ConfirmRemoveItemModal
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})

export default class PricingList extends TSXComponent<void> {
  $root: any;
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  message = "";
  uploading: any = false;
  pageNumber = 1;
  details: any = {};
  priceList: any[] = [];
  filters: any = {
    listName: '',
    itemTotalMin: '',
    itemTotalMax: '',
    description: '',
    lastUpdatedDate: {
      sDate: '',
      eDate: ''
    },
    uploadedDate: {
      sDate: '',
      eDate: ''
    },
    uploadBy: ''
  };

  searchFilters: any = {
    listName: '',
    itemTotalMin: '',
    itemTotalMax: '',
    description: '',
    lastUpdatedDate: {
      sDate: '',
      eDate: ''
    },
    uploadedDate: {
      sDate: '',
      eDate: ''
    },
    uploadBy: ''
  }

  confirmRemoveModalVisible = false;
  targetPriceList: any = null;

  sort: Sort = {
    field: SortField.LastUpdatedDate,
    direction: {
      [SortField.Name]: 1,
      [SortField.Description]: 1,
      [SortField.TotalItem]: 1,
      [SortField.LastUpdatedDate]: 2,
      [SortField.UploadedDate]: 1,
      [SortField.Uploader]: 1,
    }
  };

  showUploadPriceListModalFlg = false;

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {

      const filter = {};

      if (this.filters.listName) {
        filter['name'] = this.filters.listName.trim();
      }

      if (this.filters.description) {
        filter['description'] = this.filters.description.trim();
      }

      if (this.filters.itemTotalMin) {
        filter['itemTotalMin'] = this.filters.itemTotalMin.trim();
      }

      if (this.filters.itemTotalMax) {
        filter['itemTotalMax'] = this.filters.itemTotalMax.trim();
      }

      if (this.filters.lastUpdatedDate.sDate) {
        filter['lastUpdatedDateStart'] = this.filters.lastUpdatedDate.sDate;
      }

      if (this.filters.lastUpdatedDate.eDate) {
        filter['lastUpdatedDateEnd'] = this.filters.lastUpdatedDate.eDate;
      }

      if (this.filters.uploadedDate.sDate) {
        filter['lastUploadedDateStart'] = this.filters.uploadedDate.sDate;
      }

      if (this.filters.uploadedDate.eDate) {
        filter['lastUploadedDateEnd'] = this.filters.uploadedDate.eDate;
      }

      if (this.filters.uploadBy) {
        filter['uploadBy'] = this.filters.uploadBy.trim();
      }

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Quotes",
        FunctionName: "PriceList",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        ...filter,
        order: this.sort.field,
        direction: this.sort.direction[this.sort.field].toString()
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS != 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.priceList = this.details.PRICINGLIST;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }

    colsResizeable({ ms: 500 });
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  async searchByListName() {
    this.pageNumber = 1;

    if (this.searchFilters.listName) {
      this.filters.listName = this.searchFilters.listName;
      await this.fetchData();
    }
  }

  async resetSearchByListName(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.listName = this.searchFilters.listName = '';
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async sortingByListName() {
    this.sortBy(SortField.Name);
  }

  async searchByTotalItem() {
    this.pageNumber = 1;

    this.filters.itemTotalMin = this.searchFilters.itemTotalMin;
    this.filters.itemTotalMax = this.searchFilters.itemTotalMax;
    await this.fetchData();
  }

  async sortingByTotalItem() {
    this.sortBy(SortField.TotalItem);
  }

  async searchByDescription() {
    this.pageNumber = 1;

    if (this.searchFilters.description) {
      this.filters.description = this.searchFilters.description;
      await this.fetchData();
    }
  }

  async resetSearchByDescription(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.description = this.searchFilters.description = '';
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async sortingByDescription() {
    this.sortBy(SortField.Description);
  }

  async searchByUpdatedDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.lastUpdatedDate.sDate == undefined || this.searchFilters.lastUpdatedDate.sDate == "") &&
      (this.searchFilters.lastUpdatedDate.eDate == "" || this.searchFilters.lastUpdatedDate.eDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      this.filters.lastUpdatedDate.sDate = this.searchFilters.lastUpdatedDate.sDate;
      this.filters.lastUpdatedDate.eDate = this.searchFilters.lastUpdatedDate.eDate;
      await this.fetchData();
    }
  }

  async resetSearchByUpdatedDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.lastUpdatedDate.sDate == undefined || this.searchFilters.lastUpdatedDate.sDate == "") &&
      (this.searchFilters.lastUpdatedDate.eDate == "" || this.searchFilters.lastUpdatedDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.lastUpdatedDate.sDate = this.searchFilters.lastUpdatedDate.sDate = undefined;
      this.filters.lastUpdatedDate.eDate = this.searchFilters.lastUpdatedDate.eDate = undefined;

      await this.fetchData();
    }
  }

  async sortingByUpdatedDate() {
    this.sortBy(SortField.LastUpdatedDate);
  }

  async searchByUploadedDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.uploadedDate.sDate == undefined || this.searchFilters.uploadedDate.sDate == "") &&
      (this.searchFilters.uploadedDate.eDate == "" || this.searchFilters.uploadedDate.eDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      this.filters.uploadedDate.sDate = this.searchFilters.uploadedDate.sDate;
      this.filters.uploadedDate.eDate = this.searchFilters.uploadedDate.eDate;
      await this.fetchData();
    }
  }

  async resetSearchByUploadedDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.uploadedDate.sDate == undefined || this.searchFilters.uploadedDate.sDate == "") &&
      (this.searchFilters.uploadedDate.eDate == "" || this.searchFilters.uploadedDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.uploadedDate.sDate = this.searchFilters.uploadedDate.sDate = undefined;
      this.filters.uploadedDate.eDate = this.searchFilters.uploadedDate.eDate = undefined;

      await this.fetchData();
    }
  }

  async sortingByUploadedDate() {
    this.sortBy(SortField.UploadedDate);
  }

  async searchByUploader() {
    this.pageNumber = 1;

    if (this.searchFilters.uploadBy) {
      this.filters.uploadBy = this.searchFilters.uploadBy;
      await this.fetchData();
    }
  }

  async resetSearchByUploader(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.uploadBy = this.searchFilters.uploadBy = '';
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async sortingByUploader() {
    this.sortBy(SortField.Uploader);
  }

  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async uploadNewPriceList() {
    this.showUploadPriceListModalFlg = false;
    this.fetchData();
  }

  deletePriceList(priceList) {
    if (!this.loading) {
      this.confirmRemoveModalVisible = true;
      this.targetPriceList = priceList;
    }
  }

  async confirmDeletePriceList() {
    this.confirmRemoveModalVisible = false;
    try {
        this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: 'Quotes',
          FunctionName: 'DeletePriceList',
          userID: sessionStorage.getItem("userId"),
          priceListID: this.targetPriceList.PRICELISTID
        });

        const message = response.data.STATUSMESSAGE || "";
        if (response.data.STATUS == 1) {
          notifier.success(message);
          await this.fetchData();
        } else {
          if (message) {
            notifier.alert(message);
          }
        }
      } catch (error) {
        console.log('error ', error);
      } finally {
        this.loading = false;
      }
  }

  listPageRedirection() {
    this.$router.go(-1);
  }
}
