var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <td width=\""
    + alias4(((helper = (helper = helpers.widthPercent || (depth0 != null ? depth0.widthPercent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widthPercent","hash":{},"data":data,"loc":{"start":{"line":31,"column":17},"end":{"line":31,"column":33}}}) : helper)))
    + "\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">"
    + alias4(((helper = (helper = helpers.column || (depth0 != null ? depth0.column : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"column","hash":{},"data":data,"loc":{"start":{"line":31,"column":95},"end":{"line":31,"column":105}}}) : helper)))
    + "</td>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "    <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].SELECTEDHEADERSARRAY : depths[1]),{"name":"each","hash":{},"fn":container.program(4, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":36,"column":6},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "    </tr>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing;

  return "       <td width=\""
    + container.escapeExpression(((helper = (helper = helpers.widthPercent || (depth0 != null ? depth0.widthPercent : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"widthPercent","hash":{},"data":data,"loc":{"start":{"line":37,"column":18},"end":{"line":37,"column":34}}}) : helper)))
    + "\" style=\"text-align: left\"> "
    + ((stack1 = (helpers.getIestedIterationValues||(depth0 && depth0.getIestedIterationValues)||alias2).call(alias1,blockParams[1][0],((stack1 = blockParams[0][0]) != null ? stack1.header : stack1),{"name":"getIestedIterationValues","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":37,"column":62},"end":{"line":37,"column":108}}})) != null ? stack1 : "")
    + "\n      </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n\n<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.SELECTEDHEADERSARRAY : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":30,"column":4},"end":{"line":32,"column":13}}})) != null ? stack1 : "")
    + "  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.QUOTES : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":34,"column":2},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + "\n  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" style=\"border-top: 5px solid #b1b1b1;\" colspan=\""
    + alias4(((helper = (helper = helpers.SELECTEDHEADERSLENGTH || (depth0 != null ? depth0.SELECTEDHEADERSLENGTH : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SELECTEDHEADERSLENGTH","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":44,"column":68},"end":{"line":44,"column":93}}}) : helper)))
    + "\"></td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\">Total:</td>\n    <td width=\"20%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + alias4(((helper = (helper = helpers.SUMTOTALPRICE_FORMATTED || (depth0 != null ? depth0.SUMTOTALPRICE_FORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SUMTOTALPRICE_FORMATTED","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":46,"column":78},"end":{"line":46,"column":105}}}) : helper)))
    + "</td>\n  </tr>\n  \n</table>";
},"useData":true,"useDepths":true,"useBlockParams":true});