








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

import Pagination from "@/components/Pagination.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import FilterCurrencyBox from "@/components/FilterCurrencyBox.vue";
import InfoTooltip from "@/components/InfoTooltip.vue";
import HPAgentDetailsLinkAccountTooltip from "@/components/HPAgentDetailsLinkAccountTooltip.vue";
import CustomerAdd from "@/components/HPAgentDetailsCustomerAddModal.vue";
import OrderSearchModal from "@/components/OrderSearchModal.vue";
import SuggestedOrdersModal from "@/components/SuggestedOrdersModal.vue";
import SelectASourceModal from "@/components/SelectASourceModal.vue";
import UnverifiedOrderStatus from "@/components/UnverifiedOrderStatus.vue";

import {
  OrderAPIRepo,
  Filters,
  Sort,
  SortField,
  OrderStatus,
  UnverifyStatus
} from "@/repos/OrderAPIRepo";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "@/models/common";
import { Order } from "@/models/Order";
import ExportModal, { ExportFormat } from "@/components/ExportModal.vue";
import { UserAPIRepo } from "@/repos/UserAPIRepo";
import CustomerSearchTooltip from "@/components/CustomerSearchTooltip.vue";
import FilterModal from "@/components/FilterModal.vue";
import moment from "moment";
import { colsResizeable, colsResizeableReset, getDateRangeFilter } from "@/helpers/ApiHelper";
declare function require(params: any): any;
const template = require("@/templates/orders/orderList.handlebars");

const orderRepo = new OrderAPIRepo();
const userRepo = new UserAPIRepo();

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    // ConfirmRemoveItemModal,
    FilterCurrencyBox,
    ExportModal,
    CustomerSearchTooltip,
    InfoTooltip,
    HPAgentDetailsLinkAccountTooltip,
    CustomerAdd,
    OrderSearchModal,
    SuggestedOrdersModal,
    SelectASourceModal,
    FilterModal,
    UnverifiedOrderStatus,
    ConfirmRemoveItemModal
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class OrdersCustomer extends TSXComponent<void> {
  $allowedFunctions: any;
  $userFunctions: any;
  $isHideReseller: any;
  selectedID: (number | undefined)[] = [];
  selectedAccounts: (number | undefined)[] = [];
  selectedAccountID: (number | undefined)[] = [];
  multiAccountSelected = false;
  newPageOrder: (number | undefined)[] = [];
  totalOrders: (number | undefined)[] = [];
  selectedPage = 0;
  currentRole = sessionStorage.getItem("userRole");
  ISSEARCH = 0;
  TOTAL = 0;
  CURRENTYEAR = 0;
  LASTYEAR = 0;
  viewType = "CurrentUsers";
  showFilter = 0;
  loading = false;
  ordersData: Order.Root | any = {};
  filters: any = {
    searchAll: "",
    searchId: "",
    searchName: "",
    searchAccountName: "",
    sProfitMin: undefined,
    sProfitMax: undefined,
    sGrandTotalMin: undefined,
    sGrandTotalMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    sDate: undefined,
    eDate: undefined,
    sStatus: [],
    resellerCheckList: [],
    AccountTagID: [],
    gTagIDs: [],
    groupIDs: [],
    selectedAccs: [],
    unassigned: false,
    selectedUnverifyAccts: [],
    customerPO: "",
    customerNumber: "",
    customerName: "",
    fromDate: "",
    sSource: [],
    endDate: "",
    sTaxMin: undefined,
    sTaxMax: undefined,
    DirectLinePerMin: undefined,
    DirectLinePerMax: undefined,
    InvoicedPerMin: undefined,
    InvoicedPerMax: undefined,
    endUserName: "",
    accountTagName: [],
    selectedAccsName: [],
    groupName: [],
    filterProfit: undefined,
    filterTax: undefined,
    filterSubTotal: undefined,
    filterGrandTotal: undefined,
    filterDate: undefined,
    statusName: [],
    order: "",
    sourceName: [],
    resellerPo: "",
    indQuoteId: "",
    filterPaycheck: undefined,
    customDataOrderNumber: "",
    filterInvoiced: undefined,
    shippingName: "",
    shippingAddress1: "",
    filterOrderVarCost: undefined,
    orderVarCostTotalMin: undefined,
    orderVarCostTotalMax: undefined,
    deptName: "",
    ledgerBalanceTotalMin: undefined,
    ledgerBalanceTotalMax: undefined,
    filterLedgerBalance: undefined,
    unverifyStatus: [],
    reason: "",
    contract: [],
    contractName: []
  };
  totalPages: number = 0;
  sort: any = {
    field: null,
    direction: {
      [SortField.Id]: 1,
      [SortField.Name]: 1,
      [SortField.Customer]: 1,
      [SortField.Profit]: 1,
      [SortField.GrandTotal]: 1,
      [SortField.Date]: 1,
      [SortField.Status]: 1,
      [SortField.Order]: 1,
      [SortField.Source]: 1,
      [SortField.Tax]: 1,
      [SortField.DirectLinePer]: 1,
      [SortField.CustomerPO]: 1,
      [SortField.CUSTOMERORDERNUMBER]: 1,
      [SortField.Total]: 1,
      [SortField.InvoicedPer]: 1,
      [SortField.EndUserName]: 1,
      [SortField.shippingName]: 1,
      [SortField.OrderVarCost]: 1,
      [SortField.DepartmentName]: 1,
      [SortField.LedgerBalance]: 1,
      [SortField.ContractNumber]: 1
    }
  };

  position = {
    searchId: 1,
    searchName: 2,
    searchAccountName: 3,
    accountTagName: 4,
    selectedAccsName: 5,
    groupName: 6,
    filterProfit: 7,
    filterTax: 8,
    filterSubTotal: 9,
    filterGrandTotal: 10,
    filterDate: 11,
    statusName: 12,
    order: 13,
    sourceName: 14,
    resellerPo: 15,
    indQuoteId: 16,
    filterPaycheck: 17,
    customDataOrderNumber: 18,
    filterInvoiced: 19,
    endUserName: 20,
    shippingName: 21,
    shippingAddress1: 22,
    filterOrderVarCost: 23,
    deptName: 24,
    filterLedgerBalance: 25,
    contractName: 26
  };

  pageNumber =
    sessionStorage.pageDetails &&
    JSON.parse(sessionStorage.pageDetails)["Orders"]
      ? JSON.parse(sessionStorage.pageDetails)["Orders"]
      : 1;
  items: Order.Order[] = [];
  unverifiedItems: any = [];

  confirmRemoveModalVisible = false;
  exportModalVisible = false;
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  resellers: { ACCOUNTID?: number }[] = [];
  selectedAll = false;
  selectPageAll = false;
  curYear = new Date().getFullYear();
  lYear = new Date().getFullYear() - 1;
  excludedIDs: number[] = [];
  selectedView = false;
  details: any = [];
  orderCommInfo = {};
  commInfoVisibleIndex = -1;
  accountTags: any = [];
  searchTagsTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  searchAccTxt = "";
  filterBtn = "currentList";
  unverifyNumber = 0;
  currentCustAddDetail = [];
  currentCustIndex = -1;
  acctsInList = [];
  OrderSearchModalVisible = false;
  unverifyAccSearch = "";
  isInitial = true;
  selectedItems: object[] = [];
  pageHeaders = {
    POID: "Customer PO",
    PODESCRIPTION: "Order Name",
    ACCOUNTNAME: this.currentRole === "Customer" ? "Reseller" : "Customer",
    GRANDTOTALFORMATTED: "Grand Total",
    NEWDATEFORMATTED: "Date",
    SOURCE: "Source",
    IndirectQuoteID: "Source Quote ID"
  };
  headerOrder = [
    "POID",
    "PODESCRIPTION",
    "ACCOUNTNAME",
    "GRANDTOTALFORMATTED",
    "NEWDATEFORMATTED",
    "SOURCE",
    "IndirectQuoteID"
  ];
  selectedheaders = [
    "POID",
    "PODESCRIPTION",
    "ACCOUNTNAME",
    "GRANDTOTALFORMATTED",
    "NEWDATEFORMATTED"
  ];
  exportHeadersArr: {
    header: string;
    column: string;
    widthPercent: string;
  }[] = [];

  selectedTags = [];
  CurrentView = "My Orders";
  tagIDs: number[] = [];
  filterModalVisible = false;

  usedFilters = {
    searchId: {
      header: "Customer PO",
      function: "resetSearchById"
    },
    searchName: {
      header: "Order Name",
      function: "resetSearchByName"
    },
    searchAccountName: {
      header: "Customer",
      function: "resetSearchByAccountName"
    },
    selectedAccsName: {
      header: "Customer Accounts",
      function: "resetAcc"
    },
    accountTagName: {
      header: "Customer Tags",
      function: "resetTag"
    },
    groupName: {
      header: "Customer Groups",
      function: "resetTag"
    },
    filterProfit: {
      header: "Profit",
      function: "resetSearchByProfit"
    },
    filterTax: {
      header: "Tax",
      function: "resetSearchByTax"
    },
    filterSubTotal: {
      header: "SubTotal",
      function: "resetSearchBySubTotal"
    },
    filterGrandTotal: {
      header: "Grand Total",
      function: "resetSearchByTotal"
    },
    filterDate: {
      header: "Date",
      function: "resetSearchByDate"
    },
    statusName: {
      header: "Status",
      function: "resetStatusList"
    },
    order: {
      header: "VAR360 Order",
      function: "resetSearchByOrder"
    },
    sourceName: {
      header: "Source",
      function: "resetSourceList"
    },
    resellerPo: {
      header: "VAR PO",
      function: "resetSearchByResellerPO"
    },
    filterInvoiced: {
      header: "Invoiced %",
      function: "resetSearchByInvoicedPercent"
    },
    endUserName: {
      header: "End User Name",
      function: "resetSearchByEndUserName"
    },
    indQuoteId: {
      header: "Source Quote ID",
      function: "resetSearchByQuote"
    },
    filterPaycheck: {
      header: "Payroll %",
      function: "resetSearchByPercent"
    },
    customDataOrderNumber: {
      header: "Source Order Number",
      function: "resetSearchByCustomDataOrderNumber"
    },
    shippingName: {
      header: "Shipping Name",
      function: "resetSearchByShippingName"
    },
    shippingAddress1: {
      header: "Shipping Address1",
      function: "resetSearchByShippingAddress"
    },
    filterOrderVarCost: {
      header: "VAR Cost",
      function: "resetSearchByOrderVarCostTotal"
    },
    deptName: {
      header: "Department Name",
      function: "resetSearchByDeptName"
    },
    filterLedgerBalance: {
      header: "Ledger Balance",
      function: "resetSearchByLedgerbalanceTotal"
    },
    contractName: {
      header: "Contract Number",
      function: "resetContractList"
    }
  };
  startDate: string | undefined;
  endDate: string | undefined;
  usedFilterDetails: any = [];
  searchFilters: any = {
    searchAll: "",
    searchId: "",
    searchName: "",
    searchAccountName: "",
    sProfitMin: undefined,
    sProfitMax: undefined,
    sGrandTotalMin: undefined,
    sGrandTotalMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    sDate: undefined,
    eDate: undefined,
    sStatus: [],
    resellerCheckList: [],
    AccountTagID: [],
    gTagIDs: [],
    groupIDs: [],
    selectedAccs: [],
    unassigned: false,
    selectedUnverifyAccts: [],
    customerPO: "",
    customerNumber: "",
    customerName: "",
    fromDate: "",
    sSource: [],
    endDate: "",
    sTaxMin: undefined,
    sTaxMax: undefined,
    DirectLinePerMin: undefined,
    DirectLinePerMax: undefined,
    InvoicedPerMin: undefined,
    InvoicedPerMax: undefined,
    endUserName: "",
    accountTagName: [],
    selectedAccsName: [],
    groupName: [],
    filterProfit: undefined,
    filterTax: undefined,
    filterSubTotal: undefined,
    filterGrandTotal: undefined,
    filterDate: undefined,
    statusName: [],
    order: "",
    sourceName: [],
    resellerPo: "",
    indQuoteId: "",
    filterPaycheck: undefined,
    customDataOrderNumber: "",
    filterInvoiced: undefined,
    shippingName: "",
    shippingAddress1: "",
    filterOrderVarCost: undefined,
    orderVarCostTotalMin: undefined,
    orderVarCostTotalMax: undefined,
    deptName: "",
    ledgerBalanceTotalMin: undefined,
    ledgerBalanceTotalMax: undefined,
    filterLedgerBalance: undefined,
    unverifyStatus: [],
    reason: "",
    contract: [],
    contractName: []
  };
  removeUnOrderId = 0;
  confirmUnOrderRemove = false;
  listCustomFields: any = [];

  get paginationVisible(): boolean {
    return this.totalPages !== 1;
  }

  async created() {
    if (this.currentRole === "Customer" && this.$isHideReseller) {
      this.selectedheaders.splice(
        this.selectedheaders.indexOf("ACCOUNTNAME"),
        1
      );
      this.$delete(this.pageHeaders, "ACCOUNTNAME");
    }

    if (typeof this.$route.query.searchAll === "string") {
      this.filters.searchAll = this.$route.query.searchAll;
    }
    if (typeof this.$route.query.selectedAccs === "number") {
      this.filters.selectedAccs = [this.$route.query.selectedAccs];
    }
    if (typeof this.$route.query.searchId === "string") {
      this.filters.searchId = this.searchFilters.searchId = this.$route.query.searchId;
    }
    if (typeof this.$route.query.searchName === "string") {
      this.filters.searchName = this.searchFilters.searchName = this.$route.query.searchName;
    }
    if (typeof this.$route.query.searchAccountName === "string") {
      this.filters.searchAccountName = this.searchFilters.searchAccountName = this.$route.query.searchAccountName;
    }
    if (typeof this.$route.query.sProfitMin === "string") {
      this.filters.sProfitMin = this.searchFilters.sProfitMin = this.$route.query.sProfitMin;
    }
    if (typeof this.$route.query.sProfitMax === "string") {
      this.filters.sProfitMax = this.searchFilters.sProfitMax = this.$route.query.sProfitMax;
    }
    if (typeof this.$route.query.sTaxMin === "string") {
      this.filters.sTaxMin = this.searchFilters.sTaxMin = this.$route.query.sTaxMin;
    }
    if (typeof this.$route.query.sTaxMax === "string") {
      this.filters.sTaxMax = this.searchFilters.sTaxMax = this.$route.query.sTaxMax;
    }
    if (typeof this.$route.query.sGrandTotalMin === "string") {
      this.filters.sGrandTotalMin = this.searchFilters.sGrandTotalMin = this.$route.query.sGrandTotalMin;
    }
    if (typeof this.$route.query.sGrandTotalMax === "string") {
      this.filters.sGrandTotalMax = this.searchFilters.sGrandTotalMax = this.$route.query.sGrandTotalMax;
    }
    if (typeof this.$route.query.sTotalMin === "string") {
      this.filters.sTotalMin = this.searchFilters.sTotalMin = this.$route.query.sTotalMin;
    }
    if (typeof this.$route.query.sTotalMax === "string") {
      this.filters.sTotalMax = this.searchFilters.sTotalMax = this.$route.query.sTotalMax;
    }
    if (typeof this.$route.query.sDate === "string") {
      this.filters.sDate = this.searchFilters.sDate = this.$route.query.sDate;
    }
    if (typeof this.$route.query.eDate === "string") {
      this.filters.eDate = this.searchFilters.eDate = this.$route.query.eDate;
    }

    if (typeof this.$route.query.sStatus === "string") {
      this.filters.sStatus = [parseInt(this.$route.query.sStatus)];
    } else if (Array.isArray(this.$route.query.sStatus)) {
      this.filters.sStatus = this.$route.query.sStatus.map(s =>
        parseInt(s || "0")
      );
    }
    // if (this.currentRole != "Reseller") {
    //   this.filters.sStatus = [1,3,4,5,0];
    // }
    if (typeof this.$route.query.filterView === "string") {
      this.viewType = this.$route.query.filterView;
      this.selectedView = true;
    } else if (typeof this.$route.query.filterView === "number") {
      if (this.$route.query.tagIDs && Array.isArray(this.$route.query.tagIDs)) {
        this.filters.AccountTagID = this.$route.query.tagIDs.map(s =>
          parseInt(s || "0")
        );
      }
      // this.filters.AccountTagID.push(this.$route.query.filterView);
    }

    if (typeof this.$route.query.AccountTagID == "string") {
      this.filters.AccountTagID = [parseInt(this.$route.query.AccountTagID)];
    } else if (Array.isArray(this.$route.query.AccountTagID)) {
      this.filters.AccountTagID = this.$route.query.AccountTagID.map(s =>
        parseInt(s || "0")
      );
    }

    //switch to all company orders if redirect from dashboard (case: selected a tag)
    if (typeof this.$route.query.viewFrom != "undefined") {
      this.filters["viewFrom"] = this.$route.query.viewFrom;
      if (
        this.$allowedFunctions.includes(
          this.$userFunctions.All_Milestone_Accounts
        ) &&
        ((this.filters["viewFrom"] == "dashboard" &&
          typeof this.$route.query.filterView === "number") ||
          this.filters["viewFrom"] == "cDetails")
      ) {
        this.viewType = "All";
        this.selectedView = true;
      }
      if (
        typeof this.$route.query.gTagIDs != "undefined" &&
        Array.isArray(this.$route.query.gTagIDs) &&
        this.$route.query.gTagIDs.length
      ) {
        this.filters.gTagIDs = this.$route.query.gTagIDs.map(s =>
          parseInt(s || "0")
        );
      }
      if (
        typeof this.$route.query.groupIDs != "undefined" &&
        Array.isArray(this.$route.query.groupIDs) &&
        this.$route.query.groupIDs.length
      ) {
        this.filters.groupIDs = this.$route.query.groupIDs.map(s =>
          parseInt(s || "0")
        );
      }
    }

    //show unverify list case
    if (
      typeof this.$route.query.orderType != "undefined" &&
      this.$route.query.orderType == "unverify"
    ) {
      this.filterBtn = "unverify";
    }

    if (this.currentRole != "Reseller") {
      if (
        this.$route.query.sStatus == "2" ||
        this.$route.query.sStatus == "6" ||
        this.$route.query.sStatus == "7" ||
        this.$route.query.sStatus == "0" ||
        (this.$route.query.sStatus != "1" &&
          this.$route.query.sStatus != "3" &&
          this.$route.query.sStatus != "4" &&
          this.$route.query.sStatus != "5" &&
          this.$route.query.sStatus != "0")
      ) {
        this.filters.sStatus = [];

        this.$router.push({
          name: "Orders",
          params: {}
        });
      }
    }
    if (typeof this.$route.query.order === "string") {
      this.filters.order = this.searchFilters.order = this.$route.query.order;
    }

    await this.fetchData(false, true);
    await this.accsLoad();
    this.isInitial = true;

    if (this.currentRole === "Customer") {
      const response = await userRepo.getResellers();
      this.resellers = response.RESELLERS;
    }
  }

  @Watch("selectedheaders")
  colsChange(val: any) {
    colsResizeable();
  }

  get query() {
    const query = Object.entries(this.filters).reduce((a, [k, v]) => {
      if (Array.isArray(v)) {
        return v.length === 0 ? a : { ...a, [k]: v };
      }

      return v == null || v === "" || k == "unassigned" ? a : { ...a, [k]: v };
    }, {});
    return query;
  }
  async addHeader(c) {
    let reloadList = false;
    const addedCol = this.selectedheaders.includes(c);
    colsResizeableReset();
    const sortedHeaders: string[] = [];
    for (const ts in this.headerOrder) {
      const valIdx: string = this.headerOrder[ts];
      const i = this.selectedheaders.includes(valIdx);
      if (i) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedheaders = sortedHeaders;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedheaders.join(","),
      from: "order"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!this.selectedheaders.includes("STATUSTEXT")) {
      this.filters.statusName = undefined;
      if (this.currentRole == "Reseller") {
        this.filters.sStatus = [
          OrderStatus.Placed,
          OrderStatus.Received,
          OrderStatus.Shipped,
          OrderStatus.Delivered,
          OrderStatus.Paid,
          OrderStatus.Production,
          OrderStatus.PartialPay,
          OrderStatus.Complete,
          OrderStatus.CancelledComplete,
          0
        ];
      }
      // this.customViewStatusList();
    }

    if (!this.selectedheaders.includes("TOTALFORMATTED")) {
      this.filters.sTotalMin = this.searchFilters.sTotalMin = undefined;
      this.filters.sTotalMax = this.searchFilters.sTotalMax = undefined;
      this.filters.filterSubTotal = undefined;
      // this.resetSearchBySubTotal();
    }

    if (!this.selectedheaders.includes("SOURCE")) {
      this.filters.sSource = Object.keys(this.ordersData.sourcesObj);
      this.filters.sourceName = Object.values(this.ordersData.sourcesObj);
      // this.resetSourceList();
    }

    if (!this.selectedheaders.includes("CONTRACTNUMBER")) {
      this.filters.contract = Object.keys(this.ordersData.contractObj);
      this.filters.contractName = Object.values(this.ordersData.contractObj);
    }

    if (!this.selectedheaders.includes("TOTALPOTAX")) {
      this.filters.sTaxMin = this.searchFilters.sTaxMin = undefined;
      this.filters.sTaxMax = this.searchFilters.sTaxMax = undefined;
      this.filters.filterTax = undefined;
      // this.resetSearchByTax();
    }

    if (!this.selectedheaders.includes("POID")) {
      this.filters.searchId = this.searchFilters.searchId = "";
      // this.resetSearchById();
    }

    if (!this.selectedheaders.includes("PODESCRIPTION")) {
      this.filters.searchName = this.searchFilters.searchName = "";
      // this.resetSearchByName();
    }

    if (!this.selectedheaders.includes("PURCHASEID")) {
      this.filters.order = this.searchFilters.order = "";
      // this.resetSearchByOrder();
    }

    if (!this.selectedheaders.includes("CustomerPO")) {
      this.filters.resellerPo = this.searchFilters.resellerPo = "";
      // this.resetSearchByResellerPO();
    }

    if (!this.selectedheaders.includes("IndirectQuoteID")) {
      this.filters.indQuoteId = this.searchFilters.indQuoteId = "";
      // this.resetSearchByQuote();
    }

    if (!this.selectedheaders.includes("DirectLinePer")) {
      this.filters.DirectLinePerMin = this.searchFilters.DirectLinePerMin = undefined;
      this.filters.DirectLinePerMax = this.searchFilters.DirectLinePerMax = undefined;
      this.filters.filterPaycheck = undefined;
      // this.resetSearchByPercent();
    }

    if (!this.selectedheaders.includes("CUSTOMERORDERNUMBER")) {
      this.filters.customDataOrderNumber = this.searchFilters.customDataOrderNumber =
        "";
      // this.resetSearchByCustomDataOrderNumber();
    }

    if (!this.selectedheaders.includes("INVOICEDPERCENT")) {
      this.filters.InvoicedPerMin = this.searchFilters.InvoicedPerMin = undefined;
      this.filters.InvoicedPerMax = this.searchFilters.InvoicedPerMax = undefined;
      this.filters.filterInvoiced = undefined;
      // this.resetSearchByInvoicedPercent();
    }

    if (!this.selectedheaders.includes("ORDERVARCOSTTOTAL")) {
      this.filters.orderVarCostTotalMin = this.searchFilters.orderVarCostTotalMin = undefined;
      this.filters.orderVarCostTotalMax = this.searchFilters.orderVarCostTotalMax = undefined;
      this.filters.filterOrderVarCost = undefined;
      // this.resetSearchByOrderVarCostTotal();
    }

    if (!this.selectedheaders.includes("ENDUSERNAME")) {
      this.filters.endUserName = this.searchFilters.endUserName = undefined;
      // this.resetSearchByEndUserName();
    }

    if (!this.selectedheaders.includes("SHIPPINGNAME")) {
      this.filters.shippingName = this.searchFilters.shippingName = "";
      // this.resetSearchByShippingName();
    }

    if (!this.selectedheaders.includes("SHIPPINGADDRESS1")) {
      this.filters.shippingAddress1 = this.searchFilters.shippingAddress1 = "";
      // this.resetSearchByShippingName();
    }

    if (!this.selectedheaders.includes("DEPARTMENTNAME")) {
      this.filters.deptName = this.searchFilters.deptName = "";
    }

    if (!this.selectedheaders.includes("LEDGERBALANCEFORMATTED")) {
      this.filters.ledgerBalanceTotalMin = this.searchFilters.ledgerBalanceTotalMin = undefined;
      this.filters.ledgerBalanceTotalMax = this.searchFilters.ledgerBalanceTotalMax = undefined;
      this.filters.filterLedgerBalance = undefined;
    }

    if (
      !this.selectedheaders.includes("STATUSTEXT") ||
      !this.selectedheaders.includes("TOTALFORMATTED") ||
      !this.selectedheaders.includes("SOURCE") ||
      !this.selectedheaders.includes("TOTALPOTAX") ||
      !this.selectedheaders.includes("POID") ||
      !this.selectedheaders.includes("PODESCRIPTION") ||
      !this.selectedheaders.includes("PURCHASEID") ||
      !this.selectedheaders.includes("CustomerPO") ||
      !this.selectedheaders.includes("IndirectQuoteID") ||
      !this.selectedheaders.includes("DirectLinePer") ||
      !this.selectedheaders.includes("CUSTOMERORDERNUMBER") ||
      !this.selectedheaders.includes("INVOICEDPERCENT") ||
      !this.selectedheaders.includes("ENDUSERNAME") ||
      !this.selectedheaders.includes("SHIPPINGNAME") ||
      !this.selectedheaders.includes("SHIPPINGADDRESS1") ||
      !this.selectedheaders.includes("ORDERVARCOSTTOTAL") ||
      !this.selectedheaders.includes("DEPARTMENTNAME") ||
      !this.selectedheaders.includes("LEDGERBALANCEFORMATTED") ||
      !this.selectedheaders.includes("CONTRACTNUMBER")
    ) {
      this.pageNumber = 1;
      this.selectedID = [];
      this.selectedAll = false;
      this.excludedIDs = [];
      // await this.fetchData();
      reloadList = true;
    }

    if (addedCol && c.indexOf("customData") != -1) {
      // reload list if show custom fields on list
      reloadList = true;
    }

    if (reloadList) {
      await this.fetchData();
    }

    // if (i === -1) {
    //   this.selectedheaders.push(c);
    // } else {
    //   this.selectedheaders.splice(i, 1);
    // }
    colsResizeable();
  }

  async fetchData(
    isstatusfilter: boolean = false,
    initialLoad: boolean = false
  ) {
    this.hasChangedFilters = false;
    this.hasChangedAccountFilters = false;
    this.hasChangedSourceFilters = false;
    this.hasChangedNumberFilters = false;
    this.hasChangedCustomerFilters = false;
    this.loading = true;
    try {
      if (this.filterBtn == "currentList") {
        this.$router
          .replace({ path: this.$route.name, query: this.query })
          .catch(tmp => {});
      } else if (this.filterBtn == "unverify") {
        this.$router
          .replace({ path: this.$route.name, query: { orderType: "unverify" } })
          .catch(tmp => {});
      }
      // storing filter details in session for go back filter result
      if (JSON.parse(sessionStorage.filterDetails)["Orders"]) {
        var exisitngFilterDetails = JSON.parse(sessionStorage.filterDetails);
        exisitngFilterDetails["Orders"] = this.filters;
        exisitngFilterDetails["Quotes"] = {};
        exisitngFilterDetails["Invoices"] = {};
        exisitngFilterDetails["Opportunities"] = {};
        if (this.query != "undefined" && Object.keys(this.query).length != 0) {
          sessionStorage.setItem(
            "filterDetails",
            JSON.stringify(exisitngFilterDetails)
          );
        }
      }
      if (
        (this.query == "undefined" ||
          (this.query != "undefined" && Object.keys(this.query).length == 0)) &&
        Object.keys(JSON.parse(sessionStorage.filterDetails).Orders).length != 0
      ) {
        this.filters = this.searchFilters = JSON.parse(
          sessionStorage.filterDetails
        ).Orders;
      }
      if (
        typeof this.filters.unverifyStatus != "undefined" &&
        !this.filters.unverifyStatus.length &&
        initialLoad
      ) {
        this.filters.unverifyStatus = [UnverifyStatus.Open];
      }
      if (this.filterBtn == "unverify" && initialLoad) {
        this.pageNumber = 1;
      }
      const ordersData: any = await orderRepo.find(
        this.filters,
        this.sort,
        this.pageNumber,
        this.viewType,
        this.selectedView,
        isstatusfilter,
        this.filterBtn,
        initialLoad
      );
      if (ordersData.STATUS) {
        this.ordersData = ordersData;
        this.customerTooltipVisibleIndex = -1;
        this.details = ordersData;
        this.ISSEARCH = ordersData.ISSEARCH;
        this.TOTAL = ordersData.TOTAL;
        var date = new Date().getFullYear();
        this.curYear = date;
       /*  if (
          ordersData.UserOrderHeaders &&
          ordersData.UserOrderHeaders.length
        ) {
          this.selectedheaders = ordersData.UserOrderHeaders;
          if (this.$isHideReseller && this.selectedheaders.indexOf("ACCOUNTNAME") != -1) {
            this.selectedheaders.splice(
              this.selectedheaders.indexOf("ACCOUNTNAME"),
              1
            );
          }
        } */
        this.lYear = date - 1;
        if (typeof ordersData.STAT != "undefined") {
          this.CURRENTYEAR = ordersData.STAT[0][date];
          this.LASTYEAR = ordersData.STAT[1][date - 1];
        }
        if (
          (this.filters.endDate != undefined && this.filters.endDate != "") ||
          (this.filters.fromDate != undefined && this.filters.fromDate != "") ||
          this.filters.eDate != undefined ||
          this.filters.resellerCheckList.length != 0 ||
          this.filters.selectedAccs.length != 0 ||
          this.filters.AccountTagID.length != 0 ||
          this.filters.sDate != undefined ||
          (this.filters.sStatus.length != 0 &&
            this.filters.sStatus.length != 7) ||
          this.filters.sProfitMax != undefined ||
          this.filters.sProfitMin != undefined ||
          this.filters.sGrandTotalMax != undefined ||
          this.filters.sGrandTotalMin != undefined ||
          this.filters.sTotalMax != undefined ||
          this.filters.sTotalMin != undefined ||
          this.filters.searchAccountName != "" ||
          this.filters.searchAll != "" ||
          this.filters.searchId != "" ||
          this.filters.searchName != "" ||
          this.filters.sTaxMax != undefined ||
          this.filters.sTaxMin != undefined ||
          this.filters.endUserName != "" ||
          this.filters.shippingName != "" ||
          this.filters.shippingAddress1 != "" ||
          this.filters.deptName != ""
        ) {
          this.showFilter = 1;
        } else {
          this.showFilter = 0;
        }
        this.items = ordersData.ORDERS;
        if (
          this.searchTagsTxt == "" &&
          typeof this.details.accountTags != "undefined"
        ) {
          this.accountTags = this.details.accountTags;
        }
        if (
          (this.filters.AccountTagID && this.filters.AccountTagID.length) ||
          (this.filters.gTagIDs && this.filters.gTagIDs.length) ||
          (this.filters.groupIDs && this.filters.groupIDs.length)
        ) {
          // this.selectedView = true;
          this.CurrentView = this.getCurrentViewTitle();
          // if(!(this.filters.groupIDs && this.filters.groupIDs.length)) {
          //	this.viewType = "1"; //just set a number
          // }
        } else {
          // reset
          // this.selectedView = false;
        }
        // this.CurrentView = this.getCurrentViewTitle();

        if (
          !this.selectedView &&
          typeof ordersData["userSelectedCurrentView"] != "undefined"
        ) {
          this.selectedView = true;
          this.viewType = ordersData["userSelectedCurrentView"];
          this.CurrentView =
            this.viewType == "All" ? "Company Orders" : "My Orders";
        }

        if (typeof this.ordersData.TOTALUNVERIFIEDORDERS != "undefined") {
          this.unverifyNumber = this.ordersData.TOTALUNVERIFIEDORDERS;
        }

        if (
          this.filterBtn == "unverify" &&
          typeof ordersData["orderType"] != "undefined" &&
          ordersData["orderType"] == "unverify"
        ) {
          //this.TOTAL = this.unverifyNumber;
          /* this.unverifyNumber = this.ordersData.OPENUNVERIFYORDERS; */
          this.unverifyNumber = this.ordersData.TOTALUNVERIFIEDORDERS;
          this.unverifiedItems = ordersData.ORDERS;
          this.acctsInList = ordersData["ACCTSINLIST"];
          for (var i in this.unverifiedItems) {
            //do some mapping
            this.unverifiedItems[i].MC_ACCTID = this.unverifiedItems[
              i
            ].CUSTOMERNUMBER;
            this.unverifiedItems[i].MC_ACCTNAME = this.unverifiedItems[
              i
            ].CUSTOMERNAME;
            this.unverifiedItems[
              i
            ].MC_CDETAIL_ACCOUNTCITY = this.unverifiedItems[i].CUSTOMERCITY;
            this.unverifiedItems[
              i
            ].MC_CDETAIL_ACCOUNTSTATE = this.unverifiedItems[i].CUSTOMERSTATE;
            this.unverifiedItems[
              i
            ].MC_CDETAIL_ACCOUNTZIP = this.unverifiedItems[i].CUSTOMERZIP;
          }
        }
        if (
          typeof ordersData["userSelectedStatuses"] != "undefined" &&
          ordersData["userSelectedStatuses"] != "" &&
          !isstatusfilter
        ) {
          // this.selectedStatuses = true;
          this.filters.sStatus = ordersData["userSelectedStatuses"]
            .split(",")
            .map(val => parseInt(val));
        }
        if (
          typeof this.ordersData.sourcesObj != "undefined" &&
          this.filters.sSource.length == 0 &&
          initialLoad
        ) {
          this.filters.sSource = Object.keys(this.ordersData.sourcesObj);
          this.filters.sourceName = Object.values(this.ordersData.sourcesObj);
        }
        if (
          this.ordersData.contractObj &&
          !this.filters.contract.length &&
          initialLoad
        ) {
          this.filters.contract = Object.keys(this.ordersData.contractObj);
          this.filters.contractName = Object.values(
            this.ordersData.contractObj
          );
        }

        // append custom fields to custom colums list
        this.listCustomFields = ordersData.listCustomFields || [];
        for (const item of this.listCustomFields) {
          const field = `customData${item.CUSTOMFIELDID}`;
          if ((this.pageHeaders[field] || "") == "") {
            this.pageHeaders[field] = item.CUSTOMFIELDNAME;
          }
          if (!this.headerOrder.includes(field)) {
            this.headerOrder.push(field);
          }
        }
      } else {
        notifier.alert("No Orders found for the Account");
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }

    if (this.selectedAll === true) {
      this.selectAll(true);
    }
    //var checkAllPages = this.newPageOrder.includes(this.pageNumber);
    //if (checkAllPages)  {
    //	this.totalOrders = [];
    //	for (var val of this.items) {
    //		if (!this.excludedIDs.includes(val.PURCHASEID)) {
    //			this.totalOrders.push(val.PURCHASEID);
    //		}
    //	}
    //	var intersectingArrays = this.getArraysIntersection(this.totalOrders, this.selectedID);
    //	if (intersectingArrays.length == this.items.length) {
    //		this.ordersCheckAll = true;
    //	} else {
    //		this.ordersCheckAll = false;
    //	}
    //}
    //else {
    //	this.ordersCheckAll = false;
    //}
    this.totalOrders = [];
    for (var val of this.items) {
      if (this.selectedID.includes(val.PURCHASEID)) {
        this.totalOrders.push(val.PURCHASEID);
      }
    }
    if (this.items.length == this.totalOrders.length) {
      this.ordersCheckAll = true;
      this.selectPageAll = true;
    } else {
      this.ordersCheckAll = false;
      this.selectPageAll = false;
    }
    if (
      (this.filters.sStatus.length != 7 && this.isInitial) ||
      this.showFilter
    ) {
      this.isInitial = false;
    }
    if (!this.selectedheaders.includes("STATUSTEXT")) {
      this.filters.statusName = undefined;
    }
    if (
      this.filters.statusName != undefined &&
      this.filters.statusName.length == 0
    ) {
      for (var statusID in this.filters.sStatus) {
        if (
          $.inArray(
            OrderStatus[this.filters.sStatus[statusID]],
            this.filters.statusName
          ) == -1 &&
          OrderStatus[this.filters.sStatus[statusID]] != undefined
        ) {
          this.filters.statusName.push(
            OrderStatus[this.filters.sStatus[statusID]]
          );
        }
      }
    }

    if (this.filters["sDate"] != undefined) {
      this.startDate = moment(this.filters["sDate"]).format("MM/DD/YYYY");
    }
    if (this.filters["eDate"] != undefined) {
      this.endDate = moment(this.filters["eDate"]).format("MM/DD/YYYY");
    }

    if (
      this.filters["sProfitMin"] != undefined &&
      this.filters["sProfitMax"] != undefined
    ) {
      this.filters["filterProfit"] =
        "$" + this.filters["sProfitMin"] + " - " + this.filters["sProfitMax"];
    } else if (this.filters["sProfitMin"] != undefined) {
      this.filters["filterProfit"] = "$" + this.filters["sProfitMin"] + "+";
    } else if (this.filters["sProfitMax"] != undefined) {
      this.filters["filterProfit"] = "$" + this.filters["sProfitMax"] + "-";
    }

    if (
      this.filters["sTaxMin"] != undefined &&
      this.filters["sTaxMax"] != undefined
    ) {
      this.filters["filterTax"] =
        "$" + this.filters["sTaxMin"] + " - " + this.filters["sTaxMax"];
    } else if (this.filters["sTaxMin"] != undefined) {
      this.filters["filterTax"] = "$" + this.filters["sTaxMin"] + "+";
    } else if (this.filters["sTaxMax"] != undefined) {
      this.filters["filterTax"] = "$" + this.filters["sTaxMax"] + "-";
    }

    if (
      this.filters["sTotalMin"] != undefined &&
      this.filters["sTotalMax"] != undefined
    ) {
      this.filters["filterSubTotal"] =
        "$" + this.filters["sTotalMin"] + " - " + this.filters["sTotalMax"];
    } else if (this.filters["sTotalMin"] != undefined) {
      this.filters["filterSubTotal"] = "$" + this.filters["sTotalMin"] + "+";
    } else if (this.filters["sTotalMax"] != undefined) {
      this.filters["filterSubTotal"] = "$" + this.filters["sTotalMax"] + "-";
    }

    if (
      this.filters["sGrandTotalMin"] != undefined &&
      this.filters["sGrandTotalMax"] != undefined
    ) {
      this.filters["filterGrandTotal"] =
        "$" +
        this.filters["sGrandTotalMin"] +
        " - " +
        this.filters["sGrandTotalMax"];
    } else if (this.filters["sGrandTotalMin"] != undefined) {
      this.filters["filterGrandTotal"] =
        "$" + this.filters["sGrandTotalMin"] + "+";
    } else if (this.filters["sGrandTotalMax"] != undefined) {
      this.filters["filterGrandTotal"] =
        "$" + this.filters["sGrandTotalMax"] + "-";
    }

    if (this.startDate != undefined && this.endDate != undefined) {
      this.filters["filterDate"] = this.startDate + " - " + this.endDate;
    } else if (this.startDate != undefined) {
      this.filters["filterDate"] = this.startDate;
    } else if (this.endDate != undefined) {
      this.filters["filterDate"] = this.endDate;
    }

    if (
      this.filters["InvoicedPerMin"] != undefined &&
      this.filters["InvoicedPerMax"] != undefined
    ) {
      this.filters["filterInvoiced"] =
        this.filters["InvoicedPerMin"] + " - " + this.filters["InvoicedPerMax"];
    } else if (this.filters["InvoicedPerMin"] != undefined) {
      this.filters["filterInvoiced"] = this.filters["InvoicedPerMin"] + "+";
    } else if (this.filters["InvoicedPerMax"] != undefined) {
      this.filters["filterInvoiced"] = this.filters["InvoicedPerMax"] + "-";
    }

    if (
      this.filters["orderVarCostTotalMin"] != undefined &&
      this.filters["orderVarCostTotalMax"] != undefined
    ) {
      this.filters["filterOrderVarCost"] =
        this.filters["orderVarCostTotalMin"] +
        " - " +
        this.filters["orderVarCostTotalMax"];
    } else if (this.filters["orderVarCostTotalMin"] != undefined) {
      this.filters["filterOrderVarCost"] =
        this.filters["orderVarCostTotalMin"] + "+";
    } else if (this.filters["orderVarCostTotalMax"] != undefined) {
      this.filters["filterOrderVarCost"] =
        this.filters["orderVarCostTotalMax"] + "-";
    }

    if (
      this.filters["DirectLinePerMin"] != undefined &&
      this.filters["DirectLinePerMax"] != undefined
    ) {
      this.filters["filterPaycheck"] =
        this.filters["DirectLinePerMin"] +
        " - " +
        this.filters["DirectLinePerMax"];
    } else if (this.filters["DirectLinePerMin"] != undefined) {
      this.filters["filterPaycheck"] = this.filters["DirectLinePerMin"] + "+";
    } else if (this.filters["DirectLinePerMax"] != undefined) {
      this.filters["filterPaycheck"] = this.filters["DirectLinePerMax"] + "-";
    }

    if (
      this.filters["ledgerBalanceTotalMin"] != undefined &&
      this.filters["ledgerBalanceTotalMax"] != undefined
    ) {
      this.filters["filterLedgerBalance"] =
        this.filters["ledgerBalanceTotalMin"] +
        " - " +
        this.filters["ledgerBalanceTotalMax"];
    } else if (this.filters["ledgerBalanceTotalMin"] != undefined) {
      this.filters["filterLedgerBalance"] =
        this.filters["ledgerBalanceTotalMin"] + "+";
    } else if (this.filters["ledgerBalanceTotalMax"] != undefined) {
      this.filters["filterLedgerBalance"] =
        this.filters["ledgerBalanceTotalMax"] + "-";
    }
    this.usedFilterDetails = this.query;
    // this.testListv2();
    colsResizeable({ ms: 500 });
  }

  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }
  selectallStatus() {
    this.selectedID = this.ordersData.orderIds;
    $(".actionsBtn")
      .removeClass("gray")
      .addClass("teal");
  }
  UnselectallStatus() {
    this.selectedID = [];
    $(".actionsBtn")
      .addClass("gray")
      .removeClass("teal");
  }
  async sortingById() {
    this.sortBy(SortField.Id);
  }

  async sortingByName() {
    this.sortBy(SortField.Name);
  }

  async sortingByReseller() {
    this.sortBy(SortField.Customer);
  }

  async sortingByProfit() {
    this.sortBy(SortField.Profit);
  }

  async sortingByGrandTotal() {
    this.sortBy(SortField.GrandTotal);
  }

  async sortingByTotal() {
    this.sortBy(SortField.Total);
  }

  async sortingByDate() {
    this.sortBy(SortField.Date);
  }

  async sortingByStatus() {
    this.sortBy(SortField.Status);
  }

  async sortingByendUserName() {
    this.sortBy(SortField.EndUserName);
  }

  async sortingByOrderVarCost() {
    this.sortBy(SortField.OrderVarCost);
  }

  async sortingByUnverifyStatus() {
    this.sortBy(SortField.Order);
  }

  async sortingByReason() {
    this.sortBy(SortField.Source);
  }

  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }
  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      // const subAccounts = await CustomerRepo.findOneFewDetails(item.SECURITYGROUPID, FewDetailsJustGet.Users)
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  async searchChange(searchData) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.searchAll = searchData;
    await this.fetchData();
  }

  hasChangedFilters = false;
  hasChangedAccountFilters = false;
  hasChangedSourceFilters = false;
  hasChangedNumberFilters = false;
  hasChangedCustomerFilters = false;

  async checkStatusList(status: number, name: string) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      this.filters.sStatus != undefined &&
      this.filters.statusName != undefined
    ) {
      const i = this.filters.sStatus.findIndex(s => s === status);
      const statusIndex = this.filters.statusName.findIndex(
        statusValue => statusValue === name
      );
      if (i === -1) {
        this.filters.sStatus.push(status);
      } else {
        this.filters.sStatus.splice(i, 1);
      }

      if (statusIndex === -1) {
        this.filters.statusName.push(name);
      } else {
        this.filters.statusName.splice(statusIndex, 1);
      }
    }
    this.hasChangedFilters = true;
    // await this.fetchData(true);
  }

  async checkAllStatusList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.currentRole == "Reseller") {
      this.filters.sStatus = [
        OrderStatus.Placed,
        OrderStatus.Received,
        OrderStatus.Shipped,
        OrderStatus.Delivered,
        OrderStatus.Paid,
        OrderStatus.Production,
        OrderStatus.Cancelled,
        OrderStatus.PartialPay,
        OrderStatus.Complete,
        OrderStatus.CancelledComplete,
        0
      ];
    } else {
      this.filters.sStatus = [
        OrderStatus.Placed,
        OrderStatus.Shipped,
        OrderStatus.Delivered,
        OrderStatus.Paid,
        OrderStatus.PartialPay,
        OrderStatus.Complete,
        0
        // 8
      ];
    }
    this.filters.statusName = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async uncheckStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sStatus = [];
    this.filters.statusName = [];
    await this.fetchData();
  }

  async customViewStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.currentRole == "Reseller") {
      this.filters.sStatus = [
        OrderStatus.Placed,
        OrderStatus.Received,
        OrderStatus.Shipped,
        OrderStatus.Delivered,
        OrderStatus.Paid,
        OrderStatus.Production,
        OrderStatus.PartialPay,
        OrderStatus.Complete,
        OrderStatus.CancelledComplete,
        0
      ];
    }
    await this.fetchData();
  }

  async resetStatusList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sStatus = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllStatusList(allowFetchData);
    //await this.fetchData();
  }
  // Source filter

  async checkSourceList(status: string, name: string) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      this.filters.sSource != undefined &&
      this.filters.sourceName != undefined
    ) {
      const i = this.filters.sSource.findIndex(s => s === status);
      const sourceIndex = this.filters.sourceName.findIndex(
        sourceValue => sourceValue === name
      );
      if (i === -1) {
        this.filters.sSource.push(status);
      } else {
        this.filters.sSource.splice(i, 1);
      }
      if (sourceIndex === -1) {
        this.filters.sourceName.push(name);
      } else {
        this.filters.sourceName.splice(sourceIndex, 1);
      }
    }
    this.hasChangedSourceFilters = true;
    // await this.fetchData(true);
  }
  // Contract Number filter

  async checkContractNumberList(id: number, name: string) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      this.filters.contract != undefined &&
      this.filters.contractName != undefined
    ) {
      const i = this.filters.contract.findIndex(s => s === id);
      const contractIndex = this.filters.contractName.findIndex(
        contractValue => contractValue === name
      );
      if (i === -1) {
        this.filters.contract.push(id);
      } else {
        this.filters.contract.splice(i, 1);
      }
      if (contractIndex === -1) {
        this.filters.contractName.push(name);
      } else {
        this.filters.contractName.splice(contractIndex, 1);
      }
    }
    this.hasChangedNumberFilters = true;
    // await this.fetchData(true);
  }

  async checkAllSourceList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sSource = Object.keys(this.ordersData.sourcesObj);
    this.filters.sourceName = Object.values(this.ordersData.sourcesObj);
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async checkAllContractList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.contract = Object.keys(this.ordersData.contractObj);
    this.filters.contractName = Object.values(this.ordersData.contractObj);
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async uncheckSourceList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sSource = [];
    this.filters.sourceName = [];
    await this.fetchData();
  }

  async uncheckContractList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.contract = [];
    this.filters.contractName = [];
    await this.fetchData();
  }

  async resetSourceList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sSource = [];
    this.filters.sourceName = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllSourceList(allowFetchData);
    // await this.fetchData();
  }

  async resetContractList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.contract = [];
    this.filters.contractName = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllContractList(allowFetchData);
  }

  async resetSearchById(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchId = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    this.searchFilters.searchId = "";
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByName(allowFetchdata = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchName = this.searchFilters.searchName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchdata) {
      await this.fetchData();
    }
  }

  async resetSearchByAccountName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByProfit(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sProfitMin = this.searchFilters.sProfitMin = undefined;
    this.filters.sProfitMax = this.searchFilters.sProfitMax = undefined;
    this.filters.filterProfit = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sGrandTotalMin = this.searchFilters.sGrandTotalMin = undefined;
    this.filters.sGrandTotalMax = this.searchFilters.sGrandTotalMax = undefined;
    this.filters.filterGrandTotal = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchBySubTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin = undefined;
    this.filters.sTotalMax = this.searchFilters.sTotalMax = undefined;
    this.filters.filterSubTotal = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByPercent(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.DirectLinePerMin = this.searchFilters.DirectLinePerMin = undefined;
    this.filters.DirectLinePerMax = this.searchFilters.DirectLinePerMax = undefined;
    this.filters.filterPaycheck = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByInvoicedPercent(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.InvoicedPerMin = this.searchFilters.InvoicedPerMin = undefined;
    this.filters.InvoicedPerMax = this.searchFilters.InvoicedPerMax = undefined;
    this.filters.filterInvoiced = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByOrderVarCostTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.orderVarCostTotalMin = this.searchFilters.orderVarCostTotalMin = undefined;
    this.filters.orderVarCostTotalMax = this.searchFilters.orderVarCostTotalMax = undefined;
    this.filters.filterOrderVarCost = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByLedgerbalanceTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.ledgerBalanceTotalMin = this.searchFilters.ledgerBalanceTotalMin = undefined;
    this.filters.ledgerBalanceTotalMax = this.searchFilters.ledgerBalanceTotalMax = undefined;
    this.filters.filterLedgerBalance = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByEndUserName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.endUserName = undefined;
    this.searchFilters.endUserName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByShippingName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.shippingName = this.searchFilters.shippingName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByShippingAddress(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.shippingAddress1 = this.searchFilters.shippingAddress1 = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByDate(allowFetchdata = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.searchFilters.sDate == undefined ||
        this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = this.searchFilters.sDate = undefined;
      this.filters.eDate = this.searchFilters.eDate = undefined;
      this.filters.filterDate = undefined;
      this.startDate = undefined;
      this.endDate = undefined;
      $("#sDate").val("");
      $("#eDate").val("");
      if (allowFetchdata) {
        await this.fetchData();
      }
    }
  }

  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchId == "") {
      return;
    } else {
      this.filters.searchId = this.searchFilters.searchId;
      await this.fetchData();
    }
  }

  async searchByEndUserName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.endUserName == "") {
      return;
    } else {
      this.filters.endUserName = this.searchFilters.endUserName;
      await this.fetchData();
    }
  }

  async searchByShippingName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.shippingName == "") {
      return;
    } else {
      this.filters.shippingName = this.searchFilters.shippingName;
      await this.fetchData();
    }
  }

  async searchByShippingAddress() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.shippingAddress1 == "") {
      return;
    } else {
      this.filters.shippingAddress1 = this.searchFilters.shippingAddress1;
      await this.fetchData();
    }
  }

  async resetSearchByQuote(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.indQuoteId = this.searchFilters.indQuoteId = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByQuote() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.indQuoteId == "") {
      return;
    } else {
      this.filters.indQuoteId = this.searchFilters.indQuoteId;
      await this.fetchData();
    }
  }

  async resetSearchByResellerPO(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.resellerPo = this.searchFilters.resellerPo = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByResellerPO() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.resellerPo == "") {
      return;
    } else {
      this.filters.resellerPo = this.searchFilters.resellerPo;
      await this.fetchData();
    }
  }

  // Unverified list
  get filterByAccounts(): any[] {
    let options: {
      CUSTOMERNUMBER?: number;
      CUSTOMERNAME?: string;
      ACCOUNTID?: string;
      ACCOUNTALIAS?: string;
    }[] = this.acctsInList;
    if (this.unverifyAccSearch) {
      options = options.filter(
        option =>
          `${option.CUSTOMERNUMBER}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.CUSTOMERNAME}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.unverifyAccSearch.toLowerCase()) !== -1
      );
    }
    return options;
  }
  async searchByPo() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.customerPO == "") {
      return;
    } else {
      this.filters.customerPO = this.searchFilters.customerPO;
      await this.fetchData();
    }
  }

  async searchByAcc() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.customerName == "") {
      return;
    } else {
      this.filters.customerName = this.searchFilters.customerName;
      await this.fetchData();
    }
  }

  async resetsearchByPo() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.customerPO = this.searchFilters.customerPO = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetsearchByAcc() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.customerName = this.searchFilters.customerName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchName == "") {
      return;
    } else {
      this.filters.searchName = this.searchFilters.searchName;
      await this.fetchData();
    }
  }

  async searchByAccountName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchAccountName == "") {
      return;
    } else {
      this.filters.searchAccountName = this.searchFilters.searchAccountName;
      await this.fetchData();
    }
  }
  // Search by Order
  async searchByOrder() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.order == "") {
      return;
    } else {
      this.filters.order = this.searchFilters.order;
      await this.fetchData();
    }
  }

  async resetSearchByOrder(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.order = this.searchFilters.order = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sProfitMin = this.searchFilters.sProfitMin;
    this.filters.sProfitMax = this.searchFilters.sProfitMax;
    await this.fetchData();
  }

  async searchByGrandTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sGrandTotalMin = this.searchFilters.sGrandTotalMin;
    this.filters.sGrandTotalMax = this.searchFilters.sGrandTotalMax;
    await this.fetchData();
  }

  async searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin;
    this.filters.sTotalMax = this.searchFilters.sTotalMax;
    await this.fetchData();
  }

  async searchByDirectLinePer() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.DirectLinePerMin = this.searchFilters.DirectLinePerMin;
    this.filters.DirectLinePerMax = this.searchFilters.DirectLinePerMax;
    await this.fetchData();
  }

  async searchByInvoicedPer() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.InvoicedPerMin = this.searchFilters.InvoicedPerMin;
    this.filters.InvoicedPerMax = this.searchFilters.InvoicedPerMax;
    await this.fetchData();
  }

  async searchByOrderVarCostTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.orderVarCostTotalMin = this.searchFilters.orderVarCostTotalMin;
    this.filters.orderVarCostTotalMax = this.searchFilters.orderVarCostTotalMax;
    await this.fetchData();
  }

  async searchByLedgerbalanceTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.ledgerBalanceTotalMin = this.searchFilters.ledgerBalanceTotalMin;
    this.filters.ledgerBalanceTotalMax = this.searchFilters.ledgerBalanceTotalMax;
    await this.fetchData();
  }

  async searchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.sDate == undefined ||
        this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else if (
      !document!.querySelector<HTMLInputElement>("#sDate")!.validity!.valid ||
      !document!.querySelector<HTMLInputElement>("#eDate")!.validity!.valid
    ) {
      var errDate = "";
      if (
        !document!.querySelector<HTMLInputElement>("#sDate")!.validity!.valid
      ) {
        errDate += "start";
      }
      if (
        !document!.querySelector<HTMLInputElement>("#eDate")!.validity!.valid
      ) {
        if (errDate.length) {
          errDate += " & ";
        }
        errDate += " end ";
      }
      notifier.alert("Please Fill Valid " + errDate + " Date");
    } else {
      if (this.searchFilters.sDate == "") {
        this.filters.sDate = this.searchFilters.sDate = undefined;
        this.startDate = undefined;
      } else if (this.searchFilters.eDate == "") {
        this.filters.eDate = this.searchFilters.eDate = undefined;
        this.endDate = undefined;
      }
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.sDate = this.searchFilters.sDate;
      this.filters.eDate = this.searchFilters.eDate;
      await this.fetchData();
    }
  }

  async searchByUnverifyDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.fromDate == undefined ||
        this.searchFilters.fromDate == "") &&
      (this.searchFilters.endDate == "" ||
        this.searchFilters.endDate == undefined)
    ) {
      return;
    } else if (
      !document!.querySelector<HTMLInputElement>("#fromDate")!.validity!
        .valid ||
      !document!.querySelector<HTMLInputElement>("#endDate")!.validity!.valid
    ) {
      var errDate = "";
      if (
        !document!.querySelector<HTMLInputElement>("#fromDate")!.validity!.valid
      ) {
        errDate += "start";
      }
      if (
        !document!.querySelector<HTMLInputElement>("#endDate")!.validity!.valid
      ) {
        if (errDate.length) {
          errDate += " & ";
        }
        errDate += " end ";
      }
      notifier.alert("Please Fill Valid " + errDate + " Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.fromDate = this.searchFilters.fromDate;
      this.filters.endDate = this.searchFilters.endDate;
      await this.fetchData();
    }
  }

  async resetSearchByUnverifyDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.searchFilters.fromDate == undefined ||
        this.searchFilters.fromDate == "") &&
      (this.searchFilters.endDate == "" ||
        this.searchFilters.endDate == undefined)
    ) {
      return;
    } else {
      this.filters.fromDate = this.searchFilters.fromDate = undefined;
      this.filters.endDate = this.searchFilters.endDate = undefined;
      $("#fromDate").val("");
      $("#endDate").val("");
      await this.fetchData();
    }
  }

  /*async removeSelected() {
    this.loading = true
    this.confirmRemoveModalVisible = false
    let successCount = 0

    //for (const purchaseID of this.selectedID) {
      const reqBody = {
        session: sessionStorage.getItem('sessionID'),
        userRole: sessionStorage.getItem('userRole'),
        Controller: 'orders',
        FunctionName: 'Delete',
        //purchaseID,
        deletePurchase: 'true',
        deletePurchaseFile: 'true',
        selectedAll: this.selectedAll,
        excludedIDs: this.excludedIDs,
        selectedIDs: this.selectedID.join(",")
      };
      
      const response = await axios.post(dataURL + '?ReturnType=JSON', reqBody)
      if (response.data.STATUS !== 1) {
        if (response.data.ERROR || response.data.STATUSMESSAGE) {
          notifier.alert(response.data.ERROR || response.data.STATUSMESSAGE)
        }
        //continue
      }else {
        $(".actionsBtn").addClass('gray').removeClass('teal');
        notifier.success(`${successCount} Order(s) Deleted Successfully`)
        this.selectedID = [];
        this.selectedAll = false;
        this.excludedIDs = [];

        await this.fetchData()
      }

      //successCount++
    //}

    // if (successCount > 0) {
    //   $(".actionsBtn").addClass('gray').removeClass('teal');
    //   notifier.success(`${successCount} Order(s) Deleted Successfully`)
    //   this.selectedID = [];
    //   this.selectedAll = false;
    //   this.excludedIDs = [];
    // }
    // await this.fetchData()
    this.loading = false
  }*/

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if (
      sessionStorage.pageDetails != undefined &&
      JSON.parse(sessionStorage.pageDetails)["Orders"]
    ) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["Orders"] = this.pageNumber;
      sessionStorage.setItem("pageDetails", JSON.stringify(oldData));
    }
    if (this.pageNumber <= this.ordersData.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async htmlDownLoadFn() {
    const html = await this.selectedOrdersHtml("html");

    return () => {
      if (!html) return;
      var fileNameExport =
        "OrderList_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async selectedOrdersHtml(from: string): Promise<string | false> {
    try {
      const orderList = await orderRepo.exportOrderBySelect(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.exportHeadersArr
      );
      if (from == "print") {
        orderList["type"] = "print";
      }
      return template(orderList);
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async print() {
    await this.exportHeaders();
    this.loading = true;
    const html = await this.selectedOrdersHtml("print");
    this.loading = false;
    if (!html) return;

    printHtml(html);
  }

  async newDeployment(multiAccts) {
    // if (multiAccts == false) {
    //   this.$router.push({
    //     name: "CreateDeployment",
    //     params: {
    //       selectedIDs: this.selectedID.join(","),
    //       account: {
    //         ANAME: this.selectedAccounts[0],
    //         AID: this.selectedAccountID[0]
    //       }
    //     }
    //   });
    // }
  }

  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await orderRepo.exportListsPDFFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.exportHeadersArr
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await orderRepo.exportListsCSVFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async detailDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await orderRepo.exportOrderDetailFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async reportDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await orderRepo.reportOrderDetailFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  // showConfirmRemoveItemModal() {
  //   this.confirmRemoveModalVisible = true
  // }

  async performExport(
    formats: ExportFormat[],
    customtemplateId,
    saveAsDefaultTemplate,
    displayOnExport,
    bigdeal,
    exportPO,
    aztempidx,
    advancedFilters,
    customMessages,
    exportOrderDetail,
    reportingOrderDetail
  ) {
    this.loading = true;
    this.exportModalVisible = false;
    await this.exportHeaders();
    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    if (exportOrderDetail) {
      exportFns.push(await this.detailDownLoadFn());
    }

    if (reportingOrderDetail) {
      exportFns.push(await this.reportDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  async exportHeaders() {
    var widthSizes = {
      "col-1": 1,
      "col-1-5": 1.5,
      "col-2": 2,
      "col-2-5": 2.5,
      "col-3": 3,
      "col-3-5": 3.5,
      "col-4": 4,
      "col-5": 5,
      "col-6": 6
    };
    this.exportHeadersArr = [];
    for (var val of this.selectedheaders) {
      var cls = this.getColumnWidth(val).replace(" tar", "");
      cls = cls.replace(" lastSearchBox", "");
      cls = cls.replace(" textOverflow", "");
      var width = (widthSizes[cls] * 100) / 12 + "%";
      var tempObj = {
        header: "",
        column: "",
        widthPercent: ""
      };
      if (val === "PROFIT") {
        tempObj = {
          header: "MODIFIEDPROFIT",
          column: this.pageHeaders[val],
          widthPercent: width
        };
      } else {
        tempObj = {
          header: val,
          column: this.pageHeaders[val],
          widthPercent: width
        };
      }
      this.exportHeadersArr.push(tempObj);
    }
  }

  async filterByReseller() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async checkAllResellerList() {
    var comInstance = this;
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.resellerCheckList = [];
    this.resellers.forEach(function(val) {
      comInstance.filters.resellerCheckList.push(val.ACCOUNTID);
    });
    await this.fetchData();
  }

  async resetResellerList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.resellerCheckList = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async toggleCustomView(currStatus) {
    this.viewType = currStatus;
    this.selectedAll = false;
    this.excludedIDs = [];
    this.selectedID = [];
    this.selectedView = true;
    this.pageNumber = 1;
    if (typeof this.filters["viewFrom"] != "undefined") {
      this.filters["viewFrom"] = "";
      if (
        typeof this.filters["gTagIDs"] != "undefined" &&
        this.filters["gTagIDs"]
      ) {
        this.filters["gTagIDs"] = [];
        this.filters.AccountTagID = [];
        this.filters.accountTagName = [];
      }
      if (
        typeof this.filters["groupIDs"] != "undefined" &&
        this.filters["groupIDs"]
      ) {
        this.filters["groupIDs"] = [];
        this.filters.AccountTagID = [];
        this.filters.accountTagName = [];
      }
    }

    await this.fetchData();
    await this.accsLoad();
  }

  selectAll(selectedAll = true) {
    this.ordersCheckAll = selectedAll;
    this.excludedIDs = [];
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.items) {
        if (!this.excludedIDs.includes(val.PURCHASEID)) {
          this.selectedID.push(val.PURCHASEID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }
  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }
  checkExcludedIDs(items, key, purchaseID = 0) {
    if (this.selectedAll || this.selectPageAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
            this.ordersCheckAll = false;
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.TOTAL) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
    if (purchaseID) {
      if (this.selectedID.includes(purchaseID)) {
        this.totalOrders.push(purchaseID);
      } else {
        this.$delete(
          this.totalOrders,
          this.totalOrders.findIndex(id => id == purchaseID)
        );
      }
    }
    if (
      this.items.length == this.totalOrders.length &&
      this.items.length != 0
    ) {
      this.ordersCheckAll = true;
      this.selectPageAll = true;
    } else {
      this.ordersCheckAll = false;
      this.selectPageAll = false;
    }
  }

  commInfoLoading = false;
  async showCommInfo(index, item) {
    if (this.commInfoVisibleIndex === index || item.COMMISSIONPERCENT == 0) {
      return;
    }

    this.orderCommInfo = {};
    this.commInfoVisibleIndex = index;
    this.commInfoLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "GetCommissionLinesByPONumber",
        poNumber: item.POID
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (
        response.data.STATUS == 1 &&
        typeof response.data.COMMISSIONROWS != "undefined"
      ) {
        this.orderCommInfo = response.data.COMMISSIONROWS;
        this.orderCommInfo["PAYMENTPERCENTFORMATTED"] =
          response.data.PAYMENTPERCENTFORMATTED;
      }
    } catch (err) {
      //handle error
    } finally {
      this.commInfoLoading = false;
    }
  }

  resetSelected() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
  }

  //filter by Tags
  async filterByTag(tagName = "", groupName = "") {
    this.hasChangedAccountFilters = true;
    this.tagIDs = [];
    if (
      (this.filters.AccountTagID && this.filters.AccountTagID.length) ||
      (this.filters.gTagIDs && this.filters.gTagIDs.length) ||
      (this.filters.groupIDs && this.filters.groupIDs.length)
    ) {
      this.selectedView = true;
      this.CurrentView = this.getCurrentViewTitle();
      // if(!(this.filters.groupIDs && this.filters.groupIDs.length)) {
      // this.viewType = "1"; //just set a number
      // }
    } else {
      // reset
      this.selectedView = false;

      if (
        !this.$allowedFunctions.includes(
          this.$userFunctions.All_Milestone_Accounts
        )
      ) {
        this.CurrentView = "My Orders";
        this.viewType = "CurrentUsers";
      }
    }

    if (tagName != "" && this.filters.accountTagName != undefined) {
      const tagIndex = this.filters.accountTagName.findIndex(
        tagValue => tagValue === tagName
      );
      if (tagIndex === -1) {
        this.filters.accountTagName.push(tagName);
      } else {
        this.filters.accountTagName.splice(tagIndex, 1);
      }
    }

    if (groupName != "" && this.filters.groupName != undefined) {
      const groupIndex = this.filters.groupName.findIndex(
        groupValue => groupValue === groupName
      );
      if (groupIndex === -1) {
        this.filters.groupName.push(groupName);
      } else {
        this.filters.groupName.splice(groupIndex, 1);
      }
    }

    // this.resetSelected();
    // this.pageNumber = 1;
    // await this.fetchData();
  }
  async checkAllTag() {
    this.resetSelected();
    var $this = this;
    this.filters.AccountTagID = [];
    this.filters.accountTagName = [];
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == "undefined") {
        if (typeof val.ISGLOBALTAG == "undefined") {
          if (
            $this.filters.AccountTagID != undefined &&
            $this.filters.accountTagName != undefined
          ) {
            $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
            $this.filters.accountTagName.push(val.TAGNAME);
          }
        } else {
          $this.filters.gTagIDs.push(val.ACCOUNTTAGID);
          if ($this.filters.accountTagName != undefined) {
            $this.filters.accountTagName.push(val.TAGNAME);
          }
        }
      }
    });
    this.filterByTag();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    this.resetSelected();
    var $this = this;
    this.filters.groupIDs = [];
    this.filters.groupName = [];
    this.accountTags.forEach(function(val: any) {
      if (
        $this.filters.groupIDs &&
        !isNaN(val.ACCOUNTTAGID) &&
        typeof val.ISGROUP != "undefined"
      ) {
        if (
          $this.filters.groupIDs != undefined &&
          $this.filters.groupName != undefined
        ) {
          $this.filters.groupIDs.push(val.ACCOUNTTAGID);
          $this.filters.groupName.push(val.TAGNAME);
        }
      }
    });
    this.filterByTag();
    // this.filters.groupIDs = this.accountTags.map(val => val.ACCOUNTTAGID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag(allowFetchData = true) {
    this.resetSelected();
    this.filters.AccountTagID = [];
    this.filters.accountTagName = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    this.filters.groupName = [];
    this.pageNumber = 1;
    this.filterByTag();
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  filterTagsInBox() {
    let options = this.details["accountTags"];

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }

  itemProfit(item) {
    return item.PROFIT;
  }
  toggleActive(div) {
    this.isActive = div;
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc(accountsName: string) {
    this.hasChangedAccountFilters = true;
    if (
      accountsName != undefined &&
      this.filters.selectedAccsName != undefined
    ) {
      const accountsIndex = this.filters.selectedAccsName.findIndex(
        accountsValue => accountsValue === accountsName
      );
      if (accountsIndex === -1) {
        this.filters.selectedAccsName.push(accountsName);
      } else {
        this.filters.selectedAccsName.splice(accountsIndex, 1);
      }
    }
    // this.resetSelected();
    // this.pageNumber = 1;
    // await this.fetchData();
  }
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.filters.selectedAccsName = this.varAccs.map(val => val.ANAME);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.filters.selectedAccsName = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  //Filter by department name
  async searchByDeptName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.deptName == "") {
      return;
    } else {
      this.filters.deptName = this.searchFilters.deptName;
      await this.fetchData();
    }
  }

  //Filter by department name
  async resetSearchByDeptName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.deptName = this.searchFilters.deptName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async accsLoad() {
    // if (this.searchAccTxt == "") {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        FunctionName: "ListExt",
        controller: "Accounts",
        queryType: "top5UsedAcct",
        view: this.viewType,
        isOrder: 1,
        accSearch: 1
      });
      if (response.data.STATUS) {
        this.varAccs = response.data.ACCOUNTS;
        this.selectedAccs = response.data.VARACCOUNTSIDSRR;
        this.varAccsList = response.data.ACCOUNTS;
        if (this.searchAccTxt != "") {
          await this.filterAccsInBox();
        }
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
    // }
  }

  toggleFilterBtn(filterBtn) {
    if (this.filterBtn == filterBtn) {
      return;
    }

    this.pageNumber = 1;
    this.filterBtn = filterBtn;
    this.resetSelected();
    this.items = [];
    this.unverifiedItems = [];
    this.fetchData(false, true);
  }

  linkAccountTooltipVisible = -1;
  async linkAccount(linkAccountId: string, acc) {
    this.linkAccountTooltipVisible = -1;
    this.currentCustIndex = -1;
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "InsertAccountLinking",
        aID: acc.AID,
        LinkAccountId: linkAccountId
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS == 1) {
        await this.fetchData();
        notifier.success(
          "Linked " + linkAccountId + " to " + acc.ACCOUNTID + " - " + acc.ANAME
        );
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  filterByUnassignedAccounts() {
    this.resetSelected();
    this.pageNumber = 1;
    this.filters.selectedUnverifyAccts = [];
    this.fetchData();
  }
  filterBySelectedUnverifyAccts() {
    this.resetSelected();
    this.pageNumber = 1;
    this.fetchData();
  }
  searchForUnverifiedOrders(modalFilters) {
    this.resetSelected();
    this.pageNumber = 1;
    this.OrderSearchModalVisible = false;

    //map to page filters
    this.filters.customerPO = this.searchFilters.customerPO =
      typeof modalFilters.customerPO != "undefined"
        ? modalFilters.customerPO
        : "";
    this.filters.customerNumber =
      typeof modalFilters.customerNumber != "undefined"
        ? modalFilters.customerNumber
        : "";
    this.filters.customerName = this.searchFilters.customerName =
      typeof modalFilters.customerName != "undefined"
        ? modalFilters.customerName
        : "";
    this.filters.fromDate = this.searchFilters.fromDate =
      typeof modalFilters.fromDate != "undefined" ? modalFilters.fromDate : "";
    this.filters.endDate = this.searchFilters.endDate =
      typeof modalFilters.endDate != "undefined" ? modalFilters.endDate : "";

    this.fetchData();
  }

  SuggestedOrdersModalVisible = false;
  currentUnverifiedOrder = {};
  async showSuggestedOrders(item, index) {
    this.currentUnverifiedOrder = item;
    this.SuggestedOrdersModalVisible = true;
  }

  //generate orders from unverified orders
  selectSourceModalVisible = false;
  generateOrder() {
    if (this.selectedID.length == 0) {
      return;
    }

    //if selected multi sources orders
    var multiSources = false;
    for (var val of this.selectedItems) {
      var tmp = val["SOURCEIDS"].split(",");
      if (tmp.length > 1) {
        multiSources = true;
      }
    }
    if (multiSources) {
      //show modal to select a source
      this.selectSourceModalVisible = true;
    } else {
      this.goToCreateOrderPage();
    }
  }

  goToCreateOrderPage(selectedSource = 0) {
    if (this.selectedID.length == 0) {
      return;
    }
    for (var val of this.selectedID) {
      var tmpQuery = { orderType: "unverify" };
      if (selectedSource > 0) {
        tmpQuery["importSource"] = selectedSource;
      }
      let routeData = this.$router.resolve({
        path: "/generateOrder/" + val,
        query: tmpQuery
      });
      window.open(routeData.href, "_blank");
    }
  }

  saveSelectItems(item) {
    if (this.selectedID.includes(item.CUSTOMERPO)) {
      this.selectedItems.push(item);
    } else {
      let tmp = this.selectedItems.filter(
        (val: any) => val.CUSTOMERPO != item.CUSTOMERPO
      );
      this.selectedItems = tmp;
    }
  }

  setCustName(item) {
    console.dir(item);
    this.selectedAccounts.push(item.ACCOUNTNAME);
    this.selectedAccountID.push(item.AID);
  }

  sorting(c) {
    if (c == "POID") {
      this.sortingById();
    } else if (c == "PODESCRIPTION") {
      this.sortingByName();
    } else if (c == "ACCOUNTNAME") {
      this.sortingByReseller();
    } else if (c == "PROFIT") {
      this.sortingByProfit();
    } else if (c == "GRANDTOTALFORMATTED") {
      this.sortingByGrandTotal();
    } else if (c == "NEWDATEFORMATTED") {
      this.sortingByDate();
    } else if (c == "PURCHASEID") {
      this.sortBy(SortField.Order);
    } else if (c == "SOURCE") {
      this.sortBy(SortField.Source);
    } else if (c == "TOTALPOTAX") {
      this.sortBy(SortField.Tax);
    } else if (c == "DirectLinePer") {
      this.sortBy(SortField.DirectLinePer);
    } else if (c == "CustomerPO") {
      this.sortBy(SortField.CustomerPO);
    } else if (c == "CUSTOMERORDERNUMBER") {
      this.sortBy(SortField.CUSTOMERORDERNUMBER);
    } else if (c == "TOTALFORMATTED") {
      this.sortingByTotal();
    } else if (c == "INVOICEDPERCENT") {
      this.sortBy(SortField.InvoicedPer);
    } else if (c == "ENDUSERNAME") {
      this.sortingByendUserName();
    } else if (c == "ORDERVARCOSTTOTAL") {
      this.sortingByOrderVarCost();
    } else if (c == "DEPARTMENTNAME") {
      this.sortBy(SortField.DepartmentName);
    } else if (c == "LEDGERBALANCEFORMATTED") {
      this.sortBy(SortField.LedgerBalance);
    } else if (c == "CONTRACTNUMBER") {
      this.sortBy(SortField.ContractNumber);
    } else if (c == "SSURL") {
      this.sortBy(SortField.SSURL);
    } else {
      this.sortingByStatus();
    }
  }
  getColumnWidth(e, frm = 0) {
    var headerCls = "";
    switch (this.selectedheaders.length) {
      case 3:
        switch (e) {
          case "PROFIT":
            headerCls = "col-3";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-6";
            break;
          default:
            headerCls = "col-3 tar";
            break;
        }
        break;
      case 4:
        switch (e) {
          case "PROFIT":
            headerCls = "col-2 tar";
            break;
          case "ACCOUNTNAME":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("POID") ||
              this.selectedheaders.includes("IndirectQuoteID")
            ) {
              headerCls = "col-4";
            } else {
              headerCls = "col-6";
            }
            break;
          case "PODESCRIPTION":
            headerCls = "col-4";
            break;
          case "POID":
            headerCls = "col-4";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-2";
            break;
          case "PURCHASEID":
            headerCls = "col-2";
            break;
          case "STATUSTEXT":
            headerCls = "col-2";
            break;
          case "CustomerPO":
            headerCls = "col-2";
            break;
          case "IndirectQuoteID":
            headerCls = "col-4";
            break;
          case "SOURCE":
            headerCls = "col-2";
            break;
          default:
            headerCls = "col-2 tar";
            break;
        }
        break;
      case 5:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1-5 tar";
            break;
          case "ACCOUNTNAME":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("POID") ||
              this.selectedheaders.includes("IndirectQuoteID") ||
              this.selectedheaders.includes("CustomerPO")
            ) {
              headerCls = "col-4";
            } else {
              headerCls = "col-5";
            }
            break;
          case "PODESCRIPTION":
            if (
              this.selectedheaders.includes("POID") ||
              this.selectedheaders.includes("IndirectQuoteID") ||
              this.selectedheaders.includes("CustomerPO")
            ) {
              if (this.$isHideReseller) {
                headerCls = "col-4";
              } else {
                headerCls = "col-2-5";
              }
            } else {
              headerCls = "col-3";
            }
            break;
          case "POID":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("IndirectQuoteID") ||
              this.selectedheaders.includes("CustomerPO")
            ) {
              headerCls = "col-2-5";
            } else {
              headerCls = "col-3";
            }
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-2";
            break;
          case "PURCHASEID":
            headerCls = "col-2";
            break;
          case "STATUSTEXT":
            headerCls = "col-2";
            break;
          case "CustomerPO":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("IndirectQuoteID") ||
              this.selectedheaders.includes("POID")
            ) {
              headerCls = "col-2-5";
            } else {
              headerCls = "col-3";
            }
            break;
          case "IndirectQuoteID":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("POID") ||
              this.selectedheaders.includes("CustomerPO")
            ) {
              headerCls = "col-2-5";
            } else {
              headerCls = "col-3";
            }
            break;
          case "TOTALPOTAX":
            headerCls = "col-2";
            break;
          case "SOURCE":
            headerCls = "col-2";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-2";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      case 6:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1-5 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2-5";
            break;
          case "PODESCRIPTION":
            headerCls = "col-2";
            break;
          case "POID":
            headerCls = "col-2";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1-5";
            break;
          case "PURCHASEID":
            headerCls = "col-2";
            break;
          case "STATUSTEXT":
            if (this.selectedheaders.includes("PROFIT")) {
              headerCls = "col-3";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "CustomerPO":
            headerCls = "col-2";
            break;
          case "IndirectQuoteID":
            headerCls = "col-2";
            break;
          case "SOURCE":
            headerCls = "col-2";
            break;
          case "TOTALPOTAX":
            headerCls = "col-2";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-2-5";
            break;
          default:
            headerCls = "col-2 tar";
            break;
        }
        break;
      case 7:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1-5 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-3";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1-5";
            break;
          case "POID":
            headerCls = "col-1-5";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1-5";
            break;
          case "PURCHASEID":
            headerCls = "col-1-5";
            break;
          case "STATUSTEXT":
            headerCls = "col-1-5";
            break;
          case "CustomerPO":
            headerCls = "col-1-5";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1-5";
            break;
          case "SOURCE":
            headerCls = "col-1-5";
            break;
          case "TOTALPOTAX":
            headerCls = "col-1-5";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1-5";
            break;
          default:
            headerCls = "col-1-5 tar";
            break;
        }
        break;
      case 8:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2-5";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1-5";
            break;
          case "POID":
            headerCls = "col-1-5";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1-5";
            break;
          case "PURCHASEID":
            headerCls = "col-1-5";
            break;
          case "STATUSTEXT":
            headerCls = "col-1-5";
            break;
          case "CustomerPO":
            headerCls = "col-1-5";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1-5";
            break;
          case "SOURCE":
            headerCls = "col-1-5";
            break;
          case "TOTALPOTAX":
            headerCls = "col-1-5";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-2";
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
      case 9:
        var totalAdd = 0;
        if (!this.selectedheaders.includes("POID")) {
          totalAdd += 1;
        }
        if (!this.selectedheaders.includes("PODESCRIPTION")) {
          totalAdd += 1;
        }
        if (!this.selectedheaders.includes("CustomerPO")) {
          totalAdd += 1;
        }
        if (!this.selectedheaders.includes("IndirectQuoteID")) {
          totalAdd += 1;
        }
        // var totalsub = 0;
        // if (this.selectedheaders.includes("STATUSTEXT")) {
        // 	totalsub += 1;
        // }
        // if (this.selectedheaders.includes("SOURCE")) {
        // 	totalsub += 1;
        // }
        // if (this.selectedheaders.includes("PURCHASEID")) {
        // 	totalsub += 1;
        // }
        // if (this.selectedheaders.includes("TOTALPOTAX")) {
        // 	totalsub += 1;
        // }
        var includedHeaders = 4 - totalAdd;
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("POID") ||
              this.selectedheaders.includes("IndirectQuoteID") ||
              this.selectedheaders.includes("CustomerPO")
            ) {
              headerCls = "col-2";
            } else {
              headerCls = "col-3";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "PODESCRIPTION":
            if (
              this.selectedheaders.includes("POID") ||
              this.selectedheaders.includes("IndirectQuoteID") ||
              this.selectedheaders.includes("CustomerPO")
            ) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            if (includedHeaders == 2) {
              headerCls = "col-2";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "POID":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("IndirectQuoteID") ||
              this.selectedheaders.includes("CustomerPO")
            ) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            if (includedHeaders == 2) {
              headerCls = "col-2";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "CustomerPO":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("IndirectQuoteID") ||
              this.selectedheaders.includes("POID")
            ) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            if (includedHeaders == 2) {
              headerCls = "col-2";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "IndirectQuoteID":
            if (
              this.selectedheaders.includes("PODESCRIPTION") ||
              this.selectedheaders.includes("CustomerPO") ||
              this.selectedheaders.includes("POID")
            ) {
              headerCls = "col-1-5";
            } else {
              headerCls = "col-2";
            }
            if (includedHeaders == 2) {
              headerCls = "col-2";
            }
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "PURCHASEID":
            headerCls = "col-1";
            if (includedHeaders == 1 || includedHeaders == 3) {
              headerCls = "col-1-5";
            }

            break;
          case "STATUSTEXT":
            headerCls = "col-1";
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "SOURCE":
            headerCls = "col-1";
            if (includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "TOTALPOTAX":
            headerCls = "col-1";
            if (includedHeaders == 1 || includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            if (includedHeaders == 1 || includedHeaders == 3) {
              headerCls = "col-1-5";
            }
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
      case 10:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-2-5";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1-5";
            break;
          case "POID":
            headerCls = "col-1";
            if (this.selectedheaders.includes("PODESCRIPTION")) {
              headerCls = "col-1";
            } else {
              headerCls = "col-1-5";
            }
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "PURCHASEID":
            headerCls = "col-1";
            break;
          case "STATUSTEXT":
            headerCls = "col-1";
            break;
          case "CustomerPO":
            headerCls = "col-1";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1";
            break;
          case "SOURCE":
            headerCls = "col-1";
            break;
          case "TOTALPOTAX":
            headerCls = "col-1";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
      case 11:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-1-5";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1-5";
            break;
          case "POID":
            headerCls = "col-1";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "PURCHASEID":
            headerCls = "col-1";
            break;
          case "STATUSTEXT":
            headerCls = "col-1";
            break;
          case "CustomerPO":
            headerCls = "col-1";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1";
            break;
          case "SOURCE":
            headerCls = "col-1";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
      default:
        switch (e) {
          case "PROFIT":
            headerCls = "col-1 tar";
            break;
          case "ACCOUNTNAME":
            headerCls = "col-1";
            break;
          case "PODESCRIPTION":
            headerCls = "col-1";
            break;
          case "POID":
            headerCls = "col-1";
            break;
          case "NEWDATEFORMATTED":
            headerCls = "col-1";
            break;
          case "PURCHASEID":
            headerCls = "col-1";
            break;
          case "STATUSTEXT":
            headerCls = "col-1";
            break;
          case "CustomerPO":
            headerCls = "col-1";
            break;
          case "IndirectQuoteID":
            headerCls = "col-1";
            break;
          case "SOURCE":
            headerCls = "col-1";
            break;
          case "DEPARTMENTNAME":
            headerCls = "col-1";
            break;
          default:
            headerCls = "col-1 tar";
            break;
        }
        break;
    }
    if (
      e != "ACCOUNTNAME" &&
      e != "PROFIT" &&
      frm != 1 &&
      this.selectedheaders[0] != e
    ) {
      // headerCls += " textOverflow";
    }

    if (e.indexOf("customData") != -1) {
      headerCls = "col-1";
    }

    if (
      this.selectedheaders[this.selectedheaders.length - 1] == e ||
      this.selectedheaders[this.selectedheaders.length - 2] == e
    ) {
      headerCls += " lastSearchBox";
    }

    headerCls += ` col-${e.toLowerCase()}`;

    return headerCls;
  }
  getCurrentViewTitle() {
    if (
      (this.filters.AccountTagID && this.filters.AccountTagID.length) ||
      (this.filters.gTagIDs && this.filters.gTagIDs.length) ||
      (this.filters.groupIDs && this.filters.groupIDs.length)
    ) {
      var fulltitle: any = [];

      var $this = this;
      this.accountTags.forEach(function(val: any) {
        if (!isNaN(val.ACCOUNTTAGID)) {
          if (
            typeof val.ISGLOBALTAG == "undefined" &&
            typeof val.ISGROUP == "undefined" &&
            $this.filters.AccountTagID &&
            $this.filters.AccountTagID.includes(val.ACCOUNTTAGID)
          ) {
            fulltitle.push(val.TAGNAME);
          }
          if (
            typeof val.ISGLOBALTAG != "undefined" &&
            $this.filters.gTagIDs &&
            $this.filters.gTagIDs.includes(val.ACCOUNTTAGID)
          ) {
            fulltitle.push(val.TAGNAME);
          }
          if (
            typeof val.ISGROUP != "undefined" &&
            $this.filters.groupIDs &&
            $this.filters.groupIDs.includes(val.ACCOUNTTAGID)
          ) {
            fulltitle.push(val.TAGNAME);
          }
        }
      });
      return fulltitle.join(", ");
    }

    return "";
  }
  async selectView(item, e) {
    if (isNaN(item.ACCOUNTTAGID)) {
      // selected My Orders / company Orders
      this.tagIDs = [];
      this.filters.gTagIDs = [];
      this.filters.groupIDs = [];
      this.filters.AccountTagID = []; // reset
      this.selectedTags = []; // reset
      this.CurrentView = item.TAGNAME;
      this.viewType = item.ACCOUNTTAGID;
      this.selectedView = true;
      await this.fetchData();
      await this.accsLoad();
    } else {
      e.stopPropagation();
    }
  }
  async searchByTax() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sTaxMin = this.searchFilters.sTaxMin;
    this.filters.sTaxMax = this.searchFilters.sTaxMax;
    await this.fetchData();
  }
  async resetSearchByTax(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTaxMin = this.searchFilters.sTaxMin = undefined;
    this.filters.sTaxMax = this.searchFilters.sTaxMax = undefined;
    this.filters.filterTax = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async searchByCustomDataOrderNumber() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.customDataOrderNumber == "") {
      return;
    } else {
      this.filters.customDataOrderNumber = this.searchFilters.customDataOrderNumber;
      await this.fetchData();
    }
  }
  async resetSearchByCustomDataOrderNumber(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.customDataOrderNumber = this.searchFilters.customDataOrderNumber =
      "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  ordersCheckAll = false;
  ordersCheckAllChange() {
    this.ordersCheckAll = !this.ordersCheckAll;
    if (!this.ordersCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
  }
  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      this.selectedPage = this.pageNumber;
      this.newPageOrder.push(this.selectedPage);
      for (var val of this.items) {
        if (this.selectedID.indexOf(val.PURCHASEID) == -1) {
          this.selectedID.push(val.PURCHASEID);
        }
        if (this.totalOrders.indexOf(val.PURCHASEID) == -1) {
          this.totalOrders.push(val.PURCHASEID);
        }
        this.$delete(
          this.excludedIDs,
          this.excludedIDs.findIndex(id => id == val.PURCHASEID)
        );
      }
    } else {
      for (var val of this.items) {
        let selectedIDIndex = this.selectedID.indexOf(val.PURCHASEID);
        let totalOrdersIndex = this.totalOrders.indexOf(val.PURCHASEID);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalOrders.splice(totalOrdersIndex, 1);
      }
      let newPageOrderindex = this.newPageOrder.indexOf(this.pageNumber);
      this.newPageOrder.splice(newPageOrderindex, 1);
    }
  }
  getArraysIntersection(getOrdersOne, getOrdersTwo) {
    return getOrdersOne.filter(function(n) {
      return getOrdersTwo.indexOf(n) !== -1;
    });
  }
  validFilters(query: object) {
    var position = this.position;
    var arr: any[] = [];
    query["statusName"] = query["statusName"] ? query["statusName"] : "None";
    query["sourceName"] = query["sourceName"] ? query["sourceName"] : "None";
    query["contractName"] = query["contractName"]
      ? query["contractName"]
      : "No Filter";
    const queryFilters = Object.entries(query).reduce((a, [k, v]) => {
      if (k == "sStatus" && $.inArray(-1, v) != -1) {
        v.splice($.inArray(-1, v), 1);
      }
      if (k == "sStatus" || k == "statusName") {
        return v.length >= Object.keys(OrderStatus).length / 2
          ? a
          : { ...a, [k]: v };
      }
      if (
        (k == "sSource" || k == "sourceName") &&
        typeof this.ordersData.sourcesObj != "undefined"
      ) {
        return v.length == Object.keys(this.ordersData.sourcesObj).length
          ? a
          : { ...a, [k]: v };
      }
      if (
        (k == "contract" || k == "contractName") &&
        this.ordersData.contractObj
      ) {
        return v.length == Object.keys(this.ordersData.contractObj).length
          ? a
          : { ...a, [k]: v };
      }

      return v == null || v === "" || k == "unassigned" ? a : { ...a, [k]: v };
    }, {});

    $.each(queryFilters, function(key, valueObj) {
      if (position[key] != undefined) {
        let tempStruct: any = {};
        tempStruct[key] = valueObj;
        tempStruct["position"] = position[key];
        arr.push(tempStruct);
      }
    });
    var sortable = Object.entries(arr).sort(function(a, b) {
      return a[1].position - b[1].position;
    });
    var validFilters: any[] = [];
    for (var i in sortable) {
      let val = sortable[i];
      let lastItem = val[sortable[i].length - 1];
      let key = Object.keys(lastItem)[0];
      var value = Object.values(lastItem)[0];
      if (Array.isArray(value)) {
        value = value.join(" ,");
      }
      let tempFilterStruct: any = {};
      tempFilterStruct[key] = value;
      validFilters.push(tempFilterStruct);
    }
    return validFilters;
  }
  async clearFilter() {
    this.resetSearchById(false);
    this.resetSearchByName(false);
    this.resetSearchByAccountName(false);
    this.resetAcc(false);
    this.resetSearchByProfit(false);
    this.resetSearchByTax(false);
    this.resetSearchBySubTotal(false);
    this.resetSearchByTotal(false);
    this.resetSearchByDate(false);
    this.resetSearchByOrder(false);
    this.resetSourceList(false);
    this.resetSearchByResellerPO(false);
    this.resetSearchByInvoicedPercent(false);
    this.resetSearchByEndUserName(false);
    this.resetSearchByQuote(false);
    this.resetSearchByPercent(false);
    this.resetSearchByCustomDataOrderNumber(false);
    this.resetSearchByShippingName(false);
    this.resetSearchByShippingAddress(false);
    this.resetSearchByOrderVarCostTotal(false);
    this.resetSearchByDeptName(false);
    this.resetStatusList(false);
    this.resetTag(false);
    this.resetSearchByLedgerbalanceTotal(false);
    this.resetContractList(false);
    this.filterModalVisible = false;
    await this.fetchData();
  }
  async removeUnverifiedOrder(unOrderId) {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Orders",
      FunctionName: "UpdateUnverifiedOrderStatus",
      unOrderId: unOrderId
    });
    if (response.data.STATUS == 1) {
      await this.fetchData();
      this.confirmUnOrderRemove = false;
      notifier.success(response.data.STATUSMESSAGE);
    }
  }

  confirmRemoveUnOrder(removeId) {
    this.removeUnOrderId = removeId;
    this.confirmUnOrderRemove = true;
  }

  async checkUnverifyStatusList(status: number) {
    this.pageNumber = 1;
    if (typeof this.filters.unverifyStatus != "undefined") {
      const i = this.filters.unverifyStatus.findIndex(
        statusValue => statusValue === status
      );
      if (i === -1) {
        this.filters.unverifyStatus.push(status);
      } else {
        this.filters.unverifyStatus.splice(i, 1);
      }
      await this.fetchData();
    }
  }
  async checkDefaultUnverifyStatusList() {
    this.pageNumber = 1;
    this.filters.unverifyStatus = [UnverifyStatus.Open];
    await this.fetchData();
  }
  async uncheckAllUnverifyStatusList() {
    this.pageNumber = 1;
    this.filters.unverifyStatus = [-1];
    await this.fetchData();
  }
  async resetUnverifyStatusList() {
    this.pageNumber = 1;
    this.filters.unverifyStatus = [];
    this.checkDefaultUnverifyStatusList();
  }
  async searchByReason() {
    this.pageNumber = 1;
    if (this.searchFilters.reason == "") {
      return;
    } else {
      this.filters.reason = this.searchFilters.reason;
      await this.fetchData();
    }
  }
  async resetsearchByReason() {
    this.pageNumber = 1;
    this.filters.reason = this.searchFilters.reason = "";
    await this.fetchData();
  }

  removeColumn(header, x) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerOrder) {
      const valIdx: string = this.headerOrder[ts];
      const i = this.selectedheaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedheaders = sortedHeaders;
    this.addHeader(x);
  }

  getFilterValue(header) {
    let filter = "";
    let searchKey: string | undefined = "";
    switch (header) {
      case "POID":
        searchKey = this.filters.searchId;
        break;
      case "CustomerPO":
        searchKey = this.filters.resellerPo;
        break;
      case "IndirectQuoteID":
        searchKey = this.filters.indQuoteId;
        break;
      case "PODESCRIPTION":
        searchKey = this.filters.searchName;
        break;
      case "PURCHASEID":
        searchKey = this.filters.order;
        break;
      case "CUSTOMERORDERNUMBER":
        searchKey = this.filters.customDataOrderNumber;
        break;
      case "ACCOUNTNAME": {
        const arr: string[] = [];
        if (this.filters.searchAccountName) {
          arr.push(`Customer: ${this.filters.searchAccountName}`);
        }
        if (this.filters.gTagIDs.length || this.filters.AccountTagID.length) {
          arr.push(`Customer Tags: ${this.filters.accountTagName}`);
        }
        if (this.filters.selectedAccs.length) {
          arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
        }
        if (this.filters.groupIDs.length) {
          arr.push(`Customer Groups: ${this.filters.groupName}`);
        }
        searchKey = arr.join("; ");
        break;
      }
      case "ENDUSERNAME":
        searchKey = this.filters.endUserName;
        break;
      case "SHIPPINGNAME":
        searchKey = this.filters.shippingName;
        break;
      case "SHIPPINGADDRESS1":
        searchKey = this.filters.shippingAddress1;
        break;
      case "NEWDATEFORMATTED":
        searchKey = getDateRangeFilter(this.filters.sDate, this.filters.eDate);
        break;
      case "DEPARTMENTNAME":
        searchKey = this.filters.deptName;
        break;
      case "ORDERVARCOSTTOTAL":
        filter = this.getRangeFilter(
          this.filters.orderVarCostTotalMin,
          this.filters.orderVarCostTotalMax
        );
        searchKey = "";
        break;
      case "PROFIT":
        filter = this.getRangeFilter(
          this.filters.sProfitMin,
          this.filters.sProfitMax
        );
        searchKey = "";
        break;
      case "TOTALPOTAX":
        filter = this.getRangeFilter(
          this.filters.sTaxMin,
          this.filters.sTaxMax
        );
        searchKey = "";
        break;
      case "TOTALFORMATTED":
        filter = this.getRangeFilter(
          this.filters.sTotalMin,
          this.filters.sTotalMax
        );
        searchKey = "";
        break;
      case "GRANDTOTALFORMATTED":
        filter = this.getRangeFilter(
          this.filters.sGrandTotalMin,
          this.filters.sGrandTotalMax
        );
        searchKey = "";
        break;
      case "DirectLinePer":
        filter = this.getRangeFilter(
          this.filters.DirectLinePerMin,
          this.filters.DirectLinePerMax
        );
        searchKey = "";
        break;
      case "INVOICEDPERCENT":
        filter = this.getRangeFilter(
          this.filters.InvoicedPerMin,
          this.filters.InvoicedPerMax
        );
        searchKey = "";
        break;
      case "LEDGERBALANCEFORMATTED":
        filter = this.getRangeFilter(
          this.filters.ledgerBalanceTotalMin,
          this.filters.ledgerBalanceTotalMax
        );
        searchKey = "";
        break;
      case "STATUSTEXT":
        filter = this.getPreDefinedFilter(OrderStatus, this.filters.statusName);
        searchKey = "";
        break;
      case "SOURCE":
        if (this.filters.sourceName && !this.filters.sourceName.length)
          filter = "None";
        else if (
          this.filters.sourceName &&
          !(
            this.ordersData.sourcesObj &&
            Object.keys(this.ordersData.sourcesObj).length ==
            this.filters.sourceName.length
          )
        )
          filter = this.filters.sourceName.join(", ");
        break;
      case "CONTRACTNUMBER":
        if (this.filters.contractName && !this.filters.contractName.length)
          filter = "No Filter";
        else if (
          this.filters.contractName &&
          !(
            Object.keys(this.ordersData.contractObj).length ==
            this.filters.contractName.length
          )
        )
          filter = this.filters.contractName.join(", ");
        break;
      default:
        // nothing
        break;
    }
    return (
      (filter ? `${filter}` : "") +
      (searchKey ? (filter ? " - " : "") + searchKey : "")
    );
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`Min: ${min}`);
    }
    if (max) {
      arr.push(`Max: ${max}`);
    }
    return arr.join("; ");
  }

  getPreDefinedFilter(status, filter) {
    let statusLength = 0;
    if (this.currentRole == "Customer") {
      statusLength = 4;
    }
    if (!filter.length) {
      return "None";
    } else if (
      !(Object.keys(status).length / 2 - statusLength == filter.length)
    )
      return filter.join(", ");
  }

  async resetColumn(header) {
    this.loading = true;
    switch (header) {
      case "POID":
        this.resetSearchById();
        break;
      case "CustomerPO":
        this.resetSearchByResellerPO();
        break;
      case "IndirectQuoteID":
        this.resetSearchByQuote();
        break;
      case "PODESCRIPTION":
        this.resetSearchByName();
        break;
      case "PURCHASEID":
        this.resetSearchByOrder();
        break;
      case "CUSTOMERORDERNUMBER":
        this.searchByCustomDataOrderNumber();
        break;
      case "ACCOUNTNAME":
        this.resetSearchByAccountName(false);
        this.resetAcc(false);
        this.resetTag(false);
        await this.fetchData();
        break;
      case "ENDUSERNAME":
        this.resetSearchByEndUserName();
        break;
      case "SHIPPINGNAME":
        this.resetSearchByShippingName();
        break;
      case "SHIPPINGADDRESS1":
        this.resetSearchByShippingAddress();
        break;
      case "NEWDATEFORMATTED":
        this.resetSearchByDate();
        break;
      case "DEPARTMENTNAME":
        this.resetSearchByDeptName();
        break;
      case "PROFIT":
        this.resetSearchByProfit();
        break;
      case "TOTALPOTAX":
        this.resetSearchByTax();
        break;
      case "TOTALFORMATTED":
        this.resetSearchBySubTotal();
        break;
      case "GRANDTOTALFORMATTED":
        this.resetSearchByTotal();
        break;
      case "DirectLinePer":
        this.resetSearchByPercent();
        break;
      case "INVOICEDPERCENT":
        this.resetSearchByInvoicedPercent();
        break;
      case "ORDERVARCOSTTOTAL":
        this.resetSearchByOrderVarCostTotal();
        break;
      case "LEDGERBALANCEFORMATTED":
        this.resetSearchByLedgerbalanceTotal();
        break;
      case "STATUSTEXT":
        this.resetStatusList();
        break;
      case "SOURCE":
        this.resetSourceList();
        break;
      case "CONTRACTNUMBER":
        this.resetContractList();
        break;
      default:
        break;
    }
  }

  getListHeader(key: string) {
    let ret = "";
    if (key.indexOf("customData") != -1) {
      const fieldId = key.replace("customData", "");
      const inList = this.listCustomFields.find(
        item => item.CUSTOMFIELDID == fieldId
      );
      if (inList) {
        ret = inList.CUSTOMFIELDNAME || "";
      }
    } else {
      ret = this.pageHeaders[key];
    }
    return ret;
  }

  hasCustomData() {
    const inList = this.selectedheaders.find(
      key => key.indexOf("customData") != -1
    );
    if (inList) {
      return true;
    }

    return false;
  }

  getCustomDataField(header) {
    return `CUSTOMDATA${header.replace("customData", "")}`;
  }

  // listv2 = 0;
  // testListv2() {
  //   this.listv2 = 1;
  //   colsResizeable({ ms: 500 });
  // }

  checkSelectedAccounts() {
    var $this = this;
    // Check if all values in selectedAccounts array match the first element
    if (
      this.selectedAccounts.length > 0 &&
      this.selectedAccounts.every(function(value) {
        return value === $this.selectedAccounts[0];
      })
    ) {
      return false;
    } else {
      return true;
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  getFullURL(url) {
    if (!url) return "";
    let ret = url;
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      ret = "http://" + url;
    }
    return ret;
  }
}
