
















import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {}

interface Events {}

@Component({
  inheritAttrs: false
})
export default class InvoiceFormHeader extends TSXComponent<Props, Events> {
  // render(): VNode {
  //   return (
  //   );
  // }
}
