var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"os-font deployment-grid-view"},[_c('div',{staticClass:"bg-white py-2 mb-0"},[_c('div',{staticClass:"tmp-content"},[_c('div',{staticClass:"tmp-section position-relative"},[_c('div',{staticClass:"tmp-section-title"},[_vm._v(" ACTIVE ")]),(_vm.loading)?_c('Loader'):_vm._e(),((_vm.activeDeployments || []).length)?_c('div',{staticClass:"tmp-section-content"},[_c('div',{staticClass:"row"},_vm._l((_vm.activeDeployments || []),function(item,index){return _c('div',{key:index,staticClass:"col-4 p-0"},[_c('div',{staticClass:"tmp-card"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-3 p-0 d-flex justify-content-center"},[_c('div',{staticClass:"tmp-card-percent",class:{
                        'zero-percent': item.COMPLETIONPERCENT == 0,
                        'complete-percent': item.COMPLETIONPERCENT >= 100
                      }},[_c('svg',[_c('circle',{attrs:{"cx":"32","cy":"32","r":"32","fill":"none","stroke":"#eee"}}),(item.COMPLETIONPERCENT)?_c('circle',{attrs:{"cx":"32","cy":"32","r":"32","fill":"none","stroke":"#f00","stroke-width":"4","stroke-dasharray":201,"stroke-dashoffset":201 - (201 * item.COMPLETIONPERCENT) / 100,"transform":"rotate(180 32 32)"}}):_vm._e()]),_c('div',[_c('span',[_vm._v(_vm._s(item.COMPLETIONPERCENT || 0))]),_c('sup',[_vm._v("%")])])])]),_c('div',{staticClass:"col-9 p-0 up"},[_c('div',{staticClass:"tmp-card-title textOverflow",class:_vm.userRole == 'Reseller' ? 'deployment-name-link' : 'customerDeploymentLink'},[_c('router-link',{attrs:{"to":{
                          name: 'ViewDeployment',
                          params: {
                            id: item.DEPLOYMENTID
                          }
                        },"title":item.DEPLOYMENTNAME}},[_vm._v(" "+_vm._s(item.DEPLOYMENTNAME)+" ")])],1),_c('div',{staticClass:"tmp-card-summary"},[_c('span',{staticClass:"tmp-label-deploying"},[_vm._v("DEPLOYING")]),_c('span',[_vm._v(_vm._s(item.DEPLOYEDQTY)+" DEVICES")])]),_c('div',{staticClass:"tmp-card-summary textOverflow"},[_c('span',{attrs:{"title":item.ORDERIDS}},[_vm._v(" ORDERS "),_c('span',{class:{ 'text-grey': item.ORDERIDS == '' }},[_vm._v(" "+_vm._s(item.ORDERIDS || "N/A")+" ")])])]),_c('div',{staticClass:"tmp-card-summary"},[(item.SUMMARY)?_c('span',{staticClass:"text-truncate d-block",attrs:{"title":item.SUMMARY || ''}},[_vm._v(" "+_vm._s(item.SUMMARY || '')+" ")]):_c('span',{staticClass:"text-white"},[_vm._v(" . ")])]),(!_vm.$isHideReseller)?_c('div',{staticClass:"tmp-card-footer customer-info"},[_c('span',{staticClass:"text-truncate"},[(_vm.$parent.currentRole === 'Reseller')?_c('router-link',{attrs:{"to":{
                            name: 'ViewCustomer',
                            params: {
                              id: item.ACCOUNTID
                            }
                          },"title":item.ACCOUNTNAME}},[_vm._v(" "+_vm._s(item.ACCOUNTNAME)+" ")]):_c('router-link',{attrs:{"to":{
                            name: 'ViewDeployment',
                            params: {
                              id: item.DEPLOYMENTID
                            }
                          },"title":item.ACCOUNTNAME}},[_vm._v(" "+_vm._s(item.ACCOUNTNAME)+" ")])],1),(item.LOGOSRC && item.LOGOSRC.length)?_c('img',{attrs:{"src":item.LOGOSRC}}):_c('div',{staticClass:"circle",class:item.ICONCOLOR != ''
                            ? item.ICONCOLOR
                            : 'customerColor-op1'},[_c('p',{staticClass:"circle-inner"},[_vm._v(" "+_vm._s(_vm.$parent.getInitials(item.ACCOUNTNAME))+" ")])])]):_vm._e()])])])])}),0),_c('Pagination',{attrs:{"CURRENTPAGE":_vm.activeCurrentPage,"TOTALPAGES":_vm.activeTotalPages},on:{"loadPage":_vm.loadPageActive}})],1):(!_vm.loading)?_c('div',{staticClass:"noData"},[_vm._v(" No Records Found ")]):_vm._e()],1),_c('div',{staticClass:"tmp-section"},[_c('div',{staticClass:"tmp-section-title"},[_vm._v(" PRIOR / COMPLETED DEPLOYMENTS ")]),((_vm.completeDeployments || []).length)?_c('div',{staticClass:"tmp-section-content complete-deployments"},[_c('div',{staticClass:"row"},_vm._l((_vm.completeDeployments || []),function(item,index){return _c('div',{key:index,staticClass:"col-3 p-0 up"},[_c('div',{staticClass:"tmp-card"},[_c('div',{staticClass:"tmp-card-title textOverflow",class:_vm.userRole=='Reseller' ? 'deployment-name-link': 'customerDeploymentLink'},[_c('router-link',{staticClass:"textOverflow",attrs:{"to":{
                      name: 'ViewDeployment',
                      params: {
                        id: item.DEPLOYMENTID
                      }
                    },"title":item.DEPLOYMENTNAME}},[_vm._v(" "+_vm._s(item.DEPLOYMENTNAME)+" ")])],1),_c('div',{staticClass:"tmp-card-summary textOverflow"},[_c('span',{attrs:{"title":item.ORDERIDS}},[_vm._v(" ORDERS "),_c('span',{class:{ 'text-grey': item.ORDERIDS == '' }},[_vm._v(" "+_vm._s(item.ORDERIDS || "N/A")+" ")])])]),(!_vm.$isHideReseller)?_c('div',{staticClass:"tmp-card-footer customer-info"},[_c('span',{staticClass:"text-truncate"},[(_vm.$parent.currentRole === 'Reseller')?_c('router-link',{attrs:{"to":{
                        name: 'ViewCustomer',
                        params: {
                          id: item.ACCOUNTID
                        }
                      },"title":item.ACCOUNTNAME}},[_vm._v(" "+_vm._s(item.ACCOUNTNAME)+" ")]):_c('router-link',{attrs:{"to":{
                        name: 'ViewDeployment',
                        params: {
                          id: item.DEPLOYMENTID
                        }
                      },"title":item.ACCOUNTNAME}},[_vm._v(" "+_vm._s(item.ACCOUNTNAME)+" ")])],1),(item.LOGOSRC && item.LOGOSRC.length)?_c('img',{attrs:{"src":item.LOGOSRC}}):_c('div',{staticClass:"circle",class:item.ICONCOLOR != ''
                        ? item.ICONCOLOR
                        : 'customerColor-op1'},[_c('p',{staticClass:"circle-inner"},[_vm._v(" "+_vm._s(_vm.$parent.getInitials(item.ACCOUNTNAME))+" ")])])]):_vm._e()])])}),0),_c('Pagination',{staticClass:"complete-deployments-pagination",attrs:{"CURRENTPAGE":_vm.completeCurrentPage,"TOTALPAGES":_vm.completeTotalPages},on:{"loadPage":_vm.loadPageComplete}})],1):(!_vm.loading)?_c('div',{staticClass:"noData"},[_vm._v(" No Records Found ")]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }