


















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import {ApiHelper} from "@/helpers/all";

declare const $: any;
declare const dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
  }
})
export default class DeploymentOrderInfoTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: '' })
  purchaseID!: string;

  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  loading = true;

  orderData = {};

  $parent: any;

  async created() {
    const response = await ApiHelper.callApi('post', {
      controller: "Orders",
      FunctionName: "OrderInfo",
      purchaseID: this.purchaseID,
    });
    if (response.STATUS == 1) {
      this.orderData = response.ORDERDETAILS || {};
      this.loading = false;
    } else {
      ApiHelper.showErrorMessage(response.MESSAGE || 'Something was wrong');
    }
    console.log('response', response);
  }

  clickOutside() {
    this.$emit("close");
  }
}
