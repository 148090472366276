






























































































import { VNode } from "vue";
import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "./LaddaButton.vue";

declare const $: any
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class customizeModal extends TSXComponent<void> {

  @Prop({ required: true, default: {} })
  data!: any;

  $refs!: {
    modal: HTMLDivElement
    iconUpload: HTMLDivElement
  }

  saving = false;
  disabled = true;
  imageSrc = "";
  filePath = "";
  fileName = "";
  fileType = "";
  options = [];

  created() {
    this.imageSrc = this.data.imageSrc || "";
    this.options = this.data.options || [];
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  onFileChange(e) {
    const imageFile = e.target.files[0];
    this.imageSrc = URL.createObjectURL(imageFile);
    this.filePath = imageFile;
    this.fileName = imageFile.name.replace(/\s/g, '');
    this.fileType = imageFile.type;
    this.disabled = false;
  }

  onChangeImg() {
    $(this.$refs.iconUpload).click();
  }

  async save() {
    this.saving = true;
    if (this.fileType.length) {
      var reqData = {
        controller      : "CustomerPortals",
        FunctionName    : "uploadCustomizeIcon",
        filePath        : this.filePath,
        fileName        : this.fileName,
        fileType        : this.fileType
      }
      let formData = new FormData();
      for (let key in reqData) {
        formData.append(key, reqData[key]);
      }
      formData.append("session", sessionStorage.getItem("sessionID") || "");
      const response = await axios.post(dataURL + "?ReturnType=JSON", formData);
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if(response.data.STATUS == 1 && response.data.S3URL) {
        this.$emit("save", {
          item: this.data,
          imageSrc: response.data.S3URL,
          options: this.options,
        })
      }
    } else {
      this.$emit("save", {
        item: this.data,
        imageSrc: this.imageSrc,
        options: this.options,
      });
    }
    this.saving = false;
  }

  clear() {
    this.imageSrc = "";
    this.filePath = "";
    this.fileName = "";
    this.fileType = "";
    this.disabled = true;
    this.$emit("clear", {
      item: this.data
    });
  }

}
