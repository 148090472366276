









































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from 'axios'

import Datepicker from '../components/Datepicker.vue'
import LaddaButton from '../components/LaddaButton.vue'
import {notifier, wait} from '../models/common'

declare const dataURL: string;
declare const htmlCheck: Function;
declare const htmlParse: Function;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Datepicker,
    LaddaButton,
  },
})
export default class StatusCreate extends TSXComponent<void> {
  saving: boolean | 'error' = false
  $parent: any;
  $route: any;
  notes = "";
  OppStatusDetails = {
    note: "",
    type: 0,
    nextSteps: ""
  };

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: true,default: false })
  isConfirmed!: boolean;

  message = "";
  maxcharacter = 500;
  remaincharactersText = "500 characters remaining";
  remainNextStepsCharactersText = "500 characters remaining";

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $('.datepicker-container.datepicker-dropdown.datepicker-top-left').addClass('hide');
    $(this.$refs.modal).modal("hide");
  }

  data() {    
      const objDate = new Date();
      const statusDate = [(objDate.getMonth()+1 < 10 ? "0" : "")+(objDate.getMonth() + 1), (objDate.getDate() < 10 ? "0" : "")+objDate.getDate(), objDate.getFullYear()].join('/');
      
      return {
        OppStatusDetails: {
          note: "",
          type: 1,
          status: 0,
          date: statusDate,
          nextSteps: ""
        }
      }
  }

  async submit() { 
    if (this.OppStatusDetails.type == 7) {
      if(!this.OppStatusDetails.nextSteps.trim().length){
        return false;
      }
    } else {
      if(!this.OppStatusDetails.note.trim().length){
        return false;
      }
    }
    const OppStatusDetails = this.OppStatusDetails
    var htmlCount = 0;
    if (OppStatusDetails.note != "") {
      if (htmlCheck(OppStatusDetails.note)) {
        OppStatusDetails.note = htmlParse(OppStatusDetails.note);
        htmlCount++;
      }
    }
    if (OppStatusDetails.nextSteps.length) {
      if (htmlCheck(OppStatusDetails.nextSteps)) {
        OppStatusDetails.nextSteps = htmlParse(OppStatusDetails.nextSteps);
        htmlCount++;
      }
    }

    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
      return;
    }

    try {
      this.saving = true
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Opportunities",
        FunctionName: "opportunityNote",
        id: this.$route.params.id,
        userid: sessionStorage.getItem("userId"),
        type: OppStatusDetails.type,
        note: OppStatusDetails.type != 7 ? OppStatusDetails.note : '',
        nextSteps: OppStatusDetails.type == 7 ? OppStatusDetails.nextSteps : ''
      });

      console.dir(response);

      this.saving = false;
      this.$parent.notes = response.data.OPPORTUNITYNOTES;
      this.$parent.logs = response.data.OPPORTUNITYLOGS || [];
      $('.datepicker-container.datepicker-dropdown.datepicker-top-left').addClass('hide');
      $(this.$refs.modal).modal("hide");
    } catch (err) {
      this.saving = 'error'
      notifier.alert("Create note error: " + err.message);
    }
  }

  remaincharCount(type = "") {
    if (type == "nextSteps") {
      var remainCharacters = this.maxcharacter - this.OppStatusDetails.nextSteps.length;
      this.remainNextStepsCharactersText = remainCharacters + " characters remaining";
    } else if (type == "note") {
      var remainCharacters = this.maxcharacter - this.OppStatusDetails.note.length;
      this.remaincharactersText = remainCharacters + " characters remaining";
    }
  }
}
