
































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  title: string
  selectedIDs: string[],
  selectedAll: boolean,
  totalAll: number
}

interface Events {
  onClose: void
  onSave: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {}
})

export default class UpdateOrderStatusModal extends TSXComponent<
  Props,
  Events
> {
  @Prop({required: true})
  title!: string

  @Prop({required: true})
  selectedIDs!: string[]

  @Prop({required: false, default: false})
  selectedAll!: boolean

  @Prop({required: false, default: 0})
  totalAll!: number

  status = 'placed'

  saving = false

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  save() {
    this.saving = true
    this.$emit('save', {status: this.status})
  }
}
