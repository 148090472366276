
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class Print extends TSXComponent<void> {
  printHtml = "";

  mounted() {
    this.printHtml = sessionStorage.getItem("printHtml") || "";
    $("html head").remove();
    $("body").html(this.printHtml);
  }
}
