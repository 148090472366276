









































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import Loader from "../Loader.vue";
import LaddaButton from "../LaddaButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../../models/common";

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    LaddaButton,
    Datepicker
  }
})

export default class SnoozeTooltip extends TSXComponent<Props, Events> {
    @Prop({ required: true, default: {} })
    item! : any;

    $parent: any;
    saving = false;
    snoozeDay = false;
    snoozeWeek = false;
    snoozeDate = '';
    timeFrame = 1;

    clickOutside() {
        this.$emit("close");
    }

    created() {
        this.timeFrame = this.item.TIMEFRAME || 1;
        if ((this.item.DURATION || 1) == 1) {
            this.snoozeDay = true
        } else {
            this.snoozeWeek = true
        }
    }

    async save() {
        this.saving = true;
        let isNotShipped = false;
        let orderID = 0;
        let lineItem = 0;

        if(this.item.ALERTNAME == "Not Shipped Yet") {
            isNotShipped = true;
            orderID = this.item.ID;
            lineItem = extractText(this.item.CONTENT);
        }

        const response = await ApiHelper.callApi(
            'post',
            {
                controller: "Alerts",
                FunctionName: "Update",
                action: "updateSnooze",
                timeFrame: this.timeFrame,
                snoozeDate: this.snoozeDate,
                id: this.item.ID,
                alert: this.item.ALERTID,
                aID: this.item.AID,
                type: this.item.TYPE,
                durationType: this.snoozeDay ? 1 : 7,
                isNotShipped: isNotShipped,
                orderID: orderID,
                lineItem: lineItem,
                content: this.item.CONTENT
            }
        );
        if (response.STATUS === 1) {
            notifier.success(response.STATUSMESSAGE);
            this.$emit("close");
            this.$emit("updateTotalALert");
        } else {
           notifier.success('Something was wrong');
        }
        this.saving = false;

        function extractText(str) {
            const regex = /#(\S+)/;
            const match = str.match(regex);
            return match ? match[1] : '';
        }
    }

}
