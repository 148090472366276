










































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from "axios";
import {notifier} from '../models/common'
import LaddaButton from "../components/LaddaButton.vue"
import Loader from './Loader.vue';
import AccountTagSelect from "../components/AccountTagSelect.vue";

interface Props {
  title: string
  acctCommission: object[];
  selectedAccountID: number[];
}

interface Events {
  onClose: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Loader,
    AccountTagSelect
  }
})
export default class AccountTagsAssignModal extends TSXComponent<
  Props,
  Events
> {

  @Prop({required: true})
  title!: string

  @Prop({required: true})
  selectedAccountID!: []

  $parent: any;
  saving: any = false;
  loading = false;
  accounts: object[] = [];
  accountTags: any = [];
  selectedTagsID: number[] = [];
  selectedTags: object[] = [];
  reFetch = false;

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close', this.reFetch);
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async created() {
    this.loading = true;
    try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "Customers",
        FunctionName			: "AccountTagsList",
        getAll            : 1
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
      }
      
      if(response.data.STATUS == 1) {
        this.accountTags = response.data.ACCOUNTTAGS;

        setTimeout(() => {
          $(".account-tags #autoExpand").focus();
        }, 200);
      }
		}catch (err) {
      // console.log(err.message);
		}finally {
      this.loading = false;
		}
  }

  async confirm() {
    this.saving = true;
    try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "Customers",
        FunctionName			: "AccountTagsUpdate",
        action            : "save_multiTag_for_account",
        selectedTagsID    : this.selectedTagsID.join(","),
        selectedAccountID : this.selectedAccountID.join(",")
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
      }
      
      if(response.data.STATUS == 1) {
        this.saving = false;
        this.reFetch = true;
        this.$emit('close');
      }
		}catch (err) {
      this.saving = 'error';
		}finally {
      this.saving = false;
		}
  }

  toggleSelectedTags(selectedID: number, tagName) {
    const index = this.selectedTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedTagsID.push(selectedID);
      this.selectedTags.push({
        ACCOUNTTAGID: selectedID,
        TAGNAME: tagName
      });
      return;
    }

    this.selectedTagsID.splice(index, 1);
    this.$delete(this.selectedTags, index);
  }

  selectAllTags() {
    this.resetTags();
    this.selectedTagsID = this.accountTags.map((val: any) => val.ACCOUNTTAGID);
    this.selectedTags = this.accountTags.map((val: any) => ({
      ACCOUNTTAGID: val.ACCOUNTTAGID,
      TAGNAME: val.TAGNAME}));
  }

  resetTags() {
    this.selectedTags = [];
    this.selectedTagsID = [];
  }

  updateTagList(type, index, tagID) {
    if(this.selectedTagsID && type == "delete"){
      this.$delete(this.selectedTagsID, index);
      this.$delete(this.selectedTags, index);
    }
  }

  async createNewTag(tagName) {
    if(tagName != "") {
      var AccountTagSelect: any = this.$refs["AccountTagSelect"];
      try {
        AccountTagSelect.creating = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller				: "Customers",
          FunctionName			: "AccountTagsUpdate",
          action            : "create_new_tag",
          tagName           : tagName
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
          throw new Error(response.data.STATUSMESSAGE);
        }
        
        if(response.data.STATUS == 1 && response.data.NEWTAGID) {
          this.selectedTagsID.push(response.data.NEWTAGID);
          this.selectedTags.push({
            ACCOUNTTAGID: response.data.NEWTAGID,
            TAGNAME: tagName
          });
          this.accountTags.push({
            ACCOUNTTAGID: response.data.NEWTAGID,
            TAGNAME: tagName
          });
          AccountTagSelect.search = "";
          this.reFetch = true;
        }
        AccountTagSelect.creating = false;
      }catch (err) {
        // console.log(err.message);
      }finally {
        AccountTagSelect.creating = false;
      }
    }
  }
}
