



























import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";

import Loader from './Loader.vue'

interface Props {
  list: [];
  loading: boolean;
  accountID: string;
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
  }
})
export default class HPAgentDetailsRowTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: true })
  list!: [];

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: true })
  accountID!: "";
}
