







































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { AuditAPIRepo, Filters, Sort, SortField } from "../repos/AuditAPIRepo";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";

declare function require(params: any): any;

import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import { colsResizeable, getRangeFilter, getDateRangeFilter } from "@/helpers/ApiHelper";
import ListHeaderActions from "@/components/ListHeaderActions.vue";

const AuditRepo = new AuditAPIRepo();
declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    ListHeaderActions,
    Pagination,
    CustomerSearchTooltip,
    FilterCurrencyBox
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class Audit extends TSXComponent<void> {
  loading = false;
  viewType = "CurrentUsers";
  currentRole = sessionStorage.getItem("userRole");
	auditData: any = {};
  pageNumber = 1 ;
	items = [];
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  selectedAll = false;
  excludedIDs: string[] = [];
  selectedID: string[] = [];
  filters: Filters = {
    order: "",
    searchAccountName: "",
    sDate: undefined,
    eDate: undefined,
    OrderTotaltMin: undefined,
    OrderTotaltMax: undefined,
    InvoiceTotaltMin: undefined,
    InvoiceTotaltMax: undefined,
    pStatus: [0,1],
    TotalInvoiceMin: undefined,
    TotalInvoiceMax: undefined,
    NotInvoicedMin: undefined,
    NotInvoicedMax: undefined,
    AccountTagID: [],
    selectedAccs: [],
    groupIDs: [],
    gTagIDs: [],
    accountTagName: [],
    selectedAccsName: [],
    groupName: [],
    OrderName: ""
  };
  sort: Sort = {
    field: 0,
    direction: {
      [SortField.Order]: 1,
      [SortField.Customer]: 1,
      [SortField.Total]: 1,
      [SortField.Date]: 1,
      [SortField.OrderTotal]: 1,
      [SortField.InvoiceTotal]: 1,
      [SortField.FullPayment]: 1,
      [SortField.NotInvoicedTotal]: 1,
      [SortField.OrderName]: 1 
    }
  };
  toggleTotalDollar = true;
  accountTags: any = [];
  isActive = "customerAccounts";
  searchTagsTxt = "";
  searchAccTxt = "";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  searchFilters: Filters = {
    order: "",
    searchAccountName: "",
    sDate: undefined,
    eDate: undefined,
    OrderTotaltMin: undefined,
    OrderTotaltMax: undefined,
    InvoiceTotaltMin: undefined,
    InvoiceTotaltMax: undefined,
    pStatus: [0,1],
    TotalInvoiceMin: undefined,
    TotalInvoiceMax: undefined,
    NotInvoicedMin: undefined,
    NotInvoicedMax: undefined,
    AccountTagID: [],
    selectedAccs: [],
    groupIDs: [],
    gTagIDs: [],
    accountTagName: [],
    selectedAccsName: [],
    groupName: [],
    OrderName: ""
  };
  controllerName = "Finances";


  async created() {
    if (typeof this.$route.query.OrderTotaltMin === "string") {
      this.filters.OrderTotaltMin = this.searchFilters.OrderTotaltMin = this.$route.query.OrderTotaltMin;
    }
    if (typeof this.$route.query.OrderTotaltMax === "string") {
      this.filters.OrderTotaltMax = this.searchFilters.OrderTotaltMax = this.$route.query.OrderTotaltMax;
    }
    if (typeof this.$route.query.InvoiceTotaltMin === "string") {
      this.filters.InvoiceTotaltMin = this.searchFilters.InvoiceTotaltMin = this.$route.query.InvoiceTotaltMin;
    }
    if (typeof this.$route.query.InvoiceTotaltMax === "string") {
      this.filters.InvoiceTotaltMax = this.searchFilters.InvoiceTotaltMax = this.$route.query.InvoiceTotaltMax;
    }
    if (typeof this.$route.query.pStatus === "string") {
			this.filters.pStatus = [parseInt(this.$route.query.pStatus)];
		} else if (Array.isArray(this.$route.query.pStatus)) {
			this.filters.pStatus = this.$route.query.pStatus.map((s) =>
				parseInt(s || "0")
			);
		}
    if (typeof this.$route.query.TotalInvoiceMin === "string") {
      this.filters.TotalInvoiceMin = this.searchFilters.TotalInvoiceMin = this.$route.query.TotalInvoiceMin;
    }
    if (typeof this.$route.query.TotalInvoiceMax === "string") {
      this.filters.TotalInvoiceMax = this.searchFilters.TotalInvoiceMax = this.$route.query.TotalInvoiceMax;
    }
    if (typeof this.$route.query.NotInvoicedMin === "string") {
      this.filters.NotInvoicedMin = this.searchFilters.NotInvoicedMin = this.$route.query.NotInvoicedMin;
    }
    if (typeof this.$route.query.NotInvoicedMax === "string") {
      this.filters.NotInvoicedMax = this.searchFilters.NotInvoicedMax = this.$route.query.NotInvoicedMax;
    }
    await this.fetchData();
    await this.accsLoad();
  }
  async fetchData() {
    try {
      this.loading = true;
      const auditData = await AuditRepo.find(
        this.filters,
        this.sort,
        this.pageNumber,
        this.viewType,
        this.controllerName
			);
      this.auditData = auditData;
			this.items = auditData.AUDIT;
      console.log(auditData.AUDIT);
      if (
        this.searchTagsTxt == "" &&
        typeof this.auditData.accountTags != "undefined"
      ) {
        this.accountTags = this.auditData.accountTags;
      }
		} catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["Audit"] ){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["Audit"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (
      this.pageNumber <= this.auditData.TOTALPAGES &&
      this.pageNumber >= 1
    ) {
      await this.fetchData();
    }
  }

  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }
  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      // const subAccounts = await CustomerRepo.findOneFewDetails(item.SECURITYGROUPID, FewDetailsJustGet.Users)
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }
  async searchByOrder() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.order == "") {
      notifier.alert("Please Fill VAR360 Order");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.order = this.searchFilters.order;
      await this.fetchData();
    }
  }
  async searchByAccountName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchAccountName == "") {
      notifier.alert("Please Fill Customer Name");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.searchAccountName = this.searchFilters.searchAccountName;
      await this.fetchData();
    }
  }
  async searchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.sDate = this.searchFilters.sDate;
      this.filters.eDate = this.searchFilters.eDate;
      await this.fetchData();
    }
  }
  async searchByOrderTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.OrderTotaltMin = this.searchFilters.OrderTotaltMin;
    this.filters.OrderTotaltMax = this.searchFilters.OrderTotaltMax;
    await this.fetchData();
  }
  async searchByInvoiceTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.InvoiceTotaltMin = this.searchFilters.InvoiceTotaltMin;
    this.filters.InvoiceTotaltMax = this.searchFilters.InvoiceTotaltMax;
    await this.fetchData();
  }

  async searchByTotalInvoice() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.TotalInvoiceMin = this.searchFilters.TotalInvoiceMin;
    this.filters.TotalInvoiceMax = this.searchFilters.TotalInvoiceMax;
    await this.fetchData();
  }

  async searchByNotInvoiced() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.NotInvoicedMin = this.searchFilters.NotInvoicedMin;
    this.filters.NotInvoicedMax = this.searchFilters.NotInvoicedMax;
    await this.fetchData();
  }
  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.OrderName == "") {
      return;
    } else {
      this.filters.OrderName = this.searchFilters.OrderName;
      await this.fetchData();
    }
  }

  async checkStatusList(status: number) {
		this.selectedID = [];
		this.selectedAll = false;
		this.excludedIDs = [];
		const i = this.filters.pStatus.findIndex((s) => s === status);
		if (i === -1) {
			this.filters.pStatus.push(status);
		} else {
			this.filters.pStatus.splice(i, 1);
    }

		await this.fetchData();
  }
  async checkAllStatusList() {
		this.selectedID = [];
		this.selectedAll = false;
		this.excludedIDs = [];
		this.filters.pStatus = [0,1];
		await this.fetchData();
  }
  async resetSearchByOrder() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.order = this.searchFilters.order = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }
  async resetSearchByAccountName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async resetSearchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = this.searchFilters.sDate = undefined;
      this.filters.eDate = this.searchFilters.eDate = undefined;
      this.selectedAll = false;
      this.excludedIDs = [];
      await this.fetchData();
    }
  }
  async resetStatusList() {
		this.pageNumber = 1;
		this.selectedID = [];
		this.selectedAll = false;
		this.excludedIDs = [];
		this.checkAllStatusList();
		await this.fetchData();
	}
  async unCheckAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.pStatus = [];
    await this.fetchData();
  }
  async resetSearchByName(allowFetchdata = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.OrderName = this.searchFilters.OrderName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchdata) {
      await this.fetchData();
    }
  }
  async sortingByOrder() {
    this.sortBy(SortField.Order);
  }
  async sortingByReseller() {
    this.sortBy(SortField.Customer);
  }
  async sortingByTotalInvoice() {
    this.sortBy(SortField.Total);
  }
  async sortingByDate() {
    this.sortBy(SortField.Date);
  }
  async sortingByOrderTotal() {
    this.sortBy(SortField.OrderTotal);
  }
  async sortingByInvoiceTotal() {
    this.sortBy(SortField.InvoiceTotal);
  }
  async sortingByFullPayment() {
    this.sortBy(SortField.FullPayment);
  }
  async sortingByNotInvoicedTotal() {
    this.sortBy(SortField.NotInvoicedTotal);
  }
  async sortingByName() {
    this.sortBy(SortField.OrderName);
  }
  // Customer & User filter
  toggleActive(div) {
    this.isActive = div;
  }
  //filter by Tags
  async filterByTag(tagName = '', groupName = '') {
    this.selectedID = [];
    this.pageNumber = 1;

    if(tagName.length) {
      const tagIndex = this.filters.accountTagName.findIndex(tagValue => tagValue === tagName);
      if (tagIndex === -1) {
        this.filters.accountTagName.push(tagName);
      } else {
        this.filters.accountTagName.splice(tagIndex, 1);
      }
    }

    if(groupName.length) {
      const groupIndex = this.filters.groupName.findIndex(groupValue => groupValue === groupName);
      if (groupIndex === -1) {
        this.filters.groupName.push(groupName);
      } else {
        this.filters.groupName.splice(groupIndex, 1);
      }
    }
    await this.fetchData();
  }
  async checkAllTag() {
    this.selectedID = [];
    var $this = this;
		this.filters.AccountTagID = [];
		this.accountTags.forEach(function (val: any) {
			if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == 'undefined') {
				if (typeof val.ISGLOBALTAG == 'undefined') {
					if($this.filters.AccountTagID != undefined) {
						$this.filters.AccountTagID.push(val.ACCOUNTTAGID);
						$this.filters.accountTagName.push(val.TAGNAME);
					}
				}
				else {
					$this.filters.gTagIDs.push(val.ACCOUNTTAGID);
						$this.filters.accountTagName.push(val.TAGNAME);
				}
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    this.selectedID = [];
    var $this = this;
    this.filters.groupIDs = [];
    this.accountTags.forEach(function (val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
        $this.filters.groupIDs.push(val.ACCOUNTTAGID);
        $this.filters.groupName.push(val.TAGNAME);
      }
    });
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag(allowFetchData = true) {
    this.selectedID = [];
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    this.filters.accountTagName = [];
    this.filters.groupName = [];
    this.pageNumber = 1;
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  filterTagsInBox() {
    let options = this.auditData["accountTags"];

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }
  // Filter by Accounts
  async filterByAcc(accountsName: string) {
    this.selectedID = [];
    this.pageNumber = 1;

    if(accountsName != undefined) {
      const accountsIndex = this.filters.selectedAccsName.findIndex(accountsValue => accountsValue === accountsName);
      if (accountsIndex === -1) {
        this.filters.selectedAccsName.push(accountsName);
      } else {
        this.filters.selectedAccsName.splice(accountsIndex, 1);
      }
    }
    await this.fetchData();
  }
  async checkAllAcc() {
    this.selectedID = [];
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.filters.selectedAccsName = this.varAccs.map((val) => val.ANAME);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.selectedID = [];
    this.filters.selectedAccs = [];
    this.filters.selectedAccsName = [];
    this.pageNumber = 1;
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async accsLoad() {
    if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          isOrder: 1,
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }

  getFilterValue() {
    const arr: string[] = [];
    if(this.filters.searchAccountName) {
        arr.push(`Customer: ${this.filters.searchAccountName}`);
    }
    if(this.filters.gTagIDs.length || this.filters.AccountTagID.length) {
        arr.push(`Customer Tags: ${this.filters.accountTagName}`);
    }
    if(this.filters.selectedAccs.length) {
        arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
    }
    if(this.filters.groupIDs.length) {
        arr.push(`Customer Groups: ${this.filters.groupName}`);
    }
    return arr.join('; ');
  }

  async resetColumn() {
    this.resetSearchByAccountName(false);
    this.resetTag(false);
    this.resetAcc(false);
    this.resetSearchByName(false);
    await this.fetchData();
  }
}
