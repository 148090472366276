import axios from "axios";
import { IndirectOrderListResp } from "@/models/IndirectOrderListResp";

declare const dataURL: string;
declare const getMaxRows: Function

export type Filters = {
	searchId: string
	searchName: string
	searchAccountName: string
	sDate: string | undefined
	sPdate: string | undefined
	eDate: string | undefined
	ePdate: string | undefined
	sPaymentMin: string | undefined
	sPaymentMax: string | undefined
	sTotalMin: string | undefined
	sTotalMax: string | undefined
	AccountTagID: number[]
	groupIDs: number[]
	selectedAccs: (number | undefined)[]
	sMarginMin: string | undefined
	sMarginMax: string | undefined
	sInvoiceTaxMin: string | undefined
	sInvoiceTaxMax: string | undefined
	sInvoiceTotalMin: string | undefined
	sInvoiceTotalMax: string | undefined
	gTagIDs: number[]
}

export enum SortField {
	Id = 1,
	Name = 2,
	Customer = 3,
	Margin = 4,
	InvoiceTax = 5,
	InvoiceTotal = 6,
	PaidDate = 7
}

export type Sort = {
	field: SortField | null
	direction: {
		[SortField.Id]: 1 | 2,
		[SortField.Name]: 1 | 2,
		[SortField.Customer]: 1 | 2,
		[SortField.Margin]: 1 | 2,
		[SortField.InvoiceTax]: 1 | 2,
		[SortField.InvoiceTotal]: 1 | 2,
		[SortField.PaidDate]: 1 | 2
	}
}

export class IndirectOrderAPIRepo {
	async find(pageNumber: number, filters: Filters, sort: Sort): Promise<IndirectOrderListResp.Root> {
		const reqData: any = {
			"controller": "Finances",
			"FunctionName": "IndirectOrders",
			"PageNumber": pageNumber.toString(),
			"selectedTagIDs": filters.AccountTagID.join(","),
			"groupIDs": filters.groupIDs.join(","),
			"selectedAccountDs": filters.selectedAccs.join(","),
			"maxRows": 15,
			"gTagIDs": filters.gTagIDs.join(",")
		}
		if (filters.eDate) {
			reqData['eDate'] = filters.eDate
		}
		if (filters.sDate) {
			reqData['sDate'] = filters.sDate
		}
		if (filters.ePdate) {
			reqData['ePdate'] = filters.ePdate
		}
		if (filters.sPdate) {
			reqData['sPdate'] = filters.sPdate
		}
		if (filters.searchName) {
			reqData['sPurchaseName'] = filters.searchName
		}
		if (typeof filters.sPaymentMin !== 'undefined') {
			reqData['sPaymentMin'] = filters.sPaymentMin
		}
		if (typeof filters.sPaymentMax !== 'undefined') {
			reqData['sPaymentMax'] = filters.sPaymentMax
		}
		if (typeof filters.sTotalMin !== 'undefined') {
			reqData['sTotalMin'] = filters.sTotalMin
		}
		if (typeof filters.sTotalMax !== 'undefined') {
			reqData['sTotalMax'] = filters.sTotalMax
		}
		// if (filters.searchId) {
		// 	reqData['purchaseID'] = filters.searchId
		// }
		if (filters.searchAccountName) {
			reqData['sAccountName'] = filters.searchAccountName
		}
		if (filters.searchId) {
			reqData['invoiceID'] = filters.searchId
		}
		if (typeof filters.sMarginMin !== 'undefined') {
			reqData['sMarginMin'] = filters.sMarginMin
		}
		if (typeof filters.sMarginMax !== 'undefined') {
			reqData['sMarginMax'] = filters.sMarginMax
		}
		if (typeof filters.sInvoiceTaxMin !== 'undefined') {
			reqData['sInvoiceTaxMin'] = filters.sInvoiceTaxMin
		}
		if (typeof filters.sInvoiceTaxMax !== 'undefined') {
			reqData['sInvoiceTaxMax'] = filters.sInvoiceTaxMax
		}
		if (typeof filters.sInvoiceTotalMin !== 'undefined') {
			reqData['sInvoiceTotalMin'] = filters.sInvoiceTotalMin
		}
		if (typeof filters.sInvoiceTotalMax !== 'undefined') {
			reqData['sInvoiceTotalMax'] = filters.sInvoiceTotalMax
		}

		if (sort.field) {
			reqData["order"] = sort.field
			reqData["direction"] = sort.direction[sort.field].toString()
		}
		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);
		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}
}
