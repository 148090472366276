export const validateFn = {
  validateBeforeSubmit() {
    this.$validator.validateAll().then((result) => {
      if (result) {
        // eslint-disable-next-line
        // alert('Form Submitted!');
        return this.$root.saveFn(this.$root.items);
      }
    });
  }
};
