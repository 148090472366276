










































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "@/models/common";
import moment from "moment";

declare const $: any;
declare const dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: { LaddaButton }
})
export default class AddToScheduleGroupTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: -1 })
  currentIndex!: number;

  @Prop({ required: false, default: {} })
  currentItem!: any;

  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  @Prop({ required: false, default: [] })
  scheduleGroups!: any;

  @Prop({ required: false, default: {} })
  deploymentParams?: any;

  $parent: any;
  selectedGroupId = 0;
  loading = false;
  saving: boolean | string = false;
  availabilitiesList: any = [];
  currentRole = sessionStorage.getItem("userRole");

  clickOutside() {
    this.$emit("close");
  }

  async created() {
    this.selectedGroupId = this.currentItem.SCHEDULEGROUPID || 0;
    this.loading = true;
    const response = await ApiHelper.callApi("post", {
      Controller: "Deployments",
      FunctionName: "GetAvailabilitiesList",
      deploymentId: this.deploymentId
    });
    this.availabilitiesList = response.availabilitiesList || [];
    this.loading = false;
    // if group belongs to an availabilty has criteria not match with this user, disable selection this group
    for (const item of this.scheduleGroups) {
      item.disabled = false;

      if (!(item.AVAILABILITYID || 0)) {
        continue;
      }

      if (this.$parent.isLockedSchedule(item.AVAILABILITYID)) {
        item.disabled = true;
        continue;
      }

      const availability = this.availabilitiesList.find(
        t => t.available.availabilityId == item.AVAILABILITYID
      );
      if (!availability) continue;

      const criteriaFilters = availability.criteriaFilters || [];
      if (!criteriaFilters.length) continue;

      let matchCnt = 0;
      for (const filter of criteriaFilters) {
        if ((filter.value || []).length && filter.value[0] != "") {
          let dataKey = `CUSTOMDATA${filter.customFieldId}`;
          if (
            [
              "requestName",
              "submitterName",
              "submitterEmail",
              "leaseCoordinator"
            ].includes(filter.customFieldKey)
          ) {
            dataKey = filter.customFieldKey.toUpperCase();
          }
          const dataVal = this.currentItem[dataKey] || "";

          if (dataVal && filter.value.includes(dataVal)) {
            matchCnt += 1;
          }
        }
      }

      if (matchCnt != criteriaFilters.length) {
        item.disabled = true;
      }
    }
    this.$forceUpdate();
  }

  async addToGroup() {
    if (
      !this.selectedGroupId ||
      this.selectedGroupId == (this.currentItem.SCHEDULEGROUPID || 0)
    ) {
      return;
    }

    // if select option "setup new"
    if (this.selectedGroupId == -1) {
      this.$emit("setupNew");
      return;
    }

    // number of end users should not over the ability spots
    const selectedGroup = this.scheduleGroups.find(
      item => item.GROUPID == this.selectedGroupId
    );
    if (selectedGroup) {
      // const availability = this.availabilitiesList.find(
      //   item => item.available.availabilityId == selectedGroup.AVAILABILITYID
      // );
      // const eus = availability ? availability.eus || [] : [];
      // const allEUs = [...new Set([...eus, this.currentItem.USERID])];
      // if (
      //   availability.available.spots > 0 &&
      //   allEUs.length > availability.available.spots
      // ) {
      //   notifier.alert(
      //     `Exceeded Spots Available Per Block For This Availability (allow ${
      //       availability.available.spots
      //     })`
      //   );
      //   return;
      // }

      const availability = this.availabilitiesList.find(
        item => item.available.availabilityId == selectedGroup.AVAILABILITYID
      );
      const spots = availability
        ? parseInt(availability.available.spots || 0)
        : 0;
      if (spots && (selectedGroup.ENDUSERS || []).length + 1 > spots) {
        notifier.alert(
          `Exceeded Spots Available Per Block For This Availability (allow ${
            availability.available.spots
          })`
        );
        return;
      }
    }

    try {
      this.saving = true;
      const response = await ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "LinkToScheduleGroup",
        deploymentId: this.deploymentId,
        endUserId: this.currentItem.USERID,
        scheduleGroupId: this.selectedGroupId
      });
      if (response.STATUS == 1) {
        this.saving = false;
        // update linked to latest group
        const selectedGroup = this.scheduleGroups.find(
          item => item.GROUPID == this.selectedGroupId
        );
        if (selectedGroup) {
          this.currentItem.SCHEDULEGROUPID = selectedGroup.GROUPID;
          this.currentItem.SCHEDULEGROUPNAME = selectedGroup.GROUPNAME;
        }
        notifier.success("Saved successfully");

        this.$emit("close");
        this.$emit("reloadData");
      } else {
        this.saving = "error";
      }
    } catch (err) {
      this.saving = "error";
      console.log(err);
    }
  }
}
