





























































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from "axios";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";

interface commission {
  action?: string;
  accID?: string;
  percentage?: number;
  userId?: number;
  businessLineIdLists?: string;
}

interface Events {
  close: {
    reload;
  };
}
@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class CommissionEditAndDeleteModal extends TSXComponent<void> {
  isUpdate = true;
  deleting: any = false;

  $refs!: {
    modal: HTMLDivElement;
  };
  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close", false);
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }
  @Prop({ required: true })
  commissiondetails!: commission;

  created() {
    if (this.commissiondetails.action != "update") {
      this.isUpdate = false;
    }
  }
  async onCommissionSubmit() {
    if (!this.isUpdate) {
      this.commissiondetails.percentage = 0;
    }
    if (
      this.isUpdate &&
      (!this.commissiondetails.percentage ||
      isNaN(this.commissiondetails.percentage))
    ) {
      notifier.alert("Please enter valid percentage");
      return false;
    } else {
      if (
        this.isUpdate &&
        (typeof this.commissiondetails.percentage != 'undefined' &&
        (this.commissiondetails.percentage <= 0 ||
        this.commissiondetails.percentage >= 100))
      ) {
        notifier.alert("Please enter percentage below 100 and greater than 0");
      } else {
        this.deleting = true;
        var dataObj = {
          controller: "Users",
          FunctionName: "AccountCommission",
          userID: this.commissiondetails.userId,
          aID: this.commissiondetails.accID,
          percentage: this.commissiondetails.percentage,
          businessLineId: this.commissiondetails.businessLineIdLists
        };
        const response1 = await axios.post(
          dataURL + "?ReturnType=JSON",
          dataObj
        );

        if (response1.data.STATUS !== 1) {
          if (response1.data.ERROR || response1.data.STATUSMESSAGE) {
            notifier.alert(
              response1.data.ERROR || response1.data.STATUSMESSAGE
            );
          }
          this.deleting = "error";
        } else {
          this.deleting = false;
          this.$emit("loadCommission", 1);
          this.$emit("close", true);
          notifier.success(response1.data.MESSAGE);
        }
        this.deleting = false;
      }
    }
  }
}
