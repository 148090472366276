var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr>\n      <td width=\"12%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.MC_CDETAIL_ACCOUNTID || (depth0 != null ? depth0.MC_CDETAIL_ACCOUNTID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MC_CDETAIL_ACCOUNTID","hash":{},"data":data,"loc":{"start":{"line":14,"column":48},"end":{"line":14,"column":72}}}) : helper)))
    + "</td>\n      <td width=\"23%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.MC_CDETAIL_ACCOUNTNAME || (depth0 != null ? depth0.MC_CDETAIL_ACCOUNTNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MC_CDETAIL_ACCOUNTNAME","hash":{},"data":data,"loc":{"start":{"line":15,"column":48},"end":{"line":15,"column":74}}}) : helper)))
    + "</td>\n      <td width=\"10%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.MC_CDETAIL_PONUMBER || (depth0 != null ? depth0.MC_CDETAIL_PONUMBER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MC_CDETAIL_PONUMBER","hash":{},"data":data,"loc":{"start":{"line":16,"column":48},"end":{"line":16,"column":71}}}) : helper)))
    + "</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.MC_CDETAIL_SALESORDERNUMBER || (depth0 != null ? depth0.MC_CDETAIL_SALESORDERNUMBER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MC_CDETAIL_SALESORDERNUMBER","hash":{},"data":data,"loc":{"start":{"line":17,"column":49},"end":{"line":17,"column":80}}}) : helper)))
    + "</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.MC_CDETAIL_EXTENDEDPRICE_FORMATTED || (depth0 != null ? depth0.MC_CDETAIL_EXTENDEDPRICE_FORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MC_CDETAIL_EXTENDEDPRICE_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":18,"column":49},"end":{"line":18,"column":87}}}) : helper)))
    + "</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.MC_CDETAIL_EARNINGS_FORMATTED || (depth0 != null ? depth0.MC_CDETAIL_EARNINGS_FORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MC_CDETAIL_EARNINGS_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":19,"column":49},"end":{"line":19,"column":82}}}) : helper)))
    + "</td>\n      <td width=\"15%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.MC_CDETAIL_COMMISSIONPERCENT_FORMATTED || (depth0 != null ? depth0.MC_CDETAIL_COMMISSIONPERCENT_FORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MC_CDETAIL_COMMISSIONPERCENT_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":20,"column":49},"end":{"line":20,"column":91}}}) : helper)))
    + "%</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.PAIDTOTAL_FORMATTED_4 || (depth0 != null ? depth0.PAIDTOTAL_FORMATTED_4 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PAIDTOTAL_FORMATTED_4","hash":{},"data":data,"loc":{"start":{"line":21,"column":49},"end":{"line":21,"column":74}}}) : helper)))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"customerList_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"12%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">Account ID</td>\n    <td width=\"23%\" style=\"border-bottom: 5px solid #b1b1b1;\">Customer</td>\n    <td width=\"10%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">Customer PO</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">VAR360 Order.</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">Total</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">Profit</td>\n    <td width=\"15%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">Commission %</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">Paid Total</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.PAYCHECKDETAIL : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":2},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"75%\" colspan=\"5\">&nbsp;</td>\n    <td width=\"15%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Grand Total:</td>\n    <td width=\"10%\" style=\"text-align: right;\">"
    + container.escapeExpression(((helper = (helper = helpers.TOTALEARN_FORMATTED_4 || (depth0 != null ? depth0.TOTALEARN_FORMATTED_4 : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"TOTALEARN_FORMATTED_4","hash":{},"data":data,"loc":{"start":{"line":27,"column":47},"end":{"line":27,"column":72}}}) : helper)))
    + "</td>\n  </tr>\n</table>";
},"useData":true});