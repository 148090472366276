























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Datepicker from '../components/Datepicker.vue'
// import Pagination from "../components/Pagination.vue";
import {notifier} from '../models/common'
declare const getRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    Datepicker,
  },
})
export default class StatusCreate extends TSXComponent<void> {
  objDate = new Date();
  statusDate = [this.objDate.getMonth()+1, this.objDate.getDate(), this.objDate.getFullYear()].join('/');
  StatusDetails:{
    name?:string,
    type?:number,
    status?:number,
    date?:string,
  }= {
    name : "",
    type : 1,
    status :0,
    date : this.statusDate
  }

  created() {
    this.getOrderDetail(this.$route.params.id,self);
  }
   getOrderDetail(ordid, container) {
      var comInstance = this;
      const dataObj = {
          "controller": "Orders",
          "FunctionName": "View",
          "purchaseID": `${ordid}`,
          "Content": "Detailed"
      };       
      if (ordid != 0) {
          var orderData = getRouteData(dataObj);
          orderData.then(function (response, statusText, jqXHR) {
              try {
                  //set some value for comInstance
                  if(response.data.orderStatus.length == 1)
                    var orderDetails = response.data.orderStatus[0];
                  else 
                     var orderDetails = response.data.orderStatus[1];
                  comInstance.StatusDetails.name = orderDetails.type;
                  comInstance.StatusDetails.type =  orderDetails.status;
                  comInstance.StatusDetails.status =  orderDetails.source;
                  comInstance.StatusDetails.date = orderDetails.dateTime;
              }
              catch (e) {
                  //handle error
                  console.log('createStatuserror : ', e);
              }
          });
      }
  }
  // async validateBeforeSubmit(e) {    
     
  // }
  async onSubmit() {    
      var comInstance = this;
      var dataObj = {
          "controller"    : "orders",
          "FunctionName"  : "orderStatus",
          "update"        : true,
          "Id"       :this.$route.params.id,
          "date"     : comInstance.StatusDetails.date,
          "type"    : comInstance.StatusDetails.type,
          "source"  : comInstance.StatusDetails.status,
          "name"    : comInstance.StatusDetails.name
      };
      let resultCategoryTrend = getRouteData(dataObj);
      resultCategoryTrend.then(function (response, statusText, jqXHR) {
         // if(response.data.status == 1) {
         //    getOrderDetail(this.$route.params.id,comInstance);
         // }
      });
  }
}
