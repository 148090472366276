

























































import { VNode } from "vue";
import axios from "axios";
import { notifier } from "../models/common";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import SelectTagControl from "@/components/SelectTagControl.vue";

interface Props {
}

interface Events {
  onClose: void
  onSave: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    SelectTagControl
  }
})
export default class AttachPriceListModal extends TSXComponent<
  Props,
  Events
> {

  @Prop({ required: true })
  contract!: any;

  @Prop({ required: false, default: [] })
  priceListOptions: any = [];

  @Prop({ required: false, default: "" })
  currentPriceListId: any;

  saving = false;

  priceListID = '';
  // isUpdated = 0;

  loading: boolean = false;

  $refs!: {
    modal: HTMLDivElement
  }

  created() {
    this.priceListID = this.currentPriceListId;
  }

  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });

  }

  choosePriceListItem(priceListItemId, rowIdx) {
    this.priceListID = priceListItemId;
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide');
  }

  async save() {
    const valid = await this.$validator.validateAll();

    if (!valid) {
      return;
    }

    this.saving = true;
    try {

      const currentPriceListIdInt = +this.currentPriceListId;
      const priceListIDInt = +this.priceListID;

      if (currentPriceListIdInt === priceListIDInt) { // nothing changes
        this.$emit('save', { reload: false });
        return;
      }

      for (const priceListItem of this.contract.PRICINGLIST) { // already exists
        if (priceListItem.PRICELISTID === priceListIDInt) {
          this.$emit('save', { reload: false });
          return;
        }
      }

      const requestObj = {
        Controller: 'Quotes',
        FunctionName: 'ContractAttachPriceList',
        userID: sessionStorage.getItem("userId"),
        customFieldId: this.contract.CUSTOMFIELDID,
        customFieldOptionId: this.contract.CUSTOMFIELDOPTIONID,
        oldPriceListId: this.currentPriceListId,
        priceListId: this.priceListID,
        // isUploadNew: this.isUpdated === 0 ? 1 : 0
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj)

      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1) {
        notifier.success(message);
        this.$emit('save', { reload: true });
      } else {
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      this.saving = false;
    }
  }
}
