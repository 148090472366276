



















































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: SpiffStatusValue;
}

interface Events {
  onChangeStatus: SpiffStatusValue;
}

export enum SpiffStatusValue {
  Open = 1,
  Processing = 2,
  Completed = 3,
  Expired = 4,
  Ignored = 5,
  Addons = 6
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class SpiffStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: SpiffStatusValue;

  @Prop({ required: true, default: "open" })
  statustext!: string;

  popoverVisible = false;
  optionsVisible = false;

  get iconClass() {
    const icon =
      this.status === 1
      ? "green"
      : this.status === 2
      ? "purple"
      : this.status === 3
      ? "blue"
      : this.status === 4
      ? "gray"
      : this.status === 5
      ? "red"
      : this.status === 6
      ? "lightPurple"
      : "red";
      return icon
  }

  get statusText() {
    const status =  this.status === 1
      ? "Open"
      : this.status === 2
      ? "Processing"
      : this.status === 3
      ? "Completed"
      : this.status === 4
      ? "Expired"
      : this.status === 5
      ? "Ignored"
      : this.status === 6
      ? "Addons"
      : "Ignored";

      return status
  }
  hideTooltip() {
    this.optionsVisible = false;
  }
  mouseover() {
    if (this.optionsVisible) {
      return;
    }

    this.popoverVisible = true;
  }

  mouseout() {
    this.popoverVisible = false;
  }

  click() {
    this.popoverVisible = false;

    this.optionsVisible = !this.optionsVisible;
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false;
    }
  }

  itemClick(status: SpiffStatusValue) {
    this.$emit("changeStatus", status);
    this.optionsVisible = false;
  }
}
