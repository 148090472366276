var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRemitAddress : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInfoHeader : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":33,"column":15}}})) != null ? stack1 : "")
    + "        \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEditParagraph : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/kodamaQuoteDetails_header.handlebars"),depth0,{"name":"kodamaQuoteDetails_header","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/kodamaQuoteDetails_information.handlebars"),depth0,{"name":"kodamaQuoteDetails_information","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/kodamaQuoteDetails_notes.handlebars"),depth0,{"name":"kodamaQuoteDetails_notes","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/kodamaQuoteDetails_purchasing.handlebars"),depth0,{"name":"kodamaQuoteDetails_purchasing","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/kodamaQuoteDetails_items.handlebars"),depth0,{"name":"kodamaQuoteDetails_items","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!DOCTYPE html>\n<html>\n  <head>\n    <link href=\"https://var360.s3.amazonaws.com/public/css/export.css\" rel=\"stylesheet\">\n    <link href=\"https://fonts.googleapis.com/css?family=Work+Sans:400,600&display=swap\" rel=\"stylesheet\">\n    <style>\n      body * {\n        font-weight: 400;\n      }\n    </style>\n    <style>\n        .kodamaTemplate .headerColor {\n            background-color: #27553b;\n            color: #fff;\n            font-weight: bold;\n            font-weight: bold;\n            padding: 5px !important;\n        }\n        #quoteDetails_vendor {\n          padding: 10px;\n        }\n    </style>\n  </head>\n  <body>\n    <div id=\"container\" class=\"kodamaTemplate\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.templateItemReorder : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":6},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.pricingdetails : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":6},"end":{"line":43,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/kodamaQuoteDetails_summary.handlebars"),depth0,{"name":"kodamaQuoteDetails_summary","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/kodamaQuoteDetails_vendor_address.handlebars"),depth0,{"name":"kodamaQuoteDetails_vendor_address","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </body>\n</html>\n";
},"usePartial":true,"useData":true});