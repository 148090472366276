





































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

import Loader from "../Loader.vue";

interface Props {
  linked: boolean
}

interface Events {
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {
    Loader,
  }
})
export default class EmployeePlanStats extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: false })
  showExtend!: boolean;

  @Prop({ required: false, default: false })
  showMore!: boolean;

  @Prop({ required: false, default: false })
  hideEmployeePlanNumbers!: boolean;

  @Prop({ required: false, default: false })
  pinEmployeePlanStats!: boolean;

  @Prop({ required: false, default: '' })
  fullName!: string;

  @Prop({ required: false, default: '' })
  firstName!: string;

  @Prop({ required: false, default: '' })
  lastName!: string;

  @Prop({ required: false, default: {
      isLoading: true,
      UNPAID_COMM: 0,
      UNPAID_COMM_FORMATTED: '',
      PERIOD_COMM: 0,
      PERIOD_COMM_FORMATTED: '',
      PERCENT: 0,
      PERCENT2: 0,
      ITEMS: [],
    }
  })
  employeePlanInfo!: any;

  getInitials() {
    const arr: any[] = [];
    if(this.firstName) {
      arr.push(this.firstName.charAt(0));
    }
    if(this.lastName) {
      arr.push(this.lastName.charAt(0));
    }
    return arr.join('');

  }

  async created() {

  }

}
