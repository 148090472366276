



















































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import UserSuggestInput from "./UserSuggestInput.vue";
import directives from "../helpers/directives";
import LaddaButton from "./LaddaButton.vue";

declare const $: any;
declare const dataURL: string;

interface Props {
  list: any;
  loading: boolean;
  allocatedQty: number;
  totalQty: number;
  currentIndex: number;
  currentItem: any;
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    UserSuggestInput,
    LaddaButton
  },
  directives
})
export default class DeploymentUserCompletionTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: false, default: -1 })
  currentIndex!: number;

  @Prop({ required: false, default: {} })
  currentItem!: any;

  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  @Prop({ required: false, default: undefined })
  saveItem?: Function;

  $parent: any;
  saving = false;
  completion: any = 0;
  notes = "";
  errors: any;

  async created() {
    //
  }

  beforeDestroy() {
    //
  }

  clickOutside() {
    if (!this.saving) {
      this.$emit("close");
    }
  }

  async saveCompletion() {
    // validate
    if (!this.completion) return;
    if (this.errors.has("completionNumber")) return;

    try {
      this.saving = true;
      const response = this.saveItem
        ? await this.saveItem(
            {
              controller: "Deployments",
              FunctionName: "UpdateLogs",
              deploymentId: this.deploymentId,
              userId: this.currentItem.USERID,
              logType: 1,
              completion: this.completion,
              notes: this.notes
            },
            this.currentIndex
          )
        : undefined;
      this.saving = false;

      if (response && response.data.STATUS == 1) {
        const deploymentPercent = response.data.deploymentPercent || 0;
        this.$emit("updateDeploymentPercent", deploymentPercent);
        this.$emit("close");
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.saving = false;
    }
  }
}
