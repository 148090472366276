import { render, staticRenderFns } from "./CustomerProfitTargetModal.vue?vue&type=template&id=07ec06be&scoped=true"
import script from "./CustomerProfitTargetModal.vue?vue&type=script&lang=tsx"
export * from "./CustomerProfitTargetModal.vue?vue&type=script&lang=tsx"
import style0 from "./CustomerProfitTargetModal.vue?vue&type=style&index=0&id=07ec06be&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ec06be",
  null
  
)

export default component.exports