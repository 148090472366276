var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.ISOTHERS : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":64,"column":6}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <table>\n        <tr>\n          <td class=\"title\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYNAME : stack1), depth0))
    + " Rep and Info:</strong></td>\n        </tr>\n        <tr>\n          <td><b>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYNAME : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":17},"end":{"line":11,"column":92}}})) != null ? stack1 : "")
    + "</b></td>\n        </tr>\n        <tr>\n          <td><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYADDR1 : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":22},"end":{"line":14,"column":99}}})) != null ? stack1 : "")
    + "</strong></td>\n        </tr>\n        <tr>\n          <td>\n            <strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYADDR2 : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":97}}})) != null ? stack1 : "")
    + "</strong>\n          </td>\n        </tr>\n        <tr>\n          <td>\n            <strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYCITY : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":95}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYSTATE : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":95},"end":{"line":23,"column":179}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYZIP : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":179},"end":{"line":23,"column":258}}})) != null ? stack1 : "")
    + "</strong>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"title\"><br /><br /><br /><br /><br />NOTES:</td>\n        </tr>\n        <tr>\n          <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPMENTNOTES : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n      </table>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYNAME : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYADDR1 : stack1), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYADDR2 : stack1), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYCITY : stack1), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ",&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYSTATE : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.CUSTOMDISTYZIP : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPMENTNOTES : stack1), depth0))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "              Blind shipping required on all orders and shipments.\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n      <table>\n        <tr><td class=\"title\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VARSOURCE_NAME : stack1), depth0))
    + " Rep and Info:</strong></td></tr>\n        <tr><td><b>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.NAME : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":19},"end":{"line":41,"column":96}}})) != null ? stack1 : "")
    + "</b></td></tr>\n        <tr><td><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.ATTRN : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":42,"column":103}}})) != null ? stack1 : "")
    + "</strong></td></tr>\n        <tr>\n          <td><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.ADDRESS : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":44,"column":22},"end":{"line":44,"column":187}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.PHONE : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":47,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.EMAIL : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":50,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n        <tr><td class=\"title\"><br/><br/><br/><br/><br/>NOTES:</td></tr>\n        <tr>\n          <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPMENTNOTES : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":60,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n      </table>\n      ";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.NAME : stack1), depth0)) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.ATTRN : stack1), depth0)) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.ADDRESS : stack1), depth0)) != null ? stack1 : "")
    + "</strong><br><strong>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.ADDRESS1 : stack1), depth0)) != null ? stack1 : "")
    + "</strong>";
},"28":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.PHONE : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VENDORINFO : stack1)) != null ? stack1.EMAIL : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.PODATETIMEFORMATTED : stack1), depth0))
    + "</strong>";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPPINGTO : stack1), depth0));
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.AACCOUNTNAME : stack1), depth0));
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPTOONETIMEADDRESS : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPPINGTOADDR : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(45, data, 0),"data":data,"loc":{"start":{"line":90,"column":14},"end":{"line":95,"column":21}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPPINGTOADDR : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.AADDRESS : stack1),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":93,"column":16},"end":{"line":94,"column":91}}})) != null ? stack1 : "")
    + "\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.AADDRESS : stack1), depth0))
    + "<br />"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.ACITY : stack1), depth0))
    + ",\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.ASTATE : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.AZIP : stack1), depth0))
    + "</strong>";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <tr>\n          <td class=\"title\">End User Information</td>\n        </tr>\n        <tr>\n          <td>\n            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.EUNAME : stack1),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":12},"end":{"line":112,"column":92}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.EUPHONE : stack1),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":12},"end":{"line":113,"column":94}}})) != null ? stack1 : "")
    + "\n          </td>\n        </tr>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.EUNAME : stack1), depth0))
    + "</strong><br />";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.EUPHONE : stack1), depth0))
    + "</strong><br />";
},"53":function(container,depth0,helpers,partials,data) {
    return "        <tr>\n          <td class=\"title\">&nbsp;</td>\n        </tr>\n        <tr>\n          <td>&nbsp;</td>\n        </tr>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "      <strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VARSOURCE_NAME : stack1), depth0))
    + " Quote ID:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.INDIRECTQUOTEID : stack1), depth0))
    + "<br/>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <strong style=\"color: red;\">SHIPMENT NOTES: "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPMENTNOTES : stack1), depth0)) != null ? stack1 : "")
    + "</strong><br/>\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.VARSOURCE_NAME : stack1), depth0))
    + " Account Number: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.DISTYACCTNO : stack1), depth0))
    + "<br/>\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.TERMS : stack1), depth0))
    + "\n";
},"63":function(container,depth0,helpers,partials,data) {
    return "        CREDIT CARD - Please Call 720-514-3414 for payment via phone\n";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <br>Contract Number: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.NASPOCONTRACT : stack1), depth0))
    + "<br>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <br>Email Disty Notes: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.DISTYNOTES : stack1), depth0))
    + "<br>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda;

  return "<table width=\"100%\" id=\"quoteDetails_info\">\n  <tr>\n    <td width=\"60%\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.ISHPDIRECT : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":64,"column":13}}})) != null ? stack1 : "")
    + "\n    </td>\n    <td width=\"40%\">\n      <table>\n        <tr>\n          <td class=\"title\">P.O. Date</td>\n        </tr>\n        <tr>\n          <td>\n            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.PODATETIMEFORMATTED : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":73,"column":126}}})) != null ? stack1 : "")
    + "\n          </td>\n        </tr>\n        <tr>\n          <td colspan=\"2\" class=\"title\"><strong>Ship To</strong></td>\n        </tr>\n        <tr>\n          <td><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPPINGTO : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":80,"column":22},"end":{"line":80,"column":124}}})) != null ? stack1 : "")
    + "</strong></td>\n        </tr>\n        <tr>\n          <td class=\"title\">Shipping Address</td>\n        </tr>\n        <tr>\n          <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPPINGONETIMEADDRESS : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":87,"column":12},"end":{"line":96,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n        <tr>\n          <td class=\"title\">Customer PO</td>\n        </tr>\n        <tr>\n          <td>"
    + container.escapeExpression(alias2(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.POID : stack1), depth0))
    + "</td>\n        </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.ENDUSERID : stack1),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":8},"end":{"line":116,"column":15}}})) != null ? stack1 : "")
    + "\n        <tr>\n          <td class=\"title\">&nbsp;</td>\n        </tr>\n        <tr>\n          <td>&nbsp;</td>\n        </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPVIA : stack1),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":8},"end":{"line":131,"column":15}}})) != null ? stack1 : "")
    + "      </table>\n    </td>\n  </tr>\n  <!-- \n  <tr>\n    <td>\n      Sales Tax Rate:<strong style=\"border: 1px solid black;padding-left: 63px;\"> "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.POTAX : stack1), depth0)) != null ? stack1 : "")
    + "%</strong>\n    </td>\n  </tr> -->\n</table>\n<table width=\"100%\" id=\"quoteDetails_info\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"100%\" style=\"border-top: 5px solid #b1b1b1;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.INDIRECTQUOTEID : stack1),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":6},"end":{"line":147,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.SHIPMENTNOTES : stack1),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":6},"end":{"line":151,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.DISTYACCTNO : stack1),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":6},"end":{"line":154,"column":13}}})) != null ? stack1 : "")
    + "\n      <br/>Contract Terms: \n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.TERMS : stack1),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.program(63, data, 0),"data":data,"loc":{"start":{"line":157,"column":6},"end":{"line":161,"column":13}}})) != null ? stack1 : "")
    + "      \n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.NASPOCONTRACT : stack1),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":6},"end":{"line":165,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.DISTYNOTES : stack1),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":6},"end":{"line":168,"column":13}}})) != null ? stack1 : "")
    + "    </td>\n  </tr>\n</table>";
},"useData":true});