










import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";

import { InvoiceLineItem } from "../models/invoice";
import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import { QuoteLineItemAPIRepo } from "../repos/QuoteLineItemAPIRepo";
import { QuoteLineItemList } from "../models/QuoteLineItemList";
import { notifier } from "../models/common";

const quoteLineItemAPIRepo = new QuoteLineItemAPIRepo();

interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    line: QuoteLineItemList.Lineitems,
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
})
export default class SKUAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = []
  searchData: QuoteLineItemList.Lineitems[] = []

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    try {
      const data = await quoteLineItemAPIRepo.find(search);
      return this.createOptions(data)
    } catch (err) {
      notifier.alert(err.message)
      return {
        options: [],
      }
    }
  };

  createOptions(searchData: QuoteLineItemList.Lineitems[]) {
    const options = searchData.map(
      (item): Option => {
        return {
          value: item.QPRODSKU,
          label: `${item.QPRODSKU} - ${item.QPRODDESC}`,
          text: item.QPRODSKU
        };
      }
    )

    this.searchData = searchData
    this.options = options

    return {options}
  }

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      return
    }
    const line = this.searchData.find((item) => item.QPRODSKU === value)
    if (!line) {
      console.warn(`no line found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      line,
    })
  }
}
