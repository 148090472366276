
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { OrderAPIRepo, DetailSortField, statusFilters, productStatus } from "../repos/OrderAPIRepo";
import { InvoiceAPIRepo } from "../repos/InvoiceAPIRepo";
import InvoiceMail from "../components/InvoiceMail.vue";
import InvoiceStatusComponent, {
  InvoiceStatusValue
} from "../components/InvoiceStatus.vue";

import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl,
  wait
} from "../models/common";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import StatusModal from "../components/StatusCreate.vue";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import viewInvoiceModel from "../components/viewInvoiceModel.vue";
import CancelOrderModal from "../components/CancelOrderModal.vue";
import LogInfoModal from "../components/LogInfoModal.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import InvoicePaidConfirmModal from "../components/InvoicePaidConfirmModal.vue";
import ProfitStatusChangeTooltip from "../components/ProfitStatusChangeTooltip.vue";
import ProcessOrderModal from "../components/ProcessOrderModal.vue";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from '../components/pageTitle.vue';

import draggable from "vuedraggable";
import { microserviceQBOURL } from "../models/envVars";
import GlobalTagSelect from "../components/GlobalTagSelect.vue";
import ConfirmProfitTotalModal from "../components/ConfirmProfitTotalModal.vue";
import directives from "../helpers/directives";
import LedgerssourceAutoSuggestInput from "../components/LedgerssourceAutoSuggestInput.vue";
import TechdataStatusModal from "../components/TechdataStatusModal.vue";
import Datepicker from "../components/Datepicker.vue";
import LedgerGenerateModal from "../components/LedgerGenerateModal.vue";
import moment from "moment";
import ProductCatTooltip from "../components/ProductCatTooltip.vue";
import ApiExternal from "../components/ApiExternal.vue";
import OrderHardwares from "@/components/Order/OrderHardwares.vue";
import { ApiHelper } from "@/helpers/all";
import HardwareDetails from "@/components/Hardware/HardwareDetails.vue";
import { getTrackingLink, VarSource } from "@/helpers/ApiHelper";
import ModalEditOrderLine from "@/components/Order/ModalEditOrderLine.vue";
import SelectASourceModal from "../components/SelectASourceModal.vue";

declare const dataURL: string;
declare const $: any;
declare const htmlParse : Function;
declare const htmlCheck : Function;
declare const getRouteData : Function;
declare function require(params: any): any;
declare const LoadData: any;
const Handlebars = require("handlebars/runtime.js");
const orderRepo = new OrderAPIRepo();
const invoiceRepo = new InvoiceAPIRepo();
const userRepo = new UserAPIRepo();
const template = require("../templates/invoices/invoiceDetails.handlebars");
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {
    ModalEditOrderLine,
    OrderHardwares,
    ExportModal,
    StatusModal,
    InvoiceMail,
    InvoiceStatusComponent,
    viewInvoiceModel,
    CancelOrderModal,
    LogInfoModal,
    ConfirmRemoveItemModal,
    draggable,
    ProfitStatusChangeTooltip,
    GlobalTagSelect,
    ConfirmProfitTotalModal,
    ProcessOrderModal,
    InvoicePaidConfirmModal,
    LaddaButton,
    LedgerssourceAutoSuggestInput,
    TechdataStatusModal,
    Datepicker,
    LedgerGenerateModal,
    ProductCatTooltip,
    PageTitle,
    ApiExternal,
    HardwareDetails,
    SelectASourceModal
  },
  directives,
  beforeRouteLeave(to, from, next) {
    const self: any = this as any;
    if(self.currentRole === 'Reseller') {
      if(self.confirmLeave) {
        next();
      }else {
        // check if change ledger without saving
        if(self.changedLedgerData()) {
          self.confirmLeaveVisible = true;
          self.targetPage = to;
          next(false);
        }else {
          next();
        }
      }
    }else {
      next();
    }
  },
  methods: {
    getTrackingLink
  }
})
export default class OrderDetails extends TSXComponent<void> {
  $allowedFunctions: any;
	$userFunctions: any;
  progress = 1;
  isActive = "orderDetails";
  currentRole = sessionStorage.getItem("userRole");
  emailModalVisible = false;
  exportModalVisible = false;
  statusModalVisible = false;
  invoiceModelVisible = false;
  cancelModalVisible = false;
  profitStatusVisible = false;
  logModalVisible = -1;
  loading: string | boolean = false;
  paying: string | boolean = false;
  savingCard: string | boolean = false;
  ledgerLoading: string | boolean = false;
  isEditPo = false;
  exportPage = "order";
  exportInvoiceId = 0;
  includeInPayroll = false;
  disableRowFlag = false;
  orderTimelines = {
    waiting: "N/A",
    placed: "N/A",
    received: "N/A",
    shipped: "N/A",
    delivered: "N/A"
  };
  templateObj: {
    ACCOUNTID?: number;
    TEMPLATEID?: number;
    TEMPLATELOCATION?: string;
    TEMPLATENAME?: string;
    TEMPLATETYPE?: number;
  }[] = [];
  templateResult = this.templateObj;
  confirmRemoveModalVisible = false;
  confirmStatusPaidVisible = false;
  editPaymentInfoVisible = false;
  statusConfirmModalVisible = false;
  currentInvoiceToPaid = 0;
  StatusPaidConfirmed = false;
  orderTemplates: {
    ACCOUNTID?: number;
    TEMPLATEID?: number;
    TEMPLATELOCATION?: string;
    TEMPLATENAME?: string;
    TEMPLATETYPE?: number;
    ISDISTY?:number;
  }[] = [];
  invoiceTemplates: {
    ACCOUNTID?: number;
    TEMPLATEID?: number;
    TEMPLATELOCATION?: string;
    TEMPLATENAME?: string;
    TEMPLATETYPE?: number;
  }[] = [];
  orderLedgerData = [
    {
      D_FINTYPE_ID: 0,
      D_FINANCIALSTATUS_ID: 0,
      PLID: 0,
      D_FINANCIALSOURCE_NAME: "",
      PL_TYPE: 2,
      PL_AMOUNT: 0,
      PL_STATUS: 0,
      D_FINANCIALSTATUS_NAME: "UNPAID",
      PL_TIMESTAMPFORMAT: moment(Date.now()).utc().format("MM/DD/YYYY hh:mm A"),
      FINSOURCEID : 0,
      INVOICEID : "",
      ORDERINVOICEDATE : "",
      DUEDATE : "",
      INVOICEDATE : moment(Date.now()).utc().format("MM/DD/YYYY"),
      newRow : 0,
      PAYMENTID: 0
    }
  ];
  orderLedgerDataBK: any = [];
  orderLedgerLength = 0;
  orderHardwareLength = 0;
  orderHardwareTotal = 0;
  hardwaredetails = [];
  hardwareInfo: any = [];
  customerOrdertemplate = 0;
  varSourceName = '';
  customerInvoiceTemplate = 0;
  bigDealId = "";
  NASPOcontract = "";
  details: any = {
    ORDERDETAILS: {}
  };
  spiffStatusIcons = {
    Open: "green",
    Processing: "purple",
    Completed: "blue",
    Expired: "gray",
    Ignored: "red"
  };
  items: any = [];
  onExport: any = {
    templateid: 0,
    saveAsDefault: 0,
    showOnExport: 0,
    exportPO: 0,
    pricingid: "",
    advanced: {},
    customMessages: {},
    CustomDistyName: "",
    CustomDistyAddr1: "",
    CustomDistyAddr2: "",
    CustomDistyCity: "",
    CustomDistyState: "",
    CustomDistyZip: ""
  };
  statusDetails = {};
  orderParams: {
    IncludeInPayroll?: any;
  } = {
    IncludeInPayroll: ""
  };
  PoOrder = "";
  grouppedBySKUIndex = -1;
  viewInvoiceDetail = "";
  customDataVisible = false;
  hideCreditCard = false;
  hideCreditCardInfo = true;
  fullCustomData: object[] = [];
  customDataLoading = false;
  customDataIndex = -1;
  verify = true;
  globalTags: any = [];
  fromStatus = false;
  confirmProfitModalVisible = false;
  confirmFileRemove = false;
  removeFileId = 0;
  removeFiletype = 0;
  filetypes = {
    1 : "Purchase Order",
    2 : "Web Confirmation",
    3 : "Order Confirmation",
    4 : "Shipment Confirmation",
    5 : "Other"
  }
  showcard = 1;
  // stripe
  stripeObj: any = {};
  stripeToken = "";
  stripeLast4 = "";
  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  cvc = "";
  stripeError = 0;
  cardupdate = 0;
  processOrderModalVisible = false;
  canProcessWithDisty = false;
  confirmApproveModalVisible = false;
  allowEditPayment = false;
  PaymentText = "";
  showPaymentZone = false;
  paymentConfirmID = "";
  validSynnexCredential = false;
  validTechdataCredential = false;
  sourceCredentials: any = [];
  confirmingPaidStatus = false;
  distyProcessedID = "";
  preEmails:string[] = [];
  orderHasPaymentInfo = false;
  editPaymentForInvoiceID = 0;
  orderPaymentInfo: any = null;
  orderPaymentTotal = "";
  paymentTotalAmount = true;
  verifiedBtnEnabled = false;
  submitPaymentBtnEnabled = false;
  uploading: any = false;
  grouppedByPaycheckDetails = false;
  customDropdown: object[] = [];
  selectedValue = "Please make a selection";
  selectedCustomId = 0;
  orderContractNumber: any = [];
  selectedContractValue = "Please make a selection";
  selectedContractId = 0;
  selectedContracts: any = [];
  selectedFundingSourceId = 0;
  financialSource: object[] = [];
  selectedFinSourceValue = "Please make a selection";
  fundingSourceField = -1;
  includePaymentId = false;
  ledgerSource = "";
  ledgerSourceID = 0;
  techdataStatusModalVisible = false;
  invoiceDueDate = "";
  customSourceData = 0;
  confirmLeave = false;
  confirmLeaveVisible = false;
  targetPage: any = {};
  waitingGotoStatusModal = false;
  contractFieldId = 0;
  editLedgerInfoVisible = false;
  editStatusForLedgerID = 0;
  readMore = false;
  orderContractsID: number[] = [];
  selectedOrderContracts = "";
  confirmdeleteIncludeCustomeInv = false;
  removeIncludeInvIndex = 0;
  removeInvLoading = false;
  previousPage = false;
  productCatTTVisibleIndex = "";
  active = false;
  uncapturedAmount = 0;
  uncapturedAmountFormatted = "";
  confirmChargeVisible = false;
  confirmChargeByCustomerIdVisible = false;
  confirmChargeByCustomerIdText = "";
  selectSourceModalVisible = false;
  routerName = "";
  routerId = "";

  sort = {
    field: "",
    direction: {
      [DetailSortField.Type]: 1,
      [DetailSortField.Status]: 1,
      [DetailSortField.DueDate]: 1
    }
  };
  lineItemFilters:statusFilters = {
    statusID: []
  };
  selectedID: (number | undefined)[] = [];
  selectedAll = false;
  excludedIDs = [];
  orderQtyCount = 0;
  qtyCountFormatted = "";
  apiExternalModalVisible = false;
  apiLoading = false;
  modalHardwareDetails: {
    show: boolean;
    isLoading: boolean;
    data: any;
    reset: Function;
  } = {
    show: false,
    isLoading: false,
    data: {},
    reset: () => {
      this.modalHardwareDetails.data = {
        id: 0,
        name: "",
        imageURL: "",
        sku: "",
        statusName: "",
        statusId: 0,
        order: {
          purchaseId: 0,
          poId: "N/A"
        },
        customer: {
          aId: 0,
          aName: "N/A",
          accountId: ""
        },
        deployment: {
          deploymentId: 0,
          deploymentName: "N/A"
        },
        categoryId: 0,
        categoryName: "",
        carrier: "",
        trackingNumber: "N/A",
        endUser: {
          userId: 0,
          name: "N/A",
          addr: "",
          cityZip: ""
        },
        identifiers: [
          {
            name: "VAR360 Hardware ID",
            type: "hardware_id",
            value: "N/A"
          },
          {
            name: "Serial Number",
            type: "asset_number",
            value: ""
          },
          {
            name: "Asset Tag",
            type: "asset_tag",
            value: ""
          }
        ],
        logs: []
      };
    }
  };
  removingCard = false;

  gotoSearch(searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for/orders"
    });
  }

  listPageRedirection() {
    if (this.verify) {
      if (this.fromStatus) {
        this.$router.push({ name: "OrderStatus" });
      } else if (this.previousPage) {
        this.$router.go(-1);
      } else if (this.routerName.length && this.routerId.length) {
        this.$router.push({ name: this.routerName, params: { id: this.routerId} });
      } else {
        this.$router.push({ name: "Orders" });
      }
    } else {
      this.$router.push({ name: "Orders", query: { orderType: "unverify" } });
    }
    //To go previous page
    // window.history.back();
  }

  async created() {
    this.loading = true;

    if(
      typeof this.$route.query.name === "string" &&
      typeof this.$route.query.id === "string"
    ) {
      this.routerName = this.$route.query.name;
      this.routerId = this.$route.query.id;
      const currentQueryParam = { ...this.$route.query };
      this.$delete(currentQueryParam, "name");
      this.$delete(currentQueryParam, "id");
      this.$router.replace({ query: currentQueryParam });
    }

    if (
      typeof this.$route.query.orderType != "undefined" &&
      this.$route.query.orderType == "unverify"
    ) {
      this.verify = false;
      this.getDetailsForUnverify();
    } else {
      await this.getOrdersdetails();
    }
    if (typeof this.$route.query.tab != "undefined") {
      if (this.$route.query.tab == "status") {
        this.toggleActive("orderStatus");
        this.fromStatus = true;
      }
    }
    if(
      typeof this.$route.query.previous != "undefined" &&
      this.$route.query.previous == "true"
    ) {
      this.previousPage = true;
    }

    if (this.currentRole == "Reseller") {
      const activeTab = this.$route.params.activeTab || "";
      if(activeTab != "") {
        this.toggleActive(activeTab);

        if(activeTab == "orderInvoices") {
          const invoiceID = this.$route.params.invoiceID || "";
          if(invoiceID != "") {
            this.emailChange(invoiceID);
          }
        }
      }
    }

    // if (this.currentRole != "Reseller") {
    //this.stripeLoad();
    // }

    // save backup for orderLedgerData
    this.bkLedger();
  }

  async getOrdersdetails() {
    const response: any = await orderRepo.findOneDetails(this.$route.params.id, this.onExport, this.sort, this.lineItemFilters);
    var templateResult: {
      ACCOUNTID?: number;
      TEMPLATEID?: number;
      TEMPLATELOCATION?: string;
      TEMPLATENAME?: string;
      TEMPLATETYPE?: number;
      ISDISTY?:number;
    }[] = response.TEMPLATES;

    // reset invoice templates
    this.invoiceTemplates = [];

    for (let i = 0; i < templateResult.length; i++) {
      if (templateResult[i].TEMPLATETYPE == 3) {
        //order
        this.orderTemplates.push(templateResult[i]);
      } else if (templateResult[i].TEMPLATETYPE == 2) {
        //invoice
        this.invoiceTemplates.push(templateResult[i]);
      }
    }
    this.items = response.ORDERITEMS;
    this.orderQtyCount = response.ORDERQTYCOUNT;
    //Format the order quantity count
    this.qtyCountFormatted = this.orderQtyCount.toString();
    this.orderHardwareLength = response.orderHardwareLength || 0;
    this.orderHardwareTotal = response.orderHardwareTotal || 0;
    this.hardwaredetails = response.hardwaredetails || [];
    this.hardwareInfo = response.hardwareInfo || [];
    this.uncapturedAmount = response.uncapturedAmount || 0;
    this.uncapturedAmountFormatted = response.uncapturedAmountFormatted || "";

    if(this.orderQtyCount >= 10000) {
      var suffixes = ["", "k", "m", "b","t"];
      var suffixNum = Math.floor(this.qtyCountFormatted.length / 3.5);
      var shortValue = this.orderQtyCount / Math.pow(1000, suffixNum);
      var roundedShortValue = shortValue % 1 >= 0.8 ? Math.ceil(shortValue) : Math.floor(shortValue);
      this.qtyCountFormatted = roundedShortValue + suffixes[suffixNum];
    }

    // console.log(response);

    this.details = response;

    this.details.orderStatus = this.details.orderStatus.map((item) => {
      const Status_Details = item.Status_Details || {};
      item.PAYMENTREFERENCE = Status_Details.PAYMENTREFERENCE || '';
      item.PAYMENTTYPE = Status_Details.PAYMENTTYPE || '';
      return item;
    });
    
    //Handle stripe key:
    try {    
      if(this.details.StripePublicKey != "undefined" && this.details.StripePublicKey != "")
      {
        var stripePKey = this.details.StripePublicKey;
        await $.getScript("https://js.stripe.com/v2/", function(
          data,
          textStatus,
          jqxhr
        ) {
            //Loaded
            let stripe = Stripe.setPublishableKey(stripePKey);
        }).fail(function(jqxhr, settings, exception) {
          // console.log("stripe load failes");
        });
      }
    } catch (err) {
      console.log(err.message);
    }

    if(this.details.ORDERDETAILS.HPDIRECTTO && this.details.ORDERDETAILS.HPDIRECTTO != ""){
      var EmailObj = JSON.parse(this.details.ORDERDETAILS.HPDIRECTTO);
      for (const mailList in EmailObj) {
        const emailInfo = EmailObj[mailList];
        let euEmail = '';
        switch (typeof emailInfo) {
          case 'object':
            euEmail = emailInfo.UEMAIL || '';
            break;
          case 'string':
            if(emailInfo != ""){
              euEmail = emailInfo || '';
            }
            break;
        }
        if(euEmail != "") {
          this.preEmails.push(euEmail);
        }
      }
    }

    this.details["preEmails"] = this.preEmails.map((email) => {
      return {
        USERID: email,
        UEMAIL: email,
      }
    });
    // Ledger 
    this.orderLedgerData = this.details.ORDERLEDGER;
    this.orderLedgerLength = this.details.LEDGERCOUNT;
    var orderLedgerNew = {
      D_FINTYPE_ID: 0,
      D_FINANCIALSTATUS_ID: 0,
      PLID: 0,
      D_FINANCIALSOURCE_NAME: "",
      PL_TYPE: 2,
      PL_AMOUNT: 0,
      PL_STATUS: 0,
      D_FINANCIALSTATUS_NAME: "UNPAID",
      PL_TIMESTAMPFORMAT: moment(Date.now()).format("MM/DD/YYYY"),
      FINSOURCEID : 0,
      INVOICEID : "",
      ORDERINVOICEDATE : "",
      DUEDATE  : "",
      INVOICEDATE : moment(Date.now()).utc().format("MM/DD/YYYY"),
      newRow : 1,
      PAYMENTID: 0
    };
    this.orderLedgerData.push(orderLedgerNew);
    
    this.showPaymentZone = !!this.details.StripePublicKey || false;
    this.details["ORDERDETAIL"] =
      typeof response.ORDERDETAILS != "undefined"
        ? response.ORDERDETAILS
        : {};
    this.loading = false;
    this.details["NOTESTOTAL"] =
      typeof response.ORDERNOTES != "undefined"
        ? response.ORDERNOTES.length
        : 0;
    this.PoOrder = this.details["ORDERDETAILS"]["POID"];
    if (this.details.PaymentInfo && !this.details.PaymentInfo.length) {
      this.showcard = 3;
    }
    else{
      if(this.details.PaymentInfo[0].PAYMENTFIELDID == ""){
        this.details.PaymentInfo[0].PAYMENTFIELDID = 1;
      }
    }
    this.details["TOTAL"] =
      this.details["TAX"] +
      this.details["SHIPPING"] +
      this.details["ORDERDETAILS"]["TOTALPRICE"];
    this.details.uploading = false;

    if (
      this.details["ORDERDETAIL"].length &&
      typeof this.details["ORDERDETAIL"][0].ORDERPARAMS != "undefined" &&
      this.details["ORDERDETAIL"][0].ORDERPARAMS != ""
    ) {
      this.orderParams = JSON.parse(this.details["ORDERDETAIL"][0].ORDERPARAMS);
    }
    if (
      typeof this.orderParams.IncludeInPayroll == "undefined" ||
      this.orderParams.IncludeInPayroll == ""
    ) {
      this.orderParams.IncludeInPayroll = false;
    } else {
      this.orderParams.IncludeInPayroll = this.orderParams.IncludeInPayroll
        ? true
        : false;
    }
    this.customerOrdertemplate = response.AccountOrderTemplateId;
    this.customerInvoiceTemplate = response.AccountInvoiceTemplateId;

    // this.orderTemplates = response.TEMPLATES;
    //if (typeof response.ORDERDETAIL[0] != "undefined") {
      //this.bigDealId = response.ORDERDETAIL[0].SPECIALPRICINGID;
    //}

    //Contract Number
    this.NASPOcontract = typeof this.details.ORDERDETAILS.NASPOCONTRACT != "undefined" ? this.details.ORDERDETAILS.NASPOCONTRACT : "";
    if(typeof this.details.ORDERDETAILS.CUSTOMDATACONTRACTNUMBER != "undefined" && this.details.ORDERDETAILS.CUSTOMDATACONTRACTNUMBER != "") {
      this.bigDealId = this.details.ORDERDETAILS.CUSTOMDATACONTRACTNUMBER;
    } else {
      this.bigDealId = this.NASPOcontract;
    }

    //Include Payment Amount
    if(typeof this.details.ORDERDETAILS.PSTATUS != "undefined" && (this.details.ORDERDETAILS.PSTATUS == 5 || this.details.ORDERDETAILS.PSTATUS == 10)) {
      this.includePaymentId = true;
    } else {
      this.includePaymentId = false;
    }

    if (typeof response.TIMELINES != "undefined") {
      this.details["TIMELINES"] = response.TIMELINES;
    }

    //collect timeline info for customer portal
    // if (this.currentRole === "Customer" && this.details.TIMELINES.length) {
    //   for (var val of this.details.TIMELINES) {
    //     if (val.type == "Quote Creation") {
    //       this.orderTimelines.waiting = val.sortTime;
    //     } else if (val.type == "Order Creation" || val.status == 1) {
    //       this.orderTimelines.placed = val.sortTime;
    //     } else if (val.status == 2) {
    //       this.orderTimelines.received = val.sortTime;
    //     } else if (val.status == 3) {
    //       this.orderTimelines.shipped = val.sortTime;
    //     } else if (val.status == 4) {
    //       this.orderTimelines.delivered = val.sortTime;
    //     }
    //   }
    // }

    if(this.currentRole === "Reseller") {
      this.validSynnexCredential = this.details.validSynnexCredential || false;
      this.validTechdataCredential = this.details.validTechdataCredential || false;
      this.sourceCredentials = this.details.sourceCredentials || [];

      // can process with disty
      // if(((this.details.ORDERDETAILS.SOURCE == 2 && this.validTechdataCredential) || (this.details.ORDERDETAILS.SOURCE == 3 && this.validSynnexCredential)) 
      //   && typeof this.details.orderParams.distributorID == "undefined") {
      //   this.canProcessWithDisty = true;
      // }
      if(this.sourceCredentials.length && typeof this.details.orderParams.distributorID == "undefined" && this.details.ORDERDETAILS.SOURCE != 1) {
        const thisSourceCredential = this.sourceCredentials.find(tmp => tmp.VARSOURCE_ID == this.details.ORDERDETAILS.SOURCE);
        if(thisSourceCredential && thisSourceCredential.VARSOURCECREDENTIAL_VALID == 1) {
          this.canProcessWithDisty = true;
        }
      }

      if(typeof this.details.orderParams.distributorID != "undefined") {
        this.distyProcessedID = this.details.orderParams.distributorID;
      }

      // check if this order has payment info
      if(this.details.orderStatus) {
        this.orderPaymentInfo = this.details.orderStatus.find(item => (item.status == 5 || item.status == 10) && item.Status_Details != "");
        if(this.orderPaymentInfo) {
          this.orderHasPaymentInfo = true;
        }
      }

      // data for category
      for(const item of this.details.ORDERITEMS) {
        item.ItemCategory = item.POCATEGORY || 0;
        item.ItemCategoryName = item.CATEGORYNAME || "";
        if((item.subOrderItemsArray || []).length) {
          item.subOrderItemsArray = item.subOrderItemsArray.map(subItem => ({
            ...subItem,
            ItemCategory: subItem.POCATEGORY || 0,
            ItemCategoryName: subItem.CATEGORYNAME || ""
          }));
        }
      }
    }
    this.allowEditPayment = this.details.allowEditPayment || false;
    this.PaymentText = this.details.PaymentText || "";
    this.paymentConfirmID = this.details.PaymentConfirmID || "";
    this.bkLedger();

    this.loading = false;
  }

  async getDetailsForUnverify() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "UnverifiedView",
        CustomerPO: this.$route.params.id
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        this.items = response.data.ORDERITEMS;
        this.details = response.data;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    // console.log('mounted',this.details.ORDERDETAILS.POID.length)
    if (
      this.details.ORDERDETAILS.POID &&
      this.details.ORDERDETAILS.POID.length > 14
    ) {
      $("#poEdit .poContainer").addClass("widthControl");
    } else {
      if ($("#poEdit .poContainer").hasClass("widthControl")) {
        $("#poEdit .poContainer").removeClass("widthControl");
      }
      // $('span.edit').removeClass('changeWidth');
    }
  }

  /* REQUEST WHITE GLOVE AJAX CALL */
  async requestWhiteGlove() {
    this.apiLoading = true;
    var dataObj = {
      controller: "ExternalSends",
      FunctionName: "whiteglove",
      purchaseID: this.$route.params.id,
      userRole: sessionStorage.getItem("userRole"),
      aID: this.details["ORDERDETAILS"]["AID"]
    };
    var self = this;
    var response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);  
    if (response.data.STATUS == 1) {

        self.details.orderStatus.push({
        Id: response.data.OSID,
        Status_Details: response.data.STATRETURN[0].STATUSDETAILS,
        dateTime: response.data.STATRETURN[0].STATDATE,
        emailed: response.data.STATRETURN[0].EMAILED,
        fullName: response.data.STATRETURN[0].USERNAME,
        invoiceID: response.data.STATRETURN[0].INVID,
        number: response.data.STATRETURN[0].REQUESTID,
        showOrderProfit: 0,
        sortTime: response.data.STATRETURN[0].SORTDATE,
        source: response.data.STATRETURN[0].STATSOURCE,
        sourceName: "Manual",
        status: response.data.STATRETURN[0].STATTYPE,
        type: response.data.STATRETURN[0].TYPENAME
        });

        /* self.reloadDetails(); */

      } else {
        notifier.alert(response.data.MESSAGE);
      }
      this.apiLoading = false;
      this.apiExternalModalVisible = false;
  }

  deleteStatus(id, status) {
    var dataObj = {
      controller: "Status",
      FunctionName: "Delete",
      Status_ID: id,
      order_id: this.$route.params.id,
      status: status,
      aID: this.details["ORDERDETAILS"]["AID"]
    };
    var self = this;
    var result = getRouteData(dataObj);
    result.then(function(response) {
      if (response.data.STATUS == 1) {
        notifier.success(response.data.MESSAGE);
        self.reloadDetails();
      } else {
        notifier.alert(response.data.MESSAGE);
      }
    });
  }
  disableRow(rowId, detailID) {
    $("#" + rowId + " .list-tab").css({
      color: "#ccc",
      "font-style": "italic",
      cursor: "not-allowed"
    });
    var dataObj = {
      controller: "Orders",
      FunctionName: "View",
      Content: "Detailed",
      purchaseID: this.$route.params.id,
      mcDetailID: detailID,
      disableRowFlag: true
    };
    var self = this;
    var result = getRouteData(dataObj);
    result.then(function(response) {
      self.details.relatedCommission = response.data.relatedCommission;
    });
  }

  async sortBy(field) {
    this.ledgerLoading = true;
    this.sort.field = field;
    this.sort.direction[this.sort.field] =
      this.sort.direction[this.sort.field] == 1 ? 2 : 1;
    await this.getOrdersdetails();
    this.ledgerLoading = false;
  }

  async sortingByType() {
    this.sortBy(DetailSortField.Type);
  }

  async sortingByStatus() {
    this.sortBy(DetailSortField.Status);
  }

  async sortingByDueDate() {
    this.sortBy(DetailSortField.DueDate);
  }

  exportChange(e, id) {
    this.exportModalVisible = true;
    this.exportPage = e;
    this.exportInvoiceId = id;
  }
  emailChange(id) {
    this.emailModalVisible = true;
    this.exportInvoiceId = id;
  }
  async paycheckGenerated() {
    this.emailModalVisible = false;
  }
  async viewInvoice(e, id) {
    this.loading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Invoices",
      FunctionName: "ExportView",
      invoiceID: id,
      ExportType: "HTML",
      Content: "Detailed"
    });
    var supportList = response.data;
    var templateInv;
    supportList.ispreview = 1;
    if (supportList.TemplateSetting.TemplateName != "") {
      const mainTemplate = supportList.TemplateSetting.TemplateName.split(
        "/"
      );
      templateInv = require("../templates/invoices/" +
        mainTemplate[mainTemplate.length - 1]);
    }
    else
    {
      //Use default invoice template if no define
      templateInv = require("../templates/invoices/invoiceDetails.handlebars");
    }
    var html = templateInv(supportList);
    this.viewInvoiceDetail = html;
    this.invoiceModelVisible = true;
    this.exportInvoiceId = id;
    this.loading = false;
    
  }
  reloadDetails() {
    var container = this.$root;
    //LoadData("ViewOrder", container);
    this.getOrdersdetails();
    $(".orderStatus").click();
  }
  reloadInvoiceDetails() {
    var container = this.$root;
    //LoadData("ViewOrder", container);
    this.getOrdersdetails();
    $(".orderInvoices").click();
  }
  orderCreateInvoice() {
    this.$router.push({
      name: "InvoiceCreate",
      params: { fromOrder: this.$route.params.id }
    });
  }
  orderCreateStatus() {
    this.$router.push({
      name: "StatusCreate",
      params: { id: this.$route.params.id }
    });
  }
  addNotes(note) {
    this.addNote(note);
    $("#order-note-value").value("");
  }

  addrow(index) {
    if (index == this.orderLedgerData.length) {
      var orderLedgerNew = {
        D_FINTYPE_ID: 0,
        D_FINANCIALSTATUS_ID: 0,
        PLID: 0,
        D_FINANCIALSOURCE_NAME: "",
        PL_TYPE: 2,
        PL_AMOUNT: 0,
        PL_STATUS: 0,
        D_FINANCIALSTATUS_NAME: "UNPAID",
        PL_TIMESTAMPFORMAT: moment(Date.now()).utc().format("MM/DD/YYYY hh:mm A"),
        FINSOURCEID : 0,
        INVOICEID : "",
        ORDERINVOICEDATE : "",
        DUEDATE : "",
        INVOICEDATE : moment(Date.now()).utc().format("MM/DD/YYYY"),
        newRow : 1,
        PAYMENTID: 0
      };
      this.orderLedgerData.push(orderLedgerNew);
    }
  }

  checkIncludeCustomerInv(index, checkIncludeInv) {
    if (checkIncludeInv) {
      this.confirmdeleteIncludeCustomeInv = true;
      this.removeIncludeInvIndex = index;
    } else {
      this.removeItem(index, 0);
    }
  }

  async removeIncludeInv() {
    if (this.removeIncludeInvIndex) {
      this.removeInvLoading = true;
      await this.removeItem(this.removeIncludeInvIndex, 0);
      this.confirmdeleteIncludeCustomeInv = false;
      this.removeIncludeInvIndex = 0;
      this.removeInvLoading = false;
    }
  }

  async removeItem(index, from) {
    var reqBody = {};
    var isDelete = 0;
    this.active = true;

    if (this.orderLedgerData[index].PLID) {
      reqBody["Controller"] = "Orders",
      reqBody["FunctionName"] = "orderLedgerEdit",
      reqBody["purchaseID"] = parseInt(this.$route.params.id),
      reqBody["action"] = "delete",
      reqBody["orderLedgerID"] = this.orderLedgerData[index].PLID;
      reqBody["ledgerType"] = this.orderLedgerData[index].PL_TYPE;
      reqBody["paymentId"] = this.orderLedgerData[index].PAYMENTID;
      isDelete = 1;
    }
    var deletedLedgerID = this.orderLedgerData[index].PLID;
    const removeIndex = this.orderLedgerData.findIndex( item => item.PLID === deletedLedgerID );
    // remove object
    if (!(removeIndex && deletedLedgerID)) {
      this.orderLedgerData.splice(index, 1);
    }
    if (isDelete) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS == 1) {
        if (removeIndex && deletedLedgerID) {
          this.orderLedgerData.splice(removeIndex, 1);
        }
        this.getOrdersdetails();
        notifier.success(response.data.STATUSMESSAGE);
        this.orderLedgerLength = this.orderLedgerLength - 1;
        this.active = false;
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    }
  }

  updateProgress(value) {
    this.progress = value;
    this.updateStatus(
      value,
      this.details.ORDERDETAILS.PODESCRIPTION,
      this.details.ORDERDETAIL[0].AID
    );
  }

  async toggleActive(div) {
    if (this.isActive == "orderInvoices") {
      this.exportPage = "order";
      this.exportInvoiceId = 0;
    }
    this.$route.params.msg = "received";
    this.isActive = div;
  }

  async loadfiles() {
    var response = await orderRepo.findOneDetails(this.$route.params.id);
    this.details = response;
  }

  async editPO() {
    this.isEditPo = !this.isEditPo;
    var width = $(".poContainer").outerWidth() - 12;
    if ($("#poEdit input").hasClass("displayNone")) {
      $("#poEdit .poContainer").addClass("displayNone");
      $("#poEdit input")
        // .css("width", width)
        .removeClass("displayNone");
    } else {
      $("#poEdit input").addClass("displayNone");
      $("#poEdit .poContainer").removeClass("displayNone");
      $("#poEdit .poContainer").removeClass("displayNone");
    }
    if ($("#poEdit .poContainer").hasClass("widthControl")) {
      if (!$("#poEdit input").hasClass("displayNone")) {
        $("span.edit").removeClass("changeWidth");
      } else {
        $("span.edit").addClass("changeWidth");
      }
    } else {
      if ($("span.edit").hasClass("changeWidth")) {
        $("span.edit").removeClass("changeWidth");
      }
    }
  }
  async savePo(e) {
    if (this.details.ORDERDETAILS.POID == this.PoOrder) {
      return;
    }

    if (this.details.ORDERDETAILS.POID.trim() != "") {
      try {
        //this.loading = true;
        var updatePo = await orderRepo.updatePoOrder(
          this.details.ORDERDETAILS.POID,
          this.details.ORDERDETAILS.PURCHASEID,
          this.details.ORDERDETAILS.PODESCRIPTION,
          this.details.ORDERDETAIL.AID,
          false
        );

        //recall update api in case used a duplicated PO
        if (
          updatePo.STATUS == 0 &&
          typeof updatePo.POexisted != "undefined" &&
          updatePo.POexisted
        ) {
          if (
            confirm(
              "This PO is already in use are you sure you want to create?"
            )
          ) {
            updatePo = await orderRepo.updatePoOrder(
              this.details.ORDERDETAILS.POID,
              this.details.ORDERDETAILS.PURCHASEID,
              this.details.ORDERDETAILS.PODESCRIPTION,
              this.details.ORDERDETAIL.AID,
              true
            );
          } else {
            this.details.ORDERDETAILS.POID = this.PoOrder;
          }
        }

        if (updatePo.STATUS == 1) {
          this.PoOrder = this.details.ORDERDETAILS.POID;
          //LoadData("ViewOrder", this.$root);
          await this.editPO();
        }

        //this.loading = false;
      } catch (error) {
        // console.log("Save Po error", error);
      }
    } else {
      this.details.ORDERDETAILS.POID = this.PoOrder;
    }
  }
  async updatePO(event) {
    if (this.details.ORDERDETAILS.POID.length > 14) {
      $("#poEdit .poContainer").addClass("widthControl");
    } else {
      // $('span.edit').removeClass('changeWidth');
      $("#poEdit .poContainer").removeClass("widthControl");
    }
    if (event.key == "Enter" || event.key == "Tab") {
      $("#poEdit input").addClass("displayNone");
      $("#poEdit .poContainer").removeClass("displayNone");
    }
  }

  async changeStatus(
    invoiceId: number,
    status: InvoiceStatusValue,
    poId: number,
    confirmedVals = {}
  ) {
    // status: 0 (sent), 1 (paid), 2 (created)
    if (status == 1 && !this.StatusPaidConfirmed) {
      this.confirmStatusPaidVisible = true;
      this.currentInvoiceToPaid = invoiceId;
    } else {
      try {
        this.loading = true;
        const response = await invoiceRepo.updateInvoiceStatus(
          invoiceId,
          status,
          poId,
          this.details.MARGIN,
          this.details["ORDERDETAILS"]["AID"],
          confirmedVals
        );
        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
          throw new Error(response.data.STATUSMESSAGE);
        }

        // for add status when make invoice as paid
        // if(status == 1){
        //   this.statusDetails ={
        //     date : new Date(),
        //     profit : this.details.PROFITCOUNT,
        //     name : 'Invoice - '+invoiceId,
        //     status : 0,
        //     type : 5
        //   };
        //   this.StatusPaidConfirmed = true;
        //   this.createStatus(this.statusDetails);
        // }

        // var showNotify = true;
        /*if (status == 1) {
          //changed this invoice to paid
          var remainedUnpaidInvoices = this.details.invoicesBelongTo.filter(
            (item: any) => item.INVOICEID != invoiceId && item.IPAID != 1
          );
          if (!remainedUnpaidInvoices.length) {
            //show confirm modal to confirm profit value
            this.confirmProfitModalVisible = true;
            showNotify = false;
          }
        }*/
        
        // if (showNotify) {
        //   notifier.success("Status Updated Successfully");
        // }
        if(response.data.STATUS) {
          await this.reloadInvoiceDetails();
          notifier.success("Status Updated Successfully");
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
        this.confirmStatusPaidVisible = false;
        this.StatusPaidConfirmed = false;
        this.currentInvoiceToPaid = 0;
      }
    }
  }

  async orderDetailsHtml() {
    try {
      const orderDetails = await orderRepo.findOneDetails(
        this.$route.params.id,
        this.onExport
      );

      const tempPosition1 = orderDetails.TemplateSetting.PartialPosition1;
      const tempPosition2 = orderDetails.TemplateSetting.PartialPosition2;
      if (tempPosition1.length > 0) {
        tempPosition1.forEach(item => {
          var orderDetails_position1 = require("../templates/orders/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(item.partialname, orderDetails_position1);
        });
      }
      if (tempPosition2.length > 0) {
        tempPosition2.forEach(item => {
          var orderDetails_position2 = require("../templates/orders/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(item.partialname, orderDetails_position2);
        });
      }
      if (orderDetails.TemplateSetting.TemplateName != "") {
        const mainTemplate = orderDetails.TemplateSetting.TemplateName.split(
          "/"
        );
        var template = require("../templates/orders/" +
          mainTemplate[mainTemplate.length - 1]);
        const html = template(orderDetails);
        return html;
      } else {
        return "";
      }
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async invoiceDetailsHtml(id) {
    try {
      const invoiceDetails = await invoiceRepo.findOneDetails(
        id,
        this.onExport
      );
      const tempPosition1 = invoiceDetails.TemplateSetting.PartialPosition1;
      const tempPosition2 = invoiceDetails.TemplateSetting.PartialPosition2;
      if (tempPosition1.length > 0) {
        tempPosition1.forEach(item => {
          var invoiceDetails_position1 = require("../templates/invoices/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(
            item.partialname,
            invoiceDetails_position1
          );
        });
      }
      if (tempPosition2.length > 0) {
        tempPosition2.forEach(item => {
          var invoiceDetails_position2 = require("../templates/invoices/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(
            item.partialname,
            invoiceDetails_position2
          );
        });
      }

      if (invoiceDetails.TemplateSetting.TemplateName != "") {
        const mainTemplate = invoiceDetails.TemplateSetting.TemplateName.split(
          "/"
        );
        var template = require("../templates/invoices/" +
          mainTemplate[mainTemplate.length - 1]);
        const html = template(invoiceDetails);
        return html;
      } else {
        return "";
      }
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async htmlDownLoadFn() {
    var html = "";
    if (this.exportPage == "invoice") {
      html = await this.invoiceDetailsHtml(this.exportInvoiceId);
    } else {
      html = await this.orderDetailsHtml();
    }
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      if (!html) return;
      var fileNameExport = `OrderExport_${this.$route.params.id}.html`;

      downloadFile(fileNameExport, html);
    };
  }

  async print() {
    // this.loading = true;
    const html = await this.orderDetailsHtml();
    if (!html) {
      this.loading = false;
      return;
    }
    printHtml(html);
    this.loading = false;
  }

  async printInvoice(id) {
    const html = await this.invoiceDetailsHtml(id);
    if (!html) return;

    printHtml(html);
  }

  async saveInvoice(id) {
    const html = await this.invoiceDetailsHtml(id);
    if (!html) return;

    printHtml(html);
  }

  async exportPDF() {
    let fileUrl = "";
    try {
      if (this.exportPage == "invoice") {
        var ids = [`${this.exportInvoiceId}`];
        fileUrl = await invoiceRepo.exportDetailsPDFFileUrl(
          this.exportInvoiceId,
          this.onExport
        );
      } else {
        fileUrl = await orderRepo.exportDetailsPDFFileUrl(
          this.$route.params.id,
          this.onExport
        );
      }
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }
    this.loading = false;
    this.exportModalVisible = false;

    return () => {
      downloadFileUrl(fileUrl);
    };
  }
  async exportCSV() {
    let fileUrl = "";
    try {
      if (this.exportPage == "invoice") {
        fileUrl = await invoiceRepo.exportDetailsCSVFileUrl(
          this.exportInvoiceId,
          this.onExport
        );
      } else {
        fileUrl = await orderRepo.exportDetailsCSVFileUrl(
          this.$route.params.id,
          this.onExport
        );
      }
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async performExport(
    formats: ExportFormat[],
    templateId,
    updateDefault,
    showOnExport,
    pricingid,
    exportPO,
    azTempname = "",
    advanced?,
    customMessages = {},
    exportOrderDetail?,
    reportingOrderDetail?,
    exportDirectPaycheckDetail?,
    CustomDistyName?,
    CustomDistyAddr1?,
    CustomDistyAddr2?,
    CustomDistyCity?,
    CustomDistyState?,
    CustomDistyZip?
  ) {
    // this.loading = true;
    this.onExport = {
      templateid: templateId,
      saveAsDefault: updateDefault ? 1 : 0,
      showOnExport: showOnExport ? 1 : 0,
      exportPO: exportPO ? 1 : 0,
      pricingid: pricingid,
      advanced: advanced,
      customMessages: customMessages,
      CustomDistyName: CustomDistyName,
      CustomDistyAddr1: CustomDistyAddr1,
      CustomDistyAddr2: CustomDistyAddr2,
      CustomDistyCity: CustomDistyCity,
      CustomDistyState: CustomDistyState,
      CustomDistyZip: CustomDistyZip
    };

    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.exportCSV());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.exportPDF());
    }

    exportFns.forEach(fn => fn());
    // this.exportPage = "order";
    var response = await orderRepo.findOneDetails(this.$route.params.id);
    this.customerOrdertemplate = response.AccountOrderTemplateId;
    this.loading = false;
  }
  async statusClose(e) {
    if (!this.StatusPaidConfirmed) {
      this.statusModalVisible = true;
    }
    if (e) {
      await wait(3000);
      this.StatusPaidConfirmed = false;
    }
  }
  async createStatus(statusDetails) {
    this.statusDetails = statusDetails;
    this.statusModalVisible = false;
    if (statusDetails.type == 5 && !this.StatusPaidConfirmed) {
      // test
      // this.statusConfirmModalVisible = true;
      this.confirmStatusPaidVisible = true;
    } else {
      try {
        this.loading = true;
        var requestData = {
          controller: "orders",
          FunctionName: "orderStatus",
          update: true,
          Id: this.$route.params.id,
          date: statusDetails.date,
          type: statusDetails.type,
          source: statusDetails.status,
          name: statusDetails.name,
          partial : statusDetails.isPartial,
          profit: statusDetails.profit,
          statusNote: statusDetails.name,
          Status_Details : statusDetails.name,
          aID: this.details["ORDERDETAILS"]["AID"]
        };
        if(statusDetails.type == 5) {//set paid status
          requestData["isPaid"] = true;
        }

        // send more info in case confirm a create paid status for this order
        if(typeof statusDetails.paymentAmount != "undefined") {
          requestData["paymentAmount"] = statusDetails.paymentAmount;
        }
        if(typeof statusDetails.paymentDate != "undefined") {
          requestData["paymentDate"] = statusDetails.paymentDate;
        }
        if(typeof statusDetails.paymentType != "undefined") {
          requestData["paymentType"] = statusDetails.paymentType;
        }
        if(typeof statusDetails.paymentReference != "undefined") {
          requestData["paymentReference"] = statusDetails.paymentReference;
        }
        if(typeof statusDetails.orderSetPaidStatus != "undefined") {
          requestData["orderSetPaidStatus"] = statusDetails.orderSetPaidStatus;
        }

        const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
          throw new Error(response.data.STATUSMESSAGE);
        }
        this.orderTemplates = [];
        await this.reloadDetails();
        this.toggleActive("orderStatus");
      } catch (err) {
        notifier.alert("Create status error: " + err.message);
      } finally {
        this.loading = false;
        this.statusConfirmModalVisible = false;
        this.statusModalVisible = false;
        this.StatusPaidConfirmed = false;
      }
    }
  }

  async cancelOrder() {
    this.cancelModalVisible = false;

    try {
      this.loading = true;
      LoadData("ViewOrder", this.$root);
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
      this.reloadDetails();
    }
  }

  async saveIncludeInPayroll() {
    try {
      // this.loading = true;
      var ret = await orderRepo.saveIncludeInPayroll(
        parseInt(this.$route.params.id),
        this.orderParams.IncludeInPayroll
      );
      if (ret == true) {
        // notifier.success("Updated Successfully");
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      // this.loading = false;
    }
  }

  async sendToQuickbooks() {
    // const path = 'http://localhost:3005/orders/send_to_quickbooks?purchaseId=' + this.$route.params.id
    const path =
      microserviceQBOURL +
      "/orders/send_to_quickbooks?purchaseId=" +
      this.$route.params.id;
    var win;
    var checkConnect;
    var parameters = "location=1,width=800,height=650";
    parameters +=
      ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;

    // Launch Popup
    win = window.open(path, "connectPopup", parameters);
  }

  async removeSelected() {
    var refModal: any = this.$refs.confirmRemoveModal;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        session: sessionStorage.getItem("sessionID"),
        userRole: sessionStorage.getItem("userRole"),
        Controller: "orders",
        FunctionName: "Delete",
        deletePurchase: "true",
        deletePurchaseFile: "true",
        selectedAll: false,
        excludedIDs: [],
        selectedIDs: this.$route.params.id,
        customerAID: this.details["ORDERDETAILS"]["AID"]
      });

      if (response.data.ERROR) {
        notifier.alert(response.data.ERROR);
        refModal.deleting = "error";
        return false;
      }

      if (response.data.STATUS == 1) {
        this.$router.push({ name: "Orders" });
      }
    } catch (err) {
      refModal.deleting = "error";
    } finally {
      refModal.deleting = false;
      this.confirmRemoveModalVisible = false;
    }
  }

  updateStatus(status, orderName, aid) {
    var dataObj = {};
    const purchaseID = parseInt(this.$route.params.id);
    dataObj["controller"] = "Orders";
    dataObj["FunctionName"] = "Edit";
    dataObj["mdEditOrdername"] = orderName;
    dataObj["mdEditQuote"] = status;
    dataObj["mdaID"] = aid;
    dataObj["purchaseID"] = purchaseID;
    var $this = this;
    if (purchaseID != 0) {
      var orderData = getRouteData(dataObj);
      orderData.then(function(response, statusText, jqXHR) {
        try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
            $this.getOrdersdetails();
          } else if (!response.data.STATUS && response.data.STATUSMESSAGE) {
            notifier.alert(response.data.STATUSMESSAGE);
          } else if (response.data.ERROR) {
            // console.log(response.data.ERROR);
          }
        } catch (e) {
          //handle error
        }
      });
    }
  }

  profitStatusClose() {
    this.profitStatusVisible = false;
  }

  profitStatusOpen() {
    if (!this.details.ORDERDETAILS.CONFIRMED) {
      this.profitStatusVisible = true;
    }
  }

  showModal(show) {
    if (show == true) {
      $(".modal").addClass("b");
    } else {
      $(".modal").removeClass("b");
    }
  }

  addNote(notes) {
    var dataObj = {};
    const purchaseID = parseInt(this.$route.params.id);
    dataObj["controller"] = "Orders";
    dataObj["FunctionName"] = "Edit";
    dataObj["purchaseID"] = purchaseID;
    dataObj["mdPurchaseNote"] = notes;
    dataObj["mdNoteSelect"] = 1;
    var $this = this;

    if (purchaseID != 0) {
      var orderData = getRouteData(dataObj);
      orderData.then(function(response, statusText, jqXHR) {
        try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
            $this.getOrdersdetails();
          } else if (!response.data.STATUS && response.data.STATUSMESSAGE) {
            notifier.alert(response.data.STATUSMESSAGE);
          } else if (response.data.ERROR) {
            // console.log(response.data.ERROR);
          }
        } catch (e) {
          //handle error
        }
      });
    }
  }

  rowsGrouppedBySKU = [];
  rowsGrouppedLoading = false;
  async showRowsGrouppedBySKU(index, item) {
    if (this.grouppedBySKUIndex == index) {
      this.grouppedBySKUIndex = -1;
      return;
    }

    this.grouppedBySKUIndex = index;
    this.rowsGrouppedBySKU = [];
    try {
      this.rowsGrouppedLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "GetCommissionLinesByPONumber",
        poNumber: this.PoOrder,
        partNumber: item.MC_CDETAIL_PARTNUMBER
      });

      if (response.data.STATUS == 1) {
        this.rowsGrouppedBySKU = response.data.COMMISSIONROWS;
      }
    } catch (err) {
      this.grouppedBySKUIndex = -1;
    } finally {
      this.rowsGrouppedLoading = false;
    }
  }

  hideRowsGroupped() {
    this.grouppedBySKUIndex = -1;
    this.grouppedByPaycheckDetails = false;
  }

  async showCustomData() {
    this.customDataVisible = !this.customDataVisible;
    if (this.customDataVisible) {
      this.customDataLoading = true;
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Helpers",
          FunctionName: "CustomData",
          DataType: "1",
          Id: this.$route.params.id,
          DropdownField : 25,
          contractDropdown : 26,
          orderCustomData : true,
          aId: this.details.ORDERDETAILS.AID,
          ignoreFieldIds: "26"
        });

        if (response.data.STATUS == 1) {
          this.fullCustomData = [];
          for (var val of response.data.CUSTOMDEFINITION) {
            let tmpValue = response.data.CUSTOMDATA.filter(
              tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID
            );
            this.fullCustomData.push({
              CUSTOMFIELDID: val.CUSTOMFIELDID,
              CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
              CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
              CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
              CUSTOMDTYPE : val.CUSTOMDTYPE || 1,
              OPTIONS: val.OPTIONS || []
            });
            if(val.CUSTOMFIELDID == 25)
              this.selectedValue = tmpValue.length ? tmpValue[0].CUSTOMVALUE : "Please make a selection";
            if(val.CUSTOMDTYPE == 3 && val.CUSTOMFIELDNAME == "Custom Data on Export")
              this.selectedContractValue = tmpValue.length ? tmpValue[0].CUSTOMVALUE : "Please make a selection";
              this.contractFieldId = val.CUSTOMFIELDID;
          }

          this.globalTags = response.data.globalTags;
          this.selectedGlobalTags = response.data.selectedGlobalTags.map(
            (val: any) => ({
              CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
              CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
            })
          );
          this.selectedGlobalTagsID = this.selectedGlobalTags.map(
            (val: any) => val.CUSTOMFIELDOPTIONID
          );
          this.customDropdown = response.data.customnDropdownField;
          this.selectedCustomId = response.data.selectedDropdownField;
          this.orderContractNumber = response.data.customContractNumber;
          this.selectedContractId = response.data.selectedContractNo;
          this.selectedContracts = response.data.selectedContractData;
          if(this.selectedContracts.length) {
            this.selectedOrderContracts = this.selectedContracts[0].CUSTOMFIELDOPTIONNAME;
            this.orderContractsID = this.selectedContracts.map(
              (val: any) => val.CUSTOMFIELDOPTIONID
            );
          }

          this.financialSource = response.data.financialSource;
          this.selectedFundingSourceId = response.data.FUNDINGSOURCEID;
          this.selectedFinSourceValue = response.data.FUNDINGSOURCENAME;
        }
      } catch (err) {
        // console.log(err.message);
      } finally {
        this.customDataLoading = false;
      }
    }
  }

  showCustomEditBox(index) {
    this.customDataIndex = index;

    setTimeout(() => {
      $(".custom-data-txt")
        .focus()
        .select();
    }, 100);
  }
  hideCustomEditBox() {
    this.customDataIndex = -1;
  }

  showSourceCustomEditBox(val) {
    this.customSourceData = val;
    setTimeout(() => {
      $(".custom-data-source")
        .focus()
        .select();
    }, 100);
  }
  hideSourceCustomEditBox() {
    this.customSourceData = -1;
  }

  showFundingEditBox(val){
    this.fundingSourceField = val;
  }
  hideFundingEditBox() {
    this.fundingSourceField = -1;
  }

  async updateCustomData(item) {
    //remove HTML
    if((item.CUSTOMDTYPE || 1) != 3) {
      item.CUSTOMVALUE = this.removeHTML(item.CUSTOMVALUE);
    }

    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        customID: item.CUSTOMID,
        customFieldID: item.CUSTOMFIELDID,
        customValue: item.CUSTOMVALUE,
        Id: this.$route.params.id,
        fieldTypeId: 1
      });

      if (response.data.STATUS == 1) {
        this.customDataIndex = -1;
        if (item.CUSTOMVALUE == "") {
          //delete this item
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = 0;
        } else if (typeof response.data.NEWCUSTOMID != "undefined") {
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = response.data.NEWCUSTOMID;
        }
        this.getOrdersdetails();
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

    async updateCustomSoureData(source) {
    //remove HTML
    source = this.removeHTML(source);
    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        customSourceName: source,
        Id: this.$route.params.id
      });

      if (response.data.STATUS == 1) {
        this.customSourceData = -1;
        this.getOrdersdetails();
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }
  notHPSource() {
    if (
      this.details["ORDERDETAILS"].SOURCE != 0 &&
      this.details["ORDERDETAILS"].SOURCE != 1
    ) {
      return true;
    }
    return false;
  }
  selectingDisty(disty) {
    if (
      disty.toLowerCase() ==
      this.details["ORDERDETAILS"].VARSOURCE_NAME.toLowerCase()
    ) {
      return true;
    }
    return false;
  }
  getDistyTitle(item) {
    var ret = item.Disty + " Price: ";
    if (item.DistyPrice == 0) {
      ret += "Not Available";
    } else {
      ret += item.DistyPrice_Format;
    }

    ret += "\nAvailability: ";

    if (item.DistyAvailability == 0) {
      ret += "Not Available";
    } else {
      ret += item.DistyAvailability_Format + " units";
    }

    return ret;
  }

  selectedGlobalTagsID: number[] = [];
  selectedGlobalTags: object[] = [];
  toggleSelectedGlobalTags(selectedID: number, tagName) {
    const index = this.selectedGlobalTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedGlobalTagsID.push(selectedID);
      this.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      this.saveGlobalTag();
      return;
    }

    this.selectedGlobalTagsID.splice(index, 1);
    this.$delete(this.selectedGlobalTags, index);
    this.saveGlobalTag();
  }

  selectAllGlobalTags() {
    this.resetGlobalTags();
    this.selectedGlobalTagsID = this.globalTags.map(
      (val: any) => val.CUSTOMFIELDOPTIONID
    );
    this.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    this.saveGlobalTag();
  }

  resetGlobalTags() {
    this.selectedGlobalTags = [];
    this.selectedGlobalTagsID = [];
    this.saveGlobalTag();
  }

  updateGlobalTagList(type, index, tagID) {
    if (this.selectedGlobalTagsID && type == "delete") {
      this.$delete(this.selectedGlobalTagsID, index);
      this.$delete(this.selectedGlobalTags, index);
    }
    this.saveGlobalTag();
  }

  toggleSelectedContractNumber(selectedID: number, contractName) {
    const index = this.orderContractsID.findIndex(id => id === selectedID);
    let bigDealId = this.NASPOcontract;
    const contractGTags = this.globalTags.filter(tmp => tmp.CUSTOMFIELDOPTIONNAME == contractName);
    if (index === -1) {
      this.orderContractsID = [selectedID];
      this.selectedOrderContracts = contractName
      let tmpContractObj = this.orderContractNumber.find(
        tmp => tmp.CUSTOMFIELDOPTIONID == selectedID
      );
      if (typeof tmpContractObj != 'undefined' && typeof tmpContractObj.CUSTOMFIELDOPTIONDESC != "undefined") {
        bigDealId = tmpContractObj.CUSTOMFIELDOPTIONDESC;
      }
      //Add Related Global Tags
      const selectedGTags = this.selectedGlobalTags.findIndex((tmp: any) => tmp.CUSTOMFIELDOPTIONNAME.toUpperCase() == contractName.toUpperCase());
      if(selectedGTags == -1 && contractGTags.length) {
        this.selectedGlobalTagsID.push(contractGTags[0].CUSTOMFIELDOPTIONID);
        this.selectedGlobalTags.push({
          CUSTOMFIELDOPTIONID: contractGTags[0].CUSTOMFIELDOPTIONID,
          CUSTOMFIELDOPTIONNAME: contractGTags[0].CUSTOMFIELDOPTIONNAME
        });
      }
      this.saveContractNumber(bigDealId);
      this.saveGlobalTag();
      return;
    }
    this.orderContractsID.splice(index, 1);
    this.selectedOrderContracts = "";
    this.saveContractNumber(bigDealId);
    //Remove Related Global Tags
    if(contractGTags.length) {
      const tagIndex = this.selectedGlobalTagsID.findIndex(id => id === contractGTags[0].CUSTOMFIELDOPTIONID);
      this.selectedGlobalTagsID.splice(tagIndex, 1);
      this.selectedGlobalTags.splice(tagIndex, 1);
      this.saveGlobalTag();
    }
  }

  async saveGlobalTag() {
    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        optionIDs: this.selectedGlobalTagsID.join(","),
        Id: this.$route.params.id,
        action: "save_globalTags",
        fieldTypeId: 1
      });
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  async saveContractNumber(bigDealId = "") {
    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        optionIDs: this.selectedOrderContracts,
        Id: this.$route.params.id,
        fieldId: this.orderContractNumber[0].CUSTOMFIELDID,
        action: "save_ContractNumber",
        fieldTypeId: 1
      });
      if(response.data.STATUS === 1 && bigDealId != "") {
        this.bigDealId = bigDealId;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  async confirmProfitTotal(currentProfit) {
    var refModal: any = this.$refs.ConfirmProfitTotalRef;
    refModal.saving = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "orders",
        FunctionName: "orderStatus",
        update: true,
        Id: this.$route.params.id,
        date: new Date(),
        type: 5,
        source: 0,
        name: "order - " + this.details.ORDERDETAILS.POID,
        profit: currentProfit,
        isPaid: true
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.status !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      this.confirmProfitModalVisible = false;
      await this.reloadDetails();
    } catch (err) {
      // console.log(err);
    } finally {
      refModal.saving = false;
    }
  }
  async deleteFile(fileid) {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "orders",
        FunctionName: "Delete",
        fromDetails: true,
        pfileID: this.removeFileId
      });

      if (response.data.ERROR) {
        notifier.alert(response.data.ERROR);
        return false;
      }

      if (response.data.STATUS == 1) {
        const fileType = this.filetypes[this.removeFiletype] || '';
        if (fileType) {
          notifier.success(`"${fileType}" File Deleted Successfully!`);
        } else {
          notifier.success(response.data.STATUSMESSAGE);
        }
        // this.$router.push({ name: "Orders" });
        this.loading = false;
        this.getOrdersdetails();
      }
      if (response.data.STATUS == 2) {
        this.loading = "error";
        notifier.alert(response.data.STATUSMESSAGE);
        // this.$router.push({ name: "Orders" });
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
      this.removeFileId = 0;
      this.confirmFileRemove = false;
    }
  }
  async stripeTest() {
    if(this.cvc.length < 3) {
      this.stripeError = 5
    }
    else {
      this.stripeError = 0;
    }
    if(this.paymentTotalAmount == false || this.verifiedBtnEnabled) {
      return;
    }
    var $form = $("#posttodetailpop");
    var $this = this;
    var stripeResponseHandler = function(status, response) {
      //Stripe token failure...
      if (response.error) {
        // console.log("response error",response)
        var msg = "card name";
        if (response.error.param == "number") {
          $this.stripeError = 2;
          msg = "card number";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
          msg = "month";
        } else if (response.error.param == "exp_year") {
          $this.stripeError = 4;
          msg = "year";
        } else if (response.error.param == "cvc") {
          $this.stripeError = 5;
          msg = "cvc";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
        } else {
          $this.stripeError = 1;
        }
        notifier.alert("Verification failed due to invalid " + msg);
        $this.verifiedBtnEnabled = false;
      } else {
        notifier.success("Card details verified successfully..");
        $this.stripeToken = response.id;
        $this.stripeLast4 = response.card.last4;
        $this.stripeError = 0;
      }
    };
    // card = elements.create('card');
    // card.mount($form);
    Stripe.card.createToken($form, stripeResponseHandler);
    this.verifiedBtnEnabled = true;
    this.submitPaymentBtnEnabled = false;
  }

  async stripeLoad() {
    //Stripe.js
    await $.getScript("https://js.stripe.com/v2/", function(
      data,
      textStatus,
      jqxhr
    ) {
      //Loaded
      let stripe = Stripe.setPublishableKey(`pk_test_8s3pPRB70t00jvzNnCOUzehQ`);
    }).fail(function(jqxhr, settings, exception) {
      // console.log("stripe load failes");
    });
  }

  async deletePayment() {
    // this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "updatePayment",
        aID: this.details.ORDERDETAILS.AID,
        purchaseID: this.$route.params.id,
        isUpdate: false
      });
      if (response.data.STATUS == 1) {
        this.getOrdersdetails();
        notifier.success(response.data.STATUSMESSAGE);
        this.resetstripeform();
        this.verifiedBtnEnabled = false;
        this.submitPaymentBtnEnabled = false;
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async deleteUncapturePayment() {
    try {
      this.removingCard = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "RemoveUncapturedPayment",
        aId: this.details.ORDERDETAILS.AID,
        purchaseId: this.$route.params.id,
        stripeChargeId: this.details.orderParams.stripeToken,
        stripeCustomerId: this.details.orderParams.stripeCustomerId,
        stripeCardId: this.details.orderParams.stripeCardId
      });
      if (response.data.STATUS == 1) {
        this.getOrdersdetails();
        this.resetstripeform();
        this.showcard = 3;
        this.hideCreditCard = false;
        this.hideCreditCardInfo = true;
        this.details.orderParams.stripeToken = "";
        this.details.orderParams.stripeCustomerId = "";
        this.details.orderParams.stripeCardId = "";
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if(message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.removingCard = false;
    }
  }

  cancelDeletePayment() {
    // cancel delete a stripe card payment
    if (this.showcard == 2) {
      this.showcard = 1;
    } else if(this.showcard == 4) {
      // if cancel delete an uncapture payment, back to "submit payment" view
      this.showcard = 3;
    }
  }

  async updateCustomerQuote() {
    // this.loading = true;
    if(this.cardName == '') {
      this.stripeError = 1;
      notifier.alert("verification failed due to invalid card name");
      return;
    }
    else {
      this.stripeError = 0;
    }
    if(this.cvc == '') {
      this.stripeError = 5;
      notifier.alert("verification failed due to invalid cvc");
      return;
    }
    else {
      this.stripeError = 0;
    }
    if(this.submitPaymentBtnEnabled) {
      return;
    }
    var $form = $("#posttodetailpop");
    var $this = this;
    this.uploading = true;
    var stripeResponseHandler = function(status, response) {
      //Stripe token failure...
      if (response.error) {
        // console.log("response error",response)
        var msg = "card name";
        if (response.error.param == "number") {
          $this.stripeError = 2;
          msg = "card number";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
          msg = "month";
        } else if (response.error.param == "exp_year") {
          $this.stripeError = 4;
          msg = "year";
        } else if (response.error.param == "cvc") {
          $this.stripeError = 5;
          msg = "cvc";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
        } else {
          $this.stripeError = 1;
        }
        notifier.alert("verification failed due to invalid " + msg);
        return;
      } else {
        $this.stripeToken = response.id;
        $this.stripeLast4 = response.card.last4;
        $this.stripeError = 0;
      }
    };
    Stripe.card.createToken($form, stripeResponseHandler);
    setTimeout(async () => {
    if($this.stripeError == 0) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Orders",
          FunctionName: "updatePayment",
          token: this.stripeToken,
          last4: this.stripeLast4,
          aID: this.details.ORDERDETAILS.AID,
          cardName: this.cardName,
          purchaseID: this.$route.params.id,
          isUpdate: true,
          isNew: this.cardupdate,
          orderPaymentTotal: this.details.DUEBALANCE,
          paymentType : "Stripe"
        });
        if (response.data.STATUS == 1) {
          await this.getOrdersdetails();
          this.showcard = 1;
          this.resetstripeform();
          notifier.success(response.data.STATUSMESSAGE);
        } else {
          notifier.alert(response.data.STATUSMESSAGE);
        }
        this.uploading = false;
      } catch (err) {
        // console.log(err.message);
      } finally {
        this.loading = false;
        this.cardupdate = 0;
        this.uploading = false;
      }
    }
    }, 700);
    this.submitPaymentBtnEnabled = true;
    this.verifiedBtnEnabled = false;
  }
  async updatePaymentAPI() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "updatePayment",
        token: this.stripeToken,
        last4: this.stripeLast4,
        aID: this.details.ORDERDETAILS.AID,
        cardName: this.cardName,
        purchaseID: this.$route.params.id,
        isUpdate: true,
        isNew: this.cardupdate,
        orderPaymentTotal: this.details.DUEBALANCE
      });
      if (response.data.STATUS == 1) {
        await this.getOrdersdetails();
        this.showcard = 1;
        this.resetstripeform();
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
      this.cardupdate = 0;
    }
  }
  resetstripeform() {
    this.stripeToken = "";
    this.stripeLast4 = "";
    this.cardName = "";
    this.cardNumber = "";
    this.expMoth = "";
    this.expyear = "";
    this.cvc = "";
  }
  async validateCardName() {
    var self = this;
    const nameOnCard = $.trim($("#cc-name").val());

    if (htmlCheck(nameOnCard)) {
      self.cardName = htmlParse(nameOnCard);
      notifier.alert("HTML content found. This content has been removed!");
    }
  }

  async approveOrder() {
    var refModal: any = this.$refs.confirmApproveModal;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Orders",
        FunctionName: "ApproveOrder",
        purchaseID: this.$route.params.id
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 0) {
        if(typeof response.data.STATUSMESSAGE != "undefined" && response.data.STATUSMESSAGE != "") {
          notifier.alert(response.data.STATUSMESSAGE);
        }
        refModal.deleting = "error";
        this.resetstripeform();
        this.showcard = 3;
        this.PaymentText = "";
        this.details.PaymentInfo = [];
        return false;
      }else if(response.data.STATUS == 1) {
        // reload order status
        await this.reloadDetails();
        this.toggleActive("orderStatus");
        notifier.success("Order approved");
      }

      refModal.deleting = false;
    } catch (err) {
      refModal.deleting = "error";
    } finally {
      this.confirmApproveModalVisible = false;
    }
  }

  updateCanProcessWithDisty(val) {
    this.canProcessWithDisty = val;
  }

  getInvoicePaidNote(item) {
    var ret = "";
    try {
      if(item.Status_Details == "") {
        return "";
      }

      let tmp = JSON.parse(item.Status_Details);
      if(typeof tmp.PAYMENTAMOUNT != "undefined") {
        ret = ret + "<div>Payment Amount: " + (tmp.PAYMENTAMOUNT || "0") + "</div>";
      }
      if(typeof tmp.PAYMENTDATE != "undefined") {
        ret = ret + "<div>Payment Date: " + (tmp.PAYMENTDATE || "") + "</div>";
      }
      if(typeof tmp.PROFITTOTAL != "undefined") {
        ret = ret + "<div>Profit Total: " + (tmp.PROFITTOTAL || "") + "</div>";
      }
      if(typeof tmp.PAYMENTTYPE != "undefined") {
        ret = ret + "<div>Payment Type: " + (tmp.PAYMENTTYPE || "") + "</div>";
      }
      if(typeof tmp.PAYMENTREFERENCE != "undefined" && tmp.PAYMENTREFERENCE != "") {
        ret = ret + "<div class='status-pay-ref'>Payment Reference: " + (tmp.PAYMENTREFERENCE || "") + "</div>";
      }
    } catch (err) {
      // console.log(err);
    }

    return ret;
  }

  invoicePaidConfirm(confirmedVals) {
    this.StatusPaidConfirmed = true;
    if(this.currentInvoiceToPaid != 0) {
      // invoice set paid status
      this.changeStatus(this.currentInvoiceToPaid, 1, this.details.ORDERDETAILS.PURCHASEID, confirmedVals);
    }else {
      // order create paid status
      this.createStatus({
        date: confirmedVals.paymentDate,
        type: 5,
        name: "Order paid.",
        profit: confirmedVals.profitTotal,
        status: this.details.ORDERDETAILS.SOURCE,
        paymentAmount: confirmedVals.paymentAmount,
        paymentDate: confirmedVals.paymentDate,
        isPartial : confirmedVals.isPartial,
        paymentType: confirmedVals.paymentType,
        paymentReference: confirmedVals.paymentReference,
        orderSetPaidStatus: true
      });
      this.invoicePaidConfirmClose();
    }
  }

  invoicePaidConfirmClose(clickedCancel = false) {
    this.currentInvoiceToPaid = 0;
    this.confirmStatusPaidVisible = false;

    // back to create status modal if close invoice confirm modal
    if(this.confirmingPaidStatus) {
      this.confirmingPaidStatus = false;
      return;
    }
    if(clickedCancel && this.isActive === 'orderStatus') {
      this.statusModalVisible = true;
    }
  }

  isPaidOrder() {
    var ret = false;
    //if(typeof this.details.orderStatus != "undefined") {
     // var tmpCheck = this.details.orderStatus.filter(tmp => tmp.status == 5);
      //if(tmpCheck.length) {
       // ret = true;
      //}
    //}

    if(typeof this.details.ORDERDETAILS.PSTATUS != "undefined" && this.details.ORDERDETAILS.PSTATUS == 5) {
      ret = true;
    }

    return ret;
  }

  cardNumberChange() {
    // need to verify card again in this case
    this.stripeToken = "";
    this.verifiedBtnEnabled = false;
  }

  profitStatusSwitch(status = "unconfirm") {
    if(status == "confirm-paid") {
      this.profitStatusVisible = false;
      this.confirmStatusPaidVisible = true;
      this.confirmingPaidStatus = true;
    }
  }

  checkItemIsFee(val) {
    return (val.POPRODSKU.toLowerCase() == "fee" && val.POCUSTOMERPRICE == 0 && val.POINCLUDED == 0) ? true : false;

  }

  openPaymentInfo(invoiceID, purchaseID) {
    this.editPaymentForInvoiceID = invoiceID;
    this.editPaymentInfoVisible = true;
  }

  async confirmEditPaymentInfo(confirmedVals) {
    if(this.editPaymentForInvoiceID != 0) {
      try {
        // this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Orders",
          FunctionName: "UpdatePaymentInfo",
          purchaseID: this.$route.params.id,
          invoiceID: this.editPaymentForInvoiceID,
          profit: confirmedVals.profitTotal,
          paymentAmount: confirmedVals.paymentAmount,
          paymentDate: confirmedVals.paymentDate,
          paymentType: confirmedVals.paymentType,
          paymentReference: confirmedVals.paymentReference,
          isPartial:confirmedVals.isPartial,
          taxAmount: confirmedVals.taxAmount,
          invoicePartialAmount: confirmedVals.paymentAmount,
          partialAmountEdit: 1,
          paymentID : confirmedVals.paymentID,
          zeroPayment : parseFloat(confirmedVals.paymentAmount) == 0 ? true : false
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
          this.getOrdersdetails();
          notifier.success(response.data.STATUSMESSAGE);
        }else if(response.data.STATUSMESSAGE) {
          notifier.alert(response.data.STATUSMESSAGE);
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        // this.loading = false;
        this.editPaymentInfoVisible = false;
        this.editPaymentForInvoiceID = 0;
      }
    }
  }

  async invoicePaidRemove(){
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "UpdatePaymentInfo",
        purchaseID: this.$route.params.id,
        invoiceID: this.editPaymentForInvoiceID,
        partialAmountEdit: 1,
        invoiceReverted: 1
      });
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
        await this.getOrdersdetails();
        notifier.success(response.data.STATUSMESSAGE);
      }else if(response.data.STATUSMESSAGE) {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      // this.loading = false;
      this.editPaymentInfoVisible = false;
      this.editPaymentForInvoiceID = 0;
    }
  }

  orderPaymentAmount() {
    const oPaymentAmount = Math.abs(this.details.DUEBALANCE);
    const paymentGrantTotal = this.details.GRANDTOTAL.toFixed(2);
    if (oPaymentAmount > paymentGrantTotal || isNaN(oPaymentAmount) || oPaymentAmount == 0) {
      $(".paymentTotal #orderTotal").addClass("paymentTotalError");
      this.paymentTotalAmount = false;
    } else {
      $(".paymentTotal #orderTotal").removeClass("paymentTotalError");
      this.paymentTotalAmount = true;
      this.details.DUEBALANCE = oPaymentAmount;
    }
  }

  rowsGrouppedByPaycheck = [];
  rowsGrouppedPaycheckLoading = false;
  async showpaycheckDetails(invoiceId, orderId) {
    this.rowsGrouppedByPaycheck = [];
    if (!this.$allowedFunctions.includes(this.$userFunctions.Paychecks)) {
      this.grouppedByPaycheckDetails = false;
      return;
    }
    try {
      this.grouppedByPaycheckDetails = true;
      this.rowsGrouppedPaycheckLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "GetIndirectLinesByInvoiceNo",
        InvoiceID: invoiceId,
        purchaseId: orderId
      });

      if (response.data.STATUS == 1) {
        this.rowsGrouppedByPaycheck = response.data.PAIDLINES;
      }
    } catch (err) {
        // console.log(err.message);
    } finally {
      this.rowsGrouppedPaycheckLoading = false;
    }
  }

   async updateDropdownField(item) {
    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        customID: this.selectedCustomId,
        customFieldID: 25,
        customValue: this.selectedValue,
        Id: this.$route.params.id
      });
      if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
        if (typeof response.data.NEWCUSTOMID != "undefined") {
          this.selectedCustomId= response.data.NEWCUSTOMID;
        }
        this.customDataIndex = -1;
        item.CUSTOMVALUE = this.selectedValue;
        notifier.success(response.data.STATUSMESSAGE);
      } else if(response.data.STATUSMESSAGE) {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  // async updateContractNumber(item) {
  //   this.customDataLoading = true;
  //   try {
  //     const response = await axios.post(dataURL + "?ReturnType=JSON", {
  //       controller: "Helpers",
  //       FunctionName: "CustomDataUpdate",
  //       customID: this.selectedContractId,
  //       customFieldID: this.contractFieldId,
  //       customValue: this.selectedContractValue,
  //       Id: this.$route.params.id,
  //       isCustomDataField: true
  //     });
  //     if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
  //       if (typeof response.data.NEWCUSTOMID != "undefined") {
  //         this.selectedContractId= response.data.NEWCUSTOMID;
  //       }
  //       this.customDataIndex = -1;
  //       item.CUSTOMVALUE = this.selectedContractValue;
  //       this.bigDealId = response.data.CUSTOMDATACONTRACTNUMBER;
  //       notifier.success(response.data.STATUSMESSAGE);
  //     } else if(response.data.STATUSMESSAGE) {
  //       notifier.alert(response.data.STATUSMESSAGE);
  //     }
  //   } catch (err) {
  //     // console.log(err.message);
  //   } finally {
  //     this.customDataLoading = false;
  //   }
  // }

  async updatefundingSource(data) {
    this.customDataLoading = true;
    var $this = this;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        customID: data,
        action: 'saveFundingSource',
        Id: this.$route.params.id
      });
      if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
        notifier.success(response.data.STATUSMESSAGE);
        if(data != 0){
          const selectedFinObj: any = this.financialSource.find((element: any) => element.VARSOURCE_ID == data);
          this.selectedFinSourceValue = selectedFinObj.VARSOURCE_NAME;
          $.each(this.orderLedgerData, function(i, val) {
            if(val.PL_TYPE == 4 ) {
              val.FINSOURCEID = response.data.FINSOURCEID;
              val.D_FINANCIALSOURCE_NAME = selectedFinObj.VARSOURCE_NAME;
              $this.details.FUNDINGSOURCEID = response.data.FINSOURCEID;
            }
          });
        }
        else {
          this.selectedFinSourceValue = "";
        }
        this.fundingSourceField = -1;
      } else if(response.data.STATUSMESSAGE) {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  async updateLedgerSource(data, item, index) {
    this.editModeOn(index-1);
    item.FINSOURCEID = data.varsource.VARSOURCE_ID;
    item.D_FINANCIALSOURCE_NAME = data.varsource.VARSOURCE_NAME;    
    this.addrow(index);
  }

  async updateSource(item, index) {
    this.editModeOn(index-1);
    if(item.PL_TYPE == 1 || item.PL_TYPE == 3 || item.PL_TYPE == 5) {
      item.FINSOURCEID = 0;
      item.D_FINANCIALSOURCE_NAME = "";
    }
    this.addrow(index)
  }

  async saveLedger() {
    try {
      var result = await this.$validator.validateAll();
      if (!result || this.loading || this.orderLedgerData.length <= 1) {
        return;
      }
      let blankLedgerCount = 0;
      let orderLedgerEntries: any = [];
      for(const ledgerIndex in this.orderLedgerData) {
        if(this.orderLedgerData[ledgerIndex].PL_AMOUNT == 0 && this.orderLedgerData[ledgerIndex].PLID == 0) {
          blankLedgerCount++;
        } else {
          blankLedgerCount = 0;
        }
        if(blankLedgerCount < 1) {
          orderLedgerEntries.push(this.orderLedgerData[ledgerIndex]);
        }
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "orders",
        FunctionName: "orderLedgerEdit",
        orderLedger: orderLedgerEntries,
        action: 'save',
        purchaseID: this.$route.params.id
      });

      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
        this.getOrdersdetails();
      } 
      else {
        notifier.alert(response.data.STATUSMESSAGE);
      } 
    }
    catch (err) {
      // console.log(err.message);
    }
  }

  bkLedger() {
    this.orderLedgerDataBK = JSON.parse(JSON.stringify(this.orderLedgerData));
  }

  doConfirmLeave() {
    // special actions
    if(this.waitingGotoStatusModal) {
      this.confirmLeaveVisible = false;
      this.statusModalVisible = true;
      this.waitingGotoStatusModal = false;
    }else {
      const targetName = this.targetPage.name || "";
      if(targetName != "") {
        this.confirmLeaveVisible = false;
        this.confirmLeave = true;
        this.$router.push({
          name: this.targetPage.name,
          params: this.targetPage.params,
          query: this.targetPage.query
        });
      }
    }
  }

  changedLedgerData() {
    // check if change ledger
    let changed = false;
    if(this.orderLedgerData.length != this.orderLedgerDataBK.length) {
      changed = true;
    }else {
      this.orderLedgerData = this.orderLedgerData.map(item => ({
        ...item,
        D_FINANCIALSOURCE_NAME: item.D_FINANCIALSOURCE_NAME || ""
      }));
      for(const i in this.orderLedgerData) {
        if(JSON.stringify(this.orderLedgerData[i]) !== JSON.stringify(this.orderLedgerDataBK[i])) {
          changed = true;
          break;
        }
      }
    }
    return changed;
  }

  showStatusModal() {
    this.waitingGotoStatusModal = false;
    if(this.currentRole === 'Reseller') {
      if(this.changedLedgerData()) {
        this.confirmLeaveVisible = true;
        this.waitingGotoStatusModal = true;
      }else {
        this.statusModalVisible = true;
      }
    }else {
      this.statusModalVisible = true;
    }
  }

  stayOnPage() {
    this.confirmLeaveVisible = false;
    this.isActive = 'orderLedger';
    this.targetPage = {};
    this.confirmLeave = false;
    this.waitingGotoStatusModal = false;
  }

  async checkStatusList(status: number) {
      this.selectedID = [];
      this.selectedAll = false;
      this.excludedIDs = [];
      const i = this.lineItemFilters.statusID.findIndex((s) => s === status);
      if (i === -1) {
          this.lineItemFilters.statusID.push(status);
      } else {
          this.lineItemFilters.statusID.splice(i, 1);
      }
      this.getOrdersdetails();
  }

  async resetStatusList() {
    this.selectedID = [];
    this.lineItemFilters.statusID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.getOrdersdetails();
  }

  async checkAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.lineItemFilters.statusID = [
      productStatus.Cancelled,
      productStatus.Returned,
      productStatus.Shipped,
      productStatus.Delivered,
      productStatus.NoStatus
    ];
    this.getOrdersdetails();
  } 

  openLedgerInfo(ledgerPlId) {
    this.editStatusForLedgerID = ledgerPlId;
    this.editLedgerInfoVisible = true;
  }
  async ledgerUpdate(ledgerVals) {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "ledgerGenerate",
        pl_amount: ledgerVals.pl_amount,
        fType: ledgerVals.fType,
        fSource: ledgerVals.fSource,
        fStatus: ledgerVals.fStatus,
        purchaseID: ledgerVals.purchaseID,
        invoiceID: ledgerVals.invoiceID,
        orderInvoiceDate: ledgerVals.orderInvoiceDate,
        invoiceDueDate: ledgerVals.invoiceDueDate,
        ledgerPaidDate: ledgerVals.ledgerPaidDate,
        action : "ledgerEdit",
        ledgerID : this.editStatusForLedgerID
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
        await this.getOrdersdetails();
      }
    } catch (err) {
      //console.log(err.message);
    } finally {
      this.editLedgerInfoVisible = false;
    }
  }
  async ledgerSave() {
    $("input.error").removeClass("error");
    var valid = true;

    $(".list-item.editing input").each(function(i, obj) {
      let $this = $(obj);
      let $parent = $this.closest('.row');

      if ((!$.trim($this.val()).length && $parent.find('.addSource').val() != "4") || $this.val() == '0.00' || (!$this.hasClass('initial') && $parent.find('.source-select input').length && !$parent.find('.source-select input').val().length)) {
        valid = false;
        $this.addClass("error");
      }
    });

    if(valid) {
      let item = this.orderLedgerData[$(".list-item.editing").attr("data-id")];
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Finances",
          FunctionName: "ledgerGenerate",
          pl_amount: item.PL_AMOUNT,
          fType: item.PL_TYPE,
          fSource: item.FINSOURCEID,
          fStatus: item.PL_STATUS,
          purchaseID: this.$route.params.id,
          invoiceID: item.INVOICEID,
          orderInvoiceDate: item.ORDERINVOICEDATE,
          invoiceDueDate: item.DUEDATE,
          action : ""
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS == 1) {
          $(".list-item.editing").removeClass("editing");
          await this.getOrdersdetails();
        }
      } catch (err) {
        //console.log(err.message);
      }
    } else {
        // At least one input field is empty
        console.log("At least one field is empty");
    }
  }

  showProductCatTT(item, index, e) {
    if (this.productCatTTVisibleIndex === index) {
      return;
    }
    this.productCatTTVisibleIndex = index;
  }

  async updateItemCategory(info) {
    const mainItem = this.details.ORDERITEMS[info.index];
    let item = mainItem;
    const subIndex = info.subIndex || 0;
    if (subIndex) {
      item = item.subOrderItemsArray[subIndex - 1];
    }
    if (item) {
      const poLiId = item.POLIID || 0;
      const orderId = this.details.ORDERDETAILS.PURCHASEID || 0;
      if (poLiId && orderId) {
        item.POCATEGORY = info.ItemCategory;
        item.CATEGORYNAME = info.ItemCategoryName;
        try {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Orders",
            FunctionName: "UpdateOrderItemCategory",
            orderId,
            poLiId,
            catId: info.ItemCategory
          });
          if (response.data.STATUS == 1) {
            this.getOrdersdetails();
          }
        } catch (err) {
          console.log(err);
        } finally {
          //
        }
      }
    }
  }

  async newDeployment() {
    const params: any = {
      selectedIDs: this.details.ORDERDETAILS.PURCHASEID,
      account: {
        ANAME: this.details.ORDERDETAILS.AACCOUNTNAME,
        AID: this.details.ORDERDETAILS.AID	
      }
    };

    this.$router.push({
      name: 'CreateDeployment',
      params
    });
	}

  // async showIdentifiers(assetDetails) {
  //   this.assetId = assetDetails.PRODUCTID;
  //   this.assetSKU = assetDetails.SKU;
  //   this.assetName = assetDetails.PRODUCTNAME;
  // }

  isArray(item) {
    return Array.isArray(item) && item.length > 0 && typeof item[0] === 'object';
  }

  async showHardwareDetails(item) {
    this.modalHardwareDetails.isLoading = true;
    this.modalHardwareDetails.reset();
    const response = await ApiHelper.callApi("post", {
      controller: "Hardware",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: item.HARDWAREID
    });
    this.modalHardwareDetails.show = true;
    if (response.STATUS != 1) {
      notifier.alert(response.STATUSMESSAGE);
      this.modalHardwareDetails.show = false;
      return;
    }

    const hardwareData = response.hardware || {};
    const hardwareLogs: any = response.hardwareLogs || [];
    this.modalHardwareDetails.data = {
      ...this.modalHardwareDetails.data,
      id: hardwareData.HARDWAREID || 0,
      hardwareUUID: hardwareData.HARDWAREUUID || "",
      imageURL: hardwareData.IMAGEURL || "",
      name: hardwareData.HDESCRIPTION || "",
      sku: hardwareData.HARDWARESKU || "",
      statusName: hardwareData.HSTATUS || "",
      categoryId: hardwareData.CATEGORYID || 0,
      categoryName: hardwareData.CATEGORYNAME || "",
      order: {
        purchaseId: hardwareData.PURCHASEID || 0,
        poId: hardwareData.POID || "N/A"
      },
      customer: {
        aId: hardwareData.AID || 0,
        aName: hardwareData.ANAME || "N/A",
        accountId: hardwareData.ACCOUNTID || ""
      },
      deployment: {
        deploymentId: hardwareData.DEPLOYMENTID || 0,
        deploymentName: hardwareData.DEPLOYMENTNAME || "N/A"
      },
      endUser: {
        userId: hardwareData.USERID || 0,
        name: hardwareData.EUFULLNAME || "N/A",
        addr: hardwareData.uAddress || "",
        cityZip: this.getCityZip(hardwareData)
      },
      carrier: hardwareData.CARRIER || "",
      trackingNumber: hardwareData.TRACKINGNUMBER || "N/A"
    };
    // identifiers data
    for (const item of this.modalHardwareDetails.data.identifiers) {
      if (item.type == "hardware_id") {
        item.value = hardwareData.HARDWAREID || 0;
      } else if (item.type == "asset_number") {
        item.value = hardwareData.ASSETNUMBER || "";
      } else if (item.type == "asset_tag") {
        item.value = hardwareData.ASSETTAG || "";
      }
    }
    // logs/add desc
    for (const item of hardwareLogs) {
      if (item.LOGTYPE == 1) {
        item.desc = "Product was added";
      } else {
        item.desc = item.LOGDETAILS || "";
      }
    }
    this.modalHardwareDetails.data.logs = hardwareLogs;

    setTimeout(() => {
      this.modalHardwareDetails.isLoading = false;
    }, 500);
  }

  getCityZip(hardwareData) {
    let ret: string[] = [];
    if (hardwareData.UCITY) {
      ret.push(hardwareData.UCITY);
    }
    if (hardwareData.USTATE || hardwareData.UZIP) {
      ret.push(
        `${hardwareData.USTATE || ""} ${hardwareData.UZIP || ""}`.trim()
      );
    }

    return ret.join(", ");
  }

  updateList(item) {
    const inList: any = this.hardwareInfo.find(t => t.HARDWAREID == item.hardwareId);
    if (inList) {
      if (item.type == "asset_tag") {
        inList.ASSETTAG = item.value;
      } else if (item.type == "asset_number") {
        inList.ASSETNUMBER = item.value;
      }
    }
  }

  isTrackingItems(statusDetails) {
    if(this.isArray(statusDetails)) {
      const foundTrackingInfo = statusDetails.find(item => (item.carrier || "") != "" && (item.trackingNumber || "") != "");
      if(foundTrackingInfo) {
        return true;
      }
    }

    return false;
  }

  editOrderLineData = {
    data: {},
    show: false,
  };

  showEditOrderItem(item) {
    this.editOrderLineData.data = item;
    this.editOrderLineData.show = true;
  }

  async updateItemLineStatus(poLiId, newStatus) {
    // const findItem = this.items.find(item => item.POLIID == poLiId);
    // if (findItem) {
    //   console.log('findItem', findItem, poLiId, newStatus);
    //   switch (newStatus) {
    //     case 1:
    //       findItem.STATUSID = 1;
    //       findItem.POLINESTATUS = 'Cancelled';
    //       break;
    //     case 2:
    //       findItem.STATUSID = 2;
    //       findItem.POLINESTATUS = 'Returned';
    //       break;
    //     case 0:
    //       findItem.STATUSID = 0;
    //       findItem.POLINESTATUS = '';
    //       break;
    //     case -1:
    //       findItem.STATUSID = 0;
    //       findItem.POLINESTATUS = 'Delivered';
    //       break;
    //     case -2:
    //       findItem.STATUSID = 0;
    //       findItem.POLINESTATUS = 'Shipped';
    //       break;
    //   }
    // }
    this.loading = true;
    await this.getOrdersdetails();
    this.loading = false;
  }

  async updateLineItem(type,item) {
    /* console.log(this); */
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      session: sessionStorage.getItem("sessionID"),
      userRole: sessionStorage.getItem("userRole"),
      controller: "Orders",
      FunctionName: "orderLineItemEdit",
      type: type,
      updatePOliID: true,
      poID: this.details.ORDERDETAILS.POID,
      sku: item.MC_CDETAIL_PARTNUMBER,
      poProdDesc: item.MC_CDETAIL_PARTDESCRIPTION,
      poProdQuantity: item.MC_CDETAIL_QUANTITY,
      priceReg: item.MC_CDETAIL_UNITPRICE,
      total: item.MC_CDETAIL_QUANTITY * item.MC_CDETAIL_UNITPRICE,
      customerPrice: item.MC_CDETAIL_EXTENDEDPRICE,
      purchaseID: this.details.ORDERDETAIL.PURCHASEID
    });

    if(response.data.STATUS == 1) {
      this.getOrdersdetails();
    } else {
      notifier.alert(response.data.STATUSMESSAGE);
    }
  }

  async capturePayment(stripeToken = "", chargeByCustomerId = false) {
    // if(!stripeToken) return false;
    if(!this.hasInfoToCharge()) return;

    try {
      this.paying = true;
      let chargeTotal = this.uncapturedAmount || 0;
      if(chargeTotal == 0 && chargeByCustomerId) {
        // if charge by stripe customer/card, use a due balance of this order
        chargeTotal = this.details.DUEBALANCE;
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "updatePayment",
        token: stripeToken,
        // last4: this.stripeLast4,
        aID: this.details.ORDERDETAILS.AID,
        // cardName: this.cardName,
        purchaseID: this.$route.params.id,
        isUpdate: true,
        // isNew: this.cardupdate,
        orderPaymentTotal: chargeTotal,
        paymentType: "Stripe",
        capturePayment: true,
        stripeCustomerId: this.details.orderParams.stripeCustomerId || "",
        stripeCardId: this.details.orderParams.stripeCardId || "",
        chargeByCustomerId,
        uncapturedAmount: chargeTotal
      });
      if (response.data.STATUS == 1) {
        await this.getOrdersdetails();
        this.showcard = 1;
        this.resetstripeform();
        this.paying = false;
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        this.paying = "error";
        // the charge maybe is cancelled, notify to charge by customer info
        const errorCode = response.data.errorCode || "";
        if(errorCode != "charge_already_refunded") {
          notifier.alert(response.data.STATUSMESSAGE);
        }else if(this.details.orderParams.stripeCustomerId) {
          // if admin still want to charge by customer/card id
          this.confirmChargeByCustomerIdText = `<div>${response.data.STATUSMESSAGE}</div>
            Do you want to charge this order <strong>${this.uncapturedAmountFormatted}</strong> for customer <strong>${this.details.orderParams.stripeCustomerId}</strong>?
          `;
          this.confirmChargeByCustomerIdVisible = true;
        }

        return false;
      }
      this.confirmChargeVisible = false;
    } catch (err) {
      this.paying = "error";
      console.log(err);
    } finally {
      // this.paying = false;
      this.cardupdate = 0;
    }
    
    this.submitPaymentBtnEnabled = true;
    this.verifiedBtnEnabled = false;

    return true;
  }

  async capturePaymentByCustomer() {
    await this.capturePayment(this.details.orderParams.stripeToken || '', true);
    this.confirmChargeByCustomerIdVisible = false;
  }

  saveCardData() {
    if (
      this.cardName == "" ||
      this.cardNumber == "" ||
      this.expMoth == "" ||
      this.expyear == "" ||
      this.cvc == ""
    ) {
      return;
    }

    if (this.cvc.length < 3) {
      this.stripeError = 5;
    } else {
      this.stripeError = 0;
    }
    
    this.savingCard = true;
    const $this = this;
    Stripe.card.createToken($("#posttodetailpop"), async (status, response) => {
      // Stripe token failure...
      if (response.error) {
        var msg = "card name";
        if (response.error.param == "number") {
          $this.stripeError = 2;
          msg = "card number";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
          msg = "month";
        } else if (response.error.param == "exp_year") {
          $this.stripeError = 4;
          msg = "year";
        } else if (response.error.param == "cvc") {
          $this.stripeError = 5;
          msg = "cvc";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
        } else {
          $this.stripeError = 1;
        }
        notifier.alert("Verification failed due to invalid " + msg);
      } else {
        // $this.stripeToken = response.id;
        // $this.stripeLast4 = response.card.last4;
        $this.stripeError = 0;
      }

      if($this.stripeError == 0) {
        const stripeToken = response.id;
        const stripeLast4 = response.card.last4;
        try {
          // save card data
          const result = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Orders",
            FunctionName: "SaveCardData",
            purchaseID: this.$route.params.id,
            cardName: this.cardName,
            cardNumber: this.cardNumber,
            expMoth: this.expMoth,
            expYear: this.expyear,
            cvc: this.cvc,
            stripeToken,
            stripeLast4
          });
          if (result.data.STATUS == 1) {
            this.savingCard = false;
            this.details.orderParams.stripeCustomerId = result.data.stripeCustomerId || "";
            this.details.orderParams.stripeCardId = result.data.stripeCardId || "";
            notifier.success("Saved successfully!");
          } else {
            this.savingCard = "error";  
            const message = result.data.MESSAGE || "";
            if(message) {
              notifier.alert(message);
            }
          }
        } catch (error) {
          console.log(error);
          this.savingCard = "error";
        }
      } else {
        this.savingCard = "error";
      }
    });
  }

  chargePayment() {
    if (
      this.cardName == "" ||
      this.cardNumber == "" ||
      this.expMoth == "" ||
      this.expyear == "" ||
      this.cvc == ""
    ) {
      return;
    }

    if (this.cvc.length < 3) {
      this.stripeError = 5;
    } else {
      this.stripeError = 0;
    }
    if (this.paymentTotalAmount == false) {
      return;
    }
    this.stripeToken = "";
    this.paying = true;
    const $this = this;
    Stripe.card.createToken($("#posttodetailpop"), async (status, response) => {
      // Stripe token failure...
      if (response.error) {
        var msg = "card name";
        if (response.error.param == "number") {
          $this.stripeError = 2;
          msg = "card number";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
          msg = "month";
        } else if (response.error.param == "exp_year") {
          $this.stripeError = 4;
          msg = "year";
        } else if (response.error.param == "cvc") {
          $this.stripeError = 5;
          msg = "cvc";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
        } else {
          $this.stripeError = 1;
        }
        notifier.alert("Verification failed due to invalid " + msg);
      } else {
        $this.stripeToken = response.id;
        $this.stripeLast4 = response.card.last4;
        $this.stripeError = 0;
      }

      if($this.stripeToken != "" && $this.stripeError == 0) {
        try {
          await this.updatePayment();
          this.paying = false;
        } catch (error) {
          console.log(error);
          this.paying = "error";
        }
      } else {
        this.paying = "error";
      }
    });
  }

  get disbleSubmitCard() {
    if(this.cardName == "" || this.cardNumber == "" || this.expMoth == "" ||  this.expyear == "" || this.cvc == "") {
      return true;
    }

    return false;
  }

  async updatePayment() {
    if(this.stripeError != 0) return;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Orders",
      FunctionName: "updatePayment",
      token: this.stripeToken,
      last4: this.stripeLast4,
      aID: this.details.ORDERDETAILS.AID,
      cardName: this.cardName,
      purchaseID: this.$route.params.id,
      isUpdate: true,
      isNew: this.cardupdate,
      orderPaymentTotal: this.details.DUEBALANCE,
      paymentType : "Stripe"
    });
    if (response.data.STATUS == 1) {
      await this.getOrdersdetails();
      this.showcard = 1;
      this.resetstripeform();
      notifier.success(response.data.STATUSMESSAGE);
    } else {
      notifier.alert(response.data.STATUSMESSAGE);
    }
  }

  showStripeForm() {
    this.hideCreditCard = true;
    this.hideCreditCardInfo = false;

    // show card data if saved before
    const savedCardData = this.details.orderParams.savedCardData || {};
    if((savedCardData.cardNumber || "") != "") {
      this.cardName = savedCardData.cardName || "";
      this.cardNumber = savedCardData.cardNumber || "";
      this.expMoth = savedCardData.expMoth || "";
      this.expyear = savedCardData.expYear || "";
      this.cvc = savedCardData.cvc || "";
    }
  }

  validateBeforeCapture() {
    // const stripeToken = this.details.orderParams.stripeToken || "";
    // if(!stripeToken) return;
    if(!this.hasInfoToCharge()) return;

    const stripeToken = this.details.orderParams.stripeToken || "";
    const stripeCardId = this.details.orderParams.stripeCardId || '';
    const stripeCustomerId = this.details.orderParams.stripeCustomerId || '';

    const orderGrandTotal = this.details.GRANDTOTAL || 0;
    if(this.uncapturedAmount != 0 && orderGrandTotal != 0 && this.uncapturedAmount != orderGrandTotal) {
      // show warning if charge amount is different with order grand total
      this.confirmChargeVisible = true;
      return;
    }

    // continue to capture
    const chargeByCustomerId = !stripeToken && (stripeCustomerId && stripeCardId) ? true : false;
    this.capturePayment(stripeToken, chargeByCustomerId);
  }

  changeCard() {
    this.showcard = 3;
    this.cardupdate = 1;
    this.hideCreditCard = true;
    this.hideCreditCardInfo = false;
  }

  hasInfoToCharge() {
    const stripeToken = this.details.orderParams.stripeToken || '';
    const stripeCardId = this.details.orderParams.stripeCardId || '';
    const stripeCustomerId = this.details.orderParams.stripeCustomerId || '';
    if(stripeToken != "" || (stripeCardId != "" && stripeCustomerId != "")) {
      return true;
    }

    return false;
  }

  generateOrder() {
    //if selected multi sources orders
    var multiSources = this.details.ORDERDETAILS["SOURCEIDS"].split(",");
    if (multiSources.length > 1) {
      //show modal to select a source
      this.selectSourceModalVisible = true;
    } else {
      this.goToCreateOrderPage();
    }
  }

  goToCreateOrderPage(selectedSource = 0) {
    const id = this.$route.params.id;
    var tmpQuery = { orderType: "unverify" };
    if (selectedSource) {
      tmpQuery["importSource"] = selectedSource;
    }
    let routeData = this.$router.resolve({
      path: "/generateOrder/" + id,
      query: tmpQuery
    });
    window.open(routeData.href, "_blank");
  }

  isHPDirectOSSAuto(sourceId) {
    return sourceId == VarSource.HPDirectOSSAuto;
  }

  editModeOn(index) {
    $(".list-item.row[data-id='"+index+"']").addClass("editing");
  }
}
