






































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import PageTitle from "../components/pageTitle.vue";
import LaddaButton from "../components/LaddaButton.vue";
import ContractListAddModal from "@/components/ContractListAddModal.vue";
import AttachPriceListModal from "@/components/AttachPriceListModal.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

import {colsResizeable, getDateRangeFilter, getRangeFilter} from "@/helpers/ApiHelper";
import ListHeaderActions from "@/components/ListHeaderActions.vue";


declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    ListHeaderActions,
    Pagination,
    PageTitle,
    LaddaButton,
    ContractListAddModal,
    AttachPriceListModal,
    ConfirmRemoveItemModal
  }
})

export default class Contracts extends TSXComponent<void> {
  $root: any;
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  message = "";
  uploading: any = false;
  pageNumber = 1;
  details: any = {};
  contracts: any = [];
  currentRole = sessionStorage.getItem("userRole");

  addContractListModalFlg = false;
  attachPriceListModalFlg = false;
  targetContract: any = null;
  targetPriceListId = '';
  targetPriceList: any = {};
  priceListOptions: any = [];
  confirmRemoveModalVisible = false;

  async created() {
    this.getPricingList();
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Quotes",
        FunctionName: "ContractList",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        number: this.filters.number,
        type: this.filters.type,
        lastUpload_sDate: this.filters.lastUpload_sDate,
        lastUpload_eDate: this.filters.lastUpload_eDate,
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS != 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.contracts = this.details.CONTRACTNUMBER;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }

  async getPricingList() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Quotes",
      FunctionName: "PriceList",
      viewType: 'options'
    });

    this.priceListOptions = response.data.PRICINGLIST.map((pl) => ({
      ID: pl.PRICELISTID,
      TEXT: pl.NAME
    }));
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  listPageRedirection() {
    this.$router.go(-1);
  }

  async addNewContractList() {
    this.addContractListModalFlg = false;
    await this.fetchData();
  }

  async showAttachPriceListModal(contract, currentPriceListId = '') {
    this.targetContract = contract;
    this.targetPriceListId = currentPriceListId;
    this.attachPriceListModalFlg = true;
  }

  async attachPriceListToContract(result) {
    this.attachPriceListModalFlg = false;
    if (result.reload) {
      await this.fetchData();
    }
  }

  async detachPriceListFromContract(selectedContract, selectedPriceList) {
    this.confirmRemoveModalVisible = true;
    this.targetContract = selectedContract;
    this.targetPriceList = selectedPriceList;
  }

  async confirmDetachPriceListFromContract() {
    this.confirmRemoveModalVisible = false;

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: 'Quotes',
        FunctionName: 'ContractDetachPriceList',
        userID: sessionStorage.getItem("userId"),
        customFieldId: this.targetContract.CUSTOMFIELDID,
        customFieldOptionId: this.targetContract.CUSTOMFIELDOPTIONID,
        priceListID: this.targetPriceList.PRICELISTID
      });

      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1) {
        notifier.success(message);
        await this.fetchData();
      } else {
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      this.loading = false;
    }
  }


  filters: {
    number: string;
    type: string;
    lastUpload_sDate: string;
    lastUpload_eDate: string
  } = {
    number: "",
    type: "",
    lastUpload_sDate: "",
    lastUpload_eDate: "",
  };
  searchFilters: {
    number: string;
    type: string;
    lastUpload_sDate: string;
    lastUpload_eDate: string
  } = {
    number: "",
    type: "",
    lastUpload_sDate: "",
    lastUpload_eDate: "",
  };
  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'NUMBER':
        searchKey = this.filters.number;
        break;
      case 'TYPE':
        searchKey = this.filters.type;
        break;
      case 'LAST_UPLOAD_DATE':
        searchKey = getDateRangeFilter(this.filters.lastUpload_sDate, this.filters.lastUpload_eDate);
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }
  async resetNumberColumn() {
    this.pageNumber = 1;
    this.filters.number = this.searchFilters.number = "";
    await this.fetchData();
  }
  async resetTypeColumn() {
    this.pageNumber = 1;
    this.filters.type = this.searchFilters.type = "";
    await this.fetchData();
  }
  async resetColumn(header) {
    switch (header) {
      case 'NUMBER':
        this.resetNumberColumn();
        break;
      case 'TYPE':
        this.resetTypeColumn();
        break;

      case 'LAST_UPLOAD_DATE':
        this.resetSearchByLastUploadDate();
        break;
      default:
        break;
    }
  }
  // last_upload_date filter
  async searchByLastUploadDate() {
    this.pageNumber = 1;
    if (
        (this.searchFilters.lastUpload_sDate == undefined ||
            this.searchFilters.lastUpload_sDate == "") &&
        (this.searchFilters.lastUpload_eDate== "" ||
            this.searchFilters.lastUpload_eDate== undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.filters.lastUpload_sDate = this.searchFilters.lastUpload_sDate;
      this.filters.lastUpload_eDate = this.searchFilters.lastUpload_eDate;
      await this.fetchData();
    }
  }
  async resetSearchByLastUploadDate() {
    this.pageNumber = 1;
    if (
        (this.searchFilters.lastUpload_sDate == undefined ||
            this.searchFilters.lastUpload_eDate == "") &&
        (this.searchFilters.lastUpload_sDate== "" ||
            this.searchFilters.lastUpload_eDate== undefined)
    ) {
      return;
    } else {
      this.filters.lastUpload_sDate = this.searchFilters.lastUpload_sDate = "";
      this.filters.lastUpload_eDate = this.searchFilters.lastUpload_eDate = "";
      await this.fetchData();
    }
  }
  // number filter
  async searchByNumber() {
    this.pageNumber = 1;
    if (this.searchFilters.number == "") {
      return;
    } else {
      this.filters.number = this.searchFilters.number;
      await this.fetchData();
    }
  }
  async resetSearchByNumber() {
    this.pageNumber = 1;
    this.filters.number = this.searchFilters.number = "";
    await this.fetchData();
  }
  // type filter
  async searchByType() {
    this.pageNumber = 1;
    if (this.searchFilters.type == "") {
      return;
    } else {
      this.filters.type = this.searchFilters.type;
      await this.fetchData();
    }
  }
  async resetSearchByType() {
    this.pageNumber = 1;
    this.filters.type = this.searchFilters.type = "";
    await this.fetchData();
  }

}
