import axios from "axios";

import {QuoteLineItemList} from '../models/QuoteLineItemList';

declare const dataURL: string;

export class QuoteLineItemAPIRepo {
  async find(search: string): Promise<QuoteLineItemList.Lineitems[]> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      FunctionName: "quoteLineItemList",
      controller: "Quotes",
      search,
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      if (response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      return []
    }

    const data: QuoteLineItemList.Root = response.data

    return data.LINEITEMS || []
  }
}
