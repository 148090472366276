



































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import LaddaButton from "@/components/LaddaButton.vue";
import EmployeePlanSelect from "@/components/EmployeePlan/EmployeePlanSelect.vue";
import { notifier } from "../models/common";

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    EmployeePlanSelect
  }
})

export default class PlanGroupEdit extends TSXComponent<void> {
  $route: any;
  $router: any;
  $validator: any;
  pageTitle: string = "";
  planGroupId: number = 0;
  saving: boolean = false;
  planGroupName: string = "";
  selectedPlanID: any = [];
  selectedPlans: object[] = [];
  employeePlansData: any = [];

  async created() {
    this.planGroupId = this.$route.params.id ? parseInt(this.$route.params.id) : 0;
    if (this.planGroupId) {
      this.pageTitle =
        "Edit Plan Group #<span id='planGroupID'>" + this.planGroupId + "</span>";
    } else {
      this.pageTitle = 'Create Plan Group';
    }
    await this.fetchData();
  }

  async fetchData() {
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "PlanGroupView",
      action: "Detailed",
      planGroupId: this.planGroupId
    });

    if (response.STATUS === 1) {
      this.employeePlansData = response.EMPLOYEEPLANSDETAIL || [];
    }
  }

  validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$validator.validateAll().then(result => {
      if (result) {
        return this.submit();
      }
    });
  }

  async submit() {
    if (!this.selectedPlanID.length) {
      notifier.alert("Add at least one Plan");
      return;
    }
    this.saving = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "PlanGroupAdd",
      action: !this.planGroupId ? "insert" : "update",
      selectedPlanID: this.selectedPlanID,
      planGroupName: this.planGroupName,
      planGroupId: this.planGroupId
    });
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.backToDetails();
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.saving = false;
  }

  listPageRedirection() {
    this.backToDetails();
  }

  backToDetails() {
    if (this.planGroupId) {
      this.$router.push({ name: "PlanGroupDetails", params: { id: `${this.planGroupId}` } });
    } else {
      this.$router.push({ name: "PlanGroups" });
    }
  }

  toggleSelectedPlan(selectedID: number, planName) {
    const index = this.selectedPlanID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedPlanID.push(selectedID);
      this.selectedPlans.push({
        COMPID: selectedID,
        EMPLOYEENAME: planName
      });
      return;
    }

    this.selectedPlanID.splice(index, 1);
    this.$delete(this.selectedPlans, index);
  }

  selectAllPlans() {
    this.resetPlans();
    this.selectedPlanID = this.employeePlansData.map(
      (val: any) => val.COMPID
    );
    this.selectedPlans = this.employeePlansData.map((val: any) => ({
      COMPID: val.COMPID,
      EMPLOYEENAME: val.EMPLOYEENAME
    }));
  }

  resetPlans() {
    this.selectedPlans = [];
    this.selectedPlanID = [];
  }

  updatePlanList(index) {
    if (this.selectedPlans) {
      this.$delete(this.selectedPlans, index);
      this.$delete(this.selectedPlanID, index);
    }
  }
}
