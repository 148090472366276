var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"popover-box popover-box-tooltip add-to-schedule-group-tooltip"},[_c('div',{staticClass:"popover"},[_c('div',{staticClass:"arrow"}),_c('div',{staticClass:"popover-content"},[_c('div',{staticClass:"tooltip-header"},[_c('h5',{staticClass:"tooltip-title"},[_vm._v("Attach to schedule group")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close","data-dismiss":"modal"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('close')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"popover-content-inner"},[_c('div',{staticClass:"tooltip-container up"},[_c('div',{staticClass:"sub-label mt-0"},[_vm._v("Choose Group")]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedGroupId),expression:"selectedGroupId"}],staticClass:"mt-0",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedGroupId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"0","disabled":""}},[_vm._v("Select a Group")]),_vm._l((_vm.scheduleGroups),function(item,index){return _c('option',{key:index,attrs:{"disabled":item.disabled || false},domProps:{"value":item.GROUPID}},[_vm._v(" "+_vm._s(item.GROUPNAME)+" ")])}),(
                  _vm.currentRole === 'Reseller' && _vm.currentItem.SCHEDULEGROUPID
                )?_c('option',{attrs:{"value":"-1"}},[_vm._v(" Setup New ")]):_vm._e()],2)]),(!_vm.loading)?[(_vm.$parent.isLockedSchedule(_vm.currentItem.AVAILABILITYID || 0))?_c('div',{staticClass:"noAvailability position-absolute d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"noAvailabilityContent"},[_vm._v(" This schedule has been locked. Please contact your administrator if you need to make any changes. ")])]):(!_vm.scheduleGroups.find(function (item) { return !item.disabled; }))?_c('div',{staticClass:"noAvailability position-absolute d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"noAvailabilityContent"},[_vm._v(" No availability for Current End User Filters ")])]):_vm._e()]:_vm._e(),_c('div',{staticClass:"tooltip-footer"},[_c('LaddaButton',{staticClass:"teal attach-btn",attrs:{"type":"button","label":"Attach","loading":_vm.saving,"disabled":_vm.saving ||
                  _vm.selectedGroupId == (_vm.currentItem.SCHEDULEGROUPID || 0),"fixedWidth":true},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addToGroup($event)}}}),(_vm.currentRole === 'Reseller')?_c('LaddaButton',{staticClass:"teal setup-new-btn",class:{
                isLocked: _vm.$parent.isLockedSchedule(
                  _vm.currentItem.AVAILABILITYID || 0
                )
              },attrs:{"type":"button","label":_vm.currentItem.SCHEDULEGROUPID ? 'Edit Group' : 'Setup New',"fixedWidth":true},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.currentItem.SCHEDULEGROUPID
                  ? _vm.$emit('editGroup')
                  : _vm.$emit('setupNew')}}}):_vm._e()],1)],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }