

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import Vue from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import OrderFormLineItemList from "@/components/OrderFormLineItemList.vue";
import OrderFormSummary from "@/components/OrderFormSummary.vue";
import CustomerAutoSuggestInput from "@/components/CustomerAutoSuggestInput.vue";
import VarsourceAutoSuggestInput from "@/components/VarsourceAutoSuggestInput.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import { notifier, wait } from "@/models/common";
import directives from "@/helpers//directives";
import GlobalTagSelect from "@/components/GlobalTagSelect.vue";
import ConfirmSwitchCustomer from "@/components/ConfirmRemoveItemModal.vue";
import ConfirmSwitchTaxRate from "@/components/ConfirmRemoveItemModal.vue";
import ProcessOrderModal from "@/components/ProcessOrderModal.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import ConfirmModalBox from "@/components/ConfirmRemoveItemModal.vue";
import ConfirmCustomTax from "@/components/ConfirmRemoveItemModal.vue";
import CustomerAddressTooltip from "@/components/CustomerAddressTooltip.vue";
import LedgerssourceAutoSuggestInput from "@/components/LedgerssourceAutoSuggestInput.vue";
import UpdateNotes from "@/components/UpdateNotesModal.vue";
import EndUserAutoSuggestInput from "@/components/EndUserAutoSuggestInput.vue";
import PhoneInput from "@/components/PhoneInput.vue";
import Datepicker from "@/components/Datepicker.vue";
import CustomerDepartmentTooltip from "@/components/CustomerDepartmentTooltip.vue";
import CategorySKUModal from "@/components/CategorySKUModal.vue";
import PageTitle from "@/components/pageTitle.vue";
import LinkUnverifyOderModal from "@/components/LinkUnverifyOderModal.vue";
import { dollarFormat } from "@/helpers/ApiHelper";
import ContactTooltip from "@/components/ContactTooltip.vue";
import { ApiHelper } from "@/helpers/all";
import SubOrderEndUserCustomData from "@/components/Order/SubOrderEndUserCustomData.vue";
import opportunityAutoSuggestInput from "@/components/opportunityAutoSuggestInput.vue";

declare const dataURL: string;
declare const $: any;
declare const htmlParse: Function;
declare const htmlCheck: Function;
declare const getRouteData: Function;
declare const getImageRouteData: any;

@Component({
  inheritAttrs: false,
  directives,
  components: {
    OrderFormLineItemList,
    OrderFormSummary,
    CustomerAutoSuggestInput,
    VarsourceAutoSuggestInput,
    LaddaButton,
    GlobalTagSelect,
    ConfirmSwitchCustomer,
    ConfirmSwitchTaxRate,
    ProcessOrderModal,
    DropdownControl,
    ConfirmModalBox,
    CustomerAddressTooltip,
    ConfirmCustomTax,
    LedgerssourceAutoSuggestInput,
    UpdateNotes,
    EndUserAutoSuggestInput,
    PhoneInput,
    Datepicker,
    CustomerDepartmentTooltip,
    CategorySKUModal,
    PageTitle,
    LinkUnverifyOderModal,
    ContactTooltip,
    SubOrderEndUserCustomData,
    opportunityAutoSuggestInput
  }
})
export default class OrderCreate extends TSXComponent<void> {
  $refs!: {
    orderFormLineItemList: OrderFormLineItemList;
    OrderFormSummary: OrderFormSummary;
  };
  $router: any;
  $route: any;
  currentRole = sessionStorage.getItem("userRole");
  length = 0;
  itemName = {};

  title = "New Order";
  itemNames = this.itemName;
  customer = "";
  reseller = "";
  aID = 0;
  currentEndUser = 0;
  source = "";
  // cusTaxApplied = false;
  // currCustTax = 0;
  // prevCustTax = 0;
  sourceID = 0;
  errorItems = 0;
  arrowCounter = 0;
  profit: number | string = 0.0;
  totalShipping: number | string = 0.0;
  totalTax: number | string = 0.0;
  taxVal = 0;
  grandTotal: number | string = 0.0;
  itemTotal: number | string = 0.0;
  quoteDate = "";
  orderName = "";
  contactEmail: number[] = [];
  objDate = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "America/Denver",
      hour12: false
    })
  );
  orderDate = [
    (this.objDate.getMonth() + 1 < 10 ? "0" : "") +
      (this.objDate.getMonth() + 1),
    (this.objDate.getDate() < 10 ? "0" : "") + this.objDate.getDate(),
    this.objDate.getFullYear()
  ].join("/");
  orderID = "";
  errorMsg = "";
  suggestedQuotes = [];
  prevsource: any = {
    VARSOURCE_ID: 0,
    VARSOURCE_NAME: ""
  };
  quoteID = "";
  quoteIds: any = [];
  quoteName = "";
  summary: any = {
    itemTotal: 0,
    totalTax: 0,
    qTax: 0,
    totalShipping: 0,
    includeVarCost: true,
    totalCost: 0,
    qShip: 0,
    profit: 0,
    estPercent: 0,
    grandTotal: 0
  };
  USECUSTOMTAX = 0;
  accountID = "";
  estPercent = 0;
  loading: any = false;
  saving: any = false;
  taxLoading: any = false;
  inEditPage = false;
  duplicateOrder = false;
  purchaseID = 0;
  items = [];
  details = {};
  generateForUnverify = false;
  // globalTag = "";
  // customID = 0;
  globalTags: any = {
    CUSTOMFIELDOPTIONNAME: ""
  };
  globalMargin = 0;
  globalMarginBK = 0;
  confirmedGlobalMargin = false;
  fullCustomData: any = [];
  customDataVisible = false;
  confirmSwitchCustomerModalVisible = false;
  confirmedSwitchCustomer = false;
  confirmSwitchTaxRateVisible = false;
  ConfirmCustomTax = false;
  hpDirectTaxRate = 0;
  hpDirectTaxRateTmp = 0;
  indirectTaxRate = 0;
  indirectTaxRateTmp = 0;
  switchCustomerMsg1 = "";
  switchCustomerMsg2 = "";
  customer_bk: any = {
    customer: "",
    aID: 0,
    accountID: ""
  };
  newTaxRate = 0;
  currentTaxRate = 0;
  $decimals: any;
  processWithDisty = false;
  processOrderModalVisible = false;
  checkSKUDetails: any = {};
  processOrderType = "checkPA";
  forceProcess = false;
  validSynnexCredential = false;
  validTechdataCredential = false;
  defaultESTPercent = 0;
  HPEdefaultESTPercent = 0;
  distyMarginRate = [];
  sourceCredentials: any = [];
  euID = 0;
  euName = "";
  euPhone = "";
  euPhoneText = "";
  euEmail = "";
  customerAddresses: any = [];
  customerAddressesBilling: any = [];
  selectedAddress: number[] = [];
  selectedBillingAddress: number[] = [];
  confirmedSourceRateVisible = false;
  sourceRateMessage = "";
  // permanent or one time using
  addNewType = "";
  customerAddressVisible = false;
  billingAddressVisible = false;
  onetimeAddress: any = {};
  onetimeAddressBilling: any = {};
  orderParams: any = {};
  DistyTotal: any = {};
  // default techdata disty when using dynamic source
  defaultDisty = 2;
  distyIDs = [4];
  mailRequired = false;
  uploading: any = false;
  $decimalsView: any;
  qShip: any = 0.0;
  qTax: any = 0.0;
  customDropdown: object[] = [];
  duplicateOrderCreate = false;
  orderContractNumber: object[] = [];
  selectedContracts: any = [];
  sourceName = "";
  fundingSourceName = "";
  fundingSourceId = 0;
  fundingSourceTerms = "";
  financialSource: any = [];
  OrderUpdateNotes = false;
  updateNotes = "";
  orderContractsID: number[] = [];
  selectedOrderContracts = "";
  expandDecimal = false;
  expandDecimalPrecision = 2;
  customerDepartment: any = [];
  selectedDepartment: number[] = [];
  departmentVisible = false;
  departmentItems = [
    {
      NAME: "",
      ID: 0
    }
  ];
  duplicateDeptName = false;
  selectedDepartmentName = "";
  onetimeDepartment: any = {};
  includedItem = false;
  includedPrice = true;
  varGenerateHardware = "no";
  accountGenerateHardware = 0;
  categorySKUVisible = false;
  businessLineOptions: any = [];
  selectedBusinessLine: number[] = [];
  linkUnverifyOrderModalVisible = false;
  inputPoIdTimer: any = null;
  unverifyOrder: any = {};
  confirmedUnverifyOrder = false;
  invoiceMail: any = [];
  configCategory: any = {};
  quoteParams: any = {};
  endUsers: any = [];
  selectedEndUsers: number[] = [];
  endUserVisible = false;
  invMailVisible = false;
  highlightedAddress = 0;
  highlightedBillingAddress = 0;
  defaultEndUser = 0;
  defaultInvoiceMail = 0;
  cfIds: any = {};
  stripeToken = "";
  confirmedPayment = false;
  stripeCustomerId = "";
  stripeCardId = "";
  paymentCaptured = 0;
  quoteEndUsers: any = [];
  dataFields: any = [];
  generalDataFields: any = [];
  splitBySubOrderId: any = [
    {
      subId: 0
    }
  ];
  selectedSubOrder: any = {};
  euCustomDataVisible = false;
  splitByQuoteId: any = [];
  uploadedFileName = "";
  hideCustomDataApiCall = false;
  sourceQuoteIds = "";
  ssInfo = {
    SSId: "",
    SSUrl: "",
    SSName: ""
  };
  completePaymentEnable = false;
  confirmedDuplicateContactVisible = false;
  duplicateContactItems: any = [];
  checkingAutoTax = false;
  autoTaxSetting = {
    shippingAutoTax: 1,
    billingAutoTax: 0
  };
  addrLoading = false;
  opportunityName: string = "";
  opportunityID: number = 0;
  opportunityPrevID: number = 0;
  confirmTermsVisible = false;
  confirmedTerms = "";

  @Watch("customer")
  async customerChanged(val) {
    if (val == null) {
      this.customer = "";
      this.aID = 0;
      this.accountID = "";
      this.customer_bk = {
        customer: this.customer,
        aID: this.aID,
        accountID: this.accountID
      };
      this.customerDepartment = [];
      this.selectedDepartment = [];
      this.opportunityName = "";
      this.opportunityID = 0;
    }
  }

  @Watch("opportunityName")
  async opportunityChanged(val) {
    if (val == null) {
      this.opportunityName = "";
      this.opportunityID = 0;
    }
  }

  get isDirectOrder() {
    const sourceType = (this.source || "").toLowerCase();

    if (sourceType.endsWith("direct")) {
      return true;
    } else if (sourceType === "HPE".toLowerCase()) {
      return true;
    }

    return false;
  }

  async created() {
    if (typeof this.$route.query.aName === "string") {
      this.customer = this.$route.query.aName.length
        ? this.$route.query.aName
        : "";
    }
    if (
      typeof this.$route.query.aId !== "undefined" &&
      typeof this.$route.query.aId !== "number"
    ) {
      this.aID = this.$route.query.aId.length ? this.$route.query.aId : 0;
    }
    await this.init();
  }

  async init() {
    // reset data
    this.resetCustomerAddress();

    // convert to order params
    const quoteIds =
      (this.$route.query.quoteIds || "") != ""
        ? this.$route.query.quoteIds.split(",")
        : [];
    const fromQuote = this.$route.params.fromQuote || "";
    if (fromQuote != "" && fromQuote != "0") {
      this.quoteID = fromQuote;
      this.title = "Create Order #" + fromQuote;
    }

    // init for DistyTotal
    for (const i in this.distyIDs) {
      const distyID = this.distyIDs[i];
      this.DistyTotal[`${distyID}`] = {
        TotalPrice: 0,
        StockQty: 0,
        TotalQty: 0
      };
    }

    if (
      typeof this.$route.params.id != "undefined" &&
      parseInt(this.$route.params.id) != 0
    ) {
      //case edit order
      this.inEditPage = true;
      this.purchaseID = parseInt(this.$route.params.id);
      if (this.$route.name == "DuplicateOrder") {
        this.duplicateOrder = true;
      }
      this.editOrder(this.$route.params.id, this.duplicateOrder);
    }

    //Duplicate Order
    if (this.$route.name == "DuplicateOrder") {
      this.duplicateOrderCreate = true;
      this.inEditPage = false;
      this.title = "Duplicate Order #" + this.$route.params.id;
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    this.defaultESTPercent = response.data.ESTPercent || 0;
    this.HPEdefaultESTPercent = response.data.HPEESTPercent || 0;
    this.distyMarginRate = response.data.distributor || [];
    this.varGenerateHardware = response.data.orderGenerateHardware || "no";
    this.businessLineOptions = (response.data.businessLineOptions || []).map(
      item => ({
        ID: item.ID,
        TEXT: item.BUSINESSLINENAME,
        data: item
      })
    );
    this.configCategory = response.data.configCategory || {};
    this.cfIds = response.data.cfIds || {};

    // get global tags, custom data
    try {
      const ignoreFieldIds = [26];
      if ((this.cfIds.HPQuoteNoCFieldId || 0) > 0) {
        ignoreFieldIds.push(this.cfIds.HPQuoteNoCFieldId);
      }
      if ((this.cfIds.BigDealNoCFieldId || 0) > 0) {
        ignoreFieldIds.push(this.cfIds.BigDealNoCFieldId);
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomData",
        DataType: "1",
        Id:
          typeof this.$route.params.id != "undefined" &&
          parseInt(this.$route.params.id) != 0
            ? this.$route.params.id
            : 0,
        DropdownField: 25,
        contractDropdown: 26,
        orderCustomData: true,
        ignoreFieldIds: ignoreFieldIds.join(",")
      });

      if (response.data.STATUS == 1) {
        this.fullCustomData = [];
        for (var val of response.data.CUSTOMDEFINITION) {
          let tmpValue: any = [];
          if (typeof response.data.CUSTOMDATA != "undefined") {
            tmpValue = response.data.CUSTOMDATA.filter(
              tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID
            );
          }
          this.fullCustomData.push({
            AID: val.AID,
            CUSTOMFIELDID: val.CUSTOMFIELDID,
            CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
            CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
            CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
            CUSTOMDTYPE: val.CUSTOMDTYPE,
            OPTIONS: val.OPTIONS || [],
            CFNAME: val.CFNAME || ""
          });
        }

        this.financialSource = response.data.financialSource;
        this.globalTags = response.data.globalTags.filter(
          item => item.FORORDERS
        );
        this.customDropdown = response.data.customnDropdownField;
        this.orderContractNumber = response.data.customContractNumber;
        this.selectedContracts = response.data.selectedContractData || [];
        if (this.selectedContracts.length) {
          this.selectedOrderContracts = this.selectedContracts[0].CUSTOMFIELDOPTIONNAME;
          this.orderContractsID = this.selectedContracts.map(
            (val: any) => val.CUSTOMFIELDOPTIONID
          );
        }
        if (
          typeof this.$route.params.id != "undefined" ||
          typeof this.$route.params.fromQuote != "undefined"
        ) {
          this.selectedGlobalTags = (response.data.selectedGlobalTags || [])
            .map((val: any) => ({
              CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
              CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME,
              FORORDERS: val.FORORDERS
            }))
            .filter(item => item.FORORDERS);
          this.selectedGlobalTagsID = this.selectedGlobalTags.map(
            (val: any) => val.CUSTOMFIELDOPTIONID
          );
        }
      }
    } catch (err) {
      //handle error
      console.log(err.message);
    }

    // convert to order
    if (parseInt(this.$route.params.fromQuote || 0) != 0 || quoteIds.length) {
      if (quoteIds.length) {
        // convert from multi quotes to order
        await this.convertMultiQuotesToOrder(quoteIds);
      } else {
        // normal case
        await this.convertToOrder(this.$route.params.fromQuote, self);
      }
      // make sure source quote Id is transfered from quote to order (related field in order is "Indirect Quote ID")
      const indirectQuoteId = this.fullCustomData.find(
        item => item.CFNAME == "Indirect Quote ID"
      );
      if (indirectQuoteId && indirectQuoteId.CUSTOMVALUE == "") {
        indirectQuoteId.CUSTOMVALUE = this.sourceQuoteIds || "";
      }
    }

    let standardUUID = this.$route.params.createOrder || "";
    if (standardUUID.length) {
      const response = await ApiHelper.callApi("post", {
        controller: "Quotes",
        FunctionName: "StandardView",
        ObjID: standardUUID,
        selectedLiID: this.$route.query.selectedLiID
      });
      if (response.STATUS) {
        this.orderName = response.DETAILS.STANDARDNAME;
        let tmpGrandTotal = 0;
        this.title = "Standard Order #" + response.DETAILS.STANDARDNAME;
        var items = response.ITEMS.map(val => ({
          sku: val.SPRODSKU,
          name: val.SPRODDESC,
          customerPrice: val.SPRODPRICE,
          price: val.SPRICEREG,
          margin: val.ISCONFIG ? 0 : val.SPRODPRICE - val.SPRICEREG,
          marginRate: val.ISCONFIG
            ? 0
            : ((val.SPRODPRICE - val.SPRICEREG) / val.SPRODPRICE) * 100,
          quantity: val.SPRODQUANTITY,
          total: val.SPRICEACT,
          categoryType: val.CATEGORYTYPE || 0,
          savedAsConfig: val.ISCONFIG || 0,
          ISCONFIG: val.ISCONFIG || 0,
          baseProductId: val.STANDARDLIID || 0,
          includedparent: val.PARENTLIID || 0,
          included: val.SINCLUDED || 0,
          ItemCategoryName: val.CATEGORYNAME || "",
          ItemCategory: val.CATEGORYID || 0,
          ItemPLID: val.SPRODUCTLINE || 0,
          ItemPLName: val.PRODUCTLINE || ''
        }));
        for (let i = 0; i < items.length; i++) {
          tmpGrandTotal += items[i].total;
        }
        this.summary.grandTotal = tmpGrandTotal;
        items.push({
          sku: "",
          name: "",
          customerPrice: "",
          quantity: "",
          total: "",
          categoryType: "",
          ItemCategoryName: "",
          ItemCategory: ""
        });
        this.$refs.orderFormLineItemList.setRows(items, "");
      } else {
        notifier.alert("Unable to create order.");
        this.$router.push({
          name: "QuoteStandards"
        });
      }
    }

    //generate order for unverified order
    if (
      this.$route.name == "GenerateOrder" &&
      typeof this.$route.params.customerPO != "undefined" &&
      this.$route.params.customerPO != ""
    ) {
      this.generateForUnverify = true;
      // this.getDetailsForUnverify(this.$route.params.customerPO);
      this.orderID = this.$route.params.customerPO;
      this.unverifyOrder = await this.getUnverifyOrder(this.orderID);
      await this.confirmUnverifyOrder(this.unverifyOrder);
    }
  }

  // methods: {
  listPageRedirection() {
    this.$router.push({ name: "Orders" });
  }

  getTotals() {
    var comInstance = this;
    this.itemTotal = 0;
    this.totalTax = 0;
    this.totalShipping = 0;
    this.profit = 0;
    $.each(comInstance.itemNames, function(i, val) {
      var ind = i.replace("productName_", "");
      if (typeof comInstance.itemNames["productName_" + ind] != "undefined") {
        comInstance.itemNames["productName_" + ind].shipping =
          typeof comInstance.itemNames["productName_" + ind].shipping !=
            "undefined" &&
          comInstance.itemNames["productName_" + ind].shipping != ""
            ? parseFloat(
                comInstance.itemNames["productName_" + ind].shipping
              ).toFixed(2)
            : "0.00";
        comInstance.itemNames["productName_" + ind].tax =
          typeof comInstance.itemNames["productName_" + ind].tax !=
            "undefined" && comInstance.itemNames["productName_" + ind].tax != ""
            ? parseFloat(
                comInstance.itemNames["productName_" + ind].tax
              ).toFixed(2)
            : "0.00";
        comInstance.itemNames["productName_" + ind].customerPrice =
          typeof comInstance.itemNames["productName_" + ind].customerPrice !=
            "undefined" &&
          comInstance.itemNames["productName_" + ind].customerPrice != ""
            ? comInstance.itemNames["productName_" + ind].customerPrice
            : comInstance.itemNames["productName_" + ind].total;
        comInstance.profit =
          parseFloat(comInstance.profit.toString()) +
          parseFloat(
            (
              comInstance.itemNames["productName_" + ind].customerPrice *
                comInstance.itemNames["productName_" + ind].quantity -
              comInstance.itemNames["productName_" + ind].price *
                comInstance.itemNames["productName_" + ind].quantity
            ).toString()
          );
        comInstance.totalShipping =
          parseFloat(comInstance.totalShipping.toString()) +
          parseFloat(comInstance.itemNames["productName_" + ind].shipping);
        comInstance.totalTax =
          parseFloat(comInstance.totalTax.toString()) +
          parseFloat(comInstance.itemNames["productName_" + ind].tax);

        comInstance.itemTotal =
          parseFloat(comInstance.itemTotal.toString()) +
          parseFloat(
            (
              comInstance.itemNames["productName_" + ind].customerPrice *
              comInstance.itemNames["productName_" + ind].quantity
            ).toString()
          );
      }
    });

    this.grandTotal = this.itemTotal + this.totalShipping + this.totalTax;

    this.profit =
      "$" +
      parseFloat(this.profit.toString())
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
    this.totalShipping =
      "$" +
      parseFloat(this.totalShipping.toString())
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
    this.totalTax =
      "$" +
      parseFloat(this.totalTax.toString())
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
    this.itemTotal =
      "$" +
      parseFloat(this.itemTotal.toString())
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
    this.grandTotal =
      "$" +
      parseFloat(this.grandTotal.toString())
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
  }
  async updateCustomer(data) {
    if (this.aID != data.account.AID) {
      this.opportunityName = "";
      this.opportunityID = 0;
    }
    this.customer = data.account.ANAME;
    this.aID = data.account.AID;
    this.accountID = data.account.ACCOUNTID;
    this.accountGenerateHardware = parseInt(data.account.GENERATEHARDWARE) || 0;
    this.arrowCounter = 0;
    // this.currCustTax = data.account.TAXRATE;
    // var $this = this;
    // if (data.account.TAXRATE > 0 || this.cusTaxApplied) {
    //   $.each(this.$refs.orderFormLineItemList.itemNames, function(i, val) {
    //     if($this.prevCustTax == val.taxRate){
    //       val.taxRate = data.account.TAXRATE;
    //     }
    //     $this.$refs.orderFormLineItemList.taxRate(i);
    //   });
    //   this.cusTaxApplied = true;
    // }
    // this.prevCustTax = data.account.TAXRATE;
    // $("#autoSuggestCustomer").addClass("hide");
    const autoTax =
      data.account.SHIPPINGAUTOTAX || data.account.BILLINGAUTOTAX || 0;
    if (!autoTax) {
      // check preset tax rates if no set auto tax rate
      this.newTaxRate = 0;
      if (this.isIndirect() && data.account.INDIRECTTAXRATE > 0) {
        // indirect
        this.newTaxRate = data.account.INDIRECTTAXRATE;
      } else if (!this.isIndirect() && data.account.HPDIRECTTAXRATE > 0) {
        // direct
        this.newTaxRate = data.account.HPDIRECTTAXRATE;
      }
    }
    if (this.currentTaxRate != this.newTaxRate && !this.isEmptyLineItems()) {
      this.switchCustomerMsg1 =
        "You are about to switch to customer <strong>" +
        data.account.ANAME +
        "</strong>. Please confirm this change.";
      this.hpDirectTaxRateTmp = data.account.HPDIRECTTAXRATE;
      this.indirectTaxRateTmp = data.account.INDIRECTTAXRATE;
      this.confirmedSwitchCustomer = false;
      this.confirmSwitchCustomerModalVisible = true;
    } else {
      //backup current selected customer
      this.customer_bk = {
        customer: this.customer,
        aID: this.aID,
        accountID: this.accountID
      };

      if (!autoTax && this.currentTaxRate != this.newTaxRate) {
        //need to confirm switch tax rate in this case
        this.hpDirectTaxRateTmp = data.account.HPDIRECTTAXRATE;
        this.indirectTaxRateTmp = data.account.INDIRECTTAXRATE;
        this.confirmSwitchCustomer();
      } else {
        this.hpDirectTaxRate = data.account.HPDIRECTTAXRATE;
        this.indirectTaxRate = data.account.INDIRECTTAXRATE;
      }
    }

    // this.hpDirectTaxRate = data.account.HPDIRECTTAXRATE;
    // this.indirectTaxRate = data.account.INDIRECTTAXRATE;
    // this.applyTaxRateBySource();

    // reset customer address
    // this.customerAddresses = [];
    // this.customerAddressesBilling = [];
    // this.selectedAddress = [];
    // this.selectedBillingAddress = [];
    // this.onetimeAddress = {};
    // this.onetimeAddressBilling = {};
    // this.onetimeDepartment = {};
    // this.endUsers = [];
    // this.selectedEndUsers = [];
    // this.invoiceMail = [];
    // this.contactEmail = [];
    // this.euPhone = "";
    // this.euPhoneText = "";
    // this.euEmail = "";
    this.resetCustomerAddress();
    this.addrLoading = true;
    if (this.aID > 0) {
      await this.getCustomerAddresses(this.aID, true);
      //Get customer department details
      await this.getCustomerDepartment(this.aID);
    }
    await this.updateAddressForCustomer(this.aID);
    this.addrLoading = false;

    // To list the selected address on the top
    if (this.customerAddresses.length) {
      this.sortingAddresses(this.customerAddresses, this.selectedAddress);
    }
    if (this.customerAddressesBilling.length) {
      this.sortingAddresses(
        this.customerAddressesBilling,
        this.selectedBillingAddress
      );
    }
    // To list the selected contacts on the top
    if (this.endUsers.length) {
      this.sortingAddresses(this.endUsers, this.selectedEndUsers);
    }
    if (this.invoiceMail.length) {
      this.sortingAddresses(this.invoiceMail, this.contactEmail);
    }

    this.checkAutomateTaxRate();
  }

  resetCustomerAddress() {
    this.customerAddresses = [];
    this.customerAddressesBilling = [];
    this.selectedAddress = [];
    this.selectedBillingAddress = [];
    this.onetimeAddress = {};
    this.onetimeAddressBilling = {};
    this.onetimeDepartment = {};
    this.endUsers = [];
    this.selectedEndUsers = [];
    this.invoiceMail = [];
    this.contactEmail = [];
    this.euPhone = "";
    this.euPhoneText = "";
    this.euEmail = "";
  }

  async updateAddressForCustomer(aID) {
    try {
      // get default address
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Accounts",
        FunctionName: "defaultAddress",
        aID: aID
      });
      if (response.data.STATUS == 1) {
        // fill shipping address
        const defaultShippingAddr = response.data.DEFAULTADDRESS.find(
          tmp => tmp.ACCOUNTSADDRESS_TYPE == 1
        );
        if (defaultShippingAddr) {
          // if had a default shipping address
          this.getAddressText(defaultShippingAddr);
          this.selectedAddress = [defaultShippingAddr.ACCOUNTSADDRESS_ID];
          this.highlightedAddress = defaultShippingAddr.ACCOUNTSADDRESS_ID;
        }

        // fill billing address
        const defaultBillingAddr = response.data.DEFAULTADDRESS.find(
          tmp => tmp.ACCOUNTSADDRESS_TYPE == 2
        );
        if (defaultBillingAddr) {
          // if had a default billing address
          this.getAddressText(defaultBillingAddr);
          this.selectedBillingAddress = [defaultBillingAddr.ACCOUNTSADDRESS_ID];
          this.highlightedBillingAddress =
            defaultBillingAddr.ACCOUNTSADDRESS_ID;
        }
      }
    } catch (error) {
      // console.log(error);
    }
  }

  async updateQuote(quoteID, quoteName) {
    if (this.$route.name == "OrderCreate") {
      // case convert multi quotes to order, same action as "create order" at quote details page
      const inSuggested: any = (this.suggestedQuotes || []).find(
        (item: any) => item.QUOTEID == quoteID
      );
      if (inSuggested && (inSuggested.INGROUPQUOTES || 0)) {
        this.suggestedQuotes = [];
        await this.$router
          .replace({
            name: "OrderCreate",
            params: {
              fromQuote: "0"
            },
            query: {
              quoteIds: `${quoteID}`
            }
          })
          .catch(e => {
            // do nothing
          });
        await this.init();
        this.$nextTick().then(() => {
          $("#orderID").focus();
        });
        return;
      }

      // normal case
      await this.$router
        .replace({
          name: "OrderCreate",
          params: {
            fromQuote: quoteID || 0
          }
        })
        .catch(e => {
          // do nothing
        });
    }

    this.quoteID = quoteID;
    this.quoteName = quoteName;
    this.arrowCounter = 0;
    this.suggestedQuotes = [];
    // $("#autoSuggestQuote").addClass("hide");

    this.convertToOrder(quoteID, self);
  }

  async updateSource(data) {
    if (this.sourceID) {
      this.prevsource = (data.allOption || []).find(
        item => item.VARSOURCE_ID === this.sourceID
      );
      if (!this.prevsource) {
        this.prevsource = {
          VARSOURCE_ID: 0,
          VARSOURCE_NAME: ""
        };
      }
    } else {
      this.prevsource.VARSOURCE_ID = 0;
      this.prevsource.VARSOURCE_NAME = "";
    }
    var orderFormLineItemList: any = this.$refs.orderFormLineItemList;
    this.sourceID = data.varsource.VARSOURCE_ID;
    this.source = data.varsource.VARSOURCE_NAME || "";

    // default ON for auto name + cost in case source = dynamic
    if (this.isDynamic()) {
      orderFormLineItemList.autoName = 1;
      orderFormLineItemList.autoCost = 1;
      $(
        ".switch.auto input[type=checkbox].autoName, .switch.auto input[type=checkbox].autoCost"
      ).prop("checked", true);
    }
    await orderFormLineItemList.updateSource();
    if (this.confirmedSourceRateVisible == false) {
      this.showConfirmSwitchTaxRateBySource();
    }

    // default selecting funding source for HPE & HP Direct order
    if (this.source == "HPE" || this.source == "HP Direct") {
      var $this = this;
      this.financialSource.filter(function(finSource: any) {
        if (finSource.VARSOURCE_NAME == $this.source) {
          $this.fundingSourceId = finSource.VARSOURCE_ID;
          $this.fundingSourceName = finSource.VARSOURCE_NAME;
          $this.fundingSourceTerms = finSource.TERMS || "";
        }
      });
    }
    // default selecting funding source based on source selected
    if (data.option && data.option.fundingsourceid) {
      var $this = this;
      this.financialSource.filter(function(finSource: any) {
        if (finSource.VARSOURCE_ID == data.option.fundingsourceid) {
          $this.fundingSourceId = finSource.VARSOURCE_ID;
          $this.fundingSourceName = finSource.VARSOURCE_NAME;
          $this.fundingSourceTerms = finSource.TERMS || "";
        }
      });
    }

    // if(!!this.customer == true) {
    //   const tmpTaxRate = this.getTaxRateBySource();
    //   if(tmpTaxRate != 0 && tmpTaxRate != this.currentTaxRate) {
    //     this.currentTaxRate = tmpTaxRate;
    //     this.applyTaxRateBySource();
    //   }
    // }
  }

  showConfirmSwitchTaxRateBySource() {
    if (!!this.customer == true) {
      const autoTax =
        this.autoTaxSetting.shippingAutoTax ||
        this.autoTaxSetting.billingAutoTax ||
        0;
      if (autoTax) return;

      const tmpTaxRate = this.getTaxRateBySource();
      if (tmpTaxRate != 0 && tmpTaxRate != this.currentTaxRate) {
        // confirm apply tax rate by source
        this.switchCustomerMsg2 =
          "<strong>" +
          this.customer +
          "</strong> has a tax rate of " +
          tmpTaxRate +
          "% for <strong>" +
          this.source +
          "</strong>. Do you want to override the current tax of " +
          this.currentTaxRate +
          "%?";
        this.confirmSwitchTaxRateVisible = true;
      }
    }
  }

  showPrevSource() {
    this.sourceID = this.prevsource.VARSOURCE_ID;
    this.source = this.prevsource.VARSOURCE_NAME || "";
  }

  onArrowDown(data) {
    if (this.arrowCounter < data.length) {
      this.arrowCounter = this.arrowCounter + 1;
    }
  }
  onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }
  onPaste(e) {
    this.getQuoteData(e.target.value, 1);
  }
  onEnter(data, e) {
    e.stopPropagation();
    e.preventDefault();
    var accObj = {
      account: {
        ANAME: data[this.arrowCounter].ANAME,
        AID: data[this.arrowCounter].AID,
        ACCOUNTID: data[this.arrowCounter].ACCOUNTID
      }
    };
    if ($.isArray(data) && data.length) {
      this.updateCustomer(accObj);
    }
  }
  onEnterOnQuote(data, e) {
    e.stopPropagation();
    e.preventDefault();

    if ($.isArray(data) && data.length && data[this.arrowCounter]) {
      this.updateQuote(
        data[this.arrowCounter].QUOTEID,
        data[this.arrowCounter].QDESCRIPTION
      );
    }
  }
  onEnterItem(data, index) {
    this.itemNames[index].name = data[this.arrowCounter].ANAME;
    this.itemNames[index].price = 100;
    this.arrowCounter = 0;
    var element = document.getElementById("autoSuggest_" + index);
    element!.classList.add("hide");
  }
  async validateBeforeSubmit(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    var [result, lineItems] = await Promise.all([
      this.$validator.validateAll(),
      this.$refs.orderFormLineItemList.validateAll()
    ]);

    var htmlCount = 0;
    if ($("#orderID").val() != "") {
      if (htmlCheck($("#orderID").val())) {
        this.orderID = htmlParse($("#orderID").val());
        htmlCount++;
      }
    }
    if ($("#orderName").val() != "") {
      if (htmlCheck($("#orderName").val())) {
        this.orderName = htmlParse($("#orderName").val());
        htmlCount++;
      }
    }
    if ($("#quoteName").val() != "") {
      if (htmlCheck($("#quoteName").val())) {
        this.quoteName = htmlParse($("#quoteName").val());
        htmlCount++;
      }
    }
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
      result = false;
    }

    //Do not allow saving with 0 quantity
    var hasZeroQty = false;
    var items = this.$refs.orderFormLineItemList.getItemNames();
    let noCategory = false;
    for (let i in items) {
      if (
        items[i].sku != "" &&
        (items[i].price != 0 || items[i].customerPrice != 0) &&
        items[i].quantity == 0 &&
        !items[i].included
      ) {
        hasZeroQty = true;
        result = false;
      }
      if (
        (items[i].isFee == true || items[i].isContractFee) &&
        items[i].price == 0
      ) {
        result = false;
      }
      if (items[i].sku != "" && !(items[i].ItemCategory || 0)) {
        noCategory = true;
      }
    }
    if (hasZeroQty) {
      $("#errorMsgLine").html("Please enter a quantity to save");
    }

    if (!result) {
      return;
    }

    if (!lineItems) {
      return;
    }

    this.itemNames = lineItems;
    this.length = Object.keys(this.itemNames).length;

    if (this.mailRequired) {
      this.saving = false;
      return;
    }

    // if selected a funding source, make sure has "terms"
    if (this.fundingSourceId && !this.fundingSourceTerms) {
      this.confirmTermsVisible = true;
      this.$nextTick().then(() => {
        $("#fundingSourceTerms").focus();
      });
      return;
    }

    if (
      this.varGenerateHardware == "yes" &&
      this.accountGenerateHardware &&
      noCategory
    ) {
      // need classify sku category if auto generate hardware
      this.categorySKUVisible = true;
      return;
    }

    if (this.USECUSTOMTAX == 1) {
      this.ConfirmCustomTax = true;
      return;
    }

    try {
      this.saving = true;
      await this.createOrder(this, this.inEditPage);
      this.confirmedUnverifyOrder = false;
      this.saving = false;
    } catch (err) {
      this.saving = "error";
      console.log(err);
    }
  }
  async updateCustomTax() {
    this.taxLoading = true;
    try {
      await this.createOrder(this, this.inEditPage);
      this.taxLoading = false;
      this.ConfirmCustomTax = false;
    } catch (err) {
      this.taxLoading = "error";
    }
  }
  getQuoteDat(value, getAll) {
    var accObj = {};
    if (!getAll) {
      getAll = 0;
    }
    accObj["controller"] = "Quotes";
    accObj["FunctionName"] = "List";
    accObj["search"] = value;
    accObj["getAll"] = getAll;
    var self = this;
    var accData = getRouteData(accObj);
    accData.then(function(response, statusText, jqXHR) {
      self.suggestedQuotes = response.data.QUOTES;
    });
    var element;
    if (value.length >= 1) {
      element = document.getElementById("autoSuggestQuote");
      element.classList.remove("hide");
    } else {
      element = document.getElementById("autoSuggestQuote");
      element.classList.add("hide");
    }
  }
  submitWithNotes(data) {
    this.updateNotes = data;
    this.createOrder(this, this.inEditPage);
  }
  createOrder(value, inEditPage) {
    if (!inEditPage) {
      inEditPage = false;
    } else if (this.OrderUpdateNotes == false) {
      this.OrderUpdateNotes = true;
      return false;
    }

    // var today = new Date();
    // var date =
    //   today.getFullYear() +
    //   "-" +
    //   (today.getMonth() + 1) +
    //   "-" +
    //   today.getDate();
    var saveData = {};
    saveData["Controller"] = "Orders";
    saveData["FunctionName"] = "Add";
    saveData["inEditPage"] = inEditPage;
    saveData["plines"] = value.length.toString();
    saveData["orderID"] = value.orderID;
    saveData["aID"] = value.aID;
    saveData["accountID"] = value.accountID;
    saveData["orderDate"] = value.orderDate;
    saveData["orderName"] = value.orderName;
    saveData["totalPrice"] = "$ " + value.grandTotal;
    saveData["orderTotal"] = 0;
    saveData["qTax"] = parseFloat(value.summary.totalTax).toFixed(2);
    saveData["qShip"] = parseFloat(value.summary.totalShipping).toFixed(2);
    saveData["source"] = value.sourceID;
    saveData["EstPercent"] = this.isIndirect() ? 0 : value.summary.estPercent;
    saveData["includeVarCost"] = value.summary.includeVarCost;
    saveData["purchaseID"] = value.purchaseID;
    saveData["contactEmail"] = value.contactEmail.length
      ? value.contactEmail.join()
      : "";
    // end user info
    saveData["endUserId"] = value.selectedEndUsers.length
      ? value.selectedEndUsers.join()
      : "";
    saveData["euName"] = value.euName || "";
    saveData["euPhone"] = value.euPhone || "";
    saveData["euPhoneText"] = value.euPhoneText || "";
    saveData["euEmail"] = value.euEmail || "";
    saveData["euID"] = value.euID || 0;
    saveData["isDisty"] = value.processWithDisty;
    saveData["customSourceName"] = value.sourceName;
    saveData["orderUpdateNotes"] = this.updateNotes;
    saveData["expandDecimal"] = this.expandDecimal;
    saveData["orderQuotes"] = value.quoteIds;
    saveData["DefaultShippingID"] = value.selectedAddress.length
      ? value.selectedAddress.join()
      : 0;
    saveData["DefaultBillingID"] = value.selectedBillingAddress.length
      ? value.selectedBillingAddress.join()
      : 0;
    saveData["FileName"] = this.uploadedFileName;
    saveData["opportunityID"] = value.opportunityID;
    saveData["opportunityPrevID"] = value.opportunityPrevID;
    if (value.selectedAddress.length && saveData["DefaultShippingID"] == 0) {
      // if use a one time address for this order
      saveData["onetimeAddress"] = this.onetimeAddress;
    }
    if (
      value.selectedBillingAddress.length &&
      saveData["DefaultBillingID"] == 0
    ) {
      // if use a one time address for this order
      saveData["onetimeAddressBilling"] = this.onetimeAddressBilling;
    }

    if (inEditPage) {
      saveData["customerName"] = value.customer;
      saveData["sourceName"] = value.source;
    }

    if (
      typeof value.confirmedDuplicatePO != "undefined" &&
      value.confirmedDuplicatePO == true
    ) {
      saveData["confirmedDuplicatePO"] = true;
    }

    if (typeof value.quoteID != "undefined") {
      saveData["orderQuote"] = value.quoteID;
      if (!inEditPage) {
        // create new from ss quote
        if ((this.ssInfo.SSId || "") != "") {
          saveData["SSId"] = this.ssInfo.SSId;
        }
        if ((this.ssInfo.SSUrl || "") != "") {
          saveData["SSUrl"] = this.ssInfo.SSUrl;
        }
        if ((this.ssInfo.SSName || "") != "") {
          saveData["SSName"] = this.ssInfo.SSName;
        }
        saveData["confirmedPayment"] = this.confirmedPayment || false;
      }
    }
    // uncapture payment
    if (this.stripeToken && !this.paymentCaptured) {
      saveData["stripeToken"] = this.stripeToken || "";
      saveData["stripeCustomerId"] = this.stripeCustomerId || "";
      saveData["stripeCardId"] = this.stripeCardId || "";
      saveData["completePaymentEnable"] = this.completePaymentEnable || false;
    }

    if (this.generateForUnverify) {
      saveData["generateForUnverify"] = this.generateForUnverify;
      saveData["unverifyTotal"] = this.summary.grandTotal;
    }
    // saveData["globalTag"] = value.globalTag;
    // saveData["customID"] = value.customID;
    saveData["selectedGlobalTagsID"] = value.selectedGlobalTagsID.join(",");
    saveData["globalMargin"] = this.isIndirect() ? value.globalMargin : 0; //(value.confirmedGlobalMargin ? value.globalMargin : 0);

    saveData["selectedContractNumberValue"] = value.selectedOrderContracts;
    saveData["contractNumberFieldId"] =
      value.orderContractNumber[0].CUSTOMFIELDID;

    //check/remove HTML for custom field values
    for (var val of value.fullCustomData) {
      val.CUSTOMVALUE = this.removeHTML(val.CUSTOMVALUE);
    }
    saveData["customData"] = JSON.stringify(value.fullCustomData);
    saveData["taxRate"] = this.currentTaxRate;
    saveData["usedCustomTax"] = !!this.USECUSTOMTAX;
    // business line
    saveData["businessLineId"] = this.selectedBusinessLine[0] || 0;
    // confirmed linking with an unverify order
    saveData["confirmedUnverifyOrder"] = !inEditPage
      ? this.confirmedUnverifyOrder
      : false;
    saveData["unverifyOrder"] = JSON.stringify(this.unverifyOrder);

    let totalFee = 0;
    let totalContractFee = 0;
    let itemOrder = 0;
    const sourceIDs: any = [];

    const itemNames = this.$refs.orderFormLineItemList.getItemNames();
    $.each(itemNames, function(i, val) {
      const ind = i.replace("productName_", "");
      saveData["ItemDescription" + ind] = val.name;
      saveData["ItemNumber" + ind] =
        typeof val.sku != "undefined" ? val.sku : "";
      saveData["ItemQuantity" + ind] =
        val.quantity > 0 ? val.quantity.toString() : "";
      saveData["RegPrice" + ind] =
        val.price != 0
          ? val.price.toFixed(value.expandDecimalPrecision).toString()
          : "";
      saveData["ItemTotal" + ind] = val.total > 0 ? val.total.toString() : "";
      saveData["ItemCustomerPrice" + ind] =
        val.customerPrice != 0
          ? val.customerPrice.toFixed(value.expandDecimalPrecision).toString()
          : "";
      saveData["ItemMargin" + ind] = val.margin.toString();
      saveData["ItemMarginRate" + ind] =
        typeof val.marginRate != "undefined" ? val.marginRate : 0;
      saveData["ItemShipping" + ind] =
        val.shipping > 0 ? val.shipping.toString() : "";
      saveData["ItemTaxRate" + ind] =
        val.taxRate > 0 ? val.taxRate.toString() : "";
      saveData["ItemTax" + ind] = val.tax > 0 ? val.tax.toString() : "";
      saveData["ItemNoTax" + ind] = val.noTax ? 1 : 0;
      saveData["ItemIncluded" + ind] = val.included ? 1 : 0;
      saveData["statusid" + ind] =
        typeof val.statusid != "undefined" ? val.statusid : "0";

      if (inEditPage) {
        saveData["poLiID" + ind] =
          typeof val.poLiID != "undefined" ? val.poLiID : 0;
      }
      saveData["ItemCategory" + ind] =
        typeof val.ItemCategory != "undefined" ? val.ItemCategory : 0;
      saveData["ItemPLID" + ind] =
        typeof val.ItemPLID != "undefined" ? val.ItemPLID : 0;
      saveData["productLine" + ind] = val.ItemPLName || "";
      saveData["buildHeader" + ind] =
        typeof val.buildHeader != "undefined" ? val.buildHeader : "";
      saveData[`ItemQuoteLiId${ind}`] = val.quoteLiId || 0;
      saveData[`ItemQuoteParentLiId${ind}`] = val.quoteParentLiId || 0;
      saveData[`sourceID${ind}`] = val.dynamicInfo
        ? val.dynamicInfo.selectedDistyID || 0
        : 0;
      saveData[`ItemQuoteId${ind}`] = val.quoteId || "";
      saveData[`ItemSubQuoteId${ind}`] = val.subQuoteId || val.subOrderId || 0;
      saveData[`ItemIsConfig${ind}`] = val.ISCONFIG || 0;
      saveData[`ItemSubOrderId${ind}`] = val.subOrderId || 0;
      saveData[`ItemContractPrice${ind}`] = val.contractPrice || [];

      // specify item order
      itemOrder++;
      saveData[`ItemOrder${ind}`] = itemOrder;

      if (saveData[`sourceID${ind}`] > 0) {
        sourceIDs.push(saveData[`sourceID${ind}`]);
      }

      // calculate total fee
      if (val.isFee || false) {
        totalFee += val.price;
      }

      // calculate total contract fee
      if (val.isContractFee || false) {
        totalContractFee += val.price;
      }
    });

    saveData["totalFee"] = totalFee;
    saveData["totalContractFee"] = totalContractFee;

    // auto update source to a distributor in case dynamic
    if (saveData["source"] == 10 && sourceIDs.length) {
      const usedMultiSource = sourceIDs.find((s: any) => s != sourceIDs[0]);
      if (!usedMultiSource) {
        saveData["source"] = sourceIDs[0];
      }
    }
    saveData["fundingSource"] = this.fundingSourceId;
    saveData["fundingSourceTerms"] = this.fundingSourceTerms || "";

    // Department Info
    const departmentDetails = {
      ID: this.selectedDepartment.length ? this.selectedDepartment.join() : 0,
      NAME: this.selectedDepartmentName
    };
    saveData["departmentDetails"] = departmentDetails;
    let DefaultDeptID = this.selectedDepartment.length
      ? this.selectedDepartment.join()
      : 0;
    if (DefaultDeptID == 0) {
      // if use a one time dept for this order
      saveData["onetimeDepartment"] = this.onetimeDepartment;
    }
    saveData["includedItem"] = this.includedItem;
    saveData["includedPrice"] = this.includedPrice;

    // case multi end users
    saveData["quoteEndUsers"] = this.quoteEndUsers || [];
    saveData["dataFields"] = this.dataFields || [];
    saveData["generalDataFields"] = this.generalDataFields || [];

    // add data when duplicate order has multi end users
    // should same as convert from multi quotes (so using "subOrderId" as a "subQuoteId")
    if (this.$route.name == "DuplicateOrder" && this.isMultiEndUsers) {
      const quoteEndUsers: any = [];
      let dataFields: any = [];
      let generalDataFields: any = [];
      for (const item of this.splitBySubOrderId) {
        const subQuoteId = item.subOrderId;
        quoteEndUsers.push({
          quoteId: "",
          subQuoteId,
          euId: item.euInfo.EUID || 0,
          euName: item.euInfo.EUNAME || "",
          addr1: item.euInfo.ADDR1 || "",
          addr2: item.euInfo.ADDR2 || "",
          city: item.euInfo.CITY || "",
          euState: item.euInfo.EUSTATE || "",
          zip: item.euInfo.ZIP || "",
          country: item.euInfo.COUNTRY || "",
          firstName: item.euInfo.FIRSTNAME || "",
          lastName: item.euInfo.LASTNAME || "",
          phone: item.euInfo.PHONE || "",
          email: item.euInfo.EMAIL || ""
        });

        dataFields = [
          ...dataFields,
          ...item.euCD
            .filter(t => t.isEUData == 1)
            .map(t => ({
              quoteId: "",
              subQuoteId,
              customFieldId: t.customFieldId,
              customValue: t.customValue
            }))
        ];

        generalDataFields = [
          ...generalDataFields,
          ...item.euCD
            .filter(t => t.isEUData == 0)
            .map(t => ({
              quoteId: "",
              subQuoteId,
              customFieldId: t.customFieldId,
              customValue: t.customValue
            }))
        ];
      }

      saveData["quoteEndUsers"] = quoteEndUsers;
      saveData["dataFields"] = dataFields;
      saveData["generalDataFields"] = generalDataFields;
      saveData["duplicateOrderHasMultiEUs"] = this.duplicateOrderCreate;
    }
    // else if ((this.$route.query.quoteIds || '').length) {
    //   let dataFields: any = [];

    //   for (const item of this.dataFields) {
    //     try {
    //       const subQuoteId = item.SUBQUOTEID;
    //       const quoteId = item.QUOTEID;

    //       const filteredData = this.selectedSubOrder.euCD.filter(t => t.isEUData == 1 && t.customFieldId === item.CUSTOMFIELDID).map(t => ({
    //         quoteId,
    //         subQuoteId,
    //         customFieldId: t.customFieldId,
    //         customValue: t.customValue
    //       }));

    //       // Check if filteredData is empty
    //       if (filteredData.length === 0) {
    //         console.log('No data added for item with CUSTOMFIELDID:', item.CUSTOMFIELDID);
    //       } else {
    //         dataFields = [...dataFields, ...filteredData];
    //       }
    //     } catch (error) {
    //       console.error('Error in loop:', error);
    //     }
    //   }
    //   saveData["dataFields"] = dataFields;
    // }

    var self = this;
    var saveRecord = getRouteData(saveData);
    return saveRecord.then(async function(response, statusText, jqXHR) {
      if (response.data.ERROR) {
        return false;
      }

      if (response.data.STATUS) {
        self.items = response.data.ORDER_LINEITEMS || [];
        self.details = response.data || {};

        if (!inEditPage) {
          if (
            self.quoteID != "" &&
            self.sourceID > 1 &&
            self.processWithDisty
          ) {
            // placed with disty
            // const processResponse = await axios.post(dataURL + "?ReturnType=JSON", {
            //   controller: "Orders",
            //   FunctionName: "PlaceDistributorOrder",
            //   purchaseID: response.data.PURCHASEID,
            //   sourceId: self.sourceID
            // });
            // if(processResponse.data.STATUS) {
            //   if(typeof processResponse.data.DistributorID != "undefined") {
            //     alert("Order already pushed to distibutor with external ID: " + processResponse.data.DistributorID);
            //   }
            //   self.$router.push({ name: "Orders" }); //go to orders list
            // }else {
            //   console.error("Placed with disty error", processResponse.data.STATUSMESSAGE);
            //   throw new Error(processResponse.data.STATUSMESSAGE);
            // }
            self.showProcessOrderModal(response.data.PURCHASEID);
          } else {
            self.$router.push({
              name: "ViewOrder",
              params: { id: response.data.PURCHASEID }
            }); //save new order go to order details page
          }
        } else {
          self.$router.push({
            name: "ViewOrder",
            params: { id: self.$route.params.id }
          }); //go to order details page
        }
      } else {
        // handle errors
        if (
          typeof response.data.POexisted != "undefined" &&
          response.data.POexisted == true
        ) {
          if (
            confirm(
              "This PO is already in use are you sure you want to create?"
            )
          ) {
            value.confirmedDuplicatePO = true;
            self.createOrder(value, inEditPage);
          }
        } else if (
          typeof response.data.invalidCard != "undefined" &&
          response.data.invalidCard == true
        ) {
          self.saving = "error";
          if (response.data.MESSAGE) {
            notifier.alert(response.data.MESSAGE);
          }
          if (self.quoteID != "") {
            // go to quote details page
            self.$router.push({
              name: "ViewQuote",
              params: { id: self.quoteID }
            });
          }
        } else if (!response.data.STATUS) {
          self.saving = "error";
          notifier.alert(response.data.STATUSMESSAGE);
        }
      }
    });
  }
  async convertToOrder(quote, container) {
    this.resetStripePaymentInfo();
    // this.title = "Create Order #" + quote;
    // this.quoteID = quote;

    var comInstance = this;
    var dataObj = {
      controller: "Quotes",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: quote,
      convertingToOrder: true
    };

    const orderFormLineItemList = this.$refs.orderFormLineItemList;

    if (quote != 0) {
      this.loading = true;
      // var quoteData = getRouteData(dataObj);
      // quoteData.then(async function(response, statusText, jqXHR) {
      try {
        const response = await axios.post(
          dataURL + "?ReturnType=JSON",
          dataObj
        );
        $("input#quoteName").val(response.data.QUOTENAME);
        var retData = response.data.QUOTEITEMS;

        //set some value for comInstance
        var quoteDetails = response.data.QUOTEDETAILS;
        comInstance.aID = quoteDetails.AID.toString();
        comInstance.accountID = quoteDetails.AACCOUNTID;
        comInstance.customer = quoteDetails.AACCOUNTNAME;
        comInstance.reseller = quoteDetails.AACCOUNTNAME;
        comInstance.quoteID = quote;
        comInstance.sourceQuoteIds = quoteDetails.QQUOTEID || "";
        comInstance.quoteName = response.data.QUOTENAME;
        comInstance.orderName = response.data.QUOTENAME;
        comInstance.opportunityID =
          typeof quoteDetails.OPPORTUNITYID != "undefined"
            ? quoteDetails.OPPORTUNITYID
            : 0;
        comInstance.opportunityPrevID =
          typeof quoteDetails.OPPORTUNITYID != "undefined"
            ? quoteDetails.OPPORTUNITYID
            : 0;
        comInstance.opportunityName =
          typeof quoteDetails.OPPORTUNITYID != "undefined" &&
          quoteDetails.OPPORTUNITYID
            ? `MOP-${quoteDetails.OPPORTUNITYID}`
            : "";
        if (
          typeof response.data.Payment_CustomPO != "undefined" &&
          response.data.Payment_CustomPO != ""
        ) {
          comInstance.orderID = response.data.Payment_CustomPO;
        }
        comInstance.selectedGlobalTags = response.data.selectedGlobalTags.map(
          (val: any) => ({
            CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
            CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
          })
        );
        comInstance.selectedGlobalTagsID = comInstance.selectedGlobalTags.map(
          (val: any) => val.CUSTOMFIELDOPTIONID
        );
        // comInstance.fullCustomData = [];
        // for(var val of response.data.CUSTOMDEFINITION) {
        //   let tmpValue: any = [];
        //   if(typeof response.data.CUSTOMDATA != "undefined") {
        //     tmpValue = response.data.CUSTOMDATA.filter(tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID);
        //   }
        //   comInstance.fullCustomData.push({
        //     CUSTOMFIELDID: val.CUSTOMFIELDID,
        //     CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
        //     CUSTOMDTYPE: val.CUSTOMDTYPE,
        //     CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
        //     CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0
        //   });
        // }

        comInstance.USECUSTOMTAX =
          response.data.USECUSTOMTAX != "undefined" &&
          response.data.USECUSTOMTAX == 1
            ? 1
            : 0;
        comInstance.source =
          typeof quoteDetails.VARSOURCE_NAME != "undefined"
            ? quoteDetails.VARSOURCE_NAME
            : "";
        comInstance.sourceID =
          typeof quoteDetails.SOURCE != "undefined" ? quoteDetails.SOURCE : 0;
        comInstance.estPercent = quoteDetails.ESTPERCENT
          ? quoteDetails.ESTPERCENT
          : 0;
        if (comInstance.source == "HPE" || comInstance.source == "HP Direct") {
          comInstance.financialSource.filter(function(finSource: any) {
            if (finSource.VARSOURCE_NAME == comInstance.source) {
              comInstance.fundingSourceId = finSource.VARSOURCE_ID;
              comInstance.fundingSourceName = finSource.VARSOURCE_NAME;
              comInstance.fundingSourceTerms = finSource.TERMS || "";
            }
          });
        } else if (quoteDetails.FUNDINGSOURCEID) {
          comInstance.financialSource.filter(function(finSource: any) {
            if (finSource.VARSOURCE_ID == quoteDetails.FUNDINGSOURCEID) {
              comInstance.fundingSourceId = finSource.VARSOURCE_ID;
              comInstance.fundingSourceName = finSource.VARSOURCE_NAME;
              comInstance.fundingSourceTerms = finSource.TERMS || "";
            }
          });
        }
        if (
          quoteDetails.GLOBALMARGIN != "undefined" &&
          quoteDetails.GLOBALMARGIN != 0
        ) {
          comInstance.globalMargin = quoteDetails.GLOBALMARGIN;
          comInstance.globalMarginBK = quoteDetails.GLOBALMARGIN;
          comInstance.confirmedGlobalMargin = true;
        }

        var profitTotal = quoteDetails.PROFIT;
        if (comInstance.estPercent > 0) {
          profitTotal =
            (quoteDetails.CUSTOMERTOTALPRICE * comInstance.estPercent) / 100;
        }
        comInstance.hpDirectTaxRate =
          typeof quoteDetails.HPDIRECTTAXRATE != "undefined"
            ? quoteDetails.HPDIRECTTAXRATE
            : 0;
        comInstance.indirectTaxRate =
          typeof quoteDetails.INDIRECTTAXRATE != "undefined"
            ? quoteDetails.INDIRECTTAXRATE
            : 0;
        comInstance.currentTaxRate = comInstance.getTaxRateBySource();
        comInstance.validSynnexCredential =
          response.data.validSynnexCredential || false;
        comInstance.validTechdataCredential =
          response.data.validTechdataCredential || false;
        comInstance.sourceCredentials = response.data.sourceCredentials || [];

        // override current tax rate if user used an override value before
        if (
          typeof quoteDetails.QTAXRATE != "undefined" &&
          quoteDetails.QTAXRATE !== ""
        ) {
          comInstance.currentTaxRate = quoteDetails.QTAXRATE;
        }

        // Set expandDecimal and expandDecimalPrecision
        const quoteParams = JSON.parse(quoteDetails.QUOTEPARAMS || "{}");
        comInstance.quoteParams = quoteParams;
        const endUser = quoteParams.endUser || {};
        this.ssInfo.SSId = quoteParams.SSId || "";
        this.ssInfo.SSUrl = quoteParams.SSUrl || "";
        this.ssInfo.SSName = quoteParams.SSName || "";

        // specify uncaptured stripe
        // comInstance.stripeToken = quoteParams.stripeToken || "";
        comInstance.paymentCaptured = response.data.captured || 0;
        const paymentConfirmID = response.data.PaymentConfirmID || "";
        if (
          paymentConfirmID &&
          paymentConfirmID != "0" &&
          !comInstance.paymentCaptured
        ) {
          // if has confirmed id and uncapture this amount
          comInstance.stripeToken = paymentConfirmID;
          comInstance.confirmedPayment = true;
          comInstance.stripeCustomerId = response.data.stripeCustomerId || "";
          comInstance.stripeCardId = response.data.stripeCardId || "";
        }

        // end user info
        comInstance.euName = endUser.Name || "";
        comInstance.euEmail = endUser.SubmitterEmail || "";

        if (quoteParams.expandDecimal) {
          comInstance.expandDecimal = quoteParams.expandDecimal;
          comInstance.expandDecimalPrecision = 5;
        }

        //Fill customer department
        if (
          typeof quoteParams.departmentDetails != "undefined" &&
          quoteParams.departmentDetails != ""
        ) {
          comInstance.selectedDepartment = [
            parseInt(quoteParams.departmentDetails.ID)
          ];
          comInstance.selectedDepartmentName =
            quoteParams.departmentDetails.NAME;
        }

        //Get customer department details
        if (comInstance.aID > 0) {
          comInstance.getCustomerDepartment(comInstance.aID);
        }

        //Set Contract Number
        if (response.data.selectedContractData.length) {
          comInstance.selectedOrderContracts =
            response.data.selectedContractData[0].CUSTOMFIELDOPTIONNAME;
          comInstance.orderContractsID = response.data.selectedContractData.map(
            (val: any) => val.CUSTOMFIELDOPTIONID
          );
        }

        comInstance.includedItem = quoteParams.includedItem || false;
        comInstance.includedPrice =
          typeof quoteParams.includedPrice != "undefined"
            ? quoteParams.includedPrice
            : true;
        comInstance.selectedBusinessLine = quoteDetails.BUSINESSLINEID
          ? [quoteDetails.BUSINESSLINEID]
          : [];

        //set summary
        comInstance.summary = {
          grandTotal: response.data.GRANDTOTAL,
          itemTotal: quoteDetails.CUSTOMERTOTALPRICE,
          profit: profitTotal,
          estPercent: comInstance.estPercent,
          totalCost: quoteDetails.QTOTALPRICE,
          qShip: response.data.TOTALSHIPPING,
          qTax: response.data.TOTALTAX,
          totalShipping: response.data.TOTALSHIPPING,
          includeVarCost: true,
          totalTax: response.data.TOTALTAX
        };

        if ($("#orderID").val() == "") {
          comInstance.quoteName =
            comInstance.quoteID + " - " + response.data.QUOTENAME;
        }

        const items: {
          sku: string;
          name: string;
          price: number;
          included: number;
          ISCONFIG?: number;
          categoryType: number;
          margin: number;
          marginRate: number;
          total: number;
          taxRate: number;
          tax: number;
          shipping: number;
          priceWarn: boolean;
          noTax: boolean;
          customerPrice: number;
          quantity: number;
          ItemCategory: number;
          ItemCategoryName: string;
          isFee: boolean;
          dynamicInfo: any;
          statusid: number;
          contractPrice: any;
          contractPriceDate: Date | undefined;
          orgPrice: number;
          orgCustomerPrice: number;
          disable: boolean;
          isContractFee: boolean;
          includedparent: number;
          baseProductId: any;
          includeItemCount: number;
          quoteLiId?: number;
          quoteParentLiId?: number;
          CONFIGCOUNT?: number;
          savedAsConfig?: number;
          ItemPLID: number;
          ItemPLName: string;
          buildHeader: string;
        }[] = [];

        let baseQty = 0;
        $.each(retData, function(i, val) {
          let categoryId = val.QCATEGORY || 0;
          let categoryName = val.CATEGORYNAME || "";
          let quantity = val.QINCLUDED
            ? val.QPRODQUANTITY_BK
            : typeof val.QPRODQUANTITY != "number"
            ? 0
            : val.QPRODQUANTITY;
          if (!(val.QINCLUDED || 0)) {
            baseQty = quantity;
          }
          if (val.ISCONFIG || 0) {
            // force config item to follow the base's quantity
            // quantity = baseQty;
          } else if (
            categoryId == (comInstance.configCategory.CATEGORYID || 0)
          ) {
            // reset category if item is not config, but has config category
            categoryId = 0;
            categoryName = "";
          }

          items.push({
            sku: val.QPRODSKU,
            name: val.QPRODDESC.replace(/[$|,]/g, ""),
            price: val.QINCLUDED ? val.QPRICEREG_BK : val.QPRICEREG,
            included:
              typeof val.QINCLUDED != "undefined" && val.QINCLUDED ? 1 : 0,
            ISCONFIG: val.ISCONFIG || 0,
            categoryType: val.CATEGORYTYPE || 0,
            customerPrice: val.QINCLUDED
              ? val.QCUSTOMERPRICE_BK
              : val.QCUSTOMERPRICE,
            margin: val.QINCLUDED ? val.QMARGIN_BK : val.QMARGIN,
            marginRate: comInstance.editGetMarginRate(val, "quote"),
            total:
              typeof val.CUSTOMERTOTALPRICE != "undefined"
                ? val.CUSTOMERTOTALPRICE
                : val.QPRICEACT,
            quantity,
            taxRate:
              typeof val.QTAXRATE != "undefined" && val.QTAXRATE != ""
                ? val.QTAXRATE
                : 0,
            tax: val.QINCLUDED
              ? val.QTAX_BK
              : comInstance.getLineItemTax(
                  val.CUSTOMERTOTALPRICE,
                  val.QTAXRATE
                ),
            shipping:
              typeof val.QSHIPPING != "undefined" && val.QSHIPPING != ""
                ? val.QSHIPPING
                : 0,
            priceWarn: false,
            noTax: typeof val.NOTAX != "undefined" && val.NOTAX ? true : false,
            ItemCategory: categoryId,
            ItemCategoryName: categoryName,
            isFee: comInstance.checkItemIsFee({
              POPRODSKU: val.QPRODSKU,
              POCUSTOMERPRICE: val.QCUSTOMERPRICE_BK,
              POINCLUDED: val.QINCLUDED
            }),
            dynamicInfo: val,
            statusid:
              typeof val.STATUSID != "undefined" && val.STATUSID
                ? val.STATUSID
                : 0,
            contractPrice:
              typeof val["CONTRACTPRICE"] != "undefined"
                ? val["CONTRACTPRICE"]
                : undefined,
            contractPriceDate:
              typeof val["CONTRACTPRICEDATE"] != "undefined"
                ? val["CONTRACTPRICEDATE"]
                : undefined,
            orgPrice: val.QINCLUDED
              ? val.QPRICEREG_BK
              : typeof val.ORGQPRICEREG != "undefined"
              ? val.ORGQPRICEREG
              : val.QPRICEREG,
            orgCustomerPrice: val.QINCLUDED
              ? val.QCUSTOMERPRICE_BK
              : typeof val.ORGQCUSTOMERPRICE != "undefined"
              ? val.ORGQCUSTOMERPRICE
              : val.QCUSTOMERPRICE,
            // disable: (typeof val.PARENTDISABLE != "undefined" && val.PARENTDISABLE) || (val.QINCLUDED && comInstance.includedItem && !comInstance.includedPrice) ? true : false,
            disable: false,
            isContractFee:
              typeof val.ISCONTRACTFEE != "undefined" && val.ISCONTRACTFEE
                ? true
                : false,
            includedparent:
              typeof val.INCLUDEDPARENT != "undefined" ? val.INCLUDEDPARENT : 0,
            baseProductId:
              typeof val.QUOTELIID != "undefined" ? val.QUOTELIID : 0,
            includeItemCount:
              typeof val.INCLUDEITEMCOUNT != "undefined"
                ? val.INCLUDEITEMCOUNT
                : 0,
            quoteLiId: val.QUOTELIID || 0,
            quoteParentLiId: val.PARENTLIID || 0,
            CONFIGCOUNT: val.CONFIGCOUNT || 0,
            savedAsConfig: val.ISCONFIG || 0,
            ItemPLID: val.PRODUCTLINEID || 0,
            ItemPLName: val.PRODUCTLINENAME || "",
            buildHeader: val.BUILDHEADER || ""
          });
        });

        items.push({
          sku: "",
          name: "",
          price: 0.0,
          included: 0,
          ISCONFIG: 0,
          categoryType: 0,
          customerPrice: 0.0,
          margin: 0.0,
          marginRate: comInstance.getConfirmedGlobalMargin(true),
          total: 0.0,
          quantity: 0,
          taxRate: comInstance.currentTaxRate,
          tax: 0.0,
          shipping: 0.0,
          priceWarn: false,
          noTax: false,
          ItemCategory: 0,
          ItemCategoryName: "",
          isFee: false,
          dynamicInfo: {},
          statusid: 0,
          contractPrice: undefined,
          contractPriceDate: undefined,
          orgPrice: 0.0,
          orgCustomerPrice: 0.0,
          disable: false,
          isContractFee: false,
          includedparent: 0,
          baseProductId: 0,
          includeItemCount: 0,
          quoteLiId: 0,
          quoteParentLiId: 0,
          ItemPLID: 0,
          ItemPLName: "",
          buildHeader: ""
        });

        if (comInstance.isDynamic()) {
          orderFormLineItemList.autoName = 1;
          orderFormLineItemList.autoCost = 1;
          $(
            ".switch.auto input[type=checkbox].autoName, .switch.auto input[type=checkbox].autoCost"
          ).prop("checked", true);
          comInstance.DistyTotal =
            typeof response.data.DistyTotal != "undefined"
              ? response.data.DistyTotal
              : {};
        }

        orderFormLineItemList.setRows(items, response.data);
        orderFormLineItemList.$emit("summaryChange", comInstance.summary);

        if (comInstance.aID > 0) {
          // eslint-disable-next-line require-atomic-updates
          await comInstance.getCustomerAddresses(comInstance.aID, true);
          await comInstance.updateAddressForCustomer(comInstance.aID);
        }

        this.setSelectedAddr(quoteParams);
      } catch (e) {
        //handle error
        console.log(e);
      } finally {
        // eslint-disable-next-line require-atomic-updates
        comInstance.loading = false;
      }
      // });

      // sync custom data from quote to order
      try {
        // get custom data of quote
        const result = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Helpers",
          FunctionName: "CustomData",
          DataType: "2",
          Id: quote
        });
        if (result.data.STATUS == 1) {
          const quoteCustomFields = result.data.CUSTOMDEFINITION || [];
          const quoteCustomData = result.data.CUSTOMDATA || [];
          for (const item of quoteCustomFields) {
            const customData = quoteCustomData.find(
              t => t.CUSTOMFIELDID == item.CUSTOMFIELDID
            );
            if (!customData) continue;

            // check related field in order by field name
            const orderRelatedField = this.fullCustomData.find(
              (t: any) =>
                t.CUSTOMFIELDNAME.toLowerCase() ==
                item.CUSTOMFIELDNAME.toLowerCase()
            );
            if (!orderRelatedField) continue;

            orderRelatedField.CUSTOMVALUE = customData.CUSTOMVALUE;
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      container.items = { QDESCRIPTION: "" };
    }
  }

  getLineItemTax(total, taxRate) {
    return parseFloat((total * (taxRate / 100)).toFixed(2));
  }
  getQuoteData(value, getAll = 0) {
    const self: any = this;
    if (value.length >= 1) {
      const accObj = {};
      accObj["controller"] = "Quotes";
      accObj["FunctionName"] = "List";
      accObj["search"] = value;
      accObj["getAll"] = getAll;
      accObj["getAllQuotes"] = this.$route.name;
      const accData = getRouteData(accObj);
      accData.then(function(response, statusText, jqXHR) {
        const responseData = response.data || {};
        if (responseData.STATUS == 1) {
          if (self.quoteID == value) {
            self.suggestedQuotes = response.data.QUOTES;
          } else {
            console.log("ignore old api response", self.quoteID, value);
          }
        }
      });
    } else {
      self.suggestedQuotes = [];
    }
    // var element;
    // if (value.length >= 1) {
    //   element = document.getElementById("autoSuggestQuote");
    //   element!.classList.remove("hide");
    // } else {
    //   element = document.getElementById("autoSuggestQuote");
    //   element!.classList.add("hide");
    // }
  }
  backToDetails() {
    this.$router.push({
      name: "ViewOrder",
      params: { id: this.$route.params.id }
    });
  }
  showOrderFileUpload() {
    $("#orderFileUpload").click();
  }
  handleOrderFileUpload(e) {
    var comInstance = this;
    e.stopPropagation();
    e.preventDefault();

    var filePath = e.target.files[0].name;
    var allowedExtensions = /(\.html|\.pdf|\.csv|\.xls|\.xlsx)$/i;
    var isCSV = filePath.indexOf(".csv") != -1 ? true : false;
    if (!allowedExtensions.exec(filePath)) {
      this.errorMsg =
        "Please upload file having extensions .html/.pdf/.csv/.xls/.xlsx only.";
      $("#errorMsgLine").html(this.errorMsg);
      return false;
    } else {
      //upload file, and get returned json
      // comInstance.loading = true;
      comInstance.uploading = true;
      var processedFileUpload = getImageRouteData({
        Controller: "Orders",
        FunctionName: "Add",
        orderfile: e.target.files[0],
        uploadedFileName: e.target.files[0].name,
        fileType:
          filePath.indexOf(".html") != -1
            ? "html"
            : filePath.indexOf(".csv") != -1
            ? "csv"
            : filePath.indexOf(".xls") != -1
            ? "xls"
            : "pdf",
        source:
          typeof comInstance.sourceID != "undefined" ? comInstance.sourceID : 1,
        currPage: "order"
      })
        .then(async function(response, statusText, jqXHR) {
          if (response.data.STATUS) {
            $("#errorMsgLine").html("");
            var retData = response.data.DATA;
            //reset
            //$("#list-content-container").html("");
            comInstance.length = 0;
            // comInstance.$forceUpdate();

            var profitTotal = 0.0;
            var qTax = 0.0;
            var qShip = 0.0;
            let totalCost = 0;
            let totalAllItem = 0.0;
            var usedCustomTax = response.data.EstimatedTax ? true : false;
            comInstance.uploadedFileName = response.data.UPLOADEDFILENAME;
            // let items = {};
            const items: {
              sku: string;
              name: string;
              price: number;
              customerPrice: number;
              margin: number;
              marginRate: number;
              total: number;
              quantity: number;
              taxRate: number;
              tax: number;
              shipping: number;
              included: number;
              priceWarn: boolean;
              totalProducts: number;
              configID: any;
              noTax: boolean;
              ItemCategory: number;
              ItemCategoryName: string;
              isFee: boolean;
              dynamicInfo: any;
              statusid: number;
              contractPrice: any;
              contractPriceDate: Date | undefined;
              orgPrice: number;
              orgCustomerPrice: number;
              disable: boolean;
              isContractFee: boolean;
              includedparent: number;
              baseProductId: any;
              includeItemCount: number;
              ISCONFIG?: number;
              categoryType: number;
              ItemPLID: number;
              ItemPLName: string;
              buildHeader: string;
            }[] = [];

            $.each(retData, function(i, val) {
              var tmpName =
                typeof val.Desc != "undefined" ? val.Desc : val.Name;
              var tmpPrice =
                typeof val.Price != "undefined" ? val.Price : val.NetPrice;
              var tmpPrice_val = parseFloat(
                tmpPrice.toString().replace(/[$|,\s]/g, "")
              );
              if (val.Qty < 0 || !$.isNumeric(val.Qty)) val.Qty = 0;
              var tmpQty_val = parseFloat(
                val.Qty.toString().replace(/[$|,\s]/g, "")
              );
              var tmpIncluded = val.isIncluded || 0;
              var tmpTotal = parseFloat(
                (tmpPrice * val.Qty).toFixed(comInstance.$decimals)
              );
              let tmpIsConfig =
                tmpIncluded &&
                ((tmpPrice || 0) == 0 ||
                  (val.configID || "") != "" ||
                  (val.isConfig || 0))
                  ? 1
                  : 0;
              let tmpQty = (val.Qty.toString() != ""
                ? val.Qty.toString()
                : 0
              ).replace(/[$|,\s]/g, "");
              let tmpCostPer =
                typeof val.NetPrice != "undefined"
                  ? parseFloat(val.NetPrice.toString().replace(/[$|,\s]/g, ""))
                  : 0;

              if (!isNaN(tmpPrice_val) && !isNaN(tmpQty_val)) {
                // let tmpCostPer = (tmpPrice != "" ? parseFloat(tmpPrice.toString().replace(/[$|,\s]/g, "")) : 0);
                let tmpMargin = 0;
                if (isCSV) {
                  // tmpCostPer = (typeof val.NetPrice != "undefined" ? parseFloat(val.NetPrice.toString().replace(/[$|,\s]/g, "")) : 0);
                  tmpMargin = parseFloat(
                    (tmpPrice - tmpCostPer).toFixed(comInstance.$decimalsView)
                  );
                  profitTotal += tmpMargin * tmpQty_val;

                  // auto toggle config if cost = price = 0
                  if (tmpIncluded && (tmpCostPer == 0 && tmpPrice_val == 0)) {
                    tmpIsConfig = 1;
                  }
                }
                let tmpCustomerPrice =
                  tmpPrice != ""
                    ? parseFloat(tmpPrice.toString().replace(/[$|,\s]/g, ""))
                    : 0;
                let tmpInd = i + 1;

                // override for config sku
                if (
                  (val.configID || "").trim() != "" &&
                  val.configID.trim() == val.SKU.trim() &&
                  (val.isIncluded || 0) == 0
                ) {
                  tmpCostPer = 0;
                  tmpCustomerPrice = 0;
                  tmpMargin = 0;
                  tmpIsConfig = 0;
                  tmpTotal = 0;
                } else if (
                  (val.configID || "").trim() != "" &&
                  (val.isIncluded || 0)
                ) {
                  // base quantity
                  const tmpBase = retData.find(
                    item =>
                      (item.SKU || "").trim() == (val.configID || "").trim()
                  );
                  if (tmpBase) {
                    tmpQty = tmpBase.Qty || 0;
                    tmpTotal = parseFloat(
                      (tmpPrice * tmpQty).toFixed(comInstance.$decimals)
                    );
                  }
                }

                items.push({
                  sku: val.SKU.toString().replace(/[^a-zA-Z0-9#-]/gi, ""),
                  name: tmpName.toString(),
                  price: tmpCostPer,
                  customerPrice: tmpCustomerPrice,
                  margin: tmpMargin,
                  marginRate: comInstance.editGetMarginRate(
                    { QMARGIN: tmpMargin, QPRICEREG_BK: tmpCustomerPrice },
                    "quote"
                  ),
                  // total: tmpTotal.toFixed(comInstance.$decimals).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                  total: tmpTotal,
                  quantity: tmpQty,
                  taxRate:
                    typeof val.taxRate != "undefined" && val.taxRate != ""
                      ? val.taxRate
                      : 0,
                  tax:
                    typeof val.tax != "undefined" && val.tax != ""
                      ? val.tax
                      : 0,
                  shipping:
                    typeof val.shipping != "undefined" && val.shipping != ""
                      ? val.shipping
                      : 0,
                  included: tmpIncluded,
                  priceWarn: false,
                  totalProducts: retData.length,
                  configID:
                    typeof val.configID != "undefined" ? val.configID : "",
                  noTax: false,
                  ItemCategory: tmpIncluded
                    ? comInstance.configCategory.CATEGORYID || 0
                    : 0,
                  ItemCategoryName: tmpIncluded
                    ? comInstance.configCategory.CATEGORYNAME || ""
                    : "",
                  isFee: false,
                  dynamicInfo: {},
                  statusid: 0,
                  contractPrice:
                    typeof val["contractPrice"] != undefined
                      ? val["contractPrice"]
                      : undefined,
                  contractPriceDate:
                    typeof val["contractPriceDate"] != undefined
                      ? val["contractPriceDate"]
                      : undefined,
                  orgPrice: tmpCostPer,
                  orgCustomerPrice: tmpCustomerPrice,
                  disable: false,
                  isContractFee: false,
                  includedparent: 0,
                  baseProductId: 0,
                  includeItemCount: 0,
                  ISCONFIG: tmpIsConfig,
                  categoryType: val.CATEGORYTYPE || 0,
                  ItemPLID: 0,
                  ItemPLName: "",
                  buildHeader: val.header || ""
                });
                var tax =
                  typeof val.tax != "undefined" && val.tax != "" ? val.tax : 0;
                var shipping =
                  typeof val.shipping != "undefined" && val.shipping != ""
                    ? val.shipping
                    : 0;

                // if (tmpIncluded != 1) {
                totalAllItem =
                  totalAllItem + parseFloat((tmpPrice * val.Qty).toString());
                // }
                totalCost += parseFloat(
                  (tmpCostPer * tmpQty).toFixed(comInstance.$decimalsView)
                );

                //apply global tax for line item
                if (items[i].taxRate == 0 && !usedCustomTax) {
                  let tmpTaxRate = comInstance.getTaxRateBySource();
                  items[i].taxRate = tmpTaxRate;
                  items[i].tax = parseFloat(
                    ((tmpTotal * tmpTaxRate) / 100).toFixed(
                      comInstance.$decimalsView
                    )
                  );
                  tax = items[i].tax;
                }

                qTax = qTax + parseFloat(tax);
                qShip = qShip + parseFloat(shipping);
              }
            });

            items.push({
              sku: "",
              name: "",
              price: 0.0,
              included: 0,
              customerPrice: 0.0,
              margin: 0.0,
              marginRate: 0,
              total: 0.0,
              quantity: 0,
              taxRate: comInstance.getTaxRateBySource(),
              tax: 0.0,
              shipping: 0.0,
              priceWarn: false,
              totalProducts: 0,
              configID: "",
              noTax: false,
              ItemCategory: 0,
              ItemCategoryName: "",
              isFee: false,
              dynamicInfo: {},
              statusid: 0,
              contractPrice: undefined,
              contractPriceDate: undefined,
              orgPrice: 0.0,
              orgCustomerPrice: 0.0,
              disable: false,
              isContractFee: false,
              includedparent: 0,
              baseProductId: 0,
              categoryType: 0,
              includeItemCount: 0,
              ItemPLID: 0,
              ItemPLName: "",
              buildHeader: ""
            });

            comInstance.itemNames = items;
            comInstance.length = Object.keys(items).length;

            // //fill values from returned header
            // if(typeof response.data.HEADER != "undefined") {
            //   let header = response.data.HEADER;
            //   if(typeof header.QuoteName != "undefined") {
            //     comInstance.quoteName = header.QuoteName;
            //   }

            //   comInstance.BDnumber = (typeof header.BDnumber != "undefined" ? header.BDnumber : "");
            //   comInstance.ContractName = (typeof header.ContractName != "undefined" ? header.ContractName : "");
            //   comInstance.Created = (typeof header.Created != "undefined" ? header.Created : "");
            //   comInstance.Expires = (typeof header.Expires != "undefined" ? header.Expires : "");
            //   comInstance.QuoteNumber = (typeof header.QuoteNumber != "undefined" ? header.QuoteNumber : "");
            //   comInstance.ContractNumber = (typeof header.ContractNumber != "undefined" ? header.ContractNumber : "");
            // }

            // comInstance.addRow(true);
            if (response.data.EstimatedTax) {
              qTax = parseFloat(response.data.EstimatedTax);
              comInstance.USECUSTOMTAX = 1; //uploaded a quote file using custom tax
            }

            // recalculate order margin
            comInstance.reCalculateGlobalMargin();

            comInstance.profit = parseFloat(
              parseFloat(profitTotal.toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&")
            );
            comInstance.itemTotal = parseFloat(
              parseFloat(totalAllItem.toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&")
            );
            comInstance.totalShipping = parseFloat(
              parseFloat(qShip.toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&")
            );
            comInstance.qShip = qShip;
            comInstance.totalTax = parseFloat(
              parseFloat(qTax.toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&")
            );
            comInstance.qTax = qTax;
            comInstance.grandTotal = parseFloat(
              parseFloat((totalAllItem + qShip + qTax).toString())
                .toFixed(comInstance.$decimalsView)
                .replace(/\d(?=(\d{3})+\.)/g, "$&")
            );
            $("#orderFileUpload").val("");
            // var totalCost = 0;
            // totalCost = comInstance.itemTotal / 2;
            //set summary
            comInstance.summary = {
              itemTotal: comInstance.itemTotal,
              totalTax: comInstance.totalTax,
              qTax: comInstance.qTax,
              totalShipping: comInstance.totalShipping,
              includeVarCost: true,
              totalCost: parseFloat(
                totalCost.toFixed(comInstance.$decimalsView)
              ),
              qShip: comInstance.qShip,
              profit: comInstance.profit,
              estPercent:
                comInstance.isDirect() && comInstance.summary.estPercent
                  ? comInstance.summary.estPercent
                  : 0,
              grandTotal: comInstance.grandTotal
            };

            // check category
            const skus = [
              ...new Set(items.map(item => item.sku).filter(sku => sku != ""))
            ];
            if (skus.length) {
              const responseSKU = await axios.post(
                dataURL + "?ReturnType=JSON",
                {
                  controller: "Quotes",
                  FunctionName: "quoteLineItemList",
                  search: skus,
                  source: comInstance.sourceID,
                  contractNumberValue: comInstance.selectedOrderContracts,
                  checkCategory: true
                }
              );
              if (responseSKU.data.STATUS == 1) {
                const lineItems = responseSKU.data.LINEITEMS || [];
                for (const item of items) {
                  if (item.included) continue;
                  const inList = lineItems.find(t => t.PRODUCTSKU == item.sku);
                  if (inList) {
                    item.ItemCategory = inList.DEFAULTCATEGORYID || 0;
                    item.ItemCategoryName = inList.CATEGORYNAME || "";
                    item.ItemPLID = inList.PRODUCTLINEID || 0;
                    item.ItemPLName = inList.PRODUCTLINENAME || "";
                  }
                }
              }
            }

            // turn off "Price Roll-up"
            if (comInstance.includedPrice) {
              comInstance.autoIncludePriceSwitch();
            }

            comInstance.$refs.orderFormLineItemList.setRows(items, "");
            comInstance.$refs.orderFormLineItemList.$emit(
              "summaryChange",
              comInstance.summary
            );
            // comInstance.$refs.orderFormLineItemList.setIncludedItemPrice();
          } else if (response.data.ERROR) {
            $("#errorMsgLine").html(response.data.STATUSMESSAGE);
          } else {
            if (response.data.STATUS == 0) {
              var downloadLink =
                "<a href='https://var360.s3.amazonaws.com/spreadsheets/OrderUploadTemplate.csv' style='color:blue; cursor: pointer' target='_blank'>here</a>";
              var messageError = response.data.STATUSMESSAGE;
              messageError +=
                ". If don't have standard file, please download sample template " +
                downloadLink;
              $("#errorMsgLine").html(messageError);
              $("#errorMsgLine").attr("style", "position: relative;");
              // console.log("messageError:",messageError)
            } else {
              $("#errorMsgLine").html(response.data.STATUSMESSAGE);
            }
          }

          // eslint-disable-next-line require-atomic-updates
          comInstance.uploading = false;
          await comInstance.$nextTick();
          await wait(100);

          comInstance.toggleSelectedContractNumber(
            +comInstance.orderContractsID.join(),
            comInstance.selectedOrderContracts,
            true
          );

          // comInstance.loading = false;
          // eslint-disable-next-line require-atomic-updates
        })
        .catch(function(error) {
          $("#errorMsgLine").html(error);
          // comInstance.loading = false;
          // eslint-disable-next-line require-atomic-updates
          comInstance.uploading = "error";
        });
    }
  }
  reCalculateGlobalMargin(itemNames: any = null) {
    let items = itemNames ? itemNames : this.itemNames;
    if (this.isIndirect()) {
      //this.confirmedGlobalMargin === true
      var totalCustomerPrice = 0;
      // var totalCostPer = 0;
      var totalMargin = 0;
      for (var i in items) {
        let item = items[i];
        if (!isNaN(item.customerPrice) && !isNaN(item.quantity)) {
          totalCustomerPrice += parseFloat(
            (item.customerPrice * item.quantity).toFixed(this.$decimals)
          );
        }
        // if(!isNaN(item.price) && !isNaN(item.quantity) && !item.included) {
        //   totalCostPer += parseFloat((item.price * item.quantity).toFixed(this.$decimals));
        // }
        if (!isNaN(item.margin) && !isNaN(item.quantity)) {
          totalMargin += parseFloat((item.margin * item.quantity).toFixed(2));
          // let tmpMargin = ((item.customerPrice * item.marginRate) / 100) * item.quantity;
          // let tmpMargin = ((item.price * item.marginRate) / 100) * item.quantity;
          // if(isNaN(tmpMargin)) {
          //   tmpMargin = 0;
          // }
          // totalMargin += parseFloat(tmpMargin.toFixed(this.$decimals));
        }
      }

      if (totalCustomerPrice != 0) {
        //totalMargin = parseFloat(totalMargin.toFixed(this.$decimals));
        // this.globalMargin = parseFloat((totalMargin / totalCustomerPrice * 100).toFixed(this.$decimalsView));
        this.globalMargin = parseFloat(
          ((totalMargin / totalCustomerPrice) * 100).toFixed(this.$decimalsView)
        );
      } else {
        this.globalMargin = 0;
      }
      this.globalMarginBK = this.globalMargin;
    }
  }
  async editOrder(id, dupOrder) {
    this.title = "Update Order #" + id;
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "View",
        Content: "Detailed",
        inEditPage: true,
        purchaseID: id,
        duplicateOrder: dupOrder
      });

      if (response.data.STATUS == 1) {
        const orderFormLineItemList: any = this.$refs.orderFormLineItemList;
        this.orderParams = response.data.orderParams || {};
        var orderDetails = response.data.ORDERDETAILS;
        this.splitBySubOrderId = response.data.splitBySubOrderId || [];
        //fill Purchase Order ID
        this.orderID = orderDetails.POID;
        if (typeof response.data.orderParams.customSourceName != "undefined") {
          this.sourceName = response.data.orderParams.customSourceName;
        }

        //fill order name
        this.orderName = orderDetails.PODESCRIPTION;

        //fill customer
        this.customer = orderDetails.AACCOUNTNAME;
        this.aID = orderDetails.AID;
        this.accountID = orderDetails.AACCOUNTID;
        this.arrowCounter = 0;
        this.contactEmail =
          response.data.orderParams.contactEmail &&
          response.data.orderParams.contactEmail.length
            ? response.data.orderParams.contactEmail
                .split(",")
                .map(id => parseInt(id))
            : [];
        this.expandDecimal = response.data.orderParams.expandDecimal || false;
        if (this.expandDecimal) {
          this.expandDecimalPrecision = 5;
        }
        this.orderDate = this.duplicateOrder
          ? this.orderDate
          : orderDetails.PODATETIMEFORMATTED || this.orderDate;
        this.includedItem = response.data.orderParams.includedItem || false;
        this.includedPrice =
          typeof response.data.orderParams.includedPrice != "undefined"
            ? response.data.orderParams.includedPrice
            : true;
        if (
          typeof response.data.orderParams.DefaultShippingID != "undefined" &&
          response.data.orderParams.DefaultShippingID != ""
        ) {
          this.selectedAddress = [
            parseInt(response.data.orderParams.DefaultShippingID)
          ];
        }
        if (
          typeof this.orderParams.onetimeAddress != "undefined" &&
          Object.keys(this.orderParams.onetimeAddress).length
        ) {
          // used one time address for this order
          this.selectedAddress = [0];
          this.onetimeAddress = Object.assign(
            this.onetimeAddress,
            this.orderParams.onetimeAddress
          );
        }

        if (
          typeof response.data.orderParams.DefaultBillingID != "undefined" &&
          response.data.orderParams.DefaultBillingID != ""
        ) {
          this.selectedBillingAddress = [
            parseInt(response.data.orderParams.DefaultBillingID)
          ];
        }
        if (
          typeof this.orderParams.onetimeAddressBilling != "undefined" &&
          Object.keys(this.orderParams.onetimeAddressBilling).length
        ) {
          // used one time address for this order
          this.selectedBillingAddress = [0];
          this.onetimeAddressBilling = Object.assign(
            this.onetimeAddressBilling,
            this.orderParams.onetimeAddressBilling
          );
        }
        if (
          typeof this.orderParams.onetimeDepartment != "undefined" &&
          Object.keys(this.orderParams.onetimeDepartment).length
        ) {
          // used one time dept for this order
          this.selectedDepartment = [0];
          this.onetimeDepartment = Object.assign(
            this.onetimeDepartment,
            this.orderParams.onetimeDepartment
          );
        }

        //Fill customer department
        if (
          typeof response.data.orderParams.departmentDetails != "undefined" &&
          response.data.orderParams.departmentDetails != ""
        ) {
          this.selectedDepartment = [
            parseInt(response.data.orderParams.departmentDetails.ID)
          ];
          this.selectedDepartmentName =
            response.data.orderParams.departmentDetails.NAME;
        }

        //fill source
        this.sourceID = orderDetails.SOURCE;
        this.source = orderDetails.VARSOURCE_NAME || "";

        //fill quote
        this.quoteID = orderDetails.QUOTEID;
        this.quoteName = orderDetails.QUOTENAME;
        this.USECUSTOMTAX =
          response.data.USECUSTOMTAX != "undefined" &&
          response.data.USECUSTOMTAX == 1
            ? 1
            : 0;
        if (
          orderDetails.GLOBALMARGIN != "undefined" &&
          orderDetails.GLOBALMARGIN != 0
        ) {
          this.globalMargin = orderDetails.GLOBALMARGIN;
          this.globalMarginBK = orderDetails.GLOBALMARGIN;
          // this.confirmedGlobalMargin = true;
        }

        this.hpDirectTaxRate =
          typeof orderDetails.HPDIRECTTAXRATE != "undefined"
            ? orderDetails.HPDIRECTTAXRATE
            : 0;
        this.indirectTaxRate =
          typeof orderDetails.INDIRECTTAXRATE != "undefined"
            ? orderDetails.INDIRECTTAXRATE
            : 0;
        this.currentTaxRate = this.getTaxRateBySource();

        // override current tax rate if user used an override value before
        if (
          typeof orderDetails.POTAXRATE != "undefined" &&
          orderDetails.POTAXRATE !== ""
        ) {
          this.currentTaxRate = orderDetails.POTAXRATE;
        }

        // fill end user info
        // this.euName = orderDetails.EUNAME || "";
        // this.euPhone = orderDetails.EUPHONE || "";
        // this.euPhoneText = orderDetails.EUPHONEEXT || "";
        // this.euEmail = orderDetails.EUEMAIL || "";
        this.euID = orderDetails.ENDUSERID || 0;
        if (
          typeof response.data.orderParams.euId != "undefined" &&
          response.data.orderParams.euId.length
        ) {
          this.selectedEndUsers = [parseInt(response.data.orderParams.euId)];
        }
        this.fundingSourceName =
          typeof response.data.FUNDINGSOURCENAME != "undefined"
            ? response.data.FUNDINGSOURCENAME
            : "";
        this.fundingSourceId =
          typeof response.data.FUNDINGSOURCEID != "undefined"
            ? response.data.FUNDINGSOURCEID
            : 0;
        this.fundingSourceTerms = response.data.FUNDINGSOURCETERMS || "";
        if (response.data.FUNDINGSOURCETERMSBYORDER || "") {
          this.fundingSourceTerms = response.data.FUNDINGSOURCETERMSBYORDER;
        }

        this.selectedBusinessLine = orderDetails.BUSINESSLINEID
          ? [orderDetails.BUSINESSLINEID]
          : [];

        //detect global tag
        // if(typeof response.data.CUSTOMDATA != "undefined" && response.data.CUSTOMDATA.length) {
        //   let savedGlobalTag = response.data.CUSTOMDATA.filter(val => val.CUSTOMFIELDID == 8);
        //   if(savedGlobalTag.length) {
        //     this.globalTag = savedGlobalTag[0].CUSTOMVALUE;
        //     this.customID = savedGlobalTag[0].CUSTOMID;
        //   }
        // }

        //fill line items
        var items = response.data.ORDERITEMS.map(val => ({
          poLiID: val.POLIID,
          sku: val.POPRODSKU,
          name: val.POPRODDESC,
          price: val.POPRICEREG,
          customerPrice: val.POINCLUDED
            ? val.ITEMCUSTOMERPRICE
            : val.POCUSTOMERPRICE,
          margin: val.POMARGIN,
          marginRate: this.editGetMarginRate(val, "order"),
          total: val.LICUSTOMERTOTALPRICE,
          // quantity: (val.POINCLUDED ? val.ITEMQUANTITY : (val.POPRODQUANTITY != "" ? val.POPRODQUANTITY : 0)),
          quantity: val.POPRODQUANTITY || 0,
          tax: val.POTAX,
          taxRate: val.POTAXRATE,
          shipping: val.POSHIPPING,
          included: val.POINCLUDED,
          ISCONFIG: val.ISCONFIG || 0,
          categoryType: val.CATEGORYTYPE || 0,
          priceWarn: false,
          noTax: typeof val.NOTAX != "undefined" && val.NOTAX ? true : false,
          DistyPrice: typeof val.DistyPrice != "undefined" ? val.DistyPrice : 0,
          DistyAvailability:
            typeof val.DistyAvailability != "undefined"
              ? val.DistyAvailability
              : 0,
          DistyPrice_Format:
            typeof val.DistyPrice_Format != "undefined"
              ? val.DistyPrice_Format
              : "",
          DistyAvailability_Format:
            typeof val.DistyAvailability_Format != "undefined"
              ? val.DistyAvailability_Format
              : "",
          Disty: typeof val.Disty != "undefined" ? val.Disty : "",
          ItemCategory:
            typeof val.POCATEGORY != "undefined" ? val.POCATEGORY : 0,
          ItemCategoryName:
            typeof val.CATEGORYNAME != "undefined" ? val.CATEGORYNAME : "",
          isFee: this.checkItemIsFee(val),
          dynamicInfo: val,
          statusid: val.STATUSID,
          contractPrice:
            typeof val.CONTRACTPRICE != "undefined"
              ? val.CONTRACTPRICE
              : undefined,
          contractPriceDate:
            typeof val.CONTRACTPRICEDATE != "undefined"
              ? val.CONTRACTPRICEDATE
              : undefined,
          orgPrice:
            typeof val.ORGPRICEREG != "undefined"
              ? val.ORGPRICEREG
              : val.POPRICEREG,
          orgCustomerPrice: val.POINCLUDED
            ? val.ITEMCUSTOMERPRICE
            : typeof val.ORGCUSTOMERPRICE != "undefined"
            ? val.ORGCUSTOMERPRICE
            : val.POCUSTOMERPRICE,
          // disable: (typeof val.PARENTDISABLE != "undefined" && val.PARENTDISABLE) ? true : false,
          disable: false,
          isContractFee:
            val.POPRODSKU.toLowerCase() == "contractfee" &&
            !val.POCUSTOMERPRICE &&
            !val.POINCLUDED
              ? true
              : false,
          includedparent: val.PARENTLIID || 0,
          baseProductId: val.POLIID || 0,
          includeItemCount: 0,
          parentLiId: val.PARENTLIID || 0,
          subOrderId: val.SUBORDERID || 0,
          CONFIGCOUNT: val.CONFIGCOUNT || 0,
          savedAsConfig: val.ISCONFIG || 0,
          ItemPLID: val.PRODUCTLINEID || 0,
          ItemPLName: val.PRODUCTLINENAME || "",
          buildHeader: val.BUILDHEADER || ""
        }));
        items.push({
          poLiID: 0,
          sku: "",
          name: "",
          price: "",
          included: 0,
          ISCONFIG: 0,
          categoryType: 0,
          customerPrice: "",
          margin: "",
          marginRate: this.getConfirmedGlobalMargin(true),
          total: "",
          quantity: 0,
          taxRate: this.USECUSTOMTAX ? 0 : this.currentTaxRate,
          tax: "",
          shipping: "",
          priceWarn: false,
          noTax: false,
          DistyPrice: 0,
          DistyAvailability: 0,
          DistyPrice_Format: "",
          DistyAvailability_Format: "",
          Disty: "",
          ItemCategory: 0,
          ItemCategoryName: "",
          isFee: false,
          statusid: 0,
          contractPrice: undefined,
          contractPriceDate: undefined,
          orgPrice: "",
          orgCustomerPrice: "",
          disable: false,
          isContractFee: false,
          includedparent: 0,
          baseProductId: 0,
          includeItemCount: 0,
          parentLiId: 0,
          ItemPLID: 0,
          ItemPLName: "",
          buildHeader: ""
        });

        if (this.isDynamic()) {
          this.$refs.orderFormLineItemList.autoName = 1;
          this.$refs.orderFormLineItemList.autoCost = 1;
          $(
            ".switch.auto input[type=checkbox].autoName, .switch.auto input[type=checkbox].autoCost"
          ).prop("checked", true);
          this.DistyTotal =
            typeof response.data.DistyTotal != "undefined"
              ? response.data.DistyTotal
              : {};
        }
        this.$refs.orderFormLineItemList.setRows(items, "");

        //fill summary
        this.$refs.orderFormLineItemList.$emit("summaryChange", {
          grandTotal: response.data.GRANDTOTAL,
          itemTotal:
            response.data.duplicateorder == true
              ? response.data.custTotalDupOrder
              : orderDetails.CUSTOMERTOTALPRICE,
          totalCost:
            response.data.duplicateorder == true
              ? response.data.VarCostDupOrder
              : orderDetails.TOTALPRICE,
          profit: response.data.MARGIN,
          estPercent: orderDetails.ESTPERCENT,
          totalShipping: orderDetails.POSHIP,
          includeVarCost:
            response.data.orderParams.includeVarCost != undefined
              ? response.data.orderParams.includeVarCost
              : true,
          totalTax:
            response.data.duplicateorder == true
              ? response.data.totalTaxDupOrder
              : orderDetails.POTAX
        });

        // recalculate profit/order margin if duplicate an order has fees
        if (this.duplicateOrder && (orderDetails.TOTALFEE || 0) > 0) {
          orderFormLineItemList.updateSummary();
          orderFormLineItemList.reCalculateGlobalMargin();
        }

        if (this.aID > 0) {
          await this.getCustomerAddresses(this.aID);
          //Get customer department details
          await this.getCustomerDepartment(this.aID);
        }
        // To list the selected address on the top
        if (this.customerAddresses.length) {
          this.sortingAddresses(this.customerAddresses, this.selectedAddress);
        }
        if (this.customerAddressesBilling.length) {
          this.sortingAddresses(
            this.customerAddressesBilling,
            this.selectedBillingAddress
          );
        }
        // To list the selected contacts on the top
        if (this.endUsers.length) {
          this.sortingAddresses(this.endUsers, this.selectedEndUsers);
        }
        if (this.invoiceMail.length) {
          this.sortingAddresses(this.invoiceMail, this.contactEmail);
        }

        // To get opportunity
        this.opportunityID =
          typeof this.orderParams.opportunityID != "undefined"
            ? this.orderParams.opportunityID
            : 0;
        this.opportunityPrevID =
          typeof this.orderParams.opportunityID != "undefined"
            ? this.orderParams.opportunityID
            : 0;
        this.opportunityName =
          typeof this.orderParams.opportunityID != "undefined" &&
          this.orderParams.opportunityID
            ? `MOP-${this.orderParams.opportunityID}`
            : "";
      }
    } catch (err) {
      //handle error
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  editGetMarginRate(item, type) {
    var tmpRate =
      typeof item.MARGINRATE != "undefined" && item.MARGINRATE != ""
        ? item.MARGINRATE
        : 0;
    if (type == "order") {
      if (
        tmpRate == 0 &&
        item.POMARGIN != 0 &&
        item.POMARGIN != "" &&
        item.PRICEREG_BK != 0
      ) {
        tmpRate = (item.POMARGIN / item.PRICEREG_BK) * 100;
      }
    } else if (type == "quote") {
      if (
        tmpRate == 0 &&
        item.QMARGIN != 0 &&
        item.QMARGIN != "" &&
        item.QPRICEREG_BK != 0
      ) {
        tmpRate = (item.QMARGIN / item.QPRICEREG_BK) * 100;
      }
    }
    // if(tmpRate.toString().indexOf(".") != -1 && tmpRate.toString().split(".")[1].length > 4) {
    //   tmpRate = parseFloat(tmpRate.toFixed(4));
    // }
    tmpRate = parseFloat(tmpRate.toFixed(this.$decimals));

    return tmpRate;
  }

  // async getDetailsForUnverify(customerPO) {
  //   try {
  //     this.loading = true;
  //     var filteredSource = (typeof this.$route.query.importSource != "undefined" ? this.$route.query.importSource : 0);
  // 		const response = await axios.post(dataURL + "?ReturnType=JSON", {
  // 			controller				: "Orders",
  //       FunctionName			: "UnverifiedView",
  //       CustomerPO        : customerPO,
  //       filteredSource    : filteredSource
  // 		});

  // 		if (response.data.ERROR) {
  //       throw new Error(response.data.ERROR);
  // 		}

  //     if(response.data.STATUS == 1) {
  //       const orderFormLineItemList = this.$refs.orderFormLineItemList;
  //       let details = response.data.ORDERDETAILS;
  //       this.orderID = details.CUSTOMERPO;
  //       this.aID = details.AID;
  //       this.customer = details.ANAME;
  //       const items: any = [];
  //       for(var val of response.data.ORDERITEMS) {
  //         items.push({
  //           sku: val.PRODUCTNUMBER,
  //           name: val.PRODUCTDESCRIPTION,
  //           price: val.UNITPRICE,
  //           included: 0,
  //           customerPrice: val.UNITPRICE,
  //           margin: 0,
  //           total: (val.UNITPRICE * val.ORDEREDQUANTITY),
  //           quantity: val.ORDEREDQUANTITY,
  //           taxRate: 0,
  //           tax: 0,
  //           priceWarn: false,
  //           noTax: false
  //         });
  //       }
  //       items.push({//end row
  //         sku: "",
  //         name: "",
  //         price: 0.0,
  //         included: 0,
  //         customerPrice: 0.0,
  //         margin: 0.0,
  //         total: 0.0,
  //         quantity: 0,
  //         taxRate: 0.0,
  //         tax: 0.0,
  //         priceWarn: false,
  //         noTax: false
  //       });

  //       //set summary
  //       this.summary = {
  //         grandTotal: response.data.ORDERDETAILS.TOTAL,
  //         itemTotal: response.data.ORDERDETAILS.TOTAL,
  //         profit: 0,
  //         estPercent: 0,
  //         qShip: 0,
  //         qTax: 0,
  //         totalCost:0,
  //         totalShipping: 0,
  //         includeVarCost: true,
  //         totalTax: 0
  //       };

  //       orderFormLineItemList.setRows(items, response.data);
  //       orderFormLineItemList.$emit("summaryChange", this.summary);
  //     }
  // 	}catch (err) {
  //     //handle error
  //   }finally {
  //     this.loading = false;
  //   }
  // }

  selectedGlobalTagsID: number[] = [];
  selectedGlobalTags: object[] = [];
  toggleSelectedGlobalTags(selectedID: number, tagName) {
    const index = this.selectedGlobalTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedGlobalTagsID.push(selectedID);
      this.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      //this.saveGlobalTag();
      return;
    }

    this.selectedGlobalTagsID.splice(index, 1);
    this.$delete(this.selectedGlobalTags, index);
    //this.saveGlobalTag();
  }

  selectAllGlobalTags() {
    this.resetGlobalTags();
    this.selectedGlobalTagsID = this.globalTags.map(
      (val: any) => val.CUSTOMFIELDOPTIONID
    );
    this.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    //this.saveGlobalTag();
  }

  resetGlobalTags() {
    this.selectedGlobalTags = [];
    this.selectedGlobalTagsID = [];
    //this.saveGlobalTag();
  }

  updateGlobalTagList(type, index, tagID) {
    if (this.selectedGlobalTagsID && type == "delete") {
      this.$delete(this.selectedGlobalTagsID, index);
      this.$delete(this.selectedGlobalTags, index);
    }
    //this.saveGlobalTag();
  }

  async toggleSelectedContractNumber(
    selectedID: number,
    contractName,
    forceReload = false
  ) {
    const index = this.orderContractsID.findIndex(id => id === selectedID);
    const contractGTags = this.globalTags.filter(
      tmp => tmp.CUSTOMFIELDOPTIONNAME == contractName
    );
    if (index === -1) {
      this.orderContractsID = [selectedID];
      this.selectedOrderContracts = contractName;
      //Add Related Global Tags
      const selectedGTags = this.selectedGlobalTags.findIndex(
        (tmp: any) =>
          tmp.CUSTOMFIELDOPTIONNAME.toUpperCase() == contractName.toUpperCase()
      );
      if (selectedGTags == -1 && contractGTags.length) {
        this.selectedGlobalTagsID.push(contractGTags[0].CUSTOMFIELDOPTIONID);
        this.selectedGlobalTags.push({
          CUSTOMFIELDOPTIONID: contractGTags[0].CUSTOMFIELDOPTIONID,
          CUSTOMFIELDOPTIONNAME: contractGTags[0].CUSTOMFIELDOPTIONNAME
        });
      }
    } else {
      if (!forceReload) {
        this.orderContractsID.splice(index, 1);
        this.selectedOrderContracts = "";
        //Remove Related Global Tags
        if (contractGTags.length) {
          const tagIndex = this.selectedGlobalTagsID.findIndex(
            id => id === contractGTags[0].CUSTOMFIELDOPTIONID
          );
          this.selectedGlobalTagsID.splice(tagIndex, 1);
          this.selectedGlobalTags.splice(tagIndex, 1);
        }
      }
    }

    var orderFormLineItemList: any = this.$refs.orderFormLineItemList;
    var itemNames = orderFormLineItemList.getItemNames();
    var search: string[] = [];
    var contractFee = 0;
    const isMohave = this.selectedOrderContracts.toLowerCase() == "mohave";

    // multi end users case, and selected contract number is mohave
    if (isMohave && this.isMultiEndUsers) {
      if (this.$route.name == "OrderCreate") {
        let feeSubset = orderFormLineItemList.subsetIds.find(
          t => t.quoteId == "" && t.subQuoteId == 0
        );
        if (!feeSubset) {
          // add fee section for splitByQuoteId
          feeSubset = {
            quoteId: "",
            items: [
              {
                QUOTEID: "",
                SUBQUOTEID: 0
              }
            ]
          };
          this.splitByQuoteId.push(feeSubset);
        }
      } else {
        // edit case
        let feeSubset = orderFormLineItemList.subsetIds.find(t => t.subId == 0);
        if (!feeSubset) {
          // add fee section for splitBySubOrderId
          feeSubset = {
            subId: 0,
            subOrderId: 0,
            items: []
          };
          this.splitBySubOrderId.push(feeSubset);
        }
      }
    }

    for (var skuIndex in itemNames) {
      if (
        itemNames[skuIndex].sku != "" &&
        !search.includes(itemNames[skuIndex].sku)
      ) {
        search.push(itemNames[skuIndex].sku);
      }
      //Set contract fee
      if (
        itemNames[skuIndex].sku == "" &&
        !contractFee &&
        this.selectedOrderContracts.toLowerCase() == "mohave"
      ) {
        itemNames[skuIndex].sku = "contractfee";
        itemNames[skuIndex].name = "Mohave 1% fee";
        itemNames[skuIndex].quoteId = "";
        itemNames[skuIndex].subQuoteId = 0;
        itemNames[skuIndex].subOrderId = 0;
        contractFee++;
        orderFormLineItemList.showFeeTooltip(itemNames[skuIndex], skuIndex);
        orderFormLineItemList.addDelete(skuIndex);
      } else if (
        itemNames[skuIndex].sku == "contractfee" &&
        this.selectedOrderContracts.toLowerCase() != "mohave"
      ) {
        this.$delete(itemNames, skuIndex);
        orderFormLineItemList.addRow();
      }
    }

    if (search.length) {
      // this.loading = true;
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Quotes",
          FunctionName: "quoteLineItemList",
          search: search,
          source: this.sourceID,
          contractNumberValue: this.selectedOrderContracts,
          contractNumberId: this.orderContractsID.join()
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
          throw new Error(response.data.STATUSMESSAGE);
        }
        if (response.data.STATUS == 1) {
          for (let itemIndex in itemNames) {
            let productInfo = response.data.LINEITEMS.filter(
              val => val.PRODUCTSKU == itemNames[itemIndex].sku
            );
            if (productInfo.length) {
              productInfo = productInfo[0];
              if (
                orderFormLineItemList.autoCost == 1 ||
                orderFormLineItemList.autoName == 1
              ) {
                itemNames[itemIndex]["contractPrice"] =
                  productInfo.ContractPrice;
                itemNames[itemIndex]["contractPriceDate"] =
                  productInfo.ContractPriceDate;
              }
              if (
                orderFormLineItemList.autoName == 1 &&
                productInfo.PRODUCTNAME != ""
              ) {
                itemNames[itemIndex]["name"] = productInfo.PRODUCTNAME;
              }
            }
          }
          orderFormLineItemList.$forceUpdate();
        }
      } catch (err) {
        // console.log(err.message);
      } finally {
        // this.loading = false;
      }
    }
  }

  globalMarginInput() {
    if (this.globalMargin > 100) {
      this.globalMargin = 100;
    }
  }

  toggleConfirmGlobalMargin(e = null) {
    this.confirmedGlobalMargin = !this.confirmedGlobalMargin;
    this.$refs.orderFormLineItemList.globalMarginChanged(e, true);
  }

  isIndirect() {
    if (this.sourceID == 1 || this.sourceID == 12) {
      return false;
    }

    return true;
  }

  isDirect() {
    if (this.sourceID == 1 || this.sourceID == 12) {
      return true;
    }

    return false;
  }

  showCustomData() {
    this.customDataVisible = !this.customDataVisible;
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  applyTaxRateBySource() {
    var tmpTaxRate = this.getTaxRateBySource();
    var orderFormLineItemList: any = this.$refs.orderFormLineItemList;
    orderFormLineItemList.updateOrderTaxRate(tmpTaxRate, true);
    /*this.USECUSTOMTAX = 0;
    $.each(orderFormLineItemList.getItemNames(), function(i, val) {
      // val.taxRate = (typeof val.taxRate_bk != "undefined" ? val.taxRate_bk : tmpTaxRate);
      val.taxRate = tmpTaxRate;
      val.tax = orderFormLineItemList.getLineItemTax(val);
    });
    orderFormLineItemList.updateSummary();*/
  }

  confirmSwitchCustomer() {
    // hide confirm message 1
    this.confirmedSwitchCustomer = true; //set a confirmed flag
    this.hpDirectTaxRate = this.hpDirectTaxRateTmp;
    this.indirectTaxRate = this.indirectTaxRateTmp;
    this.customer_bk = {
      customer: this.customer,
      aID: this.aID,
      accountID: this.accountID
    };
    this.confirmSwitchCustomerModalVisible = false;

    // show confirm message 2
    this.switchCustomerMsg2 =
      "<strong>" +
      this.customer +
      "</strong> has a tax rate of " +
      this.newTaxRate +
      "%. Do you want to override the current tax of " +
      this.currentTaxRate +
      "%?";
    this.confirmSwitchTaxRateVisible = true;
  }

  closeSwitchCustomer() {
    this.confirmSwitchCustomerModalVisible = false;
    if (!this.confirmedSwitchCustomer) {
      //back to previous selected customer
      this.customer = this.customer_bk.customer;
      this.aID = this.customer_bk.aID;
      this.accountID = this.customer_bk.accountID;
    }
  }

  confirmSwitchTaxRate() {
    this.currentTaxRate = this.getTaxRateBySource();
    this.applyTaxRateBySource();
    this.confirmSwitchTaxRateVisible = false;
  }

  getTaxRateBySource() {
    var tmpTaxRate = 0;
    if (this.isIndirect() && this.indirectTaxRate > 0) {
      //indirect
      tmpTaxRate = this.indirectTaxRate;
    } else if (!this.isIndirect() && this.hpDirectTaxRate > 0) {
      //direct
      tmpTaxRate = this.hpDirectTaxRate;
    }

    return tmpTaxRate;
  }

  isEmptyLineItems() {
    var ret = true;
    var orderFormLineItemList: any = this.$refs.orderFormLineItemList;
    var itemNames = orderFormLineItemList.getItemNames();
    for (let i in itemNames) {
      let item = itemNames[i];
      if (
        item.sku != "" ||
        item.name != "" ||
        (item.price != "" && item.price != 0) ||
        (item.customerPrice != "" && item.customerPrice != 0) ||
        item.quantity > 0 ||
        (item.total != "" && item.total != 0)
      ) {
        ret = false;
        break;
      }
    }
    return ret;
  }

  checkPriceAndAvailability() {
    // const items = this.$refs.orderFormLineItemList.getItemNames();
    // const newItems = [];
    // for(const i in items) {
    //   if(items[i].sku != "") {
    //     newItems.push({
    //       POINCLUDED: items[i].included,
    //       POPRODSKU: items[i].sku,
    //       POPRODQUANTITY: items[i].quantity
    //     });
    //   }
    // }

    // // build checkSKUDetails
    // this.checkSKUDetails = {
    //   ORDERDETAILS: {
    //     SOURCE: this.sourceID
    //   },
    //   ORDERITEMS: newItems
    // };
    this.processOrderType = "checkPA";
    this.forceProcess = false;
    this.buildCheckSKUDetails(this.processOrderType);
    this.processOrderModalVisible = true;
  }

  // build checkSKUDetails
  buildCheckSKUDetails(type) {
    const items = this.$refs.orderFormLineItemList.getItemNames();
    const newItems: any = [];
    for (const i in items) {
      if (items[i].sku != "") {
        newItems.push({
          POINCLUDED: items[i].included,
          POPRODSKU: items[i].sku,
          POPRODQUANTITY: items[i].quantity
        });
      }
    }

    this.checkSKUDetails = {
      ORDERDETAILS: {
        SOURCE: this.sourceID,
        POID: this.orderID,
        QUOTEID: this.quoteID
      },
      ORDERITEMS: newItems,
      GRANDTOTALFORMATTED:
        "$" + Vue.filter("formatCurrency")(this.summary.grandTotal)
      // GRANDTOTALFORMATTED: "$" + this.$options.filters.formatCurrency(this.summary.grandTotal || 0)
    };
  }

  showProcessOrderModal(purchaseID) {
    this.processOrderType = "process";
    this.forceProcess = true;
    this.buildCheckSKUDetails(this.processOrderType);
    this.checkSKUDetails.ORDERDETAILS.PURCHASEID = purchaseID;
    this.processOrderModalVisible = true;
  }

  closeProcessOrderModal() {
    this.processOrderModalVisible = false;
    // if(this.processOrderType == "process") {
    //   if(this.quoteID != "") {
    //     // go to quote details page
    //     this.$router.push({ name: "ViewQuote", params: { id: this.quoteID } });
    //   }else {
    //     // go to orders list
    //     this.$router.push({ name: "Orders" });
    //   }
    // }
  }

  getConfirmedGlobalMargin(isLineItem = false) {
    if (this.isIndirect() && this.confirmedGlobalMargin) {
      if (isLineItem) {
        // return markup
        return this.getMarkupFromMargin(this.globalMargin);
      } else {
        // return order margin
        return this.globalMargin;
      }
    } else if (this.source == "TDSynnex") {
      return this.getMarkupFromMargin(this.globalMargin);
    }

    return 0;
  }

  checkCanProcessWithDisty() {
    if (this.sourceCredentials.length && this.sourceID != 1) {
      const thisSourceCredential = this.sourceCredentials.find(
        tmp => tmp.VARSOURCE_ID == this.sourceID
      );
      if (
        thisSourceCredential &&
        thisSourceCredential.VARSOURCECREDENTIAL_VALID == 1
      ) {
        return true;
      }
    }

    return false;
  }

  async getCustomerAddresses(aID, setDefault = false) {
    // var ret: object[] = [];
    if (aID > 0) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Queries",
          subsystem: "Helpers",
          FunctionName: "getAccountAddresses",
          aID: aID,
          addressType: "1,2"
        });
        if (response.data.STATUS) {
          // setting for auto tax when change shipping/billing address based on account setting
          this.autoTaxSetting.shippingAutoTax =
            response.data.shippingAutoTax || 0;
          this.autoTaxSetting.billingAutoTax =
            response.data.billingAutoTax || 0;
          this.accountGenerateHardware =
            parseInt(response.data.generateHardware) || 0;

          const shippingAddr = response.data.addressArr.filter(
            tmp => tmp.ACCOUNTSADDRESS_TYPE == 1
          );
          const billingAddr = response.data.addressArr.filter(
            tmp => tmp.ACCOUNTSADDRESS_TYPE == 2
          );
          this.customerAddresses = shippingAddr.map(tmp => ({
            ID: tmp.ACCOUNTSADDRESS_ID,
            TEXT: this.getAddressText(tmp),
            data: tmp
          }));
          this.customerAddressesBilling = billingAddr.map(tmp => ({
            ID: tmp.ACCOUNTSADDRESS_ID,
            TEXT: this.getAddressText(tmp),
            data: tmp
          }));
        }
        // option to add a permanent address, and one time using
        // ret.push({
        //   ID: "permanent",
        //   TEXT: "Add new shipping address"
        // });
        this.customerAddresses.push({
          ID: "onetime",
          TEXT: "Enter a shipping address"
        });
        this.customerAddressesBilling.push({
          ID: "onetime",
          TEXT: "Enter a billing address"
        });

        if (Object.keys(this.onetimeAddress).length) {
          this.customerAddresses.unshift({
            ID: 0,
            TEXT: this.getAddressText(this.onetimeAddress)
          });
        }
        if (Object.keys(this.onetimeAddressBilling).length) {
          this.customerAddressesBilling.unshift({
            ID: 0,
            TEXT: this.getAddressText(this.onetimeAddressBilling)
          });
        }
        const endUserArr = response.data.contactListArr.filter(
          tmp => tmp.ACONTACTTYPE == 1 || tmp.ACONTACTTYPE == 3 || tmp.UTYPE
        );
        const invoiceMailArr = response.data.contactListArr.filter(
          tmp => tmp.ACONTACTTYPE == 2 || tmp.ACONTACTTYPE == 3
        );
        const defaultEndUserItem = response.data.contactListArr.find(
          tmp =>
            (tmp.ACONTACTTYPE == 1 || tmp.ACONTACTTYPE == 3) && tmp.ISDEFAULT
        );
        const defaultInvoiceMailItem = response.data.contactListArr.find(
          tmp =>
            (tmp.ACONTACTTYPE == 2 || tmp.ACONTACTTYPE == 3) && tmp.ISDEFAULT
        );
        this.endUsers = endUserArr.map(tmp => ({
          ID: tmp.ACCOUNTCONTACTID,
          TEXT: tmp.ENDUSERDETAILS,
          NAME: tmp.ACONTACTNAME,
          PHONE: tmp.ACONTACTPHONE,
          EMAIL: tmp.ACONTACTEMAIL,
          PHONEEXT: tmp.ACONTACTPHONEEXT,
          ACCOUNTUSERID: tmp.ACCOUNTUSERID
        }));
        this.endUsers.push({
          ID: "onetime",
          TEXT: "Enter New End User"
        });
        if (this.selectedEndUsers.length) {
          const selectedEndUserItem = this.endUsers.find(
            tmp => tmp.ID == this.selectedEndUsers
          );
          this.euName = selectedEndUserItem.NAME;
          this.euPhone = selectedEndUserItem.PHONE;
          this.euEmail = selectedEndUserItem.EMAIL;
          this.euPhoneText = selectedEndUserItem.PHONEEXT;
        }
        if (defaultEndUserItem) {
          this.defaultEndUser = defaultEndUserItem.ACCOUNTCONTACTID;
          if (setDefault) {
            this.selectedEndUsers = [defaultEndUserItem.ACCOUNTCONTACTID];
            this.euName = defaultEndUserItem.ACONTACTNAME;
            this.euPhone = defaultEndUserItem.ACONTACTPHONE;
            this.euEmail = defaultEndUserItem.ACONTACTEMAIL;
            this.euPhoneText = defaultEndUserItem.ACONTACTPHONEEXT;
          }
        }
        this.invoiceMail = invoiceMailArr.map(tmp => ({
          ID: tmp.ACCOUNTCONTACTID,
          TEXT: tmp.ACONTACTEMAIL
        }));
        this.invoiceMail.push({
          ID: "onetime",
          TEXT: "Enter E-mail"
        });
        if (defaultInvoiceMailItem) {
          this.defaultInvoiceMail = defaultInvoiceMailItem.ACCOUNTCONTACTID;
          if (setDefault) {
            const defaultInvoiceMailIDs = response.data.contactListArr.filter(
              tmp =>
                (tmp.ACONTACTTYPE == 2 || tmp.ACONTACTTYPE == 3) &&
                tmp.ISDEFAULT
            );
            if (defaultInvoiceMailIDs.length) {
              this.contactEmail = defaultInvoiceMailIDs.map(
                item => item.ACCOUNTCONTACTID
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    // return ret;
  }

  getAddressText(address) {
    var ret = "";
    const name = address.ADDRESSNAME || "";
    const addr1 = address.ACCOUNTSADDRESS_ADDRESS1 || "";
    const addr2 = address.ACCOUNTSADDRESS_ADDRESS2 || "";
    const city = address.ACCOUNTSADDRESS_CITY || "";
    const state = address.ACCOUNTSADDRESS_STATE || "";
    const zip = address.ACCOUNTSADDRESS_ZIP || "";
    if (name != "") {
      ret += name + " -";
    }
    if (addr1 != "") {
      ret += " " + addr1;
    }
    if (addr2 != "") {
      ret += " " + addr2;
    }
    if (city != "") {
      ret += " " + city;
    }
    if (state != "" || zip != "") {
      ret += ",";
      if (state != "") {
        ret += " " + state;
      }
      if (zip != "") {
        ret += " " + zip;
      }
    }

    return ret;
  }

  updateCustomerAddress(selectedID) {
    if (selectedID == "permanent" || selectedID == "onetime") {
      this.addNewType = selectedID;
      this.customerAddressVisible = true;
    } else {
      this.selectedAddress = [selectedID];
      this.checkAutomateTaxRate("shipping");
    }
    // To list the selected address on the top
    this.sortingAddresses(this.customerAddresses, this.selectedAddress);
  }

  updateBillingAddress(selectedID) {
    if (selectedID == "permanent" || selectedID == "onetime") {
      this.addNewType = selectedID;
      this.billingAddressVisible = true;
    } else {
      this.selectedBillingAddress = [selectedID];
      this.checkAutomateTaxRate("billing");
    }
    // To list the selected address on the top
    this.sortingAddresses(
      this.customerAddressesBilling,
      this.selectedBillingAddress
    );
  }

  checkItemIsFee(val) {
    return val.POPRODSKU.toLowerCase() == "fee" &&
      val.POCUSTOMERPRICE == 0 &&
      val.POINCLUDED == 0
      ? true
      : false;
  }

  async addNewAddress(addressList, type) {
    if (type == "shipping") {
      if (addressList[0].isSave) {
        this.onetimeAddress = {};
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Accounts",
          FunctionName: "Update",
          aID: this.aID,
          addressList: addressList,
          addOneAddress: true
        });
        if (
          response.data.STATUS == 1 &&
          typeof response.data.AccountsAddress_ID != "undefined"
        ) {
          await this.getCustomerAddresses(this.aID);
          this.selectedAddress = [response.data.AccountsAddress_ID];
          this.customerAddressVisible = false;
          this.checkAutomateTaxRate("shipping");
        } else if (response.data.STATUS == 2) {
          $("#customer_address_name").addClass("error");
          notifier.alert(response.data.MESSAGE);
        }
      } else {
        this.onetimeAddress = {
          ADDRESSNAME: addressList[0].NAME,
          ACCOUNTSADDRESS_ADDRESS1: addressList[0].ADDR1,
          ACCOUNTSADDRESS_ADDRESS2: addressList[0].ADDR2,
          ACCOUNTSADDRESS_CITY: addressList[0].CITY,
          ACCOUNTSADDRESS_STATE: addressList[0].STATE,
          ACCOUNTSADDRESS_ZIP: addressList[0].ZIP
        };
        const newAddressItem = {
          ID: 0, // for one time address
          TEXT: this.getAddressText(this.onetimeAddress),
          data: this.onetimeAddress
        };
        let onetimeAddr = this.customerAddresses.find(item => item.ID == 0);
        if (onetimeAddr) {
          Object.assign(onetimeAddr, newAddressItem);
        } else {
          this.customerAddresses.unshift(newAddressItem);
        }
        this.selectedAddress = [0];
        this.customerAddressVisible = false;
        this.checkAutomateTaxRate("shipping");
      }
    } else if (type == "billing") {
      if (addressList[0].isSave) {
        this.onetimeAddressBilling = {};
        addressList[0].ADDRTYPE = 2;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Accounts",
          FunctionName: "Update",
          aID: this.aID,
          addressList: addressList,
          addOneAddress: true
        });
        if (
          response.data.STATUS == 1 &&
          typeof response.data.AccountsAddress_ID != "undefined"
        ) {
          await this.getCustomerAddresses(this.aID);
          this.selectedBillingAddress = [response.data.AccountsAddress_ID];
          this.billingAddressVisible = false;
          this.checkAutomateTaxRate("billing");
        } else if (response.data.STATUS == 2) {
          $("#customer_address_name").addClass("error");
          notifier.alert(response.data.MESSAGE);
        }
      } else {
        this.onetimeAddressBilling = {
          ADDRESSNAME: addressList[0].NAME,
          ACCOUNTSADDRESS_ADDRESS1: addressList[0].ADDR1,
          ACCOUNTSADDRESS_ADDRESS2: addressList[0].ADDR2,
          ACCOUNTSADDRESS_CITY: addressList[0].CITY,
          ACCOUNTSADDRESS_STATE: addressList[0].STATE,
          ACCOUNTSADDRESS_ZIP: addressList[0].ZIP
        };
        const newAddressItem = {
          ID: 0, // for one time address
          TEXT: this.getAddressText(this.onetimeAddressBilling),
          data: this.onetimeAddressBilling
        };
        let onetimeAddr = this.customerAddressesBilling.find(
          item => item.ID == 0
        );
        if (onetimeAddr) {
          Object.assign(onetimeAddr, newAddressItem);
        } else {
          this.customerAddressesBilling.unshift(newAddressItem);
        }
        this.selectedBillingAddress = [0];
        this.billingAddressVisible = false;
        this.checkAutomateTaxRate("billing");
      }
    }
  }

  isDynamic() {
    if (this.sourceID == 10) {
      return true;
    }

    return false;
  }

  getDistyStockStatus(item: any): string {
    let ret = "";
    if (item.StockQty == item.TotalQty) {
      ret = "all in stock";
    } else if (item.StockQty > 0 && item.StockQty < item.TotalQty) {
      ret = "partial stock";
    } else {
      ret = "no availability";
    }

    return ret;
  }

  getLowestDistyPrice(productInfo: any, item: any) {
    let prices: any = [];
    let lowestPrice = 0;
    for (const i in this.distyIDs) {
      const distyID = this.distyIDs[i];
      prices.push(productInfo[`Disty_${distyID}_Price`] || 0);
    }
    prices = prices.filter((item: any) => item > 0);
    if (prices.length == 0) {
      lowestPrice = 0;
    } else if (prices.length == 1) {
      lowestPrice = prices[0];
    } else if (prices.length > 1) {
      // get lowest disty price
      lowestPrice = Math.min(...prices);
    }

    // select a distyID for this line item
    if (lowestPrice != 0 && typeof item.dynamicInfo != "undefined") {
      for (const i in this.distyIDs) {
        const distyID = this.distyIDs[i];
        if ((productInfo[`Disty_${distyID}_Price`] || 0) == lowestPrice) {
          item.dynamicInfo.selectedDistyID = distyID;
          break;
        }
      }
    }

    return lowestPrice;
  }
  mailList(contactEmail) {
    const mailRegex = new RegExp(
      /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/
    );
    const emailList = mailRegex.test(contactEmail);
    if (contactEmail != "" && emailList == false) {
      $("#emailErrorMsg .errorMsg").text(
        "The Email field must be a valid email"
      );
      this.mailRequired = true;
    } else {
      $("#emailErrorMsg .errorMsg").text("");
      this.mailRequired = false;
    }
  }

  getMarkupFromMargin(marginRate) {
    let markup = 0;
    if (marginRate == 100) {
      return 100;
    }
    if (marginRate != 0) {
      const tmp = parseFloat((marginRate / 100).toFixed(4));
      markup = parseFloat(((tmp / (1 - tmp)) * 100).toFixed(4));
    }

    return markup;
  }

  async updateLedgerSource(data) {
    if (data.varsource != "" || data.varsource != "undefined" || data != null) {
      this.fundingSourceId = data.varsource.VARSOURCE_ID;
      this.fundingSourceName = data.varsource.VARSOURCE_NAME;
      this.fundingSourceTerms = data.varsource.TERMS || "";
    } else {
      this.fundingSourceName = "";
      this.fundingSourceId = 0;
      this.fundingSourceTerms = "";
    }
  }

  async checkFundingSource(value) {
    if (value == null) {
      this.fundingSourceName = "";
      this.fundingSourceId = 0;
      this.fundingSourceTerms = "";
    }
  }

  async updateEndUser(selectedID) {
    if (selectedID == "onetime") {
      this.addNewType = "endUser";
      this.endUserVisible = true;
    } else {
      var euDetails = this.endUsers.filter(user => user.ID == selectedID);
      this.selectedEndUsers = [selectedID];
      if (euDetails.length) {
        this.euName = euDetails[0].NAME;
        this.euPhone = euDetails[0].PHONE;
        this.euEmail = euDetails[0].EMAIL;
        this.euPhoneText = euDetails[0].PHONEEXT;
      } else {
        this.euName = "";
        this.euPhone = "";
        this.euEmail = "";
      }
      // To list the selected contacts on the top
      if (this.endUsers.length) {
        this.sortingAddresses(this.endUsers, this.selectedEndUsers);
      }
    }
  }

  updateInvoiceUser(selectedId) {
    if (selectedId == "onetime") {
      this.addNewType = "invoiceMail";
      this.invMailVisible = true;
    } else {
      const index = this.contactEmail.findIndex(id => id === selectedId);
      if (index === -1) {
        this.contactEmail.push(selectedId);
      } else {
        this.contactEmail.splice(index, 1);
      }
      // To list the selected contacts on the top
      if (this.invoiceMail.length) {
        this.sortingAddresses(this.invoiceMail, this.contactEmail);
      }
    }
  }

  selectAllInvoiceUsers() {
    this.contactEmail = this.invoiceMail.map((user: any) => user.ID);
  }

  resetAllInvoiceUsers() {
    this.contactEmail = [];
  }

  getExt(e) {
    if (e != undefined && e != "" && e != null) {
      return e.split(" ")[0];
    } else {
      return "US";
    }
  }

  countryChanged(code, phoneNumber) {
    this.euPhoneText = "+" + code + " " + phoneNumber;
    console.log(this.euPhoneText);
  }

  autoExpandDecimalSwitch(togBtn) {
    this.expandDecimalPrecision = 2;
    if (togBtn) {
      this.expandDecimalPrecision = 5;
    }
    this.expandDecimal = !this.expandDecimal;
  }

  async addNewDepartment(departmentList) {
    if (departmentList[0].isSave) {
      this.onetimeDepartment = {};
      var department = {
        NAME: departmentList[0].Name,
        ID: 0
      };
      this.departmentItems = [department];
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Contacts",
        FunctionName: "UpdateDepartment",
        aID: this.aID,
        departmentsList: this.departmentItems,
        action: "newDept"
      });
      if (response.data.STATUS == 1 && response.data.departmentID != "") {
        this.duplicateDeptName = false;
        notifier.success(response.data.STATUSMESSAGE);
        this.selectedDepartment = [response.data.departmentID];
        this.departmentVisible = false;
        //Get customer department details
        await this.getCustomerDepartment(this.aID);
      } else {
        this.duplicateDeptName = true;
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } else {
      this.onetimeDepartment = {
        Name: departmentList[0].Name
      };
      const newDepartmentItem = {
        ID: 0, // for one time dept
        TEXT: this.onetimeDepartment.Name
      };
      let onetimeDept = this.customerDepartment.find(item => item.ID == 0);
      if (onetimeDept) {
        Object.assign(onetimeDept, newDepartmentItem);
      } else {
        this.customerDepartment.unshift(newDepartmentItem);
      }
      this.selectedDepartment = [0];
      this.departmentVisible = false;
    }
    this.selectedDepartmentName = departmentList[0].Name;
  }

  async getCustomerDepartment(aID) {
    if (aID > 0) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Contacts",
          FunctionName: "UpdateDepartment",
          aID: aID,
          action: "list"
        });
        if (response.data.STATUS) {
          this.customerDepartment = response.data.DEPARTMENTDETAILS.map(
            tmp => ({
              ID: tmp.ACCOUNTDEPARTMENTID,
              TEXT: tmp.ADEPARTMENTNAME
            })
          );
        }
        this.customerDepartment.push({
          ID: "onetime",
          TEXT: "Add new department"
        });
        if (Object.keys(this.onetimeDepartment).length) {
          this.customerDepartment.unshift({
            ID: 0,
            TEXT: this.onetimeDepartment.Name
          });
        }
      } catch (error) {
        // console.log(error);
      }
    }
  }

  updateDepartment(selectedID) {
    if (selectedID == "onetime") {
      this.addNewType = selectedID;
      this.departmentVisible = true;
    } else {
      this.selectedDepartment = [selectedID];
      const selectedObj = this.customerDepartment.find(
        (item: any) => item.ID === selectedID
      );
      this.selectedDepartmentName = selectedObj.TEXT;
    }
  }
  // To list the selected address on the top
  sortingAddresses(addressArray, selectAddress) {
    var arr: any = [];
    for (let i = 0; i < addressArray.length; i++) {
      if ($.inArray(addressArray[i].ID, selectAddress) != -1) {
        arr.push(addressArray[i]);
        addressArray.splice(i, 1);
        i--;
      }
    }
    arr.reverse().forEach(item => {
      addressArray.splice(0, 0, item);
    });
  }
  autoIncludePriceSwitch() {
    this.includedPrice = !this.includedPrice;
    // this.$refs.orderFormLineItemList.setIncludedItemPrice();
  }

  updateBusinessLine(id) {
    this.selectedBusinessLine = [id];
  }

  inputPoId() {
    this.confirmedUnverifyOrder = false;
    if (!this.orderID) return;

    if (this.inputPoIdTimer) {
      clearTimeout(this.inputPoIdTimer);
    }
    this.orderID = ApiHelper.cleanString(this.orderID);
    this.inputPoIdTimer = setTimeout(async () => {
      // try {
      //   const response = await axios.post(dataURL + "?ReturnType=JSON", {
      //     controller: "Orders",
      //     FunctionName: "GetUnverifyOrder",
      //     poId: this.orderID
      //   });
      //   if (response.data.STATUS == 1) {
      //     this.unverifyOrder = response.data.unverifyOrder || {};
      //     this.unverifyOrder.lineItems = response.data.unverifyOrderItems || [];
      //     const hardwareInfo = response.data.hardwareInfo || [];
      //     for(const item of this.unverifyOrder.lineItems) {
      //       const relatedHardware = hardwareInfo.find(t => t.PRODUCTNUMBER == item.PRODUCTNUMBER);
      //       if(relatedHardware) {
      //         item.PRICE = parseFloat((parseFloat(relatedHardware.PRICE) || 0).toFixed(2));
      //       }
      //       item.PRICEFORMATTED = dollarFormat(item.PRICE);
      //     }
      //     if(this.unverifyOrder.lineItems.length) {
      //       this.linkUnverifyOrderModalVisible = true;
      //     }
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
      this.unverifyOrder = await this.getUnverifyOrder(this.orderID);
      if ((this.unverifyOrder.lineItems || []).length) {
        this.linkUnverifyOrderModalVisible = true;
      }
    }, 300);
  }

  async getUnverifyOrder(poId) {
    let unverifyOrder: any = {};
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "GetUnverifyOrder",
        poId
      });
      if (response.data.STATUS == 1) {
        unverifyOrder = response.data.unverifyOrder || {};
        unverifyOrder.lineItems = response.data.unverifyOrderItems || [];
        const hardwareInfo = response.data.hardwareInfo || [];
        for (const item of unverifyOrder.lineItems) {
          const relatedHardware = hardwareInfo.find(
            t => t.PRODUCTNUMBER == item.PRODUCTNUMBER
          );
          if (relatedHardware) {
            item.PRICE = parseFloat(
              (parseFloat(relatedHardware.PRICE) || 0).toFixed(2)
            );
          }
          item.PRICEFORMATTED = dollarFormat(item.PRICE);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return unverifyOrder;
  }

  async confirmUnverifyOrder(unverifyOrder) {
    const orderFormLineItemList = this.$refs.orderFormLineItemList;
    orderFormLineItemList.itemNames = {};
    this.includedItem = false;
    await wait(100);

    if ((unverifyOrder.lineItems || []).length) {
      const isHPDirect = true;
      const items: any = unverifyOrder.lineItems.map(item => {
        const price = isHPDirect ? 0 : item.PRICE || 0;
        const customerPrice = isHPDirect ? item.PRICE || 0 : 0;
        return {
          sku: item.PRODUCTNUMBER,
          name: item.PRODUCTDESCRIPTION,
          quantity: item.ORDEREDQUANTITY || 0,
          ItemCategory: item.CATEGORYID || 0,
          ItemCategoryName: item.CATEGORYNAME || "",
          price,
          orgPrice: price,
          customerPrice,
          orgCustomerPrice: customerPrice,
          includedparent: item.INCLUDEDPARENT,
          baseProductId: item.LINEID,
          includeItemCount: item.INCLUDEITEMCOUNT
        };
      });
      orderFormLineItemList.setRows(items, "");
      orderFormLineItemList.addRow(false);

      // set include
      for (const index in orderFormLineItemList.itemNames) {
        const item = orderFormLineItemList.itemNames[index];
        const related: any = unverifyOrder.lineItems.find(
          t => t.PRODUCTNUMBER == item.sku
        );
        if (related && (related.ISMAINITEM || "") == "N") {
          // set include
          orderFormLineItemList.includeItem(index);
          this.includedItem = true;
        }
      }

      // turn off "Price Roll-up"
      if (this.includedPrice) {
        this.autoIncludePriceSwitch();
      }

      // if this customer number was mapped, auto select a var360 customer
      if (unverifyOrder.ACCOUNTMAPPED && unverifyOrder.AID > 0) {
        this.updateCustomer({
          account: {
            ANAME: unverifyOrder.VAR360CUSTOMERNAME || "",
            AID: unverifyOrder.AID || 0,
            ACCOUNTID: unverifyOrder.ACCOUNTID || "",
            INDIRECTTAXRATE: unverifyOrder.INDIRECTTAXRATE || 0,
            HPDIRECTTAXRATE: unverifyOrder.HPDIRECTTAXRATE || 0
          }
        });
      }

      // update selected source to "HP Direct"
      if (isHPDirect && this.sourceID != 1) {
        this.updateSource({
          varsource: {
            VARSOURCE_ID: 1,
            VARSOURCE_NAME: "HP Direct"
          }
        });
      }

      this.confirmedUnverifyOrder = true;
    }

    this.linkUnverifyOrderModalVisible = false;
  }

  async addNewContact(contactItem) {
    if (this.addNewType == "endUser") {
      let filteredContact = this.endUsers.filter(
        (item: any) =>
          contactItem[0].name &&
          item.NAME &&
          item.NAME.toLowerCase() == contactItem[0].name.toLowerCase()
      );
      if (filteredContact.length) {
        this.endUserVisible = false;
        this.confirmedDuplicateContactVisible = true;
        this.duplicateContactItems = contactItem;
        return;
      }
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Accounts",
      FunctionName: "Update",
      aID: this.aID,
      contactsList: contactItem
    });
    if (response.data.STATUS && response.data.CONTACTID.length) {
      if (this.addNewType == "endUser") {
        this.selectedEndUsers = [response.data.CONTACTID];
        this.endUsers.push({
          ID: response.data.CONTACTID,
          TEXT: contactItem[0].name,
          NAME: contactItem[0].name,
          PHONE: "",
          EMAIL: "",
          PHONEEXT: ""
        });
        this.euName = contactItem[0].name;
        this.euPhone = "";
        this.euEmail = "";
        this.euPhoneText = "";
        this.sortingAddresses(this.endUsers, this.selectedEndUsers);
        this.endUserVisible = false;
      } else {
        this.contactEmail.push(response.data.CONTACTID);
        this.invoiceMail.push({
          ID: response.data.CONTACTID,
          TEXT: contactItem[0].mail
        });
        this.sortingAddresses(this.invoiceMail, this.contactEmail);
        this.invMailVisible = false;
      }
    }
  }
  async addDuplicateContact() {
    if (this.duplicateContactItems.length) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Accounts",
        FunctionName: "Update",
        aID: this.aID,
        contactsList: this.duplicateContactItems
      });
      if (response.data.STATUS && response.data.CONTACTID.length) {
        if (this.addNewType == "endUser") {
          this.selectedEndUsers = [response.data.CONTACTID];
          this.endUsers.push({
            ID: response.data.CONTACTID,
            TEXT: this.duplicateContactItems[0].name,
            NAME: this.duplicateContactItems[0].name,
            PHONE: "",
            EMAIL: "",
            PHONEEXT: ""
          });
          this.euName = this.duplicateContactItems[0].name;
          this.euPhone = "";
          this.euEmail = "";
          this.euPhoneText = "";
          this.sortingAddresses(this.endUsers, this.selectedEndUsers);
          this.confirmedDuplicateContactVisible = false;
        }
      }
    }
  }
  async updateNewContact(contactItem, value, phoneExt) {
    setTimeout(async () => {
      if (contactItem && contactItem.ID && contactItem.ID != "onetime") {
        if (typeof phoneExt != "undefined") {
          let countryCode: string = "";
          let divElement: any = document.querySelector(
            ".country-selector__country-flag div"
          );
          let classes = divElement.classList;

          for (let i = 0; i < classes.length; i++) {
            if (classes[i] !== "iti-flag-small" && classes[i] !== "iti-flag") {
              let targetClass = classes[i];
              countryCode = targetClass.toUpperCase();
              break;
            }
          }

          contactItem.PHONE = value;
          contactItem.PHONEEXT =
            countryCode + " " + $(".country-selector__input").val();
        } else {
          contactItem.EMAIL = value;
        }

        // validate End user phone
        const valid = await this.$validator.validate("euPhone");

        if (!valid) {
          return;
        }

        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Accounts",
          FunctionName: "Update",
          aID: this.aID,
          contactUpdate: contactItem
        });
        const response2 = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Contacts",
          FunctionName: "UpdateEndUser",
          aID: this.aID,
          contactUpdate: contactItem
        });
        if (response.data.STATUS) {
          this.endUsers = this.endUsers.map(item => {
            if (item.ID == contactItem.id) {
              item.NAME = contactItem.name;
              item.PHONE = contactItem.phone;
              item.EMAIL = contactItem.mail;
              item.PHONEEXT = contactItem.phoneExt;
            }
            return item;
          });
          this.sortingAddresses(this.endUsers, this.selectedEndUsers);
          this.endUserVisible = false;
        }
      }
    }, 100);
  }

  resetStripePaymentInfo() {
    this.stripeToken = "";
    this.confirmedPayment = false;
    this.stripeCustomerId = "";
    this.stripeCardId = "";
    this.paymentCaptured = 0;
  }

  async convertMultiQuotesToOrder(quoteIds: string[] = []) {
    if (!quoteIds.length) return;

    this.resetStripePaymentInfo();
    this.title = "Create Order";
    this.quoteID = "";
    this.quoteIds = quoteIds;
    const orderFormLineItemList = this.$refs.orderFormLineItemList;

    // get quotes data
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Quotes",
        FunctionName: "PrepareOrderData",
        quoteIds: quoteIds.join(",")
      });

      this.splitByQuoteId = response.data.splitByQuoteId || [];
      const orderInfo = response.data.orderInfo;
      this.quoteEndUsers = response.data.endUsers || [];
      this.dataFields = response.data.dataFields || [];
      this.generalDataFields = response.data.generalDataFields || [];
      this.aID = orderInfo.AID.toString();
      this.accountID = orderInfo.AACCOUNTID;
      this.customer = orderInfo.AACCOUNTNAME;
      this.sourceQuoteIds = orderInfo.SOURCEQUOTEIDS || "";
      const oppID = (orderInfo.OPPORTUNITYID || "")
        .split("||")
        .filter(t => t != "");
      if (oppID.length == 1) {
        this.opportunityID = oppID[0];
        this.opportunityPrevID = oppID[0];
        this.opportunityName = oppID[0] ? `MOP-${oppID[0]}` : "";
      }
      // this.reseller = quoteDetails.AACCOUNTNAME;
      // this.quoteName = response.data.QUOTENAME;
      // this.orderName = response.data.QUOTENAME;
      const quoteNames = (orderInfo.QUOTENAMES || "")
        .split("||")
        .filter(t => t != "");
      if (quoteNames.length == 1) {
        this.orderName = quoteNames[0];
      }

      // if (
      //   typeof response.data.Payment_CustomPO != "undefined" &&
      //   response.data.Payment_CustomPO != ""
      // ) {
      //   this.orderID = response.data.Payment_CustomPO;
      // }

      // this.selectedGlobalTags = response.data.selectedGlobalTags.map(
      //   (val: any) => ({
      //     CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
      //     CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
      //   })
      // );
      // this.selectedGlobalTagsID = this.selectedGlobalTags.map(
      //   (val: any) => val.CUSTOMFIELDOPTIONID
      // );

      this.USECUSTOMTAX = response.data.USECUSTOMTAX || 0;

      if ((orderInfo.SOURCEID || "") != "") {
        const sourceId = orderInfo.SOURCEID.split(",");
        if (sourceId.length == 1) {
          this.sourceID = sourceId[0];
          this.source = orderInfo.SOURCENAME || "";
        }
      }
      if ((orderInfo.ESTPERCENT || "") != "") {
        const estPercent = orderInfo.ESTPERCENT.split(",");
        if (estPercent.length == 1) {
          this.estPercent = estPercent[0];
        }
      }

      if (this.source == "HPE" || this.source == "HP Direct") {
        for (const item of this.financialSource) {
          if (item.VARSOURCE_NAME == this.source) {
            this.fundingSourceId = item.VARSOURCE_ID;
            this.fundingSourceName = item.VARSOURCE_NAME;
          }
        }
      } else if ((orderInfo.FUNDINGSOURCEID || "") != "") {
        const fundingSourceId = orderInfo.FUNDINGSOURCEID.split(",");
        if (fundingSourceId.length == 1) {
          for (const item of this.financialSource) {
            if (item.VARSOURCE_ID == fundingSourceId[0]) {
              this.fundingSourceId = item.VARSOURCE_ID;
              this.fundingSourceName = item.VARSOURCE_NAME;
            }
          }
        }
      }

      // if (
      //   quoteDetails.GLOBALMARGIN != "undefined" &&
      //   quoteDetails.GLOBALMARGIN != 0
      // ) {
      //   this.globalMargin = quoteDetails.GLOBALMARGIN;
      //   this.globalMarginBK = quoteDetails.GLOBALMARGIN;
      //   this.confirmedGlobalMargin = true;
      // }

      // this.hpDirectTaxRate =
      //   typeof quoteDetails.HPDIRECTTAXRATE != "undefined"
      //     ? quoteDetails.HPDIRECTTAXRATE
      //     : 0;

      // this.indirectTaxRate =
      //   typeof quoteDetails.INDIRECTTAXRATE != "undefined"
      //     ? quoteDetails.INDIRECTTAXRATE
      //     : 0;

      // this.currentTaxRate = this.getTaxRateBySource();

      // this.validSynnexCredential = response.data.validSynnexCredential || false;

      // this.validTechdataCredential =
      //   response.data.validTechdataCredential || false;

      // this.sourceCredentials = response.data.sourceCredentials || [];

      // override current tax rate if user used an override value before
      if ((orderInfo.TAXRATE || 0) > 0) {
        this.currentTaxRate = orderInfo.TAXRATE;
      }

      // Set expandDecimal and expandDecimalPrecision
      // const quoteParams = JSON.parse(quoteDetails.QUOTEPARAMS || "{}");
      // this.quoteParams = quoteParams;
      // const endUser = quoteParams.endUser || {};
      this.ssInfo.SSId = orderInfo.SSID || "";
      this.ssInfo.SSUrl = orderInfo.SSURL || "";
      this.ssInfo.SSName = orderInfo.SSNAME || "";

      // specify uncaptured stripe
      this.paymentCaptured = response.data.captured || 0;
      const paymentConfirmID = response.data.PaymentConfirmID || "";
      if (
        paymentConfirmID &&
        paymentConfirmID != "0" &&
        !this.paymentCaptured
      ) {
        // if has confirmed id and uncapture this amount
        this.stripeToken = paymentConfirmID;
        this.confirmedPayment = true;
        this.stripeCustomerId = response.data.stripeCustomerId || "";
        this.stripeCardId = response.data.stripeCardId || "";
      }

      // end user info
      // this.euName = endUser.Name || "";
      // this.euEmail = endUser.SubmitterEmail || "";

      // if (quoteParams.expandDecimal) {
      //   this.expandDecimal = quoteParams.expandDecimal;
      //   this.expandDecimalPrecision = 5;
      // }

      //Fill customer department
      // if (
      //   typeof quoteParams.departmentDetails != "undefined" &&
      //   quoteParams.departmentDetails != ""
      // ) {
      //   this.selectedDepartment = [parseInt(quoteParams.departmentDetails.ID)];
      //   this.selectedDepartmentName = quoteParams.departmentDetails.NAME;
      // }

      // Get customer department details
      if (this.aID > 0) {
        this.getCustomerDepartment(this.aID);
      }

      // set contract number
      const selectedContractData = response.data.selectedContractData || [];
      if (selectedContractData.length) {
        this.selectedOrderContracts =
          selectedContractData[0].CUSTOMFIELDOPTIONNAME;
        this.orderContractsID = [selectedContractData[0].CUSTOMFIELDOPTIONID];
      }

      // this.includedItem = quoteParams.includedItem || false;
      if ((orderInfo.BUSINESSLINEID || "") != "") {
        const businessLineId = orderInfo.BUSINESSLINEID.split(",");
        this.selectedBusinessLine =
          businessLineId.length == 1 ? businessLineId[0] : [];
      }

      //set summary
      this.summary = {
        grandTotal: orderInfo.GRANDTOTAL || 0,
        itemTotal: orderInfo.CUSTOMERTOTALPRICE || 0,
        profit:
          this.estPercent > 0
            ? (orderInfo.CUSTOMERTOTALPRICE * this.estPercent) / 100
            : orderInfo.PROFIT,
        estPercent: this.estPercent,
        totalCost: orderInfo.QTOTALPRICE || 0,
        qShip: orderInfo.TOTALSHIPPING || 0,
        qTax: orderInfo.TOTALTAX || 0,
        totalShipping: orderInfo.TOTALSHIPPING || 0,
        includeVarCost: true,
        totalTax: orderInfo.TOTALTAX || 0
      };

      // if ($("#orderID").val() == "") {
      //   this.quoteName = this.quoteID + " - " + response.data.QUOTENAME;
      // }

      const items: any = [];
      for (const item of this.splitByQuoteId) {
        for (const val of item.items) {
          items.push({
            quoteId: item.quoteId,
            sku: val.QPRODSKU,
            name: val.QPRODDESC.replace(/[$|,]/g, ""),
            price: val.QPRICEREG,
            included: val.QINCLUDED || 0,
            ISCONFIG: val.ISCONFIG || 0,
            categoryType: val.CATEGORYTYPE,
            customerPrice: val.QCUSTOMERPRICE,
            margin: val.QMARGIN,
            marginRate: val.MARGINRATE || 0,
            total: val.CUSTOMERTOTALPRICE || 0,
            quantity: val.QPRODQUANTITY,
            taxRate: val.QTAXRATE || 0,
            tax:
              val.QINCLUDED || 0
                ? val.QTAX
                : this.getLineItemTax(val.CUSTOMERTOTALPRICE, val.QTAXRATE),
            shipping: val.QSHIPPING || 0,
            priceWarn: false,
            noTax: val.NOTAX || false,
            ItemCategory: val.QCATEGORY || 0,
            ItemCategoryName: val.CATEGORYNAME || "",
            isFee: !!val.ISFEE,

            // dynamicInfo: val,
            // statusid:
            //   typeof val.STATUSID != "undefined" && val.STATUSID
            //     ? val.STATUSID
            //     : 0,
            // contractPrice:
            //   typeof val["CONTRACTPRICE"] != "undefined"
            //     ? val["CONTRACTPRICE"]
            //     : undefined,
            // contractPriceDate:
            //   typeof val["CONTRACTPRICEDATE"] != "undefined"
            //     ? val["CONTRACTPRICEDATE"]
            //     : undefined,

            orgPrice: val.QPRICEREG,
            orgCustomerPrice: val.QCUSTOMERPRICE,

            // disable:
            //   (typeof val.PARENTDISABLE != "undefined" && val.PARENTDISABLE) ||
            //   (val.QINCLUDED && this.includedItem && !this.includedPrice)
            //     ? true
            //     : false,
            // isContractFee:
            //   typeof val.ISCONTRACTFEE != "undefined" && val.ISCONTRACTFEE
            //     ? true
            //     : false,
            includedparent: val.INCLUDEDPARENT || 0,
            baseProductId: val.QUOTELIID,
            includeItemCount: val.INCLUDEITEMCOUNT || 0,
            quoteLiId: val.QUOTELIID || 0,
            // quoteParentLiId: val.PARENTLIID || 0,
            subQuoteId: val.SUBQUOTEID || 0,
            CONFIGCOUNT: val.CONFIGCOUNT || 0,
            savedAsConfig: val.ISCONFIG || 0,
            ItemPLID: val.PRODUCTLINEID || 0,
            ItemPLName: val.PRODUCTLINENAME || "",
            buildHeader: val.BUILDHEADER || ""
          });
        }
      }

      items.push({
        quoteId: "",
        sku: "",
        name: "",
        price: 0.0,
        included: 0,
        ISCONFIG: 0,
        categoryType: 0,
        customerPrice: 0.0,
        margin: 0.0,
        marginRate: this.getConfirmedGlobalMargin(true),
        total: 0.0,
        quantity: 0,
        taxRate: this.currentTaxRate,
        tax: 0.0,
        shipping: 0.0,
        priceWarn: false,
        noTax: false,
        ItemCategory: 0,
        ItemCategoryName: "",
        isFee: false,
        dynamicInfo: {},
        statusid: 0,
        contractPrice: undefined,
        contractPriceDate: undefined,
        orgPrice: 0.0,
        orgCustomerPrice: 0.0,
        disable: false,
        isContractFee: false,
        includedparent: 0,
        baseProductId: 0,
        includeItemCount: 0,
        quoteLiId: 0,
        quoteParentLiId: 0,
        subQuoteId: 0,
        ItemPLID: 0,
        ItemPLName: "",
        buildHeader: ""
      });

      // if (this.isDynamic()) {
      //   orderFormLineItemList.autoName = 1;
      //   orderFormLineItemList.autoCost = 1;
      //   $(
      //     ".switch.auto input[type=checkbox].autoName, .switch.auto input[type=checkbox].autoCost"
      //   ).prop("checked", true);
      //   this.DistyTotal =
      //     typeof response.data.DistyTotal != "undefined"
      //       ? response.data.DistyTotal
      //       : {};
      // }

      orderFormLineItemList.setRows(items);
      orderFormLineItemList.$emit("summaryChange", this.summary);
      this.reCalculateGlobalMargin(orderFormLineItemList.getItemNames());

      if (this.aID > 0) {
        await this.getCustomerAddresses(this.aID, true);
        await this.updateAddressForCustomer(this.aID);
      }

      if (quoteNames.length == 1) {
        const quoteParams = response.data.quoteParams || {};
        this.setSelectedAddr(quoteParams);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }

    // sync custom data from quote to order
    // try {
    //   // get custom data of quote
    //   const result = await axios.post(dataURL + "?ReturnType=JSON", {
    //     controller: "Helpers",
    //     FunctionName: "CustomData",
    //     DataType: "2",
    //     Id: quote
    //   });
    //   if (result.data.STATUS == 1) {
    //     const quoteCustomFields = result.data.CUSTOMDEFINITION || [];
    //     const quoteCustomData = result.data.CUSTOMDATA || [];
    //     for (const item of quoteCustomFields) {
    //       const customData = quoteCustomData.find(
    //         t => t.CUSTOMFIELDID == item.CUSTOMFIELDID
    //       );
    //       if (!customData) continue;

    //       // check related field in order by field name
    //       const orderRelatedField = this.fullCustomData.find(
    //         (t: any) =>
    //           t.CUSTOMFIELDNAME.toLowerCase() ==
    //           item.CUSTOMFIELDNAME.toLowerCase()
    //       );
    //       if (!orderRelatedField) continue;

    //       orderRelatedField.CUSTOMVALUE = customData.CUSTOMVALUE;
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  }

  get isMultiEndUsers() {
    const quoteIds =
      (this.$route.query.quoteIds || "") != ""
        ? this.$route.query.quoteIds.split(",")
        : [];

    // check data of splitBySubOrderId
    if (
      this.splitBySubOrderId.length >= 1 &&
      this.splitBySubOrderId[0].subOrderId > 0
    ) {
      return true;
    }

    // check if the case is converting from multi quotes
    if (quoteIds.length) {
      return true;
    }

    return false;
  }

  showEUCustomData(ss) {
    this.selectedSubOrder = ss;
    this.euCustomDataVisible = true;
    if ((this.$route.query.quoteIds || "").length || this.duplicateOrder) {
      this.hideCustomDataApiCall = true;
    }
  }

  updateEUDataFields(options: any) {
    if (!this.dataFields.length) return;

    // check and update related dataFields
    const euCD = options.selectedSubOrder.euCD || [];
    const quoteId = options.selectedSubOrder.quoteId;
    const subQuoteId = options.selectedSubOrder.subQuoteId;
    for (const item of euCD) {
      const inDataFields = this.dataFields.find(
        t =>
          t.CUSTOMFIELDID == item.customFieldId &&
          t.QUOTEID == quoteId &&
          t.SUBQUOTEID == subQuoteId
      );
      if (inDataFields) {
        inDataFields.CUSTOMVALUE = item.customValue || "";
      }
    }
  }

  async checkAutomateTaxRate(type = "") {
    // just check tax rate if enable auto taxrate when change shipping, or billing address
    // const settings = {
    //   shippingAutoTax: 1,
    //   billingAutoTax: 0
    // };

    if (
      (type == "shipping" && !this.autoTaxSetting.shippingAutoTax) ||
      (type == "billing" && !this.autoTaxSetting.billingAutoTax)
    ) {
      return;
    }

    const locationInfo: any = {
      addr1: "",
      addr2: "",
      city: "",
      state: "",
      zip: "",
      country: ""
    };
    let inList: any = null;
    if (
      this.autoTaxSetting.shippingAutoTax &&
      (this.selectedAddress || []).length
    ) {
      inList = (this.customerAddresses || []).find(
        item => item.ID == this.selectedAddress[0]
      );
    } else if (
      this.autoTaxSetting.billingAutoTax &&
      (this.selectedBillingAddress || []).length
    ) {
      inList = (this.customerAddressesBilling || []).find(
        item => item.ID == this.selectedBillingAddress[0]
      );
    }
    if (!inList) {
      return;
    }

    locationInfo.addr1 = inList.data.ACCOUNTSADDRESS_ADDRESS1 || "";
    locationInfo.addr2 = inList.data.ACCOUNTSADDRESS_ADDRESS2 || "";
    locationInfo.city = inList.data.ACCOUNTSADDRESS_CITY || "";
    locationInfo.state = inList.data.ACCOUNTSADDRESS_STATE || "";
    locationInfo.zip = inList.data.ACCOUNTSADDRESS_ZIP || "";
    if (!locationInfo.addr1) {
      return;
    }

    try {
      this.checkingAutoTax = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Orders",
        FunctionName: "GetTaxRate",
        locationInfo
      });
      if (response.data.STATUS == 1) {
        const taxRate = response.data.taxRate || 0;
        const orderFormLineItemList: any = this.$refs.orderFormLineItemList;
        if (
          taxRate &&
          taxRate != this.currentTaxRate &&
          orderFormLineItemList
        ) {
          orderFormLineItemList.updateOrderTaxRate(taxRate);
          if (orderFormLineItemList.taxRateVisible) {
            const taxRateTooltip = orderFormLineItemList.$refs.taxRateTooltip;
            if (taxRateTooltip) {
              taxRateTooltip.newTaxRate = taxRate;
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.checkingAutoTax = false;
    }
  }

  updateOpportunity(data) {
    this.opportunityID = data.option.value || 0;
  }

  setSelectedAddr(quoteParams: any = {}) {
    // shipping/billing addr from quote
    if (
      typeof quoteParams.DefaultShippingID != "undefined" &&
      quoteParams.DefaultShippingID != ""
    ) {
      const defaultShippingID = parseInt(quoteParams.DefaultShippingID);
      const inList = this.customerAddresses.find(
        t => t.ID == defaultShippingID
      );
      if (inList) {
        this.selectedAddress = [defaultShippingID];
      }
    }
    if (
      typeof quoteParams.onetimeAddress != "undefined" &&
      Object.keys(quoteParams.onetimeAddress).length
    ) {
      // used one time address
      this.selectedAddress = [0];
      this.onetimeAddress = Object.assign(
        this.onetimeAddress,
        quoteParams.onetimeAddress
      );
      this.customerAddresses.unshift({
        ID: 0,
        TEXT: this.getAddressText(this.onetimeAddress)
      });
    }
    if (
      typeof quoteParams.DefaultBillingID != "undefined" &&
      quoteParams.DefaultBillingID != ""
    ) {
      const defaultBillingID = parseInt(quoteParams.DefaultBillingID);
      const inList = this.customerAddressesBilling.find(
        t => t.ID == defaultBillingID
      );
      if (inList) {
        this.selectedBillingAddress = [defaultBillingID];
      }
    }
    if (
      typeof quoteParams.onetimeAddressBilling != "undefined" &&
      Object.keys(quoteParams.onetimeAddressBilling).length
    ) {
      // used one time address for this order
      this.selectedBillingAddress = [0];
      this.onetimeAddressBilling = Object.assign(
        this.onetimeAddressBilling,
        quoteParams.onetimeAddressBilling
      );
      this.customerAddressesBilling.unshift({
        ID: 0,
        TEXT: this.getAddressText(this.onetimeAddressBilling)
      });
    }
    // To list the selected address on the top
    if (this.customerAddresses.length) {
      this.sortingAddresses(this.customerAddresses, this.selectedAddress);
    }
    if (this.customerAddressesBilling.length) {
      this.sortingAddresses(
        this.customerAddressesBilling,
        this.selectedBillingAddress
      );
    }
  }

  async confirmFundingSourceTerms() {
    const valid = await this.$validator.validate("fundingSourceTerms");
    if (!this.confirmedTerms || !valid || !this.fundingSourceId) {
      return;
    }
    // update terms for the funding source
    // const response = await axios.post(dataURL + "?ReturnType=JSON", {
    //   controller: "Sources",
    //   FunctionName: "Edit",
    //   action: "updateTerms",
    //   sourceId: this.fundingSourceId,
    //   sourceType: "funding_source",
    //   terms: this.confirmedTerms
    // });
    this.fundingSourceTerms = this.confirmedTerms;
    this.confirmTermsVisible = false;
  }
}
