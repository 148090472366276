var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"popover-box",class:{
    nodataheight: !_vm.catList.length,
    invoice: _vm.isinvoice,
    'is-config-item': _vm.isConfig
  }},[_c('div',{staticClass:"popover"},[_c('div',{staticClass:"arrow"}),_c('div',{staticClass:"popover-content skuTooltip"},[_c('div',{staticClass:"popover-content-inner"},[(_vm.loading)?_c('Loader'):_c('div',{staticClass:"list-container"},[(!_vm.hideConfigToggle)?_c('div',{staticClass:"config-toggle bg-white"},[_c('label',{staticClass:"switch auto"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isConfig),expression:"isConfig"}],attrs:{"type":"checkbox","id":"togBtn"},domProps:{"checked":Array.isArray(_vm.isConfig)?_vm._i(_vm.isConfig,null)>-1:(_vm.isConfig)},on:{"change":[function($event){var $$a=_vm.isConfig,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isConfig=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isConfig=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isConfig=$$c}},_vm.configToggleChange]}}),_c('div',{staticClass:"slider round"})])]):_vm._e(),(
              _vm.currentItem.ItemCategory > 0 &&
                !_vm.isConfig &&
                _vm.currentItem.ItemCategoryName.toLowerCase() != 'config'
            )?_c('div',{staticClass:"bg-white"},[_vm._v(" Selected Category: "),_c('strong',[_vm._v(_vm._s(_vm.currentItem.ItemCategoryName))])]):_vm._e(),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],attrs:{"name":"search","id":"search","type":"text","placeholder":"Search Category","maxlength":"50","disabled":_vm.isConfig},domProps:{"value":(_vm.search)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();$event.stopPropagation();},"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value.trim()},function($event){return _vm.categoryLookup()}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"search"}},[_c('span',[_vm._v(" Search Category "),[_vm._v("("+_vm._s(_vm.catList.length)+" TOTAL)")]],2)])]),(_vm.catList.length === 0)?_c('div',{staticClass:"msg noData"},[_c('span',{staticClass:"up"},[_vm._v("No Categories Found")])]):_vm._e(),_c('div',{staticClass:"list-data"},[(_vm.catList.length)?_c('perfect-scrollbar',{staticClass:"ps",attrs:{"options":{
                wheelPropagation: false,
                suppressScrollX: true,
                maxScrollbarLength: 50
              }}},[_c('ul',{staticClass:"list-group list-group-flush"},_vm._l((_vm.catList),function(item,index){return _c('li',{key:index,staticClass:"list-group-item",attrs:{"title":item.CATEGORYNAME}},[_c('span',{class:_vm.isquote || _vm.isinvoice ? 'textOverflow' : '',on:{"click":function($event){return _vm.selectCategory(item)}}},[_vm._v(_vm._s(item.CATEGORYNAME))])])}),0)]):_vm._e()],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }