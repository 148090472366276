var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/quoteDetails_header_hpdirect.handlebars"),depth0,{"name":"quoteDetails_header_hpdirect","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/quoteDetails_header.handlebars"),depth0,{"name":"quoteDetails_header","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression((helpers.dynamicPartial||(depth0 && depth0.dynamicPartial)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.partialname : depth0),{"name":"dynamicPartial","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":38}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!DOCTYPE html>\n<html>\n  <head>\n    <link href=\"https://var360.s3.amazonaws.com/public/css/export.css\" rel=\"stylesheet\">\n    <link href=\"https://fonts.googleapis.com/css?family=Work+Sans:400,600&display=swap\" rel=\"stylesheet\">\n    <style>\n      body * {\n        font-weight: 400;\n      }\n    </style>\n  </head>\n  <body>\n    ####DEV DETAIL TEST####\n\n\n    <div id=\"container\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.ISHPDIRECT : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/quoteDetails_information.handlebars"),depth0,{"name":"quoteDetails_information","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      \n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.PartialPosition1 : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.PartialPosition2 : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":6},"end":{"line":33,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/quoteDetails_summary.handlebars"),depth0,{"name":"quoteDetails_summary","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </body>\n</html>\n";
},"usePartial":true,"useData":true});