var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <tr>\n        <td class='tdcolor'> "
    + alias4(((helper = (helper = helpers.TICKETID || (depth0 != null ? depth0.TICKETID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TICKETID","hash":{},"data":data,"loc":{"start":{"line":33,"column":29},"end":{"line":33,"column":41}}}) : helper)))
    + " </td>\n        <td class='tdcolor'>"
    + alias4(((helper = (helper = helpers.SUBJECT || (depth0 != null ? depth0.SUBJECT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SUBJECT","hash":{},"data":data,"loc":{"start":{"line":34,"column":28},"end":{"line":34,"column":39}}}) : helper)))
    + " </td>\n        <td class='tdcolor'>"
    + alias4(((helper = (helper = helpers.TICKETCONTENT || (depth0 != null ? depth0.TICKETCONTENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TICKETCONTENT","hash":{},"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":45}}}) : helper)))
    + " </td> \n        <td class='tdcolor'> "
    + alias4(((helper = (helper = helpers.TICKETSCREATED || (depth0 != null ? depth0.TICKETSCREATED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TICKETSCREATED","hash":{},"data":data,"loc":{"start":{"line":36,"column":29},"end":{"line":36,"column":47}}}) : helper)))
    + " </td>\n        <td class='tdcolor'> "
    + alias4(((helper = (helper = helpers.TICKETSCLOSED || (depth0 != null ? depth0.TICKETSCLOSED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TICKETSCLOSED","hash":{},"data":data,"loc":{"start":{"line":37,"column":29},"end":{"line":37,"column":46}}}) : helper)))
    + " </td>\n        <td class='tdcolor'> "
    + alias4(((helper = (helper = helpers.STATUS || (depth0 != null ? depth0.STATUS : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"STATUS","hash":{},"data":data,"loc":{"start":{"line":38,"column":29},"end":{"line":38,"column":39}}}) : helper)))
    + "</td>\n      </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<table  width=\"100%\" cellpadding=\"10\" cellspacing=\"2\" border=\"1\" style=\"font:Verdana, Arial, Helvetica, sans-serif;\">\n  <tr>\n    <th class=\"thcolor\">Ticket Id</th>\n    <th class=\"thcolor\">Subject</th>\n    <th class=\"thcolor\">Ticket Content</th>\n    <th class=\"thcolor\">Ticket Created</th>\n    <th class=\"thcolor\">Ticket Closed</th>\n    <th class=\"thcolor\">Status</th>\n  </tr>\n  <tbody>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.VIEWTICKETSDETAILS : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":40,"column":13}}})) != null ? stack1 : "")
    + "  </tbody>\n</table>";
},"useData":true});