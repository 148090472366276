






















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from "axios";

import { notifier, debounce } from "../models/common";
import { AcctsListResp } from "../models/AcctsListResp";

import Loader from "./Loader.vue";
// import CustomerAdd from '../components/HPAgentDetailsCustomerAddModal.vue';

interface Props {
  linked: boolean
  masterAccount: string;
  items: [];
  showAllocation: boolean;
  masterAccountID: string;
  lineInfo: any;
  idxval : number
}

interface Events {
  onItemClick: AcctsListResp.Accountsarr
  onClose: void
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    // CustomerAdd
  }
})
export default class HPAgentDetailsLinkAccountTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: true })
  linked!: boolean;

  @Prop({ required: true })
  masterAccount!: string;

  @Prop({ required: false, default: false })
  showAllocation!: boolean;

  @Prop({ required: false, default: "" })
  masterAccountID!: string;

  @Prop({ required: false, default: {} })
  lineInfo!: any;

  @Prop({ required: true })
  idxval!: number;

  $parent: any;
  editing = false

  search = "";
  searching = ""; // so that debounce wouldn't cause delay in UI loader

  loading = false;

  items: AcctsListResp.Accountsarr[] = [];
  getAllocationLoading = false;
  allocationInfo = [];
  hasNewAcctForm = false;

  constructor() {
    super()

    if (!this.linked) {
      this.editing = true
    }
  }

  async created() {
    await this.fetchData();
    this.focusMasterAcct();
  }

  focusMasterAcct() {
    setTimeout(() => {
      $("#masterAcct").focus();
    }, 300);
  }

  get searchMsg(): string {
    if (this.search.length === 0) {
      return `Please input at least 1 character`;
    }

    if (!this.loading && this.searching.length > 0 && this.items.length === 0) {
      return `No result`;
    }

    return "";
  }

  async fetchData() {
    this.searching = this.search;
    if (!this.search) return;

    this.loading = true;
    this.items = [];

    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "listAccts",
        searchAcct: this.search
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      this.items = response.data.ACCOUNTSARR || [];
    } catch (err) {
      //notifier.alert("search accounts fail: " + err.message);
    } finally {
      this.loading = false;
    }
  }

  onSearch = debounce(async () => {
    await this.fetchData();
  }, 200);

  itemClick(item: AcctsListResp.Accountsarr) {
    this.$emit("itemClick", item);
  }

  clickOutside() {
    this.$emit('close')
  }

  showNewAccountForm(idx) {//toggle create new customer form
    if(this.hasNewAcctForm == true) {
      $("#HPAgentDetailsLinkAccountTooltip").removeClass("has-newacct-form");
      this.hasNewAcctForm = false;
    }else {
      this.hasNewAcctForm = true;
      $("#HPAgentDetailsLinkAccountTooltip").addClass("has-newacct-form");

      this.$emit('close')
      this.$parent.currentCustAddDetail = this.lineInfo;
      this.$parent.currentCustIndex = this.idxval;
    }
  }

  doLinkingWith(item) {
    this.$emit("itemClick", item);
  }
}
