

















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier, wait } from "../models/common";
import { v4 as uuidv4 } from "uuid";

declare const $: any;
declare const getRouteData: Function;
@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class apiCreate extends TSXComponent<void> {
  loading = true;
  saving = false;

  $refs!: {
    modal: HTMLDivElement;
  };
  // apiKey: string;
  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  apiKey = "";
  // loading = false;
  // saving = false;
  // data() {
  //   return {
  //   }
  // }
  async created() {
    var result = uuidv4().replace(/-/g, "");
    this.apiKey = result.substring(0, result.length - 2);
  }
  async validateBeforeSubmit(e) {
    this.$validator.validateAll().then(result => {
      if (result) {
        e.preventDefault();
        return this.submit();
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  async submit() {
    this.saving = true;
    var self = this;
    var dataObj = {
      subsystem: "VAR360",
      controller: "VARSources",
      FunctionName: "apiKeyAdd",
      apiKey: this.apiKey
    };
    let resultCategoryTrend = getRouteData(dataObj);
    resultCategoryTrend.then(function(response, statusText, jqXHR) {
      if (response.data.STATUS) notifier.success(response.data.STATUSMESSAGE);

      self.saving = false;
    });
    await wait(500);
    this.$emit("generated");
  }
}
