import { render, staticRenderFns } from "./CommissionStatementImport.vue?vue&type=template&id=20f4c3b8&scoped=true"
import script from "./CommissionStatementImport.vue?vue&type=script&lang=tsx"
export * from "./CommissionStatementImport.vue?vue&type=script&lang=tsx"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f4c3b8",
  null
  
)

export default component.exports