





































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import axios from "axios";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import SecurityMultiSelect from "../components/SecurityMultiSelect.vue";
interface Events {
  initialLoad: { index };
}
interface commObj {
  USERID?: number;
}
interface usersObj {
  USERID: number;
  UFULLNAME?: string;
}
interface securityObj {
  SECURITYGROUPID: number;
  SECURITYGROUPNAME?: string;
}

@Component({
  inheritAttrs: false,
  components: {
    EmployeeMultiselect,
    SecurityMultiSelect,
    LaddaButton
  }
})
export default class customerDetails extends TSXComponent<Events> {
  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: true })
  accid!: string;

  @Prop({ required: true })
  commAcc!: string;

  @Prop({ required: true })
  titlename!: string;

  @Prop({ required: true })
  aid!: number;

  @Prop({ required: true })
  selectedusers!: usersObj[];

  @Prop({ required: true })
  selectedUserIds!: number[];

  @Prop({ required: true })
  selectedgroups!: securityObj[];

  @Prop({ required: true })
  selectedSecurityIds!: number[];

  @Prop({ required: true })
  allusers!: usersObj[];

  @Prop({ required: true })
  allgroups!: securityObj[];

  saving: boolean | string = false;
  selecteduserstotal = this.selectedUserIds.length;
  originalSelectedUserIds: number[] = [];
  originalselectedSecurityIds: number[] = [];
  isUserEdited = false;
  isSecurityGroupEdited = false;
  isInitial = true;
  securityInitial = true;
  // selectedUserIds: (number | undefined)[] = [];
  // selectedSecurityIds: (number | undefined)[] = [];

  created() {
    if (this.isInitial) {
      this.selectedUserIdsChanged(this.selectedUserIds);
    }
    if (this.securityInitial) {
      this.selectedSecurityIdsChanged(this.selectedSecurityIds);
    }
  }

  // user multi Select
  toggleSelectedUserId(userId, name) {
    const index = this.selectedUserIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedUserIds.push(userId);
      this.selectedusers.push({
        USERID: userId,
        UFULLNAME: name
      });
      return;
    }
    this.selectedUserIds.splice(index, 1);
    this.$delete(this.selectedusers, index);
  }
  selectAllUsers() {
    this.resetUsers();
    var $this = this;
    this.selectedUserIds = this.allusers.map(u => u.USERID);
    $.each(this.allusers, function(i, val) {
      $this.selectedusers.push({
        USERID: val.USERID,
        UFULLNAME: `${val.UFNAME} ${val.ULNAME}`
      });
    });
  }
  resetUsers() {
    this.selectedUserIds = [];
    this.selectedusers = [];
  }

  // multi Security groups select
  toggleSelectedGroupId(userId, accountName) {
    const index = this.selectedSecurityIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedSecurityIds.push(userId);
      this.selectedgroups.push({
        SECURITYGROUPNAME: accountName,
        SECURITYGROUPID: userId
      });
      return;
    }
    this.selectedSecurityIds.splice(index, 1);
    this.$delete(this.selectedgroups, index);
  }
  selectAllGroups() {
    this.resetGroups();
    var $this = this;
    this.selectedSecurityIds = this.allgroups.map(u => u.SECURITYGROUPID);

    $.each(this.allgroups, function(i, val) {
      $this.selectedgroups.push({
        SECURITYGROUPNAME: val.SECURITYGROUPNAME,
        SECURITYGROUPID: val.SECURITYGROUPID
      });
    });
  }
  resetGroups() {
    this.selectedSecurityIds = [];
    this.selectedgroups = [];
  }
  updateSGList(type, index, id) {
    if (type == "delete" && this.selectedgroups.length > 1) {
      this.$delete(this.selectedgroups, index);
      this.$delete(this.selectedSecurityIds, index);
      $(".addSG option[val=" + id + "]").attr("disabled", false);
      $(".addSG option[val=" + id + "]").attr("class", "removelight");
    } else if (type == "delete" && this.selectedgroups.length == 1) {
      this.selectedgroups[0].SECURITYGROUPID = 2;
      this.selectedgroups[0].SECURITYGROUPNAME = "DEFAULT";

      $(".addSG option").attr("disabled", false);
      $(".addSG option").attr("class", "removelight");
    }
    this.$forceUpdate();
  }

  updateUserList(type, index, aid) {
    if (type == "delete") {
      this.$delete(this.selectedusers, index);
      this.$delete(this.selectedUserIds, index);
      this.selecteduserstotal = this.selectedusers.length;
      $(".addAccount option[val=" + aid + "]").attr("disabled", false);
      $(".addAccount option[val=" + aid + "]").attr("class", "removelight");
    }
  }

  validateBeforeSubmit(e) {
    if(e) {
      e.preventDefault();
      e.stopPropagation();
    }
    var comInstance = this;
    var userList: number[] = [];
    $.each(comInstance.selectedusers, function(i, val) {
      userList.push(val.USERID);
    });

    if(!(this.isUserEdited || this.isSecurityGroupEdited)) {
      return;
    }

    //detect selected security groups
    var sgList: number[] = [];
    $.each(comInstance.selectedgroups, function(i, val) {
      sgList.push(val.SECURITYGROUPID);
    });
    comInstance.saving = true;
    return getRouteData({
      controller: "Accounts",
      FunctionName: "Update",
      aID: comInstance.aid,
      userList: userList.join(","),
      sgList: sgList.join(",")
    }).then(function(response, statusText, jqXHR) {
      if (response.data.ERROR) {
        // console.log(response.data.ERROR);
      }
      if (response.data.STATUSSUB == 3) {
        notifier.warning(response.data.MESSAGESUB);
      } else if (response.data.STATUS == 1) {
        // if (data.ACCOUNTID != comInstance.currentCustomID) {
        //   comInstance.$router.push({
        //     name: "ViewCustomer",
        //     params: { id: comInstance.currACCID }
        //   });
        // } else {
        comInstance.$emit("initialLoad", 8);
        // }
        notifier.success('Security updated successfully.');
        comInstance.saving = false;
        comInstance.originalSelectedUserIds = [ ...comInstance.selectedUserIds ];
        comInstance.originalselectedSecurityIds = [ ...comInstance.selectedSecurityIds ];
        comInstance.isUserEdited = false;
        comInstance.isSecurityGroupEdited = false;
        // router.push({ name: "Customers" });//go to customer list // As per DJ commennt to stay in same page.
      } else if (response.data.STATUS == 0) {
        comInstance.saving = "error";
        if (
          typeof response.data.ACCOUNTID_EXISTED != "undefined" &&
          response.data.ACCOUNTID_EXISTED
        ) {
          // comInstance.errorMsg.forAccountID = response.data.MESSAGE;
        }
      } else {
        // console.log("no status return from response");
      }
    });
  }

  @Watch("selectedUserIds", { deep: true })
  async selectedUserIdsChanged(val) {
    if (this.isInitial) {
      this.originalSelectedUserIds = [ ...this.selectedUserIds ];
      this.isInitial = false;
    }
    if (val.join(",") != this.originalSelectedUserIds.join(",")) {
      this.isUserEdited = true;
    } else {
      this.isUserEdited = false;
    }
  }

  @Watch("selectedSecurityIds", { deep: true})
  async selectedSecurityIdsChanged(val) {
    if (this.securityInitial) {
      this.originalselectedSecurityIds = [ ...this.selectedSecurityIds ];
      this.securityInitial = false;
    }
    if (val.join(",") != this.originalselectedSecurityIds.join(",")) {
      this.isSecurityGroupEdited = true;
    } else {
      this.isSecurityGroupEdited = false;
    }
  }
}
