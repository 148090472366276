






































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  value: any[];
  options: any[];
  placeholder: String;
  title: String;
}

interface Events {
  onClose: void
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class FilterCheckboxes extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  value!: any[];

  @Prop({ required: true })
  options!: any[];

  @Prop({ required: true })
  placeholder!: string;

  @Prop({ required: true })
  title!: string;

  hasChange = false;

  async created() {
  }

  toggleOption(value) {
    if (!this.value) {
      this.value = [];
    }
    if (this.value.includes(value)) {
      this.value = this.value.filter((id) => id != value);
    } else {
      this.value.push(value);
    }
    this.$emit('update', this.value);
    this.hasChange = true;
  }

  uncheckAll() {
    this.value = [];
    this.hasChange = true;
    this.$emit('update', []);
  }

  reset() {
    this.$emit('reset');
  }

  clickOutside() {
    if (this.hasChange) {
      this.hasChange = false;
      this.$emit('filter');
    } else {
      this.hasChange = false;
    }
  }

  getFilterValue() {
    let filter = '';
    let searchKey: string | undefined = '';
    const selectedOptions = this.options.filter((item) => this.value.includes(item.value));
    if (selectedOptions.length && this.options.length != selectedOptions.length) {
      filter = selectedOptions.map(item => item.text).join(', ');
    }
    searchKey = '';
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }
}
