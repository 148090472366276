var render, staticRenderFns
import script from "./AutoSuggestInput_poID.vue?vue&type=script&lang=tsx"
export * from "./AutoSuggestInput_poID.vue?vue&type=script&lang=tsx"
import style0 from "./AutoSuggestInput_poID.vue?vue&type=style&index=0&id=f45590ec&prod&scoped=true&lang=less"
import style1 from "./AutoSuggestInput_poID.vue?vue&type=style&index=1&id=f45590ec&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f45590ec",
  null
  
)

export default component.exports