


























































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import directives from "../helpers/directives";


declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;

@Component({
  inheritAttrs: false,
  components: {
  },
  directives
})
export default class CustomerLanding extends TSXComponent<void> {
  loading = false;
  async created() {
  }

  mounted() {
  }
  @Prop({ required: false, default: true })
  ispage!: boolean;
}
