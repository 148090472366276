
























































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import axios from "axios";
import PhoneInput from "../components/PhoneInput.vue";
import DropdownControl from "../components/DropdownControl.vue";
import USState from "../static/USstates.json";
import PageTitle from '../components/pageTitle.vue';

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    PhoneInput,
    DropdownControl,
    PageTitle
  }
})
export default class CreateEndUser extends TSXComponent<void> {
  saving: any = false;
  deploymentId = "";
  title = "Add end user";
  endUserId = "";
  stateList: any = [];
  selectedState: string[] = [];
  loading: string | boolean = false;
  endUsers = {
    FIRSTNAME: "",
    LASTNAME: "",
    ADDRESS1: "",
    ADDRESS2: "",
    CITY: "",
    STATE: "",
    ZIP: "",
    PHONE: "",
    PHONEEXT:"",
    EMAIL: ""
  };
  action = ""

  $refs!: {
    drodown: DropdownControl;
  };

  created() {
    this.deploymentId = this.$route.params.id;
    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
    if (typeof this.$route.params.endUserId != "undefined") {
      this.endUserId = this.$route.params.endUserId;
      this.title = "Update end user";
      this.action = "Update";
      this.fetchData();
    }
  }

  async fetchData() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        action: "editEndUser",
        controller: "Deployments",
        FunctionName: "EndUserDetails",
        deploymentId: this.deploymentId,
        endUserId: this.endUserId
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      if (response.data.STATUS == 1) {
        this.endUsers = response.data.EUDETAILS[0];
        this.selectedState = [response.data.EUDETAILS[0].STATE];
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$validator.validateAll().then(async result => {
      if (result) {
        try {
          this.saving = true
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Deployments",
            FunctionName: "AddEndUser",
            firstName: this.endUsers.FIRSTNAME,
            lastName: this.endUsers.LASTNAME,
            state: this.endUsers.STATE,
            address1: this.endUsers.ADDRESS1,
            address2: this.endUsers.ADDRESS2,
            city: this.endUsers.CITY,
            phone: this.endUsers.PHONE,
            phoneExt: this.endUsers.PHONEEXT,
            email: this.endUsers.EMAIL,
            zip: this.endUsers.ZIP,
            deploymentId: this.deploymentId,
            endUserId: this.endUserId,
            action: this.action
          });
          if (response.data.ERROR) {
            throw new Error(response.data.ERROR);
          }
          if (response.data.STATUS == 1) {
            this.saving = false;
            this.$router.push({
              name: "ViewDeployment",
              params:{ id: this.deploymentId, activeTab: "endUsers" } 
            });
            notifier.success(response.data.STATUSMESSAGE);
          } else {
            this.saving = "error";
            notifier.alert(response.data.STATUSMESSAGE);
          }
        } catch (err) {
          // console.log(err.message);
        }
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  countryChanged(obj) {
		this.endUsers.PHONEEXT = obj.countryCode + ' ' + '+' + obj.countryCallingCode;
	}

  getExt(e){
		if(e != undefined){
			return e.split(' ')[0]
		}
		else{
			return 'US'
		}
	}
  addUserState(selectedID) {
    this.selectedState = [selectedID];
    this.endUsers.STATE = selectedID;
  }
  close(){
    this.$refs.drodown.dropdownVisible = false;
	}
  detailPageRedirection(){
    this.$router.push({
      name: "ViewDeployment",
      params:{ id: this.deploymentId } 
    });
  }
}
