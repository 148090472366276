

































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { LedgersAPIRepo, LedgerReportSort, ledgerReportSortField, LedgerReportFilters, ReportStatus } from "../repos/LedgersAPIRepo";
import LedgerReportStatus from "../components/LedgerReportStatus.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import { notifier } from "../models/common";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { getRangeFilter } from "@/helpers/ApiHelper";

const reportRepo = new LedgersAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    LedgerReportStatus,
    FilterCurrencyBox,
    ListHeaderActions
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    }
  }
})

export default class ledgerReports extends TSXComponent<void> {
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  isSearch = 0;
  totalAll = 0;
  total = 0;
  totalReportItems = 0;
  ret = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1
  };
  pageNumber = 1; 
  viewType = "CurrentUsers";
  ledgerReportDetails = [];
  ledgerReportFilters: LedgerReportFilters = {
    Order: "",
    Name: "",
    varianceMin: "",
    varianceMax: "",
    ReportStatus: [1,0],
    reportStatusName: Object.keys(ReportStatus).filter((key) => isNaN(Number(key))),
    Reason: ""
  };
  ledgerReportSort: LedgerReportSort = {
    field: 0,
    direction: {
      [ledgerReportSortField.Order]: 1,
      [ledgerReportSortField.Name]: 1,
      [ledgerReportSortField.variance]: 1,
      [ledgerReportSortField.Status]: 1,
      [ledgerReportSortField.Reason]: 1
    }
  };
  searchFilters: LedgerReportFilters = {
    Order: "",
    Name: "",
    varianceMin: "",
    varianceMax: "",
    ReportStatus: [1,0],
    reportStatusName: Object.keys(ReportStatus).filter((key) => isNaN(Number(key))),
    Reason: ""
  };
  

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const ret = await reportRepo.findReportDetails(
      this.pageNumber,
      this.viewType,
      this.ledgerReportSort,
      this.ledgerReportFilters
    );
    this.ret = ret;
    this.ledgerReportDetails = ret.LEDGERREPORTDETAILS;
    this.isSearch = ret.ISSEARCH;
    this.totalAll = ret.TOTALALL;
    this.total = ret.TOTAL;
    this.totalReportItems = ret.TOTALREPORTSITEMS;
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if (this.pageNumber <= this.ret.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async sortBy(field: ledgerReportSortField) {
    this.ledgerReportSort.field = field;
    this.ledgerReportSort.direction[field] = this.ledgerReportSort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.ledgerReportSort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.ledgerReportSort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingByOrder() {
    await this.sortBy(ledgerReportSortField.Order);
  }

  async sortingByName() {
    await this.sortBy(ledgerReportSortField.Name);
  }

  async sortingByVariance() {
    await this.sortBy(ledgerReportSortField.variance);
  }

  async sortingByStatus() {
    await this.sortBy(ledgerReportSortField.Status);
  }

  async sortingByReason() {
    await this.sortBy(ledgerReportSortField.Reason);
  }

  async searchByOrder() {
    this.pageNumber = 1;
    if (this.searchFilters.Order == "") {
      return;
    } else {
      this.ledgerReportFilters.Order = this.searchFilters.Order;
      await this.fetchData();
    }
  }
  
  async resetsearchByOrder() {
    this.pageNumber = 1;
    this.ledgerReportFilters.Order = this.searchFilters.Order = "";
    await this.fetchData();
  }

  async searchByName() {
    this.pageNumber = 1;
    if (this.searchFilters.Name == "") {
      return;
    } else {
      this.ledgerReportFilters.Name = this.searchFilters.Name;
      await this.fetchData();
    }
  }
  
  async resetsearchByName() {
    this.pageNumber = 1;
    this.ledgerReportFilters.Name = this.searchFilters.Name = "";
    await this.fetchData();
  }

  async searchByVariance() {
    this.pageNumber = 1;
    this.ledgerReportFilters.varianceMin = this.searchFilters.varianceMin;
    this.ledgerReportFilters.varianceMax = this.searchFilters.varianceMax;
    await this.fetchData();
  }
  async resetSearchByVariance() {
    this.pageNumber = 1;   
    this.ledgerReportFilters.varianceMin = this.searchFilters.varianceMin = "";
    this.ledgerReportFilters.varianceMax = this.searchFilters.varianceMax = "";
    await this.fetchData();
  }

  async searchByReason() {
    this.pageNumber = 1;
    if (this.searchFilters.Reason == "") {
      return;
    } else {
      this.ledgerReportFilters.Reason = this.searchFilters.Reason;
      await this.fetchData();
    }
  }
  
  async resetsearchByReason() {
    this.pageNumber = 1;
    this.ledgerReportFilters.Reason = this.searchFilters.Reason = "";
    await this.fetchData();
  }

  async checkLedgerReportStatusList(status: number, name: string) {
    this.pageNumber = 1;   
    const i = this.ledgerReportFilters.ReportStatus.findIndex(s => s === status);
    const statusIndex = this.ledgerReportFilters.reportStatusName.findIndex(
      statusValue => statusValue === name
    );
    if (i === -1) {
      this.ledgerReportFilters.ReportStatus.push(status);
    } else {
      this.ledgerReportFilters.ReportStatus.splice(i, 1);
    }
    if (statusIndex === -1) {
      this.ledgerReportFilters.reportStatusName.push(name);
    } else {
      this.ledgerReportFilters.reportStatusName.splice(statusIndex, 1);
    }
    await this.fetchData();
  }

  async checkAllLedgerReportStatusList() {
    this.pageNumber = 1;
    this.ledgerReportFilters.ReportStatus = [
      ReportStatus.Open,
      ReportStatus.Close
    ];
    await this.fetchData();
  }

  async uncheckAllLedgerReportStatusList() {
    this.pageNumber = 1;
    this.ledgerReportFilters.ReportStatus = [-1];
    this.ledgerReportFilters.reportStatusName = [];
    await this.fetchData();
  }

  async resetLedgerReportStatusList() {
    this.pageNumber = 1; 
    this.ledgerReportFilters.ReportStatus = [];
    this.ledgerReportFilters.reportStatusName = Object.keys(ReportStatus).filter((key) => isNaN(Number(key)));
    this.checkAllLedgerReportStatusList();
  }

  async changeStatus(data, purchaseID) {
    const ret = await reportRepo.updateStatus(
      purchaseID,
      data.status,
      data.reasonCancel
    );
    await this.fetchData();
    notifier.success(`Order ${purchaseID}'s Status Updated Successfully`);
  }
  
  getPreDefinedFilter(filter) {
    if (filter.length != undefined && filter.length == 0) {
      return "None";
    } else if (!((Object.keys(ReportStatus).length / 2) == filter.length)) {
      return filter.join(", ");
    }
  }
}
