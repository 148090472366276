














import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";

import { InvoiceLineItem } from "../models/invoice";
import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import { AccountList } from "../models/AccountList";

interface Props {
  defaultText?: string
  value: string
  getVarAllocation: number
  placeholderText: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    account: AccountList.Accounts,
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
})
export default class UserAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  show?: boolean

  @Prop({ required: false, default : 0 })
  getVarAllocation?: number

  @Prop({ required: false, default : "" })
  placeholderText?: string

  @Prop({ required: false, default: undefined })
  customSearch?: Function

  @Prop({ required: false, default : 0 })
  sourceList?: number

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = []
  searchData: AccountList.Accounts[] = []

  createOptions(accounts: AccountList.Accounts[]) {
    const options = accounts.map(
      (acc): Option => {
        return {
          value: acc.USERID.toString(),
          text: acc.UFNAME + ' ' + acc.ULNAME
        };
      }
    )

    this.options = options
    this.searchData = accounts

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if(this.customSearch) {
      const items = await this.customSearch(search);
      return this.createOptions(items);
    }

    if (!search) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        "FunctionName": "List",
        "controller": "Users"
      });
      if (response.data.ERROR || !response.data.USERS) {
        return {
          options: []
        };
      }

      return this.createOptions(response.data.USERS)
    }

    const exportObj = {
      FunctionName: "List",
      controller: "Users",
      getAll: 1,
      search,
    };
    if(this.getVarAllocation == 1) {
      exportObj["getVarAllocation"] = this.getVarAllocation;
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    if (response.data.ERROR || !response.data.USERS) {
      return {
        options: []
      };
    }

    return this.createOptions(response.data.USERS)
  };

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      // console.log('this.options', this.options)
      // console.warn(`no option found for value[${value}]`)
      return
    }
    const account = this.searchData.find((item) => item.USERID.toString() === value)
    if (!account) {
      console.warn(`no account found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      account,
    })
  }
}
