
















































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { roundCents } from "../models/common";
import InvoiceFormHeader from "../components/InvoiceFormHeader.vue";
import InvoiceFormBasicDetails from "../components/InvoiceFormBasicDetails.vue";
import InvoiceFormLineItemList from "../components/InvoiceFormLineItemList.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { InvoiceAPIRepo, InvoiceForm } from "../repos/InvoiceAPIRepo";
import { notifier } from "../models/common";
import directives from "../helpers/directives";
import PageTitle from "../components/pageTitle.vue";
import SubOrderEndUserCustomData from "@/components/Order/SubOrderEndUserCustomData.vue";

const invoiceRepo = new InvoiceAPIRepo();
declare const dataURL: string;
declare const $: any;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;

@Component({
  inheritAttrs: false,
  components: {
    InvoiceFormHeader,
    InvoiceFormBasicDetails,
    InvoiceFormLineItemList,
    LaddaButton,
    PageTitle,
    SubOrderEndUserCustomData
  },
  directives
})
export default class InvoiceCreate extends TSXComponent<void> {
  loading = false;
  saving: boolean | "error" = false;
  title = "New Invoice";
  autoName = 1;
  autoCost = 0;
  objDate = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "America/Denver",
      hour12: false
    })
  );
  tdyDate = [
    (this.objDate.getMonth() + 1 < 10 ? "0" : "") +
      (this.objDate.getMonth() + 1),
    (this.objDate.getDate() < 10 ? "0" : "") + this.objDate.getDate(),
    this.objDate.getFullYear()
  ].join("/");
  duedateObj = new Date(this.objDate.setDate(this.objDate.getDate() + 30));
  invDue = [
    (this.duedateObj.getMonth() + 1 < 10 ? "0" : "") +
      (this.duedateObj.getMonth() + 1),
    (this.duedateObj.getDate() < 10 ? "0" : "") + this.duedateObj.getDate(),
    this.duedateObj.getFullYear()
  ].join("/");
  inlineOrderItems: {
    POPRODDESC?: string;
    POPRODSKU?: string;
    POPRICEREG?: number;
    POCUSTOMERPRICE?: number;
    POPRODQUANTITY?: number;
    POINCLUDED?: number;
    POFINALPRICE?: number;
    LICUSTOMERTOTALPRICE?: number;
  }[] = [];
  totalTax = 0;
  totalShipping = 0;
  summaryTotal = 0.0;
  summaryTotalFormat = "0.00";
  grandTotal = 0.0;
  invBasicDetails = {
    invoiceId: "[Auto Generate]",
    invoiceName: "",
    PO: "",
    shippingId: "",
    customer: {},
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    addressBilling: "",
    address2Billing: "",
    cityBilling: "",
    stateBilling: "",
    zipBilling: "",
    invoiceDate: this.tdyDate,
    invoiceDueDate: this.invDue,
    sku: "",
    purchaseID: 0,
    iNotes: "",
    customID: 0,
    customValue: ""
  };
  inEditPage = false;
  isfromOrder = false;
  fromOrderId = 0;
  taxRateMessage = "";
  usedVariedTaxRate = false;
  $router: any;
  $route: any;
  USECUSTOMTAX = 0;
  orderContractNumber: object[] = [];
  customDropdown: object[] = [];
  selectedCustomDropdown: number[] = [];
  expandDecimal = false;
  expandDecimalPrecision = 2;
  orderGrandTotal = 0;
  currentTaxRate = 0;
  splitBySubOrderId: any = [
    {
      subOrderId: 0
    }
  ];
  selectedSubOrder: any = {};
  euCustomDataVisible = false;
  cardInfo: any = {
    stripeCardId: "",
    stripeCustomerId: "",
    savedCardData: {}
  };

  async created() {
    //case edit invoice
    if (
      typeof this.$route.params.id != "undefined" &&
      parseInt(this.$route.params.id) != 0
    ) {
      this.inEditPage = true;
      await this.editInvoice(this.$route.params.id);
    } else {
      await this.getInitData();
    }

    //convert to invoice
    if (
      typeof this.$route.params.fromOrder != "undefined" &&
      parseInt(this.$route.params.fromOrder) != 0
    ) {
      this.fromOrderId = parseInt(this.$route.params.fromOrder);
      this.isfromOrder = true;
      await this.getOrderDetail(this.$route.params.fromOrder);
    }

    await this.showCustomData();
  }

  async getInitData() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "varUSers",
      FunctionName: "View",
      subsystem: "VAR360",
      getDefaultINotes: 1
    });
    if (response.data.STATUS == 1) {
      this.invBasicDetails.iNotes = response.data.defaultINotes;
    }
  }

  listPageRedirection() {
    if (this.isfromOrder) {
      // this.$router.push({
      //   name: "ViewOrder",
      //   params: { id: this.$route.params.fromOrders }
      // });s
      this.$router.push({ path: "/orderDetails/" + this.fromOrderId });
    } else {
      this.$router.push({
        name: "InvoiceDetails",
        params: { id: this.$route.params.id }
      });
    }
  }

  edit(type) {
    if (
      $("li." + type)
        .find("input")
        .hasClass("displayNone")
    ) {
      $("li." + type)
        .find("strong")
        .addClass("displayNone");
      $("li." + type)
        .find("input")
        .removeClass("displayNone");
    } else {
      $("li." + type)
        .find("input")
        .addClass("displayNone");
      $("li." + type)
        .find("strong")
        .removeClass("displayNone");

      //recalculate in case re-click edit icon to hide textbox tax/ship
      if (type == "tax" || type == "shipping") {
        this.updateTotalTaxShip(event, type, true);
      }
    }
  }

  updateTotalTaxShip(event, type, forceUpdate = false) {
    if (event.key == "Enter" || event.key == "Tab" || forceUpdate) {
      var tax =
        $("li.tax input").val() != ""
          ? parseFloat($("li.tax input").val())
          : 0.0;
      var shipping =
        $("li.shipping input").val() != ""
          ? parseFloat($("li.shipping input").val())
          : 0.0;

      this.grandTotal = this.summaryTotal + tax + shipping;
      this.totalTax = tax;
      this.totalShipping = shipping;

      $("li." + type)
        .find("input")
        .addClass("displayNone");
      $("li." + type)
        .find("strong")
        .removeClass("displayNone");

      //case: user press TAB from custom tax box, go to custom shipping box so that user can input something there
      if (event.key == "Tab" && type == "tax") {
        $("li.shipping")
          .find("strong")
          .addClass("displayNone");
        $("li.shipping")
          .find("input")
          .removeClass("displayNone");
        setTimeout(() => {
          $("li.shipping")
            .find("input")
            .focus()
            .select();
        }, 100);
      }
    }
  }

  closeInput(type) {
    $("li." + type)
      .find("input")
      .addClass("displayNone");
    $("li." + type)
      .find("strong")
      .removeClass("displayNone");

    if (type == "tax" || type == "shipping" || type == "estPercent") {
      this.updateTotalTaxShip(event, type, true);
    }
  }

  async getOrderDetail(ordID) {
    if (ordID > 0) {
      this.loading = true;
      // try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Invoices",
        FunctionName: "PrepareInvoiceData",
        purchaseID: ordID
        // will remove below later
        // invoiceAdd: true,
        // Content: "Detailed",
        // resellerCreateInvoice: true,
        // orderInvoiceLineItems: true
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        var orderDetails = response.data.ORDERDETAILS;
        // To get expandDecimal details
        this.getExpandDecimal(response.data.orderParams);
        this.invBasicDetails.PO = orderDetails.POID;
        this.invBasicDetails.customer = {
          value: orderDetails.ACCOUNTNAME,
          aID: orderDetails.AID
        };
        // this.invBasicDetails.address = orderDetails.AADDRESS;
        // this.invBasicDetails.city = orderDetails.ACITY;
        // this.invBasicDetails.state = orderDetails.ASTATE;
        // this.invBasicDetails.zip = orderDetails.AZIP;
        this.invBasicDetails.purchaseID = parseInt(ordID);
        this.USECUSTOMTAX = response.data.USECUSTOMTAX || 0;

        // populate values for cardInfo
        const orderParams = response.data.orderParams || {};
        if (
          !(orderParams.stripeTokenCaptured || 0) &&
          (orderParams.stripeCardId || "") != "" &&
          (orderParams.stripeCustomerId || "") != ""
        ) {
          this.cardInfo.stripeCardId = orderParams.stripeCardId;
          this.cardInfo.stripeCustomerId = orderParams.stripeCustomerId;
          this.cardInfo.savedCardData = orderParams.savedCardData || {};
        }

        //line items
        var totalItem = orderDetails.CUSTOMERTOTALPRICE;
        var items: any = {};
        var i = 0;
        for (var item of response.data.ORDERITEMS) {
          i += 1;
          items["productName_" + i] = {
            description: item.POPRODDESC,
            partNo: item.POPRODSKU,
            unitPrice: item.POCUSTOMERPRICE,
            quantity: item.POPRODQUANTITY || 0,
            included: item.POINCLUDED,
            ISCONFIG: item.ISCONFIG || 0,
            estPrice: item.LICUSTOMERTOTALPRICE,
            ItemCategory:
              typeof item.POCATEGORY != "undefined" ? item.POCATEGORY : 0,
            ItemCategoryName:
              typeof item.CATEGORYNAME != "undefined" ? item.CATEGORYNAME : "",
            taxable: this.getItemTaxable({
              included: item.POINCLUDED,
              noTax: item.NOTAX,
              taxRate: item.POTAXRATE
            }),

            noTax: item.NOTAX,
            taxRate: item.POTAXRATE,
            taxAmount:
              parseFloat(
                roundCents(
                  (item.LICUSTOMERTOTALPRICE * item.POTAXRATE) / 100
                ).toFixed(2)
              ) || 0,
            totalAmount:
              item.LICUSTOMERTOTALPRICE +
              parseFloat(
                roundCents(
                  (item.LICUSTOMERTOTALPRICE * item.POTAXRATE) / 100
                ).toFixed(2)
              ),
            includedparent: item.INCLUDEDPARENT || 0,
            baseProductId: item.POLIID || 0,
            includeItemCount: item.INCLUDEITEMCOUNT || 0,
            POliID: item.POLIID || 0,
            shippingDate: item.SHIPPINGDATE || "",
            subOrderId: item.SUBORDERID || 0,
            CONFIGCOUNT: item.CONFIGCOUNT || 0,
            savedAsConfig: item.ISCONFIG || 0
          };
        }
        this.taxRateMessage = this.getTaxRateMessage(Object.assign({}, items));

        //add last empty row
        items["productName_" + (i + 1)] = {
          description: "",
          partNo: "",
          unitPrice: 0.0,
          quantity: 0,
          included: 0,
          estPrice: 0.0,
          ItemCategory: 0,
          ItemCategoryName: "",
          taxable: false,
          noTax: 0,
          taxRate: 0,
          totalAmount: 0,
          taxAmount: 0,
          includedparent: 0,
          baseProductId: 0,
          includeItemCount: 0,
          POliID: 0,
          shippingDate: ""
        };

        this.summaryTotalFormat = totalItem
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        this.summaryTotal = totalItem;
        this.totalTax = response.data.TOTALTAX;
        this.totalShipping = response.data.TOTALSHIPPING;
        this.grandTotal = response.data.GRANDTOTAL;
        this.$refs.invoiceFormLineItemList.itemNames = items;
        //Set tax rate if any
        if (
          typeof orderDetails.POTAXRATE != "undefined" &&
          orderDetails.POTAXRATE != ""
        ) {
          this.currentTaxRate = orderDetails.POTAXRATE;
        }
        if (
          typeof response.data.orderParams.onetimeAddress != "undefined" &&
          Object.keys(response.data.orderParams.onetimeAddress).length
        ) {
          // used one time shipping address for this order
          this.$refs.invoiceFormBasicDetails.selectedAddress = [0];
          this.$refs.invoiceFormBasicDetails.onetimeAddress = Object.assign(
            this.$refs.invoiceFormBasicDetails.onetimeAddress,
            response.data.orderParams.onetimeAddress
          );
        }
        if (
          typeof response.data.orderParams.onetimeAddressBilling !=
            "undefined" &&
          Object.keys(response.data.orderParams.onetimeAddressBilling).length
        ) {
          // used one time billing address for this order
          this.$refs.invoiceFormBasicDetails.selectedAddressBilling = [0];
          this.$refs.invoiceFormBasicDetails.onetimeAddressBilling = Object.assign(
            this.$refs.invoiceFormBasicDetails.onetimeAddressBilling,
            response.data.orderParams.onetimeAddressBilling
          );
        }
        var defaultShipping = 0;
        if (response.data.orderParams.DefaultShippingID) {
          defaultShipping = parseInt(
            response.data.orderParams.DefaultShippingID
          );
        }
        if (orderDetails.AID > 0) {
          await this.$refs.invoiceFormBasicDetails.updateAddressForCustomer(
            orderDetails.AID,
            {
              AADDRESS: orderDetails.AADDRESS || "",
              ACITY: orderDetails.ACITY || "",
              ASTATE: orderDetails.ASTATE || "",
              AZIP: orderDetails.AZIP || ""
            },
            defaultShipping
          );
        }
        if (
          (defaultShipping > 0 ||
            (typeof response.data.orderParams.onetimeAddress != "undefined" &&
              Object.keys(response.data.orderParams.onetimeAddress).length)) &&
          orderDetails.AID > 0
        ) {
          this.$refs.invoiceFormBasicDetails.updateCustomerAddress(
            defaultShipping
          );
        }
        var defaultBilling = 0;
        if (
          typeof response.data.orderParams.DefaultBillingID != "undefined" &&
          response.data.orderParams.DefaultBillingID != ""
        ) {
          defaultBilling = parseInt(response.data.orderParams.DefaultBillingID);
        }
        if (
          (defaultBilling > 0 ||
            (typeof response.data.orderParams.onetimeAddressBilling !=
              "undefined" &&
              Object.keys(response.data.orderParams.onetimeAddressBilling)
                .length)) &&
          orderDetails.AID > 0
        ) {
          this.$refs.invoiceFormBasicDetails.updateCustomerAddressBilling(
            defaultBilling
          );
        }
        this.orderGrandTotal = response.data.ORDERPAYMENTTOTAL;
        // To list the selected address on the top
        if (this.$refs.invoiceFormBasicDetails.customerAddresses.length) {
          this.sortingAddresses(
            this.$refs.invoiceFormBasicDetails.customerAddresses,
            this.$refs.invoiceFormBasicDetails.selectedAddress
          );
        }
        if (
          this.$refs.invoiceFormBasicDetails.customerAddressesBilling.length
        ) {
          this.sortingAddresses(
            this.$refs.invoiceFormBasicDetails.customerAddressesBilling,
            this.$refs.invoiceFormBasicDetails.selectedAddressBilling
          );
        }

        const splitBySubOrderId = response.data.splitBySubOrderId || [];
        if (splitBySubOrderId.length) {
          this.splitBySubOrderId = splitBySubOrderId;
        }
      }

      this.loading = false;
      // } catch (err) {
      //   console.log(err.message);
      // } finally {
      //   this.loading = false;
      // }
    }
  }

  async showCustomData() {
    if (
      (typeof this.$route.params.fromOrder != "undefined" &&
        parseInt(this.$route.params.fromOrder) != 0) ||
      (typeof this.invBasicDetails.purchaseID != "undefined" &&
        this.invBasicDetails.purchaseID != 0)
    ) {
      // try {
      var PID = 0;
      if (
        typeof this.$route.params.fromOrder != "undefined" &&
        parseInt(this.$route.params.fromOrder) != 0
      ) {
        PID = parseInt(this.$route.params.fromOrder);
      } else if (
        typeof this.invBasicDetails.purchaseID != "undefined" &&
        this.invBasicDetails.purchaseID != 0
      ) {
        PID = this.invBasicDetails.purchaseID;
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomData",
        DataType: "1",
        Id: PID,
        contractDropdown: 26,
        datatype: 1,
        invoiceCreate: 1
      });

      if (response.data.STATUS == 1) {
        this.customDropdown = response.data.CUSTOMDEFINITION.filter(
          tmp => tmp.CUSTOMDTYPE === 3
        );
        this.orderContractNumber = response.data.customContractNumber;
        let tmpValue: any = [];
        if (typeof response.data.selectedContractData != undefined) {
          tmpValue = response.data.selectedContractData;
        }
        this.selectedCustomDropdown = [
          tmpValue.length ? tmpValue[0].CUSTOMFIELDOPTIONID : ""
        ];
        this.invBasicDetails.customID = tmpValue.length
          ? tmpValue[0].CUSTOMID
          : 0;
        this.invBasicDetails.customValue = tmpValue.length
          ? tmpValue[0].CUSTOMVALUE
          : "";
        // this.orderContractNumber = response.data.customContractNumber;
      }
      // } catch (err) {
      //   //handle error
      // }
    }
  }

  getTotals(total) {
    this.summaryTotal = total;
    this.summaryTotalFormat = parseFloat(total)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");

    // calculate total tax
    const items = this.$refs.invoiceFormLineItemList.itemNames;
    // let totalTax = 0;
    let totalTaxable = 0;
    for (const i in items) {
      if (items[i].taxable || false) {
        // const itemTax = parseFloat(
        //   ((items[i].estPrice * items[i].taxRate) / 100).toFixed(2)
        // );
        totalTaxable += items[i].estPrice;
      }
    }
    let totalTax = parseFloat(
      roundCents((totalTaxable * this.currentTaxRate) / 100).toFixed(2)
    );
    // no update total tax if using a custom tax
    if (this.USECUSTOMTAX == 0) {
      this.totalTax = totalTax;
    }

    this.grandTotal = this.totalShipping + this.totalTax + parseFloat(total);
  }

  async onSubmit() {
    //### trying to validate at one place, not in child components ###
    $("#errorMsgLineInvoice").html("");
    $("input.initial").removeClass("initial");
    var result = true;
    const [basicDetailsResult, lineItemsResult] = await Promise.all([
      this.$refs.invoiceFormBasicDetails.$validator.validateAll(),
      this.$refs.invoiceFormLineItemList.$validator.validateAll()
    ]);

    //### ignore validate on last row/empty row ###
    var invoiceFormLineItemList = this.$refs.invoiceFormLineItemList;
    var removeFields: any = [];
    var emptyRows = 0;
    for (let i in this.$refs.invoiceFormLineItemList.itemNames) {
      let item = this.$refs.invoiceFormLineItemList.itemNames[i];
      if (item.partNo == "" && item.description == "" && item.quantity == 0) {
        //empty row
        emptyRows += 1;
        for (var fieldName of [
          "desc_",
          "partNo_",
          "unitPrice_",
          "quantity_",
          "estPrice_"
        ]) {
          removeFields.push(fieldName + i);
        }
      } else if (item.included) {
        //included
        removeFields.push("quantity_" + i);
      }
    }
    for (let i in removeFields) {
      this.$delete(
        this.$validator.errors.items,
        this.$validator.errors.items.findIndex(
          val => val.field == removeFields[i]
        )
      );
    }

    var notFullLineItem = false;
    for (var itemError of this.$validator.errors.items) {
      if (itemError.field.indexOf("productName_") != -1) {
        notFullLineItem = true;
        break;
      }
    }

    if (
      emptyRows ==
        Object.keys(this.$refs.invoiceFormLineItemList.itemNames).length ||
      notFullLineItem
    ) {
      $("#errorMsgLineInvoice").html(
        "Let's enter enough info about SKU, Product, Cost and Quantity, ..."
      );

      result = false;
    }
    if (this.$validator.errors.items.length) {
      result = false;
    }

    if (result) {
      const data = Object.keys(this.$refs.invoiceFormLineItemList.itemNames)
        .filter((key: string) => {
          const item = this.$refs.invoiceFormLineItemList.itemNames[key];
          return !!item.partNo;
        })
        .map((key: string) => {
          return this.$refs.invoiceFormLineItemList.itemNames[key];
        });

      const tax = this.totalTax ? this.totalTax : 0;
      const shipping = this.totalShipping ? this.totalShipping : 0;

      const selectedAddress = this.$refs.invoiceFormBasicDetails
        .selectedAddress;
      this.$refs.invoiceFormBasicDetails.invBasicDetails.DefaultShippingID = selectedAddress.length
        ? selectedAddress.join()
        : 0;
      if (selectedAddress.length && selectedAddress[0] == 0) {
        // if use a one time shipping address for this order
        this.$refs.invoiceFormBasicDetails.invBasicDetails.onetimeAddress = this.$refs.invoiceFormBasicDetails.onetimeAddress;
      }

      const selectedAddressBilling = this.$refs.invoiceFormBasicDetails
        .selectedAddressBilling;
      this.$refs.invoiceFormBasicDetails.invBasicDetails.DefaultBillingID = selectedAddressBilling.length
        ? selectedAddressBilling.join()
        : 0;
      if (selectedAddressBilling.length && selectedAddressBilling[0] == 0) {
        // if use a one time billing address for this order
        this.$refs.invoiceFormBasicDetails.invBasicDetails.onetimeAddressBilling = this.$refs.invoiceFormBasicDetails.onetimeAddressBilling;
      }
      this.$refs.invoiceFormBasicDetails.invBasicDetails.expandDecimal = this.expandDecimal;
      this.$refs.invoiceFormBasicDetails.invBasicDetails.invoiceType = this.$refs.invoiceFormBasicDetails.selectedInvTypeDetails;
      try {
        this.saving = true;
        const requestObj: any = {
          basicDetails: this.$refs.invoiceFormBasicDetails.invBasicDetails,
          linesItems: data,
          tax: tax,
          shipping: shipping,
          inEditPage: this.inEditPage
        };
        if (
          this.isfromOrder &&
          this.cardInfo.stripeCardId != "" &&
          this.cardInfo.stripeCustomerId != ""
        ) {
          // convert to invoice: send cardInfo if any
          requestObj.cardInfo = this.cardInfo;
        }
        const response = await invoiceRepo.create(requestObj);
        this.saving = false;
        if (
          typeof this.$route.params.fromOrder != "undefined" &&
          parseInt(this.$route.params.fromOrder) > 0
        ) {
          var ordID = this.$route.params.fromOrder;
          this.$router.push({
            name: "ViewOrder",
            params: {
              id: ordID,
              activeTab: "orderInvoices",
              msg: "success",
              invoiceID: response.data.INVOICEID || ""
            }
          });
        } else if (this.inEditPage) {
          //goto detail page
          this.$router.push({
            name: "InvoiceDetails",
            params: { id: this.$route.params.id }
          });
        } else {
          //go to list page
          this.$router.push({ name: "Invoices" });
        }
      } catch (err) {
        this.saving = "error";
      }
    }
  }

  $refs!: {
    invoiceFormBasicDetails: InvoiceFormBasicDetails;
    invoiceFormLineItemList: InvoiceFormLineItemList;
  };
  autoNameSwitch(val) {
    this.autoName = val;
  }
  autoCostSwitch(val) {
    this.autoCost = val;
  }
  // @Watch("invBasicDetails.customer", { deep: true })
  // async customerAddress(val, oldVal) {
  //   if (typeof val.value != "undefined" && val.value > 0) {
  //     const UserDetails = await invoiceRepo.getAddress(val.value);
  //     this.invBasicDetails.address = UserDetails.ACCOUNTDETAILS[0].AADDRESS;
  //     this.invBasicDetails.city = UserDetails.ACCOUNTDETAILS[0].ACITY;
  //     this.invBasicDetails.state = UserDetails.ACCOUNTDETAILS[0].ASTATE;
  //     this.invBasicDetails.zip = UserDetails.ACCOUNTDETAILS[0].AZIP;
  //   }
  // }

  getSKUCatTitle(item) {
    if (typeof item.ItemCategory == "undefined" || item.ItemCategory == 0) {
      return "Category not available";
    } else {
      return "Category: " + item.ItemCategoryName;
    }
  }

  productCatTTVisibleIndex = "";
  async showProductCatTT(item, index, field, e) {
    // if((field == "desc" && item.description == "") || (field == "sku" && item.partNo == "") || (field == "desc_convert" && item.POPRODDESC == "") || (field == "sku_convert" && item.POPRODSKU == "")) {
    if (item.partNo == "") {
      this.productCatTTVisibleIndex = "";
      // if(field == "sku" || field == "sku_convert") {
      item.ItemCategory = 0;
      item.ItemCategoryName = "";
      // }
      return;
    }
    if (this.productCatTTVisibleIndex === index) {
      return;
    }

    if (e.type == "click" || (e.type == "keydown" && this.autoName === 0)) {
      this.productCatTTVisibleIndex = index;
    }
  }

  async editInvoice(id) {
    this.title = "Update Invoice #" + id;
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Invoices",
        FunctionName: "View",
        Content: "Detailed",
        inEditPage: true,
        invoiceId: id
      });

      if (response.data.STATUS == 1) {
        var details = response.data.INVOICEDETAILS;
        this.invBasicDetails.invoiceId = details.INVOICEID;
        this.invBasicDetails.PO = details.POID;
        this.invBasicDetails.invoiceName = details.IDESCRIPTION;
        this.invBasicDetails.customer = {
          value: details.AACCOUNTNAME,
          aID: details.AID
        };
        // shipping addr
        this.invBasicDetails.address = details.IADDRESS;
        this.invBasicDetails.address2 = details.IADDRESS2;
        this.invBasicDetails.city = details.ICITY;
        this.invBasicDetails.state = details.ISTATE.toUpperCase();
        this.invBasicDetails.zip = details.IZIP;
        this.$refs.invoiceFormBasicDetails.selectedState = [
          this.invBasicDetails.state
        ];

        // billing addr
        this.invBasicDetails.addressBilling = details.IADDRESSBILLING;
        this.invBasicDetails.address2Billing = details.IADDRESS2BILLING;
        this.invBasicDetails.cityBilling = details.ICITYBILLING;
        this.invBasicDetails.stateBilling = details.ISTATEBILLING.toUpperCase();
        this.invBasicDetails.zipBilling = details.IZIPBILLING;
        this.$refs.invoiceFormBasicDetails.selectedStateBilling = [
          this.invBasicDetails.stateBilling
        ];

        this.invBasicDetails.purchaseID =
          details.PURCHASEID != "" ? details.PURCHASEID : 0;
        this.invBasicDetails.shippingId = details.SHIPPINGID;
        var iDate = new Date(details.IDATE);
        var iDueDate = new Date(details.IDUEDATE);
        this.invBasicDetails.invoiceDate = details.IDATEFORMATTED;
        this.invBasicDetails.invoiceDueDate = details.IDUEDATEFORMATTED;
        // this.invBasicDetails.invoiceDate = [
        //   iDate.getMonth() + 1,
        //   iDate.getDate(),
        //   iDate.getFullYear()
        // ].join("/");
        // this.invBasicDetails.invoiceDueDate = [
        //   iDueDate.getMonth() + 1,
        //   iDueDate.getDate(),
        //   iDueDate.getFullYear()
        // ].join("/");

        this.invBasicDetails.iNotes = details.INOTES || "";
        this.$refs.invoiceFormBasicDetails.$forceUpdate();

        //line items
        var totalItem = details.ITOTALPRICE;
        var items: any = {};
        var i = 0;
        for (var item of response.data.INVOICEITEMS) {
          i += 1;
          items["productName_" + i] = {
            description: item.IPRODDESC,
            partNo: item.IPRODSKU,
            unitPrice: item.IPRICEREG,
            quantity: item.IPRODQUANTITY,
            included: item.IPRICEINCLUDED,
            ISCONFIG: item.ISCONFIG || 0,
            estPrice: item.IPRICEACT,
            ItemCategory:
              typeof item.ICATEGORY != "undefined" ? item.ICATEGORY : 0,
            ItemCategoryName:
              typeof item.CATEGORYNAME != "undefined" ? item.CATEGORYNAME : "",
            taxable: this.getItemTaxable({
              included: item.IPRICEINCLUDED,
              noTax: item.NOTAX,
              taxRate: item.TAXRATE
            }),

            noTax: item.NOTAX,
            taxRate: item.TAXRATE,
            taxAmount:
              parseFloat(
                roundCents((item.IPRICEACT * item.TAXRATE) / 100).toFixed(2)
              ) || 0,
            totalAmount:
              item.IPRICEACT +
              parseFloat(
                roundCents((item.IPRICEACT * item.TAXRATE) / 100).toFixed(2)
              ),
            includedparent: item.INCLUDEDPARENT || 0,
            baseProductId: item.INOVICELIID || 0,
            includeItemCount: item.INCLUDEITEMCOUNT || 0,
            POliID: item.POLIID || 0,
            shippingDate: item.SHIPPINGDATE || "",
            subOrderId: item.SUBORDERID || 0,
            CONFIGCOUNT: item.CONFIGCOUNT || 0,
            savedAsConfig: item.ISCONFIG || 0
          };
        }
        this.taxRateMessage = this.getTaxRateMessage(Object.assign({}, items));
        //add last empty row
        let itemIdx = i;
        if (this.splitBySubOrderId.length) {
          for (var arrItem of response.data.splitBySubOrderId) {
            items["productName_" + ++itemIdx] = {
              description: "",
              partNo: "",
              unitPrice: 0.0,
              quantity: 0,
              included: 0,
              estPrice: 0.0,
              ItemCategory: 0,
              ItemCategoryName: "",
              taxable: false,
              noTax: 0,
              taxRate: 0,
              totalAmount: 0,
              taxAmount: 0,
              includedparent: 0,
              baseProductId: 0,
              includeItemCount: 0,
              POliID: 0,
              shippingDate: "",
              subOrderId: arrItem.subOrderId
            };
          }
        } else {
          items["productName_" + ++itemIdx] = {
            description: "",
            partNo: "",
            unitPrice: 0.0,
            quantity: 0,
            included: 0,
            estPrice: 0.0,
            ItemCategory: 0,
            ItemCategoryName: "",
            taxable: false,
            noTax: 0,
            taxRate: 0,
            totalAmount: 0,
            taxAmount: 0,
            includedparent: 0,
            baseProductId: 0,
            includeItemCount: 0,
            POliID: 0,
            shippingDate: ""
          };
        }
        this.summaryTotalFormat = totalItem
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        this.summaryTotal = totalItem;
        this.totalTax = details.CUSTOMERTAX;
        this.totalShipping = details.ISHIPPING;
        this.grandTotal = details.GRANDTOTAL;
        this.$refs.invoiceFormLineItemList.itemNames = items;

        if (typeof details.INVOICEPARAMS != "undefined") {
          try {
            const invoiceParams = JSON.parse(details.INVOICEPARAMS);
            // To get expanDecimal details
            this.getExpandDecimal(invoiceParams);
            // show selected shipping address
            if (
              typeof invoiceParams.DefaultShippingID != "undefined" &&
              invoiceParams.DefaultShippingID != ""
            ) {
              this.$refs.invoiceFormBasicDetails.selectedAddress = [
                parseInt(invoiceParams.DefaultShippingID)
              ];
            }
            if (
              typeof invoiceParams.onetimeAddress != "undefined" &&
              Object.keys(invoiceParams.onetimeAddress).length
            ) {
              // used one time address for this invoice
              this.$refs.invoiceFormBasicDetails.selectedAddress = [0];
              this.$refs.invoiceFormBasicDetails.onetimeAddress = Object.assign(
                this.$refs.invoiceFormBasicDetails.onetimeAddress,
                invoiceParams.onetimeAddress
              );
            }

            // show selected billing address
            if (
              typeof invoiceParams.DefaultBillingID != "undefined" &&
              invoiceParams.DefaultBillingID != ""
            ) {
              this.$refs.invoiceFormBasicDetails.selectedAddressBilling = [
                parseInt(invoiceParams.DefaultBillingID)
              ];
            }
            if (
              typeof invoiceParams.onetimeAddressBilling != "undefined" &&
              Object.keys(invoiceParams.onetimeAddressBilling).length
            ) {
              // used one time address for this invoice
              this.$refs.invoiceFormBasicDetails.selectedAddressBilling = [0];
              this.$refs.invoiceFormBasicDetails.onetimeAddressBilling = Object.assign(
                this.$refs.invoiceFormBasicDetails.onetimeAddressBilling,
                invoiceParams.onetimeAddressBilling
              );
            }
            if (
              typeof invoiceParams.invoiceType != "undefined" &&
              Object.keys(invoiceParams.invoiceType).length
            ) {
              this.$refs.invoiceFormBasicDetails.selectedInvTypeID = [
                invoiceParams.invoiceType.ID
              ];
              Object.assign(
                this.$refs.invoiceFormBasicDetails.selectedInvTypeDetails,
                invoiceParams.invoiceType
              );
            }
            // eslint-disable-next-line no-empty
          } catch (error) {
            console.log(error);
          }
        }

        if (details.AID > 0) {
          // reset customer address
          const invoiceFormBasicDetails = this.$refs.invoiceFormBasicDetails;
          // invoiceFormBasicDetails.customerAddresses = [];
          // invoiceFormBasicDetails.selectedAddress = [];
          // invoiceFormBasicDetails.customerAddressesBilling = [];
          // invoiceFormBasicDetails.selectedAddressBilling = [];
          await invoiceFormBasicDetails.getCustomerAddresses(details.AID);
        }

        // detect if using a custom tax?
        var hasTaxable = false;
        for (const i in items) {
          if (items[i].taxable) {
            hasTaxable = true;
            this.currentTaxRate = items[i].taxRate;
            break;
          }
        }
        if (hasTaxable == false && this.totalTax != 0) {
          this.USECUSTOMTAX = 1;
        }
        this.orderGrandTotal =
          typeof details.ORDERGRANDTOTAL != "undefined"
            ? details.ORDERGRANDTOTAL
            : 0;
        // To list the selected address on the top
        if (this.$refs.invoiceFormBasicDetails.customerAddresses.length) {
          this.sortingAddresses(
            this.$refs.invoiceFormBasicDetails.customerAddresses,
            this.$refs.invoiceFormBasicDetails.selectedAddress
          );
        }
        if (
          this.$refs.invoiceFormBasicDetails.customerAddressesBilling.length
        ) {
          this.sortingAddresses(
            this.$refs.invoiceFormBasicDetails.customerAddressesBilling,
            this.$refs.invoiceFormBasicDetails.selectedAddressBilling
          );
        }
        this.splitBySubOrderId = response.data.splitBySubOrderId || [];
      }
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
    }
  }

  backToDetails() {
    this.$router.push({
      name: "InvoiceDetails",
      params: { id: this.$route.params.id }
    });
  }

  getItemTaxable(item) {
    let taxable = true;
    if (item.taxable || 0) {
      taxable = item.taxable;
    } else if ((item.taxRate || 0) == 0) {
      taxable = false;
    }

    return taxable;
  }

  getTaxRateMessage(items) {
    var ret = "";

    if (Object.keys(items).length) {
      // get a taxRate
      var taxRate = 0;
      for (var i in items) {
        if (!items[i].included && items[i].taxRate != 0) {
          taxRate = items[i].taxRate;
          break;
        }
      }
      if (taxRate != 0) {
        for (i in items) {
          if (
            !items[i].included &&
            items[i].taxRate != 0 &&
            items[i].taxRate != taxRate
          ) {
            this.usedVariedTaxRate = true;
            break;
          }
        }

        if (this.usedVariedTaxRate) {
          ret = "Varies per line item";
        } else {
          ret = taxRate + "%";
        }
      }
    }

    return ret;
  }

  updateUseCustomTaxFlag() {
    this.USECUSTOMTAX = 0;
    if (this.totalTax != 0) {
      this.USECUSTOMTAX = 1;
    }
    // set tax rate = 0 on line items
    this.taxRateMessage = "";
    const items = this.$refs.invoiceFormLineItemList.itemNames;
    for (const i in items) {
      items[i].taxRate = 0;
      items[i].taxable = false;
    }
  }

  // getItemTaxableCheckBox(item, index, taxRate) {
  //   let totalTaxCheckBox: number;
  //   const items = this.$refs.invoiceFormLineItemList.itemNames[index];
  //   var taxable = true;
  //   if (typeof item.taxable != "undefined" && item.taxable) {
  //     taxable = item.taxable;
  //   } else if (
  //     item.included ||
  //     (typeof item.taxRate != "undefined" && item.taxRate == 0)
  //   ) {
  //     taxable = false;
  //   }
  //   var checkbox = "#taxable_" + index;
  //   if ($(checkbox).hasClass("checkbox_checked")) {
  //     //unchecked
  //     items.taxable = false;
  //     items.taxRate = 0;
  //     items.taxAmount = 0;
  //     items.totalAmount = items.estPrice + items.taxAmount;
  //     this.updateTotalTaxShipCheckBox();
  //   } else if ($(checkbox).hasClass("checkbox_unchecked")) {
  //     //checked
  //     items.taxable = true;
  //     taxRate = parseFloat(taxRate.replace("%", ""));
  //     items.taxRate = taxRate;
  //     totalTaxCheckBox = parseFloat(
  //       roundCents((items.estPrice * taxRate) / 100).toFixed(2)
  //     );
  //     items.taxAmount = totalTaxCheckBox;
  //     items.totalAmount = items.estPrice + totalTaxCheckBox;
  //     this.updateTotalTaxShipCheckBox();
  //   }
  // }

  updateTotalTaxShipCheckBox() {
    // let sum = 0;
    // let taxSum = 0;
    let totalTaxable = 0;
    let subTotal = 0;
    const items = this.$refs.invoiceFormLineItemList.itemNames;
    for (const i in items) {
      // sum += items[i].totalAmount;
      // taxSum += items[i].taxAmount;
      totalTaxable += items[i].taxable ? items[i].estPrice : 0;
      subTotal += items[i].estPrice;
    }
    let totalTax = parseFloat(
      roundCents((totalTaxable * this.currentTaxRate) / 100).toFixed(2)
    );
    // sum = parseFloat(roundCents(sum).toFixed(2));
    this.grandTotal = subTotal + totalTax + this.totalShipping;
    this.totalTax = totalTax;
  }

  getExpandDecimal(params) {
    if (typeof params.expandDecimal != "undefined") {
      this.expandDecimal = params.expandDecimal;
    }
    if (this.expandDecimal) {
      this.expandDecimalPrecision = 5;
    }
  }
  // To list the selected address on the top
  sortingAddresses(addressArray, selectAddress) {
    var arr: any = [];
    for (let i = 0; i < addressArray.length; i++) {
      if ($.inArray(addressArray[i].ID, selectAddress) != -1) {
        arr.push(addressArray[i]);
        addressArray.splice(i, 1);
        i--;
      }
    }
    arr.reverse().forEach(item => {
      addressArray.splice(0, 0, item);
    });
  }

  get isMultiEndUsers() {
    // check data of splitBySubOrderId
    if (
      this.splitBySubOrderId.length >= 1 &&
      this.splitBySubOrderId[0].subOrderId > 0
    ) {
      return true;
    }

    return false;
  }

  showEUCustomData(ss) {
    this.selectedSubOrder = ss;
    this.euCustomDataVisible = true;
  }
}
