var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <tr>\n            <td width=\"60%\" style=\"vertical-align: top;\">\n                <table style=\"width: 100%;\">\n                    <tr><td colspan=\"2\" class=\"headerColor\"><strong>Quote Information</strong></td></tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showQuoteName),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":9,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showQuoteNumber),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":12,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showQuoteDate),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteExpDate),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":18,"column":27}}})) != null ? stack1 : "")
    + "                </table>\n            </td>\n            <td width=\"40%\" style=\"vertical-align: top;\">\n                <table style=\"width: 100%;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactInfo),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":46,"column":27}}})) != null ? stack1 : "")
    + "                </table>\n            </td>\n        </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <tr><td style=\"padding-top: 10px; padding-bottom: 3px; width: 140px; vertical-align: top;\">HPQuote Name: </td><td style=\"padding-top: 10px; padding-bottom: 3px;\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.QUOTENAME), depth0))
    + "</td></tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <tr><td style=\"padding-top: 10px; padding-bottom: 3px; vertical-align: top;\">HP Quote Number: </td><td style=\"padding-top: 10px; padding-bottom: 3px;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEID), depth0))
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER)) && stack1.length),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":205},"end":{"line":11,"column":311}}})) != null ? stack1 : "")
    + "</td></tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " - "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER), depth0))
    + " ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <tr><td style=\"padding-top: 10px; padding-bottom: 3px; vertical-align: top;\">Quote Date: </td><td style=\"padding-top: 10px; padding-bottom: 3px;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.LSDATE), depth0))
    + "</td></tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <tr><td style=\"padding-top: 10px; padding-bottom: 3px; vertical-align: top;\">Expiration: </td><td style=\"padding-top: 10px; padding-bottom: 3px;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEEXPIRE), depth0))
    + "</td></tr>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        <tr><td colspan=\"2\" class=\"headerColor\"><strong>Quote Contact</strong></td></tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":27,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":36,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":45,"column":31}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <tr><td style=\"padding-top: 10px; padding-bottom: 3px; width: 110px; vertical-align: top;\">Account Rep: </td><td style=\"padding-top: 10px; padding-bottom: 3px;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "</td></tr>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <tr><td style=\"padding-top: 10px; padding-bottom: 3px; vertical-align: top;\">Phone: </td><td style=\"padding-top: 10px; padding-bottom: 3px;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":30,"column":32},"end":{"line":34,"column":39}}})) != null ? stack1 : "")
    + "                            </td></tr>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEPHONECONTACT)) && stack1.QuoteContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":33,"column":36},"end":{"line":33,"column":193}}})) != null ? stack1 : "")
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEPHONECONTACT)) && stack1.QuoteContactPhoneValueText), depth0))
    + " ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Phone), depth0))
    + " ";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <tr><td style=\"padding-top: 10px; padding-bottom: 3px; vertical-align: top;\">Email: </td><td style=\"padding-top: 10px; padding-bottom: 3px;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":39,"column":32},"end":{"line":43,"column":39}}})) != null ? stack1 : "")
    + "                            </td></tr>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTECONTACT)) && stack1.QuoteContactValue),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":42,"column":36},"end":{"line":42,"column":165}}})) != null ? stack1 : "")
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTECONTACT)) && stack1.QuoteContactValue), depth0))
    + " ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Email), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"quoteDetails_info\" cellpadding=\"0\" cellspacing=\"0\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":50,"column":11}}})) != null ? stack1 : "")
    + "</table>\n";
},"useData":true});