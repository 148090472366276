





































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  linked: boolean
  masterAccount: string;
  items: [];
  showAllocation: boolean;
  masterAccountID: string;
  lineInfo: any;
  idxval: number
}

interface Events {
  onClose: void
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class ProductCatalog extends TSXComponent<Props,
  Events> {
  @Prop({ required: true })
  linked!: boolean;

  tabIndex = 0;


  constructor() {
    super()
  }

  selectSearchProduct(item) {
    this.tabIndex = 1;
  }

  async created() {
  }

}
