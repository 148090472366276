import { render, staticRenderFns } from "./PreviewIdentifiersModal.vue?vue&type=template&id=04b9b552&scoped=true"
import script from "./PreviewIdentifiersModal.vue?vue&type=script&lang=tsx"
export * from "./PreviewIdentifiersModal.vue?vue&type=script&lang=tsx"
import style0 from "./PreviewIdentifiersModal.vue?vue&type=style&index=0&id=04b9b552&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b9b552",
  null
  
)

export default component.exports