<script lang="js">
import VueChartJs from 'vue-chartjs'

export default {
    props: {
        data: Array,
        label: Array,
        options: Object,
    },
    extends: VueChartJs.Line,
    computed: {
        chartData: function() {
            return this.data;
        }
    },
    methods: {
        renderLineChart: function () {
            const self = this;
            const options = Object.assign({
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                },
                tooltips: {
                    // Disable the on-canvas tooltip
                    enabled: false,

                    custom: function(tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = '0';
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);

                            var innerHtml = '';

                            const addTitle = function(title, i) {
                                if (bodyLines[i][0].startsWith('Prior Year')) {
                                    // title = title.split(',')[0]
                                    const parts = title.split('-')
                                    const year = +parts[1] - 1
                                    const t = parts[0].toUpperCase() + ', ' + year
                                    innerHtml += '<tr><th>' + t + '</th></tr>';
                                    return
                                }
                                
                                // title = title.split(',')[1]
                                title = title.replace('-', ', ').toUpperCase()
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            }

                            // titleLines.forEach(addTitle);
                            // innerHtml += '</thead><tbody>';

                            const addBody = function(body, i) {
                                addTitle(titleLines[0], i)

                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                var span = '<span style="' + style + '"></span>';

                                const parts = body[0].split(' ')
                                var amount = parts[parts.length - 1]
                                var amountNum = parseFloat(amount);
                                if(!isNaN(amountNum) && amountNum != 0) {
                                    amount = amountNum.toFixed(2);
                                }
                                const formatedAmount = '$' + amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                innerHtml += '<tr style="color: #38c4c5"><td>' + span + formatedAmount + '</td></tr>';
                            }
                            
                            bodyLines.forEach(addBody);
                            // innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            if(tableRoot)
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = '1';
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding * 1.5 + 'px ' + tooltipModel.xPadding * 1.5 + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.backgroundColor = '#fff';
                        tooltipEl.style.boxShadow = '0px 2px 10px 2px rgba(0,0,0,0.2)';
                    }
                },
                scales: {
                    yAxes: [{
                    ticks: {
                        min: 0,
                        reverse: false,
                        callback: function(value, index, values) {
                            if(value == 0)
															return '';
                            else if(value < 1000)
															return value
                            else if(value < 10000 && value < 1000000) {
															let newVal = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
															if((newVal.slice(0, -4)) * 1000 < value) {//get more for a number. e.g: 1200 should be 1,2K
																	return '$' + newVal.slice(0, -2) + 'K';
															}

															return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").slice(0, -4) + 'K';
                            }
														else if(value > 1000000) {
															let newVal = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
															if((newVal.slice(0, -6)) * 100000 < value) {//get more for a number. e.g: 120000 should be 1,2M
																return '$' + newVal.slice(0, -2) + 'M';
															}
															if(value.toString().slice(0, -6).length > 4){
																return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").slice(0, -6) + 'M';
															}
															return '$' + value.toString().slice(0, -6) + 'M'
                            }
                            else
															return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").slice(0, -4) + 'K';
                        }
                    }
                    }]
                },
                onClick: function(point, e) {
									if(typeof this.options.totalSaleEnableClick != "undefined" && this.options.totalSaleEnableClick) {
										self.$parent.totalSaleEnableClick(point, e, this.tooltip._model,this.data,this.tooltip);
									}
                }
            }, this.options || {})

            this.renderChart({
                labels: this.label,
                datasets: JSON.parse(JSON.stringify(this.chartData)),
            }, options)
        }
    },
    mounted () {
        this.renderLineChart();
    }
}
</script>
