var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "			<tr>\n				<td class='tdcolor'> "
    + alias4(((helper = (helper = helpers.CREATEDDATETIME || (depth0 != null ? depth0.CREATEDDATETIME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CREATEDDATETIME","hash":{},"data":data,"loc":{"start":{"line":11,"column":25},"end":{"line":11,"column":44}}}) : helper)))
    + " </td>\n				<td class='tdcolor'> "
    + alias4(((helper = (helper = helpers.UFNAME || (depth0 != null ? depth0.UFNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UFNAME","hash":{},"data":data,"loc":{"start":{"line":12,"column":25},"end":{"line":12,"column":35}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.ULNAME || (depth0 != null ? depth0.ULNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ULNAME","hash":{},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":46}}}) : helper)))
    + " </td>\n				<td class='tdcolor'>"
    + alias4(((helper = (helper = helpers.CONTENT || (depth0 != null ? depth0.CONTENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CONTENT","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":35}}}) : helper)))
    + "</td>\n			</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<h2 style=\"color:#656468;padding-left:200px;\">TICKET NOTES DETAILS</h2>\n<table  width=\"100%\" cellpadding=\"10\" cellspacing=\"2\" border=\"1\" style=\"font:Verdana, Arial, Helvetica, sans-serif;margin-top:30px;\">\n	<tr>\n		<th class=\"thcolor\">Notes Created</th>\n		<th class=\"thcolor\">External Name</th>\n		<th class=\"thcolor\">Notes Content</th>\n	</tr>\n	<tbody>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.NOTESLIST : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "	</tbody>\n</table>";
},"useData":true});