


































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import Pagination from "../components/Pagination.vue";
import LaddaButton from "../components/LaddaButton.vue"
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

@Component({
  inheritAttrs: false,
  components: {
		Pagination,
		LaddaButton,
		ConfirmRemoveItemModal
	}
})
export default class ExportSetting extends TSXComponent<void> {
	$root: any;
	options = '';
	saving = false;
	loading = false;
	items = {
		quote_template: [],
		order_template: [],
		invoice_template: []
	};
	quoteTemplateId = 0;
	invoiceTemplateId = 0;
	orderTemplateId = 0;
	confirmRemoveModalVisible = false;
	selectingTemplate: any = {};
	currentRole = sessionStorage.getItem("userRole");

	created() {
		this.fetchData();	
	}

	validateBeforeSubmit(e) {
		this.$validator.validateAll().then(async (result) => {
			const quote_templateSelected = $(".addQuote option:selected span").attr('data-id');
			const order_templateSelected = $(".addOrder option:selected span").attr('data-id');
			const invoice_templateSelected = $(".addInvoice option:selected span").attr('data-id');
			if(quote_templateSelected == undefined || quote_templateSelected == "") {
					$("#quote_template").find(".errorMsg").html("The Quote template field is required");
					result = false;
			}
			if(order_templateSelected == undefined || order_templateSelected == "") {
					$("#order_template").find(".errorMsg").html("The Order template field is required");
					result = false;
			}
			if(invoice_templateSelected == undefined || invoice_templateSelected == "") {
					$("#invoice_template").find(".errorMsg").html("The Invoice template field is required");
					result = false;
			}

			if(result) {
					e.preventDefault();
					this.saving = true;
					const pData = {
						uid: this.$root.loginUserId,
						quoteTemplateId: quote_templateSelected,
						orderTemplateId: order_templateSelected,
						invoiceTemplateId: invoice_templateSelected
					}
					await this.saveSetting();
					this.saving = false
			}
			else {
					e.preventDefault();
					e.stopPropagation();
			}
		});
	}

	async saveSetting() {
		this.loading = true;
		try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "VARUsers",
				FunctionName			: "Update",
				userid						: this.$root.loginUserId,
				QuoteTemplateId		: this.quoteTemplateId,
				InvoiceTemplateId	: this.invoiceTemplateId,
				OrderTemplateId		: this.orderTemplateId,
				subsystem					: "VAR360"
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
			}
		}catch (err) {
			// console.log(err.message);
		}finally {
			this.loading = false;
		}
	}

	async fetchData() {
		this.loading = true;
		try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller      : "Templates",
				FunctionName    : "List",
				templatetype		: 0,
				subsystem				: "VAR360",
				getAll					: 1,
				fromExportSetting : true
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
			}

			var templateResult: any = response.data.TEMPLATES;
			var invoice_template: any = [];
			var order_template: any = [];
			var quote_template: any = [];
			for(let i=0; i < templateResult.length; i++) {
				if(templateResult[i].TEMPLATETYPE == 1) {//quote
						templateResult[i].selected = false;                        
						if(templateResult[i].TEMPLATEID == response.data.QuoteTemplateId) {
								templateResult[i].selected = true;
						}
						quote_template.push(templateResult[i]);
				}
				else if(templateResult[i].TEMPLATETYPE == 3) {//order
						templateResult[i].selected = false;                        
						if(templateResult[i].TEMPLATEID == response.data.OrderTemplateId) {
								templateResult[i].selected = true;
						}
						order_template.push(templateResult[i]);
				}
				else if(templateResult[i].TEMPLATETYPE == 2) {//invoice
						templateResult[i].selected = false;                        
						if(templateResult[i].TEMPLATEID == response.data.InvoiceTemplateId) {
								templateResult[i].selected = true;
						}
						invoice_template.push(templateResult[i]);
				}                   
			}
			this.items = {
				quote_template		: quote_template,
				order_template		: order_template,
				invoice_template	: invoice_template
			}
		}catch (err) {
			// console.log(err.message);
		}finally {
			this.loading = false;
		}
	}

	showConfirmDeleteModal(item) {
		this.selectingTemplate = item;
		this.confirmRemoveModalVisible = true;
	}

	async removeSelected() {
		var refModal: any = this.$refs.confirmRemoveModal;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller				: "Templates",
        FunctionName			: "Update",
        subsystem					: "VAR360",
				templateID        : this.selectingTemplate.TEMPLATEID,
				action						: "delete"
			});

			if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS === 0) {
        refModal.deleting = 'error';
        return;
			}
			
      if(response.data.STATUS == 1) {
				refModal.deleting = false;
				this.confirmRemoveModalVisible = false;
				this.fetchData();
      }
    } catch (err) {
      refModal.deleting = "error";
    }
	}
}
