

















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from '@/components/LaddaButton.vue'

interface Props {}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class PreviewIdentifiers extends TSXComponent<Props,Events> {
  @Prop({required: true})
  title!: string;

  @Prop({required: true})
  previewDetails!: object[];

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide');
  }

  close(){
    this.$emit('close');
  }

  confirm() {
    this.$emit("confirm");
  }
}
