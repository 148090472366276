var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " Cost Price ";
},"3":function(container,depth0,helpers,partials,data) {
    return " Price Per ";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.BUILDHEADER : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ISCONFIG : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":50}}})) != null ? stack1 : "")
    + ">\n      <td width=\"50%\" style=\"padding-left: "
    + alias4(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":56}}}) : helper)))
    + "px; font-style: "
    + alias4(((helper = (helper = helpers.FONTSTYLE || (depth0 != null ? depth0.FONTSTYLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FONTSTYLE","hash":{},"data":data,"loc":{"start":{"line":22,"column":72},"end":{"line":22,"column":85}}}) : helper)))
    + ";\">\n        "
    + ((stack1 = ((helper = (helper = helpers.POPRODDESC || (depth0 != null ? depth0.POPRODDESC : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODDESC","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":24}}}) : helper))) != null ? stack1 : "")
    + "<br/>\n        <strong style=\"text-transform: uppercase;\">"
    + ((stack1 = ((helper = (helper = helpers.POPRODSKU || (depth0 != null ? depth0.POPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODSKU","hash":{},"data":data,"loc":{"start":{"line":25,"column":51},"end":{"line":25,"column":66}}}) : helper))) != null ? stack1 : "")
    + "</strong>\n      </td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].EXPORTPO : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":27,"column":49},"end":{"line":27,"column":144}}})) != null ? stack1 : "")
    + "</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + ((stack1 = ((helper = (helper = helpers.POPRODQUANTITY || (depth0 != null ? depth0.POPRODQUANTITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODQUANTITY","hash":{},"data":data,"loc":{"start":{"line":28,"column":49},"end":{"line":28,"column":69}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"15%\" style=\"text-align: right;\">"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].EXPORTPO : depths[1]),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":29,"column":49},"end":{"line":29,"column":151}}})) != null ? stack1 : "")
    + "</td>\n    </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function";

  return "      <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ISCONFIG : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":52}}})) != null ? stack1 : "")
    + ">\n        <td width=\"50%\" style=\"padding-left: "
    + container.escapeExpression(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":11,"column":45},"end":{"line":11,"column":58}}}) : helper)))
    + "px; font-style: regular;\">\n          <div style=\"border-bottom: 2px solid #f0f0f0; padding-bottom: 3px;\">\n            <strong style=\"font-size: 12px; font-weight: 600; color: #000000;\">"
    + ((stack1 = ((helper = (helper = helpers.BUILDHEADER || (depth0 != null ? depth0.BUILDHEADER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BUILDHEADER","hash":{},"data":data,"loc":{"start":{"line":13,"column":79},"end":{"line":13,"column":96}}}) : helper))) != null ? stack1 : "")
    + "</strong>\n          </div>\n        </td>\n        <td width=\"10%\"></td>\n        <td width=\"10%\"></td>\n        <td width=\"15%\"></td>\n      </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"config-line\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " "
    + ((stack1 = ((helper = (helper = helpers.POPRICEREG_FORMATTED || (depth0 != null ? depth0.POPRICEREG_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"POPRICEREG_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":27,"column":69},"end":{"line":27,"column":95}}}) : helper))) != null ? stack1 : "")
    + " ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " "
    + ((stack1 = ((helper = (helper = helpers.POCUSTOMERPRICE_FORMATTED || (depth0 != null ? depth0.POCUSTOMERPRICE_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"POCUSTOMERPRICE_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":27,"column":105},"end":{"line":27,"column":136}}}) : helper))) != null ? stack1 : "")
    + " ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " "
    + ((stack1 = ((helper = (helper = helpers.LIPOPRICEREG_FORMATTED || (depth0 != null ? depth0.LIPOPRICEREG_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LIPOPRICEREG_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":29,"column":69},"end":{"line":29,"column":97}}}) : helper))) != null ? stack1 : "")
    + " ";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " "
    + ((stack1 = ((helper = (helper = helpers.LICUSTOMERTOTALPRICE_FORMATTED || (depth0 != null ? depth0.LICUSTOMERTOTALPRICE_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LICUSTOMERTOTALPRICE_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":29,"column":107},"end":{"line":29,"column":143}}}) : helper))) != null ? stack1 : "")
    + " ";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.TAXMESSAGE : stack1), depth0))
    + ": "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.LSDATE : stack1), depth0))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"50%\">&nbsp;</td>\n      <td width=\"40%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Amount Paid ("
    + alias4(((helper = (helper = helpers.INCLUDEPAYMENTTYPE || (depth0 != null ? depth0.INCLUDEPAYMENTTYPE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INCLUDEPAYMENTTYPE","hash":{},"data":data,"loc":{"start":{"line":65,"column":90},"end":{"line":65,"column":112}}}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.INCLUDEPAYMENTNUMBER : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":112},"end":{"line":65,"column":172}}})) != null ? stack1 : "")
    + "):</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.INCLUDEPAYMENTAMOUNT || (depth0 != null ? depth0.INCLUDEPAYMENTAMOUNT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INCLUDEPAYMENTAMOUNT","hash":{},"data":data,"loc":{"start":{"line":66,"column":49},"end":{"line":66,"column":73}}}) : helper)))
    + "</td>\n    </tr>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.INCLUDEPAYMENTNUMBER || (depth0 != null ? depth0.INCLUDEPAYMENTNUMBER : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"INCLUDEPAYMENTNUMBER","hash":{},"data":data,"loc":{"start":{"line":65,"column":141},"end":{"line":65,"column":165}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"50%\" style=\"border-bottom: 5px solid #b1b1b1;\">LINE ITEM / SKU</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.EXPORTPO : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":81},"end":{"line":4,"column":135}}})) != null ? stack1 : "")
    + "</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">Qty</td>\n    <td width=\"15%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">TOTAL</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ORDERITEMS : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"50%\" style=\"border-top: 5px solid #b1b1b1;\">&nbsp;</td>    \n    <td width=\"40%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\" colspan=\"2\">Subtotal:</td>\n    <td width=\"10%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + alias4(((helper = (helper = helpers.ORDERITEMSTOTAL || (depth0 != null ? depth0.ORDERITEMSTOTAL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ORDERITEMSTOTAL","hash":{},"data":data,"loc":{"start":{"line":35,"column":78},"end":{"line":35,"column":97}}}) : helper)))
    + "</td>\n  </tr>\n    <tr style=\"font-weight: bold;\">\n      <td width=\"50%\">\n        <strong>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.ORDERDETAILS : depth0)) != null ? stack1.TAXMESSAGE : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":10},"end":{"line":43,"column":17}}})) != null ? stack1 : "")
    + "        </strong>\n      </td>\n      <td width=\"40%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Tax:</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.TOTALTAXFORMATTED || (depth0 != null ? depth0.TOTALTAXFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALTAXFORMATTED","hash":{},"data":data,"loc":{"start":{"line":47,"column":49},"end":{"line":47,"column":70}}}) : helper)))
    + "</td>\n    </tr>\n    <tr style=\"font-weight: bold;\">\n      <td width=\"50%\">&nbsp;</td>\n      <td width=\"40%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Shipping:</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.TOTALSHIPPINGFORMATTED || (depth0 != null ? depth0.TOTALSHIPPINGFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALSHIPPINGFORMATTED","hash":{},"data":data,"loc":{"start":{"line":54,"column":49},"end":{"line":54,"column":75}}}) : helper)))
    + "</td>\n    </tr>\n  <tr style=\"font-weight: bold;\">\n    <td width=\"50%\">&nbsp;</td>\n    <td width=\"40%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Grand Total:</td>\n    <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.GRANDTOTALFORMATTED || (depth0 != null ? depth0.GRANDTOTALFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"GRANDTOTALFORMATTED","hash":{},"data":data,"loc":{"start":{"line":60,"column":47},"end":{"line":60,"column":70}}}) : helper)))
    + "</td>\n  </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.INCLUDEPAYMENT : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":2},"end":{"line":68,"column":9}}})) != null ? stack1 : "")
    + "</table>\n";
},"useData":true,"useDepths":true});