










































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import directives from "../helpers/directives";
import { notifier } from "@/models/common";

declare const $: any;
declare const dataURL: string;

interface Props {}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  },
  directives
})
export default class DeploymentUpdatesAddModal extends TSXComponent<void> {
  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: {} })
  statusType?: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  loading = false;
  saving = false;
  notes = "";
  completion: any = 0;
  errors: any;

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    this.completion = this.statusType.percent || 0;
    setTimeout(() => {
      $("#completionNumber")
        .focus()
        .select();
    }, 100);
  }

  async saveCompletion() {
    // validate
    const valid = await this.$validator.validateAll();
    if (!parseInt(this.completion) || !valid) return;

    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "AddStatusTypeLog",
        deploymentId: this.deploymentId,
        statusTypeId: this.statusType.id,
        completion: this.completion,
        notes: this.notes,
        sourceId: 1 //hardcode
      });
      if (response.data.STATUS == 1) {
        notifier.success("Updated status successfully");
        this.$emit("reloadStatusTypes", {
          statusTypeId: this.statusType.id
        });
        this.$emit("close");
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message != "") {
          notifier.alert(message);
        }
      }
      this.saving = false;
    } catch (error) {
      console.log(error);
    } finally {
      this.saving = false;
    }
  }
}
