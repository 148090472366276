


















































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

import LaddaButton from '../components/LaddaButton.vue'
import { notifier, downloadFileUrl } from "../models/common";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Datepicker from "./Datepicker.vue";

declare const $: any;
declare const dataURL: string;

interface Props {
  invoicesDepositId: number;
}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ConfirmRemoveItemModal,
    Datepicker
  },
})
export default class InvoiceDepositDetailsModal extends TSXComponent<void> {
  @Prop({ required: true, default: 0 })
  invoicesDepositId?: number;

  @Prop({required: true})
  title!: string

  loading = false;
  invoiceDepositDetails:any = [];
  depositPaymentTotal = 0;
  selectedID: (number | undefined)[] = [];
  confirmDepositRemove = false;
  deleteLoading: boolean | 'error' = false;
  editLoading: boolean | 'error' = false;
  invoiceId = 0;
  purchaseId = 0;
  invoiceStatusId = 0;
  deleteDeposits:any = [];
  editDepositAmount = false;
  depositEditDate = '';
  editDepositDate = '';
  isConfirmed = false;
  details:any = []

  $refs!: {
    modal: HTMLDivElement;
  };

  mounted() {
    $(this.$refs.modal).modal("show");
    // $(this.$refs.modal).on("hide.bs.modal", () => {
    //   this.$emit("close");
    // });
  }

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller    : "Invoices",
        FunctionName  : "depositList",
        invoicesDepositId : this.invoicesDepositId,
        action : "depositDetails"
      });
      this.invoiceDepositDetails = response.data.INVOICEDEPOSITDETAILS;
      this.depositEditDate = response.data.STATUSDEPOSITDATEFORMATTED;
      this.editDepositDate = response.data.DEPOSITPAIDDATEFORMATTED;
      this.depositPaymentTotal = response.data.DEPOSITTOTAL;
      this.details = response.data;
      this.isConfirmed = this.details.ISCONFIRMED;
    } catch (err) {
      // console.log(err.message); 
    } finally {
      this.loading = false;
    }
  }  

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  checkUpdate() {
    if (this.details.STATUSDEPOSITDATEFORMATTED != this.depositEditDate || this.details.ISCONFIRMED != this.isConfirmed) {
      this.editDepositAmount = true;
    }
    else {
      this.editDepositAmount = false;
    }
  }

  removeSelected(depositDetails) {
    this.invoiceId = depositDetails.INVOICEID;
    this.purchaseId = depositDetails.PURCHASEID;
    this.invoiceStatusId = depositDetails.STATUS_ID;
    this.deleteDeposits = depositDetails;
    this.confirmDepositRemove = true;
  }

  async generateConfirmation() {
    const ok = await this.$validator.validateAll();
    if (!ok) {
      return;
    } else {
      this.generate();
    }
  }

  async generate() {
    try {
      this.editLoading = true;
      const payment = {
        controller: "Invoices",
        FunctionName: "depositList",
        action: "EditDeposit",
        invoiceEditDeposits: this.invoiceDepositDetails,
        invoiceID: this.selectedID.join(","),
        depositID: this.invoicesDepositId,
        paymentDate : this.depositEditDate,
        isConfirmed: this.isConfirmed
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", payment);
      if (response.data.STATUS == 1) {
         notifier.success(response.data.STATUSMESSAGE);
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      this.editLoading = 'error'
      // console.log(err.message); 
    } finally {
      this.editLoading = false;
      this.$emit("close");
      this.$emit("depositUpdate");
    }
  }

  async deleteInvoiceDeposit() {
    this.deleteLoading = true;
    var depositPaymentTotal = 0;
    this.invoiceDepositDetails.forEach(item => {
      if(item.INVOICEID !=  this.deleteDeposits.INVOICEID)
        depositPaymentTotal += parseFloat(item.PAYMENTAMOUNT);
    });
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller    : "Invoices",
        FunctionName  : "depositList",
        depositID: this.invoicesDepositId,
        invoiceID: this.invoiceId,
        purchaseID : this.purchaseId,
        invoiceStatusId : this.invoiceStatusId,
        depositPaymentTotal : depositPaymentTotal,
        deleteInvoiceDeposits : this.deleteDeposits,
        action : "delete"
      });
      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
      } 
    } catch (err) {
        this.deleteLoading = 'error'
        // console.log(err.message); 
    } finally {
      this.deleteLoading = false;
      this.$emit("close");
      this.$emit("depositUpdate");
    }
  }

  async exportCSV() {
    let fileUrl = '';
    this.loading = true;
    try {
      const reqData = {
        controller: "Invoices",
        FunctionName: "ExportInvoiceDeposit",
        ExportType: 'CSV',
        exportForm: 'depositDetails',
        invoicesDepositId: this.invoicesDepositId,
        selectedIDs: [],
        excludedIDs: [],
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);
      if (response.data.STATUS === 1) {
        fileUrl = response.data.S3URL;
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }

    downloadFileUrl(fileUrl);
  }

  closeModal() {
    this.$emit("close");
  }
}
