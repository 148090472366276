





























































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../../components/LaddaButton.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";
import ProductAutoSuggestInput from "@/components/ProductAutoSuggestInput.vue";
import CategoryAutoSuggestInput from "@/components/CategoryAutoSuggestInput.vue";

interface Props {}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ProductAutoSuggestInput,
    CategoryAutoSuggestInput
  }
})
export default class ModalGenerateAssetTags extends TSXComponent<Props, Events> {
  title = "assets";
  productSKU = "";
  productName = "";
  category = "";
  loading: boolean = false;
  selectedProductsID: string[] = [];
  selectedProducts: any[] = [];
  isValid = false;
  saving: any = false;
  from = '';
  to = '';

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: "" })
  deploymentId!: string;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async updateProduct(item: any, inputField: string) {
    this.loading = true;
    try {
      if(inputField == 'sku') {
        this.productSKU = item.SKU;
      } else if(inputField == 'product') {
        this.productName = item.PRODUCTNAME;
      } else if(inputField == 'category') {
        this.category = item.CATEGORYNAME
      }
    
      if (inputField == 'category') {
        const response = await ApiHelper.callApi("post", {
            controller: "Deployments",
            FunctionName: "Products",
            Action: "GenerateAssetTag",
            productType : this.title,
            deploymentId: this.deploymentId,
            CategoryId: item.CATEGORYID
        });
        if (response.PRODUCTSARR.length) {
          response.PRODUCTSARR.forEach(item => {
            const productId = `p${item.PURCHASEID}-${item.SKU}`;
            const index = this.selectedProductsID.findIndex(id => id == productId);
  
            if (index === -1) {
              item.INCLUDE = 0;
              this.selectedProductsID.push(productId);
              this.selectedProducts.push(item);
            }
          });
        }
      } else {
        item["isValid"] = true;
        const productId = `p${item.PURCHASEID}-${item.SKU}`;
        const index = this.selectedProductsID.findIndex(id => id == productId);
      
        if (index === -1) {
          item.INCLUDE = 0;
          this.selectedProductsID.push(productId);
          this.selectedProducts.push(item);
        }
      }
    } catch (err) {
      // console.log(err);
    } finally {
      this.loading = false;
    }
  }

  deleteInclude(product) {
    const productId = `p${product.PURCHASEID}-${product.SKU}`;
    const index = this.selectedProductsID.findIndex(id => id == productId);
    this.$delete(this.selectedProductsID, index);
    this.$delete(this.selectedProducts, index);
  }

  validateValue(item) {
    item.isValid =
      item.INCLUDE >= 0 &&
      item.INCLUDE <= item.QUANTITY - (item.DEPLOYEDQTY || 0);
    this.isValid = !this.selectedProducts.some(function(product) {
        return product.isValid == false;
    });
  }

  async addProducts() {
    try {
      notifier.success('Asset Tag Generated Successfully');
      this.$emit("close");
      // this.saving = true;
      // const response = await ApiHelper.callApi("post", {
      //   controller: "Deployments",
      //   FunctionName: "generateAssetTags",
      //   deploymentId: this.deploymentId,
      //   productDetails: this.selectedProducts
      // });

      // if (response.ERROR) {
      //   this.saving = "error";
      //   throw new Error(response.ERROR);
      // }

      // if (response.STATUS == 1) {
      //   this.saving = false;
      //   notifier.success(response.STATUSMESSAGE);
      //   this.$emit("callback");
      // } else {
      //   this.saving = "error";
      //   notifier.alert(response.STATUSMESSAGE);
      // }
    } catch (err) {
      // console.log(err);
      this.saving = "error";
    }
  }
}
