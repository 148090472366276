


















































































































import axios from 'axios'
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import {notifier,printHtml, wait} from '../models/common'
import LaddaButton from "../components/LaddaButton.vue"
import PageTitle from '../components/pageTitle.vue';

declare const $: any;
declare const dataURL: string;
declare const require: any;
declare const getRouteData: Function;
const template = require('../templates/supports/supportDetails.handlebars')

@Component({
  inheritAttrs: false,
  components: {
     LaddaButton,
     PageTitle
  }
})
export default class SupportDetails extends TSXComponent<void>  {
    status: (any| undefined) = 1
    Message =''
    saving = false
    closed = 'Closed'
    items : {
        TICKETID?: string,
        TICKETCREATEDAT?: string,
        SUBJECT?: string,
        TICKETCLOSEDAT?: string,
        STATUSID?: string
    }[] = []
    details = {
        TOTALALERTS: 0
    }
    loading = false
    currentRole = sessionStorage.getItem("userRole");
    async created() {
        this.grabViewSupportsData();
    }
    listPageRedirection(){
        this.$router.push({ name: "Supports" });
    }
    updateStatus(value) {
        this.status = value;
        var dataObj = {};
        var self = this;
        dataObj["controller"] = "Supports";
        dataObj["FunctionName"] = "Update";
        dataObj["ticketId"] = this.$route.params.id;
        dataObj["sStatus"] = value;

        var updateData = getRouteData(dataObj);
        updateData.then(function (response, statusText, jqXHR) {
            try {
                if (response.data.STATUS == 1 && response.data.STATUSMESSAGE) {
                    self.grabViewSupportsData();
                }
            }
            catch (e) {
                //handle error
                console.log('supports.js : grabOrderData error : ', e);
            }
        });
    }
    validateBeforeSubmit(e) {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                e.preventDefault();
                    this.saving = true;
                    await this.submit();
            }
            else {
                e.preventDefault();
                e.stopPropagation();
            }
        });
    }
    grabViewSupportsData() {
        this.items = [{TICKETID: '', TICKETCREATEDAT: '', SUBJECT: '', TICKETCLOSEDAT: '', STATUSID: ''}];
        var tableColumns = [];
        this.details = { TOTALALERTS: 0 };
        var dataObj = {};
        dataObj["controller"] = "Supports";
        dataObj["FunctionName"] = "View";
        dataObj["ticketId"] = this.$route.params.id;

        var self = this;
        var viewData = getRouteData(dataObj);
        viewData.then(function (response, statusText, jqXHR) {
            try {
                if (response.data.STATUS == 1 && !response.data.STATUSMESSAGE) {
                    self.items = response.data.VIEWTICKETSDETAILS;
                    self.items['NOTESLIST'] = response.data.NOTESLIST;
                    self.details = response.data;
                    self.loading = false;
                } else if (response.data.STATUS == 2 && response.data.MESSAGE) {
                    notifier.alert(response.data.MESSAGE);
                    self.$router.push({ name: "Supports"});
                } else if (!response.data.STATUS && response.data.STATUSMESSAGE) {
                    notifier.alert(response.data.STATUSMESSAGE);
                    self.$router.push({ name: "Supports"});
                } else if (response.data.ERROR) {
                    console.log(response.data.ERROR);
                    self.$router.push({ name: "Supports"});
                }
                $('#message').val('');
            }
            catch (e) {
                //handle error
                console.log('supports.js : grabOrderData error : ', e);
            }
        })
    }
    submit() {
        const self = this
        var comInstance = this.$root;
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser')  || '{}');
        const accountId = currentUser[0]['ACCOUNTID'];
        const userId = currentUser[0]['USERID'];
        const userEmail = currentUser[0]['UEMAIL'];

            const dataObj = {
                "controller" : "Supports",
                "FunctionName" : "Add",
                "message" : $('#message').val(),
                "ticketId" : this.$route.params.id,
                "accountId" : accountId,
                "userId" : userId,
                "userEmail" : userEmail,
            };       
        var getData = getRouteData(dataObj);
        return getData.then(async (response, statusText, jqXHR) => {
            notifier.success('Message Added Successfully!');
            this.saving = false;
            await wait(1000)
            self.grabViewSupportsData();
        });
    }
    async printFn(){
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser')  || '{}');
        const accountid = currentUser[0]['ACCOUNTID'];
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Supports",
            FunctionName: "View",
            ticketId: `${this.$route.params.id}`,
            Print:'yes'
        });

        if (response.data.ERROR) {
            throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
            throw new Error(response.data.STATUSMESSAGE);
        }
        var supportList = response.data;
        var html = template(supportList)
        printHtml(html);
    }
    async pdfDownLoadFn() {
        var exportObj = {
            controller: "Supports",
            FunctionName: "ExportView",
            uploadPath: "Inbound/apiFiles/",
            keyName: "Inbound/apiFiles/",
            ExportType: 'PDF',
            ticketId: this.$route.params.id
        };
        this.downloadPdfProcess(exportObj, this);
    }
    async downloadPdfProcess(data, container) {
        var downLoadRes = getRouteData(data);
        downLoadRes.then(function (response, statusText, jqXHR) {
            try {
                if (response.data.STATUS) {
                    var link = document.createElement("a");
                    link.download = 'Supports';
                    link.target = "_blank";
                    link.href = response.data.S3URL;
                    link.click();
                } else if (response.data.ERROR) {
                    console.log(response.data.ERROR);
                }
            }
            catch (e) {
                //handle error
                console.log('quotes.js : DownLoadFn error : ', e);
            }
        })
    }

    @Watch('items', {deep: true})
    itemsChanged(val, oldVal) {
        this.status = val[0].STATUSID
    }
}
