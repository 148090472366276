var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'ib',
    ((_vm.loginCheck()) + "-role").toLowerCase(),
    {
      login:
        _vm.$route.name === 'Login' ||
        _vm.$route.name === 'passwordChange' ||
        _vm.$route.name === 'signUp'
    },
    { 'var-customer': _vm.$root.getUserRole() === 'Customer' },
    { 'var-reseller': _vm.$root.getUserRole() === 'Reseller' }
  ],attrs:{"id":"body-site"}},[_c('modal-direction',{model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}}),(_vm.message)?_c('ErrorView',{attrs:{"message":_vm.message,"redirectName":_vm.redirectName,"redirectQuery":_vm.redirectQuery},on:{"back":_vm.back}}):_c('router-view',{key:_vm.$route.name + (_vm.$route.params.id || '')}),(_vm.QuotesCustomer)?_c(_vm.QuotesCustomer,{tag:"component"}):_vm._e(),(_vm.QuoteDetailsCustomer)?_c(_vm.QuoteDetailsCustomer,{tag:"component"}):_vm._e(),(_vm.OrdersCustomer)?_c(_vm.OrdersCustomer,{tag:"component"}):_vm._e(),(_vm.OrderDetailsCustomer)?_c(_vm.OrderDetailsCustomer,{tag:"component"}):_vm._e(),_c('div',{attrs:{"id":"dashboardClone"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }