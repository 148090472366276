




































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { AccountList } from "../models/AccountList";

interface Props {
  selectedIDs: string[]
}

interface Events {
  onCloseMergeAcc: void
  onShowMergeConfirm: {
     Id: AccountList.Accounts
  }
}

declare const $: any
declare const getRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {}
})

export default class MergeAccountListModal extends TSXComponent< Props,Events > {
  @Prop({required: true})
  selectedIDs!: string[]

  saving = false
  accountUserDetail = []
  accId = 0;
  $refs!: {
    modal: HTMLDivElement
  }

  created() {
    var self = this
    var dataObj = {
      "controller": "Customers",
      "FunctionName": "List",
      "SelectedIDs": self.selectedIDs
    }
    var accUserResult = getRouteData(dataObj)
    accUserResult.then(function(response) {
      try{
        self.accountUserDetail = response.data.ACCOUNTUSERDET
      }
      catch(e) {
        //handle error
      }
    });
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('closeMergeAcc')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  merge() {
    this.saving = true
    this.$emit('showMergeConfirm', $("#mergeAccType").val());
  }

  cancelMergeAcc() {
    this.$emit('closeMergeAcc')
  }
}
