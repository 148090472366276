















































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
// import AccountComp from "../components/editAccountCommnission.vue"
import axios from "axios";
import {notifier} from '../models/common'
import PCDetailsPaidTotalTooltip from "../components/PCDetailsPaidTotalTooltip.vue";
import LaddaButton from "../components/LaddaButton.vue"

interface Props {
  title: string
  commissions: any
  commisionPercent: number
  userID: number
  EXCEEDED: boolean
}

interface Events {
  onClose: void
  onConfirm: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    // AccountComp,
    PCDetailsPaidTotalTooltip,
    LaddaButton
  }
})
export default class remainingCommissionModal extends TSXComponent<
  Props,
  Events
> {

  @Prop({required: true})
  title!: string

  @Prop({required: true})
  commissions!: any

  @Prop({required: true})
  commisionPercent!: 0

  @Prop({required: false, default: 0})
  userID!: 0

  @Prop({required: false, default: false})
  EXCEEDED!: false

  @Prop({required: false, default: 0})
  businessLineId!: number

  saving: any = false
  loading: any = false
  editAccountModelVisible = false
  paidTotalTTVisibleIndex = -1;
  paidTotalTTLoading = false
  paidTotalTTList = [];
  isDataChanged = false;

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
    
    for(var val of this.commissions) {
      if(val.ISALLOWUPDATE) {
        val.confirmed = true;
      }else {
        val.confirmed = false;
      }
    }

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  // confirm() {
  //   this.saving = true
  //   this.$emit('close')
  // }
  openAccounts(aId){
    // console.log('tested',aId,this.editAccountModelVisible)
    this.editAccountModelVisible = !this.editAccountModelVisible 
    this.$emit('updateCommission',aId)
  }
  confirmAccount(item, pos){
    // this.loading = true;
    // this.commissions.splice(pos, 1);
    // if(!this.commissions.length){
    //   this.$parent.confirmCommissionRemoveModalVisible = false;
    // }
    // this.loading = false;
    item.confirmed = (item.confirmed ? false : true);
    this.$forceUpdate();
  }

  confirmAll() {
    for(var val of this.commissions) {
      val.confirmed = true;
    }
    this.$forceUpdate();
  }

  async showTooltipPaidTotal(item, index) {
    var tooltipHeight = $( "#id"+index ).offset().top - $( window ).scrollTop();
    var bottom = ($("#id"+index).offset().top - $(window).scrollTop()) + 180;
    var container = $("body").height();
    bottom = container - bottom;
    if(bottom <= tooltipHeight) {
      if(!$("#id"+index).parent().hasClass('topTooltip')){
        $("#id"+index).parent().addClass('topTooltip');
      }
    }
    else{
      $("#id"+index).parent().removeClass('topTooltip');
    }
    
    //Tmp allow to show tooltip of Paid paycheck
    // if(this.paycheckOverview.STATUS == 1) {
    //   return;
    // }

    if (this.paidTotalTTVisibleIndex === index) return;

    this.paidTotalTTVisibleIndex = index;

    try {
      this.paidTotalTTLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Finances",
        FunctionName: "GetAssginedPercent",
        accountID: item.ACCOUNTID,
        businessLineId: this.businessLineId
      });

      if (response.data.STATUS == 1) {
        this.paidTotalTTList = response.data.ASSIGNEDPERCENTARR;
      }
    } catch (err) {
      notifier.alert(err.message);
      this.paidTotalTTVisibleIndex = -1;
    } finally {
      this.paidTotalTTLoading = false;
    }
  }

  async updateConfirmedAccts() {
    try {
      var dataNeedUpdate: any = [];
      for(var val of this.commissions) {
        if(val.confirmed) {
          dataNeedUpdate.push({
            aID: val.AID,
            newPercent: val.NEWPERCENT,
            businessLineId: this.businessLineId
          });
        }
      }

      if(dataNeedUpdate.length) {
        this.saving = true;

        //need to verify data before saving, something maybe changed
        var changedInNeededUpdate = false;
        var whatChanged: any = [];
        var latestData = [];
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          "controller"            : "Users",
          "FunctionName"          : "AccountCommission",
          "userID"                : this.userID,
          "aID"                   : 0,
          "percentage"            : this.commisionPercent,
          "batchUpdate"           : true,
          "updateConfirmedAccts"  : false,
          "businessLineId"        : this.businessLineId
        });
        latestData = (response.data.EXCEEDEDPERCENTACCOUNTS && response.data.EXCEEDEDPERCENTACCOUNTS.length ? response.data.EXCEEDEDPERCENTACCOUNTS : []);
        if(latestData.length) {
          for(var i in this.commissions) {//loop on old data, and compare with latest data
            var tmpAcct: any = latestData.filter((tmp) => tmp["AID"] == this.commissions[i]["AID"]);
            if(tmpAcct.length) {
              if(tmpAcct[0].NEWPERCENT != this.commissions[i].NEWPERCENT) {//changed this commission
                this.isDataChanged = true;
                whatChanged.push(Object.assign(this.commissions[i], tmpAcct[0]));
                if(this.commissions[i].confirmed) {
                  changedInNeededUpdate = true;
                }
              }
            }else {//not found (removed this commission)
              this.isDataChanged = true;
              whatChanged.push(Object.assign(this.commissions[i], {NEWPERCENT: 0}));
              if(this.commissions[i].confirmed) {
                changedInNeededUpdate = true;
              }
            }
          }
        }else {//removed all
          this.isDataChanged = true;
          changedInNeededUpdate = true;
        }

        if(this.isDataChanged && whatChanged.length && changedInNeededUpdate) {
          alert("Detected and reloaded some data changed. Please check on list and re-confirm.");
          for(i in this.commissions) {//unconfirm on changed rows
            var aChange: any = whatChanged.filter((tmp) => tmp["AID"] == this.commissions[i]["AID"]);
            if(aChange.length) {
              if(aChange[0].NEWPERCENT == 0) {//remove this line
                this.$delete(this.commissions, this.commissions.findIndex((tmp) => tmp.AID == aChange[0].AID));
              }else {//unconfirm this line
                this.commissions[i].confirmed = false;
              }
            }
          }
        }else {//continue to save
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            Controller            : "Users",
            FunctionName          : "AccountCommission",
            userID                : this.userID,
            batchUpdate           : true,
            updateConfirmedAccts  : true,
            dataNeedUpdate        : dataNeedUpdate
          });

          if (response.data.STATUS == 1) {
            this.$emit("loadCommission");
            this.isDataChanged = false;
            this.$emit("close");
          }
        }
      }
    } catch (err) {
      this.saving = 'error';
    } finally {
      this.saving = false;
    }
  }
}
