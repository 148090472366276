















































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import DropdownControl from "@/components/DropdownControl.vue";
import DeploymentUpdatesAddModal from "@/components/DeploymentUpdatesAddModal.vue";
import DeploymentNoteAddModal from "@/components/DeploymentNoteAddModal.vue";
import axios from "axios";
import { DStatusType } from "@/helpers/Types/StatusTypes";
import DeploymentESTTooltip from "@/components/DeploymentESTTooltip.vue";
import moment from "moment";
import { ApiHelper } from "@/helpers/all";
import UnallocatedProductsTooltip from "@/components/Deployment/UnallocatedProductsTooltip.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {
    DropdownControl,
    DeploymentUpdatesAddModal,
    DeploymentESTTooltip,
    DeploymentNoteAddModal,
    UnallocatedProductsTooltip
  }
})
export default class DeploymentDashboard extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: {} })
  deploymentDetails?: any;

  filters = {
    statusTypeId: 0
  };

  percentage = {
    plan: {
      title: "PLAN",
      receive: {
        title: "RECEIVE",
        percent: 0
      },
      schedule: {
        title: "SCHEDULE",
        percent: 0
      }
    },
    build: {
      title: "BUILD",
      build: {
        title: "BUILD",
        percent: 0
      },
      package: {
        title: "PACKAGE",
        percent: 0
      }
    },
    deliver: {
      title: "DELIVER",
      deploy: {
        title: "DEPLOY",
        percent: 0
      },
      validate: {
        title: "VALIDATE",
        percent: 0
      }
    }
  };
  statusTypes: any = [];
  notes: any = [];
  notesTotalCnt = 0;
  logActive = "updates";
  selectedStatusType: any = null;
  updatesAddModalVisible = false;
  statusTypeLogs: any = [];
  statusTypeLogsCnt = 0;
  loading = false;
  deploymentId = 0;
  uuid: any = "";
  estTooltipVisibleIndex: any = -1;
  noteAddModalVisible = false;
  unallocatedProducts: any = [];
  unallocatedTooltipVisible = false;

  async created() {
    this.deploymentId = parseInt(this.$route.params.id);
    this.uuid = this.$route.query.UUID;
    await this.fetchData();
  }

  async fetchData() {
    // get status type updates
    this.getStatusTypeLogs();

    // get status types
    this.getStatusTypes();

    // get notes
    this.getNotes();
  }

  get statusTypesGrouped() {
    const ret: any = [];
    for (const item of this.statusTypes) {
      const existed = ret.find(t => t.groupId == item.groupId);
      if (!existed) {
        var max_est_dt = "";
        var max_estFull_dt = "";
        if (!item.groupEst.length) {
          max_est_dt = item.est;
          max_estFull_dt = item.estFull;
          var max_dtObj = new Date(item.est);
          this.statusTypes
            .filter(t => t.groupId == item.groupId)
            .map(e => {
              if (new Date(e.est) > max_dtObj) {
                max_est_dt = e.est;
                max_estFull_dt = e.estFull;
                max_dtObj = new Date(e.est);
              }
            });
        }
        ret.push({
          groupId: item.groupId,
          groupName: this.getGroupName(item.groupId),
          est: max_est_dt || item.groupEst || "",
          estFull: max_estFull_dt || item.groupEstFull || "",
          items: this.statusTypes.filter(t => t.groupId == item.groupId)
        });
      }
    }

    return ret;
  }

  getGroupName(groupId) {
    let ret = "";
    switch (groupId) {
      case 1:
        ret = "Plan";
        break;
      case 2:
        ret = "Build";
        break;
      case 3:
        ret = "Deliver";
        break;
    }

    return ret;
  }

  showUpdates() {
    this.logActive = "updates";
  }

  showNotes() {
    this.logActive = "notes";
  }

  showUpdatesAddModal(statusType) {
    this.selectedStatusType = statusType;
    this.updatesAddModalVisible = true;
  }

  async getStatusTypeLogs() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "GetStatusTypeLogs",
        deploymentId: this.deploymentId,
        statusTypeId: this.filters.statusTypeId || 0
      });
      if (response.data.STATUS == 1) {
        this.statusTypeLogs = response.data.logs || [];
        this.statusTypeLogsCnt = response.data.totalAll || 0;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async getStatusTypes() {
    try {
      this.loading = true;
      const response = await ApiHelper.getStatusTypes(this.deploymentId);
      this.statusTypes = response.statusTypes;
      this.unallocatedProducts = response.unallocatedProducts || [];
      this.percentage.plan.receive.percent = response.receivePercent || 0;
      this.percentage.plan.schedule.percent = response.schedulePercent || 0;
      this.percentage.build.build.percent = response.buildPercent || 0;
      this.percentage.build.package.percent = response.packagePercent || 0;
      this.percentage.deliver.deploy.percent = response.deployPercent || 0;
      this.percentage.deliver.validate.percent = response.validatePercent || 0;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async reloadStatusTypes(options) {
    if ((options.statusTypeId || 0) > 0) {
      this.filters.statusTypeId = options.statusTypeId || 0;
    }
    this.getStatusTypeLogs();
    await this.getStatusTypes();

    // at this step, can calculate for deployment percent based on percentages of plan/build/deliver
    this.updateDeploymentPercent();
  }

  filterUpdatesBy(statusTypeId = 0) {
    if (!statusTypeId) return;

    this.filters.statusTypeId = statusTypeId;
    this.getStatusTypeLogs();
  }

  async updateDeploymentPercent() {
    // calculate for deployment percent based on percentages of plan/build/deliver
    let totalPercent =
      this.percentage.plan.receive.percent +
      this.percentage.plan.schedule.percent +
      this.percentage.build.build.percent +
      this.percentage.build.package.percent +
      this.percentage.deliver.deploy.percent +
      this.percentage.deliver.validate.percent;
    let deploymentPercent = parseFloat(totalPercent.toFixed(2)) / 6;
    deploymentPercent = Math.round(deploymentPercent);

    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateDeploymentPercent",
        deploymentId: this.deploymentId,
        deploymentPercent
      });
      if (response.data.STATUS == 1) {
        this.$emit("updateDeploymentPercent", deploymentPercent);
      }
    } catch (error) {
      console.log(error);
    }
  }

  showStatusTypeESTTooltip(type = "statusType", item) {
    let id = "";
    if (type == "statusType") {
      id = item.id;
    } else if (type == "group") {
      id = `group-${item.groupId}`;
    }

    if (this.estTooltipVisibleIndex == id) {
      return;
    }
    this.estTooltipVisibleIndex = id;
  }

  async selectedESTDay(options) {
    this.estTooltipVisibleIndex = -1;
    if (options.type == "statusType") {
      // update value for related status type
      const statusType = this.statusTypes.find(
        item => item.id == options.statusTypeId
      );
      if (!statusType) return;

      statusType.est = moment(options.selected).format("MM/DD/YY");
      statusType.estFull = options.selected;
    } else if (options.type == "group") {
      // update value for related status types in this group
      const inGroup = this.statusTypes.filter(
        item => item.groupId == options.groupId
      );
      for (const item of inGroup) {
        item.groupEst = moment(options.selected).format("MM/DD/YY");
        item.groupEstFull = options.selected;
      }
    }

    // update status type est date
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateDeploymentESTDate",
        deploymentId: options.deploymentId,
        statusTypeId: options.type == "statusType" ? options.statusTypeId : 0,
        groupId: options.type == "group" ? options.groupId : 0,
        estDate: options.selected,
        type: options.type
      });
      if (response.data.STATUS == 1) {
        //
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getNotes() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "GetNotes",
        deploymentId: this.deploymentId
      });
      if (response.data.STATUS == 1) {
        this.notes = response.data.notes || [];
        this.notesTotalCnt = response.data.totalAll || 0;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }
}
