var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function";

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.BUILDHEADER : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "    <tr>\n      <td width=\"50%\" style=\"padding-left: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInclude : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":23,"column":43},"end":{"line":23,"column":83}}})) != null ? stack1 : "")
    + "; font-style: "
    + container.escapeExpression(((helper = (helper = helpers.FONTSTYLE || (depth0 != null ? depth0.FONTSTYLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FONTSTYLE","hash":{},"data":data,"loc":{"start":{"line":23,"column":97},"end":{"line":23,"column":110}}}) : helper)))
    + "; padding-top: 20px; padding-bottom: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInclude : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":23,"column":147},"end":{"line":23,"column":186}}})) != null ? stack1 : "")
    + ";\">\n        <strong style=\"font-size: 12px;\">"
    + ((stack1 = ((helper = (helper = helpers.QPRODDESC || (depth0 != null ? depth0.QPRODDESC : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODDESC","hash":{},"data":data,"loc":{"start":{"line":25,"column":41},"end":{"line":25,"column":56}}}) : helper))) != null ? stack1 : "")
    + "</strong><br/>\n        <span style=\"font-size: 10px;\">"
    + ((stack1 = ((helper = (helper = helpers.QPRODSKU || (depth0 != null ? depth0.QPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODSKU","hash":{},"data":data,"loc":{"start":{"line":26,"column":39},"end":{"line":26,"column":53}}}) : helper))) != null ? stack1 : "")
    + "</span>\n      </td>\n      <td width=\"10%\" style=\"text-align: center; font-size: 12px;\">"
    + ((stack1 = ((helper = (helper = helpers.QPRODQUANTITY || (depth0 != null ? depth0.QPRODQUANTITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODQUANTITY","hash":{},"data":data,"loc":{"start":{"line":28,"column":67},"end":{"line":28,"column":86}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: right; font-size: 12px; padding-right: 10px;\">"
    + ((stack1 = ((helper = (helper = helpers.QCUSTOMERPRICEFORMATTED || (depth0 != null ? depth0.QCUSTOMERPRICEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QCUSTOMERPRICEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":29,"column":87},"end":{"line":29,"column":116}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: right; padding-right: 35px; font-size: 12px;\">"
    + ((stack1 = ((helper = (helper = helpers.CUSTOMERTOTALPRICEFORMATTED || (depth0 != null ? depth0.CUSTOMERTOTALPRICEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CUSTOMERTOTALPRICEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":30,"column":87},"end":{"line":30,"column":120}}}) : helper))) != null ? stack1 : "")
    + "</td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <tr>\n        <td width=\"50%\" style=\"padding-left: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInclude : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":12,"column":45},"end":{"line":12,"column":85}}})) != null ? stack1 : "")
    + "; font-style: regular; padding-top: 20px; padding-bottom: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInclude : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":12,"column":143},"end":{"line":12,"column":182}}})) != null ? stack1 : "")
    + ";\">\n          <div style=\"border-bottom: 2px solid #f0f0f0; padding-bottom: 3px;\">\n            <strong style=\"font-size: 12px; font-weight: 600;\">"
    + ((stack1 = ((helper = (helper = helpers.BUILDHEADER || (depth0 != null ? depth0.BUILDHEADER : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"BUILDHEADER","hash":{},"data":data,"loc":{"start":{"line":14,"column":63},"end":{"line":14,"column":80}}}) : helper))) != null ? stack1 : "")
    + "</strong>\n          </div>\n        </td>\n        <td width=\"10%\"></td>\n        <td width=\"20%\"></td>\n        <td width=\"20%\"></td>\n      </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "45px";
},"5":function(container,depth0,helpers,partials,data) {
    return "35px";
},"7":function(container,depth0,helpers,partials,data) {
    return "5px";
},"9":function(container,depth0,helpers,partials,data) {
    return "10px";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; font-size: 12px;\">Estimated Tax:</td>\n      <td width=\"20%\" style=\"text-align: right; padding-right: 35px; font-size: 12px;\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.TOTALTAXFORMATTED), depth0))
    + "</td>\n    </tr>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; font-size: 12px;\">Estimated Shipping:</td>\n      <td width=\"20%\" style=\"text-align: right; padding-right: 35px; font-size: 12px;\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.TOTALSHIPPINGFORMATTED), depth0))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\" style=\"padding: 0; padding-top: 40px;\">\n  <tr style=\"color: #A1A1A1; font-size: 10px; font-weight: 700; letter-spacing: 0.28px;\">\n    <td width=\"50%\" class=\"itemsHeader\" style=\"padding-bottom: 10px; padding-left: 35px;\">PRODUCT DETAILS</td>\n    <td width=\"10%\" class=\"itemsHeader\" style=\"text-align: center; padding-bottom: 10px;\">QTY</td>\n    <td width=\"20%\" class=\"itemsHeader\" style=\"text-align: right; padding-bottom: 10px; padding-right: 10px;\">UNIT PER</td>\n    <td width=\"20%\" class=\"itemsHeader\" style=\"text-align: right; padding-bottom: 10px; padding-right: 35px;\">TOTAL PRICE</td>\n  </tr>\n  <tr><td colspan=\"4\" height=\"10\" style=\"height: 10px; padding: 0;\">&nbsp;</td></tr>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.QUOTEITEMS),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; padding-top: 50px; font-size: 12px;\">Total:</td>\n    <td width=\"20%\" style=\"text-align: right; padding-right: 35px; padding-top: 50px; font-size: 12px;\">"
    + alias3(alias2(((stack1 = (data && data.root)) && stack1.TOTALCUSTOMERAMOUNTFORMATTED), depth0))
    + "</td>\n  </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.TOTALTAX),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":2},"end":{"line":50,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.TOTALSHIPPING),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":2},"end":{"line":57,"column":9}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; font-size: 12px;\">Grand Total:</td>\n    <td width=\"20%\" style=\"text-align: right; padding-right: 35px; font-size: 12px;\">"
    + alias3(alias2(((stack1 = (data && data.root)) && stack1.GRANDCUSTOMERTOTALFORMATTED), depth0))
    + "</td>\n  </tr>\n</table>\n\n";
},"useData":true});