














































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import PageTitle from "../components/pageTitle.vue";
import LaddaButton from "../components/LaddaButton.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import {
  getRangeFilter,
  colsResizeable,
  colsResizeableReset
} from "@/helpers/ApiHelper";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    PageTitle,
    LaddaButton,
    FilterCurrencyBox
  }
})

export default class ContractDetailsOld extends TSXComponent<void> {
  loading = false;
  pageNumber = 1;
  curPage = 1;
  totalRows = 0;
  details: any = {};
  contractDetails = [];
  contractId = 0;
  filters: any = {
    status: "",
    sDate: "",
    eDate: "",
    sku: "",
    description: "",
    listPriceMin: "",
    listPriceMax: "",
    contractPriceMin: "",
    contractPriceMax: "",
    notes: "",
    GPSYPL: "",
    category: "",
    familyId: "",
    categoryFamily: "",
    seriesId: "",
    productServices: "",
    modelId: "",
    productModel: "",
    directPromotion: "",
    promoWebPrice: "",
  }
  sort = {
    field: null,
    direction: {
      status: 1,
      effectiveDate: 1,
      sku: 1,
      description: 1,
      listPrice: 1,
      contractPrice: 1,
      notes: 1,
      GPSYPL: 1,
      category: 1,
      familyId: 1,
      categoryFamily: 1,
      seriesId: 1,
      productServices: 1,
      modelId: 1,
      productModel: 1,
      directPromotion: 1,
      promoWebPrice: 1,
    }
  };
  directionField = "";
  pageHeaders = {
    STATUS: "Status",
    EFFECTIVEDATEFORMATTED: "Effective Date",
    SKU: "SKU",
    DESCRIPTION: "Description",
    LISTPRICE: "List Price",
    CONTRACTPRICE: "Contract Price",
    NOTES: "Notes",
    GPSYPL: "GPSy PL",
    CATEGORY: "Category",
    FAMILYID: "Family ID",
    CATEGORYFAMILY: "Category Family",
    SERIESID: "Series ID",
    PRODUCTSERVICES: "Product Services",
    MODELID: "Model ID",
    PRODUCTMODEL: "Product Model",
    DIRECTPROMOTION: "Direct Promotion",
    PROMOWEBPRICE: "Promo Web Price"
  };
  headerOrder: any = [
    "STATUS",
    "EFFECTIVEDATEFORMATTED",
    "SKU",
    "DESCRIPTION",
    "LISTPRICE",
    "CONTRACTPRICE",
    "NOTES",
    "GPSYPL",
    "CATEGORY",
    "FAMILYID",
    "CATEGORYFAMILY",
    "SERIESID",
    "PRODUCTSERVICES",
    "MODELID",
    "PRODUCTMODEL",
    "DIRECTPROMOTION",
    "PROMOWEBPRICE"
  ];
  selectedHeaders: any = [
    "STATUS",
    "EFFECTIVEDATEFORMATTED",
    "SKU",
    "DESCRIPTION",
    "LISTPRICE",
    "CONTRACTPRICE",
    "NOTES",
    "GPSYPL",
    "CATEGORY"
  ];

  async created() {
    this.contractId = this.$route.params.id ? parseInt(this.$route.params.id) : 0;
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Quotes",
        FunctionName: "ContractView",
        id: this.contractId,
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        status: this.filters.status,
        sDate: this.filters.sDate,
        eDate: this.filters.eDate,
        sku: this.filters.sku,
        description: this.filters.description,
        listPriceMin: this.filters.listPriceMin,
        listPriceMax: this.filters.listPriceMax,
        contractPriceMin: this.filters.contractPriceMin,
        contractPriceMax: this.filters.contractPriceMax,
        notes: this.filters.notes,
        GPSYPL: this.filters.GPSYPL,
        category: this.filters.category,
        familyId: this.filters.familyId,
        categoryFamily: this.filters.categoryFamily,
        seriesId: this.filters.seriesId,
        productServices: this.filters.productServices,
        modelId: this.filters.modelId,
        productModel: this.filters.productModel,
        directPromotion: this.filters.directPromotion,
        promoWebPrice: this.filters.promoWebPrice,
        order: this.sort.field ? this.sort.field : 0,
        direction:
          this.directionField != ""
            ? this.sort.direction[this.directionField]
            : 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS != 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.contractDetails = this.details.CONTRACTDETAILS;
        const contractHeaders = this.details.CONTRACTDETAILHEADERS || [];
        if (contractHeaders.length) {
        for (const header of contractHeaders) {
          if (!this.selectedHeaders.includes(header)) {
            this.selectedHeaders.push(header);
          }
        }
      }
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  listPageRedirection() {
    this.$router.go(-1);
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  searchBy(field) {
    if (this.filters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.fetchData();
  }

  resetBy(field) {
    if (this.filters[field] == "") {
      return;
    }
    this.pageNumber = 1;
    this.filters[field] = "";
    this.fetchData();
  }

  async searchByDate() {
    if (
      this.filters.sDate == "" && this.filters.eDate == ""
    ) {
      return;
    }
    this.pageNumber = 1;
    await this.fetchData();
  }

  async resetSearchByDate() {
    if (
      this.filters.sDate == "" && this.filters.eDate == ""
    ) {
      return;
    }
    this.pageNumber = 1;
    this.filters.sDate = "";
    this.filters.eDate = "";
    await this.fetchData();
  }

  async searchByListPrice() {
    if (
      this.filters.listPriceMin == "" && this.filters.listPriceMax == ""
    ) {
      return;
    }
    this.pageNumber = 1;
    await this.fetchData();
  }

  async resetSearchByListPrice() {
    this.pageNumber = 1;
    this.filters.listPriceMin = "";
    this.filters.listPriceMax = "";
    await this.fetchData();
  }

  async searchByContractPrice() {
    if (
      this.filters.contractPriceMin == "" && this.filters.contractPriceMax == ""
    ) {
      return;
    }
    this.pageNumber = 1;
    await this.fetchData();
  }

  async resetSearchByContractPrice() {
    this.pageNumber = 1;
    this.filters.contractPriceMin = "";
    this.filters.contractPriceMax = "";
    await this.fetchData();
  }

  async addHeader(c) {
    const addedCol = this.selectedHeaders.includes(c);
    colsResizeableReset();
    var sortedHeaders: string[] = [];
    for (const item of this.headerOrder) {
      if (this.selectedHeaders.includes(item)) {
        sortedHeaders.push(item);
      }
    }
    this.selectedHeaders = sortedHeaders;

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "contractDetails"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    colsResizeable();
  }

  removeColumn(header) {
    this.selectedHeaders = this.selectedHeaders.filter(item => item != header);
    this.addHeader(header);
  }

  disabledHeader(x) {
    if (!this.selectedHeaders.includes(x) && this.selectedHeaders.length > 13) {
      // prevent selecting over 13 cols
      return true;
    }

    return false;
  }
}
