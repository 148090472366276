



















































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";

import Loader from './Loader.vue'

interface Props {
  data: any,
  infoType: string,
  myLoading: boolean
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
  }
})
export default class HPAgentDetailsRowTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: "" })
  data!: any;

  @Prop({ required: false, default: "" })
  infoType!: string;

  @Prop({ required: false, default: false })
  myLoading!: boolean;
  
  loading = false;

  created() {
    if(this.infoType != "" && this.data == "") {
      this.loading = true;
    }else {
      this.loading = false;
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  @Watch("data")
  async dataChanged() {
    if(this.data != "") {
      this.loading = false;
    }
  }
}
