

















































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../models/common";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class CustomerProfitTargetModal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: [] })
  profitTargets!: any;

  @Prop({ required: false, default: 0 })
  aID!: number;

  $parent: any;
  $systemGroups: any;
  $validator: any;
  saving: boolean = false;
  currentYear: number = new Date().getFullYear();
  target_year: number = this.currentYear;
  target_profit: number = 0;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async save() {
    const result = await this.$validator.validateAll();
    if (result && this.aID) {
      let activeTarget = this.checkTarget();
      if (activeTarget) {
        this.errorMsgActiveTarget();
        return;
      }
      if (this.target_year > (this.currentYear+1)) {
        notifier.alert(`You can only set the target year till ${this.currentYear+1}.`);
        return;
      }
      this.saving = true;
      const response = await ApiHelper.callApi("post", {
        controller: "Customers",
        FunctionName: "UpdateProfitTargets",
        aID: this.aID,
        target_year: this.target_year,
        target_profit: this.target_profit,
        action: "insert"
      });

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        // if (response.NEW_TARGET_PROFIT) {
        //   this.$parent.profit_targets.push(response.NEW_TARGET_PROFIT);
        // }
        // if (response.CY_TARGET) {
        //   this.$parent.cy_target = response.CY_TARGET;
        // }
        this.$parent.initialLoad(-1);
        this.$emit("close");
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.saving = false;
    }
  }

  async changeTargetActive(item) {
    if (!item.ISACTIVE) {
      let activeTarget = this.checkTarget(item.TARGET_YEAR, item.TARGETID);
      if (activeTarget) {
        this.errorMsgActiveTarget();
        return;
      }
    }
    item.ISACTIVE = !item.ISACTIVE;
    const response = await ApiHelper.callApi("post", {
      controller: "Customers",
      FunctionName: "UpdateProfitTargets",
      aID: this.aID,
      isActive: item.ISACTIVE,
      targetUUID: item.TARGETUUID,
      action: "updateTargetActive"
    });
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      // if (response.CY_TARGET) {
      //   this.$parent.cy_target = response.CY_TARGET;
      // }
      this.$parent.initialLoad(-1);
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }

  checkTarget(year = this.target_year, id = 0) {
    return this.profitTargets.filter((item: any) => year == item.TARGET_YEAR && id != item.TARGETID && item.ISACTIVE).length ? true : false;
  }

  errorMsgActiveTarget() {
    notifier.alert("You have already set the target for this year. If you want to set a new target, Please turn off the existing Active Target.");
  }

}
