











































































































































































































































































































































































































































































































import axios from "axios";
import Vue from 'vue'
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import Datepicker from '../components/Datepicker.vue'
import LaddaButton from "../components/LaddaButton.vue";
import { notifier, wait } from "../models/common";
import directives from "../helpers/directives";
import OpportunityTagSelect from "../components/OpportunityTagSelect.vue";
import GlobalTagSelect from "@/components/GlobalTagSelect.vue";
import PageTitle from '../components/pageTitle.vue';
import CustomerAutoSuggestInput from "../components/CustomerAutoSuggestInput.vue";
import UserAutoSuggestInput from "../components/UserSuggestInput.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import poIDQuoteIDAutoSuggestInput from "@/components/poIDQuoteIDAutoSuggestInput.vue";
import moment from "moment";
import PhoneInput from "@/components/PhoneInput.vue";

declare const dataURL: string;
declare const $: any;
declare const htmlParse : Function;
declare const htmlCheck : Function;
declare const getRouteData : Function;

@Component({
  inheritAttrs: false,
  directives,
  components: {
    PhoneInput,
    Datepicker,
    LaddaButton,
    OpportunityTagSelect,
    PageTitle,
    CustomerAutoSuggestInput,
    DropdownControl,
    UserAutoSuggestInput,
    GlobalTagSelect,
    poIDQuoteIDAutoSuggestInput
  }
})
export default class createOpportunity extends TSXComponent<void> {
  $router: any;
  $route: any;
  $validator: any;
  currentRole = sessionStorage.getItem("userRole");

  title = "New Opportunity";
  customer = "";
  reseller = "";
  account_lead = "";
  account_lead_name = "";
  contactEmail = "";
  account_name = "";
  contact_name = "";
  email = "";
  phone = "";
  phoneExt = "";
  opp_number = "";
  sales_stage = 1;
  expected_close_date = "";
  created_date = "";
  follow_up = "";
  errorMsg = "";
  globalTags: any[] = [];
  oNotes = "";
  opportunityNote = "";
  loading: any = false;
  saving: any = false;
  uploading: any = false;
  total_amt: any = "";
  est_profit: any = "";
  est_profit_percentage: any = "";
  isEstProfitPercentage: boolean = false;
  oppID = 0;
  opportunityNoteId = 0;
  selectedOpportunityTagsID: number[] = [];
  selectedOpportunityTags: any[] = [];
  aID: number = 0;
  businessLineOptions: any = [];
  selectedBusinessLine: number[] = [];
  ProbabilityOptions = [
    {
      ID: 4,
      TEXT: "25%",
    },
    {
      ID: 3,
      TEXT: "50%",
    },
    {
      ID: 2,
      TEXT: "75%", 
    },
    {
      ID: 1,
      TEXT: "100%",
    }
  ];
  selectedProbability: number[] = [4];
  est_payment_date: string ="";
  orderQuoteIDs: string = "";
  selectedOrderAndQuoteIDs: any = [];

  billingAddresses: any[] = [];

  @Watch("account_name")
  async customerChanged(val) {
    if(val == null) {
      this.account_name = "";
      this.aID = 0;
    }
  }

  async created() {
    if (typeof this.$route.query.aName === "string") {
      this.account_name = this.$route.query.aName.length ? this.$route.query.aName : "";
    }
    if (
      typeof this.$route.query.aId !== "undefined"
      && typeof this.$route.query.aId !== "number"
    ) {
      this.aID = this.$route.query.aId.length ? this.$route.query.aId : 0;
    }
    if (typeof this.$route.params.id != "undefined" && parseInt(this.$route.params.id)) {
      this.oppID = parseInt(this.$route.params.id);
      this.title = 'Edit Opportunity';
    }
    //get tags
    const tagsResponse = await axios.post(dataURL + "?ReturnType=JSON", {
      controller				: "Customers",
      FunctionName			: "GlobalTagsList",
      hidePagination    : true
    });

    if(tagsResponse.data.STATUS == 1) {
      this.globalTags = tagsResponse.data.GLOBALTAGS.filter(item => item.FOROPPORTUNITIES);
    }
    if(this.oppID) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Opportunities",
          FunctionName: "View",
          ObjID: this.oppID,
          Content: "Detailed"
        });
        if (response.data.STATUS == 1) {
          let oppDetails = response.data.OPPORTUNITYDETAILS[0];
          const opportunityNoteArr = response.data.OPPORTUNITYNOTES;
          this.account_lead = oppDetails.ACCOUNT_LEAD;
          this.account_lead_name = oppDetails.ACCOUNT_LEAD_NAME;
          this.account_name = oppDetails.ACCOUNT_NAME;
          this.contact_name = oppDetails.CONTACT_NAME || '';
          this.phone = oppDetails.PHONE || '';
          this.phoneExt = oppDetails.PHONE_EXT || '';
          this.email = oppDetails.EMAIL || '';
          this.aID = oppDetails.AID;
          this.opp_number = oppDetails.OPP_NUMBER;
          this.sales_stage = oppDetails.SALESSTAGE;
          this.expected_close_date = oppDetails.EXPECTED_CLOSE_DATE_FORMATTED;
          this.est_payment_date = oppDetails.EST_PAYMENT_DATE_FORMATTED;
          this.created_date = oppDetails.CREATED_DATE_FORMATTED;
          this.follow_up = oppDetails.FOLLOW_UP_FORMATTED;
          this.total_amt = oppDetails.TOTAL_AMT.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
          this.est_profit = oppDetails.ESTPROFIT.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
          this.est_profit_percentage = oppDetails.ESTPROFITPERCENTAGE.toFixed(2);
          this.oNotes = oppDetails.DESCRIPTION;
          this.selectedOpportunityTagsID = oppDetails.OPPORTUNITYTAGS;
          this.globalTags = response.data.globalTags.filter(item => item.FOROPPORTUNITIES);
          this.selectedBusinessLine = oppDetails.BUSINESSLINEID ? [oppDetails.BUSINESSLINEID] : [];
          this.selectedProbability = oppDetails.PROBABILITY ? [oppDetails.PROBABILITY] : [4];
          this.selectedOrderAndQuoteIDs = oppDetails.OPPLINKORDERANDQUOTE || [];

          if (Array.isArray(opportunityNoteArr)) {
            const generalNote = opportunityNoteArr.find((opn) => opn.OPPTYPE === "1");
            this.opportunityNote = generalNote.OPPNOTE;
            this.opportunityNoteId = generalNote.OPPNOTEID;
          }

          var selectedOpportunityTagsData: number[] = [];
          $.each(this.selectedOpportunityTagsID, function (i, val) {
            selectedOpportunityTagsData.push(parseInt(val))
          });
          this.selectedOpportunityTagsID = selectedOpportunityTagsData;
          this.globalTags.forEach(data => {
            selectedOpportunityTagsData.map(e => {
              if (e === data.CUSTOMFIELDOPTIONID) {
                this.selectedOpportunityTags.push({
                  CUSTOMFIELDOPTIONID: parseInt(data.CUSTOMFIELDOPTIONID),
                  CUSTOMFIELDOPTIONNAME: data.CUSTOMFIELDOPTIONNAME,
                  FOROPPORTUNITIES: data.FOROPPORTUNITIES
                })
              }
            })
          });
          this.selectedOpportunityTags = this.selectedOpportunityTags.filter(item => item.FOROPPORTUNITIES);
          var selectedOpportunityTagsData: number[] = [];
          $.each(this.selectedOpportunityTags, function (i, val) {
            selectedOpportunityTagsData.push(parseInt(val.CUSTOMFIELDOPTIONID))
          });
          this.selectedOpportunityTagsID = selectedOpportunityTagsData;

          if (oppDetails.AID) {
            await this.getBillingAddresses(oppDetails.AID);
          }
        }
      }
      catch (e) {
        //handle error
      }
    }
    await this.setBusinessLines();
  }

  // listPageRedirection(){
  //   this.$router.push({ name: "Opportunities" });
  // }

  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    var result = true;
    result = await this.$validator.validateAll();

    if($("#account_name").val() == "" || $("#oNotes").val() =="" || this.expected_close_date == "" || !this.est_payment_date.length) {
      notifier.alert("Please fill out necessary fields, thank you!");
      this.saving = "error";
      result = false;
    }

    var htmlCount = 0;
    if (this.account_name != "") {
      if (htmlCheck(this.account_name)) {
        this.account_name = htmlParse(this.account_name);
        htmlCount++;
      }
    }

    if (this.account_lead != "") {
      if (htmlCheck(this.account_lead)) {
        this.account_lead = htmlParse(this.account_lead);
        htmlCount++;
      }
    }

    if (this.opp_number != "") {
      if (htmlCheck(this.opp_number)) {
        this.opp_number = htmlParse(this.opp_number);
        htmlCount++;
      }
    }

    if (this.oNotes != "") {
      if (htmlCheck(this.oNotes)) {
        this.oNotes = htmlParse(this.oNotes);
        htmlCount++;
      }
    }

    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
      result = false;
    }

    if (!result) {
      return;
    }
    this.saving = true;

    try {
      const result = await this.createOpportunity(this);
      if (result) {
        this.saving = false;
        if(this.oppID) {
          this.$router.push({name: "OpportunityDetails", params: { id: `${this.oppID}`}});
        } else {
          this.$router.push({name: "Opportunities"});
        }

      }
    } catch (err) {
      this.saving = "error";
    } finally {
      this.saving = false;
    }
  }

  createOpportunity(value) {
    var self = this;
    var saveData = {};
    saveData["Controller"] = "Opportunities";
    saveData["FunctionName"] = "Add";
    saveData["account_name"] = value.account_name;
    saveData["contact_name"] = value.contact_name;
    saveData["phone"] = value.phone;
    saveData["phoneExt"] = value.phoneExt;
    saveData["email"] = value.email;
    saveData["description"] = value.oNotes;
    saveData["opportunityNote"] = value.opportunityNote;
    saveData["total_amt"] = value.total_amt.replaceAll(",", "") || 0;
    saveData["est_profit"] = value.est_profit.replaceAll(",", "") || 0;
    saveData["est_profit_percentage"] = value.est_profit_percentage || 0;
    saveData["opp_number"] = value.opp_number;
    saveData["sales_stage"] = value.sales_stage;
    saveData["expected_close_date"] = value.expected_close_date;
    saveData["est_payment_date"] = value.est_payment_date;
    saveData["created_date"] = value.created_date;
    saveData["follow_up"] = value.follow_up;
    saveData["account_lead"] = value.account_lead;
    saveData["tags"] = this.selectedOpportunityTagsID;
    saveData["aID"] = this.aID;
    saveData["userId"] = sessionStorage.getItem("userId");
    saveData["businessLineId"] = this.selectedBusinessLine[0] || 0;
    saveData["selectedProbability"] = this.selectedProbability[0] || 1;
    if (this.oppID) {
      saveData["ms_opp_id"] = this.oppID;
      saveData["ms_opp_note_id"] = this.opportunityNoteId;
      saveData["action"] = "update";
    }
    let orderIDs = [];
    let quoteIDs = [];
    this.selectedOrderAndQuoteIDs.map((item: any) => {
      if (item.TYPE === 1) {
        orderIDs.push(item.ID);
      } else if (item.TYPE === 2) {
        quoteIDs.push(item.ID);
      }
    });
    saveData["orderIDs"] = orderIDs.join();
    saveData["quoteIDs"] = quoteIDs.join();
    
    var saveOpportunity = getRouteData(saveData);
    return saveOpportunity.then(async function(response, statusText, jqXHR) {
      if (response.data.ERROR) {
        return false;
      }
      self.oppID = response.data.oppID || self.oppID;
      return true;
    });
  }

  backToDetails() {
    if (this.oppID) {
      this.$router.push({
        name: "OpportunityDetails",
        params: { id: this.oppID }
      });
    } else {
      this.$router.push({
        name: "Opportunities"
      });
    }
  }

  toggleSelectedOpportunityTags(selectedID: number, tagName) {
    const index = this.selectedOpportunityTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedOpportunityTagsID.push(selectedID);
      this.selectedOpportunityTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      //this.saveGlobalTag();
      return;
    }

    this.selectedOpportunityTagsID.splice(index, 1);
    this.$delete(this.selectedOpportunityTags, index);
    //this.saveGlobalTag();
  }

  selectAllOpportunityTags() {
    this.resetOpportunityTags();
    this.selectedOpportunityTagsID = this.globalTags.map((val: any) => val.CUSTOMFIELDOPTIONID);
    this.selectedOpportunityTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    //this.saveGlobalTag();
  }

  resetOpportunityTags() {
    this.selectedOpportunityTags = [];
    this.selectedOpportunityTagsID = [];
    //this.saveGlobalTag();
  }

  updateGlobalTagList(type, index, tagID) {
    if (this.selectedOpportunityTagsID && type == "delete") {
      this.$delete(this.selectedOpportunityTagsID, index);
      this.$delete(this.selectedOpportunityTags, index);
    }
    //this.saveGlobalTag();
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  updateCustomer(data) {
    this.account_name = data.account.ANAME;
    this.aID = data.account.AID;
    this.getBillingAddresses(data.account.AID);
  }

  async setBusinessLines() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    (response.data.businessLineOptions || []).map(item => (
      this.businessLineOptions.push({
        ID: item.ID,
        TEXT: item.BUSINESSLINENAME,
        data: item
      })
    ));
  }

  async getBillingAddresses(aID) {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Customers",
      FunctionName: "BillingAddress",
      aID: aID
    });

    if (response.data.STATUS === 1) {
      this.billingAddresses = response.data.BILLINGADDRESSES || [];
    }
  }

  updateBusinessLine(id) {
    this.selectedBusinessLine = [id];
  }

  updateProbability(id) {
    this.selectedProbability = [id];
  }

  estCalc1() {
    const totalAmt = this.total_amt.replaceAll(",", "");
    const estimateProfit = this.est_profit.replaceAll(",", "");

    let totalAmount = !isNaN(totalAmt) ? totalAmt : 0;
    let estProfit = !isNaN(estimateProfit) ? estimateProfit : 0;
    if (parseFloat(this.est_profit_percentage || 0) && this.isEstProfitPercentage) {
      this.estCalc2();
    } else {
      let estPercentage = totalAmount ? ((estProfit * 100)/totalAmount) : 0;
      this.est_profit_percentage = estPercentage.toFixed(2);
    }
  }

  estCalc2() {
    const totalAmt = this.total_amt.replaceAll(",", "");

    let totalAmount = !isNaN(totalAmt) ? totalAmt : 0;
    let estProfitPercentage = !isNaN(this.est_profit_percentage) ? this.est_profit_percentage : 0;
    let estProfitAmount = (estProfitPercentage * totalAmount)/100;
    this.est_profit = estProfitAmount.toFixed(2);
  }

  updateEstPaymentDate() {
    let estCloseDate = new Date(this.expected_close_date);
    if (moment(estCloseDate).isValid()) {
      // find net terms from billing addresses
      let netTerms = 45;
      for (const billAddress of this.billingAddresses) {
        if (billAddress.ISDEFAULT === 1) {
          netTerms = billAddress.NETTERMS;
        }
      }

      let estPaymentDate = new Date(estCloseDate.setDate(estCloseDate.getDate() + netTerms));
      this.est_payment_date = moment(estPaymentDate).format("MM/DD/YYYY");
    } else {
      this.est_payment_date = "";
    }
  }

  updatePoAndQuote(data) {
    if (data.poAndQuote.ID) {
      // Find the index of the order/quote in the list of selected orders/quotes
      const index = this.selectedOrderAndQuoteIDs.findIndex((item: any) => item.ID == data.poAndQuote.ID);

      // If the order/quote is not already in the list, add it
      if (index === -1) {
        let orderAndQuoteStruct = {
          "ID"          : data.poAndQuote.ID,
          "TYPE"        : data.poAndQuote.TYPE,
          "TYPEASTEXT"  : data.poAndQuote.TYPEASTEXT,
          "PO"          : data.poAndQuote.PO,
          "DESCRIPTION" : data.poAndQuote.DESCRIPTION
        }
        this.selectedOrderAndQuoteIDs.push(orderAndQuoteStruct);
      }
    }
    this.orderQuoteIDs = " ";
  }

  deleteSelectedPoAndQuote(index) {
    this.$delete(this.selectedOrderAndQuoteIDs, index);
  }

  getPoAndQuoteText(item) {
    let orderAndQuoteString = "";
    orderAndQuoteString += (item.TYPEASTEXT ? `${item.TYPEASTEXT}: ` : '') + `#${item.ID}`;
    if (item.PO) {
      orderAndQuoteString += ` PO: ${item.PO}`;
    }
    orderAndQuoteString += ` Description: ${item.DESCRIPTION}`;
    return orderAndQuoteString
  }

  getExt(e){
    if(e != undefined){
      return e.split(' ')[0]
    }
    else{
      return 'US'
    }
  }

  countryChanged(obj) {
    if (typeof obj.countryCode != 'undefined' && typeof obj.countryCallingCode != 'undefined') {
      this.phoneExt = obj.countryCode + ' ' + '+' + obj.countryCallingCode;
    }
  }

  formatNumber(n) {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatCurrency(event, id) {
    // Ensure value is treated as a string
    let input_val = String(event.target.value);

    // Extract and remove the dollar sign to handle numeric operations
    input_val = input_val.replace(/^\$/, "");

    if (input_val === "") {
      return '';
    }

    // Split the input value into two parts: before and after the decimal point
    let parts = input_val.split(".");
    let left_side = parts[0];
    let right_side = parts.length > 1 ? '.' + parts[1] : '';

    left_side = this.formatNumber(left_side);

    // Only format the right side if there's something to format
    if (right_side) {
      right_side = right_side.substring(0, 3); // Keep the decimal point and the next two digits
    }

    this[id] = `${left_side}${right_side}`;
  }

  handleBlur(event, id) {
    let input_val = String(event.target.value);

    // Remove any existing dollar sign to avoid confusion in formatting
    input_val = input_val.replace(/^\$/, "");

    if (input_val === "") {
      this[id] = "";
      return '';
    }

    // Check if the value includes a decimal point but no cents or incomplete cents
    let parts = input_val.split(".");
    if (parts.length < 2 || parts[1].length < 2) {
      // Append missing parts or pad with zeros to ensure two decimal places
      input_val = parts[0] + (parts.length < 2 ? ".00" : "." + parts[1].padEnd(2, '0'));
    } else {
      // Ensure two decimal places if user entered partial cents
      input_val = parts[0] + "." + parts[1].substring(0, 2);
    }

    
    // Re-add the dollar sign for consistent currency formatting
    this[id] = `${input_val}`;

  }
}
