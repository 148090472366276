

















































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit } from "vue-property-decorator";
import AccountMultiselect from "@/components/AccountMultiselect.vue";
import SecurityMultiSelect from "@/components/SecurityMultiSelect.vue";
import LaddaButton from "@/components/LaddaButton.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    AccountMultiselect,
    LaddaButton,
    SecurityMultiSelect
  }
})

export default class AddAccountsModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: [] })
  accountsList!: any[];

  @Prop({ required: false, default: [] })
  groupList!: any[];

  selectedAccountIds: any = [];
  selectedAccounts: object[] = [];
  loading: boolean = false;
  selectedGroupIds: any = [];
  selectedGroups: object[] = [];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  toggleSelectedAccIds(selectedID: number, accountName) {
    const index = this.selectedAccountIds.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedAccountIds.push(selectedID);
      this.selectedAccounts.push({
        AID: selectedID,
        ANAME: accountName
      });
      return;
    }

    this.selectedAccountIds.splice(index, 1);
    this.$delete(this.selectedAccounts, index);
  }

  selectAllAccs() {
    this.resetAccs();
    this.selectedAccountIds = this.accountsList.map(
      (val: any) => val.AID
    );
    this.selectedAccounts = this.accountsList.map((val: any) => ({
      AID: val.AID,
      ANAME: val.ANAME
    }));
  }

  resetAccs() {
    this.selectedAccounts = [];
    this.selectedAccountIds = [];
  }

  updateAccountList(aID) {
    const accountNameIdx = this.selectedAccounts.findIndex((item: any) => item.AID === aID);
    if (accountNameIdx !== -1) {
      this.selectedAccounts.splice(accountNameIdx, 1);
    }
    const accountIdIdx = this.selectedAccountIds.findIndex(id => id === aID);
    if (accountIdIdx !== -1) {
      this.selectedAccountIds.splice(accountIdIdx, 1);
    }
  }
  
  toggleSelectedGroupId(selectedID: number, groupName: string) {
    const index = this.selectedGroupIds.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedGroupIds.push(selectedID);
      this.selectedGroups.push({
        SECURITYGROUPID: selectedID,
        SECURITYGROUPNAME: groupName
      });
      return;
    }

    this.selectedGroupIds.splice(index, 1);
    this.$delete(this.selectedGroups, index);
  }

  selectAllGroups() {
    this.resetGroups();
    this.selectedGroupIds = this.groupList.map(
      (val: any) => val.SECURITYGROUPID
    );
    this.selectedGroups = this.groupList.map((val: any) => ({
      SECURITYGROUPID: val.SECURITYGROUPID,
      SECURITYGROUPNAME: val.SECURITYGROUPNAME
    }));
  }

  resetGroups() {
    this.selectedGroups = [];
    this.selectedGroupIds = [];
  }

  updateSGList(groupID) {
    const groupNameIdx = this.selectedGroups.findIndex((item: any) => item.SECURITYGROUPID === groupID);
    if (groupNameIdx !== -1) {
      this.selectedGroups.splice(groupNameIdx, 1);
    }
    const groupIdIdx = this.selectedGroupIds.findIndex(id => id === groupID);
    if (groupIdIdx !== -1) {
      this.selectedGroupIds.splice(groupIdIdx, 1);
    }
  }

  exported() {
    this.loading = true;
    this.$emit("export", {
      accountIds: this.selectedAccountIds,
      groupIds: this.selectedGroupIds
    });
  }
}
