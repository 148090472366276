

























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from 'axios'

import Pagination from "../components/Pagination.vue";

import { QuoteAPIRepo, Filters, Sort, SortField, QuoteStatus, ResellerCheckList } from '../repos/QuoteAPIRepo'
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import { Quote } from "../models/Quote";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
  }
})
export default class Quotes extends TSXComponent<void> {
  selectedID = [];
  currentRole = sessionStorage.getItem("userRole");
  loading = false
  quotesData: Quote.Root | any = {}
  pageNumber = 1
  items: Quote.Quotes[] = []
}


