






















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import Datepicker from "@/components/Datepicker.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import moment from "moment";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Datepicker,
    ConfirmRemoveItemModal
  }
})

export default class ModalEditOrderStatus extends TSXComponent<void> {

  @Prop({ required: true, default: '' })
  title!: string;

  @Prop({ required: false, default: false })
  showShippedDate!: boolean;

  @Prop({ required: false, default: "" })
  shippedDate!: string;

  $refs!: {
    modal: HTMLDivElement;
  };

  $parent: any;
  saving: boolean | "error" = false;
  confirmOrderLinesChangeVisible: boolean = false;
  updateAllLineItem: boolean = false;

  created() {
    if (!this.shippedDate.length) {
      this.shippedDate = moment(new Date()).format("MM/DD/YYYY");
    }
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
    $('.datepicker-container.datepicker-dropdown').addClass('datepicker-hide');
  }

  onSave() {
    let hasError = false;
    let errorMessage = '';
    if (!moment(this.shippedDate).isValid()) {
      errorMessage = 'Invalid Shipped Date';
      hasError = true;
    }
    if (hasError) {
      ApiHelper.showErrorMessage(errorMessage);
      return;
    }
    this.confirmOrderLinesChangeVisible = true;
  }

  confirm() {
    this.updateAllLineItem = true;
    this.$emit("save", {
      shippedDate : this.shippedDate,
      updateAllLineItem : true
    });
  }

  cancel() {
    this.confirmOrderLinesChangeVisible = false;
    this.$emit("save", {
      shippedDate : this.shippedDate,
      updateAllLineItem : false
    });
  }
}
