






































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

import LaddaButton from "../components/LaddaButton.vue";
import { notifier, wait } from "../models/common";
import axios from "axios";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class RefreshStore extends TSXComponent<void> {
  saving: boolean | "error" = false;
  updateNotes: string = "";
  $parent: any;

  @Prop({ required: false, default: "" })
  selectedIDs!: Array<any>;

  @Prop({ required: false, default: [] })
  selectedItems!: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  loading = false;
  storeSKUs: any = [];
  uniqueSKUs: any = [];
  skuAssociations: any = {};
  newTR = "";

  async created() {
    this.getUniqueSKUs();
    // get available skus in smartstore
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "ExternalSends",
        FunctionName: "GetSmartStoreSKUs",
        orderIds: [
          ...new Set(this.selectedItems.map(item => item.PURCHASEID))
        ].join(",")
      });
      const message = response.data.MESSAGE || "";
      if (response.data.STATUS == 1) {
        this.storeSKUs = response.data.products || [];
        this.skuAssociations = response.data.associatedSKUs || {};
      } else {
        notifier.alert(message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  getUniqueSKUs() {
    this.uniqueSKUs = [
      ...new Set(
        this.selectedItems.map(item => {
          return {
            sku: item.SKU,
            id: item.HARDWAREID,
            name: item.HDESCRIPTION
          };
        })
      )
    ];
    this.uniqueSKUs.forEach(item => {
      this.$set(this.skuAssociations, item.sku, "");
    });
  }

  updateSkuAssociation(sku, associatedSku) {
    this.$set(this.skuAssociations, sku, associatedSku);
  }

  async updateAll() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Queries",
        subsystem: "Helpers",
        FunctionName: "varParam",
        action: "update",
        associatedSKUs: this.skuAssociations
      });

      if (response.status === 200) {
        // this.$emit("close");
        // const response2 = await axios.post("https://dev-us.com/Framework/?ReturnType=JSON", {
        //   controller: "Carts",
        //   FunctionName:"CartAction",
        //   SaveFunctionType: "ProductAddUpdate",
        //   associatedSKUs: this.skuAssociations
        // }, {
        //   headers: {
        //     'Content-Type': 'application/json'
        //     // any other headers you might need
        //   }
        // });
      } else {
        // Handle non-200 responses here, if necessary
        console.error("Non-200 response received:", response);
      }

      // validate and prepare request data
      let isValid = true;
      const products: any = [];
      for (const item of this.uniqueSKUs) {
        const ssSKU = this.skuAssociations[item.sku] || "";
        const ssProduct = this.storeSKUs.find(
          item => item.OLMS_PRODUCT_SKU == ssSKU
        );
        if (ssSKU == "" || !ssProduct) {
          isValid = false;
          break;
        }

        products.push({
          OLMS_PRODUCT_ID: ssProduct.OLMS_PRODUCT_ID,
          OLMS_PRODUCT_SKU: ssProduct.OLMS_PRODUCT_SKU,
          quantity: 1
        });
      }

      if (!isValid) {
        notifier.alert("Please select Store SKU");
        return;
      }

      // try to create a SS TR
      const result = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "ExternalSends",
        FunctionName: "CreateSmartStoreTR",
        orderIds: this.selectedItems.map(item => item.PURCHASEID).join(","),
        products
      });
      const message = result.data.MESSAGE || "";
      if (result.data.STATUS == 1) {
        // success
        // this.$emit("close");
        this.$emit("resetSelectedIds");

        this.newTR = result.data.newTR || "";
        if (this.newTR) {
          notifier.success(`Created SS TR ${this.newTR}`);
        }
      } else {
        notifier.alert(message || "Something went wrong");
      }
    } catch (error) {
      // Handle errors from the axios request here
      console.error("Error with request:", error);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }
}
