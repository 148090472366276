


















































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import axios from "axios";
import LaddaButton from "../components/LaddaButton.vue";
import UserSuggestInput from "../components/UserSuggestInput.vue";
import SecurityMultiSelect from "../components/SecurityMultiSelect.vue";
import AccountTagSelect from "../components/AccountTagSelect.vue";
import AcctsCommUpdateModal from "../components/AcctsCommUpdateModal.vue";
import { notifier } from "../models/common";
import directives from "../helpers/directives";
import BusinessLineToolTip from "../components/BusinessLineToolTip.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
interface Events {
  initialLoad: { index };
}
interface commObj {
  USERID: number;
  PERCENTAGE: string;
  BUSINESSLINEID: number;
  BUSINESSLINENAME: string;
}
interface usersObj {
  USERID: number;
  UFULLNAME?: string;
}
interface securityObj {
  SECURITYGROUPID: number;
  SECURITYGROUPNAME?: string;
}

declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const getMaxRows: Function;

@Component({
  inheritAttrs: false,
  components: {
    UserSuggestInput,
    SecurityMultiSelect,
    AccountTagSelect,
    AcctsCommUpdateModal,
    LaddaButton,
    BusinessLineToolTip,
    ConfirmRemoveItemModal
  },
  directives
})
export default class CustomerDetailsTabCommission extends TSXComponent<Events> {
  @Prop({ required: true })
  selectedusers!: usersObj[];

  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: true })
  selectedgroups!: securityObj[];

  @Prop({ required: true })
  alias!: string;

  @Prop({ required: true })
  accname!: string;

  @Prop({ required: true })
  accid!: string;

  @Prop({ required: true })
  commAcc!: string;

  @Prop({ required: true })
  titlename!: string;

  @Prop({ required: true })
  aid!: number;

  @Prop({ required: true })
  remainingCommValue!: number;

  @Prop({ required: true })
  commAvail!: number;

  @Prop({ required: true })
  commisionusers!: commObj[];

  @Prop({ required: true })
  allSGComm!: object[];

  @Prop({ required: true })
  selectedTagsID!: number[];

  @Prop({ required: true })
  selectedTags!: object[];

  @Prop({ required: true })
  businessLineOptions!: object[];

  @Prop({ required: true })
  accountTags!: object[];

  @Prop({ required: true })
  remainingBusinessLineComm!: object[];

  @Prop({ required: false, default: false })
  businessLineSwitch!: boolean;

  @Prop({ required: false, default: 0 })
  businessLineId!: number;

  loading: boolean | string = false;
  commissionAdding = this.loading;
  AcctsCommUpdateModalVisible = false;
  userType = 1;
  userID = 0;
  remainingComm = 0;
  userAvailable = false;
  aID = 0;
  userName = "";
  // selectedTags: object[] = [];
  // selectedTagsID: number[] = [];
  selectedTagsIDComm: number[] = [];
  selectedTagsComm: object[] = [];
  selectedSecurityIdsComm: number[] = [];
  selectedSGComm: object[] = [];
  // allSGComm: object[] = [];
  businessLineToolTip = false;
  oldBlineComm: any = [];
  orgCommisionusers: any = {};
  confirmBLineToggle = false;
  bLineLoading = false;

  created() {
    this.orgCommisionusers = JSON.parse(JSON.stringify(this.commisionusers));
    this.remainingComm = this.remainingCommValue;
  }

  /** account tags at commission tab */
  toggleSelectedTagsComm(selectedID: number, tagName) {
    const index = this.selectedTagsIDComm.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedTagsIDComm.push(selectedID);
      this.selectedTagsComm.push({
        ACCOUNTTAGID: selectedID,
        TAGNAME: tagName
      });
      return;
    }

    this.selectedTagsIDComm.splice(index, 1);
    this.$delete(this.selectedTagsComm, index);
  }
  selectAllTagsComm() {
    this.resetTagsComm();
    var comInstance = this;
    this.accountTags.forEach(function(val: any) {
      comInstance.selectedTagsIDComm.push(val.ACCOUNTTAGID);
      comInstance.selectedTagsComm.push({
        ACCOUNTTAGID: val.ACCOUNTTAGID,
        TAGNAME: val.TAGNAME
      });
    });
  }
  resetTagsComm() {
    this.selectedTagsComm = [];
    this.selectedTagsIDComm = [];
  }
  updateTagListComm(type, index, tagID) {
    if (this.selectedTagsIDComm && type == "delete") {
      this.$delete(this.selectedTagsIDComm, index);
      this.$delete(this.selectedTagsComm, index);
    }
  }

  /** SG at commission tab */
  toggleSelectedGroupIdComm(selectedID: number, groupName) {
    const index = this.selectedSecurityIdsComm.findIndex(
      id => id === selectedID
    );
    if (index === -1) {
      this.selectedSecurityIdsComm.push(selectedID);
      this.selectedSGComm.push({
        SECURITYGROUPID: selectedID,
        SECURITYGROUPNAME: groupName
      });
      return;
    }

    this.selectedSecurityIdsComm.splice(index, 1);
    this.$delete(this.selectedSGComm, index);
  }
  selectAllGroupsComm() {
    this.resetGroupsComm();
    var comInstance = this;
    this.allSGComm.forEach(function(val: any) {
      comInstance.selectedSecurityIdsComm.push(val.SECURITYGROUPID);
      comInstance.selectedSGComm.push({
        SECURITYGROUPID: val.SECURITYGROUPID,
        SECURITYGROUPNAME: val.SECURITYGROUPNAME
      });
    });
  }
  resetGroupsComm() {
    this.selectedSecurityIdsComm = [];
    this.selectedSGComm = [];
  }
  updateSGListComm(type, index, tagID) {
    if (this.selectedSecurityIdsComm && type == "delete") {
      this.$delete(this.selectedSecurityIdsComm, index);
      this.$delete(this.selectedSGComm, index);
    }
  }
  updateCustomer(data:any = {}) {
    var isFromupdateBlineID = true;
    if (Object.keys(data).length) {
      this.userName = data.account.UFNAME + " " + data.account.ULNAME;
      this.userID = data.account.USERID;
      this.userType =
        typeof data.account.TYPE != "undefined" ? data.account.TYPE : 1;
        isFromupdateBlineID = false;
    }
    this.aID = this.aid;

    var dataObj = {
      controller: "Users",
      FunctionName: "AccountCommission",
      from: "checkUserAvailable",
      userID: this.userID,
      aID: this.aID,
      accountID: this.accid,
      percentage: 0,
      type: this.userType,
      businessLineId: this.businessLineId,
      businessLineSwitch: this.businessLineSwitch
    };
    var self = this;
    getRouteData(dataObj).then(function(response) {
      self.userAvailable = response.data.USERAVALIABLE;
      self.commAvail = response.data.USERSEXISTINGPER;
      if (!isFromupdateBlineID) self.businessLineId = response.data.BUSINESSLINEID;
      var userPer = response.data.USERPER;   
      if (self.userAvailable) {
        var minRemainingComm = 100;
        self.oldBlineComm = JSON.parse(JSON.stringify(self.remainingBusinessLineComm));

        // calculate the remaining commission for the update operation
        self.oldBlineComm.forEach((oldBLine:any) => {
          if (!self.businessLineId) {
            oldBLine.REMAININGCOMM = oldBLine.REMAININGCOMM + userPer;
            if (!oldBLine.ID) self.remainingComm = oldBLine.REMAININGCOMM;
          }
          else if (oldBLine.ID == self.businessLineId) {
            oldBLine.REMAININGCOMM = oldBLine.REMAININGCOMM + userPer;
            self.remainingComm = oldBLine.REMAININGCOMM;
          }
          // calculate the minimum remaining commission
          if (oldBLine.ID && minRemainingComm > oldBLine.REMAININGCOMM)  minRemainingComm = oldBLine.REMAININGCOMM;
        });

        // set minimum available commission to ALL type on updating commission;
        self.oldBlineComm.every((bline:any) => {
          if(!bline.ID) {
            bline.REMAININGCOMM = minRemainingComm;
            return false;
          }
          return true;
        })
      }
      $("input#commissionPercentage").focus();
    });
  }
  customChange(e) {
    if (e == null) {
      this.userID = 0;
    }
  }
  removeCommission(item, remainingComm) {
    this.loading = true;
    var dataObj = {
      controller: "Users",
      FunctionName: "AccountCommission",
      userID: item.USERID,
      aID: this.aid,
      percentage: 0,
      remainingComm: remainingComm,
      type: item.TYPE,
      linkPaymentId: item.LINKPAYMENTID,
      businessLineId: item.BUSINESSLINEID
    };
    var self = this;
    self.commisionusers.forEach((element, index) => {
      if (item.USERID == element.USERID && item.BUSINESSLINEID == element.BUSINESSLINEID) {
        self.$delete(self.commisionusers, index);
      }
    });
    getRouteData(dataObj).then(function(response, statusText, jqXHR) {
      // self.remainingComm = response.data.REMAININGCOMM;
      self.commAvail = response.data.REMAININGCOMM;
      self.loading = false;
  
      try {
        if (response.data.STATUS) {
          //clear selected user before
          self.userID = 0;
          self.userName = "";

          var dataObj = {};
          dataObj["controller"] = "Customers";
          dataObj["FunctionName"] = "View";
          dataObj["aID"] = self.accid;
          dataObj["userID"] = self.aID;
          dataObj["maxRows"] =
            sessionStorage.getItem("maxRows") != ""
              ? sessionStorage.getItem("maxRows")
              : getMaxRows();
          self.loading = true;
          var customResult = getRouteData(dataObj);
          customResult.then(function(response, statusText) {
            self.commisionusers = response.data.CUSTOMERS[0].COMMISIONUSERS;
            self.orgCommisionusers = JSON.parse(JSON.stringify(self.commisionusers));
            self.remainingComm = response.data.CUSTOMERS[0].REMAININGCOMM;
            self.remainingBusinessLineComm = response.data.CUSTOMERS[0].REMAININGBUSINESSLINECOMM;
            self.commAvail = response.data.CUSTOMERS[0].REMAININGCOMM;
            self.loading = false;
            self.$emit("initialLoad", 7);
          });
          notifier.success(response.data.MESSAGE);
          $(".IZ-select__input input").val("");
        } else {
          notifier.alert(response.data.MESSAGE);
        }
      } catch (e) {
        //handle error
      }
    });
  }
  async addCommission(commAvail) {
    var errorTab = 0;
    // var result = true;
    var result = await this.$validator.validateAll();
    // this.$validator.validateAll().then(async result => {
    if (!result) {
      return;
    }
    if ($("#accountName input[type=text]").val() == "") {
      $(".errorMsg.userName").text("The User Name / Var  field is required");
      result = false;
      errorTab = 2;
    } else {
      $(".errorMsg.userName").text("");
    }

    if ($("#commissionPercentage").val() != "") {
      if (isNaN(this.remainingComm)) {
        $(".errorMsg.commPercent").text("Please Enter Valid numeric format");
        result = false;
        errorTab = 2;
      } else {
        $(".errorMsg.commPercent").text("");
      }
    }

    if (
      $("#commissionPercentage").val() > 100 ||
      $("#commissionPercentage").val() <= 0
    ) {
      result = false;
      errorTab = 2;
    }

    if (result) {
      result = this.checkBusinessComission();
      if (!result) {
        errorTab = 2;
      }   
    }

    var existedLinkID: any = [];
    var val: any;
    var realCommAvail = 0;
    if (this.commisionusers.length) {
      //check if existed in list
      for (val of this.commisionusers) {
        if (
          val.USERID == this.userID &&
          val.BUSINESSLINEID == this.businessLineId &&
          val.TYPE == this.userType &&
          typeof val.LINKPAYMENTID != "undefined"
        ) {
          existedLinkID.push(val.LINKPAYMENTID);
        }
      }
    }

    if (result) {
      var dataObj = {
        controller: "Users",
        FunctionName: "AccountCommission",
        userID: this.userID,
        aID: this.aID,
        percentage: this.remainingComm,
        type: this.userType,
        linkPaymentId: existedLinkID.length ? existedLinkID[0] : 0,
        businessLineId: this.businessLineId
      };
      var self = this;

      this.commissionAdding = true;
      var resultRes = getRouteData(dataObj);
      await resultRes.then(function(response, statusText) {
        try {
          if (response.data.STATUS) {
            //clear selected user before
            self.userID = 0;
            self.userName = "";

            var dataObj = {};
            dataObj["controller"] = "Customers";
            dataObj["FunctionName"] = "View";
            dataObj["aID"] = self.accid;
            dataObj["userID"] = self.aID;
            dataObj["maxRows"] =
              sessionStorage.getItem("maxRows") != ""
                ? sessionStorage.getItem("maxRows")
                : getMaxRows();
            self.loading = true;
            var customResult = getRouteData(dataObj);
            customResult.then(function(response, statusText) {
              self.commisionusers = response.data.CUSTOMERS[0].COMMISIONUSERS;
              self.orgCommisionusers = JSON.parse(JSON.stringify(self.commisionusers));
              self.remainingComm = response.data.CUSTOMERS[0].REMAININGCOMM;
              self.remainingBusinessLineComm = response.data.CUSTOMERS[0].REMAININGBUSINESSLINECOMM;
              self.commAvail = response.data.CUSTOMERS[0].REMAININGCOMM;
              self.loading = false;
              self.$emit("initialLoad", 7);
            });
            $(".IZ-select__input input").val("");
            notifier.success(response.data.MESSAGE);
          } else {
            notifier.alert(response.data.MESSAGE);
          }
        } catch (e) {
          //handle error
        }
      });
      this.commissionAdding = false;
    } else {
      if (errorTab) {
        var tabErr = ["customerDetail", "commission"];
        // this.toggleActive(tabErr[errorTab - 1]);
      }
    }
  }
  async updateCommission(userItem) {
    var canAdd = 1;
    if (
      isNaN(userItem.PERCENTAGE) ||
      userItem.PERCENTAGE == "" ||
      userItem.PERCENTAGE <= 0
    ) {
      notifier.alert("Please enter valid PERCENTAGE");
      canAdd = 0;
    } else {
      var remainComm = 0;
      this.remainingBusinessLineComm.forEach((bLine:any) => {
        if (bLine.ID == userItem.BUSINESSLINEID) remainComm = bLine.REMAININGCOMM;
      });
      for (var index = 0; index < this.orgCommisionusers.length; index++) {
        if (this.orgCommisionusers[index].USERID == userItem.USERID) {
          if (userItem.PERCENTAGE > (this.orgCommisionusers[index].PERCENTAGE + remainComm)) {
            notifier.alert("Cannot save as total commission exceeds 100%");
            canAdd = 0;
          }
          break;
        }
      }
    }
    if (canAdd) {
      var dataObj = {
        controller: "Users",
        FunctionName: "AccountCommission",
        userID: userItem.USERID,
        aID: this.aid,
        percentage: userItem.PERCENTAGE,
        linkPaymentId: userItem.LINKPAYMENTID,
        type: userItem.TYPE,
        businessLineId: userItem.BUSINESSLINEID
      };
      var self = this;
      self.loading = true;
      var result = getRouteData(dataObj);
      await result.then(function(response, statusText) {
        // var msg = response.data.MESSAGE;
        try {
          if (response.data.STATUS) {
            notifier.success(response.data.MESSAGE);
            var dataObj = {};
            dataObj["controller"] = "Customers";
            dataObj["FunctionName"] = "View";
            dataObj["aID"] = self.accid;
            dataObj["userID"] = self.aID;
            // dataObj["maxRows"] =
            //   sessionStorage.getItem("maxRows") != ""
            //     ? sessionStorage.getItem("maxRows")
            //     : getMaxRows();
            // self.loading = true;
            var customResult = getRouteData(dataObj);
            customResult.then(function(response, statusText) {
              self.commisionusers = response.data.CUSTOMERS[0].COMMISIONUSERS;
              self.orgCommisionusers = JSON.parse(JSON.stringify(self.commisionusers));
              self.remainingComm = response.data.CUSTOMERS[0].REMAININGCOMM;
              self.commAvail = response.data.CUSTOMERS[0].REMAININGCOMM;
              self.remainingBusinessLineComm = response.data.CUSTOMERS[0].REMAININGBUSINESSLINECOMM;
              self.loading = false;
              // notifier.success(msg);
            });
            $(".IZ-select__input input").val("");
          } else {
            notifier.alert(response.data.MESSAGE);
            self.loading = false;
          }
        } catch (e) {
          //handle error
          self.loading = false;
        }
      });
    } else {
      this.$emit("initialLoad", 7);
    }
  }

  updatebusinessID(id) {
    this.$parent.businessLineId = id;
    this.businessLineId = id;
    this.updateCustomer();

    var bLineComm = this.userAvailable ? this.oldBlineComm : this.remainingBusinessLineComm;

    bLineComm.every((bLine: any) => {
      if (bLine.ID == id) {
        this.remainingComm = bLine.REMAININGCOMM;
        return false;
      }
      return true;
    });
  }

  checkBusinessComission() {
    var id = this.businessLineId;
    var result = true;
    var bLineComm = this.userAvailable ? this.oldBlineComm : this.remainingBusinessLineComm;

    bLineComm.every((bLine:any) => {
      if (bLine.ID == id) {
        if (this.remainingComm > bLine.REMAININGCOMM) {
          $(".errorMsg.commPercent").text(
            `The Commission Percentage field must be less than ${bLine.REMAININGCOMM} for ${bLine.BUSINESSLINENAME}`
          );
          result = false;
          return false;
        }
      }
      return true;
    });
    return result;
  }

  async autoBusinessLineSwitch() {
    this.bLineLoading = true;

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller         : "Users",
      FunctionName       : "AccountCommission",
      action             : "businessLine_update",
      aID                : this.aid,
      businessLineSwitch : !this.businessLineSwitch
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    
    if(response.data.STATUS == 1) {
      this.$emit("initialLoad", 7);
      this.confirmBLineToggle = false;
      this.bLineLoading = false;
    }
  }
}
