














































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import {notifier, downloadFile, printHtml, downloadFileUrl} from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import SelectTagControl from "@/components/SelectTagControl.vue";
import PageTitle from '../components/pageTitle.vue';


@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    LaddaButton,
    PageTitle,
    SelectTagControl
  }
})
export default class CustomerPortals extends TSXComponent<void> {
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  saving: any = false;
  pageNumber = 1;
  details: any = {
  };
  tagName = "";
  isActive = 1;
  statuses = [
    {
      ID: 1,
      TEXT: 'Active'
    },
    {
      ID: 0,
      TEXT: 'Archived'
    }
  ]

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      if (this.$route.params.id) {
        var reqData = {
          controller      : "Customers",
          FunctionName    : "GlobalTagView",
          GlobalTagID    : this.$route.params.id
        }
        const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);
  
        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
  
        if(response.data.STATUS == 1) {
          this.details = response.data;
          this.details.currentTagName = response.data.TAGINFO.CUSTOMFIELDOPTIONNAME;
          this.tagName = response.data.TAGINFO.CUSTOMFIELDOPTIONNAME;
          this.isActive = response.data.TAGINFO.ISACTIVE;
        }
      }
    }catch (err) {
      // console.log(err.message);
    }finally {
      this.loading = false;
    }
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  listPageRedirection(){
    this.$router.push({ name: "GlobalTags" });
  }

  async saveTag() {
    //remove html
    this.tagName = this.removeHTML(this.tagName);

    this.$validator.validateAll().then(async result => {
      if(result) {
        this.$validator.errors.clear();
        this.saving = true;
        try {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller      : "Customers",
            FunctionName    : "UpdateGlobalTag",
            GlobalTagID    : this.$route.params.id ? this.$route.params.id : 0,
            tagName         : this.tagName,
            isActive        : this.isActive
          });

          if (response.data.ERROR) {
            this.saving = "error";
            throw new Error(response.data.ERROR);
          }
          
          if(response.data.STATUS == 1) {
            this.saving = false;
            this.$router.push({ name: "GlobalTags" });
            notifier.success(response.data.STATUSMESSAGE);
          }else {
            this.saving = 'error';
            if(typeof response.data.tagNameExisted != "undefined" && response.data.tagNameExisted) {
              this.$validator.errors.add({
                field: "tagName",
                msg: response.data.STATUSMESSAGE
              });
            }
          }
        } catch (err) {
          this.saving = "error";
        }
      }
    });
  }

  chooseStatus(status, rowIdx) {
    this.isActive = status;
  }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }
}
