



















































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier, wait } from "../models/common";
import xmlformatter from "xml-formatter";

interface Props {
  purchaseID: number;
  details?: any;
  aID?: number;
}

interface Events {}

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class TechdataStatusModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: 0 })
  purchaseID?: number;

  @Prop({ required: false, default: 0 })
  aID?: number;

  @Prop({ required: false, default: {} })
  details?: any;
 
  loading = false;
  orderLoading: boolean | 'error' = false; 
  invoiceLoading: boolean | 'error' = false; 
  ShipmentLoading: boolean | 'error' = false;
  orderResponse = "";
  orderStatusTitle = "";

  $refs!: {
    modal: HTMLDivElement;
  };

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  async checkStatus(statusCode) {  
    try {
      if (statusCode == 1) {
        this.orderLoading = true;
        this.orderStatusTitle = "Order Status";
      } else if (statusCode == 2) {
          this.invoiceLoading = true;
          this.orderStatusTitle = "Invoice Detail";
      } else {
          this.ShipmentLoading = true;
          this.orderStatusTitle = "Shipment Detail";
      }
      const req = {
        controller: "Helpers",
        FunctionName: "ORDERSTATUS_TECHDATA",
        distyOrderNumber: this.details.DistyOrderNumber ? this.details.DistyOrderNumber : '',
        CustomerOrderNumber: this.details.ORDERDETAILS.CUSTOMERPO,
        InvoiceNumber: this.details.InvoiceNumber ? this.details.InvoiceNumber : '',
        techdataStatusCode : statusCode
      }; 
      const response = await axios.post(dataURL + "?ReturnType=JSON", req);
      this.orderResponse = typeof response.data.DebugRepsonse != "undefined" ? response.data.DebugRepsonse : "";
      // this.orderResponse = response.data;
    } catch (err) {
      if (statusCode == 1) {
        this.orderLoading = 'error';
      } else if (statusCode == 2) {
          this.invoiceLoading = 'error';
      } else {
          this.ShipmentLoading = 'error';
      }
    } finally {
      if(statusCode == 1) {
        this.orderLoading = false;
      } else if (statusCode == 2) {
          this.invoiceLoading = false;
      } else {
          this.ShipmentLoading = false;
      }
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  close() {
    this.$emit('close')
  }  
}
