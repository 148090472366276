

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import DeploymentsAssetsIdentifiersAddModal from "../components/DeploymentsAssetsIdentifiersAddModal.vue";
import AssetsAllocationTooltip from "../components/AssetsAllocationTooltip.vue";
import UsersAllocationTooltip from "../components/UsersAllocationTooltip.vue";
import { notifier, downloadFileUrl, downloadFile } from "../models/common";
import {
  colsResizeable,
  colsResizeableReset,
  colsResizeableUpdate,
  loginCheck
} from "@/helpers/ApiHelper";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import DeploymentEUModal from "../components/DeploymentEUModal.vue";
import LogInfoModal from "../components/LogInfoModal.vue";
import DeploymentUserCompletionTooltip from "../components/DeploymentUserCompletionTooltip.vue";
import moment from "moment";
import { any } from "prop-types";
import PageTitle from "../components/pageTitle.vue";
import DeploymentDashboard from "@/components/Deployment/DeploymentDashboard.vue";
import ApiExternal from "../components/ApiExternal.vue";
import DeploymentSchedule from "@/components/Deployment/DeploymentSchedule.vue";
import ModalAddScheduleGroup from "@/components/Deployment/ModalAddScheduleGroup.vue";
import ModalAddDeploymentStatus from "@/components/Deployment/ModalAddDeploymentStatus.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { ApiHelper } from "@/helpers/all";
import { getTrackingLink } from "@/helpers/ApiHelper";
import ModalEditDeploymentDataField from "@/components/Deployment/ModalEditDeploymentDataField.vue";
import ModalGenerateAssetTags from "@/components/Deployment/ModalGenerateAssetTags.vue";
import AddToScheduleGroupTooltip from "@/components/Deployment/AddToScheduleGroupTooltip.vue";
import PickWindowTooltip from "@/components/Deployment/PickWindowTooltip.vue";
import SchedulePortalSettingsTooltip from "@/components/Deployment/SchedulePortalSettingsTooltip.vue";
import SetAvailabilityModal from "@/components/Deployment/SetAvailabilityModal.vue";
import DeploymentAssets from "@/components/Deployment/DeploymentAssets.vue";
import DeploymentServices from "@/components/Deployment/DeploymentServices.vue";
import { getEUAddress } from "@/helpers/ApiHelper";
import FilterSearch from "@/components/List/FilterSearch.vue";
import FilterCheckboxes from "@/components/List/FilterCheckboxes.vue";
import ModalLockSchedule from "@/components/Deployment/ModalLockSchedule.vue";
import { getDateRangeFilter } from "@/helpers/ApiHelper";
import DropdownControl from "../components/DropdownControl.vue";
import DeploymentOrderInfoTooltip from "@/components/Deployment/DeploymentOrderInfoTooltip.vue";

declare function require(params: any): any;
const template = require("../templates/deployments/deploymentScheduleDetails.handlebars");

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    DeploymentOrderInfoTooltip,
    ModalLockSchedule,
    FilterCheckboxes,
    FilterSearch,
    DeploymentServices,
    DeploymentAssets,
    ModalAddDeploymentStatus,
    ModalAddScheduleGroup,
    DeploymentSchedule,
    DeploymentDashboard,
    DeploymentsAssetsIdentifiersAddModal,
    AssetsAllocationTooltip,
    UsersAllocationTooltip,
    ConfirmRemoveItemModal,
    DeploymentEUModal,
    DeploymentUserCompletionTooltip,
    LogInfoModal,
    PageTitle,
    ApiExternal,
    ModalEditDeploymentDataField,
    ModalGenerateAssetTags,
    AddToScheduleGroupTooltip,
    PickWindowTooltip,
    SetAvailabilityModal,
    SchedulePortalSettingsTooltip,
    ListHeaderActions,
    DropdownControl
  },
  methods: {
    loginCheck,
    getTrackingLink,
    getEUAddress,
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class DeploymentDetails extends TSXComponent<void> {
  changingSelectedHeaders = false;
  @Watch("tabEuSelectedHeaders")
  colsChange(val: any) {
    this.changingSelectedHeaders = true;
    setTimeout(() => {
      this.changingSelectedHeaders = false;
      colsResizeableUpdate({
        ms: 500,
        selector: ".page-list .page-list-container > table.tbl-" + this.isActive
      });
    }, 1);
  }

  changingScheduleSelectedHeaders = false;
  @Watch("tabScheduleSelectedHeaders")
  colsScheduleChange(val: any) {
    this.changingScheduleSelectedHeaders = true;
    setTimeout(() => {
      this.changingScheduleSelectedHeaders = false;
      colsResizeableUpdate({
        ms: 500,
        selector: ".page-list .page-list-container > table.tbl-schedule"
      });
    }, 1);
  }

  $refs!: {
    DeploymentDashboard: DeploymentDashboard;
  };
  logModalVisible = -1;
  isActive = "dashboard";
  currentRole = sessionStorage.getItem("userRole");
  loading: string | boolean = false;
  deleteLoading: string | boolean = false;
  details: any = {};
  orderDetails: any[] = [];
  deploymentDetails: any = {};
  allLinkedHardwares: any[] = [];
  deploymentId = "";
  endUserId = 0;
  endUserName = "";
  confirmEndUserRemove = false;
  userName = "";
  sort = {
    field: null,
    direction: {
      euName: 1
    }
  };
  directionField = "";
  identifiersAddModalVisible = false;
  callIdentifiersFrom = "";
  assetId = 0;
  assetSKU = 0;
  assetName = 0;
  allocationTooltipVisibleIndex = -1;
  allocationTooltipLoading = false;
  allocationTooltipList = [];
  type: string = "";
  modalVisible = false;
  selectedUser: any = null;
  completionTooltipVisibleIndex = -1;
  completionTooltipLoading = false;
  totalDepPercent = 0;
  fullCustomData: any = [];
  customDataLoading = false;
  customDataIndex = -1;
  customOrderDataIndex = -1;
  customDataVisible = false;
  tieOrders: any = [];
  orderCustomData: any = [];
  firstPurchaseId = 0;
  apiExternalModalVisible = false;
  apiLoading = false;
  productDetails = {};
  showModalEditDeploymentDataField = false;
  showModalGenerateAssetTags = false;
  scheduleTooltipVisibleIndex = -1;
  pickWindowTooltipVisibleIndex = "";
  showTooltipOrdersVisibleIndex = "";
  availabilityModalVisible = false;
  setupNewScheduleInfo: any = {
    selectedUser: {},
    index: -1,
    scheduleGroupId: 0
  };
  accountId = 0;
  searchHeader = "";
  tabEuHeaderOptions = {};
  tabEuHeaders = {
    name: "Name",
    emailPhone: "Email/Phone Number",
    assets: "Assets",
    deployment: "Deployment",
    actions: "Actions",
    completion: "Completion",
    order: "Order"
  };
  tabEuHeaderOrder: any = [
    "name",
    "emailPhone",
    "assets",
    "deployment",
    "actions",
    "completion",
    "order"
  ];
  tabEuSelectedHeaders: any = [];
  tabEuFilters: any = {
    name: "",
    emailPhone: "",
    assets: "",
    deployment: "",
    actions: "",
    completion: "",
    order: "",
    deploymentDate: {
      sDate: "",
      eDate: ""
    }
  };

  tabEUSearch: any = {
    deploymentDate: {
      sDate: "",
      eDate: ""
    }
  };

  tabScheduleFilter = {
    sDate: "",
    eDate: ""
  };

  searchScheduleHeader = "";
  tabScheduleHeaders = {
    scheduleGroup: "Schedule Group",
    siteLocation: "Site Location",
    targetDate: "Target Date",
    notes: "Notes",
    actions: "Actions",
    completion: "Completion"
  };

  tabScheduleHeaderOrder: string[] = [
    "scheduleGroup",
    "siteLocation",
    "targetDate",
    "notes",
    "actions",
    "completion"
  ];

  tabScheduleSelectedHeaders: string[] = [];

  scheduleSettingsTooltipVisible = false;
  deploymentParams: any = {};
  schedulePortalSettings: any = {
    isShowAll: true,
    lockUnscheduledUsers: false
  };
  allDeploymentEndUsers: any = [];
  availabilitiesList: any = [];
  modalDeleteScheduleTime = {
    show: false,
    data: {},
    callback: () => {
      console.log("nothing");
    }
  };
  modalDeleteScheduleGroup = {
    show: false,
    data: {},
    callback: () => {
      console.log("nothing");
    }
  };
  euPaging = {
    loadingMore: false,
    pageNumber: 1,
    itemsPerPage: 10
  };
  portalDomain: any = {};

  tabSortDirection = {
    schedule: 0,
    endUser: 0
  };
  eusCheckedAll = false;
  selectedEndUserIds: number[] = [];
  confirmMoveEndUser = false;
  moveToDeploymentId = [];
  deploymentsList = [];

  async created() {
    this.deploymentId = this.$route.params.id;
    const currentUser = JSON.parse(
      sessionStorage.getItem("currentUser") || "{}"
    );
    this.accountId = currentUser[0]["ACCOUNTID"] || 0;

    await this.fetchData();

    const activeTab = this.$route.params.activeTab || "";
    if (activeTab != "") {
      this.toggleActive(activeTab);
    }

    // // get custom data of first purchase
    // if (this.tieOrders.length) {
    //   this.tieOrders.sort((a, b) => a.LINKID - b.LINKID); // sort asc
    //   const firstPurchaseId = this.tieOrders[0].PURCHASEID;
    //   if (firstPurchaseId) {
    //     this.firstPurchaseId = firstPurchaseId;
    //     try {
    //       const response = await axios.post(dataURL + "?ReturnType=JSON", {
    //         controller: "Helpers",
    //         FunctionName: "CustomData",
    //         DataType: "1,2",
    //         Id: firstPurchaseId,
    //         DropdownField: 25,
    //         contractDropdown: 26,
    //         orderCustomData: true
    //       });

    //       if (response.data.STATUS == 1) {
    //         this.orderCustomData = [];
    //         for (var val of response.data.CUSTOMDEFINITION) {
    //           let tmpValue = response.data.CUSTOMDATA.filter(
    //             tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID
    //           );
    //           this.orderCustomData.push({
    //             CUSTOMFIELDID: val.CUSTOMFIELDID,
    //             CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
    //             CUSTOMDTYPE: val.CUSTOMDTYPE,
    //             CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
    //             CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0
    //           });
    //           // if (val.CUSTOMFIELDID == 25)
    //           //   this.selectedValue = tmpValue.length
    //           //     ? tmpValue[0].CUSTOMVALUE
    //           //     : "Please make a selection";
    //           // if (
    //           //   val.CUSTOMDTYPE == 3 &&
    //           //   val.CUSTOMFIELDNAME == "Custom Data on Export"
    //           // )
    //           //   this.selectedContractValue = tmpValue.length
    //           //     ? tmpValue[0].CUSTOMVALUE
    //           //     : "Please make a selection";
    //           // this.contractFieldId = val.CUSTOMFIELDID;
    //         }

    //         // this.customDropdown = response.data.customnDropdownField;
    //         // this.selectedCustomId = response.data.selectedDropdownField;
    //         // this.orderContractNumber = response.data.customContractNumber;
    //         // this.selectedContractId = response.data.selectedContractNo;
    //         // this.selectedContracts = response.data.selectedContractData;
    //         // if (this.selectedContracts.length) {
    //         //   this.selectedOrderContracts = this.selectedContracts[0].CUSTOMFIELDOPTIONNAME;
    //         //   this.orderContractsID = this.selectedContracts.map(
    //         //     (val: any) => val.CUSTOMFIELDOPTIONID
    //         //   );
    //         // }

    //         // this.financialSource = response.data.financialSource;
    //         // this.selectedFundingSourceId = response.data.FUNDINGSOURCEID;
    //         // this.selectedFinSourceValue = response.data.FUNDINGSOURCENAME;
    //       }
    //     } catch (err) {
    //       console.log(err);
    //     } finally {
    //       // this.customDataLoading = false;
    //     }
    //   }
    // }
  }

  // listCustomFields: any = [];
  mounted() {
    window.addEventListener("resize", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  }

  handleScroll() {
    if (this.$route.name != "ViewDeployment") {
      return;
    }

    this.$nextTick().then(() => {
      // detect pageNumber for end users list
      if (this.isActive == "endUsers" && this.details.EUDETAILS.length) {
        const trHeight = 80;
        const detailsHeight = $("#details-content").outerHeight();

        // auto detect a pageNumber to fit with current height of #details-content
        if (this.euPaging.pageNumber == 1 && detailsHeight) {
          const showingCnt = Math.ceil(detailsHeight / trHeight);
          this.euPaging.pageNumber = Math.ceil(
            showingCnt / this.euPaging.itemsPerPage
          );
        }

        let documentHeight = document.body.scrollHeight;
        let currentScroll = window.scrollY + window.innerHeight;
        let showingCnt = $(".tbl-endUsers tbody tr:visible").length;
        if (
          showingCnt <
          this.euPaging.pageNumber * this.euPaging.itemsPerPage
        ) {
          // if not load yet, or loaded full items
          return;
        }

        if (currentScroll + 300 > documentHeight) {
          if (
            this.euPaging.pageNumber * this.euPaging.itemsPerPage <
            this.details.EUDETAILS.length
          ) {
            this.euPaging.pageNumber += 1;
          }
        }
      }
    });
  }

  async fetchData(ignoreLoading = false) {
    if (!ignoreLoading) {
      this.loading = true;
    }
    try {
      this.bkEuFilters = this.euFilters;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "view",
        deploymentId: this.deploymentId,
        order: this.sort.field ? this.sort.field : 0,
        euFilters: this.euFilters,
        direction:
          this.directionField != ""
            ? this.sort.direction[this.directionField]
            : 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      if (response.data.STATUS == 1) {
        const responseData = response.data || {};

        this.portalDomain = responseData.portalDomain || {};
        this.allLinkedHardwares = responseData.allLinkedHardwares || [];
        this.allDeploymentEndUsers = responseData.allDeploymentEndUsers || [];
        this.availabilitiesList = responseData.availabilitiesList || [];
        responseData.EUDETAILS.map(item => {
          if ((item.EUCD || []).length) {
            item.EUCD.map(cdItem => {
              if (cdItem.isEUData) {
                item["customData" + cdItem.customFieldId] = cdItem.customValue;
              }
            });
          }
          return item;
        });
        this.details = responseData;
        this.details.ORIGINAL_SCHEDULEGROUPS = this.details.SCHEDULEGROUPS;
        this.details.ORIGINAL_EUDETAILS = this.details.EUDETAILS;
        this.deploymentParams =
          this.details.DEPLOYMENTDETAILS[0].DEPLOYMENTPARAMS || {};
        const schedulePortalSettings = JSON.parse(
          this.deploymentParams.schedulePortalSettings || "{}"
        );
        if (Object.keys(schedulePortalSettings).length) {
          // if saved before
          this.schedulePortalSettings = schedulePortalSettings;
        }

        // get Order Options for Add Status modal
        const orderDetails = this.details.ORDERDETAILS || [];
        this.orderDetails = orderDetails.map(item => {
          return {
            id: item.PURCHASEID,
            name: item.PURCHASEID
          };
        });
        this.userName =
          typeof this.details.USERNAME != "undefined"
            ? this.details.USERNAME
            : "";
        this.deploymentDetails = this.details.DEPLOYMENTDETAILS.length
          ? this.details.DEPLOYMENTDETAILS[0]
          : {};
        this.totalDepPercent = this.details.TOTALCOMPLETE;
        // this.tieOrders = this.details.ORDERDETAILS || [];

        this.tabEuHeaderOptions = {};
        const euFieldOptions = this.details.euFieldOptions || [];
        // append end user custom fields if any
        for (const item of this.details.euFields || []) {
          const field = `customData${item.CUSTOMFIELDID}`;
          if ((this.tabEuHeaders[field] || "") == "") {
            this.tabEuHeaders[field] = item.CUSTOMFIELDNAME;
          }
          if (!this.tabEuHeaderOrder.includes(field)) {
            this.tabEuHeaderOrder.push(field);
          }
          if (item.CUSTOMDTYPE == 3) {
            const findOptions = euFieldOptions.filter(
              (option: any) => option.FIELDID == item.CUSTOMFIELDID
            );
            this.tabEuHeaderOptions[field] = findOptions.map(option => {
              return {
                value: option.FIELDOPTIONNAME || "",
                text: option.FIELDOPTIONNAME || ""
              };
            });
          }
        }

        // selected end user fields
        const selectedEUHeaders = this.details.selectedEUHeaders || "";
        if (selectedEUHeaders) {
          for (const item of selectedEUHeaders.split(",")) {
            if (!this.tabEuSelectedHeaders.includes(item)) {
              this.tabEuSelectedHeaders.push(item);
            }
          }
        } else {
          // init tabEuSelectedHeaders
          this.tabEuSelectedHeaders = [
            "name",
            "emailPhone",
            "assets",
            "deployment",
            "actions",
            "completion"
          ];
        }
        this.tabEuSelectedHeaders.map((header: any) => {
          this.euFilters[header] = this.bkEuFilters[header]
            ? this.bkEuFilters[header]
            : "";
        });

        // selected schedule view fields
        const selectedScheduleViewHeaders = this.details.selectedScheduleHeaders || "";
        if (selectedScheduleViewHeaders) {
          for (const item of selectedScheduleViewHeaders.split(",")) {
            if (!this.tabScheduleSelectedHeaders.includes(item)) {
              this.tabScheduleSelectedHeaders.push(item);
            }
          }
        } else {
          // init tabScheduleSelectedHeaders
          this.tabScheduleSelectedHeaders = this.tabScheduleHeaderOrder;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async deleteEndUser() {
    try {
      this.deleteLoading = false;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "EndUserDetails",
        deploymentId: this.deploymentId,
        endUserId: this.endUserId,
        action: "delete",
        endUserIds: this.endUserId ? [this.endUserId] : this.selectedEndUserIds
      });
      if (response.data.STATUS == 1) {
        // update selectedEndUserIds after deleting
        if (this.endUserId) {
          this.selectedEndUserIds = this.selectedEndUserIds.filter(
            id => id != this.endUserId
          );
        } else {
          this.selectedEndUserIds = [];
        }

        ApiHelper.updateDeploymentPercent(this.deploymentId).then(ret => {
          this.updateDeploymentPercent(ret);
        });
        notifier.success(response.data.STATUSMESSAGE);
        await this.fetchData();
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.deleteLoading = false;
      this.confirmEndUserRemove = false;
    }
  }

  // ASSETS tab
  removeSelected(deploymentId, item) {
    this.deploymentId = deploymentId;
    this.endUserId = item.USERID;
    this.endUserName = item.EUNAME;
    this.confirmEndUserRemove = true;
  }

  modalRemoveAsset = {
    show: false,
    loading: false,
    deploymentId: 0,
    selectedData: {},
    productName: ""
  };

  async deleteAsset() {
    try {
      this.modalRemoveAsset.loading = false;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "removeProducts",
        deploymentId: this.deploymentId,
        selectedItems: [this.modalRemoveAsset.selectedData]
      });
      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
        await this.fetchData();
      } else {
        notifier.alert(response.data.ERROR || "Something was wrong");
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.modalRemoveAsset.loading = false;
      this.modalRemoveAsset.show = false;
    }
  }

  removeAssetSelected(deploymentId, item) {
    this.modalRemoveAsset.deploymentId = item.DEPLOYMENTID;
    this.modalRemoveAsset.selectedData = item;
    this.modalRemoveAsset.productName = item.PRODUCTNAME;
    this.modalRemoveAsset.show = true;
  }
  // -------

  toggleActive(div) {
    if (div == "endUsers") {
      this.handleScroll();
    }

    colsResizeableReset({
      ms: 0,
      selector: ".page-list .page-list-container > table.tbl-" + this.isActive
    });
    this.isActive = div;
    this.allocationTooltipVisibleIndex = -1;
    this.customDataVisible = false;
    this.setupNewScheduleInfo = {
      selectedUser: {},
      index: -1,
      scheduleGroupId: 0
    };
    switch (div) {
      case "endUsers":
      case "ids":
      case "status":
      case "progress":
        colsResizeable({
          ms: 500,
          selector: ".page-list .page-list-container > table.tbl-" + div
        });
        break;
    }
  }

  listPageRedirection() {
    this.$router.push({ name: "Deployments" });
  }

  addProducts(type) {
    this.$router.push({
      name: "AssetServiceAdd",
      params: { type: type, id: this.deploymentId }
    });
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async showIdentifiers(assetDetails) {
    this.assetId = assetDetails.PRODUCTID;
    this.assetSKU = assetDetails.SKU;
    this.assetName = assetDetails.PRODUCTNAME;
    this.productDetails = assetDetails;
    this.endUserId = assetDetails.endUserId || 0;
    this.callIdentifiersFrom = assetDetails.callFrom || "";
    this.identifiersAddModalVisible = true;
  }

  showIdentifiersByUser(options) {
    this.showIdentifiers(options);
    // close end user modal
    this.modalVisible = false;
  }

  async closeAssetsIdentifiersModal(callFrom = "") {
    this.identifiersAddModalVisible = false;
    if (callFrom == "EUModal") {
      // re-open eu modal
      this.modalVisible = true;
    }
    await this.fetchData();
  }

  async showAllocationTooltip(type = "", item, index, e) {
    if (this.allocationTooltipVisibleIndex === index) {
      return;
    }

    if (type == "users") {
      // get a list of users are assgned for this product
      this.allocationTooltipVisibleIndex = index;
      this.isTopTooltip("users", $(e.target));
      try {
        this.allocationTooltipLoading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          Controller: "Deployments",
          FunctionName: "GetAssetsAllocated",
          deploymentId: this.deploymentId,
          productID: item.PRODUCTID,
          type: "related_users"
        });
        if (response.data.STATUS == 1) {
          this.allocationTooltipList = response.data.allocated || [];
          this.allocationTooltipList.forEach(function(val: any) {
            val.oldAllocated = val.ALLOCATED;
          });
        }

        this.allocationTooltipLoading = false;
      } catch (err) {
        notifier.alert(err.message);
        this.allocationTooltipVisibleIndex = -1;
      } finally {
        this.allocationTooltipLoading = false;
      }
    } else if (type == "products") {
      // get a list of products are assigned for this user
      this.allocationTooltipVisibleIndex = index;
      try {
        this.allocationTooltipLoading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          Controller: "Deployments",
          FunctionName: "GetAssetsAllocated",
          deploymentId: this.deploymentId,
          userId: item.USERID,
          type: "related_products"
        });
        if (response.data.STATUS == 1) {
          this.allocationTooltipList = response.data.allocated || [];
          this.allocationTooltipList.forEach(function(val: any) {
            val.oldAllocated = val.ALLOCATED;
          });
        }

        this.allocationTooltipLoading = false;
      } catch (err) {
        notifier.alert(err.message);
        this.allocationTooltipVisibleIndex = -1;
      } finally {
        this.allocationTooltipLoading = false;
      }
    }
  }

  updateAllocated(info) {
    if (this.isActive == "endUsers") {
      this.details.EUDETAILS[info.currentIndex].ALLOCATED = info.allocated;
    } else {
      if (this.isActive == "assets") {
        this.details.HARDWAREDETAILS[info.currentIndex].ALLOCATED =
          info.allocated;
      } else {
        this.details.SERVICESDETAILS[info.currentIndex].ALLOCATED =
          info.allocated;
      }
    }
  }

  isTopTooltip(type = "", tooltip: any = null) {
    if (type == "users") {
      // user allocation tooltip
      if (!tooltip.length) return false;
      $(".assets-allocation-tooltip").removeClass("topTooltip");
      // const tooltipHeight = tooltip.offset().top + $(window).scrollTop();
      const tooltipHeight = tooltip.offset().top - $(window).scrollTop();
      const container = $(window).height();
      if (tooltipHeight + 270 >= container) {
        tooltip.addClass("topTooltip");
        return true;
      }
    } else if (type == "completion") {
      // completion tooltip
      if (!tooltip.length) return false;
      $(".completion-tooltip").removeClass("topTooltip");
      // const tooltipHeight = tooltip.offset().top + $(window).scrollTop();
      const tooltipHeight = tooltip.offset().top - $(window).scrollTop();
      const container = $(window).height();

      if (tooltipHeight + 310 >= container) {
        tooltip.addClass("topTooltip");
        return true;
      }
    } else {
      if (!tooltip.length) return false;
      tooltip.removeClass("topTooltip");

      // const tooltipHeight = tooltip.offset().top + $(window).scrollTop();
      const tooltipHeight = tooltip.offset().top - $(window).scrollTop();
      const container = $(window).height();
      if (
        tooltipHeight + 360 >= container &&
        tooltip.offset().top > 520 /* fix overlap tooltip header */
      ) {
        tooltip.addClass("topTooltip");
        return true;
      }
    }

    return false;
  }

  getTotalAllocated(items) {
    let total = 0;
    for (const item of items) {
      total += item.ALLOCATED || 0;
    }
    total = parseFloat(total.toFixed(2));

    return total;
  }

  Modal(userInfo, type) {
    if (userInfo) {
      this.selectedUser = {
        ...userInfo,
        SITELOCATION: userInfo.SITELOCATION || "",
        LOCATIONNAME: userInfo.LOCATIONNAME || ""
      };
      if (!userInfo.DEPLOYMENTDATE.length) {
        this.selectedUser["DATE"] = "";
        this.selectedUser["HOUR"] = "";
        this.selectedUser["MINUTES"] = "";
      }
    } else {
      this.selectedUser = {
        SITELOCATION: "",
        LOCATIONNAME: "",
        ADDR1: "",
        ADDR2: "",
        ALLOCATED: "",
        ASSETS: [],
        CITY: "",
        COMPLETION: "",
        DEPLOYMENTID: this.deploymentId,
        EMAIL: "",
        EUADDRESS: "",
        EUNAME: "",
        FIRSTNAME: "",
        LASTNAME: "",
        LATESTLOGTS: "",
        LOGS: "",
        PHONE: "",
        PHONEEXT: "",
        SERVICES: [],
        STATE: "",
        TOTALLOGS: "",
        USERID: 0,
        ZIP: "",
        DATE: "",
        HOUR: "",
        MINUTES: ""
      };
      this.selectedUser = Object.create(this.selectedUser);
    }

    this.type = type;
    this.modalVisible = true;
  }

  async reloadAllocation() {
    await this.fetchData(true);
    this.$forceUpdate();
  }

  sortSchedule() {
    const sortDirection = this.tabSortDirection.schedule === 0 ? 1 : 0; // 0: asc, 1: desc
    const compare = (a, b) => {
      if (a.TARGETSTARTDATETIMEINT < b.TARGETSTARTDATETIMEINT) {
        return sortDirection > 0 ? 1 : -1;
      }

      if (a.TARGETSTARTDATETIMEINT > b.TARGETSTARTDATETIMEINT) {
        return sortDirection > 0 ? -1 : 1;
      }

      return 0;
    };

    this.details.SCHEDULEGROUPS = this.details.SCHEDULEGROUPS.sort(compare);
    this.tabSortDirection.schedule = sortDirection;
  }

  filterSchedule() {
    const MIN_DATE_INT = "00000000000000";
    const MAX_DATE_INT = "99991231235959";

    let startDateInt = this.tabScheduleFilter.sDate.replaceAll("-", "");
    startDateInt = startDateInt ? startDateInt + "000000" : MIN_DATE_INT;

    let endDateInt = this.tabScheduleFilter.eDate.replaceAll("-", "");
    endDateInt = endDateInt ? endDateInt + "235959" : MAX_DATE_INT;

    this.details.SCHEDULEGROUPS = this.details.ORIGINAL_SCHEDULEGROUPS.filter(
      sg =>
        sg.TARGETSTARTDATETIMEINT >= startDateInt &&
        sg.TARGETSTARTDATETIMEINT <= endDateInt
    );
  }

  sortEU() {
    const sortDirection = this.tabSortDirection.endUser === 0 ? 1 : 0; // 0: asc, 1: desc
    const compare = (a, b) => {
      if (a.TARGETSTARTDATETIMEINT < b.TARGETSTARTDATETIMEINT) {
        return sortDirection > 0 ? 1 : -1;
      }

      if (a.TARGETSTARTDATETIMEINT > b.TARGETSTARTDATETIMEINT) {
        return sortDirection > 0 ? -1 : 1;
      }

      return 0;
    };

    this.details.EUDETAILS = this.details.EUDETAILS.sort(compare);
    this.tabSortDirection.endUser = sortDirection;
  }

  searchEUByDeploymentDate() {
    const MIN_DATE_INT = "00000000000000";
    const MAX_DATE_INT = "99991231235959";

    let startDateInt = this.tabEuFilters.deploymentDate.sDate.replaceAll(
      "-",
      ""
    );
    startDateInt = startDateInt ? startDateInt + "000000" : MIN_DATE_INT;

    let endDateInt = this.tabEuFilters.deploymentDate.eDate.replaceAll("-", "");
    endDateInt = endDateInt ? endDateInt + "235959" : MAX_DATE_INT;

    this.details.EUDETAILS = this.details.ORIGINAL_EUDETAILS.filter(
      sg =>
        sg.TARGETSTARTDATETIMEINT >= startDateInt &&
        sg.TARGETSTARTDATETIMEINT <= endDateInt
    );
    this.tabEUSearch.deploymentDate = { ...this.tabEuFilters.deploymentDate };
  }

  resetSearchEUByDeploymentDate() {
    this.tabEuFilters.deploymentDate.sDate = "";
    this.tabEuFilters.deploymentDate.eDate = "";

    this.tabEUSearch.deploymentDate = { ...this.tabEuFilters.deploymentDate };
    this.searchEUByDeploymentDate();
  }

  getFilterDeploymentDateValue() {
    return getDateRangeFilter(
      this.tabEUSearch.deploymentDate.sDate,
      this.tabEUSearch.deploymentDate.eDate
    );
  }

  async callDashboard() {
    if (this.isActive == "dashboard") {
      await this.$refs.DeploymentDashboard.fetchData();
    }
  }

  showCompletionTooltip(item, index, e: any) {
    if (this.completionTooltipVisibleIndex === index) {
      return;
    }
    this.completionTooltipVisibleIndex = index;
    this.isTopTooltip("completion", $(e.target).closest(".completion-tooltip"));
  }

  async updateAllocation(requestObj) {
    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.STATUS == 1) {
      this.fetchData(true);
    }
    return response;
  }

  async saveCompletion(requestObj, index) {
    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.STATUS == 1) {
      // update current line
      const user = this.details.EUDETAILS[index];
      /* user.LATESTLOGTS = moment().utc(); */
      user.COMPLETION = response.data.COMPLETION;
      user.LATESTLOGTS = response.data.LOGTS;
      user.TOTALLOGS += 1;
      user.LOGS.push({
        COMPLETION: response.data.COMPLETION,
        CREATEDTS: response.data.LOGTS,
        ENDUSERID: user.USERID,
        LOGID: response.data.logId,
        NOTES: response.data.NOTES,
        PRODUCTID: ""
      });
    }
    return response;
  }

  getLatestLogText(createdTs) {
    const date = createdTs ? moment(createdTs) : moment();
    return date.utc().fromNow();
  }

  reloadEUInfo(userId, euInfo) {
    const user = this.details.EUDETAILS.find(item => item.USERID == userId);
    if (user) {
      user.EUNAME = [euInfo.lastName, euInfo.firstName].join(", ");
      user.FIRSTNAME = euInfo.firstName || "";
      user.LASTNAME = euInfo.lastName || "";
      user.SITELOCATION = euInfo.siteLocation || "";
      user.ADDR1 = euInfo.addr1 || "";
      user.ADDR2 = euInfo.addr2 || "";
      user.CITY = euInfo.city || "";
      user.STATE = euInfo.state || "";
      user.ZIP = euInfo.zip || "";
      user.EMAIL = euInfo.email || "";
      user.PHONE = euInfo.phone || "";
      user.PHONEEXT = euInfo.phoneExt || "";
      user.EUADDRESS = [user.ADDR1, user.ADDR2, user.CITY, user.STATE, user.ZIP]
        .filter(item => item != "")
        .join(", ");
      user.DEPLOYMENTDATE = euInfo.dateTimeFormatted || "";
      user.DATE = euInfo.deploymentDate || "";
      user.HOUR = euInfo.deploymentTime.HH || "";
      user.MINUTES = euInfo.deploymentTime.mm || "";
    }
  }

  updateActions(userId, info) {
    if (!userId) return;
    if (info.type == "remove") {
      const prevLogId = info.prevLogId || 0;
      const prevLogCreatedTs = info.prevLogCreatedTs || "";
      const completion = info.completion || 0;
      const user = this.details.EUDETAILS.find(item => item.USERID == userId);
      if (user) {
        user.TOTALLOGS -= 1;
        user.COMPLETION = 0;
        if (prevLogId) {
          user.LATESTLOGTS = prevLogCreatedTs;
          user.COMPLETION = completion;
        }
        this.fetchData(true);
      }
    }
  }

  updateDeploymentPercent(percent) {
    this.totalDepPercent = percent || 0;
  }

  getInvoicePaidNote(item) {
    var ret = "";
    try {
      if (item.STATUS_DETAILS == "") {
        return "";
      }

      let tmp = JSON.parse(item.STATUS_DETAILS);
      if (typeof tmp.PAYMENTAMOUNT != "undefined") {
        ret =
          ret + "<div>Payment Amount: " + (tmp.PAYMENTAMOUNT || "0") + "</div>";
      }
      if (typeof tmp.PAYMENTDATE != "undefined") {
        ret = ret + "<div>Payment Date: " + (tmp.PAYMENTDATE || "") + "</div>";
      }
      if (typeof tmp.PROFITTOTAL != "undefined") {
        ret = ret + "<div>Profit Total: " + (tmp.PROFITTOTAL || "") + "</div>";
      }
      if (typeof tmp.PAYMENTTYPE != "undefined") {
        ret = ret + "<div>Payment Type: " + (tmp.PAYMENTTYPE || "") + "</div>";
      }
      if (
        typeof tmp.PAYMENTREFERENCE != "undefined" &&
        tmp.PAYMENTREFERENCE != ""
      ) {
        ret =
          ret +
          "<div class='status-pay-ref'>Payment Reference: " +
          (tmp.PAYMENTREFERENCE || "") +
          "</div>";
      }
    } catch (err) {
      // console.log(err);
    }

    return ret;
  }

  async showCustomData() {
    this.customDataVisible = !this.customDataVisible;
    if (this.customDataVisible) {
      this.customDataLoading = true;
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Helpers",
          FunctionName: "CustomData",
          DataType: "5",
          Id: this.$route.params.id,
          aId: this.deploymentDetails.AID
        });

        if (response.data.STATUS == 1) {
          const customData = response.data.CUSTOMDATA || [];
          this.fullCustomData = (response.data.CUSTOMDEFINITION || []).map(
            item => {
              const fieldData: any = customData.find(
                t => t.CUSTOMFIELDID == item.CUSTOMFIELDID
              );

              const tmp: any = {
                CUSTOMFIELDID: item.CUSTOMFIELDID,
                CUSTOMFIELDNAME: item.CUSTOMFIELDNAME,
                CUSTOMVALUE: fieldData ? fieldData.CUSTOMVALUE : "",
                CUSTOMID: fieldData ? fieldData.CUSTOMID : 0,
                CUSTOMDTYPE: item.CUSTOMDTYPE || 1,
                OPTIONS: item.OPTIONS || []
              };
              return tmp;
            }
          );
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.customDataLoading = false;
      }
    }
  }

  showCustomEditBox(index) {
    this.customDataIndex = index;

    setTimeout(() => {
      $(".custom-data-txt")
        .focus()
        .select();
    }, 100);
  }

  hideCustomEditBox() {
    this.customDataIndex = -1;
  }

  // showOrderCustomEditBox(index) {
  //   this.customOrderDataIndex = index;

  //   setTimeout(() => {
  //     $(".custom-data-txt")
  //       .focus()
  //       .select();
  //   }, 100);
  // }

  // hideOrderCustomEditBox() {
  //   this.customOrderDataIndex = -1;
  // }

  async updateCustomData(item) {
    //remove HTML
    item.CUSTOMVALUE = this.removeHTML(item.CUSTOMVALUE);

    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        customID: item.CUSTOMID,
        customFieldID: item.CUSTOMFIELDID,
        customValue: item.CUSTOMVALUE,
        Id: this.$route.params.id,
        fieldTypeId: 5
      });

      if (response.data.STATUS == 1) {
        this.customDataIndex = -1;
        if (item.CUSTOMVALUE == "") {
          //delete this item
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = 0;
        } else if (typeof response.data.NEWCUSTOMID != "undefined") {
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = response.data.NEWCUSTOMID;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.customDataLoading = false;
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  /* REQUEST WHITE GLOVE AJAX CALL */
  async requestWhiteGlove() {
    this.apiLoading = true;
    var dataObj = {
      controller: "ExternalSends",
      FunctionName: "whiteglove",
      deploymentID: this.$route.params.id,
      userRole: sessionStorage.getItem("userRole"),
      aID: this.details["ORDERDETAILS"]["AID"]
    };
    var self = this;
    var response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);

    if (response.data.STATUS == 1) {
      self.details.STATUSDETAILS.push({
        Id: response.data.LOGID,
        Status_Details: response.data.STATRETURN[0].STATUSDETAILS,
        dateTime: response.data.STATRETURN[0].STATDATE,
        emailed: response.data.STATRETURN[0].EMAILED,
        fullName: response.data.STATRETURN[0].USERNAME,
        invoiceID: response.data.STATRETURN[0].INVID,
        number: response.data.STATRETURN[0].REQUESTID,
        showOrderProfit: 0,
        sortTime: response.data.STATRETURN[0].SORTDATE,
        source: response.data.STATRETURN[0].STATSOURCE,
        sourceName: "Manual",
        status: response.data.STATRETURN[0].STATTYPE,
        type: response.data.STATRETURN[0].TYPENAME
      });

      /* self.reloadDetails(); */
    } else {
      notifier.alert(response.data.MESSAGE);
    }
    this.apiLoading = false;
    this.apiExternalModalVisible = false;
  }

  async openDashboard(UUID) {
    console.log("UUID", UUID);
    const currentUser = JSON.parse(
      sessionStorage.getItem("currentUser") || "{}"
    );
    const accountId = currentUser[0]["ACCOUNTID"];
    const routeData = this.$router.resolve({
      name: "Deploy",
      query: { aID: accountId, UUID: UUID }
    });
    window.open(routeData.href, "_blank");
  }

  deleteStatus(id, number, status) {
    const dataObj = {
      controller: "Status",
      FunctionName: "Delete",
      Status_ID: id,
      order_id: number,
      status: status,
      aID: this.details["ORDERDETAILS"]["AID"],
      deploymentId: this.deploymentId
    };
    const self = this;
    const result = getRouteData(dataObj);
    result.then(function(response) {
      if (response.data.STATUS == 1) {
        notifier.success(response.data.MESSAGE);
        self.fetchData(false);
      } else {
        notifier.alert(response.data.MESSAGE);
      }
    });
  }

  modalAddStatus = {
    show: false,
    loading: false
  };

  doUpdateStatus() {
    this.isActive = "status";
    this.modalAddStatus.show = true;
  }

  modalAddSchedule = {
    show: false,
    loading: false
  };

  isArray(item) {
    return (
      Array.isArray(item) && item.length > 0 && typeof item[0] === "object"
    );
  }

  isTrackingItems(statusDetails) {
    if (this.isArray(statusDetails)) {
      const foundTrackingInfo = statusDetails.find(
        item => (item.carrier || "") != "" && (item.trackingNumber || "") != ""
      );
      if (foundTrackingInfo) {
        return true;
      }
    }

    return false;
  }

  async updateProgress(value) {
    try {
      const response = await ApiHelper.callApi("post", {
        controller: "Deployments",
        FunctionName: "UpdateDeploymentStatus",
        update: true,
        deploymentId: this.deploymentId,
        date: this.deploymentDetails.DEPLOYMENTDATE,
        deployStatus: value
      });
      if (response.STATUS) {
        this.details.DEPLOYSTATUSTEXT = response.DEPLOYSTATUS;
      }
    } catch (err) {
      console.log(err);
    }
  }

  showAttachGroupTooltip(item, index, e: any) {
    if (this.scheduleTooltipVisibleIndex == index) {
      return;
    }
    this.scheduleTooltipVisibleIndex = index;
    this.isTopTooltip(
      "",
      $(e.target)
        .closest(".deployment-col")
        .find(".schedule-group-info")
    );
    this.setupNewScheduleInfo = {
      selectedUser: item,
      index,
      scheduleGroupId: item.SCHEDULEGROUPID || 0
    };
  }

  showPickWindowTooltip(item, index, e: any) {
    if (this.pickWindowTooltipVisibleIndex == `pickWindow${index}`) {
      return;
    }
    this.pickWindowTooltipVisibleIndex = `pickWindow${index}`;
    this.isTopTooltip(
      "",
      $(e.target)
        .closest(".deployment-col")
        .find(".pick-window-btn")
    );
  }

  closeAddScheduleModal() {
    this.modalAddSchedule.show = false;
    // reopen tooltip "attach to group" if opened before
    if (this.isActive == "endUsers" && this.setupNewScheduleInfo.index >= 0) {
      $(
        `.schedule-group-info-eu${
          this.setupNewScheduleInfo.selectedUser.USERID
        }`
      ).click();
    }
  }

  modalEditDeployment = {
    show: false,
    isProcessing: false,
    controls: {
      name: {
        value: "",
        error: ""
      }
    }
  };

  showEditDeployment() {
    this.modalEditDeployment.controls.name.value = this.deploymentDetails.DEPLOYMENTNAME;
    this.modalEditDeployment.controls.name.error = "";
    this.modalEditDeployment.show = true;
  }
  hideEditDeployment() {
    this.modalEditDeployment.show = false;
  }
  async doUpdateDeployment(showError = false) {
    let hasError = false;
    let message = "";
    if (this.modalEditDeployment.isProcessing) {
      return;
    }
    if (
      this.modalEditDeployment.controls.name.value ==
      this.deploymentDetails.DEPLOYMENTNAME
    ) {
      message = "Deployment Name is not changed";
      hasError = true;
    }

    if (!this.modalEditDeployment.controls.name.value) {
      message = "Deployment Name is required";
      hasError = true;
    }
    if (hasError) {
      if (showError) {
        ApiHelper.showErrorMessage(message);
      }
    } else {
      this.modalEditDeployment.isProcessing = true;
      const response = await ApiHelper.callApi("post", {
        controller: "Deployments",
        FunctionName: "UpdateDeployment",
        deploymentId: this.deploymentId,
        deploymentName: this.modalEditDeployment.controls.name.value
      });
      if (response.STATUS == 1) {
        ApiHelper.showSuccessMessage(
          response.STATUSMESSAGE || "Updated Name successfully"
        );
        this.deploymentDetails.DEPLOYMENTNAME = this.modalEditDeployment.controls.name.value;
        this.modalEditDeployment.isProcessing = false;
        this.modalEditDeployment.show = false;
      } else {
        this.modalEditDeployment.isProcessing = false;
        ApiHelper.showErrorMessage("Cant update deployment name");
      }
    }
  }

  disabledHeader(x) {
    const shows = [
      "name"
      // "emailPhone"
      // "assets",
      // "deployment",
      // "actions",
      // "completion",
    ];
    if (shows.includes(x)) {
      return true;
    }
    let limit = 11;
    if (this.tabEuSelectedHeaders.includes("assets")) {
      limit = limit - 1;
    }
    if (this.tabEuSelectedHeaders.includes("deployment")) {
      limit = limit - 1;
    }
    if (
      !this.tabEuSelectedHeaders.includes(x) &&
      this.tabEuSelectedHeaders.length > limit
    ) {
      // prevent selecting over ${limit} cols
      return true;
    }

    return false;
  }

  async addHeader(c) {
    const addedCol = this.tabEuSelectedHeaders.includes(c);
    colsResizeableReset({
      ms: 0,
      selector: ".page-list .page-list-container > table.tbl-endUsers"
    });
    const sortedHeaders: string[] = [];
    for (const item of this.tabEuHeaderOrder) {
      if (this.tabEuSelectedHeaders.includes(item)) {
        sortedHeaders.push(item);
      }
    }
    this.tabEuSelectedHeaders = sortedHeaders;

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Customers",
      FunctionName: "UpdateAccountParams",
      headers: this.tabEuSelectedHeaders.join(","),
      action: "saveDeploymentEUHeaders",
      aId: this.deploymentDetails.AID || 0
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    /*
    if (addedCol && c.indexOf("customData") != -1) {
      // reload list if show custom fields on list
      await this.fetchData(true);
    }
    */

    if (c.indexOf("customData") != -1) {
      if (typeof this.euFilters[c] != "undefined") {
        this.euFilters[c] = "";
      }
      // reload list if show custom fields on list
      await this.fetchData(true);
    }

    colsResizeable({
      ms: 500,
      selector: ".page-list .page-list-container > table.tbl-endUsers"
    });
  }

  checkFilterExists(header) {
    if (typeof this.tabEuFilters[header] != "undefined") {
      return !this.tabEuFilters[header].length;
    } else {
      return true;
    }
  }

  searchByHeader() {
    const filteredHeader = Object.entries(this.tabEuHeaders).reduce(
      (header, [key, value]) => {
        if (value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1) {
          header[key] = value;
        }
        return header;
      },
      {}
    );
    return filteredHeader;
  }

  disabledScheduleHeader(x) {
    const shows = [
      "scheduleGroup",
      "siteLocation",
    ];
    if (shows.includes(x)) {
      return true;
    }
    let limit = 6;

    if (
      !this.tabScheduleSelectedHeaders.includes(x) &&
      this.tabScheduleSelectedHeaders.length > limit
    ) {
      // prevent selecting over ${limit} cols
      return true;
    }

    return false;
  }

  async addScheduleHeader(c) {
    const sortedHeaders: string[] = [];

    for (const item of this.tabScheduleHeaderOrder) {
      if (this.tabScheduleSelectedHeaders.includes(item)) {
        sortedHeaders.push(item);
      }
    }
    this.tabScheduleSelectedHeaders = sortedHeaders;

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Customers",
      FunctionName: "UpdateAccountParams",
      headers: this.tabScheduleSelectedHeaders.join(","),
      action: "saveDeploymentScheduleHeaders",
      aId: this.deploymentDetails.AID || 0
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (c.indexOf("customData") != -1) {
      if (typeof this.euFilters[c] != "undefined") {
        this.euFilters[c] = "";
      }
      // reload list if show custom fields on list
      await this.fetchData(true);
    }

    colsResizeable({
      ms: 500,
      selector: ".page-list .page-list-container > table.tbl-schedule"
    });
  }

  searchByScheduleHeader() {
    const filteredScheduleHeader = Object.entries(this.tabScheduleHeaders).reduce(
      (header, [key, value]) => {
        if (value.toLowerCase().search(this.searchScheduleHeader.toLowerCase()) != -1) {
          header[key] = value;
        }
        return header;
      },
      {}
    );
    return filteredScheduleHeader;
  }

  clickOutsideHeader() {
    this.searchHeader = "";
    this.searchScheduleHeader = "";
  }

  getCustomDataField(header) {
    const ssFinalInfoFields = [
      "customDataRequestName",
      "customDataSubmitterName",
      "customDataSubmitterEmail",
      "customDataApprover"
    ];
    if (ssFinalInfoFields.find(t => t.toLowerCase() == header.toLowerCase())) {
      let ret = header.replace("customData", "").toUpperCase();
      if (ret == "APPROVER") {
        ret = "LEASECOORDINATOR";
      }
      return ret;
    }

    return `CUSTOMDATA${header.replace("customData", "")}`;
  }

  euFilters: any = {};
  bkEuFilters: any = {};

  hasEuFilters() {
    for (const [key, value] of Object.entries(this.euFilters)) {
      if (typeof value == "string" && value) {
        return true;
      } else if (Array.isArray(value) && value.length) {
        return true;
      }
    }
    return false;
  }

  doEuFilters(header, value) {
    this.bkEuFilters[header] = value;
    this.euFilters[header] = value;
    this.fetchData(true);
  }

  showScheduleSettingsTooltip(e) {
    this.scheduleSettingsTooltipVisible = !this.scheduleSettingsTooltipVisible;
  }

  schedulePortalLink(endUserId = 0) {
    if (this.deploymentDetails.DEPLOYMENTUUID == "" || !this.accountId) {
      return "";
    }

    let query = {};
    if (!endUserId) {
      // params for full deployment
      query = {
        isShowAll: this.schedulePortalSettings.isShowAll,
        lockUnscheduledUsers: this.schedulePortalSettings.lockUnscheduledUsers,
        cd: this.euCustomDataFieldIds
      };
      if (!this.schedulePortalSettings.isShowAll) {
        query = { ...query, ...this.euFilters };
      }
    } else {
      // params for each end user
      query = {
        cd: this.euCustomDataFieldIds
      };
    }

    const routeData = this.$router.resolve({
      name: "DeploymentSchedule",
      params: {
        uuid: this.deploymentDetails.DEPLOYMENTUUID,
        aID: `${this.accountId}`,
        userId: `${endUserId}`
      },
      query
    });
    const portalLink = new URL(
      routeData.href,
      this.portalDomain.DOMAINFULL || window.location.origin
    ).href;

    return portalLink;
  }

  get euCustomDataFieldIds() {
    // based on tabEuSelectedHeaders
    // const ret: string[] = [];
    // for (const header of this.tabEuSelectedHeaders || []) {
    //   if (header.indexOf("customData") == -1) continue;
    //
    //   const fieldId = header.replace("customData", "");
    //   if (parseInt(fieldId) > 0) {
    //     ret.push(fieldId);
    //   }
    // }
    let cdIds = "";
    const tmpIds: any[] = [];
    this.availabilitiesList.map(item => {
      item.criteriaFilters.map((filter: any) => {
        if (filter.customFieldId && !tmpIds.includes(filter.customFieldId)) {
          tmpIds.push(filter.customFieldId);
        } else if (
          (filter.customFieldKey || "") != "" &&
          !tmpIds.includes(filter.customFieldKey)
        ) {
          tmpIds.push(filter.customFieldKey);
        }
      });
    });
    cdIds = tmpIds.join("-");

    return cdIds;
  }

  async removeScheduleTime() {
    const item: any = this.modalDeleteScheduleTime.data || {};
    // const response = await ApiHelper.callApi("post", {
    //   Controller: "Deployments",
    //   FunctionName: "UpdateScheduleATime",
    //   deploymentId: this.deploymentId,
    //   availabilityId: 0,
    //   endUserId: item.USERID || 0,
    //   timeInfo: {
    //     scheduleDate: '',
    //     startTime: '',
    //     endTime: '',
    //     startTimePeriod: '',
    //     endTimePeriod: '',
    //     isOverride: 0
    //   }
    // });
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Deployments",
      FunctionName: "RemoveSchedule",
      deploymentId: this.deploymentId,
      scheduleGroupId: 0,
      endUserId: item.USERID || 0
    });

    if (response.data.STATUS) {
      this.updateDeploymentPercent(response.data.deploymentPercent || 0);
      this.modalDeleteScheduleTime.callback();
      this.fetchData(true);
      ApiHelper.showSuccessMessage("Deleted the Time successfully.");
    } else {
      ApiHelper.showErrorMessage("Cant delete the Time");
    }
    this.modalDeleteScheduleTime.show = false;
  }

  async removeLinkToScheduleGroup() {
    const item: any = this.modalDeleteScheduleGroup.data || {};
    const response = await ApiHelper.callApi("post", {
      Controller: "Deployments",
      FunctionName: "RemoveScheduleGroup",
      deploymentId: this.deploymentId,
      endUserId: item.USERID || 0
    });
    if (response.STATUS) {
      this.updateDeploymentPercent(response.deploymentPercent || 0);
      this.modalDeleteScheduleGroup.callback();
      this.fetchData(true);
      ApiHelper.showSuccessMessage("Deleted the Group successfully.");
    } else {
      ApiHelper.showErrorMessage("Cant delete the Group");
    }
    this.modalDeleteScheduleGroup.show = false;
  }

  async exportCSV() {
    if (["Schedule", "endUsers", "assets"].includes(this.isActive)) {
      const response = await ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "ExportDeploymentDetail",
        deploymentId: this.deploymentId,
        tabView: this.isActive,
        tabEuSelectedHeaders: this.tabEuSelectedHeaders.join(),
        ExportType: "CSV"
      });

      const message = response.STATUSMESSAGE || "";

      if (response.STATUS == 1) {
        downloadFileUrl(response.S3URL);
        if (message) {
          ApiHelper.showSuccessMessage(message);
        }
      } else {
        if (message) {
          ApiHelper.showErrorMessage(message);
        }
      }
    }
  }

  async htmlDownLoadFn() {
    const response = await ApiHelper.callApi("post", {
      Controller: "Deployments",
      FunctionName: "ExportDeploymentScheduleDetail",
      deploymentId: this.deploymentId
    });
    if (response.STATUS) {
      const html = template(response);
      if (!html) return;
      var fileNameExport =
        "ScheduleExport_" + Math.round(+new Date() / 1000) + ".html";
      return downloadFile(fileNameExport, html);
    }
  }

  modalLockSchedule = {
    loading: false,
    show: false
  };

  showLockSchedule() {
    this.modalLockSchedule.show = true;
  }

  get hasEUCustomFields() {
    let ret = false;
    // const euFields = (this.details.euFields || []).filter(
    //   t => t.CUSTOMFIELDID > 0
    // );
    if ((this.details.euFields || []).length) {
      ret = true;
    }

    return ret;
  }

  isLockedSchedule(availabilityId = 0) {
    let ret = false;
    const lockedSchedule = this.deploymentParams.lockedSchedule || 0;
    const lockSelect = this.deploymentParams.lockSelect || 0;
    const lockAvailability =
      (this.deploymentParams.lockAvailability || "") != ""
        ? this.deploymentParams.lockAvailability.split(",")
        : [];
    const lockOnDate = this.deploymentParams.lockOnDate || 0;
    const lockDate = this.deploymentParams.lockDate || "";
    if (
      lockedSchedule &&
      (!lockSelect || lockAvailability.includes(`${availabilityId}`))
    ) {
      // set lock, need to check if lock now or on a specific date onward
      if (
        !lockOnDate ||
        (lockDate &&
          moment(lockDate).isValid() &&
          new Date(moment().format("MM/DD/YYYY")) >=
            new Date(moment(lockDate).format("MM/DD/YYYY")))
      ) {
        // detect locked schedule
        ret = true;
      }
    }

    return ret;
  }

  getEuName(firstName, lastName) {
    const names: string[] = [];
    if (lastName) {
      names.push(lastName);
    }
    if (firstName) {
      names.push(firstName);
    }
    return names.join(", ");
  }

  eusCheckedAllChange() {
    this.eusCheckedAll = !this.eusCheckedAll;
    if (this.eusCheckedAll) {
      // check all
      this.selectedEndUserIds = this.details.EUDETAILS.map(item => item.USERID);
    } else {
      // uncheck all
      this.selectedEndUserIds = [];
    }
  }

  get deletedEUNames() {
    let ret: string[] = [];
    if (this.endUserName) {
      ret = [this.endUserName];
    } else if (this.selectedEndUserIds.length) {
      ret = this.details.EUDETAILS.filter(item =>
        this.selectedEndUserIds.includes(item.USERID)
      ).map(item => item.EUNAME);
    }

    return ret.join(", ");
  }

  async showConfirmMoveEndUserModal() {
    this.endUserId = 0;
    this.endUserName = "";
    this.moveToDeploymentId = [];
    this.confirmMoveEndUser = true;

    // get deployment list
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Deployments",
      FunctionName: "DeploymentsList",
      getAll: 1,
      aId: this.deploymentDetails.AID
    });

    if (response.data.STATUS == 1) {
      this.deploymentsList = (response.data.DEPLOYMENTS || [])
        .filter(item => item.DEPLOYMENTID != this.deploymentId)
        .map(item => ({
          ID: item.DEPLOYMENTID,
          TEXT: item.DEPLOYMENTNAME
        }));
    }
  }

  async moveEndUser() {
    // validate
    if (!this.moveToDeploymentId.length) {
      notifier.alert("Please select a deployment");
      return;
    }

    try {
      this.deleteLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "EndUserDetails",
        action: "moveEndUsers",
        deploymentId: this.deploymentId,
        moveToDeploymentId: this.moveToDeploymentId[0],
        endUserIds: this.selectedEndUserIds
      });

      if ((response.data.STATUS || 0) == 1) {
        this.deleteLoading = false;
        this.selectedEndUserIds = [];
        this.confirmMoveEndUser = false;
        notifier.success(response.data.STATUSMESSAGE);
        await this.fetchData();
      } else {
        this.deleteLoading = "error";
        await this.$nextTick();
        this.deleteLoading = false;
      }
    } catch (err) {
      this.deleteLoading = "error";
      await this.$nextTick();
      this.deleteLoading = false;
      console.log(err.message);
    }
  }
}
