






































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class AcceleratorsModal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: [] })
  acceleratorData!: any;

  @Prop({ required: false, default: {}})
  data!: any;

  saving: boolean = false;
  acceleratorDataBK: any = [];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    this.acceleratorDataBK = JSON.parse(JSON.stringify(this.acceleratorData));
  }

  addInputRow() {
    var newAccelerator = {
      LINKID: 0,
      QUALIFIERS: 1,
      THRESHOLD: 0,
      CEILING: 0,
      IMPACTBONUS: 0
    };
    this.acceleratorDataBK.push(newAccelerator);
  }

  deleteInputRow(index) {
    if (index < this.acceleratorDataBK.length) {
      this.acceleratorDataBK.splice(index, 1);
    }
  }

  async save() {
    const result = await this.$validator.validateAll();

    if (!result) {
      return;
    }

    this.saving = true;
    let acceleratorData = this.acceleratorDataBK.filter((item: any) => item.THRESHOLD || item.CEILING || item.IMPACTBONUS);
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanAdd",
      acceleratorData: acceleratorData,
      action: "updateAccelerator",
      compUUID: this.data.COMPUUID || ''
    });

    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.$emit("reload");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.saving = false;
  }

  removeChar(data, key) {
    if (typeof data[key] != 'undefined') {
      data[key] = data[key].replace(/[^0-9.]/g, "");
      if (!data[key].trim().length) {
        data[key] = 0;
      }
    }
  }
}
