















































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

import LaddaButton from '../components/LaddaButton.vue'
import {notifier, wait} from '../models/common'

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
  },
})
export default class UpdateNotes extends TSXComponent<void> {
  saving: boolean | 'error' = false
  updateNotes: string = "";
  $parent: any;

  @Prop({ required: false,default: "" })
  title!: string;

  @Prop({ required: false,default: "" })
  label!: string;

  @Prop({ required: false,default: "" })
  buttonLabel!: string;

  @Prop({ required: false,default: "" })
  notesType!: string;

  $refs!: {
    modal: HTMLDivElement;
  };
  
  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async updationNotes(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.updateNotes.length > htmlParse(this.updateNotes).length) {
      notifier.alert("HTML content found. This content has been removed!");
      this.updateNotes = htmlParse(this.updateNotes);
    }

    this.saving = true;
    if (this.notesType == "Order") {
      this.$emit("confirm", this.updateNotes);
    }
  }
}
