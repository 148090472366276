




























import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import CustomerAdd from "../pages/CustomerAdd.vue";
import { AcctsListResp } from "../models/AcctsListResp";

interface Props {
  lineInfo: [];
  isPage: boolean;
  includedFrom: string;
  isFinances: boolean;
}
interface Events {
  onClose: void;
  doLinkingWith: {
    item: AcctsListResp.Accountsarr;
  };
}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    CustomerAdd
  }
})
export default class MergeAccountListModal extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  lineInfo!: [];

  @Prop({ required: true })
  isPage!: boolean;

  @Prop({ required: true })
  includedFrom!: string;

  @Prop({ required: false, default: true })
  isFinances!: boolean;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
      // this.$parent.editCommissionShowIndex = -1;
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
    // this.$parent.editCommissionShowIndex = -1;
  }
  doLinkingWith(item) {
    this.$emit("doLinkingWith", {
      AID: item.AID,
      ACCOUNTID: item.ACCOUNTID,
      ANAME: item.ANAME
    });
    // this.$parent.editCommissionShowIndex = -1;
  }
  exit() {
    this.$emit("close");
  }

  cancelMergeAcc() {
    this.$emit("closeMergeAcc");
  }
}
