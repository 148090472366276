import { render, staticRenderFns } from "./SpiffSources.vue?vue&type=template&id=2e006fdf&scoped=true"
import script from "./SpiffSources.vue?vue&type=script&lang=ts"
export * from "./SpiffSources.vue?vue&type=script&lang=ts"
import style0 from "./SpiffSources.vue?vue&type=style&index=0&id=2e006fdf&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e006fdf",
  null
  
)

export default component.exports