



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import log from "../helpers/log";
import axios from "axios";
import directives from "../helpers/directives";
import Pagination from "../components/Pagination.vue";
import { validateFn } from "../helpers";
import {
  UserAPIRepo,
  Filters,
  Sort,
  SortField
} from "../repos/UserAPIRepo";
import { notifier } from "../models/common";
import ConfirmRemoveAccountModal from "../components/confirmCopyAccountsModal.vue";
import AccountMultiselect from "../components/AccountMultiselect.vue";
import SecurityMultiSelect from "../components/SecurityMultiSelect.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import commissionUpdate from "../components/CommissionEditAndDeleteModal.vue";
import editcommission from "../components/EditCommissionToolTip.vue";
import remainingCommissions from "../components/remainingComissionModel.vue";
import AccountComp from "../components/editAccountCommnission.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import ConfirmRemoveAllComm from "../components/ConfirmRemoveItemModal.vue";
import { ErrorBag } from "vee-validate";
import PhoneInput from "../components/PhoneInput.vue";
import DropdownControl from "../components/DropdownControl.vue";
import USState from "../static/USstates.json";
import PageTitle from '../components/pageTitle.vue';

declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;

const userRepo = new UserAPIRepo();
@Component({
  inheritAttrs: false,
  components: {
    ConfirmRemoveAccountModal,
    AccountMultiselect,
    SecurityMultiSelect,
    EmployeeMultiselect,
    commissionUpdate,
    LaddaButton,
    Pagination,
    editcommission,
    remainingCommissions,
    AccountComp,
    ConfirmRemoveItemModal,
    ConfirmRemoveAllComm,
    PhoneInput,
    DropdownControl,
    PageTitle
  },
  directives
})
export default class Profile extends TSXComponent<void> {
  pageNumber = 1;
  isportal = 0;
  hideCommission = 0;
  portalCustomer = "";
  saving: any = false;
  selectedUserIds: (number | undefined)[] = [];
  selectedGroupIds: (number | undefined)[] = [];
  selectedName = [];
  editCommissionAccId = 0;
  commissionInput = 0;
  remainingCommissionVisible = false;
  balanceCommissions = [];
  editCommissionShowIndex = -1;
  isActive = "userDetailTab";
  confirmRemoveModalVisible = false;
  confirmCommissionRemoveModalVisible = false;
  editAccountModelVisible = false;
  isConfirmChecked = true;
  buttonVisible = true;
  selectedID = [];
  linkpaymentIds = "";
  currentRole = sessionStorage.getItem("userRole");
  updateCommisionModal = 0;
  userCommission = [];
  userCommissionPaging = { currentPage: 1, totalPages: 0 };
  commissionUpdateInfo: {
    accID: number;
    userId: number;
    action: string;
    accName: string;
    percentage: number;
    businessLineIdLists: any;
  } = {
    accID: 0,
    userId: 0,
    action: "update",
    accName: "",
    percentage: 0,
    businessLineIdLists: -1
  };
  userlist: {
    accID?: number;
    USERID?: number;
    disabled?: boolean;
    UFULLNAME?: string;
    class?: string;
  }[] = [];
  accList: {
    AID?: number;
    USERID?: number;
    disabled?: boolean;
    ANAME?: string;
    class?: string;
  }[] = [];
  secList: {
    AID?: number;
    USERID?: number;
    disabled?: boolean;
    SECURITYGROUPNAME?: string;
    SECURITYGROUPID?: number;
    class?: string;
  }[] = [];
  filters: any = {
    searchAll: "",
    searchId: "",
    searchName: ""
  };
  sort: Sort = {
    field: null,
    direction: {
      [SortField.Id]: 1,
      [SortField.Name]: 1,
      [SortField.Percent]: 1
    }
  };
  details: any = {
    TOTALPAGES: 0,
    UserSelectedTotal: 0,
    UserSelectedList: this.userlist,
    USERLIST: this.userlist
  };
  options = this.accList;
  items: any = {
    ACCOUNTLIST: this.accList,
    ALLACCOUNTS: this.accList,
    NewPassword: "",
    UFNAME: "",
    ULNAME: "",
    ACCOUNTTOTAL: 0,
    USERID: 0,
    USERSTATE: "",
    USTATE: "",
    UADDRESS: "",
    UPHONEEXT: ""
  };
  userName = "";
  loading = false;
  ALLSECURITYGROUPS = this.secList;
  selectedGroups: {
    USERID?: number;
    SECURITYGROUPNAME?: string;
    SECURITYGROUPID?: number;
  }[] = [];
  submitHide = false;
  EXCEEDED = false;
  isEmailExists = false;
  confirmRemoveAllComm = false;
  fromPortals = 0;
  stateList: any = [];
  selectedState: string[] = [];
  businessLineId = 0;
  $refs!: {
    drodown: DropdownControl;
    confirmRemoveAllComm: ConfirmRemoveAllComm;
    updateCommForAllAccts: remainingCommissions;
  };

  hideCompPlanFlg = 1;

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    if (window.location.href.indexOf("?tab=") > -1) {
      var activeTab = window.location.href.split("?tab=");
      this.isActive = activeTab[1];
    }
    if (window.location.href.indexOf("?portals=") > -1) {
      this.fromPortals = 1;
    }
  }
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  @Watch("balanceCommissions", { deep: true })
  commissionConfirm(newV, old) {
    if (!newV.length) {
      this.remainingCommissionVisible = false;
      this.confirmCommissionRemoveModalVisible = false;
    }
  }
  async created() {
    if (
      typeof this.$route.query.portalUser === "string" &&
      typeof this.$route.query.cutsomer === "string"
    ) {
      this.isportal = parseInt(this.$route.query.portalUser);
      this.portalCustomer = this.$route.query.cutsomer;
    }
    for (const i in USState) {
			this.stateList.push({
				ID: USState[i].Abbr,
				TEXT: USState[i].Abbr,
				data: USState[i]
			});
		}
    await this.fetchData();
  }

  async fetchData() {
    var objId =
      this.$route.params.id && parseInt(this.$route.params.id) > 0
        ? this.$route.params.id
        : sessionStorage.getItem("userId");
    var dataObj = {
      controller: "VARUsers",
      FunctionName: "Edit",
      subsystem: "VAR360",
      AccountID: this.filters.searchId,
      aName: this.filters.searchName,
      userId: objId
    };

    if (this.sort.field) {
      dataObj["order"] = this.sort.field;
      dataObj["direction"] = this.sort.direction[this.sort.field].toString();
    }

    // userData = getRouteData(dataObj);
    var self = this;
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    // await userData.then(function (response, statusText, jqXHR) {
    try {
      if (response.data.STATUS == 1) {
        response.data.USERDETAIL[0].ACCOUNTLIST.forEach(function(item) {
          self.selectedUserIds.push(item.AID);
        });
        response.data.SELECTEDGROUPS.forEach(function(item) {
          self.selectedGroupIds.push(item.SECURITYGROUPID);
        });
        // this.selectedUserIds = response.data.USERDETAIL[0].ACCOUNTLIST;
        this.selectedState = [response.data.USERDETAIL[0].USTATE];
        self.userCommission =
          typeof response.data.USERCOMMISSION != "undefined"
            ? response.data.USERCOMMISSION
            : [];
        self.details = response.data;
        self.hideCommission = response.data.isPortalUser;
        self.isportal = response.data.isPortalUser;
        self.items = response.data.USERSDETAILS;
        // profile.js
        self.items = response.data.USERDETAIL[0];
        self.hideCompPlanFlg = self.items.HIDECOMPPLANFLG;
        // self.details = response.data;
        const userList = self.details.UserSelectedList;
        const allUser = self.details.USERLIST;
        var userListID: (number | undefined)[] = [];
        if (userList.length > 0) {
          userList.forEach(function(element) {
            userListID.push(element.USERID);
          });
        }
        if (allUser.length > 0) {
          for (var i = 0; i < allUser.length; i++) {
            if (userListID.indexOf(allUser[i].USERID) >= 0) {
              allUser[i].disabled = true;
              allUser[i].class = "highlight";
            } else {
              allUser[i].disabled = false;
              allUser[i].class = "";
            }
          }
        }
        const accountList = self.items.ACCOUNTLIST;
        const allAccount = self.items.ALLACCOUNTS;
        var accountListID: (number | undefined)[] = [];
        if (accountList.length > 0) {
          accountList.forEach(function(element) {
            accountListID.push(element.AID);
          });
        }
        if (allAccount.length > 0) {
          for (var ij = 0; ij < allAccount.length; ij++) {
            if (accountListID.indexOf(allAccount[ij].AID) >= 0) {
              allAccount[ij].disabled = true;
              allAccount[ij].class = "highlight";
            } else {
              allAccount[ij].disabled = false;
              allAccount[ij].class = "";
            }
          }
        }
        self.items.ALLACCOUNTS = allAccount;
        self.items.NewPassword = "";
        self.linkpaymentIds = response.data.PAYMENTIDSLIST;
        self.userName = self.items.UFNAME + " " + self.items.ULNAME;
        self.items.USERSTATE = self.items.USTATE;

        self.ALLSECURITYGROUPS = response.data.ALLSECURITYGROUPS;
        self.selectedGroups = response.data.SELECTEDGROUPS;
        $.each(self.ALLSECURITYGROUPS, function(i, val) {
          if (val.SELECTED) {
            self.ALLSECURITYGROUPS[i].disabled = true;
            self.ALLSECURITYGROUPS[i].class = "highlight";
            // self.selectedGroups.push({
            //     "SECURITYGROUPNAME": val.SECURITYGROUPNAME,
            //     "SECURITYGROUPID": val.SECURITYGROUPID
            // });
          } else {
            self.ALLSECURITYGROUPS[i].disabled = false;
            self.ALLSECURITYGROUPS[i].class = "";
          }
        });
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
      } else if (response.data.MESSAGE) {
        notifier.alert(response.data.MESSAGE);
      }
    } catch (err) {
      //handle error
      console.log(err);
    }
    // }).catch(function (error) {
    // 	console.log('error', error);
    // });
  }
  listPageRedirection() {
    if (this.fromPortals) {
      this.$router.push({
        name: "UpdatePortal",
        params: { id: this.items.ACCOUNTID }
      });
    } else if (this.isportal) {
      this.$router.push({
        name: "ViewCustomer",
        params: { id: this.portalCustomer },
        query: { tab: "advancedDet" }
      });
    } else {
      // this.$router.push({ name: "Users" });
      //To go previous page
      window.history.back();
    }
  }
  listPageCustomerRedirection() {
    // this.$router.push({ name: "customerList" });
    //To go previous page
    window.history.back();
  }
  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (
      this.pageNumber <= this.userCommissionPaging.totalPages &&
      this.pageNumber >= 1
    ) {
      await this.loadCommission();
    }

    if (this.pageNumber == 0) {
      this.pageNumber = 1;
    }
    if (this.pageNumber > this.userCommissionPaging.totalPages) {
      this.pageNumber = this.userCommissionPaging.totalPages;
    }
  }
  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.loadCommission();
  }

  async sortingById() {
    this.sortBy(SortField.Id);
  }

  async sortingByName() {
    this.sortBy(SortField.Name);
  }

  async sortingByPercent() {
    this.sortBy(SortField.Percent);
  }
  showButton() {
    // if($('#confirmPassword').val() == '' && $('#upassword').val() == '') {
    //   this.buttonVisible = true;
    // }
    // else {
    // this.buttonVisible = false;
    var pwd = $("#confirmPassword").val();
    var repwd = $("#upassword").val();

    if ($.trim(pwd).length > 0) {
      if ($.trim(pwd) == $.trim(repwd)) {
        $(".validationMatch")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationMatch").hasClass("fail")) {
          $(".validationMatch")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).match(/[A-Z]/)) {
        $(".validationUppercase")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationUppercase").hasClass("fail")) {
          $(".validationUppercase")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).length >= 8) {
        $(".validationLength")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationLength").hasClass("fail")) {
          $(".validationLength")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).match(/\d/)) {
        $(".validationNumber")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationNumber").hasClass("fail")) {
          $(".validationNumber")
            .addClass("fail")
            .removeClass("success");
        }
      }
    } else {
      $(".passResetRequirements .validation")
        .removeClass("success")
        .addClass("fail");
    }
    // }
  }
  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.filters.searchId == "") {
      return;
    } else {
      await this.loadCommission();
    }
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.filters.searchName == "") {
      return;
    } else {
      await this.loadCommission();
    }
  }

  async resetSearchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchId = "";
    await this.loadCommission();
  }

  async resetSearchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchName = "";
    await this.loadCommission();
  }

  // methods: {
  // 	validateFn,
  validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.isEmailExists = false;
    this.$validator.validateAll().then(result => {
      // $(".errorMsg").html("");
      //make sure an account is selected
      // if(this.aID == "" && this.customer != "") {
      //     $("#customer").parent().find(".errorMsg").html("Please select a customer from suggested list.");
      //     result = false;
      // }

      //validate for password
      var errorTab = 0;
      if (this.isActive === "pwdResetTab") {
        if (
          $(".passResetRequirements .validation.validationLength").hasClass(
            "fail"
          ) ||
          $(".passResetRequirements .validation.validationUppercase").hasClass(
            "fail"
          ) ||
          $(".passResetRequirements .validation.validationNumber").hasClass(
            "fail"
          ) ||
          $(".passResetRequirements .validation.validationMatch").hasClass(
            "fail"
          )
        ) {
          result = false;
          errorTab = 2;
        } else {
          result = true;
        }
      }
      // if (
      //   $("#confirmPassword").val() != "" &&
      //   $("#upassword").val() != "" &&
      //   $("#confirmPassword").val() != $("#upassword").val()
      // ) {
      //   result = false;
      //   $("#pwdError").show();
      //   $("#pwdError").html("Passwords should match");
      //   // console.log('err',$("#pwdError").html())
      //   errorTab = 2;
      // }
      // if ($("#confirmPassword").val() != "" && $("#upassword").val() == "") {
      //   result = false;
      //   $(".errorMsg.upassword").html("Please enter the confirm new password.");
      //   errorTab = 2;
      // } else {
      //   $(".errorMsg.upassword").html("");
      // }
      // if ($("#confirmPassword").val() == "" && $("#upassword").val() != "") {
      //   result = false;
      //   $(".errorMsg.confirmPassword").html("Please enter the confirm password.");
      //   errorTab = 2;
      // } else {
      //   $(".errorMsg.confirmPassword").html("");
      // }
      if (
        !$("#uemail")
          .val()
          .match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}")
      ) {
        result = false;
        errorTab = 1;
      }
      if ($("#uaddress").val() == "") {
        $(".errorMsg.address").html("Please enter a address");
        result = false;
        errorTab = 1;
      }

      // if (
      //   !$("#uphone")
      //     .val()
      //     .match(
      //       "^[\\(\\)\\.\\- ]{0,}[0-9]{3}[\\(\\)\\.\\- ]{0,}[0-9]{3}[\\(\\)\\.\\- ]{0,}[0-9]{4}[\\(\\)\\.\\- ]{0,}$"
      //     )
      // ) {
      //   $(".errorMsg.phone").html("Please enter a valid phone number");
      //   result = false;
      //   errorTab = 1;
      // }
      // if ($("#ustate").val() != "") {
      //   if (
      //     !$("#ustate")
      //       .val()
      //       .match("^[a-zA-Z ]*$")
      //   ) {
      //     $(".errorMsg.state").html("Please Enter Valid text format");
      //     result = false;
      //     errorTab = 1;
      //   }
      // }
      if ($("#ucity").val() != "") {
        if (
          !$("#ucity")
            .val()
            .match("^[a-zA-Z ]*$")
        ) {
          $(".errorMsg.city").html("Please Enter Valid text format");
          result = false;
          errorTab = 1;
        }
      }
      if (
        !$("#uzip")
          .val()
          .match("^[0-9]{5}$")
      ) {
        $(".errorMsg.zip").html("Please enter a valid Zip code");
        result = false;
        errorTab = 1;
      }
      //validate data input
      if (
        $("#ufname").val() == "" ||
        $("#ulname").val() == "" ||
        $("#ucity").val() == "" ||
        // $("#ustate").val() == "" ||
        $("#uzip").val() == ""
      ) {
        $("#errorMsgLineQuote").html(
          "Please make sure all your personl information is filled out!"
        );
        result = false;
        errorTab = 1;
      }
      if (this.isEmailExists) {
        result = false;
        errorTab = 1;
      }
      var htmlCount = 0;
      if ($("#ufname").val() != "") {
        if (htmlCheck($("#ufname").val())) {
          this.items.UFNAME = htmlParse($("#ufname").val());
          htmlCount++;
        }
      }
      if ($("#ulname").val() != "") {
        if (htmlCheck($("#ulname").val())) {
          this.items.ULNAME = htmlParse($("#ulname").val());
          htmlCount++;
        }
      }
      if ($("#uaddress").val() != "") {
        if (htmlCheck($("#uaddress").val())) {
          this.items.UADDRESS = htmlParse($("#uaddress").val());
          htmlCount++;
        }
      }
      if (htmlCount != 0) {
        notifier.alert("HTML content found. This content has been removed!");
        result = false;
      }
      if (result) {
        //return this.saveFn(this.items);
        if (this.isActive != "userCommTab") {
          this.saveUser(this.items);
        }
      } else {
        if(this.isActive == 'pwdResetTab' && errorTab == 2){
          notifier.alert("Error: Password Criteria not met");
        }
        if (
          this.$validator.errors.first("ufname") ||
          this.$validator.errors.first("zip") ||
          this.$validator.errors.first("ulname") ||
          this.$validator.errors.first("uemail") ||
          this.$validator.errors.first("uaddress") ||
          this.$validator.errors.first("city") ||
          this.$validator.errors.first("state") ||
          this.$validator.errors.first("phone")
        ) {
          errorTab = 1;
        }
        if (errorTab) {
          var tabErr = ["userDetailTab", "pwdResetTab"];
          this.toggleActive(tabErr[errorTab - 1]);
        }
      }
    });
  }
  updateAccountList(type, index, aId = 0) {
    if (type == "delete") {
      this.$delete(this.selectedUserIds, index);
      this.$delete(this.items.ACCOUNTLIST, index);
      this.items.ACCOUNTTOTAL = this.selectedUserIds.length;

      const inList = this.items.ALLACCOUNTS.find(item => item.AID == aId);
      if(inList) {
        inList.selected = false;
      }
    }
  }
  // updateUserList(type, index, aid) {

  //   if (type == "delete") {
  //     this.$delete(this.details.UserSelectedList, index);
  //     this.details.UserSelectedTotal = this.details.UserSelectedList.length;
  //     $(".addUser option[val=" + aid + "]").attr("disabled", false);
  //     $(".addUser option[val=" + aid + "]").attr("class", "removelight");
  //     this.selectedName.pop(
  //       $(".addUser option:selected span").attr("data-name")
  //     );
  //   }
  // }
  updateSGList(type, index, id) {
    if (type == "delete" && (this.selectedGroups.length > 1 || !(Object.keys(this.details.BasicUserSG).includes('SECURITYGROUPID')))) {
      this.$delete(this.selectedGroups, index);
      this.$delete(this.selectedGroupIds, index);
      $(".addSG option[val=" + id + "]").attr("disabled", false);
      $(".addSG option[val=" + id + "]").attr("class", "removelight");
    } else if (type == "delete" && this.selectedGroups.length == 1) {
      this.selectedGroups[0].SECURITYGROUPID = this.details.BasicUserSG.SECURITYGROUPID;
      this.selectedGroups[0].SECURITYGROUPNAME = this.details.BasicUserSG.SECURITYGROUPNAME;
      this.selectedGroupIds[0] = this.details.BasicUserSG.SECURITYGROUPID;

      $(".addSG option").attr("disabled", false);
      $(".addSG option").attr("class", "removelight");
    }
    this.$forceUpdate();
  }
  allAccounts(e) {
    if (this.options.length) {
      e.preventDefault();
      this.options = this.items.ALLACCOUNTS;
      $(".js-example-tags.form-control").click();
    }
  }
  onChange() {
    var included = 0;
    const acctList = this.items.ACCOUNTLIST;
    for (var i = 0; i < acctList.length; i++) {
      if (
        acctList[i].AID == $(".addAccount option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.items.ACCOUNTLIST.push({
        USERID: $(".addAccount option:selected span").attr("data-user"),
        ANAME: $(".addAccount option:selected span").attr("data-name"),
        AID: $(".addAccount option:selected span").attr("data-id")
      });
      this.items.ACCOUNTTOTAL = this.items.ACCOUNTLIST.length;
    }

    // $(".addAccount option:selected").remove();
    // $(".addAccount option:first-child").prop('selected');
    const numb = $(".addAccount option:selected span").attr("data-id");
    $(".addAccount option[val=" + numb + "]").attr("disabled", true);
    $(".addAccount option[val=" + numb + "]").attr("class", "highlight");
    $(".addAccount option:first-child").prop("selected", true);
  }
  showConfirmRemoveItemModal() {
    this.confirmRemoveModalVisible = true;
  }
  showUserList() {
    $(".copyButton").attr("disabled", true);
    $(".addUser").val("default");
    $(".copyUser").removeClass("displayNone");
    $("#profile-account-list, #profile-sg-list").addClass("displayNone");
    this.submitHide = true;
  }
  showSecurityGroup() {
    this.loading = false;
    var userId = this.$route.params.id;
    this.$router.push({
      name: "SecurityGroupAdd",
      params: { userId: userId, msg: "success" }
    });
  }
  hideUserList() {
    $(".copyUser").addClass("displayNone");
    $("#profile-account-list, #profile-sg-list").removeClass("displayNone");
    this.submitHide = false;
  }
  onChangeUser() {
    var included = 0;
    const userList = this.details.UserSelectedList;
    $(".copyButton").attr("disabled", false);
    for (var i = 0; i < userList.length; i++) {
      if (
        userList[i].USERID == $(".addUser option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.details.UserSelectedList.push({
        USERID: $(".addUser option:selected span").attr("data-user"),
        UFULLNAME: $(".addUser option:selected span").attr("data-name")
      });
      this.selectedName = $(".addUser option:selected span").attr("data-name");

      this.details.UserSelectedTotal = this.details.UserSelectedList.length;
    }
  }
  //when click on security groups dropdown
  onChangeSGList() {
    var included = 0;
    const selectedList = this.selectedGroups;
    for (var i = 0; i < selectedList.length; i++) {
      if (
        selectedList[i].SECURITYGROUPID ==
        $(".addSG option:selected span").attr("data-id")
      ) {
        included = 1;
        break;
      }
    }

    if (included != 1) {
      this.selectedGroups.push({
        SECURITYGROUPNAME: $(".addSG option:selected span").attr("data-name"),
        SECURITYGROUPID: $(".addSG option:selected span").attr("data-id")
      });
    }

    const numb = $(".addSG option:selected span").attr("data-id");
    $(".addSG option[val=" + numb + "]").attr("disabled", true);
    $(".addSG option[val=" + numb + "]").attr("class", "highlight");
    $(".addSG option:first-child").prop("selected", true);
    this.$forceUpdate();
  }
  copyFunctions() {
    var comInstance = this;
    comInstance.saving = true;
    var userList: (number | undefined)[] = [];
    $.each(comInstance.details.UserSelectedList, function(i, val) {
      userList.push(val.USERID);
    });
    getRouteData({
      controller: "VARUsers",
      FunctionName: "Update",
      subsystem: "VAR360",
      userid:
        comInstance.$route.params.id &&
        parseInt(comInstance.$route.params.id) > 0
          ? comInstance.$route.params.id
          : sessionStorage.getItem("userId"),
      copy: "yes",
      userList: userList.join(",")
    }).then(function(response, statusText, jqXHR) {
      if (response.data.COPYSTATUS == 1) {
        comInstance.items.ACCOUNTLIST = response.data.ACCOUNTLIST;
        comInstance.items.ACCOUNTTOTAL = response.data.ACCOUNTLIST.length;
        comInstance.selectedUserIds = response.data.ACCOUNTID;
        comInstance.saving = false;
        comInstance.confirmRemoveModalVisible = false;
        $(".copyUser").addClass("displayNone");
        comInstance.details.UserSelectedList = response.data.USERSELECTEDLIST;
        comInstance.selectedName = [];
        comInstance.hideUserList();
      } else {
        comInstance.saving = "error";
      }
    });
  }
  async saveUser(data) {
    this.saving = true;
    var comInstance = this;
    await getRouteData({
      controller: "VARUsers",
      FunctionName: "CheckEmailExists",
      subsystem: "VAR360",
      uemail: data.UEMAIL,
      domain: data.DOMAIN,
      userid: data.USERID
    }).then(function(response, statusText, jqXHR) {
      if (!response.data.RECORDCOUNT) {
        //detect selected accounts
        var accountsList: (number | undefined)[] = [];
        $.each(comInstance.items.ACCOUNTLIST, function(i, val) {
          accountsList.push(val.AID);
        });
        accountsList = comInstance.selectedUserIds;

        //detect selected security groups
        var sgList: (number | undefined)[] = [];
        $.each(comInstance.selectedGroups, function(i, val) {
          sgList.push(val.SECURITYGROUPID);
        });
        sgList = comInstance.selectedGroupIds;
        const requestObject: any = {
          controller: "VARUsers",
          FunctionName: "Update",
          subsystem: "VAR360",
          userid:
            comInstance.$route.params.id &&
            parseInt(comInstance.$route.params.id) > 0
              ? comInstance.$route.params.id
              : sessionStorage.getItem("userId"),
          accountid: data.ACCOUNTID,
          active: data.ACTIVE,
          ufname: data.UFNAME,
          ulname: data.ULNAME,
          uemail: data.UEMAIL,
          upassword: data.NewPassword,
          uaddress: data.UADDRESS,
          ucity: data.UCITY,
          ustate: data.USERSTATE,
          uzip: data.UZIP,
          uphone: data.UPHONE,
          uPhoneExt: data.UPHONEEXT,
          companyName: data.ACCOUNTNAME,
          accountsList: accountsList.join(","),
          sgList: sgList.join(",")
        }
        // use different api route for customer users
        if (comInstance.currentRole == "Customer" || comInstance.fromPortals) {
          requestObject.FunctionName = "UpdateCustomerUser";
          if(comInstance.fromPortals) {
            requestObject.varUpdateCustomerUser = true;
          }
        }

        getRouteData(requestObject).then(function(response, statusText, jqXHR) {
          if (response.data.STATUS == 1) {
            if (comInstance.$route.params.id) {
              notifier.success(response.data.MESSAGE);
              $(".errorMsg").html("");
              comInstance.isEmailExists = false;

              // John comment - some indicator showing it saved and then stay on the same page and don't refresh page.
              // router.push({ name: "Users" });
              //   comInstance.toggleActive("userDetailTab");
            }
          } else {
            console.log(response.data.MESSAGE);
            comInstance.saving = "error";
          }
        });
        comInstance.saving = false;
      } else {
        //!response.data.RECORDCOUNT
        // notifier.alert(response.data.MESSAGE);
        comInstance.isEmailExists = true;
        comInstance.saving = "error";
      }
      // comInstance.saving = false;
    });
  }
  toggleActive(div) {
    this.isActive = div;

    if (div == "userCommTab") {
      //load data
      this.loadCommission();
    }
  }
  toggleSelectedUserId(userId, accountName) {
    const index = this.selectedUserIds.findIndex(id => id === userId);
    const inList = this.items.ALLACCOUNTS.find(item => item.AID == userId);
    if (index === -1) {
      this.selectedUserIds.push(userId);
      this.items.ACCOUNTLIST.push({
        USERID: this.items.USERID,
        ANAME: accountName,
        AID: userId
      });
      this.items.ACCOUNTTOTAL = this.items.ACCOUNTLIST.length;

      // mark selected in all accounts list
      if(inList) {
        inList.selected = true;
      }

      return;
    }

    this.selectedUserIds.splice(index, 1);
    this.$delete(this.items.ACCOUNTLIST, index);
    if(inList) {
      inList.selected = false;
    }
  }

  selectAllUsers() {
    this.resetUsers();
    var thisUserID = this.items.USERID;
    var $this = this;
    this.selectedUserIds = this.items.ALLACCOUNTS.map(u => u.AID);

    $.each(this.items.ALLACCOUNTS, function(i, val) {
      $this.items.ACCOUNTLIST.push({
        USERID: thisUserID,
        ANAME: val.ANAME,
        AID: val.AID
      });
    });
  }

  resetUsers() {
    this.selectedUserIds = [];
    this.items.ACCOUNTLIST = [];
  }

  toggleSelectedGroupId(userId, accountName) {
    const index = this.selectedGroupIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedGroupIds.push(userId);
      this.selectedGroups.push({
        USERID: this.items.USERID,
        SECURITYGROUPNAME: accountName,
        SECURITYGROUPID: userId
      });

      // auto select accounts this group handles
      const related: any = this.ALLSECURITYGROUPS.find(item => item.SECURITYGROUPID == userId);
      const aIds = related ? (related.AIDS || "") : "";
      if(aIds != "") {
        this.autoSelectAccounts(aIds);
      }

      return;
    }

    this.selectedGroupIds.splice(index, 1);
    this.$delete(this.selectedGroups, index);
  }

  selectAllGroups() {
    this.resetGroups();
    var thisUserID = this.items.USERID;
    var $this = this;
    this.selectedGroupIds = this.ALLSECURITYGROUPS.map(u => u.SECURITYGROUPID);

    $.each(this.ALLSECURITYGROUPS, function(i, val) {
      $this.selectedGroups.push({
        USERID: thisUserID,
        SECURITYGROUPNAME: val.SECURITYGROUPNAME,
        SECURITYGROUPID: val.SECURITYGROUPID
      });
      $this.autoSelectAccounts(val.AIDS || "");
    });
  }

  resetGroups() {
    this.selectedGroupIds = [];
    this.selectedGroups = [];
  }
  toggleSelectedCopyUserId(userId, accountName) {
    const index = this.selectedGroupIds.findIndex(id => id === userId);
    // To select single Account
    this.details.UserSelectedList = [];
    if (index === -1) {
      // this.selectedGroupIds.push(userId);
      this.details.UserSelectedList.push({
        USERID: userId,
        UFULLNAME: accountName
      });
      this.selectedName = accountName;
      $(".copyButton").attr("disabled", false);
      return;
    }
  }

  showCommissionEditModal(accountId, userId, percent, aName) {
    this.$router.push({
      name: "ViewCustomer",
      params: { id: accountId },
      query: {
        tab: "commission"
      }
    });
  }

  editCommission(accountId, userId) {
    var dataObj = {
      controller: "VARUsers",
      FunctionName: "Edit",
      subsystem: "VAR360",
      from: "editUserCommission",
      userId: userId,
      accountId: accountId
    };
    var self = this;
    getRouteData(dataObj).then(function(response) {
      console.log("editCommission", response);
    });
  }

  deleteCommission(accountId, userId, percent, aName, businessLineIdLists) {
    this.commissionUpdateInfo.action = "delete";
    // this.accountId = accountId;
    // this.userId = userId;
    this.updateCommisionModal = 1;
    this.commissionUpdateInfo.percentage = percent;
    this.commissionUpdateInfo.accID = accountId;
    this.commissionUpdateInfo.userId = userId;
    this.commissionUpdateInfo.accName = aName;
    this.commissionUpdateInfo.businessLineIdLists = businessLineIdLists.length ? businessLineIdLists : -1;
    // this.showCommissionEditModal(accountId, userId,percent,aName);
  }

  async modalClose(t) {
    this.updateCommisionModal = 0;
    this.commissionUpdateInfo.action = "update";
    this.loadCommission(1);
    // if(t){
    //     this.loading = true;
    //     var objId =
    //       this.$route.params.id && parseInt(this.$route.params.id) > 0
    //         ? this.$route.params.id
    //         : sessionStorage.getItem("userId");
    //     var dataObj = {
    //       controller: "VARUsers",
    //       FunctionName: "Edit",
    //       subsystem: "VAR360",
    //       userId: objId
    //     };

    //     // userData = getRouteData(dataObj);
    //     var self = this;
    //     const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    //     try {
    //         if (response.data.STATUS == 1) {
    //           self.userCommission = response.data.USERCOMMISSION;
    //         } else if (response.data.ERROR) {
    //           console.log(response.data.ERROR);
    //         } else if (response.data.MESSAGE) {
    //           notifier.alert(response.data.MESSAGE);
    //         }

    //       } catch (e) {
    //         //handle error
    //         console.log("profile.js : getRouteData error : ", e);
    //       }
    //     self.loading = false;
    // }
  }
  EnableEditCommission(i: number, event: MouseEvent) {
    event.stopPropagation();
    if (this.editCommissionShowIndex === i) {
      this.editCommissionShowIndex = -1;
    } else {
      this.editCommissionShowIndex = i;
    }
  }

  handleClickOutside(event: MouseEvent) {
    const addTopPopOverToolTipElement = this.$el.querySelectorAll('.addTopPopOverToolTip .text');
    const editCommissionElement = this.$el.querySelectorAll('.popover-content-inner');

    const clickedInsidePopOver = Array.from(addTopPopOverToolTipElement).some(element => element.contains(event.target as Node));
    const clickedInsideCommission = Array.from(editCommissionElement).some(element => element.contains(event.target as Node));

    if (clickedInsidePopOver || clickedInsideCommission) {
      return;
    }

    this.editCommissionShowIndex = -1;
  }
  openAccounts(aId) {
    this.editAccountModelVisible = !this.editAccountModelVisible;
  }
  editAccountCommission(acc) {
    this.editCommissionAccId = acc;
    this.remainingCommissionVisible = !this.remainingCommissionVisible;
    this.editAccountModelVisible = !this.editAccountModelVisible;
  }

  openCommssionModel() {
    this.remainingCommissionVisible = !this.remainingCommissionVisible;
    this.editAccountModelVisible = !this.editAccountModelVisible;
  }

  confirmRemoveCommission() {
    this.confirmCommissionRemoveModalVisible = false;
    this.balanceCommissions = [];
    this.remainingCommissionVisible = false;
    this.isConfirmChecked = false;
  }

  async loadCommission(page = 0) {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARUsers",
        FunctionName: "AllCommissions",
        subsystem: "VAR360",
        userId: this.$route.params.id,
        pageNumber: page > 0 ? page : this.pageNumber,
        AccountID: this.filters.searchId,
        aName: this.filters.searchName,
        order: this.sort.field != null ? this.sort.field : 0,
        direction:
          this.sort.field != null
            ? this.sort.direction[this.sort.field].toString()
            : 1
      });

      if (response.data.ERROR) {
        log.error(response.data.ERROR);
        return false;
      }
      if (response.data.STATUS !== 1) {
        log.error(response.data.STATUSMESSAGE);
        return false;
      }

      this.userCommission = response.data.USERCOMMISSION;
      this.userCommissionPaging = {
        currentPage: response.data.CURRENTPAGE,
        totalPages: response.data.TOTALPAGES
      };
    } catch (e) {
      log.debug("loadCommission", e);
    } finally {
      this.loading = false;
    }
  }

  reloadComm() {
    var updateCommForAllAccts: any = this.$refs.updateCommForAllAccts;
    if (updateCommForAllAccts.isDataChanged) {
      this.loadCommission(1);
    }
  }

  async removeAllComm() {
    var refModal: any = this.$refs.confirmRemoveAllComm;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Users",
        FunctionName: "AccountCommission",
        action: "remove_all_comm_for_user",
        forUserID: this.$route.params.id
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.confirmRemoveAllComm = false;
        this.loadCommission(1);
      }
    } catch (err) {
      console.log(err);
      refModal.deleting = "error";
    } finally {
      refModal.deleting = false;
      this.confirmRemoveAllComm = false;
    }
  }

  updateUserState(selectedID) {
    this.selectedState = [selectedID];
    this.items.USERSTATE =  selectedID;
  }
  close(){
    this.$refs.drodown.dropdownVisible = false;
  }
  
  getExt(e){
    if(e != undefined){
      return e.split(' ')[0]
    }
    else{
      return 'US'
    }
  }
  countryChanged(obj) {
    this.items.UPHONEEXT = obj.countryCode+' '+'+'+obj.countryCallingCode;
  }

  autoSelectAccounts(aIds = "") {
    if(aIds == "") return;
    for(const aId of aIds.split(",")) {
      const inList = this.items.ALLACCOUNTS.find(item => item.AID == aId);
      if(inList && !inList.selected) {
        // auto select
        this.toggleSelectedUserId(inList.AID, inList.ANAME);
      }
    }
  }

  async hideCompPlanSwitch(val: number) {
    try {

      const userId = this.$route.params.id && parseInt(this.$route.params.id) > 0
                          ? this.$route.params.id
                          : sessionStorage.getItem("userId");

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARUsers",
        FunctionName: "Update",
        subsystem: "VAR360",
        action: 'HideCompPlanFlg',
        userId: userId,
        hideCompPlanFlg: val
      });

      const message = response.data.MESSAGE || "";
      if (response.data.STATUS == 1) {
        this.hideCompPlanFlg = val;
        sessionStorage.setItem('hideCompPlanFlg', val.toString());
        if (message) {
          notifier.success(message);
        }
      } else {
        if (message) {
          notifier.alert(message);
        }
      }
    } catch(error) {
      console.log(error);
    }
  }

}
