


















































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import axios from 'axios'

declare const dataURL: string;
declare const $: any;

interface Props {
 invoiceId : number;
 details : string;
}

interface Events {}

@Component({
  inheritAttrs: false,
})
export default class StatusCreate extends TSXComponent< Props,Events > {

  @Prop({ required: true })
   invoiceId!: number; 
  @Prop({ required: true })
   details!: string;

  saving = false
  $parent: any;
  StatusDetails: any

  $refs!: {
    modal: HTMLDivElement;
  };
  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }
  exportInvoice() {
    this.$emit("close");
    if(typeof this.$parent.exportChange != "undefined") {
      this.$parent.exportChange('invoice',this.invoiceId);
    }else {
      this.$emit("exportInvoice");
    }
  }
  printInvoice() {
    if(typeof this.$parent.printInvoice != "undefined") {
      this.$parent.printInvoice(this.invoiceId);
    }else {
      this.$emit("printInvoice");
    }
  }
  mailInvoice() {
    this.$emit("close");
    if(typeof this.$parent.emailChange != "undefined") {
      this.$parent.emailChange(this.invoiceId);
    }else {
      this.$emit("mailInvoice");
    }
  }
}
