var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda;

  return "<table width=\"100%\" id=\"customerList_header\">\n  <tr>\n    <td style=\"padding-right: 5px;\">\n      <img src=\"https://s3.amazonaws.com/smartstoresgeneric/mail/HP_logo_remote.png\" width=\"63\" height=\"63\" style=\"display:block; border: 0;\" border=\"0\" />\n    </td>\n    <td colspan=\"2\"><img src=\"https://smartstoresgeneric.s3.amazonaws.com/mail/milestonetechlogo.png\" style=\"height:37px !important;display:block; border: 0; float: right;height: 52px;\" border=\"0\" /></td>\n  </tr>\n  <tr>\n    <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 20px;letter-spacing: 1.7px;\">\n      <strong>HP INC.</strong><br/>\n      14231 Tandem Blvd, Austin, Tx 78728\n    </td>\n  </tr>\n  <tr>\n    <td colspan=\"3\">\n      <table width=\"100%\">\n        <tr>\n          <td style=\"padding: 20px 0;font-size: 26px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\"><span style=\"color: #0196d5;\">Dashboard Details</span></td>\n        </tr>\n      </table>\n    </td>\n  </tr>\n</table>\n\n<!--\n<table width=\"100%\" id=\"quoteDetails_info\">\n  <tr>\n    <td width=\"60%\">\n      <table>\n        <tr><td class=\"title\">Quote Name</td></tr>\n        <tr><td><strong>"
    + alias1(((helper = (helper = helpers.QUOTENAME || (depth0 != null ? depth0.QUOTENAME : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"QUOTENAME","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":37}}}) : helper)))
    + "</strong></td></tr>\n        <tr><td class=\"title\">Quote Number</td></tr>\n        <tr><td><strong>"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.QUOTEID : stack1), depth0))
    + "</strong></td></tr>\n        <tr><td class=\"title\">Quote Date & Expiration</td></tr>\n        <tr><td><strong>"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.LSDATE : stack1), depth0))
    + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.QUOTEEXPIRE : stack1), depth0))
    + "</strong></td></tr>\n      </table>\n    </td>\n    <td width=\"40%\">\n      <table>\n        <tr><td class=\"title\">Account Rep</td></tr>\n        <tr><td><strong>"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.VARCONTACT : depth0)) != null ? stack1.ContactName : stack1), depth0))
    + "</strong></td></tr>\n        <tr><td class=\"title\">Phone</td></tr>\n        <tr><td><strong>"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.VARCONTACT : depth0)) != null ? stack1.Phone : stack1), depth0))
    + "</strong></td></tr>\n        <tr><td class=\"title\">Email</td></tr>\n        <tr><td><strong>"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.VARCONTACT : depth0)) != null ? stack1.Email : stack1), depth0))
    + "</strong></td></tr>\n      </table>\n    </td>\n  </tr>\n</table>\n-->\n<table width=\"100%\" id=\"customerList_info\">\n  <tr><td></td></tr>\n</table>\n";
},"useData":true});