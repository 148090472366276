




























































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { EngineAPIRepo } from "../repos/EngineAPIRepo";
import ConfirmModalBox from "../components/ConfirmRemoveItemModal.vue";
import MessageBox from "../components/MessageBox.vue";
import OrderStatusReportModal from "../components/OrderStatusReportModal.vue";
import { colsResizeable } from "@/helpers/ApiHelper";

import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
declare const dataURL: string;
const engineRepo = new EngineAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    ConfirmModalBox,
    MessageBox,
    OrderStatusReportModal
  }
})
export default class Engine extends TSXComponent<void> {
  loading = false;
	currentRole = sessionStorage.getItem("userRole");
  curPage = 1;
  totalRows = 0;
  pageNumber = 1;
  engineList = [];
  total = 0;
  confirmedEngineVisible = false;
  messageBoxModal = false;
  EngineAction = 0;
  entriesMessage = '';
  HPOSSImport = 2; // engineId
  CommissionImport = 3; // Weekly Agent Commission Statement import
  selectedEngine: any = null;
  orderStatusReportVisible = false;
  ordersCheckStatus: any = [];
  updateMap: any = [];

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const engineData = await engineRepo.engineList(
        this.pageNumber
      );
      this.engineList = engineData.ENGINELIST;
      this.total = engineData.TOTAL;
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
    this.loading = false;

    colsResizeable({ms: 500});
  }

  async runEngine() {
    const engineID = this.selectedEngine.ENGINEID || 0;
    if(this.selectedEngine.ISCHECKSTATUS || 0) {
      this.checkIndirectStatus();
      return;
    }

    this.loading = true;
    try {
      const engineData = await engineRepo.engineRun(
        engineID
      );

      if(engineData.STATUS == 1) {
        this.entriesMessage = engineData.ENTRIESUPDATED + " of entries successfully updated. " + engineData.ENTRIESNOTUPDATED + " of entries failed.";
        this.confirmedEngineVisible = false;
        this.messageBoxModal = true;
      }
      // this.engineList = engineData.ENGINELIST;
      // this.total = engineData.TOTAL;
    } catch (err) {
      console.log(err);
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
    // this.loading = false;
  }

  // async loadPage(page: number, type: "prev" | "next" | "") {
  //   if (type === "prev") {
  //     this.curPage = this.curPage - 1;
  //   } else if (type === "next") {
  //     this.curPage = this.curPage + 1;
  //   } else {
  //     this.curPage = page;
  //   }
  //   console.log('page',this.curPage)
  //   if (this.curPage <= this.totalRows && this.curPage >= 1) {
  //     await this.fetchData();
  //   }
  // }

  selectEngine(item) {
    this.selectedEngine = item;
  }

  async checkIndirectStatus() {
    const handleFile = (this.selectedEngine.HANDLEFILE || "").replace(".cfm", "");
    if(handleFile == "") return;

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Subsystem: "Engine",
        Controller: "Status",
        FunctionName: handleFile,
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      
      if(response.data.STATUS) {
        this.confirmedEngineVisible = false;
        this.updateMap = response.data.updateMap || [];
        this.ordersCheckStatus = response.data.orders || [];
        this.orderStatusReportVisible = true;
      }else {
        const message = response.data.STATUSMESSAGE || "";
        if (message != "") {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }
}
