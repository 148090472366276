






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { Summary } from "./OrderFormSummary.vue";
import PriceWarnTooltip from "../components/PriceWarnTooltip.vue";
import ProductCatTooltip from "../components/ProductCatTooltip.vue";
import ConfirmYesNoTooltip from "../components/ConfirmYesNoTooltip.vue";
import directives from "../helpers/directives";
import axios from "axios";
import {dollarFormat, isPriceExceeded, isPriceListExceeded} from "@/helpers/ApiHelper";
import ContractPriceTooltip from "../components/ContractPriceTooltip.vue";
import BuildHeaderTooltip from "@/components/BuildHeaderTooltip.vue";

interface Props {
  index: string;
  item: ItemName;
  i: number;
}

interface Events {
  onSummaryChange: Summary;
}

export interface ItemName {
  sku: string;
  name: string;
  price: number;
  customerPrice: number;
  margin: number;
  marginRate: number;
  total: number;
  quantity: number;
  quantityBk?: number;
  tax: number;
  taxRate: number;
  shipping: number;
  included: number;
  ISCONFIG?: number;
  categoryType: number;
  priceWarn: boolean;
  noTax: boolean;
  isFee: boolean;
  dynamicInfo: any;
  statusid: number;
  contractPrice: any;
  contractPriceDate: Date | undefined;
  orgPrice: number;
  orgCustomerPrice: number;
  disable: boolean;
  isContractFee: boolean;
  ItemCategory?: number;
  ItemCategoryBk?: number;
  ItemCategoryName?: string;
  ItemCategoryNameBk?: string;
  includedparent: number;
  baseProductId: any;
  includeItemCount: number;
  quoteLiId?: number;
  quoteParentLiId?: number;
  parentLiId?: number;
  quoteId?: string;
  subQuoteId?: number;
  subOrderId?: number;
  CONFIGCOUNT?: number;
  savedAsConfig?: number;
  ItemPLID?: number;
  ItemPLName?: string;
  buildHeader?: string;
}
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {
    PriceWarnTooltip,
    ProductCatTooltip,
    ConfirmYesNoTooltip,
    ContractPriceTooltip,
    BuildHeaderTooltip
  },
  directives,
  methods: {
    isPriceExceeded,
    isPriceListExceeded,
    dollarFormat
  }
})
export default class OrderFormLineItemListRow extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: {} })
  ss!: any;

  @Prop({ required: true })
  index!: string;

  @Prop({ required: true })
  item!: ItemName;

  @Prop({ required: true })
  ismarginTax!: boolean;

  @Prop({ required: true })
  i!: number;

  @Prop({ required: true, default: 2 })
  expandDecimalPrecision!: number;

  @Prop({ required: true, default: false })
  fromQuote!: boolean;

  @Prop({ required: false, default: {} })
  configCategory!: any;

  $parent: any;
  inputSKUTimer: any = null;
  loading = false;
  contractPriceTooltipIndex = -1;
  buildHeaderTooltipIndex = -1;

  constructor() {
    super();
  }

  async validateAll(): Promise<
    "blank" | "invalid" | { item: ItemName; index: string }
  > {
    // Old code
    // if (this.item.sku.trim() == '' && this.item.name.trim() == '' && !parseInt(this.item.price) && !parseInt(this.item.customerPrice) && !parseInt(this.item.quantity) ) {
    if (
      this.item.sku.trim() == "" &&
      this.item.name.trim() == "" &&
      this.item.quantity == 0
    ) {
      for (var k = 0; k < this.$validator.errors.items.length; k++) {
        if (
          this.$validator.errors.items[k].field == `cust_cost_${this.index}`
        ) {
          this.$validator.errors.items.splice(k, 1);
          break;
        }
      }
      for (var j = 0; j < this.$validator.errors.items.length; j++) {
        if (this.$validator.errors.items[j].field == `cost_${this.index}`) {
          this.$validator.errors.items.splice(j, 1);
          break;
        }
      }

      return "blank";
    }
    $("input.initial.error.number").removeClass("initial");
    $("input.initial.error").removeClass("initial");

    const valid = await this.$validator.validateAll();
    if (!valid) {
      // if (this.item.price > 0 || this.item.customerPrice > 0) {
      if (this.$validator.errors.items.length) {
        for (var i = 0; i < this.$validator.errors.items.length; i++) {
          if (
            this.$validator.errors.items[i].field == `cust_cost_${this.index}`
          ) {
            this.$validator.errors.items.splice(i, 1);
            continue;
          }
          if (
            this.$validator.errors.items.length &&
            this.$validator.errors.items[i].field != undefined &&
            this.$validator.errors.items[i].field == `cost_${this.index}`
          ) {
            this.$validator.errors.items.splice(i, 1);
          }
        }
      } else {
        //console.log('length',this.errors.items.length)
      }
      // } else {
      //console.log('else')
      // }
      if (this.$validator.errors.items.length) {
        return "invalid";
      }
    }

    return {
      item: this.item,
      index: this.index
    };
  }

  get includedEnabled(): boolean {
    return this.i !== 0;
  }

  // isEmptyRow(item, i) {
  //   if((i == Object.keys(this.$parent.itemNames).length - 1)) {
  //     // last row
  //     return true;
  //   }
  //   if(item.sku == "" && item.name == "" && item.quantity == 0) {
  //     return true;
  //   }

  //   return false;
  // }

  // isFee(item) {
  //   return item.isFee || item.isContractFee || false;
  // }

  selectDisty(item, distyID) {
    const distyPrice = item.dynamicInfo[`Disty_${distyID}_Price`] || 0;
    if (distyPrice == 0) {
      return;
    }

    item.dynamicInfo.selectedDistyID = distyID;
    item.price = distyPrice;
    this.$emit("calcWithChangeCostPrice");
    this.$forceUpdate();
  }

  clearSelectedDisty(item) {
    if (
      this.$parent.$parent.isDynamic() &&
      typeof item.dynamicInfo != "undefined"
    ) {
      item.dynamicInfo.selectedDistyID = 0;
      this.$forceUpdate();
    }
  }

  // disableTax(item,status) {
  //   if((status!=1 && status!=2) || this.$route.name =='DuplicateOrder') {
  //     this.$emit('itemTaxable', item.index)
  //   }
  // }

  // disableInclude(status) {
  //   if(!this.includedEnabled || status == 1 || status == 2) {
  //     return;
  //   }

  //   this.$emit('includeItem');
  // }

  inputSKU(item, index, e: any) {
    // this.$parent.checkingSKU = true;
    this.$emit("addDelete", index);
    this.$parent.showFeeTooltip(item, index, e);

    // if(this.inputSKUTimer) {
    //   clearTimeout(this.inputSKUTimer);
    // }
    // this.inputSKUTimer = setTimeout(() => {
    //   this.$emit('SKULookup', index, item);
    // }, 300);
  }

  categoryImgLink(item) {
    var checkSku =
      item.sku != "" && !item.isFee && item.sku.toLowerCase() != "contractfee";
    if (
        checkSku &&
        ((item.ISCONFIG || item.ItemCategory) && (item.categoryType != 0 || item.ItemCategoryName.toLowerCase() != "choose a category"))
    ) {
      const configCategoryId = this.configCategory.CATEGORYID || 0;
      if (
        (configCategoryId > 0 && configCategoryId == item.ItemCategory) ||
        (item.ISCONFIG || 0)
      ) {
        // specify image for config item
        return require("@/assets/images/config-cat-ico.svg");
      }

      return require("@/assets/images/category_icon.svg");
    } else {
      return require("@/assets/images/category-not-available.svg");
    }
  }

  showIncludeBtn(item) {
    if (
      !this.includedEnabled ||
      this.$parent.isFee(item) ||
      item.isContractFee ||
      item.included
    ) {
      return false;
    }

    return true;
  }

  showContractTooltip(index) {
    this.contractPriceTooltipIndex = index;
  }

  hideContractTooltip() {
    this.contractPriceTooltipIndex = -1;
  }

  updateContractPrice(data, item) {
    item.customerPrice = data.contractPrice;
    this.contractPriceTooltipIndex = -1;
    this.$emit("marginCalc1");
  }

  saveBuildHeader(data, item) {
    item.buildHeader = data.buildHeader || '';
    this.buildHeaderTooltipIndex = -1;
  }
}
