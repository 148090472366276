






















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: customerStatusValue;
}

interface Events {
  onChangeStatus: customerStatusValue,
  onChangelevel: customerRoleValue
}

export enum customerStatusValue {
  active = 1,//0 or 1
  inactive = 0
}

export enum customerRoleValue {
  admin = 1,//0 or 1
  support = 2,
  general = 0
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class customerStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: customerStatusValue;

  @Prop({ required: false, default: 0 })
  isrole!: customerRoleValue;

  @Prop({ required: false, default: 0 })
  isdisable!: boolean;

  popoverVisible = false
  optionsVisible = false

  get iconClass() {
    if(this.isrole){
      if(this.status == 1){
        return 'lightPurple'
      }else if(this.status == 2){
        return 'blue'
      }
      else{
        return 'purple'
      }
    }else{
      if(this.status == customerStatusValue.active || this.status == 1){
        return 'green'
      }
      else{
        return 'red'
      }
    }
  }

  get statusText() {
    if(this.isrole){
      if(this.status == 1){
        return 'Admin'
      }else if(this.status == 2){
        return 'Support'
      }
      else{
        return 'General'
      }
    }else{
      if(this.status == customerStatusValue.active || this.status == 1){
        return 'Active'
      }
      else{
        return 'Inactive'
      }
    }
  }
  hideTooltip() {
    this.optionsVisible = false;
  }
  mouseover() {
    if (this.optionsVisible) {
      return
    }

    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    if(!this.isdisable){
      this.popoverVisible = false
      
      this.optionsVisible = !this.optionsVisible
    }
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: customerStatusValue) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }

  statusUpdate(level:customerRoleValue){
    this.$emit('changeLevel', level)
    this.optionsVisible = false
  }
}
