













import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";
import directives from "../helpers/directives";

import { InvoiceLineItem } from "../models/invoice";
import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput_poID.vue";
import { poIDList } from "../models/poIDList";

interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    poID: poIDList.POID,
    purchaseID: poIDList.PURCHASEID,
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
  directives
})
export default class CustomerAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  show?: boolean

  @Prop({ required: false, default : 0 })
  deploymentId?: number

  @Prop({ required: false, default : "Orders" })
  controller?: string

  @Prop({ required: false, default : 0 })
  aID?: number

  @Prop({ required: false, default : false })
  filterByCustomer?: boolean

  @Prop({ required: false, default : false })
  isLedgerEdit?: boolean

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = []
  searchData: any = []
  $parent: any;

  createOptions(pos: any) {
    const options = pos.map(
      (acc): Option => {
        return {
          value: acc.PURCHASEID,
          text: acc.PURCHASEID
        };
      }
    )

    this.options = options
    this.searchData = pos

    return {
      options,
    };
  }

  createProductOptions(pos) {
    const options = pos.map(
      (acc): Option => {
        return {
          value: acc.PURCHASEID,
          text: `${acc.PURCHASEID} - ${acc.PODESCRIPTION}`
        };
      }
    )

    this.options = options
    this.searchData = pos

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!search) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        "FunctionName": "purchases",
        "controller": this.controller,
        "queryType": "top5Recent",
        "id": this.deploymentId || 0,
        "aID": this.aID || 0,
        "filterByCustomer": this.filterByCustomer
      });

      if (response.data.ERROR || !response.data.poIDlist) {
        return {
          options: []
        };
      }

      if(this.controller == "Deployments") {
        return this.createProductOptions(response.data.poIDlist)
      }
      else {
        return this.createOptions(response.data.poIDlist)
      }
    }

    const exportObj = {
      FunctionName: "purchases",
      controller: this.controller,
      getAll: 1,
      search,
      id: this.deploymentId || 0,
      aID: this.aID || 0,
      filterByCustomer: this.filterByCustomer
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    if (response.data.ERROR || !response.data.poIDlist) {
      return {
        options: []
      };
    }

    if(this.controller == "Deployments") {
      return this.createProductOptions(response.data.poIDlist)
    }
    else {
      return this.createOptions(response.data.poIDlist)
    }
  };
  
  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      //console.log('this.options', this.options)
      //console.warn(`no option found for value[${value}]`)
      return
    }
    const poID = this.searchData.find((item) => item.POID.toString() === value)
    const purchaseID = this.searchData.find((item) => item.PURCHASEID.toString() === value.toString())
    if (typeof this.$parent.pl_amount != 'undefined' && !this.isLedgerEdit) {
      const selectedData = this.searchData.filter((item) => item.PURCHASEID === value)
      this.$parent.pl_amount = selectedData.length ? (selectedData[0].VARCOSTAMOUNT - selectedData[0].DISTRIBUTORBILLAMOUNT) : 0
    }
    this.$emit('selection', value)
    if (!poID) {
      // console.warn(`no poID found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      poID,
      purchaseID,
    })
  }

  onAutoFill(value: string) {
    if (!value) return

    const option = this.options.find((o) => o.value === value)
    const poID = this.searchData.find((item) => item.POID.toString() === value)
    const purchaseID = this.searchData.find((item) => item.PURCHASEID.toString() === value.toString())
    if (typeof this.$parent.pl_amount != 'undefined') {
      const selectedData = this.searchData.filter((item) => item.PURCHASEID === value)
      this.$parent.pl_amount = selectedData.length ? (selectedData[0].VARCOSTAMOUNT - selectedData[0].DISTRIBUTORBILLAMOUNT) : 0
    }
    this.$emit('selection', value)
    if (!poID) {
      // console.warn(`no poID found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      poID,
      purchaseID,
    })
  }

  getval(event)
	{
		alert(event.target.value);
	}
}
