













































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import directives from "../../helpers/directives";
import { notifier } from "@/models/common";
import Loader from "@/components/Loader.vue";

declare const $: any;
declare const dataURL: string;

interface Props {}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Loader
  },
  directives
})
export default class ModalAddDeploymentScheduleGroupUserNote extends TSXComponent<void> {
  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: true, default: 0 })
  scheduleGroupId?: number;

  @Prop({ required: false, default: 0 })
  endUserId?: number;

  @Prop({ required: false, default: "" })
  endUserName?: string;

  @Prop({ required: false, default: "" })
  note?: string;

  $refs!: {
    modal: HTMLDivElement;
  };

  loading = false;
  saving: any = false;
  errors: any;

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    setTimeout(() => {
      $("#deploymentNote").focus();
    }, 100);
  }

  async saveNote() {
    // validate
    if (this.note == "") return;

    this.saving = true;
    try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "UpdateScheduleGroupUserNotes",
          deploymentId: this.deploymentId,
          scheduleGroupId: this.scheduleGroupId,
          userId: this.endUserId,
          note: this.note
        });

        const message = response.data.STATUSMESSAGE || "";
        if (response.data.STATUS == 1) {
          notifier.success(message);
          this.$emit("callback", {
            scheduleGroupId: this.scheduleGroupId,
            userId: this.endUserId,
            note: this.note
          });
        } else {
          if (message) {
            notifier.alert(message);
          }
        }
      } catch (error) {
        console.log('error ', error);
      } finally {
        this.saving = false;
      }
  }
}
