









import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";

export interface Item {}

interface Props {}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {}
})
export default class Loading extends TSXComponent<Props, Events> {}
