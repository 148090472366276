













































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
declare function require(params: any): any;

import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import { notifier,
downloadFile,
downloadFileUrl 
} from "../models/common";
import LedgerPaymentDetailsModal from "../components/LedgerPaymentDetailsModal.vue";
import LedgerPaymentModal from "../components/LedgerPaymentModal.vue";
import { LedgerPaymentAPIRepo,PaymentFilters, PaymentSort, PaymentSortField } from "../repos/LedgerPaymentAPIRepo";
import PageTitle from '../components/pageTitle.vue';
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { getDateRangeFilter } from "@/helpers/ApiHelper";

const paymnetRepo = new LedgerPaymentAPIRepo();

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    ListHeaderActions,
    Pagination,
    FilterCurrencyBox,
    LedgerPaymentDetailsModal,
    LedgerPaymentModal,
    PageTitle
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})

export default class ledgerPayments extends TSXComponent<void> {
  $refs!: {
    LedgerPaymentDetailsModal: LedgerPaymentDetailsModal;
  };
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  isSearch = 0;
  totalAll = 0;
  total = 0;
  totalPaymentsItems = 0;
  ret = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1
  };
  pageNumber = 1; 
  ledgerPaymentId = 0;
  paymentDetailsModalVisible = false;
  paymentModalVisible = false;
  viewType = "CurrentUsers";
  selectedView = false;
  ledgerPaymentDetails:any = [];
  paymentFilters: PaymentFilters = {
    paymentId: "",
    paymentName: "",
    pTotalMin: "",
    pTotalMax: "",
    paymentsDate: undefined,
    paymenteDate: undefined,
    paidsDate: undefined,
    paideDate: undefined,
    paymentSource: [],
    paymentSourceName: [],
    quickbooks: [0, 1]
  };
  paymentSort = {
    field: 0,
    direction: {
      [PaymentSortField.paymentId]: 1,
      [PaymentSortField.paymentName]: 1,
      [PaymentSortField.paymentDate]: 1,
      [PaymentSortField.paidDate]: 1,
      [PaymentSortField.paymentTotal]: 1
    }
  };
  selectedAll = false;
  selectedID: (number | undefined)[] = [];
  excludedIDs: number[] = [];
  showFilter = 0;
  ledgerPaymentsCheckAll = false;
  selectPageAll = false;
  selectedPage = 0;
  newPageLedgerPayment: (number | undefined)[] = [];
  totalLedgerPayments: (number | undefined)[] = [];
  sourceList = [];
  searchFilters: PaymentFilters = {
    paymentId: "",
    paymentName: "",
    pTotalMin: "",
    pTotalMax: "",
    paymentsDate: undefined,
    paymenteDate: undefined,
    paidsDate: undefined,
    paideDate: undefined,
    paymentSource: [],
    paymentSourceName: [],
    quickbooks: [0, 1]
  };
  

  async created() {
    await this.fetchData(true);
  }

  hasChangedSourceFilters = false;

  async fetchData(initialLoad: boolean = false) {
    this.loading = true;
    this.hasChangedSourceFilters = false;
    try {
      const ret = await paymnetRepo.findPaymentDetails(
      this.paymentFilters,
      this.paymentSort,
      this.pageNumber,
      this.viewType,
      this.selectedView,
      initialLoad
    );
    this.ret = ret;
    this.ledgerPaymentDetails = ret.PAYMENTDETAILS;
    this.isSearch = ret.ISSEARCH;
    this.totalAll = ret.TOTALALL;
    this.total = ret.TOTAL;
    this.totalPaymentsItems = ret.TOTALPAYMENTSITEMS;
    this.sourceList = ret.sourcesObj;
    if(typeof this.sourceList != 'undefined' && this.paymentFilters.paymentSource.length == 0 && initialLoad) {
      this.paymentFilters.paymentSource = Object.keys(this.sourceList);
      this.paymentFilters.paymentSourceName = Object.values(this.sourceList);
    }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    if (this.selectedAll === true) {
      this.selectAll(true);
    }
    var checkAllPages = this.newPageLedgerPayment.includes(this.pageNumber);
    if (checkAllPages)  {
      this.totalLedgerPayments = [];
      for (var val of this.ledgerPaymentDetails) {
        if (!this.excludedIDs.includes(val.PAYMENTID)) {
          this.totalLedgerPayments.push(val.PAYMENTID);
        }
      }
      var intersectingArrays = this.getArraysIntersection(this.totalLedgerPayments, this.selectedID);
      if (intersectingArrays.length == this.ledgerPaymentDetails.length) {
        this.ledgerPaymentsCheckAll = true;
      } else {
        this.ledgerPaymentsCheckAll = false;
      }
    }
    else {
      this.ledgerPaymentsCheckAll = false;
    }
    if(this.isSearch ==1 && !initialLoad) {
      this.showFilter = 1;
    }
  }
  listPageRedirection() {
    this.$router.push({ name: "Ledgers" });
  }
  async sortBy(field: PaymentSortField) {
    this.paymentSort.field = field;
    this.paymentSort.direction[field] = this.paymentSort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.paymentSort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.paymentSort.direction[dirField] = 1;
    }
    await this.fetchData();
  }
  async sortingByPaymentId() {
    await this.sortBy(PaymentSortField.paymentId);
  } 
  async sortingByPurchaseID() {
    await this.sortBy(PaymentSortField.paymentName);
  }
  async sortingByPaymentDate() {
    await this.sortBy(PaymentSortField.paymentDate);
  }
  async sortingByPaidDate() {
    await this.sortBy(PaymentSortField.paidDate);
  }
  async sortingByTotalPayment() {
    await this.sortBy(PaymentSortField.paymentTotal);
  }
  async sortingByQuickbooks() {
    await this.sortBy(PaymentSortField.quickbooks);
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if (this.pageNumber <= this.ret.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async searchById() {
    this.pageNumber = 1;  
    if (this.searchFilters.paymentId == "") {
      return;
    } else {
      this.paymentFilters.paymentId = this.searchFilters.paymentId;
      await this.fetchData();
    }
  }
  
  async resetSearchById() {
    this.pageNumber = 1;
    this.paymentFilters.paymentId = this.searchFilters.paymentId = "";
    await this.fetchData();
  }

  async searchByPaymentName() {
    this.pageNumber = 1;
    if (this.searchFilters.paymentName == "") {
      return;
    } else {
      this.paymentFilters.paymentName = this.searchFilters.paymentName;
      await this.fetchData();
    }
  }
  
  async resetSearchByPaymentName() {
    this.pageNumber = 1;
    this.paymentFilters.paymentName = this.searchFilters.paymentName = "";
    await this.fetchData();
  }

  async searchByPaymentDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.paymentsDate == undefined ||
        this.searchFilters.paymentsDate == "") &&
      (this.searchFilters.paymenteDate == "" ||
        this.searchFilters.paymenteDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.paymentFilters.paymentsDate = this.searchFilters.paymentsDate;
      this.paymentFilters.paymenteDate = this.searchFilters.paymenteDate;
      await this.fetchData();
    }
  }
  async resetSearchByPaymentDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.paymentsDate == undefined ||
        this.searchFilters.paymentsDate == "") &&
      (this.searchFilters.paymenteDate == "" ||
        this.searchFilters.paymenteDate == undefined)
    ) {
      return;
    } else {
      this.paymentFilters.paymentsDate = this.searchFilters.paymentsDate = "";
      this.paymentFilters.paymenteDate = this.searchFilters.paymenteDate = "";
      await this.fetchData();
    }
  }

  async searchByPaidDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.paidsDate == undefined ||
        this.searchFilters.paidsDate == "") &&
      (this.searchFilters.paideDate == "" ||
        this.searchFilters.paideDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.paymentFilters.paidsDate = this.searchFilters.paidsDate;
      this.paymentFilters.paideDate = this.searchFilters.paideDate;
      await this.fetchData();
    }
  }
  async resetSearchByPaidDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.paidsDate == undefined ||
        this.searchFilters.paidsDate == "") &&
      (this.searchFilters.paideDate == "" ||
        this.searchFilters.paideDate == undefined)
    ) {
      return;
    } else {
      this.paymentFilters.paidsDate = this.searchFilters.paidsDate = "";
      this.paymentFilters.paideDate = this.searchFilters.paideDate = "";
      await this.fetchData();
    }
  }  
  async searchByAmount() {
    this.pageNumber = 1;
    this.paymentFilters.pTotalMin = this.searchFilters.pTotalMin;
    this.paymentFilters.pTotalMax = this.searchFilters.pTotalMax;
    await this.fetchData();
  }
  async resetSearchByAmount() {
    this.pageNumber = 1;   
    this.paymentFilters.pTotalMin = this.searchFilters.pTotalMin = "";
    this.paymentFilters.pTotalMax = this.searchFilters.pTotalMax = "";
    await this.fetchData();
  }
  async showPayments(paymentId) {
    if(this.paymentDetailsModalVisible) {
      await this.$refs.LedgerPaymentDetailsModal.closeModal();
    }
    this.ledgerPaymentId = paymentId;
    this.paymentDetailsModalVisible = true;
  }
  async checkQuickbooks(quickBooksId) {
    try {
      const quickbooks = {
        controller: "Finances",
        FunctionName: "ledgerPayment",
        action: "checkQuickbooks",
        paymentID: quickBooksId
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", quickbooks);
    } catch (err) {
      // console.log(err.message);
    }
    await this.fetchData();
  }
  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }
    return ret;
  }
  async csvDownLoadFn() {
    this.loading = true;
    let fileUrl;
    try {
      fileUrl = await paymnetRepo.exportListsCSVFileUrl(
        this.paymentFilters,
        this.paymentSort,
        this.pageNumber,
        this.viewType,
        this.selectedView,
        this.selectedAll,
        this.excludedIDs,
        this.selectedID
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    this.loading = false;
    return downloadFileUrl(fileUrl);
  }
  ledgersCheckAllChange() {
    this.ledgerPaymentsCheckAll = !this.ledgerPaymentsCheckAll;
    if (!this.ledgerPaymentsCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
  }
  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      this.selectedPage = this.pageNumber
      this.newPageLedgerPayment.push(this.selectedPage);
      for (var val of this.ledgerPaymentDetails) {
        if (!this.excludedIDs.includes(val.PAYMENTID)) {
          if(this.selectedID.indexOf(val.PAYMENTID) == -1)  {
            this.selectedID.push(val.PAYMENTID);
            this.totalLedgerPayments.push(val.PAYMENTID);
          }
        }
      }
    } else {
      for (var val of this.ledgerPaymentDetails) {
        let selectedIDIndex = this.selectedID.indexOf(val.PAYMENTID);
        let totalLedgerPaymentsIndex = this.totalLedgerPayments.indexOf(val.PAYMENTID);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalLedgerPayments.splice(totalLedgerPaymentsIndex, 1);
      }
      let newPageLedgerIndex = this.newPageLedgerPayment.indexOf(this.pageNumber)
      this.newPageLedgerPayment.splice(newPageLedgerIndex, 1);
    }
  }
  checkExcludedIDs(items, key) {
    if (this.selectedAll || this.selectPageAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
            this.ledgerPaymentsCheckAll = false;
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex((id) => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.totalPaymentsItems) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
    if(this.selectedID.length == this.totalLedgerPayments.length && this.selectedID.length !=0) {
      this.ledgerPaymentsCheckAll = true;
    }
    else{
      this.ledgerPaymentsCheckAll = false;
    }
  }
  selectAll(selectedAll = true) {
    this.ledgerPaymentsCheckAll = false;
    this.excludedIDs = [];
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.ledgerPaymentDetails) {
        if (!this.excludedIDs.includes(val.PAYMENTID)) {
          this.selectedID.push(val.PAYMENTID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }
  getArraysIntersection(getLedgerPaymentsOne,getLedgerPaymentsTwo) {
    return getLedgerPaymentsOne.filter(function(n) { return getLedgerPaymentsTwo.indexOf(n) !== -1;});
  }
  async checkSourceList(paymentSource: string, name: string) {
    this.pageNumber = 1;
    const i = this.paymentFilters.paymentSource.findIndex(s => s === paymentSource);
    if (i === -1) {
      this.paymentFilters.paymentSource.push(paymentSource);
    } else {
      this.paymentFilters.paymentSource.splice(i, 1);
    }
    const sourceIndex = this.paymentFilters.paymentSourceName.findIndex(
      sourceValue => sourceValue === name
    );
    if (sourceIndex === -1) {
      this.paymentFilters.paymentSourceName.push(name);
    } else {
      this.paymentFilters.paymentSourceName.splice(sourceIndex, 1);
    }
    this.hasChangedSourceFilters = true;
    // await this.fetchData();
  }
  async checkAllSourceList() {
    this.pageNumber = 1;
    this.paymentFilters.paymentSource = Object.keys(this.sourceList);
    this.paymentFilters.paymentSourceName = Object.values(this.sourceList);
    await this.fetchData();
  }
  async uncheckAllSourceList() {
    this.pageNumber = 1;
    this.paymentFilters.paymentSource = [];
    this.paymentFilters.paymentSourceName = [];
    await this.fetchData();
  }
  async resetSourceList() {
    this.paymentFilters.paymentSource = [];
    this.paymentFilters.paymentSourceName = [];
    this.checkAllSourceList();
  }
  async checkQuickbooksList(quickbooks: number) {
    this.pageNumber = 1;
    const i = this.paymentFilters.quickbooks.findIndex(s => s === quickbooks);
    if (i === -1) {
      this.paymentFilters.quickbooks.push(quickbooks);
    } else {
      this.paymentFilters.quickbooks.splice(i, 1);
    }
    await this.fetchData();
  }
  async checkAllQuickbooksList() {
    this.pageNumber = 1;
    this.paymentFilters.quickbooks = [0, 1];
    await this.fetchData();
  }
  async uncheckAllQuickbooksList() {
    this.pageNumber = 1;
    this.paymentFilters.quickbooks = [];
    await this.fetchData();
  }
  async resetQuickbooksList() {
    this.paymentFilters.quickbooks = [];
    this.checkAllQuickbooksList();
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'QUICKBOOKS':
        if ( this.paymentFilters.quickbooks && !this.paymentFilters.quickbooks.length) {
          filter = "None";
        } else if (this.paymentFilters.quickbooks && this.paymentFilters.quickbooks.length != 2) {
          const arrStatusNames: string[] = [];
          if (this.paymentFilters.quickbooks.includes(1)) {
            arrStatusNames.push('Checked');
          }
          if (this.paymentFilters.quickbooks.includes(0)) {
            arrStatusNames.push('Unchecked');
          }
          filter = arrStatusNames.join(', ');
        }
        break;
      case 'SOURCE':
        if (this.paymentFilters.paymentSourceName && !this.paymentFilters.paymentSourceName.length)
          filter = "None";
        else if (
          this.paymentFilters.paymentSourceName &&
          !(Object.keys(this.sourceList).length == this.paymentFilters.paymentSourceName.length)
        ) {
          filter = this.paymentFilters.paymentSourceName.join(", ");
        }
        break;
      case 'DATE':
        filter = getDateRangeFilter(this.paymentFilters.paymentsDate, this.paymentFilters.paymenteDate);
        searchKey = '';
        break;
      case 'PAIDDATE':
        filter = getDateRangeFilter(this.paymentFilters.paidsDate, this.paymentFilters.paideDate);
        searchKey = '';
        break;
      case 'TOTAL':
        filter = this.getRangeFilter(this.paymentFilters.pTotalMin, this.paymentFilters.pTotalMax);
        searchKey = '';
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`Min: ${min}`);
    }
    if (max) {
      arr.push(`Max: ${max}`);
    }
    return arr.join('; ');
  }
}
