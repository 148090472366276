var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.companyName : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                The HP, Inc. Vendor address is:<br/><br/>\n                HP Inc.<br/>\n                Attn: Public Sector Sales<br/>\n            ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.streetAddress : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                3800 Quick Hill Road<br/>\n                Bldg 2, Suite 100<br/>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.cityState : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                Austin, TX 78728\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"quoteDetails_vendor\">\n    <tr><td class=\"headerColor\"><strong>HP Inc. Vendor Address</strong></td></tr>\n    <tr>\n        <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.companyName : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.streetAddress : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.cityState : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "        </td>\n    </tr>\n</table>\n";
},"useData":true});