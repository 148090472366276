


































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Watch, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import CustomerAutoSuggestInput from "../components/CustomerAutoSuggestInput.vue";
import poIDAutoSuggestInput from "../components/poIDAutoSuggestInput.vue";
import Vuex from "vuex";
import { Order } from "@/models/Order";
import PageTitle from "../components/pageTitle.vue";
import SetAvailabilityModal from "@/components/Deployment/SetAvailabilityModal.vue";
import moment from "moment";
import { ApiHelper } from "@/helpers/all";
import Datepicker from "@/components/Datepicker.vue";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    LaddaButton,
    CustomerAutoSuggestInput,
    poIDAutoSuggestInput,
    PageTitle,
    SetAvailabilityModal,
    Datepicker,
    FormMultiSelect
  }
})
export default class CreateDeployment extends TSXComponent<void> {
  productDetails: {
    [key: string]: {
      detail?: string;
      description?: string;
    };
  } = {};
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  saving: any = false;
  pageNumber = 1;
  deploymentName = "";
  deploymentId = "";
  customer = "";
  aID = 0;
  fullCustomData: any = [];
  customDataVisible = false;
  ordersData: Order.Root | any = {};
  orderDetails: any = [];
  orderDetailsBK: any = [];
  purchaseID = "";
  selectedOrdersID: number[] = [];
  details: any = {};
  orderDeployed = true;
  tieOrder = true;
  // firstPurchaseId = 0;
  today: any = new Date();
  yyyy: any = this.today.getFullYear();
  mm: any = this.today.getMonth() + 1; // Months start at 0!
  dd: any = this.today.getDate();
  availabilityModalVisible = false;
  available: any = {
    start: "",
    end: "",
    daysOfWeek: [],
    duration: {
      hours: "",
      startTime: "",
      startTimePeriod: "",
      endTime: "",
      endTimePeriod: ""
    },
    spots: ""
  };
  exceptions: any = [];
  criteriaFilters: any = [];
  statusTypes: any = [];
  availabilitiesList: any = [];

  async created() {
    if (typeof this.$route.query.aName === "string") {
      this.customer = this.$route.query.aName.length ? this.$route.query.aName : "";
    }
    if (
      typeof this.$route.query.aId !== "undefined"
      && typeof this.$route.query.aId !== "number"
    ) {
      this.aID = this.$route.query.aId.length ? this.$route.query.aId : 0;
    }
    if (this.dd < 10) this.dd = "0" + this.dd;
    if (this.mm < 10) this.mm = "0" + this.mm;

    // get deployment custom fields
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomData",
        DataType: "5",
        Id: parseInt(this.$route.params.id) || 0
      });

      if (response.data.STATUS == 1) {
        const customData = response.data.CUSTOMDATA || [];
        this.fullCustomData = (response.data.CUSTOMDEFINITION || []).map(
          item => {
            const fieldData: any = customData.find(
              t => t.CUSTOMFIELDID == item.CUSTOMFIELDID
            );
            const tmp: any = {
              AID: item.AID,
              CUSTOMFIELDID: item.CUSTOMFIELDID,
              CUSTOMFIELDNAME: item.CUSTOMFIELDNAME,
              CUSTOMVALUE: fieldData ? fieldData.CUSTOMVALUE : "",
              CUSTOMID: fieldData ? fieldData.CUSTOMID : 0,
              CUSTOMDTYPE: item.CUSTOMDTYPE || 1,
              OPTIONS: item.OPTIONS || [],
              selectedIds: []
            };
            return tmp;
          }
        );
      }
    } catch (err) {
      console.log(err);
    }

    this.updateCustomer(this.$route.params);
    const orderIds = this.$route.params.selectedIDs || "";
    if (orderIds) {
      this.selectedOrdersID = `${orderIds}`.split(",").map(id => parseInt(id));
      this.updateOrder();
    }

    this.deploymentName = `${this.dd}/${this.mm}/${this.yyyy} - New Deployment`;
    $("#deploymentName").focus();

    // get deployment status types
    const responseStatusTypes = await ApiHelper.getStatusTypes(0);
    this.statusTypes = responseStatusTypes.statusTypes || [];
  }

  // async mounted() {
  // if (typeof this.$route.params.selectedIDs != "undefined") {
  //   if (typeof this.$route.params.selectedIDs == "number") {
  //     this.updateOrder(this.$route.params.selectedIDs);
  //   } else {
  //     var skuArray = this.$route.params.selectedIDs.split(",");
  //     for (var i = 0; i < skuArray.length; i++) {
  //       this.updateOrder(skuArray[i]);
  //     }
  //   }
  // }

  // this.updateCustomer(this.$route.params);
  // const orderIds = this.$route.params.selectedIDs || "";
  // if (orderIds) {
  //   this.selectedOrdersID = `${orderIds}`.split(",").map(id => parseInt(id));
  //   this.updateOrder();
  // }

  // this.deploymentName = `${this.dd}/${this.mm}/${this.yyyy} - New Deployment`;
  // $("#deploymentName").focus();
  // }

  // This function updates an order in the system
  async updateOrder(orderId = 0) {
    if (orderId) {
      // Find the index of the order in the list of selected orders
      const index = this.selectedOrdersID.findIndex(id => id == orderId);

      // If the order is not already in the list, add it
      if (index === -1) {
        this.selectedOrdersID.push(orderId);
      }
    }

    // Reset the purchaseID to an empty string
    this.purchaseID = " ";

    // Call an asynchronous function to get the details of the order
    await this.getOrderDetails();

    // init custom data for deployment based on first imported order
    /*const firstPurchaseId = this.selectedOrdersID.length
      ? this.selectedOrdersID[0]
      : 0;
    if (firstPurchaseId != this.firstPurchaseId) {
      // Send a POST request to a data URL to get custom data for the order
      try {
        const result = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Helpers",
          FunctionName: "CustomData",
          DataType: "1",
          Id: firstPurchaseId
        });

        // If the request is successful, update custom fields in the deployment
        if (result.data.STATUS == 1) {
          // Get the custom fields and data for the order
          const orderCustomFields = result.data.CUSTOMDEFINITION || [];
          const orderCustomData = result.data.CUSTOMDATA || [];

          // Loop through each custom field and update the corresponding field in the deployment
          for (const item of orderCustomFields) {
            // Find the custom data for the current field
            const customData = orderCustomData.find(
              t => t.CUSTOMFIELDID == item.CUSTOMFIELDID
            );
            if (!customData) continue;

            // Find the corresponding field in the deployment by name
            const relatedField = this.fullCustomData.find(
              (t: any) =>
                t.CUSTOMFIELDNAME.toLowerCase() ==
                item.CUSTOMFIELDNAME.toLowerCase()
            );
            if (!relatedField) continue;

            // Update the custom value in the deployment
            relatedField.CUSTOMVALUE = customData.CUSTOMVALUE;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }*/

    // fill custom data from all orders
    const relatedFields = this.fullCustomData.filter(
      item => item.AID == 0 || item.AID == this.aID
    );
    if (relatedFields.length && this.selectedOrdersID.length) {
      try {
        const result = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Orders",
          FunctionName: "CustomData",
          DataType: "1",
          ids: this.selectedOrdersID.join(",")
        });

        if (result.data.STATUS == 1) {
          const orderCustomData = result.data.CUSTOMDATA || [];
          for (const item of relatedFields) {
            const customData = orderCustomData.filter(
              t => t.CUSTOMFIELDID == item.CUSTOMFIELDID
            );
            if (!customData.length) continue;

            // update the custom value in the deployment
            if (item.CUSTOMDTYPE == 1) {
              // text field
              item.CUSTOMVALUE = [
                ...new Set(customData.map(t => t.CUSTOMVALUE))
              ].join(", ");
            } else if (item.CUSTOMDTYPE == 3) {
              // multiple dropdown field
              item.selectedIds = [
                ...new Set(customData.map(t => t.CUSTOMVALUE))
              ];
              item.CUSTOMVALUE = item.selectedIds.length
                ? JSON.stringify(item.selectedIds)
                : "";
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getOrderDetails() {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "Purchases",
        Content: "Detailed",
        purchaseID: this.selectedOrdersID.length
          ? this.selectedOrdersID.join(",")
          : 0
      });

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.orderDetails = this.details.ORDERDETAILS.filter((item) => !(item.CANCELLED || false));
        for (const item of this.orderDetails) {
          item["productId"] = item.POLIID;
          item["SKU"] = item.POPRODSKU;
          item["PRODUCTNAME"] = item.POPRODDESC;
          item["QUANTITY"] = item.POPRODQUANTITY;
          item["PRODUCTTYPENAME"] = item.PRODUCTTYPE;
          item["PRODUCTTYPE"] = item.TYPE;
          item["allocated"] = 0;
          item["isMainItem"] = item.ISMAINITEM || 0;
          item["includedItems"] = this.orderDetails.filter(
            t => item.POLIID == t.POLIIDPARENT && t.ISCONFIG
          );
          item["poLiIdParent"] = item.POLIIDPARENT || 0;
          item["show"] =
            item.ISMAINITEM || (item.POINCLUDED && !item.ISCONFIG)
              ? true
              : false;
          item["ProductTypeSelected"] = item.CATEGORYTYPE == 1 ? 1 : 2;
          item["error"] = "";
        }
        this.orderDetailsBK = JSON.parse(JSON.stringify(this.orderDetails));
        if (this.orderDeployed) {
          this.orderDetails.forEach(item => {
            item.INCLUDE = item.POPRODQUANTITY;
          });
        }

        // pupulate data for customer
        if (!this.aID && this.orderDetails.length) {
          this.aID = this.orderDetails[0].AID;
          this.customer = this.orderDetails[0].ANAME;
        }
      }
    } catch (err) {
      // handle error
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  listPageRedirection() {
    this.$router.push({ name: "Deployments" });
  }

  updateCustomer(data) {
    if (!data.account) return;

    this.customer = data.account.ANAME;
    this.aID = data.account.AID;
  }

  changeCustomer(value) {
    if (!value) {
      this.aID = 0;
    }
  }

  async saveDeployment() {
    //remove html
    this.deploymentName = this.removeHTML(this.deploymentName);
    let valid = await this.$validator.validateAll();

    // validate deployed quantity
    let validQty = true;
    for (const item of this.orderDetails) {
      if (!item.isMainItem && item.ISCONFIG) continue;
      if (
        parseInt(item.INCLUDE) < 0 ||
        parseInt(item.INCLUDE) > item.POPRODQUANTITY
      ) {
        item.error = "invalid quantity";
        validQty = false;
      }
    }
    if (!validQty) {
      this.$forceUpdate();
    }

    if (valid && validQty) {
      this.$validator.errors.clear();

      // check/remove HTML for custom field values
      for (const item of this.fullCustomData) {
        if (item.CUSTOMDTYPE == 1) {
          item.CUSTOMVALUE = this.removeHTML(item.CUSTOMVALUE);
        }
      }

      const productDetails = this.orderDetails
        .filter(item => item.isMainItem || (item.POINCLUDED && !item.ISCONFIG))
        .map(item => ({
          ...item,
          PRODUCTTYPE: item.ProductTypeSelected,
          TYPE: item.ProductTypeSelected
        }));
      const noSelectProductType = productDetails.find(
        item => item.ProductTypeSelected == 0
      );
      if (noSelectProductType) {
        notifier.alert("Please select product type for sku");
        return;
      }

      try {
        this.saving = true;

        // reset availabilityId
        const availabilitiesList = JSON.parse(
          JSON.stringify(this.availabilitiesList)
        );
        for (const item of availabilitiesList) {
          item.available.availabilityId = 0;
        }

        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "DeploymentAdder",
          enduserid: 0,
          action: "Add",
          deploymentName: this.deploymentName,
          aID: this.aID,
          customData: JSON.stringify(
            this.fullCustomData.filter(t => t.AID == 0 || t.AID == this.aID)
          ),
          productDetails,
          tieOrder: this.tieOrder,
          orderIds: this.selectedOrdersID,
          available: this.available,
          exceptions: this.exceptions,
          criteriaFilters: this.criteriaFilters,
          availabilitiesList,
          statusTypes: this.statusTypes.map(item => ({
            id: item.id,
            estFull: item.estFull
          }))
        });

        if (response.data.ERROR) {
          this.saving = "error";
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS == 1) {
          this.saving = false;
          this.$router.push({ name: "Deployments" });
          notifier.success(response.data.STATUSMESSAGE);
        } else {
          this.saving = "error";
          notifier.alert(response.data.STATUSMESSAGE);
        }
      } catch (err) {
        console.log(err);
        this.saving = "error";
      }
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  showCustomData() {
    this.customDataVisible = !this.customDataVisible;
  }

  // tieOrderChange() {
  //   this.tieOrder = !this.tieOrder;
  // }

  orderDeployedChange() {
    this.orderDetails = JSON.parse(JSON.stringify(this.orderDetailsBK));
    // this.orderDeployed = !this.orderDeployed;
    if (this.orderDeployed) {
      this.orderDetails.forEach(orderItem => {
        orderItem.INCLUDE = orderItem.POPRODQUANTITY;
      });
    }
  }

  item = {
    PRODUCTTYPE: 0
  };

  // Computed properties
  productTypeName(productType) {
    switch (productType) {
      case 1:
        return "Hardware";
      case 2:
        return "Services";
      default:
        return "Please Select Option";
    }
  }

  selectedProductType(item) {
    const productTypes = [
      { value: "0", text: "NA" },
      { value: "1", text: "Hardware" },
      { value: "2", text: "Services" }
    ];

    const matchingProductType = productTypes.find(
      option => option.text === item.CATTYPENOTE
    );

    return matchingProductType ? matchingProductType.value : 0;
  }

  deleteSelectedOrders(index) {
    this.$delete(this.selectedOrdersID, index);
    if (!this.selectedOrdersID.length) {
      this.customer = "";
      this.aID = 0;
    }
    this.getOrderDetails();
  }

  disableInclude(item) {
    item.INCLUDE = 0;
  }

  toggleProducts() {
    $(".productsDetails").fadeToggle();
  }

  updateAvailability(options) {
    this.available = options.available;
    this.exceptions = options.exceptions;
    this.criteriaFilters = options.criteriaFilters;
    this.availabilitiesList = options.availabilitiesList;
  }

  getExceptionDates(item) {
    const ret: any = [];
    if (item.start && moment(item.start).isValid()) {
      ret.push(moment(item.start).format("MM/DD/YYYY"));
    }
    if (item.end && moment(item.end).isValid()) {
      ret.push(moment(item.end).format("MM/DD/YYYY"));
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  getExceptionTimes(item: any) {
    const ret: any = [];
    const betweenTimes = item.betweenTimes;
    if (betweenTimes.start && betweenTimes.startPeriod) {
      ret.push(`${betweenTimes.start} ${betweenTimes.startPeriod}`);
    }
    if (betweenTimes.end && betweenTimes.endPeriod) {
      ret.push(`${betweenTimes.end} ${betweenTimes.endPeriod}`);
    }
    if (!ret.length) {
      return "Full Day";
    }

    return ret.length == 2 ? ret.join(" - ") : ret.length == 1 ? ret[0] : "";
  }

  hasAvailable(item) {
    let ret = false;
    ret =
      item.available.start ||
      item.available.daysOfWeek.length ||
      (item.available.duration.startTime && item.available.duration.endTime)
        ? true
        : false;
    return ret;
  }

  toggleCDOption(item, id, text) {
    // update item.selectedIds
    if (!item.selectedIds.includes(id)) {
      item.selectedIds.push(id);
    } else {
      item.selectedIds = item.selectedIds.filter(t => t != id);
    }
    item.CUSTOMVALUE = item.selectedIds.length
      ? JSON.stringify(item.selectedIds)
      : "";
  }
}
