import { render, staticRenderFns } from "./ProductCatalog.vue?vue&type=template&id=33583264&scoped=true"
import script from "./ProductCatalog.vue?vue&type=script&lang=tsx"
export * from "./ProductCatalog.vue?vue&type=script&lang=tsx"
import style0 from "./ProductCatalog.vue?vue&type=style&index=0&id=33583264&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33583264",
  null
  
)

export default component.exports