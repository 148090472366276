var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr>\n      <td width=\"50%\" style=\"padding-left: "
    + alias4(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":10,"column":43},"end":{"line":10,"column":56}}}) : helper)))
    + "px; font-style: "
    + alias4(((helper = (helper = helpers.FONTSTYLE || (depth0 != null ? depth0.FONTSTYLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FONTSTYLE","hash":{},"data":data,"loc":{"start":{"line":10,"column":72},"end":{"line":10,"column":85}}}) : helper)))
    + ";\">\n        <strong>"
    + ((stack1 = ((helper = (helper = helpers.QPRODDESC || (depth0 != null ? depth0.QPRODDESC : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODDESC","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":31}}}) : helper))) != null ? stack1 : "")
    + "</strong><br/>\n        "
    + ((stack1 = ((helper = (helper = helpers.QPRODSKU || (depth0 != null ? depth0.QPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODSKU","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\n      </td>\n      <td width=\"10%\" style=\"text-align: center;\">"
    + ((stack1 = ((helper = (helper = helpers.QPRODQUANTITY || (depth0 != null ? depth0.QPRODQUANTITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QPRODQUANTITY","hash":{},"data":data,"loc":{"start":{"line":14,"column":50},"end":{"line":14,"column":69}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + ((stack1 = ((helper = (helper = helpers.QCUSTOMERPRICEFORMATTED || (depth0 != null ? depth0.QCUSTOMERPRICEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"QCUSTOMERPRICEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":15,"column":49},"end":{"line":15,"column":78}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + ((stack1 = ((helper = (helper = helpers.CUSTOMERTOTALPRICEFORMATTED || (depth0 != null ? depth0.CUSTOMERTOTALPRICEFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CUSTOMERTOTALPRICEFORMATTED","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":82}}}) : helper))) != null ? stack1 : "")
    + "</td>\n    </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.DISPLAYONEXPORT : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          REFERENCING - BD# "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.SPECIALPRICINGID : stack1), depth0))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Estimated Tax:</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + container.escapeExpression(((helper = (helper = helpers.TOTALTAXFORMATTED || (depth0 != null ? depth0.TOTALTAXFORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TOTALTAXFORMATTED","hash":{},"data":data,"loc":{"start":{"line":34,"column":49},"end":{"line":34,"column":70}}}) : helper)))
    + "</td>\n    </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Estimated Shipping:</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + container.escapeExpression(((helper = (helper = helpers.TOTALSHIPPINGFORMATTED || (depth0 != null ? depth0.TOTALSHIPPINGFORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TOTALSHIPPINGFORMATTED","hash":{},"data":data,"loc":{"start":{"line":41,"column":49},"end":{"line":41,"column":75}}}) : helper)))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"50%\" style=\"border-bottom: 5px solid #b1b1b1;\">LINE ITEM / SKU</td>\n    <td width=\"10%\" style=\"text-align: center; border-bottom: 5px solid #b1b1b1;\">QTY</td>\n    <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">PRICE PER</td>\n    <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">TOTAL PRICE</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.QUOTEITEMS : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" style=\"border-top: 5px solid #b1b1b1;\" colspan=\"2\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.SPECIALPRICINGID : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "    </td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\">Total:</td>\n    <td width=\"20%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + alias4(((helper = (helper = helpers.TOTALCUSTOMERAMOUNTFORMATTED || (depth0 != null ? depth0.TOTALCUSTOMERAMOUNTFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTALCUSTOMERAMOUNTFORMATTED","hash":{},"data":data,"loc":{"start":{"line":28,"column":78},"end":{"line":28,"column":110}}}) : helper)))
    + "</td>\n  </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.TOTALTAX : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":2},"end":{"line":36,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.TOTALSHIPPING : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":2},"end":{"line":43,"column":9}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" colspan=\"2\">&nbsp;</td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1;\">Grand Total:</td>\n    <td width=\"20%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.GRANDCUSTOMERTOTALFORMATTED || (depth0 != null ? depth0.GRANDCUSTOMERTOTALFORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"GRANDCUSTOMERTOTALFORMATTED","hash":{},"data":data,"loc":{"start":{"line":47,"column":47},"end":{"line":47,"column":78}}}) : helper)))
    + "</td>\n  </tr>\n</table>";
},"useData":true});