




















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { PaycheckResp } from "../models/PaycheckResp";

interface Props {
  options: PaycheckResp.BusinessLine[];
  selectedBusinessLineIds: number[];
  isSingleSelect: boolean;
  disabled: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class BusinessLineMultiselect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: PaycheckResp.BusinessLine[];

  @Prop({ required: true })
  selectedBusinessLineIds!: number[];

  @Prop({ required: false, default: false })
  isSingleSelect?: boolean;

  @Prop({ required: false, default: false })
  disabled?: boolean;

  dropdownVisible = false
  search = ''
  selectedBusinessLine = 'Select Business Line'
  showOnlySelected: boolean = false

  get filteredOptions(): PaycheckResp.BusinessLine[] {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => option.BUSINESSLINENAME.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }

    if (this.showOnlySelected) {
      options = options.filter((o) => this.selectedBusinessLineIds.indexOf(o.ID) !== -1)
    }

    return options
  }

  toggleDropdown() {
    if(!this.disabled) {
      this.dropdownVisible = !this.dropdownVisible
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(businessLineId: number): boolean {
    const checked = !!this.selectedBusinessLineIds.find((id) => id === businessLineId)
    return checked
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  SelectBusinessLine(e){
    this.selectedBusinessLine = e.BUSINESSLINENAME;
    this.$emit('check', e.ID, e.BUSINESSLINENAME)
    this.dropdownVisible = false
  }
}
