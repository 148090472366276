



























































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier, wait } from "../models/common";
import axios from "axios";
import PageTitle from '../components/pageTitle.vue';
// import { validateFn } from "../helpers";

@Component({
  inheritAttrs: false,
  components: {
    PageTitle
  }
})
export default class HardwareDetails extends TSXComponent<void> {
  productDetails: {
    [key: string]: {
      detail?: string;
      description?: string;
    };
  } = {};
  currentRole = sessionStorage.getItem("userRole");
  removed: string[] = [];
  hardwareImages: string[] = [];
  saving = false;
  loading = false;
  items: {
    HARDWAREIMAGES?: string;
    HARDWAREID?: number;
  } = {};
  details = [];
  hardwareDetails: any = {};

  async created() {
    // var dataObj = {
    //   controller: "Hardware",
    //   Content: "Detailed",
    //   ObjID: this.$route.params.id
    // };

    // if (parseInt(this.$route.params.id) != 0) {
    //   dataObj["FunctionName"] = "View";
    //   var container = this;
    //   var hardwareViewData = getRouteData(dataObj);
    //   hardwareViewData
    //     .then(function(response, statusText, jqXHR) {
    //       try {
    //         if (response.data.STATUS && response.data.STATUSMESSAGE) {
    //           container.items = response.data.HARDWAREDETAILS || [];
    //           container.loading = false;
    //           container.productDetails =
    //             response.data.HARDWAREPRODUCTDETAILS || [];
    //           var images = container.items.HARDWAREIMAGES
    //             ? container.items.HARDWAREIMAGES.split(",")
    //             : [];
    //           for (var image in images) {
    //             images[image] =
    //               dataURL +
    //               "Inbound/apiFiles/images/" +
    //               container.items.HARDWAREID +
    //               "/" +
    //               images[image];
    //           }
    //           container.hardwareImages =
    //             container.items.HARDWAREIMAGES != "" ? images : [];
    //           container.details = response.data;
    //         } else if (!response.data.STATUS && response.data.STATUSMESSAGE) {
    //           notifier.warning(response.data.STATUSMESSAGE);
    //         } else if (response.data.ERROR) {
    //           console.log(response.data.ERROR);
    //         }
    //       } catch (e) {
    //         //handle error
    //         console.log("hardware.js : grabHardwareData error : ", e);
    //       }
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //     });
    // }

    const hardwareId = parseInt(this.$route.params.id) || 0;
    if (hardwareId) {
      try {
        this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Hardware",
          FunctionName: "View",
          Content: "Detailed",
          ObjID: hardwareId
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }

        if (response.data.STATUS !== 1) {
          const message = response.data.STATUSMESSAGE || "";
          const errorCode = response.data.errorCode || "";
          if (message) {
            notifier.alert(message);
          }
          if (errorCode == "not_found") {
            this.$router.replace({
              name: "Hardware"
            });
            return;
          }
        }

        if (response.data.STATUS == 1) {
          this.items = response.data.HARDWAREDETAILS || [];
          this.productDetails = response.data.HARDWAREPRODUCTDETAILS || [];
          var images = this.items.HARDWAREIMAGES
            ? this.items.HARDWAREIMAGES.split(",")
            : [];
          for (var image in images) {
            images[image] =
              dataURL +
              "Inbound/apiFiles/images/" +
              this.items.HARDWAREID +
              "/" +
              images[image];
          }
          this.hardwareImages = this.items.HARDWAREIMAGES != "" ? images : [];
          this.details = response.data;

          this.hardwareDetails = response.data.hardware || null;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
  listPageRedirection() {
    this.$router.push({ name: "Hardware" });
  }
  pdfDownLoadFn(id) {
    var exportObj = {
      controller: "Hardware",
      FunctionName: "ExportView",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      Content: "Detailed",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "PDF";
    exportObj["ObjID"] = id;
    this.downloadProcess(exportObj);
  }

  printFn(id) {
    var exportObj = {
      controller: "Hardware",
      FunctionName: "ExportView",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      Content: "Detailed",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "PDF";
    exportObj["Print"] = "Yes";
    exportObj["serviceID"] = id;
    printProcess(exportObj);
  }

  downloadProcess(data) {
    var downLoadRes = getRouteData(data);
    downLoadRes.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS) {
          var link = document.createElement("a");
          link.download = "Quotes";
          link.href = response.data.S3URL;
          link.click();
        } else if (response.data.ERROR) {
          console.log(response.data.ERROR);
        }
      } catch (e) {
        //handle error
        console.log("hardware.js : csvDownLoadFn error : ", e);
      }
    });
  }
  // validateFn;
}
