



































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import UserSuggestInput from '../components/UserSuggestInput.vue'
import axios from "axios";
import { notifier } from "../models/common";
import BusinessLineToolTip from "../components/BusinessLineToolTip.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

interface Props {
  title: string
  selectedIDs: string[],
  selectedAll: boolean,
  totalAll: number
}

interface Events {
  onClose: void
  onSave: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    UserSuggestInput,
    BusinessLineToolTip,
    ConfirmRemoveItemModal
  }
})
export default class UpdateItemStatusModal extends TSXComponent<
  Props,
  Events
> {
  @Prop({required: true, default: ""})
  title!: string

  @Prop({required: true, default: 0})
  currentProfit!: number

  @Prop({ required: true })
  list!: any;

  @Prop({ required: false, default: 0 })
  aID!: number;

  @Prop({ required: false, default: "" })
  invoiceId!: string;

  @Prop({ required: false, default: 0 })
  assignedPercent!: number;

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: false, default: false })
  customProfit!: boolean;

  @Prop({ required: true })
  businessLineSwitch!: boolean;

  @Prop({ required: false, default: [] })
  remainingBusinessLineComm!: any;

  saving = false
  userID = 0;
  userName = "";
  userType = 1;
  commAvail = 0;
  remainingComm = 0;
  totalAllocation = 0;
  isDelete = false;
  calculatingCommisions = false;
  businessLineToolTip = false;
  businessLineOptions: any = [];
  businessLineId = 0;
  businessLineName = "";
  confirmBLineToggle = false;
  bLineLoading = false;
  $parent: any;

  $refs!: {
    modal: HTMLDivElement
  }

  created() {
    this.remainingComm = this.remainingBusinessLineComm.length ? this.remainingBusinessLineComm[0].REMAININGCOMM : 0;
    this.commAvail = this.remainingComm;

    this.getBuinessOptions();
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  save() {
    this.saving = true
    this.$emit('save', this.currentProfit)
  }

  checkIfDisabled() {
    if(this.$validator.errors.items.length || this.saving) {
      return true;
    }
    
    return false;
  }

  async updateCustomer(data:any = {}) {
    if (Object.keys(data).length) {
      this.userName = data.account.UFNAME + ' ' + data.account.ULNAME;
      this.userID = data.account.USERID;
      this.userType = (typeof data.account.TYPE != "undefined" ? data.account.TYPE : 1);
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller         : "Invoices",
      FunctionName       : "InvoiceCommission",
      action             : "checkUserAvailable",
      userId             : this.userID,
      invoiceId          : this.invoiceId,
      businessLineSwitch : this.businessLineSwitch,
      businessLineId     : this.businessLineId
    });

    if(response.data.STATUS) {
      this.remainingComm = response.data.REMAININGCOMM;
      this.commAvail = this.remainingComm;
    }
  }

  customChange(e){
    if(e == null){
      this.userID = 0;
    }
  }

  async addCommission() {
    var result = true;
    var realCommAvail = this.commAvail;
    if(this.userID == 0) {
      result = false;
    }

    if(isNaN(this.remainingComm) || this.remainingComm <= 0 || this.remainingComm > realCommAvail || this.remainingComm <= 0.004) {
      if(this.userID > 0) {
        $(".quick-edit-comm span.right").addClass("custom-error");
      }
      result = false;
    }
    const responsePrecent = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Invoices",
        FunctionName: "InvoiceCommission",
        invoiceId: this.$route.params.id
      });
    var balanComm = 100 - responsePrecent.data.TOTALPERCENT;
    var commissionwarn = false;
    if(this.list.length != responsePrecent.data.ALLOCATIONARR.length){
      commissionwarn = true;
    }
    if(this.remainingComm > this.commAvail){
      result = false;
      $(".quick-edit-comm span.right").addClass("custom-error")
      commissionwarn = true;
    }

    this.businessLineOptions.forEach((bLine) => {
      if (bLine.ID == this.businessLineId) this.businessLineName = bLine.TEXT;
    } );
   
    if (result) {
      this.calculatingCommisions = true
      try {
        const commissionIndex = this.list.findIndex((commission: any) => commission.USERID === this.userID && commission.BUSINESSLINEID == this.businessLineId);
        if (commissionIndex > -1) {
          this.list.splice(commissionIndex, 1);
        }

        const commDetails = {
          "USERID"            : this.userID,
          "USERNAME"          : this.userName,
          "PERCENTAGE"        : this.remainingComm,
          "BUSINESSLINEID"    : this.businessLineId,
          "BUSINESSLINENAME"  : this.businessLineName
        }
        this.list.push(commDetails);
        const response  = await axios.post(dataURL + "?ReturnType=JSON", {
          "controller"        : "Invoices",
          "FunctionName"      : "InvoiceCommission",
          "invoiceId"         : this.invoiceId,
          "newCommArr"        : this.list,
          "aID"               : this.aID,
          "action"            : "saveOverRide"
        });
        if(response.data.STATUS == 1) {
          //reset
          this.userName = "";
          this.userID = 0;
          this.updateAllocationList();
        }
      } catch (err) {
        // console.log(err.message);
      } finally {
        this.calculatingCommisions = false;
      }
    }
    if(commissionwarn){
      notifier.warning(`The Commission Percentage field must be less than ${this.commAvail} for ${this.businessLineName}`);
    }
  }
  async removeCommission(item) {
    this.calculatingCommisions = true;
    try {
      const index = this.list.findIndex(com => com.USERID === item.USERID && com.BUSINESSLINEID == item.BUSINESSLINEID);
      this.list.splice(index, 1);
      const response  = await axios.post(dataURL + "?ReturnType=JSON", {
        "controller"        : "Invoices",
        "FunctionName"      : "InvoiceCommission",
        "invoiceId"         : this.invoiceId,
        "newCommArr"        : this.list,
        "aID"               : this.aID,
        "action"            : "saveOverRide"
      });
      if(response.data.STATUS == 1) {
        this.updateAllocationList();
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.calculatingCommisions = false;
    }
  }

  async getBuinessOptions() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    this.businessLineOptions = [];
    if(this.businessLineSwitch) {
      (response.data.businessLineOptions || []).map(item => (
        this.businessLineOptions.push({
          ID: item.ID,
          TEXT: item.BUSINESSLINENAME,
          data: item
        })
      ));
      this.businessLineId = 1;
    } else {
      this.businessLineOptions.push({
        ID: 0,
        TEXT: "ALL"
      });
      this.businessLineId = 0;
    }
  }

  updatebusinessID(id) {
    this.businessLineId = id;
    this.updateCustomer();
  }

  async autoBusinessLineSwitch() {
    this.bLineLoading = true;
    this.businessLineSwitch = !this.businessLineSwitch;

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller         : "Invoices",
      FunctionName       : "InvoiceCommission",
      action             : "businessLine_update",
      invoiceId          : this.invoiceId,
      businessLineSwitch : this.businessLineSwitch
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    
    if(response.data.STATUS == 1) {
      this.getBuinessOptions();
      this.updateAllocationList();
      this.confirmBLineToggle = false;
      this.bLineLoading = false;
      this.$parent.businessLineSwitch = this.businessLineSwitch;
    }
  }

  async updateAllocationList() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      Controller: "Invoices",
      FunctionName: "InvoiceCommission",
      invoiceId: this.$route.params.id
    });

    if (response.data.STATUS == 1) {
      this.list = typeof response.data.ALLOCATIONARR != "undefined" ? response.data.ALLOCATIONARR : [];
      this.remainingBusinessLineComm = response.data.REMAININGBUSINESSLINECOMM;
      this.remainingComm = this.remainingBusinessLineComm.length ? this.remainingBusinessLineComm[0].REMAININGCOMM : 0;
      this.commAvail = this.remainingComm;
    }
  }
}
