































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {}
})
export default class ItemStatus extends TSXComponent<void> {
  @Prop({ required: true })
  status!: number;

  @Prop({ required: true, default: "" })
  statusText!: string;

  @Prop({ required: true, default: [] })
  options!: any;

  @Prop({ required: true, default: true })
  allowChangeStatus!: boolean;

  popoverVisible = false;
  optionsVisible = false;

  hideTooltip() {
    this.optionsVisible = false;
  }

  mouseover() {
    if (this.optionsVisible) {
      return;
    }
    this.popoverVisible = true;
  }

  mouseout() {
    this.popoverVisible = false;
  }

  click() {
    if (!this.allowChangeStatus) {
      return;
    }

    this.popoverVisible = false;
    this.optionsVisible = !this.optionsVisible;
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false;
    }
  }

  itemClick(status) {
    this.$emit("changeStatus", status);
    this.optionsVisible = false;
  }

  get currentStatusColor() {
    let ret = "";
    if (this.status) {
      const inList = this.options.find(item => item.ID == this.status);
      if (inList) {
        ret = inList.color || "";
      }
    }

    return ret;
  }
}
