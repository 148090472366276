














import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";
import directives from "../helpers/directives";

import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";

interface Props {
  defaultText?: string
  value: string
}

interface Events {}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
  directives
})
export default class poIDQuoteIDAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, type: String, default: 'all' })
  object?: string

  @Prop({ required: false, type: String, default: 'Orders and Quotes' })
  field?: string

  @Prop({ required: false, type: String, default: [] })
  selectedOrderAndQuoteIDs?: any[]

  @Prop({ required: false, default : false })
  show?: boolean

  @Prop({ required: false, default : 0 })
  aID?: number

  @Prop({ required: false, default: "Order or Quote Number"})
  placeholderText?: string;

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = []
  searchData: any = []

  createOptions(poAndQuotes: any) {
    const options = poAndQuotes.map(
      (item): Option => {
        return {
          value: item.ID,
          text: `${item.TYPEASTEXT}: #${item.ID} ${this.getPO(item.PO)} Description: ${item.DESCRIPTION}`
        };
      }
    )

    this.options = options
    this.searchData = poAndQuotes

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!this.aID) {
      return {
        options: []
      };
    }
    search = search.trim();
    const orderIDs: any = [];
    const quoteIDs: any = [];
    if (this.selectedOrderAndQuoteIDs) {
      this.selectedOrderAndQuoteIDs.map((item: any) => {
        if (item.TYPE === 1) {
          orderIDs.push(item.ID);
        } else if (item.TYPE === 2) {
          quoteIDs.push(item.ID);
        }
      });
    }
    const excludedOrderIDs = orderIDs.join(',');
    const excludedQuoteIDs = quoteIDs.join(',');
    if (!search) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        "FunctionName": "PurchasesQuotes",
        "controller": "Opportunities",
        "queryType": "top5UsedPurchasesQuotes",
        "object": this.object,
        "excludedOrderIDs": excludedOrderIDs,
        "excludedQuoteIDs": excludedQuoteIDs,
        "aID": this.aID || undefined,
      });
      if (response.data.ERROR || !response.data.POANDQUOTEIDS) {
        return {
          options: []
        };
      }

      return this.createOptions(response.data.POANDQUOTEIDS)
    }

    const exportObj = {
      FunctionName: "PurchasesQuotes",
      controller: "Opportunities",
      getAll: 1,
      object: this.object,
      excludedOrderIDs: excludedOrderIDs,
      excludedQuoteIDs: excludedQuoteIDs,
      aID: this.aID || undefined,
      search
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    if (response.data.ERROR || !response.data.POANDQUOTEIDS) {
      return {
        options: []
      };
    }

    return this.createOptions(response.data.POANDQUOTEIDS)
  };

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      //console.log('this.options', this.options)
      //console.warn(`no option found for value[${value}]`)
      return
    }
    const poAndQuote = this.searchData.find((item) => item.ID.toString() === value.toString())
    if (!poAndQuote) {
      // console.warn(`no Order and Quote found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      poAndQuote,
    })
  }

  getPO(po) {
    if (po.trim().length) {
      return `PO: ${po}`
    } else {
      return ''
    }
  }
}
