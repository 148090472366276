


































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import {dollarFormat} from "../helpers/ApiHelper";

@Component({
  methods: {dollarFormat},
  inheritAttrs: true,
  components: {}
})

export default class ContractPriceTooltip extends TSXComponent<void> {
    @Prop({ required: false, default: [] })
    data!: any;

    @Prop({ required: false, default: 0 })
    productPrice!: number;

    clickOutside() {
        this.$emit("close");
    }

    updateContractPrice(data) {
        this.$emit("updateContractPrice", {
            contractPrice: data.CONTRACTPRICE
        })
    }
}
