

























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../../models/common";
import LaddaButton from "@/components/LaddaButton.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class ReimursementsModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: []})
  reimursementData!: any;

  @Prop({ required: false, default: {}})
  data!: any;

  @Prop({ required: false, default: 1})
  selectedReimursementMonth!: number;

  @Prop({ required: false, default: [] })
  lockedMonthData!: any;

  @Prop({ required: false, default: "" })
  selectedReimursementMonthAsString!: string;

  @Prop({ required: false, default: false })
  showReimursementMonthDropdown!: boolean;

  $validator: any;
  reimursement_month: number = 1;
  reimursement_amount: number = 0;
  reimursement_notes: string = "";
  saving: boolean = false;
  totalReimbursements: number = 0;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  created() {
    if (this.selectedReimursementMonth !== 1) {
      this.reimursement_month = this.selectedReimursementMonth;
    } else if (this.selectedReimursementMonth === 1 && this.lockedMonthData.length) {
      let current_active_month = this.reimursementData.find((item: any) => !this.lockedMonthData.includes(item.MONTH));
      if (current_active_month) {
        this.reimursement_month = current_active_month.MONTH || 1;
      }
    }
    this.changeMonth();
    this.getTotalReimbursement();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async save() {
    const result = await this.$validator.validateAll();

    if (!result) {
      return;
    }

    this.reimursement_amount = this.reimursement_amount || 0;

    let findReimursement = this.reimursementData.find((item: any) => item.MONTH == this.reimursement_month);
    if (findReimursement) {
      findReimursement.AMOUNT = this.reimursement_amount;
      findReimursement.AMOUNT_FORMATTED = `$${this.formatter(this.reimursement_amount)}`;
      findReimursement.NOTES = this.reimursement_notes;
    } else {
      let tempStruct = {};
      tempStruct["MONTH"] = this.reimursement_month;
      tempStruct["AMOUNT"] = this.reimursement_amount;
      tempStruct["AMOUNT_FORMATTED"] = `$${this.formatter(this.reimursement_amount)}`;
      tempStruct["NOTES"] = this.reimursement_notes;
      this.reimursementData.push(tempStruct);
    }

    this.saving = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanAdd",
      user_account: this.data.EMPLOYEEID || 0,
      action: "updateReimursement",
      reimursementData: this.reimursementData,
      compUUID: this.data.COMPUUID || '',
      totalReimbursements: this.totalReimbursements
    });
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.$emit("reload");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.saving = false;
  }

  formatter(amount) {
    // Use Intl.NumberFormat to format the total as a currency string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }

  changeMonth() {
    let findReimursement = this.reimursementData.find((item: any) => item.MONTH == this.reimursement_month);
    if (findReimursement) {
      this.reimursement_amount = findReimursement.AMOUNT;
      this.reimursement_notes = findReimursement.NOTES || "";
    }
  }

  getTotalReimbursement() {
    let tempTotal = 0;
    if (!isNaN(this.reimursement_amount)) {
      this.reimursement_amount = Number(this.reimursement_amount) || 0;
      this.reimursementData.map((item: any) => {
        if (item.MONTH != this.reimursement_month) {
          tempTotal += Number(item.AMOUNT);
        } else if (!isNaN(this.reimursement_amount)) {
          tempTotal += this.reimursement_amount;
        }
      });
      this.totalReimbursements = tempTotal;
    }
  }

}
