













































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";

declare const $: any;
interface Props {
  currentItem: any;
}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader
  }
})
export default class ProductCatTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  currentItem!: any;

  @Prop({ required: false, default: false })
  isquote!: boolean;

  @Prop({ required: false, default: false })
  isinvoice!: boolean;

  @Prop({ required: false, default: -1 })
  currentIndex?: number;

  @Prop({ required: false, default: 0 })
  subCurrentIndex?: number;

  @Prop({ required: false, default: false })
  hideConfigToggle!: boolean;

  @Prop({ required: false, default: {} })
  configCategory!: any;

  @Prop({ required: false, default: "" })
  from!: string;

  $parent: any;
  loading = false;
  catList: object[] = [];
  fullCatList: object[] = [];
  search = "";
  isConfig = false;

  clickOutside() {
    this.$emit("close");
  }

  async created() {
    // check if this is config item
    // const configCategoryId = this.configCategory.CATEGORYID || 0;
    // if(configCategoryId > 0 && configCategoryId == (this.currentItem.ItemCategory || 0)) {
    //   this.isConfig = true;
    // }
    this.isConfig = !!(this.currentItem.ISCONFIG || 0);

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "Category",
        ignoreConfig: true
      });

      if (response.data.STATUS == 1) {
        this.fullCatList = response.data.CATEGORY;
        this.catList = response.data.CATEGORY;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  selectCategory(catItem) {
    if (this.isConfig) return;

    this.currentItem.ItemCategory = catItem.CATEGORYID;
    this.currentItem.ItemCategoryName = catItem.CATEGORYNAME;
    this.$parent.$forceUpdate();
    this.$emit("updateItemCategory", {
      index: this.currentIndex,
      subIndex: this.subCurrentIndex,
      ItemCategory: this.currentItem.ItemCategory,
      ItemCategoryName: this.currentItem.ItemCategoryName,
      selectedItem: this.currentItem
    });
    this.$emit("close");
  }

  async categoryLookup() {
    let options = this.fullCatList;

    if (this.search) {
      options = options.filter(
        (option: any) =>
          `${option.CATEGORYNAME}`
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
      );
    }

    this.catList = options;
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  /* REMOVING ABILITY TO CREATE A CATEGORY BUT LEAVING IN CODE FOR FUTURE REFERENCE */
  /*   async createCategory() {
    //remove HTML
    this.search = this.removeHTML(this.search);

    if (this.search != "" && this.search.length >= 2) {
      try {
        this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Helpers",
          FunctionName: "Category",
          category: this.search,
          action: "add"
        });

        if (response.data.STATUS == 1) {
          if (response.data.newCatID > 0) {
            this.currentItem.ItemCategory = response.data.newCatID;
            this.currentItem.ItemCategoryName = this.search;
            this.$parent.$forceUpdate();
            this.$emit("close");
          } else {
            this.categoryLookup();
          }
        }
      } catch (err) {
        // console.log(err.message);
      } finally {
        this.loading = false;
      }
    }
  } */

  async configToggleChange() {
    const configCategoryId = this.configCategory.CATEGORYID || 0;
    this.currentItem.ISCONFIG = this.isConfig ? 1 : 0;
    if (this.isConfig) {
      // turn on "configuration"
      this.currentItem.ItemCategory = this.configCategory.CATEGORYID || 0;
      this.currentItem.ItemCategoryName =
        this.configCategory.CATEGORYNAME || "";
    } else {
      // turn off "configuration"
      if (this.currentItem.ItemCategory == configCategoryId) {
        this.currentItem.ItemCategory = 0;
        this.currentItem.ItemCategoryName = "";
      }
    }

    this.$emit("configToggleChange");
    this.$parent.$forceUpdate();

    this.$emit("updateItemCategory", {
      index: this.currentIndex,
      subIndex: this.subCurrentIndex,
      ItemCategory: this.currentItem.ItemCategory,
      ItemCategoryName: this.currentItem.ItemCategoryName,
      selectedItem: this.currentItem,
      configToggle: true
    });
  }
}
