





























































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

import Pagination from "../components/Pagination.vue";
// import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue"
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import InfoTooltip from "../components/InfoTooltip.vue";

import { OrderAPIRepo, OrderStatus } from "../repos/OrderAPIRepo";
import { notifier } from "../models/common";
import { Order } from "../models/Order";
// import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import { colsResizeable, VarSource } from "@/helpers/ApiHelper";
declare function require(params: any): any;
const orderRepo = new OrderAPIRepo();
declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    FilterCurrencyBox,
    // ExportModal,
    CustomerSearchTooltip,
    InfoTooltip
  }
})
export default class HPDirectOSSAuto extends TSXComponent<void> {
  selectedID: (number | undefined)[] = [];
  currentRole = sessionStorage.getItem("userRole");
  ISSEARCH = 0;
  TOTAL = 0;
  viewType = "CurrentUsers";
  showFilter = 0;
  loading = false;
  ordersData: Order.Root | any = {};
  filters: any = {
    searchAll: "",
    searchId: "",
    searchName: "",
    searchAccountName: "",
    sProfitMin: undefined,
    sProfitMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    sDate: undefined,
    eDate: undefined,
    sStatus: [1, 2, 3, 4, 5, 7, 10, 13, 14, 0],
    resellerCheckList: [],
    AccountTagID: [],
    selectedAccs: [],
    order: "",
    sSource: [VarSource.HPDirectOSSAuto],
    gTagIDs: [],
    groupIDs: [],
    statusImportId: 0
  };
  totalPages: number = 0;
  sort: any = {
    field: null,
    direction: {
      poId: 1,
      orderName: 1,
      customerName: 1,
      reseller: 1,
      source: 1,
      status: 1,
      dateTime: 1,
      details: 1
    }
  };
  directionField = "";
  pageNumber =
    sessionStorage.pageDetails &&
    JSON.parse(sessionStorage.pageDetails)["Order"]
      ? JSON.parse(sessionStorage.pageDetails)["Order"]
      : 1;
  items: Order.Order[] = [];

  confirmRemoveModalVisible = false;
  // exportModalVisible = false;
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  resellers: { ACCOUNTID?: number }[] = [];
  selectedAll = false;
  curYear = new Date().getFullYear();
  lYear = new Date().getFullYear() - 1;
  excludedIDs: number[] = [];
  selectedView = false;
  details: any = [];
  commInfoVisibleIndex = -1;
  accountTags: any = [];
  searchTagsTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  searchAccTxt = "";
  filterBtn = "currentList";
  orderAllStatus: number[] = [1, 2, 3, 4, 5, 7, 10, 13, 14, 0];
  searchFilters: any = {
    searchAll: "",
    searchId: "",
    searchName: "",
    searchAccountName: "",
    sProfitMin: undefined,
    sProfitMax: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    sDate: undefined,
    eDate: undefined,
    sStatus: [1, 2, 3, 4, 5, 7, 10, 13, 14, 0],
    resellerCheckList: [],
    AccountTagID: [],
    selectedAccs: [],
    order: "",
    sSource: [VarSource.HPDirectOSSAuto],
    gTagIDs: [],
    groupIDs: []
  };

  s3URL = "";

  get paginationVisible(): boolean {
    return this.totalPages !== 1;
  }

  async created() {
    if (typeof this.$route.query.searchAll === "string") {
      this.filters.searchAll = this.$route.query.searchAll;
    }
    if (typeof this.$route.query.searchId === "string") {
      this.filters.searchId = this.searchFilters.searchId = this.$route.query.searchId;
    }
    if (typeof this.$route.query.searchName === "string") {
      this.filters.searchName = this.searchFilters.searchName = this.$route.query.searchName;
    }
    if (typeof this.$route.query.searchAccountName === "string") {
      this.filters.searchAccountName = this.searchFilters.searchAccountName = this.$route.query.searchAccountName;
    }
    if (typeof this.$route.query.sDate === "string") {
      this.filters.sDate = this.$route.query.sDate;
    }
    if (typeof this.$route.query.eDate === "string") {
      this.filters.eDate = this.$route.query.eDate;
    }

    if (typeof this.$route.query.sStatus === "string") {
      this.filters.sStatus = [parseInt(this.$route.query.sStatus)];
    } else if (Array.isArray(this.$route.query.sStatus)) {
      this.filters.sStatus = this.$route.query.sStatus.map(s =>
        parseInt(s || "0")
      );
    }

    this.accsLoad();
    this.getStatusImportLog();
    await this.fetchData(false, true);
    colsResizeable({ ms: 500 });
  }

  get query() {
    const query = Object.entries(this.filters).reduce((a, [k, v]) => {
      if (Array.isArray(v)) {
        return v.length === 0 ? a : { ...a, [k]: v };
      }

      return v == null || v === "" ? a : { ...a, [k]: v };
    }, {});
    return query;
  }

  async fetchData(
    isstatusfilter: boolean = false,
    initialLoad: boolean = false
  ) {
    this.loading = true;
    try {
      this.filters.statusImportId = parseInt(this.$route.params.id) || 0;
      this.$router.replace({ query: this.query }).catch(tmp => {});
      const ordersData = await orderRepo.findStatus(
        this.filters,
        this.sort,
        this.pageNumber,
        this.viewType,
        this.selectedView,
        isstatusfilter,
        this.filterBtn,
        initialLoad,
        this.orderAllStatus
      );
      if (ordersData.STATUS == 1) {
        this.ordersData = ordersData;
        this.customerTooltipVisibleIndex = -1;
        this.details = ordersData;
        this.ISSEARCH = ordersData.ISSEARCH;
        this.TOTAL = ordersData.TOTAL;
        var date = new Date().getFullYear();
        this.curYear = date;
        this.lYear = date - 1;

        if (
          this.filters.eDate != undefined ||
          this.filters.resellerCheckList.length != 0 ||
          this.filters.sDate != undefined ||
          this.filters.sStatus.length != 0 ||
          this.filters.sProfitMax != undefined ||
          this.filters.sProfitMin != undefined ||
          this.filters.sTotalMax != undefined ||
          this.filters.sTotalMin != undefined ||
          this.filters.searchAccountName != "" ||
          this.filters.searchAll != "" ||
          this.filters.searchId != "" ||
          this.filters.searchName != ""
        ) {
          this.showFilter = 1;
        } else {
          this.showFilter = 0;
        }
        this.items = ordersData.ORDERS;
        if (this.searchTagsTxt == "") {
          this.accountTags = this.details.accountTags;
        }
      }
    } catch (err) {
      console.log(err);
      // notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    this.filters.directionField = directionField;
    await this.fetchData();
  }

  selectallStatus() {
    this.selectedID = this.ordersData.orderIds;
  }
  UnselectallStatus() {
    this.selectedID = [];
  }

  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }
  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }

  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  async searchChange(searchData) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.searchAll = searchData;
    await this.fetchData();
  }

  async checkStatusList(status: number) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.sStatus.findIndex(s => s === status);
    if (i === -1) {
      this.filters.sStatus.push(status);
    } else {
      this.filters.sStatus.splice(i, 1);
    }

    await this.fetchData(true);
  }

  async checkAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sStatus = [
      OrderStatus.Placed,
      OrderStatus.Received,
      OrderStatus.Shipped,
      OrderStatus.Delivered,
      OrderStatus.Paid,
      OrderStatus.Cancelled,
      OrderStatus.PartialPay,
      OrderStatus.Complete,
      OrderStatus.CancelledComplete,
      0
    ];
    await this.fetchData();
  }

  async uncheckAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sStatus = [-1];
    await this.fetchData();
  }

  async resetStatusList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sStatus = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllStatusList();
    await this.fetchData();
  }

  async resetSearchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchId = this.searchFilters.searchId = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchName = this.searchFilters.searchName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByAccountName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sProfitMin = undefined;
    this.filters.sProfitMax = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = undefined;
    this.filters.sTotalMax = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.filters.sDate == undefined || this.filters.sDate == "") &&
      (this.filters.eDate == "" || this.filters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = undefined;
      this.filters.eDate = undefined;
      $("#sDate").val("");
      $("#eDate").val("");
      await this.fetchData();
    }
  }

  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchId == "") {
      return;
    } else {
      this.filters.searchId = this.searchFilters.searchId;
      await this.fetchData();
    }
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchName == "") {
      return;
    } else {
      this.filters.searchName = this.searchFilters.searchName;
      await this.fetchData();
    }
  }

  async searchByAccountName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.searchAccountName == "") {
      return;
    } else {
      this.filters.searchAccountName = this.searchFilters.searchAccountName;
      await this.fetchData();
    }
  }

  async searchByProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async searchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.filters.sDate == undefined || this.filters.sDate == "") &&
      (this.filters.eDate == "" || this.filters.eDate == undefined)
    ) {
      return;
    } else if (
      !document!.querySelector<HTMLInputElement>("#sDate")!.validity!.valid ||
      !document!.querySelector<HTMLInputElement>("#eDate")!.validity!.valid
    ) {
      var errDate = "";
      if (
        !document!.querySelector<HTMLInputElement>("#sDate")!.validity!.valid
      ) {
        errDate += "start";
      }
      if (
        !document!.querySelector<HTMLInputElement>("#eDate")!.validity!.valid
      ) {
        if (errDate.length) {
          errDate += " & ";
        }
        errDate += " end ";
      }
      notifier.alert("Please Fill Valid " + errDate + " Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      await this.fetchData();
    }
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if (
      sessionStorage.pageDetails != undefined &&
      JSON.parse(sessionStorage.pageDetails)["Order"]
    ) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["Order"] = this.pageNumber;
      sessionStorage.setItem("pageDetails", JSON.stringify(oldData));
    }

    if (this.pageNumber <= this.ordersData.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async filterByReseller() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async checkAllResellerList() {
    var comInstance = this;
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.resellerCheckList = [];
    this.resellers.forEach(function(val) {
      comInstance.filters.resellerCheckList.push(val.ACCOUNTID);
    });
    await this.fetchData();
  }

  async resetResellerList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.resellerCheckList = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll && total) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }
  checkExcludedIDs(items, key) {
    if (this.selectedAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.TOTAL) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }

  //filter by Tags
  async filterByTag() {
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllTag() {
    var $this = this;
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == "undefined") {
        if (typeof val.ISGLOBALTAG == "undefined") {
          if ($this.filters.AccountTagID != undefined) {
            $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
          }
        } else {
          $this.filters.gTagIDs.push(val.ACCOUNTTAGID);
        }
      }
    });
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    var $this = this;
    this.filters.groupIDs = [];
    this.accountTags.forEach(function(val: any) {
      if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != "undefined") {
        $this.filters.groupIDs.push(val.ACCOUNTTAGID);
      }
    });
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag() {
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    this.pageNumber = 1;
    await this.fetchData();
  }
  filterTagsInBox() {
    let options = this.details["accountTags"];

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }

  toggleActive(div) {
    this.isActive = div;
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc() {
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllAcc() {
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc() {
    this.filters.selectedAccs = [];
    this.pageNumber = 1;
    await this.fetchData();
  }
  async accsLoad() {
    if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          view: this.viewType,
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getStatusImportLog() {
    const statusImportId = parseInt(this.$route.params.id) || 0;
    if (statusImportId > 0) {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          Controller: "Orders",
          FunctionName: "GetStatusImportLog",
          statusImportId
        });
        if (response.data.STATUS) {
          this.s3URL = response.data.statusImport.S3CSVURL || "";
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
}
