















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

import LaddaButton from "../components/LaddaButton.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class InvoiceStatementsModal extends TSXComponent<void> {

  @Prop({ required: true, default: '' })
  title?: String;

  @Prop({ required: true, default: [] })
  invoices?: String[];

  @Prop({ required: true, default: [] })
  statements?: String[];

  loading = false;

  $refs!: {
    modal: HTMLDivElement;
  };

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }
}
