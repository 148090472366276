













import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";

import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import { AccountList } from "../models/AccountList";

interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    endUsers: AccountList.EndUsers
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
})
export default class EndUserAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  disabled?: boolean
  
  @Prop({ required: false, type: Number })
  customerId?: number

  @Prop({ required: false, type: String })
  fields?: string

  @Prop({ required: false, type: String })
  fieldPass?: string

  @Prop({ required: false, type: String })
  placeholderPass?: string

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string
  $parent: any;

  options: Option[] = []
  searchData: AccountList.EndUsers[] = []

  createOptions(endUsers: AccountList.EndUsers[]) {
    const options = endUsers.map(
      (item): Option => {
        return {
          value: item.ACCOUNTCONTACTID.toString(),
          text: item.ENDUSERDETAILS
        };
      }
    )

    this.options = options
    this.searchData = endUsers

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!search) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        "controller": "Helpers",
        "FunctionName": "EndUsers",
        "ReturnType": "json",
        "customerId": this.customerId
      });
      if (response.data.ERROR || !response.data.ENDUSERS) {
        return {
          options: []
        };
      }
      return this.createOptions(response.data.ENDUSERS)
    }

    const exportObj = {
      controller: "Helpers",
      FunctionName: "EndUsers",
      ReturnType: "json",
      getAll: 1,
      search,
      customerId: this.customerId
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    if (response.data.ERROR || !response.data.ENDUSERS) {
      return {
        options: []
      };
    }
    return this.createOptions(response.data.ENDUSERS)
  };

  onBlur(data) {
    if (data.search && !data.value) {
      this.$parent.euName = data.search;
    }
    else if (!data.search && !data.value) {
      this.$parent.euName = "";
      this.$parent.euPhone = "";
      this.$parent.euEmail = "";
    }
  }

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      return
    }
    const endUser = this.searchData.find((item) => item.ACCOUNTCONTACTID.toString() === value)
    const allOption = this.searchData
    if (!endUser) {
      console.warn(`no end user found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      endUser,
      allOption
    })
  }
}
