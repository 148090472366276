var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "HP Quote Number: ";
},"3":function(container,depth0,helpers,partials,data) {
    return "Quote Number: ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " - "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER), depth0))
    + " ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr><td style=\"background-color: #fff3af; padding: 5px; vertical-align: top;\">Contract Number: </td><td style=\"background-color: #fff3af; padding: 5px;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.CONTRACTNUMBER), depth0))
    + "</td></tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.paragraphRows),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.paragraphRows),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":17,"column":29}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        <tr>\n                            <td style=\"background-color: #fff3af;width: 175px; padding: 5px; vertical-align: top;\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.EDITPARAGRAPHNAME : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":115},"end":{"line":14,"column":168}}})) != null ? stack1 : "")
    + "</td>\n                            <td style=\"background-color: #fff3af; padding: 5px;\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.EDITPARAGRAPHCONTENT : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":81},"end":{"line":15,"column":140}}})) != null ? stack1 : "")
    + "</td>\n                        </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.EDITPARAGRAPHNAME || (depth0 != null ? depth0.EDITPARAGRAPHNAME : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EDITPARAGRAPHNAME","hash":{},"data":data,"loc":{"start":{"line":14,"column":140},"end":{"line":14,"column":161}}}) : helper)));
},"14":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.EDITPARAGRAPHCONTENT || (depth0 != null ? depth0.EDITPARAGRAPHCONTENT : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EDITPARAGRAPHCONTENT","hash":{},"data":data,"loc":{"start":{"line":15,"column":109},"end":{"line":15,"column":133}}}) : helper)));
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <tr><td style=\"background-color: #fff3af;width: 175px; padding: 5px; vertical-align: top;\">Purchasing Instructions:</td><td style=\"background-color: #fff3af; padding: 5px;\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.POInstructions),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":19,"column":193},"end":{"line":19,"column":792}}})) != null ? stack1 : "")
    + "</td></tr>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.POInstructions), depth0)) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.PURCHASINGINSTRUCTION),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":19,"column":281},"end":{"line":19,"column":471}}})) != null ? stack1 : "")
    + ", list Partner ID: 10281367 and Quote ID: "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEID), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER)) && stack1.length),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":544},"end":{"line":19,"column":650}}})) != null ? stack1 : "")
    + " on PO. Forward PO to <a href=\"mailto: AZOM@MilestoneTech.com\">AZOM@MilestoneTech.com</a> for processing. Do not send to HP. Thank you!";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.PURCHASINGINSTRUCTION), depth0)) != null ? stack1 : "")
    + " ";
},"22":function(container,depth0,helpers,partials,data) {
    return " State APP, please see last page only. All others, please make PO out to HP Inc";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"quoteDetails_purchaseInfo\">\n    <tr>\n        <td colspan=\"2\">\n        <table width=\"100%\">\n            <tr><td colspan=\"2\" class=\"headerColor\">Purchasing Information</td></tr>\n            <tr><td style=\"background-color: #fff3af; padding: 5px; vertical-align: top;\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":90},"end":{"line":6,"column":173}}})) != null ? stack1 : "")
    + "</td><td style=\"background-color: #fff3af; padding: 5px;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEID), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER)) && stack1.length),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":262},"end":{"line":6,"column":368}}})) != null ? stack1 : "")
    + "</td></tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.CONTRACTNUMBER),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showParagarph),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "        </table>\n        </td>\n    </tr>\n</table>\n";
},"useData":true});