































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../../components/LaddaButton.vue"
interface Props {
  title: string
  selectedIDs: number,
  totalAll: number,
  customMessage: string,
  customCheckbox: string,
  useLaddaButton: boolean,
  progressBar: number
}
interface Events {
  onClose: void
  onConfirm: void
}
declare const $: any
@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class ConfirmUpdateCustomerSettingsModal extends TSXComponent<
    Props,
    Events
> {
  @Prop({required: false, default: false})
  isEditedCustomerSettings!: boolean;
  @Prop({required: false, default: false})
  isEditedCustomerDetail!: boolean;
  @Prop({required: false, default: false})
  isEditedCustomerDetailsTabTemplates!: boolean;
  @Prop({required: false, default: false})
  isEditedCustomerDetailsTabTags!: boolean;
  @Prop({required: false, default: false})
  isEditedCustomerSecurity!: boolean;
  @Prop({required: false, default: 0})
  progressBar!: number
  @Prop({required: false, default: "Yes"})
  confirmLabel!: string
  @Prop({required: false, default: "Cancel"})
  cancelLabel!: string
  saving = false
  // deleting: any = false;
  fullWidth = "width: 0%;";
  width = 0;
  inProgress = false;
  progressBarModal = false;
  seconds = this.progressBar;
  intervalId: any = 0;
  customCheckboxVal = false;
  $refs!: {
    modal: HTMLDivElement
  }
  async mounted() {
    $(this.$refs.modal).modal('show');
    this.$emit('afterShow');
    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });
    $(this.$refs.modal).on('hidden.bs.modal', () => {
      if ($('.modal.show:visible').length) {
        $('body').addClass('modal-open');
      }
    });
  }
  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }
  confirm() {
    this.saving = true
    this.progressBarModal = true
    if (this.inProgress) {
      clearInterval(this.intervalId);
    } else {
      if (this.seconds === 0) {
        this.seconds = this.progressBar;
      }
      this.intervalId = setInterval(() => this.timer(), 1000);
    }
    this.inProgress = !this.inProgress;
    this.$emit('confirm');
  }
  close(){
    this.$emit('close')
    this.$emit('showPrevSource')
  }
  timer() {
    this.seconds += Math.round(100/this.progressBar);
    this.width = this.seconds;
    this.fullWidth = `width:${this.seconds}%`;
    if (this.seconds > 100) {
      this.seconds = 100;
      this.width = this.seconds;
      this.fullWidth = `width:${this.seconds}%`;
      this.inProgress = !this.inProgress;
      clearInterval(this.intervalId);
    }
  }
}
