


































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

import LaddaButton from "../components/LaddaButton.vue";
import { wait } from "../models/common";

declare const dataURL: string;
declare const $: any;
declare const getImageRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class OrderUploadFileModal extends TSXComponent<void> {
  uploading = false;

  showModal(show) {
    if(show == true){
        $(".modal").addClass('b');
    }
    else{
        $(".modal").removeClass('b');
    }
  }
  handleUploadNewFile() {
    var isValid = true;
    if($("#defaultForm-email").val() == "") {
        $(".fileTypeErrorMsg").html("Please select a file type!");
        isValid = false;
    }else {
        $(".fileTypeErrorMsg").html("");
    }
    
    if($("#defaultForm-pass").get(0).files.length == 0) {
        $(".orderFileErrorMsg").html("Please select an order file!");
        isValid = false;
    }else {
        $(".orderFileErrorMsg").html("");
    }

    //validate file type
    if(isValid){
      const dataFileType = [
                              "text/csv",
                              "application/msword",
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                              "application/pdf",
                              "application/vnd.ms-excel",
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                              "image/jpeg",
                              "image/tiff",
                              "image/png",
                              "message/rfc822",
                              "text/plain"];
      const fileTypeUpload = $("#defaultForm-pass").get(0).files[0].type;
      const fileNameUpload = $("#defaultForm-pass").get(0).files[0].name;
      const parseFileName = fileNameUpload.split('.');
      if(dataFileType.indexOf(fileTypeUpload) == -1 && ( parseFileName[parseFileName.length - 1] != "xls" && fileTypeUpload != "") ) {
          $(".orderFileErrorMsg").html("Please select an order file type as: PDF, Word docs, Excel/CSV, TXT");
          isValid = false;
      }
    }
    
    if(!isValid) {
        return;
    }
    
    //var filePath = $("#defaultForm-pass").get(0).files[0].name;
    var purchaseID = this.$route.params.id;
    this.uploading = true
    return getImageRouteData({
        Controller: "Orders",
        FunctionName: "Edit",
        mdOrderFile: $("#defaultForm-pass").get(0).files[0],
        mdTypeFile: $("#defaultForm-email").val(),
        purchaseID: purchaseID
    }).then(async (response, statusText, jqXHR) => {
        this.uploading = false
        if(response.data.STATUS) {
          $("#orderFiles").click();
          await wait(1000)
            // if(response.data.STATUSMESSAGE) {
            //     alert(response.data.STATUSMESSAGE);
            // }
            // $(".modal button.close").click();

            //reset modal
            $(".modal").removeClass('b');
            $("#defaultForm-email").val("");
            $("#defaultForm-pass").val("");
            $(".orderFiles").click();

            // this.$root.details.ORDERFILES = response.data.ORDERFILES;
        }else if(response.data.ERROR) {
            // console.log(response.data.ERROR);
        }
    }).catch(function (error) {
        // console.log(error);
    });
  }
}
