



















































import { VNode } from "vue";
import axios from "axios";
import { notifier } from "../models/common";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";

interface Props {
}

interface Events {
  onClose: void
  onSave: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
  }
})
export default class AttachPriceListModal extends TSXComponent<
  Props,
  Events
> {

  form: any = {
    name: '',
    type: '',
  }

  loading: boolean = false;

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async save() {
    const valid = await this.$validator.validateAll();

    if (!valid) {
      return;
    }

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: 'Quotes',
        FunctionName: 'ContractAdd',
        userID: sessionStorage.getItem("userId"),
        contractName: this.form.name,
        contractType: this.form.type,
      });

      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1) {
        notifier.success(message);
        this.$emit('save', 1);
      } else {
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      this.loading = false;
    }
  }
}
