










































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../../models/common";
import LaddaButton from "@/components/LaddaButton.vue";
import { loginCheck } from "@/helpers/ApiHelper";

declare const dataURL: string;
declare const $: any;
declare const htmlParse: Function;
declare const htmlCheck: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  },
  methods: {
    loginCheck
  }
})
export default class SubOrderEndUserCustomData extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: true, default: {} })
  selectedSubOrder!: any;

  @Prop({ required: true, default: 0 })
  purchaseId!: number;

  @Prop({ required: false, default: false })
  hideApiCall!: boolean;

  @Prop({ required: true, default: [] })
  data!: any;

  loading = false;
  $parent: any;
  $route: any;
  // fullCustomData: object[] = [];
  customDataIndex = -1;
  savedCD: any = [];
  saving: boolean | "error" = false;
  submitInfo: any = [];

  get dataFieldCountCheck() {
    let saveAsDefaultEnduserViewCount = Object.values(
      this.data.saveAsDefaultEnduserView
    ).filter((item: any) => item === true).length;
    if (saveAsDefaultEnduserViewCount >= 10) {
      return true;
    }
    return false;
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    this.savedCD = JSON.parse(JSON.stringify(this.selectedSubOrder.euCD || []));

    // populate data for submitInfo
    if (this.selectedSubOrder.euInfo.SUBMITTERNAME || "") {
      this.submitInfo = [
        {
          label: "Request Name",
          value: this.selectedSubOrder.euInfo.REQUESTNAME || ""
        },
        {
          // label: "Submitter Name",
          label:
            this.selectedSubOrder.euInfo.SSSUBMITTERLABEL || "Submitter Name",
          value: this.selectedSubOrder.euInfo.SUBMITTERNAME || ""
        },
        {
          // label: "Submitter Email",
          label:
            this.selectedSubOrder.euInfo.SSSUBMITTEREMAILLABEL ||
            "Submitter Email",
          value: this.selectedSubOrder.euInfo.SUBMITTEREMAIL || ""
        },
        {
          label:
            this.selectedSubOrder.euInfo.SSAPPROVERLABEL || "Approver Name",
          value: this.selectedSubOrder.euInfo.LEASECOORDINATOR || ""
        }
      ];
    }

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
    this.selectedSubOrder.euCD = this.savedCD;
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  // showCustomEditBox(index) {
  //   this.customDataIndex = index;

  //   setTimeout(() => {
  //     $(".custom-data-txt")
  //       .focus()
  //       .select();
  //   }, 100);
  // }

  // hideCustomEditBox() {
  //   this.customDataIndex = -1;
  // }

  get changedValues() {
    if (
      JSON.stringify(this.savedCD) !=
      JSON.stringify(this.selectedSubOrder.euCD || [])
    ) {
      return true;
    }

    return false;
  }

  async updateCustomData() {
    // remove HTML
    const euData = this.selectedSubOrder.euCD.filter(t => t.isEUData == 1);
    for (const item of euData) {
      if ((item.customDtype || 1) != 3) {
        item.customValue = this.removeHTML(item.customValue);
      }
    }

    try {
      this.saving = true;
      if (!this.hideApiCall) {
        const response = await ApiHelper.callApi("post", {
          Controller: "Orders",
          FunctionName: "UpdateSubOrderEUData",
          euData: euData.map(t => ({
            customFieldId: t.customFieldId,
            customValue: t.customValue
          })),
          subOrderId: this.selectedSubOrder.subOrderId,
          purchaseId: this.purchaseId
        });
        this.saving = false;

        if (response.STATUS == 1) {
          notifier.success(response.STATUSMESSAGE);

          // update saved values
          this.$nextTick().then(() => {
            this.savedCD = JSON.parse(
              JSON.stringify(this.selectedSubOrder.euCD || [])
            );
          });
        }
      } else {
        this.saving = false;
        // update saved values
        this.$nextTick().then(() => {
          this.savedCD = JSON.parse(
            JSON.stringify(this.selectedSubOrder.euCD || [])
          );
        });
        this.$emit("updateEUDataFields", {
          selectedSubOrder: this.selectedSubOrder
        });
        notifier.success("Updated successfully");
      }
    } catch (err) {
      this.saving = "error";
      console.log(err.message);
    }
  }

  async saveDefaultEnduserView() {
    const response = await ApiHelper.callApi("post", {
      controller: "Customers",
      FunctionName: "UpdateAccountParams",
      saveAsDefaultEnduserView: this.data.saveAsDefaultEnduserView,
      aID: this.data.ORDERDETAILS.AID,
      action: "saveAsDefaultEnduserView"
    });
    if (response.STATUS === 1) {
      notifier.success(
        "Successfully updated the default view for this Account"
      );
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }
}
