

























































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import { notifier, wait } from "../../models/common";
import LaddaButton from '@/components/LaddaButton.vue'
import AlertNameAutoSuggestInput from "@/components/Alert/AlertNameAutoSuggestInput.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import CustomerAutoSuggestInput from "@/components/CustomerAutoSuggestInput.vue";
import SecurityAutoSuggestInput from "@/components/SecurityAutoSuggestInput.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    AlertNameAutoSuggestInput,
    DropdownControl,
    CustomerAutoSuggestInput,
    SecurityAutoSuggestInput
  }
})
export default class CustomAlertModal extends TSXComponent<void> {

  @Prop({ required: true, default: 0 })
  Id!: any;

  @Prop({ required: true, default: '' })
  title!: string;

  @Prop({ required: true, default: 1 })
  type!: string;

  @Prop({ required: false, type: Boolean, default: false })
  isAutoAlert!: string

  @Prop({ required: false, default: [] })
  statusOption!: any;

  @Prop({ required: false, default: "" })
  statusType!: string;
  
  @Prop({ required: true, default: 0 })
  aID!: number;

  @Prop({ required: false, default: [] })
  alerts!: any;

  @Prop({ required: false, default: "" })
  alertCreateType!: string;

  alertName = '';
  timeFrame = 0;
  alertID = 0;
  isDays = false;
  isWeeks = false;
  alertContent = '';
  alertCriteria = {
    timeFrame: {
      isActive: false,
      timeFrame: 1,
      isDays: true,
      isWeeks: false
    },
    onStatusChange: {
      isActive: false
    },
    onSpecificStatus : {
        isActive: false,
        customStatus: []
    },
    noStatusChanged: {
      isActive: false,
      timeFrame: 1,
      isDays: true,
      isWeeks: false
    },
    noStatusChangedByDate: {
      isActive: false,
      timeFrame: 1,
      date: '',
    },
    noStatusChangedIn: {
      isActive: false,
      isDays: true,
      timeFrame: 1,
    },
    purposes: {
      isQuote: false,
      isOrder: false,
      isInvoice: false
    }
  };
  isAlertEveryone =  false;
  selectedStatus: any = [];
  isEdited = false;
  saving = false;
  isSaveForReuse = false;
  customerAlertId: number = 0;
  orderStatusOption: any[] = [
    {
      ID: 1,
      TEXT: "Placed"
    },
    {
      ID: 2,
      TEXT: "Received"
    },
    {
      ID: 3,
      TEXT: "Shipped"
    },
    {
      ID: 4,
      TEXT: "Delivered"
    },
    {
      ID: 6,
      TEXT: "Production"
    },
    {
      ID: 7,
      TEXT: "Cancelled"
    },
    {
      ID: 13,
      TEXT: "Complete"
    }
  ];
  invoiceStatusOptions: any[] = [
    {
      ID: 0,
      TEXT: "Sent"
    },
    {
      ID: 1,
      TEXT: "Paid"
    },
    {
      ID: 2,
      TEXT: "Created"
    },
    {
      ID: 3,
      TEXT: "Partial Pay"
    }
  ];
  quotesStatusOption: any[] = [
    {
      ID: 1,
      TEXT: "Open"
    },
    {
      ID: 2,
      TEXT: "Closed"
    },
    {
      ID: 5,
      TEXT: "Purchased"
    },
    {
      ID: 6,
      TEXT: "Requested"
    },
    {
      ID: 7,
      TEXT: "Assigned"
    },
    {
      ID: 8,
      TEXT: "Purchase Pending"
    }
  ];
  selectedAccounts: any = [];
  selectedGroups: any = [];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    if (this.Id > 0 && this.isAutoAlert) {
      const alert = this.alerts.find((item) => item.alertId === this.Id.toString() || item.ALERTID === this.Id);
      if (alert) {
        this.updateCustomAlert({line: alert});
      }
    }

    this.$forceUpdate();
  }

  purposeCheck(checkboxName) {
    this.selectedStatus = [];
    $('input[type="checkbox"].alertPurpose').not(this).prop('checked', false);
    for (let purposeType in this.alertCriteria.purposes) {
      if (purposeType != checkboxName && $(`input[type="checkbox"].${checkboxName}`).not(":checked")) {
        this.alertCriteria.purposes[purposeType] = false;
      }
    }
  }

  async saveAlert() {
    let result = await this.$validator.validateAll();
    if (this.alertCriteria.noStatusChangedByDate.isActive && !this.alertCriteria.noStatusChangedByDate.date) {
      ApiHelper.showErrorMessage('Date is required');
      return;
    }
    if (result) {
      try {
        this.saving = true;
        if (this.selectedStatus.length) {
          this.alertCriteria.onSpecificStatus.customStatus = this.selectedStatus;
        }

        let response: any = null;

        if (this.isAutoAlert) {

          const {noStatusChangedByDate, noStatusChanged, ...criteria} = this.alertCriteria;

          const autoAlert = {
              alertName: this.alertName,
              CONTENT: this.alertContent,
              isAlertEveryone: this.isAlertEveryone,
              isSaveForReuse: this.isSaveForReuse,
              alertCriteria: criteria,
              alertId: this.customerAlertId
          };

          let dataObj = {};
          if (this.alertCreateType === "accountLevel") {
            let selectedAccountIDs = [];
            let selectedGroupsIDs = [];
            if (this.selectedAccounts.length) {
              selectedAccountIDs = this.selectedAccounts.map((item: any) => item.AID);
            }
            if (this.selectedGroups.length) {
              selectedGroupsIDs = this.selectedGroups.map((item: any) => item.SECURITYGROUPID);
            }

            autoAlert["accountIDs"] = selectedAccountIDs.join();
            autoAlert["securityGroupIDs"] = selectedGroupsIDs.join();

            dataObj = {
              controller: "Alerts",
              FunctionName: "UpdateAccountAlert",
              action: "saveAccountAlert",
              autoAlert,
              selectedAccounts: this.selectedAccounts,
              selectedGroups: this.selectedGroups
            }
          } else {
            dataObj = {
              controller: "Customers",
              FunctionName: "UpdateAccountParams",
              action: "saveAutoAlert",
              autoAlert,
              aID: this.aID,
              userID: sessionStorage.getItem("userId"),
            }
          }
          response = await ApiHelper.callApi(
            'post',
            dataObj
          );
        } else {
          response = await ApiHelper.callApi(
            'post',
            {
              controller: "Alerts",
              FunctionName: "Add",
              type: this.type,
              id: this.Id,
              alertID: this.alertID,
              content: this.alertContent,
              alertName: this.alertName,
              timeFrame: this.timeFrame,
              AlertParams: this.alertCriteria,
              alertFor: this.isAlertEveryone,
              statusType: this.statusType,
              aID: this.aID,
              isSaveForReuse: this.isSaveForReuse
            }
          );
        }

        if (response.STATUS == 1) {
          // this.alerts.push({
          //   alertName: this.alertName,
          //   alertID: this.alertID
          // });
          this.$emit('close');
          this.$emit('addCustomAlert', {
            ALERTNAME: this.alertName,
            ALERTID: response.ALERTGENERATEDKEY,
            PARAMS: response.ALERTPARAM,
            ISSAVEFORREUSE: this.isSaveForReuse,
            DURATION: this.alertID.toString(),
            ISUPDATE: this.customerAlertId ? true : false
          });
          this.$emit('addCreatedAlert', (
            response.CREATEDALERT
          ));
          notifier.success(response.MESSAGE || response.STATUSMSG);
          this.saving = false;
        } else {
          notifier.alert('Something went wrong');
        }
      } catch (err) {
        // console.log(err.message);
        this.saving = false;
      }
    } else {
      return false;
    }
  }

  validateCheck(keyName) {
    this.timeFrame = 0;
    this.alertID = 0;
    this.isEdited = false;
    if (typeof this.alertCriteria[keyName]["isActive"] != 'undefined' && this.alertCriteria[keyName]["isActive"]) {
      this.isEdited = true;
      Object.keys(this.alertCriteria).map((key) => {
        if (key != keyName) {
          if (typeof this.alertCriteria[key]["isActive"] != 'undefined') {
            this.alertCriteria[key]["isActive"] = false;
          }
          if (keyName === "onSpecificStatus") {
            this.checkSelectedStatus();
          }
        } else {
          if (typeof this.alertCriteria[key]["timeFrame"] != 'undefined') {
            this.timeFrame = this.alertCriteria[key]["timeFrame"];
          }
          if (typeof this.alertCriteria[key]["isDays"] != 'undefined' && typeof this.alertCriteria[key]["isWeeks"] != 'undefined') {
            this.alertID = this.alertCriteria[key]["isDays"] ? 1 : 7;
          }
        }
      });
    }
  }

  updateStatus(selectedId) {
    const index = this.selectedStatus.findIndex(id => id === selectedId);
    if (index === -1) {
      this.selectedStatus.push(selectedId)
    } else {
      this.selectedStatus.splice(index, 1);
    }
    this.checkSelectedStatus();
  }

  checkSelectedStatus() {
    this.isEdited = false;
    if (this.selectedStatus.length) {
      this.isEdited = true;
    }
  }

  selectAllStatus() {
    let allStatusOptions = this.alertCriteria.purposes.isQuote ? this.quotesStatusOption : this.alertCriteria.purposes.isInvoice ? this.invoiceStatusOptions : this.alertCriteria.purposes.isOrder ? this.orderStatusOption : this.statusOption
    this.selectedStatus = allStatusOptions.map((status: any) => status.ID);
  }

  resetAllStatus() {
    this.selectedStatus = [];
  }

  updateCustomAlert(data) {
    if (typeof data.line.alertName != "undefined" || typeof data.line.autoAlertName != "undefined" || typeof data.line.ALERTNAME != "undefined") {
      this.alertName = data.line.alertName || data.line.autoAlertName || data.line.ALERTNAME;
    }
    if (typeof data.line.alertCriteria != "undefined") {
      this.alertCriteria = data.line.alertCriteria;
      if (typeof this.alertCriteria.noStatusChangedByDate == 'undefined') {
        this.alertCriteria["noStatusChangedByDate"] = {
          isActive: false,
          timeFrame: 1,
          date: ''
        };
      }

      if (typeof this.alertCriteria.purposes == 'undefined') {
        this.alertCriteria["purposes"] = {
          isQuote: false,
          isOrder: false,
          isInvoice: false
        };
      }
      if (typeof this.alertCriteria.onSpecificStatus != "undefined" && typeof this.alertCriteria.onSpecificStatus.isActive != "undefined" && typeof this.alertCriteria.onSpecificStatus.customStatus != "undefined" && this.alertCriteria.onSpecificStatus.isActive) {
        this.selectedStatus = this.alertCriteria.onSpecificStatus.customStatus;
      }
      this.isEdited = true;
    }
    if (typeof data.line.CONTENT != "undefined") {
      this.alertContent = data.line.CONTENT;
    }
    if (typeof data.line.isAlertEveryone != "undefined") {
      this.isAlertEveryone = data.line.isAlertEveryone;
    }
    if (typeof data.line.isSaveForReuse != "undefined") {
      this.isSaveForReuse = data.line.isSaveForReuse;
    }
    if (typeof data.line.alertId != "undefined") {
      this.customerAlertId = data.line.alertId;
    } else if (typeof data.line.ALERTID != "undefined") {
      this.customerAlertId = data.line.ALERTID;
    }
    if (typeof data.line.selectedAccounts != "undefined") {
      this.selectedAccounts = data.line.selectedAccounts;
    }
    if (typeof data.line.selectedGroups != "undefined") {
      this.selectedGroups = data.line.selectedGroups;
    }
  }

  newAlertName(alertName) {
    if (alertName.length) {
      this.alertName = alertName;
      const alert = this.alerts.find((item) => item.ALERTNAME === alertName || item.alertName === alertName);
      this.customerAlertId = 0;
      if (alert && alert.alertId) {
        this.customerAlertId = alert.alertId;
      }
    }
  }

  updateTimeFrame(value) {
    this.timeFrame = value;
  }

  updateAlertID(value, type) {
    this.alertID = 0;
    this.isEdited = false;
    if (value) {
      this.alertID = type == 'isDays' ? 1 : 7;
      this.isEdited = true;
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  updateCustomer(data) {
    if (data.account) {
      const index = this.selectedAccounts.findIndex((item: any) => item.AID === data.account.AID);
      if (index === -1) {
        this.selectedAccounts.push({
          ANAME: data.account.ANAME || '',
          AID: data.account.AID || 0
        });
      }
    }
  }

  updateAccountList(index) {
    this.$delete(this.selectedAccounts, index);
  }

  updateSecurityGroup(data) {
    if (data.securityGroups) {
      const index = this.selectedGroups.findIndex((item: any) => item.SECURITYGROUPID === data.securityGroups.SECURITYGROUPID);
      if (index === -1) {
        this.selectedGroups.push({
          SECURITYGROUPNAME: data.securityGroups.SECURITYGROUPNAME || '',
          SECURITYGROUPID: data.securityGroups.SECURITYGROUPID || 0
        });
      }
    }
  }

  updateSGList(index) {
    this.$delete(this.selectedGroups, index);
  }

}
