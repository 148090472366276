





































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier, downloadFile, printHtml } from "../models/common";
import Pagination from "../components/Pagination.vue";
import UpdateItemStatusModal from "../components/UpdateItemStatusModal.vue";
import DeleteItemModal from "../components/DeleteItemModal.vue";
import UserStatus, {UserStatusValue} from "../components/UserStatus.vue";
import {
  UserAPIRepo,
  UserFilters,
  UserActiveStatus,
  UserLevel,
  UserSort,
  UserSortField
} from "../repos/UserAPIRepo";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { getRangeFilter, getDateRangeFilter } from "@/helpers/ApiHelper";

declare const dataURL: string;
declare const getMaxRows: Function;

const userRepo = new UserAPIRepo();
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    ListHeaderActions,
    Pagination,
    UpdateItemStatusModal,
    DeleteItemModal,
    UserStatus
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class Users extends TSXComponent<void> {
  selectedID = [];
  updateStatusModalVisible = false;
  deleteUserModalVisible = false;
  checkList: string[] = [];
  levelCheckList: number[] = [1,0];
  activeList: number[] = [];
  loading = false;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["users"] ? JSON.parse(sessionStorage.pageDetails)["users"] : 1 ;
  currentRole = sessionStorage.getItem('userRole');
  getClass = this.currentRole === "Customer" ? "user up" : "up";
  dataObj = {
    controller: "varUSers",
    FunctionName: "List",
    subsystem: "VAR360",
    maxRows: sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows(),
    status : this.levelCheckList.join(",")
  };
  // details: {
  //   CURRENTPAGE: number;
  //   TOTALPAGES: number;
  //   sDate: string;
  //   eDate: string;
  //   sLoginDate: string;
  //   eLoginDate: string;
  //   searchName: string;
  //   searchEmail: string;
  //   searchUserID: string;
  // } = {
  //   CURRENTPAGE: 1,
  //   TOTALPAGES: 1,
  //   sDate: "",
  //   eDate: "",
  //   sLoginDate: "",
  //   eLoginDate: "",
  //   searchName: "",
  //   searchEmail: "",
  //   searchUserID: ""
  // };
  details:any = {};
  //     items: {
  // 		type: string,
  // 		checked: boolean
  // 	}[] = [
  //     {
  //       type: "text",
  //       checked: false
  //     }
  //   ];
  userStatus = UserActiveStatus;
  items: string[] = [];
  filters: UserFilters = {
    sStatus: [1,0],
    sLevels: [],
    statusName: Object.keys(this.userStatus).filter((key) => isNaN(Number(key))),
    sDate: "",
    eDate: "",
    sLoginDate: "",
    eLoginDate: "",
    searchName: "",
    searchEmail: "",
    searchUserID: ""
  };
  searchFilters: UserFilters = {
    sStatus: [1,0],
    sLevels: [],
    statusName: Object.keys(this.userStatus).filter((key) => isNaN(Number(key))),
    sDate: "",
    eDate: "",
    sLoginDate: "",
    eLoginDate: "",
    searchName: "",
    searchEmail: "",
    searchUserID: ""
  };
  sort:UserSort = {
    field: null,
    direction: {
      [UserSortField.Name]: 1,
      [UserSortField.Email]: 1,
      [UserSortField.Date]: 1,
      [UserSortField.LoginDate]: 1,
      [UserSortField.Status]: 1
    }
  }

  async created() {
    this.dataObj["pageNumber"] = this.pageNumber;
    await this.fetchUsers(this.dataObj);
  }

  async showUpdateStatusModal() {
    this.updateStatusModalVisible = true;
  }
  async showDeleteUserModal() {
    this.deleteUserModalVisible = true;
  }

  // async sortingFields(orderSort, nameSort) {
  //   this.dataObj["order"] = orderSort;
  //   this.dataObj["maxRows"] = getMaxRows;
  //   this.dataObj["direction"] = parseInt(this.details[nameSort]) === 1 ? 2 : 1;
  //   await this.fetchUsers(this.dataObj, this, nameSort, "direction", "");
  // }

  async sortBy(field: UserSortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    this.dataObj["order"] = this.sort.field;
    this.dataObj["direction"] = this.sort.direction[this.sort.field] ? this.sort.direction[this.sort.field].toString() : "1";
    await this.fetchUsers(this.dataObj);
  }

  async sortingByName() {
    this.sortBy(UserSortField.Name);
  }

  async sortingByEmail() {
    this.sortBy(UserSortField.Email);
  }

  async sortingByDate() {
    this.sortBy(UserSortField.Date);
  }

  async sortingByLoginDate() {
    this.sortBy(UserSortField.LoginDate);
  }

  async sortingByStatus() {
    this.sortBy(UserSortField.Status);
  }

  async updateStatus(status) {
    var statusIndex = 1;
    if (status.status == "open") {
      statusIndex = 1;
    } else if (status.status == "close") {
      statusIndex = 0;
    }

    const getUser = sessionStorage.getItem("currentUser")
      ? sessionStorage.getItem("currentUser")
      : "";
    const currentUser = getUser ? JSON.parse(getUser) : "";
    const accountid = currentUser[0]["ACCOUNTID"];
    if (this.selectedID.length > 0) {
      this.loading = true;
      try {
        const params = {
          selectedID: this.selectedID.toString(),
          accountid: accountid,
          status: statusIndex
        };
        const response = await userRepo.updateStatus(params);
        if (response.STATUS == 1) {
          notifier.success(response.MESSAGE);
          //reload list
          this.loadPage(this.details.CURRENTPAGE, "");
        } else {
          notifier.alert("Failed update status of user(s)!");
        }
        this.selectedID = [];
        this.updateStatusModalVisible = false;
      } catch (error) {
        //log error
        notifier.alert("Error: " + error);
      } finally {
        this.loading = false;
      }
    } else {
      notifier.alert("Please select any one user(s)");
    }
  }

  async changeStatus(userid, status) {
    const statusIndex = status

    try {
      this.loading = true
      await axios.post(dataURL + "?ReturnType=JSON", {
          "FunctionName"  : "Update",
          "controller"    : "VARUsers",
          "subsystem"     : "VAR360",
          "userId"        : userid,
          "active"        : status
      })
      this.fetchUsers(this.dataObj);
    } catch (err) {
        notifier.alert(err.message)
    } finally {
      this.loading = false
    }
  }

  async resetSearchByName() {
    this.filters.searchName = this.searchFilters.searchName = "";
    this.dataObj["name"] = "";
    this.dataObj["pageNumber"] = 1;
    await this.fetchUsers(this.dataObj);
  }

  async searchByName() {
    this.dataObj["pageNumber"] = 1;
    this.dataObj["name"] = this.searchFilters.searchName;
    this.filters.searchName = this.searchFilters.searchName;
    if(this.searchFilters.searchName == undefined || this.searchFilters.searchName ==  '') {
      return;
    }else{
      await this.fetchUsers(this.dataObj);
    }
  }

  async searchByEmail() {
    this.dataObj["pageNumber"] = 1;
    this.dataObj["email"] = this.searchFilters.searchEmail;
    this.filters.searchEmail = this.searchFilters.searchEmail;
    if(this.searchFilters.searchEmail == undefined || this.searchFilters.searchEmail ==  '') {
      return;
    }else{
      await this.fetchUsers(this.dataObj);
    }
  }

  async searchByDate() {
    this.dataObj["pageNumber"] = 1;
    this.dataObj["sDate"] = this.searchFilters.sDate;
    this.dataObj["eDate"] = this.searchFilters.eDate;
    this.filters.sDate = this.searchFilters.sDate;
    this.filters.eDate = this.searchFilters.eDate;
     if((this.searchFilters.sDate == undefined || this.searchFilters.sDate ==  '') && (this.searchFilters.eDate == '' || this.searchFilters.eDate == undefined)) {
      return;
    }else {
      await this.fetchUsers(this.dataObj);
    }
  }

  async searchByLoginDate() {
    this.dataObj["pageNumber"] = 1;
    this.dataObj["sLoginDate"] = this.searchFilters.sLoginDate;
    this.dataObj["eLoginDate"] = this.searchFilters.eLoginDate;
    this.filters.sLoginDate = this.searchFilters.sLoginDate;
    this.filters.eLoginDate = this.searchFilters.eLoginDate;
    if((this.searchFilters.sLoginDate == undefined || this.searchFilters.sLoginDate ==  '') && (this.searchFilters.eLoginDate == '' || this.searchFilters.eLoginDate == undefined)) {
      return;
    }else {
      await this.fetchUsers(this.dataObj);
    }
  }

  // Level Filter
  // async checkStatusList(level: number) {
  //   this.selectedID = [];
  //   if(this.filters.sLevels && this.filters.sLevels.length){
  //     const i = this.filters.sLevels.findIndex(s => s === level);
  //     if (i === -1) {
  //       this.filters.sLevels.push(level);
  //     } else {
  //       this.filters.sLevels.splice(i, 1);
  //     }
  //     // var index = this.levelCheckList.indexOf(value);
  //     // if (index < 0) {
  //     //   this.levelCheckList.push(value);
  //     // } else {
  //     //   this.levelCheckList.splice(index, 1);
  //     // }
  //     this.dataObj["level"] = this.filters.sLevels.toString();
  //   }
  //   this.dataObj["pageNumber"] = 1;
  //   await this.fetchUsers(
  //     this.dataObj,
  //     this,
  //     "levelCheckListStr",
  //     "level",
  //     ""
  //   );
  // }

  async checkAllStatusList() {
    // this.levelCheckList = [1, 2, 3];
    // this.dataObj["level"] = this.levelCheckList.toString();
    this.dataObj["level"] = [
      UserLevel.Admin,
      UserLevel.General,
      UserLevel.Support
    ].toString();
    // var items = document.getElementsByName("statusCheckBox");
    // for (var i = 0; i < items.length; i++) {
    //   if (items[i].type == "checkbox") items[i].checked = true;
    // }
    this.dataObj["pageNumber"] = 1;
    await this.fetchUsers(
      this.dataObj
    );
  }

  async resetStatusList() {
    // this.levelCheckList = [];
    // this.dataObj["level"] = this.levelCheckList.toString();
    // var items = document.getElementsByName("statusCheckBox");
    // for (var i = 0; i < items.length; i++) {
    //   if (items[i].type == "checkbox") items[i].checked = false;
    // }
    this.selectedID = [];
    this.searchFilters.sLevels = [];
    this.dataObj["level"] = [].toString();
    this.dataObj["pageNumber"] = 1;
    await this.fetchUsers(
      this.dataObj
    );
  }

  // Status Filter
  async checkAllStatus() {
    // this.activeList = [0, 1];
    // this.dataObj["status"] = this.activeList.toString();
    // var items = document.getElementsByName("activeCheckBox");
    // for (var i = 0; i < items.length; i++) {
    //   if (items[i].type == "checkbox") items[i].checked = true;
    // }
    this.filters.sStatus = [
      UserActiveStatus.Active,
      UserActiveStatus.InActive
    ];
    this.filters.statusName = Object.keys(this.userStatus).filter((key) => isNaN(Number(key)));
    this.dataObj["status"] = [
      UserActiveStatus.Active,
      UserActiveStatus.InActive
    ].toString();
    this.dataObj["pageNumber"] = 1;
    await this.fetchUsers(
      this.dataObj
    );
  }

  async uncheckAllStatus() {
    this.filters.sStatus = [-1];
    this.dataObj["status"] = [-1].toString();
    this.dataObj["pageNumber"] = 1;
    this.filters.statusName = [];
    await this.fetchUsers(
      this.dataObj
    );
  }

  async resetAllStatus() {
    // this.activeList = [];
    // this.dataObj["status"] = this.activeList.toString();
    // var items = document.getElementsByName("activeCheckBox");
    // for (var i = 0; i < items.length; i++) {
    //   if (items[i].type == "checkbox") items[i].checked = false;
    // }
    this.filters.sStatus = [];
    this.dataObj["status"] = [].toString();
    this.dataObj["status"] = '-1';
    this.dataObj["pageNumber"] = 1;
    await this.fetchUsers(
      this.dataObj
    );
  }

  async checkStatus(status: number, name: string) {
    this.selectedID = [];
    if(this.filters.sStatus && this.filters.statusName){
      const i = this.filters.sStatus.findIndex(s => s === status);
      const statusIndex = this.filters.statusName.findIndex(
        statusValue => statusValue === name
      );
      if (i === -1) {
        this.filters.sStatus.push(status);
      } else {
        this.filters.sStatus.splice(i, 1);
      }

      if (statusIndex === -1) {
        this.filters.statusName.push(name);
      } else {
        this.filters.statusName.splice(statusIndex, 1);
      }
      // var index = this.activeList.indexOf(value);
      // if (index < 0) {
      //   this.activeList.push(value);
      // } else {
      //   this.activeList.splice(index, 1);
      // }
      this.dataObj["status"] = this.filters.sStatus.toString();
      if(!this.filters.sStatus.length){
        this.dataObj["status"] = '-1';
      }
    }
    this.dataObj["pageNumber"] = 1;
    await this.fetchUsers(
      this.dataObj
    );
  }

  //reset filter
  async resetSearchByUserID() {
    this.filters.searchUserID = this.searchFilters.searchUserID = "";
    this.dataObj["userid"] = "";
    this.dataObj["pageNumber"] = 1;
    await this.fetchUsers(
      this.dataObj
    );
  }

  async resetSearchByEmail() {
    this.filters.searchEmail = this.searchFilters.searchEmail = "";
    this.dataObj["email"] = "";
    this.dataObj["pageNumber"] = 1;
    await this.fetchUsers(this.dataObj);
  }

  async resetSearchByLoginDate() {
    this.dataObj["pageNumber"] = 1;
    if((this.searchFilters.sLoginDate == undefined || this.searchFilters.sLoginDate ==  '') && (this.searchFilters.eLoginDate == '' || this.searchFilters.eLoginDate == undefined)) {
      return
    }else {
      this.filters.sLoginDate = this.searchFilters.sLoginDate = "";
      this.filters.eLoginDate = this.searchFilters.eLoginDate = "";
      this.dataObj["sLoginDate"] = this.searchFilters.sLoginDate;
      this.dataObj["eLoginDate"] = this.searchFilters.eLoginDate;
      await this.fetchUsers(this.dataObj);
    }
  }

  async resetSearchByDate() {
    this.dataObj["pageNumber"] = 1;
    if((this.searchFilters.sDate == undefined || this.searchFilters.sDate ==  '') && (this.searchFilters.eDate == '' || this.searchFilters.eDate == undefined)) {
      return
    }else {
      this.filters.sDate = this.searchFilters.sDate = "";
      this.filters.eDate = this.searchFilters.eDate = "";
      this.dataObj["sDate"] = this.searchFilters.sDate;
      this.dataObj["eDate"] = this.searchFilters.eDate;
      await this.fetchUsers(this.dataObj);
    }
  }

  async deleteUser() {
    if (this.selectedID.length > 0) {
      this.loading = true;
      try {
        const params = {
          selectedID: this.selectedID.toString()
        };
        const response = await userRepo.deleteUser(params);
        if (response.STATUS == 1) {
          notifier.success(response.MESSAGE);
          //reload list
          this.loadPage(this.details.CURRENTPAGE, "");
        } else {
          notifier.alert("Failed delete user(s)!");
        }
        this.selectedID = [];
        this.deleteUserModalVisible = false;
      } catch (error) {
        //log error
        notifier.alert("Error: " + error);
      } finally {
        this.loading = false;
      }
    } else {
      notifier.alert("Please select any one user(s)");
    }
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["users"]){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["users"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      this.dataObj["pageNumber"] = this.pageNumber;
      await this.fetchUsers(this.dataObj);
    }
  }

  async fetchUsers(dataObj) {
    this.loading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    if (response.data.STATUS) {
      this.loading = false;
      this.items = response.data.USERSDETAILS || [];
      this.details = response.data;
      // Object.assign(this.details, response.data || {});
      // if (option == "page") {
      //   // if (dataObj["sDate"] != "" || dataObj["eDate"] != "") {
      //   //   this.details.sDate = dataObj["sDate"];
      //   //   this.details.eDate = dataObj["eDate"];
      //   // }
      // } else {
      //   if (option === "new") {
      //     // this.details = response.data;
      //     this.searchFilters["searchName"] = "";
      //     this.searchFilters["searchId"] = "";
      //   } else {
      //     this.searchFilters[keyName] = dataObj[fieldname];
      //   }
      // }
    } else if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    // return response.data;
  }

  showDetail(userId) {
    this.$router.push({ name: "EditUser", params: { id: userId } });
  }

  getPreDefinedFilter(status, filter) {
    if (filter.length != undefined && filter.length == 0) {
      return "None";
    } else if (!((Object.keys(status).length / 2) == filter.length)) {
      return filter.join(", ");
    }
  }
}
