import { render, staticRenderFns } from "./CustomerDepartmentTooltip.vue?vue&type=template&id=e19e4d80&scoped=true"
import script from "./CustomerDepartmentTooltip.vue?vue&type=script&lang=tsx"
export * from "./CustomerDepartmentTooltip.vue?vue&type=script&lang=tsx"
import style0 from "./CustomerDepartmentTooltip.vue?vue&type=style&index=0&id=e19e4d80&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e19e4d80",
  null
  
)

export default component.exports