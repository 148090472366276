




















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import EmployeePlanSelect from "@/components/EmployeePlan/EmployeePlanSelect.vue";
import { FinancialBoardsAPIRepo } from "@/repos/FinancesDashboardAPIRepo";
import { notifier } from "@/models/common";

declare const $: any;

const financialBoardsRepo = new FinancialBoardsAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    EmployeePlanSelect
  }
})

export default class EmployeePlanModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: "" })
  boardUUID!: string;

  @Prop({ required: false, default: [] })
  employeePlansData!: any;

  @Prop({ required: false, default: [] })
  selectedPlans!: object[];

  @Prop({ required: false, default: [] })
  selectedPlanID!: any;

  @Prop({ required: false, default: new Date().getFullYear() })
  year!: number | string;

  $validator: any;
  saving: boolean = false;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  toggleSelectedPlan(selectedID: number, planName) {
    const index = this.selectedPlanID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedPlanID.push(selectedID);
      this.selectedPlans.push({
        COMPID: selectedID,
        EMPLOYEENAME: planName
      });
      return;
    }

    this.selectedPlanID.splice(index, 1);
    this.$delete(this.selectedPlans, index);
  }

  selectAllPlans() {
    this.resetPlans();
    this.selectedPlanID = this.employeePlansData.map(
      (val: any) => val.COMPID
    );
    this.selectedPlans = this.employeePlansData.map((val: any) => ({
      COMPID: val.COMPID,
      EMPLOYEENAME: val.EMPLOYEENAME
    }));
  }

  resetPlans() {
    this.selectedPlans = [];
    this.selectedPlanID = [];
  }

  updatePlanList(index) {
    if (this.selectedPlans) {
      this.$delete(this.selectedPlans, index);
      this.$delete(this.selectedPlanID, index);
    }
  }

  async save() {
    
    if (this.boardUUID.length) {
      this.saving = true;
  
      const response: any = await financialBoardsRepo.addBoardEmployeePlan(
        this.boardUUID,
        this.selectedPlanID,
        this.year
      );
  
      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.$emit("reload");
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.saving = false;
    }
  }
}
