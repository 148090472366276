






























































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "@/components/Pagination.vue";
import { colsResizeable, getRangeFilter } from "@/helpers/ApiHelper";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../models/common";
import LaddaButton from "@/components/LaddaButton.vue";
import EmployeePlanComponent from "@/components/EmployeePlan/EmployeePlanToolTip.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import FilterCurrencyBox from "@/components/FilterCurrencyBox.vue";

declare const getMaxRows: Function;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    LaddaButton,
    EmployeePlanComponent,
    ListHeaderActions,
    FilterCurrencyBox
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    }
  }
})

export default class PlanGroups extends TSXComponent<void> {
  loading = false;
  pageNumber = 1;
  isSearch = 0;
  totalAll = 0;
  totalPlanGroups = 0;
  details: any = {};
  planGroups: any = [];
  planCountTooltipIndex = -1;
  filters: any = {
    planName: "",
    totalBaseMin: undefined,
    totalBaseMax: undefined,
    totalCompMin: undefined,
    totalCompMax: undefined,
    totalOteMin: undefined,
    totalOteMax: undefined
  }
  searchFilters: any = {
    planName: "",
    totalBaseMin: undefined,
    totalBaseMax: undefined,
    totalCompMin: undefined,
    totalCompMax: undefined,
    totalOteMin: undefined,
    totalOteMax: undefined
    
  }
  SortField = {
    PlanName: 1,
    TotalBase: 2,
    TotalComp: 3,
    TotalOte: 4
  }
  sort: any = {
    field: null,
    direction: {
      PlanName: 1,
      TotalBase: 1,
      TotalComp: 1,
      TotalOte: 1
    }
  };

  async created() {
    await this.fetchData(true);
  }

  async fetchData(initialLoad: boolean = false) {
    this.loading = true;
    try {
      let reqData = this.prepareFilteredData();
      reqData["FunctionName"]   = "PlanGroup";
      reqData["pageNumber"]     = this.pageNumber;
      reqData["maxRows"]        = sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows();

      const response = await ApiHelper.callApi("post", reqData);

      this.details = response;
      this.planGroups = this.details.PLANGROUPS || [];
      this.isSearch = this.details.ISSEARCH;
      this.totalAll = this.details.TOTALALL;
      this.totalPlanGroups = this.details.TOTALPLANGROUPS;
      
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ ms: 500 });

  }


  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= (this.details.TOTALPAGES || 1) && this.pageNumber >= 1) {
      this.fetchData();
    }
  }


  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  prepareFilteredData() {
    let reqData = {
      controller: "Finances"
    }

    if (this.sort.field) {
      reqData["order"] = this.sort.field;
      reqData["direction"] = this.sort.direction[this.sort.field].toString();
    }

    if (this.filters.planName) {
      reqData["planName"] = this.filters.planName;
    }

    if (this.filters.totalBaseMin) {
      reqData['totalBaseMin'] = this.filters.totalBaseMin;
    }

    if (this.filters.totalBaseMax) {
      reqData['totalBaseMax'] = this.filters.totalBaseMax;
    }

    if (this.filters.totalCompMin) {
      reqData['totalCompMin'] = this.filters.totalCompMin;
    }

    if (this.filters.totalCompMax) {
      reqData['totalCompMax'] = this.filters.totalCompMax;
    }

    if (this.filters.totalOteMin) {
      reqData['totalOteMin'] = this.filters.totalOteMin;
    }

    if (this.filters.totalOteMax) {
      reqData['totalOteMax'] = this.filters.totalOteMax;
    }

    return reqData;
  }

  async sortBy(field: any) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingByPlanName() {
    this.sortBy(this.SortField.PlanName);
  }

  async sortingByTotalBase() {
    this.sortBy(this.SortField.TotalBase);
  }

  async sortingByTotalComp() {
    this.sortBy(this.SortField.TotalComp);
  }

  async sortingByTotalOte() {
    this.sortBy(this.SortField.TotalOte);
  }

  async searchByPlanName() {
    this.pageNumber = 1;
    if (this.searchFilters.planName == "") {
      notifier.alert("Please Fill Employee Name");
    } else {
      this.filters.planName = this.searchFilters.planName;
      await this.fetchData();
    }
  }

  async searchByTotalBase() {
    this.pageNumber = 1;
    this.filters.totalBaseMin = this.searchFilters.totalBaseMin;
    this.filters.totalBaseMax = this.searchFilters.totalBaseMax;
    await this.fetchData();
  }

  async searchByTotalComp() {
    this.pageNumber = 1;
    this.filters.totalCompMin = this.searchFilters.totalCompMin;
    this.filters.totalCompMax = this.searchFilters.totalCompMax;
    await this.fetchData();
  }

  async searchByTotalOte() {
    this.pageNumber = 1;
    this.filters.totalOteMin = this.searchFilters.totalOteMin;
    this.filters.totalOteMax = this.searchFilters.totalOteMax;
    await this.fetchData();
  }

  async resetSearchByPlanName(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.planName = this.searchFilters.planName = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByTotalBase(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.totalBaseMin = this.searchFilters.totalBaseMin = undefined;
    this.filters.totalBaseMax = this.searchFilters.totalBaseMax = undefined;
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByTotalComp(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.totalCompMin = this.searchFilters.totalCompMin = undefined;
    this.filters.totalCompMax = this.searchFilters.totalCompMax = undefined;
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByTotalOte(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.totalOteMin = this.searchFilters.totalOteMin = undefined;
    this.filters.totalOteMax = this.searchFilters.totalOteMax = undefined;
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetColumn(header) {
    switch (header) {
      case 'PLANNAME':
        this.resetSearchByPlanName();
        break;
      default:
        break;
    }
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'PLANNAME':
        searchKey = this.filters.planName;
        break;
      case 'TOTALBASE':
        filter = getRangeFilter(this.filters.totalBaseMin, this.filters.totalBaseMax);
        break;
      case 'TOTALCOMM':
        filter = getRangeFilter(this.filters.totalCompMin, this.filters.totalCompMax);
        break;
      case 'TOTALOTE':
        filter = getRangeFilter(this.filters.totalOteMin, this.filters.totalOteMax);
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  sorting(c) {
    switch (c) {
      case "PLANNAME":
        this.sortingByPlanName();
        break;
      case "TOTALBASE":
        this.sortingByTotalBase();
        break;
      case "TOTALCOMM":
        this.sortingByTotalComp();
        break;
      case "TOTALOTE":
        this.sortingByTotalOte();
        break;
      default:
        break;
    }
  }
}
