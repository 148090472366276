var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"planGroup-Details",attrs:{"id":"container"}},[_c('add-header'),_c('div',{staticClass:"up h-auto",attrs:{"id":"page-header"}},[(!_vm.loading)?_c('div',{attrs:{"id":"page-title-container"}},[_c('span',{staticClass:"page-title",attrs:{"id":"planGrpPreviousIcon"}},[_c('span',{attrs:{"id":"previousIcon"},on:{"click":_vm.listPageRedirection}})]),_c('span',{staticClass:"page-title left mb-3 textOverflow",attrs:{"title":_vm.details.PLANNAME,"data-type":"orders"}},[_vm._v(_vm._s(_vm.details.PLANNAME))])]):_vm._e(),(!_vm.loading)?[_c('div',{staticClass:"pg-total-container ml-4"},[_c('div',{staticClass:"pg-total-container-details"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"pg-title"},[_vm._v("Total Base")]),_c('span',{staticClass:"pg-price"},[_vm._v(_vm._s(_vm.pgTotalBase))])]),_c('div',{staticClass:"pg-line"})]),_c('div',{staticClass:"pg-total-container-details"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"pg-title"},[_vm._v("Total Comp")]),_c('span',{staticClass:"pg-price"},[_vm._v(_vm._s(_vm.pgTotalComp))])]),_c('div',{staticClass:"pg-line"})]),_c('div',{staticClass:"pg-total-container-details"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"pg-title"},[_vm._v("Total OTE")]),_c('span',{staticClass:"pg-price"},[_vm._v(_vm._s(_vm.pgTotalOte))])])])]),_c('div',{staticClass:"pg-total-container ml-4"},[_c('div',{staticClass:"pg-total-container-details"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"pg-title"},[_vm._v("Total Comp To Date")]),_c('span',{staticClass:"pg-price"},[_vm._v(_vm._s(_vm.pgCompToDate))])])])])]:_vm._e(),_c('div',{staticClass:"buttonContainer btnLg"},[_vm._m(0),_c('ul',{staticClass:"menu"},[_c('div',[_c('li',{on:{"click":function () {
              _vm.showExportToGustoModal = true;
            }}},[_vm._v(" Export to Gusto ")]),_c('li',{on:{"click":function () {
              _vm.confirmDeletePlanGroup = true;
            }}},[_vm._v(" Delete Plan Group ")])])])])],2),_c('div',{staticClass:"page-list"},[_c('div',{staticClass:"page-list-container"},[(_vm.loading)?_c('Loader',{staticClass:"loader"}):[(_vm.pgEmpPlansData.length)?[_vm._l((_vm.pgEmpPlansData),function(planItem,planIndex){return _c('table',{key:planIndex,staticClass:"mt-3"},[_c('thead',[_c('tr',{staticClass:"table-secondary"},[_c('th',{staticClass:"w-12"},[_c('div',{staticClass:"head-item fw-700"},[_c('router-link',{staticClass:"textOverflow employeeName",attrs:{"to":{
                        name: 'EmployeePlanDetails',
                        params: {
                          id: planItem.COMPUUID || 0
                        }
                      },"title":((planItem.EMPLOYEENAME) + ", " + (planItem.OTEYEAR))}},[_vm._v(" "+_vm._s(planItem.EMPLOYEENAME)+", "+_vm._s(planItem.OTEYEAR)+" ")])],1)]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" January "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(1)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(1, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" February "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(2)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(2, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" March "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(3)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(3, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" April "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(4)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(4, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" May "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(5)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(5, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" June "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(6)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(6, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" July "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(7)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(7, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" August "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(8)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(8, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" September "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(9)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(9, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" October "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(10)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(10, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" November "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(11)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(11, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-6"},[_c('div',{staticClass:"head-item"},[_vm._v(" December "),_c('span',{staticClass:"lock-icon",class:{
                        'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(12)
                      },on:{"click":function($event){return _vm.lockEmpPlanMonth(12, planItem, planIndex)}}})])]),_c('th',{staticClass:"w-8"},[_c('div',{staticClass:"head-item"},[_vm._v("Total")])])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"fw-700"},[_vm._v("Base")]),_vm._l((12),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                    'plan-dark-border-right' : item === 12,
                    'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(item)
                  }},[_vm._v("$"+_vm._s(_vm._f("formatCurrency")(planItem.OTEBASE/12)))])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(planItem.OTEBASE_FORMATTED))])],2),_c('tr',[_c('td',[_c('span',{staticClass:"fw-700 d-flex"},[_vm._v(" Commission ")]),_c('span',{staticClass:"accelerators"},[_vm._v("Accelerators")])]),_vm._l(((planItem.COMMISSION || [])),function(item,index){return _c('td',{key:index,staticClass:"tar",class:{
                    'plan-dark-border-right' : item.MONTH === 12,
                    'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(item.MONTH)
                  },on:{"mouseleave":function () {
                    _vm.showAcceleratorTooltipIndex = -1;
                    _vm.showPlanAcceleratorTooltipIndex = -1;
                  }}},[_c('span',{staticClass:"fw-700 d-flex flex-column"},[(item.ISCOMMAVAILABLE)?[_vm._v(" "+_vm._s(item.COMMISSION_FORMATTED)+" ")]:[_vm._v(" - ")]],2),_c('span',{staticClass:"accelerators-total",on:{"mouseenter":function () {
                      if((item.ACCELERATORS || []).length) {
                        _vm.showAcceleratorTooltipIndex = index;
                        _vm.showPlanAcceleratorTooltipIndex = planIndex;
                      }
                    }}},[_vm._v(" "+_vm._s(item.ACCELERATORAMOUNT_FORMATTED)+" ")]),(_vm.showAcceleratorTooltipIndex === index && _vm.showPlanAcceleratorTooltipIndex === planIndex)?_c('TargetProfitTooltip',{staticClass:"accelerators-profit-box",attrs:{"action":"accelerators","acceleratorData":(item.ACCELERATORS || [])}}):_vm._e()],1)}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(planItem.COMMISSIONTOTAL))])],2),_c('tr',[_c('td',{staticClass:"fw-700"},[_vm._v("Adjustments")]),_vm._l(((planItem.ADJUSTMENTS || [])),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                    'plan-dark-border-right' : item.MONTH === 12,
                    'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(item.MONTH)
                  }},[_c('div',{staticClass:"d-flex justify-content-end"},[(!(planItem.LOCKEDMONTHS || []).includes(item.MONTH))?_c('span',{staticClass:"pen-icon",attrs:{"title":"Edit"},on:{"click":function () {
                        if (_vm.$systemGroups.includes('financial_admin') && !(planItem.LOCKEDMONTHS || []).includes(item.MONTH)) {
                          _vm.selectedAdjustmentMonth = item.MONTH;
                          _vm.selectedAdjustmentMonthAsString = item.MONTHASSTRING;
                          _vm.showAdjustmentsModal = true;
                          _vm.adjustmentData = (planItem.ADJUSTMENTS || []);
                          _vm.lockedMonthData = (planItem.LOCKEDMONTHS || []);
                          _vm.empDetails = planItem.EMPLOYEEPLANDETAIL;
                        }
                      }}}):_vm._e(),(item.AMOUNT_FORMATTED && item.AMOUNT)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(planItem.ADJUSTMENTSTOTAL))])],2),_c('tr',[_c('td',{staticClass:"fw-700"},[_vm._v("Reimbursements")]),_vm._l(((planItem.REIMBURSEMENTS || [])),function(item,index){return _c('td',{key:index,staticClass:"tar fw-400",class:{
                    'plan-dark-border-right' : item.MONTH === 12,
                    'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(item.MONTH)
                  }},[_c('div',{staticClass:"d-flex justify-content-end"},[(!(planItem.LOCKEDMONTHS || []).includes(item.MONTH))?_c('span',{staticClass:"pen-icon",attrs:{"title":"Edit"},on:{"click":function () {
                        if (_vm.$systemGroups.includes('financial_admin') && !(planItem.LOCKEDMONTHS || []).includes(item.MONTH)) {
                          _vm.selectedReimursementMonth = item.MONTH;
                          _vm.selectedReimursementMonthAsString = item.MONTHASSTRING;
                          _vm.showReimursementsModal = true;
                          _vm.reimursementData = (planItem.REIMBURSEMENTS || []);
                          _vm.lockedMonthData = (planItem.LOCKEDMONTHS || []);
                          _vm.empDetails = planItem.EMPLOYEEPLANDETAIL;
                        }
                      }}}):_vm._e(),(item.AMOUNT_FORMATTED && item.AMOUNT)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)])}),_c('td',{staticClass:"tar fw-700 plan-bg-total"},[_vm._v(_vm._s(planItem.REIMURSEMENTSTOTAL))])],2),_c('tr',{staticClass:"total-Compensation"},[_c('td',{staticClass:"fw-700"},[_vm._v("Total Compensation")]),_vm._l(((planItem.TOTALCOMPENSATION || [])),function(item,index){return _c('td',{key:index,staticClass:"tar fw-700",class:{
                    'plan-dark-border-right' : item.MONTH === 12,
                    'activeMonth' : (planItem.LOCKEDMONTHS || []).includes(item.MONTH)
                  }},[(item.AMOUNT_FORMATTED)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)}),_c('td',{staticClass:"tar fw-700"},[_vm._v(_vm._s(planItem.TOTALCOMPENSATIONTOTAL))])],2)])])}),_c('table',{staticClass:"mt-5"},[_c('tbody',[_c('tr',{staticClass:"total-Compensation"},[_c('td',{staticClass:"fw-700 w-12 textOverflow",attrs:{"title":((_vm.details.PLANNAME) + " Base")}},[_vm._v(_vm._s(_vm.details.PLANNAME)+" Base")]),_vm._l((_vm.pgBaseData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-700 w-6",class:{
                    'plan-dark-border-right' : item.MONTH === 12
                  }},[(item.AMOUNT_FORMATTED)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)}),_c('td',{staticClass:"tar fw-700 w-8"},[_vm._v(_vm._s(_vm.pgTotalBase))])],2)])]),_c('table',{staticClass:"mt-3"},[_c('tbody',[_c('tr',{staticClass:"total-Compensation"},[_c('td',{staticClass:"fw-700 w-12 textOverflow",attrs:{"title":((_vm.details.PLANNAME) + " Commission")}},[_vm._v(_vm._s(_vm.details.PLANNAME)+" Commission")]),_vm._l((_vm.pgCommissionData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-700 w-6",class:{
                    'plan-dark-border-right' : item.MONTH === 12
                  }},[(item.COMMISSION_FORMATTED)?[_vm._v(" "+_vm._s(item.COMMISSION_FORMATTED)+" ")]:[_vm._v(" - ")]],2)}),_c('td',{staticClass:"tar fw-700 w-8"},[_vm._v(_vm._s(_vm.pgCommissionTotal))])],2)])]),_c('table',{staticClass:"mt-3"},[_c('tbody',[_c('tr',{staticClass:"total-Compensation"},[_c('td',{staticClass:"fw-700 w-12 textOverflow",attrs:{"title":((_vm.details.PLANNAME) + " Adjustments")}},[_vm._v(_vm._s(_vm.details.PLANNAME)+" Adjustments")]),_vm._l((_vm.pgAdjustmentsData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-700 w-6",class:{
                    'plan-dark-border-right' : item.MONTH === 12
                  }},[(item.AMOUNT_FORMATTED)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)}),_c('td',{staticClass:"tar fw-700 w-8"},[_vm._v(_vm._s(_vm.pgAdjustmentsTotal))])],2)])]),_c('table',{staticClass:"mt-3"},[_c('tbody',[_c('tr',{staticClass:"total-Compensation"},[_c('td',{staticClass:"fw-700 w-12 textOverflow",attrs:{"title":((_vm.details.PLANNAME) + " Reimbursements")}},[_vm._v(_vm._s(_vm.details.PLANNAME)+" Reimbursements")]),_vm._l((_vm.pgReimbursementsData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-700 w-6",class:{
                    'plan-dark-border-right' : item.MONTH === 12
                  }},[(item.AMOUNT_FORMATTED)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)}),_c('td',{staticClass:"tar fw-700 w-8"},[_vm._v(_vm._s(_vm.pgReimbursementsTotal))])],2)])]),_c('table',{staticClass:"mt-3"},[_c('tbody',[_c('tr',{staticClass:"total-Compensation"},[_c('td',{staticClass:"fw-700 w-12 textOverflow",attrs:{"title":((_vm.details.PLANNAME) + " Compensation")}},[_vm._v(_vm._s(_vm.details.PLANNAME)+" Compensation")]),_vm._l((_vm.pgCompensationData),function(item,index){return _c('td',{key:index,staticClass:"tar fw-700 w-6",class:{
                    'plan-dark-border-right' : item.MONTH === 12
                  }},[(item.AMOUNT_FORMATTED)?[_vm._v(" "+_vm._s(item.AMOUNT_FORMATTED)+" ")]:[_vm._v(" - ")]],2)}),_c('td',{staticClass:"tar fw-700 w-8"},[_vm._v(_vm._s(_vm.pgCompensationTotal))])],2)])])]:_c('div',{staticClass:"no-result"},[_c('div',{staticClass:"noData"},[_vm._v("No Records Found")])])]],2)]),(_vm.confirmDeletePlanGroup)?_c('ConfirmRemoveItemModal',{ref:"confirmRemoveModal",attrs:{"title":"Delete Plan Group Confirmation","customMessage":"Are you sure you want to delete this?","useLaddaButton":true,"deleting":_vm.deleteLoading},on:{"close":function($event){_vm.confirmDeletePlanGroup = false},"confirm":function($event){return _vm.deletePlanGroup()}}}):_vm._e(),(_vm.showAdjustmentsModal)?_c('AdjustmentsModal',{attrs:{"adjustmentData":_vm.adjustmentData,"data":_vm.empDetails,"lockedMonthData":_vm.lockedMonthData,"selectedAdjustmentMonth":_vm.selectedAdjustmentMonth,"selectedAdjustmentMonthAsString":_vm.selectedAdjustmentMonthAsString},on:{"close":function () {
      _vm.showAdjustmentsModal = false;
    },"reload":function($event){return _vm.reloadPlan()}}}):_vm._e(),(_vm.showReimursementsModal)?_c('ReimursementsModal',{attrs:{"reimursementData":_vm.reimursementData,"data":_vm.empDetails,"lockedMonthData":_vm.lockedMonthData,"selectedReimursementMonth":_vm.selectedReimursementMonth,"selectedReimursementMonthAsString":_vm.selectedReimursementMonthAsString},on:{"close":function () {
      _vm.showReimursementsModal = false;
    },"reload":function($event){return _vm.reloadPlan()}}}):_vm._e(),(_vm.showExportToGustoModal)?_c('ExportToGustoModal',{on:{"close":function () {
      _vm.showExportToGustoModal = false;
    },"export":function (data) { return _vm.exportToGusto(data); }}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"actionsBtn btn gray right"},[_c('span',{staticClass:"name"},[_vm._v("Actions")])])}]

export { render, staticRenderFns }