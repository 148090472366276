





















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: GroupStatusValue;
  system_group: any;
}

interface Events {
  onChangeStatus: GroupStatusValue
}

export enum GroupStatusValue {
  Open = 1,
  Close = 0
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class QuoteStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: GroupStatusValue;

  @Prop({ required: true })
  system_group!: any;

  popoverVisible = false
  optionsVisible = false

  get iconClass() {
    return this.status == GroupStatusValue.Open ? 'green' : (this.status == GroupStatusValue.Close)? 'red' : 'red'
  }

  get statusText() {
    return this.status == GroupStatusValue.Open ? 'Active' : (this.status == GroupStatusValue.Close)? 'Inactive' : 'Deleted'
  }
  
  hideTooltip() {
    this.optionsVisible = false;
  }

  mouseover() {
    if (this.optionsVisible) {
      return
    }

    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false
    
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: GroupStatusValue) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }
}
