import { render, staticRenderFns } from "./customDataAdd.vue?vue&type=template&id=5ae10588&scoped=true"
import script from "./customDataAdd.vue?vue&type=script&lang=tsx"
export * from "./customDataAdd.vue?vue&type=script&lang=tsx"
import style0 from "./customDataAdd.vue?vue&type=style&index=0&id=5ae10588&prod&scoped=true&lang=less"
import style1 from "./customDataAdd.vue?vue&type=style&index=1&id=5ae10588&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae10588",
  null
  
)

export default component.exports