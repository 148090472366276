

























































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { FinancialBoardsAPIRepo, BoardDetailsSortField, BoardDetailsSort } from "@/repos/FinancesDashboardAPIRepo";
import AddFinancialBoardModal from "@/components/AddFinancialBoardModal.vue";
import CustomerAutoSuggestInput from "@/components/CustomerAutoSuggestInput.vue";
import Datepicker from "@/components/Datepicker.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import ExpensesModal from "@/components/Boards/ExpensesModal.vue";
import EmployeePlanModal from "@/components/Boards/EmployeePlanModal.vue";
import EmployeePlanTooltip from "@/components/Boards/EmployeePlanTooltip.vue";
import ExpensesTypeModal from "@/components/Boards/ExpensesTypeModal.vue";
import moment from "moment";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import { notifier } from "@/models/common";

const financialBoardsRepo = new FinancialBoardsAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    AddFinancialBoardModal,
    CustomerAutoSuggestInput,
    Datepicker,
    LaddaButton,
    ExpensesModal,
    EmployeePlanModal,
    EmployeePlanTooltip,
    ExpensesTypeModal,
    ConfirmRemoveItemModal
  }
})

export default class BoardDetails extends TSXComponent<void> {
  $route: any;
  $router: any;
  loading: boolean = false;
  viewType: string = "YTD";
  accounts: any[] = [];
  boardDetails: any = {};
  boardUUID: string = "";
  loadingBoardView: boolean = false;
  sort: BoardDetailsSort = {
    field: 0,
    direction: {
      [BoardDetailsSortField.revenue]: 1,
      [BoardDetailsSortField.profit]: 1
    }
  };
  addFinancialBoardModalVisible: boolean = false;
  businessLineOptions: any = [];
  isActive: string = "accounts";
  expenses: any[] = [];
  saving: boolean = false;
  currYear: number = new Date().getFullYear();
  sortingYear: number | string = this.currYear;
  showExpensesModal: boolean = false;
  showEmployeePlanModal: boolean = false;
  selectedExpensesMonthAsString: string = "";
  expensesMonth: number = 0;
  employeePlans: any = [];
  selectedPlanID: any = [];
  selectedPlans: object[] = [];
  empPlanCnt: number = 0;
  showEmpPlanTooltip: boolean = false;
  selectedEmpPlanData: any = [];
  showExpenseTypeModal: boolean = false;
  expensesTypes: any = [];
  expenseTypeUUID: string = "";
  selectedExpensesTypeName: string = "";
  expensesYear: number = this.currYear;
  expensesTypeName: string = "";
  confirmExpensesTypeDelete: boolean = false;
  deletingExpensesType: boolean = false;

  created(){
    this.boardUUID = this.$route.params.id ? this.$route.params.id : '';
    this.fetchData(true);
  }

  async fetchData(initialLoad: boolean = false, isLoading: boolean = true) {
    if (isLoading) {
      if (initialLoad) {
        this.loading = true;
      } else {
        this.loadingBoardView = true;
      }
    }
    const response: any = await financialBoardsRepo.findOneDetails(
      this.boardUUID,
      this.viewType,
      'boardView',
      '',
      false,
      this.sort,
      this.sortingYear
    );

    if (response.STATUS === 1) {
      if (response.BOARDLIST.length) {
        this.boardDetails = response.BOARDLIST[0];
      }
      this.businessLineOptions = (response.businessLineOptions || []).map(item => ({
        ID: item.ID,
        TEXT: item.BUSINESSLINENAME,
        data: item
      }));
      this.accounts = response.ACCOUNTS || [];
      if (response.EXPENSESDETAILS) {
        this.employeePlans = response.EXPENSESDETAILS.EMPLOYEEPLANS || [];
        this.selectedEmpPlanData = response.EXPENSESDETAILS.SELECTEDEMPPLANS || [];
        this.empPlanCnt = response.EXPENSESDETAILS.EMPPLANCNT || 0;
        let selectedEmpPlanIds = response.EXPENSESDETAILS.SELECTEDEMPPLANIDS || [];
        this.updatePlan(selectedEmpPlanIds);
        this.expensesTypes = response.EXPENSESDETAILS.EXPENSESTYPES || [];
      }
    }
    this.loading = false;
    this.loadingBoardView = false;
  }

  async changeView(type) {
    if (this.viewType != type) {
      this.viewType = type;
      await this.fetchAccountData();
    }
  }

  async fetchAccountData() {
    this.loadingBoardView = true;
    const response: any = await financialBoardsRepo.findOneDetails(
      this.boardUUID,
      this.viewType,
      'loadBoradView',
      '',
      false,
      this.sort
    );

    if (response.STATUS === 1) {
      this.accounts = response.ACCOUNTS || [];
    }
    this.loadingBoardView = false;
  }

  listPageRedirection() {
    this.$router.push({ name: "Boards" });
  }

  openOrderList(item) {
    if (item.ORDERIDS) {
      this.$router.push({
        name: "Orders",
        query: {
          order: item.ORDERIDS.join()
        },
      });
    }
  }

  openOppList(item) {
    if (item.OPPCNTIDS) {
      this.$router.push({
        name: "Opportunities",
        query: {
          ms_opp_id: item.OPPCNTIDS.join()
        },
      });
    }
  }

  sorting(c) {
    switch (c) {
      case "REVENUE":
        this.sortingByRevenue();
        break;
      case "PROFIT":
        this.sortingByProfit();
        break;
    }
  }

  async sortBy(field: BoardDetailsSortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchAccountData();
  }

  async sortingByRevenue() {
    this.sortBy(BoardDetailsSortField.revenue);
  }

  async sortingByProfit() {
    this.sortBy(BoardDetailsSortField.profit);
  }

  async reload(isLoading: boolean = true) {
    this.addFinancialBoardModalVisible = false;
    this.showExpensesModal = false;
    this.showEmployeePlanModal = false;
    await this.fetchData(false, isLoading);
  }

  toggleActive(activeTab: string = "") {
    this.isActive = activeTab;
  }

  yearOptions() {
    const currentYear: number = new Date().getFullYear();
    const options: any[] = [];
    // Add 12 Month, current year, and 5 years before the current year
    for (let i = 0; i <= 5; i++) {
      options.push(currentYear - i);
      if (i === 0) {
        options.push("12 Months");
      }
    }
    return options;
  }

  async changeYear(year) {
    if (this.sortingYear != year) {
      this.sortingYear = year;
      await this.fetchExpensesData();
    }
  }

  resetPlans() {
    this.selectedPlans = [];
    this.selectedPlanID = [];
  }

  updatePlan(planArr = []) {
    this.resetPlans();
    if (planArr.length) {
      planArr.map((id: number) => {
        let empPlanDetail = this.employeePlans.find((item: any) => item.COMPID == id && item.OTEYEAR == this.sortingYear);
        if (empPlanDetail) {
          let tempStruct = {};
          tempStruct["COMPID"] = empPlanDetail.COMPID;
          tempStruct["EMPLOYEENAME"] = empPlanDetail.EMPLOYEENAME;
          this.selectedPlans.push(tempStruct);
          this.selectedPlanID.push(empPlanDetail.COMPID);
        }
      });
    }
  }

  async fetchExpensesData() {
    const response: any = await financialBoardsRepo.findExpensesDetails(
      this.boardUUID,
      this.sortingYear
    );

    if (response.STATUS === 1) {
      if (response.EXPENSESDETAILS) {
        this.employeePlans = response.EXPENSESDETAILS.EMPLOYEEPLANS || [];
        this.selectedEmpPlanData = response.EXPENSESDETAILS.SELECTEDEMPPLANS || [];
        this.empPlanCnt = response.EXPENSESDETAILS.EMPPLANCNT || 0;
        let selectedEmpPlanIds = response.EXPENSESDETAILS.SELECTEDEMPPLANIDS || [];
        this.updatePlan(selectedEmpPlanIds);
        this.expensesTypes = response.EXPENSESDETAILS.EXPENSESTYPES || [];
      }
    }
  }

  loadExpenseType(data) {
    if (data.action === "add") {
      let expensesTypeStruct = {};
      let expensesMonthDataArr: any = [];
  
      const today = new Date();
  
      // Calculate the start date (11 months ago)
      const startDate = new Date(today.getFullYear(), today.getMonth() - 11, 1);
  
      // Calculate the end date (last day of the current month)
      const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
      for (let index = 1; index <= 12; index++) {
        let tempStruct = {};
        tempStruct["MONTH"] = index;
        tempStruct["MONTHASSTRING"] = moment(index, 'M').format('MMMM');
        tempStruct["ISPROFITAVAILABLE"] = false;
        let year = this.sortingYear;
        if (this.sortingYear === "12 Months") {
          year = new Date(startDate).getFullYear();
          if (index < new Date(startDate).getMonth()+1) {
            year++;
          }
        }
        tempStruct["YEAR"] = year;
        expensesMonthDataArr.push(tempStruct);
      }
      expensesTypeStruct["EXPENSETYPENAME"] = data.expensesTypeName || '';
      expensesTypeStruct["EXPENSETYPEUUID"] = data.expenseTypeUUID || '';
      expensesTypeStruct["EXPENSETYPEDATA"] = expensesMonthDataArr;
      expensesTypeStruct["EXPENSESTYPETOTAL"] = 0;
      expensesTypeStruct["EXPENSESTYPETOTAL_FORMATTED"] = "$0.00";
      this.expensesTypes.push(expensesTypeStruct);
    } else {
      this.expensesTypes.map((item: any) => {
        if (item.EXPENSETYPEUUID == (data.expenseTypeUUID || '')) {
          item.EXPENSETYPENAME = data.expensesTypeName || '';
        }
      });
    }
    this.showExpenseTypeModal = false;
  }

  async deleteExpensesType() {
    if (this.expenseTypeUUID) {
      this.deletingExpensesType = true;
      const response: any = await financialBoardsRepo.deleteExpensesType(
        this.boardUUID,
        this.expenseTypeUUID
      );
      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        await this.fetchData(false, false);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }

      this.deletingExpensesType = false;
    }
    this.confirmExpensesTypeDelete = false;
  }
}
