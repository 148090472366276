declare const AWN: any;

interface Notifier {
  warning(message: string): void;
  alert(message: string): void;
  success(message: string): void;
}

export const notifier: Notifier = new AWN({
  position: "bottom-left",
  duration: 10000
});

export function downloadFile(filename: string, content: string) {
  var blob = new Blob([content], { type: "text/html" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var e: any = document.createEvent("MouseEvents"),
      a = document.createElement("a");
    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ["text/html", a.download, a.href].join(":");
    e.initEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
  }
}

export function printHtml(html: string) {
  let ifrm = document.getElementById('iframe') as HTMLIFrameElement
  if (ifrm) {
    document.body.removeChild(ifrm);
  }

  ifrm = document.createElement("iframe") as HTMLIFrameElement;
  sessionStorage.setItem('printHtml', html)
    
  ifrm.setAttribute('id', 'iframe')
  // ifrm.setAttribute('src', `/print.html`);
  ifrm.setAttribute('src', `#/print`);
  ifrm.style.display = 'none'
  document.body.appendChild(ifrm)
  ifrm.onload = function() {
    setTimeout(() => {
      if (!ifrm || !ifrm.contentWindow) return
      ifrm.contentWindow.print();
    }, 300);
  }
}

export function debounce(func: Function, wait: number, immediate?: boolean) {
	var timeout;
	return function(this: any) {
		var context = this, args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	};
}

export function roundCents(num: number): number {
  return Math.round(num * 100) / 100
}

export function roundCentsWithDecimal(num: number): number {
  return Math.round(num * 100000) / 100000
}

export function downloadFileUrl(fileUrl: string) {
  if (!fileUrl) return

  const link = document.createElement("a") as HTMLAnchorElement;
 
  link.setAttribute('target', '_blank')
  link.setAttribute('href', fileUrl)
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link);
}

export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
