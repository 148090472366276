import { render, staticRenderFns } from "./ReportBuilderDetails.vue?vue&type=template&id=324f91bb&scoped=true"
import script from "./ReportBuilderDetails.vue?vue&type=script&lang=tsx"
export * from "./ReportBuilderDetails.vue?vue&type=script&lang=tsx"
import style0 from "./ReportBuilderDetails.vue?vue&type=style&index=0&id=324f91bb&prod&lang=less"
import style1 from "./ReportBuilderDetails.vue?vue&type=style&index=1&id=324f91bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324f91bb",
  null
  
)

export default component.exports