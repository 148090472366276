import { render, staticRenderFns } from "./OrderWarranties.vue?vue&type=template&id=5e7bb2ab&scoped=true"
import script from "./OrderWarranties.vue?vue&type=script&lang=tsx"
export * from "./OrderWarranties.vue?vue&type=script&lang=tsx"
import style0 from "./OrderWarranties.vue?vue&type=style&index=0&id=5e7bb2ab&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7bb2ab",
  null
  
)

export default component.exports