

























































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {}
})
export default class serviceDetails extends TSXComponent<void> {
  allStatus = [
    { id: 1, name: "Checking" },
    { id: 2, name: "Repairing" },
    { id: 3, name: "Testing" },
    { id: 4, name: "Finished" }
  ];
  sStatus: number[] = [];
  allSelected = false;
  selectedID: number[] = [];
  currentRole = sessionStorage.getItem("userRole");
  tableColumns = [];
  details = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
    TOTALALERTS: 0,
    searchByTicket: ""
  };
  dataObj = {};
  created() {
    this.dataObj["controller"] = "Services";
    this.dataObj["FunctionName"] = "List";
  }
  // methods: {
  grabSerrviceData(container) {
    this.fetchServiceData(this.dataObj, this, "", "", "new");
  }
  loadNextpage(pageNumber) {
    this.dataObj["pageNumber"] = pageNumber;
    this.fetchServiceData(this.dataObj, this, "", "", "page");
  }

  sortingByTicket() {
    this.dataObj["order"] = 1;
    this.dataObj["direction"] =
      parseInt(this.details["sortingId"]) === 1 ? 2 : 1;
    this.fetchServiceData(this.dataObj, this, "sortingId", "direction", "");
  }

  sortingBySKU() {
    this.dataObj["order"] = 2;
    this.dataObj["direction"] =
      parseInt(this.details["sortingId"]) === 1 ? 2 : 1;
    this.fetchServiceData(this.dataObj, this, "sortingId", "direction", "");
  }

  sortingByCategory() {
    this.dataObj["order"] = 3;
    this.dataObj["direction"] =
      parseInt(this.details["sortingId"]) === 1 ? 2 : 1;
    this.fetchServiceData(this.dataObj, this, "sortingId", "direction", "");
  }

  sortingByCost() {
    this.dataObj["order"] = 4;
    this.dataObj["direction"] =
      parseInt(this.details["sortingId"]) === 1 ? 2 : 1;
    this.fetchServiceData(this.dataObj, this, "sortingId", "direction", "");
  }

  sortingByReqDate() {
    this.dataObj["order"] = 5;
    this.dataObj["direction"] =
      parseInt(this.details["sortingId"]) === 1 ? 2 : 1;
    this.fetchServiceData(this.dataObj, this, "sortingId", "direction", "");
  }

  sortingByTargetDate() {
    this.dataObj["order"] = 6;
    this.dataObj["direction"] =
      parseInt(this.details["sortingId"]) === 1 ? 2 : 1;
    this.fetchServiceData(this.dataObj, this, "sortingId", "direction", "");
  }
  searchByAll(searchData) {
    this.dataObj["sSearchAll"] = searchData;
    this.fetchServiceData(this.dataObj, this, "searchAll", "sSearchAll", "");
  }

  searchByTicket() {
    this.dataObj["serviceID"] = this.details.searchByTicket;
    this.fetchServiceData(
      this.dataObj,
      this,
      "searchByTicket",
      "serviceID",
      ""
    );
  }

  resetSearchByTicket() {
    $("#searchByTicket").val("");
    this.details.searchByTicket = "";
    this.dataObj["serviceID"] = "";
    this.fetchServiceData(
      this.dataObj,
      this,
      "searchByTicket",
      "serviceID",
      ""
    );
  }

  pdfDownLoadFn(selectedID) {
    var exportObj = {
      controller: "Services",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "PDF";
    exportObj["selectedID"] = selectedID;
    this.downloadProcess(exportObj, this);
  }

  csvDownLoadFn(selectedID) {
    var exportObj = {
      controller: "Services",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "CSV";
    exportObj["selectedID"] = selectedID;
    this.downloadProcess(exportObj, this);
  }

  htmlDownLoadFn(selectedID) {
    var exportObj = {
      controller: "Services",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "HTML";
    exportObj["selectedID"] = selectedID;
    this.downloadProcess(exportObj, this);
  }
  printFn(selectedID) {
    var exportObj = {
      controller: "Services",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole")
    };
    exportObj["ExportType"] = "PDF";
    exportObj["selectedID"] = selectedID;
    exportObj["Print"] = "Yes";
    printProcess(exportObj);
  }
  searchChange(searchData) {
    this.searchByAll(searchData);
  }
  resetStatus() {
    this.sStatus = [];
  }
  selectAllStatus() {
    this.sStatus = [];
    if (!this.allSelected) {
      for (var status in this.allStatus) {
        this.sStatus.push(this.allStatus[status].id);
      }
      this.allSelected = true;
      return;
    }
    this.allSelected = false;
  }
  downloadProcess(data, container) {
    var downLoadRes = getRouteData(data);
    downLoadRes.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS) {
          var link = document.createElement("a");
          link.download = "Quotes";
          link.href = response.data.S3URL;
          link.click();
        } else if (response.data.ERROR) {
          console.log(response.data.ERROR);
        }
      } catch (e) {
        //handle error
        console.log("hardware.js : csvDownLoadFn error : ", e);
      }
    });
  }
  fetchServiceData(dataObj, container, keyName, fieldname, option) {
    let ServicesData = getRouteData(dataObj);
    //now attach a done handler!
    ServicesData.then(function(response, statusText, jqXHR) {
      try {
        if (response.data.STATUS && response.data.STATUSMESSAGE) {
          container.serviceItems = response.data.SERVICES || [];
          container.details = response.data || {};
          container.loading = false;
          if (option === "new") {
            container.details["sortingId"] = 1;
            container.details["sortingTotal"] = 1;
          } else if (option !== "page") {
            container.details[keyName] = dataObj[fieldname];
          }
        } else if (response.data.ERROR) {
          console.log(response.data.ERROR);
        }
      } catch (e) {
        //handle error
        console.log("services.js : grabServicesData error : ", e);
      }
    }).catch(function(error) {
      console.log(error);
    });
  }
  searchByStatus(value) {
    this.dataObj["sStatus"] = value;
    this.fetchServiceData(this.dataObj, this, "status", "sStatus", "");
  }
  // },
  @Watch("sStatus", { deep: true })
  sStatusFunc(to, from) {
    this.searchByStatus(to);
  }
}
