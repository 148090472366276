













import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";

import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import { Source } from "../models/Source";

interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    varsources: Source.Varsources,
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
})
export default class LedgerssourceAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  disabled?: boolean
  

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = []
  searchData: Source.Varsources[] = []

  createOptions(varsources: Source.Varsources[]) {
    const options = varsources.map(
      (item): Option => {
        return {
          value: item.VARSOURCE_ID.toString(),
          text: item.VARSOURCE_NAME
        };
      }
    )

    this.options = options
    this.searchData = varsources

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!search) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        "controller": "Helpers",
        "FunctionName": "financialSource",
        "ReturnType": "json"
      });
      if (response.data.ERROR || !response.data.VARSOURCES) {
        return {
          options: []
        };
      }

      return this.createOptions(response.data.VARSOURCES)
    }

    const exportObj = {
      controller: "Helpers",
      FunctionName: "financialSource",
      ReturnType: "json",
      getAll: 1,
      search
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    if (response.data.ERROR || !response.data.VARSOURCES) {
      return {
        options: []
      };
    }

    return this.createOptions(response.data.VARSOURCES)
  };

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      return
    }
    const varsource = this.searchData.find((item) => item.VARSOURCE_ID.toString() === value)
    const allOption = this.searchData
    if (!varsource) {
      console.warn(`no varsource found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      varsource,
      allOption
    })
  }
}
