
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Model, Emit, Inject } from "vue-property-decorator";
import { SyntheticEvent, InputHTMLAttributes } from "vue-tsx-support/types/dom";

interface Props {
  name: string;
  placeholder: string;
  value: string;
}

interface Events {}

declare const $: any

function formatDate(d: Date) {
  return ((d.getMonth()+1) < 10 ? "0" : "")+(d.getMonth()+1) + '/'+(d.getDate() < 10 ? "0" : "")+ + d.getDate() + "/" + d.getFullYear()
}

@Component({
  inheritAttrs: true,
  inject: ["$validator"]
})
export default class Datepicker extends TSXComponent<Props, Events> {
  @Prop({ required: true, type: String })
  name!: string;

  @Prop({ required: false, type: String })
  className?: string;

  @Prop({ required: true, type: String })
  placeholder!: string;

  @Prop({ required: false, type: String, default: null })
  startDate!: string | null;

  @Prop({ required: false, type: String, default: null })
  endDate!: string | null;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: false })
  inline!: boolean;

  @Prop({ required: false, default: false })
  fromOrderDetails!: boolean;

  @Prop({ required: false, default: null })
  container!: string | null;

  @Prop({ required: false, default: null })
  date!: string | null;

  @Prop({ required: false, default: null })
  filter!: Function;
  
  @Model("input", {
    type: String,
    required: true
  })
  readonly value!: string;

  mounted() {
    var html = '';
        html += '<div id="datepicker" class="datepicker-container">';
        html += '<div class="datepicker-panel" data-view="years picker"><ul class="fr-current"><li data-view="years prev"><img src="images/left_arrow.png" alt="Icon Prev" /></li><li data-view="years current"></li><li data-view="years next"><img src="images/right_arrow.png" alt="Icon Next" /></li></ul><ul data-view="years"></ul></div>';
        html += '<div class="datepicker-panel" data-view="months picker"><ul class="fr-current"><li data-view="year prev"><img src="images/left_arrow.png" alt="Icon Prev" /></li><li data-view="year current"></li><li data-view="year next"><img src="images/right_arrow.png" alt="Icon Next" /></li></ul><ul data-view="months"></ul></div>';
        html += '<div class="datepicker-panel" data-view="days picker"><ul class="fr-current"><li data-view="month prev"><img src="images/left_arrow.png" alt="Icon Prev" /></li><li data-view="month current"></li><li data-view="month next"><img src="images/right_arrow.png" alt="Icon Next" /></li></ul><ul data-view="week"></ul><ul data-view="days"></ul></div>';
        html += '</div>';

    let datePickerOptions: any = {
      autoHide: true,
      zIndex: 2048,
      daysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      template: html,
      offset: this.fromOrderDetails ? -330 : 10,
      inline: this.inline,
      container: this.container,
      date: this.date,
      filter: this.filter
    };

    $('#shippedDate, #orderDate').click(function(){
      $('.datepicker-container.datepicker-dropdown').removeClass('datepicker-top-left').addClass('datepicker-bottom-left shipmentDatePicker');
    });

    // Check if the startDate prop exists and its value
    if (this.startDate) {
      if (this.startDate === 'noPast') {
        datePickerOptions.startDate = new Date();
      } else {
        datePickerOptions.startDate = this.startDate;
      }
    }

    if (this.endDate) {
      datePickerOptions.endDate = this.endDate;
    }

    // Initialize the datepicker
    const $elem = $(this.$refs.input);
    $elem.datepicker(datePickerOptions);

    $elem.on('pick.datepicker', (e) => {
      this.$emit('input', formatDate(e.date));
      this.$emit('change');
      if(e.view == "day") {
        this.$emit('selectedDay', formatDate(e.date));
      }
    });
  }

  setStartDate(date) {
    if (!date) return;
    const $elem = $(this.$refs.input);
    $elem.datepicker("setStartDate", date);
  }

  setEndDate(date) {
    if (!date) return;
    const $elem = $(this.$refs.input);
    $elem.datepicker("setEndDate", date);
  }

  setDate(date) {
    if (!date) return;
    const $elem = $(this.$refs.input);
    $elem.datepicker("setDate", date);
  }

  formatDate(date) {
    if (!date) return;
    const $elem = $(this.$refs.input);
    return $elem.datepicker("formatDate", date);
  }

  destroy() {
    const $elem = $(this.$refs.input);
    $elem.datepicker("destroy");
  }

  // onInput = (event: React.FormEvent<HTMLInputElement>): void => {
  //   const input = event.target as HTMLInputElement;
  //   this.$emit('input', input.value);
  // }
  @Emit("input")
  onInput(e: SyntheticEvent<InputHTMLAttributes, Event>) {
    return e.target.value;
  }

  onChange(e: SyntheticEvent<InputHTMLAttributes, Event>) {
    this.$emit('inputChange', e.target.value);
  }

  $refs!: {
    input: HTMLInputElement
  }

  render() {
    return (
      <input
        ref='input'
        class={this.className}
        type="text"
        id={this.name}
        name={this.name}
        value={this.value}
        placeholder={this.placeholder}
        onInput={this.onInput}
        onChange={this.onChange}
        autoComplete="off"
        disabled={this.disabled}
      />
    );
  }
}
