




















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { PaycheckResp } from "../models/PaycheckResp";

interface Props {
  options: PaycheckResp.Tag[];
  selectedTagIds: number[];
  isSingleSelect: boolean;
  tagFor: string;
  disabled: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class TagMultiselect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: PaycheckResp.Tag[];

  @Prop({ required: true })
  selectedTagIds!: number[];

  @Prop({ required: false, default: false })
  isSingleSelect?: boolean;

  @Prop({ required: false, default: 'Select Tag(s)' })
  tagFor?: string;

  @Prop({ required: false, default: 'Search Tags' })
  placeholder?: string;

  @Prop({ required: false, default: false })
  disabled?: boolean;

  dropdownVisible = false
  search = ''
  selectedTag = 'Select Tag'
  showOnlySelected: boolean = false

  get filteredOptions(): PaycheckResp.Tag[] {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => option.CUSTOMFIELDOPTIONNAME.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }

    if (this.showOnlySelected) {
      options = options.filter((o) => this.selectedTagIds.indexOf(o.CUSTOMFIELDOPTIONID) !== -1)
    }

    return options
  }

  toggleDropdown() {
    if(!this.disabled) {
      this.dropdownVisible = !this.dropdownVisible
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(tagId: number): boolean {
    const checked = !!this.selectedTagIds.find((id) => id === tagId)
    if (tagId === 0 && this.selectedTagIds.includes(tagId) && !checked) {
      return true
    }
    return checked
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  SelectTag(e){
    this.selectedTag = e.CUSTOMFIELDOPTIONNAME;
    this.$emit('check', e.CUSTOMFIELDOPTIONID, e.CUSTOMFIELDOPTIONNAME)
    this.dropdownVisible = false
  }
}
