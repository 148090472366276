








































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../../models/common";
import LaddaButton from "@/components/LaddaButton.vue";
import { loginCheck } from "@/helpers/ApiHelper";

declare const dataURL: string;
declare const $: any;
declare const htmlParse: Function;
declare const htmlCheck: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  },
  methods: {
    loginCheck
  }
})
export default class SubQuoteEndUserCustomData extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: true, default: {} })
  selectedSubQuote!: any;

  @Prop({ required: true, default: "" })
  quoteId!: string;

  @Prop({ required: true, default: [] })
  data!: any;

  loading = false;
  $parent: any;
  $route: any;
  fullCustomData: object[] = [];
  customDataIndex = -1;
  savedCD: any = [];
  saving: boolean | "error" = false;
  submitInfo: any = [];

  get dataFieldCountCheck() {
    let saveAsDefaultQuoteEnduserViewCount = Object.values(
      this.data.saveAsDefaultQuoteEnduserView
    ).filter((item: any) => item === true).length;
    if (saveAsDefaultQuoteEnduserViewCount >= 10) {
      return true;
    }
    return false;
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    this.savedCD = JSON.parse(JSON.stringify(this.selectedSubQuote.euCD || []));

    // populate data for submitInfo
    if (this.selectedSubQuote.euInfo.SUBMITTERNAME || "") {
      this.submitInfo = [
        {
          label: "Request Name",
          value: this.selectedSubQuote.euInfo.REQUESTNAME || ""
        },
        {
          // label: "Submitter Name",
          label:
            this.selectedSubQuote.euInfo.SSSUBMITTERLABEL || "Submitter Name",
          value: this.selectedSubQuote.euInfo.SUBMITTERNAME || ""
        },
        {
          // label: "Submitter Email",
          label:
            this.selectedSubQuote.euInfo.SSSUBMITTEREMAILLABEL ||
            "Submitter Email",
          value: this.selectedSubQuote.euInfo.SUBMITTEREMAIL || ""
        },
        {
          label:
            this.selectedSubQuote.euInfo.SSAPPROVERLABEL || "Approver Name",
          value: this.selectedSubQuote.euInfo.LEASECOORDINATOR || ""
        }
      ];
    }

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
    this.selectedSubQuote.euCD = this.savedCD;
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  // showCustomEditBox(index) {
  //   this.customDataIndex = index;

  //   setTimeout(() => {
  //     $(".custom-data-txt")
  //       .focus()
  //       .select();
  //   }, 100);
  // }

  // hideCustomEditBox() {
  //   this.customDataIndex = -1;
  // }

  get changedValues() {
    if (
      JSON.stringify(this.savedCD) !=
      JSON.stringify(this.selectedSubQuote.euCD || [])
    ) {
      return true;
    }

    return false;
  }

  async updateCustomData() {
    // remove HTML
    const euData = this.selectedSubQuote.euCD || [];
    for (const item of euData) {
      if ((item.customDtype || 1) != 3) {
        item.customValue = this.removeHTML(item.customValue);
      }
    }

    try {
      this.saving = true;
      const response = await ApiHelper.callApi("post", {
        Controller: "Quotes",
        FunctionName: "UpdateSubQuoteEUData",
        euData: euData.map(t => ({
          customFieldId: t.customFieldId,
          customValue: t.customValue
        })),
        subQuoteId: this.selectedSubQuote.subQuoteId,
        quoteId: this.quoteId
      });
      this.saving = false;

      if (response.STATUS == 1) {
        notifier.success(response.STATUSMESSAGE);

        // update saved values
        this.$nextTick().then(() => {
          this.savedCD = JSON.parse(
            JSON.stringify(this.selectedSubQuote.euCD || [])
          );
        });
      }
    } catch (err) {
      this.saving = "error";
      console.log(err.message);
    }
  }

  async saveDefaultQuoteEnduserView() {
    const response = await ApiHelper.callApi("post", {
      controller: "Customers",
      FunctionName: "UpdateAccountParams",
      saveAsDefaultQuoteEnduserView: this.data.saveAsDefaultQuoteEnduserView,
      aID: this.data.QUOTEDETAILS.AID,
      action: "saveAsDefaultQuoteEnduserView"
    });
    if (response.STATUS === 1) {
      notifier.success(
        "Successfully updated the default view for this Account"
      );
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }
}
