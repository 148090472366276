







































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import GlobalTagSelect from "../GlobalTagSelect.vue";
import { notifier } from "../../models/common";
import DropdownControl from "@/components/DropdownControl.vue";
import opportunityAutoSuggestInput from "@/components/opportunityAutoSuggestInput.vue";
import LaddaButton from "@/components/LaddaButton.vue";

declare const dataURL: string;
declare const $: any;
declare const htmlParse : Function;
declare const htmlCheck : Function;

interface Props {
  data: any;
  title: string;
}

@Component({
  inheritAttrs: false,
  components: {
    DropdownControl,
    GlobalTagSelect,
    opportunityAutoSuggestInput,
    LaddaButton
  }
})
export default class ModalEditQuoteDataField extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: true, default: [] })
  data!: any;

  @Prop({ required: true, default: '' })
  title!: string;


  loading = false;
  $parent: any;
  $route: any;
  fullCustomData: object[] = [];
  quoteContractNumber: any = [];
  selectedQuoteContracts = "";
  quoteContractsID: number[] = [];
  globalTags: any = [];
  selectedGlobalTagsID: number[] = [];
  selectedGlobalTags: object[] = [];
  customDataIndex = -1;
  customSourceData = 0;
  NASPOcontract = "";
  cfIds: any = [];
  opportunityName: string = "";
  opportunityID: number = 0;
  opportunityPrevID: number = 0;
  savingOpp: boolean = false;

  dataFields = {
    priceTypeIds: [0],
    priceType: 0,
  };

  @Watch("opportunityName")
  async opportunityChanged(val) {
    if(val == null) {
      this.opportunityName = "";
      this.opportunityID = 0;
    }
  }

  async created() {
    const priceType = this.data.QUOTEDETAILS.PRICETYPE || 0;
    this.dataFields.priceTypeIds = [priceType];
    this.dataFields.priceType = priceType;

    this.opportunityID = typeof this.data.QUOTEDETAILS.OPPORTUNITYID != "undefined" ? this.data.QUOTEDETAILS.OPPORTUNITYID : 0;
    this.opportunityPrevID = typeof this.data.QUOTEDETAILS.OPPORTUNITYID != "undefined" ? this.data.QUOTEDETAILS.OPPORTUNITYID : 0;
    // this.opportunityName = typeof this.data.QUOTEDETAILS.OPPORTUNITYID != "undefined" && this.data.QUOTEDETAILS.OPPORTUNITYID ? `MOP-${this.data.QUOTEDETAILS.OPPORTUNITYID}` : '';
    await this.showCustomData();

    if (typeof this.data.QUOTEDETAILS.OPPORTUNITYID != "undefined" && this.data.QUOTEDETAILS.OPPORTUNITYID) {
      await this.getOppDetail();
    }
  }

  get dataFieldCountCheck() {
    let saveAsDefaultQuoteViewCount = Object.values(this.data.saveAsDefaultQuoteView).filter((item: any) => item === true).length;
    if (saveAsDefaultQuoteViewCount >= 10) {
      return true;
    }
    return false;
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async getOppDetail() {
    const exportObj = {
      controller: "Queries",
      subsystem: "Helpers",
      FunctionName: "opportunities",
      action: 'findById',
      ms_opp_id: this.data.QUOTEDETAILS.OPPORTUNITYID
    };

    const response = await ApiHelper.callApi("post", exportObj);
    if (response.STATUS == 1) {
      this.opportunityName = response.OPPORTUNITYDETAIL.DESCRIPTION ? response.OPPORTUNITYDETAIL.DESCRIPTION :`MOP-${this.data.QUOTEDETAILS.OPPORTUNITYID}`;
    }
  }

  async showCustomData() {
    try {
      this.loading = true;
      const res = await ApiHelper.callApi(
        'post',
        {
          controller: "VARSources",
          FunctionName: "GlobalParams",
          subsystem: "VAR360",
          action: "list"
        }
      );
      this.cfIds = res.cfIds || {};
      const ignoreFieldIds = [26];
      // if((cfIds.HPQuoteNoCFieldId || 0) > 0) {
      //   ignoreFieldIds.push(cfIds.HPQuoteNoCFieldId);
      // }
      // if((cfIds.BigDealNoCFieldId || 0) > 0) {
      //   ignoreFieldIds.push(cfIds.BigDealNoCFieldId);
      // }
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomData",
          DataType: "2",
          Id: this.$route.params.id,
          contractDropdown : 26,
          aId: this.data.QUOTEDETAILS.AID,
          ignoreFieldIds: ignoreFieldIds.join(",")
        }
      );

      if (response.STATUS == 1) {
        this.fullCustomData = [];
        for (var val of response.CUSTOMDEFINITION) {
          let tmpValue = response.CUSTOMDATA.filter(
            tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID
          );
          this.fullCustomData.push({
            CUSTOMFIELDID: val.CUSTOMFIELDID,
            CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
            CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
            CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
            CUSTOMDTYPE: val.CUSTOMDTYPE || 1,
            OPTIONS: val.OPTIONS || []
          });
          this.quoteContractNumber = response.customContractNumber;
          const selectedContracts = response.selectedContractData;
          if(selectedContracts.length) {
            this.selectedQuoteContracts = selectedContracts[0].CUSTOMFIELDOPTIONNAME;
            this.quoteContractsID = selectedContracts.map(
              (val: any) => val.CUSTOMFIELDOPTIONID
            );
          }
        }

        this.globalTags = response.globalTags.filter(item => item.FORQUOTES);
        this.selectedGlobalTags = response.selectedGlobalTags.map(
          (val: any) => ({
            CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
            CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME,
            FORQUOTES: val.FORQUOTES
          })
        ).filter(item => item.FORQUOTES);
        this.selectedGlobalTagsID = this.selectedGlobalTags.map(
          (val: any) => val.CUSTOMFIELDOPTIONID
        );
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  async updateCustomData(item) {
    //remove HTML
    if((item.CUSTOMDTYPE || 1) != 3) {
      item.CUSTOMVALUE = this.removeHTML(item.CUSTOMVALUE);
    }

    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          customID: item.CUSTOMID,
          customFieldID: item.CUSTOMFIELDID,
          customValue: item.CUSTOMVALUE,
          Id: this.$route.params.id,
          qQuoteID : item.CUSTOMFIELDID == this.cfIds.HPQuoteNoCFieldId ? 1 : 0,
          bigDealNo: item.CUSTOMFIELDNAME.toLowerCase() == "big deal number" ? 1 : 0,
          fieldTypeId: 2
        }
      );

      if (response.STATUS == 1) {
        this.customDataIndex = -1;
        this.$parent.fullCustomData.map((customDataItem: any) => {
          if (customDataItem.CUSTOMFIELDID === item.CUSTOMFIELDID) {
            customDataItem.CUSTOMVALUE = item.CUSTOMVALUE;
          }
        });
        if (item.CUSTOMVALUE == "") {
          //delete this item
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = 0;
        } else if (typeof response.NEWCUSTOMID != "undefined") {
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = response.NEWCUSTOMID;
        }
        notifier.success(response.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  showCustomEditBox(customFieldId) {
    this.customDataIndex = customFieldId;

    setTimeout(() => {
      $(".custom-data-txt")
        .focus()
        .select();
    }, 100);
  }

  hideCustomEditBox() {
    this.customDataIndex = -1;
  }

  showSourceCustomEditBox(val) {
    this.customSourceData = val;
    setTimeout(() => {
      $(".custom-data-source")
        .focus()
        .select();
    }, 100);
  }

  hideSourceCustomEditBox() {
    this.customSourceData = -1;
  }

  async updateCustomSoureData(source) {
    //remove HTML
    source = this.removeHTML(source);
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          customSourceName: source,
          isQuoteDetail: true,
          Id: this.$route.params.id
        }
      );

      if (response.STATUS == 1) {
        this.customSourceData = -1;
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  //Select Conract Number
  toggleSelectedContractNumber(selectedID: number, contractName) {
    const index = this.quoteContractsID.findIndex(id => id === selectedID);
    let bigDealId = this.NASPOcontract;
    const contractGTags = this.globalTags.filter(tmp => tmp.CUSTOMFIELDOPTIONNAME == contractName);
    if (index === -1) {
      this.quoteContractsID = [selectedID];
      this.selectedQuoteContracts = contractName
      this.$parent.selectedQuoteContracts = contractName;
      let tmpContractObj = this.quoteContractNumber.find(
        tmp => tmp.CUSTOMFIELDOPTIONID == selectedID
      );
      if (typeof tmpContractObj != 'undefined' && typeof tmpContractObj.CUSTOMFIELDOPTIONDESC != "undefined") {
        let descPrefix = tmpContractObj.CUSTOMFIELDOPTIONDESC.length ? ':' : '';
        bigDealId = tmpContractObj.CUSTOMFIELDOPTIONNAME + descPrefix + tmpContractObj.CUSTOMFIELDOPTIONDESC;    
      }
      //Add Related Global Tags
      const selectedGTags = this.selectedGlobalTags.findIndex((tmp: any) => tmp.CUSTOMFIELDOPTIONNAME.toUpperCase() == contractName.toUpperCase());
      if(selectedGTags == -1 && contractGTags.length) {
        this.selectedGlobalTagsID.push(contractGTags[0].CUSTOMFIELDOPTIONID);
        this.selectedGlobalTags.push({
          CUSTOMFIELDOPTIONID: contractGTags[0].CUSTOMFIELDOPTIONID,
          CUSTOMFIELDOPTIONNAME: contractGTags[0].CUSTOMFIELDOPTIONNAME
        });
        this.$parent.selectedGlobalTags.push({
          CUSTOMFIELDOPTIONID: contractGTags[0].CUSTOMFIELDOPTIONID,
          CUSTOMFIELDOPTIONNAME: contractGTags[0].CUSTOMFIELDOPTIONNAME
        });
      }
      this.saveContractNumber(bigDealId);
      this.saveGlobalTag();
      return;
    }
    this.quoteContractsID.splice(index, 1);
    this.selectedQuoteContracts = "";
    this.$parent.selectedQuoteContracts = "";
    this.saveContractNumber(bigDealId);
    //Remove Related Global Tags
    if(contractGTags.length) {
      const tagIndex = this.selectedGlobalTagsID.findIndex(id => id === contractGTags[0].CUSTOMFIELDOPTIONID);
      this.selectedGlobalTagsID.splice(tagIndex, 1);
      this.selectedGlobalTags.splice(tagIndex, 1);
      this.$parent.selectedGlobalTags.splice(tagIndex, 1);
      this.saveGlobalTag();
    }
  }

  //Save Contract Number
  async saveContractNumber(bigDealId = "") {
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          optionIDs: this.selectedQuoteContracts,
          Id: this.$route.params.id,
          fieldId: this.quoteContractNumber[0].CUSTOMFIELDID,
          action: "save_ContractNumber",
          fieldTypeId: 2
        }
      );
      if(response.STATUS === 1 && bigDealId != "") {
        this.$parent.bigDealId = bigDealId;
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  async saveGlobalTag() {
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          optionIDs: this.selectedGlobalTagsID.join(","),
          Id: this.$route.params.id,
          action: "save_globalTags",
          fieldTypeId: 2
        }
      );
    } catch (err) {
      // console.log(err.message);
    }
  }

  toggleSelectedGlobalTags(selectedID: number, tagName) {
    const index = this.selectedGlobalTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedGlobalTagsID.push(selectedID);
      this.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      this.$parent.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      this.saveGlobalTag();
      return;
    }

    this.selectedGlobalTagsID.splice(index, 1);
    this.$delete(this.selectedGlobalTags, index);
    this.$delete(this.$parent.selectedGlobalTags, index);
    this.saveGlobalTag();
  }

  selectAllGlobalTags() {
    this.resetGlobalTags();
    this.selectedGlobalTagsID = this.globalTags.map(
      (val: any) => val.CUSTOMFIELDOPTIONID
    );
    this.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    this.$parent.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    this.saveGlobalTag();
  }

  resetGlobalTags() {
    this.selectedGlobalTags = [];
    this.$parent.selectedGlobalTags = [];
    this.selectedGlobalTagsID = [];
    this.saveGlobalTag();
  }

  updateGlobalTagList(type, index, tagID) {
    if (this.selectedGlobalTagsID && type == "delete") {
      this.$delete(this.selectedGlobalTagsID, index);
      this.$delete(this.selectedGlobalTags, index);
      this.$delete(this.$parent.selectedGlobalTags, index);
    }
    this.saveGlobalTag();
  }

  async toggleSelectedPriceType(selectedID: number) {
    const oldPriceType = this.dataFields.priceType;
    this.dataFields.priceTypeIds = [selectedID];
    this.dataFields.priceType = selectedID;
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          priceType: this.dataFields.priceType,
          Id: this.$route.params.id,
          action: "save_priceType",
          isQuoteDetail: true,
          fieldTypeId: 1
        }
      );
      if (response.STATUS == 1) {
        ApiHelper.showSuccessMessage('Updated Price Type successfully!');
      } else {
        this.dataFields.priceType = oldPriceType; // rollback priceType if error
        ApiHelper.showErrorMessage(response.STATUSMESSAGE || 'Something was wrong');
      }
      this.data.QUOTEDETAILS.PRICETYPE = this.dataFields.priceType;
      this.$emit("updateCustomPriceType", {
        priceType: this.dataFields.priceType
      });
    } catch (err) {
      // console.log(err.message);
    }
  }

  async saveDefaultQuoteView() {
    const response = await ApiHelper.callApi(
      'post',
      {
        controller: "Users",
        FunctionName: "paramsUpdate",
        saveAsDefaultQuoteView: this.data.saveAsDefaultQuoteView,
        action: "saveAsDefaultQuoteView"
      }
    );
    if (response.STATUS === 1) {
      notifier.success("Successfully updated the default view for this User");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }

  get cfSourceQuoteId() {
    return this.fullCustomData.find((item: any) => item.CUSTOMFIELDID == this.cfIds.HPQuoteNoCFieldId);
  }

  get cfBigDealNumber() {
    return this.fullCustomData.find((item: any) => item.CUSTOMFIELDID == this.cfIds.BigDealNoCFieldId);
  }

  get topData() {
    let ret: any = [];
    if(this.cfSourceQuoteId) {
      ret.push(this.cfSourceQuoteId);
    }
    if(this.cfBigDealNumber) {
      ret.push(this.cfBigDealNumber);
    }

    return ret;
  }

  async updateOpportunity(data) {
    this.opportunityID = data.option.value || 0;
  }

  async saveOpportunity() {
    this.savingOpp = true;
    const response = await ApiHelper.callApi(
      'post',
      {
        controller          : "Quotes",
        FunctionName        : "UpdateOpportunity",
        opportunityID       : this.opportunityID,
        opportunityPrevID   : this.opportunityPrevID,
        quoteID             : this.data.QUOTEDETAILS.QUOTEID || ''
      }
    );
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.$parent.details.QUOTEDETAILS.OPPORTUNITYID = this.opportunityID;
      this.opportunityPrevID = this.opportunityID;
      if (!this.opportunityID) {
        this.opportunityName = "";
      }
    }
    this.savingOpp = false;
  }

  checkOpp() {
    return this.opportunityID == this.opportunityPrevID ? true : false;
  }
}
