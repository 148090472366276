var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_header : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_paragraph : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":8},"end":{"line":68,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRemitAddress : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":8},"end":{"line":72,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInfoHeader : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":8},"end":{"line":75,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_table : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":8},"end":{"line":103,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCoreProduct : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":8},"end":{"line":117,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isFooterDetail : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":8},"end":{"line":120,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_data_fields : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":8},"end":{"line":175,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":10},"end":{"line":50,"column":17}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <table width=\"100%\" class=\"element-header "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":54},"end":{"line":45,"column":116}}})) != null ? stack1 : "")
    + " element-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":45,"column":125},"end":{"line":45,"column":131}}}) : helper)))
    + "\">\n              <tr>\n                <td class=\"element-header-content\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":47,"column":51},"end":{"line":47,"column":60}}}) : helper)))
    + "</td>\n              </tr>\n            </table>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":45,"column":93},"end":{"line":45,"column":109}}}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":10},"end":{"line":67,"column":17}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <table width=\"100%\" class=\"element-paragraph "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":57},"end":{"line":55,"column":122}}})) != null ? stack1 : "")
    + " element-"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":55,"column":131},"end":{"line":55,"column":137}}}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.header : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":14},"end":{"line":60,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":14},"end":{"line":65,"column":21}}})) != null ? stack1 : "")
    + "            </table>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-paragraph-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":55,"column":99},"end":{"line":55,"column":115}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <tr>\n                  <td class=\"element-paragraph-header\">"
    + container.escapeExpression(((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"header","hash":{},"data":data,"loc":{"start":{"line":58,"column":55},"end":{"line":58,"column":65}}}) : helper)))
    + "</td>\n                </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <tr>\n                  <td class=\"element-paragraph-content\">"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":63,"column":56},"end":{"line":63,"column":65}}}) : helper)))
    + "</td>\n                </tr>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/indirect_invoiceDetails_header.handlebars"),depth0,{"name":"indirect_invoiceDetails_header","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/indirect_invoiceDetails_address.handlebars"),depth0,{"name":"indirect_invoiceDetails_address","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":10},"end":{"line":85,"column":17}}})) != null ? stack1 : "")
    + "          <table width=\"100%\" class=\"element-table "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":51},"end":{"line":87,"column":112}}})) != null ? stack1 : "")
    + "\">\n            <thead>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.headers : stack1),{"name":"each","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":14},"end":{"line":91,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.rows : stack1),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":14},"end":{"line":100,"column":23}}})) != null ? stack1 : "")
    + "            </tbody>\n          </table>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <table width=\"100%\" class=\"element-header "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":54},"end":{"line":80,"column":116}}})) != null ? stack1 : "")
    + " element-header-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":80,"column":132},"end":{"line":80,"column":138}}}) : helper)))
    + "\">\n              <tr>\n                <td class=\"element-header-content\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":82,"column":51},"end":{"line":82,"column":60}}}) : helper)))
    + "</td>\n              </tr>\n            </table>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-table-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":87,"column":89},"end":{"line":87,"column":105}}}) : helper)));
},"23":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <th>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":90,"column":20},"end":{"line":90,"column":29}}}) : helper)))
    + "</th>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":16},"end":{"line":98,"column":25}}})) != null ? stack1 : "")
    + "              </tr>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                  <td>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":97,"column":22},"end":{"line":97,"column":31}}}) : helper)))
    + "</td>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n            <tr style=\"color: #b1b1b1; height: 40px;\">\n              <td width=\"37%\" style=\"border-bottom: 5px solid #b1b1b1;\">LINE ITEM</td>\n              <td width=\"15%\" style=\"padding-left: 5px; text-align: left; border-bottom: 5px solid #b1b1b1;\">SKU</td>\n              <td width=\"15%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">PRICE PER</td>\n              <td width=\"13%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">QTY</td>\n              <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">TOTAL</td>\n            </tr>\n"
    + ((stack1 = container.invokePartial(require("./partials/indirect_invoiceDetails_items.handlebars"),depth0,{"name":"indirect_invoiceDetails_items","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/indirect_invoiceDetails_summary.handlebars"),depth0,{"name":"indirect_invoiceDetails_summary","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </table>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/indirect_invoiceDetails_message.handlebars"),depth0,{"name":"indirect_invoiceDetails_message","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <table width=\"100%\" style=\"padding: 10px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.serialNumber),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":12},"end":{"line":141,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.assetTag),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":12},"end":{"line":159,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.trackingInfo),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":160,"column":12},"end":{"line":173,"column":19}}})) != null ? stack1 : "")
    + "          </table>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr>\n                <td>\n                  <span class=\"title_detail\" style=\"font-weight: bold;\">SERIAL NUMBER</span>\n                  <div style=\"padding: 0 10px\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.INVOICEITEMS),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":20},"end":{"line":137,"column":29}}})) != null ? stack1 : "")
    + "                  </div>\n                </td>\n              </tr>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.SERIALNUMBER : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":22},"end":{"line":136,"column":29}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <span style=\"font-weight: bold;\">"
    + alias4(((helper = (helper = helpers.IPRODSKU || (depth0 != null ? depth0.IPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IPRODSKU","hash":{},"data":data,"loc":{"start":{"line":131,"column":57},"end":{"line":131,"column":71}}}) : helper)))
    + " / "
    + alias4(((helper = (helper = helpers.PRODUCTNAME || (depth0 != null ? depth0.PRODUCTNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PRODUCTNAME","hash":{},"data":data,"loc":{"start":{"line":131,"column":74},"end":{"line":131,"column":89}}}) : helper)))
    + ":</span>\n                        <div style=\"font-size: 11px;\">\n                          "
    + alias4(((helper = (helper = helpers.SERIALNUMBER || (depth0 != null ? depth0.SERIALNUMBER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SERIALNUMBER","hash":{},"data":data,"loc":{"start":{"line":133,"column":26},"end":{"line":133,"column":44}}}) : helper)))
    + "\n                        </div>\n                        <br />\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr>\n                <td>\n                  <span class=\"title_detail\" style=\"font-weight: bold;\">ASSET TAG</span>\n                  <div style=\"padding: 0 10px\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.INVOICEITEMS),{"name":"each","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":20},"end":{"line":155,"column":29}}})) != null ? stack1 : "")
    + "                  </div>\n                </td>\n              </tr>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ASSETTAG : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":22},"end":{"line":154,"column":29}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <span style=\"font-weight: bold;\">"
    + alias4(((helper = (helper = helpers.IPRODSKU || (depth0 != null ? depth0.IPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IPRODSKU","hash":{},"data":data,"loc":{"start":{"line":149,"column":57},"end":{"line":149,"column":71}}}) : helper)))
    + " / "
    + alias4(((helper = (helper = helpers.PRODUCTNAME || (depth0 != null ? depth0.PRODUCTNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PRODUCTNAME","hash":{},"data":data,"loc":{"start":{"line":149,"column":74},"end":{"line":149,"column":89}}}) : helper)))
    + ":</span>\n                        <div style=\"font-size: 11px;\">\n                          "
    + alias4(((helper = (helper = helpers.ASSETTAG || (depth0 != null ? depth0.ASSETTAG : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ASSETTAG","hash":{},"data":data,"loc":{"start":{"line":151,"column":26},"end":{"line":151,"column":40}}}) : helper)))
    + "\n                        </div>\n                        <br />\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr>\n                <td>\n                  <span class=\"title_detail\" style=\"font-weight: bold;\">TRACKING INFORMATION(S)</span>\n                  <div style=\"padding: 0 10px\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.VARactivity_details),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":165,"column":20},"end":{"line":169,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n                </td>\n              </tr>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.VARactivity_details),{"name":"each","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":22},"end":{"line":168,"column":31}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div style=\"padding: 5px 0;\">"
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "</div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <div>\n          <table cellspacing=\"0\" cellpadding=\"0\">\n            <tr>\n              <td style=\"padding-left: 5px;\">\n                <a href=\""
    + container.escapeExpression(((helper = (helper = helpers.portalLink || (depth0 != null ? depth0.portalLink : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"portalLink","hash":{},"data":data,"loc":{"start":{"line":183,"column":25},"end":{"line":183,"column":39}}}) : helper)))
    + "\" style=\"display: inline-block\"><img title=\"Portal Link\" src=\"https://var360.dev-us.com/images/cart.svg\"></a>\n              </td>\n              <td style=\"padding-left: 5px;\">\n                <span>Submit payment via credit card</span>\n              </td>\n            </tr>\n          </table>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!DOCTYPE html>\n<html>\n  <head>\n    <link href=\"https://var360.s3.amazonaws.com/public/css/export.css\" rel=\"stylesheet\" />\n    <link href=\"https://fonts.googleapis.com/css?family=Work+Sans&display=swap\" rel=\"stylesheet\">\n    <style>\n      .check {\n        height: 7px !important;\n        width: 7px !important;\n      }\n      .config-line {\n        color: #b1b1b1;\n      }\n\n      /* custom style template builder */\n      .element-header-type2, \n      .element-paragraph-type2,\n      .element-table-type2 {\n        padding: 0 10px;\n      }\n      .element-header-type2 td, .element-paragraph-type2 td {\n      }\n      .element-header-type2 .element-header-content,\n      .element-paragraph-type2 .element-paragraph-header,\n      .element-table-type2 th {\n        padding-top: 10px;\n        padding-bottom: 3px;\n        text-transform: uppercase;\n        color: #b1b1b1;\n        font-weight: bold;\n        font-family: sans-serif;\n      }\n      .element-table-type2 th {\n        text-align: left;\n        font-weight: 400;\n        padding-top: 0;\n      }\n    </style>\n  </head>\n  <body>\n    <div id=\"container\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.templateItemReorder : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":176,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.portalLink : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":6},"end":{"line":191,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </body>\n</html>\n";
},"usePartial":true,"useData":true});