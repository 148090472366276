





















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: SpiffStatusValue;
}

interface Events {
  onChangeStatus: SpiffStatusValue;
}

export enum SpiffStatusValue {
  Pending = 0,
  Paid = 1,
  Cancel = 2
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class paystatuscomponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: SpiffStatusValue;

  @Prop({ required: true, default: "open" })
  statustext!: string;

  popoverVisible = false;
  optionsVisible = false;

  get iconClass() {
    const icon =
      this.status === 1
      ? "green"
      : this.status === 0
      ? "purple"
      :  "red";
      return icon
  }

  get statusText() {
    const status =  this.status === 1
      ? "Paid"
      : this.status === 0
      ? "Pending"
      : "Cancel";

      return status
  }

  mouseover() {
    if (this.optionsVisible) {
      return;
    }

    this.popoverVisible = true;
  }

  mouseout() {
    this.popoverVisible = false;
  }

  hideTooltip() {
    this.optionsVisible = false;
  }
  click() {
    this.popoverVisible = false;

    this.optionsVisible = !this.optionsVisible;
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false;
    }
  }

  itemClick(status: SpiffStatusValue) {
    this.$emit("changeStatus", status);
    this.optionsVisible = false;
  }
}
