var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"allow-expand-full api-keys-page",attrs:{"id":"container"}},[_c('add-header'),_vm._m(0),_c('div',{staticClass:"page-header-details"}),_c('form',{class:{ 'was-validated': _vm.errors.items.length },attrs:{"novalidate":"","id":"credentialsFrm"},on:{"submit":function (e) { return _vm.validateBeforeSubmit(e); }}},[_c('div',{staticClass:"bkgFill"},[(_vm.currentRole == 'Reseller')?_c('div',{staticClass:"profile-details mt-0",attrs:{"id":"page-details"}},[_c('div',{staticClass:"profile-row",attrs:{"id":"details-content"}},[_c('div',{staticClass:"profile-row",attrs:{"id":"api-data"}},[_c('div',{staticClass:"profile-row"},[_c('button',{staticClass:"btn btn-primary",style:(_vm.varApiStatusCount >= _vm.limitTotalApi
                    ? 'opacity: .65;cursor: default;'
                    : ''),attrs:{"type":"button","disabled":_vm.varApiStatusCount >= _vm.limitTotalApi,"data-dismiss":"modal"},on:{"click":function($event){_vm.apiModalVisible = true}}},[_vm._v(" GENERATE API KEY ")])]),_c('div',{staticClass:"page-list",attrs:{"id":"page-list"}},[_c('div',{staticClass:"page-list-container"},[_c('table',[_vm._m(1),_c('tbody',[(_vm.loading)?_c('Loader'):_vm._l((_vm.varSourceAPI),function(varSourceAPI,index){return _c('tr',{key:index},[_c('td',{staticClass:"col-APIKEYID"},[_c('div',{staticClass:"left",attrs:{"title":varSourceAPI.APIKEYID}},[_c('div',{staticClass:"textOverflow"},[_vm._v(" "+_vm._s(varSourceAPI.APIKEYID)+" ")])])]),_c('td',{staticClass:"col-STATUS"},[_c('div',{staticClass:"left",style:(_vm.varApiStatusCount >= _vm.limitTotalApi &&
                            !varSourceAPI.STATUS
                              ? 'opacity: .65;pointer-events: none;'
                              : '')},[_c('span',{staticClass:"api-status"},[_c('UserStatus',{attrs:{"status":varSourceAPI.STATUS,"shortStatusText":true},on:{"changeStatus":function (status) {
                                  _vm.changeStatus(
                                    varSourceAPI.APIKEYID,
                                    status,
                                    'update'
                                  );
                                }}})],1)])]),_c('td',{staticClass:"col-BUTTON"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"deleteIcon",attrs:{"src":"images/close_search.png","id":"deleteApiKey","title":"Remove"},on:{"click":function($event){return _vm.changeStatus(varSourceAPI.APIKEYID, 1, 'delete')}}})])])])})],2)]),(_vm.varSourceAPI.length === 0 && !_vm.loading)?_c('div',{staticClass:"noData"},[_vm._v("No Records Found")]):_vm._e()])])])])]):_vm._e()])]),(_vm.apiModalVisible)?_c('apiModal',{on:{"close":function($event){_vm.apiModalVisible = false},"submit":_vm.apiSubmit,"generated":_vm.apiModalReload}}):_vm._e(),_c('add-footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-header up",attrs:{"id":"page-header"}},[_c('span',{staticClass:"page-title left",attrs:{"data-type":"admin"}},[_vm._v("Api Keys")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"list-tab w-50"},[_c('div',{staticClass:"head-item",attrs:{"title":"API Key"}},[_c('div',{staticClass:"left noOverflow"},[_vm._v("API Key")])])]),_c('th',{staticClass:"list-tab w-25"},[_c('div',{staticClass:"head-item",attrs:{"title":"Status"}},[_c('div',{staticClass:"left noOverflow"},[_vm._v("Status")])])]),_c('th',{staticClass:"list-tab w-25"},[_c('div',{staticClass:"head-item",attrs:{"title":"Actions"}},[_c('div',{staticClass:"left noOverflow"},[_vm._v("Actions")])])])])])}]

export { render, staticRenderFns }