import { render, staticRenderFns } from "./AcctsCommUpdateModal.vue?vue&type=template&id=5da94729&scoped=true"
import script from "./AcctsCommUpdateModal.vue?vue&type=script&lang=tsx"
export * from "./AcctsCommUpdateModal.vue?vue&type=script&lang=tsx"
import style0 from "./AcctsCommUpdateModal.vue?vue&type=style&index=0&id=5da94729&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5da94729",
  null
  
)

export default component.exports