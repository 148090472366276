































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { notifier } from "../models/common";
import PageTitle from "../components/pageTitle.vue";
import LaddaButton from "../components/LaddaButton.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    PageTitle,
    LaddaButton,
    ConfirmRemoveItemModal
  }
})
export default class TestOrderStatus extends TSXComponent<void> {
  $root: any;
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  formAction = dataURL;
  message = "";
  uploading: any = false;
  redirectConfirmVisible = false;

  async validateBeforeSubmit(e) {
    // this.message = "";
    const files = $("#orderStatusFiles").get(0).files || [];
    // let valid = true;
    if (!files.length) {
      // valid = false;
      return;
    }
    // else {
    //   let length = 0;
    //   for (const item of files) {
    //     if (
    //       item.name.indexOf("OpenOrder") != -1 ||
    //       item.name.indexOf("ShippedOrder") != -1
    //     ) {
    //       length += 1;
    //     }
    //   }
    //   if (length < 2) {
    //     valid = false;
    //   }
    // }

    // if (!valid) {
    //   this.message = "Need 2 files 'OpenOrderReport' and 'ShippedOrderReport'";
    //   e.preventDefault();
    //   e.stopPropagation();
    // }

    const fileName = files[0].name;
    const allowedExtensions = /(\.csv)$/i;
    if (!allowedExtensions.exec(fileName)) {
      this.message = "Please upload file having extensions .csv only.";
      return;
    }

    try {
      this.uploading = true;
      this.message = "";
      // this.updateMap = [];

      //send data
      const requestObj = {
        Controller: "Inbound",
        FunctionName: "orderStatus_v2",
        pk: "devkey",
        isWebSubmit: true,
        filePath: files[0],
        fileName,
        fileType:
          fileName.indexOf(".csv") != -1
            ? "csv"
            : fileName.indexOf(".xls") != -1
            ? "xls"
            : ""
      };

      const formData = new FormData();
      for (let key in requestObj) {
        formData.append(key, requestObj[key]);
      }
      formData.append("session", sessionStorage.getItem("sessionID") || "");
      const response: any = await axios.post(
        dataURL + "?ReturnType=JSON&r=Inbound/orderStatus_v2",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data"
          }
        }
      );

      if (response.data.STATUS == 1) {
        notifier.success("Update Orders successfully");
        $("#orderStatusFiles").val("");
        this.uploading = false;
        // this.updateMap = response.data.updateMap || [];
        // this.createdAccounts = response.data.createdAccounts || [];
        // this.ignoredLinesLength = response.data.ignoredLinesLength || 0;
        // this.ignoredPoIds = response.data.ignoredPoIds || [];
      } else {
        let message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }

        const isHardwareFile = response.data.isHardwareFile || false;
        if (isHardwareFile) {
          this.redirectConfirmVisible = true;
        }

        this.uploading = "error";
      }
    } catch (error) {
      this.uploading = "error";
      console.log(error);
    }
  }

  listPageRedirection() {
    this.$router.go(-1);
  }

  gotoImportHardwarePage() {
    this.$router.push({ name: "ImportHardwares" });
  }
}
