













































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import UserSuggestInput from "./UserSuggestInput.vue";
import directives from "../helpers/directives";
import { ApiHelper } from "@/helpers/all";
import { v4 as uuidv4 } from "uuid";

declare const $: any;
declare const dataURL: string;

interface Props {
  list: any;
  loading: boolean;
  allocatedQty: number;
  totalQty: number;
  currentIndex: number;
  currentItem: any;
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    UserSuggestInput
  },
  directives
})
export default class AssetsAllocationTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: true })
  list!: any;

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: false, default: 0 })
  allocatedQty!: number;

  @Prop({ required: false, default: 0 })
  totalQty!: number;

  @Prop({ required: false, default: -1 })
  currentIndex!: number;

  @Prop({ required: false, default: {} })
  currentItem!: any;

  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  @Prop({ required: false, default: 0 })
  productType?: number;

  $parent: any;
  userID = 0;
  userName = "";
  userType = 1;
  qtyAvailable = 0;
  remainingQty = 0;
  isDelete = false;
  calculatingQty = false;
  productData: any = null;
  searchedItems: any = [];

  async created() {
    this.remainingQty = parseFloat(
      (this.totalQty - this.allocatedQty).toFixed(2)
    );
    this.qtyAvailable = this.remainingQty;
  }

  beforeDestroy() {
    //case use function "Allocation other than 100%": detect case to reload data
    if (
      this.$parent.reloadedParent != undefined &&
      !this.$parent.reloadedParent &&
      this.qtyAvailable === 0 &&
      this.$parent.viewFilters.allrows.allocMin !== "" &&
      this.$parent.viewFilters.allrows.allocMax !== ""
    ) {
      this.$parent.reloadedParent = true;
      this.$parent.allrows_searchByAlloc();
    }
  }

  customChange(e) {
    if (e == null) {
      this.userID = 0;
      this.productData = null;
    }
  }

  async selectItem(data) {
    // ignore if fully allocated (just to make sure we not selecting for fully allocated items)
    // these items are disable on click event
    const productData = data.account.data;
    const productTotalQty = productData.PRODUCTTOTALQTY || 0;
    // product total allocated include for this user
    const productTotalAllocated = productData.TOTALALLOCATED || 0;
    // product allocated just for this user
    const allocatedThisUser = productData.ALLOCATEDTHISUSER || 0;
    let realQtyAvailable =
      productTotalQty - (productTotalAllocated - allocatedThisUser);
    realQtyAvailable = parseFloat(realQtyAvailable.toFixed(2));
    if (realQtyAvailable <= 0) {
      this.userID = 0;
      this.userName = "";
      this.productData = null;
      return;
    }

    this.userName = data.account.UFNAME + " " + data.account.ULNAME;
    this.userID = data.account.USERID;
    this.productData = productData;
    $("input#qtyTxt")
      .focus()
      .select();

    setTimeout(() => {
      this.disableFullyAllocatedItems(this.searchedItems);
    }, 100);
  }

  async addQuantity() {
    var result = true;
    const productId = this.userID;
    // var realQtyAvailable = this.qtyAvailable;
    $(
      ".quick-edit-qty span.left, .quick-edit-qty span.right, .edit-comm-txt"
    ).removeClass("custom-error");
    if (productId == 0) {
      result = false;
    }

    var existedLinkID: any = [];
    var val: any;
    if (this.list.length) {
      // check if existed in list
      for (val of this.list) {
        if (val.PRODUCTID == productId && typeof val.LINKID != "undefined") {
          existedLinkID.push(val.LINKID);
        }
      }
    }

    if (
      isNaN(this.remainingQty) ||
      this.remainingQty <= 0
      // || this.remainingQty > realQtyAvailable
    ) {
      if (productId > 0) {
        $(".quick-edit-qty span.right").addClass("custom-error");
      }
      result = false;
    }
    var data = {};

    // re-check real quantity available for this product
    data = {
      controller: "Deployments",
      FunctionName: "GetProductsToAllocate",
      deploymentId: this.deploymentId,
      productId,
      userId: this.currentItem.USERID,
      productType: this.productType || undefined
    };
    const response = await axios.post(dataURL + "?ReturnType=JSON", data);
    const productData = response.data.ITEMS[0] || null;
    const productTotalQty = productData.PRODUCTTOTALQTY || 0;
    // product total allocated include for this user
    const productTotalAllocated = productData.TOTALALLOCATED || 0;
    // product allocated just for this user
    const allocatedThisUser = productData.ALLOCATEDTHISUSER || 0;
    let realQtyAvailable =
      productTotalQty - (productTotalAllocated - allocatedThisUser);
    realQtyAvailable = parseFloat(realQtyAvailable.toFixed(2));

    // var balanceQty = this.totalQty - response.data.totalAllocated;
    // var showWarning = false;
    if (this.remainingQty > realQtyAvailable) {
      result = false;
      $(".quick-edit-qty span.right").addClass("custom-error");
      // showWarning = true;
      if (realQtyAvailable > 0) {
        notifier.warning(`Allocation cannot exceed ${realQtyAvailable}`);
      } else if (realQtyAvailable == 0) {
        notifier.warning(`Product fully allocated already`);
      }
      // this.$emit("reload");
    }

    if (result) {
      this.calculatingQty = true;
      try {
        if (this.currentItem.USERID > 0) {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Deployments",
            FunctionName: "UpdateAllocation",
            linkId: existedLinkID.length ? existedLinkID[0] : 0,
            deploymentId: this.deploymentId,
            userId: this.currentItem.USERID,
            productId,
            allocated: this.remainingQty
          });
          if (response.data.STATUS == 1) {
            ApiHelper.updateDeploymentPercent(this.deploymentId).then(ret => {
              this.$emit("updateDeploymentPercent", ret);
            });
            this.updateList({
              LINKID: existedLinkID.length
                ? existedLinkID[0]
                : response.data.LINKID,
              SKU: this.productData.SKU,
              PRODUCTID: this.productData.PRODUCTID,
              PRODUCTNAME: this.productData.PRODUCTNAME,
              ALLOCATED: this.remainingQty,
              PURCHASEID: this.productData.PURCHASEID
            });
            this.$emit("reload");
          }
        } else {
          this.updateList({
            LINKID: existedLinkID.length ? existedLinkID[0] : uuidv4(),
            SKU: this.productData.SKU,
            PRODUCTID: this.productData.PRODUCTID,
            PRODUCTNAME: this.productData.PRODUCTNAME,
            ALLOCATED: this.remainingQty,
            PURCHASEID: this.productData.PURCHASEID
          });
        }

        // reset
        this.userName = "";
        this.userID = 0;
        this.productData = null;
      } catch (err) {
        console.log(err.message);
      } finally {
        this.calculatingQty = false;
      }
    }

    // if (showWarning) {
    //   notifier.warning(`Allocation cannot exceed ${realQtyAvailable}`);
    //   this.$emit("reload");
    // }
  }

  updateList(item) {
    var existed = false;
    var index = 0;
    var doDelete =
      typeof item.remove != "undefined" && item.remove == true ? true : false;
    var total = 0;

    if (typeof item.ALLOCATED != "undefined") {
      item.ALLOCATED = parseFloat(item.ALLOCATED);
    }

    for (var i in this.list) {
      if (this.list[i].LINKID == item.LINKID) {
        //existed
        existed = true;
        if (doDelete) {
          //find index
          index = parseInt(i);
        } else {
          //update allocated
          this.list[i].ALLOCATED = item.ALLOCATED;
        }
        break;
      }
    }

    if (!existed) {
      //insert
      this.list.push({ ...item, PRODUCTTYPE: this.productType });
    }

    if (doDelete) {
      this.$delete(this.list, index);
    }

    //calculate on new list
    this.list.forEach(function(val: any, index) {
      val.oldAllocated = val.ALLOCATED;
      total += parseFloat(val.ALLOCATED);
    });
    total = parseFloat(total.toFixed(2));
    // this.qtyAvailable = parseFloat((this.totalQty - total).toFixed(2));
    this.remainingQty = 0;

    // update assests allocated
    this.$emit("updateAllocated", {
      allocated: total,
      currentIndex: this.currentIndex,
      allocatedThisItem: item.ALLOCATED,
      productId: item.PRODUCTID,
      productInfo: item,
      PRODUCTTYPE: this.productType
    });
  }

  async removeAllocation(item, remainingQty) {
    this.calculatingQty = true;
    this.isDelete = true;
    try {
      if (item.USERID > 0) {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "UpdateAllocation",
          action: "remove",
          linkId: item.LINKID,
          deploymentId: this.deploymentId,
          userId: item.USERID,
          productId: item.PRODUCTID
        });
        if (response.data.STATUS == 1) {
          ApiHelper.updateDeploymentPercent(this.deploymentId).then(ret => {
            this.$emit("updateDeploymentPercent", ret);
          });
          this.updateList({
            LINKID: item.LINKID,
            PRODUCTID: item.PRODUCTID,
            remove: true
          });
          this.$emit("reload");
        }
      } else {
        this.updateList({
          LINKID: item.LINKID,
          PRODUCTID: item.PRODUCTID,
          remove: true
        });
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      this.calculatingQty = false;
    }
  }

  async updateQuantity(item) {
    var result = true;
    $(".quick-edit-qty span.left, .quick-edit-qty span.right").removeClass(
      "custom-error"
    );
    $(".edit-comm-txt[data-linkid=" + item.LINKID + "]").removeClass(
      "custom-error"
    );

    if (item.ALLOCATED <= 0 || isNaN(item.ALLOCATED)) {
      $(".edit-comm-txt[data-linkid=" + item.LINKID + "]").addClass(
        "custom-error"
      );
      result = false;
    }

    // cannot over real available
    // re-check real quantity available for this product
    const requestObj = {
      controller: "Deployments",
      FunctionName: "GetProductsToAllocate",
      deploymentId: this.deploymentId,
      productId: item.PRODUCTID,
      userId: this.currentItem.USERID,
      productType: this.productType || undefined
    };
    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    const productData = response.data.ITEMS[0] || null;
    const productTotalQty = productData.PRODUCTTOTALQTY || 0;
    // product total allocated include for this user
    const productTotalAllocated = productData.TOTALALLOCATED || 0;
    // product allocated just for this user
    const allocatedThisUser = productData.ALLOCATEDTHISUSER || 0;
    let realQtyAvailable =
      productTotalQty - (productTotalAllocated - allocatedThisUser);
    realQtyAvailable = parseFloat(realQtyAvailable.toFixed(2));

    if (item.ALLOCATED > realQtyAvailable) {
      $(".edit-comm-txt[data-linkid=" + item.LINKID + "]").addClass(
        "custom-error"
      );
      result = false;
      notifier.warning(`Allocation cannot exceed ${realQtyAvailable}`);
    }

    if (result == true) {
      this.calculatingQty = true;
      try {
        if (this.currentItem.USERID > 0) {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Deployments",
            FunctionName: "UpdateAllocation",
            linkId: item.LINKID,
            deploymentId: this.deploymentId,
            userId: this.currentItem.USERID,
            productId: item.PRODUCTID,
            allocated: item.ALLOCATED
          });
          if (response.data.STATUS == 1) {
            ApiHelper.updateDeploymentPercent(this.deploymentId).then(ret => {
              this.$emit("updateDeploymentPercent", ret);
            });
            this.updateList({
              LINKID: item.LINKID,
              SKU: item.SKU,
              PRODUCTID: item.PRODUCTID,
              PRODUCTNAME: item.PRODUCTNAME,
              ALLOCATED: item.ALLOCATED,
              PURCHASEID: item.PURCHASEID
            });
            this.$emit("reload");
          }
        } else {
          this.updateList({
            LINKID: item.LINKID,
            SKU: item.SKU,
            PRODUCTID: item.PRODUCTID,
            PRODUCTNAME: item.PRODUCTNAME,
            ALLOCATED: item.ALLOCATED,
            PURCHASEID: item.PURCHASEID
          });
        }
      } finally {
        this.calculatingQty = false;
      }
    }
  }

  checkAllocated(item) {
    var domItem = $(".edit-comm-txt[data-linkid=" + item.LINKID + "]");
    if (domItem.hasClass("custom-error")) {
      item.ALLOCATED = item.oldAllocated;
      domItem.removeClass("custom-error");
    }
  }

  clickOutside() {
    if (!this.calculatingQty && !this.isDelete) {
      this.$emit("close");
    }
    this.isDelete = false;
  }

  async searchItems(key: string) {
    const requestObj: any = {
      controller: "Deployments",
      FunctionName: "GetProductsToAllocate",
      deploymentId: this.deploymentId,
      userId: this.currentItem.USERID,
      productType: this.productType || undefined
    };
    if (key) {
      // requestObj.getAll = 1;
      requestObj.key = key;
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.ERROR) {
      return [];
    }

    // save search items (filter to just get not full allocated items)
    this.searchedItems = (response.data.ITEMS || []).filter(
      item => item.PRODUCTTOTALQTY != item.TOTALALLOCATED
    );

    // change this a bit to fit with format of component UserSuggestInput
    const items = this.searchedItems.map(item => ({
      USERID: item.PRODUCTID,
      ULNAME: item.PRODUCTNAME,
      UFNAME: "",
      data: item
    }));

    // disable items fully allocated
    // just can do this by js at this time
    setTimeout(() => {
      this.disableFullyAllocatedItems(this.searchedItems);
    }, 50);

    return items;
  }

  disableFullyAllocatedItems(items: any = []) {
    for (const item of items) {
      const domItem = $(
        `.assets-allocation-tooltip.products-tooltip .quick-edit-qty .IZ-select__menu:visible .IZ-select__item .item[data-value=${
          item.PRODUCTID
        }]`
      );
      const title = `${item.PRODUCTNAME} (${item.TOTALALLOCATED}/${
        item.PRODUCTTOTALQTY
      })`;
      if (domItem.length) {
        domItem
          .removeClass("fully-allocated")
          .off("click")
          .attr("title", title);
      }

      const productTotalQty = item.PRODUCTTOTALQTY || 0;
      // product total allocated include for this user
      const productTotalAllocated = item.TOTALALLOCATED || 0;
      // product allocated just for this user
      const allocatedThisUser = item.ALLOCATEDTHISUSER || 0;
      let realQtyAvailable =
        productTotalQty - (productTotalAllocated - allocatedThisUser);
      realQtyAvailable = parseFloat(realQtyAvailable.toFixed(2));

      if (realQtyAvailable <= 0) {
        // disable click on this item
        if (domItem.length) {
          domItem
            .addClass("fully-allocated")
            .off("click")
            .on("click", e => {
              e.preventDefault();
              e.stopPropagation();
            });
        }
      }
    }
  }
}
