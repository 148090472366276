var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr>\n      <td width=\"10%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.REPORTID || (depth0 != null ? depth0.REPORTID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"REPORTID","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":60}}}) : helper)))
    + "</td>\n      <td width=\"30%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.REPORTNAME || (depth0 != null ? depth0.REPORTNAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"REPORTNAME","hash":{},"data":data,"loc":{"start":{"line":11,"column":48},"end":{"line":11,"column":62}}}) : helper)))
    + "</td>\n      <td width=\"20%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.REPORTTYPE || (depth0 != null ? depth0.REPORTTYPE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"REPORTTYPE","hash":{},"data":data,"loc":{"start":{"line":12,"column":48},"end":{"line":12,"column":62}}}) : helper)))
    + "</td>\n      <td width=\"20%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.CREATED || (depth0 != null ? depth0.CREATED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CREATED","hash":{},"data":data,"loc":{"start":{"line":13,"column":49},"end":{"line":13,"column":60}}}) : helper)))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"customerList_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"10%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">Report ID</td>\n    <td width=\"30%\" style=\"border-bottom: 5px solid #b1b1b1;\">Report Name</td>\n    <td width=\"20%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">Report Type</td>\n    <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">Creation Date</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.REPORTS : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "</table>";
},"useData":true});