var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"quoteDetails_notes\" cellspacing=\"3\" cellpadding=\"3\">\n    <tr><td colspan=\"2\" class=\"title headerColor\" style=\"font-weight: 400;\">Quote Notes</td></tr>\n    <tr>\n        <td colspan=\"2\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteNotes),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "        </td>\n    </tr>\n</table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteNotes), depth0))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTENOTES),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTENOTES), depth0)) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.useDefaultAZNOTES),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":24,"column":27}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                    We at Milestone understand that these are unprecedented times. To best serve you as your trusted partner, we want to ensure you have accurate shipping dates.\n                    <br/><br/>\n                    We have been notified that desktop delivery lead times could be up to 8-10 weeks, notebook delivery lead times could be up to 10-12 weeks, and Chromebook delivery lead times could be 12+ weeks. We will do everything possible on our end to escalate orders to help. \n                    <br/><br/>\n                    Due to an increase in demand industry-wide, Chromebooks are highly constrained and lead times for Chromebooks can exceed 12 weeks from the date Milestone receives the PO.<br/><br/>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <!--                IMPORTANT NOTE: We at Milestone understand that these are unprecedented times. To best serve you as your trusted partner, we want to ensure you have accurate shipping dates as we near FYE.<br/><br/>\n                    We have been notified that desktop delivery lead times could be up to <strong style=\"color: red;\">8-10 weeks</strong> and notebook delivery lead times could be up to <strong style=\"color: red;\">10-12 weeks</strong>. We will do everything possible on our end to escalate orders to help. We are asking that you include on FYE PO’s that the order is <strong style=\"color: red;\">PERISHABLE</strong> and must be delivered by June 30, 2020. This will help us work with HP to escalate orders.\n    -->\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.DISPLAYQUOTENOTES),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "");
},"useData":true});