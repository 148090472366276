var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <table width=\"100%\" id=\"quoteDetails_info\" style=\"padding-left: 10px !important; padding-right: 10px !important;\">\n    <tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.HAVEBILLING),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":34,"column":13}}})) != null ? stack1 : "")
    + "      <td width=\"30%\">\n        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":10},"end":{"line":72,"column":17}}})) != null ? stack1 : "")
    + "        </table>\n      </td>\n      <td width=\"40%\">\n        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.invoiceId),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":10},"end":{"line":79,"column":17}}})) != null ? stack1 : "")
    + "          <tr>\n            <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.invDate),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":14},"end":{"line":84,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.DUEDATEFORMATTED),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":14},"end":{"line":87,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.TERMS),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":14},"end":{"line":90,"column":21}}})) != null ? stack1 : "")
    + "            </td>\n          </tr>\n        </table>\n      </td>\n    </tr>\n\n  </table>\n  <table width=\"100%\" id=\"quoteDetails_notes\">\n    <tr style=\"border-top: 1px;\">\n      <td width=\"50%\"><span class=\"title_detail\" style=\"font-weight: bold;\">Customer PO</span> <br/> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.POID), depth0))
    + "</td>\n      "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.CUSTOMDATACONTRACTNUMBER),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":102,"column":6},"end":{"line":106,"column":13}}})) != null ? stack1 : "")
    + "    </tr>\n  </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <td width=\"30%\" style=\"vertical-align: top;\">\n        <table>\n          <tr><td class=\"title_detail\" style=\"font-weight: bold;\">BILL TO</td></tr>\n          <tr>\n            <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":29,"column":21}}})) != null ? stack1 : "")
    + "            </td>\n          </tr>\n        </table>\n      </td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "                "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLTOONETIMEADDRESSNAME), depth0))
    + "<br />\n                "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLINGTO),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESSBILLING),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLINGTO), depth0))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "\n                ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESSBILLING), depth0))
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS2BILLING),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":18},"end":{"line":24,"column":25}}})) != null ? stack1 : "")
    + "                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.ICITYBILLING), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.ISTATEBILLING), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IZIPBILLING), depth0))
    + " <br/>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS2BILLING), depth0))
    + "<br/>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLINGADDR), depth0)) != null ? stack1 : "")
    + "<br/>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr><td class=\"title_detail\" style=\"font-weight: bold;\">SHIP TO</td></tr>\n            <tr>\n              <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":69,"column":23}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.EUNAME),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":18},"end":{"line":45,"column":25}}})) != null ? stack1 : "")
    + "                  "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPTOONETIMEADDRESSNAME), depth0))
    + "<br />\n                  "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.EUNAME), depth0))
    + "<br />\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":49,"column":18},"end":{"line":53,"column":25}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.EUNAME),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":18},"end":{"line":56,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":57,"column":18},"end":{"line":68,"column":25}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGTO), depth0))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "\n                  ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS), depth0))
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS2),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":61,"column":27}}})) != null ? stack1 : "")
    + "                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.ICITY), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.ISTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IZIP), depth0))
    + " <br/>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS2), depth0))
    + "<br/>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    \n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":20},"end":{"line":67,"column":27}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr><td style=\"padding-top: 10px;\"><span class=\"title_detail\" style=\"font-weight: bold;\">INVOICE ID</span> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.INVOICEID), depth0))
    + "</td></tr>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <span class=\"title_detail\" style=\"font-weight: bold;\">INVOICE DATE</span> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IDATEFORMATTED), depth0))
    + "<br/>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <span class=\"title_detail\" style=\"font-weight: bold;\">DUE DATE</span> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.DUEDATEFORMATTED), depth0))
    + "<br/>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <span class=\"title_detail\" style=\"font-weight: bold;\">TERMS</span> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.TERMS), depth0))
    + "\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<td><span class=\"title_detail\" style=\"font-weight: bold;\">CONTRACT NUMBER</span> <br/>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.CUSTOMDATACONTRACTNUMBER), depth0))
    + "</td>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.NASPOCONTRACT),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":8},"end":{"line":105,"column":15}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<td><span class=\"title_detail\" style=\"font-weight: bold;\">CONTRACT NUMBER</span> <br /> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.NASPOCONTRACT), depth0))
    + "</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":109,"column":7}}})) != null ? stack1 : "");
},"useData":true});