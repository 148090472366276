











import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";


interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    line: any
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
})
export default class CategoryAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, type: String, default: "" })
  deploymentId!: string

  @Model('input', {
    type: String,
    required: true
  })
  readonly value!: string

  options: Option[] = []
  searchData: any[] = [];

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {

      if(!search) {

        const requestObj = {
          controller: "Helpers",
          FunctionName: "Category"
        }

        if (this.deploymentId.length) {
          requestObj['deploymentId'] = this.deploymentId;
        }

        const response = await ApiHelper.callApi("post", requestObj);
    
    
        if (response.ERROR || !response.CATEGORY.length) {
            return {
              options: []
            };
          }
          return this.createOptions(response.CATEGORY)
      }

      const requestObj = {
        controller: "Helpers",
        FunctionName: "Category",
        category: search
      };

      if (this.deploymentId.length) {
        requestObj['deploymentId'] = this.deploymentId;
      }

      const response = await ApiHelper.callApi("post", requestObj);
      if (response.ERROR || !response.CATEGORY.length) {
        return {
          options: []
        };
      }
      return this.createOptions(response.CATEGORY)
    }

  createOptions(searchData: any[]) {
    const options = searchData.map(
      (item): Option => {
        return {
          value: item.CATEGORYID,
          label: item.CATEGORYNAME,
          text: item.CATEGORYNAME
        };
      }
    )
    this.searchData = searchData
    this.options = options

    return {options}
  }

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return;
    const option = this.options.find((o) => o.value === value)
    if (!option) {
      return
    }

    const line = this.searchData.find((item) => item.CATEGORYID === value)

    if (!line) {
      console.warn(`no line found for value[${value}]`)
      return
    }
    this.$emit('selection', line)
  }
}
