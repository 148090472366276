

















































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";

import InputDate from "../components/InputDate.vue";
import { PaycheckResp } from "../models/PaycheckResp";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    InputDate
  }
})
export default class OrderDetails extends TSXComponent<void> {
  loading = true;
  generating = false;

  name = "";
  startDate: Date;
  endDate: Date;
  selectedMonth = null;

  selectedUserIds: number[] = [];
  selectedStatementIds: number[] = [];
  selectedOrderIds: number[] = [];
  indirectSale = false;

  pageData: PaycheckResp.Root = {
    Users: [],
    ORDERS: [],
    CommissionStatements: [],
    TOTALPAGES: 1,
    EXCLUDEDORDERS: 0,
    CURRENTPAGE: 1
  };

  activeTab: "statements" | "orders" = "statements";

  constructor() {
    super();

    const date = new Date();
    this.endDate = new Date(date.getFullYear(), date.getMonth(), 0);
    this.startDate = new Date(
      this.endDate.getFullYear(),
      this.endDate.getMonth(),
      1
    );
  }

  async startDateChange() {
    await this.fetchData();
  }

  async endDateChange() {
    await this.fetchData();
  }

  async updateMonth(date) {
    this.startDate = date.startOf("month").toDate();
    this.endDate = date.endOf("month").toDate();
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const paycheckDefinitionResp = await axios.post(
        dataURL + "?ReturnType=JSON",
        {
          controller: "Finances",
          FunctionName: "paycheckDefinition",
          sDate: moment(this.startDate).format("MM/DD/YYYY"),
          eDate: moment(this.endDate).format("MM/DD/YYYY")
        }
      );

      this.pageData = paycheckDefinitionResp.data;

      this.selectedStatementIds = this.selectedStatementIds.filter(id =>
        this.pageData.CommissionStatements.some(
          item => item.MC_CSTATEMENT_ID === id
        )
      );
      this.selectedOrderIds = this.selectedOrderIds.filter(id =>
        this.pageData.ORDERS.some(item => item.PURCHASEID === id)
      );
    } finally {
      this.loading = false;
    }
  }

  async created() {
    await this.fetchData();
  }

  async generate() {
    if (this.generating) return;

    const ok = await this.$validator.validateAll();
    if (!ok) {
      return;
    }

    this.generating = true;
    try {
      const payl = {
        controller: "Finances",
        FunctionName: "payCheckGenerate",
        indirectSale: this.indirectSale ? "1" : "0",
        userids: this.selectedUserIds.join(","),
        statementids: this.selectedStatementIds.join(","),
        orderids: this.selectedOrderIds.join(","),
        sDate: moment(this.startDate).format("MM/DD/YYYY"),
        eDate: moment(this.endDate).format("MM/DD/YYYY"),
        title: this.name
      };

      const resp = await axios.post(dataURL + "?ReturnType=JSON", payl);

      this.$router.push({
        name: "Payroll",
        query: {
          PCGroupId: resp.data.PCGroupId
        }
      });
    } finally {
      this.generating = false;
    }
  }
}
