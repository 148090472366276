export function htmlParse(inputVal: string) {
	const parsedHTML = $.parseHTML(inputVal) as any;
	if (parsedHTML !== null && parsedHTML.length > 0) {
		// eslint-disable-next-line no-prototype-builtins
		if (parsedHTML[0].hasOwnProperty('textContent') == false && parsedHTML[0].textContent === null) {
			return '';
		} else {
			return parsedHTML[0].textContent;
		}
	} else {
		return '';
	}
}
