































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

interface Props {
  title: String;
  search: String;
  placeholder: String;
}

interface Events {
  onClose: void
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class FilterSearch extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  search!: string;

  @Prop({ required: true })
  placeholder!: string;

  @Prop({ required: false })
  customClass?: string;

  keyword = '';

  async created() {
    this.keyword = this.search;
  }

  reset() {
    this.keyword = '';
    this.$emit('search', '');
  }

  doSearch() {
    this.search = this.keyword;
    this.$emit('search', this.search);
  }

  getFilterValue() {
    let filter = this.search;
    let searchKey = '';
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }
}
