































































































































































































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from "axios";

import LaddaButton from "../components/LaddaButton.vue";
import { notifier, wait } from "../models/common";

declare const dataURL: string;
declare const $: any;

interface Props {
  details: any;
}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class StatusCreate extends TSXComponent<void> {
  @Prop({ required: true, default: [] })
  details?: any;

  saving: boolean | "error" = false;

  $refs!: {
    modal: HTMLDivElement;
  };

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }
}
