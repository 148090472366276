


















import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import directives from "../helpers/directives";
import LaddaButton from "./LaddaButton.vue";

declare const $: any;
declare const dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    LaddaButton
  },
  directives
})
export default class DeploymentOrdersTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: {} })
  ordersdata!: any;

  $parent: any;
  errors: any;

  beforeDestroy() {
    //
  }

  clickOutside() {
    this.$emit("close");
  }

  onMouseOut() {
    this.$emit('reset-tooltip');
  }
}
