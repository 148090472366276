




























































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import GlobalTagSelect from "@/components/GlobalTagSelect.vue";

declare const dataURL: string;
declare const $: any;
declare const htmlParse: Function;
declare const htmlCheck: Function;

interface Props {
  data: any;
  title: string;
}

@Component({
  inheritAttrs: false,
  components: {
    FormMultiSelect,
    GlobalTagSelect
  }
})
export default class ModalEditDeploymentDataField extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: true, default: [] })
  data!: any;

  @Prop({ required: true, default: '' })
  title!: string;

  customDataIndex = -1;
  fullCustomData: any = [];
  globalTags: any = [];
  selectedGlobalTags: object[] = [];
  selectedGlobalTagsID: number[] = [];
  loading: string | boolean = false;

  async created() {
    await this.showCustomData();
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async showCustomData() {
    try {
      this.loading = true;
      const response = await ApiHelper.callApi(
        "post", 
        {
          controller: "Helpers",
          FunctionName: "CustomData",
          DataType: "5",
          Id: this.$route.params.id,
          aId: this.data.AID
        }
      );

      if (response.STATUS == 1) {
        const customData = response.CUSTOMDATA || [];
        this.fullCustomData = (response.CUSTOMDEFINITION || []).map(
          item => {
            const fieldData: any = customData.find(
              t => t.CUSTOMFIELDID == item.CUSTOMFIELDID
            );

            const customValue = fieldData ? fieldData.CUSTOMVALUE : "";
            let selectedIds: string[] = [];
            if(item.CUSTOMDTYPE == 3 && customValue != "") {
              try {
                selectedIds = JSON.parse(customValue);
              } catch (error) {
                selectedIds.push(customValue);
              }
            }

            const tmp: any = {
              CUSTOMFIELDID: item.CUSTOMFIELDID,
              CUSTOMFIELDNAME: item.CUSTOMFIELDNAME,
              CUSTOMVALUE: customValue,
              CUSTOMID: fieldData ? fieldData.CUSTOMID : 0,
              CUSTOMDTYPE: item.CUSTOMDTYPE || 1,
              OPTIONS: item.OPTIONS || [],
              selectedIds
            };
            return tmp;
          }
        );
        this.globalTags = response.globalTags.filter(item => item.FORDEPLOYMENTS);
        this.selectedGlobalTags = response.selectedGlobalTags.map(
          (val: any) => ({
            CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
            CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME,
            FORDEPLOYMENTS: val.FORDEPLOYMENTS
          })
        ).filter(item => item.FORDEPLOYMENTS);
        this.selectedGlobalTagsID = this.selectedGlobalTags.map(
          (val: any) => val.CUSTOMFIELDOPTIONID
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  showCustomEditBox(index) {
    this.customDataIndex = index;

    setTimeout(() => {
      $(".custom-data-txt")
        .focus()
        .select();
    }, 100);
  }

  hideCustomEditBox() {
    this.customDataIndex = -1;
  }

  async updateCustomData(item) {
    //remove HTML
    if(item.CUSTOMDTYPE == 1) {
      item.CUSTOMVALUE = this.removeHTML(item.CUSTOMVALUE);
    }

    try {
      const response = await ApiHelper.callApi(
        "post",
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          customID: item.CUSTOMID,
          customFieldID: item.CUSTOMFIELDID,
          customValue: item.CUSTOMVALUE,
          Id: this.$route.params.id,
          fieldTypeId: 5
        }
      );

      if (response.STATUS == 1) {
        this.customDataIndex = -1;
        if ((item.CUSTOMDTYPE == 1 && item.CUSTOMVALUE == "") || (item.CUSTOMDTYPE == 3 && !item.selectedIds.length)) {
          //delete this item
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = 0;
        } else if (typeof response.NEWCUSTOMID != "undefined") {
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = response.NEWCUSTOMID;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  toggleCDOption(item, id, text) {
    // update item.selectedIds
    if (!item.selectedIds.includes(id)) {
      item.selectedIds.push(id);
    } else {
      item.selectedIds = item.selectedIds.filter(t => t != id);
    }
    item.CUSTOMVALUE = item.selectedIds.length ? JSON.stringify(item.selectedIds) : "";
    this.updateCustomData(item);
  }

  toggleSelectedGlobalTags(selectedID: number, tagName) {
    const index = this.selectedGlobalTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedGlobalTagsID.push(selectedID);
      this.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      this.saveGlobalTag();
      return;
    }

    this.selectedGlobalTagsID.splice(index, 1);
    this.$delete(this.selectedGlobalTags, index);
    this.saveGlobalTag();
  }

  async saveGlobalTag() {
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          optionIDs: this.selectedGlobalTagsID.join(","),
          Id: this.$route.params.id,
          action: "save_globalTags",
          fieldTypeId: 1
        }
      );
    } catch (err) {
      // console.log(err.message);
    }
  }

  selectAllGlobalTags() {
    this.resetGlobalTags();
    this.selectedGlobalTagsID = this.globalTags.map(
      (val: any) => val.CUSTOMFIELDOPTIONID
    );
    this.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    this.saveGlobalTag();
  }

  resetGlobalTags() {
    this.selectedGlobalTags = [];
    this.selectedGlobalTagsID = [];
    this.saveGlobalTag();
  }

  updateGlobalTagList(type, index) {
    if (this.selectedGlobalTagsID && type == "delete") {
      this.$delete(this.selectedGlobalTagsID, index);
      this.$delete(this.selectedGlobalTags, index);
    }
    this.saveGlobalTag();
  }
}
