import axios from "axios";
declare const dataURL: string;

export enum ReportStatus {
  Enabled = 1, 
  Disabled = 0,
}

export enum ReportBuildSource {
  BuildTool = 1, 
  Custom = 2,
}

export enum ReportTypeFilter {
  Html = 'Html', 
  Xlsx = 'Xlsx',
}
export enum SortField {
  Id = 1,
  ReportName = 2,
  ReportType = 3,
  ReportDate = 4,
  LastRunDate = 5,
}

export type Filters = {
  searchAll: string
  searchId: string
  searchName: string
  sDate: string | undefined
  eDate: string | undefined
  sRunDate: string | undefined
  eRunDate: string | undefined
  rStatus: ReportStatus[]
  rBuildSource: ReportBuildSource[]
  rTypeFilter: ReportTypeFilter[] | string []
}

export type Sort = {
  field: SortField | null
  direction: {
    [SortField.Id]: 1 | 2,
    [SortField.ReportName]: 1 | 2,
    [SortField.ReportType]: 1 | 2,
    [SortField.ReportDate]: 1 | 2,
    [SortField.LastRunDate]: 1 | 2,
  }
}
export class ReportAPIRepo {

  async reportList(filters: Filters, sort: Sort, pageNumber: number, irStatus: boolean = false) {
    // console.log('params',sort)
    // console.log('Filters',filters)
    const paramsQuery: any = {
      "controller": "Reports",
      "FunctionName": "ReportsList",
      "search": filters.searchAll,
      "PageNumber": pageNumber.toString(),
      "sReportID": filters.searchId,
      "sReportName": filters.searchName
    };
    if (filters.sDate) {
      paramsQuery['sDate'] = filters.sDate
    }
    if (filters.eDate) {
      paramsQuery['eDate'] = filters.eDate
    }
    if (filters.sRunDate) {
      paramsQuery['sRunDate'] = filters.sRunDate
    }
    if (filters.eRunDate) {
      paramsQuery['eRunDate'] = filters.eRunDate
    }
    if (filters.rStatus.length > 0) {
      paramsQuery['runReportStatus'] = filters.rStatus.join(',')
    }
    if (irStatus && filters.rStatus.length == 0) {
      paramsQuery['runReportStatus'] = '-1';
    }

    if (filters.rBuildSource.length == 0) {
      paramsQuery['rBuildSource'] = '-1';
    } else {
      paramsQuery['rBuildSource'] = filters.rBuildSource.join(', ')
    }

    if (filters.rTypeFilter.length > 0) {
      paramsQuery['rTypeFilters'] = filters.rTypeFilter.join(',')
    }
    if (sort.field) {
      paramsQuery["order"] = sort.field
      paramsQuery["direction"] = sort.direction[sort.field].toString()
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", paramsQuery);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    //return data
    return response.data;
  }
  async exportListsCSVFileUrl(type: number,ids: string[]): Promise<string> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "reports",
      ExportType: "CSV",
      selectedID: ids.toString(),
      FunctionName: "reportExport",
      type : type
    });
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportListsPDFFileUrl(ids: string[],type: number,filters: any,sort: any): Promise<string> {
    var reqData = this.prepareFilteredData(filters,sort);
    reqData["selectedID"] = ids.toString();
    reqData["FunctionName"] = "reportExport";
    reqData["type"] = type;
    reqData["ExportType"] = "PDF";
    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async getReports(ids, pageNumber: number){
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "reports",
      type: ids,
      PageNumber: pageNumber,
      FunctionName: "getReportType",
    });
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    return response.data;
  }

  async reportGenerate(ids, start, end, type){
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "reports",
      FunctionName: "run",
      id: ids,
      startdate: start,
      ExportType: type,
      enddate: end
    });
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    return response.data;
  }

  async exportReportBySelect(type: number, ids: string[], filters: any, sort: any): Promise<string> {
    var reqData = this.prepareFilteredData(filters, sort);
    reqData["selectedID"] = ids.toString();
    reqData["FunctionName"] = "ReportsList";
    reqData["type"] = type;
    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    return response.data;
  }

  prepareFilteredData(filters: any, sort: any) {
    var ret = {
      "controller": "Reports",
      "search": filters.searchAll,
      "sReportID": filters.searchId,
      "sReportName": filters.searchName,
      "runReportStatus": filters.rStatus.join(","),
      "rTypeFilters": filters.rTypeFilter.join(","),
      "eDate": filters.eDate,
      "sDate": filters.sDate,
      "eRunDate": filters.eRunDate,
      "sRunDate": filters.sRunDate
    }

    if (sort.field) {
      ret["order"] = sort.field
      ret["direction"] = sort.direction[sort.field].toString()
    }
    return ret;
  }

}