var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRemitAddress : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInfoHeader : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/pastDueNotice_invoiceDetails_header.handlebars"),depth0,{"name":"pastDueNotice_invoiceDetails_header","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/pastDueNotice_invoiceDetails_address.handlebars"),depth0,{"name":"pastDueNotice_invoiceDetails_address","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n          <tr style=\"color: #b1b1b1; height: 40px;\">\n            <td width=\"37%\" style=\"border-bottom: 5px solid #b1b1b1;\">Line Item</td>\n            <td width=\"15%\" style=\"padding-left: 5px; text-align: left; border-bottom: 5px solid #b1b1b1;\">SKU</td>\n            <td width=\"15%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">PRICE PER</td>\n            <td width=\"13%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">QTY</td>\n            <td width=\"20%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">TOTAL</td>\n          </tr>\n"
    + ((stack1 = container.invokePartial(require("./partials/pastDueNotice_invoiceDetails_items.handlebars"),depth0,{"name":"pastDueNotice_invoiceDetails_items","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/pastDueNotice_invoiceDetails_summary.handlebars"),depth0,{"name":"pastDueNotice_invoiceDetails_summary","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </table>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <div>\n          <table cellspacing=\"0\" cellpadding=\"0\">\n            <tr>\n              <td style=\"padding-left: 5px;\">\n                <a href=\""
    + container.escapeExpression(((helper = (helper = helpers.portalLink || (depth0 != null ? depth0.portalLink : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"portalLink","hash":{},"data":data,"loc":{"start":{"line":45,"column":25},"end":{"line":45,"column":39}}}) : helper)))
    + "\" style=\"display: inline-block\"><img title=\"Portal Link\" src=\"https://var360.dev-us.com/images/cart.svg\"></a>\n              </td>\n              <td style=\"padding-left: 5px;\">\n                <span>Submit payment via credit card</span>\n              </td>\n            </tr>\n          </table>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!DOCTYPE html>\n<html>\n  <head>\n    <link href=\"https://var360.s3.amazonaws.com/public/css/export.css\" rel=\"stylesheet\" />\n    <link href=\"https://fonts.googleapis.com/css?family=Work+Sans&display=swap\" rel=\"stylesheet\">\n    <style>\n      .check {\n        height: 7px !important;\n        width: 7px !important;\n      }\n      .config-line {\n        color: #b1b1b1;\n      }\n    </style>\n  </head>\n  <body>\n    <div id=\"container\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.templateItemReorder : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.pricingdetails : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":6},"end":{"line":38,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/pastDueNotice_invoiceDetails_message.handlebars"),depth0,{"name":"pastDueNotice_invoiceDetails_message","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.portalLink : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":6},"end":{"line":53,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </body>\n</html>\n";
},"usePartial":true,"useData":true});