






































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import apiModal from "../components/apiCreate.vue";
import UserStatus from "../components/UserStatus.vue";
import { notifier, wait } from "../models/common";
import { colsResizeable } from "@/helpers/ApiHelper";

declare const dataURL: string;
declare const $: any;
declare const Stripe: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    apiModal,
    UserStatus
  }
})
export default class ApiKeys extends TSXComponent<void> {
  $validator: any;
  apiModalVisible = false;
  loading = false;
  currentRole = sessionStorage.getItem("userRole");
  varSourceAPI = [];
  varApiStatusCount = 0;
  detail = {};
  limitTotalApi = 5;

  async apiModalReload() {
    this.apiModalVisible = false;
    await this.fetchData();
  }

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      subsystem: "VAR360",
      controller: "VARSources",
      FunctionName: "View",
      varsource_id: 3
    });
    this.varSourceAPI = response.data.VARAPIDATAARRAY;
    this.varApiStatusCount = response.data.VARAPISTATUSCOUNT;

    this.loading = false;

    colsResizeable({ms: 500});
  }

  async changeStatus(apiKey, status, action) {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      subsystem: "VAR360",
      controller: "VARSources",
      FunctionName: "apiKeyUpdate",
      apiKey: apiKey,
      action: action,
      active: status
    });
    if (response.data.STATUS) {
      notifier.success(response.data.STATUSMESSAGE);
      await this.fetchData();
    }
  }
}
