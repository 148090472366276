











































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../../components/LaddaButton.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";

declare const $: any;
declare const getImageRouteData: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Datepicker,
  }
})
export default class ModalAddDeploymentStatus extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
    identifierImportFileUpload: HTMLDivElement
  };

  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: true, default: [] })
  orders?: any[];

  @Prop({ required: false, default: false })
  loading?: boolean | string;

  @Prop({ required: false, default: 0 })
  aID?: number;

  $parent: any;
  objDate = new Date();
  statusDate = [this.objDate.getMonth()+1, this.objDate.getDate(), this.objDate.getFullYear()].join('/');
  formData = {
    loading: false,
    controls: {
      type: {
        value: this.$parent.details.DEPLOYSTATUS,
        error: '',
      },
      source: {
        value: '0',
        error: '',
      },
      order: {
        value: '',
        error: '',
      },
      date: {
        value: this.statusDate,
        error: '',
      },
      statusDetails: {
        value: '',
        error: '',
      },
      employee: {
        value: '',
        error: '',
      },
    }
  };
  endUserIdentifierFileName:string = "";


  mounted() {
    this.formData.controls.order.value = this.orders && this.orders.length == 1 ? this.orders[0].id : '';
    $(this.$refs.modal).modal("show");
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async onSubmit() {
    if (this.formData.loading) {
      return false;
    }
    if (this.endUserIdentifierFileName.length) {
      this.importEndUserIdentifier();
      return;
    }
    let hasError = false;
    if (this.formData.controls.type.value == '12' && this.formData.controls.statusDetails.value == '') {
      this.formData.controls.statusDetails.error = 'Status Details is required';
      hasError = true;
    }

    if (hasError) {
      notifier.alert('Please complete the form');
    } else {
      let FunctionName = "";
      this.formData.loading = true;
      if(this.formData.controls.type.value != 12){
        FunctionName = "UpdateDeploymentStatus";
      }
      else{
        FunctionName = "DeploymentAddNote";
      }
      const response = await ApiHelper.callApi(
        "post",
        {
          "controller": "Deployments",
          "FunctionName": FunctionName,
          "update": true,
          "deploymentId": this.$parent.deploymentId,
          "date": this.$parent.deploymentDetails.DEPLOYMENTDATE,
          "deployStatus": this.formData.controls.type.value,
          "note": this.formData.controls.statusDetails.value
        }
      );
      this.formData.loading = false;
      if (response.STATUS == 1) {
        notifier.success('Added');
        this.$emit('callback');
      } else {
        notifier.alert(response.STATUSMESSAGE || `Cant add new status`);
      }
    }
  }

  clickOutside() {
    this.$emit("close");
  }

  showIdentifierImportFileUpload() {
    $(this.$refs.identifierImportFileUpload).click();
  }

  handleIdentifierImportFileUpload(e) {
    e.stopPropagation();
    e.preventDefault();

    var filePath = e.target.files[0].name;
    var allowedExtensions = /(\.csv)$/i;
    if (!allowedExtensions.exec(filePath)) {
      notifier.alert("Please upload file having extensions .csv only.");
    } else {
      this.endUserIdentifierFileName = filePath;
    }
  }

  async importEndUserIdentifier() {
    var comInstance = this;
    var filePath = $(this.$refs.identifierImportFileUpload).get(0).files[0].name;
    var allowedExtensions = /(\.csv)$/i;

    if (!allowedExtensions.exec(filePath)) {
      notifier.alert("Please upload file having extensions .csv only.");
    } else {
      //upload file, and get returned json
      comInstance.formData.loading = true;
      var processedFileUpload = getImageRouteData({
        Controller: "Deployments",
        FunctionName: "EndUserIdentifierImport",
        importfile: $(comInstance.$refs.identifierImportFileUpload).get(0).files[0],
        fileType: filePath.indexOf(".csv") != -1 ? "csv" : "",
        aId: comInstance.aID,
        deploymentId: comInstance.deploymentId
      }).then(async (response, statusText, jqXHR) => {
        comInstance.formData.loading = false;
        if(response.data.STATUS == 1) {
          notifier.success(response.data.STATUSMESSAGE);
          comInstance.$emit('callback');
        }
        else {
          notifier.alert(response.data.STATUSMESSAGE);
        }
      }).catch(function (error) {
        // console.log(error);
      });
    }
  }
}
