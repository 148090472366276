

























import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue"

interface Props {
  title: string
  customMessage: string
  customClass: string
}

interface Events {
  onClose: void
  onConfirm: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class ConfirmRemoveItemModal extends TSXComponent<
  Props,
  Events
> {
  @Prop({required: true})
  title!: string

  @Prop({required: false, default: "btn-secondary"})
  customClass!: string

  @Prop({required: false, default: ""})
  customMessage!: string

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  close(){
    this.$emit('close')
  }
}
