





























import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

declare const $: any;
declare const dataURL: string;

interface Props {}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {}
})
export default class DeploymentScheduleUsersTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: false, default: -1 })
  currentIndex!: number;

  @Prop({ required: false, default: {} })
  currentItem!: any;

  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  $parent: any;

  clickOutside() {
    this.$emit("close");
  }
}
