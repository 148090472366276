










import { Component as TSXComponent } from 'vue-tsx-support';
import { Component, Prop, Emit, Model, Watch } from 'vue-property-decorator';

interface Props {
  data_class: string;
  title: string;
  dataType: string;
  onIconClick: () => void;
}

@Component
export default class PageTitle extends TSXComponent<Props> {
  @Prop({ required: true })
  data_class!: string;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  dataType!: string;

  @Prop({ required: true })
  onIconClick!: () => void;
}
