


























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class OrderWarranties extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: [] })
  items?: any;

  @Prop({ required: false, default: [] })
  hardwareInfo?: any;

  @Watch("items")
  itemsChanged(newItems: any) {
    if (this.hardwareInfo.length) {
      for (const item of newItems) {
        item.hardwares = this.hardwareInfo.filter(
          t =>
            t.HARDWARESKU == item.HARDWARESKU &&
            (t.ASSETNUMBER != "" || t.ASSETTAG != "")
        );
      }
    }
  }

}
