











































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "../../components/LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import { FormControls } from "@/models/FormControls";
import FormSelect = FormControls.FormSelect;
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";
import { notifier } from "@/models/common";

@Component({
  inheritAttrs: false,
  components: {
    FormMultiSelect,
    LaddaButton,
    Datepicker
  }
})
export default class ModalLockSchedule extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: {} })
  deploymentParams?: any;

  @Prop({ required: false, default: false })
  loading?: boolean | string;

  @Prop({ required: false, default: [] })
  availabilitiesList?: any[];

  objDate = new Date();
  statusDate = [
    this.objDate.getMonth() + 1,
    this.objDate.getDate(),
    this.objDate.getFullYear()
  ].join("/");
  formData: {
    loading: boolean;
    controls: {
      lockedSchedule: boolean;
      availability: {
        value: any[];
        options: any[];
        label: string;
        error: string;
      };
      lockSelect: boolean;
      lockOnDate: boolean;
      date: {
        value: string;
        error: string;
      };
    };
  } = {
    loading: false,
    controls: {
      lockedSchedule: false,
      availability: {
        label: "",
        value: [],
        error: "",
        options: []
      },
      lockSelect: false,
      lockOnDate: false,
      date: {
        error: "",
        value: ""
      }
    }
  };

  isLoaded = false;
  initDates = {
    startDate: "",
    endDate: ""
  };

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  clickOutside() {
    this.$emit("close");
  }

  mounted() {
    const deploymentParams = this.deploymentParams || {};
    this.formData.controls.lockedSchedule = !!(
      deploymentParams.lockedSchedule || 0
    );
    this.formData.controls.lockSelect = !!(deploymentParams.lockSelect || 0);
    this.formData.controls.availability.value = deploymentParams.lockAvailability
      ? deploymentParams.lockAvailability.split(",")
      : [];
    this.formData.controls.lockOnDate = !!(deploymentParams.lockOnDate || 0);
    this.formData.controls.date.value =
      deploymentParams.lockDate && moment(deploymentParams.lockDate).isValid()
        ? moment(deploymentParams.lockDate).format("MM/DD/YYYY")
        : "";
    this.formData.controls.availability.options = (
      this.availabilitiesList || []
    ).map(item => {
      return {
        id: item.available.availabilityId + "",
        text: item.name,
        html: `${item.name}`,
        title: `${item.name}`,
        data: item
      };
    });
    this.isLoaded = true;

    $(this.$refs.modal).modal("show");
  }

  async onSubmit() {
    if (this.formData.loading) {
      return false;
    }
    // validate
    let valid = true;
    if (
      this.formData.controls.lockSelect &&
      !this.formData.controls.availability.value.length
    ) {
      valid = false;
      notifier.alert("Please select at least an availability to lock");
    }
    if (
      valid &&
      this.formData.controls.lockOnDate &&
      (!this.formData.controls.date.value ||
        !moment(this.formData.controls.date.value).isValid())
    ) {
      valid = false;
      notifier.alert("Please select a date to lock");
    }
    if (!valid) return;

    this.formData.loading = true;
    const lockDate = this.formData.controls.date.value
      ? moment(this.formData.controls.date.value).format("YYYY-MM-DD")
      : "";
    const lockAvailability = this.formData.controls.availability.value.join(
      ","
    );
    const response = await ApiHelper.callApi("post", {
      controller: "Deployments",
      FunctionName: "UpdateLockSchedule",
      update: true,
      deploymentId: this.deploymentId,
      lockedSchedule: this.formData.controls.lockedSchedule ? 1 : 0,
      lockSelect: this.formData.controls.lockSelect ? 1 : 0,
      availability: lockAvailability,
      lockOnDate: this.formData.controls.lockOnDate ? 1 : 0,
      lockDate
    });
    this.formData.loading = false;
    if (response.STATUS == 1) {
      notifier.success(response.STATUSMESSAGE || "Updated Lock Schedule");
      this.$emit("callback");
      this.$emit("close");
    } else {
      notifier.alert(response.STATUSMESSAGE || `Cant update Lock Schedule`);
    }
  }

  resetSetting() {
    // reset
    this.formData.controls.lockedSchedule = false;
    this.formData.controls.date.value = "";
    this.formData.controls.lockSelect = false;
    this.formData.controls.lockOnDate = false;
    this.formData.controls.availability.value = [];
  }

  doUnlockAll() {
    this.resetSetting();
    this.onSubmit();
  }

  toggleSelectedAvailability(userId: number, accountName) {
    this.formData.controls.lockSelect = true;
    const index = this.formData.controls.availability.value.findIndex(
      id => id === userId
    );
    if (index === -1) {
      this.formData.controls.availability.value.push(userId);
      return;
    }

    this.formData.controls.availability.value.splice(index, 1);
    // this.$delete(this.details.securityAccountsArr, index);
  }

  selectAllAvailabilities() {
    this.resetSelectedAvailabilities();
    const $this = this;
    this.formData.controls.availability.value = [];
    $.each($this.formData.controls.availability.options, (i, val) => {
      $this.formData.controls.availability.value.push(val.id);
    });
  }

  resetSelectedAvailabilities() {
    this.formData.controls.availability.value = [];
    // this.details.securityAccountsArr = [];
  }

  lockToggle() {
    if (!this.formData.controls.lockedSchedule) {
      this.resetSetting();
    }
  }

  lockSelectToggle() {
    if (!this.formData.controls.lockSelect) {
      // reset selected availabilities
      this.formData.controls.availability.value = [];
    }
  }

  lockOnDateToggle() {
    if (!this.formData.controls.lockOnDate) {
      this.formData.controls.date.value = "";
    }
  }

  dayChange(selected) {
    this.formData.controls.lockOnDate = true;
  }
}
