

































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ModalAddScheduleGroup from "@/components/Deployment/ModalAddScheduleGroup.vue";
import { colsResizeable, getEUAddress } from "@/helpers/ApiHelper";
import DeploymentScheduleUsersTooltip from "@/components/Deployment/DeploymentScheduleUsersTooltip.vue";
import ModalEditScheduleGroupNotes from "@/components/Deployment/ModalEditScheduleGroupNotes.vue";
import ModalEditScheduleGroupUserNotes from "@/components/Deployment/ModalEditScheduleGroupUserNotes.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import axios from "axios";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";
import { getDateRangeFilter } from "@/helpers/ApiHelper";

interface Props {
  accounts: [];
  users: [];
}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {
    ModalEditScheduleGroupNotes,
    ModalAddScheduleGroup,
    DeploymentScheduleUsersTooltip,
    ConfirmRemoveItemModal,
    LaddaButton,
    ModalEditScheduleGroupUserNotes,
    ListHeaderActions
  },
  methods: {
    getEUAddress,
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
    
  }
})
export default class DeploymentSchedule extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: [] })
  items?: any;

  @Prop({ required: false, default: [] })
  accounts?: any;

  @Prop({ required: false, default: [] })
  users?: any;

  @Prop({ required: true, default: [] })
  columns!: string[];

  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: false, default: {sDate: '', eDate: ''} })
  filter?: any;

  searchCondition = {
    sDate: '',
    eDate: ''
  }

  accountOptions = [];
  usersTooltipVisibleIndex = -1;
  modalEditSchedule = {
    show: false,
    loading: false,
    data: {}
  };
  modalEditScheduleUserNotes = {
    show: false,
    data: {
      scheduleGroupId: 0,
      userId: 0,
      note: ''
    }
  };
  confirmRemoveSchedule = false;
  confirmRemoveScheduleNote = false;
  confirmRemoveScheduleGroupUserNotes = false;
  deleteLoading: boolean | string = false;
  selectedItem: any = null;
  currentRole = sessionStorage.getItem("userRole");
  scheduleGroupExpandSettings: any = [];
  deleteScheduleGroupUserNote = {
    loading: false,
    data: {
      scheduleGroupId: 0,
      userId: 0,
      note: ''
    }
  };

  async created() {
    colsResizeable({
      ms: 500,
      selector: ".page-list .page-list-container > table.tbl-schedule"
    });

    for (const item of this.items) {
      this.scheduleGroupExpandSettings.push({isExpanded: false});
      if (item.ENDUSERS.length) {
        const params = this.parseJSON(item.PARAMS);
        const groupUserNotes = params.groupUserNotes || [];
        for (const endUser of item.ENDUSERS) {
          const userNote = groupUserNotes.find((gun) => gun.USERID === endUser.USERID);

          if (userNote) {
            endUser.note = userNote.NOTE;
          } else {
            endUser.note = '';
          }
        }
      }
    }

    this.searchCondition = { ...this.filter };
  }

  sortScheduleTargetDate() {
    this.$emit("sort");
  }

  searchByDate() {
    this.searchCondition = { ...this.filter };
    this.$emit("filter");
  }

  resetSearchByDate() {
    this.filter.sDate = '';
    this.filter.eDate = '';

    this.searchCondition = { ...this.filter };

    this.$emit("filter");
  }

  openEditModal(item: any) {
    if (this.currentRole === 'Reseller' && (item.ITEMTYPE || 1) == 1) {
      // is group
      this.modalEditSchedule.data = item;
      this.modalEditSchedule.show = true;
    }
  }

  modalEditScheduleNotes : {
    show: boolean;
    loading: boolean;
    data: any;
  } = {
    show: false,
    loading: false,
    data: {}
  };

  showEditNote(item) {
    this.modalEditScheduleNotes.data = item;
    this.modalEditScheduleNotes.show = true;
  }

  showEditScheduleGroupUserNote(scheduleGroupId, userId, note) {
    this.modalEditScheduleUserNotes.show = true;
    this.modalEditScheduleUserNotes.data = {
      scheduleGroupId,
      userId,
      note
    };

  }

  updateScheduleGroupNote(id, notes) {
    const groupName = this.modalEditScheduleNotes.data.GROUPNAME || '';
    const findGroup = this.items.find(item => item.GROUPID == id && item.GROUPNAME == groupName);
    if (findGroup) {
      findGroup.GROUPNOTES = notes;
    }
  }

  updateScheduleGroupUserNote({scheduleGroupId, userId, note}) {
    this.modalEditScheduleUserNotes.show = false;
    // set updated note to schedule group user
    const findScheduleGroup = this.items.find(item => item.GROUPID === scheduleGroupId);

    if (findScheduleGroup.ENDUSERS.length > 0) {
      for (const endUser of findScheduleGroup.ENDUSERS) {
        if (endUser.USERID === userId) {
          endUser.note = note;
        }
      }
    }
  }

  showUsersTooltip(item, index, e: any) {
    if (this.usersTooltipVisibleIndex === index) {
      return;
    }
    this.usersTooltipVisibleIndex = index;
    this.isTopTooltip("endUsers", $(e.target));
  }

  isTopTooltip(type = "", tooltip: any = null) {
    if (type == "endUsers") {
      if (!tooltip.length) return false;
      $(".eu-info").removeClass("topTooltip");
      const tooltipHeight = tooltip.offset().top + $(window).scrollTop();
      const container = $("body").height();

      if (tooltipHeight + 310 >= container) {
        tooltip.addClass("topTooltip");
        return true;
      }
    }

    return false;
  }

  getFilterDateValue() {
    return getDateRangeFilter(this.searchCondition.sDate, this.searchCondition.eDate);
  }

  parseJSON(paramsStr) {
    try {
        return JSON.parse(paramsStr)
    } catch (e) {
        return {};
    }
  }

  async removeSchedule() {
    if (!this.selectedItem) return;

    try {
      this.deleteLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "RemoveSchedule",
        deploymentId: this.deploymentId,
        scheduleGroupId: this.selectedItem.GROUPID || 0,
        endUserId: this.selectedItem.ENDUSERIDS || 0
      });

      if (response.data.STATUS == 1) {
        this.deleteLoading = false;
        notifier.success(response.data.MESSAGE);
        this.$emit("reloadData");
        this.confirmRemoveSchedule = false;
      } else {
        this.deleteLoading = "error";
      }
    } catch (error) {
      this.deleteLoading = "error";
    }
  }

  async removeScheduleNote() {
    if (!this.selectedItem) return;

    try {
      this.deleteLoading = true;
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Deployments",
          FunctionName: "RemoveScheduleNote",
          deploymentId: this.deploymentId,
          scheduleGroupId: this.selectedItem.GROUPID || 0,
          endUserId: this.selectedItem.ITEMTYPE === 2 ? this.selectedItem.ENDUSERIDS || 0 : 0,
          itemType: this.selectedItem.ITEMTYPE
        }
      );

      if (response.STATUS == 1) {
        const groupName = this.selectedItem.GROUPNAME || '';
        const groupId = this.selectedItem.GROUPID || 0 || '';
        const findGroup = this.items.find(item => item.GROUPID == groupId && item.GROUPNAME == groupName);
        if (findGroup) {
          findGroup.GROUPNOTES = '';
        }
        ApiHelper.showSuccessMessage(response.STATUSMESSAGE || 'Deleted notes successfully');
      } else {
        ApiHelper.showErrorMessage(response.STATUSMESSAGE || 'Cant remove note');
      }
      this.deleteLoading = false;
      this.confirmRemoveScheduleNote = false;
    } catch (error) {
      this.deleteLoading = false;
      this.deleteLoading = "error";
      this.confirmRemoveScheduleNote = false;
    }
  }

  async removeScheduleGroupUserNote() {
    this.deleteScheduleGroupUserNote.loading = true;
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "UpdateScheduleGroupUserNotes",
          deploymentId: this.deploymentId,
          scheduleGroupId: this.deleteScheduleGroupUserNote.data.scheduleGroupId,
          userId: this.deleteScheduleGroupUserNote.data.userId,
          note: ''
        });

        const message = response.data.STATUSMESSAGE || "";
        if (response.data.STATUS == 1) {
          notifier.success(message);
          const findScheduleGroup = this.items.find(item => item.GROUPID === this.deleteScheduleGroupUserNote.data.scheduleGroupId);

          if (findScheduleGroup.ENDUSERS.length > 0) {
            for (const endUser of findScheduleGroup.ENDUSERS) {
              if (endUser.USERID === this.deleteScheduleGroupUserNote.data.userId) {
                endUser.note = '';
              }
            }
          }

          this.confirmRemoveScheduleGroupUserNotes = false;
        } else {
          if (message) {
            notifier.alert(message);
          }
        }
      } catch (error) {
        console.log('error ', error);
      } finally {
        this.deleteScheduleGroupUserNote.loading = false;
      }
  }

  toggleUserNote(index) {
    this.scheduleGroupExpandSettings[index].isExpanded = !this.scheduleGroupExpandSettings[index].isExpanded;
  }
}
