






































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from '../components/LaddaButton.vue'

interface Props {}

interface Events {
  onClose: void; 
}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class FilterModal extends TSXComponent<Props, Events> {
  
  @Prop({ required: false, default: [] })
  filters?: any;
  
  @Prop({ required: false, default: {} })
  filterHeaders?: any;

  @Prop({required: true})
  title!: string

  @Prop({ required: false, default: false })
  showClearBtn?: any;

  saving: boolean = false

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  clear() {
    this.saving = true;
    this.$emit("clear");
  }
  //Individual reset function
  resetIndividually(resetFunction: string) {
    if(resetFunction != "") {
      if(this.filters.length == 1 || ( this.filters.length == 2 && resetFunction == "resetTag")) {
        this.$emit("close");
      }
      this.$parent[resetFunction]();
    }
  }
}
