var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function";

  return "<table width=\"100%\" id=\"customerList_header\">\n  <tr>\n    <td style=\"padding-right: 5px;\">\n      <img src=\"https://s3.amazonaws.com/smartstoresgeneric/mail/HP_logo_remote.png\" width=\"63\" height=\"63\" style=\"display:block; border: 0;\" border=\"0\" />\n    </td>\n    <td colspan=\"2\"><img src=\"https://smartstoresgeneric.s3.amazonaws.com/mail/milestonetechlogo.png\" style=\"display:block; border: 0; float: right; height: 52px;\" border=\"0\" /></td>\n  </tr>\n  <tr>\n    <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 20px;letter-spacing: 1.7px;\">\n      <strong>HP INC.</strong><br/>\n      14231 Tandem Blvd, Austin, Tx 78728\n    </td>\n  </tr>\n  <tr>\n    <td colspan=\"3\">\n      <table width=\"100%\">\n        <tr>\n          <td style=\"padding: 20px 0;font-size: 26px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\"># "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PAYCHECKOVERVIEW : depth0)) != null ? stack1.PAYCHECKID : stack1), depth0))
    + " <span style=\"color: #0196d5;\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PAYCHECKOVERVIEW : depth0)) != null ? stack1.PAYCHECKNAME : stack1), depth0))
    + "</span> </td>\n        </tr>\n      </table>\n    </td>\n  </tr>\n</table>\n<table width=\"100%\" id=\"quoteDetails_info\">\n  <tr>\n    <td width=\"60%\">\n      <table>\n        <tr><td class=\"title\">User Name :</td></tr>\n        <tr><td><strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.USERINFO : depth0)) != null ? stack1.UFULLNAME : stack1), depth0))
    + "</strong></td></tr>\n        <tr><td class=\"title\">Date range :</td></tr>\n        <tr><td><strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PAYCHECKOVERVIEW : depth0)) != null ? stack1.STARTDATE_FORMATTED : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PAYCHECKOVERVIEW : depth0)) != null ? stack1.ENDDATE_FORMATTED : stack1), depth0))
    + "</strong></td></tr>\n        <tr><td class=\"title\">Date :</td></tr>\n        <tr><td><strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PAYCHECKOVERVIEW : depth0)) != null ? stack1.GENERATEDDATE_FORMATTED : stack1), depth0))
    + "</strong></td></tr>\n      </table>\n    </td>\n    <td width=\"40%\">\n      <table>\n        <tr><td class=\"title\">Note :</td></tr>\n        <tr><td><strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PAYCHECKOVERVIEW : depth0)) != null ? stack1.PNOTE : stack1), depth0))
    + "</strong></td></tr>\n        <tr><td class=\"title\">Total :</td></tr>\n        <tr><td><strong>"
    + alias2(((helper = (helper = helpers.TOTALEARN_FORMATTED_4 || (depth0 != null ? depth0.TOTALEARN_FORMATTED_4 : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"TOTALEARN_FORMATTED_4","hash":{},"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":41,"column":49}}}) : helper)))
    + "</strong></td></tr>\n        <tr><td class=\"title\">Status :</td></tr>\n        <tr><td><strong>"
    + alias2(((helper = (helper = helpers.STATUSASTEXT || (depth0 != null ? depth0.STATUSASTEXT : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"STATUSASTEXT","hash":{},"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":43,"column":40}}}) : helper)))
    + "</strong></td></tr>\n      </table>\n    </td>\n  </tr>\n</table>\n\n<table width=\"100%\" id=\"customerList_info\">\n  <tr><td></td></tr>\n</table>\n";
},"useData":true});