









import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Dashboard from '../components/Dashboard.vue'
import CustomerDashboard from '../customer/components/Dashboard.vue';
@Component({
  inheritAttrs: false,
  components: {
    Dashboard,
    CustomerDashboard
  },
})
export default class DashboardPage extends TSXComponent<void> {
  $showDashboard: any;
  $defaultView: any;
  $isCustomer: any;
  created() {
    if (!this.$showDashboard && this.$isCustomer) {
      this.$router.push({
        path: this.$defaultView,
      });
    }
  }

  async beforeCreate() {
    $("body").removeClass("login");
  }
}
