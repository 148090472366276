


















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
	options: [];
	selectedID: any;
}

interface SelectOption {
	ID: string;
	TEXT: string;
}

interface Events { }

declare const $: any;

@Component({
	inheritAttrs: false,
	components: {}
})

// Clone from DropdownControl
export default class SelectTagControl extends TSXComponent<Props, Events> {
	@Prop({ required: true })
	options!: SelectOption[];

	@Prop({ required: true })
	selectedID!: string;

	@Prop({ required: false, default: "" })
	label!: string;

	@Prop({ required: false, default: true })
	hideSearch?: boolean;

	@Prop({ required: false, default: "" })
	placeholderText?: string;

	@Prop({ required: false, default: -1 })
	indexVal?: number;

	@Prop({ required: false, default: '' })
	errorClass?: string;

	@Prop({ required: false, default: false })
	disabled!: any;

	search = '';
	dropdownVisible = false;
	isFocused = false;

	get filteredOptions() {

		const options = this.options || [];
		if (this.search) {
			return options.filter((opt) => opt.TEXT.toLowerCase().includes(this.search.toLowerCase()))
		}

		return options;
	}

	toggleDropdown() {
		if (!this.isFocused && this.disabled == 0) {
			this.dropdownVisible = !this.dropdownVisible;
			if (this.dropdownVisible) {
				this.isFocused = false;
			}
		}
		else {
			this.isFocused = false;
		}
	}
	toggleDropdownOpen() {
		if (!this.dropdownVisible) {
			this.dropdownVisible = true;
			this.isFocused = true;
		}
	}

	isChecked(id: any): boolean {
		const checked = this.filteredOptions.findIndex((opt) => opt.ID === this.selectedID) >= 0;
		return checked;
	}

	checkToHide() {
		this.dropdownVisible = false;
	}

	clickOutside() {
		this.dropdownVisible = false;
	}

	getSelectedText() {
		const selected = this.filteredOptions.find((opt) => opt.ID === this.selectedID);
		return selected ? selected.TEXT : '';
	}
}
