







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import DeploymentEUModal from "../components/DeploymentEUModal.vue";
import ModalEditDeploymentDataField from "../components/Deployment/ModalEditDeploymentDataField.vue";
import moment from "moment";
import axios from "axios";
import { DStatusType } from "../helpers/Types/StatusTypes";

declare const $: any;
declare const dataURL: string;

interface Props {
}

interface Events {
}

@Component({
  inheritAttrs: false,
  components: {
    DeploymentEUModal,
    ModalEditDeploymentDataField
  },
  methods: {}
})
export default class Deploy extends TSXComponent<Props, Events> {
  modalVisible = false;
  showModalEditDeploymentDataField = false;
  pageData = {
    isLoading: false,
    hasError: false,
  };
  filters = {
    statusTypeId: 0
  };
  percentage = {
    plan: {
      title: "PLAN",
      receive: {
        title: "RECEIVE",
        percent: 0
      },
      schedule: {
        title: "SCHEDULE",
        percent: 0
      }
    },
    build: {
      title: "BUILD",
      build: {
        title: "BUILD",
        percent: 0
      },
      package: {
        title: "PACKAGE",
        percent: 0
      }
    },
    deliver: {
      title: "DELIVER",
      deploy: {
        title: "DEPLOY",
        percent: 0
      },
      validate: {
        title: "VALIDATE",
        percent: 0
      }
    }
  };
  estTooltipVisibleIndex: any = -1;
  logActive = "updates";
  notesTotalCnt = 0;
  statusTypeLogsCnt = 0;
  statusTypeLogs: any = [];
  notes: any = [];
  uuid:any = "";
  statusTypes: any = [];
  statusTypesGrouped: any = [];
  logModalVisible = -1;
  isActive = "dashboard";
  currentRole = sessionStorage.getItem("userRole");
  loading: string | boolean = false;
  deleteLoading: string | boolean = false;
  details: any = {};
  deploymentDetails: any = {};
  deploymentId = "";
  deploymentUUID:any = 0;
  endUserId = 0;
  endUserName = "";
  confirmEndUserRemove = false;
  userName = "";
  sort = {
    field: null,
    direction: {
      euName: 1
    }
  };
  directionField = "";
  identifiersAddModalVisible = false;
  assetId = 0;
  assetSKU = 0;
  assetName = 0;
  allocationTooltipVisibleIndex = -1;
  allocationTooltipLoading = false;
  allocationTooltipList = [];
  type: string = "";
  selectedUser: any = null;
  completionTooltipVisibleIndex = -1;
  completionTooltipLoading = false;
  totalDepPercent = 0;
  fullCustomData: any = [];
  customDataLoading = false;
  customDataIndex = -1;
  customOrderDataIndex = -1;
  customDataVisible = false;
  tieOrders: any = [];
  orderCustomData: any = [];
  firstPurchaseId = 0;
  apiExternalModalVisible = false;
  apiLoading = false;

  async created() {
    $("html, body, #body-site").addClass("external-h-100");
    this.deploymentUUID = this.$route.query.UUID;
    this.uuid = this.$route.query.UUID;
    await this.fetchData();
  }

  async fetchData(ignoreLoading = false) {
    await this.getDeploymentID();

    // get status type updates
    this.getStatusTypeLogs();

    // get status types
    this.getStatusTypes();

    // get notes
    this.getNotes();

    if (!ignoreLoading) {
      this.loading = true;
    }
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "view",
        subsystem: "External",
        deploymentUUID: this.deploymentUUID
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.deploymentId = this.details.DEPLOYMENTID;
        this.userName =
          typeof this.details.USERNAME != "undefined"
            ? this.details.USERNAME
            : "";
        this.deploymentDetails = this.details.DEPLOYMENTDETAILS.length
          ? this.details.DEPLOYMENTDETAILS[0]
          : {};
        this.totalDepPercent = Math.round(this.details.TOTALCOMPLETE);
        // this.tieOrders = this.details.ORDERDETAILS || [];
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  Modal(userInfo, type) {
    if (userInfo) {
      this.selectedUser = {
        ...userInfo,
        SITELOCATION: userInfo.SITELOCATION || "",
        LOCATIONNAME: userInfo.LOCATIONNAME || "",
      };
      if (!userInfo.DEPLOYMENTDATE.length) {
        this.selectedUser["DATE"] = "";
        this.selectedUser["HOUR"] = "";
        this.selectedUser["MINUTES"] = "";
      }
    } else {
      this.selectedUser = {
        SITELOCATION: "",
        LOCATIONNAME: "",
        ADDR1: "",
        ADDR2: "",
        ALLOCATED: "",
        ASSETS: [],
        CITY: "",
        COMPLETION: "",
        DEPLOYMENTID: this.deploymentId,
        EMAIL: "",
        EUADDRESS: "",
        EUNAME: "",
        FIRSTNAME: "",
        LASTNAME: "",
        LATESTLOGTS: "",
        LOGS: "",
        PHONE: "",
        PHONEEXT: "",
        SERVICES: [],
        STATE: "",
        TOTALLOGS: "",
        USERID: 0,
        ZIP: "",
        DATE: "",
        HOUR: "",
        MINUTES: ""
      };
      this.selectedUser = Object.create(this.selectedUser);
    }

    this.type = type;
    this.modalVisible = true;
  }

  toggleActive(div) {
    this.isActive = div;
    this.allocationTooltipVisibleIndex = -1;
    this.customDataVisible = false;
  }

  getTotalAllocated(items) {
    let total = 0;
    for (const item of items) {
      total += item.ALLOCATED || 0;
    }
    total = parseFloat(total.toFixed(2));

    return total;
  }

  isArray(item) {
    return (
      Array.isArray(item) && item.length > 0 && typeof item[0] === "object"
    );
  }

  isTrackingItems(statusDetails) {
    if (this.isArray(statusDetails)) {
      const foundTrackingInfo = statusDetails.find(
        item => (item.carrier || "") != "" && (item.trackingNumber || "") != ""
      );
      if (foundTrackingInfo) {
        return true;
      }
    }

    return false;
  }

  async getStatusTypes() {
    try {
      this.loading = true;
      //const response = await getStatusTypes(this.deploymentId);
      
      let ret = {
        statusTypes: [],
        receivePercent: 0,
        schedulePercent: 0,
        buildPercent: 0,
        packagePercent: 0,
        deployPercent: 0,
        validatePercent: 0,
        deploymentPercent: 0
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "GetStatusTypes",
        subsystem: "External",
        deploymentId: this.deploymentId
      });

      const calculateCategoryPercent = (type = "", statusTypes) => {
        let ret = 0;
        let totalPercent = 0;
        let totalCnt = 0;
        let relatedIds: number[] = [];
        switch (type) {
          case "receive":
            relatedIds = [DStatusType.Receive];
            break;
          case "schedule":
            relatedIds = [DStatusType.Allocate, DStatusType.CompleteScheduling];
            break;
          case "build":
            relatedIds = [DStatusType.Imaging, DStatusType.Tagging];
            break;
          case "package":
            relatedIds = [DStatusType.Packaging];
            break;
          case "deploy":
            relatedIds = [DStatusType.Deploy];
            break;
          case "validate":
            relatedIds = [DStatusType.SignOff];
            break;
        }
        if (!relatedIds.length) return 0;

        for (const item of statusTypes) {
          if (relatedIds.includes(item.id)) {
            totalCnt += 1;
            totalPercent += item.percent || 0;
          }
        }

        if (totalCnt > 0) {
          ret = totalPercent / totalCnt;
        }

        return parseInt(ret.toFixed(2));
      }

      if (response.data.STATUS == 1) {
      ret.statusTypes = (response.data.statusTypes || []).map(item => ({
        groupId: item.GROUPID,
        groupEst: moment(item.GROUPESTFORMATTED || "").isValid()
          ? moment(item.GROUPESTFORMATTED).format("MM/DD/YY")
          : "",
        groupEstFull: item.GROUPESTFORMATTED || "",
        id: item.STATUSTYPEID,
        estFull: item.ESTFORMATTED || "",
        est: moment(item.ESTFORMATTED || "").isValid()
          ? moment(item.ESTFORMATTED).format("MM/DD/YY")
          : "",
        title: item.STATUSTYPENAME,
        percent: parseInt(item.CURRENTPERCENT),
        total: parseInt(item.TOTALUPDATES)
      }));
  
      // calculate category percent
      // plan
      ret.receivePercent = calculateCategoryPercent("receive", ret.statusTypes);
      ret.schedulePercent = calculateCategoryPercent("schedule", ret.statusTypes);
      // build
      ret.buildPercent = calculateCategoryPercent("build", ret.statusTypes);
      ret.packagePercent = calculateCategoryPercent("package", ret.statusTypes);
      // deliver
      ret.deployPercent = calculateCategoryPercent("deploy", ret.statusTypes);
      ret.validatePercent = calculateCategoryPercent("validate", ret.statusTypes);
  
      // calculate deployment percent
      let totalPercent =
        ret.receivePercent +
        ret.schedulePercent +
        ret.buildPercent +
        ret.packagePercent +
        ret.deployPercent +
        ret.validatePercent;
        ret.deploymentPercent = parseFloat(totalPercent.toFixed(2)) / 6;
        ret.deploymentPercent = Math.round(ret.deploymentPercent);
      }

      this.statusTypes = ret;
      this.percentage.plan.receive.percent = ret.receivePercent || 0;
      this.percentage.plan.schedule.percent = ret.schedulePercent || 0;
      this.percentage.build.build.percent = ret.buildPercent || 0;
      this.percentage.build.package.percent = ret.packagePercent || 0;
      this.percentage.deliver.deploy.percent = ret.deployPercent || 0;
      this.percentage.deliver.validate.percent = ret.validatePercent || 0;

      const grouped:any = [];
      let item: any;
      for (item of ret.statusTypes) {
        const existed = grouped.find(t => t.groupId == item.groupId);
        if (!existed) {
          grouped.push({
            groupId: item.groupId,
            groupName: this.getGroupName(item.groupId),
            est: item.groupEst || "",
            estFull: item.groupEstFull || "",
            items: item.filter(t => t.groupId == item.groupId)
          });
        }
      }

      this.statusTypesGrouped = grouped;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  

  async getDeploymentID() {
    try{
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "GetDeployNumber",
        subsystem: "External",
        deploymentUUID: this.uuid
      });

      this.deploymentId = response.data.deployNumber;
    } catch(error) {
      console.log(error);
    }
  }

  async getStatusTypeLogs() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "GetStatusTypeLogs",
        subsystem: "External",
        deploymentId: this.deploymentId
      });
      if (response.data.STATUS == 1) {
        this.statusTypeLogs = response.data.logs || [];
        this.statusTypeLogsCnt = response.data.totalAll || 0;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async getNotes() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "GetNotes",
        subsystem: "External",
        deploymentId: this.deploymentId
      });
      if (response.data.STATUS == 1) {
        this.notes = response.data.notes || [];
        this.notesTotalCnt = response.data.totalAll || 0;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  showStatusTypeESTTooltip(type = "statusType", item) {
    let id = "";
    if (type == "statusType") {
      id = item.id;
    } else if (type == "group") {
      id = `group-${item.groupId}`;
    }

    if (this.estTooltipVisibleIndex == id) {
      return;
    }
    this.estTooltipVisibleIndex = id;
  }  

  async selectedESTDay(options) {
    this.estTooltipVisibleIndex = -1;
    if (options.type == "statusType") {
      // update value for related status type
      const statusType = this.statusTypes.find(
        item => item.id == options.statusTypeId
      );
      if (!statusType) return;

      statusType.est = moment(options.selected).format("MM/DD/YY");
      statusType.estFull = options.selected;
    } else if (options.type == "group") {
      // update value for related status types in this group
      const inGroup = this.statusTypes.filter(
        item => item.groupId == options.groupId
      );
      for (const item of inGroup) {
        item.groupEst = moment(options.selected).format("MM/DD/YY");
        item.groupEstFull = options.selected;
      }
    }

    // update status type est date
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateDeploymentESTDate",
        subsystem: "External",
        deploymentId: options.deploymentId,
        statusTypeId: options.type == "statusType" ? options.statusTypeId : 0,
        groupId: options.type == "group" ? options.groupId : 0,
        estDate: options.selected,
        type: options.type
      });
      if (response.data.STATUS == 1) {
        //
      }
    } catch (error) {
      console.log(error);
    }
  }

  getGroupName(groupId) {
    let ret = "";
    switch (groupId) {
      case 1:
        ret = "Plan";
        break;
      case 2:
        ret = "Build";
        break;
      case 3:
        ret = "Deliver";
        break;
    }

    return ret;
  }

  showUpdates() {
    this.logActive = "updates";
  }

  showNotes() {
    this.logActive = "notes";
  }

  getLatestLogText(createdTs) {
    const date = createdTs ? moment(createdTs) : moment();
    return date.utc().fromNow();
  }

  getTrackingLink(carrier, trackingNumber){
    if (!carrier || !trackingNumber) return "";
    let ret = "";
    switch (carrier.toLowerCase().trim()) {
      case "federal express corporation":
        ret = `https://www.fedex.com/fedextrack/?cntry_code=us&tracknumbers=${trackingNumber}`;
        break;
      case "eagle global logistics":
        ret = `http://www.cevalogistics.com/ceva-trak?sv=${trackingNumber}`;
        break;
      case "ups":
        ret = `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
        break;
    }

    return ret;
  }

  getInvoicePaidNote(item) {
    var ret = "";
    try {
      if (item.STATUS_DETAILS == "") {
        return "";
      }

      let tmp = JSON.parse(item.STATUS_DETAILS);
      if (typeof tmp.PAYMENTAMOUNT != "undefined") {
        ret =
          ret + "<div>Payment Amount: " + (tmp.PAYMENTAMOUNT || "0") + "</div>";
      }
      if (typeof tmp.PAYMENTDATE != "undefined") {
        ret = ret + "<div>Payment Date: " + (tmp.PAYMENTDATE || "") + "</div>";
      }
      if (typeof tmp.PROFITTOTAL != "undefined") {
        ret = ret + "<div>Profit Total: " + (tmp.PROFITTOTAL || "") + "</div>";
      }
      if (typeof tmp.PAYMENTTYPE != "undefined") {
        ret = ret + "<div>Payment Type: " + (tmp.PAYMENTTYPE || "") + "</div>";
      }
      if (
        typeof tmp.PAYMENTREFERENCE != "undefined" &&
        tmp.PAYMENTREFERENCE != ""
      ) {
        ret =
          ret +
          "<div class='status-pay-ref'>Payment Reference: " +
          (tmp.PAYMENTREFERENCE || "") +
          "</div>";
      }
    } catch (err) {
      // console.log(err);
    }

    return ret;
  }

  getTotalPeople(item: any) {
    const params = JSON.parse(item.PARAMS || "{}");
    const selectedIds = params.users || "[]";
    const ids = JSON.parse(selectedIds);
    return ids.length;
  }

  getAddress(item: any) {
    const arr: any = [];
    if(item.ADDRESS1) {
      arr.push(item.ADDRESS1);
    }
    if(item.ADDRESS2) {
      arr.push(item.ADDRESS2);
    }
    if(item.CITY) {
      arr.push(item.CITY);
    }
    if(item.STATE) {
      arr.push(item.STATE);
    }
    if(item.ZIP) {
      arr.push(item.ZIP);
    }
    return arr.join(',');
  }
}
