import axios from "axios";
import { QuoteViewDetailed } from '../models/QuoteViewDetailed';
import { notifier } from "@/models/common";
import {headersarr} from "@/repos/OrderAPIRepo";
import {Order} from "@/models/Order";

declare const dataURL: string;
declare const getMaxRows: Function;

export type Filters = {
	ms_opp_id?: string
	account_name?: string
	description?: string
	total_amt?: string
	opp_number?: string
	sales_stage?: number[]
	tags?: number[]
	expected_close_date?: string
	follow_up?: string
	account_lead?: string
	sTotalMin?: number
	sTotalMax?: number
	expected_close_date_sDate?: string | undefined
	expected_close_date_eDate?: string | undefined
	follow_up_sDate?: string | undefined
	follow_up_eDate?: string | undefined
	tagsName?: string[]
	est_payment_sDate?: string | undefined
	est_payment_eDate?: string | undefined
}

export enum SortField {
	ms_opp_id = 1,
	account_name = 2,
	description = 3,
	total_amt = 4,
	opp_number = 5,
	sales_stage = 6,
	expected_close_date = 7,
	follow_up = 8,
	account_lead = 9,
	probability = 10,
	businessLineName = 11,
	EstProfit = 12,
	nextSteps = 13,
	EstProfitPer = 14,
	est_payment_date = 15,
	add_date = 16,
}

export type Sort = {
	field: SortField | null | number
	direction: {
		[SortField.ms_opp_id]: number,
		[SortField.account_name]: number,
		[SortField.description]: number,
		[SortField.total_amt]: number,
		[SortField.opp_number]: number,
		[SortField.sales_stage]: number,
		[SortField.expected_close_date]: number,
		[SortField.follow_up]: number,
		[SortField.account_lead]: number,
	}
}

export type onExport = {
	templateid?: number,
	showOnExport?: number,
	saveAsDefault?: number,
	pricingid?: string,
	subTempName?: string,
	advanced?: any,
	customMessages?: object,
	doExport?: number
  }

export class OpportunityAPIRepo {
	async find(filters: Filters, sort: Sort, pageNumber: number, initialLoad: boolean = false, viewType: string = 'CurrentUsers', selectedView = false, CurrentOppType: string = '') {
		const reqData: any = this.prepareFilteredData(filters, sort, initialLoad, viewType);
		reqData["FunctionName"] = "List";
		reqData["maxRows"] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows();
		reqData["PageNumber"] = pageNumber.toString();
		reqData["selectedView"] = selectedView;
		reqData["currentOppType"] = CurrentOppType;

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

	async findOneDetails(opportunityID: string, exportObj?: onExport): Promise<QuoteViewDetailed.Root> {
		const response = await axios.post(dataURL + "?ReturnType=JSON", {
		  controller: "Opportunities",
		  FunctionName: "View",
		  ObjID: opportunityID,
		  displayOnExport: exportObj ? exportObj.showOnExport : 0,
		  saveAsDefault: exportObj ? exportObj.saveAsDefault : 0,
		  SPECIALPRICINGID: exportObj ? exportObj.pricingid : '',
		  exportTemplate: exportObj ? exportObj.templateid : 0,
		  Aztempname: exportObj ? exportObj.subTempName : "",
		  hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
		  hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
		  Content: "Detailed",
		  customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {})
		});
	
		if (response.data.ERROR) {
		  throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
		  throw new Error(response.data.STATUSMESSAGE);
		}
	
		return response.data
	  }

	async updateNotes(paycheckID: number, pNote: string) {
		var ret = true;
		var requestData = {
			"controller": "Finances",
			"FunctionName": "UpdatePaycheckNotes",
			"paycheckID": paycheckID,
			"pNote": pNote
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			ret = false;
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			ret = false;
			throw new Error(response.data.STATUSMESSAGE);
		}

		return ret;
	}

	async updateStatus(id: string, index: string) {
		var params: any = {
			"controller": "Quotes",
			"FunctionName": "opportunityUpdate",
			"ms_opp_id": id,
			"sales_stage": index
		}
		const response = await axios.post(dataURL + "?ReturnType=JSON", params);
		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (!response.data.STATUS) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

	async saveAndRemoveTags(selectedOpportunityTagsID: number[], id: number) {
		var params: any = {
			controller: "Opportunities",
			FunctionName: "UpdateOpportunity",
			oppID: id,
			field: 'TAGS',
			selectedTagVal: selectedOpportunityTagsID
		}
		const response = await axios.post(dataURL + "?ReturnType=JSON", params);
		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (!response.data.STATUS) {
			throw new Error(response.data.STATUSMESSAGE);
		}
		return response.data
	}

	prepareFilteredData(filters: any, sort: any, initialLoad: boolean = false, view: string = 'CurrentUsers', isStatus: boolean = false) {
		var ret = {
			"controller": "Opportunities",
			"ms_opp_id": filters.ms_opp_id,
			"account_name": filters.account_name,
			"description": filters.description,
			"nextSteps": filters.nextSteps,
			"oppLead": filters.oppLead,
			"total_amt": filters.total_amt,
			"sTotalMin": Number(filters.sTotalMin),
			"sTotalMax": Number(filters.sTotalMax),
			"opp_number": filters.opp_number,
			"sales_stage": filters.sales_stage ? filters.sales_stage.join(",") : '',
			"expected_close_date_sDate": filters.expected_close_date_sDate ? filters.expected_close_date_sDate : '',
			"expected_close_date_eDate": filters.expected_close_date_eDate ? filters.expected_close_date_eDate : '',
			"est_payment_sDate": filters.est_payment_sDate ? filters.est_payment_sDate : '',
			"est_payment_eDate": filters.est_payment_eDate ? filters.est_payment_eDate : '',
			"follow_up_sDate": filters.follow_up_sDate ? filters.follow_up_sDate : '',
			"follow_up_eDate": filters.follow_up_eDate ? filters.follow_up_eDate : '',
			"opp_update_date_sDate": filters.opp_update_date_sDate ? filters.opp_update_date_sDate : '',
			"opp_update_date_eDate": filters.opp_update_date_eDate ? filters.opp_update_date_eDate : '',
			"expected_close_date": filters.expected_close_date,
			"follow_up": filters.follow_up,
			"account_lead": filters.account_lead,
			"opp_type": filters.opp_type,
			"view": view
		}

		if (sort.field) {
			ret["order"] = sort.field;
			ret["direction"] = sort.direction[sort.field].toString();
		}

		if (filters.sales_stage.length == 0) {
			ret["sales_stage"] = "-1";
		}

		ret["tags"] = "";
		if (filters.tags.length) {
			ret['tags'] = filters.tags.join(',');
		}
		else if (!initialLoad && filters.tags.length == 0) {
			ret['tags'] = "-1";
		}

		if (!initialLoad && filters.probability && filters.probability.length) {
			ret['probability'] = filters.probability.join(',');
		} else if (!initialLoad && filters.probability && filters.probability.length == 0) {
			ret['probability'] = "-1";
		}

		ret["tagIDs"] = (typeof filters["AccountTagID"] != "undefined" ? filters["AccountTagID"].join(",") : "");
		ret["gTagIDs"] = (typeof filters["gTagIDs"] != "undefined" ? filters["gTagIDs"].join(",") : "");
		ret["groupIDs"] = (typeof filters["groupIDs"] != "undefined" ? filters["groupIDs"].join(",") : "");
		ret["selectedAccountIDs"] = (typeof filters["selectedAccs"] != "undefined" ? filters["selectedAccs"].join(",") : "");
		ret["selectedUserIDs"] = (typeof filters["selectedUsers"] != "undefined" ? filters["selectedUsers"].join(",") : "");

		ret['businessLine'] = "";
		if (typeof filters.businessLineId != 'undefined') {
			if (filters.businessLineId.length > 0) {
				ret['businessLine'] = filters.businessLineId.join(',')
			}
			else if (!initialLoad && filters.businessLineId.length == 0) {
				ret['businessLine'] = "-1";
			}
		}

		if (typeof filters.estProfitMin !== 'undefined') {
			ret['estProfitMin'] = filters.estProfitMin;
		}
		if (typeof filters.estProfitMax !== 'undefined') {
			ret['estProfitMax'] = filters.estProfitMax;
		}

		if (typeof filters.estProfitPerMin !== 'undefined') {
			ret['estProfitPerMin'] = filters.estProfitPerMin;
		}

		if (typeof filters.estProfitPerMax !== 'undefined') {
			ret['estProfitPerMax'] = filters.estProfitPerMax;
		}

		if (typeof filters["viewFrom"] != "undefined") {
			ret["viewFrom"] = filters["viewFrom"];
		}
	
		return ret;
	}




	async exportListsCSVFileUrl(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: headersarr[] = [], isOrderStatus = 0): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, false, viewType);
		reqData["FunctionName"] = "Export";
		reqData["selectedID"] = ids;
		reqData["ExportType"] = "CSV";
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;
		reqData["selectedHeadersList"] = selectedHeaders;
		reqData["isOrderStatus"] = isOrderStatus;

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async exportOpportunityDetailFileUrl(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType);
		reqData["FunctionName"] = "ExportOpportunityDetail";
		reqData["selectedID"] = ids;
		reqData["ExportType"] = "CSV";
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async reportOpportunityDetailFileUrl(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType);
		reqData["FunctionName"] = "ReportingOpportunityDetails";
		reqData["selectedID"] = ids;
		reqData["ExportType"] = "CSV";
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async exportETS(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType);
		reqData["FunctionName"] = "ExportETS";
		reqData["selectedID"] = ids;
		reqData["ExportType"] = "CSV";
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async exportDetailsPDFFileUrl(purchaseID: string, exportObj: onExport): Promise<string> {
		const response = await axios.post(dataURL + "?ReturnType=JSON", {
			controller: "Opportunities",
			Content: "Detailed",
			ExportType: "PDF",
			FunctionName: "ExportView",
			displayOnExport: exportObj.showOnExport,
			// exportPO: exportObj.exportPO,
			saveAsDefault: exportObj.saveAsDefault,
			SPECIALPRICINGID: exportObj.pricingid,
			exportTemplate: exportObj.templateid,
			hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
			hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
			includePayment: exportObj && exportObj.advanced && exportObj.advanced.includePayment ? exportObj.advanced.includePayment : false,
			purchaseID: purchaseID,
			customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {}),
			// CustomDistyName: typeof exportObj != "undefined" && typeof exportObj.CustomDistyName != "undefined" ? exportObj.CustomDistyName : '',
			// CustomDistyAddr1: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr1 != "undefined" ? exportObj.CustomDistyAddr1 : '',
			// CustomDistyAddr2: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr2 != "undefined" ? exportObj.CustomDistyAddr2 : '',
			// CustomDistyCity: typeof exportObj != "undefined" && typeof exportObj.CustomDistyCity != "undefined" ? exportObj.CustomDistyCity : '',
			// CustomDistyState: typeof exportObj != "undefined" && typeof exportObj.CustomDistyState != "undefined" ? exportObj.CustomDistyState : '',
			// CustomDistyZip: typeof exportObj != "undefined" && typeof exportObj.CustomDistyZip != "undefined" ? exportObj.CustomDistyZip : '',
			// exportView: exportObj.exportView || "",
			priceRolledUp: exportObj && exportObj.advanced && exportObj.advanced.priceRolledUp ? 1 : 0
		});

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async exportDetailsCSVFileUrl(purchaseID: string, exportObj: onExport): Promise<string> {
		const requestObj = {
			controller: "Opportunities",
			Content: "Detailed",
			ExportType: "CSV",
			FunctionName: "ExportView",
			displayOnExport: exportObj.showOnExport,
			// exportPO: exportObj.exportPO,
			saveAsDefault: exportObj.saveAsDefault,
			SPECIALPRICINGID: exportObj.pricingid,
			exportTemplate: exportObj.templateid,
			hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
			hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
			includePayment: exportObj && exportObj.advanced && exportObj.advanced.includePayment ? exportObj.advanced.includePayment : false,
			purchaseID: purchaseID,
			customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {}),
			// CustomDistyName: typeof exportObj != "undefined" && typeof exportObj.CustomDistyName != "undefined" ? exportObj.CustomDistyName : '',
			// CustomDistyAddr1: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr1 != "undefined" ? exportObj.CustomDistyAddr1 : '',
			// CustomDistyAddr2: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr2 != "undefined" ? exportObj.CustomDistyAddr2 : '',
			// CustomDistyCity: typeof exportObj != "undefined" && typeof exportObj.CustomDistyCity != "undefined" ? exportObj.CustomDistyCity : '',
			// CustomDistyState: typeof exportObj != "undefined" && typeof exportObj.CustomDistyState != "undefined" ? exportObj.CustomDistyState : '',
			// CustomDistyZip: typeof exportObj != "undefined" && typeof exportObj.CustomDistyZip != "undefined" ? exportObj.CustomDistyZip : '',
			// exportView: exportObj.exportView || ""
		};
		const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async exportOpportunityBySelect(purchaseIDs: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: headersarr[] = [], isOrderStatus = 0): Promise<Order.Root> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType);
		reqData["FunctionName"] = "List";
		reqData["selectedID"] = [];
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;
		reqData["selectedHeadersList"] = selectedHeaders;
		if(isOrderStatus){
			reqData["FunctionName"] = "statusList";
		}

		if (purchaseIDs.length > 0) {
			reqData.selectedID = purchaseIDs;
		} else {
			reqData.getAll = 1;
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

	async exportListsPDFFileUrl(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: headersarr[] = [], isOrderStatus = 0): Promise<string> {
		const reqData: any = this.prepareFilteredData(filters, sort, viewType);
		reqData["FunctionName"] = "Export";
		reqData["selectedID"] = ids;
		reqData["ExportType"] = "PDF";
		reqData["selectedAll"] = selectedAll;
		reqData["excludedIDs"] = excludedIDs;
		reqData["selectedView"] = selectedView;
		reqData["selectedHeadersList"] = selectedHeaders;
		reqData["isOrderStatus"] = isOrderStatus;
		const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}
}

