

















































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import moment from "moment";

import InputDate from "../components/InputDate.vue";
import Pagination from "../components/Pagination.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier, wait, downloadFileUrl } from "../models/common";
import InfoTooltip from "../components/InfoTooltip.vue";
import InputDateRange from "../components/customDateComponent.vue";
import poIDAutoSuggestInput from "../components/poIDAutoSuggestInput.vue";
import Datepicker from "./Datepicker.vue";
import directives from "../helpers/directives";

interface Props {}

interface Events {
	onClose: void;
	onGenerated: void;
}

declare const $: any;
declare const dataURL: string;
declare const getMaxRows: Function;

@Component({
	inheritAttrs: false,
	components: {
		InputDate,
		Pagination,
		EmployeeMultiselect,
		LaddaButton,
		InfoTooltip,
		InputDateRange,
		poIDAutoSuggestInput,
		Datepicker
	},
	directives
})
export default class LedgerGenerateModal extends TSXComponent<Props, Events> {

	@Prop({required: true, default: ""})
	title!: string

	@Prop({required: false, default: false})
	isLedgerEdit!: boolean

	@Prop({required: false, default: ""})
	editledgerID!: string; 

	@Prop({required: false, default: false})
	loading!: any
	
	generating = false;
	confirmLedgerGenerate = false;
	ledgerLoading = false;
	noOfOrders = 0;
	ledgerTotalAmount = 0;
	statementAmount = 0;
	indirectAmount = 0;
	statementsTotal = 0;
	indirectTotal = 0;
	grandTotal = "0";
	TYPE = "";
	SOURCE = "";
	STATUS = "";
	purchaseID = "";
	poID = "";
	PID = "";
	PIDlist: number[] = [];
	fStatus = 0;
	fSource = "";
	fType = 2;
	pl_amount = "";
	startDate = Date.now();
	endDate = Date.now();
	startDateOrder = Date.now();
	endDateOrder = Date.now();
	selectedMonth = null;
	dateFilter = 0;
	selectedStatementIds: number[] = [];
	selectedOrderIds: number[] = [];
	indirectSale = false;
	ordersInOtherLedgers = false;
	statementInOtherLedgers = false;
	onlyPaidOrders = true;
	ledgerIncludeName = "";
	invoiceID = "";
	invoiceDueDate = moment(Date.now()).add(45, 'd').format("MM/DD/YYYY");
	orderInvoiceDate = "";
	ledgerPaidDate = "";
	modalTitle = "";
	ledgerEditPaidDate = 0;
	orderInvoiceDateTemp = "";

	activeTab: "statements" | "orders" = "statements";

	// Allocation tool tip
	allocationTooltipVisibleIndex = -1;
	allocationTooltipLoading = false;
	allocationTooltipList = [];
	allocationTooltipaID = 0;

	$refs!: {
		modal: HTMLDivElement;
		table: HTMLDivElement;
		customdate: InputDateRange;
	};

	mounted() {
		$(this.$refs.modal).modal("show");

		// $(this.$refs.modal).on("hide.bs.modal", () => {
		// 	this.$emit("close");
		// });
	}

	async created() {
		this.modalTitle = this.title;
		if(this.editledgerID != "") {
			// case user wants to edit ledger info
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				Controller: "Finances",
				FunctionName: "ledgerOptions",
				editledger: "true",
				selectedID: this.editledgerID
			});
			this.purchaseID = response.data.getLedgerDetails[0].PURCHASEID;
			this.pl_amount = response.data.getLedgerDetails[0].PL_AMOUNT;
			this.fType = response.data.getLedgerDetails[0].PL_TYPE;
			this.fSource = response.data.getLedgerDetails[0].PL_FINSOURCE;
			this.fStatus = response.data.getLedgerDetails[0].PL_STATUS;
			this.invoiceID = response.data.getLedgerDetails[0].INVOICEID;
			this.orderInvoiceDate = response.data.getLedgerDetails[0].ORDERINVOICEDATE;
			this.invoiceDueDate = response.data.getLedgerDetails[0].INVOICEDUEDATE;
			this.ledgerPaidDate = response.data.getLedgerDetails[0].LEDGERPAIDDATE;
			this.ledgerEditPaidDate = response.data.getLedgerDetails[0].LEDGERPAIDDATE != '' ? 1 : 0;
			this.TYPE = response.data.getfinancialtype;
			this.SOURCE = response.data.getfinancialsource;
			this.STATUS = response.data.getfinancialstatus;
		} else {
			var sendData = {
				controller: "Finances",
				FunctionName: "ledgerOptions"
			};
			var vm = this;
			var ledgerSelects = getRouteData(sendData);
			ledgerSelects.then(function(response, statusText, jqXHR) {
				try {
					vm.TYPE = response.data.getfinancialtype;
					vm.SOURCE = response.data.getfinancialsource;
					vm.STATUS = response.data.getfinancialstatus;
				} catch (e) {
					//handle error
				}
			});
		}
	}

	// async generate() {
	// 	const ok = await this.$validator.validateAll();
	// 	if (!ok) {
	// 		return;
	// 	}

	// 	this.ledgerLoading = true;
	// 	try {
	// 		const payl = {
	// 			controller: "Finances",
	// 			FunctionName: "ledgerGenerate",
	// 			pl_amount: this.pl_amount,
	// 			fType: this.fType,
	// 			fSource: this.fSource,
	// 			fStatus: this.fStatus,
	// 			purchaseID: this.purchaseID,
	// 			invoiceID: this.invoiceID,
	// 			invoiceDueDate: this.invoiceDueDate
	// 		};

	// 		const resp = await axios.post(dataURL + "?ReturnType=JSON", payl);
	// 		// this.$emit('generated', resp);
	// 		this.$emit("ledgerUpdate");
	// 		this.ledgerLoading = false;
	// 		// await wait(1000);
	// 	} catch (err) {
	// 		notifier.alert(err.message);
	// 	} finally {
	// 		this.ledgerLoading = false;
	// 		this.$emit("close");
	// 	}
	// }

	async generate() {
		const ok = await this.$validator.validateAll();
		if (!ok) {
			return;
		}
		if (this.checkPaidDate) {
			return;
		}
		const ledgerVals = {
			pl_amount: this.pl_amount,
			fType: this.fType,
			fSource: this.fSource ? this.fSource : 0,
			fStatus: this.fStatus,
			purchaseID: this.purchaseID,
			invoiceID: this.invoiceID,
			orderInvoiceDate: this.orderInvoiceDate,
			invoiceDueDate: this.invoiceDueDate,
			ledgerPaidDate : this.ledgerPaidDate ? this.ledgerPaidDate : ''
		};
		this.$emit('generate', ledgerVals);
	}

	beforeDestroy() {
		$(this.$refs.modal).modal("hide");
	}

	empInfoTooltipData = "";
	empInfoTooltipVisibleIndex = -1;
	async showUsersCntInfo(index, item) {
		if (item.TOTALEMPLOYEES > 0) {
			this.empInfoTooltipData = "";
			this.empInfoTooltipVisibleIndex = index;
			try {
				const response = await axios.post(dataURL + "?ReturnType=JSON", {
					controller: "Finances",
					FunctionName: "GetAssginedPercent",
					cs_id: item.MC_CSTATEMENT_ID,
				});
				this.empInfoTooltipData =
					response.data.STATUS == 1 ? response.data.ASSIGNEDPERCENTARR : [];
			} catch (err) {
				// console.log(err.message);
			}
		}
	}

	async findPID(val){
		try {
			if(val != "" && val.length > 2){
				const response = await axios.post(dataURL + "?ReturnType=JSON", {
					controller: "Finances",
					FunctionName: "GetPurchaseIDs",
					poID: val,
				});
				this.PIDlist = response.data.productIDs
			}
			else{
				this.PIDlist = [];
			}
		} catch (err) {
			// console.log(err.message);
		}
	}

	updatePoID(data) {
		console.log("HERE!!!");
		console.dir(data)
		this.poID = data.account.poID;
		this.purchaseID = data.account.purchaseID;
	}
	async updateLedgerType(ledgerType) {
		if(ledgerType == 1 || ledgerType == 3 || ledgerType == 5) {
			this.fSource = "";
		}
		if(ledgerType == 4){
			this.orderInvoiceDateTemp = this.orderInvoiceDate;
			this.orderInvoiceDate = "";
		} else if(this.orderInvoiceDateTemp != ""){
			this.orderInvoiceDate = this.orderInvoiceDateTemp;
		}
	}

	clickOutside() {
		this.$emit("close");
	}

	removePaidDate() {
		if (this.fStatus != 1) {
			this.ledgerEditPaidDate = 0;
			this.ledgerPaidDate = '';
		}
	}

	get checkPaidDate() {
		if (this.fStatus == 1 && !this.ledgerPaidDate.length && this.ledgerEditPaidDate == 1) {
			return true;
		}
		return false;
	}
}
