var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":26,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOtherOptions : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":14},"end":{"line":80,"column":21}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isContractNo : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contractNumber),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":24,"column":25}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SPECIALPRICINGID),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <td style=\"padding: 5px; vertical-align: top;\">Contract Number: </td>\n                      <td style=\"padding: 5px;\">\n                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SPECIALPRICINGID), depth0))
    + "\n                      </td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.ISCUSTOMER),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":18},"end":{"line":24,"column":18}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SPECIALPRICINGID),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + "                  ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showQuoteNumber),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":44,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":54,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showPurchasingInstruction),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":79,"column":23}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                  <tr>\n                    <td style=\"padding: 5px; vertical-align: top;\">\n                      "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":31,"column":22},"end":{"line":31,"column":105}}})) != null ? stack1 : "")
    + "\n                    </td>\n                    <td style=\"padding: 5px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QQUOTEID),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":34,"column":22},"end":{"line":38,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER)) && stack1.length),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":22},"end":{"line":41,"column":29}}})) != null ? stack1 : "")
    + "                    </td>\n                  </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "HP Quote Number: ";
},"14":function(container,depth0,helpers,partials,data) {
    return "Quote Number: ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QQUOTEID), depth0))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEID), depth0))
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        - "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER), depth0))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contractNumber),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":18},"end":{"line":53,"column":25}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.paragraphRows),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":56,"column":18},"end":{"line":78,"column":25}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.paragraphRows),{"name":"each","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":20},"end":{"line":62,"column":29}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                      <tr>\n                        <td style=\"background-color: #fcfcfc;width: 175px; padding: 5px; vertical-align: top;\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.EDITPARAGRAPHNAME : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":111},"end":{"line":59,"column":164}}})) != null ? stack1 : "")
    + "</td>\n                        <td style=\"padding: 5px;\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.EDITPARAGRAPHCONTENT : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":50},"end":{"line":60,"column":109}}})) != null ? stack1 : "")
    + "</td>\n                      </tr>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.EDITPARAGRAPHNAME || (depth0 != null ? depth0.EDITPARAGRAPHNAME : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EDITPARAGRAPHNAME","hash":{},"data":data,"loc":{"start":{"line":59,"column":136},"end":{"line":59,"column":157}}}) : helper)));
},"29":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.EDITPARAGRAPHCONTENT || (depth0 != null ? depth0.EDITPARAGRAPHCONTENT : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EDITPARAGRAPHCONTENT","hash":{},"data":data,"loc":{"start":{"line":60,"column":78},"end":{"line":60,"column":102}}}) : helper)));
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <tr>\n                      <td style=\"background-color: #fcfcfc;width: 175px; padding: 5px; vertical-align: top;\">Purchasing Instructions: </td>\n                      <td style=\"padding: 5px;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.POInstructions),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":75,"column":31}}})) != null ? stack1 : "")
    + "                      </td>\n                    </tr>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.POInstructions), depth0)) != null ? stack1 : "")
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.PURCHASINGINSTRUCTION),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":70,"column":26},"end":{"line":74,"column":33}}})) != null ? stack1 : "")
    + ", list Partner ID: 10281367 and Quote ID: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QQUOTEID),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":74,"column":75},"end":{"line":74,"column":190}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER)) && stack1.length),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":191},"end":{"line":74,"column":297}}})) != null ? stack1 : "")
    + "on PO. Forward PO to <a href=\"mailto: co@milestonetech.com\">co@milestonetech.com</a> for processing. Do not send to HP. Thank you!\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.PURCHASINGINSTRUCTION), depth0)) != null ? stack1 : "")
    + " \n";
},"37":function(container,depth0,helpers,partials,data) {
    return "                            State APP, please see last page only. All others, please make PO out to HP Inc\n                          ";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QQUOTEID), depth0))
    + " ";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEID), depth0))
    + " ";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " - "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"quoteDetails_purchaseInfo\" style=\"margin-top: 35px; padding: 0;\">\n    <tr>\n        <td colspan=\"2\" style=\"padding: 0 32px;\">\n          <table width=\"100%\">\n            <tr><td colspan=\"2\" class=\"headerColor\" style=\"background: #fcfcfc; color: #A1A1A1; font-size: 10px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 0.24px; padding-top: 5px; padding-bottom: 12px;\">PURCHASING INSTRUCTIONS</td></tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.draggableOptions),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":81,"column":21}}})) != null ? stack1 : "")
    + "          </table>\n        </td>\n    </tr>\n</table>\n\n";
},"useData":true});