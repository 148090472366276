var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <tr>\n    <td width=\"10%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.PURCHASEID || (depth0 != null ? depth0.PURCHASEID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PURCHASEID","hash":{},"data":data,"loc":{"start":{"line":15,"column":46},"end":{"line":15,"column":60}}}) : helper)))
    + "</td>\n    <td width=\"16%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.D_FINANCIALSOURCE_NAME || (depth0 != null ? depth0.D_FINANCIALSOURCE_NAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"D_FINANCIALSOURCE_NAME","hash":{},"data":data,"loc":{"start":{"line":16,"column":46},"end":{"line":16,"column":72}}}) : helper)))
    + "</td>\n    <td width=\"18%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.D_FINANCIALTYPE_NAME || (depth0 != null ? depth0.D_FINANCIALTYPE_NAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"D_FINANCIALTYPE_NAME","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":70}}}) : helper)))
    + "</td>\n    <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.PL_AMOUNT || (depth0 != null ? depth0.PL_AMOUNT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PL_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":60}}}) : helper)))
    + "</td>\n    <td width=\"9%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.D_FINANCIALSTATUS_NAME || (depth0 != null ? depth0.D_FINANCIALSTATUS_NAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"D_FINANCIALSTATUS_NAME","hash":{},"data":data,"loc":{"start":{"line":19,"column":46},"end":{"line":19,"column":72}}}) : helper)))
    + "</td>\n    <td width=\"9%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.INVOICEID || (depth0 != null ? depth0.INVOICEID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INVOICEID","hash":{},"data":data,"loc":{"start":{"line":20,"column":46},"end":{"line":20,"column":59}}}) : helper)))
    + "</td>\n    <td width=\"10%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.INVOICEDATE || (depth0 != null ? depth0.INVOICEDATE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INVOICEDATE","hash":{},"data":data,"loc":{"start":{"line":21,"column":47},"end":{"line":21,"column":62}}}) : helper)))
    + "</td>\n    <td width=\"9%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.IDUEDATE || (depth0 != null ? depth0.IDUEDATE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IDUEDATE","hash":{},"data":data,"loc":{"start":{"line":22,"column":46},"end":{"line":22,"column":58}}}) : helper)))
    + "</td>\n    <td width=\"9%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.IPAIDDATE || (depth0 != null ? depth0.IPAIDDATE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IPAIDDATE","hash":{},"data":data,"loc":{"start":{"line":23,"column":46},"end":{"line":23,"column":59}}}) : helper)))
    + "</td>\n  </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"10%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">VAR360 ORDER</td>\n    <td width=\"16%\" style=\"border-bottom: 5px solid #b1b1b1;\">FUNDING SOURCE</td>\n    <td width=\"18%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">LEDGER ENTRY TYPE</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">$ AMOUNT</td>\n    <td width=\"9%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">STATUS</td>\n    <td width=\"9%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">INVOICE ID</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">INVOICE DATE</td>\n    <td width=\"9%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">DUE DATE</td>\n    <td width=\"9%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">PAID DATE</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.LEDGEROVERVIEW : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" style=\"border-top: 5px solid #b1b1b1;\" colspan=\"8\"></td>\n  </tr>\n</table>\n";
},"useData":true});