var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <tr>\n      <td width=\"52%\">\n        <table class=\"print-background\">\n          <tbody>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showQuoteName),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":12},"end":{"line":88,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showQuoteNumber),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":12},"end":{"line":101,"column":19}}})) != null ? stack1 : "")
    + "            <tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showQuoteDate),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":103,"column":14},"end":{"line":111,"column":21}}})) != null ? stack1 : "")
    + "            </tr>\n            <tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showQuoteDate),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":114,"column":14},"end":{"line":122,"column":21}}})) != null ? stack1 : "")
    + "            </tr>\n          </tbody>\n        </table>\n      </td>\n      <!-- <td style=\"width: 12px;\">&nbsp;</td> -->\n      <td width=\"48%\" style=\"vertical-align: top\">\n        <table style=\"width: 100%;\">\n          <tr>\n            <td>\n              <table class=\"contactname-background\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":16},"end":{"line":140,"column":25}}})) != null ? stack1 : "")
    + "              </table>\n            </td>\n          </tr>\n          <tr>\n            <td>\n              <table class=\"contactinfo-background\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":16},"end":{"line":160,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":16},"end":{"line":174,"column":23}}})) != null ? stack1 : "")
    + "              </table>\n            </td>\n          </tr>\n        </table>\n      </td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr>\n                <td colspan=\"2\" style=\"color: #D8D8D8; font-size: 10px; font-weight: 700; line-height: normal; letter-spacing: 0.28px; padding: 28px 35px 3px;\">QUOTE NAME</td>\n              </tr>\n              <tr>\n                <td colspan=\"2\" style=\"padding: 3px 35px 0;text-transform: none;font-size: 17px;font-weight: 600;line-height: normal;letter-spacing: 0.48px;max-width: 345px;\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.QUOTENAME), depth0))
    + "</td>\n              </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr>\n                <td colspan=\"2\" style=\"color: #D8D8D8; font-size: 10px; font-weight: 700; line-height: normal; letter-spacing: 0.28px; padding: 19px 35px; padding-bottom: 3px;\">QUOTE NUMBER</td>\n              </tr>\n              <tr>\n                <td colspan=\"2\" style=\"white-space: nowrap; padding: 3px 35px; font-size: 15px; font-weight: 600; line-height: normal; letter-spacing: 0.44px;\">\n                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEID), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER)) && stack1.length),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":18},"end":{"line":98,"column":25}}})) != null ? stack1 : "")
    + "                </td>\n              </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    - "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEREVISIONNUMBER), depth0))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <td style=\"color: #D8D8D8; font-size: 10px; font-weight: 700; line-height: normal; letter-spacing: 0.28px; padding: 26px 0 6px 35px; width: 175px;\">QUOTE DATE</td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteExpDate),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":16},"end":{"line":107,"column":23}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                  <td style=\"color: #D8D8D8; font-size: 10px; font-weight: 700; line-height: normal; letter-spacing: 0.28px; padding: 26px 0 6px 0;\">QUOTE EXPIRATION</td>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                <td colspan=\"2\" style=\"color: #D8D8D8; font-size: 10px; font-weight: 700; line-height: normal; letter-spacing: 0.28px; padding: 26px 0 6px 35px; width: 175px;\">&nbsp;</td>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <td style=\"padding-left: 35px; padding-bottom: 28px; font-size: 12px; font-weight: 500; line-height: normal; letter-spacing: 0.34px;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.LSDATE), depth0))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteExpDate),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":16},"end":{"line":118,"column":23}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <td style=\"padding-right: 35px; padding-bottom: 28px; font-size: 12px; font-weight: 500; line-height: normal; letter-spacing: 0.34px;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.QUOTEEXPIRE), depth0))
    + "</td>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                <td colspan=\"2\" style=\"padding-left: 35px; padding-bottom: 28px; font-size: 12px; font-weight: 500; line-height: normal; letter-spacing: 0.34px;\">&nbsp;</td>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <tr>\n                    <td style=\"color: #A1A1A1; font-size: 10px; font-weight: 700; line-height: normal; letter-spacing: 0.28px; padding: 26px 45px 3px;\">QUOTE CONTACT</td>\n                  </tr>\n                  <tr>\n                    <td style=\"padding: 3px 45px 14px;color: #000;font-size: 14px;font-weight: 600;line-height: normal;letter-spacing: 0.4px;white-space: nowrap;max-width: 345px;overflow: hidden;text-overflow: ellipsis;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "&nbsp;</td>\n                  </tr>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <tr>\n                    <td style=\"color: #A1A1A1; font-size: 10px; font-weight: 700; line-height: normal; letter-spacing: 0.28px; padding: 14px 45px; padding-bottom: 3px;\">PHONE</td>\n                  </tr>\n                  <tr>\n                    <td style=\"padding: 3px 45px; color: #000; font-size: 13px; font-weight: 700; line-height: normal; letter-spacing: 0.36px;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":153,"column":22},"end":{"line":157,"column":29}}})) != null ? stack1 : "")
    + "                    </td>\n                  </tr>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEPHONECONTACT)) && stack1.QuoteContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":156,"column":24},"end":{"line":156,"column":181}}})) != null ? stack1 : "")
    + "&nbsp;\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEPHONECONTACT)) && stack1.QuoteContactPhoneValueText), depth0))
    + " ";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Phone), depth0))
    + " ";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <tr>\n                    <td style=\"color: #A1A1A1; font-size: 10px; font-weight: 700; line-height: normal; letter-spacing: 0.28px; padding: 14px 45px; padding-bottom: 3px;\">EMAIL</td>\n                  </tr>\n                  <tr>\n                    <td style=\"padding: 3px 45px 31px;color: #000;font-size: 13px;font-weight: 700;line-height: normal;letter-spacing: 0.36px;white-space: nowrap;max-width: 345px;overflow: hidden;text-overflow: ellipsis;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":167,"column":22},"end":{"line":171,"column":29}}})) != null ? stack1 : "")
    + "                    </td>\n                  </tr>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTECONTACT)) && stack1.QuoteContactValue),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":170,"column":24},"end":{"line":170,"column":153}}})) != null ? stack1 : "")
    + "&nbsp;\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTECONTACT)) && stack1.QuoteContactValue), depth0))
    + " ";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Email), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<table width=\"100%\" id=\"quoteDetails_info\" style=\"padding: 0;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":2},"end":{"line":181,"column":9}}})) != null ? stack1 : "")
    + "</table>\n";
},"useData":true});