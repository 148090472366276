











































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "@/models/common";

declare const $: any
declare const getImageRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class SpiffImport extends TSXComponent<void> {

  uploading = false

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  handleReportFileUpload(e) {
    $(".spiffFileErrorMsg").html("");
  }

  handleUploadNewFile() {
    var comInstance = this;

    if (!$("#spiffFileUpload").get(0).files.length) {
      $(".spiffFileErrorMsg").html("Please upload a file.");
      return false;
    }
    var filePath = $("#spiffFileUpload").get(0).files[0].name;
    var allowedExtensions = /(\.csv)$/i;
    if (!allowedExtensions.exec(filePath)) {
      $(".spiffFileErrorMsg").html("Please upload file having extensions .csv only.");
      return false;
    } else {
      $(".spiffFileErrorMsg").html("");
      //upload file, and get returned json
      comInstance.uploading = true;
      var processedFileUpload = getImageRouteData({
        Controller: "Finances",
        FunctionName: "SpiffImport",
        orderfile: $("#spiffFileUpload").get(0).files[0],
        fileType: filePath.indexOf(".csv") != -1 ? "csv" : ""
      }).then(async (response, statusText, jqXHR) => {
        comInstance.uploading = false
        if(response.data.STATUS == 1) {
          //reset upload file
          $("#spiffFileUpload").val("");
          notifier.success(response.data.STATUSMESSAGE);
          this.$emit("save");
        }
        else {
          notifier.alert(response.data.STATUSMESSAGE)
        }
      }).catch(function (error) {
          // console.log(error);
      });
    }
  }

}
