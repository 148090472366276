






















































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import DropdownControl from "@/components/DropdownControl.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl
  }
})

export default class ExportToGustoModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  $validator: any;
  saving: boolean = false;
  planMonthOptions: any = [
    {
      'TEXT': 'January',
      'ID': 1
    },
    {
      'TEXT': 'February',
      'ID': 2
    },
    {
      'TEXT': 'March',
      'ID': 3
    },
    {
      'TEXT': 'April',
      'ID': 4
    },
    {
      'TEXT': 'May',
      'ID': 5
    },
    {
      'TEXT': 'June',
      'ID': 6
    },
    {
      'TEXT': 'July',
      'ID': 7
    },
    {
      'TEXT': 'August',
      'ID': 8
    },
    {
      'TEXT': 'September',
      'ID': 9
    },
    {
      'TEXT': 'October',
      'ID': 10
    },
    {
      'TEXT': 'November',
      'ID': 12
    },
    {
      'TEXT': 'December',
      'ID': 12
    },
  ];
  selectedPlanMonth: number[] = [];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  updatePlanMonth(id) {
    this.selectedPlanMonth = [id];
  }

  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$validator.validateAll().then(result => {
      if (result) {
        return this.exportGusto();
      }
    });
  }

  exportGusto() {
    if (this.selectedPlanMonth.length) {
      this.saving = true;
      this.$emit('export', {
        planMonth: this.selectedPlanMonth[0]
      });
    }
  }
}
