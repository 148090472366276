





























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../models/common";
import LaddaButton from "@/components/LaddaButton.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})

export default class AdjustmentsModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: []})
  adjustmentData!: any;

  @Prop({ required: false, default: {}})
  data!: any;

  @Prop({ required: false, default: 1})
  selectedAdjustmentMonth!: number;

  @Prop({ required: false, default: [] })
  lockedMonthData!: any;

  @Prop({ required: false, default: "" })
  selectedAdjustmentMonthAsString!: string;

  @Prop({ required: false, default: false })
  showAdjustmentMonthDropdown!: boolean;

  @Prop({ required: false, default: 0 })
  selectedAdjustmentAID!: number;

  @Prop({ required: false, default: "" })
  selectedAdjustmentAccountName!: string;

  $validator: any;
  adjustment_month: number = 1;
  adjustment_amount: number = 0;
  adjustment_notes: string = "";
  saving: boolean = false;
  totalAdjustments: number = 0;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  created() {
    if (this.selectedAdjustmentMonth !== 1) {
      this.adjustment_month = this.selectedAdjustmentMonth;
    } else if (this.selectedAdjustmentMonth === 1 && this.lockedMonthData.length) {
      let current_active_month = this.adjustmentData.find((item: any) => !this.lockedMonthData.includes(item.MONTH));
      if (current_active_month) {
        this.adjustment_month = current_active_month.MONTH || 1;
      }
    }
    this.changeMonth();
    this.getTotalAdjustment()
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async save() {
    const result = await this.$validator.validateAll();

    if (!result) {
      return;
    }

    this.adjustment_amount = this.adjustment_amount || 0;

    let findAdjustment = this.adjustmentData.find((item: any) => item.MONTH == this.adjustment_month);
    if (findAdjustment) {
      findAdjustment.AMOUNT = this.adjustment_amount;
      findAdjustment.AMOUNT_FORMATTED = `$${this.formatter(this.adjustment_amount)}`;
      findAdjustment.NOTES = this.adjustment_notes
    } else {
      let tempStruct = {};
      tempStruct["MONTH"] = this.adjustment_month;
      tempStruct["AMOUNT"] = this.adjustment_amount;
      tempStruct["AMOUNT_FORMATTED"] = `$${this.formatter(this.adjustment_amount)}`;
      tempStruct["NOTES"] = this.adjustment_notes;
      this.adjustmentData.push(tempStruct);
    }

    this.saving = true;
    let action: string = "updateAdjustment";
    if (this.selectedAdjustmentAID) {
      action = "updateRealizedProfitAdjustment";
    }
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanAdd",
      user_account: this.data.EMPLOYEEID || 0,
      action: action,
      adjustmentData: this.adjustmentData,
      compUUID: this.data.COMPUUID || '',
      totalAdjustments: this.totalAdjustments,
      adjustmentAID: this.selectedAdjustmentAID
    });
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.$emit("reload");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.saving = false;
  }

  formatter(amount) {
    // Use Intl.NumberFormat to format the total as a currency string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }

  changeMonth() {
    let findAdjustment = this.adjustmentData.find((item: any) => item.MONTH == this.adjustment_month);
    if (findAdjustment) {
      this.adjustment_amount = findAdjustment.AMOUNT;
      this.adjustment_notes = findAdjustment.NOTES || "";
    }
  }

  getTotalAdjustment() {
    let tempTotal = 0;
    if (!isNaN(this.adjustment_amount)) {
      this.adjustment_amount = Number(this.adjustment_amount) || 0;
      this.adjustmentData.map((item: any) => {
        if (item.MONTH != this.adjustment_month) {
          tempTotal += Number(item.AMOUNT);
        } else if (!isNaN(this.adjustment_amount)) {
          tempTotal += this.adjustment_amount;
        }
      });
      this.totalAdjustments = tempTotal;
    }
  }

}
