


































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { SecurityGroupDetails } from "../models/SecurityGroupDetails";

interface Props {
  options: SecurityGroupDetails.SecurityGroup[];
  selectedUserIds: number[];
  disabled: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class SecurityMultiSelect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: SecurityGroupDetails.SecurityGroup[];

  @Prop({ required: true })
  selectedUserIds!: number[];

  @Prop({ required: false, default: false })
  disabled?: boolean;

  @Prop({ required: false, default: false })
  highlightOptions?: boolean;

  @Prop({ required: false, default: "Select Security Group(s)" })
  label!: string;

  @Prop({ required: false, default: "Search Groups" })
  placeholderText!: string;

  dropdownVisible = false
  search = ''
  showOnlySelected: boolean = false

  autoExpand() {
    var value = $("#autoExpand").val();
    $("#autoExpand").css("width", ((value.length+1) * 9.2) + 'px');
  }

  get filteredOptions(): SecurityGroupDetails.SecurityGroup[] {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => `${option.SECURITYGROUPNAME}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 )
    }

    if (this.showOnlySelected) {
      options = options.filter((o) => this.selectedUserIds.indexOf(o.SECURITYGROUPID) !== -1)
    }

    return options
  }

  toggleDropdown() {
    if(!this.disabled) {
      this.dropdownVisible = !this.dropdownVisible
      if(this.dropdownVisible == true) {
        setTimeout(() => {
          $(".SecurityMultiSelect #autoExpand").focus();
        }, 300);
      }
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(userId: number): boolean {
    const checked = !!this.selectedUserIds.find((id) => id === userId)
    return checked
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }
}
