






































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";

import Loader from './Loader.vue'

interface Props {
  customerId: number;
  customerName: string;
  loading: boolean;
  list: string;
}

interface Events {}


@Component({
  inheritAttrs: false,
  components: {
    Loader,
  }
})
export default class CustomerSearchTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: true })
  customerId!: string;

  @Prop({ required: true })
  loading!: boolean;

   @Prop({ required: true })
  list!: string; 

  @Prop({ required: true })
  customerName!: string; 

  @Prop({ required: true })
  viewType!: string; 

  @Prop({ required: false, default: false })
  isHideSearchFor!: boolean;

  $allowedFunctions!: number[];
  $userFunctions!: {
    All_Milestone_Accounts: number
  };
  $parent: any;

  searchCustomer() {
    if(this.list == 'accounts'){
      this.$parent.viewFilters.allrows.aName = this.customerId;
    }else if(this.list == 'paycheck') {
     this.$parent.filters.aName = this.customerId;
    }else if(this.list == 'opportunities') {
     this.$parent.searchFilters.account_name = this.customerId;
    }else {
      this.$parent.searchFilters.searchAccountName = this.customerId;
    }
    this.$emit("search");
  }
  customerDetail() {
    this.$router.push({
      name: 'ViewCustomer',params: { id: this.customerId }
    })
  }
  globalSearch() {
    if(this.$allowedFunctions.includes(this.$userFunctions.All_Milestone_Accounts)) {
      this.$router.push({
        name: 'Search',params: { key: this.customerName, searchIn: this.list },
        query: {viewType : this.viewType}
      })
   }else {
     this.$router.push({
        name: 'Search',params: { key: this.customerName, searchIn: this.list }
      })
   }
  }
}
