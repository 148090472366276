var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerContent : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.ENDMESSAGE : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.QUOTEDETAILS : depth0)) != null ? stack1.ENDMESSAGE : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" id=\"quoteDetails_summary\">\n  <tr><td class=\"headerColor\"><strong>Terms and Conditions</strong></td></tr>\n  <tr>\n    <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.footerContent : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":11,"column":13}}})) != null ? stack1 : "")
    + "    </td>\n  </tr>\n</table>\n";
},"useData":true});